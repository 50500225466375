import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const orderListsServices = async (
  login_key,
  user_id,
  shop_id,
  start_date,
  end_date
) => {
  return await axios.put(`${baseUrl}orderList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    StartDate: start_date,
    EndDate: end_date,
  });
};
export const getOrderDetailServices = async (
  login_key,
  user_id,
  shop_id,
  order_id
) => {
  return await axios.put(`${baseUrl}orderDetailList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    OrderId: order_id,
  });
};
export const getOrderListAppShopsServices = async (
  login_key,
  user_id,
  order_status
) => {
  return await axios.put(`${baseUrl}orderListAllShops/`, {
    LoginKey: login_key,
    UserId: user_id,
    OrderStatus: order_status,
  });
};
