import React, { useEffect, useState } from "react";
import { primaryColor, white } from "../constants/Colors";
import { BsAndroid2 } from "react-icons/bs";
import { FaInternetExplorer } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
const ListHeader = ({
  title,
  clickedItem,
  show_back,
  item_uniqe_id,
  sort_switch,
  sort_click,
  sort_key,
}) => {
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const { lang } = useSelector((state) => state.selectedLanguage);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <div className="list_header_container">
      {show_back ? (
        <i className="fas fa-arrow-right" onClick={clickedItem}></i>
      ) : null}

      <h6
        className="menu-list-title"
        style={{
          margin: 0,
          width: width1 <= 560 ? "50%" : "77%",
        }}
      >
        {title}
      </h6>
      <h6>{item_uniqe_id}</h6>
      {sort_switch ? (
        <div className="product_header_container">
          <div className="product-list-header-cotainer-switch-buttons">
            <button
              onClick={() => sort_click(1)}
              style={{
                backgroundColor: sort_key == 1 ? primaryColor : null,
                color: sort_key == 1 ? white : null,
              }}
            >
              {/* <i class="fa fa-internet-explorer" aria-hidden="true"></i> */}
              <FaInternetExplorer style={{ margin: "0px 2px 4px 0px" }} />
              {lang.web}
            </button>
            <button
              onClick={() => sort_click(2)}
              style={{
                backgroundColor: sort_key == 2 ? primaryColor : null,
                color: sort_key == 2 ? white : null,
              }}
            >
              {/* <i class="fa fa-android" aria-hidden="true"></i> */}
              <BsAndroid2 style={{ margin: "0px 2px 4px 0px" }} />
              {lang.app}
            </button>
          </div>
        </div>
      ) : null}
      {/* {additionalـparameters ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "45%",
          }}
        >
          <h6 style={{ margin: 0 }}>{promotiom_status}</h6>
          <h6 style={{ margin: 0 }}>{exclude_meal_deals}</h6>
          <h6 style={{ margin: 0 }}>{code}</h6>
        </div>
      ) : null} */}
    </div>
  );
};

export default ListHeader;
