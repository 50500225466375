import React, { useState, useEffect } from "react";
import { main_nav_button_color, primaryColor } from "../../constants/Colors";
import ListHeader from "../../components/ListHeader";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  backToOrderAction,
  getOrderDetailAction,
} from "../../redux/Orders/Actions";
import { visibleRefunPopupAction } from "../../redux/financial/Actions";
import moment from "moment";
import Payment from "../settings/AppSettings/Componenets/Payment";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.selectedLanguage);

  const {
    loading_orders,
    selected_order_list,
    get_order_detail_list,
    back_to_order,
  } = useSelector((state) => state.ordersReduser);

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [clickedButton, setClickedButton] = useState(1);
  const handleRefunOrder = () => {
    // dispatch(showOrderListPopupAction(false));
    dispatch(
      visibleRefunPopupAction([
        true,
        selected_order_list,
        moment(selected_order_list.orderList_start_date).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        moment(selected_order_list.orderList_end_date).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        selected_shop[0].ShopId,
      ])
    );
  };
  useEffect(() => {
    if (selected_order_list) {
      dispatch(
        getOrderDetailAction(
          login_key,
          user_id,
          selected_order_list.ShopId,
          selected_order_list.OrderId
        )
      );
    }
  }, [selected_order_list]);

  return (
    <div
      className="countaining-div common-styles orders-container-div"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        backgroundColor: "#f8fafb",
      }}
      id="countaining"
    >
      <ListHeader
        title={lang.order_detail}
        show_back={true}
        clickedItem={() => {
          navigate(-1);
          dispatch(backToOrderAction(true));
        }}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="order_details_container">
            <div className="order_details_container_first_section">
              <div className="order_details_container_first_section_header">
                <button
                  type=""
                  style={{
                    borderBottom:
                      clickedButton == 1 ? `2px solid ${primaryColor}` : null,
                  }}
                  onClick={() => setClickedButton(1)}
                >
                  {lang.details}
                </button>
                <button
                  onClick={() => setClickedButton(2)}
                  type=""
                  style={{
                    borderBottom:
                      clickedButton == 2 ? `2px solid ${primaryColor}` : null,
                  }}
                >
                  {lang.customers}
                </button>
              </div>
              {clickedButton == 1 ? (
                <div>
                  <div className="order_detail_ocntainer">
                    <p>Order Id </p>
                    <p>
                      #
                      {selected_order_list ? selected_order_list.OrderId : null}
                    </p>
                  </div>
                  {selected_order_list ? (
                    selected_order_list.SaleMethodName ? (
                      <div className="order_detail_ocntainer">
                        <p>Sale Method </p>
                        <p>{selected_order_list.SaleMethodName}</p>
                      </div>
                    ) : null
                  ) : null}
                  <div className="order_detail_ocntainer">
                    <p>PayMethod </p>
                    <p>
                      {" "}
                      {selected_order_list
                        ? selected_order_list.PayMethodName
                        : null}
                    </p>
                  </div>
                  <div className="order_detail_ocntainer">
                    <p>Order date </p>
                    <p>
                      {" "}
                      {selected_order_list
                        ? moment(selected_order_list.OrderDate).format(
                            "YYYY/MM/DD-HH:mm"
                          )
                        : null}
                    </p>
                  </div>
                  <div className="order_detail_ocntainer">
                    <p>Delivery date </p>
                    <p>
                      {" "}
                      {selected_order_list
                        ? moment(selected_order_list.DeliveryTime).format(
                            "YYYY/MM/DD-HH:mm"
                          )
                        : null}
                    </p>
                  </div>
                  {selected_order_list ? (
                    selected_order_list.SpecialNote != "" ? (
                      <div className="order_detail_ocntainer_1">
                        <p>Special note :</p>
                        <p
                          style={{
                            wordWrap: "break-word",

                            overflowWrap: "anywhere",
                          }}
                        >
                          {selected_order_list.SpecialNote}
                        </p>
                      </div>
                    ) : null
                  ) : null}
                </div>
              ) : (
                <div>
                  <div className="order_detail_ocntainer">
                    <p>Customer </p>
                    <p>
                      {selected_order_list
                        ? selected_order_list.CustomerFullName
                        : null}
                    </p>
                  </div>{" "}
                  <div className="order_detail_ocntainer">
                    <p>Email </p>
                    <p>
                      {selected_order_list ? selected_order_list.Mail : null}
                    </p>
                  </div>
                  <div className="order_detail_ocntainer">
                    <p>Phone </p>
                    <p>
                      {selected_order_list
                        ? selected_order_list.CustomerTelephone
                        : null}
                    </p>
                  </div>
                  {selected_order_list ? (
                    selected_order_list.SaleMethod == 1000101 ||
                    selected_order_list.SaleMethod == 1000105 ? (
                      <div className="order_detail_ocntainer_1">
                        <p>Address :</p>
                        <p
                          style={{
                            wordWrap: "break-word",
                            overflowWrap: "anywhere",
                          }}
                        >
                          {selected_order_list.Flat}{" "}
                          {selected_order_list.CustomerAddress}
                        </p>
                      </div>
                    ) : null
                  ) : null}
                </div>
              )}
            </div>
            <div className="order_details_container_second_section">
              <div className="order_details_container_first_section_header">
                <h5>{lang.order_items}</h5>
              </div>
              <div>
                <div
                  style={{
                    borderBottom: "1px solid rgb(192, 192, 192)",
                  }}
                >
                  {get_order_detail_list
                    ? get_order_detail_list.length > 0
                      ? get_order_detail_list.map((item, index) => (
                          <div
                            className="order_detail_"
                            style={{
                              padding: "15px 0px",
                            }}
                          >
                            <div className="basket_product_container">
                              <div className="basket_product_title_container">
                                <div id="product_number_container">
                                  <h6 style={{ margin: 0 }}>{item.Count}</h6>
                                </div>
                                <h6 style={{ margin: 0 }}>
                                  {item.CrustName ? item.CrustName : null}{" "}
                                  {item.OptionName ? item.OptionName : null}{" "}
                                  {item.IsGeneralItem
                                    ? item.EditedTitle
                                    : item.ProductName}
                                </h6>
                              </div>
                              <h6 style={{ margin: 0 }}>
                                £{item.SubTotal.toFixed(2)}
                              </h6>
                            </div>
                            {item.order_detail_subitem_list ? (
                              item.order_detail_subitem_list.length > 0 ? (
                                <div className="basket_sublist_container">
                                  {item.order_detail_subitem_list.map(
                                    (sub_item, ind) => (
                                      <div
                                        style={{
                                          padding: "4px 0px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="basket_product_title_container">
                                          <p style={{ margin: 0 }}>
                                            {sub_item.ItemName
                                              ? sub_item.ItemName
                                              : null}
                                          </p>
                                        </div>
                                        <p style={{ margin: 0 }}>
                                          £{sub_item.ItemAmount.toFixed(2)}
                                        </p>
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        ))
                      : null
                    : null}
                </div>
                <div style={{ padding: "10px 0px" }}>
                  <div className="order_detail_ocntainer">
                    <p>Sub total </p>
                    <p>
                      £
                      {selected_order_list
                        ? selected_order_list.SubTotal.toFixed(2)
                        : null}
                    </p>
                  </div>
                  {selected_order_list ? (
                    selected_order_list.DeliveryCharge > 0 ? (
                      <div className="order_detail_ocntainer">
                        <p>Delivery fee </p>
                        <p>£{selected_order_list.DeliveryCharge.toFixed(2)}</p>
                      </div>
                    ) : null
                  ) : null}

                  {selected_order_list ? (
                    selected_order_list.AdminFee > 0 ? (
                      <div className="order_detail_ocntainer">
                        <p>Admin fee </p>
                        <p>£{selected_order_list.AdminFee.toFixed(2)}</p>{" "}
                      </div>
                    ) : null
                  ) : null}
                  {selected_order_list ? (
                    selected_order_list.CarrierBag > 0 ? (
                      <div className="order_detail_ocntainer">
                        <p>Carrier bag </p>
                        <p>£{selected_order_list.CarrierBag.toFixed(2)}</p>
                      </div>
                    ) : null
                  ) : null}
                  {selected_order_list ? (
                    selected_order_list.ExtraChargeText ? (
                      <div className="order_detail_ocntainer">
                        <p>{selected_order_list.ExtraChargeText} </p>
                        <p>
                          £{selected_order_list.ExtraChargeAmount.toFixed(2)}
                        </p>
                      </div>
                    ) : null
                  ) : null}
                  {selected_order_list ? (
                    selected_order_list.TotalDiscount > 0 ? (
                      <div className="order_detail_ocntainer">
                        <p>Discount </p>

                        <p>£{selected_order_list.TotalDiscount.toFixed(2)}</p>
                      </div>
                    ) : null
                  ) : null}

                  {/* ) : null} */}
                  <div className="order_detail_ocntainer">
                    <p>Total </p>
                    <p>
                      £
                      {selected_order_list
                        ? selected_order_list.ForPay.toFixed(2)
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="order_details_container_third_section">
              <div className="order_details_container_first_section_header">
                <h5>{lang.refund_payment}</h5>
              </div>
              <div className="order_details_container_third_section_refund_and_reset">
                <div>
                  <p>
                    If you customer request for refund or cancel the order
                    please click of refund request here and your refund will
                    process by our Payment gateway, please advice your customer
                    this process taking about 5-10 working days.
                  </p>
                  <button
                    type=""
                    className="order_details_refund_payment_button"
                    onClick={handleRefunOrder}
                  >
                    {lang.refund_payment}
                  </button>
                </div>
                <div>
                  <h5>Reset</h5>
                  <p>
                    If you reset the order it changes the status back to 1 and
                    removes it from any closed session. This makes it available
                    again as a new order on the OrderPad.
                  </p>
                  <button type="" className="order_details_refund_reset_order">
                    {lang.reset_order}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
