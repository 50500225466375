import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import ListHeader from "./ListHeader";
import { main_nav_button_color } from "../constants/Colors";
import SaveMenuButton from "./SaveMenuButton";
import { primaryColor, main_nav_back_color } from "../constants/Colors";
import { normal_input_height } from "../constants/Sizes";
import { createUsersAction, updateUsersAction } from "../redux/Users/Actions";
import { shopListByGroupIdAction } from "../redux/Users/Actions";
const EmployeesEdit = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_users_list, selected_users_list_employees, employees_list } =
    useSelector((state) => state.usersReduser);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    dashboard_accessUser,
    dashboard_accessUser_id,
    dashboard_shop_group,
    dashboard_shop_group_id,
  } = useSelector((state) => state.categoryList);
  const { shop_list_by_group_id, result_successfull } = useSelector(
    (state) => state.usersReduser
  );

  const [firstName, setFirstName] = useState("");
  const [lastNmae, setLastName] = useState("");
  const [gropList, setGroupList] = useState(null);
  const [shopGrop, setShopGrop] = useState([]);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHash, setPasswordHash] = useState("");
  const [active, setActive] = useState(false);
  const [shoGroupList, setShopGroupList] = useState([]);
  const [loginOTP, setLoginOTP] = useState(true);
  const [mobile, setMobile] = useState("");
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [twoFactor, setTwoFactor] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const getSHA256Hash = async (input) => {
    const textAsBuffer = new TextEncoder().encode(input);

    const hashBuffer = await window.crypto.subtle.digest(
      "SHA-256",
      textAsBuffer
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((item) => item.toString(16).padStart(2, "0"))
      .join("");
    return hash;
  };
  // (async () => {
  //   const hash = await getSHA256Hash(password);
  //   console.log("h", hash);
  // })();
  const handleCreateAndSave = async () => {
    const hash_password = await getSHA256Hash(password);
    let dashboard_accessUser_id_list_to_server_array = [];
    let shop_group_list = [];
    if (gropList) {
      for (let i = 0; i < gropList.length; i++) {
        dashboard_accessUser_id_list_to_server_array.push(gropList[i].value);
      }
    }
    if (shopGrop) {
      for (let i = 0; i < shopGrop.length; i++) {
        shop_group_list.push(shopGrop[i].value);
      }
    }

    if (selected_users_list_employees) {
      if (
        firstName == "" ||
        lastNmae == "" ||
        userName == "" ||
        password == "" ||
        !gropList ||
        !shopGrop.length > 0
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        if (loginOTP) {
          if (mobile == "") {
            toast.error(
              <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          } else {
            dispatch(
              updateUsersAction(
                login_key,
                user_id,
                selected_users_list_employees.UserId,
                10001203,
                firstName,
                lastNmae,
                active ? 1 : 0,
                shopGrop ? shop_group_list.toString() : "",
                userName,
                password == "11111111"
                  ? passwordHash.replaceAll(`"`, `\"\"`)
                  : hash_password.replaceAll(`"`, `\"\"`),
                dashboard_accessUser_id_list_to_server_array.toString(),
                0,
                mobile ? mobile : "",
                loginOTP ? 1 : 0,
                twoFactor ? 1 : 0
              )
            );
          }
        } else {
          dispatch(
            updateUsersAction(
              login_key,
              user_id,
              selected_users_list_employees.UserId,
              10001203,
              firstName,
              lastNmae,
              active ? 1 : 0,
              shopGrop ? shop_group_list.toString() : "",
              userName,
              password == "11111111"
                ? passwordHash.replaceAll(`"`, `\"\"`)
                : hash_password.replaceAll(`"`, `\"\"`),
              dashboard_accessUser_id_list_to_server_array.toString(),
              0,
              mobile ? mobile : "",
              loginOTP ? 1 : 0,
              twoFactor ? 1 : 0
            )
          );
        }
      }
    } else {
      if (
        firstName == "" ||
        lastNmae == "" ||
        userName == "" ||
        password == "" ||
        !gropList ||
        !shopGrop.length > 0
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        if (loginOTP) {
          if (mobile == "") {
            toast.error(
              <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          } else {
            dispatch(
              createUsersAction(
                login_key,
                user_id,
                10001203,
                firstName,
                lastNmae,
                active ? 1 : 0,
                shopGrop ? shop_group_list.toString() : "",
                userName,
                hash_password.replaceAll(`"`, `\"\"`),
                dashboard_accessUser_id_list_to_server_array.toString(),
                0,
                mobile ? mobile : "",
                loginOTP ? 1 : 0,
                twoFactor ? 1 : 0
              )
            );
          }
        } else {
          dispatch(
            createUsersAction(
              login_key,
              user_id,
              10001203,
              firstName,
              lastNmae,
              active ? 1 : 0,
              shopGrop ? shop_group_list.toString() : "",
              userName,
              hash_password.replaceAll(`"`, `\"\"`),
              dashboard_accessUser_id_list_to_server_array.toString(),
              0,
              mobile ? mobile : "",
              loginOTP ? 1 : 0,
              twoFactor ? 1 : 0
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    if (selected_users_list_employees) {
      setFirstName(selected_users_list_employees.FirstName);
      setLastName(selected_users_list_employees.LastName);
      setUserName(selected_users_list_employees.UserName);
      if (selected_users_list_employees.Pass.length > 20) {
        setPasswordHash(selected_users_list_employees.Pass);
        setPassword(11111111);
      } else {
        setPassword(selected_users_list_employees.Pass);
      }

      setActive(selected_users_list_employees.Active);
      setGroupList(selected_users_list_employees.access);
      setShopGrop(selected_users_list_employees.ShopGroupId);
      if (selected_users_list_employees.Mobile) {
        setMobile(selected_users_list_employees.Mobile);
      } else {
        setMobile("");
      }
      setTwoFactor(selected_users_list_employees.TwoFactor);
      setLoginOTP(selected_users_list_employees.LoginOTP);
    }
  }, [selected_users_list_employees]);

  useEffect(() => {
    const copy_dashboard_shop_group = [...dashboard_shop_group];
    if (copy_dashboard_shop_group.length > 0) {
      const sortArrey = copy_dashboard_shop_group.sort(function (a, b) {
        return a.SortId - b.SortId;
      });
      setShopGroupList(sortArrey);
    }
  }, [dashboard_shop_group]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <ListHeader
        title={lang.imployees_edit}
        show_back={true}
        clickedItem={() => setClickedItem(1)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {/* <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "50px",
              marginBottom: "-50px",
            }}
          >
            <div className="sm-m"></div>
            <div className="sm-m"></div>
            <div className="sm-m">
              <h6 className=" h6">* {lang.access}</h6>
            </div>
          </div> */}
          <div className="sm-div1">
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.first_name}</h6>
              <input
                className="sm-input input-padding"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.last_name}</h6>
              <input
                className="sm-input input-padding"
                value={lastNmae}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            {/* <Box
              component="form"
              // sx={{
              //   "& > :not(style)": { m: 1, width: "35ch", height: "20ch" },
              // }}
              // noValidate
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
              autoComplete="on"
            >
              <CssTextField
                key={1}
                label={lang.first_name}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                key={2}
                label={lang.last_name}
                value={lastNmae}
                onChange={(e) => setLastName(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                onBlur={() => setFocusedInputKey(null)}
              />
             
            </Box> */}
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.access}</h6>
              <Select
                options={dashboard_accessUser}
                defaultValue={gropList}
                //   isSearchable={false}
                onChange={setGroupList}
                value={gropList}
                isMulti={true}
                styles={customStyles}
                isClearable={true}
                isSearchable={false}
              />
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              // marginTop: "50px",
              marginBottom: "-50px",
            }}
          >
            <div className="sm-m"></div>
            <div className="sm-m"></div>
            <div className="sm-m">
              <h6 className=" h6">* {lang.shop_group}</h6>
            </div>
          </div> */}
          <div className="sm-div2">
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.user_name}</h6>
              <input
                className="sm-input input-padding"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.pass}</h6>
              <input
                className="sm-input input-padding"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setPassword("")}
              />
            </div>
            {/* <Box
              component="form"
              // sx={{
              //   "& > :not(style)": { m: 1, width: "35ch", height: "20ch" },
              // }}
              // noValidate
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              autoComplete="on"
            >
              <CssTextField
                key={3}
                label={lang.user_name}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 3 ? true : false}
                onFocus={() => setFocusedInputKey(3)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                key={4}
                label={lang.pass}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 4 ? true : false}
                onFocus={() => setFocusedInputKey(4)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box> */}
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.shop_group}</h6>
              <Select
                options={shoGroupList}
                defaultValue={shopGrop}
                //   isSearchable={false}
                onChange={(list, item) => setShopGrop(list)}
                value={shopGrop}
                isMulti={true}
                styles={customStyles}
                isClearable={true}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="sm-div2">
            <div className="sm-m  ">
              <h6 className="submenuEdit-titles h6">
                {loginOTP ? `* ${lang.mobile}` : lang.mobile}
              </h6>
              <input
                className="sm-input input-padding"
                value={mobile}
                maxLength={11}
                onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="sm-m  div-chechbox-p countainer-checkbox1">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={loginOTP}
                      onChange={() => setLoginOTP(!loginOTP)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.login_otp}
                />
              </FormGroup>
            </div>
            <div className="sm-m  div-chechbox-p countainer-checkbox1">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={() => setActive(!active)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>
            {/* </div> */}
          </div>
          <div className="sm-m  div-chechbox-p countainer-checkbox1">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={twoFactor}
                    onChange={() => setTwoFactor(!twoFactor)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.two_factor}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={handleCreateAndSave} />
    </div>
  );
};

export default EmployeesEdit;
