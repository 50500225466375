export const english_oto_menu = [
  {
    id: 1,
    title: "Setting",
    icon: "",
    expanded: false,
    path: "",
    sublist: [],
    role_id: 91,
  },
  {
    id: 2,
    title: "MetaData",
    icon: "",
    expanded: false,
    path: "metadata",
    sublist: [],
    role_id: 92,
  },
];
export const turkish_oto_menu = [];
export const persian_oto_menu = [];
