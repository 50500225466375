import React, { useState, useEffect } from "react";
import SaveMenuButton from "../../../components/SaveMenuButton";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../../../constants/Colors";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  getExternalCompanyListAction,
  showExternalCompanyShopsListPopupAction,
  updateExternalCompanyAction,
} from "../../../redux/MainNavGeneral/Actions";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import { dashboard_domain } from "../../../constants/BaseUrl";
import ListHeader from "../../../components/ListHeader";
import { makeStyles } from "@material-ui/core";
const ExternalCompanyEdit = ({ setClickedItem }) => {
  const useStyles = makeStyles({
    customLabelStyle: {
      fontSize: "12px",
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { shops_list } = useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { external_company_list, selected_external_company } = useSelector(
    (state) => state.MainNavGeneralRedusers
  );
  const { general_second_screen_shop_list } = useSelector(
    (state) => state.shopReducer
  );

  const { result_failed, result_message, result_successfull, loading } =
    useSelector((state) => state.MainNavGeneralRedusers);

  const [shopList, setShopList] = useState([]);
  const [shopList1, setShopList1] = useState([]);
  const [shopList2, setShopList2] = useState([]);
  const [shopList3, setShopList3] = useState([]);
  const [src, setSrc] = useState(null);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [shopsAccess, setShopsAccess] = useState("");
  const [radioButton, setRadioButton] = useState("all shops");
  const [selectedShopIds, setSelectedShopIds] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [shopsIds, setShopsIds] = useState([]);
  const [lastImageUrl, setLastImageUrl] = useState("");

  const onSelectPicture = (e) => {
    setImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }

    // setImagesList(images_list);
  };
  const handleShowShops = (e) => {
    setRadioButton(e.target.value);
    let new_shops_list = [];
    const copy_shops_list = [...shopList2];

    if (e.target.value == "all shops") {
      if (shopList3.length > 0) {
        for (let i = 0; i < shopList3.length; i++) {
          new_shops_list.push(shopList3[i]);
        }
      } else if (copy_shops_list) {
        if (copy_shops_list.length > 0) {
          for (let i = 0; i < copy_shops_list.length; i++) {
            new_shops_list.push(copy_shops_list[i]);
          }
        }
      }
    } else if (e.target.value == "selected shops") {
      if (shopList3.length > 0) {
        for (let i = 0; i < shopList3.length; i++) {
          if (shopList3[i].selected) {
            new_shops_list.push(shopList3[i]);
          }
        }
      } else if (copy_shops_list) {
        if (copy_shops_list.length > 0) {
          for (let index = 0; index < copy_shops_list.length; index++) {
            if (copy_shops_list[index].selected) {
              new_shops_list.push(copy_shops_list[index]);
            }
          }
        }
      }
    } else if (e.target.value == "unselected shops") {
      if (shopList3.length > 0) {
        for (let i = 0; i < shopList3.length; i++) {
          if (!shopList3[i].selected) {
            new_shops_list.push(shopList3[i]);
          }
        }
      } else if (copy_shops_list) {
        if (copy_shops_list.length > 0) {
          for (let index = 0; index < copy_shops_list.length; index++) {
            if (!copy_shops_list[index].selected) {
              new_shops_list.push(copy_shops_list[index]);
            }
          }
        }
      }
    }

    setShopList1(new_shops_list);
    setShopList(new_shops_list);
  };

  const handleSelectItem = (item) => {
    let copy_list = [...shopList1];
    for (let i = 0; i < copy_list.length; i++) {
      if (copy_list[i].ShopId == item.ShopId) {
        if (copy_list[i].selected) {
          copy_list[i].selected = false;
          let new_list = selectedShopIds.filter((i) => i != item.ShopId);

          setSelectedShopIds(new_list);
        } else {
          copy_list[i].selected = true;
          selectedShopIds.push(item.ShopId);
        }
      }
    }

    setShopList(copy_list);
    // setShopList1(copy_list);
    // setShopList2(copy_list);
  };

  const hanldeSerchInput = (e) => {
    setRadioButton("all shops");
    setSearchInput(e.target.value);
    const copy_shop_list = [...shopList2];

    let shop_list = [];
    let newList = [];
    let new_shops_list = [];
    const copy_shops_list = [...shopList1];
    if (e.target.value !== "") {
      newList = copy_shop_list.filter((item) => {
        let lc = item.SearchTitle.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });

      setShopList(newList);
      setShopList1(newList);
      setShopList3(newList);
    } else {
      setShopList3([]);
      setShopList(copy_shop_list);
      setShopList1(copy_shop_list);
    }
  };
  const formData = new FormData();
  const handleSave = () => {
    let shops_id = [];
    const copy_shop_list = [...shopList];
    if (copy_shop_list) {
      if (copy_shop_list.length > 0) {
        for (let i = 0; i < copy_shop_list.length; i++) {
          if (copy_shop_list[i].selected) {
            shops_id.push(copy_shop_list[i].ShopId);
          }
        }
      }
    }

    if (title == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (image != "" && image.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.image_size_error}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append(
        "ExternalCompanyId",
        selected_external_company.ExternalCompanyId
      );
      formData.append("Title", title);
      formData.append(
        "ShopGroupId",
        shops_id ? (shops_id.length > 0 ? shops_id.toString() : "") : ""
      );
      formData.append("ProfileImage", image ? image : "");
      formData.append("LastProfileImageName", lastImageUrl ? lastImageUrl : "");

      dispatch(
        updateExternalCompanyAction(
          formData,
          login_key,
          user_id,
          selected_external_company.ExternalCompanyId,
          title,
          shops_id ? (shops_id.length > 0 ? shops_id.toString() : "") : "",
          image ? image : "",
          lastImageUrl ? lastImageUrl : ""
        )
      );
    }
  };
  useEffect(() => {
    let shop_ids = [];
    if (general_second_screen_shop_list) {
      if (general_second_screen_shop_list.length > 0) {
        for (let i = 0; i < general_second_screen_shop_list.length; i++) {
          shop_ids.push(general_second_screen_shop_list[i].ShopId);
          setShopsIds(shop_ids);
        }
        setShopList(general_second_screen_shop_list);
        setShopList1(general_second_screen_shop_list);
        setShopList2(general_second_screen_shop_list);
      }
    }
  }, [general_second_screen_shop_list]);

  useEffect(() => {
    const copy_shopList = [...general_second_screen_shop_list];
    if (selected_external_company) {
      if (selected_external_company.access_Shop) {
        if (selected_external_company.access_Shop.length > 0) {
          for (
            let i = 0;
            i < selected_external_company.access_Shop.length;
            i++
          ) {
            if (
              selected_external_company.ExternalCompanyId ==
              selected_external_company.access_Shop[i].ExternalCompanyId
            ) {
              if (copy_shopList) {
                if (copy_shopList.length > 0) {
                  for (let index = 0; index < copy_shopList.length; index++) {
                    if (
                      selected_external_company.access_Shop[i].ShopId ==
                      copy_shopList[index].ShopId
                    ) {
                      copy_shopList[index].selected = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
      setLastImageUrl(selected_external_company.Logo);
      setShopList(copy_shopList);
      setTitle(selected_external_company.Title);

      if (selected_external_company.Logo) {
        setSrc(`${dashboard_domain}${selected_external_company.Logo}`);
      } else {
        setSrc("");
      }
    }
    return () => {
      if (copy_shopList) {
        if (copy_shopList.length > 0) {
          for (let index = 0; index < copy_shopList.length; index++) {
            copy_shopList[index].selected = false;
            setShopList(copy_shopList);
          }
        }
      }
    };
  }, [selected_external_company]);
  useEffect(() => {
    formData.delete("ProfileImage");
  }, []);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);

  return (
    <div
      className="countaining-div "
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <ListHeader
        title={lang.external_company_edit}
        show_back={true}
        clickedItem={() => setClickedItem(1)}
      />
      <div className="menu_list_header_container_tabel_list">
        <div
          className="margin_menu_normal_horizontal"
          style={{ height: "100%" }}
        >
          <div className="external-company-container">
            <div className="external-company-container-title-and-logo">
              <Box
                component="form"
                // sx={{
                //   "& > :not(style)": { m: 1, display: "flex" },
                // }}

                autoComplete="on"
              >
                <TextField
                  sx={{
                    "& label.Mui-focused": {
                      color: primaryColor,
                    },
                    "&:hover label": {
                      color: primaryColor,
                    },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "15px",
                      height: "50px",
                      color: "black",
                      "& fieldset": {
                        borderColor: "grey",
                      },
                      "&:hover fieldset": {
                        borderColor: primaryColor,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor,
                      },
                    },
                  }}
                  size="small"
                  key={2}
                  InputLabelProps={{
                    sx: {
                      fontSize: 16,
                      // top: -7,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primaryColor,
                      },
                    },
                  }}
                  label={`* ${lang.title}`}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{
                    width: "100%",
                  }}
                  // autoFocus={focusedInputKey == 2 ? true : false}
                  // onFocus={() => setFocusedInputKey(2)}
                />
              </Box>

              <div className="external-company-container-choose-image-box">
                <div
                  className="second-screen-container-image external-company-container-image"
                  id="second-screen-container-image"
                  // ref={elementRef}
                  style={{ position: "relative" }}
                >
                  <img
                    // onLoad={loadImage}
                    src={
                      src
                        ? src
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                    }
                    id="profile_image"
                  />
                  <p className="image-default-size">{lang.image_size}</p>
                </div>
                <div className="second-screen-container-send-and-chooseImage-buttons">
                  <div>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      class="common-inputfile "
                      accept="image/*"
                      onChange={(e) => onSelectPicture(e)}
                    />
                    <label for="file">
                      {" "}
                      <i className="fas fa-camera"></i>
                      <span> Choose Photo</span>
                    </label>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="external-company-container-shops-list">
              <div className="external-company-container-serach-box-and-radio-buttons">
                <div className=" external-company-container-search-input">
                  <i class="fa fa-search" aria-hidden="true"></i>
                  <input
                    placeholder="Search by shop name , shop id"
                    onChange={(e) => hanldeSerchInput(e)}
                  />
                </div>
                <div className=" external-company-container-radio-buttons">
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    <FormControlLabel
                      classes={{ label: classes.customLabelStyle }}
                      control={
                        <Radio
                          value="all shops"
                          checked={radioButton === "all shops"}
                          onChange={(e) => handleShowShops(e)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: 22,
                            },
                          }}
                        />
                      }
                      label={lang.all_shops}
                    />
                  </RadioGroup>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    // className="shop-group-popup-radio-group"
                  >
                    <FormControlLabel
                      classes={{ label: classes.customLabelStyle }}
                      control={
                        <Radio
                          value="selected shops"
                          checked={radioButton === "selected shops"}
                          onChange={(e) => handleShowShops(e)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: 22,
                            },
                          }}
                        />
                      }
                      label={lang.selected_shops}
                    />
                  </RadioGroup>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    // className="shop-group-popup-radio-group"
                  >
                    <FormControlLabel
                      classes={{ label: classes.customLabelStyle }}
                      control={
                        <Radio
                          value="unselected shops"
                          checked={radioButton === "unselected shops"}
                          onChange={(e) => handleShowShops(e)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: 22,
                            },
                          }}
                        />
                      }
                      label={lang.unselected_shops}
                    />
                  </RadioGroup>
                </div>
              </div>

              <div
                className="shop_group_container_shops_list"
                style={{
                  height: "calc(100vh - 400px)",
                }}
              >
                <div className="shop_groups_container_boyh_lists">
                  {shopList.map((item, index) =>
                    index % 2 == 0 ? (
                      <div key={index} onClick={() => handleSelectItem(item)}>
                        <div
                          className=" shop_group_container_any_shop"
                          style={{
                            backgroundColor: item.InProductSubmenu
                              ? "#bdbdbd"
                              : null,
                          }}
                        >
                          {" "}
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item.selected ? true : false}
                                  // onChange={() => handleSelectItem(item)}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: primaryColor,
                                    },
                                  }}
                                />
                              }
                              // label={lang.incloud_half_and_half}
                            />
                          </FormGroup>
                          <p>
                            {item.ShopId} {item.Title}
                          </p>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
                <div className="shop_groups_container_boyh_lists">
                  {shopList.map((item, index) =>
                    index % 2 != 0 ? (
                      <div key={index} onClick={() => handleSelectItem(item)}>
                        <div
                          className=" shop_group_container_any_shop"
                          style={{
                            backgroundColor: item.InProductSubmenu
                              ? "#bdbdbd"
                              : null,
                          }}
                        >
                          {" "}
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item.selected ? true : false}
                                  // onChange={() => handleSelectItem(item)}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: primaryColor,
                                    },
                                  }}
                                />
                              }
                              // label={lang.incloud_half_and_half}
                            />
                          </FormGroup>
                          <p>
                            {item.ShopId} {item.Title}
                          </p>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <SaveMenuButton clickButton={handleSave} />
    </div>
  );
};

export default ExternalCompanyEdit;
