import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import toast, { Toaster } from "react-hot-toast";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ListHeader from "../../../../components/ListHeader";
import { useSelector, useDispatch } from "react-redux";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import Select from "react-select";
import {
  createPaymentDojoSettingAction,
  updatePaymentDojoSettingAction,
} from "../../../../redux/settings/Action";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
const DojoEdit = ({ setClickedItem }) => {
  const didMountRef = useRef(false);
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    selected_dojo_setting,
    result_successfull,
    get_payment_dojo_setting_list,
  } = useSelector((state) => state.appSettingsRedusers);

  const { worksStation_list, WS_Ids, work_station_list_for_dojo } = useSelector(
    (state) => state.worksStationsReduser
  );
  const [title, setTitle] = useState("");
  const [terminalId, setTerminalId] = useState("");
  const [softWareHouse, setSoftWareHouse] = useState("");
  const [currency, setcurrency] = useState("");
  const [installerId, setInstallerId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiUrl, setApiUrl] = useState("");

  const [active, setActive] = useState(true);
  const [selectedWorkStation, setSelectedWorkStation] = useState(null);
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [wsIds, setWsIds] = useState([]);
  const [terminalIds, setTerminalIds] = useState([]);
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "black",
      fontSize: "15px",

      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });
  const customStyles = {
    control: (base, state) => ({
      width: "100%",
      zIndex: "55555",
      ...base,
      // height: 40,
      minHeight: 45,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleCreateAndSavePaymentDojoSetting = () => {
    if (selected_dojo_setting) {
      if (
        title == "" ||
        terminalId == "" ||
        currency == "" ||
        apiKey == "" ||
        apiUrl == "" ||
        !selectedWorkStation
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (terminalId) {
        if (terminalIds) {
          if (terminalIds.length > 0) {
            if (terminalIds.includes(terminalId)) {
              if (selected_dojo_setting.TId == terminalId) {
                dispatch(
                  updatePaymentDojoSettingAction(
                    login_key,
                    user_id,
                    selected_shop[0].ShopId,
                    selected_dojo_setting.Id,
                    title,
                    terminalId,
                    apiUrl,
                    apiKey,
                    currency,
                    softWareHouse,
                    installerId,
                    active ? 1 : 0,
                    selectedWorkStation.value
                  )
                );
              } else {
                toast.error(
                  <h5>
                    {" "}
                    &nbsp;&nbsp;{lang.terminal_id_duplicate}&nbsp;&nbsp;{" "}
                  </h5>,
                  {
                    position: "top-center",
                    duration: 3000,
                    style: { backgroundColor: "red", color: "white" },
                    icon: (
                      <i
                        style={{ fontSize: 40 }}
                        className="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>
                    ),
                  }
                );
              }
            } else {
              dispatch(
                updatePaymentDojoSettingAction(
                  login_key,
                  user_id,
                  selected_shop[0].ShopId,
                  selected_dojo_setting.Id,
                  title,
                  terminalId,
                  apiUrl,
                  apiKey,
                  currency,
                  softWareHouse,
                  installerId,
                  active ? 1 : 0,
                  selectedWorkStation.value
                )
              );
            }
          }
        }
      }
    } else {
      if (
        title == "" ||
        terminalId == "" ||
        currency == "" ||
        apiKey == "" ||
        apiUrl == "" ||
        !selectedWorkStation
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (terminalId) {
        if (terminalIds) {
          if (terminalIds.length > 0) {
            if (terminalIds.includes(terminalId)) {
              toast.error(
                <h5> &nbsp;&nbsp;{lang.terminal_id_duplicate}&nbsp;&nbsp; </h5>,
                {
                  position: "top-center",
                  duration: 3000,
                  style: { backgroundColor: "red", color: "white" },
                  icon: (
                    <i
                      style={{ fontSize: 40 }}
                      className="fa fa-times-circle"
                      aria-hidden="true"
                    ></i>
                  ),
                }
              );
            } else {
              dispatch(
                createPaymentDojoSettingAction(
                  login_key,
                  user_id,
                  selected_shop[0].ShopId,
                  title,
                  terminalId,
                  apiUrl,
                  apiKey,
                  currency,
                  softWareHouse,
                  installerId,
                  active ? 1 : 0,
                  selectedWorkStation.value
                )
              );
            }
          } else {
            dispatch(
              createPaymentDojoSettingAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                title,
                terminalId,
                apiUrl,
                apiKey,
                currency,
                softWareHouse,
                installerId,
                active ? 1 : 0,
                selectedWorkStation.value
              )
            );
          }
        } else {
          dispatch(
            createPaymentDojoSettingAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              title,
              terminalId,
              apiUrl,
              apiKey,
              currency,
              softWareHouse,
              installerId,
              active ? 1 : 0,
              selectedWorkStation.value
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    let work_station = null;

    if (selected_dojo_setting) {
      setTitle(selected_dojo_setting.Title);
      setTerminalId(selected_dojo_setting.TId);
      setSoftWareHouse(selected_dojo_setting.Software_House_Id);
      setcurrency(selected_dojo_setting.Currency);
      setInstallerId(selected_dojo_setting.Installer_Id);
      setApiKey(selected_dojo_setting.ApiKey);
      setApiUrl(selected_dojo_setting.ApiUrl);
      setActive(selected_dojo_setting.Active);
      if (selected_dojo_setting.WorkstationId) {
        if (WS_Ids.includes(parseInt(selected_dojo_setting.WorkstationId))) {
          let index = WS_Ids.indexOf(
            parseInt(selected_dojo_setting.WorkstationId)
          );
          work_station = {
            label: work_station_list_for_dojo[index].label,
            value: work_station_list_for_dojo[index].value,
          };

          setSelectedWorkStation(work_station);
        }
      }
    } else {
      setTitle("");
      setTerminalId("");
      setSoftWareHouse("");
      setcurrency("");
      setInstallerId("");
      setApiKey("");
      setApiUrl("");
      setActive("");
    }
  }, [selected_dojo_setting]);

  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);

  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);
  useEffect(() => {
    let tids = [];
    if (get_payment_dojo_setting_list) {
      if (get_payment_dojo_setting_list.length > 0) {
        for (let i = 0; i < get_payment_dojo_setting_list.length; i++) {
          tids.push(get_payment_dojo_setting_list[i].TId);
        }
        setTerminalIds(tids);
      }
    }
  }, [get_payment_dojo_setting_list]);
  return (
    <div
      className="countaining-div "
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
        overflowY: "auto",
      }}
    >
      {" "}
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <ListHeader
          title={selected_dojo_setting ? lang.edit_dojo : lang.create_dojo}
          show_back={true}
          clickedItem={() => setClickedItem(1)}
        />
      </div>
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {" "}
          <div className="ASOP-div1">
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              // className="general_setting_carrier_bag"
              component="form"
              // sx={{
              //   "& > :not(style)": { height: "9ch" },
              // }}
              // noValidate

              autoComplete="on"
            >
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                // className="text"
                style={{ width: "32.5%" }}
                key={1}
                label={`* ${lang.title}`}
                value={title}
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={2}
                label={`* ${lang.t_id}`}
                value={terminalId}
                onChange={(e) => setTerminalId(e.target.value)}
                style={{ width: "32.5%" }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                className="text"
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0, zIndex: "0" },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                style={{ width: "32.5%" }}
                key={4}
                label={`${"*"} ${lang.currency}`}
                value={currency}
                defaultValue={currency}
                onChange={(e) => setcurrency(e.target.value)}
                autoFocus={focusedInputKey == 4 ? true : false}
                onFocus={() => setFocusedInputKey(4)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box>
          </div>
          <div className="ASOP-div1">
            <div className="text">
              <h6 className="h6">* {lang.api_key}</h6>
              <textarea
                className=" dojo-edit-textarea"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </div>
            <div className="text">
              <h6 className="h6">*{lang.api_url}</h6>
              <textarea
                className=" dojo-edit-textarea"
                value={apiUrl}
                onChange={(e) => setApiUrl(e.target.value)}
              />
            </div>

            <div className="text">
              <h6 className="h6">* {lang.works_station_list}</h6>
              <Select
                options={work_station_list_for_dojo}
                defaultValue={selectedWorkStation}
                isSearchable={false}
                onChange={(list, item) => setSelectedWorkStation(list)}
                value={selectedWorkStation}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>{" "}
          <div style={{ width: "32.5%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={() => setActive(!active)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.active}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={handleCreateAndSavePaymentDojoSetting} />
    </div>
  );
};

export default DojoEdit;
