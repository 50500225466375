import React, { useEffect } from "react";

import { Routes, Route } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import UserSideBar from "../../components/UserSideBar";
import Owners from "./UsersOwners";
import Employees from "./UsersEmployees";
import Drivers from "../dashboard/employees/Drivers";
import ShopGroup from "./ShopGroup";

const Users = () => {
  const navigate = useNavigate();
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <div className="dashborard-index-countainer-div">
      <UserSideBar />

      <Routes>
        <Route path="shopGroups" element={<ShopGroup />} />
        <Route path="" element={<Owners />} />
        <Route path="employees" element={<Employees />} />
        <Route path="drivers" element={<Drivers />} />
      </Routes>
    </div>
  );
};

export default Users;
