import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import toast, { Toaster } from "react-hot-toast";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  promotion_type_list,
  promotion_rate_option_list,
} from "../constants/DropDownList";
import {
  primaryColor,
  main_nav_button_color,
  main_nav_back_color,
} from "../constants/Colors";
import ListHeader from "./ListHeader";
import SaveMenuButton from "./SaveMenuButton";
import {
  createPromotionAction,
  updatePromotionAction,
} from "../redux/settings/Action";
const PromotionsEdit = ({ setClickedItem1 }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { get_promotions_list, selected_promotion, result_successfull } =
    useSelector((state) => state.appSettingsRedusers);
  const [title, setTilte] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);
  const [code, setCode] = useState("");
  const [rate, setRate] = useState(0);
  const [type, setType] = useState({
    value: 1,
    label: "Delivery & Collection ",
  });
  const [rateOption, setRateOption] = useState({
    value: 1,
    label: "Percentage",
  });
  const [usesPerPromotion, setUsesPerPromotion] = useState(0);
  const [usesPerCustomer, setUsesPerCustomer] = useState(0);
  const [minOrder, setMinOrder] = useState("00");
  const [excludedPromotion, setExcludedPromotion] = useState([]);
  const [active, setActive] = useState(false);
  const [excludeMealDeals, setExcludeMealDeals] = useState(false);
  const [showOnWeb, setShowOnWeb] = useState(false);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleCreateAndUpdatePromotion = () => {
    if (selected_promotion) {
      if (
        title == "" ||
        startDate == null ||
        endDate == null ||
        type == null ||
        rateOption == null
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updatePromotionAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_promotion.Id,
            title,
            description ? description : "",
            startDate
              ? startDate.$d
                ? moment(startDate.$d).format("YYYY-MM-DD 05:00:00")
                : moment(startDate).format("YYYY-MM-DD 05:00:00")
              : "",
            endDate
              ? endDate.$d
                ? moment(endDate.$d).format("YYYY-MM-DD 04:59:59")
                : moment(endDate).format("YYYY-MM-DD 04:59:59")
              : "",
            monday ? 1 : 0,
            tuesday ? 1 : 0,
            wednesday ? 1 : 0,
            thursday ? 1 : 0,
            friday ? 1 : 0,
            saturday ? 1 : 0,
            sunday ? 1 : 0,
            code ? code : "",
            type.value,
            rate,
            rateOption.value,
            minOrder ? minOrder : 0,
            active ? 1 : 0,
            excludeMealDeals ? 1 : 0,
            usesPerPromotion ? usesPerPromotion : 0,
            usesPerCustomer ? usesPerCustomer : 0,
            "",
            showOnWeb ? 1 : 0
          )
        );
      }
    } else {
      if (
        title == "" ||
        startDate == null ||
        endDate == null ||
        type == null ||
        rateOption == null
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          createPromotionAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            title,
            description ? description : "",
            startDate
              ? startDate.$d
                ? moment(startDate.$d).format("YYYY-MM-DD hh:mm")
                : moment(startDate).format("YYYY-MM-DD hh:mm")
              : "",
            endDate
              ? endDate.$d
                ? moment(endDate.$d).format("YYYY-MM-DD hh:mm")
                : moment(endDate).format("YYYY-MM-DD hh:mm")
              : "",
            monday ? 1 : 0,
            tuesday ? 1 : 0,
            wednesday ? 1 : 0,
            thursday ? 1 : 0,
            friday ? 1 : 0,
            saturday ? 1 : 0,
            sunday ? 1 : 0,
            code ? code : "",
            type.value,
            rate,
            rateOption.value,
            minOrder ? minOrder : "",
            active ? 1 : 0,
            excludeMealDeals ? 1 : 0,
            usesPerPromotion ? usesPerPromotion : 0,
            usesPerCustomer ? usesPerCustomer : 0,
            "",
            showOnWeb ? 1 : 0
          )
        );
      }
    }
  };
  useEffect(() => {
    if (selected_promotion) {
      setTilte(selected_promotion.Title);
      setDescription(selected_promotion.Description);
      setMonday(selected_promotion.DayOfWeek0);
      setTuesday(selected_promotion.DayOfWeek1);
      setWednesday(selected_promotion.DayOfWeek2);
      setThursday(selected_promotion.DayOfWeek3);
      setFriday(selected_promotion.DayOfWeek4);
      setSaturday(selected_promotion.DayOfWeek5);
      setSunday(selected_promotion.DayOfWeek6);
      setStartDate(selected_promotion.StartDate);
      setEndDate(selected_promotion.EndDate);
      setMinOrder(selected_promotion.MinOrder);
      setExcludeMealDeals(selected_promotion.ExcludeMealDeals);
      setExcludedPromotion(selected_promotion.ExcludedPromotions);
      setRate(selected_promotion.Rate);
      setRateOption(selected_promotion.RateOption);
      setType(selected_promotion.SaleMethod);
      setShowOnWeb(selected_promotion.ShowInSite);
      setActive(selected_promotion.Status);
      setUsesPerPromotion(selected_promotion.UsesPerPromotion);
      setUsesPerCustomer(selected_promotion.UsesPerCustomer);
      setCode(selected_promotion.Code);
    }
  }, [selected_promotion]);

  useEffect(() => {
    if (result_successfull) {
      setClickedItem1(1);
    }
  }, [result_successfull]);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return width1 <= 560 ? (
    <div className="countaining-div">
      {" "}
      <div>
        <ListHeader
          title={selected_promotion ? "Edit promotion" : "Create promotion"}
          show_back={true}
          clickedItem={() => setClickedItem1(1)}
        />
      </div>
      <div
        className="menu_list_header_container"
        style={{
          height: "100%",
          paddingBottom: "30px",
        }}
      >
        <div className="margin_menu_normal_horizontal">
          {" "}
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">* {lang.title}</h6>
            <input
              className="ASOP-input-text input-padding"
              value={title}
              onChange={(e) => setTilte(e.target.value)}
            />
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">{lang.description}</h6>
            <input
              className="ASOP-input-text input-padding"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={2}>
                <DesktopDatePicker
                  label={`* ${lang.from}`}
                  inputFormat="YYYY-MM-DD "
                  value={startDate}
                  onChange={(i) => setStartDate(i)}
                  renderInput={(params) => <TextField {...params} />}
                  InputProps={{
                    sx: {
                      height: "45px",
                      top: 3,
                    },
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={2}>
                <DesktopDatePicker
                  label={`* ${lang.to}`}
                  inputFormat="YYYY-MM-DD"
                  value={endDate}
                  onChange={(i) => setEndDate(i)}
                  renderInput={(params) => <TextField {...params} />}
                  InputProps={{
                    sx: {
                      height: "45px",
                      top: 3,
                    },
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">{lang.code}</h6>
            <input
              className="ASOP-input-text input-padding"
              value={code}
              maxLength={10}
              onChange={(e) =>
                setCode(
                  e.target.value.replace(
                    /[^\d\.,q,w,e,r,t,y,u,i,o,p,a,s,d,f,g,h,j,k,l,z,x,c,v,b,n,m]+/g,
                    ""
                  )
                )
              }
            />
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">* {lang.rate} </h6>
            <input
              className="ASOP-input-text input-padding"
              value={rate}
              onChange={(e) =>
                setRate(e.target.value.replace(/[^\d\.,%]+/g, ""))
              }
            />
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6-text h6">* {lang.type}</h6>
            <Select
              options={promotion_type_list}
              defaultValue={type}
              isSearchable={false}
              onChange={(list, item) => {
                setType(list);
              }}
              value={type}
              styles={customStyles}
              isClearable={false}
            />
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6-text h6">* {lang.rate_option}</h6>
            <Select
              options={promotion_rate_option_list}
              defaultValue={rateOption}
              isSearchable={false}
              onChange={(list, item) => {
                setRateOption(list);
              }}
              value={rateOption}
              styles={customStyles}
              isClearable={false}
            />
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6-text h6">
              {lang.uses_per_promotion}
              <span style={{ color: "grey", fontSize: "15px" }}>
                (0 means infinity)
              </span>
            </h6>
            <input
              className="ASOP-input-text input-padding"
              value={usesPerPromotion}
              onChange={(e) =>
                setUsesPerPromotion(e.target.value.replace(/\D/g, ""))
              }
            />
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">{lang.min_order}</h6>
            <input
              className="ASOP-input-text input-padding"
              value={minOrder}
              onChange={(e) =>
                setMinOrder(e.target.value.replace(/[^\d\.,£]+/g, ""))
              }
            />
          </div>{" "}
          <div className="ASOP-div1">
            {" "}
            <div>
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeMealDeals}
                      onChange={() => setExcludeMealDeals(!excludeMealDeals)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.exclude_meal_deals}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={() => setActive(!active)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.status}
                />
              </FormGroup>
            </div>
          </div>
          <div>
            {" "}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showOnWeb}
                    onChange={() => setShowOnWeb(!showOnWeb)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.show_in_web}
              />
            </FormGroup>
          </div>
          <hr />
          <div className="ASOP-div1">
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={monday}
                      onChange={() => setMonday(!monday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.monday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tuesday}
                      onChange={() => setTuesday(!tuesday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.tuesday}
                />
              </FormGroup>
            </div>
          </div>
          <div className="ASOP-div1">
            {" "}
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wednesday}
                      onChange={() => setWednesday(!wednesday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.wednesday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={thursday}
                      onChange={() => setThursday(!thursday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.thursday}
                />
              </FormGroup>
            </div>
          </div>
          <div className="ASOP-div1">
            {" "}
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={friday}
                      onChange={() => setFriday(!friday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.friday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saturday}
                      onChange={() => setSaturday(!saturday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.saturday}
                />
              </FormGroup>
            </div>
          </div>
          <div className="ASOP-div1">
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sunday}
                      onChange={() => setSunday(!sunday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.sunday}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <div
        className="mobile-container-save-button"
        onClick={() => handleCreateAndUpdatePromotion()}
      >
        <button type="">{lang.save}</button>
      </div>{" "}
    </div>
  ) : (
    <div className="countaining-div">
      <div
        style={{
          marginTop: "10px",
        }}
      >
        <ListHeader
          title={selected_promotion ? "Edit promotion" : "Create promotion"}
          show_back={true}
          clickedItem={() => setClickedItem1(1)}
        />
      </div>
      <div
        className="menu_list_header_container_tabel_list"
        style={{
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">* {lang.title}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={title}
                onChange={(e) => setTilte(e.target.value)}
              />
            </div>

            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">{lang.description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <Stack spacing={2}>
                  <DesktopDatePicker
                    label={`* ${lang.from}`}
                    inputFormat="YYYY-MM-DD "
                    value={startDate}
                    onChange={(i) => setStartDate(i)}
                    renderInput={(params) => <TextField {...params} />}
                    InputProps={{
                      sx: {
                        height: "45px",
                        top: 3,
                      },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </div>

            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <Stack spacing={2}>
                  <DesktopDatePicker
                    label={`* ${lang.to}`}
                    inputFormat="YYYY-MM-DD"
                    value={endDate}
                    onChange={(i) => setEndDate(i)}
                    renderInput={(params) => <TextField {...params} />}
                    InputProps={{
                      sx: {
                        height: "45px",
                        top: 3,
                      },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </div>
          </div>
          <div className="ASOP-div1">
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={monday}
                      onChange={() => setMonday(!monday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.monday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tuesday}
                      onChange={() => setTuesday(!tuesday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.tuesday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wednesday}
                      onChange={() => setWednesday(!wednesday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.wednesday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={thursday}
                      onChange={() => setThursday(!thursday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.thursday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={friday}
                      onChange={() => setFriday(!friday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.friday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saturday}
                      onChange={() => setSaturday(!saturday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.saturday}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sunday}
                      onChange={() => setSunday(!sunday)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.sunday}
                />
              </FormGroup>
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">{lang.code}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={code}
                maxLength={10}
                onChange={(e) =>
                  setCode(
                    e.target.value.replace(
                      /[^\d\.,q,w,e,r,t,y,u,i,o,p,a,s,d,f,g,h,j,k,l,z,x,c,v,b,n,m]+/g,
                      ""
                    )
                  )
                }
              />
            </div>
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">* {lang.rate} </h6>
              <input
                className="ASOP-input-text input-padding"
                value={rate}
                onChange={(e) =>
                  setRate(e.target.value.replace(/[^\d\.,%]+/g, ""))
                }
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6-text h6">* {lang.type}</h6>
              <Select
                options={promotion_type_list}
                defaultValue={type}
                isSearchable={false}
                onChange={(list, item) => {
                  setType(list);
                }}
                value={type}
                styles={customStyles}
                isClearable={false}
              />
            </div>

            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6-text h6">* {lang.rate_option}</h6>
              <Select
                options={promotion_rate_option_list}
                defaultValue={rateOption}
                isSearchable={false}
                onChange={(list, item) => {
                  setRateOption(list);
                }}
                value={rateOption}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6-text h6">
                {lang.uses_per_promotion}
                <span style={{ color: "grey", fontSize: "15px" }}>
                  (0 means infinity)
                </span>
              </h6>
              <input
                className="ASOP-input-text input-padding"
                value={usesPerPromotion}
                onChange={(e) =>
                  setUsesPerPromotion(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>

            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6-text h6">
                {lang.uses_per_customer}
                <span style={{ color: "grey", fontSize: "15px" }}>
                  (0 means infinity)
                </span>
              </h6>
              <input
                className="ASOP-input-text input-padding"
                value={usesPerCustomer}
                onChange={(e) =>
                  setUsesPerCustomer(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">{lang.min_order}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={minOrder}
                onChange={(e) =>
                  setMinOrder(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>{" "}
            {/* <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6-text h6">{lang.excluded_promotion}</h6>
              <Select
                options={promotion_type_list}
                defaultValue={excludedPromotion}
                isSearchable={false}
                onChange={(list, item) => {
                  setExcludedPromotion(list);
                }}
                value={excludedPromotion}
                styles={customStyles}
                isClearable={false}
                isMulti={true}
              />
            </div> */}
          </div>

          <div className="ASOP-div1">
            {" "}
            <div className="ASOP-div1-input-text">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={() => setActive(!active)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.status}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeMealDeals}
                      onChange={() => setExcludeMealDeals(!excludeMealDeals)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.exclude_meal_deals}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showOnWeb}
                      onChange={() => setShowOnWeb(!showOnWeb)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_in_web}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <SaveMenuButton clickButton={handleCreateAndUpdatePromotion} />
    </div>
  );
};

export default PromotionsEdit;
