import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import UserSideBar from "../../components/UserSideBar";
import OrderStatisSideBar from "../../components/OederStatusSideBar";
import Failed from "./Component/Failed";
import Pending from "./Component/Pending.";
import Loader from "../../components/Loader";
import InComplete from "./Component/InComplete";
import { setDefaultAction } from "../../redux/menu/Actions";
import NotPrinted from "./Component/NotPrinted";
import Rejected from "./Component/Rejected";
const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { loading_orders } = useSelector((state) => state.ordersReduser);
  const { result_successfull, result_failed, result_message } = useSelector(
    (state) => state.ordersReduser
  );
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  return (
    <div className="dashborard-index-countainer-div">
      <OrderStatisSideBar />

      <Routes>
        <Route path="" element={<NotPrinted />} />
        <Route path="pending" element={<Pending />} />
        <Route path="field" element={<Failed />} />
        <Route path="incomplete" element={<InComplete />} />
        <Route path="rejected" element={<Rejected />} />
      </Routes>
      {loading_orders ? <Loader /> : null}
      <Toaster />
    </div>
  );
};

export default Users;
