import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const devicesServices = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}getworkstationlistall/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};

export const licenceWorkStationServices = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}getLicenceWorkstationList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};

export const createLicenceServices = async (
  login_key,
  shop_id,
  workStation_id,
  device_id,
  licence_course,
  user_id
) => {
  return await axios.post(`${baseUrl}createLicence/`, {
    LoginKey: login_key,
    ShopId: shop_id,
    WorkstationId: workStation_id,
    DeviceId: device_id,
    LicenceCourse: licence_course,
    UserId: user_id,
  });
};

export const getLicenceListByWorkStaionIdServices = async (
  login_key,
  user_id,
  workStation_id
) => {
  return await axios.put(`${baseUrl}getLicenceListByworkstationid/`, {
    LoginKey: login_key,
    UserId: user_id,
    WorkstationId: workStation_id,
  });
};

export const getGeneralSecondScreenListService = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}secondScreenImagePublicList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};
export const createGeneralSecondScreenService = async (formData) => {
  return await axios.post(`${baseUrl}createSecondScreenImagePublic/`, formData);
};
export const updateGeneralSecondScreenService = async (
  login_key,
  user_id,
  id,
  sort_id,
  active,
  duration
) => {
  return await axios.put(`${baseUrl}updateSecondScreenImagePublic/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    SortId: sort_id,
    Active: active,
    Duration: duration,
  });
};
export const deleteGeneralSecondScreenService = async (
  login_key,
  user_id,
  id,
  last_image_url
) => {
  return await axios.put(`${baseUrl}deleteSecondScreenImagePublic/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    LastImageUrlName: last_image_url,
  });
};
export const generalSecondScreenShopGroupListService = async (
  login_key,
  user_id
) => {
  return await axios.put(`${baseUrl}secondScreenShopGroupList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};
export const updateGeneralSecondScreenShopGroupService = async () => {
  return await axios.put(`${baseUrl}`);
};
