import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  main_nav_button_color,
  main_nav_back_color,
  primaryColor,
  white,
} from "../../../constants/Colors";
import Carousel, { consts } from "react-elastic-carousel";
import { selectedVatAction } from "../../../redux/GeneralSetting/Actions";
import General from "./GeneralComponent/index";
import PostCode from "./GeneralComponent/PostCode";
import Vat from "./GeneralComponent/Vat";
import EditHalf from "./GeneralComponent/EditHalf";
import CarrierBag from "./GeneralComponent/CarrierBag";
import CodeDetailPopup from "../CodingSetting/Component/CodeDetailPopup";
import { selectedTabelsAction } from "../../../redux/GeneralSetting/Actions";
import Delivery from "./GeneralComponent/Delivery";
import DeliveryTabs from "./GeneralComponent/DeliveryTabs";
import ShortCuts from "./GeneralComponent/ShortCuts";
import About from "./GeneralComponent/About";
const GeneralTab = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [clickedItem, setClickedItem] = useState(1);
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i
          class="fa fa-chevron-left"
          aria-hidden="true"
          style={{ fontSize: "20px", marginTop: "5px" }}
        ></i>
      ) : (
        <i
          class="fa fa-chevron-right"
          aria-hidden="true"
          style={{
            fontSize: "20px",
            marginTop: "5px",
            marginLeft: "-16px",
          }}
        ></i>
      );
    return (
      <button onClick={onClick} disabled={isEdge} style={{ border: "none" }}>
        {pointer}
      </button>
    );
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 330, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 450, itemsToShow: 3, itemsToScroll: 2, pagination: false },
    { width: 580, itemsToShow: 4, itemsToScroll: 2, pagination: false },
    { width: 800, itemsToShow: 5, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 6, pagination: false },
    { width: 1150, itemsToShow: 7, itemsToScroll: 2, pagination: false },
  ];
  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <div className="menu_list_header_container_tabel_list">
        <div className="main_nav_container">
          <div className="settings_tab_buttons_container web-setting-container-Carousel">
            <Carousel
              // className="styling-example"
              breakPoints={breakPoints}
              itemsToShow={6}
              className="carousel"
              renderArrow={myArrow}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <div direction="row">
                    {pages.map((page) => {
                      // const isActivePage = activePage === page;
                      return (
                        <div
                          style={{ display: "none" }}
                          key={page}
                          onClick={() => onClick(page)}
                          // active={isActivePage}
                        />
                      );
                    })}
                  </div>
                );
              }}
            >
              <button
                key={1}
                onClick={() => {
                  setClickedItem(1);
                  dispatch(selectedTabelsAction(null));
                }}
                style={{
                  backgroundColor: clickedItem == 1 ? primaryColor : white,
                  color: clickedItem == 1 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 1 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.sit_in}
              </button>
              <button
                key={3}
                onClick={() => {
                  setClickedItem(3);
                  dispatch(selectedVatAction(null));
                }}
                style={{
                  backgroundColor: clickedItem == 3 ? primaryColor : white,
                  color: clickedItem == 3 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 3 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                VAT
              </button>
              <button
                key={4}
                onClick={() => setClickedItem(4)}
                style={{
                  backgroundColor: clickedItem == 4 ? primaryColor : white,
                  color: clickedItem == 4 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 4 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                Half
              </button>
              <button
                key={5}
                onClick={() => setClickedItem(5)}
                style={{
                  backgroundColor: clickedItem == 5 ? primaryColor : white,
                  color: clickedItem == 5 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 5 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                Carrier bag
              </button>
              {/* <button
                key={6}
                onClick={() => setClickedItem(6)}
                style={{
                  backgroundColor: clickedItem == 6 ? primaryColor : white,
                  color: clickedItem == 6 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 6 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                Post Code
              </button> */}
              <button
                key={7}
                onClick={() => setClickedItem(7)}
                style={{
                  backgroundColor: clickedItem == 7 ? primaryColor : white,
                  color: clickedItem == 7 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 7 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.delivery}
              </button>
              <button
                key={8}
                onClick={() => setClickedItem(8)}
                style={{
                  backgroundColor: clickedItem == 8 ? primaryColor : white,
                  color: clickedItem == 8 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 8 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.short_cut}
              </button>
              <button
                key={9}
                onClick={() => setClickedItem(9)}
                style={{
                  backgroundColor: clickedItem == 9 ? primaryColor : white,
                  color: clickedItem == 9 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 9 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.about}
              </button>
            </Carousel>
          </div>
        </div>

        {clickedItem == 1 ? (
          <General />
        ) : clickedItem == 3 ? (
          <Vat />
        ) : clickedItem == 4 ? (
          <EditHalf />
        ) : clickedItem == 5 ? (
          <CarrierBag />
        ) : clickedItem == 6 ? (
          <PostCode />
        ) : clickedItem == 7 ? (
          <DeliveryTabs />
        ) : clickedItem == 8 ? (
          <ShortCuts />
        ) : clickedItem == 9 ? (
          <About />
        ) : null}
      </div>
    </div>
  );
};

export default GeneralTab;
