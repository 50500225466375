import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import { useSelector, useDispatch } from "react-redux";
import { Stripe_Mode, Account_Type } from "./List";
import Loader from "../../../../components/Loader";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  getStripeListAction,
  updateStripeAction,
} from "../../../../redux/settings/Action";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
const Stripe = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    result_successfull,
    result_message,
    result_failed,
    web_setting_stripe_list,
    loading_appSettings,
  } = useSelector((state) => state.appSettingsRedusers);
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [secretKey, setSecretKey] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [account1, setAccount1] = useState("");
  const [account2, setAccount2] = useState("");
  const [webhook, setWebhook] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [stripeMode, setStripeMode] = useState({
    value: 1,
    label: "Live Mode",
  });
  const [ofOrder, setOfOrder] = useState(7);
  const [statusPence, setStatusPence] = useState(70);

  const [monday1, setMonday1] = useState(true);
  const [tuesday1, setTuesday1] = useState(true);
  const [wednesday1, setWednsday1] = useState(true);
  const [thursday1, setThursday1] = useState(true);
  const [friday1, setFriday1] = useState(true);
  const [saturday1, setSaturday1] = useState(true);
  const [sunday1, setSunday1] = useState(true);
  const [monday2, setMonday2] = useState(false);
  const [tuesday2, setTuesday2] = useState(false);
  const [wednesday2, setWednsday2] = useState(false);
  const [thursday2, setThursday2] = useState(false);
  const [friday2, setFriday2] = useState(false);
  const [saturday2, setSaturday2] = useState(false);
  const [sunday2, setSunday2] = useState(false);
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "black",
      fontSize: "15px",

      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });
  const customStyles = {
    control: (base, state) => ({
      width: "100%",
      zIndex: "55555",
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  useEffect(() => {
    let account_type_value = [];
    let stripe_mode_value = [];
    let account_type = {};
    let stripe_mode = [];

    if (Account_Type) {
      if (Account_Type.length > 0) {
        for (let i = 0; i < Account_Type.length; i++) {
          account_type_value.push(Account_Type[i].value);
        }
      }
    }
    if (Stripe_Mode) {
      if (Stripe_Mode.length > 0) {
        for (let i = 0; i < Stripe_Mode.length; i++) {
          stripe_mode_value.push(Stripe_Mode[i].value);
        }
      }
    }
    if (web_setting_stripe_list) {
      if (web_setting_stripe_list.length > 0) {
        if (web_setting_stripe_list[0].PublishableKey) {
          setPublicKey(web_setting_stripe_list[0].PublishableKey);
        } else {
          setPublicKey("");
        }
        if (web_setting_stripe_list[0].SecretKey) {
          setSecretKey(web_setting_stripe_list[0].SecretKey);
        } else {
          setSecretKey("");
        }
        if (web_setting_stripe_list[0].Account) {
          setAccount1(web_setting_stripe_list[0].Account);
        } else {
          setAccount1("");
        }
        if (web_setting_stripe_list[0].Webhook) {
          setWebhook(web_setting_stripe_list[0].Webhook);
        } else {
          setWebhook("");
        }

        if (web_setting_stripe_list[0].AccountType) {
          if (
            account_type_value.includes(
              parseInt(web_setting_stripe_list[0].AccountType)
            )
          ) {
            let index = account_type_value.indexOf(
              parseInt(web_setting_stripe_list[0].AccountType)
            );
            account_type = {
              label: Account_Type[index].label,
              value: Account_Type[index].value,
            };
            setAccountType(account_type);
          }
        } else {
          setAccountType(null);
        }
        if (
          web_setting_stripe_list[0].StripeMode ||
          web_setting_stripe_list[0].StripeMode == 0
        ) {
          if (
            stripe_mode_value.includes(
              parseInt(web_setting_stripe_list[0].StripeMode)
            )
          ) {
            let index = stripe_mode_value.indexOf(
              parseInt(web_setting_stripe_list[0].StripeMode)
            );
            stripe_mode = {
              label: Stripe_Mode[index].label,
              value: Stripe_Mode[index].value,
            };
            setStripeMode(stripe_mode);
          }
        }
        if (web_setting_stripe_list[0].Account_2) {
          setAccount2(web_setting_stripe_list[0].Account_2);
        } else {
          setAccount2("");
        }
        if (web_setting_stripe_list[0].ActiveDayOfAccount) {
          if (web_setting_stripe_list[0].ActiveDayOfAccount.includes(0)) {
            setMonday1(true);
          } else {
            setMonday1(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount.includes(1)) {
            setTuesday1(true);
          } else {
            setTuesday1(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount.includes(2)) {
            setWednsday1(true);
          } else {
            setWednsday1(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount.includes(3)) {
            setThursday1(true);
          } else {
            setThursday1(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount.includes(4)) {
            setFriday1(true);
          } else {
            setFriday1(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount.includes(5)) {
            setSaturday1(true);
          } else {
            setSaturday1(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount.includes(6)) {
            setSunday1(true);
          } else {
            setSunday1(false);
          }
        } else {
          setMonday1(false);
          setTuesday1(false);
          setWednsday1(false);
          setFriday1(false);
          setThursday1(false);
          setSaturday1(false);
          setSunday1(false);
        }
        if (web_setting_stripe_list[0].ActiveDayOfAccount_2) {
          if (web_setting_stripe_list[0].ActiveDayOfAccount_2.includes(0)) {
            setMonday2(true);
          } else {
            setMonday2(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount_2.includes(1)) {
            setTuesday2(true);
          } else {
            setTuesday2(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount_2.includes(2)) {
            setWednsday2(true);
          } else {
            setWednsday2(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount_2.includes(3)) {
            setThursday2(true);
          } else {
            setThursday2(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount_2.includes(4)) {
            setFriday2(true);
          } else {
            setFriday2(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount_2.includes(5)) {
            setSaturday2(true);
          } else {
            setSaturday2(false);
          }
          if (web_setting_stripe_list[0].ActiveDayOfAccount_2.includes(6)) {
            setSunday2(true);
          } else {
            setSunday2(false);
          }
        } else {
          setMonday2(false);
          setTuesday2(false);
          setWednsday2(false);
          setFriday2(false);
          setThursday2(false);
          setSaturday2(false);
          setSunday2(false);
        }
        setStatusPence(web_setting_stripe_list[0].ApplicationFeeStaticPence);
        setOfOrder(web_setting_stripe_list[0].ApplicationFeePercent);
      } else {
        setPublicKey("");
        setSecretKey("");
        setAccount1("");
        setAccount2("");
        setWebhook("");
        setAccountType(null);
        setMonday1(false);
        setTuesday1(false);
        setWednsday1(false);
        setFriday1(false);
        setThursday1(false);
        setSaturday1(false);
        setSunday2(false);
        setMonday2(false);
        setTuesday2(false);
        setWednsday2(false);
        setFriday2(false);
        setThursday2(false);
        setSaturday2(false);
        setSunday2(false);
      }
    } else {
      setPublicKey("");
      setSecretKey("");
      setAccount1("");
      setAccount2("");
      setWebhook("");
      setAccountType(null);
      setMonday1(false);
      setTuesday1(false);
      setWednsday1(false);
      setFriday1(false);
      setThursday1(false);
      setSaturday1(false);
      setSunday2(false);
      setMonday2(false);
      setTuesday2(false);
      setWednsday2(false);
      setFriday2(false);
      setThursday2(false);
      setSaturday2(false);
      setSunday2(false);
    }
  }, [web_setting_stripe_list]);
  const handleSaveStripe = () => {
    let active_day_of_acount1 = [];
    let active_day_of_acount2 = [];
    if (monday1) {
      active_day_of_acount1.push(0);
    } else {
      active_day_of_acount1.push();
    }
    if (tuesday1) {
      active_day_of_acount1.push(1);
    } else {
      active_day_of_acount1.push();
    }
    if (wednesday1) {
      active_day_of_acount1.push(2);
    } else {
      active_day_of_acount1.push();
    }
    if (thursday1) {
      active_day_of_acount1.push(3);
    } else {
      active_day_of_acount1.push();
    }
    if (friday1) {
      active_day_of_acount1.push(4);
    } else {
      active_day_of_acount1.push();
    }
    if (saturday1) {
      active_day_of_acount1.push(5);
    } else {
      active_day_of_acount1.push();
    }
    if (sunday1) {
      active_day_of_acount1.push(6);
    } else {
      active_day_of_acount1.push();
    }
    if (monday2) {
      active_day_of_acount2.push(0);
    } else {
      active_day_of_acount2.push();
    }
    if (tuesday2) {
      active_day_of_acount2.push(1);
    } else {
      active_day_of_acount2.push();
    }
    if (wednesday2) {
      active_day_of_acount2.push(2);
    } else {
      active_day_of_acount2.push();
    }
    if (thursday2) {
      active_day_of_acount2.push(3);
    } else {
      active_day_of_acount2.push();
    }
    if (friday2) {
      active_day_of_acount2.push(4);
    } else {
      active_day_of_acount2.push();
    }
    if (saturday2) {
      active_day_of_acount2.push(5);
    } else {
      active_day_of_acount2.push();
    }
    if (sunday2) {
      active_day_of_acount2.push(6);
    } else {
      active_day_of_acount2.push();
    }

    if (
      secretKey == "" ||
      publicKey == "" ||
      accountType == null ||
      stripeMode == null ||
      ofOrder == "" ||
      statusPence == "" ||
      account1 == ""
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateStripeAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          web_setting_stripe_list[0] ? web_setting_stripe_list[0].Id : 0,
          secretKey,
          publicKey,
          account1,
          account2,
          active_day_of_acount1
            ? active_day_of_acount1.length > 0
              ? active_day_of_acount1.toString()
              : ""
            : "",
          active_day_of_acount2
            ? active_day_of_acount2.length > 0
              ? active_day_of_acount2.toString()
              : ""
            : "",
          webhook,
          parseFloat(ofOrder),
          statusPence ? parseInt(statusPence) : 70,
          accountType.value,
          stripeMode.value
        )
      );
    }
  };
  useImperativeHandle(forwardedRef, () => ({
    saveStripe: () => {
      handleSaveStripe();
    },
  }));
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());

      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveStripe();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [secretKey, publicKey]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getStripeListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div
            className="AGS-div111"
            style={{ color: "grey", lineHeight: "35px" }}
          >
            <p>
              These options are only available to express and custom account
              types as our platform becomes responsible for the transaction
              fees,Stripe charges 2.9% + 2p per transaction. These fees are
              subtracted from the order total and transferred to the platform
              account.
            </p>
            {/* <p>
              These fees are subtracted from theorder total and transferred to
              the plantform account.
            </p> */}
          </div>
          <div className="ASOP-div1">
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              // className="general_setting_carrier_bag"
              component="form"
              // sx={{
              //   "& > :not(style)": { height: "9ch" },
              // }}
              // noValidate

              autoComplete="on"
            >
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={1}
                label={`* ${lang.of_order}`}
                value={ofOrder}
                defaultValue={ofOrder}
                onChange={(e) =>
                  setOfOrder(e.target.value.replace(/[^\d\.,£,%]+/g, ""))
                }
                style={{
                  width: "45%",
                }}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={2}
                label={`* ${lang.static_pence}`}
                value={statusPence}
                onChange={(e) =>
                  setStatusPence(e.target.value.replace(/\D/g, ""))
                }
                style={{
                  width: "45%",
                }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box>
          </div>
          <div className="ASOP-div1">
            <div style={{ width: "45%" }}>
              <h6 className="h6">* {lang.account_type}</h6>
              <Select
                options={Account_Type}
                //   defaultValue={kioskShowHideValues}
                isSearchable={false}
                onChange={(list, item) => setAccountType(list)}
                value={accountType}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div style={{ width: "45%" }}>
              <h6 className="h6">* {lang.stripe_mode}</h6>
              <Select
                options={Stripe_Mode}
                //   defaultValue={kioskShowHideValues}
                isSearchable={false}
                onChange={(list, item) => setStripeMode(list)}
                value={stripeMode}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              // className="general_setting_carrier_bag"
              component="form"
              // sx={{
              //   "& > :not(style)": { height: "9ch" },
              // }}
              // noValidate

              autoComplete="on"
            >
              <CssTextField
                type={"password"}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0, zIndex: "0" },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={3}
                label={`${"*"} ${lang.secret_key}`}
                value={secretKey}
                defaultValue={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
                style={{
                  width: "45%",
                }}
                autoFocus={focusedInputKey == 3 ? true : false}
                onFocus={() => setFocusedInputKey(3)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                type={"password"}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0, zIndex: "0" },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={4}
                label={`${"*"} ${lang.public_key}`}
                value={publicKey}
                onChange={(e) => setPublicKey(e.target.value)}
                style={{
                  width: "45%",
                }}
                autoFocus={focusedInputKey == 4 ? true : false}
                onFocus={() => setFocusedInputKey(4)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box>
          </div>

          <div className="ASOP-div1">
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              // className="general_setting_carrier_bag"
              component="form"
              // sx={{
              //   "& > :not(style)": { height: "9ch" },
              // }}
              // noValidate

              autoComplete="on"
            >
              <div style={{ width: "45%" }}>
                <CssTextField
                  InputLabelProps={{
                    sx: {
                      fontSize: 16,
                      top: -5,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primaryColor,
                      },
                    },
                  }}
                  key={5}
                  label={`*${lang.account} 1`}
                  value={account1}
                  defaultValue={account1}
                  onChange={(e) => setAccount1(e.target.value)}
                  style={{
                    width: "100%",
                  }}
                  autoFocus={focusedInputKey == 5 ? true : false}
                  onFocus={() => setFocusedInputKey(5)}
                  onBlur={() => setFocusedInputKey(null)}
                />{" "}
                <div className="stripe-container-days">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={monday1}
                          onChange={() => {
                            setMonday1(!monday1);
                            !monday1 ? setMonday2(false) : setMonday2(true);
                          }}
                          sx={{
                            // "&.MuiSvgIcon-root": { marginLeft: "-50px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.mon}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tuesday1}
                          onChange={() => {
                            setTuesday1(!tuesday1);
                            !tuesday1 ? setTuesday2(false) : setTuesday2(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.tues}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={wednesday1}
                          onChange={() => {
                            setWednsday1(!wednesday1);
                            !wednesday1
                              ? setWednsday2(false)
                              : setWednsday2(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.wen}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thursday1}
                          onChange={() => {
                            setThursday1(!thursday1);
                            !thursday1
                              ? setThursday2(false)
                              : setThursday2(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.thurs}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={friday1}
                          onChange={() => {
                            setFriday1(!friday1);
                            !friday1 ? setFriday2(false) : setFriday2(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.fri}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saturday1}
                          onChange={() => {
                            setSaturday1(!saturday1);
                            !saturday1
                              ? setSaturday2(false)
                              : setSaturday2(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.sat}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sunday1}
                          onChange={() => {
                            setSunday1(!sunday1);
                            !sunday1 ? setSunday2(false) : setSunday2(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.sun}
                    />
                  </FormGroup>
                </div>
              </div>
              <div style={{ width: "45%" }}>
                <CssTextField
                  InputLabelProps={{
                    sx: {
                      fontSize: 16,
                      top: -5,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primaryColor,
                      },
                    },
                  }}
                  key={7}
                  label={`${lang.account} 2`}
                  value={account2}
                  defaultValue={account2}
                  onChange={(e) => setAccount2(e.target.value)}
                  style={{
                    width: "100%",
                  }}
                  autoFocus={focusedInputKey == 7 ? true : false}
                  onFocus={() => setFocusedInputKey(7)}
                  onBlur={() => setFocusedInputKey(null)}
                />{" "}
                <div className="stripe-container-days">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={monday2}
                          onChange={() => {
                            !monday2 ? setMonday1(false) : setMonday1(true);
                            setMonday2(!monday2);
                          }}
                          sx={{
                            // "&.MuiSvgIcon-root": { marginLeft: "-50px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.mon}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tuesday2}
                          onChange={() => {
                            setTuesday2(!tuesday2);
                            !tuesday2 ? setTuesday1(false) : setTuesday1(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.tues}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={wednesday2}
                          onChange={() => {
                            setWednsday2(!wednesday2);
                            !wednesday2
                              ? setWednsday1(false)
                              : setWednsday1(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.wen}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={thursday2}
                          onChange={() => {
                            setThursday2(!thursday2);
                            !thursday2
                              ? setThursday1(false)
                              : setThursday1(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.thurs}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={friday2}
                          onChange={() => {
                            setFriday2(!friday2);
                            !friday2 ? setFriday1(false) : setFriday1(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.fri}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saturday2}
                          onChange={() => {
                            setSaturday2(!saturday2);
                            !saturday2
                              ? setSaturday1(false)
                              : setSaturday1(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.sat}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sunday2}
                          onChange={() => {
                            setSunday2(!sunday2);
                            !sunday2 ? setSunday1(false) : setSunday1(true);
                          }}
                          sx={{
                            // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                          size="small"
                        />
                      }
                      label={lang.sun}
                    />
                  </FormGroup>
                </div>{" "}
              </div>
            </Box>
          </div>
          <div className="ASOP-div1">
            {" "}
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              // className="general_setting_carrier_bag"
              component="form"
              // sx={{
              //   "& > :not(style)": { height: "9ch" },
              // }}
              // noValidate

              autoComplete="on"
            >
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={6}
                label={lang.webhook}
                value={webhook}
                onChange={(e) => setWebhook(e.target.value)}
                style={{
                  width: "45%",
                }}
                autoFocus={focusedInputKey == 6 ? true : false}
                onFocus={() => setFocusedInputKey(6)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box>
          </div>
        </div>
      </div>
      {/* {loading_appSettings ? <Loader /> : null} */}
    </div>
  );
});

export default Stripe;
