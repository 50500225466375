import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import { setDefaultAction } from "../../../redux/menu/Actions";
import MenuListItem from "../../../components/MenuListItem";
import { useDispatch, useSelector } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import { marginNormalMenuHorizontal } from "../../../constants/Sizes";
import { main_nav_button_color } from "../../../constants/Colors";
import {
  visibleDriversPopupAction,
  driverShopListAction,
  deleteDriverShopAction,
} from "../../../redux/Employees/Actions";
import { selectedOtemInAllLists } from "../../../redux/menu/Actions";
import toast, { Toaster } from "react-hot-toast";
const Deriver = () => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    driver_shop_lust,
    loading_employees,
    result_successfull,
    result_message,
    result_failed,
  } = useSelector((state) => state.employeesRedusers);
  const { selected_item_in_all_lists } = (state) => state.categoryList;
  const [driverShopList, setDriverShopList] = useState([]);
  const handleDeletedDriverShop = (item) => {
    dispatch(
      deleteDriverShopAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        item.DriverId
      )
    );
  };
  const handleShowDeleteBox = (item) => {
    dispatch(selectedOtemInAllLists(item));
    const copy_list = [...driverShopList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.DriverId == copy_list[i].DriverId) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setDriverShopList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...driverShopList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.DriverId == copy_list[i].DriverId) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setDriverShopList(copy_list);
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        driverShopListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    if (driver_shop_lust) {
      if (driver_shop_lust.length > 0) {
        setDriverShopList(driver_shop_lust);
      } else {
        setDriverShopList([]);
      }
    } else {
      setDriverShopList([]);
    }
  }, [driver_shop_lust]);
  // useEffect(() => {
  //   return () => {
  //     dispatch(selectedOtemInAllLists(null));
  //   };
  // }, []);

  return (
    <div
      className="countaining-div"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: window.innerHeight - 220,
      }}
    >
      <div className="menu_list_header_container">
        {driverShopList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {driverShopList.map((item, index) => (
              <div
              //   key={index}
              >
                <MenuListItem
                  field_number={3}
                  field_1_title={`# ${item.DriverId}`}
                  field_2_title={`${item.FirstName} ${item.LastName}`}
                  field_3_title={item.Mobile}
                  show_field_icon={true}
                  item_title={item.FirstName}
                  item_id={item.DriverId}
                  item_lastName={item.LastName}
                  item_mobile={item.Mobile}
                  btn_number={0}
                  // bt_1_title={lang.edit}
                  // bt_1_click={() => handleClickedItem(item, 2)}
                  show_delete={true}
                  delete_click={() => handleDeletedDriverShop(item)}
                  show_edit_icon={false}
                  without_key={true}
                  number={0}
                  selected={selected_item_in_all_lists}
                  item={item}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              style={{ width: "172px" }}
              onClick={() => dispatch(visibleDriversPopupAction(true))}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_driver}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {driverShopList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={() => dispatch(visibleDriversPopupAction(true))}
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_driver}</p>
          </div>
        ) : null}
      </div>
      {loading_employees ? <Loader /> : null}
    </div>
  );
};

export default Deriver;
