import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  getShopEmployersListFailedAction,
  getShopEmployersListSuccessfullAction,
  createEmployersListFailedAction,
  getShopEmployersListAction,
  createEmployersListSuccessfullAction,
  updateEmployersListSuccessfullAction,
  deleteShopEmployeesFailedAction,
  deleteShopEmployeesSuccessfullAction,
  updateEmployersListFailedAction,
  getDriversListFailedAction,
  getDriversListSuccessfullAction,
  getDriversListAction,
  createDriverFailedAction,
  createDriverSuccessfullAction,
  deleteDriverFailedAction,
  deleteDriverSuccessfullAction,
  updateDriverFailedAction,
  updateDriverSuccessfullAction,
  driverWithUniqueIdFailedAction,
  driverWithUniqueIdSuccessfullAction,
  createDriverShopFailedAction,
  createDriverShopSuccessfullAction,
  driverShopListAction,
  driverShopListFailedAction,
  driverShopListSuccessfullAction,
  deleteDriverShopFailedAction,
  deleteDriverShopSuccessfullAction,
  deviceAccessGroupListAction,
  deviceAccessGroupListFailedAction,
  deviceAccessGroupListSuccessfullAction,
  deviceRoleListAction,
  createDevicesAccessGroupFailedAction,
  createDevicesAccessGroupSuccessfullAction,
  updateDevicesAccessGroupFailedAction,
  updateDevicesAccessGroupSuccessfullAction,
  deleteDevicesAccessGroupFailedAction,
  deleteDevicesAccessGroupSuccessfullAction,
  getCheckInCheckOutListAction,
  getCheckInCheckOutListFailedAction,
  getCheckInCheckOutListSuccessfullAction,
  updateCheckInCheckOutFailedAction,
  updateCheckInCheckOutSuccessfullAction,
  deleteCheckIncheckOutFailedAction,
  deleteCheckIncheckOutSuccessfullAction,
  getEnterExitListByEmployeeIdFailedAction,
  getEnterExitListByEmployeeIdSuccessfullAction,
} from "../../redux/Employees/Actions";
import {
  getShopEmployeesServices,
  updateShopEmployessServices,
  createShopEmployessServices,
  deleteShopEmployeesServices,
  getDriversListServices,
  createDriversServices,
  deleteDriverServices,
  updateDriverServices,
  driverWithUniqueIdServices,
  createDriverShopServices,
  driverShopListServices,
  deleteDriverShopServices,
  deviceAccessGroupListServices,
  createDeviceAccessGroupServices,
  updateDeviceAccessGroupServices,
  deleteDevicesAccessService,
  getCheckInCheckOutListService,
  updateCheckInCheckOutService,
  deleteCheckInCheckOutService,
  getEnterExitListByIdService,
} from "../../services/ShopEmployeesServices";

function* getShopEmployees({ payload }) {
  try {
    const response = yield call(
      getShopEmployeesServices,
      payload.login_key,
      payload.user_id,
      payload.shopId
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.ShopEmployeeslist.length > 0) {
          let my_list = [];
          for (let i = 0; i < response.data.ShopEmployeeslist.length; i++) {
            my_list.push({
              ...response.data.ShopEmployeeslist[i],
              showDeleteBox: false,
            });
          }
          yield put(getShopEmployersListSuccessfullAction(my_list));
        } else {
          yield put(getShopEmployersListFailedAction());
        }
      } else {
        yield put(getShopEmployersListFailedAction());
      }
    } else {
      yield put(getShopEmployersListFailedAction());
    }
  } catch (error) {
    put(getShopEmployersListFailedAction(error.message));
  }
}
function* createShopEmployess({ payload }) {
  try {
    const response = yield call(
      createShopEmployessServices,
      payload.login_key,
      payload.login_key1,
      payload.user_id,
      payload.shopId,
      payload.gender_id,
      payload.employee_name,
      payload.job,
      payload.national_id,
      payload.mobile,
      payload.telephone,
      payload.email,
      payload.address,
      payload.post_code,
      payload.user_name,
      payload.pass,
      payload.dob,
      payload.married,
      payload.in_suranceld,
      payload.date_in,
      payload.date_out,
      payload.active,
      payload.group_id,
      payload.is_admin,
      payload.single_user,
      payload.btn_color,
      payload.btn_font_color,
      payload.url_image
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getShopEmployersListAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
        yield put(
          createEmployersListSuccessfullAction(response.data.ResultMessage)
        );
      } else {
        yield put(createEmployersListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createEmployersListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(createEmployersListFailedAction(error.message));
  }
}
function* updateShopEmployess({ payload }) {
  try {
    const response = yield call(
      updateShopEmployessServices,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shopId,
      payload.gender_id,
      payload.employee_name,
      payload.job,
      payload.national_id,
      payload.mobile,
      payload.telephone,
      payload.email,
      payload.address,
      payload.post_code,
      payload.user_name,
      payload.pass,
      payload.dob,
      payload.married,
      payload.in_suranceld,
      payload.date_in,
      payload.date_out,
      payload.active,
      payload.group_id,
      payload.is_admin,
      payload.single_user,
      payload.btn_color,
      payload.btn_font_color,
      payload.url_image
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getShopEmployersListAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
        yield put(
          updateEmployersListSuccessfullAction(response.data.ResultMessage)
        );
      } else {
        yield put(updateEmployersListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateEmployersListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateEmployersListFailedAction(error.message));
  }
}

function* deleteShopEmployess({ payload }) {
  try {
    const response = yield call(
      deleteShopEmployeesServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.employee_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteShopEmployeesSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getShopEmployersListAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(deleteShopEmployeesFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteShopEmployeesFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteShopEmployeesFailedAction(error.message));
  }
}
function* getDriversList({ payload }) {
  try {
    const response = yield call(
      getDriversListServices,
      payload.login_key,
      payload.user_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.drivers_list.length > 0) {
          let my_list = [];
          for (let i = 0; i < response.data.drivers_list.length; i++) {
            my_list.push({
              ...response.data.drivers_list[i],
              showDeleteBox: false,
            });
          }
          yield put(getDriversListSuccessfullAction(my_list));
        } else {
          yield put(getDriversListFailedAction());
        }
      } else {
        yield put(getDriversListFailedAction());
      }
    } else {
      yield put(getDriversListFailedAction());
    }
  } catch (error) {
    yield put(getDriversListFailedAction(error.message));
  }
}
function* createDrivers({ payload }) {
  try {
    const response = yield call(
      createDriversServices,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.first_name,
      payload.last_name,
      payload.active,
      payload.mobile,
      payload.user_name,
      payload.password,
      payload.image
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(
          createDriverSuccessfullAction(response.data[0].ResultMessage)
        );
        yield put(getDriversListAction(payload.login_key, payload.user_id));
      } else {
        yield put(createDriverFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createDriverFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createDriverFailedAction(error.message));
  }
}
function* deleteDriver({ payload }) {
  try {
    const response = yield call(
      deleteDriverServices,
      payload.login_key,
      payload.user_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(deleteDriverSuccessfullAction(response.data.ResultMessage));
        yield put(getDriversListAction(payload.login_key, payload.user_id));
      } else {
        yield put(deleteDriverFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteDriverFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteDriverFailedAction(error.message));
  }
}
function* updateDriver({ payload }) {
  try {
    const response = yield call(
      updateDriverServices,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.first_name,
      payload.last_name,
      payload.active,
      payload.mobile,
      payload.user_name,
      payload.password,
      payload.image
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateDriverSuccessfullAction(response.data.ResultMessage));
        yield put(getDriversListAction(payload.login_key, payload.user_id));
      } else {
        yield put(updateDriverFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateDriverFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateDriverFailedAction(error.message));
  }
}
function* driverWithUniqueId({ payload }) {
  try {
    const response = yield call(
      driverWithUniqueIdServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.driver_unique_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.driverWith_UniqueId.length > 0) {
          yield put(
            driverWithUniqueIdSuccessfullAction(
              response.data.driverWith_UniqueId
            )
          );
        } else {
          yield put(driverWithUniqueIdFailedAction());
        }
      } else {
        yield put(driverWithUniqueIdFailedAction());
      }
    } else {
      yield put(driverWithUniqueIdFailedAction());
    }
  } catch (error) {
    yield put(driverWithUniqueIdFailedAction(error.message));
  }
}

function* driverShopList({ payload }) {
  try {
    const response = yield call(
      driverShopListServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.shop_driver_list.length > 0) {
          let my_list = [];
          for (let i = 0; i < response.data.shop_driver_list.length; i++) {
            my_list.push({
              ...response.data.shop_driver_list[i],
              showDeleteBox: false,
            });
          }
          yield put(driverShopListSuccessfullAction(my_list));
        } else {
          yield put(driverShopListFailedAction());
        }
      } else {
        yield put(driverShopListFailedAction());
      }
    } else {
      yield put(driverShopListFailedAction());
    }
  } catch (error) {
    yield put(driverShopListFailedAction(error.message));
  }
}
function* cretaeDriverShop({ payload }) {
  try {
    const response = yield call(
      createDriverShopServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.driver_id
    );

    if (response.status == 200) {
      if (response.data[0].ResultCode) {
        yield put(
          createDriverShopSuccessfullAction(response.data[0].ResultMessage)
        );
        yield put(
          driverShopListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(createDriverShopFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createDriverShopFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createDriverShopFailedAction(error.message));
  }
}
function* deleteDriverShop({ payload }) {
  try {
    const response = yield call(
      deleteDriverShopServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.driver_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteDriverShopSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          driverShopListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(deleteDriverShopFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteDriverShopFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteDriverShopFailedAction(error.message));
  }
}
function* deviceAccessGroupList({ payload }) {
  try {
    const response = yield call(
      deviceAccessGroupListServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        let my_list = [];
        if (response.data.device_roles_list) {
          if (response.data.device_roles_list.length > 0) {
            for (let i = 0; i < response.data.device_roles_list.length; i++) {
              my_list.push({
                ...response.data.device_roles_list[i],
                checked: false,
              });
            }

            yield put(deviceRoleListAction(my_list));
          } else {
            yield put(deviceRoleListAction());
          }
        } else {
          yield put(deviceRoleListAction());
        }
        if (response.data.device_access_group_list) {
          if (response.data.device_access_group_list.length > 0) {
            let my_list = [];
            for (
              let i = 0;
              i < response.data.device_access_group_list.length;
              i++
            ) {
              my_list.push({
                ...response.data.device_access_group_list[i],
                showDeleteBox: false,
              });
            }
            yield put(deviceAccessGroupListSuccessfullAction(my_list));
          } else {
            yield put(deviceAccessGroupListFailedAction(""));
          }
        } else {
          yield put(deviceAccessGroupListFailedAction(""));
        }
      } else {
        yield put(
          deviceAccessGroupListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(deviceAccessGroupListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deviceAccessGroupListFailedAction(error.message));
  }
}
function* createDeviceAccessGroup({ payload }) {
  try {
    const response = yield call(
      createDeviceAccessGroupServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.role_id,
      payload.title
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createDevicesAccessGroupSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          deviceAccessGroupListAction(
            payload.shop_id,
            payload.login_key,
            payload.user_id
          )
        );
      } else {
        yield put(
          createDevicesAccessGroupFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        createDevicesAccessGroupFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(createDevicesAccessGroupFailedAction(error.message));
  }
}
function* updateDeviceAccessGroup({ payload }) {
  try {
    const response = yield call(
      updateDeviceAccessGroupServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.role_id,
      payload.title,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateDevicesAccessGroupSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          deviceAccessGroupListAction(
            payload.shop_id,
            payload.login_key,
            payload.user_id
          )
        );
      } else {
        yield put(
          updateDevicesAccessGroupFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateDevicesAccessGroupFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateDevicesAccessGroupFailedAction(error.message));
  }
}
function* deleteDevicessAccess({ payload }) {
  try {
    const response = yield call(
      deleteDevicesAccessService,
      payload.shop_id,
      payload.login_key,
      payload.user_id,
      payload.id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteDevicesAccessGroupSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          deviceAccessGroupListAction(
            payload.shop_id,
            payload.login_key,
            payload.user_id
          )
        );
      } else {
        yield put(
          deleteDevicesAccessGroupFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        deleteDevicesAccessGroupFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(deleteDevicesAccessGroupFailedAction(error.message));
  }
}
function* getCheckInCheckOutList({ payload }) {
  try {
    const response = yield call(
      getCheckInCheckOutListService,
      payload.shop_id,
      payload.login_key,
      payload.user_id,
      payload.date_from,
      payload.date_to
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Enter_Exit_List) {
          if (response.data.Enter_Exit_List.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.Enter_Exit_List.length; i++) {
              my_list.push({
                ...response.data.Enter_Exit_List[i],
                showDeleteBox: false,
              });
            }
            yield put(getCheckInCheckOutListSuccessfullAction(my_list));
          } else {
            yield put(getCheckInCheckOutListFailedAction(""));
          }
        } else {
          yield put(getCheckInCheckOutListFailedAction(""));
        }
      } else {
        yield put(
          getCheckInCheckOutListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getCheckInCheckOutListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getCheckInCheckOutListFailedAction(error.message));
  }
}

function* updateCheckInCheckOut({ payload }) {
  try {
    const response = yield call(
      updateCheckInCheckOutService,

      payload.shop_id,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.date_from,
      payload.date_to,
      payload.low_time_work
      // payload.login_user_id,
      // payload.date_from_for_list,
      // payload.date_to_for_list
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateCheckInCheckOutSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getCheckInCheckOutListAction(
            payload.shop_id,
            payload.login_key,
            payload.login_user_id,
            payload.date_from_for_list,
            payload.date_to_for_list
          )
        );
      } else {
        yield put(
          updateCheckInCheckOutFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateCheckInCheckOutFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateCheckInCheckOutFailedAction(error.message));
  }
}
function* deleteCheckInCheckOut({ payload }) {
  try {
    const response = yield call(
      deleteCheckInCheckOutService,
      payload.shop_id,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.login_user_id,
      payload.date_from,
      payload.date_to
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteCheckIncheckOutSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getCheckInCheckOutListAction(
            payload.shop_id,
            payload.login_key,
            payload.login_user_id,
            payload.date_from,
            payload.date_to
          )
        );
      } else {
        yield put(
          deleteCheckIncheckOutFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(deleteCheckIncheckOutFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteCheckIncheckOutFailedAction(error.message));
  }
}
function* getEnterExitListByEmployeeId({ payload }) {
  try {
    const response = yield call(
      getEnterExitListByIdService,
      payload.shop_id,
      payload.login_key,
      payload.user_id,
      payload.date_from,
      payload.date_to,
      payload.employee_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Enter_Exit_List) {
          if (response.data.Enter_Exit_List.length > 0) {
            yield put(
              getEnterExitListByEmployeeIdSuccessfullAction(
                response.data.Enter_Exit_List
              )
            );
          } else {
            yield put(getEnterExitListByEmployeeIdFailedAction(""));
          }
        } else {
          yield put(getEnterExitListByEmployeeIdFailedAction(""));
        }
      } else {
        yield put(
          getEnterExitListByEmployeeIdFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getEnterExitListByEmployeeIdFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getEnterExitListByEmployeeIdFailedAction(error.message));
  }
}
export function* watchGetEnterExitListByEmployeeId() {
  yield takeLatest(
    action_types.GET_ENTER_EXIT_LIST_BY_EMPLOYEE,
    getEnterExitListByEmployeeId
  );
}
export function* watchDeleteCheckInCheckOut() {
  yield takeLatest(
    action_types.DELETE_CHECK_IC_CHECK_OUT,
    deleteCheckInCheckOut
  );
}
export function* watchUpdateCheckInCheckOut() {
  yield takeLatest(
    action_types.UPDATE_CHECK_IC_CHECK_OUT,
    updateCheckInCheckOut
  );
}
export function* watchGetCheckInCheckOutList() {
  yield takeLatest(
    action_types.GET_CHECK_IC_CHECK_OUT_LIST,
    getCheckInCheckOutList
  );
}
export function* watchDeleteDevicessAccess() {
  yield takeLatest(
    action_types.DELETE_DEVICE_ACCESS_GROUP,
    deleteDevicessAccess
  );
}
export function* watchCreateDeviceAccessGroupList() {
  yield takeLatest(
    action_types.CREATE_DEVICE_ACCESS_GROUP,
    createDeviceAccessGroup
  );
}
export function* watchUpdateDeviceAccessGroupList() {
  yield takeLatest(
    action_types.UPDATE_DEVICE_ACCESS_GROUP,
    updateDeviceAccessGroup
  );
}
export function* watchDeviceAccessGroupList() {
  yield takeLatest(
    action_types.DEVICE_ACCESS_GROUP_LIST,
    deviceAccessGroupList
  );
}
export function* watchDeleteDriverShop() {
  yield takeLatest(action_types.DELETE_DRIVER_SHOP, deleteDriverShop);
}
export function* watchCretaeDriverShop() {
  yield takeLatest(action_types.CREATE_DRIVER_SHOP, cretaeDriverShop);
}
export function* watchDriverShopList() {
  yield takeLatest(action_types.DRIVER_SHOP_LIST, driverShopList);
}
export function* watchDriverWithUniqueId() {
  yield takeLatest(action_types.DRIVER_WITH_UNIQUE_ID, driverWithUniqueId);
}
export function* watchUpdateDriversList() {
  yield takeLatest(action_types.UPDATE_DRIVER, updateDriver);
}
export function* watchDeleteDriver() {
  yield takeLatest(action_types.DELETE_DRIVER, deleteDriver);
}
export function* watchCreateDriversList() {
  yield takeLatest(action_types.CREATE_DRIVER, createDrivers);
}
export function* watchGetDriversList() {
  yield takeLatest(action_types.GET_DRIVERS_LIST, getDriversList);
}

export function* watchDeleteShopEmployees() {
  yield takeLatest(action_types.DELETE_SHOP_EMPLOYEES, deleteShopEmployess);
}

export function* watchUpdateShopEmployees() {
  yield takeLatest(action_types.UPDATE_EMPLOYEES, updateShopEmployess);
}
export function* watchCreateShopEmployees() {
  yield takeLatest(action_types.CREATE_EMPLOYEES, createShopEmployess);
}
export function* watchGetShopEmployees() {
  yield takeLatest(action_types.GET_SHOP_EMPLOYEES, getShopEmployees);
}
export default function* rootShopEmployess() {
  yield all([
    fork(watchGetShopEmployees),
    fork(watchCreateShopEmployees),
    fork(watchUpdateShopEmployees),
    fork(watchDeleteShopEmployees),
    fork(watchGetDriversList),
    fork(watchCreateDriversList),
    fork(watchDeleteDriver),
    fork(watchUpdateDriversList),
    fork(watchDriverWithUniqueId),
    fork(watchDriverShopList),
    fork(watchCretaeDriverShop),
    fork(watchDeleteDriverShop),
    fork(watchDeviceAccessGroupList),
    fork(watchCreateDeviceAccessGroupList),
    fork(watchUpdateDeviceAccessGroupList),
    fork(watchDeleteDevicessAccess),
    fork(watchGetCheckInCheckOutList),
    fork(watchUpdateCheckInCheckOut),
    fork(watchDeleteCheckInCheckOut),
    fork(watchGetEnterExitListByEmployeeId),
  ]);
}
