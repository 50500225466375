import { combineReducers } from "redux";
import appSettingsRedusers from "./settings/Reduser";
import selectedLanguage from "./language/Reducers";
import categoryList from "./menu/Reducers";
import productList from "./product/Redusers";
import submenuRedusers from "./submenu/Reduser";
import shopReducer from "./shop/Reducers";
import employeesRedusers from "./Employees/Redusers";
import worksStationsReduser from "./WorksStations/Redusers";
import loginReducer from "./login/Reducers";
import usersReduser from "./Users/Redusers";
import devicesWorkStationReduser from "./devices/Redusers";
import tabelAreaRedusers from "./GeneralSetting/Redusers";
import ordersReduser from "./Orders//Redusers";
import customersRedusers from "./Customers/Redusers";
import salesRedusers from "./sales/Redusers";
import financialReduser from "./financial/Redusers";
import MainNavGeneralRedusers from "./MainNavGeneral/Reducers";
import setupReduser from "./setup/Redusers";
import WePosRedusers from "./WePos/Redusers";
export const Reducers = combineReducers({
  selectedLanguage,
  categoryList,
  productList,
  submenuRedusers,
  appSettingsRedusers,
  shopReducer,
  employeesRedusers,
  worksStationsReduser,
  loginReducer,
  usersReduser,
  devicesWorkStationReduser,
  tabelAreaRedusers,
  ordersReduser,
  customersRedusers,
  salesRedusers,
  financialReduser,
  setupReduser,
  MainNavGeneralRedusers,
  WePosRedusers,
});
