import React, { useEffect, useState } from "react";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  getAboutUsListAction,
  updateAboutUsAction,
} from "../../../../redux/GeneralSetting/Actions";
import Loader from "../../../../components/Loader";
import { setDefaultAction } from "../../../../redux/menu/Actions";
const About = () => {
  const {
    result_failed,
    result_successfull,
    result_message,
    loading_post_code,
    about_us_list,
  } = useSelector((state) => state.tabelAreaRedusers);
  const dispatch = useDispatch();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const [message, setMessage] = useState("");
  const handleSave = () => {
    dispatch(
      updateAboutUsAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        message ? message : ""
      )
    );
  };
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getAboutUsListAction(login_key, user_id, selected_shop[0].ShopId)
        );
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (about_us_list) {
      if (about_us_list.length > 0) {
        if (about_us_list[0].Description) {
          setMessage(about_us_list[0].Description);
        } else {
          setMessage("");
        }
      } else {
        setMessage("");
      }
    } else {
      setMessage("");
    }
  }, [about_us_list]);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: window.innerHeight - 220,
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {" "}
          <div className="sm-div2">
            <div className="general-setting-about-container">
              <h6>{lang.about_us}</h6>
              <textarea
                className="general-setting-about-textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />{" "}
            </div>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={handleSave} />
      {loading_post_code ? <Loader /> : null}
    </div>
  );
};

export default About;
