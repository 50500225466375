import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import { showProductAvailabilityPopupAction } from "../redux/product/Action";
import { product_availability_time } from "../constants/DropDownList";
import {
  getProductOpenCloseListAction,
  setDefaultAction,
  createProductOpenCloseTimeAction,
} from "../redux/menu/Actions";
import Loader from "./Loader";

const ProductAvailabilityPopup = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { product_list, selected_Product } = useSelector(
    (state) => state.productList
  );
  const {
    result_successfull,
    result_failed,
    result_message,
    // loading_category,
    get_product_open_close_list,
    product_open_close_time_popup,
    product_open_close_result_successfull,
  } = useSelector((state) => state.categoryList);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const [openTimesList, setOpenTimesList] = useState([]);
  const [closeTimesList, setCloseTimesList] = useState([]);
  const [mondayCheckbox, setMondayCheckbox] = useState(false);
  const [sundayCheckbox, setSundayCheckbox] = useState(false);
  const [tuesdayCheckbox, setTuesdayCheckbox] = useState(false);
  const [wednesdayCheckbox, setWednesdayCheckbox] = useState(false);
  const [thursdayCheckbox, setThursdayCheckbox] = useState(false);
  const [fridayCheckbox, setFridayCheckbox] = useState(false);
  const [saturdayCheckbox, setSaturdayCheckbox] = useState(false);

  const [openMondayTime, setOpenMondayTime] = useState(null);
  const [closeMondayTime, setCloseMondayTime] = useState(null);

  const [openSundayTime, setOpenSundayTime] = useState(null);
  const [closeSundayTime, setCloseSundayTime] = useState(null);

  const [openTuesdayTime, setOpenTuesdayTime] = useState(null);
  const [closeTuesdayTime, setCloseTuesdayTime] = useState(null);

  const [openWednesdayTime, setOpenWednesdayTime] = useState(null);
  const [closeWednesdayTime, setCloseWednesdayTime] = useState(null);

  const [openThursdayTime, setOpenThursdayTime] = useState(null);
  const [closeThursdayTime, setCloseThursdayTime] = useState(null);

  const [openFridayTime, setOpenFridayTime] = useState(null);
  const [closeFridayTime, setCloseFridayTime] = useState(null);

  const [openSaturdayTime, setOpenSaturdayTime] = useState(null);
  const [closeSaturdayTime, setCloseSaturdayTime] = useState(null);

  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getProductOpenCloseListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_Product.Id
          )
        );
      }
    }
  }, [selected_shop, selected_Product]);
  // useEffect(() => {
  //   if (result_successfull) {
  //     dispatch(setDefaultAction());
  //     toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
  //       position: "top-center",
  //       duration: 3000,
  //       style: { backgroundColor: "green", color: "white" },
  //       icon: (
  //         <i
  //           style={{ fontSize: 40 }}
  //           class="fa fa-check-circle"
  //           aria-hidden="true"
  //         ></i>
  //       ),
  //     });
  //   } else if (result_failed) {
  //     dispatch(setDefaultAction());
  //     if (result_message) {
  //       toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
  //         position: "top-center",
  //         duration: 3000,
  //         style: { backgroundColor: "red", color: "white" },
  //         icon: (
  //           <i
  //             style={{ fontSize: 40 }}
  //             class="fa fa-times-circle"
  //             aria-hidden="true"
  //           ></i>
  //         ),
  //       });
  //     }
  //   }
  // }, [result_successfull, result_failed]);

  const handleCreateProductOpenCloseTime = () => {
    let array = [
      {
        Day_OfWeek: 0,
        OpenTime: openMondayTime
          ? openMondayTime.label.includes("after midnight")
            ? `${openMondayTime.label.split(" ")[0]}:00`
            : `${openMondayTime.label}:00`
          : "00:00:00",
        CloseTime: closeMondayTime
          ? closeMondayTime.label.includes("after midnight")
            ? `${closeMondayTime.label.split(" ")[0]}:00`
            : `${closeMondayTime.label}:00`
          : "00:00:00",
        Active: mondayCheckbox ? 1 : 0,
      },
      {
        Day_OfWeek: 1,
        OpenTime: openTuesdayTime
          ? openTuesdayTime.label.includes("after midnight")
            ? `${openTuesdayTime.label.split(" ")[0]}:00`
            : `${openTuesdayTime.label}:00`
          : "00:00:00",

        CloseTime: closeTuesdayTime
          ? closeTuesdayTime.label.includes("after midnight")
            ? `${closeTuesdayTime.label.split(" ")[0]}:00`
            : `${closeTuesdayTime.label}:00`
          : "00:00:00",
        Active: tuesdayCheckbox ? 1 : 0,
      },
      {
        Day_OfWeek: 2,
        OpenTime: openWednesdayTime
          ? openWednesdayTime.label.includes("after midnight")
            ? `${openWednesdayTime.label.split(" ")[0]}:00`
            : `${openWednesdayTime.label}:00`
          : "00:00:00",

        CloseTime: closeWednesdayTime
          ? closeWednesdayTime.label.includes("after midnight")
            ? `${closeWednesdayTime.label.split(" ")[0]}:00`
            : `${closeWednesdayTime.label}:00`
          : "00:00:00",
        Active: wednesdayCheckbox ? 1 : 0,
      },
      {
        Day_OfWeek: 3,
        OpenTime: openThursdayTime
          ? openThursdayTime.label.includes("after midnight")
            ? `${openThursdayTime.label.split(" ")[0]}:00`
            : `${openThursdayTime.label}:00`
          : "00:00:00",
        CloseTime: closeThursdayTime
          ? closeThursdayTime.label.includes("after midnight")
            ? `${closeThursdayTime.label.split(" ")[0]}:00`
            : `${closeThursdayTime.label}:00`
          : "00:00:00",
        Active: thursdayCheckbox ? 1 : 0,
      },
      {
        Day_OfWeek: 4,
        OpenTime: openFridayTime
          ? openFridayTime.label.includes("after midnight")
            ? `${openFridayTime.label.split(" ")[0]}:00`
            : `${openFridayTime.label}:00`
          : "00:00:00",
        CloseTime: closeFridayTime
          ? closeFridayTime.label.includes("after midnight")
            ? `${closeFridayTime.label.split(" ")}:00`
            : `${closeFridayTime.label}:00`
          : "00:00:00",
        Active: fridayCheckbox ? 1 : 0,
      },
      {
        Day_OfWeek: 5,
        OpenTime: openSaturdayTime
          ? openSaturdayTime.label.includes("after midnight")
            ? `${openSaturdayTime.label.split(" ")[0]}:00`
            : `${openSaturdayTime.label}:00`
          : "00:00:00",
        CloseTime: closeSaturdayTime
          ? closeSaturdayTime.label.includes("after midnight")
            ? `${closeSaturdayTime.label.split(" ")[0]}:00`
            : `${closeSaturdayTime.label}:00 `
          : "00:00:00",
        Active: saturdayCheckbox ? 1 : 0,
      },
      {
        Day_OfWeek: 6,
        OpenTime: openSundayTime
          ? openSundayTime.label.includes("after midnight")
            ? `${openSundayTime.label.split(" ")[0]}:00`
            : `${openSundayTime.label}:00`
          : "00:00:00",
        CloseTime: closeSundayTime
          ? closeSundayTime.label.includes("after midnight")
            ? `${closeSundayTime.label.split(" ")[0]}`
            : `${closeSundayTime.label}:00`
          : "00:00:00",
        Active: sundayCheckbox ? 1 : 0,
      },
    ];
    let array1 = { DaysList: array };
    dispatch(
      createProductOpenCloseTimeAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        selected_Product.Id,
        array1
      )
    );
  };
  const handleFocusOntimes = (id) => {
    let chanched_time = [];
    let copy_product_availability_time_label = [];
    let copy_product_availability_time = [...product_availability_time];
    for (let i = 0; i < copy_product_availability_time.length; i++) {
      copy_product_availability_time_label.push(
        copy_product_availability_time[i].label
      );
    }
    if (id == 2) {
      if (openMondayTime) {
        if (
          copy_product_availability_time_label.includes(
            String(openMondayTime.label)
          )
        ) {
          let index = copy_product_availability_time_label.indexOf(
            String(openMondayTime.label)
          );
          for (let i = 0; i < copy_product_availability_time.length; i++) {
            if (index >= i) {
              chanched_time.push({
                ...copy_product_availability_time[i],
                isDisabled: true,
              });
            } else {
              chanched_time.push(copy_product_availability_time[i]);
            }
          }
          let array = [];
          for (let i = 0; i < chanched_time.length; i++) {
            if (!chanched_time[i].isDisabled) {
              array.push(chanched_time[i]);
            }
          }
          setCloseTimesList(array);
        }
      } else {
        setCloseMondayTime(null);
      }
    } else if (id == 4) {
      if (openTuesdayTime) {
        if (
          copy_product_availability_time_label.includes(
            String(openTuesdayTime.label)
          )
        ) {
          let index = copy_product_availability_time_label.indexOf(
            String(openTuesdayTime.label)
          );
          for (let i = 0; i < copy_product_availability_time.length; i++) {
            if (index >= i) {
              chanched_time.push({
                ...copy_product_availability_time[i],
                isDisabled: true,
              });
            } else {
              chanched_time.push(copy_product_availability_time[i]);
            }
          }
          let array = [];
          for (let i = 0; i < chanched_time.length; i++) {
            if (!chanched_time[i].isDisabled) {
              array.push(chanched_time[i]);
            }
          }
          setCloseTimesList(array);
        }
      } else {
        setCloseTuesdayTime(null);
      }
    } else if (id == 6) {
      if (openWednesdayTime) {
        if (
          copy_product_availability_time_label.includes(
            String(openWednesdayTime.label)
          )
        ) {
          let index = copy_product_availability_time_label.indexOf(
            String(openWednesdayTime.label)
          );
          for (let i = 0; i < copy_product_availability_time.length; i++) {
            if (index >= i) {
              chanched_time.push({
                ...copy_product_availability_time[i],
                isDisabled: true,
              });
            } else {
              chanched_time.push(copy_product_availability_time[i]);
            }
          }
          let array = [];
          for (let i = 0; i < chanched_time.length; i++) {
            if (!chanched_time[i].isDisabled) {
              array.push(chanched_time[i]);
            }
          }
          setCloseTimesList(array);
        }
      } else {
        setCloseWednesdayTime(null);
      }
    } else if (id == 8) {
      if (openThursdayTime) {
        if (
          copy_product_availability_time_label.includes(
            String(openThursdayTime.label)
          )
        ) {
          let index = copy_product_availability_time_label.indexOf(
            String(openThursdayTime.label)
          );
          for (let i = 0; i < copy_product_availability_time.length; i++) {
            if (index >= i) {
              chanched_time.push({
                ...copy_product_availability_time[i],
                isDisabled: true,
              });
            } else {
              chanched_time.push(copy_product_availability_time[i]);
            }
          }
          let array = [];
          for (let i = 0; i < chanched_time.length; i++) {
            if (!chanched_time[i].isDisabled) {
              array.push(chanched_time[i]);
            }
          }
          setCloseTimesList(array);
        }
      } else {
        setCloseThursdayTime(null);
      }
    } else if (id == 10) {
      if (openFridayTime) {
        if (
          copy_product_availability_time_label.includes(
            String(openFridayTime.label)
          )
        ) {
          let index = copy_product_availability_time_label.indexOf(
            String(openFridayTime.label)
          );
          for (let i = 0; i < copy_product_availability_time.length; i++) {
            if (index >= i) {
              chanched_time.push({
                ...copy_product_availability_time[i],
                isDisabled: true,
              });
            } else {
              chanched_time.push(copy_product_availability_time[i]);
            }
          }
          let array = [];
          for (let i = 0; i < chanched_time.length; i++) {
            if (!chanched_time[i].isDisabled) {
              array.push(chanched_time[i]);
            }
          }
          setCloseTimesList(array);
        }
      } else {
        setCloseFridayTime(null);
      }
    } else if (id == 12) {
      if (openSaturdayTime) {
        if (
          copy_product_availability_time_label.includes(
            String(openSaturdayTime.label)
          )
        ) {
          let index = copy_product_availability_time_label.indexOf(
            String(openSaturdayTime.label)
          );
          for (let i = 0; i < copy_product_availability_time.length; i++) {
            if (index >= i) {
              chanched_time.push({
                ...copy_product_availability_time[i],
                isDisabled: true,
              });
            } else {
              chanched_time.push(copy_product_availability_time[i]);
            }
          }
          let array = [];
          for (let i = 0; i < chanched_time.length; i++) {
            if (!chanched_time[i].isDisabled) {
              array.push(chanched_time[i]);
            }
          }
          setCloseTimesList(array);
        }
      } else {
        setCloseSaturdayTime(null);
      }
    } else if (id == 14) {
      if (openSundayTime) {
        if (
          copy_product_availability_time_label.includes(
            String(openSundayTime.label)
          )
        ) {
          let index = copy_product_availability_time_label.indexOf(
            String(openSundayTime.label)
          );
          for (let i = 0; i < copy_product_availability_time.length; i++) {
            if (index >= i) {
              chanched_time.push({
                ...copy_product_availability_time[i],
                isDisabled: true,
              });
            } else {
              chanched_time.push(copy_product_availability_time[i]);
            }
          }
          let array = [];
          for (let i = 0; i < chanched_time.length; i++) {
            if (!chanched_time[i].isDisabled) {
              array.push(chanched_time[i]);
            }
          }
          setCloseTimesList(array);
        }
      } else {
        setCloseSundayTime(null);
      }
    }
  };

  useEffect(() => {
    let copy_product_availability_time_label = [];
    let open_monday_time = null;
    let close_monday_time = null;
    let open_tuesday_time = null;
    let close_tuesday_time = null;
    let open_wednesday_time = null;
    let close_wednesday_time = null;
    let open_thursday_time = null;
    let close_thursday_time = null;
    let open_friday_time = null;
    let close_friday_time = null;
    let open_saturday_time = null;
    let close_saturday_time = null;
    let open_sunday_time = null;
    let close_sunday_time = null;
    let copy_product_availability_time = [...product_availability_time];
    for (let i = 0; i < copy_product_availability_time.length; i++) {
      copy_product_availability_time_label.push(
        copy_product_availability_time[i].label.split(" ")[0]
      );
    }

    if (get_product_open_close_list) {
      if (get_product_open_close_list.length > 0) {
        for (let i = 0; i < get_product_open_close_list.length; i++) {
          if (get_product_open_close_list[i].DayOfWeek == 0) {
            if (get_product_open_close_list[i].OpenTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );
                open_monday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setOpenMondayTime(open_monday_time);
            }
            if (get_product_open_close_list[i].CloseTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );

                close_monday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setCloseMondayTime(close_monday_time);
            }
            setMondayCheckbox(get_product_open_close_list[i].Active);
          } else if (get_product_open_close_list[i].DayOfWeek == 1) {
            if (get_product_open_close_list[i].OpenTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );
                open_tuesday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setOpenTuesdayTime(open_tuesday_time);
            }
            if (get_product_open_close_list[i].CloseTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );

                close_tuesday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setCloseTuesdayTime(close_tuesday_time);
            }
            setTuesdayCheckbox(get_product_open_close_list[i].Active);
          } else if (get_product_open_close_list[i].DayOfWeek == 2) {
            if (get_product_open_close_list[i].OpenTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );
                open_wednesday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setOpenWednesdayTime(open_wednesday_time);
            }
            if (get_product_open_close_list[i].CloseTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );

                close_wednesday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setCloseWednesdayTime(close_wednesday_time);
            }
            setWednesdayCheckbox(get_product_open_close_list[i].Active);
          } else if (get_product_open_close_list[i].DayOfWeek == 3) {
            if (get_product_open_close_list[i].OpenTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );
                open_thursday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setOpenThursdayTime(open_thursday_time);
            }
            if (get_product_open_close_list[i].CloseTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );

                close_thursday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setCloseThursdayTime(close_thursday_time);
            }
            setThursdayCheckbox(get_product_open_close_list[i].Active);
          } else if (get_product_open_close_list[i].DayOfWeek == 4) {
            if (get_product_open_close_list[i].OpenTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );
                open_friday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setOpenFridayTime(open_friday_time);
            }
            if (get_product_open_close_list[i].CloseTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );

                close_friday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setCloseFridayTime(close_friday_time);
            }
            setFridayCheckbox(get_product_open_close_list[i].Active);
          } else if (get_product_open_close_list[i].DayOfWeek == 5) {
            if (get_product_open_close_list[i].OpenTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );
                open_saturday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setOpenSaturdayTime(open_saturday_time);
            }
            if (get_product_open_close_list[i].CloseTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );

                close_saturday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setCloseSaturdayTime(close_saturday_time);
            }
            setSaturdayCheckbox(get_product_open_close_list[i].Active);
          } else if (get_product_open_close_list[i].DayOfWeek == 6) {
            if (get_product_open_close_list[i].OpenTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].OpenTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );
                open_sunday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setOpenSundayTime(open_sunday_time);
            }
            if (get_product_open_close_list[i].CloseTime) {
              if (
                copy_product_availability_time_label.includes(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                )
              ) {
                let index = copy_product_availability_time_label.indexOf(
                  String(
                    `${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[0]
                    }:${
                      get_product_open_close_list[i].CloseTime.split(
                        "."
                      )[0].split(":")[1]
                    }`
                  )
                );

                close_sunday_time = {
                  value: copy_product_availability_time[index].value,
                  label: copy_product_availability_time[index].label,
                };
              }
              setCloseSundayTime(close_sunday_time);
            }
            setSundayCheckbox(get_product_open_close_list[i].Active);
          }
        }
      } else {
        setMondayCheckbox(false);
        setTuesdayCheckbox(false);
        setWednesdayCheckbox(false);
        setThursdayCheckbox(false);
        setWednesdayCheckbox(false);
        setFridayCheckbox(false);
        setSaturdayCheckbox(false);
        setSundayCheckbox(false);
        setOpenMondayTime(null);
        setCloseMondayTime(null);
        setOpenTuesdayTime(null);
        setCloseTuesdayTime(null);
        setOpenWednesdayTime(null);
        setCloseWednesdayTime(null);
        setOpenThursdayTime(null);
        setCloseThursdayTime(null);
        setOpenFridayTime(null);
        setCloseFridayTime(null);
        setOpenSaturdayTime(null);
        setCloseSaturdayTime(null);
        setOpenSundayTime(null);
        setCloseSundayTime(null);
      }
    }
  }, [get_product_open_close_list]);
  useEffect(() => {
    if (product_open_close_result_successfull) {
      dispatch(showProductAvailabilityPopupAction(false));
    }
  }, [product_open_close_result_successfull]);
  useEffect(() => {
    if (product_availability_time) {
      if (product_availability_time.length > 0) {
        setOpenTimesList(product_availability_time);
        setCloseTimesList(product_availability_time);
      }
    }
  }, [product_availability_time]);
  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showProductAvailabilityPopupAction(false))}
    >
      <div
        onClick={(i) => i.stopPropagation()}
        className="create_shop_popup_content_container product_open_close_time_popup_container"
      >
        <div className="product_open_close_container_header">
          <i
            style={{
              fontSize: "23px",
            }}
            onClick={() => dispatch(showProductAvailabilityPopupAction(false))}
            class="fa fa-times"
            aria-hidden="true"
          ></i>
          <h5>{lang.availability}</h5>
        </div>
        <div className="product_open_close_time_container_dropdown">
          <div className="product_open_close_time_container_any_day">
            <div style={{ width: "25%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mondayCheckbox}
                      onChange={() => setMondayCheckbox(!mondayCheckbox)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                      disabled={
                        openMondayTime != null && closeMondayTime != null
                          ? false
                          : true
                      }
                    />
                  }
                  label="Monday"
                />
              </FormGroup>
            </div>
            <div className="product_open_close_time_container_time_selector">
              <div style={{ width: "47%" }}>
                <Select
                  options={openTimesList}
                  defaultValue={openMondayTime}
                  isSearchable={false}
                  onChange={(list, item) => setOpenMondayTime(list)}
                  value={openMondayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="From..."
                  onFocus={() => handleFocusOntimes(1)}
                />{" "}
              </div>
              <div style={{ width: "47%" }}>
                <Select
                  options={closeTimesList}
                  defaultValue={closeMondayTime}
                  isSearchable={false}
                  onChange={(list, item) => setCloseMondayTime(list)}
                  value={closeMondayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="To..."
                  onFocus={() => handleFocusOntimes(2)}
                />
              </div>
            </div>
          </div>
          <div className="product_open_close_time_container_any_day">
            <div style={{ width: "25%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tuesdayCheckbox}
                      onChange={() => setTuesdayCheckbox(!tuesdayCheckbox)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                      disabled={
                        openTuesdayTime != null && closeTuesdayTime != null
                          ? false
                          : true
                      }
                    />
                  }
                  label="Tuesday"
                />
              </FormGroup>
            </div>
            <div className="product_open_close_time_container_time_selector">
              <div style={{ width: "47%" }}>
                <Select
                  options={openTimesList}
                  defaultValue={openTuesdayTime}
                  isSearchable={false}
                  onChange={(list, item) => setOpenTuesdayTime(list)}
                  value={openTuesdayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="From..."
                  onFocus={() => handleFocusOntimes(3)}
                />{" "}
              </div>
              <div style={{ width: "47%" }}>
                <Select
                  options={closeTimesList}
                  defaultValue={closeTuesdayTime}
                  isSearchable={false}
                  onChange={(list, item) => setCloseTuesdayTime(list)}
                  value={closeTuesdayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="To..."
                  onFocus={() => handleFocusOntimes(4)}
                />
              </div>
            </div>
          </div>
          <div className="product_open_close_time_container_any_day">
            <div style={{ width: "25%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wednesdayCheckbox}
                      onChange={() => setWednesdayCheckbox(!wednesdayCheckbox)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                      disabled={
                        openWednesdayTime != null && closeWednesdayTime != null
                          ? false
                          : true
                      }
                    />
                  }
                  label="Wednesday"
                />
              </FormGroup>
            </div>
            <div className="product_open_close_time_container_time_selector">
              <div style={{ width: "47%" }}>
                <Select
                  options={openTimesList}
                  defaultValue={openWednesdayTime}
                  isSearchable={false}
                  onChange={(list, item) => setOpenWednesdayTime(list)}
                  value={openWednesdayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="From..."
                  onFocus={() => handleFocusOntimes(5)}
                />{" "}
              </div>
              <div style={{ width: "47%" }}>
                <Select
                  options={closeTimesList}
                  defaultValue={closeWednesdayTime}
                  isSearchable={false}
                  onChange={(list, item) => setCloseWednesdayTime(list)}
                  value={closeWednesdayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="To..."
                  onFocus={() => handleFocusOntimes(6)}
                />
              </div>
            </div>
          </div>
          <div className="product_open_close_time_container_any_day">
            <div style={{ width: "25%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={thursdayCheckbox}
                      onChange={() => setThursdayCheckbox(!thursdayCheckbox)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                      disabled={
                        openThursdayTime != null && closeThursdayTime != null
                          ? false
                          : true
                      }
                    />
                  }
                  label="Thursday"
                />
              </FormGroup>
            </div>
            <div className="product_open_close_time_container_time_selector">
              <div style={{ width: "47%" }}>
                <Select
                  options={openTimesList}
                  defaultValue={openThursdayTime}
                  isSearchable={false}
                  onChange={(list, item) => setOpenThursdayTime(list)}
                  value={openThursdayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="From..."
                  onFocus={() => handleFocusOntimes(7)}
                />{" "}
              </div>
              <div style={{ width: "47%" }}>
                <Select
                  options={closeTimesList}
                  defaultValue={closeThursdayTime}
                  isSearchable={false}
                  onChange={(list, item) => setCloseThursdayTime(list)}
                  value={closeThursdayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="To..."
                  onFocus={() => handleFocusOntimes(8)}
                />
              </div>
            </div>
          </div>
          <div className="product_open_close_time_container_any_day">
            <div style={{ width: "25%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fridayCheckbox}
                      onChange={() => setFridayCheckbox(!fridayCheckbox)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                      disabled={
                        openFridayTime != null && closeFridayTime != null
                          ? false
                          : true
                      }
                    />
                  }
                  label="Friday"
                />
              </FormGroup>
            </div>
            <div className="product_open_close_time_container_time_selector">
              <div style={{ width: "47%" }}>
                <Select
                  options={openTimesList}
                  defaultValue={openFridayTime}
                  isSearchable={false}
                  onChange={(list, item) => setOpenFridayTime(list)}
                  value={openFridayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="From..."
                  onFocus={() => handleFocusOntimes(9)}
                />{" "}
              </div>
              <div style={{ width: "47%" }}>
                <Select
                  options={closeTimesList}
                  defaultValue={closeFridayTime}
                  isSearchable={false}
                  onChange={(list, item) => setCloseFridayTime(list)}
                  value={closeFridayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="To..."
                  onFocus={() => handleFocusOntimes(10)}
                />
              </div>
            </div>
          </div>
          <div className="product_open_close_time_container_any_day">
            <div style={{ width: "25%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saturdayCheckbox}
                      onChange={() => setSaturdayCheckbox(!saturdayCheckbox)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                      disabled={
                        openSaturdayTime != null && closeSaturdayTime != null
                          ? false
                          : true
                      }
                    />
                  }
                  label="Saturday"
                />
              </FormGroup>
            </div>
            <div className="product_open_close_time_container_time_selector">
              <div style={{ width: "47%" }}>
                <Select
                  options={openTimesList}
                  defaultValue={openSaturdayTime}
                  isSearchable={false}
                  onChange={(list, item) => setOpenSaturdayTime(list)}
                  value={openSaturdayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="From..."
                  onFocus={() => handleFocusOntimes(11)}
                />{" "}
              </div>
              <div style={{ width: "47%" }}>
                <Select
                  options={closeTimesList}
                  defaultValue={closeSaturdayTime}
                  isSearchable={false}
                  onChange={(list, item) => setCloseSaturdayTime(list)}
                  value={closeSaturdayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="To..."
                  onFocus={() => handleFocusOntimes(12)}
                />
              </div>
            </div>
          </div>
          <div className="product_open_close_time_container_any_day">
            <div style={{ width: "25%" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sundayCheckbox}
                      onChange={() => setSundayCheckbox(!sundayCheckbox)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                      disabled={
                        openSundayTime != null && closeSundayTime != null
                          ? false
                          : true
                      }
                    />
                  }
                  label="Sunday"
                />
              </FormGroup>
            </div>
            <div className="product_open_close_time_container_time_selector">
              <div style={{ width: "47%" }}>
                <Select
                  options={openTimesList}
                  defaultValue={openSundayTime}
                  isSearchable={false}
                  onChange={(list, item) => setOpenSundayTime(list)}
                  value={openSundayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="From..."
                  onFocus={() => handleFocusOntimes(13)}
                />{" "}
              </div>
              <div style={{ width: "47%" }}>
                <Select
                  options={closeTimesList}
                  defaultValue={closeSundayTime}
                  isSearchable={false}
                  onChange={(list, item) => setCloseSundayTime(list)}
                  value={closeSundayTime}
                  isMulti={false}
                  styles={customStyles}
                  isClearable={false}
                  placeholder="To..."
                  onFocus={() => handleFocusOntimes(14)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="product_open_close_time_container_save_button">
          <button onClick={handleCreateProductOpenCloseTime}>
            {lang.save}
          </button>
        </div>
      </div>
      <Toaster />
      {product_open_close_time_popup ? <Loader /> : null}
    </div>
  );
};

export default ProductAvailabilityPopup;
