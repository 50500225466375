import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { main_nav_button_color } from "../../../constants/Colors";
import ListHeader from "../../../components/ListHeader";
import MenuListItem from "../../../components/MenuListItem";
import {
  getExternalCompanyListAction,
  selectedExternalCompanyAction,
} from "../../../redux/MainNavGeneral/Actions";
import { changeSelectedButtonBorderColorOnFocus } from "../../../redux/menu/Actions";
const ExternalCompanyList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { external_company_list, selected_external_company } = useSelector(
    (state) => state.MainNavGeneralRedusers
  );
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [externalCompanyList, setExternalCompanyList] = useState([]);
  const handleClickedItem = (item, key) => {
    setClickedItem(key);
    dispatch(selectedExternalCompanyAction(item));
  };

  useEffect(() => {
    if (external_company_list) {
      if (external_company_list.length > 0) {
        setExternalCompanyList(external_company_list);
      } else {
        setExternalCompanyList([]);
      }
    } else {
      setExternalCompanyList([]);
    }
  }, [external_company_list]);
  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));

      // dispatch(selectedExternalCompanyAction(null));
    };
  }, [change_selected_button_border_color_on_focus]);
  useEffect(() => {
    dispatch(getExternalCompanyListAction(login_key, user_id));
  }, []);
  return (
    <div
      className="countaining-div"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <ListHeader title={lang.external_company_list} show_back={false} />
      <div className="menu_list_header_container">
        {/* BACK & TITLE */}

        {externalCompanyList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {externalCompanyList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={item.Title}
                  item_title={item.Title}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleClickedItem(item, 2)}
                  show_delete={false}
                  //   delete_click={() => handleDeleteItem(item)}
                  show_edit_icon={false}
                  selected={selected_external_company}
                  item={item}
                  number={0}
                  //   show_delete_box={() => handleShowDeleteBox(item)}
                  //   cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExternalCompanyList;
