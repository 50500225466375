import React, { useState, useEffect } from "react";
import useScrollbarSize from "react-scrollbar-size";
import MenuListItem from "../../../../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";

import { useDispatch, useSelector } from "react-redux";
import {
  vatListAction,
  visibleVatPopupAction,
  selectedVatAction,
  deleteVatAction,
} from "../../../../redux/GeneralSetting/Actions";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
} from "../../../../redux/menu/Actions";
const VatList = () => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();

  const { vat_list, selected_vat_list } = useSelector(
    (state) => state.tabelAreaRedusers
  );

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const [vatList, setVatList] = useState([]);
  const handleDeleteVat = (item) => {
    dispatch(
      deleteVatAction(login_key, user_id, selected_shop[0].ShopId, item.VatId)
    );
  };
  const handleCreateVat = () => {
    dispatch(visibleVatPopupAction(true));
    dispatch(selectedVatAction(null));
  };
  const handleEditVat = (item) => {
    dispatch(visibleVatPopupAction(true));
    dispatch(selectedVatAction(item));
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...vatList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.VatId == copy_list[i].VatId) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setVatList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...vatList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.VatId == copy_list[i].VatId) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setVatList(copy_list);
  };
  const handleEditIconClick = (item) => {
    const copy_product_list = [...vatList];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.VatId == copy_product_list[i].VatId) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setVatList(copy_product_list);
    dispatch(selectedOtemInAllLists(item));
  };

  const handleCloseIcon = (item) => {
    const copy_product_list = [...vatList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.VatId == copy_product_list[index].VatId) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setVatList(copy_product_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...vatList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
      // dispatch(justTitleAndPriceAction(0));
    }

    setVatList(copy_product_list);
  };

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(vatListAction(login_key, user_id, selected_shop[0].ShopId));
    }
  }, [selected_shop]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    if (vat_list.length > 0) {
      setVatList(vat_list);
    }
  }, [vat_list]);
  return (
    <div
      className="countaining-div common-styles"
      style={{
        height: "100%",
      }}
      id="countaining"
    >
      <div className="menu_list_header_container">
        {vat_list.length > 0 ? (
          <div>
            {vat_list.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  item_title={item.VatName}
                  field_1_title={item.VatName}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleEditVat(item)}
                  show_delete={true}
                  delete_click={() => handleDeleteVat(item)}
                  show_edit_icon={true}
                  selected={selected_vat_list}
                  item={item}
                  number={11}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                  click_edit_icon={() => handleEditIconClick(item)}
                  click_close_icon={() => handleCloseIcon(item)}
                  on_blur={() => handleCloseEditBoxWithOnBlur()}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span style={{ width: "172px" }} onClick={handleCreateVat}>
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_vat}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {vat_list.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={handleCreateVat}
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_vat}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VatList;
