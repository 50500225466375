import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const getWebSettingServices = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}getWebSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const updateWebSettingServices = async (
  formData,
  login_key,
  user_id,
  shop_id,
  mail_profile,
  domain,
  fav_icon,
  banner_text,
  banner_text_l2,
  banner_image,
  show_banner_image,
  facebooh_url,
  twittr_url,
  instgram_url,
  app_store_url,
  google_play_url,
  postcode_message,
  background_image,
  show_background_image,
  background_repeate
) => {
  // formData.append("LoginKey", login_key);
  // formData.append("UserId", user_id);
  // formData.append("ShopId", shop_id);
  // formData.append("MailProfile", mail_profile);
  // formData.append("Domain", domain);
  // formData.append("FavIcon", fav_icon);
  // formData.append("BannerText", banner_text);
  // formData.append("BannerTextL2", banner_text_l2);
  // formData.append("BannerImage", banner_image);
  // formData.append("ShowBannerImage", show_banner_image);
  // formData.append("FacebookUrl", facebooh_url);
  // formData.append("TwitterUrl", twittr_url);
  // formData.append("InstagramUrl", instgram_url);
  // formData.append("AppStoreUrl", app_store_url);
  // formData.append("GooglePlayUrl", google_play_url);
  // formData.append("PostcodeMessage", postcode_message);
  // formData.append("BackgroundImage", background_image);
  // formData.append("ShowBackgroundImage", show_background_image);
  // formData.append("BackgroundRepeat", background_repeate);
  return await axios.post(`${baseUrl}updateWebSetting/`, formData);
};

export const getOpenCloseTimeListService = async (
  login_key,
  shop_id,
  user_id
) => {
  return await axios.put(`${baseUrl}openCloseTimeList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const updateOpenCloseTimeListService = async (
  login_key,
  user_id,
  shop_id,
  id,
  day_of_week,
  delivery_open_time1,
  delivery_close_time1,
  delivery_open_time2,
  delivery_close_time2,
  delivery_open_time3,
  delivery_close_time3,
  collection_open_time1,
  collection_close_time1,
  collection_open_time2,
  collection_close_time2,
  collection_open_time3,
  collection_close_time3,
  open_delivery,
  open_collection,
  open_delivery_2,
  open_collection_2,
  open_delivery_3,
  open_collection_3,
  is_open_close_status
) => {
  return await axios.put(`${baseUrl}updateOpenCloseTime/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    id: id,
    day_of_week: day_of_week,
    delivery_open_time1: delivery_open_time1,
    delivery_close_time1: delivery_close_time1,
    delivery_open_time2: delivery_open_time2,
    delivery_close_time2: delivery_close_time2,
    delivery_open_time3: delivery_open_time3,
    delivery_close_time3: delivery_close_time3,
    collection_open_time1: collection_open_time1,
    collection_close_time1: collection_close_time1,
    collection_open_time2: collection_open_time2,
    collection_close_time2: collection_close_time2,
    collection_open_time3: collection_open_time3,
    collection_close_time3: collection_close_time3,
    open_delivery: open_delivery,
    open_collection: open_collection,
    open_delivery_2: open_delivery_2,
    open_collection_2: open_collection_2,
    open_delivery_3: open_delivery_3,
    open_collection_3: open_collection_3,
    IsOpenCloseStatus: is_open_close_status,
  });
};

export const createOpenCloseTimeService = async (
  login_key,
  user_id,
  shop_id,
  day_of_week,
  delivery_open_time1,
  delivery_close_time1,
  delivery_open_time2,
  delivery_close_time2,
  delivery_open_time3,
  delivery_close_time3,
  collection_open_time1,
  collection_close_time1,
  collection_open_time2,
  collection_close_time2,
  collection_open_time3,
  collection_close_time3,
  open_delivery,
  open_collection,
  open_delivery_2,
  open_collection_2,
  open_delivery_3,
  open_collection_3
) => {
  return await axios.post(`${baseUrl}createOpenCloseTime/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    day_of_week: day_of_week,
    delivery_open_time1: delivery_open_time1,
    delivery_close_time1: delivery_close_time1,
    delivery_open_time2: delivery_open_time2,
    delivery_close_time2: delivery_close_time2,
    delivery_open_time3: delivery_open_time3,
    delivery_close_time3: delivery_close_time3,
    collection_open_time1: collection_open_time1,
    collection_close_time1: collection_close_time1,
    collection_open_time2: collection_open_time2,
    collection_close_time2: collection_close_time2,
    collection_open_time3: collection_open_time3,
    collection_close_time3: collection_close_time3,
    open_delivery: open_delivery,
    open_collection: open_collection,
    open_delivery_2: open_delivery_2,
    open_collection_2: open_collection_2,
    open_delivery_3: open_delivery_3,
    open_collection_3: open_collection_3,
  });
};

export const getWebPaymentSettingService = async (
  login_key,
  user_id,
  shopId
) => {
  return await axios.put(`${baseUrl}getPaymentSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
  });
};
export const updateWebPaymentSettingService = async (
  login_key,
  user_id,
  shopId,
  id,
  delivery_payment_method_cash,
  delivery_payment_method_stripe,
  delivery_payment_method_pay_pal,
  delivery_payment_method_adyen,
  collection_patment_method_cash,
  collection_patment_method_stripe,
  collection_patment_method_pay_pal,
  collection_patment_method_adyen,
  credit_card_surcharge,
  credit_card_surcharge_label,
  credit_card_surcharge_visible
) => {
  return await axios.put(`${baseUrl}updatePaymentSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    Id: id,
    DeliveryPaymentMethodCash: delivery_payment_method_cash,
    DeliveryPaymentMethodStripe: delivery_payment_method_stripe,
    DeliveryPaymentMethodPayPal: delivery_payment_method_pay_pal,
    DeliveryPaymentMethodAdyen: delivery_payment_method_adyen,
    CollectionPaymentMethodCash: collection_patment_method_cash,
    CollectionPaymentMethodStripe: collection_patment_method_stripe,
    CollectionPaymentMethodPayPal: collection_patment_method_pay_pal,
    CollectionPaymentMethodAdyen: collection_patment_method_adyen,
    CreditCardSurcharge: credit_card_surcharge,
    CreditCardSurchargeLabel: credit_card_surcharge_label,
    CreditCardSurchargeVisible: credit_card_surcharge_visible,
  });
};

//STRIPE
export const getStripeListservice = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}getStripeSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const updateStripeservice = async (
  login_key,
  user_id,
  shop_id,
  id,
  secret_ley,
  publishable_key,
  account1,
  account2,
  active_day_of_acount1,
  active_day_of_acount2,
  webhook,
  ofOrder,
  statusPence,
  accountType,
  stripeMode
) => {
  return await axios.put(`${baseUrl}updateStripeSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    SecretKey: secret_ley,
    PublishableKey: publishable_key,
    Webhook: webhook,
    Account: account1,
    Account_2: account2,
    ActiveDayOfAccount: active_day_of_acount1,
    ActiveDayOfAccount_2: active_day_of_acount2,
    ApplicationFeePercent: ofOrder,
    ApplicationFeeStaticPence: statusPence,
    AccountType: accountType,
    StripeMode: stripeMode,
  });
};

// SHOP SETTING TIME GAP
export const getShopSettingTimeGapService = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}getshopsettingpublic/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const updateShopSettingTimeGapService = async (
  login_key,
  user_id,
  shop_id,
  normal_delivery,
  busy_delivery,
  normal_collection,
  busy_collection,
  expire_time,
  display_time_gap_in_app,
  active_time_gap,
  delivery_time_segmentation,
  collection_time_segmentantion
) => {
  return await axios.put(`${baseUrl}updateshopsettingtimegap/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    NormalDelivery: normal_delivery,
    BusyDelivery: busy_delivery,
    NormalCollection: normal_collection,
    BusyCollection: busy_collection,
    ExpireTime: expire_time,
    DisplayTimeGapInApp: display_time_gap_in_app,
    ActiveTimeGap: active_time_gap,
    DeliveryTimeSegmentation: delivery_time_segmentation,
    CollectionTimeSegmentation: collection_time_segmentantion,
  });
};

export const getWebDeliveryListServices = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}deliveryWebSettingGet/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const updateWebDeliveryServices = async (
  login_key,
  user_id,
  shop_id,
  id,
  web_min_order_value,
  web_min_order_charge_message,

  web_min_free_delivery
) => {
  return await axios.put(`${baseUrl}updateDeliveryWebSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    web_MinimumOrderValue: web_min_order_value,
    web_MinimumOrderChargeMessage: web_min_order_charge_message,

    web_minimum_free_delivery: web_min_free_delivery,
  });
};

//MAINTENANCE
export const getMaintenanceListServices = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}maintenanceGet/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const updateMaintenanceServices = async (
  login_key,
  user_id,
  shop_id,
  maintenance_mode,
  maintenance_message,
  forse_close_order,
  is_only_asap,
  close_order_message,
  show_asap,
  next_day_pree_order,
  pree_order,
  pree_order_message,
  maintenance_type,
  maintenance_open_shop_date
) => {
  return await axios.put(`${baseUrl}updateMaintenance/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    MaintenanceMode: maintenance_mode,
    MaintenanceMessage: maintenance_message,
    ForceCloseOrder: forse_close_order,
    IsOnlyASAP: is_only_asap,
    CloseOrderMessage: close_order_message,
    ShowASAP: show_asap,
    NextDayPreOrder: next_day_pree_order,
    PreOrder: pree_order,
    PreOrderMessage: pree_order_message,
    MaintenanceType: maintenance_type,
    MaintenanceOpenShopDate: maintenance_open_shop_date,
  });
};

export const getNotificationEmailSeevice = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}notificationEmailList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const createNotificationEmailService = async (
  login_key,
  user_id,
  shop_id,
  email
) => {
  return await axios.post(`${baseUrl}createNotificationEmail/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Email: email,
  });
};

export const updateNotificationEmailService = async (
  login_key,
  user_id,
  shop_id,
  email,
  id,
  active
) => {
  return await axios.put(`${baseUrl}updateNotificationEmail/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    Email: email,
    Active: active,
  });
};

export const deleteNotificationEmailService = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.put(`${baseUrl}deleteNotificationEmail/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
  });
};

//WEB PROMOTIONS
export const getPromotionsListService = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}promotionList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const createPromotionService = async (
  login_key,
  user_id,
  shop_id,
  title,
  description,
  start_date,
  end_date,
  day_of_week0,
  day_of_week1,
  day_of_week2,
  day_of_week3,
  day_of_week4,
  day_of_week5,
  day_of_week6,
  code,
  sale_method,
  rate,
  rate_option,
  min_order,
  status,
  excludes_meal_deals,
  uses_per_promotion,
  uses_per_customer,
  excluded_promotions,
  show_in_site
) => {
  return await axios.post(`${baseUrl}createPromotion/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Title: title,
    Description: description,
    StartDate: start_date,
    EndDate: end_date,
    DayOfWeek0: day_of_week0,
    DayOfWeek1: day_of_week1,
    DayOfWeek2: day_of_week2,
    DayOfWeek3: day_of_week3,
    DayOfWeek4: day_of_week4,
    DayOfWeek5: day_of_week5,
    DayOfWeek6: day_of_week6,
    Code: code,
    SaleMethod: sale_method,
    Rate: rate,
    RateOption: rate_option,
    MinOrder: min_order,
    Status: status,
    ExcludeMealDeals: excludes_meal_deals,
    UsesPerPromotion: uses_per_promotion,
    UsesPerCustomer: uses_per_customer,
    ExcludedPromotions: excluded_promotions,
    ShowInSite: show_in_site,
  });
};
export const updatePromotionService = async (
  login_key,
  user_id,
  shop_id,
  id,
  title,
  description,
  start_date,
  end_date,
  day_of_week0,
  day_of_week1,
  day_of_week2,
  day_of_week3,
  day_of_week4,
  day_of_week5,
  day_of_week6,
  code,
  sale_method,
  rate,
  rate_option,
  min_order,
  status,
  excludes_meal_deals,
  uses_per_promotion,
  uses_per_customer,
  excluded_promotions,
  show_in_site
) => {
  return await axios.put(`${baseUrl}updatePromotion/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    Title: title,
    Description: description,
    StartDate: start_date,
    EndDate: end_date,
    DayOfWeek0: day_of_week0,
    DayOfWeek1: day_of_week1,
    DayOfWeek2: day_of_week2,
    DayOfWeek3: day_of_week3,
    DayOfWeek4: day_of_week4,
    DayOfWeek5: day_of_week5,
    DayOfWeek6: day_of_week6,
    Code: code,
    SaleMethod: sale_method,
    Rate: rate,
    RateOption: rate_option,
    MinOrder: min_order,
    Status: status,
    ExcludeMealDeals: excludes_meal_deals,
    UsesPerPromotion: uses_per_promotion,
    UsesPerCustomer: uses_per_customer,
    ExcludedPromotions: excluded_promotions,
    ShowInSite: show_in_site,
  });
};
export const deletePromotionService = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.delete(`${baseUrl}deletePromotion/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      Id: id,
    },
  });
};

export const getAdyenListService = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}getPaymentAdyenSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const updateAdyenService = async (
  login_key,
  user_id,
  shop_id,
  id,
  webhook,
  api_key,
  account_id
) => {
  return await axios.put(`${baseUrl}updatePaymentAdyenSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    ApiKey: api_key,
    Webhook: webhook,
    Account: account_id,
  });
};

// META DATA
export const getMetaDataListService = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}metaList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const updateMetaDataService = async (
  login_key,
  user_id,
  shop_id,
  meta_title,
  meta_desc,
  meta_keywords
) => {
  return await axios.put(`${baseUrl}updateMeta/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    MetaTitle: meta_title,
    MetaDescription: meta_desc,
    MetaKey: meta_keywords,
  });
};
