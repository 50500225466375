import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  main_nav_button_color,
  primaryColor,
  white,
  main_nav_back_color,
} from "../constants/Colors";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import HeaderList from "./HeaderList";
import OrderListItem from "./OrderListItem ";
import { debit_filter } from "../constants/MainFinancialMenu";
import DatePicker from "react-datepicker";
import { getFinancialRefundListAction } from "../redux/financial/Actions";
import {
  Sale_method,
  financial_refund_sale_method,
} from "../constants/OrderStatus";
import Loader from "./Loader";
const RefundList = () => {
  const dispatch = useDispatch();
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const { payment_method, payment_method_id } = useSelector(
    (state) => state.categoryList
  );

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { loading, financial_refund_list, financial_debt_list } = useSelector(
    (state) => state.financialReduser
  );
  const [selectedShop, setSelectedShop] = useState({
    GroupId: 0,
    value: 999999,
    label: "All",
    selected: false,
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [debitFilter, setDebitFilter] = useState({
    label: "Debit",
    value: 2,
  });
  const [saleMethod, setSaleMethod] = useState({
    label: "Web delivery",
    value: 1000105,
  });
  const [selectedCourseKey, setSelectedCourseKey] = useState(0);
  const [financialOrderList1, setFinancialOrderList1] = useState([]);
  const [filteredFromAllItems, setFilteredFromAllItems] = useState(false);
  const [financialOrderList, setFinancialOrderList] = useState([]);
  const hanldeFilterDebit = (list, item) => {
    let my_list = [];
    let last_list = [];
    setDebitFilter(list);
    const copy_financialOrderList = [...financialOrderList1];
    if (list.value == 1) {
      my_list = copy_financialOrderList;
    } else if (list.value == 2) {
      if (copy_financialOrderList) {
        if (copy_financialOrderList.length > 0) {
          for (let i = 0; i < copy_financialOrderList.length; i++) {
            if (copy_financialOrderList[i].StatusId == 1) {
              my_list.push(copy_financialOrderList[i]);
            }
          }
        }
      }
    } else if (list.value == 3) {
      if (copy_financialOrderList) {
        if (copy_financialOrderList.length > 0) {
          for (let i = 0; i < copy_financialOrderList.length; i++) {
            if (copy_financialOrderList[i].StatusId == 3) {
              my_list.push(copy_financialOrderList[i]);
            }
          }
        }
      }
    }

    if (my_list) {
      if (my_list.length > 0) {
        for (let i = 0; i < my_list.length; i++) {
          if (saleMethod.value == 1) {
            last_list = my_list;
          } else {
            if (my_list[i].SaleMethodId == saleMethod.value) {
              last_list.push(my_list[i]);
            }
          }
        }
      }
    }
    setFinancialOrderList(last_list);
  };
  const handleFilterBySaleMethod = (list, item) => {
    setSaleMethod(list);
    let my_list = [];
    let last_list = [];
    setSaleMethod(list);

    const copy_financialOrderList = [...financialOrderList1];
    if (list.value == 1) {
      my_list = copy_financialOrderList;
    } else {
      if (copy_financialOrderList) {
        if (copy_financialOrderList.length > 0) {
          for (let i = 0; i < copy_financialOrderList.length; i++) {
            if (copy_financialOrderList[i].SaleMethodId == list.value) {
              my_list.push(copy_financialOrderList[i]);
            }
          }
        }
      }
    }

    if (my_list) {
      if (my_list.length > 0) {
        for (let i = 0; i < my_list.length; i++) {
          if (debitFilter.value == 1) {
            last_list = my_list;
          } else if (debitFilter.value == 2) {
            if (my_list[i].StatusId == 1) {
              last_list.push(my_list[i]);
            }
          } else if (debitFilter.value == 3) {
            if (my_list[i].StatusId == 3) {
              last_list.push(my_list[i]);
            }
          }
        }
      }
    }
    setFinancialOrderList(last_list);
  };
  // const handleGetList = () => {
  //   setDebitFilter({ label: "Debit", value: 2 });
  //   dispatch(
  //     getFinancialRefundListAction(
  //       login_key,
  //       user_id,
  //       selected_shop[0].ShopId,
  //       moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
  //       moment(endDate).format("YYYY-MM-DD hh:mm:ss")
  //     )
  //   );
  // };
  const handleGetRefundList = (key) => {
    setSelectedCourseKey(key);
    setDebitFilter({ label: "Debit", value: 2 });
    setSaleMethod({ label: "Web delivery", value: 1000105 });
    let date = new Date();
    let newDate = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
      newDate.setDate(newDate.getDate() - 1);
    }

    // console.log("Time:", hours + ":" + minutes + ":" + seconds);
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay();
    let decreased_days = day == 0 ? day + 6 : day - 1;
    let new_date = newDate;
    new_date.setDate(new_date.getDate() - decreased_days);

    let this_month =
      month < 9
        ? `${year}-0${month + 1}-01 ${hours + ":" + minutes + ":" + seconds} `
        : `${year}-${month + 1}-01 ${hours + ":" + minutes + ":" + seconds}`;

    if (key == 1) {
      setStartDate(new_date);
      setEndDate(date);
    } else if (key == 2) {
      setStartDate(new Date(this_month));
      setEndDate(date);
    }

    if (
      moment(startDate).format("YYYY-MM-DD HH:mm:ss") <=
      moment(endDate).format("YYYY-MM-DD HH:mm:ss")
    ) {
      if (selected_shop) {
        if (selected_shop.length > 0) {
          dispatch(
            getFinancialRefundListAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              key == 1
                ? moment(new_date).format("YYYY-MM-DD HH:mm:ss")
                : key == 2
                ? this_month
                : key == 3
                ? moment(startDate).format("YYYY-MM-DD HH:mm:ss")
                : "",
              key == 1
                ? moment(date).format("YYYY-MM-DD HH:mm:ss")
                : key == 2
                ? moment(date).format("YYYY-MM-DD HH:mm:ss")
                : key == 3
                ? moment(endDate).format("YYYY-MM-DD HH:mm:ss")
                : ""
            )
          );
        }
      }
    } else {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.start_date_end_date_in_order_list}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 5000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };
  useEffect(() => {
    setSaleMethod({ label: "Web delivery", value: 1000105 });
    let debt_list = [];
    let new_debt_list = [];
    let last_list = [];
    if (financial_debt_list) {
      if (financial_debt_list.length > 0) {
        for (let i = 0; i < financial_debt_list.length; i++) {
          debt_list.push({ ...financial_debt_list[i], show_send_box: false });
        }
        if (debt_list) {
          if (debt_list.length > 0) {
            for (let i = 0; i < debt_list.length; i++) {
              if (filteredFromAllItems) {
                setFilteredFromAllItems(false);
                new_debt_list = debt_list;
              } else {
                if (debt_list[i].StatusId == 1) {
                  new_debt_list.push(debt_list[i]);
                  setDebitFilter({ label: "Debit", value: 2 });
                }
              }
            }

            if (new_debt_list.length > 0) {
              for (let index = 0; index < new_debt_list.length; index++) {
                if (new_debt_list[index].SaleMethodId == saleMethod.value) {
                  last_list.push(new_debt_list[index]);
                }
              }
            }
          }
        }
        setFinancialOrderList(last_list);
        setFinancialOrderList1(debt_list);
      } else {
        setFinancialOrderList([]);
        setFinancialOrderList1([]);
      }
    } else {
      setFinancialOrderList([]);
      setFinancialOrderList1([]);
    }
  }, [financial_debt_list]);

  useEffect(() => {
    let date = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
    }
    setStartDate(date);
    setEndDate(date);

    if (login_key && user_id) {
      dispatch(
        getFinancialRefundListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          // moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
          // moment(endDate).format("YYYY-MM-DD hh:mm:ss")
          moment(date).format("YYYY-MM-DD HH:mm:ss"),
          moment(date).format("YYYY-MM-DD HH:mm:ss")
        )
      );
    }
  }, [selected_shop]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
        // backgroundColor: "red",
      }}
      id="countaining"
    >
      {" "}
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {" "}
          <div
            className="sm-div2"
            style={{
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            <div style={{ width: "15%" }}>
              <h6 className="h6">{lang.status}</h6>
              <Select
                options={debit_filter}
                defaultValue={debitFilter}
                isSearchable={false}
                onChange={(list, item) => hanldeFilterDebit(list, item)}
                value={debitFilter}
                isMulti={false}
                styles={customStyles}
                // className="select"
              />
            </div>
            <div style={{ width: "15%" }}>
              <h6 className="h6">{lang.sale_method}</h6>
              <Select
                options={financial_refund_sale_method}
                defaultValue={saleMethod}
                isSearchable={false}
                onChange={(list, item) => handleFilterBySaleMethod(list, item)}
                value={saleMethod}
                isMulti={false}
                styles={customStyles}
                // className="select"
              />
            </div>
            <div className="dashboard-refund-container-any-filter-section">
              <button
                style={{
                  backgroundColor: selectedCourseKey == 1 ? primaryColor : null,
                  color: selectedCourseKey == 1 ? "white" : null,
                  border: selectedCourseKey == 1 ? "none" : null,
                }}
                onClick={() => handleGetRefundList(1)}
              >
                {lang.week}
              </button>
              <button
                style={{
                  backgroundColor: selectedCourseKey == 2 ? primaryColor : null,
                  color: selectedCourseKey == 2 ? "white" : null,
                  border: selectedCourseKey == 2 ? "none" : null,
                }}
                onClick={() => handleGetRefundList(2)}
              >
                {lang.month}
              </button>
            </div>
            <div style={{ width: "16%", height: "40px" }}>
              <h6 className="h6">{lang.from}</h6>

              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(e) => setStartDate(e)}
                className="date_picker"
                calendarClassName="calendarClassName"
              />
            </div>
            <div style={{ width: "16%", height: "40px" }}>
              <h6 className="h6">{lang.to}</h6>

              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={endDate}
                onChange={(e) => setEndDate(e)}
                className="date_picker"
              />
            </div>
            <div
              style={{
                width: "13%",
                height: "40px",
                marginTop: "27px",
              }}
              // onClick={handleSearchOrderByDate}
            >
              <button
                style={{
                  border: `1px solid ${primaryColor}`,
                  backgroundColor: primaryColor,
                  width: "100%",
                  color: white,
                  height: "100%",
                  borderRadius: "2px",
                }}
                onClick={() => handleGetRefundList(3)}
              >
                {lang.result}
              </button>
            </div>

            {/* STICKY ADD SUBMENU BUTTON */}
          </div>
          <div className="debt-container-main" style={{ height: "100%" }}>
            <div>
              <HeaderList number={4} />
            </div>
            <div
              style={{
                flex: 1,
                overflowY: "auto",
              }}
            >
              {financialOrderList
                ? financialOrderList.length > 0
                  ? financialOrderList.map((item, index) => (
                      <OrderListItem
                        index1={index}
                        item={item}
                        order_id=""
                        invice_id=""
                        status=""
                        status_name=""
                        channels=""
                        requested_for={item.RefoundDate}
                        sale_mathod=""
                        Refound_Date={item.RefoundDate}
                        // store_name={item.Store_name}
                        RefoundNote={item.RefoundNote}
                        shop_name={item.ShopName}
                        post_code=""
                        order_amount={item.RefoundAmount.toFixed(2)}
                        payment_status=""
                        customer=""
                        sale_mathod_number=""
                        number={4}
                        dashboard_refund={true}
                        //   on_click1={() => handleSendDebt(item)}
                        //   show_send_box_onclick={() => handleShowSendBox(item)}
                        //   close_send_box_onclick={() => hanldeCancelSendDebt(item)}
                      />
                    ))
                  : null
                : null}{" "}
            </div>
            {/* <div className="debt-container-create-debt"></div> */}
          </div>
        </div>
        {loading ? <Loader /> : null}
      </div>
    </div>
  );
};

export default RefundList;
