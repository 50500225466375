import React from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  secondScreenImageDeletePopupAction,
  deleteSecondScreenEachPhotoAction,
} from "../../../../redux/settings/Action";
import { deleteGeneralSecondScreenImageAction } from "../../../../redux/devices/Actions";

const SecondScreenImageDeletePopup = () => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);

  const { selected_second_screen_image } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const { general_second_screen_create_popup } = useSelector(
    (state) => state.devicesWorkStationReduser
  );
  const handleDeleted = () => {
    if (general_second_screen_create_popup) {
      dispatch(
        deleteGeneralSecondScreenImageAction(
          login_key,
          user_id,
          selected_second_screen_image.Id,
          selected_second_screen_image.ImageUrl
        )
      );
    } else {
      dispatch(
        deleteSecondScreenEachPhotoAction(
          selected_second_screen_image.ShopId,
          login_key,
          user_id,
          selected_second_screen_image.Id,
          selected_second_screen_image.ImageUrl
        )
      );
    }
  };

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(secondScreenImageDeletePopupAction(false))}
    >
      <div
        className="delete_customer_popup_container "
        onClick={(i) => i.stopPropagation()}
      >
        <div className="delete_customer_popup_container_icon">
          <i
            class="fa fa-times"
            aria-hidden="true"
            onClick={() => dispatch(secondScreenImageDeletePopupAction(false))}
          ></i>
        </div>
        <div className="delete_customer_popup ">
          <h6 style={{ fontSize: "19px" }}>{lang.delete_message}</h6>
          <div
            className="menu-list-container-cancel-and-ok-button"
            // onClick={() => handleCancelDeleteBox(item)}
          >
            <button
              onClick={() =>
                dispatch(secondScreenImageDeletePopupAction(false))
              }
            >
              {lang.cancel}
            </button>
            <button onClick={() => handleDeleted()}>{lang.ok}</button>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default SecondScreenImageDeletePopup;
