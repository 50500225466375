import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import MenuListItem from "../../../../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";
import {
  getPaymentDojoListAction,
  selectedPaymentDojoSettingAction,
  deleteCodeDetailAction,
  deletePaymentDojoSettingAction,
} from "../../../../redux/settings/Action";
const DojoList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { get_payment_dojo_setting_list, selected_dojo_setting } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const [paymentDojoList, setPpaymentDojoList] = useState([]);
  const handleDeleted = (item) => {
    dispatch(
      deletePaymentDojoSettingAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        item.Id
      )
    );
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...paymentDojoList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setPpaymentDojoList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...paymentDojoList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setPpaymentDojoList(copy_list);
  };
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getPaymentDojoListAction(login_key, user_id, selected_shop[0].ShopId)
        );
      }
    }
  }, [selected_shop]);
  useEffect(() => {
    if (get_payment_dojo_setting_list) {
      if (get_payment_dojo_setting_list.length > 0) {
        setPpaymentDojoList(get_payment_dojo_setting_list);
      } else {
        setPpaymentDojoList([]);
      }
    } else {
      setPpaymentDojoList([]);
    }
  }, [get_payment_dojo_setting_list]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
    >
      <div className="menu_list_header_container">
        {" "}
        {paymentDojoList.length > 0 ? (
          <div>
            {paymentDojoList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={2}
                  field_1_title={item.Title}
                  field_2_title={`${lang.t_id} : ${item.TId}`}
                  show_status={true}
                  item_title={item.Title}
                  active={item.Active}
                  show_id={true}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => {
                    setClickedItem(2);
                    dispatch(selectedPaymentDojoSettingAction(item));
                  }}
                  show_delete={true}
                  show_edit_icon={false}
                  item={item}
                  number={0}
                  delete_click={() => handleDeleted(item)}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              style={{ minWidth: "250px" }}
              onClick={() => {
                setClickedItem(2);
                dispatch(selectedPaymentDojoSettingAction(null));
              }}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_dojo}</p>
            </span>
          </div>
        )}
      </div>
      {paymentDojoList.length > 0 ? (
        <div
          onClick={() => {
            setClickedItem(2);
            dispatch(selectedPaymentDojoSettingAction(null));
          }}
          className="div_submenu_add_container_fixed"
          style={{
            width: 250,
            right:
              (window.innerWidth * 2) / 245 +
              width +
              marginNormalMenuHorizontal,
          }}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          <p>{lang.create_dojo}</p>
        </div>
      ) : null}
    </div>
  );
};

export default DojoList;
