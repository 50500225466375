import React, { useEffect, useState } from "react";
import { main_nav_button_color } from "../../../constants/Colors";
import ListHeader from "../../../components/ListHeader";
import { useDispatch, useSelector } from "react-redux";
import DevicesHeaderList from "../../../components/DevicesHeaderList";
import DevicesListItem from "../../../components/DevicesListItem";
import { getLicenceListByWorkStaionIdAction } from "../../../redux/devices/Actions";
const ViewLicences = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    get_licence_list_by_workStaion_id,
    work_station_id,
    selectd_licence,
  } = useSelector((state) => state.devicesWorkStationReduser);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [licenenceList, setLicenceList] = useState([]);
  useEffect(() => {
    dispatch(
      getLicenceListByWorkStaionIdAction(
        login_key,
        user_id,
        selectd_licence.WorkStationId
      )
    );
  }, []);
 

  return (
    <div
      className="countaining-div common-styles"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
      id="countaining"
    >
        <ListHeader
          title={lang.licence_list}
          show_back={true}
          clickedItem={() => setClickedItem(1)}
        />
          <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div>
            <DevicesHeaderList button_key={false} licence_view={true} />
            {get_licence_list_by_workStaion_id.map((item, index) => (
              <div key={index}>
              <DevicesListItem
                expired={item.Expired}
                active={item.Active}
                reserve={item.Reserve}
                expire_date={item.ExpireDate}
                start_date={item.StartDate}
                licence_course={item.LicenceCourse}
                button_key={false}
                days_left={item.Daysleft}
                setClickedItem={setClickedItem}
                licence_view={true}
              /></div>
          ))} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLicences;
