import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import { Navigate, useNavigate } from "react-router-dom";

import { changeSelectedButtonBorderColorOnFocus } from "../redux/menu/Actions";
import ListHeader from "./ListHeader";
import MenuListItem from "./MenuListItem";
import { marginNormalMenuHorizontal } from "../constants/Sizes";
import { main_nav_button_color } from "../constants/Colors";
import { getUsersListAction, deleteUsersAction } from "../redux/Users/Actions";
import { shopListByGroupIdAction } from "../redux/Users/Actions";
import {
  selectedUsersOwnersAction,
  newShopListAction,
} from "../redux/Users/Actions";

const OwnersList = ({ setClickedItem }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_worksStations_list, worksStation_list } = useSelector(
    (state) => state.worksStationsReduser
  );
  const {
    dashboard_accessUser,
    dashboard_accessUser_id,
    dashboard_shop_group,
    dashboard_shop_group_id,
    change_selected_button_border_color_on_focus,
  } = useSelector((state) => state.categoryList);
  const { shop_list_by_group_id, shop_ids, selected_users_list_owners } =
    useSelector((state) => state.usersReduser);
  const { owners_list } = useSelector((state) => state.usersReduser);

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const [shopList, setShopList] = useState([]);
  const [ownersList, setOwnersList] = useState([]);

  const handleClickedItem = (item, key) => {
    setClickedItem(key);
    let DashboardAccessUserList = [];
    let DashboardShopGroupList = [];
    let DashboardShopIds = [];
    if (item.access) {
      for (let i = 0; i < item.access.length; i++) {
        if (item.access[i].AccessGroupId == "1") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 1) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 1,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "2") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 2) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 2,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "3") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 3) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 3,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "4") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 4) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 4,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "5") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 5) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 5,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "6") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 6) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 6,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "7") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 7) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 7,
              });
            }
          }
        }
      }
    }
    if (dashboard_shop_group_id.includes(parseInt(item.ShopGroupId))) {
      let index = dashboard_shop_group_id.indexOf(parseInt(item.ShopGroupId));
      DashboardShopGroupList = {
        value: dashboard_shop_group[index].value,
        label: dashboard_shop_group[index].label,
      };
    }
    if (shop_ids.includes(parseInt(item.ShopId))) {
      let index = shop_ids.indexOf(parseInt(item.ShopId));
      DashboardShopIds = {
        value: shopList[index].value,
        label: shopList[index].label,
      };
    }
    dispatch(
      selectedUsersOwnersAction({
        ...item,
        access: DashboardAccessUserList,
        ShopGroupId: DashboardShopGroupList,
        ShopId: DashboardShopIds,
      })
    );
  };

  const handleCreateItem = (key) => {
    setClickedItem(key);
    dispatch(selectedUsersOwnersAction(null));
  };
  const handleDeleteItem = (item) => {
    dispatch(deleteUsersAction(login_key, user_id, item.UserId, item.UserType));
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...ownersList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.UserId == copy_list[i].UserId) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setOwnersList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...ownersList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.UserId == copy_list[i].UserId) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setOwnersList(copy_list);
  };
  useEffect(() => {
    dispatch(getUsersListAction(login_key, user_id, 10001202));
  }, []);

  useEffect(() => {
    let owner_list = [];
    if (owners_list.length > 0) {
      for (let index = 0; index < owners_list.length; index++) {
        owner_list.push({
          ...owners_list[index],
          showDeleteBox: false,
        });
      }
      setOwnersList(owner_list);
    }
  }, [owners_list]);

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    dispatch(shopListByGroupIdAction(login_key, user_id, 0));
  }, []);

  useEffect(() => {
    let my_list = [];
    if (shop_list_by_group_id) {
      for (let i = 0; i < shop_list_by_group_id.length; i++) {
        my_list.push({
          label: shop_list_by_group_id[i].Title,
          value: shop_list_by_group_id[i].ShopId,
        });
      }
      setShopList(my_list);
      dispatch(newShopListAction(my_list));
    }
  }, [shop_list_by_group_id]);
  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  return (
    <div
      className="countaining-div"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <ListHeader title={lang.owners_list} show_back={false} />
      <div className="menu_list_header_container">
        {/* BACK & TITLE */}

        {ownersList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {ownersList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={item.UserName}
                  item_title={item.UserName}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleClickedItem(item, 2)}
                  show_delete={true}
                  delete_click={() => handleDeleteItem(item)}
                  show_edit_icon={false}
                  selected={selected_users_list_owners}
                  item={item}
                  number={0}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              style={{ width: "172px" }}
              onClick={() => handleCreateItem(2)}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_owner}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {ownersList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={() => handleCreateItem(2)}
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_owner}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OwnersList;
