import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import store from "store2";
import { primaryColor } from "../constants/Colors";
import { useSelector, useDispatch } from "react-redux";
import {
  resetCashOrderAction,
  showSetupPpopupAction,
} from "../redux/setup/Actions";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./Loader";
import { setDefaultAction } from "../redux/menu/Actions";
const SetupPopup = () => {
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { result_successfull, result_failed, result_message, setup_loading } =
    useSelector((state) => state.setupReduser);
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const loginPassword = store.get("password");

  const handleResetCashOrder = () => {
    if (password == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (loginPassword == password) {
      dispatch(
        resetCashOrderAction(login_key, user_id, selected_shop[0].ShopId)
      );
    } else {
      toast.error(<h5> &nbsp;&nbsp;{lang.incurrect_pssword}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  };

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
      dispatch(showSetupPpopupAction(false));
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => {
        dispatch(showSetupPpopupAction(false));
      }}
    >
      <div
        className="create_shop_popup_content_container"
        style={{
          zIndex: "1000",
          width: "25%",
          height: "40%",
        }}
        onClick={(i) => i.stopPropagation()}
      >
        <div
          className=" create-shop-close-div-post-code"
          style={{ border: "none" }}
        >
          <i
            onClick={() => {
              dispatch(showSetupPpopupAction(false));
            }}
            style={{ fontSize: 25 }}
            aria-hidden="true"
            className="fa fa-times post-code-close-icon"
          ></i>
          <p className="post-code-p">Enter password</p>
        </div>
        <div className="setup-popup-cotainer-body">
          <div>
            {" "}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mt: 5, display: "flex" },
              }}
              // className="menu-list-container-inputs"
              autoComplete="off"
            >
              <TextField
                autoComplete="0ff"
                type="password"
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                key={2}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                value={password}
                // defaultValue={amount}
                onChange={(e) => setPassword(e.target.value)}
                label={`* ${lang.pass}`}
                //   autoFocus={focusedInputKey == 2 ? true : false}
                //   onFocus={() => setFocusedInputKey(2)}
                focused
                className="refund-popup-textfield"
                // onKeyDown={() => handlePasswordKeyDown()}
              />
            </Box>
          </div>
          <div className="refund-popup-container-save-button">
            <button type="" onClick={() => handleResetCashOrder()}>
              {lang.apply}
            </button>
          </div>
        </div>
      </div>
      <Toaster />
      {setup_loading ? <Loader /> : null}
    </div>
  );
};

export default SetupPopup;
