import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../constants/Colors";
import {
  selectedUsersEmployeesAction,
  selectedUsersOwnersAction,
  selectedShopGroupAction,
} from "../redux/Users/Actions";
import { selectedDriverAction } from "../redux/Employees/Actions";
import { getUsersListAction } from "../redux/Users/Actions";
const UserSideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { users_menu } = useSelector((state) => state.selectedLanguage);

  const [userSideBarList, setUserSideBarList] = useState([]);
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);

  const handleCollapse = (item, myindex) => {
    if (item.id == 1) {
      dispatch(selectedUsersOwnersAction(null));
    } else if (item.id == 2) {
      dispatch(selectedUsersEmployeesAction(null));
    } else if (item.id == 3) {
      dispatch(selectedDriverAction(null));
    } else {
      dispatch(selectedShopGroupAction(null));
    }

    navigate(item.id == 1 ? "" : item.path);
    if (item.path) {
      setSelectedPath(item.path);
    }

    const copy_item = { ...item };
    const copy_userSideBarList = [...userSideBarList];
    copy_userSideBarList.forEach((element, index) => {
      if (myindex == index) {
        copy_item.expanded = !copy_item.expanded;
        copy_userSideBarList[myindex] = copy_item;
      } else {
        element.expanded = false;
        copy_userSideBarList[index] = element;
      }
    });
    //     if(item.title=="Employees"){
    // dispatch(getUsersListAction(login_key,10001203 ))
    //     }else{
    //       dispatch(getUsersListAction(login_key,10001202 ))
    //     }
    setUserSideBarList(copy_userSideBarList);

    if (!item.expanded) {
      setSelectedTitleIndex(myindex);
    } else {
      setSelectedTitleIndex(null);
    }
  };

  useEffect(() => {
    if (users_menu.length > 0) {
      setUserSideBarList(users_menu);
    }
  }, [users_menu]);

  return (
    <div className="user-sideBar-container-div">
      {userSideBarList.map((item, index) => (
        <div key={index}>
          <div
            className="user-sidebar-title"
            style={{
              backgroundColor:
                selectedTitleIndex == index ? main_nav_back_color : null,
              borderRightColor: primaryColor,
              borderRightStyle: "solid",
              borderRightWidth: selectedTitleIndex == index ? 4 : 0,
            }}
            onClick={() => handleCollapse(item, index)}
          >
            <div className="sidebar_title_icon_container sidebar_content_color">
              <i class={item.icon} aria-hidden="true"></i>
              <h6 className="m-0 sidebar_content_color">{item.title}</h6>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserSideBar;
