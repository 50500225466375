import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import useScrollbarSize from "react-scrollbar-size";
import ClipLoader from "react-spinners/ClipLoader";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import store from "store2";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../../../components/Loader";
import { primaryColor } from "../../../constants/Colors";
import { getShopsListAction } from "../../../redux/shop/Actions";

import {
  generalSecondScreenShopGroupListAction,
  showMainSecondScreenShopGroupPopupAction,
  updateGeneralSecondScreenShopGroupAction,
} from "../../../redux/devices/Actions";
import { listClasses } from "@mui/material";

const SecondScreenShopGroupPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useScrollbarSize();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { general_second_screen_shop_list } = useSelector(
    (state) => state.shopReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);

  const {
    general_second_screen_shop_group_list,
    loading_devices_worksStations,
  } = useSelector((state) => state.devicesWorkStationReduser);
  const {
    visible_cusom_charge,
    visible_code_detail,
    show_notification_email_popup,
    show_stripe_popup,
    show_second_screen_image_details_popup,
    second_screen_image_delete_popup,
  } = useSelector((state) => state.appSettingsRedusers);

  const lastItem =
    general_second_screen_shop_group_list[
      general_second_screen_shop_group_list.length - 1
    ];

  const [groupName, setGroupName] = useState("");
  const [sort, setSort] = useState("");
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [shopList, setShopList] = useState([]);
  const [shopList1, setShopList1] = useState([]);
  const [shopList2, setShopList2] = useState([]);
  const [shopList3, setShopList3] = useState([]);
  const [selectedShopIds, setSelectedShopIds] = useState([]);
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [radioButton, setRadioButton] = useState("all shops");
  const [searchInput, setSearchInput] = useState("");
  const [shopsIds, setShopsIds] = useState([]);
  const selectedShop = store.get("selectedShop");
  const loging_response = store.get("login_response");
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "grey",
      fontSize: "15px",
      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });

  const handleAddShop = () => {
    let shop_ids = [];
    const copy_shopList = [...shopList];
    if (copy_shopList) {
      if (copy_shopList.length > 0) {
        for (let i = 0; i < copy_shopList.length; i++) {
          if (copy_shopList[i].selected) {
            shop_ids.push(copy_shopList[i].ShopId);
          }
        }
      }
    }
    if (shop_ids.length > 0) {
      dispatch(
        updateGeneralSecondScreenShopGroupAction(
          login_key,
          user_id,
          1,
          shop_ids.toString()
        )
      );
    } else {
      toast.error(<h5> &nbsp;&nbsp;{lang.select_shop}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  };

  const handleSelectItem = (item) => {
    let copy_list = [...shopList1];
    for (let i = 0; i < copy_list.length; i++) {
      if (copy_list[i].ShopId == item.ShopId) {
        if (copy_list[i].selected) {
          copy_list[i].selected = false;
          let new_list = selectedShopIds.filter((i) => i != item.ShopId);

          setSelectedShopIds(new_list);
        } else {
          copy_list[i].selected = true;
          selectedShopIds.push(item.ShopId);
        }
      }
    }

    setShopList(copy_list);
    // setShopList1(copy_list);
    // setShopList2(copy_list);
  };

  const hanldeSerchInput = (e) => {
    setRadioButton("all shops");
    setSearchInput(e.target.value);
    const copy_shop_list = [...shopList2];

    let shop_list = [];
    let newList = [];
    let new_shops_list = [];
    const copy_shops_list = [...shopList1];
    if (e.target.value !== "") {
      newList = copy_shop_list.filter((item) => {
        let lc = item.SearchTitle.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });

      setShopList(newList);
      setShopList1(newList);
      setShopList3(newList);
    } else {
      setShopList3([]);
      setShopList(copy_shop_list);
      setShopList1(copy_shop_list);
    }
  };

  const handleCancel = () => {
    setShopList([]);
    setSelectedShopIds([]);
    dispatch(showMainSecondScreenShopGroupPopupAction(false));
  };

  const handleShowShops = (e) => {
    setRadioButton(e.target.value);
    let new_shops_list = [];
    const copy_shops_list = [...shopList2];
    // if (searchInput) {
    //   const copy_shops_list1 = [...shopList1];
    //   if (copy_shops_list1) {
    //     if (copy_shops_list1.length > 0) {
    //       for (let i = 0; i < copy_shops_list1.length; i++) {
    //         copy_shops_list.push(copy_shops_list1[i]);
    //       }
    //     }
    //   }
    // } else {
    //   const copy_shops_list2 = [...shopList2];
    //   if (copy_shops_list2) {
    //     if (copy_shops_list2.length > 0) {
    //       for (let i = 0; i < copy_shops_list2.length; i++) {
    //         copy_shops_list.push(copy_shops_list2[i]);
    //       }
    //     }
    //   }
    // }

    if (e.target.value == "all shops") {
      if (shopList3.length > 0) {
        for (let i = 0; i < shopList3.length; i++) {
          new_shops_list.push(shopList3[i]);
        }
      } else if (copy_shops_list) {
        if (copy_shops_list.length > 0) {
          for (let i = 0; i < copy_shops_list.length; i++) {
            new_shops_list.push(copy_shops_list[i]);
          }
        }
      }
    } else if (e.target.value == "selected shops") {
      if (shopList3.length > 0) {
        for (let i = 0; i < shopList3.length; i++) {
          if (shopList3[i].selected) {
            new_shops_list.push(shopList3[i]);
          }
        }
      } else if (copy_shops_list) {
        if (copy_shops_list.length > 0) {
          for (let index = 0; index < copy_shops_list.length; index++) {
            if (copy_shops_list[index].selected) {
              new_shops_list.push(copy_shops_list[index]);
            }
          }
        }
      }
    } else if (e.target.value == "unselected shops") {
      if (shopList3.length > 0) {
        for (let i = 0; i < shopList3.length; i++) {
          if (!shopList3[i].selected) {
            new_shops_list.push(shopList3[i]);
          }
        }
      } else if (copy_shops_list) {
        if (copy_shops_list.length > 0) {
          for (let index = 0; index < copy_shops_list.length; index++) {
            if (!copy_shops_list[index].selected) {
              new_shops_list.push(copy_shops_list[index]);
            }
          }
        }
      }
    }

    setShopList1(new_shops_list);
    setShopList(new_shops_list);
  };

  useEffect(() => {
    let shop_ids = [];
    if (general_second_screen_shop_list) {
      if (general_second_screen_shop_list.length > 0) {
        for (let i = 0; i < general_second_screen_shop_list.length; i++) {
          shop_ids.push(general_second_screen_shop_list[i].ShopId);
          setShopsIds(shop_ids);
        }
        setShopList(general_second_screen_shop_list);
        setShopList1(general_second_screen_shop_list);
        setShopList2(general_second_screen_shop_list);
        // setSelectedShopIds(selected_shop_ids);
      }
    }
  }, [general_second_screen_shop_list]);

  useEffect(() => {
    dispatch(generalSecondScreenShopGroupListAction(login_key, user_id));
  }, [show_second_screen_image_details_popup]);

  useEffect(() => {
    const copy_shopList = [...general_second_screen_shop_list];
    let shop_group_ids = [];
    if (general_second_screen_shop_group_list) {
      if (general_second_screen_shop_group_list.length > 0) {
        for (let i = 0; i < general_second_screen_shop_group_list.length; i++) {
          shop_group_ids =
            general_second_screen_shop_group_list[0].ShopId.split(",");
        }
      }
    }

    if (shop_group_ids.length > 0) {
      for (let i = 0; i < shop_group_ids.length; i++) {
        if (copy_shopList.length > 0) {
          for (let index = 0; index < copy_shopList.length; index++) {
            if (parseInt(shop_group_ids[i]) == copy_shopList[index].ShopId) {
              copy_shopList[index].selected = true;
            } else if (!shopsIds.includes(parseInt(shop_group_ids[i]))) {
              copy_shopList[index].selected = false;
            }
          }
        }
      }
      setShopList(copy_shopList);
    }
  }, [general_second_screen_shop_group_list]);

  return (
    <div
      className="sub_menu_list_popup_container"
      style={{ overflowY: "hidden" }}
      onClick={handleCancel}
    >
      <div
        className="submenu_popup_buttons_list_container product-submenu-list-popup-first-div"
        style={{
          height: "100%",
          marginTop: "25px",
          width: "55%",
        }}
        onClick={(i) => i.stopPropagation()}
      >
        <div className="shop-group-popup-header-div">
          <div className="shop-group-popup-conatiner-close-icon">
            <i
              onClick={handleCancel}
              style={{ fontSize: 27 }}
              aria-hidden="true"
              className="fa fa-times shop-group-close-icon"
            ></i>
            <h6 className="post-code-p">{lang.shop_group}</h6>
          </div>
          <div>
            {" "}
            <div className="shop-group-popup-container-search-box">
              <i class="fa fa-search" aria-hidden="true"></i>{" "}
              <input
                placeholder="Search by shop name , shop id"
                onChange={(e) => hanldeSerchInput(e)}
              />
            </div>
            <div className="shop-group-popup-container-radio_button">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      value="all shops"
                      checked={radioButton === "all shops"}
                      onChange={(e) => handleShowShops(e)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.all_shops}
                />
              </RadioGroup>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // className="shop-group-popup-radio-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      value="selected shops"
                      checked={radioButton === "selected shops"}
                      onChange={(e) => handleShowShops(e)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.selected_shops}
                />
              </RadioGroup>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // className="shop-group-popup-radio-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      value="unselected shops"
                      checked={radioButton === "unselected shops"}
                      onChange={(e) => handleShowShops(e)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.unselected_shops}
                />
              </RadioGroup>
            </div>
            {/* <div className="shop-group-popup-container-inputs-div">
              <div style={{ width: "50%" }}>
                <h6 className="h6">* {lang.group_name}</h6>
                <input
                  className="shop-group-popup-inputs"
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </div>
              <div
                className="shop-group-popup-sort-input-div"
                style={{ width: "50%" }}
              >
                <h6 className="h6 ">* {lang.sort_id}</h6>
                <input
                  className="shop-group-popup-inputs"
                  type="text"
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="shop_group_container_shops_list">
          <div className="shop_groups_container_boyh_lists">
            {shopList.map((item, index) =>
              index % 2 == 0 ? (
                <div key={index} onClick={() => handleSelectItem(item)}>
                  <div
                    className=" shop_group_container_any_shop"
                    style={{
                      backgroundColor: item.InProductSubmenu ? "#bdbdbd" : null,
                    }}
                  >
                    {" "}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.selected ? true : false}
                            // onChange={() => handleSelectItem(item)}
                            sx={{
                              "&.Mui-checked": {
                                color: primaryColor,
                              },
                            }}
                          />
                        }
                        // label={lang.incloud_half_and_half}
                      />
                    </FormGroup>
                    <p>
                      {item.ShopId} {item.Title}
                    </p>
                    {/* <button
                  onClick={() => handleSelectItem(item)}
                  style={{
                    backgroundColor: item.selected ? primaryColor : null,
                    color: item.selected ? "white" : null,
                  }}
                >
                  {lang.select}
                </button> */}
                  </div>
                </div>
              ) : null
            )}
          </div>
          <div className="shop_groups_container_boyh_lists">
            {shopList.map((item, index) =>
              index % 2 != 0 ? (
                <div key={index} onClick={() => handleSelectItem(item)}>
                  <div
                    className=" shop_group_container_any_shop"
                    style={{
                      backgroundColor: item.InProductSubmenu ? "#bdbdbd" : null,
                    }}
                  >
                    {" "}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.selected ? true : false}
                            // onChange={() => handleSelectItem(item)}
                            sx={{
                              "&.Mui-checked": {
                                color: primaryColor,
                              },
                            }}
                          />
                        }
                        // label={lang.incloud_half_and_half}
                      />
                    </FormGroup>
                    <p>
                      {item.ShopId} {item.Title}
                    </p>
                    {/* <button
                  onClick={() => handleSelectItem(item)}
                  style={{
                    backgroundColor: item.selected ? primaryColor : null,
                    color: item.selected ? "white" : null,
                  }}
                >
                  {lang.select}
                </button> */}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>

        <div className="shop_group_container_save_button">
          <button onClick={() => handleAddShop()} id="popup_add">
            {lang.save}
          </button>
        </div>
        {loading_devices_worksStations ? <Loader /> : null}
      </div>
      <Toaster />
    </div>
  );
};

export default SecondScreenShopGroupPopup;
