import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import {
  main_nav_button_color,
  white,
  primaryColor,
} from "../../../constants/Colors";
import FullScreen from "./FullScreen";
import HalfScreen from "./HalfScreen";
import { useDispatch } from "react-redux";
import { selectedScreenAction } from "../../../redux/devices/Actions";

const SecondScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickedItem, setClickedItem] = useState(2);
  const [selectedItemId, setSelectedItemId] = useState(1);

  const hendleItemClicked = (key, path) => {
    setSelectedItemId(key);
    navigate(path);
  };

  return (
    <div
      className="countaining-div common-styles"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
      id="countaining"
    >
      <div id="container" style={{ height: "100%" }}>
        <div className="second-screen-contaoner-fullscreen-and-halfscreen-header">
          <button
            key={1}
            onClick={() => {
              hendleItemClicked(1, "");
              dispatch(selectedScreenAction(1));
            }}
            style={{
              backgroundColor: selectedItemId == 1 ? primaryColor : white,
              color: selectedItemId == 1 ? white : main_nav_button_color,
              borderWidth: selectedItemId == 1 ? 0 : 0.3,
            }}
          >
            Full screen{" "}
          </button>
          <button
            key={2}
            onClick={() => {
              hendleItemClicked(2, "halfscreen");
              dispatch(selectedScreenAction(0));
            }}
            style={{
              backgroundColor: selectedItemId == 2 ? primaryColor : white,
              color: selectedItemId == 2 ? white : main_nav_button_color,
              borderWidth: selectedItemId == 2 ? 0 : 0.3,
            }}
          >
            Half screen
          </button>
        </div>

        <div className="margin_menu_normal_horizontal general-scond-screen">
          <div className="second-screen-contaoner-fullscreen-and-halfscreen-details">
            <Routes>
              <Route path="" element={<FullScreen />} />
              <Route path="halfscreen" element={<HalfScreen />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondScreen;
