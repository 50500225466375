import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const getCustomersListService = async (
  login_key,
  user_id,
  shop_id,
  pageNumber,
  search_text,
  search_type
) => {
  return await axios.put(`${baseUrl}customerList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    page_Number: pageNumber,
    txtSearch: search_text,
    SearchType: search_type,
  });
};
export const editCustomersListService = async (
  login_key,
  user_id,
  shop_id,
  customer_id,
  customer_address_id,
  first_name,
  last_name,
  email,
  mobile,
  address,
  post_code,
  delivery_charge
) => {
  return await axios.put(`${baseUrl}customerUpdate/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    CustomerId: customer_id,
    CustomerAddressId: customer_address_id,
    FirstName: first_name,
    LastName: last_name,
    Email: email,
    Mobile: mobile,
    Address: address,
    PostCode: post_code,
    DeliveryCharge: delivery_charge,
  });
};

export const deleteCustomersListService = async (
  login_key,
  user_id,
  shop_id,
  cutomer_id
) => {
  return await axios.put(`${baseUrl}customerDelete/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    CustomerId: cutomer_id,
  });
};

export const getCustomersExportListService = async (
  login_key,
  user_id,
  shop_id,
  search_text,
  search_type
) => {
  return await axios.put(`${baseUrl}customerExport/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    txtSearch: search_text,
    SearchType: search_type,
  });
};
