import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import validator from "validator";
import Select, { components } from "react-select";
import {
  main_nav_button_color,
  primaryColor,
  main_nav_back_color,
} from "../../../constants/Colors";
import ListHeader from "../../../components/ListHeader";
import SaveMenuButton from "../../../components/SaveMenuButton";
import {
  getShopDetailAction,
  updateShopAction,
} from "../../../redux/shop/Actions";
import { setDefaultAction } from "../../../redux/menu/Actions";
import Loader from "../../../components/Loader";
import { image_Url } from "../../../constants/BaseUrl";
import store from "store2";
const Shop = () => {
  const loging_response = store.get("login_response");
  const dispatch = useDispatch();

  const { selected_shop } = useSelector((state) => state.shopReducer);

  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    shop_detail_loading,
    result_successfull,
    result_failed,
    result_message,
    get_shop_detail,
  } = useSelector((state) => state.shopReducer);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const [title, setTitle] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [postCode, setPostCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [sendSmsHeader, setSendSmsHeader] = useState("");
  const [sendEmailHeader, setSendEmailHeader] = useState("");
  const [active, setActive] = useState(true);
  const [includeAndriodApp, setIncludAndriodApp] = useState(false);
  const [includeIosApp, setIncludeIosApp] = useState(false);
  const [logo, setLogo] = useState("");
  const [file, setFile] = useState("");
  const [lastLogoName, setLstLogoName] = useState("");
  const [emptyformData, setEmptyFormData] = useState(false);
  const [androidUrl, setAndroidUrl] = useState("");
  const [iosUrl, setIosUrl] = useState("");
  const [srcDimensions, setSrcDimensions] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  const [key1, setKey1] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [activityTypeIdsForSend, setActivityTypeIdsForSend] = useState([]);
  const [activityType, setActivityType] = useState([]);

  const imageUrl = file;
  // const CustomOption = (props) => {
  //   return (
  //     <components.Option {...props}>
  //       <input
  //         type="checkbox"
  //         checked={props.ShopId == selected_shop[0].ShopId}
  //         onChange={() => null}
  //       />{" "}
  //       <label>{props.label}</label>
  //     </components.Option>
  //   );
  // };
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const onSelectFile = (e) => {
    setLogo(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setFile(URL.createObjectURL(e.target.files[0]));
      setKey1(e);
      // reader.readAsDataURL(e.target.files[0]);
      // reader.addEventListener("load", () => setLogo(reader.result));
      // reader.readAsDataURL(e.target.files[0]);

      // const formData = new FormData();
      // //FILE INFO NAME WILL BE "my-image-file"
      // formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
      // setLogo(formData);
    }
  };

  const handleSelecetActivityType = (list, item) => {
    if (item.action == "select-option") {
      setActivityType(list);
      setActivityTypeIdsForSend(list);
    } else {
      if (list.length > 0) {
        setActivityType(list);
        setActivityTypeIdsForSend(list);
      } else {
        setActivityTypeIdsForSend(0);

        setActivityType(list);
      }
    }
    // if (item.action == "remove-value") {
    //   if (item.action == "clear")

    // }
  };

  const formData = new FormData();
  const handleUpdateShop = (e) => {
    let type_ids = [];
    if (activityTypeIdsForSend.length > 0) {
      for (let i = 0; i < activityTypeIdsForSend.length; i++) {
        type_ids.push(activityTypeIdsForSend[i].value);
      }
    }

    // e.preventDefault();

    if (
      title == "" ||
      userName == "" ||
      password == "" ||
      postCode == "" ||
      city == "" ||
      address == "" ||
      mobile == "" ||
      latitude == "" ||
      longitude == ""
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (!validator.isInt(String(mobile))) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.the_mobile_format_is_incorrect}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (!phoneNumber == "" && !validator.isInt(String(phoneNumber))) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{
            lang.the_phone_number_format_is_incorrect
          }&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (!faxNumber == "" && !validator.isInt(String(faxNumber))) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.the_fax_number_format_is_incorrect}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (logo.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.size_image}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
    // else if (srcDimensions.width != srcDimensions.height) {
    //   toast.error(
    //     <h5>
    //       {" "}
    //       &nbsp;&nbsp;{lang.verify_with_height_in_shopLogo}&nbsp;&nbsp;{" "}
    //     </h5>,
    //     {
    //       position: "top-center",
    //       duration: 3000,
    //       style: { backgroundColor: "red", color: "white" },
    //       icon: (
    //         <i
    //           style={{ fontSize: 40 }}
    //           className="fa fa-times-circle"
    //           aria-hidden="true"
    //         ></i>
    //       ),
    //     }
    //   );
    // }
    else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("ShopId", selected_shop[0].ShopId);
      formData.append("Title", title);
      formData.append("AdminId", 1);
      formData.append("UserName", userName);
      formData.append("Pass", password);
      formData.append("Mobile", mobile);
      formData.append("PhoneNumber", phoneNumber);
      formData.append("FaxNumber", faxNumber);
      formData.append("Address", address);
      formData.append("City", city);
      formData.append("Postcode", postCode);
      formData.append("IsActive", active ? 1 : 0);
      formData.append("IncludeAndroidApp", includeAndriodApp ? 1 : 0);
      formData.append("IncludeIOSApp", includeIosApp ? 1 : 0);
      formData.append("SendSmsHeader", sendSmsHeader);
      formData.append("SendEmailHeader", sendEmailHeader);
      formData.append("Logo", logo ? logo : "");
      formData.append("LastLogoName", lastLogoName ? lastLogoName : "");
      formData.append("AndroidUrl", androidUrl ? androidUrl : "");
      formData.append("IosUrl", iosUrl ? iosUrl : "");
      formData.append("DeleteImage", 0);
      formData.append("Latitude", latitude ? latitude : "");
      formData.append("Longitude", longitude ? longitude : "");
      formData.append(
        "ActivityType",
        !activityTypeIdsForSend ? 0 : type_ids ? type_ids.toString() : ""
      );
      dispatch(
        updateShopAction(
          formData,
          login_key,
          user_id,
          selected_shop[0].ShopId,
          title,
          1,
          userName,
          password,
          mobile,
          phoneNumber,
          faxNumber,
          address,
          city,
          postCode,
          active ? 1 : 0,
          includeAndriodApp ? 1 : 0,
          includeIosApp ? 1 : 0,
          sendSmsHeader,
          sendEmailHeader,
          logo ? logo : ""
        )
      );
    }
  };

  const handleDeleteImage = (key) => {
    setLogo("");
    setFile("");
    if (key1) {
      key1.target.value = null;
    }
    if (get_shop_detail[0].Logo != "") {
      if (`${image_Url}${baseUrl}/${get_shop_detail[0].Logo}` != file) {
        setFile(`${image_Url}${baseUrl}/${get_shop_detail[0].Logo}`);
      } else {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("ShopId", selected_shop[0].ShopId);
        formData.append("Title", title);
        formData.append("AdminId", 1);
        formData.append("UserName", userName);
        formData.append("Pass", password);
        formData.append("Mobile", mobile);
        formData.append("PhoneNumber", phoneNumber);
        formData.append("FaxNumber", faxNumber);
        formData.append("Address", address);
        formData.append("City", city);
        formData.append("Postcode", postCode);
        formData.append("IsActive", active ? 1 : 0);
        formData.append("IncludeAndroidApp", includeAndriodApp ? 1 : 0);
        formData.append("IncludeIOSApp", includeIosApp ? 1 : 0);
        formData.append("SendSmsHeader", sendSmsHeader);
        formData.append("SendEmailHeader", sendEmailHeader);
        formData.append("Logo", logo ? logo : "");
        formData.append("LastLogoName", lastLogoName ? lastLogoName : "");
        formData.append("AndroidUrl", androidUrl ? androidUrl : "");
        formData.append("IosUrl", iosUrl ? iosUrl : "");
        formData.append("DeleteImage", key);
        formData.append("Latitude", latitude ? latitude : "");
        formData.append("Longitude", longitude ? longitude : "");
        formData.append("ActivityType", "");
        dispatch(
          updateShopAction(
            formData,
            login_key,
            user_id,
            selected_shop[0].ShopId,
            title,
            1,
            userName,
            password,
            mobile,
            phoneNumber,
            faxNumber,
            address,
            city,
            postCode,
            active ? 1 : 0,
            includeAndriodApp ? 1 : 0,
            includeIosApp ? 1 : 0,
            sendSmsHeader,
            sendEmailHeader,
            logo ? logo : ""
          )
        );
      }
    }
  };

  useEffect(() => {
    if (selected_shop.length > 0) {
      const image_href = selected_shop[0].Domain;
      // const image_href = selected_shop[0].SubDomain;
      dispatch(
        getShopDetailAction(login_key, user_id, selected_shop[0].ShopId)
      );
      setBaseUrl(image_href);
    }
  }, [selected_shop]);

  useEffect(() => {
    let activity_type_list = [];

    let selected_types = [];
    if (get_shop_detail.length > 0) {
      if (get_shop_detail[0].activityType) {
        if (get_shop_detail[0].activityType.length > 0) {
          for (let i = 0; i < get_shop_detail[0].activityType.length; i++) {
            activity_type_list.push({
              ...get_shop_detail[0].activityType[i],
              label: get_shop_detail[0].activityType[i].Title,
              value: get_shop_detail[0].activityType[i].Id,
            });
            if (
              selected_shop[0].ShopId ==
              get_shop_detail[0].activityType[i].ShopId
            ) {
              selected_types.push({
                ...get_shop_detail[0].activityType[i],
                label: get_shop_detail[0].activityType[i].Title,
                value: get_shop_detail[0].activityType[i].Id,
              });
            }
          }
        }
      }

      setActivityType(selected_types);

      setActivityTypeList(activity_type_list);
      setTitle(get_shop_detail[0].Title);
      setUserName(get_shop_detail[0].UserName);
      setPassword(get_shop_detail[0].Pass);
      setMobile(get_shop_detail[0].Mobile);
      if (get_shop_detail[0].PhoneNumber) {
        setPhoneNumber(get_shop_detail[0].PhoneNumber);
      } else {
        setPhoneNumber("");
      }

      setFaxNumber(get_shop_detail[0].FaxNumber);
      setPostCode(get_shop_detail[0].Postcode);
      setAddress(get_shop_detail[0].Address);
      setCity(get_shop_detail[0].City);
      if (get_shop_detail[0].Latitude) {
        setLatitude(get_shop_detail[0].Latitude);
      } else {
        setLatitude("");
      }
      if (get_shop_detail[0].Longitude) {
        setLongitude(get_shop_detail[0].Longitude);
      } else {
        setLongitude("");
      }

      if (get_shop_detail[0].SendEmailHeader == null) {
        setSendEmailHeader("");
      } else {
        setSendEmailHeader(get_shop_detail[0].SendEmailHeader);
      }
      if (get_shop_detail[0].SendSmsHeader == null) {
        setSendSmsHeader("");
      } else {
        setSendSmsHeader(get_shop_detail[0].SendSmsHeader);
      }
      setActive(get_shop_detail[0].IsActive);
      setIncludAndriodApp(get_shop_detail[0].IncludeAndroidApp);
      setIncludeIosApp(get_shop_detail[0].IncludeIOSApp);
      if (get_shop_detail[0].Logo) {
        setFile(`${image_Url}${baseUrl}/${get_shop_detail[0].Logo}`);
      } else {
        setFile("");
      }

      // setFile(
      //   `${image_href}${700 + selected_shop[0].ShopId}/${
      //     get_shop_detail[0].Logo
      //   }`
      // );
      setLstLogoName(get_shop_detail[0].Logo);
      setIosUrl(get_shop_detail[0].IosUrl);
      setAndroidUrl(get_shop_detail[0].AndroidUrl);
    }
  }, [get_shop_detail]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleUpdateShop();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    title,
    userName,
    password,
    mobile,
    phoneNumber,
    faxNumber,
    address,
    city,
    postCode,
    active,
    includeAndriodApp,
    includeIosApp,
    sendSmsHeader,
    sendEmailHeader,
    logo ? logo : "",
  ]);

  useEffect(() => {
    if (result_successfull) {
      setEmptyFormData(!emptyformData);
      setLogo("");
    }
  }, [result_successfull]);

  useEffect(() => {
    formData.delete("LoginKey");
    formData.delete("UserId");
    formData.delete("ShopId");
    formData.delete("Title");
    formData.delete("AdminId");
    formData.delete("UserName");
    formData.delete("Pass");
    formData.delete("Mobile");
    formData.delete("PhoneNumber");
    formData.delete("FaxNumber");
    formData.delete("Address");
    formData.delete("City");
    formData.delete("Postcode");
    formData.delete("IsActive");
    formData.delete("IncludeAndroidApp");
    formData.delete("IncludeIOSApp");
    formData.delete("SendSmsHeader");
    formData.delete("SendEmailHeader");
    formData.delete("Logo");
    formData.delete("LastLogoName");
    formData.delete("AndroidUrl", "");
    formData.delete("IosUrl", "");
    formData.delete("DeleteImage");
  }, [emptyformData]);

  // useEffect(() => {
  //   if (selected_shop) {
  //     if (selected_shop.length > 0) {
  //       let base_url = `otopartnercentre.com:${
  //         700 + selected_shop[0].ShopId
  //       }/var/www/shop${selected_shop[0].ShopId}/`;
  //       setBaseUrl(base_url);
  //     }
  //   }
  // }, [selected_shop]);
  // console.log("aa", activityTypeList);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <ListHeader title={lang.Edit_shop} show_back={false} />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> * {lang.title}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                name="title"
                id="title"
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.user_name}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                name="user_name"
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.pass}</h6>
              <input
                type="password"
                className="ASOP-input-text input-padding"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.mobile}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={mobile}
                onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
                maxLength={11}
                name="mobile"
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.phone_number}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={phoneNumber}
                onChange={(e) =>
                  setPhoneNumber(e.target.value.replace(/\D/g, ""))
                }
                maxLength={11}
                name="phone_number"
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.vat_number}</h6>
              <input
                maxLength={11}
                className="ASOP-input-text input-padding"
                value={faxNumber}
                onChange={(e) =>
                  setFaxNumber(e.target.value.replace(/\D/g, ""))
                }
                name="fax_number"
              />
            </div>
          </div>{" "}
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.address}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="address"
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.city}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                name="city"
              />
            </div>{" "}
            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.post_code}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
                name="post_code"
              />
            </div>
          </div>{" "}
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.send_sms_header}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={sendSmsHeader}
                onChange={(e) => setSendSmsHeader(e.target.value)}
                name="send_sms_header"
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.send_email_header}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={sendEmailHeader}
                onChange={(e) => setSendEmailHeader(e.target.value)}
                name="send_imail_header"
              />
            </div>
          </div>{" "}
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.lat}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={latitude}
                onChange={(e) =>
                  setLatitude(e.target.value.replace(/[^\d\.,-]+/g, ""))
                }
                name="send_sms_header"
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.long}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={longitude}
                onChange={(e) =>
                  setLongitude(e.target.value.replace(/[^\d\.,-]+/g, ""))
                }
                name="send_imail_header"
              />
            </div>
          </div>{" "}
          <div className="ASOP-div1">
            <div
              className="div-chechbox-p ASOP-div1-input-text
          "
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={() => setActive(!active)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeAndriodApp}
                      onChange={() => setIncludAndriodApp(!includeAndriodApp)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.include_android_app}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeIosApp}
                      onChange={() => setIncludeIosApp(!includeIosApp)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.include_ios_app}
                />
              </FormGroup>
            </div>
          </div>
          <div className="ASOP-div1">
            {" "}
            <div>
              <h6 className="p-image h6">{lang.shop_logo}</h6>
              <form id="form">
                {" "}
                <div>
                  <input
                    // value={logo}
                    type="file"
                    accept="image/*"
                    onChange={(e) => onSelectFile(e)}
                    className="SIE-file-i"
                    name="Logo"
                  />
                </div>
                <div
                  className="SIE-image-item"
                  style={{ position: "relative" }}
                >
                  {file ? (
                    <div
                      className="container-image-delete-icon"
                      onClick={() => handleDeleteImage(1)}
                    >
                      <i
                        style={{
                          fontSize: "20px",
                          color: "red",
                          marginLeft: "1px",
                        }}
                        class="fa fa-times"
                        aria-hidden="true"
                      ></i>
                    </div>
                  ) : null}
                  <img
                    style={{ backgroundColor: "#F7F7F6" }}
                    src={
                      file
                        ? file
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                    }
                    className="images-style"
                    id="output"
                    onLoad={loadImage}
                  />
                </div>
                <p className="image-default-size">{lang.image_size}</p>
              </form>
            </div>{" "}
            <div style={{ width: "64.7%" }}>
              <h6 className=" h6">{lang.shop_activity_type}</h6>
              <Select
                options={activityTypeList}
                defaultValue={activityType}
                isSearchable={false}
                onChange={(list, item) => handleSelecetActivityType(list, item)}
                value={activityType}
                styles={customStyles}
                isClearable={true}
                isMulti
                // components={{ Option: CustomOption }}
              />
            </div>{" "}
          </div>{" "}
          <div className="ASOP-div1  "></div>
          {/* {shop_detail_loading ? <Loader /> : null} */}
        </div>
      </div>
      <Toaster />
      <SaveMenuButton clickButton={() => handleUpdateShop()} />
    </div>
  );
};

export default Shop;
