import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";

import { changeSelectedButtonBorderColorOnFocus } from "../redux/menu/Actions";
import ListHeader from "../components/ListHeader";
import MenuListItem from "../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../constants/Sizes";
import { main_nav_button_color } from "../constants/Colors";
import {
  getShopEmployersListAction,
  deleteShopEmployeesAction,
  selecteShopEmployees,
  createImployersListAction,
} from "../redux/Employees/Actions";
import { clickedItemAction } from "../redux/menu/Actions";
const EmployeesList = ({ setClickedItem, setCreate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useScrollbarSize();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { selected_shop_employees, imployees_list } = useSelector(
    (state) => state.employeesRedusers
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    gender,
    gender_id,
    job,
    job_id,
    device_access_group,
    device_access_group_id,
    change_selected_button_border_color_on_focus,
  } = useSelector((state) => state.categoryList);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { product_submenu_list } = useSelector((state) => state.productList);

  const [employeeList, setEmployeeList] = useState([]);
  const handleDeleteItem = (item) => {
    dispatch(
      deleteShopEmployeesAction(login_key, user_id, item.ShopId, item.Id)
    );
  };
  const handleClickedItem = (item, key) => {
    let new_gender = null;
    let group_list = null;
    let new_job = null;

    if (gender_id.includes(parseInt(item.GenderId))) {
      let index = gender_id.indexOf(parseInt(item.GenderId));
      new_gender = {
        value: gender[index].value,
        label: gender[index].label,
      };
    }

    if (job_id.includes(parseInt(item.Job))) {
      let index = job_id.indexOf(parseInt(item.Job));
      new_job = {
        value: job[index].value,
        label: job[index].label,
      };
    }
    if (device_access_group_id.includes(parseInt(item.GroupId))) {
      let index = device_access_group_id.indexOf(parseInt(item.GroupId));
      group_list = {
        value: device_access_group[index].value,
        label: device_access_group[index].label,
      };
    }
    setClickedItem(key);
    dispatch(
      selecteShopEmployees({
        ...item,
        GenderId: new_gender,
        Job: new_job,
        GroupId: group_list,
      })
    );
  };
  const handleCreateItem = (key) => {
    setClickedItem(key);
    dispatch(selecteShopEmployees(null));
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...employeeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setEmployeeList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...employeeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setEmployeeList(copy_list);
  };
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getShopEmployersListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);
  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);
  useEffect(() => {
    if (imployees_list) {
      if (imployees_list.length > 0) {
        setEmployeeList(imployees_list);
      } else {
        setEmployeeList([]);
      }
    } else {
      setEmployeeList([]);
    }
  }, [imployees_list]);

  return (
    <div
      className="countaining-div"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
    >
      <div className="menu_list_header_container">
        {/* BACK & TITLE */}

        {employeeList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {employeeList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={item.EmployeeName}
                  active={item.Active}
                  item_title={item.EmployeeName}
                  btn_number={2}
                  bt_2_title={lang.edit}
                  bt_1_title={lang.report}
                  bt_1_click={() => {
                    handleClickedItem(item, 3);
                    dispatch(clickedItemAction(7));
                  }}
                  bt_2_click={() => {
                    handleClickedItem(item, 2);
                    dispatch(clickedItemAction(10));
                  }}
                  show_delete={true}
                  delete_click={() => handleDeleteItem(item)}
                  show_edit_icon={false}
                  selected={selected_shop_employees}
                  item={item}
                  number={1}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                  show_status={true}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container" style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              onClick={() => handleCreateItem(2)}
              style={{ width: "160px" }}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_employee}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {employeeList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={() => handleCreateItem(2)}
            style={{
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_employee}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EmployeesList;
