import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListHeader from "../../../components/ListHeader";
import { main_nav_button_color } from "../../../constants/Colors";
import {
  getWorkStationMainButtonsListAction,
  selectedMainButtonAction,
  showWorkStationMainButtonsPopupAction,
} from "../../../redux/WorksStations/Actions";
import { image_Url } from "../../../constants/BaseUrl";
const MainButton = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_worksStations_list, work_station_main_button_list } =
    useSelector((state) => state.worksStationsReduser);
  const [mainButtonList, SetMainButtonList] = useState([]);
  const [enablesLength, setEnablesLength] = useState(0);
  const image_href1 = selected_shop[0].Domain;
  const hanldeEditSelectedMainButtonDetail = (item) => {
    dispatch(selectedMainButtonAction(item));
    dispatch(showWorkStationMainButtonsPopupAction(true));
  };
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getWorkStationMainButtonsListAction(
            selected_shop[0].ShopId,
            login_key,
            user_id,
            selected_worksStations_list.WorkStationId
          )
        );
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    let enables_list = [];
    if (work_station_main_button_list) {
      if (work_station_main_button_list.length > 0) {
        const sortArrey = work_station_main_button_list.sort(function (a, b) {
          return a.SortId - b.SortId;
        });
        SetMainButtonList(sortArrey);

        for (let i = 0; i < work_station_main_button_list.length; i++) {
          if (work_station_main_button_list[i].Enable) {
            enables_list.push(work_station_main_button_list[i]);
          }
        }

        setEnablesLength(enables_list.length);
      } else {
        SetMainButtonList([]);
      }
    } else {
      SetMainButtonList([]);
    }
  }, [work_station_main_button_list]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <div style={{ margin: "5px 0px 0px 10px" }}>
        <i className="fas fa-arrow-right" onClick={() => setClickedItem(1)}></i>
      </div>

      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div
            className="main-buttons-container"
            // style={{
            //   width: mainButtonList
            //     ? mainButtonList.length > 0 && mainButtonList.length < 10
            //       ? "48%"
            //       : mainButtonList.length > 9
            //       ? enablesLength <= 9
            //         ? "48%"
            //         : "65%"
            //       : null
            //     : null,
            // }}
          >
            {mainButtonList
              ? mainButtonList.length > 0
                ? mainButtonList.map((item, index) => (
                    <div
                      key={index}
                      className="main-buttons-container-any-button"
                      onClick={() => hanldeEditSelectedMainButtonDetail(item)}
                    >
                      <img
                        style={{
                          filter: !item.Enable
                            ? "brightness(.39)"
                            : "brightness(100%)",
                        }}
                        src={`${image_Url}${image_href1}/${item.ButtonImageUrl}`}
                      />
                      <h6>{item.ButtonName}</h6>
                    </div>
                  ))
                : null
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainButton;
