import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  getCategoriesSuccessfulAction,
  getCategoriesFailedAction,
  createCategorySuccessfulAction,
  createCategoryFailedAction,
  getDropDownSuccessfulAction,
  getDropDownFailedAction,
  selectedCategoryAction,
  sendCategorySettingsSuccessfulAction,
  sendCategorySettingsFailedAction,
  deleteCategorySuccessfulAction,
  deleteCategoryFailedAction,
  getCategoriesListAction,
  getCategoryOptionsListSuccessfulAction,
  getCategoryOptionsListFailedAction,
  createCategoryOptionSuccessfulAction,
  createCategoryOptionFailedAction,
  getCategoryOptionsListAction,
  sendCategoryOptionSuccessfulAction,
  sendCategoryOptionFailedAction,
  deleteCategoryOptionSuccessfulAction,
  deleteCategoryOptionFailedAction,
  getCategoryOptionCrustFailedAction,
  getCategoryOptionCrustSuccessfullAction,
  createCategoryOptionCrustFailedAction,
  createCategoryOptionCrustSuccessfullAction,
  getCategoryOptionCrustAction,
  deleteCategoryOptionCrustFailedAction,
  deleteCategoryOptionCrustSuccessfullAction,
  updateCategoryOptionCrustFailedAction,
  updateCategoryOptionCrustSuccessfullAction,
  hendleEmterKeyAction,
  newCategoryIdAction,
  createProductOpenCloseTimeFailedAction,
  createProductOpenCloseTimeSuccessfullAction,
  getProductOpenCloseListAction,
  getProductOpenCloseListFailedAction,
  getProductOpenCloseListSuccessfullAction,
  editCategoeyStatusFailedAction,
  editCategoryStatusSuccessfullAction,
  editProductStatusFailedAction,
  editProductStatusSuccessfullAction,
  getCategoryOpenCloseListAction,
  getCategoryOpenCloseListFailedAction,
  getCategoryOpenCloseListSuccessfullAction,
  createCategoryOpenCloseTimeFailedAction,
  createCategoryOpenCloseTimeSuccessfullAction,
  updateCategorySortFailedAction,
  updateCategorySortSuccessfullAction,
  updateCategoryAppSortFailedAction,
  updateCategoryAppSortSuccessfullAction,
  updateProductSortFailedAction,
  updateProductAppSortSuccessfullAction,
  updateProductSortSuccessfullAction,
  updateProductAppSortFailedAction,
  updateProductSubmenuAppSortFailedAction,
  updateProductSubmenuAppSortSuccessfullAction,
  updateProductSubmenuWebSortFailedAction,
  updateProductSubmenuWebSortSuccessfullAction,
  updateProductOptionWebSortSuccessfullAction,
  updateProductOptionWebSortFailedAction,
  updateProductOptionAppSortSuccessfullAction,
  updateProductOptionAppSortFailedAction,
} from "./Actions";
import {
  getProductAction,
  getProductOptionsAction,
  getProductSubmenuAction,
} from "../product/Action";
import {
  getCategoryServices,
  createCategoryServices,
  uploadImageServices,
  getDropdownServices,
  sendCategorySettingsServices,
  deleteCategoryServices,
  getCategoryOptionsServices,
  createCategoryOptionsServices,
  sendCategoryOptionsServices,
  deleteCategoryOptionsServices,
  getCategoryOptionCrustServices,
  createCategoryOptionCrustServices,
  deleteCategoryOptionCrustServices,
  updateCategoryOptionCrustServices,
  createProductOpenCloseTimeService,
  getProductOpenCloseListService,
  editCtaegoryStatusService,
  editProductStatusService,
  createCategoryOpenCloseTimeService,
  getCategoryOpenCloseListService,
  updateCategorySortService,
  updateCategoryAppSortService,
  updateProductSortService,
  updateProductAppSortService,
  updateProductSubmenuAppSortService,
  updateProductSubmenuWebSortService,
  updateProductOptionWebSortService,
  updateProductOptionAppSortService,
} from "../../services/CategorySevices";

function* getCategoriesList({ payload }) {
  try {
    const response = yield call(
      getCategoryServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Categorylist) {
          if (response.data.Categorylist.length > 0) {
            let my_list = [];
            let selectedCat;
            if (payload.cat_id) {
              for (let i = 0; i < response.data.Categorylist.length; i++) {
                if (response.data.Categorylist[i].Id == payload.cat_id) {
                  my_list.push({
                    ...response.data.Categorylist[i],
                    collapse: true,
                    showDeleteBox: false,
                    AppVisible: response.data.Categorylist[i].AppVisible
                      ? response.data.Categorylist[i].AppVisible.split(",")
                      : [],
                    KioskVisible: response.data.Categorylist[i].KioskVisible
                      ? response.data.Categorylist[i].KioskVisible.split(",")
                      : [],
                    WebVisible: response.data.Categorylist[i].WebVisible
                      ? response.data.Categorylist[i].WebVisible.split(",")
                      : [],
                    PrinterId: response.data.Categorylist[i].PrinterId
                      ? response.data.Categorylist[i].PrinterId.split(",")
                      : [],
                  });
                  selectedCat = {
                    ...response.data.Categorylist[i],
                    collapse: true,
                    showDeleteBox: false,
                    AppVisible: response.data.Categorylist[i].AppVisible
                      ? response.data.Categorylist[i].AppVisible.split(",")
                      : [],
                    KioskVisible: response.data.Categorylist[i].KioskVisible
                      ? response.data.Categorylist[i].KioskVisible.split(",")
                      : [],
                    WebVisible: response.data.Categorylist[i].WebVisible
                      ? response.data.Categorylist[i].WebVisible.split(",")
                      : [],
                    PrinterId: response.data.Categorylist[i].PrinterId
                      ? response.data.Categorylist[i].PrinterId.split(",")
                      : [],
                  };
                } else {
                  my_list.push({
                    ...response.data.Categorylist[i],
                    collapse: false,
                    showDeleteBox: false,
                    AppVisible: response.data.Categorylist[i].AppVisible
                      ? response.data.Categorylist[i].AppVisible.split(",")
                      : [],
                    KioskVisible: response.data.Categorylist[i].KioskVisible
                      ? response.data.Categorylist[i].KioskVisible.split(",")
                      : [],
                    WebVisible: response.data.Categorylist[i].WebVisible
                      ? response.data.Categorylist[i].WebVisible.split(",")
                      : [],
                    PrinterId: response.data.Categorylist[i].PrinterId
                      ? response.data.Categorylist[i].PrinterId.split(",")
                      : [],
                  });
                }
              }

              yield put(getCategoriesSuccessfulAction(my_list));

              yield put(selectedCategoryAction(selectedCat));
            } else {
              for (let i = 0; i < response.data.Categorylist.length; i++) {
                if (!response.data.Categorylist[i].IsDeleted) {
                  my_list.push({
                    ...response.data.Categorylist[i],
                    collapse: false,
                    showDeleteBox: false,
                    AppVisible: response.data.Categorylist[i].AppVisible
                      ? response.data.Categorylist[i].AppVisible.split(",")
                      : [],
                    KioskVisible: response.data.Categorylist[i].KioskVisible
                      ? response.data.Categorylist[i].KioskVisible.split(",")
                      : [],
                    WebVisible: response.data.Categorylist[i].WebVisible
                      ? response.data.Categorylist[i].WebVisible.split(",")
                      : [],
                    PrinterId: response.data.Categorylist[i].PrinterId
                      ? response.data.Categorylist[i].PrinterId.split(",")
                      : [],
                  });
                }
              }
              my_list[0].collapse = true;
              my_list[0].showDeleteBox = false;

              yield put(getCategoriesSuccessfulAction(my_list));
              yield put(selectedCategoryAction(my_list[0]));
            }
          } else {
            yield put(getCategoriesFailedAction(""));
          }
        } else {
          yield put(getCategoriesFailedAction(""));
        }
      } else {
        yield put(getCategoriesFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getCategoriesFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getCategoriesFailedAction(error.message));
  }
}
function* createCategory({ payload }) {
  try {
    const response = yield call(
      createCategoryServices,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.title
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(createCategorySuccessfulAction(response.data.ResultMessage));
        yield put(
          getCategoriesListAction(
            payload.login_key,
            payload.user_id,
            payload.id,
            response.data.Id
          )
        );
        yield put(newCategoryIdAction(response.data.Id));
      } else {
        yield put(createCategoryFailedAction("Server error, please try again"));
      }
    } else {
      yield put(createCategoryFailedAction("Server error, please try again"));
    }
  } catch (error) {
    yield put(createCategoryFailedAction(error.message));
  }
}
function* deleteCategory({ payload }) {
  try {
    const response = yield call(
      deleteCategoryServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.category_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(deleteCategorySuccessfulAction(response.data.ResultMessage));
        yield put(
          getCategoriesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            0
          )
        );
      } else {
        yield put(deleteCategoryFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteCategoryFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteCategoryFailedAction(error.message));
  }
}

function* getDropdown({ payload }) {
  try {
    const response = yield call(
      getDropdownServices,
      payload.login_key,
      payload.user_id,
      payload.id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        let all = [];
        let my_list = [];
        all[0] = { data: response.data.Printer };
        all[1] = { data: response.data.CategoryAvailable };
        all[2] = { data: response.data.FoodGroupSection };
        all[3] = { data: response.data.ProductGroup };
        all[4] = { data: response.data.Vat };
        all[5] = { data: response.data.FontFamily };
        all[6] = { data: response.data.FontSize };
        all[7] = { data: response.data.gender };
        all[8] = { data: response.data.job };
        all[9] = { data: response.data.DeviceType };
        all[10] = { data: response.data.licence_options };
        all[11] = { data: response.data.linkto };
        all[12] = { data: response.data.device_access_group };
        all[13] = { data: response.data.DashboardAccessUser };
        all[14] = { data: response.data.DashboardShopGroup };
        all[15] = { data: response.data.paymentmethod };
        all[16] = { data: response.data.DeliveryChargeType };
        all[17] = { data: response.data.order_status };
        all[18] = { data: response.data.callerId };
        all[19] = { data: response.data.second_language };

        // all[18]={data:response.data.}
        // if (response.data.length > 0) {
        //   let all = [];
        //   for (let i = 0; i < response.data.length; i++) {
        //     if (response.data[i].Id == 51) {
        //       all[0] = {
        //         id: response.data[i].Id,
        //         title: "Printer list",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 2) {
        //       all[1] = {
        //         id: response.data[i].Id,
        //         title: "Show Hide",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 6) {
        //       all[2] = {
        //         id: response.data[i].Id,
        //         title: "Report Section",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 5) {
        //       all[3] = {
        //         id: response.data[i].Id,
        //         title: "Product Group",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 50) {
        //       all[4] = {
        //         id: response.data[i].Id,
        //         title: "Vat",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 7) {
        //       all[5] = {
        //         id: response.data[i].Id,
        //         title: "Font Family",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 8) {
        //       all[6] = {
        //         id: response.data[i].Id,
        //         title: "Font Size",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 52) {
        //       all[7] = {
        //         id: response.data[i].Id,
        //         title: "Link To",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 9) {
        //       all[8] = {
        //         id: response.data[i].Id,
        //         title: "Gender",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 10) {
        //       all[9] = {
        //         id: response.data[i].Id,
        //         title: "Job",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 11) {
        //       all[10] = {
        //         id: response.data[i].Id,
        //         title: "DeviceType",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 53) {
        //       all[11] = {
        //         id: response.data[i].Id,
        //         title: "device_access_group",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 13) {
        //       all[12] = {
        //         id: response.data[i].Id,
        //         title: "licence options",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 54) {
        //       all[13] = {
        //         id: response.data[i].Id,
        //         title: "DashboardAccessUser",
        //         data: response.data[i].objectname,
        //       };
        //     } else if (response.data[i].Id == 55) {
        //       all[14] = {
        //         id: response.data[i].Id,
        //         title: "DashboardShopGroup",
        //         data: response.data[i].objectname,
        //       };
        //     }
        //   }

        yield put(getDropDownSuccessfulAction(all));
      } else {
        yield put(getDropDownFailedAction());
      }
    } else {
      yield put(getDropDownFailedAction());
    }
  } catch (error) {
    yield put(getDropDownFailedAction());
  }
}
function* sendCategorySettings({ payload }) {
  try {
    const response = yield call(
      sendCategorySettingsServices,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          sendCategorySettingsSuccessfulAction(response.data.ResultMessage)
        );
        yield put(
          getCategoriesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.id
          )
        );
      } else {
        yield put(
          sendCategorySettingsFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(sendCategorySettingsFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(sendCategorySettingsFailedAction(error.message));
  }
}

function* getCategoryOptionsList({ payload }) {
  try {
    const response = yield call(
      getCategoryOptionsServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.cat_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Optionslist) {
          if (response.data.Optionslist.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.Optionslist.length; i++) {
              if (!response.data.Optionslist[i].IsDeleted) {
                my_list.push({
                  ...response.data.Optionslist[i],
                  showEditBox: false,
                  showDeleteBox: false,
                  showCrusts: false,
                });
              }
            }
            yield put(getCategoryOptionsListSuccessfulAction(my_list));
          } else {
            yield put(getCategoryOptionsListFailedAction(""));
          }
        } else {
          yield put(getCategoryOptionsListFailedAction(""));
        }
      } else {
        yield put(
          getCategoryOptionsListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getCategoryOptionsListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getCategoryOptionsListFailedAction(error.message));
  }
}
function* createCategoryOption({ payload }) {
  try {
    const response = yield call(
      createCategoryOptionsServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.cat_id,
      payload.title,
      payload.desc,
      payload.amount
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getCategoryOptionsListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
        yield put(
          createCategoryOptionSuccessfulAction(response.data.ResultMessage)
        );
      } else {
        yield put(
          createCategoryOptionFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(createCategoryOptionFailedAction("Server error, try again"));
    }
  } catch (error) {
    yield put(createCategoryOptionFailedAction(error.message));
  }
}
function* sendCategoryOption({ payload }) {
  try {
    const response = yield call(
      sendCategoryOptionsServices,
      payload.login_key,
      payload.user_id,
      payload.option_id,
      payload.shop_id,
      payload.cat_id,
      payload.app_title,
      payload.short_name,
      payload.web_title,
      payload.web_desc,
      payload.kiosk_title,
      payload.kiosk_desc,
      payload.second_language,
      payload.multi_select_price,
      payload.price_level_main_1,
      payload.price_level_main_2,
      payload.price_level_main_3,
      payload.price_level_main_4,
      payload.price_level_main_5,
      payload.price_level_main_6,
      payload.price_level_main_7,
      payload.price_level_main_8,
      payload.price_level_collection_1,
      payload.price_level_collection_2,
      payload.price_level_collection_3,
      payload.price_level_collection_4,
      payload.price_level_collection_5,
      payload.price_level_collection_6,
      payload.price_level_collection_7,
      payload.price_level_collection_8,
      payload.price_level_eatin_1,
      payload.price_level_eatin_2,
      payload.price_level_eatin_3,
      payload.price_level_eatin_4,
      payload.price_level_eatin_5,
      payload.price_level_eatin_6,
      payload.price_level_eatin_7,
      payload.price_level_eatin_8,
      payload.vat,
      payload.visible_app,
      payload.visible_web,
      payload.visible_kiosk,
      payload.stock,
      payload.sort_app,
      payload.sort_web,
      payload.sort_kiosk,
      payload.font_size_app,
      payload.bg_color_app,
      payload.font_color_app,
      payload.just_title_and_price,
      payload.amount_delivery,
      payload.amount_collection,
      payload.amount_takeaway,
      payload.amount_eatIn
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getCategoryOptionsListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
        yield put(
          sendCategoryOptionSuccessfulAction(response.data.ResultMessage)
        );
      } else {
        yield put(sendCategoryOptionFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(sendCategoryOptionFailedAction("Server error, try again"));
    }
  } catch (error) {
    yield put(sendCategoryOptionFailedAction(error.message));
  }
}
function* deleteCategoryOption({ payload }) {
  try {
    const response = yield call(
      deleteCategoryOptionsServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.option_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getCategoryOptionsListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
        yield put(
          deleteCategoryOptionSuccessfulAction(response.data.ResultMessage)
        );
      } else {
        yield put(
          deleteCategoryOptionFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(deleteCategoryOptionFailedAction("Server error, try again"));
    }
  } catch (error) {
    yield put(deleteCategoryOptionFailedAction(error.message));
  }
}

function* getCategoryOptionCrust({ payload }) {
  try {
    const response = yield call(
      getCategoryOptionCrustServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.option_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Crustslist.length > 0) {
          let my_list = [];
          for (let i = 0; i < response.data.Crustslist.length; i++) {
            my_list.push({
              ...response.data.Crustslist[i],
              showEditBox: false,
              showDeleteBox: false,
            });
          }
          yield put(getCategoryOptionCrustSuccessfullAction(my_list));
        } else {
          yield put(getCategoryOptionCrustFailedAction());
        }
      } else {
        yield put(getCategoryOptionCrustFailedAction());
      }
    } else {
      yield put(getCategoryOptionCrustFailedAction());
    }
  } catch (error) {
    yield put(getCategoryOptionCrustFailedAction());
  }
}

function* createCategoryOptionCrust({ payload }) {
  try {
    const response = yield call(
      createCategoryOptionCrustServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.option_id,
      payload.app_title,
      payload.desc,
      payload.amount
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getCategoryOptionCrustAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.option_id
          )
        );

        yield put(
          createCategoryOptionCrustSuccessfullAction(
            response.data.ResultMessage
          )
        );
      } else {
        yield put(
          createCategoryOptionCrustFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        createCategoryOptionCrustFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(createCategoryOptionCrustFailedAction(error.message));
  }
}

function* deleteCategoryOptionCrust({ payload }) {
  try {
    const response = yield call(
      deleteCategoryOptionCrustServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.crust_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getCategoryOptionCrustAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.option_id
          )
        );
        yield put(
          deleteCategoryOptionCrustSuccessfullAction(
            response.data.ResultMessage
          )
        );
      } else {
        yield put(
          deleteCategoryOptionCrustFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        deleteCategoryOptionCrustFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(deleteCategoryOptionCrustFailedAction(error.message));
  }
}

function* updateCategoryOptionCrust({ payload }) {
  try {
    const response = yield call(
      updateCategoryOptionCrustServices,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shop_id,
      payload.option_id,
      payload.app_title,
      payload.web_title,
      payload.web_desc,
      payload.kiosk_title,
      payload.kiosk_desc,
      payload.second_language,
      payload.price,
      payload.app_visible,
      payload.web_visible,
      payload.kiosk_visible,
      payload.app_sort_id,
      payload.web_sort_id,
      payload.kiosk_sort_id,
      payload.app_bg_color,
      payload.app_font_color,
      payload.app_title_fontsize,
      payload.just_title_and_price
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getCategoryOptionCrustAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.option_id
          )
        );
        yield put(
          updateCategoryOptionCrustSuccessfullAction(
            response.data.ResultMessage
          )
        );
      } else {
        yield put(
          updateCategoryOptionCrustFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateCategoryOptionCrustFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateCategoryOptionCrustFailedAction(error.message));
  }
}
function* createProductOpenClose({ payload }) {
  try {
    const response = yield call(
      createProductOpenCloseTimeService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_id,
      payload.days_of_week
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createProductOpenCloseTimeSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getProductOpenCloseListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id
          )
        );
      } else {
        yield put(
          createProductOpenCloseTimeFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        createProductOpenCloseTimeFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(createProductOpenCloseTimeFailedAction(error.message));
  }
}
function* getProductOpenCloseList({ payload }) {
  try {
    const response = yield call(
      getProductOpenCloseListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.product_open_close_list) {
          if (response.data.product_open_close_list.length > 0) {
            yield put(
              getProductOpenCloseListSuccessfullAction(
                response.data.product_open_close_list
              )
            );
          } else {
            yield put(getProductOpenCloseListFailedAction(""));
          }
        } else {
          yield put(getProductOpenCloseListFailedAction(""));
        }
      } else {
        yield put(
          getProductOpenCloseListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getProductOpenCloseListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getProductOpenCloseListFailedAction(error.message));
  }
}
function* editCategoryStatus({ payload }) {
  try {
    const response = yield call(
      editCtaegoryStatusService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.cat_id,
      payload.cat_status
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          editCategoryStatusSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getCategoriesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
        yield put(
          getProductAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(editCategoeyStatusFailedAction(response.data.ResultMessage));
        yield put(
          getCategoriesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      }
    } else {
      yield put(editCategoeyStatusFailedAction(response.data.ResultMessage));
      yield put(
        getCategoriesListAction(
          payload.login_key,
          payload.user_id,
          payload.shop_id,
          payload.cat_id
        )
      );
    }
  } catch (error) {
    yield put(editCategoeyStatusFailedAction(error.message));
    yield put(
      getCategoriesListAction(
        payload.login_key,
        payload.user_id,
        payload.shop_id,
        payload.cat_id
      )
    );
  }
}
function* editProductStatus({ payload }) {
  try {
    const response = yield call(
      editProductStatusService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_status,
      payload.product_id,
      payload.cat_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          editProductStatusSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getProductAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(editProductStatusFailedAction(response.data.ResultMessage));
        // yield put(
        //   getProductAction(
        //     payload.login_key,
        //     payload.user_id,
        //     payload.shop_id,
        //     payload.cat_id
        //   )
        // );
      }
    } else {
      yield put(editProductStatusFailedAction(response.data.ResultMessage));
      // yield put(
      //   getProductAction(
      //     payload.login_key,
      //     payload.user_id,
      //     payload.shop_id,
      //     payload.cat_id
      //   )
      // );
    }
  } catch (error) {
    yield put(editProductStatusFailedAction(error.message));
    // yield put(
    //   getProductAction(
    //     payload.login_key,
    //     payload.user_id,
    //     payload.shop_id,
    //     payload.cat_id
    //   )
    // );
  }
}
function* createCategoryOpenClose({ payload }) {
  try {
    const response = yield call(
      createCategoryOpenCloseTimeService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.cat_id,
      payload.days_of_week
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createCategoryOpenCloseTimeSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getCategoryOpenCloseListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(
          createCategoryOpenCloseTimeFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        createCategoryOpenCloseTimeFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(createCategoryOpenCloseTimeFailedAction(error.message));
  }
}

function* getCategoryOpenCloseList({ payload }) {
  try {
    const response = yield call(
      getCategoryOpenCloseListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.cat_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.category_open_close_list) {
          if (response.data.category_open_close_list.length > 0) {
            yield put(
              getCategoryOpenCloseListSuccessfullAction(
                response.data.category_open_close_list
              )
            );
          } else {
            yield put(getCategoryOpenCloseListFailedAction(""));
          }
        } else {
          yield put(getCategoryOpenCloseListFailedAction(""));
        }
      } else {
        yield put(
          getCategoryOpenCloseListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getCategoryOpenCloseListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getCategoryOpenCloseListFailedAction(error.message));
  }
}

function* updateCategorySort({ payload }) {
  try {
    const response = yield call(
      updateCategorySortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.cat_id,
      payload.old_sort_id,
      payload.new_sort_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateCategorySortSuccessfullAction("Done"));
        yield put(
          getCategoriesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(updateCategorySortFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateCategorySortFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateCategorySortFailedAction(error.message));
  }
}
function* updateCategoryAppSort({ payload }) {
  try {
    const response = yield call(
      updateCategoryAppSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.cat_id,
      payload.old_sort_id,
      payload.new_sort_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateCategoryAppSortSuccessfullAction("Done"));
        yield put(
          getCategoriesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(
          updateCategoryAppSortFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateCategoryAppSortFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateCategoryAppSortFailedAction(error.message));
  }
}
// UPDATE PRODUCT SORT
function* updateProductSort({ payload }) {
  try {
    const response = yield call(
      updateProductSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_id,
      payload.old_sort_id,
      payload.new_sort_id,
      payload.cat_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateProductSortSuccessfullAction("Done"));
        yield put(
          getProductAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(updateProductSortFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateProductSortFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateProductSortFailedAction(error.message));
  }
}
function* updateProductAppSort({ payload }) {
  try {
    const response = yield call(
      updateProductAppSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_id,
      payload.old_sort_id,
      payload.new_sort_id,
      payload.cat_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateProductAppSortSuccessfullAction("Done"));
        yield put(
          getProductAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(
          updateProductAppSortFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateProductAppSortFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateProductAppSortFailedAction(error.message));
  }
}
//UPDATE PRODUCT SUBMENU SORT
function* updateProductSubmenuWebSort({ payload }) {
  try {
    const response = yield call(
      updateProductSubmenuWebSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_submenu_id,
      payload.old_sort_id,
      payload.new_sort_id,
      payload.product_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateProductSubmenuWebSortSuccessfullAction("Done"));
        yield put(
          getProductSubmenuAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id
          )
        );
      } else {
        yield put(
          updateProductSubmenuWebSortFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateProductSubmenuWebSortFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateProductSubmenuWebSortFailedAction(error.message));
  }
}
function* updateProductSubmenuAppSort({ payload }) {
  try {
    const response = yield call(
      updateProductSubmenuAppSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_submenu_id,
      payload.old_sort_id,
      payload.new_sort_id,
      payload.product_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateProductSubmenuAppSortSuccessfullAction("Done"));
        yield put(
          getProductSubmenuAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id
          )
        );
      } else {
        yield put(
          updateProductSubmenuAppSortFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateProductSubmenuAppSortFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateProductSubmenuAppSortFailedAction(error.message));
  }
}
//UPDATE PRODUCT OPTION SORT
function* updateProductOptionWebSort({ payload }) {
  try {
    const response = yield call(
      updateProductOptionWebSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_option_id,
      payload.old_sort_id,
      payload.new_sort_id,
      payload.product_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateProductOptionWebSortSuccessfullAction("Done"));
        yield put(
          getProductOptionsAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id
          )
        );
      } else {
        yield put(
          updateProductOptionWebSortFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateProductOptionWebSortFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateProductOptionWebSortFailedAction(error.message));
  }
}
function* updateProductOptionAppSort({ payload }) {
  try {
    const response = yield call(
      updateProductOptionAppSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_option_id,
      payload.old_sort_id,
      payload.new_sort_id,
      payload.product_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateProductOptionAppSortSuccessfullAction("Done"));
        yield put(
          getProductOptionsAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id
          )
        );
      } else {
        yield put(
          updateProductOptionAppSortFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateProductOptionAppSortFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateProductOptionAppSortFailedAction(error.message));
  }
}
export function* watchUpdateProductOptionAppSort() {
  yield takeLatest(
    action_types.UPDATE_PRODUCT_OPTION_APP_SORT,
    updateProductOptionAppSort
  );
}
export function* watchUpdateProductOptionWebSort() {
  yield takeLatest(
    action_types.UPDATE_PRODUCT_OPTION_WEB_SORT,
    updateProductOptionWebSort
  );
}
export function* watchUpdateProductSubmenuAppSort() {
  yield takeLatest(
    action_types.UPDATE_PRODUCT_SUBMENU_APP_SORT,
    updateProductSubmenuAppSort
  );
}
export function* watchUpdateProductSubmenuWebSort() {
  yield takeLatest(
    action_types.UPDATE_PRODUCT_SUBMENU_WEB_SORT,
    updateProductSubmenuWebSort
  );
}
export function* watchUpdateProductAppSort() {
  yield takeLatest(action_types.UPDATE_PRODUCT_APP_SORT, updateProductAppSort);
}
export function* watchUpdateProductSort() {
  yield takeLatest(action_types.UPDATE_PRODUCT_SORT, updateProductSort);
}
export function* watchUpdateCategoryAppSort() {
  yield takeLatest(
    action_types.UPDATE_CATEGORY_APP_SORT,
    updateCategoryAppSort
  );
}
export function* watchUpdateCategorySort() {
  yield takeLatest(action_types.UPDATE_CATEGORY_SORT, updateCategorySort);
}
export function* watchEditProductStatus() {
  yield takeLatest(action_types.EDIT_PRODUCT_STATUS, editProductStatus);
}
export function* watchEditCategoryStatus() {
  yield takeLatest(action_types.EDIT_CATEGORY_STATUS, editCategoryStatus);
}
export function* watchGetCategoryOpenCloseTimeList() {
  yield takeLatest(
    action_types.GET_CATEGORY_OPEN_CLOSE_LIST,
    getCategoryOpenCloseList
  );
}
export function* watchCreateCategoryOpenCloseTime() {
  yield takeLatest(
    action_types.CREATE_CATEGORY_OPEN_CLOSE_TIME,
    createCategoryOpenClose
  );
}

export function* watchGetProductOpenCloseTimeList() {
  yield takeLatest(
    action_types.GET_PRODUCT_OPEN_CLOSE_LIST,
    getProductOpenCloseList
  );
}
export function* watchCreateProductOpenCloseTime() {
  yield takeLatest(
    action_types.CREATE_PRODUCT_OPEN_CLOSE_TIME,
    createProductOpenClose
  );
}
export function* watchUpdateCategoryOptionCrust() {
  yield takeLatest(
    action_types.UPDATE_CATEGORY_OPTION_CRUST,
    updateCategoryOptionCrust
  );
}

export function* watchDeleteCategoryOptionCrust() {
  yield takeLatest(
    action_types.DELETE_CATEGORY_OPTION_CRUST,
    deleteCategoryOptionCrust
  );
}
export function* watchCreateCategoryOptionCrust() {
  yield takeLatest(
    action_types.CREATE_CATEGORY_OPTION_CRUST,
    createCategoryOptionCrust
  );
}

export function* watchGetCategoryOptionCrust() {
  yield takeLatest(
    action_types.GET_CATEGORY_OPTION_CRUST,
    getCategoryOptionCrust
  );
}
export function* watchGetCategories() {
  yield takeLatest(action_types.GET_CATEGORY_SAGA, getCategoriesList);
}
export function* watchCreateCategory() {
  yield takeLatest(action_types.CREATE_CATEGORY_SAGA, createCategory);
}
export function* watchGetDropdown() {
  yield takeLatest(action_types.GET_DROPDOWN, getDropdown);
}
export function* watchSendCategorySettings() {
  yield takeLatest(action_types.SEND_CATEGORY_SETTINGS, sendCategorySettings);
}
export function* watchDeleteCategory() {
  yield takeLatest(action_types.DELETE_CATEGORY, deleteCategory);
}
export function* watchGetCategoryOptionsList() {
  yield takeLatest(action_types.GET_CATEGORY_OPTIONS, getCategoryOptionsList);
}
export function* watchCreateCategoryOption() {
  yield takeLatest(action_types.CREATE_CATEGORY_OPTION, createCategoryOption);
}
export function* watchSendCategoryOption() {
  yield takeLatest(action_types.SEND_CATEGORY_OPTION, sendCategoryOption);
}
export function* watchDeleteCategoryOption() {
  yield takeLatest(action_types.DELETE_CATEGORY_OPTION, deleteCategoryOption);
}
export default function* rootMenu() {
  yield all([
    fork(watchGetCategories),
    fork(watchCreateCategory),
    fork(watchGetDropdown),
    fork(watchSendCategorySettings),
    fork(watchDeleteCategory),
    fork(watchGetCategoryOptionsList),
    fork(watchCreateCategoryOption),
    fork(watchSendCategoryOption),
    fork(watchDeleteCategoryOption),
    fork(watchGetCategoryOptionCrust),
    fork(watchCreateCategoryOptionCrust),
    fork(watchDeleteCategoryOptionCrust),
    fork(watchUpdateCategoryOptionCrust),
    fork(watchCreateProductOpenCloseTime),
    fork(watchGetProductOpenCloseTimeList),
    fork(watchEditCategoryStatus),
    fork(watchEditProductStatus),
    fork(watchGetCategoryOpenCloseTimeList),
    fork(watchCreateCategoryOpenCloseTime),
    fork(watchUpdateCategorySort),
    fork(watchUpdateCategoryAppSort),
    fork(watchUpdateProductSort),
    fork(watchUpdateProductAppSort),
    fork(watchUpdateProductSubmenuWebSort),
    fork(watchUpdateProductSubmenuAppSort),
    fork(watchUpdateProductOptionAppSort),
    fork(watchUpdateProductOptionWebSort),
  ]);
}
