import * as actionTypes from "../ActionTypes";

const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_post_code: false,
  tabel_area_list: [],
  selected_tabel: null,
  get_tabel_area_list_loading: false,
  selected_area: null,
  tabels_list: [],
  visible_post_code_popup: false,
  allowed_post_code_list: [],
  loading_tabel_area: false,
  selected_allowed_post_code: null,
  vat_list: [],
  visible_vat_popup: false,
  selected_vat_list: null,
  half_and_half_list: [],
  cariier_bag_list: [],
  show_add_table_popup: false,
  short_cut_button_list: [],
  about_us_list: [],
  by_area_list: [],
  selected_delivery_by_area: null,
  show_create_delivery_by_area_popup: false,
  popup_loading: false,
  delivery_area_map_list: [],
  selected_delivery_area_map: null,
  is_created: false,
  default_map_center: null,
  post_code_fof_list: [],
  selected_post_code_fof: null,
};

const tabelAreaRedusers = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_tabel_area: false,
        get_tabel_area_list_loading: false,
        loading_post_code: false,
        popup_loading: false,
        is_created: false,
      };
    case actionTypes.GET_TABEL_AREA:
      return {
        ...state,
        get_tabel_area_list_loading: true,
      };
    case actionTypes.GET_TABEL_AREA_SUCCESSFULL:
      return {
        ...state,
        get_tabel_area_list_loading: false,
        tabel_area_list: action.payload,
      };
    case actionTypes.GET_TABEL_AREA_FAILED:
      return {
        ...state,
        get_tabel_area_list_loading: false,
        tabel_area_list: [],
      };
    case actionTypes.SELECTED_TABEL:
      return {
        ...state,
        selected_tabel: action.payload,
      };
    case actionTypes.SHOW_ADD_TABLE_POPUP:
      return {
        ...state,
        show_add_table_popup: action.payload,
      };
    case actionTypes.CREATE_AREA:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_tabel_area: true,
      };
    case actionTypes.CREATE_AREA_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_tabel_area: false,
      };
    case actionTypes.CREATE_AREA_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_tabel_area: false,
      };

    case actionTypes.CREATE_TABEL:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_tabel_area: true,
      };
    case actionTypes.CREATE_TABEL_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_tabel_area: false,
        show_add_table_popup: false,
      };
    case actionTypes.CREATE_TABEL_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_tabel_area: false,
      };

    case actionTypes.UPDATE_AREA:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_tabel_area: true,
      };
    case actionTypes.UPDATE_AREA_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_tabel_area: false,
      };
    case actionTypes.UPDATE_AREA_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_tabel_area: false,
      };
    case actionTypes.UPDATE_TABEL:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_tabel_area: true,
      };
    case actionTypes.UPDATE_TABEL_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_tabel_area: false,
      };
    case actionTypes.UPDATE_TABEL_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_tabel_area: false,
      };
    case actionTypes.SELECTTED_AREA:
      return {
        ...state,
        selected_area: action.payload,
        tabels_list: action.payload ? action.payload.Tables : [],
      };
    case actionTypes.SELECTTED_AREA_EMPTY:
      return {
        ...state,
        selected_area: action.payload,
      };

    case actionTypes.VISIBLE_POST_CODE_POPUP:
      return {
        ...state,
        visible_post_code_popup: action.payload,
      };
    case actionTypes.ALLOWE_POST_CODE_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.ALLOWE_POST_CODE_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        allowed_post_code_list: action.payload,
      };
    case actionTypes.ALLOWE_POST_CODE_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        allowed_post_code_list: [],
      };
    case actionTypes.CREATE_ALLOWED_POST_CODE:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_post_code: true,
      };
    case actionTypes.CREATE_ALLOWED_POST_CODE_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_post_code: false,
        visible_post_code_popup: false,
      };
    case actionTypes.CREATE_ALLOWED_POST_CODE_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.UPDATE_ALLOWED_POST_CODE:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_post_code: true,
      };
    case actionTypes.UPDATE_ALLOWED_POST_CODE_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_post_code: false,
        visible_post_code_popup: false,
      };
    case actionTypes.UPDATE_ALLOWED_POST_CODE_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.DELETE_ALLOWED_POST_CODE:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_post_code: true,
      };
    case actionTypes.DELETE_ALLOWED_POST_CODE_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.DELETE_ALLOWED_POST_CODE_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.SELECTED_ALLOWED_POST_CODE:
      return {
        ...state,
        selected_allowed_post_code: action.payload,
      };
    case actionTypes.VAT_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.VAT_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        vat_list: action.payload,
      };
    case actionTypes.VAT_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        vat_list: [],
      };
    case actionTypes.CREATE_VAT:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.CREATE_VAT_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        visible_vat_popup: false,
      };
    case actionTypes.CREATE_VAT_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_VAT:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.UPDATE_VAT_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        visible_vat_popup: false,
      };
    case actionTypes.UPDATE_VAT_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.VISIBLE_VAT_POPUP:
      return {
        ...state,
        visible_vat_popup: action.payload,
      };
    case actionTypes.SELECTED_VAT:
      return {
        ...state,
        selected_vat_list: action.payload,
      };
    case actionTypes.GET_HALF_AND_HALF:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GET_HALF_AND_HALF_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        half_and_half_list: action.payload,
      };
    case actionTypes.GET_HALF_AND_HALF_FAILED:
      return {
        ...state,
        loading_post_code: false,
        half_and_half_list: [],
      };
    case actionTypes.UPDATE_HALF_AND_HALF:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.UPDATE_HALF_AND_HALF_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_HALF_AND_HALF_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.DELETE_VAT:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.DELETE_VAT_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.DELETE_VAT_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    //CARIIER BAG
    case actionTypes.GENERAL_SETTING_GET_CARRIER_BAG_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GENERAL_SETTING_GET_CARRIER_BAG_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        cariier_bag_list: action.payload,
      };
    case actionTypes.GENERAL_SETTING_GET_CARRIER_BAG_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_failed: true,
        result_message: action.payload,
        cariier_bag_list: [],
      };
    case actionTypes.GENERAL_SETTING_UPDATE_CARRIER_BAG:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.GENERAL_SETTING_UPDATE_CARRIER_BAG_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.GENERAL_SETTING_UPDATE_CARRIER_BAG_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };

    case actionTypes.GET_SHORT_CUT_BUTTON_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GET_SHORT_CUT_BUTTON_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        short_cut_button_list: action.payload,
      };
    case actionTypes.GET_SHORT_CUT_BUTTON_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        short_cut_button_list: [],
      };
    case actionTypes.UPDATE_SHORT_CUT_BUTTON:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.UPDATE_SHORT_CUT_BUTTON_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_SHORT_CUT_BUTTON_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };

    //ABOUT US
    case actionTypes.GET_ABOUT_US_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GET_ABOUT_US_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        about_us_list: action.payload,
      };
    case actionTypes.GET_ABOUT_US_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        about_us_list: [],
      };
    case actionTypes.UPDATE_ABOUT_US:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.UPDATE_ABOUT_US_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_ABOUT_US_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };

    // DELIVERY BY AREA
    case actionTypes.GET_BY_AREA_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GET_BY_AREA_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        by_area_list: action.payload,
      };
    case actionTypes.GET_BY_AREA_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        by_area_list: [],
      };

    // CREATE BY AREA
    case actionTypes.CREATE_BY_AREA:
      return {
        ...state,
        result_message: "",
        result_successfull: false,
        result_failed: false,
        popup_loading: true,
      };
    case actionTypes.CREATE_BY_AREA_SUCCESSFULL:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
        popup_loading: false,
        show_create_delivery_by_area_popup: false,
      };
    case actionTypes.CREATE_BY_AREA_FAILED:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
        popup_loading: false,
      };

    //DELETE BY AREA
    case actionTypes.DELETE_BY_AREA:
      return {
        ...state,
        result_message: "",
        result_successfull: false,
        result_failed: false,
        loading_post_code: true,
      };
    case actionTypes.DELETE_BY_AREA_SUCCESSFULL:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
        loading_post_code: false,
        show_create_delivery_by_area_popup: false,
      };
    case actionTypes.DELETE_BY_AREA_FAILED:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
        loading_post_code: false,
      };

    //UPDATE BY AREA
    case actionTypes.UPDATE_BY_AREA:
      return {
        ...state,
        result_message: "",
        result_successfull: false,
        result_failed: false,
        popup_loading: true,
      };
    case actionTypes.UPDATE_BY_AREA_SUCCESSFULL:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
        popup_loading: false,
        show_create_delivery_by_area_popup: false,
      };
    case actionTypes.UPDATE_BY_AREA_FAILED:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
        popup_loading: false,
      };
    //SELECTED DELIVERY BY AREA
    case actionTypes.SELECTED_DELIVERY_BY_AREA:
      return {
        ...state,
        selected_delivery_by_area: action.payload,
      };
    case actionTypes.SHOW_CREATE_DELIVERY_BY_AREA_POPUP:
      return {
        ...state,
        show_create_delivery_by_area_popup: action.payload,
      };

    //DELIVERY AREA MAP
    case actionTypes.GET_DELIVERY_AREA_MAP_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GET_DELIVERY_AREA_MAP_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        delivery_area_map_list: action.payload,
      };
    case actionTypes.GET_DELIVERY_AREA_MAP_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        delivery_area_map_list: [],
      };

    //CREATE DELIVERY AREA MAP
    case actionTypes.CREATE_DELIVERY_AREA_MAP:
      return {
        ...state,
        loading_post_code: true,
        result_message: "",
        result_successfull: false,
        result_failed: false,
        is_created: false,
      };
    case actionTypes.CREATE_DELIVERY_AREA_MAP_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
        is_created: true,
      };
    case actionTypes.CREATE_DELIVERY_AREA_MAP_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
        is_created: false,
      };

    //UPDATE DELIVERY AREA MAP
    case actionTypes.UPDATE_DELIVERY_AREA_MAP:
      return {
        ...state,
        loading_post_code: true,
        result_message: "",
        result_successfull: false,
        result_failed: false,
      };
    case actionTypes.UPDATE_DELIVERY_AREA_MAP_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
      };
    case actionTypes.UPDATE_DELIVERY_AREA_MAP_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
      };

    //DELETE DELIVERY AREA MAP
    case actionTypes.DELETE_DELIVERY_AREA_MAP:
      return {
        ...state,
        loading_post_code: true,
        result_message: "",
        result_successfull: false,
        result_failed: false,
      };
    case actionTypes.DELETE_DELIVERY_AREA_MAP_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
      };
    case actionTypes.DELETE_DELIVERY_AREA_MAP_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
      };
    //DEFAULT MAP CENTER
    case actionTypes.DEFAULT_MAP_CENTER:
      return {
        ...state,
        default_map_center: action.payload,
      };
    //POST CODE FOF
    case actionTypes.GET_ALLOWED_POST_CODE_FOF_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GET_ALLOWED_POST_CODE_FOF_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        post_code_fof_list: action.payload,
      };
    case actionTypes.GET_ALLOWED_POST_CODE_FOF_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        post_code_fof_list: [],
      };
    case actionTypes.CREATE_ALLOWED_POST_CODE_FOF:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_post_code: true,
      };
    case actionTypes.CREATE_ALLOWED_POST_CODE_FOF_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.CREATE_ALLOWED_POST_CODE_FOF_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.UPDATE_ALLOWED_POST_CODE_FOF:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_post_code: true,
      };
    case actionTypes.UPDATE_ALLOWED_POST_CODE_FOF_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.UPDATE_ALLOWED_POST_CODE_FOF_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.DELETE_ALLOWED_POST_CODE_FOF:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_post_code: true,
      };
    case actionTypes.DELETE_ALLOWED_POST_CODE_FOF_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.DELETE_ALLOWED_POST_CODE_FOF_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_post_code: false,
      };
    case actionTypes.SELECTED_ALLOWED_POST_CODE_FOF:
      return {
        ...state,
        selected_post_code_fof: action.payload,
      };
    default:
      return state;
  }
};
export default tabelAreaRedusers;
