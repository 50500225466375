import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";

import {
  usersServices,
  createUsersServices,
  updateUsersServices,
  deleteUsersservices,
  shopGroupListServices,
  createShopGroupServices,
  updateShopGroupServices,
  deleteShopGroupServices,
  shopListByGroupIdSevices,
} from "../../services/UsersServices";
import {
  getUsersListAction,
  getUsersListFailedAction,
  createUsersFailedAction,
  createUsersSuccessfullAction,
  updateUsersFailedAction,
  updateUsersSuccessfullAction,
  deleteUsersFailedAction,
  deleteUsersSuccessfullAction,
  getEmployeesListAction,
  getOwnersListAction,
  shopGroupListAction,
  shopGroupListFailedAction,
  shopGroupListSuccessfullAction,
  createShopGroupFailedAction,
  createShopGroupSuccessfullAction,
  deleteShopGroupFailedAction,
  deleteShopGroupSuccessfullAction,
  updateShopGroupFailedAction,
  updateShopGroupSuccessfullAction,
  shopListByGroupIdFailedAction,
  shopListByGroupIdSuccessfullAction,
  shopListByGroupIdAction,
  selectedShopIdsAction,
} from "./Actions";
import { getDropDownAction } from "../menu/Actions";
function* getUsersList({ payload }) {
  try {
    const response = yield call(
      usersServices,
      payload.login_key,
      payload.user_id,
      payload.user_type
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.UsersList) {
          if (response.data.UsersList.length > 0) {
            for (let i = 0; i < response.data.UsersList.length; i++) {
              if (response.data.UsersList[i].UserType == 10001202) {
                yield put(getOwnersListAction(response.data.UsersList));
              } else {
                yield put(getEmployeesListAction(response.data.UsersList));
              }
            }
          } else {
            yield put(getUsersListFailedAction(""));
          }
        } else {
          yield put(getUsersListFailedAction(""));
        }
      } else {
        yield put(getUsersListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getUsersListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getUsersListFailedAction(error.message));
  }
}

function* createUsers({ payload }) {
  try {
    const response = yield call(
      createUsersServices,
      payload.login_key,
      payload.user_id,
      payload.user_type,
      payload.first_name,
      payload.last_name,
      payload.active,
      payload.shop_group_id,
      payload.user_name,
      payload.pass,
      payload.access_group_id,
      payload.shop_id,
      payload.mobile,
      payload.login_otp,
      payload.two_factor
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(createUsersSuccessfullAction(response.data[0].ResultMessage));
        yield put(
          getUsersListAction(
            payload.login_key,
            payload.user_id,
            payload.user_type
          )
        );
      } else {
        yield put(createUsersFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createUsersFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createUsersFailedAction(error.message));
  }
}

function* updateUsers({ payload }) {
  try {
    const response = yield call(
      updateUsersServices,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.user_type,
      payload.first_name,
      payload.last_name,
      payload.active,
      payload.shop_group_id,
      payload.user_name,
      payload.pass,
      payload.access_group_id,
      payload.shop_id,
      payload.mobile,
      payload.login_otp,
      payload.two_factor
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateUsersSuccessfullAction(response.data.ResultMessage));
        yield put(
          getUsersListAction(
            payload.login_key,
            payload.user_id,
            payload.user_type
          )
        );
      } else {
        yield put(updateUsersFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateUsersFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateUsersFailedAction(error.message));
  }
}
function* deleteUsers({ payload }) {
  try {
    const response = yield call(
      deleteUsersservices,
      payload.login_key,
      payload.user_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(deleteUsersSuccessfullAction(response.data.ResultMessage));
        yield put(
          getUsersListAction(
            payload.login_key,
            payload.user_id,
            payload.user_type
          )
        );
      } else {
        yield put(deleteUsersFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteUsersFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteUsersFailedAction(error.message));
  }
}
function* shopGroupList({ payload }) {
  try {
    const response = yield call(
      shopGroupListServices,
      payload.login_key,
      payload.user_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.ShopGroup_list.length > 0) {
          let my_list = [];
          for (let i = 0; i < response.data.ShopGroup_list.length; i++) {
            my_list.push({
              ...response.data.ShopGroup_list[i],
              showDeleteBox: false,
            });
          }
          yield put(shopGroupListSuccessfullAction(my_list));
        } else {
          yield put(shopGroupListFailedAction(""));
        }
      } else {
        yield put(shopGroupListFailedAction(response.data.ResultMessag));
      }
    } else {
      yield put(shopGroupListFailedAction(response.data.ResultMessag));
    }
  } catch (error) {
    yield put(shopGroupListFailedAction(error.message));
  }
}
function* createShopGroup({ payload }) {
  try {
    const response = yield call(
      createShopGroupServices,
      payload.login_key,
      payload.user_id,
      payload.group_name,
      payload.shops_group,
      payload.sort_id
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(
          createShopGroupSuccessfullAction(response.data[0].ResultMessage)
        );
        yield put(shopGroupListAction(payload.login_key, payload.user_id));
        yield put(getDropDownAction(payload.login_key, payload.user_id, 0));
      } else {
        yield put(createShopGroupFailedAction(response.data[0].ResultMessag));
      }
    } else {
      yield put(createShopGroupFailedAction(response.data[0].ResultMessag));
    }
  } catch (error) {
    yield put(createShopGroupFailedAction(error.message));
  }
}
function* updateShopGroup({ payload }) {
  try {
    const response = yield call(
      updateShopGroupServices,
      payload.login_key,
      payload.user_id,
      payload.group_id,
      payload.group_name,
      payload.shops_group,
      payload.sort_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateShopGroupSuccessfullAction(response.data.ResultMessage)
        );
        yield put(shopGroupListAction(payload.login_key, payload.user_id));
        yield put(getDropDownAction(payload.login_key, payload.user_id, 0));
      } else {
        yield put(updateShopGroupFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateShopGroupFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateShopGroupFailedAction(error.message));
  }
}
function* deleteShopGroup({ payload }) {
  try {
    const response = yield call(
      deleteShopGroupServices,
      payload.login_key,
      payload.user_id,
      payload.group_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteShopGroupSuccessfullAction(response.data.ResultMessage)
        );
        yield put(shopGroupListAction(payload.login_key, payload.user_id));
        yield put(getDropDownAction(payload.login_key, payload.user_id, 0));
      } else {
        yield put(
          deleteShopGroupFailedAction(
            response.deleteShopGroupFailedAction.ResultMessag
          )
        );
      }
    } else {
      yield put(deleteShopGroupFailedAction(response.data.ResultMessag));
    }
  } catch (error) {
    yield put(deleteShopGroupFailedAction(error.message));
  }
}
function* shopListByGroupId({ payload }) {
  try {
    const response = yield call(
      shopListByGroupIdSevices,
      payload.login_key,
      payload.user_id,
      payload.group_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.ShopList_byGroupId.length > 0) {
          let my_list = [];
          let selectedShopIds = [];
          for (let i = 0; i < response.data.ShopList_byGroupId.length; i++) {
            if (response.data.ShopList_byGroupId[i].GroupId) {
              if (
                payload.group_id == response.data.ShopList_byGroupId[i].GroupId
              ) {
                my_list.push({
                  ...response.data.ShopList_byGroupId[i],
                  selected: true,
                  SearchTitle: `${response.data.ShopList_byGroupId[i].ShopId} ${response.data.ShopList_byGroupId[i].Title}`,
                });
                selectedShopIds.push(
                  response.data.ShopList_byGroupId[i].ShopId
                );
              } else {
                my_list.push({
                  ...response.data.ShopList_byGroupId[i],
                  selected: false,
                  SearchTitle: `${response.data.ShopList_byGroupId[i].ShopId} ${response.data.ShopList_byGroupId[i].Title}`,
                });
              }
            } else {
              my_list.push({
                ...response.data.ShopList_byGroupId[i],
                selected: false,
                SearchTitle: `${response.data.ShopList_byGroupId[i].ShopId} ${response.data.ShopList_byGroupId[i].Title}`,
              });
            }
          }
          yield put(selectedShopIdsAction(selectedShopIds));
          yield put(shopListByGroupIdSuccessfullAction(my_list));
        } else {
          yield put(shopListByGroupIdFailedAction());
        }
      } else {
        yield put(shopListByGroupIdFailedAction());
      }
    } else {
      yield put(shopListByGroupIdFailedAction());
    }
  } catch (error) {
    yield put(shopListByGroupIdFailedAction());
  }
}
export function* watchShopListByGroupId() {
  yield takeLatest(action_types.SHOP_LIST_BY_GROUP_ID, shopListByGroupId);
}
export function* watchDeleteShopGroup() {
  yield takeLatest(action_types.DELETE_SHOP_GROUP, deleteShopGroup);
}
export function* watchUpdateShopGroup() {
  yield takeLatest(action_types.UPDATE_SHOP_GROUP, updateShopGroup);
}
export function* watchCreateShopGroup() {
  yield takeLatest(action_types.CREATE_SHOP_GROUP, createShopGroup);
}
export function* watchShopGroupList() {
  yield takeLatest(action_types.SHOP_GROUP_LIST, shopGroupList);
}
export function* watchDeleteUsers() {
  yield takeLatest(action_types.DELETE_USERS, deleteUsers);
}
export function* watchUpdateUsers() {
  yield takeLatest(action_types.UPDATE_USERS, updateUsers);
}

export function* watchCreateUsers() {
  yield takeLatest(action_types.CREATE_USERS, createUsers);
}

export function* watchGetUsersList() {
  yield takeLatest(action_types.GET_USERS_LIST, getUsersList);
}

export default function* rootUsers() {
  yield all([
    fork(watchGetUsersList),
    fork(watchCreateUsers),
    fork(watchUpdateUsers),
    fork(watchDeleteUsers),
    fork(watchDeleteShopGroup),
    fork(watchUpdateShopGroup),
    fork(watchCreateShopGroup),
    fork(watchShopGroupList),
    fork(watchShopListByGroupId),
  ]);
}
