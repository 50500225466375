import { borderRadius } from "@mui/system";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { primaryColor, white } from "../constants/Colors";
import { Badge, Button } from "react-bootstrap";
import {
  showMessageSupportPopupAction,
  selectedShopStatusAction,
} from "../redux/shop/Actions";
const ShopStatusMainList = ({
  logo,
  shop_name,
  domain,
  open_close_time,
  status,
  new_message,
  index1,
  onClick,
  old_message,
  shop_id,
}) => {
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { shop_status_list } = useSelector((state) => state.shopReducer);
  const dispatch = useDispatch();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div
      className="ShopStatusMainList_parent_div"
      style={{
        border: ".3px solid #e5e5e5",
        borderBottom:
          shop_status_list.length - 1 == index1 ? ".3px solid #e5e5e5" : "none",
      }}
    >
      <div className="ShopStatusMainList_container_list_items">
        <div className="ShopStatusMainList_container_shop_logo">
          <img src={logo} />
        </div>
        <div className="ShopStatusMainList_container_data">
          <p
            className="ShopStatusMainList_p_tags"
            style={{
              justifyContent: "flex-start",
            }}
          >
            {shop_name} ({shop_id})
          </p>{" "}
          <a
            href={`https://${domain}`}
            target="_blank"
            className="ShopStatusMainList_p_tags"
            rel="noreferrer"
          >
            {domain}
          </a>
          {/* <p
            className="ShopStatusMainList_p_tags"
            onClick={() => openInNewTab(domain)}
          >
            {domain}
          </p> */}
          {new_message != 0 ? (
            <p
              className="ShopStatusMainList_p_tags"
              style={{ cursor: "pointer" }}
              onClick={onClick}
            >
              <span>{new_message} New</span>
              {lang.message1}
            </p>
          ) : old_message == 0 ? (
            <p
              className="ShopStatusMainList_p_tags"
              style={{
                color: "grey",
                // cursor: "pointer",
              }}
            >
              {lang.no_message}
            </p>
          ) : (
            <p
              className="ShopStatusMainList_p_tags"
              style={{
                color: "grey",
                cursor: "pointer",
              }}
              onClick={onClick}
            >
              {`${old_message} ${lang.message1}`}
            </p>
          )}
          <div>
            <p
              style={{
                color: status == 0 ? "rgb(8, 168, 8)" : "red",
              }}
            >
              {status == 0 ? "Online" : "Maintainence"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopStatusMainList;
