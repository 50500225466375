import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";

import { getTakingsService } from "../../services/TakingServices";

function* getTakings() {
  try {
    const response = yield call(getTakingsService);
  } catch (error) {
    // yield put(getWorksStationsFailedAction());
  }
}

export function* watchGetTakings() {
  yield takeLatest(action_types.TEST_SERVICE, getTakings);
}

export default function* rootTakings() {
  yield all([fork(watchGetTakings)]);
}
