import React, { useState, useEffect, useRef } from "react";
import ListHeader from "../../../components/ListHeader";
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "datetime-picker-reactjs/dist/index.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { getEnterExitListByEmployeeIdAction } from "../../../redux/Employees/Actions";
const AnyEmployeeEntryExitTimeReport = ({ setClickedItem }) => {
  const didMountRef = useRef();
  const dispatch = useDispatch();
  const { selected_shop_employees, enter_exit_list_by_employee_id } =
    useSelector((state) => state.employeesRedusers);

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);

  const [selectedCourseKey, setSelectedCourseKey] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [enterExitList, setEnterExitList] = useState([]);

  const pdfDownload = async () => {
    var doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          `Name : ${selected_shop_employees.EmployeeName}`,
          `From : ${moment(startDate).format("YYYY-MM-DD H:mm:ss")} `,
          `To : ${moment(endDate).format("YYYY-MM-DD H:mm:ss")}`,
        ],
      ],

      headerStyles: {
        halign: "left",
        cellWidth: 69,
        valign: "middle",
        // minCellWidth: ,
        fillColor: "white",
        textColor: "gray",
        fontSize: 12,
      },

      margin: { right: 30, left: 10 },
    });
    doc.autoTable({
      head: [["Check in", "Check out", "Duration", "Work deficit"]],

      headStyles: {
        halign: "center",
        cellWidth: 48,
        minCellHeight: 12,
        valign: "middle",
        fillColor: "#910d63",
      },
      margin: { right: 30, left: 10 },
    });
    if (enter_exit_list_by_employee_id) {
      if (enter_exit_list_by_employee_id.length > 0) {
        for (let i = 0; i < enter_exit_list_by_employee_id.length; i++) {
          doc.autoTable({
            body: [
              [
                // enter_exit_list_by_employee_id[i].EmployeeName,
                moment(enter_exit_list_by_employee_id[i].DateEnter).format(
                  "YYYY-MM-DD H:mm:ss"
                ),
                moment(enter_exit_list_by_employee_id[i].DateExit).format(
                  "YYYY-MM-DD H:mm:ss"
                ),
                enter_exit_list_by_employee_id[i].daily_operation,

                enter_exit_list_by_employee_id[i].LowTimeWork == 0
                  ? "00:00:00"
                  : enter_exit_list_by_employee_id[i].LowTimeWork,
              ],
            ],
            margin: { right: 30, left: 10 },
            columnStyles: {
              0: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 40.5,
                cellWidth: 48,
                minCellHeight: 11,
                valign: "middle",
              },
              1: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 50.5,
                cellWidth: 48,
                minCellHeight: 11,
                valign: "middle",
              },
              2: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 50.5,
                cellWidth: 48,
                minCellHeight: 11,
                valign: "middle",
              },
              3: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 20,
                cellWidth: 48,
                minCellHeight: 11,
                valign: "middle",
              },
              //   4: {
              //     halign: "center",
              //     fillColor: "#ebebeb",
              //     // cellWidth: 20,
              //     cellWidth: 48,
              //     minCellHeight: 11,
              //     valign: "middle",
              //   },
            },
            theme: "striped",
          });
        }
      }
    }

    doc.save("StaffReport.pdf");
  };

  const handleGetEmployeePerformance = (key) => {
    setSelectedCourseKey(key);
    let date = new Date();

    let newDate = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
      newDate.setDate(newDate.getDate() - 1);
    }

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay();

    let decreased_days = day == 0 ? day + 6 : day - 1;
    let new_date = newDate;
    let this_month =
      month < 9 ? `${year}-0${month + 1}-01` : `${year}-${month + 1}-01`;
    new_date.setDate(new_date.getDate() - decreased_days);

    // let daysLength = new Date(year, month + 1, 0).getDate();
    // let lastmonth = new Date(year, 12, 0).getDate();
    if (key == 1) {
      setStartDate(date);
      setEndDate(date);
    } else if (key == 2) {
      setStartDate(new_date);
      setEndDate(date);
    } else if (key == 3) {
      setStartDate(new Date(this_month));
      setEndDate(date);
    } else if (key == 4) {
      setStartDate(new Date(`${year}-01-01`));
      setEndDate(date);
    } else if (key == 5) {
      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
    }

    if (
      moment(startDate).format("YYYY-MM-DD") <=
      moment(endDate).format("YYYY-MM-DD")
    ) {
      if (selected_shop) {
        if (selected_shop.length > 0) {
          dispatch(
            getEnterExitListByEmployeeIdAction(
              selected_shop[0].ShopId,
              login_key,
              user_id,
              key == 1
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 2
                ? moment(new_date).format("YYYY-MM-DD h:mm:ss")
                : key == 3
                ? this_month
                : key == 4
                ? `${year}-01-01`
                : key == 5
                ? moment(startDate).format("YYYY-MM-DD h:mm:ss")
                : "",
              key == 1
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 2
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 3
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 4
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 5
                ? moment(endDate).format("YYYY-MM-DD h:mm:ss")
                : "",
              selected_shop_employees.Id
            )
          );
        }
      }
    } else {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.start_date_end_date_in_order_list}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 5000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };
  useEffect(() => {
    let date = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
    }
    setStartDate(date);
    setEndDate(date);
    if (selected_shop_employees) {
      setSelectedCourseKey(1);
      dispatch(
        getEnterExitListByEmployeeIdAction(
          selected_shop[0].ShopId,
          login_key,
          user_id,
          moment(date).format("YYYY-MM-DD h:mm:ss"),
          moment(date).format("YYYY-MM-DD h:mm:ss"),
          selected_shop_employees.Id
        )
      );
    }
  }, [selected_shop, selected_shop_employees]);

  useEffect(() => {
    if (enter_exit_list_by_employee_id) {
      if (enter_exit_list_by_employee_id.length > 0) {
        setEnterExitList(enter_exit_list_by_employee_id);
      } else {
        setEnterExitList([]);
      }
    } else {
      setEnterExitList([]);
    }
  }, [enter_exit_list_by_employee_id]);

  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "100%",
      }}
    >
      <div style={{ marginTop: "10px" }}>
        <ListHeader
          title={selected_shop_employees.EmployeeName}
          show_back={true}
          clickedItem={() => setClickedItem(1)}
        />
      </div>
      <div>
        <div className="margin_menu_normal_horizontal">
          {" "}
          <div className=" enter-exit-container-buttons-date-picker">
            <div className=" enter-exit-container-buttons ">
              <button
                style={{
                  backgroundColor: selectedCourseKey == 1 ? "red" : null,
                  color: selectedCourseKey == 1 ? "white" : null,
                }}
                onClick={() => handleGetEmployeePerformance(1)}
              >
                {lang.today}
              </button>
              <button
                style={{
                  backgroundColor: selectedCourseKey == 2 ? "red" : null,
                  color: selectedCourseKey == 2 ? "white" : null,
                }}
                onClick={() => handleGetEmployeePerformance(2)}
              >
                {lang.week}
              </button>
              <button
                style={{
                  backgroundColor: selectedCourseKey == 3 ? "red" : null,
                  color: selectedCourseKey == 3 ? "white" : null,
                }}
                onClick={() => handleGetEmployeePerformance(3)}
              >
                {lang.month}
              </button>
              <button
                style={{
                  backgroundColor: selectedCourseKey == 4 ? "red" : null,
                  color: selectedCourseKey == 4 ? "white" : null,
                }}
                onClick={() => handleGetEmployeePerformance(4)}
              >
                {lang.year}
              </button>
            </div>

            <div className=" enter-exot-container-datefrom-dateto">
              <div style={{ width: "29%" }}>
                <h6 className="h6">{lang.from}</h6>

                <DatePicker
                  placeholderText="From"
                  dateFormat="yyyy-MM-dd"
                  selected={startDate}
                  onChange={(e) => setStartDate(e)}
                  className="date_picker_sales"
                  // calendarClassName="calendarClassName"
                />
              </div>
              <div style={{ width: "29%" }}>
                <h6 className="h6">{lang.to}</h6>
                <DatePicker
                  placeholderText="To"
                  dateFormat="yyyy-MM-dd"
                  selected={endDate}
                  onChange={(e) => setEndDate(e)}
                  className="date_picker_sales"
                />
              </div>
              <div className="sales-container-result-button">
                <button onClick={() => handleGetEmployeePerformance(5)}>
                  Result
                </button>
              </div>
              <div className="sales-container-result-button">
                <button
                  // onClick={() => handleGetEmployeePerformance(5)}
                  style={{
                    backgroundColor: "rgb(55, 55, 247)",
                    fontSize: "14px",
                  }}
                  onClick={pdfDownload}
                >
                  {lang.export_to_pdf}
                </button>
              </div>
            </div>
          </div>
          <div className="entry-exit-container-main1">
            <div className="list_header_container entry-exit-container-header entry-exit-commn-styles">
              <h6>{lang.check_in}</h6>
              <h6>{lang.check_out}</h6>
              <h6>{lang.duration}</h6>
              <h6>{lang.work_deficit}</h6>
            </div>
            <div
              className="menu_list_header_container "
              style={{ height: "87%" }}
            >
              <div
                className="margin_menu_normal_horizontal"
                // ref={pdfref}
                id="my-table"
              >
                {enterExitList
                  ? enterExitList.length > 0
                    ? enterExitList.map((item, index) => (
                        <div className="entry-exit-container">
                          <div
                            className="entry-exit-container-main-section entry-exit-commn-styles"
                            style={{ width: "100%" }}
                          >
                            <p>
                              {moment(item.DateEnter).format(
                                "YYYY-MM-DD H:mm:ss"
                              )}
                            </p>
                            <p>
                              {moment(item.DateExit).format(
                                "YYYY-MM-DD H:mm:ss"
                              )}
                            </p>{" "}
                            <p>{item.daily_operation}</p>
                            <p>
                              {item.LowTimeWork == 0
                                ? "00:00:00"
                                : item.LowTimeWork}
                            </p>
                          </div>
                        </div>
                      ))
                    : null
                  : null}
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnyEmployeeEntryExitTimeReport;
