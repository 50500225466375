import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useScrollbarSize from "react-scrollbar-size";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import {
  showAddTablePopupAction,
  visiblePostCodePopup,
} from "../../../../redux/GeneralSetting/Actions";

import {
  createAllowedPostCodeAction,
  updateAllowedPostCodeAction,
} from "../../../../redux/GeneralSetting/Actions";
import {
  primaryColor,
  main_nav_button_color,
  main_nav_back_color,
} from "../../../../constants/Colors";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import { border } from "@mui/system";
import { number_of_table } from "../../../../constants/Teble";
import {
  createTabelAction,
  updateTabelAction,
} from "../../../../redux/GeneralSetting/Actions";
const TablePopup = () => {
  const postCodeRef = useRef();
  const deliveyChargeRef = useRef();
  const deliveyMinChargeRef = useRef();

  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_allowed_post_code } = useSelector(
    (state) => state.tabelAreaRedusers
  );
  const {
    tabel_area_list,
    loading_tabel_area,
    selected_area,
    selected_tabel,
    tabels_list,
    result_successfull,
  } = useSelector((state) => state.tabelAreaRedusers);
  const [numberOfTable, setNumberOfTable] = useState({ value: 1, label: "1" });
  const [appVisible, setAppVisible] = useState(true);
  const [kioskVisible, setKioskVisible] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 130,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleCreateAndSaveTabel = () => {
    if (numberOfTable.value) {
      dispatch(
        createTabelAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          "",
          selected_area.Id,
          1,
          numberOfTable.value,
          appVisible ? 1 : 0,
          kioskVisible ? 1 : 0
        )
      );
    } else {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleCreateAndSaveTabel();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [appVisible, kioskVisible]);

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showAddTablePopupAction(false))}
    >
      <div
        className="create_shop_popup_content_container post-code-content-container"
        onClick={(i) => i.stopPropagation()}
      >
        <div className=" create-shop-close-div-post-code">
          <i
            onClick={() => dispatch(showAddTablePopupAction(false))}
            style={{ fontSize: 25 }}
            aria-hidden="true"
            className="fa fa-times post-code-close-icon"
          ></i>
          <p className="post-code-p">{lang.add_table}</p>
        </div>
        <div className="container-input-div">
          <div
            className="create_shop_inputs_container post-code-create_shop_inputs_container"
            style={{ height: "13%", margin: "25px auto " }}
          >
            <form>
              <h6 className="h6 post-code-h6">* {lang.number_of_table}</h6>
              <Select
                options={number_of_table}
                defaultValue={numberOfTable}
                isSearchable={false}
                onChange={(list, item) => setNumberOfTable(list)}
                value={numberOfTable}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </form>
          </div>
          <div className="create_shop_inputs_container table-popup-container-checkbox">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={appVisible}
                    onChange={() => setAppVisible(!appVisible)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.app_visible}
              />
            </FormGroup>
          </div>
          <div
            className="create_shop_inputs_container"
            style={{ margin: "25px auto " }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={kioskVisible}
                    onChange={() => setKioskVisible(!kioskVisible)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.kiosk_visible}
              />
            </FormGroup>
          </div>
        </div>
        <div className="post-code-popup-buttons-container">
          <button
            // disabled={selectedSubmenuIds.length > 0 ? false : true}
            onClick={handleCreateAndSaveTabel}
            id="popup_add"
            // onKeyDown={handleSaveKeyDown}
          >
            {lang.save}
          </button>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default TablePopup;
