import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../../../../components/Loader";
import { useSelector, useDispatch } from "react-redux";
import { primaryColor } from "../../../../constants/Colors";
import {
  showSecondScreenImageDetailsPopupAction,
  updateSecondScreenEachPhotoSettingAction,
  createSecondScreenImagesAction,
} from "../../../../redux/settings/Action";
import { createGeneralSecondScreenImageAction } from "../../../../redux/devices/Actions";
const SecondScreenImageDetailsPopup = () => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [href, setHref] = useState("");
  const [image, setImage] = useState("");
  const [imagesList, setImagesList] = useState([]);
  const [active, setActive] = useState(true);
  const [sort, setSort] = useState("");
  const [srcDimensions, setSrcDimensions] = useState({});
  const [duration, setDuration] = useState(2);
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  const [containerImageWidth, setContainerImageWidth] = useState(0);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { general_second_screen_create_popup } = useSelector(
    (state) => state.devicesWorkStationReduser
  );

  const { loading_devices_worksStations, selected_screen } = useSelector(
    (state) => state.devicesWorkStationReduser
  );
  const imageUrl = href;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const onSelectPicture = (e) => {
    let images_list = [];
    if (imagesList.length > 0) {
      images_list = imagesList;
    } else {
      images_list = [];
    }

    setImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    images_list.push({ url: URL.createObjectURL(e.target.files[0]) });
    setImagesList(images_list);
  };

  const formData = new FormData();
  const handleCreateNewImage = () => {
    let width = srcDimensions.height * 0.75;
    let width1 = srcDimensions.height * 0.8;
    if (image == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.choose_potho}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      if (general_second_screen_create_popup) {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("Duration", duration ? duration : 2);
        formData.append("Active", active ? 1 : 0);
        formData.append("ImageFile", image ? image : "");
        formData.append("IsFullScreen", selected_screen);
        if (selected_screen == 1) {
          if (srcDimensions.width == 1920 && srcDimensions.height == 1080) {
            dispatch(
              createGeneralSecondScreenImageAction(formData, login_key, user_id)
            );
          } else {
            toast.error(
              <h5>
                {" "}
                &nbsp;&nbsp;{
                  lang.general_second_screen_image_sizes
                }&nbsp;&nbsp;{" "}
              </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          }
        } else if (selected_screen == 0) {
          if (srcDimensions.width == 1070 && srcDimensions.height == 1080) {
            dispatch(
              createGeneralSecondScreenImageAction(formData, login_key, user_id)
            );
          } else {
            toast.error(
              <h5>
                {" "}
                &nbsp;&nbsp;{lang.second_screen_image_sizes}&nbsp;&nbsp;{" "}
              </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          }
        }
      } else {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("ShopId", selected_shop[0].ShopId);
        formData.append("Duration", duration ? duration : 2);
        formData.append("Active", active ? 1 : 0);
        formData.append("ImageFile", image ? image : "");
        formData.append("IsFullScreen", selected_screen);
        if (selected_screen == 1) {
          if (srcDimensions.width == 1920 && srcDimensions.height == 1080) {
            dispatch(
              createSecondScreenImagesAction(
                formData,
                login_key,
                user_id,
                selected_shop[0].ShopId
              )
            );
          } else {
            toast.error(
              <h5>
                {" "}
                &nbsp;&nbsp;{
                  lang.general_second_screen_image_sizes
                }&nbsp;&nbsp;{" "}
              </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          }
        } else if (selected_screen == 0) {
          if (srcDimensions.width == 1070 && srcDimensions.height == 1080) {
            dispatch(
              createSecondScreenImagesAction(
                formData,
                login_key,
                user_id,
                selected_shop[0].ShopId
              )
            );
          } else {
            toast.error(
              <h5>
                {" "}
                &nbsp;&nbsp;{lang.second_screen_image_sizes}&nbsp;&nbsp;{" "}
              </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      if (elementRef.current) {
        const width = elementRef.current.offsetWidth;

        setContainerImageWidth(width);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [elementRef]);

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showSecondScreenImageDetailsPopupAction(false))}
    >
      <div
        onClick={(i) => i.stopPropagation()}
        className="create_shop_popup_content_container second-screen-image-details-popup-container"
        style={{
          width: selected_screen == 1 ? " 45%" : "29%",
          height: selected_screen == 1 ? "90%" : "90%",
        }}
      >
        <div className="second-screen-image-popup-container">
          <div className="second-screen-image-popup-container-contents">
            <p>Photo set up</p>
            <hr />

            <div className="second-screen-image-popup-container-formgroup">
              <div className="second-screen-container-upload-image">
                <div
                  className="second-screen-container-image"
                  id="second-screen-container-image"
                  ref={elementRef}
                  style={{
                    width: selected_screen == 1 ? "100%" : "297px",
                    height:
                      selected_screen == 1
                        ? `${0.56 * containerImageWidth}px`
                        : "300px",
                  }}
                >
                  <img
                    onLoad={loadImage}
                    src={
                      href
                        ? href
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                    }
                    id="profile_image"
                  />
                  {selected_screen == 1 ? (
                    <p>Dimensions : 1920 * 1080</p>
                  ) : (
                    <p>Dimensions : 1070 * 1080</p>
                  )}
                </div>
                <div
                  className="second-screen-container-send-and-chooseImage-buttons"
                  style={{
                    width: selected_screen == 1 ? "100%" : "300px",
                  }}
                >
                  <div>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      class="inputfile"
                      accept="image/*"
                      onChange={(e) => onSelectPicture(e)}
                    />
                    <label for="file">
                      {" "}
                      <i className="fas fa-camera"></i>
                      <span> Choose Photo</span>
                    </label>
                  </div>{" "}
                </div>
              </div>
              <div
                className="second-screen-container-box-checkbox"
                style={{
                  width: selected_screen == 0 ? "300px" : null,
                  margin: selected_screen == 0 ? "auto" : null,
                }}
              >
                <Box
                  component="form"
                  // sx={{
                  //   "& > :not(style)": { m: 1, display: "flex" },
                  // }}
                  style={{ marginTop: "25px" }}
                  className="second-screen-image-popup-box"
                  // className="menu-list-container-inputs"
                  autoComplete="on"
                >
                  {/* <TextField
                    key={1}
                    sx={{
                      "& label.Mui-focused": {
                        color: primaryColor,
                      },
                      "&:hover label": {
                        color: primaryColor,
                      },
                      "& .MuiOutlinedInput-root": {
                        fontSize: "15px",
                        height: "45px",
                        color: "black",

                        "& fieldset": {
                          borderColor: "grey",
                        },
                        "&:hover fieldset": {
                          borderColor: primaryColor,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: primaryColor,
                        },
                      },
                    }}
                    size="small"
                    label={lang.sort}
                    InputLabelProps={{
                      sx: {
                        fontSize: 16,
                        // top: -7,
                        "&.MuiInputLabel-shrink": { top: 0 },
                        "&.Mui-focused": {
                          color: primaryColor,
                        },
                        "& label.Mui-focused": {
                          color: primaryColor,
                        },
                        "&:hover label": {
                          color: primaryColor,
                        },
                      },
                    }}
                    value={sort}
                    // defaultValue={number == 9 ? postCode : appTitle}
                    onChange={(e) => setSort(e.target.value)}
                    autoFocus={focusedInputKey == 1 ? true : false}
                    onFocus={() => setFocusedInputKey(1)}
                  /> */}
                  <TextField
                    key={2}
                    sx={{
                      "& label.Mui-focused": {
                        color: primaryColor,
                      },
                      "&:hover label": {
                        color: primaryColor,
                      },
                      "& .MuiOutlinedInput-root": {
                        fontSize: "15px",
                        height: "45px",
                        color: "black",

                        "& fieldset": {
                          borderColor: "grey",
                        },
                        "&:hover fieldset": {
                          borderColor: primaryColor,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: primaryColor,
                        },
                      },
                    }}
                    size="small"
                    label={lang.duration}
                    InputLabelProps={{
                      sx: {
                        fontSize: 16,
                        // top: -7,
                        "&.MuiInputLabel-shrink": { top: 0 },
                        "&.Mui-focused": {
                          color: primaryColor,
                        },
                        "& label.Mui-focused": {
                          color: primaryColor,
                        },
                        "&:hover label": {
                          color: primaryColor,
                        },
                      },
                    }}
                    value={duration}
                    // defaultValue={number == 9 ? postCode : appTitle}
                    onChange={(e) => setDuration(e.target.value)}
                    autoFocus={focusedInputKey == 2 ? true : false}
                    onFocus={() => setFocusedInputKey(2)}
                  />
                </Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={active}
                        onChange={() => setActive(!active)}
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.active}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div
            className="second-screen-image-popup-container-save-button"
            style={{
              width: selected_screen == 0 ? "300px" : null,
              margin: selected_screen == 0 ? "auto" : null,
            }}
          >
            <button type="" onClick={handleCreateNewImage}>
              {" "}
              {lang.save}
            </button>
          </div>
        </div>
      </div>
      <Toaster />
      {loading_devices_worksStations ? <Loader /> : null}
    </div>
  );
};

export default SecondScreenImageDetailsPopup;
