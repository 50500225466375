import * as actionType from "../ActionTypes";

export const getWePosSettingListAction = (login_key, user_id) => {
  return {
    type: actionType.GET_WEPOS_SETTING_LIST,
    payload: { login_key, user_id },
  };
};

export const getWePosSettingListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_WEPOS_SETTING_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getWePosSettingListFailedAction = (data) => {
  return {
    type: actionType.GET_WEPOS_SETTING_LIST_FAILED,
    payload: data,
  };
};

export const updateWePosSettingAction = (formData, login_key, user_id) => {
  return {
    type: actionType.UPDATE_WEPOS_SETTING,
    payload: {
      formData,
      login_key,
      user_id,
    },
  };
};
export const updateWePosSettingSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_WEPOS_SETTING_SUCCESSFULL,
    payload: data,
  };
};
export const updateWePosSettingFailedAction = (data) => {
  return {
    type: actionType.UPDATE_WEPOS_SETTING_FAILED,
    payload: data,
  };
};

//WEPOS META DATA
// META DATA
export const getWePosMetaDataListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_WEPOS_META_DATA_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getWePosMetaDataListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_WEPOS_META_DATA_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getWePosMetaDataListFailedAction = (data) => {
  return {
    type: actionType.GET_WEPOS_META_DATA_LIST_FAILED,
    payload: data,
  };
};

export const updateWePosMetaDataAction = (
  login_key,
  user_id,
  meta_title,
  meta_desc,
  meta_keywords
) => {
  return {
    type: actionType.UPDATE_WEPOS_META_DATA,
    payload: {
      login_key,
      user_id,
      meta_title,
      meta_desc,
      meta_keywords,
    },
  };
};
export const updateWePosMetaDataSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_WEPOS_META_DATA_SUCCESSFULL,
    payload: data,
  };
};
export const updateWePosMetaDataFailedAction = (data) => {
  return {
    type: actionType.UPDATE_WEPOS_META_DATA_FAILED,
    payload: data,
  };
};
