import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { usePopperTooltip } from "react-popper-tooltip";
import Loader from "../../../components/Loader";
import { main_nav_button_color, primaryColor } from "../../../constants/Colors";
import ListHeader from "../../../components/ListHeader";
import { editCustomerAction } from "../../../redux/Customers/Actions";
// import { customersList } from "./List";
import {
  getCustomersListAction,
  showDeleteCustomerPopupAction,
  getCustomersExportListAction,
  deletingCustomerInfoAction,
} from "../../../redux/Customers/Actions";
import { setDefaultAction } from "../../../redux/menu/Actions";
// type Props = {
//   csvData: Object[],
//   fileName: string,
// };

const Customers = () => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      closeOnOutsideClick: true,
    });
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    customers_list,
    loading,
    result_successfull,
    result_failed,
    result_message,
    customers_export_list,
  } = useSelector((state) => state.customersRedusers);
  const { profile_list } = useSelector((state) => state.loginReducer);
  const { device_type } = useSelector((state) => state.categoryList);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const [pageNumber, setPageNumber] = useState(1);
  const [customersList, setCustomersList] = useState([]);
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [radioButton, setRadioBurron] = useState("1");
  const [showSearchTypeBox, setShowSearchTypeBox] = useState(false);
  const [changeStyleOnFocuse, SetChangeStylesOnFocuse] = useState(false);
  const [clickOnSearchButton, setClickOnSearchButton] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [id, setId] = useState(0);
  const [showFirstNameInput, setShowFirstNameInput] = useState(true);
  const [address, setAdsress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [deliveryCharge, setDeliveyrCharge] = useState(0);
  const [addressInputBorderColor, setAddressInputBorderColor] =
    useState("#b4b4b4");
  const [rolesid, setRolesId] = useState([]);
  const [userType, setUserType] = useState(0);
  const [userAccesses, setUserAccesses] = useState([]);
  const [postCodeInputBorderColor, setPostCodeInputBorderColor] =
    useState("#b4b4b4");

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData: Object[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const handleChangePage = (event, value) => {
    setPageNumber(value);
    dispatch(
      getCustomersListAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        value,
        searchText ? searchText : "",
        radioButton
      )
    );
    document.getElementById("container-customers-list").scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClickOnEditIcon = (item) => {
    setShowFirstNameInput(true);
    let copy_customersList = [...customersList];
    if (copy_customersList) {
      if (copy_customersList.length > 0) {
        for (let i = 0; i < copy_customersList.length; i++) {
          if (item.CustomerId == copy_customersList[i].CustomerId) {
            copy_customersList[i].show_edit_input = true;
          } else {
            copy_customersList[i].show_edit_input = false;
          }
        }
      }
    }
    setCustomersList(copy_customersList);
  };
  const handleClickOnCloseIcon = (item) => {
    setShowFirstNameInput(true);
    let copy_customersList = [...customersList];
    if (copy_customersList) {
      if (copy_customersList.length > 0) {
        for (let i = 0; i < copy_customersList.length; i++) {
          if (item.CustomerId == copy_customersList[i].CustomerId) {
            copy_customersList[i].show_edit_input = false;
          }
        }
      }
    }
    setCustomersList(copy_customersList);
  };
  const handleCloseSerachTypeBoxes = () => {
    if (searchText == "") {
      setShowSearchTypeBox(false);
      SetChangeStylesOnFocuse(false);
    } else {
      SetChangeStylesOnFocuse(false);
    }
  };
  const handleGetListBySerch = () => {
    setPageNumber(1);
    if (searchText != "") {
      setClickOnSearchButton(true);
      dispatch(
        getCustomersListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          1,
          searchText ? searchText : "",
          radioButton
        )
      );
      dispatch(
        getCustomersExportListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          searchText ? searchText : "",
          radioButton
        )
      );
      setAddressInputBorderColor("#b4b4b4");
      setPostCodeInputBorderColor("#b4b4b4");
    }
  };
  const handleEditCustomerInfo = (item, number, e) => {
    const customers_list = [...customersList];
    if (customers_list) {
      if (customers_list.length > 0) {
        for (let i = 0; i < customers_list.length; i++) {
          if (item.CustomerId == customers_list[i].CustomerId) {
            if (number == 1) {
              customers_list[i].FirstName = e.target.value;
            } else if (number == 2) {
              customers_list[i].LastName = e.target.value;
            } else if (number == 3) {
              customers_list[i].Mail = e.target.value;
            } else if (number == 4) {
              customers_list[i].Mobile = e.target.value;
            } else if (number == 5) {
              if (customers_list[i].customer_address) {
                customers_list[i].customer_address[0].Address = e.target.value;
              } else {
                setAdsress(e.target.value);
              }
            } else if (number == 6) {
              if (customers_list[i].customer_address) {
                customers_list[i].customer_address[0].PostCode = e.target.value;
              } else {
                setPostCode(e.target.value);
              }
            } else if (number == 7) {
              if (customers_list[i].customer_address) {
                customers_list[i].customer_address[0].DeliveryCharge =
                  e.target.value;
              } else {
                setDeliveyrCharge(e.target.value);
              }
            }
          }
        }
        setCustomersList(customers_list);
      }
    }
  };

  const handleEditAndSaveCustomerNewInfo = (item) => {
    const customers_list = [...customersList];
    if (customers_list) {
      if (customers_list.length > 0) {
        for (let i = 0; i < customers_list.length; i++) {
          if (item.CustomerId == customers_list[i].CustomerId) {
            setId(item.CustomerId);
            if (item.customer_address) {
              if (
                item.customer_address[0].Address != "" &&
                item.customer_address[0].PostCode == ""
              ) {
                setPostCodeInputBorderColor("red");
                setAddressInputBorderColor("#b4b4b4");
              } else if (
                item.customer_address[0].Address == "" &&
                item.customer_address[0].PostCode != ""
              ) {
                setAddressInputBorderColor("red");
                setPostCodeInputBorderColor("#b4b4b4");
              } else if (
                item.customer_address[0].Address == "" &&
                item.customer_address[0].PostCode == ""
              ) {
                setAddressInputBorderColor("red");
                setPostCodeInputBorderColor("red");
              } else {
                setAddressInputBorderColor("#b4b4b4");
                setPostCodeInputBorderColor("#b4b4b4");
                dispatch(
                  editCustomerAction(
                    login_key,
                    user_id,
                    selected_shop[0].ShopId,
                    item.CustomerId,
                    item.customer_address
                      ? item.customer_address[0].CustomerAddressId
                      : 0,
                    item.FirstName,
                    item.LastName,
                    item.Mail,
                    item.Mobile,
                    item.customer_address
                      ? item.customer_address[0].Address
                      : address,
                    item.customer_address
                      ? item.customer_address[0].PostCode
                      : postCode,
                    item.customer_address
                      ? item.customer_address[0].DeliveryCharge
                        ? item.customer_address[0].DeliveryCharge
                        : 0
                      : deliveryCharge,
                    pageNumber,
                    searchText ? searchText : "",
                    radioButton
                  )
                );
              }
            } else {
              if (address != "" && postCode == "") {
                setPostCodeInputBorderColor("red");
                setAddressInputBorderColor("#b4b4b4");
              } else if (address == "" && postCode != "") {
                setAddressInputBorderColor("red");
                setPostCodeInputBorderColor("#b4b4b4");
              } else {
                setAddressInputBorderColor("#b4b4b4");
                setPostCodeInputBorderColor("#b4b4b4");
                dispatch(
                  editCustomerAction(
                    login_key,
                    user_id,
                    selected_shop[0].ShopId,
                    item.CustomerId,
                    item.customer_address
                      ? item.customer_address[0].CustomerAddressId
                      : 0,
                    item.FirstName,
                    item.LastName,
                    item.Mail,
                    item.Mobile,
                    item.customer_address
                      ? item.customer_address[0].Address
                      : address,
                    item.customer_address
                      ? item.customer_address[0].PostCode
                      : postCode,
                    item.customer_address
                      ? item.customer_address[0].DeliveryCharge
                        ? item.customer_address[0].DeliveryCharge
                        : 0
                      : deliveryCharge,
                    pageNumber,
                    searchText ? searchText : "",
                    radioButton
                  )
                );
              }
            }
          }
        }
      }
    }
  };
  const handleShowDeleteBox = (item) => {
    dispatch(showDeleteCustomerPopupAction(true));
    dispatch(
      deletingCustomerInfoAction([
        item,
        pageNumber,
        searchText ? searchText : "",
        radioButton,
      ])
    );

    setAddressInputBorderColor("#b4b4b4");
    setPostCodeInputBorderColor("#b4b4b4");
  };
  const handleChangeSearchInoutText = (e) => {
    setSearchText(e.target.value);
    if (!e.target.value) {
      dispatch(
        getCustomersListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          1,
          "",
          1
        )
      );
      dispatch(
        getCustomersExportListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          "",
          1
        )
      );
      setAddressInputBorderColor("#b4b4b4");
      setPostCodeInputBorderColor("#b4b4b4");
    }
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      setSearchText("");
      setShowSearchTypeBox(false);
      setPageNumber(1);
      dispatch(
        getCustomersListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          1,
          "",
          1
        )
      );
      dispatch(
        getCustomersExportListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          "",
          1
        )
      );
      setAddressInputBorderColor("#b4b4b4");
      setPostCodeInputBorderColor("#b4b4b4");
    }
  }, [selected_shop]);

  useEffect(() => {
    if (customers_list) {
      if (customers_list.length > 0) {
        setCustomersList(customers_list);
        for (let i = 0; i < customers_list.length; i++) {
          setPaginationNumber(customers_list[i].PaginationsNumber);
        }
      } else {
        setCustomersList([]);
        setPaginationNumber(0);
      }
    } else {
      setCustomersList([]);
      setPaginationNumber(0);
    }
  }, [customers_list]);

  useEffect(() => {
    if (result_successfull) {
      if (clickOnSearchButton) {
        setShowSearchTypeBox(false);
        dispatch(setDefaultAction());
        setClickOnSearchButton(false);
      }
    }
  }, [result_successfull, clickOnSearchButton]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message != "") {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message != "") {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);
  useEffect(() => {
    let roles_id = [];
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          roles_id.push(userAccesses[i].RoleId);
          setUserType(userAccesses[i].UserType);
        }
        setRolesId(roles_id);
      }
    }
  }, [userAccesses]);

  return (
    <div
      className="countaining-div "
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
      id="countaining"
      onClick={() => handleCloseSerachTypeBoxes()}
    >
      <ListHeader title={lang.customers} show_back={false} />
      <div className="menu_list_header_container-12">
        <div className="margin_menu_normal_horizontal">
          <div
            className="customer-container-searchbox"
            onClick={(i) => i.stopPropagation()}
          >
            <div className="customer-searchbox-container-search-input-and-button">
              <div
                className="customer-searchbox-container-search-input-and-icon"
                style={{
                  border: changeStyleOnFocuse
                    ? `1.5px solid ${primaryColor}`
                    : null,
                  width: userType
                    ? userType == 10001201
                      ? "74%"
                      : rolesid
                      ? rolesid.length > 0
                        ? rolesid.includes(14)
                          ? "74%"
                          : "88%"
                        : "88%"
                      : "88%"
                    : "88%",
                }}
              >
                <i class="fa fa-search" aria-hidden="true"></i>
                <input
                  onFocus={() => {
                    setShowSearchTypeBox(true);
                    SetChangeStylesOnFocuse(true);
                  }}
                  value={searchText}
                  onChange={(e) => handleChangeSearchInoutText(e)}
                  placeholder="Search by full name , email , mobile , address , post Code"
                />
              </div>
              <button
                className="customers-search-button"
                onClick={() => handleGetListBySerch()}
              >
                Search
              </button>{" "}
              {userType == 10001201 ? (
                <button
                  className="customers-export-to-excel-button"
                  onClick={(e) =>
                    exportToCSV(customers_export_list, "text-excel-doc")
                  }
                >
                  Export to Excel
                </button>
              ) : rolesid ? (
                rolesid.length > 0 ? (
                  rolesid.includes(14) ? (
                    <button
                      className="customers-export-to-excel-button"
                      onClick={(e) =>
                        exportToCSV(customers_export_list, "text-excel-doc")
                      }
                    >
                      Export to Excel
                    </button>
                  ) : null
                ) : null
              ) : null}
            </div>
            {showSearchTypeBox ? (
              <div className="customers-container-search-type">
                <p>{lang.search_by} :</p>
                <div className="customers-container-radio-bottons">
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value="1"
                          checked={radioButton === "1"}
                          onChange={(e) => setRadioBurron(e.target.value)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                        />
                      }
                      label={lang.full_name}
                    />
                  </RadioGroup>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value="5"
                          checked={radioButton === "5"}
                          onChange={(e) => setRadioBurron(e.target.value)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                        />
                      }
                      label={lang.email}
                    />
                  </RadioGroup>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value="2"
                          checked={radioButton === "2"}
                          onChange={(e) => setRadioBurron(e.target.value)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                        />
                      }
                      label={lang.mobile}
                    />
                  </RadioGroup>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value="3"
                          checked={radioButton === "3"}
                          onChange={(e) => setRadioBurron(e.target.value)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                        />
                      }
                      label={lang.address}
                    />
                  </RadioGroup>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value="4"
                          checked={radioButton === "4"}
                          onChange={(e) => setRadioBurron(e.target.value)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                        />
                      }
                      label={lang.post_code}
                    />
                  </RadioGroup>
                </div>
              </div>
            ) : null}
          </div>
          <div>
            <div className="customers-container-header">
              <h6>{lang.full_name}</h6>
              <h6>{lang.email}</h6>
              <h6>{lang.mobile}</h6>
              <h6>{lang.address}</h6>
              <h6>{lang.post_code}</h6>
              <h6>{lang.delivery_charge}</h6>
              <h6>{lang.last_order}</h6>
              <h6>{lang.order_no}</h6>
              <div style={{ width: "70px" }}></div>
            </div>
            <div
              className="container-customers-list"
              id="container-customers-list"
            >
              {customersList
                ? customersList.length > 0
                  ? customersList.map((item, index) => (
                      <div
                        className="customers-container-list"
                        style={{
                          backgroundColor: index % 2 == 0 ? "#f2f4f6" : "white",
                        }}
                      >
                        {item.show_edit_input ? (
                          showFirstNameInput ? (
                            <div className="customer_container_full_name">
                              <div>
                                <p style={{ fontSize: "12px" }}>
                                  {lang.first_name}
                                </p>{" "}
                                <i
                                  style={{ fontSize: "13px" }}
                                  class="fa fa-retweet"
                                  aria-hidden="true"
                                  onClick={() => setShowFirstNameInput(false)}
                                ></i>
                              </div>
                              <input
                                value={item.FirstName}
                                onChange={(e) =>
                                  handleEditCustomerInfo(item, 1, e)
                                }
                              />
                            </div>
                          ) : (
                            <div className="customer_container_full_name">
                              <div>
                                <p style={{ fontSize: "12px" }}>
                                  {lang.last_name}
                                </p>
                                <i
                                  style={{ fontSize: "13px" }}
                                  class="fa fa-retweet"
                                  aria-hidden="true"
                                  onClick={() => setShowFirstNameInput(true)}
                                ></i>
                              </div>{" "}
                              <input
                                value={item.LastName}
                                onChange={(e) =>
                                  handleEditCustomerInfo(item, 2, e)
                                }
                              />
                            </div>
                          )
                        ) : (
                          <h6>
                            {item.FirstName} {item.LastName}
                          </h6>
                        )}
                        {item.show_edit_input ? (
                          <input
                            value={item.Mail}
                            onChange={(e) => handleEditCustomerInfo(item, 3, e)}
                          />
                        ) : (
                          <h6>{item.Mail}</h6>
                        )}
                        {item.show_edit_input ? (
                          <input
                            value={item.Mobile}
                            onChange={(e) => handleEditCustomerInfo(item, 4, e)}
                          />
                        ) : (
                          <h6>{item.Mobile}</h6>
                        )}
                        {item.show_edit_input ? (
                          <input
                            value={
                              item.customer_address
                                ? item.customer_address.length > 0
                                  ? item.customer_address[0].Address
                                  : null
                                : null
                            }
                            style={{
                              border:
                                id == item.CustomerId
                                  ? addressInputBorderColor == "#b4b4b4"
                                    ? `.3px solid ${addressInputBorderColor}`
                                    : `1.5px solid ${addressInputBorderColor}`
                                  : null,
                            }}
                            onChange={(e) => handleEditCustomerInfo(item, 5, e)}
                          />
                        ) : (
                          <h6>
                            {item.customer_address
                              ? item.customer_address.length > 0
                                ? item.customer_address[0].Address
                                : null
                              : null}
                          </h6>
                        )}

                        {item.show_edit_input ? (
                          <input
                            value={
                              item.customer_address
                                ? item.customer_address.length > 0
                                  ? item.customer_address[0].PostCode
                                  : null
                                : null
                            }
                            style={{
                              border:
                                id == item.CustomerId
                                  ? postCodeInputBorderColor == "#b4b4b4"
                                    ? `.3px solid ${postCodeInputBorderColor}`
                                    : `1.5px solid ${postCodeInputBorderColor}`
                                  : null,
                            }}
                            onChange={(e) => handleEditCustomerInfo(item, 6, e)}
                          />
                        ) : (
                          <h6>
                            {item.customer_address
                              ? item.customer_address.length > 0
                                ? item.customer_address[0].PostCode
                                : null
                              : null}
                          </h6>
                        )}
                        {item.show_edit_input ? (
                          <input
                            style={{ textAlign: "center" }}
                            value={
                              item.customer_address
                                ? item.customer_address.length > 0
                                  ? item.customer_address[0].DeliveryCharge
                                    ? item.customer_address[0].DeliveryCharge
                                    : 0
                                  : null
                                : null
                            }
                            onChange={(e) => handleEditCustomerInfo(item, 7, e)}
                          />
                        ) : (
                          <h6>
                            {item.customer_address
                              ? item.customer_address.length > 0
                                ? item.customer_address[0].DeliveryCharge
                                  ? `£${parseFloat(
                                      item.customer_address[0].DeliveryCharge
                                    ).toFixed(2)}`
                                  : "£0.00"
                                : null
                              : null}
                          </h6>
                        )}

                        <h6>
                          {item.LastOrderDate
                            ? moment(item.LastOrderDate).format("YYYY/MM/DD")
                            : null}
                        </h6>

                        <h6>{item.CountOfOrders}</h6>

                        {item.show_edit_input ? (
                          <div className="customer-container-save-and-close-icon">
                            <div>
                              <i
                                class="fa fa-check menu-list-fa-check"
                                aria-hidden="true"
                                style={{ fontSize: "19px" }}
                                onClick={() =>
                                  handleEditAndSaveCustomerNewInfo(item)
                                }
                              ></i>
                            </div>
                            <div>
                              <i
                                class="fa fa-times menu-list-fa-times"
                                aria-hidden="true"
                                style={{ fontSize: "19px" }}
                                onClick={() => handleClickOnCloseIcon(item)}
                              ></i>
                            </div>
                          </div>
                        ) : (
                          <div className="customer-container-delete-and-edit-icon">
                            {" "}
                            <i
                              onClick={() => handleClickOnEditIcon(item)}
                              class="fa fa-pencil-square-o"
                              aria-hidden="true"
                              style={{ fontSize: "18px" }}
                            ></i>
                            <i
                              onClick={() => handleShowDeleteBox(item)}
                              class="fas fa-trash"
                              style={{ fontSize: "18px" }}
                              // ref={item.CustomerId == id ? setTriggerRef : null}
                            ></i>
                          </div>
                        )}
                      </div>
                    ))
                  : null
                : null}
              <div className="container-Pagination">
                <Pagination
                  count={paginationNumber}
                  shape="rounded"
                  defaultPage={1}
                  page={pageNumber}
                  onChange={handleChangePage}
                  variant="outlined"
                  color="secondary"
                  hideNextButton={true}
                  hidePrevButton={true}
                  defaultValue={pageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? <Loader /> : null}
    </div>
  );
};

export default Customers;
