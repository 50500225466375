import * as actionTypes from "../ActionTypes";

const initialStates = {
  product_list: [],
  selected_Product: null,
  product_option_list: [],
  product_submenu_list: [],
  show_product_submenu_popup: false,
  selected_Product_submenu: null,
  result_successfull: false,
  result_failed: false,
  result_message: "",
  show_product_availability_popup: false,
};

const productList = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.VISIBLE_PRODUCT_SUBMENU_POPUP:
      return {
        ...state,
        show_product_submenu_popup: action.payload,
      };
    case actionTypes.SELECTED_PRODUCT:
      return {
        ...state,
        selected_Product: action.payload,
      };

    case actionTypes.GET_PRODUCTS_SUCCESSFULL:
      return {
        ...state,
        product_list: action.payload,
      };
    case actionTypes.GET_PRODUCT_SUBMENU_SUCCESSFULL:
      return {
        ...state,
        product_submenu_list: action.payload,
      };
    case actionTypes.GET_PRODUCT_SUBMENU_FAILED:
      return {
        ...state,
        product_submenu_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.GET_PRODUCTS_FAILED:
      return {
        ...state,
        product_list: [],
      };
    case actionTypes.SEND_PRODUCT_SETTINGS:
      return {
        ...state,
      };

    // PRODUCT SUBMENU
    // case actionTypes.SEND_PRODUCT_SETTINGS:
    //   return {
    //     ...state,
    //     product_list: [],
    //   };
    case actionTypes.SELECTED_PRODUCT_SUBMENU:
      return {
        ...state,
        selected_Product_submenu: action.payload,
      };
    case actionTypes.SHOW_PRODUCT_AVAILABILITY_POPUP:
      return {
        ...state,
        show_product_availability_popup: action.payload,
      };
    default:
      return state;
  }
};
export default productList;
