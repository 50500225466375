export const priceLevel=[
    {value:1,label:"PriceLevel1"},
    {value:2,label:"PriceLevel2"},
    {value:3,label:"PriceLevel3"},
    {value:4,label:"PriceLevel4"},
    {value:5,label:"PriceLevel5"},
    {value:6,label:"PriceLevel6"},
    {value:7,label:"PriceLevel7"},
    {value:8,label:"PriceLevel8"},
    {value:9,label:"None"},
]