import React, { useState, useEffect, useRef } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Carousel, { consts } from "react-elastic-carousel";

import ClipLoader from "react-spinners/ClipLoader";
import Loader from "../../../components/Loader";

import OrderProccess from "../AppSettings/Componenets/OrderProccess";
import SaveMenuButton from "../../../components/SaveMenuButton";
import Payment from "./Componenets/Payment";
import Colour from "./Componenets/Colour";
import Display from "./Componenets/Display";
import PasswordProtect from "./Componenets/PasswordProtect";

import { setDefaultAction } from "../../../redux/menu/Actions";
import {
  main_nav_button_color,
  main_nav_back_color,
  primaryColor,
  white,
} from "../../../constants/Colors";
import DeliveryCharge from "./Componenets/Type";
import PaymentLink from "./Componenets/PaymentLink";
import NumberOfPrints from "./Componenets/NumberOfPrint";
import { getWorksStationsAction } from "../../../redux/WorksStations/Actions";
import DojoList from "./Componenets/DojoList";
import DojoIndex from "./Componenets/DojoIndex";
import SecondScreen from "./Componenets/SecondScreen";

import SalesReport from "./Componenets/SalesReport";
const AppSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const passwordProtectRef = useRef(null);
  const paymentRef = useRef(null);
  const displayRef = useRef(null);
  const orderProccessRef = useRef(null);
  const colorRef = useRef(null);
  const paymentLinkRef = useRef(null);
  const numberOfPrintsRef = useRef(null);
  const salesReportRef = useRef(null);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    loading_appSettings,
    result_message,
    result_failed,
    result_successfull,
  } = useSelector((state) => state.appSettingsRedusers);

  let [color, setColor] = useState("red");
  const [clickedItem, setClickedItem] = useState(1);

  const override: CSSProperties = {
    display: "block",
    margin: "0px auto",
    borderColor: "red",
  };

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  const type = consts.PREV;

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i
          class="fa fa-chevron-left"
          aria-hidden="true"
          style={{ fontSize: "20px", marginTop: "5px" }}
        ></i>
      ) : (
        <i
          class="fa fa-chevron-right"
          aria-hidden="true"
          style={{
            fontSize: "20px",
            marginTop: "5px",
            marginLeft: "-16px",
          }}
        ></i>
      );
    return (
      <button onClick={onClick} disabled={isEdge} style={{ border: "none" }}>
        {pointer}
      </button>
    );
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 330, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 450, itemsToShow: 3, itemsToScroll: 2, pagination: false },
    { width: 580, itemsToShow: 4, itemsToScroll: 2, pagination: false },
    { width: 800, itemsToShow: 5, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 6, pagination: false },
    { width: 1150, itemsToShow: 7, itemsToScroll: 2, pagination: false },
  ];

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <div className="menu_list_header_container_tabel_list">
        <div className="main_nav_container">
          <div className="settings_tab_buttons_container web-setting-container-Carousel">
            <Carousel
              // className="styling-example"
              breakPoints={breakPoints}
              itemsToShow={6}
              className="carousel"
              renderArrow={myArrow}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <div direction="row">
                    {pages.map((page) => {
                      // const isActivePage = activePage === page;
                      return (
                        <div
                          style={{ display: "none" }}
                          key={page}
                          onClick={() => onClick(page)}
                          // active={isActivePage}
                        />
                      );
                    })}
                  </div>
                );
              }}
            >
              <button
                key={1}
                onClick={() => setClickedItem(1)}
                style={{
                  backgroundColor: clickedItem == 1 ? primaryColor : white,
                  color: clickedItem == 1 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 1 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.order_proccess}
              </button>
              <button
                key={2}
                onClick={() => setClickedItem(2)}
                style={{
                  backgroundColor: clickedItem == 2 ? primaryColor : white,
                  color: clickedItem == 2 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 2 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.payment}
              </button>
              <button
                key={3}
                onClick={() => setClickedItem(3)}
                style={{
                  backgroundColor: clickedItem == 3 ? primaryColor : white,
                  color: clickedItem == 3 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 3 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.display}
              </button>
              <button
                key={4}
                onClick={() => setClickedItem(4)}
                style={{
                  backgroundColor: clickedItem == 4 ? primaryColor : white,
                  color: clickedItem == 4 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 4 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.password_protect}
              </button>
              <button
                key={5}
                onClick={() => setClickedItem(5)}
                style={{
                  backgroundColor: clickedItem == 5 ? primaryColor : white,
                  color: clickedItem == 5 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 5 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.color}
              </button>
              <button
                key={6}
                onClick={() => setClickedItem(6)}
                style={{
                  backgroundColor: clickedItem == 6 ? primaryColor : white,
                  color: clickedItem == 6 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 6 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                Delivery
              </button>
              <button
                key={7}
                onClick={() => setClickedItem(7)}
                style={{
                  backgroundColor: clickedItem == 7 ? primaryColor : white,
                  color: clickedItem == 7 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 7 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                Payment link
              </button>
              <button
                key={8}
                onClick={() => setClickedItem(8)}
                style={{
                  backgroundColor: clickedItem == 8 ? primaryColor : white,
                  color: clickedItem == 8 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 8 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.number_of_prints}
              </button>
              <button
                key={9}
                onClick={() => setClickedItem(9)}
                style={{
                  backgroundColor: clickedItem == 9 ? primaryColor : white,
                  color: clickedItem == 9 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 9 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.dojo}
              </button>
              {/* <button
                key={11}
                onClick={() => setClickedItem(11)}
                style={{
                  backgroundColor: clickedItem == 11 ? primaryColor : white,
                  color: clickedItem == 11 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 11 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.et_pay}
              </button> */}
              <button
                key={10}
                onClick={() => setClickedItem(10)}
                style={{
                  backgroundColor: clickedItem == 10 ? primaryColor : white,
                  color: clickedItem == 10 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 10 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.second_screen}
              </button>
              <button
                key={12}
                onClick={() => setClickedItem(12)}
                style={{
                  backgroundColor: clickedItem == 12 ? primaryColor : white,
                  color: clickedItem == 12 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 12 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.sale_report}
              </button>
            </Carousel>
          </div>
        </div>

        {clickedItem == 1 ? (
          <OrderProccess ref={orderProccessRef} />
        ) : clickedItem == 2 ? (
          <Payment ref={paymentRef} />
        ) : clickedItem == 3 ? (
          <Display ref={displayRef} />
        ) : clickedItem == 4 ? (
          <PasswordProtect ref={passwordProtectRef} />
        ) : clickedItem == 5 ? (
          <Colour ref={colorRef} />
        ) : clickedItem == 6 ? (
          <DeliveryCharge />
        ) : clickedItem == 7 ? (
          <PaymentLink ref={paymentLinkRef} />
        ) : clickedItem == 8 ? (
          <NumberOfPrints ref={numberOfPrintsRef} />
        ) : clickedItem == 9 ? (
          <DojoIndex />
        ) : clickedItem == 10 ? (
          <SecondScreen />
        ) : clickedItem == 12 ? (
          <SalesReport ref={salesReportRef} />
        ) : null}

        {loading_appSettings ? <Loader /> : null}
      </div>
      <div>
        {clickedItem == 6 || clickedItem == 9 || clickedItem == 10 ? null : (
          <SaveMenuButton
            clickButton={() =>
              clickedItem == 1
                ? orderProccessRef.current.saveSettings()
                : clickedItem == 2
                ? paymentRef.current.saveSettings()
                : clickedItem == 3
                ? displayRef.current.saveSettings()
                : clickedItem == 4
                ? passwordProtectRef.current.saveSettings()
                : clickedItem == 5
                ? colorRef.current.saveSettings()
                : clickedItem == 7
                ? paymentLinkRef.current.saveSettings()
                : clickedItem == 8
                ? numberOfPrintsRef.current.saveSettings()
                : clickedItem == 12
                ? salesReportRef.current.saveSettings()
                : null
            }
          />
        )}
      </div>
    </div>
  );
};

export default AppSettings;
