import React from "react";

const OpenCloseTitleList = () => {
  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: 0,
            width: "12.5%",
            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Day of week
        </p>
        <p
          style={{
            margin: 0,
            width: "12.5%",

            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Delivery starts
        </p>
        <p
          style={{
            margin: 0,
            width: "12.5%",

            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Delivery ends
        </p>
        <p
          style={{
            margin: 0,
            width: "12.5%",
            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Collection starts
        </p>
        <p
          style={{
            margin: 0,
            width: "12.5%",

            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Collection ends
        </p>
        <p
          style={{
            margin: 0,
            width: "12.5%",

            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Action
        </p>
        {/* <p style={{ margin: 0 }}>Action</p> */}
        <p
          style={{
            margin: 0,
            width: "12.5%",

            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Collection
        </p>
        <p
          style={{
            margin: 0,
            width: "12.5%",

            padding: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Delivery
        </p>
      </div>
    </div>
  );
};

export default OpenCloseTitleList;
