import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const getSalesOvrviewListService = async (
  login_key,
  user_id,
  shop_id,
  from_date,
  to_date
) => {
  return await axios.put(`${baseUrl}overview/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    DateFrom: from_date,
    DateTo: to_date,
  });
};
