import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  getFinancialOrderListFailedAction,
  getFinancialOrderListSuccessfullAction,
  refundOrderFailedAction,
  refundOrderSuccessfullAction,
  getFinancialOrderListAction,
  getFinancialRefundListSuccessfullAction,
  getFinancialRefundListFailedAction,
  getFinancialDebtListSuccessfullAction,
  getFinancialRefundListAction,
  sendDebtSuccessfullAction,
  sendDebtFailedAction,
} from "./Actions";
import { ordersListAction } from "../Orders/Actions";
import {
  getFinancialOrderListService,
  refundOrderService,
  getFinancialRefundListService,
  sendDebtService,
} from "../../services/FinancialServices";

function* getFinancialOrderList({ payload }) {
  try {
    const response = yield call(
      getFinancialOrderListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.start_date,
      payload.end_date
    );

    if ((response.status = 200)) {
      if (response.data.ResultCode == 1) {
        if (response.data.order_list) {
          if (response.data.order_list.length > 0) {
            yield put(
              getFinancialOrderListSuccessfullAction(response.data.order_list)
            );
          } else {
            yield put(getFinancialOrderListFailedAction(""));
          }
        } else {
          yield put(getFinancialOrderListFailedAction(""));
        }
      } else {
        yield put(
          getFinancialOrderListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getFinancialOrderListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getFinancialOrderListFailedAction(error.message));
  }
}
function* refundOrder({ payload }) {
  try {
    const response = yield call(
      refundOrderService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.order_id,
      payload.refund_amount,
      payload.refund_note,
      payload.device_type
    );
    if ((response.status = 200)) {
      if (response.data.ResultCode == 1) {
        if (payload.orderlist_or_financial == 1) {
          yield put(
            ordersListAction(
              payload.login_key,
              payload.user_id,
              payload.shop_id,
              payload.start_date,
              payload.end_date
            )
          );
        } else {
          if (payload.order_id == 0) {
            yield put(
              getFinancialRefundListAction(
                payload.login_key,
                payload.user_id,
                payload.selected_shop,
                payload.start_date,
                payload.end_date
              )
            );
          } else {
            yield put(
              getFinancialOrderListAction(
                payload.login_key,
                payload.user_id,
                payload.selected_shop,
                payload.start_date,
                payload.end_date
              )
            );
          }
        }

        yield put(refundOrderSuccessfullAction(response.data.ResultMessage));
      } else {
        yield put(refundOrderFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(refundOrderFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(refundOrderFailedAction(error.message));
  }
}
function* getFinancialRefundList({ payload }) {
  try {
    const response = yield call(
      getFinancialRefundListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.start_date,
      payload.end_date
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.debit_list) {
          if (response.data.debit_list.length > 0) {
            yield put(
              getFinancialDebtListSuccessfullAction(response.data.debit_list)
            );
          } else {
            yield put(getFinancialRefundListFailedAction(""));
          }
        } else {
          yield put(getFinancialRefundListFailedAction(""));
        }
      } else {
        yield put(
          getFinancialRefundListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getFinancialRefundListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getFinancialRefundListFailedAction(error.message));
  }
}
function* sendDebt({ payload }) {
  try {
    const response = yield call(
      sendDebtService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.amount,
      payload.refund_note,
      payload.order_id,
      payload.selectedShop,
      payload.startDate,
      payload.endDate
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(sendDebtSuccessfullAction(response.data.ResultMessage));
        yield put(
          getFinancialRefundListAction(
            payload.login_key,
            payload.user_id,
            payload.selectedShop,
            payload.startDate,
            payload.endDate
          )
        );
      } else {
        yield put(sendDebtFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(sendDebtFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(sendDebtFailedAction(error.message));
  }
}
export function* watchSendDebt() {
  yield takeLatest(action_types.SEND_DEBT, sendDebt);
}
export function* watchGetFinancialRefundList() {
  yield takeLatest(
    action_types.GET_FINANCIAL_REFUND_LIST,
    getFinancialRefundList
  );
}
export function* watchRefundOrder() {
  yield takeLatest(action_types.REFUND_ORDER, refundOrder);
}
export function* watchGetFinancialOrderList() {
  yield takeLatest(
    action_types.GET_FINANCIAL_ORDER_LIST,
    getFinancialOrderList
  );
}

export default function* rootFinancial() {
  yield all([
    fork(watchGetFinancialOrderList),
    fork(watchRefundOrder),
    fork(watchGetFinancialRefundList),
    fork(watchSendDebt),
  ]);
}
