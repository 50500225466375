import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  createShopService,
  getShopsListService,
  getDetailShopServices,
  updateShopServices,
  getShopStatusListServices,
  getMessageSupportListService,
  updateMessageSupportService,
} from "../../services/ShopServices";
import store from "store2";
import {
  createShopSuccessfullAction,
  createShopFailedAction,
  getShopsListSuccessfullAction,
  getShopsListFailedAction,
  getShopsListAction,
  getShopDetailFailedAction,
  getShopDetailAction,
  getShopDetailSuccessfullAction,
  updateShopFailedAction,
  updateShopSuccessfullAction,
  getShopStatusListFailedAction,
  getShopStatusListSucccesfullAction,
  getShopStatusListAction,
  getMessageSupportListFailedAction,
  getMessageSupportListSuccessfullAction,
  getMessageSupportListAction,
  updateMessageSupportFailedAction,
  updateMessageSupportSuccessfullAction,
  generalSecondScreenShopListAction,
} from "./Actions";
import { getDropDownAction } from "../menu/Actions";
function* createShop({ payload }) {
  try {
    const response = yield call(
      createShopService,
      payload.login_key,
      payload.admin_id,
      payload.user_id,
      payload.title,
      payload.mobile,
      payload.address,
      payload.postcode,
      payload.city,
      payload.user_type
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(createShopSuccessfullAction(response.data.ResultMessage));
        yield put(
          getShopsListAction(
            payload.login_key,
            payload.user_id,
            response.data.Id,
            payload.admin_id,
            payload.user_type
          )
        );
      } else {
        yield put(createShopFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createShopFailedAction("Server error, try again"));
    }
  } catch (error) {
    yield put(createShopFailedAction(error.message));
  }
}
function* getShopsList({ payload }) {
  try {
    const response = yield call(
      getShopsListService,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Shopslist) {
          if (response.data.Shopslist.length > 0) {
            let second_screen_shop_list = [];
            for (let i = 0; i < response.data.Shopslist.length; i++) {
              second_screen_shop_list.push({
                ...response.data.Shopslist[i],
                selected: false,
                SearchTitle: `${response.data.Shopslist[i].ShopId}${response.data.Shopslist[i].Title}`,
              });
            }
            yield put(
              generalSecondScreenShopListAction(second_screen_shop_list)
            );
            let my_list = [];
            let selected_list = [];
            for (let i = 0; i < response.data.Shopslist.length; i++) {
              if (payload.shop_id) {
                if (payload.admin_id == 0 && payload.user_type != 10001201) {
                  my_list[i] = {
                    ...response.data.Shopslist[i],
                    value: response.data.Shopslist[i].ShopId,
                    label: `${response.data.Shopslist[i].ShopId} ${response.data.Shopslist[i].Title}`,
                    selected: false,
                  };
                  selected_list.push({
                    ...response.data.Shopslist[i],
                    value: response.data.Shopslist[i].ShopId,
                    label: `${response.data.Shopslist[i].ShopId} ${response.data.Shopslist[i].Title}`,
                    selected: false,
                  });
                } else {
                  if (response.data.Shopslist[i].ShopId == payload.shop_id) {
                    selected_list.push({
                      ...response.data.Shopslist[i],
                      value: response.data.Shopslist[i].ShopId,
                      label: `${response.data.Shopslist[i].ShopId} ${response.data.Shopslist[i].Title}`,
                      selected: false,
                    });
                    my_list[i] = {
                      ...response.data.Shopslist[i],
                      value: response.data.Shopslist[i].ShopId,
                      label: `${response.data.Shopslist[i].ShopId} ${response.data.Shopslist[i].Title}`,
                      selected: false,
                    };
                  } else {
                    my_list[i] = {
                      ...response.data.Shopslist[i],
                      value: response.data.Shopslist[i].ShopId,
                      label: `${response.data.Shopslist[i].ShopId} ${response.data.Shopslist[i].Title}`,
                      selected: false,
                    };
                  }
                }
              } else {
                if (i == 0) {
                  selected_list.push({
                    ...response.data.Shopslist[0],
                    value: response.data.Shopslist[0].ShopId,
                    label: `${response.data.Shopslist[0].ShopId} ${response.data.Shopslist[0].Title}`,
                    selected: false,
                  });
                }
                my_list[i] = {
                  ...response.data.Shopslist[i],
                  value: response.data.Shopslist[i].ShopId,
                  label: `${response.data.Shopslist[i].ShopId} ${response.data.Shopslist[i].Title}`,
                  selected: false,
                };
              }
            }

            yield put(getShopsListSuccessfullAction(my_list, selected_list));

            yield put(
              getDropDownAction(
                payload.login_key,
                payload.user_id,
                selected_list[0].ShopId
              )
            );
          } else {
            yield put(getShopsListFailedAction(""));
          }
        } else {
          yield put(getShopsListFailedAction(""));
        }
      } else {
        store.remove("selectedShop");
        yield put(getShopsListFailedAction("Shops list is empty"));
      }
    } else {
      store.remove("selectedShop");
      yield put(getShopsListFailedAction("Server error, try again"));
    }
  } catch (error) {
    store.remove("selectedShop");
    yield put(getShopsListFailedAction(error.message));
  }
}
function* getShopDetail({ payload }) {
  try {
    const response = yield call(
      getDetailShopServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Shop_Status_List.length > 0) {
          yield put(
            getShopDetailSuccessfullAction(response.data.Shop_Status_List)
          );
        } else {
          yield put(getShopDetailFailedAction());
        }
      } else {
        yield put(getShopDetailFailedAction());
      }
    } else {
      yield put(getShopDetailFailedAction());
    }
  } catch (error) {
    yield put(getShopDetailFailedAction());
  }
}

function* updateShop({ payload }) {
  try {
    const response = yield call(
      updateShopServices,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.title,
      payload.admin_id,
      payload.user_name,
      payload.pass,
      payload.mobile,
      payload.phone_number,
      payload.fax_number,
      payload.address,
      payload.city,
      payload.post_code,
      payload.active,
      payload.include_android_app,
      payload.include_ios_app,
      payload.send_sms_header,
      payload.send_email_header,
      payload.logo
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateShopSuccessfullAction(response.data.ResultMessage));
        yield put(
          getShopsListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateShopFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateShopFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateShopFailedAction(error.message));
  }
}

function* getShopStatusList({ payload }) {
  try {
    const response = yield call(
      getShopStatusListServices,
      payload.login_key,
      payload.user_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.shop_status_list.length > 0) {
          yield put(
            getShopStatusListSucccesfullAction(response.data.shop_status_list)
          );
        } else {
          yield put(getShopStatusListFailedAction(response.data.ResultMessage));
        }
      } else {
        yield put(getShopStatusListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getShopStatusListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getShopStatusListFailedAction(error.message));
  }
}
function* getMessageSupportList({ payload }) {
  try {
    const response = yield call(
      getMessageSupportListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Message_Support_List) {
          if (response.data.Message_Support_List.length > 0) {
            let my_list = [];
            for (
              let i = 0;
              i < response.data.Message_Support_List.length;
              i++
            ) {
              my_list.push({
                ...response.data.Message_Support_List[i],
                hasـanـanswer: response.data.Message_Support_List[i].AnswerDate
                  ? true
                  : false,
                show_edit_box: false,
              });
            }
            yield put(getMessageSupportListSuccessfullAction(my_list));
          } else {
            yield put(getMessageSupportListFailedAction(""));
          }
        } else {
          yield put(getMessageSupportListFailedAction("No message"));
        }
      } else {
        yield put(
          getMessageSupportListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getMessageSupportListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getMessageSupportListFailedAction(error.message));
  }
}
function* updateMessageSupport({ payload }) {
  try {
    const response = yield call(
      updateMessageSupportService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.user_answer
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateMessageSupportSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getMessageSupportListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
        yield put(getShopStatusListAction(payload.login_key, payload.user_id));
      } else {
        yield put(
          updateMessageSupportFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateMessageSupportFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateMessageSupportFailedAction(error.message));
  }
}
export function* watchUpdateMessageSupport() {
  yield takeLatest(action_types.UPDATE_MESSAGE_SUPPORT, updateMessageSupport);
}
export function* watchGetMessageSupportList() {
  yield takeLatest(
    action_types.GET_MESSAGE_SUPPORT_LIST,
    getMessageSupportList
  );
}
export function* watchGetShopStatusList() {
  yield takeLatest(action_types.GET_SHOP_STATUS_LIST, getShopStatusList);
}
export function* watchUpdateShop() {
  yield takeLatest(action_types.UPDATE_SHOP, updateShop);
}
export function* watchGetShopDetail() {
  yield takeLatest(action_types.GET_SHOP_DETAIL, getShopDetail);
}
export function* watchCreateShop() {
  yield takeLatest(action_types.CREATE_SHOP, createShop);
}
export function* watchGetShopsList() {
  yield takeLatest(action_types.GET_SHOPS_LIST, getShopsList);
}
export default function* rootShopSaga() {
  yield all([
    fork(watchCreateShop),
    fork(watchGetShopsList),
    fork(watchGetShopDetail),
    fork(watchUpdateShop),
    fork(watchGetShopStatusList),
    fork(watchGetMessageSupportList),
    fork(watchUpdateMessageSupport),
  ]);
}
