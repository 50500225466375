import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { setDefaultAction } from "../../../redux/menu/Actions";
import useScrollbarSize from "react-scrollbar-size";
import { primaryColor, main_nav_back_color } from "../../../constants/Colors";
import { main_nav_button_color } from "../../../constants/Colors";
import {
  visibleCreateLicencePopup,
  createLicenceAction,
} from "../../../redux/devices/Actions";
const LicencePopup = () => {
  const { height, width } = useScrollbarSize();
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { licence_options_id, licence_options } = useSelector(
    (state) => state.categoryList
  );
  const [licenceOptions, setLicenceOptions] = useState(null);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 500,
      backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const { selectd_licence, result_successfull, result_failed, result_message } =
    useSelector((state) => state.devicesWorkStationReduser);

  const handleCreateLicenceOption = () => {
    dispatch(
      createLicenceAction(
        login_key,
        selectd_licence.ShopId,
        selectd_licence.WorkStationId,
        selectd_licence.DeviceId,
        licenceOptions.value,
        user_id
      )
    );
  };

  // useEffect(() => {
  //   if (result_successfull) {
  //     dispatch(setDefaultAction());
  //     toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
  //       position: "top-center",
  //       duration: 3000,
  //       style: { backgroundColor: "green", color: "white" },
  //       icon: (
  //         <i
  //           style={{ fontSize: 40 }}
  //           class="fa fa-check-circle"
  //           aria-hidden="true"
  //         ></i>
  //       ),
  //     });
  //   } else if (result_failed) {
  //     dispatch(setDefaultAction());
  //     toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
  //       position: "top-center",
  //       duration: 3000,
  //       style: { backgroundColor: "red", color: "white" },
  //       icon: (
  //         <i
  //           style={{ fontSize: 40 }}
  //           class="fa fa-times-circle"
  //           aria-hidden="true"
  //         ></i>
  //       ),
  //     });
  //   }
  // }, [result_successfull, result_failed]);

  return (
    <div className=" create-licence-popup-container">
      <div
        className="create-licence-popup-content-container"
        style={{ height: "28%", width: "25%" }}
      >
        <div className="licence-popup-drop-down">
          <h6 className="h6 licence-popup-p ">{lang.licence_course}</h6>
          <Select
            options={licence_options}
            defaultValue={licenceOptions}
            isSearchable={false}
            onChange={(list, item) => setLicenceOptions(list)}
            value={licenceOptions}
            isMulti={false}
            styles={customStyles}
          />
        </div>
        <div
          className="submenu_popup_buttons_container"
          style={{
            position: "absolute",
            bottom: "0px",
            backgroundColor: " #f9f6f6",
          }}
        >
          <div
            style={{
              marginRight: 16 + width,
              borderTop: 0,
              marginLeft: "28px",
            }}
          >
            <button
              id="popup_cancel"
              onClick={() => dispatch(visibleCreateLicencePopup(false))}
            >
              {lang.cancel}
            </button>
            <button onClick={() => handleCreateLicenceOption()} id="popup_add">
              {lang.re_new}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicencePopup;
