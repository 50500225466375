export const financial_menu = [
  {
    id: 1,
    title: "Refund",
    icon: "fas fa-hand-holding-usd",
    expanded: false,
    path: "refund",
    sublist: [],
    access_id: 80,
  },
  {
    id: 2,
    title: "Debit",
    icon: "fa fa-money",
    expanded: false,
    path: "debt",
    sublist: [],
    isDisabled: false,
    access_id: 81,
  },
];

export const debit_filter = [
  { label: "All", value: 1 },
  { label: "Debit", value: 2 },
  { label: "Debited", value: 3 },
];
