import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import toast, { Toaster } from "react-hot-toast";
import { SketchPicker } from "react-color";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ListHeader from "../components/ListHeader";
import SaveMenuButton from "../components/SaveMenuButton";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import { image_Url } from "../constants/BaseUrl";
import { selectedProductSubmenuAction } from "../redux/product/Action";
import { updateProductSubmenuEditAction } from "../redux/product/Action";

const ProductSubmenuEdit = ({ setClickedItem }) => {
  const didMountRef = useRef();

  // const image_href = "http://45.131.139.146";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    category_list,
    selected_category,
    show_hide,
    printer_list,
    report_section,
    product_group,
    vat,
    font_size,
    font_family,
    updating_successfull,
    updating_failed,
    category_update_message,
    result_successfull,
  } = useSelector((state) => state.categoryList);
  const { submenu_list } = useSelector((state) => state.submenuRedusers);
  const { selected_Product_submenu, product_submenu_list } = useSelector(
    (state) => state.productList
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);

  const [appTitle, setAppTitle] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [kioskTitle, setKioskTitle] = useState("");
  const [kioskDesc, setKioskDesc] = useState("");
  const [appSort, setAppsort] = useState(0);
  const [webSort, setWebsort] = useState(0);
  const [kioskSort, setKiosksort] = useState(0);

  const [visibleOnApp, setVisibleOnApp] = useState(true);
  const [visibleOnWeb, setVisibleOnWeb] = useState(true);
  const [visibleOnKiosk, setVisibleOnKiosk] = useState(true);
  const [secoundLanguage, setSecoundLanguage] = useState("");

  const [src, setSrc] = useState(null);
  const [url, setUrl] = useState(null);
  const [href, setHref] = useState(null);

  const [appImage, setAppImage] = useState("");
  const [showAppImage, setShowAppImage] = useState(false);
  const [webImage, setWebImage] = useState("");
  const [showWebImage, setShowWebImage] = useState(false);
  const [kioskImage, setkioskImage] = useState("");
  const [showKioskImage, setShowKioskImage] = useState(false);
  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#14aeba");
  const [titleColor, setTitleColor] = useState("#d0021b");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [amount, setAmount] = useState(0);
  const [countItemForUseAmount, setCountItemForUseAmount] = useState(0);
  const [isFree, setIsFree] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isAfter, setIsAfter] = useState(false);
  const [minSelect, setMinSelect] = useState(1);
  const [maxSelect, setMaxSelect] = useState(1);
  const [baseName, setBaseName] = useState("");
  const [fontSize, setFontSize] = useState(null);
  const [printerId, setPrinterId] = useState([]);
  const [fontFamily, setFontFamily] = useState(null);
  const [width, setWidth] = useState(16);
  const [height, setHeight] = useState(20);
  const [oneOfEachItem, setOneOfEachItem] = useState(false);
  const [lastAppImageName, setLastAppImageName] = useState("");
  const [lastWebImageName, setLastWebImageName] = useState("");
  const [lastKioskImageName, setLastKiokImageName] = useState("");
  const [srcDimensions, setSrcDimensions] = useState({});
  const [urlDimensions, setUrlDimensions] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  const [key1, setKey1] = useState("");
  const [key2, setKey2] = useState("");
  const [deleteImageStatus, setDeleteImageStatus] = useState(false);
  const [submenuList, setSubmenuList] = useState([]);
  const [submenuIds, setSubmenuIds] = useState([]);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const image_href = selected_shop[0].Domain;
  // const image_href = selected_shop[0].SubDomain;
  const imageUrl = src;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const imageUrl1 = href;
  const loadImage1 = () => {
    const img = new Image();
    img.src = imageUrl1;

    img.onload = () => {
      setUrlDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleInputBackgroundColor = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleInputTitleColor = (e) => {
    setTitleColor(e.target.value);
  };

  const handleClose = () => {
    setShowColorPicker(false);
  };

  const handleCloseInputColor = () => {
    setShowSketchPicker(false);
  };

  const handleChangeCompleteBackgroundColor = (color) => {
    setBackgroundColor(color.hex);
    document.getElementById("input_backgroundColor").value = color.hex;
  };

  const handleOnclickeInput = () => {
    setShowSketchPicker(!showSketchPicker);
    setShowColorPicker(false);
  };

  const handleChangeCompleteTitleColor = (color) => {
    setTitleColor(color.hex);
    document.getElementById("inputColor").value = color.hex;
  };

  const handleOnclickeInputColor = () => {
    setShowColorPicker(!showColorPicker);
    setShowSketchPicker(false);
  };

  const onSelectFile = (e) => {
    setAppImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }
    setKey1(e);
  };

  const onSelectImage = (e) => {
    setWebImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const onSelectPicture = (e) => {
    setkioskImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    setKey2(e);
  };
  const formData1 = new FormData();
  const handleSavedClicked = () => {
    let printer_list_to_server_array = [];
    if (printerId) {
      for (let i = 0; i < printerId.length; i++) {
        printer_list_to_server_array.push(printerId[i].value);
      }
    }
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (appImage.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.app_image_size}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (webImage.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.web_image_size}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (kioskImage.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.kiosk_image_size}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      formData1.append("LoginKey", login_key);
      formData1.append("UserId", user_id);
      formData1.append("Id", selected_Product_submenu.Id);
      formData1.append("ShopId", selected_Product_submenu.ShopId);
      formData1.append("ProductId", selected_Product_submenu.ProductId);
      formData1.append("SubMenuId", selected_Product_submenu.SubMenuId);
      formData1.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
      formData1.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
      formData1.append(
        "WebTitle",
        webTitle
          ? webTitle.replaceAll(`"`, `\"\"`)
          : appTitle.replaceAll(`"`, `\"\"`)
      );
      formData1.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
      formData1.append(
        "KioskTitle",
        kioskTitle
          ? kioskTitle.replaceAll(`"`, `\"\"`)
          : appTitle.replaceAll(`"`, `\"\"`)
      );
      formData1.append("KioskDescription", kioskDesc.replaceAll(`"`, `\"\"`));
      formData1.append(
        "SecondLanguage",
        secoundLanguage.replaceAll(`"`, `\"\"`)
      );
      formData1.append("IsFree", isFree ? 1 : 0);
      formData1.append("Amount", amount ? parseFloat(amount) : 0);
      formData1.append("MinSelect", minSelect ? parseInt(minSelect) : 0);
      formData1.append("MaxSelect", maxSelect ? parseInt(maxSelect) : 0);
      formData1.append("AppVisible", visibleOnApp ? 1 : 0);
      formData1.append("WebVisible", visibleOnWeb ? 1 : 0);
      formData1.append("KioskVisible", visibleOnKiosk ? 1 : 0);
      formData1.append("AppSortId", appSort ? parseInt(appSort) : 0);
      formData1.append("WebSortId", webSort ? parseInt(webSort) : 0);
      formData1.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
      formData1.append("AppBgColor", backgroundColor);
      formData1.append("AppTitleFontSize", width ? parseInt(width) : 0);
      formData1.append(
        "AppTitleFontFamily",
        fontFamily ? fontFamily.value.toString() : String(4)
      );
      formData1.append("AppTitleColor", titleColor);
      formData1.append("AppTileWidth", width ? parseInt(width) : 0);
      formData1.append("AppTileHeight", height ? parseInt(height) : 0);
      formData1.append("IsFrist", isFirst ? 1 : 0);
      formData1.append(
        "PrinterId",
        printerId.length > 0 ? printer_list_to_server_array.toString() : ""
      );
      formData1.append("IsAfter", selectedSubmenu ? selectedSubmenu.value : 0);
      formData1.append(
        "CountItemForUseAmount",
        countItemForUseAmount ? parseInt(countItemForUseAmount) : 0
      );
      formData1.append("AppImageUrl", appImage);
      formData1.append("AppVisibleImage", showAppImage ? 1 : 0);
      formData1.append("WebImageUrl", webImage);
      formData1.append("WebVisibleImage", showWebImage ? 1 : 0);
      formData1.append("KioskImageUrl", kioskImage);
      formData1.append("KioskVisibleImage", showKioskImage ? 1 : 0);
      formData1.append("OneOfEachItem", oneOfEachItem ? 1 : 0);
      //  formData1.append("LastKioskImageUrlName", lastKioskImageName);
      formData1.append("LastAppImageUrlName", lastAppImageName);
      formData1.append("LastWebImageUrlName", lastWebImageName);
      formData1.append("JustTitleAndPrice", 0);
      formData1.append("DeleteImage", 0);
      dispatch(
        updateProductSubmenuEditAction(
          formData1,
          login_key,
          user_id,
          selected_Product_submenu.Id,
          selected_Product_submenu.ShopId,
          selected_Product_submenu.ProductId,
          selected_Product_submenu.SubMenuId,
          appTitle.replaceAll(`"`, `\"\"`),
          appDesc.replaceAll(`"`, `\"\"`),
          webTitle.replaceAll(`"`, `\"\"`),
          webDesc.replaceAll(`"`, `\"\"`),
          kioskTitle.replaceAll(`"`, `\"\"`),
          kioskDesc.replaceAll(`"`, `\"\"`),
          secoundLanguage.replaceAll(`"`, `\"\"`),
          isFree ? 1 : 0,
          amount ? parseFloat(amount) : 0,
          minSelect ? parseInt(minSelect) : 0,
          maxSelect ? parseInt(maxSelect) : 0,
          visibleOnApp ? 1 : 0,
          visibleOnWeb ? 1 : 0,
          visibleOnKiosk ? 1 : 0,
          appSort ? parseInt(appSort) : 0,
          webSort ? parseInt(webSort) : 0,
          kioskSort ? parseInt(kioskSort) : 0,
          backgroundColor,
          fontSize ? fontSize.value : 30,
          fontFamily ? fontFamily.value.toString() : String(4),
          titleColor,
          width ? parseInt(width) : 0,
          height ? parseInt(height) : 0,
          isFirst ? 1 : 0,
          printerId.length > 0 ? printer_list_to_server_array.toString() : "",
          isAfter ? 1 : 0,
          countItemForUseAmount ? parseInt(countItemForUseAmount) : 0,
          appImage,
          showAppImage ? 1 : 0,
          webImage,
          showWebImage ? 1 : 0,
          kioskImage,
          showKioskImage ? 1 : 0,
          oneOfEachItem ? 1 : 0
        )
      );
    }
  };

  const handleDeleteImage = (key) => {
    setDeleteImageStatus(true);
    if (key == 1) {
      setSrc("");
      setAppImage("");
      if (key1) {
        key1.target.value = null;
      }
      if (selected_Product_submenu.AppImageUrl != "") {
        if (
          `${image_Url}${image_href}/${selected_Product_submenu.AppImageUrl}` !=
          src
        ) {
          setSrc(
            `${image_Url}${image_href}/${selected_Product_submenu.AppImageUrl}`
          );
        } else {
          let printer_list_to_server_array = [];
          formData1.append("LoginKey", login_key);
          formData1.append("UserId", user_id);
          formData1.append("Id", selected_Product_submenu.Id);
          formData1.append("ShopId", selected_Product_submenu.ShopId);
          formData1.append("ProductId", selected_Product_submenu.ProductId);
          formData1.append("SubMenuId", selected_Product_submenu.SubMenuId);
          formData1.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
          formData1.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
          formData1.append(
            "WebTitle",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData1.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
          formData1.append(
            "KioskTitle",
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData1.append(
            "KioskDescription",
            kioskDesc.replaceAll(`"`, `\"\"`)
          );
          formData1.append(
            "SecondLanguage",
            secoundLanguage.replaceAll(`"`, `\"\"`)
          );
          formData1.append("IsFree", isFree ? 1 : 0);
          formData1.append("Amount", amount ? parseFloat(amount) : 0);
          formData1.append("MinSelect", minSelect ? parseInt(minSelect) : 0);
          formData1.append("MaxSelect", maxSelect ? parseInt(maxSelect) : 0);
          formData1.append("AppVisible", visibleOnApp ? 1 : 0);
          formData1.append("WebVisible", visibleOnWeb ? 1 : 0);
          formData1.append("KioskVisible", visibleOnKiosk ? 1 : 0);
          formData1.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData1.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData1.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData1.append("AppBgColor", backgroundColor);
          formData1.append("AppTitleFontSize", width ? parseInt(width) : 0);
          formData1.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData1.append("AppTitleColor", titleColor);
          formData1.append("AppTileWidth", width ? parseInt(width) : 0);
          formData1.append("AppTileHeight", height ? parseInt(height) : 0);
          formData1.append("IsFrist", isFirst ? 1 : 0);
          formData1.append(
            "PrinterId",
            printerId.length > 0 ? printer_list_to_server_array.toString() : ""
          );
          formData1.append(
            "IsAfter",
            selectedSubmenu ? selectedSubmenu.value : 0
          );
          formData1.append(
            "CountItemForUseAmount",
            countItemForUseAmount ? parseInt(countItemForUseAmount) : 0
          );
          formData1.append("AppImageUrl", "");
          formData1.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData1.append("WebImageUrl", webImage);
          formData1.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData1.append("KioskImageUrl", kioskImage);
          formData1.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData1.append("OneOfEachItem", oneOfEachItem ? 1 : 0);
          formData1.append("LastAppImageUrlName", lastAppImageName);
          formData1.append("LastWebImageUrlName", lastWebImageName);
          formData1.append("JustTitleAndPrice", 0);
          formData1.append("DeleteImage", 1);
          dispatch(
            updateProductSubmenuEditAction(
              formData1,
              login_key,
              user_id,
              selected_Product_submenu.Id,
              selected_Product_submenu.ShopId,
              selected_Product_submenu.ProductId,
              selected_Product_submenu.SubMenuId,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secoundLanguage.replaceAll(`"`, `\"\"`),
              isFree ? 1 : 0,
              amount ? parseFloat(amount) : 0,
              minSelect ? parseInt(minSelect) : 0,
              maxSelect ? parseInt(maxSelect) : 0,
              visibleOnApp ? 1 : 0,
              visibleOnWeb ? 1 : 0,
              visibleOnKiosk ? 1 : 0,
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              isFirst ? 1 : 0,
              printerId.length > 0
                ? printer_list_to_server_array.toString()
                : "",
              isAfter ? 1 : 0,
              countItemForUseAmount ? parseInt(countItemForUseAmount) : 0,
              appImage,
              showAppImage ? 1 : 0,
              webImage,
              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0,
              oneOfEachItem ? 1 : 0
            )
          );
        }
      }
    } else {
      setHref("");
      setkioskImage("");
      if (key2) {
        key2.target.value = null;
      }
      if (selected_Product_submenu.KioskImageUrl != "") {
        if (
          `${image_Url}${image_href}/${selected_Product_submenu.KioskImageUrl}` !=
          href
        ) {
          setHref(
            `${image_Url}${image_href}/${selected_Product_submenu.KioskImageUrl}`
          );
        } else {
          let printer_list_to_server_array = [];
          formData1.append("LoginKey", login_key);
          formData1.append("UserId", user_id);
          formData1.append("Id", selected_Product_submenu.Id);
          formData1.append("ShopId", selected_Product_submenu.ShopId);
          formData1.append("ProductId", selected_Product_submenu.ProductId);
          formData1.append("SubMenuId", selected_Product_submenu.SubMenuId);
          formData1.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
          formData1.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
          formData1.append(
            "WebTitle",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData1.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
          formData1.append(
            "KioskTitle",
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData1.append(
            "KioskDescription",
            kioskDesc.replaceAll(`"`, `\"\"`)
          );
          formData1.append(
            "SecondLanguage",
            secoundLanguage.replaceAll(`"`, `\"\"`)
          );
          formData1.append("IsFree", isFree ? 1 : 0);
          formData1.append("Amount", amount ? parseFloat(amount) : 0);
          formData1.append("MinSelect", minSelect ? parseInt(minSelect) : 0);
          formData1.append("MaxSelect", maxSelect ? parseInt(maxSelect) : 0);
          formData1.append("AppVisible", visibleOnApp ? 1 : 0);
          formData1.append("WebVisible", visibleOnWeb ? 1 : 0);
          formData1.append("KioskVisible", visibleOnKiosk ? 1 : 0);
          formData1.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData1.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData1.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData1.append("AppBgColor", backgroundColor);
          formData1.append("AppTitleFontSize", width ? parseInt(width) : 0);
          formData1.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData1.append("AppTitleColor", titleColor);
          formData1.append("AppTileWidth", width ? parseInt(width) : 0);
          formData1.append("AppTileHeight", height ? parseInt(height) : 0);
          formData1.append("IsFrist", isFirst ? 1 : 0);
          formData1.append(
            "PrinterId",
            printerId.length > 0 ? printer_list_to_server_array.toString() : ""
          );
          formData1.append(
            "IsAfter",
            selectedSubmenu ? selectedSubmenu.value : 0
          );
          formData1.append(
            "CountItemForUseAmount",
            countItemForUseAmount ? parseInt(countItemForUseAmount) : 0
          );
          formData1.append("AppImageUrl", appImage);
          formData1.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData1.append("WebImageUrl", webImage);
          formData1.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData1.append("KioskImageUrl", "");
          formData1.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData1.append("OneOfEachItem", oneOfEachItem ? 1 : 0);
          formData1.append("LastAppImageUrlName", lastAppImageName);
          formData1.append("LastWebImageUrlName", lastWebImageName);
          formData1.append("JustTitleAndPrice", 0);
          formData1.append("DeleteImage", 3);
          dispatch(
            updateProductSubmenuEditAction(
              formData1,
              login_key,
              user_id,
              selected_Product_submenu.Id,
              selected_Product_submenu.ShopId,
              selected_Product_submenu.ProductId,
              selected_Product_submenu.SubMenuId,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secoundLanguage.replaceAll(`"`, `\"\"`),
              isFree ? 1 : 0,
              amount ? parseFloat(amount) : 0,
              minSelect ? parseInt(minSelect) : 0,
              maxSelect ? parseInt(maxSelect) : 0,
              visibleOnApp ? 1 : 0,
              visibleOnWeb ? 1 : 0,
              visibleOnKiosk ? 1 : 0,
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              isFirst ? 1 : 0,
              printerId.length > 0
                ? printer_list_to_server_array.toString()
                : "",
              isAfter ? 1 : 0,
              countItemForUseAmount ? parseInt(countItemForUseAmount) : 0,
              appImage,
              showAppImage ? 1 : 0,
              webImage,
              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0,
              oneOfEachItem ? 1 : 0
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSavedClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    appTitle,
    appDesc,
    webTitle,
    webDesc,
    kioskTitle,
    kioskDesc,
    secoundLanguage,
    isFree,
    amount,
    minSelect,
    maxSelect,
    visibleOnApp,
    visibleOnWeb,
    visibleOnKiosk,
    appSort,
    webSort,
    kioskSort,
    backgroundColor,
    fontSize,
    fontFamily,
    titleColor,
    width,
    height,
    isFirst,
    printerId,
    isAfter,
    countItemForUseAmount,
    appImage,
    showAppImage,
    webImage,
    showWebImage,
    showKioskImage,
  ]);

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    let new_list = [];
    let sub_ids = [];
    if (submenu_list) {
      if (submenu_list.length > 0) {
        for (let i = 0; i < submenu_list.length; i++) {
          new_list.push({
            ...submenu_list[i],
            label: submenu_list[i].AppTitle,
            value: submenu_list[i].Id,
          });
          sub_ids.push(submenu_list[i].Id);
        }
      }
    }

    setSubmenuIds(sub_ids);
    setSubmenuList(new_list);
  }, [submenu_list]);

  useEffect(() => {
    let selected_submenu = null;
    if (selected_Product_submenu) {
      setBaseName(selected_Product_submenu.BaseName);
      setAppTitle(selected_Product_submenu.AppTitle);
      setAppDesc(selected_Product_submenu.AppDescription);
      setWebTitle(selected_Product_submenu.WebTitle);
      setWebDesc(selected_Product_submenu.WebDescription);
      setKioskTitle(selected_Product_submenu.KioskTitle);
      setKioskDesc(selected_Product_submenu.KioskDescription);
      setAppsort(selected_Product_submenu.AppSortId);
      setWebsort(selected_Product_submenu.WebSortId);
      setKiosksort(selected_Product_submenu.KioskSortId);

      if (selected_Product_submenu.IsNextSubMenuId) {
        if (
          submenuIds.includes(
            parseInt(selected_Product_submenu.IsNextSubMenuId)
          )
        ) {
          let index = submenuIds.indexOf(
            parseInt(selected_Product_submenu.IsNextSubMenuId)
          );

          selected_submenu = {
            label: submenuList[index].label,
            value: submenuList[index].value,
          };
        }
      }
      setSelectedSubmenu(selected_submenu);
      setIsAfter(selected_Product_submenu.IsAfter);
      setIsFirst(selected_Product_submenu.IsFrist);
      setIsFree(selected_Product_submenu.IsFree);
      setAmount(selected_Product_submenu.Amount);
      setMinSelect(selected_Product_submenu.MinSelect);
      setMaxSelect(selected_Product_submenu.MaxSelect);
      setSecoundLanguage(selected_Product_submenu.SecondLanguage);
      setCountItemForUseAmount(selected_Product_submenu.CountItemForUseAmount);
      setFontFamily(selected_Product_submenu.AppTitleFontFamily);
      setFontSize(selected_Product_submenu.AppTitleFontSize);
      setBackgroundColor(selected_Product_submenu.AppBgColor);
      setTitleColor(selected_Product_submenu.AppTitleColor);
      setHeight(selected_Product_submenu.AppTileHeight);
      setWidth(selected_Product_submenu.AppTileWidth);
      setPrinterId(selected_Product_submenu.PrinterId);
      setVisibleOnApp(selected_Product_submenu.AppVisible);
      setVisibleOnWeb(selected_Product_submenu.WebVisible);
      setVisibleOnKiosk(selected_Product_submenu.KioskVisible);

      // setSrc(
      //   `${image_href}${700 + selected_category.ShopId}/${
      //     selected_category.AppImageUrl
      //   }`
      // );

      // setHref(
      //   `${image_href}${700 + selected_category.ShopId}/${
      //     selected_category.KioskImageUrl
      //   }`
      // );
      if (selected_Product_submenu.AppImageUrl) {
        setSrc(
          `${image_Url}${image_href}/${selected_Product_submenu.AppImageUrl}`
        );
      } else {
        setSrc("");
      }
      if (selected_Product_submenu.KioskImageUrl) {
        setHref(
          `${image_Url}${image_href}/${selected_Product_submenu.KioskImageUrl}`
        );
      } else {
        setHref("");
      }

      setLastAppImageName(selected_category.AppImageUrl);

      setLastAppImageName(selected_Product_submenu.AppImageUrl);
      setLastWebImageName(selected_Product_submenu.WebImageUrl);
      // setLastKiokImageName(selected_Product_submenu.KioskImageUrl);
      setShowAppImage(selected_Product_submenu.AppVisibleImage);
      setShowWebImage(selected_Product_submenu.WebVisibleImage);
      setShowKioskImage(selected_Product_submenu.KioskVisibleImage);
      setOneOfEachItem(selected_Product_submenu.OneOfEachItem);
    }
  }, [selected_Product_submenu, submenuIds]);

  useEffect(() => {
    if (selected_category && didMountRef.current) {
      setClickedItem(2);
    }
    didMountRef.current = true;
  }, [selected_category]);

  useEffect(() => {
    if (result_successfull) {
      setClickedItem(6);
    }
  }, [result_successfull]);

  // useEffect(() => {
  //   if (selected_shop) {
  //     if (selected_shop.length > 0) {
  //       let base_url = `otopartnercentre.com:${
  //         700 + selected_shop[0].ShopId
  //       }/var/www/shop${selected_shop[0].ShopId}/`;
  //       setBaseUrl(base_url);
  //     }
  //   }
  // }, [selected_shop]);

  return (
    <div className="countaining-div">
      <ListHeader
        title={lang.edit_product_submenu}
        show_back={true}
        clickedItem={() => setClickedItem(6)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {/* TITLES */}
          <div className="div-1">
            {/* APP TITLE */}
            <div className="text ">
              <h6 className="title h6">{lang.app_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setAppTitle(e.target.value)}
                  type="text"
                  value={appTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setAppDesc(e.target.value)}
                  type="text"
                  value={appDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            {/* WEB TTILE */}
            <div className="text ">
              <h6 className="title h6">{lang.web_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setWebTitle(e.target.value)}
                  type="text"
                  value={webTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setWebDesc(e.target.value)}
                  type="text"
                  value={webDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            {/* KIOSK TITLE */}
            <div className="text ">
              <h6 className="title h6">{lang.kiosk_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setKioskTitle(e.target.value)}
                  type="text"
                  value={kioskTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setKioskDesc(e.target.value)}
                  type="text"
                  value={kioskDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
          </div>
          {/* *** */}

          {/* SORT */}
          <div className="div-3">
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={appSort}
                onChange={(e) => setAppsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={webSort}
                onChange={(e) => setWebsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={kioskSort}
                onChange={(e) =>
                  setKiosksort(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
          </div>
          {/* *** */}

          {/* IMAGE */}
          <div className="div-4 ">
            <div className=" text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectFile(e)}
                  className="file-i"
                />
              </div>
              <div className="image-item" style={{ position: "relative" }}>
                {src ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImage(1)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    src
                      ? src
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
            {/* <div className="text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectImage(e)}
                  className="file-i"
                />
              </div>
              <div className="image-item">
                <img src={url} className="images-style" />
              </div>
            </div> */}
            <div className=" text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectPicture(e)}
                  className="file-i"
                />
              </div>
              <div className="image-item" style={{ position: "relative" }}>
                {href ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImage(3)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}
                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    href
                      ? href
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage1}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
          </div>
          {/* IMAGE CHECK BOXES */}
          <div className="div-5">
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAppImage}
                      onChange={() => setShowAppImage(!showAppImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showWebImage}
                      onChange={() => setShowWebImage(!showWebImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showKioskImage}
                      onChange={() => setShowKioskImage(!showKioskImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="div-9" style={{ marginBottom: "-50px" }}>
              {/* BACKGROUND  COLOR */}
              <div className="countaining-colorPicker">
                <h6 className="h6">{lang.backgroundColor}</h6>
                <div className="bachgroundColor-div">
                  <div
                    className="bachgroundColor"
                    style={{ backgroundColor: backgroundColor }}
                  ></div>
                  <input
                    className="backgroundColor-input"
                    onMouseOver={handleOnclickeInput}
                    Value={backgroundColor}
                    onChange={handleInputBackgroundColor}
                    id="input_backgroundColor"
                  />
                </div>
                {showSketchPicker ? (
                  <div
                    className="SketchPicker-div"
                    onMouseLeave={handleCloseInputColor}
                  >
                    <SketchPicker
                      color={backgroundColor}
                      onChangeComplete={handleChangeCompleteBackgroundColor}
                    />
                  </div>
                ) : null}
              </div>

              {/* FONT COLOR */}
              <div className="countaining-colorPicker">
                <h6 className="h6">{lang.fontColor}</h6>
                <div className="bachgroundColor-div">
                  <div
                    className="bachgroundColor"
                    style={{ backgroundColor: titleColor }}
                  ></div>
                  <input
                    className="backgroundColor-input"
                    onMouseOver={handleOnclickeInputColor}
                    Value={titleColor}
                    onChange={handleInputTitleColor}
                    id="inputColor"
                  />
                </div>
                {showColorPicker ? (
                  <div className="SketchPicker-div" onMouseLeave={handleClose}>
                    <SketchPicker
                      color={titleColor}
                      onChangeComplete={handleChangeCompleteTitleColor}
                    />
                  </div>
                ) : null}
              </div>

              {/* FONT SIZE */}
              <div className="Appearance_information">
                <h6 className="h6-text h6">{lang.fontSize}</h6>
                <Select
                  options={font_size}
                  defaultValue={fontSize}
                  isSearchable={false}
                  onChange={(list, item) => setFontSize(list)}
                  value={fontSize}
                  styles={customStyles}
                  isClearable={false}
                />
              </div>
              {/* FONT FAMILY */}
              <div className="Appearance_information">
                <h6 className="h6-text h6">{lang.fontFamily}</h6>
                <Select
                  options={font_family}
                  defaultValue={fontFamily}
                  isSearchable={false}
                  onChange={(list, item) => {
                    setFontFamily(list);
                  }}
                  value={fontFamily}
                  styles={customStyles}
                  isClearable={false}
                />
              </div>
              {/* WIDTH */}
              <div className="Appearance_information">
                <h6 className="h6-text h6">{lang.width}</h6>
                <input
                  onChange={(e) => setWidth(e.target.value.replace(/\D/g, ""))}
                  type="text"
                  value={width}
                  className="Appearance_information_input input-padding"
                  // placeholder={lang.placeholderTitle}
                />
              </div>
              {/* HEIGHT */}
              <div className="Appearance_information">
                <h6 className="h6-text h6">{lang.height}</h6>
                <input
                  onChange={(e) => setHeight(e.target.value.replace(/\D/g, ""))}
                  type="text"
                  value={height}
                  className="Appearance_information_input input-padding"
                  // placeholder={lang.placeholderTitle}
                />
              </div>
            </div>

            {/* <div
              style={{
                height: "30px",
                width: "32.5%",
                // marginTop: "32px",
              }}
            >
              <h6 className="sort-title h6">{lang.base_name}</h6>
              <input
                className="sort-input1 input-padding"
                value={baseName}
                disabled
              />
            </div> */}
          </div>
          <div className="div-5">
            <div className="text">
              <h6 className="sort-title h6">{lang.base_name}</h6>
              <input
                className="sort-input1 input-padding"
                value={baseName}
                disabled
              />
            </div>
            <div className="text">
              <h6 className="h6-text h6">{lang.is_next}</h6>
              <Select
                options={submenuList}
                defaultValue={selectedSubmenu}
                isSearchable={false}
                onChange={(list, item) => {
                  setSelectedSubmenu(list);
                }}
                value={selectedSubmenu}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="div-3">
            <div className="text">
              <h6 className=" h6"> {lang.minSelect}</h6>
              <input
                className="PSE-input input-padding"
                onChange={(e) =>
                  setMinSelect(e.target.value.replace(/\D/g, ""))
                }
                value={minSelect}
              />
            </div>
            <div className="text ">
              <h6 className="h6"> {lang.maxSelect}</h6>
              <input
                className="PSE-input input-padding"
                onChange={(e) =>
                  setMaxSelect(e.target.value.replace(/\D/g, ""))
                }
                value={maxSelect}
              />
            </div>
            <div className="text">
              <h6 className=" h6">{lang.secoundLanguage}</h6>
              <input
                className="PSE-input input-padding"
                onChange={(e) => setSecoundLanguage(e.target.value)}
                value={secoundLanguage}
              />
            </div>
          </div>

          <div className="div-3">
            <div className="text">
              <h6 className=" h6">{lang.number_of_items_to_apply_price}</h6>
              <input
                className="PSE-input input-padding"
                value={countItemForUseAmount}
                onChange={(e) =>
                  setCountItemForUseAmount(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
            <div className="text">
              <h6 className=" h6"> {lang.amount}</h6>
              <input
                className="PSE-input input-padding"
                value={amount}
                onChange={(e) =>
                  setAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="text">
              <h6 className=" h6">{lang.printer_id}</h6>
              <Select
                options={printer_list}
                defaultValue={printerId}
                isSearchable={false}
                onChange={(list, item) => setPrinterId(list)}
                value={printerId}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>

          <div className="PSE-div9">
            {/* VISIBLE ON APP */}
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnApp}
                      onChange={() => setVisibleOnApp(!visibleOnApp)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_app}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON WEB */}
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnWeb}
                      onChange={() => setVisibleOnWeb(!visibleOnWeb)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_web}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON KIOSK */}
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnKiosk}
                      onChange={() => setVisibleOnKiosk(!visibleOnKiosk)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_kiosk}
                />
              </FormGroup>
            </div>
            {/* ***** */}
          </div>
          <div className="PSE-div9">
            {/* VISIBLE ON APP */}
            {/* <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAfter}
                      onChange={() => setIsAfter(!isAfter)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.beside_previous_item}
                />
              </FormGroup>
            </div> */}
            {/* ***** */}
            {/* VISIBLE ON WEB */}
            <div className=" div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFirst}
                      onChange={() => setIsFirst(!isFirst)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.is_first}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON KIOSK */}
            <div className="text div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFree}
                      onChange={() => setIsFree(!isFree)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.is_free}
                />
              </FormGroup>
            </div>
            <div className="text div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={oneOfEachItem}
                      onChange={() => setOneOfEachItem(!oneOfEachItem)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.one_of_each_item}
                />
              </FormGroup>
            </div>
            {/* ***** */}
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSavedClicked()} />
    </div>
  );
};

export default ProductSubmenuEdit;
