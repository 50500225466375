import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { image_Url } from "../../constants/BaseUrl";
import {
  getShopStatusListAction,
  showMessageSupportPopupAction,
  selectedShopStatusAction,
} from "../../redux/shop/Actions";
import Loader from "../../components/Loader";
import { List } from "./List";
import { setDefaultAction } from "../../redux/menu/Actions";
import ShopStatusHeader from "../../components/ShopStatusHeader";
import ShopStatusMainList from "../../components/ShopStatusMainList";
import { getMessageSupportListAction } from "../../redux/shop/Actions";
const ShopStatus = () => {
  const image_href = "http://37.32.4.85:800/";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [onlinesLength, setOnlinesLength] = useState(0);
  const [maintainecesLength, setMaintainecesLength] = useState(0);
  const [newMessageLength, setNewMessageLength] = useState(0);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const [shopStatusList, setShopStatusList] = useState([]);
  const [shopStatusList1, setShopStatusList1] = useState([]);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    shop_status_loader,
    shop_status_list,
    get_message_support_list,
    result_successfull,
    result_successfull_1,
  } = useSelector((state) => state.shopReducer);

  const { selected_shop_status, show_message_support_popup_loader } =
    useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);

  const handleClickedOnlineBox = () => {
    const copy_shopStatusList = [...shopStatusList1];
    let new_shopStatusList = [];
    if (copy_shopStatusList) {
      if (copy_shopStatusList.length > 0) {
        for (let index = 0; index < copy_shopStatusList.length; index++) {
          if (copy_shopStatusList[index].MaintenanceMode == 0) {
            new_shopStatusList.push(copy_shopStatusList[index]);
          }
        }
        for (let index = 0; index < copy_shopStatusList.length; index++) {
          if (copy_shopStatusList[index].MaintenanceMode == 1) {
            new_shopStatusList.push(copy_shopStatusList[index]);
          }
        }
      }
    }
    setShopStatusList(new_shopStatusList);
  };
  const handleClickedMaintenanceBox = () => {
    const copy_shopStatusList = [...shopStatusList1];
    let new_shopStatusList = [];
    if (copy_shopStatusList) {
      if (copy_shopStatusList.length > 0) {
        for (let index = 0; index < copy_shopStatusList.length; index++) {
          if (copy_shopStatusList[index].MaintenanceMode == 1) {
            new_shopStatusList.push(copy_shopStatusList[index]);
          }
        }
        for (let index = 0; index < copy_shopStatusList.length; index++) {
          if (copy_shopStatusList[index].MaintenanceMode == 0) {
            new_shopStatusList.push(copy_shopStatusList[index]);
          }
        }
      }
    }
    setShopStatusList(new_shopStatusList);
  };
  const habdleClickOnShop = (item) => {
    dispatch(getMessageSupportListAction(login_key, user_id, item.ShopId));

    dispatch(selectedShopStatusAction(item));
  };
  const handleClickedNewMessageBox = () => {
    const copy_shopStatusList = [...shopStatusList1];
    let new_shopStatusList = [];
    if (copy_shopStatusList) {
      if (copy_shopStatusList.length > 0) {
        for (let index = 0; index < copy_shopStatusList.length; index++) {
          if (copy_shopStatusList[index].NewMessage != 0) {
            new_shopStatusList.push(copy_shopStatusList[index]);
          }
        }
        for (let index = 0; index < copy_shopStatusList.length; index++) {
          if (copy_shopStatusList[index].NewMessage == 0) {
            new_shopStatusList.push(copy_shopStatusList[index]);
          }
        }
      }
    }
    setShopStatusList(new_shopStatusList);
  };
  useEffect(() => {
    let new_messages = 0;
    let onlineList = [];
    let maintainenceList = [];
    let onlineLength = 0;
    let maintainenceLength = 0;
    if (shopStatusList.length > 0) {
      for (let i = 0; i < shopStatusList.length; i++) {
        if (shopStatusList[i].MaintenanceMode == 0) {
          onlineList.push(shopStatusList[i]);
        } else {
          maintainenceList.push(shopStatusList[i]);
        }
        if (shopStatusList[i].NewMessage) {
          new_messages = new_messages + shopStatusList[i].NewMessage;
        }
      }
      setNewMessageLength(new_messages);
      onlineLength = onlineList.length;
      setOnlinesLength(onlineLength);
      maintainenceLength = maintainenceList.length;
      setMaintainecesLength(maintainenceLength);
    }
  }, [shopStatusList]);
  useEffect(() => {
    dispatch(getShopStatusListAction(login_key, user_id));
  }, []);

  useEffect(() => {
    let new_shopStatusList = [];
    if (shop_status_list.length > 0) {
      for (let index = 0; index < shop_status_list.length; index++) {
        if (shop_status_list[index].NewMessage != 0) {
          new_shopStatusList.push(shop_status_list[index]);
        }
      }
      for (let index = 0; index < shop_status_list.length; index++) {
        if (shop_status_list[index].NewMessage == 0) {
          new_shopStatusList.push(shop_status_list[index]);
        }
      }

      setShopStatusList(new_shopStatusList);
      setShopStatusList1(new_shopStatusList);
    }
  }, [shop_status_list]);

  useEffect(() => {
    if (result_successfull_1) {
      dispatch(setDefaultAction());
      if (get_message_support_list) {
        if (get_message_support_list.length > 0) {
          dispatch(showMessageSupportPopupAction(true));
        }
      }
    }
  }, [get_message_support_list, result_successfull_1]);

  return (
    <div className="countaining-div-11 customer_container">
      <div className="customer-container-div">
        <div className="shop_status_container_header">
          <div
            className="shop_status_length_boxes-1"
            onClick={handleClickedOnlineBox}
          >
            {" "}
            <h2 style={{ margin: 0 }}>{onlinesLength}</h2>
            <p>{lang.online}</p>
          </div>
          <div
            className="shop_status_length_boxes-1 shop_status_length_boxes_online_1"
            onClick={handleClickedNewMessageBox}
          >
            <div>
              <h2 style={{ margin: 0 }}>{newMessageLength}</h2>{" "}
              <p>{lang.new_message}</p>
            </div>
          </div>
          <div
            className="shop_status_length_boxes-1 shop_status_length_boxes_maintanance_1"
            onClick={handleClickedMaintenanceBox}
          >
            <h2 style={{ margin: 0 }}>{maintainecesLength}</h2>
            <p>{lang.maintainence}</p>
          </div>
        </div>
        <div className="shop_status_container_main_section">
          <div className="shop_status_header_list">
            <ShopStatusHeader />
          </div>

          {shopStatusList.map((item, index) => (
            <div className="shop_status_main_list">
              <ShopStatusMainList
                index1={index}
                logo={
                  item.Logo
                    ? `${image_Url}${item.Domain}/${item.Logo}`
                    : "https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder.jpg"
                }
                shop_name={item.Title}
                shop_id={item.ShopId}
                domain={item.Domain}
                open_close_time={item.Created_at}
                status={item.MaintenanceMode}
                new_message={item.NewMessage}
                old_message={item.OldMessage}
                onClick={() => habdleClickOnShop(item)}
              />
              <div
                className="container_right_bar"
                style={{
                  backgroundColor:
                    item.MaintenanceMode == 0 ? "rgb(8, 168, 8)" : "red",
                }}
              ></div>
            </div>
          ))}
        </div>
        <Toaster />
        {show_message_support_popup_loader || shop_status_loader ? (
          <Loader />
        ) : null}
      </div>
    </div>
  );
};

export default ShopStatus;
