import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
import {
  getDeliverySettingAction,
  updateDeliverySettingAction,
} from "../../../../redux/settings/Action";

const DeliverySetting = () => {
  const dispatch = useDispatch();
  const { get_delivery_setting_list } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const { delivery_charge_type, delivery_charge_type_id } = useSelector(
    (state) => state.categoryList
  );
  const [minOrderValue, setMinOrderValue] = useState("");
  const [minOrderChargeMessage, setMinOrderChargeMessage] = useState("");
  const [spicificAmount, setSpicificAmount] = useState();
  useState("");
  const [printFreeDeliveryMessage, setPrintFreeDeliveryMessage] = useState("");

  const [defaultDeliveryChargeTypeId, setDefaultDeliveryChargeTypeId] =
    useState(null);
  const [minimumAcceptedMilMessage, setMinimumAcceptedMilMessage] =
    useState("");
  const [
    minOrderAmountToFreeDeliveryCharge,
    setMinOrderAmountToFreeDeliveryCharge,
  ] = useState(0);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleUpdateDeliverySetting = () => {
    if (printFreeDeliveryMessage == "" || !defaultDeliveryChargeTypeId) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateDeliverySettingAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          get_delivery_setting_list[0].Id,

          defaultDeliveryChargeTypeId.value,
          spicificAmount ? parseInt(spicificAmount) : 0,
          printFreeDeliveryMessage.replaceAll(`"`, `\"\"`)
        )
      );
    }
  };

  useEffect(() => {
    if (get_delivery_setting_list.length > 0) {
      let defaultDeliveryCharge = [];
      for (let i = 0; i < get_delivery_setting_list.length; i++) {
        if (get_delivery_setting_list[i].DefaultDeliveryChargeTypeId) {
          if (
            delivery_charge_type_id.includes(
              parseInt(get_delivery_setting_list[i].DefaultDeliveryChargeTypeId)
            )
          ) {
            let index = delivery_charge_type_id.indexOf(
              get_delivery_setting_list[i].DefaultDeliveryChargeTypeId
            );
            defaultDeliveryCharge = {
              label: delivery_charge_type[index].label,
              value: delivery_charge_type[index].value,
            };
          }
        }
      }

      get_delivery_setting_list[0].DefaultDeliveryChargeTypeId =
        defaultDeliveryCharge;
      setMinOrderValue(get_delivery_setting_list[0].MinimumOrderValue);

      setMinOrderChargeMessage(
        get_delivery_setting_list[0].MinimumOrderChargeMessage
      );
      setSpicificAmount(get_delivery_setting_list[0].SpicificAmount);

      setPrintFreeDeliveryMessage(
        get_delivery_setting_list[0].PrintFreeDeliveryMessage
      );

      setDefaultDeliveryChargeTypeId(
        get_delivery_setting_list[0].DefaultDeliveryChargeTypeId
      );

      setMinOrderAmountToFreeDeliveryCharge(
        get_delivery_setting_list[0].minimum_free_delivery
      );
    }
  }, [get_delivery_setting_list]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getDeliverySettingAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleUpdateDeliverySetting();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    minimumAcceptedMilMessage,
    printFreeDeliveryMessage,
    spicificAmount,
    defaultDeliveryChargeTypeId,
    minOrderValue,
    minOrderAmountToFreeDeliveryCharge,
  ]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
      id="countaining"
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="div-3">
            <div className="text" style={{ width: "47%" }}>
              <h6 className="sort-title h6">{lang.spicific_amount}</h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={spicificAmount}
                onChange={(e) =>
                  setSpicificAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="text" style={{ width: "47%" }}>
              <h6 className="sort-title h6">
                * {lang.Print_free_delivery_message}
              </h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={printFreeDeliveryMessage}
                onChange={(e) => setPrintFreeDeliveryMessage(e.target.value)}
              />
            </div>
            {/* <div className="text" style={{ width: "47%" }}>
              <h6 className="sort-title h6">{lang.min_order_value}</h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={minOrderValue}
                onChange={(e) =>
                  setMinOrderValue(e.target.value.replace(/\D/g, ""))
                }
              />
            </div> */}
          </div>

          <div className="div-3">
            <div className="text" style={{ width: "47%" }}>
              <h6 className="sort-title h6">
                * {lang.default_delivery_charge_type_id}
              </h6>
              <Select
                options={delivery_charge_type}
                defaultValue={defaultDeliveryChargeTypeId}
                isSearchable={false}
                onChange={(list, item) => setDefaultDeliveryChargeTypeId(list)}
                value={defaultDeliveryChargeTypeId}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="div-3">
            {/* <div className="text" style={{ width: "47%" }}>
              <h6 className="sort-title h6">
                {lang.minimum_order_amount_to_free_delivery_charge}
              </h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={minOrderAmountToFreeDeliveryCharge}
                onChange={(e) =>
                  setMinOrderAmountToFreeDeliveryCharge(
                    e.target.value.replace(/[^\d\.,£]+/g, "")
                  )
                }
              />
            </div> */}
            {/* <div className="text" style={{ width: "47%" }}>
              <h6 className="sort-title h6">
                * {lang.min_order_charge_message}
              </h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={minOrderChargeMessage}
                onChange={(e) => setMinOrderChargeMessage(e.target.value)}
              />
            </div> */}
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={handleUpdateDeliverySetting} />
    </div>
  );
};

export default DeliverySetting;
