import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import store from "store2";
import ListHeader from "../../../components/ListHeader";
import MenuListItem from "../../../components/ListHeader";
import AddMenuItem from "../../../components/ListHeader";
import {
  createProductAction,
  getProductAction,
  selectedProductAction,
  deleteProductAction,
  selectedProductOptionListAction,
  selectedProductSubmenuAction,
} from "../../../redux/product/Action";
import {
  changeSelectedButtonBorderColorOnFocus,
  clickedItemAction,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
  editProductStatusAction,
} from "../../../redux/menu/Actions";
import { receiveLoginResponse } from "../../../redux/login/Actions";
import { primaryColor } from "../../../constants/Colors";
const MobileProductsList = ({ setClickedItem1, productListInMenuStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { product_list, selected_Product } = useSelector(
    (state) => state.productList
  );
  const {
    result_category_visible_successfull,
    category_list,
    selected_category,
    show_hide,
    show_hide_web,
    show_hide_kiosk,
    printer_list,
    printer_list_id,
    report_section,
    report_section_id,
    product_group,
    product_group_id,
    vat,
    vat_id,
    font_family,
    font_family_id,
    font_size,
    font_size_id,
    link_to,
    link_to_id,
    change_selected_button_border_color_on_focus,
    result_successfull,
  } = useSelector((state) => state.categoryList);

  const [productList, setProductList] = useState([]);

  const [titleProduct, setTitleProduct] = useState("");
  const [descProduct, setDescProduct] = useState("");
  const [id, setId] = useState(0);

  const handleOnclickButton = (title, desc, amount) => {
    dispatch(
      createProductAction(
        login_key,
        user_id,
        selected_category.ShopId,
        selected_category.Id,
        title.replaceAll(`"`, `\"\"`),
        desc.replaceAll(`"`, `\"\"`),
        amount ? parseFloat(amount) : 0
      )
    );

    document.getElementById("countaining").scroll({
      top: document.getElementById("countaining").scrollHeight,
      behavior: "smooth",
    });
  };

  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...productList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
    }
    setProductList(copy_product_list);
  };
  const hanleEditProductVisible = (item) => {
    const copy_productList = [...productList];
    if (copy_productList) {
      if (copy_productList.length > 0) {
        for (let i = 0; i < copy_productList.length; i++) {
          if (copy_productList[i].Id == item.Id) {
            if (item.WebVisible[0] == "0") {
              item.WebVisible[0] = "5";
            } else {
              item.WebVisible[0] = "0";
            }
          }
        }
      }
    }
    setProductList(copy_productList);

    dispatch(
      editProductStatusAction(
        login_key,
        user_id,
        item.ShopId,
        item.WebVisible[0].toString(),
        item.Id
      )
    );
  };

  useEffect(() => {
    if (selected_category) {
      dispatch(
        getProductAction(
          login_key,
          user_id,
          selected_category.ShopId,
          selected_category.Id
        )
      );
    }
  }, []);

  useEffect(() => {
    if (product_list) {
      setProductList(product_list);
    } else {
      setProductList([]);
    }
  }, [product_list]);

  useEffect(() => {
    if (selected_category) {
      dispatch(
        getProductAction(
          login_key,
          user_id,
          selected_category.ShopId,
          selected_category.Id
        )
      );
    } else {
      setProductList([]);
    }
  }, [selected_category]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_product_list = [...productList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (copy_product_list[index].showEditBox) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setProductList(copy_product_list);
    dispatch(justTitleAndPriceAction(1));

    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(justTitleAndPriceAction(0));
      dispatch(selectedOtemInAllLists(null));
    };
  }, [change_selected_button_border_color_on_focus]);

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: primaryColor,
      "&:hover": {
        backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: primaryColor,
    },
  }));
  useEffect(() => {
    setProductList(productListInMenuStatus);
  }, [productListInMenuStatus]);
  return (
    <div
      className="countaining-div"
      id="countaining"
      onClick={handleCloseEditBoxWithOnBlur}
    >
      <ListHeader
        title={lang.products_list}
        show_back={true}
        clickedItem={() => setClickedItem1(1)}
      />
      <div className="menu_list_header_container">
        {productList.length > 0 ? (
          <div>
            {productList.map((item, index) => (
              <div>
                {!item.IsDeleted ? (
                  <div className="menu_list_item_container">
                    {" "}
                    <div
                      className="menu_list_title_container"
                      style={{ marginRight: "0px" }}
                    >
                      <h6>{item.AppTitle}</h6>
                      <GreenSwitch
                        disabled={
                          selected_category.WebVisible
                            ? selected_category.WebVisible.length > 0
                              ? selected_category.WebVisible[0].value == 0
                                ? true
                                : false
                              : null
                            : null
                        }
                        checked={item.WebVisible[0] == "0" ? false : true}
                        onChange={() => hanleEditProductVisible(item)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <div className=" mobile-container-noOption">
            <h2 id="noOption">{lang.noOption}</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileProductsList;
