import * as actionType from "../ActionTypes";

export const loginAction = (lat, long, user_name, password) => {
  return {
    type: actionType.LOGIN,
    payload: { lat, long, user_name, password },
  };
};
export const loginSuccessfull = (data) => {
  return {
    type: actionType.LOGIN_SUCCESSFULL,
    payload: data,
  };
};
export const loginFailed = (data) => {
  return {
    type: actionType.LOGIN_FAILED,
    payload: data,
  };
};
export const setDefaultLogin = () => {
  return {
    type: actionType.SET_DEFAULT_LOGIN,
  };
};

export const updateProfileAction = (
  login_key,
  profile_image,
  id,
  first_name,
  last_name,
  mobile,
  address,

  lastImageProfileName
) => {
  return {
    type: actionType.UPDATE_PROFILE,
    payload: {
      login_key,
      profile_image,
      id,
      first_name,
      last_name,
      mobile,
      address,

      lastImageProfileName,
    },
  };
};
// export const sendImageFormatAction = (data) => {
//   return {
//     type: actionType.SEND_IMAGE_FORMAT,
//     payload: data,
//   };
// };
export const updateProfileSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_PROFILE_SUCCESSFULL,
    payload: data,
  };
};
export const updateProfileFailedAction = (data) => {
  return {
    type: actionType.UPDATE_PROFILE_FAILED,
    payload: data,
  };
};

export const sendLoginParameterToProfile = (data) => {
  return {
    type: actionType.SEND_LOGIN_PARAMETERS_TO_PROFILE,
    payload: data,
  };
};
export const setDefaultProfile = () => {
  return {
    type: actionType.SET_DEFAULT_PROFILE,
  };
};
export const receiveLoginResponse = (data) => {
  return {
    type: actionType.RECEIVE_LOGIN_RESPONSE,
    payload: data,
  };
};
//  export const showFropileImageAction=(data)=>{
//   return{
//     type:actionType.SHOW_PROFILE_IMAGE,
//     payload:data
//   }
//  }

export const sendOTPLoginAction = (login_key, user_id, mobile) => {
  return {
    type: actionType.SEND_OPT_LOGIN,
    payload: { login_key, user_id, mobile },
  };
};

export const sendOTPLoginSuccessfullAction = (data) => {
  return {
    type: actionType.SEND_OPT_LOGIN_SUCCESSFULL,
    payload: data,
  };
};

export const sendOTPLoginFailedAction = (data) => {
  return {
    type: actionType.SEND_OPT_LOGIN_FAILED,
    payload: data,
  };
};

//VERIDY OTP LOGIN
export const verifyOTPLoginAction = (login_key, user_id, mobile, OTP_code) => {
  return {
    type: actionType.VERIFY_OTP_LOGIN,
    payload: { login_key, user_id, mobile, OTP_code },
  };
};

export const verifyOTPLoginSuccessfullAction = (data) => {
  return {
    type: actionType.VERIFY_OTP_LOGIN_SUCCESSFULL,
    payload: data,
  };
};

export const verifyOTPLoginFailedAction = (data) => {
  return {
    type: actionType.VERIFY_OTP_LOGIN_FAILED,
    payload: data,
  };
};

// ACTIVE TWO FACTOR
export const activeTwoFactorAuthAction = (
  login_key,
  user_id,
  active_two_factor
) => {
  return {
    type: actionType.ACTIVE_TWO_FACTOR_AUTH,
    payload: {
      login_key,
      user_id,
      active_two_factor,
    },
  };
};

export const activeTwoFactorAuthSuccessfullAction = (data) => {
  return {
    type: actionType.ACTIVE_TWO_FACTOR_AUTH_SUCCESSFULL,
    payload: data,
  };
};

export const activeTwoFactorAuthFailedAction = (data) => {
  return {
    type: actionType.ACTIVE_TWO_FACTOR_AUTH_FAILED,
    payload: data,
  };
};
