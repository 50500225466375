import * as actionTypes from "../ActionTypes";

const initialStates = {
  show_create_shop_popup: false,
  visible_shop_craeting_loader: false,
  result_successfull: false,
  result_successfull_1: false,
  result_failed: false,
  result_message: "",
  shops_list: [],
  selected_shop: [],
  get_shop_detail: [],
  shop_status_list: [],
  shop_status_loader: false,
  get_new_shop_list: [],
  show_message_support_popup: false,
  get_message_support_list: [],
  show_message_support_popup_loader: false,
  selected_shop_status: null,
  general_second_screen_shop_list: [],
};

const shopReducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_successfull_1: false,
        result_message: "",
        show_message_support_popup_loader: false,
      };
    case actionTypes.VISIBLE_SHOP_CREATE_POPUP:
      return {
        ...state,
        show_create_shop_popup: action.payload,
      };
    case actionTypes.GET_NEW_SHOP_LIST:
      return {
        ...state,
        get_new_shop_list: action.payload,
      };
    case actionTypes.CREATE_SHOP:
      return {
        ...state,
        visible_shop_craeting_loader: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.CREATE_SHOP_SUCCESSFULL:
      return {
        ...state,
        show_create_shop_popup: false,
        visible_shop_craeting_loader: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.CREATE_SHOP_FAILED:
      return {
        ...state,

        visible_shop_craeting_loader: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.SET_DEFAULT_CREATE_SHOP_STATUS:
      return {
        ...state,

        visible_shop_craeting_loader: false,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    // GET SHOPS LIST
    case actionTypes.GET_SHOPS_LIST:
      return {
        ...state,

        visible_shop_craeting_loader: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.GET_SHOPS_LIST_SUCCESSFULL:
      return {
        ...state,
        visible_shop_craeting_loader: false,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        shops_list: action.payload.shops_list,
        selected_shop: action.payload.selected_shop,
      };
    case actionTypes.GET_SHOPS_LIST_FAILED:
      return {
        ...state,
        visible_shop_craeting_loader: false,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        shops_list: action.payload,
      };
    case actionTypes.GET_SHOP_DETAIL:
      return {
        ...state,
        visible_shop_craeting_loader: true,
      };
    case actionTypes.GET_SHOP_DETAIL_SUCCESSFULL:
      return {
        ...state,
        visible_shop_craeting_loader: false,
        get_shop_detail: action.payload,
      };
    case actionTypes.GET_SHOP_DETAIL_FAILED:
      return {
        ...state,
        visible_shop_craeting_loader: false,
        get_shop_detail: [],
      };
    case actionTypes.UPDATE_SHOP:
      return {
        ...state,
        visible_shop_craeting_loader: true,
        result_message: "",
        result_successfull: false,
        result_failed: false,
      };
    case actionTypes.UPDATE_SHOP_SUCCESSFULL:
      return {
        ...state,
        visible_shop_craeting_loader: false,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
      };
    case actionTypes.UPDATE_SHOP_FAILED:
      return {
        ...state,
        visible_shop_craeting_loader: false,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
      };
    case actionTypes.GET_SHOP_STATUS_LIST:
      return {
        ...state,
        shop_status_loader: true,
      };
    case actionTypes.GET_SHOP_STATUS_LIST_SUCCESSFULL:
      return {
        ...state,
        shop_status_loader: false,
        shop_status_list: action.payload,
      };
    case actionTypes.GET_SHOP_STATUS_LIST_FAILED:
      return {
        ...state,
        shop_status_loader: false,
        shop_status_list: [],
        result_failed: true,
        result_message: action.payload,
      };

    //MESSAGE SUPPORT
    case actionTypes.SHOW_MESSAGE_SUPPORT_POPUP:
      return {
        ...state,
        show_message_support_popup: action.payload,
      };
    case actionTypes.GET_MESSAGE_SUPPORT_LIST:
      return {
        ...state,
        show_message_support_popup_loader: true,
        result_successfull_1: false,
      };
    case actionTypes.GET_MESSAGE_SUPPORT_LIST_SUCCESSFULL:
      return {
        ...state,
        show_message_support_popup_loader: false,
        result_successfull_1: true,
        result_message: "",
        get_message_support_list: action.payload,
      };
    case actionTypes.GET_MESSAGE_SUPPORT_LIST_FAILED:
      return {
        ...state,
        show_message_support_popup_loader: false,
        get_message_support_list: [],
        result_failed: true,
        result_successfull_1: false,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_MESSAGE_SUPPORT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        show_message_support_popup_loader: true,
      };
    case actionTypes.UPDATE_MESSAGE_SUPPORT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        show_message_support_popup_loader: false,
      };
    case actionTypes.UPDATE_MESSAGE_SUPPORT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        show_message_support_popup_loader: false,
      };
    case actionTypes.SELECTED_SHOP_STATUS:
      return {
        ...state,
        selected_shop_status: action.payload,
      };
    case actionTypes.GENERAL_SECOND_SCREEN_SHOP_LIST:
      return {
        ...state,
        general_second_screen_shop_list: action.payload,
      };
    default:
      return state;
  }
};
export default shopReducer;
