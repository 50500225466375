//GET ALL APP SETTING
import * as actionType from "../ActionTypes";

export const getAllAppSettingsAction = (login_key, user_id, shopId) => {
  return {
    type: actionType.GET_ALL_APP_SETTINGS,
    payload: { login_key, user_id, shopId },
  };
};
export const getAllAppSettingsSuccessfullAction = (data) => {
  return {
    type: actionType.GET_ALL_APP_SETTINGS_SUCCESSFULL,
    payload: data,
  };
};
export const getAllAppSettingFailedAction = () => {
  return {
    type: actionType.GET_ALL_APP_SETTINGS_FAILED,
  };
};
export const updateShopSettingAppOrderProcessAction = (
  login_key,
  user_id,
  shopId,
  prefix_yes_for_submenu,
  prefix_no_for_submenu,
  total_half_text,
  users_need_to_login_before_taking_order,
  use_table_area,
  ask_number_guests,
  ask_customer_on_collection,
  ask_customer_on_takeaway,
  show_tip_in_eat_in,
  show_tip_in_takeaway
) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS,
    payload: {
      login_key,
      user_id,
      shopId,
      prefix_yes_for_submenu,
      prefix_no_for_submenu,
      total_half_text,
      users_need_to_login_before_taking_order,
      use_table_area,
      ask_number_guests,
      ask_customer_on_collection,
      ask_customer_on_takeaway,
      show_tip_in_eat_in,
      show_tip_in_takeaway,
    },
  };
};
export const updateShopSettingAppOrderProcessSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS_SUCCESSFULL,
    payload: data,
  };
};
export const updateShopSettingAppOrderProcessFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS_FAILED,
    payload: data,
  };
};
export const updateShopSettingAppPaymentAction = (
  login_key,
  user_id,
  shopId,
  use_peyment_method,
  delivery_default_peyment_method,
  collection_default_peyment_method,
  takeaway_default_peyment_method,
  eat_in_default_peyment_method,
  show_not_paid_in_delivery,
  show_not_paid_in_collection,
  show_not_paid_in_takeaway,
  show_not_paid_in_eatIn
) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_PAYMENT,
    payload: {
      login_key,
      user_id,
      shopId,
      use_peyment_method,
      delivery_default_peyment_method,
      collection_default_peyment_method,
      takeaway_default_peyment_method,
      eat_in_default_peyment_method,
      show_not_paid_in_delivery,
      show_not_paid_in_collection,
      show_not_paid_in_takeaway,
      show_not_paid_in_eatIn,
    },
  };
};
export const updateShopSettingAppPaymentSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_PAYMENT_SUCCESSFULL,
    payload: data,
  };
};
export const updateShopSettingAppPaymentFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_PAYMENT_FAILED,
    payload: data,
  };
};
export const updateShopSettingAppColourAction = (
  login_key,
  user_id,
  shopId,
  display_order_lasting_morethan_minutes,
  order_queue_list_colour,
  assing_driver_flashing_colour,
  printed_orders_colour
) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_COLOUR,
    payload: {
      login_key,
      user_id,
      shopId,
      display_order_lasting_morethan_minutes,
      order_queue_list_colour,
      assing_driver_flashing_colour,
      printed_orders_colour,
    },
  };
};
export const updateShopSettingAppColourSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_COLOUR_SUCCESSFULL,
    payload: data,
  };
};

export const updateShopSettingAppColourFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_COLOUR_FAILED,
    payload: data,
  };
};
export const updateShopSettingAppDisplayAction = (
  login_key,
  user_id,
  shopId,
  display_previous_order,
  show_basket_in_second_screen,
  delivery_description,
  collection_description,
  takeaway_description,
  eatIn_description,
  web_del_description,
  web_coll_description,
  kiosk_takeaway_description,
  kiosk_eatIn_description,
  show_barcode,
  show_not_paid_finishing_order_in_collection,
  show_not_paid_finishing_order_in_eatIn,
  shop_opening_time,
  shop_closing_time,
  second_language
) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_DISPLAY,
    payload: {
      login_key,
      user_id,
      shopId,
      display_previous_order,
      show_basket_in_second_screen,
      delivery_description,
      collection_description,
      takeaway_description,
      eatIn_description,
      web_del_description,
      web_coll_description,
      kiosk_takeaway_description,
      kiosk_eatIn_description,
      show_barcode,
      show_not_paid_finishing_order_in_collection,
      show_not_paid_finishing_order_in_eatIn,
      shop_opening_time,
      shop_closing_time,
      second_language,
    },
  };
};

export const updateShopSettingAppDisplaySuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_DISPLAY_SUCCESSFULL,
    payload: data,
  };
};

export const updateShopSettingAppDisplayFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_DISPLAY_FAILED,
    payload: data,
  };
};

export const updateShopSettingAppPasswordProtectAction = (
  login_key,
  user_id,
  shopId,
  driver_float,
  driver_payment,
  takings,
  edit_order,
  driver_analysis,
  order_line_add_change,
  data_export_to_excel,
  discount,
  timeSheet,
  cancel_order,
  maintenance,
  list_all_order,
  system_setup
) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT,
    payload: {
      login_key,
      user_id,
      shopId,
      driver_float,
      driver_payment,
      takings,
      edit_order,
      driver_analysis,
      order_line_add_change,
      data_export_to_excel,
      discount,
      timeSheet,
      cancel_order,
      maintenance,
      list_all_order,
      system_setup,
    },
  };
};

export const updateShopSettingAppPasswordProtectSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT_SUCCESSFULL,
    payload: data,
  };
};

export const updateShopSettingAppPasswordProtectFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT_FAILED,
    payload: data,
  };
};

export const getDeliveyChargeTypeAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_DELIVERY_CHARGE_TYPE,
    payload: { login_key, user_id, shop_id },
  };
};
export const getDeliveyChargeTypeSuccessfullAction = (data) => {
  return {
    type: actionType.GET_DELIVERY_CHARGE_TYPE_SUCCESSFULL,
    payload: data,
  };
};
export const getDeliveyChargeTypeFailedAction = () => {
  return {
    type: actionType.GET_DELIVERY_CHARGE_TYPE_FAILED,
  };
};
export const updateDeliveryChargeAction = (
  login_key,
  user_id,
  shop_id,
  id,
  title,
  delivery_charge,
  background_color
) => {
  return {
    type: actionType.UPDATE_DELIVERY_CHARGE,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      title,
      delivery_charge,
      background_color,
    },
  };
};
export const updateDeliveryChargeSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_DELIVERY_CHARGE_SUCCESSFULL,
    payload: data,
  };
};
export const updateDeliveryChargeFailedAction = (data) => {
  return {
    type: actionType.UPDATE_DELIVERY_CHARGE_FAILED,
    payload: data,
  };
};
export const createDeliveryChargeAction = (
  login_key,
  user_id,
  shop_id,
  title,
  delivery_charge,
  background_color
) => {
  return {
    type: actionType.CRETAE_DELIVERY_CHARGE,
    payload: {
      login_key,
      user_id,
      shop_id,
      title,
      delivery_charge,
      background_color,
    },
  };
};
export const createDeliveryChargeSuccesffullAction = (data) => {
  return {
    type: actionType.CRETAE_DELIVERY_CHARGE_SUCCESSFULL,
    payload: data,
  };
};
export const createDeliveryChargeFailedAction = (data) => {
  return {
    type: actionType.CRETAE_DELIVERY_CHARGE_FAILED,
    payload: data,
  };
};
export const deleteDeliveryChargeAction = (login_key, user_id, shop_id, id) => {
  return {
    type: actionType.DELETE_DELIVERY_CHARGE,
    payload: { login_key, user_id, shop_id, id },
  };
};
export const deleteDeliveryChargeSuccesffullAction = (data) => {
  return {
    type: actionType.DELETE_DELIVERY_CHARGE_SUCCESSFULL,
    payload: data,
  };
};
export const deleteDeliveryChargeFailedAction = (data) => {
  return {
    type: actionType.DELETE_DELIVERY_CHARGE_FAILED,
    payload: data,
  };
};

export const selectedDeliveryChargeType = (data) => {
  return {
    type: actionType.SELECTED_DELIVERY_CHARGE_TYPE,
    payload: data,
  };
};

export const visibleCusomChargeAction = (data) => {
  return {
    type: actionType.VISIBLE_CUTOM_CHARGE,
    payload: data,
  };
};

export const getDeliverySettingAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_DELIVERY_SETTING,
    payload: {
      login_key,
      user_id,
      shop_id,
    },
  };
};

export const getDeliverySettingSuccessfullAction = (data) => {
  return {
    type: actionType.GET_DELIVERY_SETTING_SUCCESSFULL,
    payload: data,
  };
};
export const getDeliverySettingFailedAction = () => {
  return {
    type: actionType.GET_DELIVERY_SETTING_FAILED,
  };
};

export const updateDeliverySettingAction = (
  login_key,
  user_id,
  shop_id,
  id,

  default_delivery_charge_type_id,
  spicific_amount,
  Print_free_delivery_message
) => {
  return {
    type: actionType.UPDATE_DELIVERY_SETTING,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,

      default_delivery_charge_type_id,
      spicific_amount,
      Print_free_delivery_message,
    },
  };
};
export const updateDeliverySettingSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_DELIVERY_SETTING_SUCCESFFULL,
    payload: data,
  };
};
export const updateDeliverySettingFailedAction = (data) => {
  return {
    type: actionType.UPDATE_DELIVERY_SETTING_FAILED,
    payload: data,
  };
};
export const codeDetailListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.CODE_DETAIL_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const codeDetailListSuccessfullAction = (data) => {
  return {
    type: actionType.CODE_DETAIL_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const codeDetailListFailedAction = () => {
  return {
    type: actionType.CODE_DETAIL_LIST_FAILED,
  };
};
export const createCodeDetailAction = (
  login_key,
  user_id,
  shop_id,
  group_id,
  title,
  active,
  sort_id,
  id
) => {
  return {
    type: actionType.CREATE_CODE_DETAIL,
    payload: {
      login_key,
      user_id,
      shop_id,
      group_id,
      title,
      active,
      sort_id,
      id,
    },
  };
};
export const createCodeDetailSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_CODE_DETAIL_SUCCESSFULL,
    payload: data,
  };
};
export const createCodeDetailFailedAction = (data) => {
  return {
    type: actionType.CREATE_CODE_DETAIL_FAILED,
    payload: data,
  };
};
export const updateCodeDetailAction = (
  login_key,
  user_id,
  shop_id,
  id,
  group_id,
  title,
  active,
  sort_id
) => {
  return {
    type: actionType.UPDATE_CODE_DETAIL,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      group_id,
      title,
      active,
      sort_id,
    },
  };
};
export const updateCodeDetailSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_CODE_DETAIL_SUCCESSFULL,
    payload: data,
  };
};
export const updateCodeDetailFailedAction = (data) => {
  return {
    type: actionType.UPDATE_CODE_DETAIL_FAILED,
    payload: data,
  };
};
export const deleteCodeDetailAction = (login_key, user_id, shop_id, id) => {
  return {
    type: actionType.DELETE_CODE_DETAIL,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
    },
  };
};
export const deleteCodeDetailSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_CODE_DETAIL_SUCCESSFULL,
    payload: data,
  };
};
export const deleteCodeDetailFailedAction = (data) => {
  return {
    type: actionType.DELETE_CODE_DETAIL_FAILED,
    payload: data,
  };
};
export const visibleCodeDetailAction = (data) => {
  return {
    type: actionType.VISIBLE_CODE_DETAIL,
    payload: data,
  };
};
export const selectedCodeDetailAction = (data) => {
  return {
    type: actionType.SELECTED_CODE_DETEIL,
    payload: data,
  };
};
export const chaangedCodeDetailListAction = (data) => {
  return {
    type: actionType.CHANGED_CODE_DETAIL_LIST,
    payload: data,
  };
};
export const selectedGroupIdAction = (data) => {
  return {
    type: actionType.SELECTED_GROUP_ID,
    payload: data,
  };
};

//GET WEB SETTING
export const getWebSettingAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_WEB_SETTING,
    payload: { login_key, user_id, shop_id },
  };
};
export const getWebSettingSuccessfullAction = (data) => {
  return {
    type: actionType.GET_WEB_SETTING_SUCCESSFULL,
    payload: data,
  };
};
export const getWebSettingFailedAction = () => {
  return {
    type: actionType.GET_WEB_SETTING_FAILED,
  };
};

//UPDATE WEB SETTING
export const updateWebSettingAction = (
  formData,
  login_key,
  user_id,
  shop_id,
  mail_profile,
  domain,
  fav_icon,
  banner_text,
  banner_text_l2,
  banner_image,
  show_banner_image,
  facebooh_url,
  twittr_url,
  instgram_url,
  app_store_url,
  google_play_url,
  postcode_message,
  background_image,
  show_background_image,
  background_repeate,
  lastFavIconName,
  lastBannerImageName,
  lastBackgroundImageName
) => {
  return {
    type: actionType.UPDATE_WEB_SETTING,
    payload: {
      formData,
      login_key,
      user_id,
      shop_id,
      mail_profile,
      domain,
      fav_icon,
      banner_text,
      banner_text_l2,
      banner_image,
      show_banner_image,
      facebooh_url,
      twittr_url,
      instgram_url,
      app_store_url,
      google_play_url,
      postcode_message,
      background_image,
      show_background_image,
      background_repeate,
      lastFavIconName,
      lastBannerImageName,
      lastBackgroundImageName,
    },
  };
};
export const updateWebSettingSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_WEB_SETTING_SUCCESFULL,
    payload: data,
  };
};
export const updateWebSettingFailedAction = (data) => {
  return {
    type: actionType.UPDATE_WEB_SETTING_FAILED,
    payload: data,
  };
};

// OPEN CLOSE TIME
export const getOpenCloseTimeListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_OPEN_CLOSE_TIME_LIST,
    payload: { login_key, shop_id, user_id },
  };
};
export const getOpenCloseTimeListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_OPEN_CLOSE_TIME_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getOpenCloseTimeListFailedAction = (data) => {
  return {
    type: actionType.GET_OPEN_CLOSE_TIME_LIST_FAILED,
    payload: data,
  };
};
// export const openCloseList2 = (data) => {
//   return {
//     type: actionType.OPEN_CLOSE_LIST2,
//     payload: data,
//   };
// };
export const updateOpenCloseTimeAction = (
  login_key,
  user_id,
  shop_id,
  id,
  day_of_week,
  delivery_open_time1,
  delivery_close_time1,
  delivery_open_time2,
  delivery_close_time2,
  delivery_open_time3,
  delivery_close_time3,
  collection_open_time1,
  collection_close_time1,
  collection_open_time2,
  collection_close_time2,
  collection_open_time3,
  collection_close_time3,
  open_delivery,
  open_collection,
  open_delivery_2,
  open_collection_2,
  open_delivery_3,
  open_collection_3,
  is_open_close_status
) => {
  return {
    type: actionType.UPDATE_OPEN_CLOSE_TIME,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      day_of_week,
      delivery_open_time1,
      delivery_close_time1,
      delivery_open_time2,
      delivery_close_time2,
      delivery_open_time3,
      delivery_close_time3,
      collection_open_time1,
      collection_close_time1,
      collection_open_time2,
      collection_close_time2,
      collection_open_time3,
      collection_close_time3,
      open_delivery,
      open_collection,
      open_delivery_2,
      open_collection_2,
      open_delivery_3,
      open_collection_3,
      is_open_close_status,
    },
  };
};
export const updateOpenCloseTimeSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_OPEN_CLOSE_TIME_SUCCESSFULL,
    payload: data,
  };
};
export const updateOpenCloseTimeFailedAction = (data) => {
  return {
    type: actionType.UPDATE_OPEN_CLOSE_TIME_FAILED,
    payload: data,
  };
};
export const createOpenCloseTimeAction = (
  login_key,
  user_id,
  shop_id,
  day_of_week,
  delivery_open_time1,
  delivery_close_time1,
  delivery_open_time2,
  delivery_close_time2,
  delivery_open_time3,
  delivery_close_time3,
  collection_open_time1,
  collection_close_time1,
  collection_open_time2,
  collection_close_time2,
  collection_open_time3,
  collection_close_time3,
  open_delivery,
  open_collection,
  open_delivery_2,
  open_collection_2,
  open_delivery_3,
  open_collection_3
) => {
  return {
    type: actionType.CREATE_OPEN_CLOSE_TIME,
    payload: {
      login_key,
      user_id,
      shop_id,
      day_of_week,
      delivery_open_time1,
      delivery_close_time1,
      delivery_open_time2,
      delivery_close_time2,
      delivery_open_time3,
      delivery_close_time3,
      collection_open_time1,
      collection_close_time1,
      collection_open_time2,
      collection_close_time2,
      collection_open_time3,
      collection_close_time3,
      open_delivery,
      open_collection,
      open_delivery_2,
      open_collection_2,
      open_delivery_3,
      open_collection_3,
    },
  };
};
export const createOpenCloseTimeSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_OPEN_CLOSE_TIME_SUCCESSFULL,
    payload: data,
  };
};
export const createOpenCloseTimeFailedAction = (data) => {
  return {
    type: actionType.CREATE_OPEN_CLOSE_TIME_FAILED,
    payload: data,
  };
};

//WEB PAYMENT SETTING ACTIONS
export const getWebPaymentSettingListAction = (login_key, user_id, shopId) => {
  return {
    type: actionType.GET_WEB_PAYMENT_SETTING_LIST,
    payload: {
      login_key,
      user_id,
      shopId,
    },
  };
};
export const getWebPaymentSettingListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_WEB_PAYMENT_SETTING_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getWebPaymentSettingListFailedAction = (data) => {
  return {
    type: actionType.GET_WEB_PAYMENT_SETTING_LIST_FAILED,
    payload: data,
  };
};
export const updateWebPaymentSettingAction = (
  login_key,
  user_id,
  shopId,
  id,
  delivery_payment_method_cash,
  delivery_payment_method_stripe,
  delivery_payment_method_pay_pal,
  delivery_payment_method_adyen,
  collection_patment_method_cash,
  collection_patment_method_stripe,
  collection_patment_method_pay_pal,
  collection_patment_method_adyen,
  credit_card_surcharge,
  credit_card_surcharge_label,
  credit_card_surcharge_visible
) => {
  return {
    type: actionType.UPDATE_WEB_PAYMENT_SETTING,
    payload: {
      login_key,
      user_id,
      shopId,
      id,
      delivery_payment_method_cash,
      delivery_payment_method_stripe,
      delivery_payment_method_pay_pal,
      delivery_payment_method_adyen,
      collection_patment_method_cash,
      collection_patment_method_stripe,
      collection_patment_method_pay_pal,
      collection_patment_method_adyen,
      credit_card_surcharge,
      credit_card_surcharge_label,
      credit_card_surcharge_visible,
    },
  };
};
export const updateWebPaymentSettingSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_WEB_PAYMENT_SETTING_SUCCESSFULL,
    payload: data,
  };
};
export const updateWebPaymentSettingFailedAction = (data) => {
  return {
    type: actionType.UPDATE_WEB_PAYMENT_SETTING_FAILED,
    payload: data,
  };
};

// WEB SETTING STRIPE
export const getStripeListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_STRIPE_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getStripeListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_STRIPE_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getStripeListFailedAction = (data) => {
  return {
    type: actionType.GET_STRIPE_LIST_FAILED,
    payload: data,
  };
};

export const updateStripeAction = (
  login_key,
  user_id,
  shop_id,
  id,
  secret_ley,
  publishable_key,
  account1,
  account2,
  active_day_of_acount1,
  active_day_of_acount2,
  webhook,
  ofOrder,
  statusPence,
  accountType,
  stripeMode
) => {
  return {
    type: actionType.UPDATE_STRIPE,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      secret_ley,
      publishable_key,
      account1,
      account2,
      active_day_of_acount1,
      active_day_of_acount2,
      webhook,
      ofOrder,
      statusPence,
      accountType,
      stripeMode,
    },
  };
};
export const updateStripeSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_STRIPE_SUCCESSFULL,
    payload: data,
  };
};
export const updateStripeFailedAction = (data) => {
  return {
    type: actionType.UPDATE_STRIPE_FAILED,
    payload: data,
  };
};

export const showStripePopupAction = (data) => {
  return {
    type: actionType.SHOW_STRIPE_POPUP,
    payload: data,
  };
};
// SHOP SETTIMG TIME GAP
export const getShopSettingTimeGapListAction = (
  login_key,
  user_id,
  shop_id
) => {
  return {
    type: actionType.GET_SHOP_SETTING_TIME_GAP_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getShopSettingTimeGapListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_SHOP_SETTING_TIME_GAP_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getShopSettingTimeGapListFailedAction = (data) => {
  return {
    type: actionType.GET_SHOP_SETTING_TIME_GAP_LIST_FAILED,
    payload: data,
  };
};

export const updateShopSettingTimeGapAction = (
  login_key,
  user_id,
  shop_id,
  normal_delivery,
  busy_delivery,
  normal_collection,
  busy_collection,
  expire_time,
  display_time_gap_in_app,
  active_time_gap,
  delivery_time_segmentation,
  collection_time_segmentantion
) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_TIME_GAP,
    payload: {
      login_key,
      user_id,
      shop_id,
      normal_delivery,
      busy_delivery,
      normal_collection,
      busy_collection,
      expire_time,
      display_time_gap_in_app,
      active_time_gap,
      delivery_time_segmentation,
      collection_time_segmentantion,
    },
  };
};
export const updateShopSettingTimeGapSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_TIME_GAP_SUCCESSFULL,
    payload: data,
  };
};
export const updateShopSettingTimeGapFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SHOP_SETTING_TIME_GAP_FAILED,
    payload: data,
  };
};

export const getWebDeliveryListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_WEB_DELIVERY_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getWebDeliveryListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_WEB_DELIVERY_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getWebDeliveryListFailedAction = (data) => {
  return {
    type: actionType.GET_WEB_DELIVERY_LIST_FAILED,
    payload: data,
  };
};
export const updateWebDeliveryAction = (
  login_key,
  user_id,
  shop_id,
  id,
  web_min_order_value,
  web_min_order_charge_message,

  web_min_free_delivery
) => {
  return {
    type: actionType.UPDATE_WEB_DELIVERY,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      web_min_order_value,
      web_min_order_charge_message,

      web_min_free_delivery,
    },
  };
};
export const updateWebDeliverySuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_WEB_DELIVERY_SUCCESSFULL,
    payload: data,
  };
};
export const updateWebDeliveryFailedAction = (data) => {
  return {
    type: actionType.UPDATE_WEB_DELIVERY_FAILED,
    payload: data,
  };
};

//MAINTENANCE LIST
export const getMaintenanceListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_MAINTENANCE_LIST,
    payload: {
      login_key,
      user_id,
      shop_id,
    },
  };
};
export const maintenanceMessageListAction = (data) => {
  return {
    type: actionType.MAINTENACE_MESSAGE_LIST,
    payload: data,
  };
};
export const getMaintenanceListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_MAINTENANCE_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getMaintenanceListFailedAction = (data) => {
  return {
    type: actionType.GET_MAINTENANCE_LIST_FAILED,
    payload: data,
  };
};

export const updateMaintenanceAction = (
  login_key,
  user_id,
  shop_id,
  maintenance_mode,
  maintenance_message,
  forse_close_order,
  is_only_asap,
  close_order_message,
  show_asap,
  next_day_pree_order,
  pree_order,
  pree_order_message,
  maintenance_type,
  maintenance_open_shop_date
) => {
  return {
    type: actionType.UPDATE_MAINTENANCE,
    payload: {
      login_key,
      user_id,
      shop_id,
      maintenance_mode,
      maintenance_message,
      forse_close_order,
      is_only_asap,
      close_order_message,
      show_asap,
      next_day_pree_order,
      pree_order,
      pree_order_message,
      maintenance_type,
      maintenance_open_shop_date,
    },
  };
};
export const updateMaintenanceSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_MAINTENANCE_SUCCESSFULL,
    payload: data,
  };
};
export const updateMaintenanceFailedAction = (data) => {
  return {
    type: actionType.UPDATE_MAINTENANCE_FAILED,
    payload: data,
  };
};

//PAYMENT LINK SMS
export const getPaymentLinkListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_PAYMENT_LINK,
    payload: { login_key, user_id, shop_id },
  };
};
export const getPaymentLinkListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_PAYMENT_LINK_SUCCESSFULL,
    payload: data,
  };
};
export const getPaymentLinkListFailedAction = (data) => {
  return {
    type: actionType.GET_PAYMENT_LINK_FAILED,
    payload: data,
  };
};

export const updatePaymentLinkAction = (
  login_key,
  user_id,
  shop_id,
  delivery_by_payeat,
  collection_by_payeat,
  takeaway_by_payeat,
  eatIn_by_payeat,
  expire_time_min,
  payment_method,
  ask_before_sending_text_message,
  message,
  domain,
  admin_fee
) => {
  return {
    type: actionType.UPDATE_PAYMENT_LINK,
    payload: {
      login_key,
      user_id,
      shop_id,
      delivery_by_payeat,
      collection_by_payeat,
      takeaway_by_payeat,
      eatIn_by_payeat,
      expire_time_min,
      payment_method,
      ask_before_sending_text_message,
      message,
      domain,
      admin_fee,
    },
  };
};

export const updatePaymentLinkSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_PAYMENT_LINK_SUCCESSFULL,
    payload: data,
  };
};
export const updatePaymentLinkFailedAction = (data) => {
  return {
    type: actionType.UPDATE_PAYMENT_LINK_FAILED,
    payload: data,
  };
};

//NOTIFICATION EMAIL
export const getNotificationEmailListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_NOTIFICATION_EMAIL_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getNotificationEmailListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_NOTIFICATION_EMAIL_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getNotificationEmailListFailedAction = (data) => {
  return {
    type: actionType.GET_NOTIFICATION_EMAIL_LIST_FAILED,
    payload: data,
  };
};
export const createNotificationEmailAction = (
  login_key,
  user_id,
  shop_id,
  email
) => {
  return {
    type: actionType.CREATE_NOTIFICATION_EMAIL,
    payload: { login_key, user_id, shop_id, email },
  };
};
export const createNotificationEmailSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_NOTIFICATION_EMAIL_SUCCESSFULL,
    payload: data,
  };
};

export const createNotificationEmailFailedAction = (data) => {
  return {
    type: actionType.CREATE_NOTIFICATION_EMAIL_FAILED,
    payload: data,
  };
};

export const updateNotificationEmailAction = (
  login_key,
  user_id,
  shop_id,
  email,
  id,
  active
) => {
  return {
    type: actionType.UPDATE_NOTIFICATION_EMAIL,
    payload: { login_key, user_id, shop_id, email, id, active },
  };
};
export const updateNotificationEmailSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_NOTIFICATION_EMAIL_SUCCESSFULL,
    payload: data,
  };
};

export const updateNotificationEmailFailedAction = (data) => {
  return {
    type: actionType.UPDATE_NOTIFICATION_EMAIL_FAILED,
    payload: data,
  };
};

export const deleteNotificationEmailAction = (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return {
    type: actionType.DELETE_NOTIFICATION_EMAIL,
    payload: { login_key, user_id, shop_id, id },
  };
};
export const deleteNotificationEmailSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_NOTIFICATION_EMAIL_SUCCESSFULL,
    payload: data,
  };
};

export const deleteNotificationEmailFailedAction = (data) => {
  return {
    type: actionType.DELETE_NOTIFICATION_EMAIL_FAILED,
    payload: data,
  };
};

export const showNotificationEmailPopupAction = (data) => {
  return {
    type: actionType.SHOW_NOTIFICATION_EMAIL_POPUP,
    payload: data,
  };
};

export const selectedNotificationEmailAction = (data) => {
  return {
    type: actionType.SELECTED_NOTIFICATION_EMAIL,
    payload: data,
  };
};

// WEB PROMOTION
export const getPromotionListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_PROMOTION_LIST,
    payload: {
      login_key,
      user_id,
      shop_id,
    },
  };
};
export const getPromotionListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_PROMOTION_LIST_SUCCESSFULL,
    payload: data,
  };
};
//CREATE PROMOTION
export const getPromotionListFailedAction = (data) => {
  return {
    type: actionType.GET_PROMOTION_LIST_FAILED,
    payload: data,
  };
};

export const createPromotionAction = (
  login_key,
  user_id,
  shop_id,
  title,
  description,
  start_date,
  end_date,
  day_of_week0,
  day_of_week1,
  day_of_week2,
  day_of_week3,
  day_of_week4,
  day_of_week5,
  day_of_week6,
  code,
  sale_method,
  rate,
  rate_option,
  min_order,
  status,
  excludes_meal_deals,
  uses_per_promotion,
  uses_per_customer,
  excluded_promotions,
  show_in_site
) => {
  return {
    type: actionType.CREATE_PROMOTION,
    payload: {
      login_key,
      user_id,
      shop_id,
      title,
      description,
      start_date,
      end_date,
      day_of_week0,
      day_of_week1,
      day_of_week2,
      day_of_week3,
      day_of_week4,
      day_of_week5,
      day_of_week6,
      code,
      sale_method,
      rate,
      rate_option,
      min_order,
      status,
      excludes_meal_deals,
      uses_per_promotion,
      uses_per_customer,
      excluded_promotions,
      show_in_site,
    },
  };
};
export const createPromotionSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_PROMOTION_SUCCESSFULL,
    payload: data,
  };
};
export const createPromotionFailedAction = (data) => {
  return {
    type: actionType.CREATE_PROMOTION_FAILED,
    payload: data,
  };
};

//UPDATE PROMOTION
export const updatePromotionAction = (
  login_key,
  user_id,
  shop_id,
  id,
  title,
  description,
  start_date,
  end_date,
  day_of_week0,
  day_of_week1,
  day_of_week2,
  day_of_week3,
  day_of_week4,
  day_of_week5,
  day_of_week6,
  code,
  sale_method,
  rate,
  rate_option,
  min_order,
  status,
  excludes_meal_deals,
  uses_per_promotion,
  uses_per_customer,
  excluded_promotions,
  show_in_site
) => {
  return {
    type: actionType.UPDATE_PROMOTION,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      title,
      description,
      start_date,
      end_date,
      day_of_week0,
      day_of_week1,
      day_of_week2,
      day_of_week3,
      day_of_week4,
      day_of_week5,
      day_of_week6,
      code,
      sale_method,
      rate,
      rate_option,
      min_order,
      status,
      excludes_meal_deals,
      uses_per_promotion,
      uses_per_customer,
      excluded_promotions,
      show_in_site,
    },
  };
};
export const updatePromotionSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_PROMOTION_SUCCESSFULL,
    payload: data,
  };
};
export const updatePromotionFailedAction = (data) => {
  return {
    type: actionType.UPDATE_PROMOTION_FAILED,
    payload: data,
  };
};

//DELETE PROMOTION
export const deletePromotionsAction = (login_key, user_id, shop_id, id) => {
  return {
    type: actionType.DELETE_PROMOTION,
    payload: { login_key, user_id, shop_id, id },
  };
};
export const deletePromotionsSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_PROMOTION_SUCCESSFULL,
    payload: data,
  };
};
export const deletePromotionsFailedAction = (data) => {
  return {
    type: actionType.DELETE_PROMOTION_FAILED,
    payload: data,
  };
};

export const selectedPromotionAction = (data) => {
  return {
    type: actionType.SELECTED_PROMOTION,
    payload: data,
  };
};

// APP SETTING NUMBER OF PRINTS
export const getNumberOfPrintsListAction = (
  login_key,
  user_id,
  shop_id,
  works_stations_id
) => {
  return {
    type: actionType.GET_NUMBERS_OF_PRINTS,
    payload: { login_key, user_id, shop_id, works_stations_id },
  };
};
export const getNumberOfPrintsListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_NUMBERS_OF_PRINTS_SUCCESSFULL,
    payload: data,
  };
};
export const getNumberOfPrintsListFailedAction = (data) => {
  return {
    type: actionType.GET_NUMBERS_OF_PRINTS_FAILED,
    payload: data,
  };
};

//UPDATE NUMBER OF PRINTS
export const updateNumberOfPrintsAction = (
  login_key,
  user_id,
  shop_id,
  works_stations_id,
  delivery_count,
  collection_count,
  takeaway_count,
  eat_in_count
) => {
  return {
    type: actionType.UPDATE_NUMBER_OF_PRINTS,
    payload: {
      login_key,
      user_id,
      shop_id,
      works_stations_id,
      delivery_count,
      collection_count,
      takeaway_count,
      eat_in_count,
    },
  };
};
export const updateNumberOfPrintsSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_NUMBER_OF_PRINTS_SUCCESSFULL,
    payload: data,
  };
};
export const updateNumberOfPrintsFailedAction = (data) => {
  return {
    type: actionType.UPDATE_NUMBER_OF_PRINTS_FAILED,
    payload: data,
  };
};

//PAYMENT DOJO SETTING
export const getPaymentDojoListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_PAYMENT_DOJO_SETTING_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getPaymentDojoListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_PAYMENT_DOJO_SETTING_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getPaymentDojoListFailedAction = (data) => {
  return {
    type: actionType.GET_PAYMENT_DOJO_SETTING_LIST_FAILED,
    payload: data,
  };
};
export const createPaymentDojoSettingAction = (
  login_key,
  user_id,
  shop_id,
  title,
  Tid,
  api_url,
  api_key,
  currency,
  software_house_id,
  installer_id,
  active,
  workstation_id
) => {
  return {
    type: actionType.CREATE_PAYMENT_DOJO_SETTING,
    payload: {
      login_key,
      user_id,
      shop_id,
      title,
      Tid,
      api_url,
      api_key,
      currency,
      software_house_id,
      installer_id,
      active,
      workstation_id,
    },
  };
};
export const createPaymentDojoSettingSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_PAYMENT_DOJO_SETTING_SUCCESSFULL,
    payload: data,
  };
};
export const createPaymentDojoSettingFailedAction = (data) => {
  return {
    type: actionType.CREATE_PAYMENT_DOJO_SETTING_FAILED,
    payload: data,
  };
};

export const updatePaymentDojoSettingAction = (
  login_key,
  user_id,
  shop_id,
  id,
  title,
  Tid,
  api_url,
  api_key,
  currency,
  software_house_id,
  installer_id,
  active,
  workstation_id
) => {
  return {
    type: actionType.UPDATE_PAYMENT_DOJO_SETTING,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      title,
      Tid,
      api_url,
      api_key,
      currency,
      software_house_id,
      installer_id,
      active,
      workstation_id,
    },
  };
};
export const updatePaymentDojoSettingSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_PAYMENT_DOJO_SETTING_SUCCESSFULL,
    payload: data,
  };
};
export const updatePaymentDojoSettingFailedAction = (data) => {
  return {
    type: actionType.UPDATE_PAYMENT_DOJO_SETTING_FAILED,
    payload: data,
  };
};

export const deletePaymentDojoSettingAction = (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return {
    type: actionType.DELETE_PAYMENT_DOJO_SETTING,
    payload: { login_key, user_id, shop_id, id },
  };
};
export const deletePaymentDojoSettingSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_PAYMENT_DOJO_SETTING_SUCCESSFULL,
    payload: data,
  };
};
export const deletePaymentDojoSettingFailedAction = (data) => {
  return {
    type: actionType.DELETE_PAYMENT_DOJO_SETTING_FAILED,
    payload: data,
  };
};

export const selectedPaymentDojoSettingAction = (data) => {
  return {
    type: actionType.SELECTED_PAYMENT_DOJO_SETTING,
    payload: data,
  };
};

//SECON SCREEN
export const showSecondScreenImageDetailsPopupAction = (data) => {
  return {
    type: actionType.SHOW_SECOND_SCREEN_IMAGE_DETAILS_POPUP,
    payload: data,
  };
};
export const getSecondScreenImagesListAction = (
  login_key,
  user_id,
  shop_id
) => {
  return {
    type: actionType.GET_SECOND_SCREEN_IMAGES_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getSecondFullScreenImagesListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_SECOND_FULL_SCREEN_IMAGES_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getSecondHalfScreenImagesListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_SECOND_HALF_SCREEN_IMAGES_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getSecondScreenImagesListFailedAction = (data) => {
  return {
    type: actionType.GET_SECOND_SCREEN_IMAGES_LIST_FAILED,
    payload: data,
  };
};

export const createSecondScreenImagesAction = (
  formData,
  login_key,
  user_id,
  shop_id,
  sort_id,
  active,
  image_file
) => {
  return {
    type: actionType.CREATE_SECOND_SCREEN_IMAGE,
    payload: {
      formData,
      login_key,
      user_id,
      shop_id,
      sort_id,
      active,
      image_file,
    },
  };
};
export const createSecondScreenImagesSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_SECOND_SCREEN_IMAGE_SUCCESSFULL,
    payload: data,
  };
};
export const createSecondScreenImagesFailedAction = (data) => {
  return {
    type: actionType.CREATE_SECOND_SCREEN_IMAGE_FAILED,
    payload: data,
  };
};

export const updateSecondScreenEachPhotoSettingAction = (
  login_key,
  user_id,
  shop_id,
  id,
  sort_id,
  active,
  duration
) => {
  return {
    type: actionType.UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      sort_id,
      active,
      duration,
    },
  };
};
export const updateSecondScreenEachPhotoSettingSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING_SUCCESSFULL,
    payload: data,
  };
};
export const updateSecondScreenEachPhotoSettingFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING_FAILED,
    payload: data,
  };
};

export const deleteSecondScreenEachPhotoAction = (
  shop_id,
  login_key,
  user_id,
  id,
  last_image_url
) => {
  return {
    type: actionType.DELETE_SECOND_SCREEN_EACH_PHOTO,
    payload: { shop_id, login_key, user_id, id, last_image_url },
  };
};
export const deleteSecondScreenEachPhotoSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_SECOND_SCREEN_EACH_PHOTO_SUCCESSFULL,
    payload: data,
  };
};
export const deleteSecondScreenEachPhotoFailedAction = (data) => {
  return {
    type: actionType.DELETE_SECOND_SCREEN_EACH_PHOTO_FAILED,
    payload: data,
  };
};

export const selectedSecondScreenImageAction = (data) => {
  return {
    type: actionType.SELECTED_SECOND_SCREEN_IMAGE,
    payload: data,
  };
};

export const secondScreenImageDeletePopupAction = (data) => {
  return {
    type: actionType.SECOND_SCREEN_IMAGE_DELETE_POPUP,
    payload: data,
  };
};

// WEB SETTING ADYEN
export const getAdyenListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_ADYEN_LIST,
    payload: { login_key, user_id, shop_id },
  };
};

export const getAdyenListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_ADYEN_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getAdyenListFailedAction = (data) => {
  return {
    type: actionType.GET_ADYEN_LIST_FAILED,
    payload: data,
  };
};

export const updateAdyenAction = (
  login_key,
  user_id,
  shop_id,
  id,
  webhook,
  api_key,
  account_id
) => {
  return {
    type: actionType.UPDATE_ADYEN,
    payload: { login_key, user_id, shop_id, id, webhook, api_key, account_id },
  };
};

export const updateAdyenSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_ADYEN_SUCCESSFULL,
    payload: data,
  };
};

export const updateAdyenFailedAction = (data) => {
  return {
    type: actionType.UPDATE_ADYEN_FAILED,
    payload: data,
  };
};

// SETTING APP SALES REPORT

export const updateSalesReportAction = (
  login_key,
  user_id,
  shop_id,
  show_categories_on_zprint
) => {
  return {
    type: actionType.UPDATE_SALES_REPORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      show_categories_on_zprint,
    },
  };
};

export const updateSalesReportSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SALES_REPORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateSalesReportFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SALES_REPORT_FAILED,
    payload: data,
  };
};
// META DATA
export const getMetaDataListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_META_DATA_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getMetaDataListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_META_DATA_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getMetaDataListFailedAction = (data) => {
  return {
    type: actionType.GET_META_DATA_LIST_FAILED,
    payload: data,
  };
};

export const updateMetaDataAction = (
  login_key,
  user_id,
  shop_id,
  meta_title,
  meta_desc,
  meta_keywords
) => {
  return {
    type: actionType.UPDATE_META_DATA,
    payload: {
      login_key,
      user_id,
      shop_id,
      meta_title,
      meta_desc,
      meta_keywords,
    },
  };
};
export const updateMetaDataSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_META_DATA_SUCCESSFULL,
    payload: data,
  };
};
export const updateMetaDataFailedAction = (data) => {
  return {
    type: actionType.UPDATE_META_DATA_FAILED,
    payload: data,
  };
};
