import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";
import { marginNormalMenuHorizontal } from "../constants/Sizes";
import {
  deviceAccessGroupListAction,
  selectedAccessGroupAction,
  deleteDevicesAccessGroupAction,
} from "../redux/Employees/Actions";
import ListHeader from "./ListHeader";
import { changeSelectedButtonBorderColorOnFocus } from "../redux/menu/Actions";
import MenuListItem from "./MenuListItem";
const GroupsList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { device_access_group_list, selected_access_group, device_roles_list } =
    useSelector((state) => state.employeesRedusers);
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [groupList, setGroupList] = useState([]);

  const handleEditGroup = (item, key) => {
    dispatch(selectedAccessGroupAction(item));
    setClickedItem(key);
  };
  const handleCreateGroup = (key) => {
    dispatch(selectedAccessGroupAction(null));
    setClickedItem(key);
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...groupList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setGroupList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...groupList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setGroupList(copy_list);
  };
  const handleDeleted = (item) => {
    dispatch(deleteDevicesAccessGroupAction(login_key, user_id, 0, item.Id));
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(deviceAccessGroupListAction(0, login_key, user_id));
    }
  }, [selected_shop]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    if (device_access_group_list) {
      if (device_access_group_list.length > 0) {
        setGroupList(device_access_group_list);
      } else {
        setGroupList([]);
      }
    } else {
      setGroupList([]);
    }
  }, [device_access_group_list]);

  return (
    <div className="countaining-div">
      <ListHeader title={lang.staff_access_list} show_back={false} />
      <div className="menu_list_header_container">
        {/* BACK & TITLE */}

        {groupList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {groupList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={item.Title}
                  item_title={item.Title}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleEditGroup(item, 2)}
                  show_delete={true}
                  delete_click={() => handleDeleted(item)}
                  show_edit_icon={false}
                  selected={selected_access_group}
                  item={item}
                  number={0}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container" style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              onClick={() => handleCreateGroup(2)}
              style={{ width: "200px" }}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_staff_access}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {groupList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={() => handleCreateGroup(2)}
            style={{
              width: "200px",
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_staff_access}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GroupsList;
