import React, { useEffect } from "react";
import { Navbar, Container, Alert } from "react-bootstrap";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import store from "store2";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import Home from "./pages/home";
import ServiceCharges from "./pages/billing/ServiceCharges";
import TopNav from "./components/TopNav";
import ProductSubmenuListPopup from "./components/ProductSubmenuListPopup";
import CreateShop from "./components/CreateShop";
import MainNav from "./components/MainNav";
import ShopsStatus from "./pages/shopsStatus/ShopStatus";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import Devices from "./pages/devices";
import Takings from "./pages/takings";
import OrdersStatus from "./pages/ordersstatus";
import Loader from "./components/Loader";
import Login from "./pages/login";
import { setDefaultAction, getShopsListAction } from "./redux/shop/Actions";
import { getDropDownAction } from "./redux/menu/Actions";
import LicencePopup from "./pages/devices/Licence/licencePopup";
import PostCodePopup from "./pages/settings/GeneralSetting/GeneralComponent/PostCodePopup";
import VatPopup from "./pages/settings/GeneralSetting/GeneralComponent/VatPopup";
import DriversPopup from "./pages/dashboard/employees/DriversPopup";
import CustomChargePopup from "./pages/settings/AppSettings/Componenets/CustomChargepopup";
import CodeDetailPopup from "./pages/settings/CodingSetting/Component/CodeDetailPopup";
import ShopGroupPopup from "./components/ShopGroupPopup";
import OrderDetailPopup from "./components/OrderDetailPopup";
import Groups from "./pages/dashboard/employees/GroupIndex";
import NotificationEmailPopup from "./pages/settings/WebSetting/Componenets/EmailNotificationPopup";
import {
  receiveLoginResponse,
  loginSuccessfull,
  loginAction,
} from "./redux/login/Actions";
import LoaderMini from "./components/LoaderMini";
import LoginLoader from "./components/LoginLoader";
import ProductAvailabilityPopup from "./components/ProductAvailabilityPopup";
import ShopMessageSupportPopup from "./components/ShopMessageSupportPopup";
import DeleteCustomerPopup from "./pages/dashboard/Cusomers/DeleteCustomerPopup";
import MainButtonsEdit from "./pages/Works Stations/component/EditMainButtonPopup";
import RefundPopup from "./pages/finamcial/components/RefundPopup";
import Financial from "./pages/finamcial/index";
import SecondScreenShopGroupPopup from "./pages/devices/SecondScreen/ShopGroupPopup";
import DebtPopup from "./pages/finamcial/components/DebtPopup";
import CategoryAvailabilityPopup from "./components/CategoryAvailabilityPopup";
import SecondScreenImageDetailsPopup from "./pages/settings/AppSettings/Componenets/SecondScreenImageDetailsPopup";
import SecondScreenImageDeletePopup from "./pages/settings/AppSettings/Componenets/SecondScreenImageDeletePopup";
import General from "./pages/MainNavGenaral";
import TablePopup from "./pages/settings/GeneralSetting/GeneralComponent/TablePopup";
import SetupPopup from "./components/SetupPopup";
import ExternalCompanyShopGroupPopup from "./pages/MainNavGenaral/Component/ExternalCompanyShopGroupPopup";
import ItemListPopup from "./components/ItemListPopup";
import ImportSubmenuPopup from "./components/ImportSubmenuPopup";
import WePos from "./pages/WePos/index";
const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [width1, setWidth1] = React.useState(window.innerWidth);

  const { show_main_second_screen_shop_group_popup } = useSelector(
    (state) => state.devicesWorkStationReduser
  );
  const {
    result_login_successfull,
    login_key,
    user_id,
    refresh_login_loader,
    login_date,
  } = useSelector((state) => state.loginReducer);

  const { show_category_availability_popup } = useSelector(
    (state) => state.categoryList
  );

  const { visible_drivers_popup } = useSelector(
    (state) => state.employeesRedusers
  );

  const { visible_create_licence_popup } = useSelector(
    (state) => state.devicesWorkStationReduser
  );

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { show_product_submenu_popup } = useSelector(
    (state) => state.productList
  );

  const { deleting_customer_info, show_delete_customer_popup } = useSelector(
    (state) => state.customersRedusers
  );

  const { visible_refund_popup, visible_create_debt_popup } = useSelector(
    (state) => state.financialReduser
  );
  const { show_setup_popup } = useSelector((state) => state.setupReduser);
  const {
    show_create_shop_popup,
    visible_shop_craeting_loader,
    result_successfull,
    result_failed,
    result_message,
    get_new_shop_list,
    show_message_support_popup,
  } = useSelector((state) => state.shopReducer);

  const { show_product_availability_popup } = useSelector(
    (state) => state.productList
  );

  const { visible_post_code_popup, visible_vat_popup, show_add_table_popup } =
    useSelector((state) => state.tabelAreaRedusers);
  const { show_select_item_popup, show_import_submenu_popup } = useSelector(
    (state) => state.submenuRedusers
  );
  const {
    visible_cusom_charge,
    visible_code_detail,
    show_notification_email_popup,
    show_stripe_popup,
    show_second_screen_image_details_popup,
    second_screen_image_delete_popup,
  } = useSelector((state) => state.appSettingsRedusers);

  const { visible_shop_group_popup } = useSelector(
    (state) => state.usersReduser
  );

  const { show_work_station_main_buttons_popup } = useSelector(
    (state) => state.worksStationsReduser
  );
  const { show_order_list_popup } = useSelector((state) => state.ordersReduser);
  const loging_response = store.get("login_response");
  const selectedShop = store.get("selectedShop");
  const last_opening_time = store.get("saved_time");

  useEffect(() => {
    const password = store.get("password");
    const user_name = store.get("user_name");
    if (!result_login_successfull) {
      if (loging_response) {
        if (loging_response.UserAccesses) {
          if (loging_response.UserAccesses.length > 0) {
            if (loging_response.UserAccesses[0].UserType != 10001201) {
              navigate("/");
              store.remove("login_response");
              store.remove("selectedShop");
            } else {
              dispatch(
                loginAction(
                  "",
                  "",
                  user_name.replaceAll(`"`, `\"\"`),
                  password.replaceAll(`"`, `\"\"`)
                )
              );
              if (width1 <= 560) {
                navigate("/home/orders");
              } else {
                navigate("/home");
              }
            }
          }
        } else {
          navigate("/");
          store.remove("login_response");
          store.remove("selectedShop");
        }
      } else {
        store.remove("login_response");
        store.remove("selectedShop");
        navigate("/");
      }
    } else {
      if (width1 <= 560) {
        navigate("/home/orders");
      }
    }
  }, [result_login_successfull]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    if (loging_response) {
      if (loging_response.ResultCode != 1) {
        navigate("/");
      } else {
        if (result_login_successfull) {
          dispatch(
            getShopsListAction(
              loging_response.LoginKey,
              loging_response.UserAccesses[0].UserId,
              loging_response.UserAccesses[0].UserType == 10001201
                ? selectedShop
                  ? selectedShop.length > 0
                    ? selectedShop[0].ShopId
                    : 0
                  : 0
                : 0,
              1,
              loging_response.UserAccesses[0].UserType
            )
          );
        }
      }
    } else {
      navigate("/");
    }
  }, [refresh_login_loader]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <div
      className="container-fluid"
      style={{
        zIndex: 11,
        position: "fixed",
        height: "100%",
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

        // backgroundColor: refresh_login_loader
        //   ? " rgba(99, 97, 97, 0.75)"
        //   : null,
      }}
      // onClick={handleCloseDeleteBox}
    >
      <div>
        <div>
          <TopNav />
        </div>
        <div>
          <MainNav />
        </div>
      </div>

      <div
        className="all_content_container"
        style={{
          width: "100%",
          height: "100%",
          paddingTop: 8,
        }}
      >
        <Routes>
          <Route path="/*" element={<Dashboard />} />
          {/* <Route path="dashboard/*" element={<Dashboard />} /> */}
          <Route path="users/*" element={<Users />} />
          <Route path="/ordersstatus/*" element={<OrdersStatus />} />
          <Route path="/billing/servicecharges" element={<ServiceCharges />} />
          <Route path="/Shopsstatus" element={<ShopsStatus />} />
          <Route path="/gropus" element={<Groups />} />
          <Route path="/devices/*" element={<Devices />} />
          <Route path="/financial/*" element={<Financial />} />
          <Route path="/generall/*" element={<General />} />
          <Route path="/wepos/*" element={<WePos />} />
        </Routes>
      </div>
      {show_order_list_popup ? <OrderDetailPopup /> : null}
      {visible_code_detail ? <CodeDetailPopup /> : null}
      {visible_cusom_charge ? <CustomChargePopup /> : null}
      {visible_drivers_popup ? <DriversPopup /> : null}
      {visible_vat_popup ? <VatPopup /> : null}
      {visible_post_code_popup ? <PostCodePopup /> : null}
      {visible_create_licence_popup ? <LicencePopup /> : null}
      {show_product_submenu_popup ? <ProductSubmenuListPopup /> : null}
      <div>{show_create_shop_popup ? <CreateShop /> : null}</div>
      {visible_shop_craeting_loader ? <Loader /> : null}
      {visible_shop_group_popup ? <ShopGroupPopup /> : null}
      {show_notification_email_popup ? <NotificationEmailPopup /> : null}
      {show_product_availability_popup ? <ProductAvailabilityPopup /> : null}
      {show_message_support_popup ? <ShopMessageSupportPopup /> : null}
      {show_delete_customer_popup ? <DeleteCustomerPopup /> : null}
      {show_work_station_main_buttons_popup ? <MainButtonsEdit /> : null}
      {visible_refund_popup ? <RefundPopup /> : null}
      {visible_create_debt_popup ? <DebtPopup /> : null}
      {show_category_availability_popup ? <CategoryAvailabilityPopup /> : null}
      {show_add_table_popup ? <TablePopup /> : null}
      {show_second_screen_image_details_popup ? (
        <SecondScreenImageDetailsPopup />
      ) : null}
      {second_screen_image_delete_popup ? (
        <SecondScreenImageDeletePopup />
      ) : null}
      {show_main_second_screen_shop_group_popup ? (
        <SecondScreenShopGroupPopup />
      ) : null}
      {show_setup_popup ? <SetupPopup /> : null}
      {show_select_item_popup ? <ItemListPopup /> : null}
      {show_import_submenu_popup ? <ImportSubmenuPopup /> : null}
      <Toaster />

      {/* {!result_login_successfull ? (
        loging_response.UserAccesses ? (
          loging_response.UserAccesses.length > 0 ? (
            loging_response.UserAccesses[0].UserType != 10001201 ? (
              <Navigate to="/login" replace={true} />
            ) : null
          ) : (
            <Navigate to="/login" replace={true} />
          )
        ) : (
          <Navigate to="/login" replace={true} />
        )
      ) : null} */}
      {refresh_login_loader ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            zIndex: 33333333333333,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <LoginLoader />
        </div>
      ) : null}
    </div>
  );
};

export default App;
