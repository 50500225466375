import React, { useState, useEffect, useRef } from "react";
import context from "react-bootstrap/esm/AccordionContext";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import toast, { Toaster } from "react-hot-toast";
import ListHeader from "../components/ListHeader";
import SaveMenuButton from "../components/SaveMenuButton";
import {
  main_nav_button_color,
  primaryColor,
  main_nav_back_color,
} from "../constants/Colors";
import {
  number_of_row_culmn,
  number_of_culmn,
} from "../constants/NumberOfRowAndCulmn";

import {
  selectedSubmenuAction,
  updateSubmenuAction,
} from "../redux/submenu/Action";

const SubmenuEdit = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef();
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { font_size, font_size_id } = useSelector(
    (state) => state.categoryList
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { selected_submenu, submenu_list, result_successfull } = useSelector(
    (state) => state.submenuRedusers
  );

  const [appTitle, setAppTitle] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [kioskTitle, setKioskTitle] = useState("");
  const [kioskDesc, setKioskDesc] = useState("");
  const [shortName, setShortName] = useState("");
  const [secoundLanguage, setSecoundLanguage] = useState("");
  const [sort, setSort] = useState(1);
  const [minSelect, setMinSelect] = useState(1);
  const [maxSelect, setMaxSelect] = useState(1);
  const [joinBefore, setJoinBefore] = useState(true);
  const [inFirst, setInFirst] = useState(false);
  const [isOverridePrice, setIsOverridePrice] = useState(true);
  const [incloudeDressing, setIncloudeDressing] = useState(false);
  const [incloudeExtra, setIncloudeExtra] = useState(false);
  const [visible, setVisible] = useState(true);
  const [numberOfItemsRow, setNumberOfItemsRow] = useState(null);
  const [numberOfItemsCulmn, setNumberOfItemsCulmn] = useState(null);
  const [itemsFontSize, setItemsFontSize] = useState(null);
  const [fixed, setFixed] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleIncloudeDressing = () => {
    setIncloudeDressing(!incloudeDressing);
    if (!incloudeDressing) {
      setIncloudeExtra(false);
    } else {
    }
  };
  const handleIncloudeExtra = (e) => {
    setIncloudeExtra(!incloudeExtra);
    if (!incloudeExtra) {
      setIncloudeDressing(false);
    }
  };

  const handleSaveClicked = () => {
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else
      dispatch(
        updateSubmenuAction(
          login_key,
          user_id,
          selected_submenu.Id,
          selected_submenu.ShopId,
          appTitle.replaceAll(`"`, `\"\"`),
          appDesc.replaceAll(`"`, `\"\"`),
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          webDesc.replaceAll(`"`, `\"\"`),
          kioskTitle
            ? kioskTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          kioskDesc.replaceAll(`"`, `\"\"`),
          secoundLanguage.replaceAll(`"`, `\"\"`),
          minSelect ? parseInt(minSelect) : 0,
          maxSelect ? parseInt(maxSelect) : 0,
          isOverridePrice ? 1 : 0,
          incloudeExtra ? 1 : 0,
          // incloudeDressing ? 1 :
          0,
          joinBefore ? 1 : 0,
          inFirst ? 1 : 0,
          shortName,
          visible ? 1 : 0,
          visible ? 1 : 0,
          visible ? 1 : 0,
          sort ? parseInt(sort) : 0,
          sort ? parseInt(sort) : 0,
          sort ? parseInt(sort) : 0,
          0,
          numberOfItemsCulmn ? parseInt(numberOfItemsCulmn.value) : 4,
          numberOfItemsRow ? parseInt(numberOfItemsRow.value) : 6,
          itemsFontSize ? itemsFontSize.value : 9,
          fixed ? 1 : 0
        )
      );
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    appTitle,
    appDesc,
    webTitle,
    webDesc,
    kioskTitle,
    kioskDesc,
    secoundLanguage,
    minSelect,
    maxSelect,
    isOverridePrice,
    joinBefore,
    visible,
    sort,
    shortName,
    incloudeExtra,
    incloudeDressing,
    numberOfItemsCulmn,
    numberOfItemsCulmn,
  ]);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);
  useEffect(() => {
    let rows_column_value = [];
    if (number_of_row_culmn.length > 0) {
      for (let i = 0; i < number_of_row_culmn.length; i++) {
        rows_column_value.push(number_of_row_culmn[i].value);
      }
    }

    let number_of_row = null;
    let number_of_column = null;
    let itemsFontSoze1 = null;
    if (selected_submenu) {
      setAppTitle(selected_submenu.AppTitle);
      setAppDesc(selected_submenu.AppDescription);
      setWebTitle(selected_submenu.WebTitle);
      setWebDesc(selected_submenu.WebDescription);
      setKioskTitle(selected_submenu.KioskTitle);
      setKioskDesc(selected_submenu.KioskDescription);
      setMinSelect(selected_submenu.MinSelect);
      setMaxSelect(selected_submenu.MaxSelect);
      setSecoundLanguage(selected_submenu.SecondLanguage);
      setJoinBefore(selected_submenu.JoinBefore);
      setIsOverridePrice(selected_submenu.IsOverridePrice);
      setSort(selected_submenu.AppSortId);
      setVisible(selected_submenu.AppVisible);
      setInFirst(selected_submenu.NameFirst);
      setIncloudeExtra(selected_submenu.ExtraQ);
      setIncloudeDressing(selected_submenu.YesNoQ);
      setShortName(selected_submenu.ShortName);
      setFixed(selected_submenu.FixedItem);
      if (selected_submenu.NumberOfItemsColumn) {
        if (
          rows_column_value.includes(
            parseInt(selected_submenu.NumberOfItemsColumn)
          )
        ) {
          let index = rows_column_value.indexOf(
            parseInt(selected_submenu.NumberOfItemsColumn)
          );
          number_of_column = {
            value: number_of_row_culmn[index].value,
            label: number_of_row_culmn[index].label,
          };
        }
      }
      if (selected_submenu.NumberOfItemsRow) {
        if (
          rows_column_value.includes(
            parseInt(selected_submenu.NumberOfItemsRow)
          )
        ) {
          let index = rows_column_value.indexOf(
            parseInt(selected_submenu.NumberOfItemsRow)
          );
          number_of_row = {
            value: number_of_row_culmn[index].value,
            label: number_of_row_culmn[index].label,
          };
        }
      }
      if (selected_submenu.ItemsFontSize) {
        if (font_size_id.includes(parseInt(selected_submenu.ItemsFontSize))) {
          let index = font_size_id.indexOf(
            parseInt(selected_submenu.ItemsFontSize)
          );
          itemsFontSoze1 = {
            label: font_size[index].label,
            value: font_size[index].value,
          };
        }
      }
      setNumberOfItemsCulmn(number_of_column);
      setNumberOfItemsRow(number_of_row);
      setItemsFontSize(itemsFontSoze1);
    }
  }, [selected_submenu]);
  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);

  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);

  return (
    // <div className="sm-container">
    //   <i className="fas fa-arrow-right" onClick={() => handleClickItem(1)}></i>
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <ListHeader
        title={lang.submenu_edit}
        show_back={true}
        clickedItem={() => setClickedItem(1)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div1">
            <div className=" sm-m">
              <h6 className="submenuEdit-titles h6">{lang.app_title}</h6>
              <div className="sm-div1-2">
                <input
                  className="sm-div1-input1"
                  onChange={(e) => setAppTitle(e.target.value)}
                  type="text"
                  value={appTitle}
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  className="sm-div1-input2"
                  onChange={(e) => setAppDesc(e.target.value)}
                  type="text"
                  value={appDesc}
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">{lang.web_title}</h6>
              <div className="sm-div1-2">
                <input
                  className="sm-div1-input1"
                  onChange={(e) => setWebTitle(e.target.value)}
                  type="text"
                  value={webTitle}
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  className="sm-div1-input2"
                  onChange={(e) => setWebDesc(e.target.value)}
                  type="text"
                  value={webDesc}
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">{lang.kiosk_title}</h6>
              <div className="sm-div1-2">
                <input
                  className="sm-div1-input1"
                  onChange={(e) => setKioskTitle(e.target.value)}
                  type="text"
                  value={kioskTitle}
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  className="sm-div1-input2"
                  onChange={(e) => setKioskDesc(e.target.value)}
                  type="text"
                  value={kioskDesc}
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
          </div>
          <div className="sm-div2">
            <div className=" sm-m">
              <h6 className="submenuEdit-titles h6"> {lang.shortName}</h6>
              <input
                className="sm-input input-padding"
                onChange={(e) => setShortName(e.target.value)}
                value={shortName}
                style={{ margin: 0 }}
              />
            </div>
            <div className=" sm-m">
              <h6 className="submenuEdit-titles h6">{lang.secoundLanguage}</h6>
              <input
                className="sm-input SE-secoundLanguage input-padding"
                onChange={(e) => setSecoundLanguage(e.target.value)}
                value={secoundLanguage}
                style={{ margin: 0 }}
              />
            </div>
            <div className="sm-m ">
              <h6 className="submenuEdit-titles h6">{lang.sort}</h6>
              <input
                className="sm-input input-padding"
                onChange={(e) => setSort(e.target.value.replace(/\D/g, ""))}
                value={sort}
              />
            </div>
          </div>
          {/* <div className="sm-div3"></div> */}
          <div className="sm-div2">
            <div className="sm-m">
              <h6 className="h6 ">{lang.number_of_items_culmn}</h6>
              <Select
                options={number_of_culmn}
                defaultValue={numberOfItemsCulmn}
                onChange={(list, item) => setNumberOfItemsCulmn(list)}
                value={numberOfItemsCulmn}
                styles={customStyles}
                isClearable={false}
                isSearchable={false}
                isMulti={false}
              />
            </div>
            <div className="sm-m">
              <h6 className=" h6">{lang.number_of_items_row}</h6>
              <Select
                options={number_of_row_culmn}
                defaultValue={numberOfItemsRow}
                isSearchable={false}
                onChange={(list, item) => setNumberOfItemsRow(list)}
                value={numberOfItemsRow}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>

            <div className="sm-m">
              <h6 className="h6-text h6">{lang.items_font_size}</h6>
              <Select
                options={font_size}
                defaultValue={itemsFontSize}
                isSearchable={false}
                onChange={(list, item) => setItemsFontSize(list)}
                value={itemsFontSize}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="sm-div3">
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6"> {lang.minSelect}</h6>
              <input
                className="sm-input input-padding"
                onChange={(e) =>
                  setMinSelect(e.target.value.replace(/\D/g, ""))
                }
                value={minSelect}
              />
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">{lang.maxSelect}</h6>
              <input
                className="sm-input input-padding"
                onChange={(e) =>
                  setMaxSelect(e.target.value.replace(/\D/g, ""))
                }
                value={maxSelect}
              />
            </div>
          </div>
          <div className="sm-div4">
            <div className="sm-m ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setJoinBefore(e.target.checked)}
                      checked={joinBefore}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.beside_previous_item}
                />
              </FormGroup>
            </div>
            <div className="sm-m">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setInFirst(e.target.checked)}
                      checked={inFirst}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.name_first}
                />
              </FormGroup>
            </div>
            <div className="sm-m">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setIsOverridePrice(e.target.checked)}
                      checked={isOverridePrice}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.isOverridePrice}
                />
              </FormGroup>
            </div>
            {/* <div className="div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleIncloudeDressing}
                      checked={incloudeDressing}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.incloudeDressing}
                />
              </FormGroup>
            </div> */}
          </div>
          <div className="sm-div4">
            <div className="sm-m">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleIncloudeExtra(e)}
                      checked={incloudeExtra}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.incloudeExtra}
                />
              </FormGroup>
            </div>
            <div className="sm-m">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setVisible(e.target.checked)}
                      checked={visible}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible}
                />
              </FormGroup>
            </div>
            <div className="sm-m">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setFixed(e.target.checked)}
                      checked={fixed}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.fixed}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSaveClicked()} />
      {/* <button className="sm_button" onClick={handleSaveClicked}>
        save
      </button> */}
    </div>
  );
};

export default SubmenuEdit;
