import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import ListHeader from "../../../../components/ListHeader";
import MenuListItem from "../../../../components/MenuListItem";
import AddMenuItem from "../../../../components/AddMenuItem";
import {
  selectedTabelsAction,
  selectedAreaAction,
  showAddTablePopupAction,
} from "../../../../redux/GeneralSetting/Actions";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";
import { changeSelectedButtonBorderColorOnFocus } from "../../../../redux/menu/Actions";
import useScrollbarSize from "react-scrollbar-size";
const TabelsList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { height, width } = useScrollbarSize();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [tabelsList, setTabelList] = useState([]);
  const { selected_area, tabels_list, tabel_area_list, selected_tabel } =
    useSelector((state) => state.tabelAreaRedusers);
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  const handleEditTabel = (item, key) => {
    setClickedItem(key);
    dispatch(selectedTabelsAction(item));
  };
  const handleCreateTabel = () => {
    setClickedItem(2);
    dispatch(selectedTabelsAction(null));
  };
  const lastItem = tabel_area_list[tabel_area_list.length - 1];

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    if (tabel_area_list) {
      if (tabel_area_list.length > 0) {
        if (tabels_list) {
          if (tabels_list.length > 0) {
            setTabelList(tabels_list);
          } else {
            setTabelList([]);
          }
        } else {
          setTabelList([]);
        }
      } else {
        setTabelList([]);
      }
    } else {
      setTabelList([]);
    }
  }, [tabel_area_list, tabels_list]);

  return (
    <div className="countaining-div" id="countaining">
      <div className="menu_list_header_container">
        {/* <ListHeader title={selected_area?selected_area.TableName:lang.tabels_list} show_back={false} /> */}

        {tabelsList.length ? (
          <div style={{ marginBottom: "57px" }}>
            {tabelsList.map((item, index) => (
              <div>
                {/* {!item.IsDeleted ? ( */}
                <MenuListItem
                  field_number={1}
                  field_1_title={item.TableName}
                  item_title={item.TableName}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleEditTabel(item, 2)}
                  show_delete={false}
                  // delete_click={() => handleDeleted(item)}
                  backgroundColors={true}
                  items={item}
                  show_edit_icon={false}
                  number={0}
                  selected={selected_tabel}
                  item={item}
                />
                {/* // ) : null} */}
              </div>
            ))}
          </div>
        ) : tabel_area_list ? (
          tabel_area_list.length > 0 ? (
            <div
              className="div_submenu_add_container"
              style={{ zIndex: 1 }}
              onClick={() => {
                // handleCreateTabel(2);
                dispatch(showAddTablePopupAction(true));
              }}
            >
              <h4>{lang.noOption}</h4>
              <span>
                <i class="fa fa-plus" aria-hidden="true"></i>
                <p>{lang.add_tabels}</p>
              </span>
            </div>
          ) : (
            <div className="div_submenu_add_container" style={{ zIndex: 1 }}>
              <h4 style={{ marginTop: "60px" }}>{lang.no_tabel}</h4>
            </div>
          )
        ) : (
          <div
            className="div_submenu_add_container"
            style={{ zIndex: 1 }}
            onClick={() => {
              // handleCreateTabel(2);
              dispatch(showAddTablePopupAction(true));
            }}
          >
            <h4 style={{ marginTop: "60px" }}>{lang.no_tabel}</h4>
          </div>
        )}
      </div>

      {tabelsList.length ? (
        <div
          className="div_submenu_add_container_fixed"
          onClick={() => {
            // handleCreateTabel(2);
            dispatch(showAddTablePopupAction(true));
          }}
          style={{
            right:
              (window.innerWidth * 2) / 100 +
              width +
              marginNormalMenuHorizontal,
            bottom: "19px",
            left: "1180px",
          }}
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
          <p>{lang.add_tabels}</p>
        </div>
      ) : null}
    </div>
  );
};

export default TabelsList;
