import * as actionTypes from "../ActionTypes";

export const showSetupPpopupAction = (data) => {
  return {
    type: actionTypes.SHOW_SETUP_POPUP,
    payload: data,
  };
};

export const resetCashOrderAction = (login_key, user_id, shop_id) => {
  return {
    type: actionTypes.RESET_CASH_ORDER,
    payload: { login_key, user_id, shop_id },
  };
};
export const resetCashOrderSuccessfullAction = (data) => {
  return {
    type: actionTypes.RESET_CASH_ORDER_SUCCESSFULL,
    payload: data,
  };
};
export const resetCashOrderFailedAction = (data) => {
  return {
    type: actionTypes.RESET_CASH_ORDER_FAILED,
    payload: data,
  };
};
