import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";
//CREATE SUBMENU
export const createSubmenuServices = async (
  login_key,
  user_id,
  shopId,
  title,
  description,
  Include_Dressing
) => {
  return await axios.post(`${baseUrl}createsubmenu/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    AppTitle: title,
    AppDescription: description,
    IncludeDressing: Include_Dressing,
  });
};
export const getSubmenuServices = async (
  login_key,
  user_id,
  shopId,
  prodcut_id
) => {
  return await axios.put(`${baseUrl}submenuslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    ProductId: prodcut_id,
  });
};
export const deleteSubmenuServices = async (
  login_key,
  user_id,
  shopId,
  submenuId
) => {
  return await axios.delete(`${baseUrl}deletesubmenu/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shopId,
      SubmenuId: submenuId,
    },
  });
};

export const updateSubmnuServices = async (
  login_key,
  user_id,
  id,
  shopId,
  app_title,
  app_description,
  web_title,
  web_description,
  kiosk_title,
  kiosk_description,
  secound_language,
  min_select,
  max_select,
  is_override_price,
  extra_q,
  yes_no_q,
  join_before,
  name_first,
  short_name,
  app_vissible,
  web_visible,
  kiosk_visible,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  just_title_and_price,
  number_of_items_column,
  number_of_items_row,
  items_font_size,
  fixed
) => {
  return await axios.put(`${baseUrl}updatesubmenu/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    ShopId: shopId,
    AppTitle: app_title,
    AppDescription: app_description,
    WebTitle: web_title,
    WebDescription: web_description,
    KioskTitle: kiosk_title,
    KioskDescription: kiosk_description,
    SecondLanguage: secound_language,
    MinSelect: min_select,
    MaxSelect: max_select,
    IsOverridePrice: is_override_price,
    ExtraQ: extra_q,
    YesNoQ: yes_no_q,
    JoinBefore: join_before,
    NameFirst: name_first,
    ShortName: short_name,
    AppVisible: app_vissible,
    WebVisible: web_visible,
    KioskVisible: kiosk_visible,
    AppSortId: app_sort_id,
    WebSortId: web_sort_id,
    KioskSortId: kiosk_sort_id,
    JustTitleAndPrice: just_title_and_price,
    NumberOfItemsColumn: number_of_items_column,
    NumberOfItemsRow: number_of_items_row,
    ItemsFontSize: items_font_size,
    FixedItem: fixed,
  });
};

export const createSubmenuItemServices = async (
  login_key,
  user_id,
  ShopId,
  submenuId,
  app_title,
  app_description,
  amount
) => {
  return await axios.post(`${baseUrl}createsubmenuitem/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: ShopId,
    SubmenuId: submenuId,
    AppTitle: app_title,
    AppDescription: app_description,
    MainPrice: amount,
  });
};

export const getSubmenuItemServices = async (
  login_key,
  user_id,
  shopId,
  submenuId
) => {
  return await axios.put(`${baseUrl}subitemslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    SubMenuId: submenuId,
  });
};

export const deleteSubmenuItemServices = async (
  login_key,
  user_id,
  shopId,
  id,
  submenuId
) => {
  return await axios.delete(`${baseUrl}deletesubmenuitem/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shopId,
      Id: id,
      SubmenuId: submenuId,
    },
  });
};

export const updateSubmenuItemEditServices = async (
  formData2,
  login_key,
  user_id,
  id,
  shopId,
  submenuId,
  price_level_collec_take,
  price_level_eat_in,
  price_level_main,
  app_title,
  app_description,
  web_title,
  web_description,
  kiosk_title,
  kiosk_description,
  secound_language,
  amount_main,
  amount_collection,
  amount_takeaway,
  amount_eat_in,
  app_vissible,
  web_visible,
  kiosk_visible,
  app_bg_color,
  app_title_font_size,
  app_title_font_family,
  app_title_color,
  vatId,
  is_dif_option_vat,
  unitId,
  barcode,
  stock,
  custom_title,
  close_count,
  printer_id,
  report_section,
  product_group,
  app_title_width,
  app_title_height,
  link_to,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  app_image_url,
  app_visible_image,
  web_image_url,
  web_visible_image,
  kiosk_image_url,
  kiosk_visible_image
) => {
  //   formData.append('LoginKey',login_key);
  //   formData.append('UserId',user_id);
  //   formData.append('Id',id);
  //   formData.append('ShopId',shopId);
  //   formData.append('SubMenuId',submenuId);
  //   formData.append('PriceLevelCollTake',price_level_collec_take);
  //   formData.append('PriceLevelEatIn',price_level_eat_in);
  //   formData.append('PriceLevelMain',price_level_main);
  //   formData.append('AppTitle',app_title);
  //   formData.append('AppDescription',app_description);
  //   formData.append('WebTitle',web_title);
  //   formData.append('WebDescription',web_description);
  //   formData.append('KioskTitle',kiosk_title);
  //   formData.append('KioskDescription',kiosk_description);
  //   formData.append('SecondLanguage',secound_language);
  //   formData.append('AmountMain',amount_main);
  //   formData.append('AmountCollection',amount_collection);
  //   formData.append('AmountTakeaway',amount_takeaway);
  //   formData.append('AmountEatIn',amount_eat_in);
  //   formData.append('AppVisible',app_vissible);
  //   formData.append('WebVisible',web_visible);
  //   formData.append('KioskVisible',kiosk_visible);
  //   formData.append('AppBgColor',app_bg_color);
  //   formData.append('AppTitleFontSize',app_title_font_size);
  //   formData.append('AppTitleFontFamily',app_title_font_family);
  //   formData.append('AppTitleColor',app_title_color);
  //   formData.append('VatId',vatId);
  //   formData.append('IsDifOptionVat',is_dif_option_vat);
  //   formData.append('UnitId',unitId);
  //   formData.append('Barcode',barcode);
  //   formData.append('Stock',stock);
  //   formData.append('CustomTitle',custom_title);
  //   formData.append('CloseCount',close_count);
  //   formData.append('PrinterId',printer_id);
  //   formData.append('ReportSection',report_section);
  //   formData.append('ProductGroup',product_group);
  //   formData.append('AppTileWidth',app_title_width);
  // formData.append('AppTileHeight',app_title_height);
  // formData.append('Linkto',link_to);
  // formData.append('AppSortId',app_sort_id);
  // formData.append('WebSortId',web_sort_id);
  // formData.append('KioskSortId',kiosk_sort_id);
  // formData.append('AppImageUrl',app_image_url);
  // formData.append('AppVisibleImage',app_visible_image);
  // formData.append('WebImageUrl',web_image_url);
  // formData.append('WebVisibleImage',web_visible_image);
  // formData.append('KioskImageUrl',kiosk_image_url);
  // formData.append('KioskVisibleImage',kiosk_visible_image)

  return await axios.post(
    `${baseUrl}updatesubmenuitem/`,
    formData2
    //  {

    //   LoginKey: login_key,
    //   UserId: user_id,
    //   Id: id,
    //   ShopId: shopId,
    //   SubMenuId: submenuId,
    //   PriceLevelCollTake:price_level_collec_take,
    //   PriceLevelEatIn:price_level_eat_in,
    //   PriceLevelMain:price_level_main,
    //   AppTitle: app_title,
    //   AppDescription: app_description,
    //   WebTitle: web_title,
    //   WebDescription: web_description,
    //   KioskTitle: kiosk_title,
    //   KioskDescription: kiosk_description,
    //   SecondLanguage: secound_language,
    //   AmountMain: amount_main,
    //   AmountCollection: amount_collection,
    //   AmountTakeaway: amount_takeaway,
    //   AmountEatIn: amount_eat_in,
    //   AppVisible: app_vissible,
    //   WebVisible: web_visible,
    //   KioskVisible: kiosk_visible,
    //   AppBgColor: app_bg_color,
    //   AppTitleFontSize: app_title_font_size,
    //   AppTitleFontFamily: app_title_font_family,
    //   AppTitleColor: app_title_color,
    //   VatId: vatId,
    //   IsDifOptionVat: is_dif_option_vat,
    //   UnitId: unitId,
    //   Barcode: barcode,
    //   Stock: stock,
    //   CustomTitle: custom_title,
    //   CloseCount: close_count,
    //   PrinterId: printer_id,
    //   ReportSection: report_section,
    //   ProductGroup: product_group,
    //   AppTileWidth: app_title_width,
    //   AppTileHeight: app_title_height,
    //   Linkto: link_to,
    //   AppSortId: app_sort_id,
    //   WebSortId: web_sort_id,
    //   KioskSortId: kiosk_sort_id,
    //   AppImageUrl: app_image_url,
    //   AppVisibleImage: app_visible_image,
    //   WebImageUrl: web_image_url,
    //   WebVisibleImage: web_visible_image,
    //   KioskImageUrl: kiosk_image_url,
    //   KioskVisibleImage: kiosk_visible_image,
    // }
  );
};

//IMPORT SUBITEM
export const importSubItemService = async (
  login_key,
  user_id,
  shop_id,
  sub_item_id,
  sunmenu_id,
  import_category
) => {
  return await axios.put(`${baseUrl}importSubItem/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: sub_item_id,
    SubmenuId: sunmenu_id,
    ImportCategory: import_category,
  });
};

// UPDATE SUBMENU ITEM
export const updateSubmenuItemWebSortService = async (
  login_key,
  user_id,
  shop_id,
  sub_item_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(`${baseUrl}updateSubitemWebSortid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    SubItemId: sub_item_id,
    OldSortId: old_sort_id,
    NewSortId: new_sort_id,
  });
};
export const updateSubmenuItemAppSortService = async (
  login_key,
  user_id,
  shop_id,
  sub_item_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(`${baseUrl}updateSubitemAppSortid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    SubItemId: sub_item_id,
    OldSortId: old_sort_id,
    NewSortId: new_sort_id,
  });
};
