import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { SketchPicker } from "react-color";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ListHeader from "../components/ListHeader";
import SaveMenuButton from "../components/SaveMenuButton";
import { priceLevel } from "../constants/PriceLevel";
import { image_Url } from "../constants/BaseUrl";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import {
  selectedSubmenuItemAction,
  updateSubmenuItemEditAction,
} from "../redux/submenu/Action";

const SubmenuItemEdit = ({ setClickedItem }) => {
  const didMountRef = useRef();
  // const ref = useRef(null);
  // const image_href = "http://37.32.4.85:";

  //  const image_href="http://46.101.51.209:800/";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_submenu_item, result_successfull } = useSelector(
    (state) => state.submenuRedusers
  );
  const {
    category_list,
    selected_category,
    show_hide,
    printer_list,
    report_section,
    product_group,
    vat,
    font_size,
    font_family,
    updating_successfull,
    updating_failed,
    category_update_message,
    link_to,
  } = useSelector((state) => state.categoryList);
  const image_href = selected_shop[0].Domain;
  const [productGroup, setProductGroup] = useState(null);
  // const [priceLevel, setPriceLevel] = useState(0);
  const [appTitle, setAppTitle] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [kioskTitle, setKioskTitle] = useState("");
  const [kioskDesc, setKioskDesc] = useState("");
  const [appSort, setAppsort] = useState(1);
  const [webSort, setWebsort] = useState(1);
  const [kioskSort, setKiosksort] = useState(1);
  const [fontSize, setFontSize] = useState(null);

  const [fontFamily, setFontFamily] = useState(null);
  const [selectedVat, setSelectedVat] = useState(null);
  const [linkto, setLinkto] = useState(0);
  const [secondLanguage, setSecondLanguage] = useState("");
  const [src, setSrc] = useState(null);
  const [key1, setKey1] = useState("");
  const [key2, setKey2] = useState("");
  const [href, setHref] = useState(null);
  const [appImage, setAppImage] = useState("");
  const [showAppImage, setShowAppImage] = useState(true);

  const [showWebImage, setShowWebImage] = useState(true);
  const [kioskImage, setkioskImage] = useState("");
  const [showKioskImage, setShowKioskImage] = useState(true);
  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#14aeba");
  const [titleColor, setTitleColor] = useState("#d0021b");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [reportSection, setReportSection] = useState(null);
  const [selectedPrinterList, setSelectedPrinterList] = useState([]);
  const [appVisible, setAppVisible] = useState(true);
  const [webVisible, setWebVisible] = useState(true);
  const [kioskVisible, setKioskVisible] = useState(true);
  const [isDifOptionVat, setIsDifOptionVat] = useState(true);
  const [amountMain, setAmountMain] = useState(0.0);
  const [amountCollection, setAmountCollevtion] = useState(0.0);
  const [amountTakeaway, setAmountTakeaway] = useState(0.0);
  const [amountEatIn, setAmountEatIn] = useState(0.0);

  const [closeCount, setCloseCount] = useState(1);
  const [customTitle, setCustomTitle] = useState("");
  const [barcode, setBarcode] = useState("");

  const [priceLevelEatIn, setPriceLevelEatIn] = useState(null);
  const [priceLevelTakeaway, setPriceLevelTakeawayt] = useState(null);
  const [priceLevelMain, setPriceLevelMain] = useState(null);
  const [width, setWidth] = useState(16);
  const [height, setHeight] = useState(20);
  const [lastAppImageName, setLastAppImageName] = useState("");
  const [lastKioskImageName, setLastKiokImageName] = useState("");

  const [focused, setFocused] = useState(false);
  const [srcDimensions, setSrcDimensions] = useState({});
  const [urlDimensions, setUrlDimensions] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  const imageUrl = src;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl1 = href;
  const loadImage1 = () => {
    const img = new Image();
    img.src = imageUrl1;

    img.onload = () => {
      setUrlDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleInputBackgroundColor = (e) => {
    setBackgroundColor(e.target.value);
  };
  const handleInputTitleColor = (e) => {
    setTitleColor(e.target.value);
  };
  const handleClose = () => {
    setShowColorPicker(false);
  };

  const handleCloseInputColor = () => {
    setShowSketchPicker(false);
  };
  const handleChangeCompleteBackgroundColor = (color) => {
    setBackgroundColor(color.hex);
    document.getElementById("input_backgroundColor").value = color.hex;
  };

  const handleOnclickeInput = () => {
    setShowSketchPicker(!showSketchPicker);
    setShowColorPicker(false);
  };
  const handleChangeCompleteTitleColor = (color) => {
    setTitleColor(color.hex);
    document.getElementById("inputColor").value = color.hex;
  };
  const handleOnclickeInputColor = () => {
    setShowColorPicker(!showColorPicker);
    setShowSketchPicker(false);
  };

  const onSelectFile = (e) => {
    setAppImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }
    setKey1(e);
  };
  // const onSelectImage = (e) => {
  //   setWebImage(e.target.files[0]);
  //   if (e.target.files && e.target.files.length > 0) {
  //     setUrl(URL.createObjectURL(e.target.files[0]));
  //   }
  // };
  const onSelectPicture = (e) => {
    setkioskImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    setKey2(e);
  };
  const formData = new FormData();
  const handleSave = () => {
    let printer_list_to_server_array = [];
    if (selectedPrinterList) {
      for (let i = 0; i < selectedPrinterList.length; i++) {
        printer_list_to_server_array.push(selectedPrinterList[i].value);
      }
    }
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (appImage.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.app_image_size}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (kioskImage.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.kiosk_size_image}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("Id", selected_submenu_item.Id);
      formData.append("ShopId", selected_submenu_item.ShopId);
      formData.append("SubMenuId", selected_submenu_item.SubMenuId);
      formData.append(
        "PriceLevelCollTake",
        priceLevelTakeaway ? priceLevelTakeaway.value : 0
      );
      formData.append(
        "PriceLevelEatIn",
        priceLevelEatIn ? priceLevelEatIn.value : 0
      );
      formData.append(
        "PriceLevelMain",
        priceLevelMain ? priceLevelMain.value : 0
      );
      formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
      formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
      formData.append("WebTitle", webTitle.replaceAll(`"`, `\"\"`));
      formData.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
      formData.append("KioskTitle", kioskTitle.replaceAll(`"`, `\"\"`));
      formData.append("KioskDescription", kioskDesc.replaceAll(`"`, `\"\"`));
      formData.append("SecondLanguage", secondLanguage.replaceAll(`"`, `\"\"`));
      formData.append("AmountMain", amountMain ? parseFloat(amountMain) : 0);
      formData.append(
        "AmountCollection",
        amountCollection ? parseFloat(amountCollection) : 0
      );
      formData.append(
        "AmountTakeaway",
        amountTakeaway ? parseFloat(amountTakeaway) : 0
      );
      formData.append("AmountEatIn", amountEatIn ? parseFloat(amountEatIn) : 0);
      formData.append("AppVisible", appVisible ? 1 : 0);
      formData.append("WebVisible", webVisible ? 1 : 0);
      formData.append("KioskVisible", webVisible ? 1 : 0);
      formData.append("AppBgColor", backgroundColor);
      formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
      formData.append(
        "AppTitleFontFamily",
        fontFamily ? fontFamily.value.toString() : String(4)
      );
      formData.append("AppTitleColor", titleColor);
      formData.append("VatId", selectedVat ? selectedVat.value : 0);
      formData.append("IsDifOptionVat", isDifOptionVat ? 1 : 0);
      formData.append("UnitId", 0);
      formData.append("Barcode", barcode.replaceAll(`"`, `\"\"`));
      formData.append("Stock", 0);
      formData.append("CustomTitle", customTitle.replaceAll(`"`, `\"\"`));
      formData.append("CloseCount", closeCount ? parseInt(closeCount) : 0);
      formData.append(
        "PrinterId",
        selectedPrinterList ? printer_list_to_server_array.toString() : ""
      );
      formData.append("ReportSection", reportSection ? reportSection.value : 0);
      formData.append("ProductGroup", productGroup ? productGroup.value : 0);
      formData.append("AppTileWidth", width ? parseInt(width) : 0);
      formData.append("AppTileHeight", height ? parseInt(height) : 0);
      formData.append("Linkto", linkto ? linkto.value : 1);
      formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
      formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
      formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
      formData.append("AppImageUrl", appImage);
      formData.append("AppVisibleImage", showAppImage ? 1 : 0);
      formData.append("WebImageUrl", "");
      formData.append("WebVisibleImage", showWebImage ? 1 : 0);
      formData.append("KioskImageUrl", kioskImage);
      formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
      formData.append("LastAppImageUrlName", lastAppImageName);
      formData.append("JustTitleAndPrice", 0);
      formData.append("DeleteImage", 0);
      dispatch(
        updateSubmenuItemEditAction(
          formData,
          login_key,
          user_id,
          selected_submenu_item.Id,
          selected_submenu_item.ShopId,
          selected_submenu_item.SubMenuId,
          priceLevelTakeaway ? priceLevelTakeaway.value : 0,
          priceLevelEatIn ? priceLevelEatIn.value : 0,
          priceLevelMain ? priceLevelMain.value : 0,
          appTitle.replaceAll(`"`, `\"\"`),
          appDesc.replaceAll(`"`, `\"\"`),
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          webDesc.replaceAll(`"`, `\"\"`),
          kioskTitle
            ? kioskTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          kioskDesc.replaceAll(`"`, `\"\"`),
          secondLanguage.replaceAll(`"`, `\"\"`),
          amountMain ? parseFloat(amountMain) : 0,
          amountCollection ? parseFloat(amountCollection) : 0,
          amountTakeaway ? parseFloat(amountTakeaway) : 0,
          amountEatIn ? parseFloat(amountEatIn) : 0,
          appVisible ? 1 : 0,
          webVisible ? 1 : 0,
          kioskVisible ? 1 : 0,
          backgroundColor,
          fontSize ? fontSize.value : 30,
          fontFamily ? fontFamily.value.toString() : String(4),
          titleColor,
          selectedVat ? selectedVat.value : 0,
          isDifOptionVat ? 1 : 0,
          0,
          barcode.replaceAll(`"`, `\"\"`),
          0,
          customTitle.replaceAll(`"`, `\"\"`),
          closeCount ? parseInt(closeCount) : 0,
          selectedPrinterList ? printer_list_to_server_array.toString() : "",
          reportSection ? reportSection.value : 0,
          productGroup ? productGroup.value : 0,
          width ? parseInt(width) : 0,
          height ? parseInt(height) : 0,
          linkto ? linkto.value : 1,
          appSort ? parseInt(appSort) : 0,
          webSort ? parseInt(webSort) : 0,
          kioskSort ? parseInt(kioskSort) : 0,
          appImage,
          showAppImage ? 1 : 0,

          showWebImage ? 1 : 0,
          kioskImage,
          showKioskImage ? 1 : 0
        )
      );
    }
  };
  const handleDeleteImage = (key) => {
    let printer_list_to_server_array = [];
    if (key == 1) {
      if (key1) {
        key1.target.value = null;
      }
      setSrc("");
      setAppImage("");
      if (selected_submenu_item.AppImageUrl != "") {
        if (
          `${image_Url}${image_href}/${selected_submenu_item.AppImageUrl}` !=
          src
        ) {
          setSrc(
            `${image_Url}${image_href}/${selected_submenu_item.AppImageUrl}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_submenu_item.Id);
          formData.append("ShopId", selected_submenu_item.ShopId);
          formData.append("SubMenuId", selected_submenu_item.SubMenuId);
          formData.append(
            "PriceLevelCollTake",
            priceLevelTakeaway ? priceLevelTakeaway.value : 0
          );
          formData.append(
            "PriceLevelEatIn",
            priceLevelEatIn ? priceLevelEatIn.value : 0
          );
          formData.append(
            "PriceLevelMain",
            priceLevelMain ? priceLevelMain.value : 0
          );
          formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
          formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
          formData.append("WebTitle", webTitle.replaceAll(`"`, `\"\"`));
          formData.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
          formData.append("KioskTitle", kioskTitle.replaceAll(`"`, `\"\"`));
          formData.append(
            "KioskDescription",
            kioskDesc.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "SecondLanguage",
            secondLanguage.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "AmountMain",
            amountMain ? parseFloat(amountMain) : 0
          );
          formData.append(
            "AmountCollection",
            amountCollection ? parseFloat(amountCollection) : 0
          );
          formData.append(
            "AmountTakeaway",
            amountTakeaway ? parseFloat(amountTakeaway) : 0
          );
          formData.append(
            "AmountEatIn",
            amountEatIn ? parseFloat(amountEatIn) : 0
          );
          formData.append("AppVisible", appVisible ? 1 : 0);
          formData.append("WebVisible", webVisible ? 1 : 0);
          formData.append("KioskVisible", webVisible ? 1 : 0);
          formData.append("AppBgColor", backgroundColor);
          formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
          formData.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData.append("AppTitleColor", titleColor);
          formData.append("VatId", selectedVat ? selectedVat.value : 0);
          formData.append("IsDifOptionVat", isDifOptionVat ? 1 : 0);
          formData.append("UnitId", 0);
          formData.append("Barcode", barcode.replaceAll(`"`, `\"\"`));
          formData.append("Stock", 0);
          formData.append("CustomTitle", customTitle.replaceAll(`"`, `\"\"`));
          formData.append("CloseCount", closeCount ? parseInt(closeCount) : 0);
          formData.append(
            "PrinterId",
            selectedPrinterList ? printer_list_to_server_array.toString() : ""
          );
          formData.append(
            "ReportSection",
            reportSection ? reportSection.value : 0
          );
          formData.append(
            "ProductGroup",
            productGroup ? productGroup.value : 0
          );
          formData.append("AppTileWidth", width ? parseInt(width) : 0);
          formData.append("AppTileHeight", height ? parseInt(height) : 0);
          formData.append("Linkto", linkto ? linkto.value : 1);
          formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData.append("AppImageUrl", "");
          formData.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData.append("WebImageUrl", "");
          formData.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData.append("KioskImageUrl", kioskImage);
          formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData.append("LastAppImageUrlName", lastAppImageName);
          formData.append("JustTitleAndPrice", 0);
          formData.append("DeleteImage", 1);
          dispatch(
            updateSubmenuItemEditAction(
              formData,
              login_key,
              user_id,
              selected_submenu_item.Id,
              selected_submenu_item.ShopId,
              selected_submenu_item.SubMenuId,
              priceLevelTakeaway ? priceLevelTakeaway.value : 0,
              priceLevelEatIn ? priceLevelEatIn.value : 0,
              priceLevelMain ? priceLevelMain.value : 0,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle
                ? webTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle
                ? kioskTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              amountMain ? parseFloat(amountMain) : 0,
              amountCollection ? parseFloat(amountCollection) : 0,
              amountTakeaway ? parseFloat(amountTakeaway) : 0,
              amountEatIn ? parseFloat(amountEatIn) : 0,
              appVisible ? 1 : 0,
              webVisible ? 1 : 0,
              kioskVisible ? 1 : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              selectedVat ? selectedVat.value : 0,
              isDifOptionVat ? 1 : 0,
              0,
              barcode.replaceAll(`"`, `\"\"`),
              0,
              customTitle.replaceAll(`"`, `\"\"`),
              closeCount ? parseInt(closeCount) : 0,
              selectedPrinterList
                ? printer_list_to_server_array.toString()
                : "",
              reportSection ? reportSection.value : 0,
              productGroup ? productGroup.value : 0,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              linkto ? linkto.value : 1,
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              appImage,
              showAppImage ? 1 : 0,

              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0
            )
          );
        }
      }
    } else {
      if (key2) {
        key2.target.value = null;
      }
      setHref("");
      setkioskImage("");
      if (selected_submenu_item.KioskImageUrl != "") {
        if (
          `${image_Url}${image_href}/${selected_submenu_item.KioskImageUrl}` !=
          href
        ) {
          setHref(
            `${image_Url}${image_href}/${selected_submenu_item.KioskImageUrl}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_submenu_item.Id);
          formData.append("ShopId", selected_submenu_item.ShopId);
          formData.append("SubMenuId", selected_submenu_item.SubMenuId);
          formData.append(
            "PriceLevelCollTake",
            priceLevelTakeaway ? priceLevelTakeaway.value : 0
          );
          formData.append(
            "PriceLevelEatIn",
            priceLevelEatIn ? priceLevelEatIn.value : 0
          );
          formData.append(
            "PriceLevelMain",
            priceLevelMain ? priceLevelMain.value : 0
          );
          formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
          formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
          formData.append("WebTitle", webTitle.replaceAll(`"`, `\"\"`));
          formData.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
          formData.append("KioskTitle", kioskTitle.replaceAll(`"`, `\"\"`));
          formData.append(
            "KioskDescription",
            kioskDesc.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "SecondLanguage",
            secondLanguage.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "AmountMain",
            amountMain ? parseFloat(amountMain) : 0
          );
          formData.append(
            "AmountCollection",
            amountCollection ? parseFloat(amountCollection) : 0
          );
          formData.append(
            "AmountTakeaway",
            amountTakeaway ? parseFloat(amountTakeaway) : 0
          );
          formData.append(
            "AmountEatIn",
            amountEatIn ? parseFloat(amountEatIn) : 0
          );
          formData.append("AppVisible", appVisible ? 1 : 0);
          formData.append("WebVisible", webVisible ? 1 : 0);
          formData.append("KioskVisible", webVisible ? 1 : 0);
          formData.append("AppBgColor", backgroundColor);
          formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
          formData.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData.append("AppTitleColor", titleColor);
          formData.append("VatId", selectedVat ? selectedVat.value : 0);
          formData.append("IsDifOptionVat", isDifOptionVat ? 1 : 0);
          formData.append("UnitId", 0);
          formData.append("Barcode", barcode.replaceAll(`"`, `\"\"`));
          formData.append("Stock", 0);
          formData.append("CustomTitle", customTitle.replaceAll(`"`, `\"\"`));
          formData.append("CloseCount", closeCount ? parseInt(closeCount) : 0);
          formData.append(
            "PrinterId",
            selectedPrinterList ? printer_list_to_server_array.toString() : ""
          );
          formData.append(
            "ReportSection",
            reportSection ? reportSection.value : 0
          );
          formData.append(
            "ProductGroup",
            productGroup ? productGroup.value : 0
          );
          formData.append("AppTileWidth", width ? parseInt(width) : 0);
          formData.append("AppTileHeight", height ? parseInt(height) : 0);
          formData.append("Linkto", linkto ? linkto.value : 1);
          formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData.append("AppImageUrl", appImage ? appImage : "");
          formData.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData.append("WebImageUrl", "");
          formData.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData.append("KioskImageUrl", "");
          formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData.append("LastAppImageUrlName", lastAppImageName);
          formData.append("JustTitleAndPrice", 0);
          formData.append("DeleteImage", 3);
          dispatch(
            updateSubmenuItemEditAction(
              formData,
              login_key,
              user_id,
              selected_submenu_item.Id,
              selected_submenu_item.ShopId,
              selected_submenu_item.SubMenuId,
              priceLevelTakeaway ? priceLevelTakeaway.value : 0,
              priceLevelEatIn ? priceLevelEatIn.value : 0,
              priceLevelMain ? priceLevelMain.value : 0,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle
                ? webTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle
                ? kioskTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              amountMain ? parseFloat(amountMain) : 0,
              amountCollection ? parseFloat(amountCollection) : 0,
              amountTakeaway ? parseFloat(amountTakeaway) : 0,
              amountEatIn ? parseFloat(amountEatIn) : 0,
              appVisible ? 1 : 0,
              webVisible ? 1 : 0,
              kioskVisible ? 1 : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              selectedVat ? selectedVat.value : 0,
              isDifOptionVat ? 1 : 0,
              0,
              barcode.replaceAll(`"`, `\"\"`),
              0,
              customTitle.replaceAll(`"`, `\"\"`),
              closeCount ? parseInt(closeCount) : 0,
              selectedPrinterList
                ? printer_list_to_server_array.toString()
                : "",
              reportSection ? reportSection.value : 0,
              productGroup ? productGroup.value : 0,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              linkto ? linkto.value : 1,
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              appImage,
              showAppImage ? 1 : 0,

              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0
            )
          );
        }
      }
    }
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSave();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    priceLevel,
    appTitle,
    appDesc,
    webTitle,
    webDesc,
    kioskTitle,
    kioskDesc,
    secondLanguage,
    amountMain,
    amountCollection,
    amountTakeaway,
    amountEatIn,
    appVisible,
    webVisible,
    kioskVisible,
    backgroundColor,
    fontSize,
    fontFamily,
    titleColor,
    selectedVat,
    isDifOptionVat,
    barcode,
    customTitle,
    closeCount,
    selectedPrinterList,
    reportSection,
    productGroup,
    width,
    height,
    linkto,
    appSort,
    webSort,
    kioskSort,
    appImage,
    showAppImage,
    showWebImage,
    kioskImage,
    showKioskImage,
  ]);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (selected_submenu_item) {
      setAppTitle(selected_submenu_item.AppTitle);
      setAppDesc(selected_submenu_item.AppDescription);
      setWebTitle(selected_submenu_item.WebTitle);
      setWebDesc(selected_submenu_item.WebDescription);
      setKioskTitle(selected_submenu_item.KioskTitle);
      setKioskDesc(selected_submenu_item.KioskDescription);
      setSecondLanguage(selected_submenu_item.SecondLanguage);
      setAmountMain(selected_submenu_item.AmountMain);
      setAmountCollevtion(selected_submenu_item.AmountCollection);
      setAmountTakeaway(selected_submenu_item.AmountTakeaway);
      setAmountEatIn(selected_submenu_item.AmountEatIn);
      setAppVisible(selected_submenu_item.AppVisible);
      setWebVisible(selected_submenu_item.WebVisible);
      setKioskVisible(selected_submenu_item.KioskVisible);
      setBackgroundColor(selected_submenu_item.AppBgColor);
      setFontSize(selected_submenu_item.AppTitleFontSize);
      setFontFamily(selected_submenu_item.AppTitleFontFamily);
      setTitleColor(selected_submenu_item.AppTitleColor);
      setSelectedVat(selected_submenu_item.VatId);
      setIsDifOptionVat(selected_submenu_item.IsDifOptionVat);
      setBarcode(selected_submenu_item.Barcode);
      setCustomTitle(selected_submenu_item.CustomTitle);
      setCloseCount(selected_submenu_item.CloseCount);
      setSelectedPrinterList(selected_submenu_item.PrinterId);
      setReportSection(selected_submenu_item.ReportSection);
      setProductGroup(selected_submenu_item.ProductGroup);
      setWidth(selected_submenu_item.AppTileWidth);
      setHeight(selected_submenu_item.AppTileHeight);
      setLinkto(selected_submenu_item.Linkto);
      setAppsort(selected_submenu_item.AppSortId);
      setWebsort(selected_submenu_item.WebSortId);
      setKiosksort(selected_submenu_item.KioskSortId);
      // setSrc(`${baseUrl}${selected_submenu_item.AppImageUrl}`);
      // setHref(
      //   `${image_href}${700 + selected_category.ShopId}/${
      //     selected_category.KioskImageUrl
      //   }`
      // );

      // setSrc(
      //   `${image_href}${700 + selected_category.ShopId}/${
      //     selected_category.AppImageUrl
      //   }`
      // );
      if (selected_submenu_item.KioskImageUrl) {
        setHref(
          `${image_Url}${image_href}/${selected_submenu_item.KioskImageUrl}`
        );
      } else {
        setHref("");
      }
      if (selected_submenu_item.AppImageUrl) {
        setSrc(
          `${image_Url}${image_href}/${selected_submenu_item.AppImageUrl}`
        );
      } else {
        setSrc("");
      }

      setLastAppImageName(selected_category.AppImageUrl);
      setLastAppImageName(selected_submenu_item.AppImageUrl);
      setShowAppImage(selected_submenu_item.AppVisibleImage);
      setShowWebImage(selected_submenu_item.WebVisibleImage);
      // setHref(`${baseUrl}${selected_submenu_item.KioskImageUrl}`);
      setShowKioskImage(selected_submenu_item.KioskVisibleImage);
      setPriceLevelEatIn(selected_submenu_item.PriceLevelEatIn);
      setPriceLevelMain(selected_submenu_item.PriceLevelMain);
      setPriceLevelTakeawayt(selected_submenu_item.PriceLevelCollTake);
    }
  }, [selected_submenu_item]);

  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(3);
    }
  }, [result_successfull]);
  // const useFocus = (ref, defaultState = false) => {
  //   const [state, setState] = useState(defaultState);

  //   useEffect(() => {

  //     const onFocus = () => setState(true);
  //     const onBlur = () => setState(false);
  //     ref.current.addEventListener("focus", onFocus);
  //     ref.current.addEventListener("blur", onBlur);

  //     return () => {
  //
  //     };
  //   }, []);

  //   return state;
  // };
  // useEffect(() => {
  //   if (selected_shop) {
  //     if (selected_shop.length > 0) {
  //       let base_url = `otopartnercentre.com:${
  //         700 + selected_shop[0].ShopId
  //       }/var/www/shop${selected_shop[0].ShopId}/`;
  //       setBaseUrl(base_url);
  //     }
  //   }
  // }, [selected_shop]);

  return (
    // <div style={{ paddingBottom: "130px" ,width:"97%"}}>
    //   <i className="fas fa-arrow-right" onClick={() => setClickedItem(3)}></i>
    <div
      // ref={ref}
      // onFocus={onFocus}
      // onBlur={onBlur}
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
      // className={`countaining-div ${focused && "is-focused"}`}
    >
      <ListHeader
        title={lang.submenu_item_edit}
        show_back={true}
        clickedItem={() => setClickedItem(5)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {/* TITLES */}
          <div
            className="sm-div1 "
            style={{
              marginTop: 30,
            }}
          >
            {/* APP TITLE */}
            <div className=" sm-m">
              <h6 className="title h6">{lang.app_title}</h6>
              <div className="sm-div1-2 ">
                <input
                  onChange={(e) => setAppTitle(e.target.value)}
                  type="text"
                  value={appTitle}
                  className="sm-div1-input1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setAppDesc(e.target.value)}
                  type="text"
                  value={appDesc}
                  className="sm-div1-input2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            {/* WEB TTILE */}
            <div className=" sm-m">
              <h6 className="title h6">{lang.web_title}</h6>
              <div className="sm-div1-2  ">
                <input
                  onChange={(e) => setWebTitle(e.target.value)}
                  type="text"
                  value={webTitle}
                  className="sm-div1-input1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setWebDesc(e.target.value)}
                  type="text"
                  value={webDesc}
                  className="sm-div1-input2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            {/* KIOSK TITLE */}
            <div className=" sm-m ">
              <h6 className="title h6">{lang.kiosk_title}</h6>
              <div className="sm-div1-2  ">
                <input
                  onChange={(e) => setKioskTitle(e.target.value)}
                  type="text"
                  value={kioskTitle}
                  className="sm-div1-input1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setKioskDesc(e.target.value)}
                  type="text"
                  value={kioskDesc}
                  className="sm-div1-input2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
          </div>
          {/* SORT */}
          <div className="sm-div2">
            <div className="sm-m">
              <h6 className=" h6">{lang.sort}</h6>
              <input
                className="sm-input input-padding"
                placeholder={lang.placeholderSort}
                value={appSort}
                onChange={(e) => setAppsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="sm-m">
              <h6 className=" h6">{lang.sort}</h6>
              <input
                className="sm-input input-padding"
                placeholder={lang.placeholderSort}
                value={webSort}
                onChange={(e) => setWebsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="sm-m">
              <h6 className=" h6">{lang.sort}</h6>
              <input
                className="sm-input input-padding"
                placeholder={lang.placeholderSort}
                value={kioskSort}
                onChange={(e) =>
                  setKiosksort(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
          </div>
          {/* *** */}
          {/* IMAGE */}
          <div className="SIE-div2 ">
            <div>
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectFile(e)}
                  className="SIE-file-i"
                />
              </div>
              <div className="SIE-image-item" style={{ position: "relative" }}>
                {src ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImage(1)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    src
                      ? src
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
            {/* <div>
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectImage(e)}
                  className="SIE-file-i"
                />
              </div>
              <div className="SIE-image-item">
                <img src={url} className="images-style" />
              </div>
            </div> */}
            <div style={{ marginRight: "-41px" }}>
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectPicture(e)}
                  className="SIE-file-i"
                />
              </div>
              <div className="SIE-image-item" style={{ position: "relative" }}>
                {href ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImage(3)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}
                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    href
                      ? href
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage1}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
          </div>
          {/* IMAGE CHECK BOXES */}
          <div className="SIE-div2-chechbox">
            <div className="div-chechbox-p sm-m">
              {/* <input
                id="check46"
                // className="checkbox"
                type="checkbox"
                checked={showAppImage}
                onChange={() => setShowAppImage(!showAppImage)}
              />
              <label for="check46" className="checkmark"></label>
              <h6 className="h6">{lang.showImage}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAppImage}
                      onChange={() => setShowAppImage(!showAppImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p sm-m">
              {/* <input
                id="check47"
                // className="checkbox"
                type="checkbox"
                checked={showWebImage}
                onChange={() => setShowWebImage(!showWebImage)}
              />
              <label for="check47" className="checkmark"></label>
              <h6 className=" h6">{lang.showImage}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showWebImage}
                      onChange={() => setShowWebImage(!showWebImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
            <div className=" sm-m div-chechbox-p">
              {/* <input
                id="check48"
                // className="checkbox"
                type="checkbox"
                checked={showKioskImage}
                onChange={() => setShowKioskImage(!showKioskImage)}
              />
              <label for="check48" className="checkmark"></label>
              <h6 className=" h6">{lang.showImage}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showKioskImage}
                      onChange={() => setShowKioskImage(!showKioskImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
          </div>
          <div className="div-9 submenuItemEdit_div9">
            {/* BACKGROUND  COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.backgroundColor}</h6>
              <div className="SIT-bachgroundColor-div">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: backgroundColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInput}
                  Value={backgroundColor}
                  onChange={handleInputBackgroundColor}
                  id="input_backgroundColor"
                />
              </div>
              {showSketchPicker ? (
                <div
                  className="SketchPicker-div"
                  onMouseLeave={handleCloseInputColor}
                >
                  <SketchPicker
                    color={backgroundColor}
                    onChangeComplete={handleChangeCompleteBackgroundColor}
                  />
                </div>
              ) : null}
            </div>

            {/* FONT COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.fontColor}</h6>
              <div className="SIT-bachgroundColor-div">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: titleColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInputColor}
                  Value={titleColor}
                  onChange={handleInputTitleColor}
                  id="inputColor"
                />
              </div>
              {showColorPicker ? (
                <div className="SketchPicker-div" onMouseLeave={handleClose}>
                  <SketchPicker
                    color={titleColor}
                    onChangeComplete={handleChangeCompleteTitleColor}
                  />
                </div>
              ) : null}
            </div>

            {/* FONT SIZE */}
            <div className="SIE-Appearance_information">
              <h6 className="h6-text h6">{lang.fontSize}</h6>
              <Select
                options={font_size}
                defaultValue={fontSize}
                isSearchable={false}
                onChange={(list, item) => setFontSize(list)}
                value={fontSize}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* FONT FAMILY */}
            <div className="SIE-Appearance_information">
              <h6 className="h6-text h6">{lang.fontFamily}</h6>
              <Select
                options={font_family}
                defaultValue={fontFamily}
                isSearchable={false}
                onChange={(list, item) => {
                  setFontFamily(list);
                }}
                value={fontFamily}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* WIDTH */}
            <div className="SIE-Appearance_information">
              <h6 className="h6-text h6">{lang.width}</h6>
              <input
                onChange={(e) => setWidth(e.target.value.replace(/\D/g, ""))}
                type="text"
                value={width}
                className="Appearance_information_input input-padding"
                // placeholder={lang.placeholderTitle}
              />
            </div>
            {/* HEIGHT */}
            <div className="SIE-Appearance_information">
              <h6 className="h6-text h6">{lang.height}</h6>
              <input
                onChange={(e) => setHeight(e.target.value.replace(/\D/g, ""))}
                type="text"
                value={height}
                className="Appearance_information_input input-padding"
                // placeholder={lang.placeholderTitle}
              />
            </div>
          </div>
          <div className="submenuItemEdit_firstGroupDropDown">
            <div className="submenuItemEdit-multiselect-div">
              <h6 className=" h6"> {lang.price_level_main}</h6>
              <Select
                options={priceLevel}
                defaultValue={priceLevelMain}
                isSearchable={false}
                onChange={(list, item) => {
                  setPriceLevelMain(list);
                }}
                value={priceLevelMain}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="submenuItemEdit-multiselect-div ">
              <h6 className="h6">
                {" "}
                {lang.price_level_takeaway}//{lang.collections}
              </h6>
              <Select
                options={priceLevel}
                defaultValue={priceLevelTakeaway}
                isSearchable={false}
                onChange={(list, item) => {
                  setPriceLevelTakeawayt(list);
                }}
                value={priceLevelTakeaway}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="submenuItemEdit-multiselect-div">
              <h6 className=" h6">{lang.price_level_eat_in}</h6>
              <Select
                options={priceLevel}
                defaultValue={priceLevelEatIn}
                isSearchable={false}
                onChange={(list, item) => {
                  setPriceLevelEatIn(list);
                }}
                value={priceLevelEatIn}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="submenuItemEdit_firstGroupDropDown">
            <div className="submenuItemEdit-multiselect-div">
              <h6 className="h6">{lang.report_section}</h6>
              <Select
                options={report_section}
                defaultValue={reportSection}
                isSearchable={false}
                onChange={(list, item) => setReportSection(list)}
                value={reportSection}
                styles={customStyles}
                isClearable={false}
              />
            </div>

            <div className="submenuItemEdit-multiselect-div">
              <h6 className="h6">{lang.productGroup}</h6>
              <Select
                options={product_group}
                defaultValue={productGroup}
                isSearchable={false}
                onChange={(list, item) => setProductGroup(list)}
                value={productGroup}
                styles={customStyles}
                isClearable={false}
              />
            </div>

            <div className="submenuItemEdit-multiselect-div">
              <h6 className="h6">{lang.secoundLanguage}</h6>
              <input
                className="submenuItemEdit-input input-padding"
                value={secondLanguage}
                onChange={(e) => setSecondLanguage(e.target.value)}
              />
            </div>
          </div>
          <div className="submenuItemEdit_secoundGroupDropDown">
            <div className="submenuItemEdit-multiselect-div">
              <h6 className=" h6">{lang.printer}</h6>
              <Select
                options={printer_list}
                defaultValue={selectedPrinterList}
                isSearchable={false}
                onChange={(list, item) => setSelectedPrinterList(list)}
                value={selectedPrinterList}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="submenuItemEdit-multiselect-div">
              <h6 className="h6 ">{lang.vat}</h6>
              <Select
                options={vat}
                defaultValue={selectedVat}
                onChange={(list, item) => {
                  setSelectedVat(list);
                }}
                value={selectedVat}
                styles={customStyles}
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </div>

          <div className="submenuItemEdit-amountdiv">
            <div className="submenuItemEdit-div1">
              <h6 className="h6"> {lang.amount_main}</h6>
              <input
                className="submenuItemEdit-input input-padding"
                value={amountMain}
                onChange={(e) =>
                  setAmountMain(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="submenuItemEdit-div1">
              <h6 className="h6">{lang.amount_collection}</h6>
              <input
                className="submenuItemEdit-input input-padding"
                value={amountCollection}
                onChange={(e) =>
                  setAmountCollevtion(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="submenuItemEdit-div1">
              <h6 className="h6">{lang.amount_takeaway}</h6>
              <input
                className="submenuItemEdit-input input-padding"
                value={amountTakeaway}
                onChange={(e) =>
                  setAmountTakeaway(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="submenuItemEdit-div1">
              <h6 className="h6">{lang.amount_eatIn}</h6>
              <input
                className="submenuItemEdit-input input-padding"
                value={amountEatIn}
                onChange={(e) =>
                  setAmountEatIn(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
          </div>
          {/* <div className="submenuItemEdit-amountdiv">
            <div className="submenuItemEdit-div1">
              <h6 className="h6">{lang.barcode}</h6>
              <input
                className="submenuItemEdit-input input-padding"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
              />
            </div>
            <div className="submenuItemEdit-div1">
              <h6 className="h6">{lang.customTitle}</h6>
              <input
                className="submenuItemEdit-input input-padding"
                value={customTitle}
                onChange={(e) => setCustomTitle(e.target.value)}
              />
            </div>
            <div className="submenuItemEdit-div1">
              <h6 className="h6"> {lang.close_count}</h6>
              <input
                className="submenuItemEdit-input input-padding"
                value={closeCount}
                onChange={(e) => setCloseCount(e.target.value)}
              />
             </div> 
          </div> */}
          {/* <div className="submenuItemEdit-amountdiv">
            <div className="submenuItemEdit-div1 div-chechbox-p">
              <input
                id="check49"
                type="checkbox"
                checked={appVisible}
                onChange={() => setAppVisible(!appVisible)}
              />
              <label for="check49" className="checkmark"></label>
              <h6 className=" h6">{lang.app_visible}</h6>
            </div>
            <div className="submenuItemEdit-div1 div-chechbox-p">
              <input
                id="check50"
                type="checkbox"
                checked={webVisible}
                onChange={() => setWebVisible(!webVisible)}
              />
              <label for="check50" className="checkmark"></label>
              <h6 className=" h6">{lang.web_visible}</h6>
            </div>
            <div className="submenuItemEdit-div1 div-chechbox-p">
              <input
                id="check51"
                type="checkbox"
                checked={kioskVisible}
                onChange={() => setKioskVisible(!kioskVisible)}
              />
              <label for="check51" className="checkmark"></label>
              <h6 className=" h6">{lang.kiosk_visible}</h6>
            </div>
            <div className="submenuItemEdit-div1 div-chechbox-p">
              <input
                id="check52"
                type="checkbox"
                checked={isDifOptionVat}
                onChange={() => setIsDifOptionVat(!isDifOptionVat)}
              />
              <label for="check52" className="checkmark"></label>
              <h6 className=" h6">{lang.is_dif_option_vat}</h6>
            </div>
          </div> */}
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSave()} />
    </div>
  );
};

export default SubmenuItemEdit;
