import * as actionType from "../ActionTypes";

const initialStates = {
  result_failed: false,
  result_message: "",
  result_successfull: false,
  loading: false,
  fof_list: [],
  fof_meta_data_list: [],
};

const WePosRedusers = (state = initialStates, action) => {
  switch (action.type) {
    case actionType.SET_DEFAULT:
      return {
        ...state,
        result_failed: false,
        result_message: "",
        result_successfull: false,
        loading: false,
      };
    case actionType.GET_WEPOS_SETTING_LIST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_WEPOS_SETTING_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        fof_list: action.payload,
      };
    case actionType.GET_WEPOS_SETTING_LIST_FAILED:
      return {
        ...state,
        loading: false,
        fof_list: [],
      };
    case actionType.UPDATE_WEPOS_SETTING:
      return {
        ...state,
        loading: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };
    case actionType.UPDATE_WEPOS_SETTING_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionType.UPDATE_WEPOS_SETTING_FAILED:
      return {
        ...state,
        loading: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };

    // WEPOS META DATA
    case actionType.GET_WEPOS_META_DATA_LIST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_WEPOS_META_DATA_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        fof_meta_data_list: action.payload,
      };
    case actionType.GET_WEPOS_META_DATA_LIST_FAILED:
      return {
        ...state,
        loading: false,
        fof_meta_data_list: [],
      };
    case actionType.UPDATE_WEPOS_META_DATA:
      return {
        ...state,
        result_message: "",
        result_successfull: false,
        result_failed: false,
        loading: true,
      };
    case actionType.UPDATE_WEPOS_META_DATA_SUCCESSFULL:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
        loading: false,
      };
    case actionType.UPDATE_WEPOS_META_DATA_FAILED:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
        loading: false,
      };

    default:
      return state;
  }
};
export default WePosRedusers;
