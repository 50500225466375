import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Multiselect from "multiselect-react-dropdown";
import store from "store2";
import { Availability } from "../constants/DropDownList";
// import Checkbox from "@material-ui/core/Checkbox";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";

import {
  number_of_row_culmn,
  number_of_culmn,
} from "../constants/NumberOfRowAndCulmn";
import SaveMenuButton from "../components/SaveMenuButton";
import ListHeader from "../components/ListHeader";
import { image_Url } from "../constants/BaseUrl";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import {
  sendCategorySettingsAction,
  setDefaultAction,
  createCategoryAction,
  showCategoryAvailabilityPopupAction,
} from "../redux/menu/Actions";
import { receiveLoginResponse } from "../redux/login/Actions";
const CategoryEdit = ({ title, controlInputFocuse }) => {
  // const image_href="http://46.101.51.209:800/";
  const default_colors = ["#4d7534", "#4d7534", "#4d7534", "#4d7534"];
  const dispatch = useDispatch();
  const appMultiSelectRef = useRef();
  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    category_list,
    selected_category,
    show_hide,
    printer_list,
    report_section,
    product_group,
    vat,
    font_size,
    font_size_id,
    font_family,
    updating_successfull,
    updating_failed,
    category_update_message,
    result_message,
    handle_enter_key,
  } = useSelector((state) => state.categoryList);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { loading_category_settings, result_successfull } = useSelector(
    (state) => state.categoryList
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    selected_submenu,
    submenu_list,
    fixed_submenu_list,
    fixed_submenu_ids,
  } = useSelector((state) => state.submenuRedusers);
  // const image_href1 = selected_shop[0].SubDomain;
  const image_href1 = selected_shop[0].Domain;
  const [src, setSrc] = useState(null);
  const [url, setUrl] = useState(null);
  const [href, setHref] = useState(null);

  const [appTitle, setAppTitle] = useState("");
  const [mealDeals, setMealDeals] = useState(false);
  const [appDesc, setAppDesc] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [kioskTitle, setKioskTitle] = useState("");
  const [kioskDesc, setKioskDesc] = useState("");

  const [appSort, setAppsort] = useState(0);
  const [webSort, setWebsort] = useState(0);
  const [kioskSort, setKiosksort] = useState(0);

  const [appImage, setAppImage] = useState("");
  const [showAppImage, setShowAppImage] = useState(false);
  const [webImage, setWebImage] = useState("");
  const [showWebImage, setShowWebImage] = useState(false);
  const [kioskImage, setkioskImage] = useState("");
  const [showKioskImage, setShowKioskImage] = useState(false);

  const [fontSize, setFontSize] = useState(null);
  const [productFontSize, setProductFontSize] = useState(null);
  const [fontFamily, setFontFamily] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [selectedVat, setSelectedVat] = useState(null);
  const [reportSection, setReportSection] = useState(null);
  const [selectedPrinterList, setSelectedPrinterList] = useState([]);
  const [productGroup, setProductGroup] = useState(null);

  const [secondLanguage, setSecondLanguage] = useState("");

  const [backgroundColor, setBackgroundColor] = useState("#14aeba");
  const [titleColor, setTitleColor] = useState("#d0021b");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const [appShowHideValues, setAppShowHideValues] = useState([]);
  const [webShowHideValues, setWebShowHideValues] = useState([]);
  const [kioskShowHideValues, setKioskShowHideValues] = useState([]);
  const [lastAppImageName, setLastAppImageName] = useState("");
  const [lastWebImageName, setLastWebImageName] = useState("");
  const [lastKioskImageName, setLastKiokImageName] = useState("");
  const [incloudHalfAndHalf, setIncloudHalfAndHalf] = useState(false);
  const [emptyFormData, setEmptyFormData] = useState(false);
  const [numberOfProductRow, setNumberOfProductRow] = useState(null);
  const [numberOfProductCulmn, setNumberOfProductCulmn] = useState(null);
  const [srcDimensions, setSrcDimensions] = useState({});
  const [urlDimensions, setUrlDimensions] = useState({});
  const [hrefDimensions, setHrefDimensions] = useState({});
  const [productBackgroundColor, setProductBackgroundColor] = useState([
    "#4d7534",
    "#4d7534",
    "#4d7534",
    "#4d7534",
  ]);
  const [colorKey, setColorKey] = useState(0);
  const [
    showProductBackGroundColorPicker,
    setShowProductBackGroundColorPicker,
  ] = useState(false);
  const [baseUrl, setBaseUrl] = useState("");
  const [key1, setKey1] = useState("");
  const [key2, setKey2] = useState("");
  const [key3, setKey3] = useState("");
  const [availability, setAvailability] = useState({
    value: 1,
    label: "Always",
  });
  const [selectedFixedItem, setSelectedFixedItem] = useState(null);

  const imageUrl = src;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl1 = url;
  const loadImage1 = () => {
    const img = new Image();
    img.src = imageUrl1;

    img.onload = () => {
      setUrlDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const imageUrl2 = href;
  const loadImage2 = () => {
    const img = new Image();
    img.src = imageUrl2;

    img.onload = () => {
      setHrefDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  // useImperativeHandle(forwardedRef, () => ({
  //   addCategoryTitle: () => {
  //     addCategoryTitle();
  //   },
  // }));

  const style = {
    chips: {
      // background: "white",
    },
    searchBox: {
      border: "none",
      "border-bottom": "1px solid blue",
      // "border": "1px solid blue",
      // "border-radius": "0px",
      padding: "20px 20px 0px 20px",
      backgroundColor: "white",
    },
    multiselectContainer: {
      // color: "red",
    },
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  // const single_style = {
  //   chips: {
  //     // background: "#0091ea",
  //     // color: "white",
  //     fontSize: 25,
  //   },
  //   searchBox: {
  //     border: "none",
  //     "border-bottom": "1px solid blue",
  //     // "border-radius": "0px",
  //     padding: "10px 20px 0px 20px",
  //     backgroundColor: "white",
  //   },
  //   multiselectContainer: {
  //     // color: "white",
  //   },
  // };

  const reserMy = () => {
    appMultiSelectRef.current.resetSelectedValues(["EatIn"]);
  };
  const handleCategoryAvailability = (list) => {
    setAvailability(list);
  };
  const handleInputBackgroundColor = (e) => {
    setBackgroundColor(e.target.value);
  };
  const handleInputTitleColor = (e) => {
    setTitleColor(e.target.value);
  };
  const handleProductBackgroundColor = (key, e) => {
    if (key == 1) {
      setProductBackgroundColor([
        e.target.value,
        productBackgroundColor[1],
        productBackgroundColor[2],
        productBackgroundColor[3],
      ]);
    } else if (key == 2) {
      setProductBackgroundColor([
        productBackgroundColor[0],
        e.target.value,
        productBackgroundColor[2],
        productBackgroundColor[3],
      ]);
    } else if (key == 3) {
      setProductBackgroundColor([
        productBackgroundColor[0],
        productBackgroundColor[1],
        e.target.value,
        productBackgroundColor[3],
      ]);
    } else {
      setProductBackgroundColor([
        productBackgroundColor[0],
        productBackgroundColor[1],
        productBackgroundColor[2],
        e.target.value,
      ]);
    }
  };
  const handleShowProductBackgroundColorPicker = (key) => {
    setColorKey(key);
    setShowProductBackGroundColorPicker(true);
  };
  const handleChangeCompleteProductBackgroundColor = (color) => {
    if (colorKey == 1) {
      setProductBackgroundColor([
        color.hex,
        productBackgroundColor[1],
        productBackgroundColor[2],
        productBackgroundColor[3],
      ]);
    } else if (colorKey == 2) {
      setProductBackgroundColor([
        productBackgroundColor[0],
        color.hex,
        productBackgroundColor[2],
        productBackgroundColor[3],
      ]);
    } else if (colorKey == 3) {
      setProductBackgroundColor([
        productBackgroundColor[0],
        productBackgroundColor[1],
        color.hex,
        productBackgroundColor[3],
      ]);
    } else {
      setProductBackgroundColor([
        productBackgroundColor[0],
        productBackgroundColor[1],
        productBackgroundColor[2],
        color.hex,
      ]);
    }
  };
  const handleClose = () => {
    setShowColorPicker(false);
  };
  const handleCloseProductColorPicker = () => {
    setShowProductBackGroundColorPicker(false);
  };
  const handleCloseInputColor = () => {
    setShowSketchPicker(false);
  };
  const handleChangeCompleteBackgroundColor = (color) => {
    setBackgroundColor(color.hex);
    document.getElementById("input_backgroundColor").value = color.hex;
  };

  const handleOnclickeInput = () => {
    setShowSketchPicker(!showSketchPicker);
    setShowColorPicker(false);
  };
  const handleChangeCompleteTitleColor = (color) => {
    setTitleColor(color.hex);
    document.getElementById("inputColor").value = color.hex;
  };
  const handleOnclickeInputColor = () => {
    setShowColorPicker(!showColorPicker);
    setShowSketchPicker(false);
  };
  const onSelectFile = (e) => {
    setAppImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
      //  const formData = new FormData();
      //  formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
      // setAppImage(formData);
    }

    setKey1(e);
  };
  const onSelectImage = (e) => {
    setKey2(e);
    setWebImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const onSelectPicture = (e) => {
    setKey3(e);
    setkioskImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
  };
  const onSelect = (selectedList, selectedItem) => {
    if (selectedItem.id == 5) {
      setAppShowHideValues([{ id: 5, name: "All" }]);
    } else if (selectedItem.id == 0) {
      setAppShowHideValues([{ id: 0, name: "None" }]);
    } else {
      let selected_list_idies = [];
      for (let i = 0; i < selectedList.length; i++) {
        selected_list_idies.push(selectedList[i].id);
      }
      if (selected_list_idies.includes(0) || selected_list_idies.includes(5)) {
        setAppShowHideValues([selectedItem]);
      } else {
        setAppShowHideValues(selectedList);
      }
    }
  };
  const onSelectAppVisible = (list, item) => {
    if (item.action == "select-option") {
      if (item.option.value == 5) {
        setAppShowHideValues([{ value: 5, label: "All" }]);
      } else if (item.option.value == 0) {
        setAppShowHideValues([{ value: 0, label: "None" }]);
      } else {
        let id_list = [];
        for (let i = 0; i < list.length; i++) {
          id_list.push(list[i].value);
        }
        if (id_list.includes(5) || id_list.includes(0)) {
          setAppShowHideValues([item.option]);
        } else {
          setAppShowHideValues(list);
        }
      }
    } else if (item.action == "remove-value") {
      setAppShowHideValues(list);
    }
  };
  const onSelectWebVisible = (list, item) => {
    if (item.action == "select-option") {
      if (item.option.value == 5) {
        setWebShowHideValues([{ value: 5, label: "All" }]);
      } else if (item.option.value == 0) {
        setWebShowHideValues([{ value: 0, label: "None" }]);
      } else {
        let id_list = [];
        for (let i = 0; i < list.length; i++) {
          id_list.push(list[i].value);
        }
        if (id_list.includes(5) || id_list.includes(0)) {
          setWebShowHideValues([item.option]);
        } else {
          setWebShowHideValues(list);
        }
      }
    } else if (item.action == "remove-value") {
      setWebShowHideValues(list);
    }
  };
  const onSelectKioskVisible = (list, item) => {
    if (item.action == "select-option") {
      if (item.option.value == 5) {
        setKioskShowHideValues([{ value: 5, label: "All" }]);
      } else if (item.option.value == 0) {
        setKioskShowHideValues([{ value: 0, label: "None" }]);
      } else {
        let id_list = [];
        for (let i = 0; i < list.length; i++) {
          id_list.push(list[i].value);
        }

        if (id_list.includes(5) || id_list.includes(0)) {
          setKioskShowHideValues([item.option]);
        } else {
          setKioskShowHideValues(list);
        }
      }
    } else if (item.action == "remove-value") {
      setKioskShowHideValues(list);
    }
  };
  const onSelectWeb = (selectedList, selectedItem) => {
    if (selectedItem.id == 5) {
      setWebShowHideValues([{ name: "All", id: 5 }]);
    } else if (selectedItem.id == 0) {
      setWebShowHideValues([{ name: "None", id: 0 }]);
    } else {
      let selected_list_idies = [];
      for (let i = 0; i < selectedList.length; i++) {
        selected_list_idies.push(selectedList[i].id);
      }
      if (selected_list_idies.includes(0) || selected_list_idies.includes(5)) {
        setWebShowHideValues([selectedItem]);
      } else {
        setWebShowHideValues(selectedList);
      }
    }
  };
  const onSelectKiosk = (selectedList, selectedItem) => {
    if (selectedItem.id == 5) {
      setKioskShowHideValues([{ name: "All", id: 5 }]);
    } else if (selectedItem.id == 0) {
      setKioskShowHideValues([{ name: "None", id: 0 }]);
    } else {
      let selected_list_idies = [];
      for (let i = 0; i < selectedList.length; i++) {
        selected_list_idies.push(selectedList[i].id);
      }
      if (selected_list_idies.includes(0) || selected_list_idies.includes(5)) {
        setKioskShowHideValues([selectedItem]);
      } else {
        setKioskShowHideValues(selectedList);
      }
    }
  };
  const formData = new FormData();

  const handleSaveClicked = () => {
    let app_visible_to_server_array = [];
    let web_visible_to_server_array = [];
    let kiosk_visible_to_server_array = [];
    let printer_list_to_server_array = [];
    const areEqual =
      default_colors.length === productBackgroundColor.length &&
      default_colors.every(
        (value, index) => value === productBackgroundColor[index]
      );
    let all_colors = [];
    if (areEqual) {
      all_colors = [backgroundColor];
    } else {
      all_colors = [
        backgroundColor,
        productBackgroundColor[0],
        productBackgroundColor[1],
        productBackgroundColor[2],
        productBackgroundColor[3],
      ];
    }

    if (appShowHideValues) {
      for (let i = 0; i < appShowHideValues.length; i++) {
        app_visible_to_server_array.push(appShowHideValues[i].value);
      }
    }
    if (webShowHideValues) {
      for (let i = 0; i < webShowHideValues.length; i++) {
        web_visible_to_server_array.push(webShowHideValues[i].value);
      }
    }
    if (kioskShowHideValues) {
      for (let i = 0; i < kioskShowHideValues.length; i++) {
        kiosk_visible_to_server_array.push(kioskShowHideValues[i].value);
      }
    }
    if (selectedPrinterList) {
      for (let i = 0; i < selectedPrinterList.length; i++) {
        printer_list_to_server_array.push(selectedPrinterList[i].value);
      }
    }
    if (appTitle) {
      if (appImage != "" && appImage.size > 200000) {
        toast.error(<h5> &nbsp;&nbsp;{lang.app_image_size}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else if (
        srcDimensions != {} &&
        srcDimensions.width != srcDimensions.height
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.verify_with_height_in_appimage}&nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (webImage != "" && webImage.size > 200000) {
        toast.error(<h5> &nbsp;&nbsp;{lang.web_image_size}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else if (kioskImage != "" && kioskImage.size > 200000) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.kiosk_image_size}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        appShowHideValues.length > 0 &&
        webShowHideValues.length > 0 &&
        kioskShowHideValues.length > 0
      ) {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("Id", selected_category.Id);
        formData.append("ShopId", selected_category.ShopId);
        formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
        formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
        formData.append(
          "WebTitle",
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`)
        );
        formData.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
        formData.append(
          "KioskTitle",
          kioskTitle
            ? kioskTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`)
        );
        formData.append("KioskDescription", kioskDesc.replaceAll(`"`, `\"\"`));
        formData.append(
          "SecondLanguage",
          secondLanguage.replaceAll(`"`, `\"\"`)
        );
        formData.append("AppVisible", app_visible_to_server_array.toString());
        formData.append("WebVisible", web_visible_to_server_array.toString());
        formData.append(
          "KioskVisible",
          kiosk_visible_to_server_array.toString()
        );
        formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
        formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
        formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
        formData.append(
          "PrinterId",
          selectedPrinterList ? printer_list_to_server_array.toString() : ""
        );
        formData.append(
          "ReportSection",
          reportSection ? reportSection.value : 0
        );
        formData.append("ProductGroup", productGroup ? productGroup.value : 0);
        formData.append("IncludeHalfAndHalf", incloudHalfAndHalf ? 1 : 0);
        formData.append("VatId", selectedVat ? selectedVat.value : 0);
        formData.append("AppBgColor", all_colors.toString());
        formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
        formData.append(
          "AppTitleFontFamily",
          fontFamily ? fontFamily.value.toString() : String(4)
        );
        formData.append("AppTitleColor", titleColor);
        formData.append(
          "NumberOfProductsColumn",
          numberOfProductCulmn ? parseInt(numberOfProductCulmn.value) : 4
        );
        formData.append(
          "NumberOfProductsRow",
          numberOfProductRow ? parseInt(numberOfProductRow.value) : 6
        );
        formData.append("AppImageUrl", appImage ? appImage : "");
        formData.append("AppVisibleImage", showAppImage ? 1 : 0);
        formData.append("WebImageUrl", webImage ? webImage : "");
        formData.append("WebVisibleImage", showWebImage ? 1 : 0);
        formData.append("KioskImageUrl", kioskImage ? kioskImage : "");
        formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
        formData.append(
          "LastAppImageUrlName",
          lastAppImageName ? lastAppImageName : ""
        );
        formData.append(
          "LastWebImageUrlName",
          lastWebImageName ? lastWebImageName : ""
        );
        formData.append(
          "ProductsFontSize",
          productFontSize ? productFontSize.value : 9
        );
        formData.append("DeleteImage", 0);
        formData.append("MealDeal", mealDeals ? 1 : 0);
        formData.append("AlwaysOpen", availability.value == 0 ? 0 : 1);
        formData.append(
          "FixedItemId",
          selectedFixedItem ? selectedFixedItem.value : 0
        );
        dispatch(
          sendCategorySettingsAction(
            formData,
            login_key,
            user_id,
            selected_category.Id,
            selected_category.ShopId,
            appTitle.replaceAll(`"`, `\"\"`),
            appDesc.replaceAll(`"`, `\"\"`),
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`),
            webDesc.replaceAll(`"`, `\"\"`),
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`),
            kioskDesc.replaceAll(`"`, `\"\"`),
            secondLanguage.replaceAll(`"`, `\"\"`),
            app_visible_to_server_array.toString(),
            web_visible_to_server_array.toString(),
            kiosk_visible_to_server_array.toString(),
            appSort ? parseInt(appSort) : 0,
            webSort ? parseInt(webSort) : 0,
            kioskSort ? parseInt(kioskSort) : 0,
            selectedPrinterList ? printer_list_to_server_array.toString() : "",
            reportSection ? reportSection.value : 0,
            productGroup ? productGroup.value : 0,
            1,
            selectedVat ? selectedVat.value : 0,
            backgroundColor,
            fontSize ? fontSize.value : 30,
            fontFamily ? fontFamily.value.toString() : String(4),
            titleColor,
            width ? parseInt(width) : 0,
            height ? parseInt(height) : 0,
            appImage,
            showAppImage ? 1 : 0,
            webImage,
            showWebImage ? 1 : 0,
            kioskImage,
            showKioskImage ? 1 : 0
          )
        );
      } else {
        toast.error(<h5> &nbsp;&nbsp;{lang.fill_show_hide}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  };

  // useEffect(() => {
  //   const user_access = store.get("userAccess");
  //   if (!result_login_successfull) {
  //     if (user_access) {
  //       if (user_access.length > 0) {
  //         if (user_access[0].UserType != 10001201) {
  //           navigate("/");
  //         } else {
  //           dispatch(receiveLoginResponse(user_access));
  //         }
  //       }
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, []);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        if (
          title == "" &&
          controlInputFocuse != true &&
          handle_enter_key != true
        ) {
          handleSaveClicked();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    appTitle,
    appDesc,
    webTitle,
    webDesc,
    kioskTitle,
    kioskDesc,
    secondLanguage,
    appShowHideValues,
    webShowHideValues,
    kioskShowHideValues,
    appSort,
    webSort,
    kioskSort,
    selectedPrinterList,
    reportSection,
    productGroup,
    selectedVat,
    backgroundColor,
    fontSize,
    fontFamily,
    titleColor,
    width,
    height,
    appImage,
    showAppImage,
    webImage,
    showWebImage,
    kioskImage,
    showKioskImage,
    numberOfProductCulmn,
    numberOfProductRow,
  ]);

  useEffect(() => {
    if (result_successfull) {
      setEmptyFormData(!emptyFormData);
      setAppImage("");
      setWebImage("");
    }
  }, [result_successfull]);

  const handleDeleteImagesAndUpdate = (key) => {
    let app_visible_to_server_array = [];
    let web_visible_to_server_array = [];
    let kiosk_visible_to_server_array = [];
    let printer_list_to_server_array = [];

    if (appShowHideValues) {
      for (let i = 0; i < appShowHideValues.length; i++) {
        app_visible_to_server_array.push(appShowHideValues[i].value);
      }
    }
    if (webShowHideValues) {
      for (let i = 0; i < webShowHideValues.length; i++) {
        web_visible_to_server_array.push(webShowHideValues[i].value);
      }
    }
    if (kioskShowHideValues) {
      for (let i = 0; i < kioskShowHideValues.length; i++) {
        kiosk_visible_to_server_array.push(kioskShowHideValues[i].value);
      }
    }
    if (selectedPrinterList) {
      for (let i = 0; i < selectedPrinterList.length; i++) {
        printer_list_to_server_array.push(selectedPrinterList[i].value);
      }
    }
    if (key == 1) {
      if (key1) {
        key1.target.value = null;
      }

      setSrc("");
      setAppImage("");
      if (selected_category.AppImageUrl != "") {
        if (
          `${image_Url}${image_href1}/${selected_category.AppImageUrl}` != src
        ) {
          setSrc(`${image_Url}${image_href1}/${selected_category.AppImageUrl}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_category.Id);
          formData.append("ShopId", selected_category.ShopId);
          formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
          formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
          formData.append(
            "WebTitle",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
          formData.append(
            "KioskTitle",
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "KioskDescription",
            kioskDesc.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "SecondLanguage",
            secondLanguage.replaceAll(`"`, `\"\"`)
          );
          formData.append("AppVisible", app_visible_to_server_array.toString());
          formData.append("WebVisible", web_visible_to_server_array.toString());
          formData.append(
            "KioskVisible",
            kiosk_visible_to_server_array.toString()
          );
          formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData.append(
            "PrinterId",
            selectedPrinterList ? printer_list_to_server_array.toString() : ""
          );
          formData.append(
            "ReportSection",
            reportSection ? reportSection.value : 0
          );
          formData.append(
            "ProductGroup",
            productGroup ? productGroup.value : 0
          );
          formData.append("IncludeHalfAndHalf", incloudHalfAndHalf ? 1 : 0);
          formData.append("VatId", selectedVat ? selectedVat.value : 0);
          formData.append("AppBgColor", backgroundColor);
          formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
          formData.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData.append("AppTitleColor", titleColor);
          formData.append(
            "NumberOfProductsColumn",
            numberOfProductCulmn ? parseInt(numberOfProductCulmn.value) : 4
          );
          formData.append(
            "NumberOfProductsRow",
            numberOfProductRow ? parseInt(numberOfProductRow.value) : 6
          );
          formData.append("AppImageUrl", "");
          formData.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData.append("WebImageUrl", webImage ? webImage : "");
          formData.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData.append("KioskImageUrl", kioskImage ? kioskImage : "");
          formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData.append("LastAppImageUrlName", lastAppImageName);
          formData.append("LastWebImageUrlName", lastWebImageName);
          formData.append(
            "ProductsFontSize",
            productFontSize ? productFontSize.value : 9
          );
          formData.append(
            "DeleteImage",

            1
          );
          formData.append("MealDeal", mealDeals ? 1 : 0);
          formData.append("AlwaysOpen", availability.value == 0 ? 0 : 1);
          formData.append(
            "FixedItemId",
            selectedFixedItem ? selectedFixedItem.value : 0
          );
          dispatch(
            sendCategorySettingsAction(
              formData,
              login_key,
              user_id,
              selected_category.Id,
              selected_category.ShopId,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle
                ? webTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle
                ? kioskTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              toString(1),
              toString(1),
              toString(1),
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              "",
              reportSection ? reportSection.value : 0,
              productGroup ? productGroup.value : 0,
              1,
              selectedVat ? selectedVat.value : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              appImage,
              showAppImage ? 1 : 0,
              webImage,
              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0
            )
          );
        }
      }
    } else if (key == 2) {
      if (key2) {
        key2.target.value = null;
      }

      setUrl("");
      setWebImage("");
      if (selected_category.WebImageUrl != "") {
        if (
          `${image_Url}${image_href1}/${selected_category.WebImageUrl}` != url
        ) {
          setUrl(`${image_Url}${image_href1}/${selected_category.WebImageUrl}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_category.Id);
          formData.append("ShopId", selected_category.ShopId);
          formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
          formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
          formData.append(
            "WebTitle",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
          formData.append(
            "KioskTitle",
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );

          formData.append(
            "KioskDescription",
            kioskDesc.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "SecondLanguage",
            secondLanguage.replaceAll(`"`, `\"\"`)
          );
          formData.append("AppVisible", app_visible_to_server_array.toString());
          formData.append("WebVisible", web_visible_to_server_array.toString());
          formData.append(
            "KioskVisible",
            kiosk_visible_to_server_array.toString()
          );
          formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData.append(
            "PrinterId",
            selectedPrinterList ? printer_list_to_server_array.toString() : ""
          );
          formData.append(
            "ReportSection",
            reportSection ? reportSection.value : 0
          );
          formData.append(
            "ProductGroup",
            productGroup ? productGroup.value : 0
          );
          formData.append("IncludeHalfAndHalf", incloudHalfAndHalf ? 1 : 0);
          formData.append("VatId", selectedVat ? selectedVat.value : 0);
          formData.append("AppBgColor", backgroundColor);
          formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
          formData.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData.append("AppTitleColor", titleColor);
          formData.append(
            "NumberOfProductsColumn",
            numberOfProductCulmn ? parseInt(numberOfProductCulmn.value) : 4
          );
          formData.append(
            "NumberOfProductsRow",
            numberOfProductRow ? parseInt(numberOfProductRow.value) : 6
          );
          formData.append("AppImageUrl", appImage ? appImage : "");
          formData.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData.append("WebImageUrl", "");
          formData.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData.append("KioskImageUrl", kioskImage ? kioskImage : "");
          formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData.append("LastAppImageUrlName", lastAppImageName);
          formData.append("LastWebImageUrlName", lastWebImageName);
          formData.append(
            "ProductsFontSize",
            productFontSize ? productFontSize.value : 9
          );
          formData.append(
            "DeleteImage",

            2
          );
          formData.append("MealDeal", mealDeals ? 1 : 0);
          formData.append("AlwaysOpen", availability.value == 0 ? 0 : 1);
          formData.append(
            "FixedItemId",
            selectedFixedItem ? selectedFixedItem.value : 0
          );
          dispatch(
            sendCategorySettingsAction(
              formData,
              login_key,
              user_id,
              selected_category.Id,
              selected_category.ShopId,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle
                ? webTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle
                ? kioskTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              "",
              "",
              "",
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              "",
              reportSection ? reportSection.value : 0,
              productGroup ? productGroup.value : 0,
              1,
              selectedVat ? selectedVat.value : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              appImage,
              showAppImage ? 1 : 0,
              webImage,
              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0
            )
          );
        }
      }
    } else {
      if (key3) {
        key3.target.value = null;
      }

      setHref("");
      setkioskImage("");
      if (selected_category.KioskImageUrl != "") {
        if (
          `${image_Url}${image_href1}/${selected_category.KioskImageUrl}` !=
          href
        ) {
          setHref(
            `${image_Url}${image_href1}/${selected_category.KioskImageUrl}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_category.Id);
          formData.append("ShopId", selected_category.ShopId);
          formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
          formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
          formData.append(
            "WebTitle",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("WebDescription", webDesc.replaceAll(`"`, `\"\"`));
          formData.append(
            "KioskTitle",
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "KioskDescription",
            kioskDesc.replaceAll(`"`, `\"\"`)
          );
          formData.append(
            "SecondLanguage",
            secondLanguage.replaceAll(`"`, `\"\"`)
          );
          formData.append("AppVisible", app_visible_to_server_array.toString());
          formData.append("WebVisible", web_visible_to_server_array.toString());
          formData.append(
            "KioskVisible",
            kiosk_visible_to_server_array.toString()
          );
          formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData.append(
            "PrinterId",
            selectedPrinterList ? printer_list_to_server_array.toString() : ""
          );
          formData.append(
            "ReportSection",
            reportSection ? reportSection.value : 0
          );
          formData.append(
            "ProductGroup",
            productGroup ? productGroup.value : 0
          );
          formData.append("IncludeHalfAndHalf", incloudHalfAndHalf ? 1 : 0);
          formData.append("VatId", selectedVat ? selectedVat.value : 0);
          formData.append("AppBgColor", backgroundColor);
          formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
          formData.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData.append("AppTitleColor", titleColor);
          formData.append(
            "NumberOfProductsColumn",
            numberOfProductCulmn ? parseInt(numberOfProductCulmn.value) : 4
          );
          formData.append(
            "NumberOfProductsRow",
            numberOfProductRow ? parseInt(numberOfProductRow.value) : 6
          );
          formData.append("AppImageUrl", appImage ? appImage : "");
          formData.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData.append("WebImageUrl", "");
          formData.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData.append("KioskImageUrl", "");
          formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData.append("LastAppImageUrlName", lastAppImageName);
          formData.append("LastWebImageUrlName", lastWebImageName);
          formData.append(
            "ProductsFontSize",
            productFontSize ? productFontSize.value : 9
          );
          formData.append(
            "DeleteImage",

            3
          );
          formData.append("MealDeal", mealDeals ? 1 : 0);
          formData.append("AlwaysOpen", availability.value == 0 ? 0 : 1);
          formData.append(
            "FixedItemId",
            selectedFixedItem ? selectedFixedItem.value : 0
          );
          dispatch(
            sendCategorySettingsAction(
              formData,
              login_key,
              user_id,
              selected_category.Id,
              selected_category.ShopId,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle
                ? webTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle
                ? kioskTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              "",
              "",
              "",
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              "",
              reportSection ? reportSection.value : 0,
              productGroup ? productGroup.value : 0,
              1,
              selectedVat ? selectedVat.value : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              appImage,
              showAppImage ? 1 : 0,
              webImage,
              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    let rows_column_value = [];
    for (let i = 0; i < number_of_row_culmn.length; i++) {
      rows_column_value.push(number_of_row_culmn[i].value);
    }
    let number_of_row = null;
    let number_of_column = null;
    let productFontSize = null;
    let availability = null;
    let fixed_submenu = null;
    let copy_Availability = [...Availability];

    if (selected_category) {
      setAppTitle(selected_category.AppTitle);
      setAppDesc(selected_category.AppDescription);
      setWebTitle(selected_category.WebTitle);
      setWebDesc(selected_category.WebDescription);
      setKioskTitle(selected_category.KioskTitle);
      setKioskDesc(selected_category.KioskDescription);
      setAppsort(selected_category.AppSortId);
      setWebsort(selected_category.WebSortId);
      setKiosksort(selected_category.KioskSortId);

      if (selected_category.KioskImageUrl) {
        setHref(
          `${image_Url}${image_href1}/${selected_category.KioskImageUrl}`
        );
      } else {
        setHref("");
      }

      if (selected_category.AppImageUrl) {
        setSrc(`${image_Url}${image_href1}/${selected_category.AppImageUrl}`);
      } else {
        setSrc("");
      }

      setLastAppImageName(selected_category.AppImageUrl);
      if (selected_category.WebImageUrl) {
        setUrl(`${image_Url}${image_href1}/${selected_category.WebImageUrl}`);
      } else {
        setUrl("");
      }

      setMealDeals(selected_category.MealDeal);
      setLastWebImageName(selected_category.WebImageUrl);
      setShowAppImage(selected_category.AppVisibleImage);
      setShowWebImage(selected_category.WebVisibleImage);
      setShowKioskImage(selected_category.KioskVisibleImage);
      setSecondLanguage(selected_category.SecondLanguage);
      setBackgroundColor(selected_category.AppBgColor);
      setTitleColor(selected_category.AppTitleColor);
      setAppShowHideValues(selected_category.AppVisible);
      setWebShowHideValues(selected_category.WebVisible);
      setKioskShowHideValues(selected_category.KioskVisible);
      setSelectedVat(selected_category.VatId);
      setReportSection(selected_category.ReportSection);
      setProductGroup(selected_category.ProductGroup);
      setSelectedPrinterList(selected_category.PrinterId);
      setFontSize(selected_category.AppTitleFontSize);
      setFontFamily(selected_category.AppTitleFontFamily);
      setWidth(selected_category.AppTileWidth);
      setHeight(selected_category.AppTileHeight);
      setIncloudHalfAndHalf(selected_category.IncludeHalfAndHalf);
      if (selected_category.NumberOfProductsColumn) {
        if (
          rows_column_value.includes(
            parseInt(selected_category.NumberOfProductsColumn)
          )
        ) {
          let index = rows_column_value.indexOf(
            parseInt(selected_category.NumberOfProductsColumn)
          );
          number_of_column = {
            value: number_of_row_culmn[index].value,
            label: number_of_row_culmn[index].label,
          };
        }
      }
      if (selected_category.FixedItemId) {
        if (
          fixed_submenu_ids.includes(parseInt(selected_category.FixedItemId))
        ) {
          let index = fixed_submenu_ids.indexOf(
            parseInt(selected_category.FixedItemId)
          );
          fixed_submenu = {
            value: fixed_submenu_list[index].value,
            label: fixed_submenu_list[index].label,
          };
        }
      } else {
        fixed_submenu = {
          value: fixed_submenu_list[0].value,
          label: fixed_submenu_list[0].label,
        };
      }
      if (selected_category.NumberOfProductsRow) {
        if (
          rows_column_value.includes(
            parseInt(selected_category.NumberOfProductsRow)
          )
        ) {
          let index = rows_column_value.indexOf(
            parseInt(selected_category.NumberOfProductsRow)
          );
          number_of_row = {
            value: number_of_row_culmn[index].value,
            label: number_of_row_culmn[index].label,
          };
        }
      }
      if (selected_category.ProductsFontSize) {
        if (
          font_size_id.includes(parseInt(selected_category.ProductsFontSize))
        ) {
          let index = font_size_id.indexOf(
            parseInt(selected_category.ProductsFontSize)
          );
          productFontSize = {
            label: font_size[index].label,
            value: font_size[index].value,
          };
        }
      }

      if (selected_category.AlwaysOpen) {
        availability = {
          value: copy_Availability[0].value,
          label: copy_Availability[0].label,
        };
      } else {
        availability = {
          value: copy_Availability[1].value,
          label: copy_Availability[1].label,
        };
      }
      setAvailability(availability);
      setProductFontSize(productFontSize);
      setNumberOfProductCulmn(number_of_column);
      setNumberOfProductRow(number_of_row);
      setSelectedFixedItem(fixed_submenu);
    }
  }, [selected_category]);
  useEffect(() => {
    formData.delete("LoginKey");
    formData.delete("UserId");
    formData.delete("Id");
    formData.delete("ShopId");
    formData.delete("AppTitle");
    formData.delete("AppDescription");
    formData.delete("WebTitle");
    formData.delete("WebDescription");
    formData.delete("KioskTitle");
    formData.delete("KioskDescription");
    formData.delete("SecondLanguage");
    formData.delete("AppVisible");
    formData.delete("WebVisible");
    formData.delete("KioskVisible");
    formData.delete("AppSortId");
    formData.delete("WebSortId");
    formData.delete("KioskSortId");
    formData.delete("PrinterId");
    formData.delete("ReportSection");
    formData.delete("ProductGroup");
    formData.delete("IncludeHalfAndHalf");
    formData.delete("VatId");
    formData.delete("AppBgColor");
    formData.delete("AppTitleFontSize");
    formData.delete("AppTitleFontFamily");
    formData.delete("AppTitleColor");
    formData.delete("AppTileWidth");
    formData.delete("AppTileHeight");
    formData.delete("AppImageUrl");
    formData.delete("AppVisibleImage");
    formData.delete("WebImageUrl");
    formData.delete("WebVisibleImage");
    formData.delete("KioskImageUrl");
    formData.delete("KioskVisibleImage");
    formData.delete("NumberOfProductsColumn");
    formData.delete("NumberOfProductsRow");
    formData.delete("DeleteImage");
    formData.delete("MealDeal");
  }, []);

  return (
    <div className="countaining-div">
      <ListHeader title={lang.edit_category} show_back={false} />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="div-6">
            <div className="text ">
              <h6 className="autocomplete-title h6 ">{lang.vat}</h6>
              <Select
                options={vat}
                defaultValue={selectedVat}
                onChange={(list, item) => {
                  setSelectedVat(list);
                }}
                value={selectedVat}
                styles={customStyles}
                isClearable={false}
                isSearchable={false}
              />
            </div>

            {/* REPORT SECTION */}
            <div className="text ">
              <h6 className="autocomplete-title h6">{lang.printerSection}</h6>
              <Select
                options={report_section}
                defaultValue={reportSection}
                isSearchable={false}
                onChange={(list, item) => setReportSection(list)}
                value={reportSection}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* PRODUCT GROUP */}
            <div className="text ">
              <h6 className="autocomplete-title h6">{lang.productGroup}</h6>
              <Select
                options={product_group}
                defaultValue={productGroup}
                isSearchable={false}
                onChange={(list, item) => setProductGroup(list)}
                value={productGroup}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="div-7">
            {/* PRINTER */}
            <div className="text ">
              <h6 className="printer h6">{lang.printer}</h6>
              <Select
                options={printer_list}
                defaultValue={selectedPrinterList}
                isSearchable={false}
                onChange={(list, item) => setSelectedPrinterList(list)}
                value={selectedPrinterList}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="text ">
              <h6 className="Secound-language h6">{lang.secoundLanguage}</h6>
              <input
                type="text"
                className="CE-secound-language-input input-padding"
                value={secondLanguage}
                onChange={(e) => setSecondLanguage(e.target.value)}
              />
            </div>
            <div
              className=" text"
              style={{
                marginTop: "35px",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={incloudHalfAndHalf}
                      onChange={() =>
                        setIncloudHalfAndHalf(!incloudHalfAndHalf)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.incloud_half_and_half}
                />
              </FormGroup>
            </div>
          </div>
          <div className="div-1 ">
            <div className="text">
              <h6 className="show-hide h6">{lang.availability}</h6>
              <div className="container_availability_dropdown">
                <div style={{ width: "80%" }}>
                  <Select
                    options={Availability}
                    defaultValue={availability}
                    isSearchable={false}
                    onChange={handleCategoryAvailability}
                    value={availability}
                    isMulti={false}
                    styles={customStyles}
                    isClearable={false}
                  />
                </div>
                <button
                  onClick={() =>
                    dispatch(showCategoryAvailabilityPopupAction(true))
                  }
                  disabled={
                    availability
                      ? availability.value == 1
                        ? true
                        : false
                      : false
                  }
                >
                  {lang.edit}
                </button>
              </div>
            </div>
          </div>
          <div className="list_header_container-general">
            <div>{/* <h6 id="general_text">{lang.general}</h6> */}</div>
          </div>
          {/* TITLES */}
          <div className="div-1">
            {/* APP TITLE */}
            <div className="text ">
              <h6 className="title h6">{lang.app_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setAppTitle(e.target.value)}
                  type="text"
                  value={appTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setAppDesc(e.target.value)}
                  type="text"
                  value={appDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            {/* WEB TTILE */}
            <div className="text ">
              <h6 className="title h6">{lang.web_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setWebTitle(e.target.value)}
                  type="text"
                  value={webTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setWebDesc(e.target.value)}
                  type="text"
                  value={webDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            {/* KIOSK TITLE */}
            <div className="text ">
              <h6 className="title h6">{lang.kiosk_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setKioskTitle(e.target.value)}
                  type="text"
                  value={kioskTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setKioskDesc(e.target.value)}
                  type="text"
                  value={kioskDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
          </div>
          {/* ********* */}
          {/* SHOW HIDES */}
          <div className="div-2">
            {/* APP SHOW  HIDE */}
            <div className="text">
              <h6 className="show-hide h6">{lang.show_hide}</h6>
              <Select
                options={show_hide}
                defaultValue={appShowHideValues}
                isSearchable={false}
                onChange={onSelectAppVisible}
                value={appShowHideValues}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* WEB SHOW  HIDE  */}
            <div className="text">
              <h6 className="show-hide h6 ">{lang.show_hide}</h6>
              <Select
                options={show_hide}
                defaultValue={webShowHideValues}
                isSearchable={false}
                onChange={onSelectWebVisible}
                value={webShowHideValues}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* KIOSK SHOW  HIDE  */}
            <div className="text">
              <h6 className="show-hide h6">{lang.show_hide}</h6>
              <Select
                options={show_hide}
                defaultValue={kioskShowHideValues}
                isSearchable={false}
                onChange={onSelectKioskVisible}
                value={kioskShowHideValues}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>

          {/* SORT */}
          <div className="div-3">
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                type="text"
                className="category-sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={appSort}
                disabled
                onChange={(e) => setAppsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                type="text"
                className="category-sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={webSort}
                disabled
                onChange={(e) => setWebsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                type="text"
                className="category-sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={kioskSort}
                disabled
                onChange={(e) =>
                  setKiosksort(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
          </div>

          {/* IMAGE */}
          <div className="div-4 ">
            <div className="text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectFile(e)}
                  className="file-i"
                />
              </div>
              <div className="image-div" style={{ position: "relative" }}>
                {src ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImagesAndUpdate(1)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    src
                      ? src
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
            <div className=" text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectImage(e)}
                  className="file-i"
                />
              </div>
              <div className="image-div" style={{ position: "relative" }}>
                {url ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImagesAndUpdate(2)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        marginLeft: "1px",
                        color: "red",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    url
                      ? url
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage1}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
            <div className="text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectPicture(e)}
                  className="file-i1"
                  disabled={true}
                />
              </div>

              <div className="image-div" style={{ position: "relative" }}>
                {href ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImagesAndUpdate(3)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        marginLeft: "1px",
                        color: "red",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    href
                      ? href
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage2}
                  placeholder="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShVmRUY2IOJIbWqUzEB9winRjKeH9NWVnkDw&usqp=CAU"
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
          </div>
          {/* IMAGE CHECK BOXES */}
          <div className="div-5 ">
            <div className="text div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAppImage}
                      onChange={() => setShowAppImage(!showAppImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>

            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showWebImage}
                      onChange={() => setShowWebImage(!showWebImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showKioskImage}
                      onChange={() => setShowKioskImage(!showKioskImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
          </div>
          <div className="div-5 ">
            <div className="text">
              <h6 className="h6-text h6">{lang.fixed_submenu}</h6>
              <Select
                options={fixed_submenu_list}
                defaultValue={selectedFixedItem}
                isSearchable={false}
                onChange={(list, item) => {
                  setSelectedFixedItem(list);
                }}
                value={selectedFixedItem}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="div-9 ">
            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.number_of_product_culmn}</h6>
              <Select
                options={number_of_culmn}
                defaultValue={numberOfProductCulmn}
                isSearchable={false}
                onChange={(list, item) => {
                  setNumberOfProductCulmn(list);
                }}
                value={numberOfProductCulmn}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.number_of_product_row}</h6>
              <Select
                options={number_of_row_culmn}
                defaultValue={numberOfProductRow}
                isSearchable={false}
                onChange={(list, item) => {
                  setNumberOfProductRow(list);
                }}
                value={numberOfProductRow}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* BACKGROUND  COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.backgroundColor}</h6>
              <div className="bachgroundColor-div">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: backgroundColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInput}
                  Value={backgroundColor}
                  onChange={handleInputBackgroundColor}
                  id="input_backgroundColor"
                />
              </div>
              {showSketchPicker ? (
                <div
                  className="SketchPicker-div"
                  onMouseLeave={handleCloseInputColor}
                >
                  <SketchPicker
                    color={backgroundColor}
                    onChangeComplete={handleChangeCompleteBackgroundColor}
                  />
                </div>
              ) : null}
            </div>
            {/* FONT COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.fontColor}</h6>
              <div className="bachgroundColor-div">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: titleColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInputColor}
                  Value={titleColor}
                  onChange={handleInputTitleColor}
                  id="inputColor"
                />
              </div>
              {showColorPicker ? (
                <div className="SketchPicker-div" onMouseLeave={handleClose}>
                  <SketchPicker
                    color={titleColor}
                    onChangeComplete={handleChangeCompleteTitleColor}
                  />
                </div>
              ) : null}
            </div>
            <div
              className="Appearance_information"
              style={{
                position: "relative",
              }}
            >
              <h6 className="h6">{lang.product_background_color}</h6>
              <div
                className="category-container-color-box"
                id="input_backgroundColor"
              >
                <div
                  style={{
                    backgroundColor: productBackgroundColor[0],
                  }}
                  onChange={(e) => handleProductBackgroundColor(1, e)}
                  onClick={() => handleShowProductBackgroundColorPicker(1)}
                ></div>
                <div
                  style={{
                    backgroundColor: productBackgroundColor[1],
                  }}
                  onChange={(e) => handleProductBackgroundColor(2, e)}
                  onClick={() => handleShowProductBackgroundColorPicker(2)}
                ></div>
                <div
                  style={{
                    backgroundColor: productBackgroundColor[2],
                  }}
                  onChange={(e) => handleProductBackgroundColor(3, e)}
                  onClick={() => handleShowProductBackgroundColorPicker(3)}
                ></div>
                <div
                  onChange={(e) => handleProductBackgroundColor(4, e)}
                  onClick={() => handleShowProductBackgroundColorPicker(4)}
                  style={{
                    backgroundColor: productBackgroundColor[3],
                  }}
                ></div>
              </div>{" "}
              {showProductBackGroundColorPicker ? (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "45798765",
                    left: "20px",
                  }}
                  // className="SketchPicker-div"
                  onMouseLeave={handleCloseProductColorPicker}
                >
                  <SketchPicker
                    color={
                      colorKey == 1
                        ? productBackgroundColor[0]
                        : colorKey == 2
                        ? productBackgroundColor[1]
                        : colorKey == 3
                        ? productBackgroundColor[2]
                        : productBackgroundColor[3]
                    }
                    onChangeComplete={
                      handleChangeCompleteProductBackgroundColor
                    }
                  />
                </div>
              ) : null}
            </div>

            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.fontSize}</h6>
              <Select
                options={font_size}
                defaultValue={fontSize}
                isSearchable={false}
                onChange={(list, item) => {
                  setFontSize(list);
                }}
                value={fontSize}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.product_font_size}</h6>
              <Select
                options={font_size}
                defaultValue={productFontSize}
                isSearchable={false}
                onChange={(list, item) => {
                  setProductFontSize(list);
                }}
                value={productFontSize}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* FONT FAMILY */}
            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.fontFamily}</h6>
              <Select
                options={font_family}
                defaultValue={fontFamily}
                isSearchable={false}
                onChange={(list, item) => {
                  setFontFamily(list);
                }}
                value={fontFamily}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* WIDTH */}
            {/* <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.width}</h6>
              <input
                onChange={(e) => setWidth(e.target.value.replace(/\D/g, ""))}
                type="text"
                value={width}
                className="Appearance_information_input input-padding"
                // placeholder={lang.placeholderTitle}
              />
            </div> */}
            {/* HEIGHT */}
            {/* <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.height}</h6>
              <input
                onChange={(e) => setHeight(e.target.value.replace(/\D/g, ""))}
                type="text"
                value={height}
                className="Appearance_information_input input-padding"
                // placeholder={lang.placeholderTitle}
              />
            </div> */}
          </div>
          <div className="div-5 ">
            <div className="text div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mealDeals}
                      onChange={() => setMealDeals(!mealDeals)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.meal_deals}
                />
              </FormGroup>
            </div>
          </div>
          {/* VAT */}
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSaveClicked()} />
      <Toaster />
    </div>
  );
};

export default CategoryEdit;
