import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

import MenuListItem from "../components/MenuListItem";
import ListHeader from "../components/ListHeader";
import AddMenuItem from "../components/AddMenuItem";
import { main_nav_button_color } from "../constants/Colors";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { primaryColor } from "../constants/Colors";
import toast, { Toaster } from "react-hot-toast";
import {
  createSubmenuAction,
  getSubmenuAction,
  selectedSubmenuAction,
  deleteSubmenuaction,
  selectedSubmenuItemAction,
  getSubmenuItemAction,
  updateSubmenuItemEditAction,
  updateSubmenuAction,
  showImportSubmenuPopupAction,
} from "../redux/submenu/Action";
import {
  getSubItemPublicListAction,
  getSubmenuPublicListActiom,
} from "../redux/MainNavGeneral/Actions";
import {
  changeSelectedButtonBorderColorOnFocus,
  clickedItemAction,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
  selectedOtemInSomeLists,
} from "../redux/menu/Actions";
const SubmenuList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [titleSubmenu, setTitleSubmenu] = useState("");
  const [descSubmenu, setDescSubmenu] = useState("");
  // const [emptyInput, setEmptyInput] = useState(false);
  const {
    selected_submenu,
    loading_submenu,
    result_message,
    result_failed,
    result_successfull,
    submenu_list,
    submenu_item_list,
  } = useSelector((state) => state.submenuRedusers);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const {
    change_selected_button_border_color_on_focus,
    selected_item_in_all_lists,
    selected_item_in_some_lists,
  } = useSelector((state) => state.categoryList);

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const [userAccesses, setUserAccesses] = useState([]);
  const [userType, setUserType] = useState(0);
  const [rolesId, setRolesId] = useState([]);
  const [submenuList, setSubmenuList] = useState([]);
  const [appTitle, setAppTitle] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [secondLanguage, setSecondLanguage] = useState("");
  const [mainPrice, setMainPrice] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [itemAppTitle, setItemAppTitle] = useState("");
  const [itemWebTitle, setItemWebTitle] = useState("");
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  const [submenuItemList, setSubmenuItemList] = useState([]);
  const [itemSecondLangusge, setItemSecondLanguage] = useState("");
  const handleOnclickButton = (t, d) => {
    // setTitleSubmenu(t);
    // setDescSubmenu(d)
    dispatch(
      createSubmenuAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        t.replaceAll(`"`, `\"\"`),
        d.replaceAll(`"`, `\"\"`),
        0,
        0
      )
    );
    // setEmptyInput(true);
    document.getElementById("countaining").scroll({
      top: document.getElementById("countaining").scrollHeight,
      behavior: "smooth",
    });
  };

  const handleDeleted = (item) => {
    dispatch(deleteSubmenuaction(login_key, user_id, item.ShopId, item.Id));
  };

  const handleClickItem = (item, key) => {
    setClickedItem(key);
    dispatch(selectedSubmenuAction(item));
  };
  const handleSelectedSubmenu = (item) => {
    dispatch(selectedSubmenuAction(item));
  };
  const handleEditIconClick = (item) => {
    const copy_product_list = [...submenuList];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.Id == copy_product_list[i].Id) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_list);
    dispatch(selectedOtemInAllLists(item));
  };

  const handleCloseIcon = (item) => {
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
    }

    setSubmenuList(copy_product_list);
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...submenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setSubmenuList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...submenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setSubmenuList(copy_list);
  };
  const handleShowOptions = (item) => {
    const copy_list = [...submenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showItems = true;
      } else {
        copy_list[i].showItems = false;
      }
    }
    setSubmenuList(copy_list);
    handleSelectedSubmenu(item);
  };
  const handleCloseOptions = (item) => {
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showItems = false;
      }
    }
    setSubmenuList(copy_product_list);
  };
  const handleEditOptionPrice = (element) => {
    dispatch(selectedOtemInSomeLists(element));
    const copy_product_option_list = [...submenuItemList];
    for (let i = 0; i < copy_product_option_list.length; i++) {
      if (element.Id == copy_product_option_list[i].Id) {
        copy_product_option_list[i].showEditBox = true;
      } else {
        copy_product_option_list[i].showEditBox = false;
      }
    }
    setSubmenuItemList(copy_product_option_list);
  };
  const handleCloseItemEditBox = (element) => {
    const copy_product_option_list = [...submenuItemList];
    for (let i = 0; i < copy_product_option_list.length; i++) {
      if (element.Id == copy_product_option_list[i].Id) {
        copy_product_option_list[i].showEditBox = false;
      }
    }
    setSubmenuItemList(copy_product_option_list);
  };
  const handleSaveClicked = () => {
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else
      dispatch(
        updateSubmenuAction(
          login_key,
          user_id,
          selected_item_in_all_lists.Id,
          selected_item_in_all_lists.ShopId,
          appTitle.replaceAll(`"`, `\"\"`),
          "",
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          "",
          "",
          "",
          secondLanguage.replaceAll(`"`, `\"\"`),
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          "",
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          4,
          6,
          9,
          0
        )
      );
  };

  const formData = new FormData();
  const handleSaveItem = () => {
    if (itemAppTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("Id", selected_item_in_some_lists.Id);
      formData.append("ShopId", selected_item_in_some_lists.ShopId);
      formData.append("SubMenuId", selected_item_in_some_lists.SubMenuId);
      formData.append("PriceLevelCollTake", 0);
      formData.append("PriceLevelEatIn", 0);
      formData.append("PriceLevelMain", 0);
      formData.append("AppTitle", itemAppTitle.replaceAll(`"`, `\"\"`));
      formData.append("AppDescription", "");
      formData.append(
        "WebTitle",
        itemWebTitle
          ? itemWebTitle.replaceAll(`"`, `\"\"`)
          : itemAppTitle.replaceAll(`"`, `\"\"`)
      );
      formData.append("WebDescription", "");
      formData.append("KioskTitle", "");
      formData.append("KioskDescription", "");
      formData.append(
        "SecondLanguage",
        itemSecondLangusge.replaceAll(`"`, `\"\"`)
      );
      formData.append("AmountMain", itemPrice ? parseFloat(itemPrice) : 0);
      formData.append("AmountCollection", 0);
      formData.append("AmountTakeaway", 0);
      formData.append("AmountEatIn", 0);
      formData.append("AppVisible", 0);
      formData.append("WebVisible", 0);
      formData.append("KioskVisible", 0);
      formData.append("AppBgColor", "");
      formData.append("AppTitleFontSize", 30);
      formData.append("AppTitleFontFamily", String(4));
      formData.append("AppTitleColor", "");
      formData.append("VatId", 0);
      formData.append("IsDifOptionVat", 0);
      formData.append("UnitId", 0);
      formData.append("Barcode", "");
      formData.append("Stock", 0);
      formData.append("CustomTitle", "");
      formData.append("CloseCount", 0);
      formData.append("PrinterId", "");
      formData.append("ReportSection", 0);
      formData.append("ProductGroup", 0);
      formData.append("AppTileWidth", 0);
      formData.append("AppTileHeight", 0);
      formData.append("Linkto", 1);
      formData.append("AppSortId", 0);
      formData.append("WebSortId", 0);
      formData.append("KioskSortId", 0);
      formData.append("AppImageUrl", "");
      formData.append("AppVisibleImage", 0);
      formData.append("WebImageUrl", "");
      formData.append("WebVisibleImage", 0);
      formData.append("KioskImageUrl", "");
      formData.append("KioskVisibleImage", 0);
      formData.append("LastAppImageUrlName", "");
      formData.append("JustTitleAndPrice", 1);
      formData.append("DeleteImage", 0);
      dispatch(
        updateSubmenuItemEditAction(
          formData,
          login_key,
          user_id,
          selected_item_in_some_lists.Id,
          selected_item_in_some_lists.ShopId,
          selected_item_in_some_lists.SubMenuId
        )
      );
    }
  };
  const handleShowImportSubmenuPopup = () => {
    dispatch(showImportSubmenuPopupAction(true));

    dispatch(getSubmenuPublicListActiom(login_key, user_id));
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getSubmenuAction(login_key, user_id, selected_shop[0].ShopId, 0)
      );
    }
  }, [selected_shop]);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleOnclickButton();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (copy_product_list[index].showEditBox) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_list);
    dispatch(justTitleAndPriceAction(1));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(selectedOtemInAllLists(null));
      dispatch(justTitleAndPriceAction(0));
      dispatch(selectedOtemInSomeLists(null));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    const new_sub_list = [];
    if (submenu_list.length > 0) {
      for (let i = 0; i < submenu_list.length; i++) {
        if (!submenu_list[i].YesNoQ) {
          new_sub_list.push(submenu_list[i]);
        }
      }
      setSubmenuList(new_sub_list);
    } else {
      setSubmenuList([]);
    }
  }, [submenu_list]);

  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);

  useEffect(() => {
    let roles_id = [];
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          setUserType(userAccesses[0].UserType);
          roles_id.push(userAccesses[i].RoleId);
        }
      }
    }
    setRolesId(roles_id);
  }, [userAccesses]);

  useEffect(() => {
    if (selected_submenu) {
      dispatch(
        getSubmenuItemAction(
          login_key,
          user_id,
          selected_submenu.ShopId,
          selected_submenu.Id
        )
      );
    }
  }, [selected_submenu]);
  useEffect(() => {
    if (submenu_item_list) {
      if (submenu_item_list.length > 0) {
        setSubmenuItemList(submenu_item_list);
      }
    }
  }, [submenu_item_list]);
  useEffect(() => {
    if (selected_item_in_some_lists) {
      setItemPrice(selected_item_in_some_lists.AmountMain);
      setItemAppTitle(selected_item_in_some_lists.AppTitle);
      setItemWebTitle(selected_item_in_some_lists.WebTitle);
      setItemSecondLanguage(selected_item_in_some_lists.SecondLanguage);
    }
  }, [selected_item_in_some_lists]);
  useEffect(() => {
    if (selected_item_in_all_lists) {
      if (selected_item_in_all_lists.AppTitle) {
        setAppTitle(selected_item_in_all_lists.AppTitle);
      } else {
        setAppTitle("");
      }
      if (selected_item_in_all_lists.WebTitle) {
        setWebTitle(selected_item_in_all_lists.WebTitle);
      } else {
        setWebTitle("");
      }

      if (selected_item_in_all_lists.SecondLanguage) {
        setSecondLanguage(selected_item_in_all_lists.SecondLanguage);
      } else {
        setSecondLanguage("");
      }
    }
  }, [selected_item_in_all_lists]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
      onClick={handleCloseEditBoxWithOnBlur}
    >
      {" "}
      {userType == 10001202 && rolesId.includes(17) && !rolesId.includes(1) ? (
        <>
          <div className="menu_list_header_container">
            {submenuList.length > 0 ? (
              <div>
                {submenuList.map((item, index) => (
                  <div>
                    {item.showEditBox ? (
                      <div
                        className="menu_list_item_container box_shadow menu-list-container"
                        onClick={(i) => i.stopPropagation()}
                      >
                        <Box
                          component="form"
                          className="menu-list-container-inputs"
                          autoComplete="on"
                        >
                          <TextField
                            key={1}
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",

                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            label={lang.app_title}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,
                                "&.MuiInputLabel-shrink": { top: 0 },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                                "& label.Mui-focused": {
                                  color: primaryColor,
                                },
                                "&:hover label": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            value={appTitle}
                            // defaultValue={number == 9 ? postCode : appTitle}
                            onChange={(e) => setAppTitle(e.target.value)}
                            style={{
                              width: "32%",
                            }}
                            autoFocus={focusedInputKey == 1 ? true : false}
                            onFocus={() => setFocusedInputKey(1)}
                          />
                          <TextField
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",
                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            key={2}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,
                                "&.MuiInputLabel-shrink": { top: 0 },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            label={lang.web_title}
                            value={webTitle}
                            onChange={(e) => setWebTitle(e.target.value)}
                            style={{
                              width: "32%",
                            }}
                            autoFocus={focusedInputKey == 2 ? true : false}
                            onFocus={() => setFocusedInputKey(2)}
                          />
                          <TextField
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",
                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            key={4}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,

                                "&.MuiInputLabel-shrink": {
                                  top: 0,
                                },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            label={lang.secoundLanguage}
                            value={secondLanguage}
                            onChange={(e) => setSecondLanguage(e.target.value)}
                            style={{
                              width: "32%",
                            }}
                            autoFocus={false}
                            onFocus={() => setFocusedInputKey(3)}
                          />
                        </Box>

                        <div className="menu-list-container-edit-box-icons">
                          <div
                            className="menu-list-container-icon"
                            onClick={() => handleSaveClicked()}
                          >
                            <i
                              class="fa fa-check menu-list-fa-check"
                              aria-hidden="true"
                              style={{ fontSize: " 23px" }}
                            ></i>
                          </div>
                          <div
                            className="menu-list-container-icon"
                            onClick={() => handleCloseIcon(item)}
                          >
                            <i
                              class="fa fa-times menu-list-fa-times"
                              aria-hidden="true"
                              style={{ fontSize: " 23px" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="menu_list_item_container">
                        <div
                          className="menu_list_title_container"
                          style={{
                            marginRight: "-15px",
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: "18px",
                          }}
                          onClick={(i) => i.stopPropagation()}
                        >
                          <div
                            style={{
                              width: "75%",
                              display: "flex",
                            }}
                          >
                            <div
                              className="menu-list-continer-title"
                              style={{
                                width: "48%",
                              }}
                            >
                              <h6
                                style={{
                                  margin: 0,
                                }}
                              >
                                {item.AppTitle}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="menu-list-continer-price-and-delete-and-edit-icons"
                            style={{ width: "10%" }}
                          >
                            <i
                              class="fa fa-pencil"
                              aria-hidden="true"
                              onClick={() => handleEditIconClick(item)}
                            ></i>
                            <div className="owners-menu-container-products-options">
                              <p style={{ margin: 0 }}>{lang.items}</p>

                              {item.showItems ? (
                                <i
                                  class="fa fa-caret-down"
                                  aria-hidden="true"
                                  onClick={() => handleCloseOptions(item)}
                                ></i>
                              ) : (
                                <i
                                  class="fa fa-caret-up"
                                  aria-hidden="true"
                                  onClick={() => handleShowOptions(item)}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {item.showItems
                      ? submenuItemList
                        ? submenuItemList.length > 0
                          ? submenuItemList.map((element, index) => (
                              <div style={{ padding: "0px 15px" }}>
                                {element.showEditBox ? (
                                  <div
                                    className="menu_list_item_container box_shadow menu-list-container"
                                    onClick={(i) => i.stopPropagation()}
                                  >
                                    <Box
                                      component="form"
                                      className="menu-list-container-inputs"
                                      autoComplete="on"
                                    >
                                      <TextField
                                        key={1}
                                        sx={{
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            fontSize: "15px",
                                            height: "40px",
                                            color: "black",

                                            "& fieldset": {
                                              borderColor: "grey",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: primaryColor,
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: primaryColor,
                                            },
                                          },
                                        }}
                                        size="small"
                                        label={lang.app_title}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: 16,
                                            // top: -7,
                                            "&.MuiInputLabel-shrink": {
                                              top: 0,
                                            },
                                            "&.Mui-focused": {
                                              color: primaryColor,
                                            },
                                            "& label.Mui-focused": {
                                              color: primaryColor,
                                            },
                                            "&:hover label": {
                                              color: primaryColor,
                                            },
                                          },
                                        }}
                                        value={itemAppTitle}
                                        // defaultValue={number == 9 ? postCode : appTitle}
                                        onChange={(e) =>
                                          setItemAppTitle(e.target.value)
                                        }
                                        style={{
                                          width: "27%",
                                        }}
                                        autoFocus={
                                          focusedInputKey == 1 ? true : false
                                        }
                                        onFocus={() => setFocusedInputKey(1)}
                                      />
                                      <TextField
                                        sx={{
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            fontSize: "15px",
                                            height: "40px",
                                            color: "black",
                                            "& fieldset": {
                                              borderColor: "grey",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: primaryColor,
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: primaryColor,
                                            },
                                          },
                                        }}
                                        size="small"
                                        key={2}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: 16,
                                            // top: -7,
                                            "&.MuiInputLabel-shrink": {
                                              top: 0,
                                            },
                                            "&.Mui-focused": {
                                              color: primaryColor,
                                            },
                                          },
                                        }}
                                        label={lang.web_title}
                                        value={itemWebTitle}
                                        onChange={(e) =>
                                          setItemWebTitle(e.target.value)
                                        }
                                        style={{
                                          width: "27%",
                                        }}
                                        autoFocus={
                                          focusedInputKey == 2 ? true : false
                                        }
                                        onFocus={() => setFocusedInputKey(2)}
                                      />
                                      <TextField
                                        sx={{
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            fontSize: "15px",
                                            height: "40px",
                                            color: "black",
                                            "& fieldset": {
                                              borderColor: "grey",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: primaryColor,
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: primaryColor,
                                            },
                                          },
                                        }}
                                        size="small"
                                        key={4}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: 16,
                                            // top: -7,

                                            "&.MuiInputLabel-shrink": {
                                              top: 0,
                                            },
                                            "&.Mui-focused": {
                                              color: primaryColor,
                                            },
                                          },
                                        }}
                                        label={lang.secoundLanguage}
                                        value={itemSecondLangusge}
                                        onChange={(e) =>
                                          setItemSecondLanguage(e.target.value)
                                        }
                                        style={{
                                          width: "27%",
                                        }}
                                        autoFocus={false}
                                        onFocus={() => setFocusedInputKey(3)}
                                      />
                                      <TextField
                                        sx={{
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            fontSize: "15px",
                                            height: "40px",
                                            color: "black",
                                            "& fieldset": {
                                              borderColor: "grey",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: primaryColor,
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: primaryColor,
                                            },
                                          },
                                        }}
                                        size="small"
                                        key={4}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: 16,
                                            // top: -7,

                                            "&.MuiInputLabel-shrink": {
                                              top: 0,
                                            },
                                            "&.Mui-focused": {
                                              color: primaryColor,
                                            },
                                          },
                                        }}
                                        label={lang.mainprice}
                                        value={itemPrice}
                                        onChange={(e) =>
                                          setItemPrice(e.target.value)
                                        }
                                        style={{
                                          width: "15%",
                                        }}
                                        autoFocus={false}
                                        onFocus={() => setFocusedInputKey(3)}
                                      />
                                    </Box>

                                    <div className="menu-list-container-edit-box-icons">
                                      <div
                                        className="menu-list-container-icon"
                                        onClick={() => handleSaveItem()}
                                      >
                                        <i
                                          class="fa fa-check menu-list-fa-check"
                                          aria-hidden="true"
                                          style={{ fontSize: " 23px" }}
                                        ></i>
                                      </div>
                                      <div
                                        className="menu-list-container-icon"
                                        onClick={() =>
                                          handleCloseItemEditBox(element)
                                        }
                                      >
                                        <i
                                          class="fa fa-times menu-list-fa-times"
                                          aria-hidden="true"
                                          style={{ fontSize: " 23px" }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="menu_list_item_container">
                                    <div
                                      className="menu_list_title_container"
                                      style={{
                                        marginRight: "-15px",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingBottom: "18px",
                                        backgroundColor: "#dddddd",
                                      }}
                                      onClick={(i) => i.stopPropagation()}
                                    >
                                      <div
                                        style={{
                                          width: "80%",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          className="menu-list-continer-title"
                                          style={{
                                            width: "48%",
                                          }}
                                        >
                                          <h6
                                            style={{
                                              margin: 0,
                                            }}
                                          >
                                            {element.AppTitle}
                                          </h6>
                                        </div>
                                      </div>
                                      <div
                                        className="menu-list-continer-price-and-delete-and-edit-icons"
                                        style={{ width: "12%" }}
                                      >
                                        {element.AmountMain ? (
                                          <h6
                                            style={{
                                              margin: "0px 13px 0px 0px",
                                            }}
                                          >
                                            £{element.AmountMain}
                                          </h6>
                                        ) : null}

                                        <i
                                          class="fa fa-pencil"
                                          aria-hidden="true"
                                          onClick={() =>
                                            handleEditOptionPrice(element)
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))
                          : null
                        : null
                      : null}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <AddMenuItem
            // empty={emptyInput}
            // setEmptyInput={(i) => setEmptyInput(i)}
            button_title={lang.addSubmenu}
            buttonClick={(t, d, a) => handleOnclickButton(t, d, a)}
            result_successfull={result_successfull}
          />
        </>
      ) : (
        <>
          {" "}
          <div className="list_header_container  submenu-edit-container-header">
            <h6 style={{ width: "41.5%" }}>{lang.title}</h6>
            <div>
              <h6> {lang.minSelect}</h6>
              <h6>{lang.maxSelect}</h6>
            </div>
          </div>
          <div className="menu_list_header_container">
            {submenuList.length > 0 ? (
              <div style={{ marginBottom: "150px" }}>
                {submenuList.map((item, index) => (
                  <div key={index}>
                    {!item.IsDeleted ? (
                      <MenuListItem
                        field_number={3}
                        item_title={item.AppTitle}
                        field_1_title={item.AppTitle}
                        field_2_title={item.MinSelect}
                        field_3_title={item.MaxSelect}
                        item_price={null}
                        btn_number={2}
                        bt_1_title={lang.edit}
                        bt_1_click={() => {
                          handleClickItem(item, 2);
                          dispatch(clickedItemAction(2));
                        }}
                        bt_2_title={lang.item}
                        bt_2_click={() => {
                          handleClickItem(item, 3);
                          dispatch(selectedSubmenuItemAction(null));
                          dispatch(clickedItemAction(3));
                        }}
                        show_delete={true}
                        delete_click={() => handleDeleted(item)}
                        show_edit_icon={true}
                        // without_key={false}
                        number={2}
                        selected={selected_submenu}
                        item={item}
                        click_edit_icon={() => handleEditIconClick(item)}
                        click_close_icon={() => handleCloseIcon(item)}
                        show_delete_box={() => handleShowDeleteBox(item)}
                        cancel_delete_box={() => handleCancelDeleteBox(item)}
                      />
                    ) : null}
                  </div>
                ))}
              </div>
            ) : (
              <h2 className="no-option-sm">{lang.noOption}</h2>
            )}
          </div>
          <AddMenuItem
            func={titleSubmenu}
            func1={descSubmenu}
            button_title={lang.addSubmenu}
            buttonClick={(t, d) => handleOnclickButton(t, d)}
            // empty={emptyInput}
            // setEmptyInput={(i) => setEmptyInput(i)}
            result_successfull={result_successfull}
            number={2}
            buttonClick1={() => handleShowImportSubmenuPopup()}
          />
        </>
      )}
    </div>
  );
};

export default SubmenuList;
