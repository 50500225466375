import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const getShopEmployeesServices = async (login_key, user_id, shopId) => {
  return await axios.put(`${baseUrl}shopemployeeslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
  });
};
const formData = new FormData();
export const createShopEmployessServices = async (
  login_key,
  login_key1,
  user_id,
  shopId,
  gender_id,
  employee_name,
  job,
  national_id,
  mobile,
  telephone,
  email,
  address,
  post_code,
  user_name,
  pass,
  dob,
  married,
  in_suranceld,
  date_in,
  date_out,
  active,
  group_id,
  is_admin,
  single_user,
  btn_color,
  btn_font_color,
  url_image
) => {
  // formData.append("LoginKey", login_key);
  // formData.append("UserId", user_id);
  // formData.append("ShopId", shopId);
  // formData.append("GenderId", gender_id);
  // formData.append("EmployeeName", employee_name);
  // formData.append("Job", job);
  // formData.append("NationalId", national_id);
  // formData.append("Mobile", mobile);
  // formData.append("Telephone", telephone);
  // formData.append("Email", email);
  // formData.append("Address", address);
  // formData.append("PostCode", post_code);
  // formData.append("UserName", user_name);
  // formData.append("Pass", pass);
  // formData.append("DOB", dob);
  // formData.append("Married", married);
  // formData.append("InsuranceId", in_suranceld);
  // formData.append("DateIn", date_in);
  // formData.append("DateOut", date_out);
  // formData.append("Active", active);
  // formData.append("GroupId", group_id);
  // formData.append("IsAdmin", is_admin);
  // formData.append("SingleUser", single_user);
  // formData.append("BtnColor", btn_color);
  // formData.append("BtnFontColor", btn_font_color);
  // formData.append("UrlImage", url_image);
  return await axios.post(
    `${baseUrl}shopemployeescreate/`,
    login_key
    // {
    //   LoginKey: login_key,
    //   UserId: user_id,
    //   ShopId: shopId,
    //   GenderId: gender_id,
    //   EmployeeName: employee_name,
    //   Job: job,
    //   NationalId: national_id,
    //   Mobile: mobile,
    //   Telephone: telephone,
    //   Email: email,
    //   Address: address,
    //   PostCode: post_code,
    //   UserName: user_name,
    //   Pass: pass,
    //   DOB: dob,
    //   Married: married,
    //   InsuranceId: in_suranceld,
    //   DateIn: date_in,
    //   DateOut: date_out,
    //   Active: active,
    //   GroupId: group_id,
    //   IsAdmin: is_admin,
    //   SingleUser: single_user,
    //   BtnColor: btn_color,
    //   BtnFontColor: btn_font_color,
    //   UrlImage: url_image,
    // }
  );
};
export const updateShopEmployessServices = async (
  formData,
  login_key,
  user_id,
  id,
  shopId,
  gender_id,
  employee_name,
  job,
  national_id,
  mobile,
  telegram,
  email,
  address,
  post_code,
  user_name,
  pass,
  dob,
  married,
  in_suranceld,
  date_in,
  date_out,
  active,
  group_id,
  is_admin,
  single_user,
  btn_color,
  btn_font_color,
  url_image
) => {
  // formData.append("LoginKey", login_key);
  // formData.append("UserId", user_id);
  // formData.append("Id", id);
  // formData.append("ShopId", shopId);
  // formData.append("GenderId", gender_id);
  // formData.append("EmployeeName", employee_name);
  // formData.append("Job", job);
  // formData.append("NationalId", national_id);
  // formData.append("Mobile", mobile);
  // formData.append("Telephone", telegram);
  // formData.append("Email", email);
  // formData.append("Address", address);
  // formData.append("PostCode", post_code);
  // formData.append("UserName", user_name);
  // formData.append("Pass", pass);
  // formData.append("DOB", dob);
  // formData.append("Married", married);
  // formData.append("InsuranceId", in_suranceld);
  // formData.append("DateIn", date_in);
  // formData.append("DateOut", date_out);
  // formData.append("Active", active);
  // formData.append("GroupId", group_id);
  // formData.append("IsAdmin", is_admin);
  // formData.append("SingleUser", single_user);
  // formData.append("BtnColor", btn_color);
  // formData.append("BtnFontColor", btn_font_color);
  // formData.append("UrlImage", url_image);
  return await axios.post(
    `${baseUrl}shopemployeesupdate/`,
    formData
    //  {
    //   LoginKey: login_key,
    //   UserId: user_id,
    //   Id: id,
    //   ShopId: shopId,
    //   GenderId: gender_id,
    //   EmployeeName: employee_name,
    //   Job: job,
    //   NationalId: national_id,
    //   Mobile: mobile,
    //   Telephone: telegram,
    //   Email: email,
    //   Address: address,
    //   PostCode: post_code,
    //   UserName: user_name,
    //   Pass: pass,
    //   DOB: dob,
    //   Married: married,
    //   InsuranceId: in_suranceld,
    //   DateIn: date_in,
    //   DateOut: date_out,
    //   Active: active,
    //   GroupId: group_id,
    //   IsAdmin: is_admin,
    //   SingleUser: single_user,
    //   BtnColor: btn_color,
    //   BtnFontColor: btn_font_color,
    //   UrlImage: url_image,
    // }
  );
};
export const deleteShopEmployeesServices = async (
  login_key,
  user_id,
  shopId,
  employee_id
) => {
  return await axios.delete(`${baseUrl}shopemployeesdelete/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shopId,
      EmployeeId: employee_id,
    },
  });
};

export const getDriversListServices = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}driversList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};
const formDataa = new FormData();
export const createDriversServices = async (
  formData,
  login_key,
  user_id,
  first_name,
  last_name,
  active,
  mobile,
  user_name,
  password,
  image
) => {
  // formData.append("LoginKey", login_key);
  // formData.append(" UserId", user_id);
  // formData.append("FirstName", first_name);
  // formData.append("LastName", last_name);
  // formData.append("Active", active);
  // formData.append("Mobile", mobile);
  // formData.append("UserName", user_name);
  // formData.append("Pass", password);
  // formData.append("ImageUrl", image);
  return await axios.post(
    `${baseUrl}createDriver/`,
    formData
    // {
    // LoginKey: login_key,
    // UserId: user_id,
    // FirstName: first_name,
    // LastName: last_name,
    // Active: active,
    // Mobile: mobile,
    // UserName: user_name,
    // Pass: password,
    // ImageUrl: image,
    // }
  );
};
export const deleteDriverServices = async (login_key, user_id, id) => {
  return await axios.delete(`${baseUrl}deleteDriver/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      Id: id,
    },
  });
};

export const updateDriverServices = async (
  formData,
  login_key,
  user_id,
  id,
  first_name,
  last_name,
  active,
  mobile,
  user_name,
  password,
  image
) => {
  // formData.append("LoginKey", login_key);
  // formData.append(" UserId", user_id);
  // formData.append("Id", id);
  // formData.append("FirstName", first_name);
  // formData.append("LastName", last_name);
  // formData.append("Active", active);
  // formData.append("Mobile", mobile);
  // formData.append("UserName", user_name);
  // formData.append("Pass", password);
  // formData.append("ImageUrl", image);
  return await axios.post(
    `${baseUrl}updateDriver/`,
    formData
    //   {
    //   LoginKey: login_key,
    //   UserId: user_id,
    //   Id: id,
    //   FirstName: first_name,
    //   LastName: last_name,
    //   Active: active,
    //   Mobile: mobile,
    //   UserName: user_name,
    //   Pass: password,
    //   ImageUrl: image,
    // }
  );
};

export const driverWithUniqueIdServices = async (
  login_key,
  user_id,
  shop_id,
  driver_unique_id
) => {
  return await axios.put(`${baseUrl}driverWithUniqueId/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    DriverUniqueId: driver_unique_id,
  });
};
export const driverShopListServices = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}driverShopList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const createDriverShopServices = async (
  login_key,
  user_id,
  shop_id,
  driver_id
) => {
  return await axios.post(`${baseUrl}createDriverShop/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    DriverId: driver_id,
  });
};
export const deleteDriverShopServices = async (
  login_key,
  user_id,
  shop_id,
  driver_id
) => {
  return await axios.delete(`${baseUrl}deleteDriverShop/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      DriverId: driver_id,
    },
  });
};
export const deviceAccessGroupListServices = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}deviceAccessGroupList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const createDeviceAccessGroupServices = async (
  login_key,
  user_id,
  shop_id,
  role_id,
  title
) => {
  return await axios.put(`${baseUrl}createDeviceAccessGroup/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Title: title,
    RoleId: role_id,
  });
};
export const updateDeviceAccessGroupServices = async (
  login_key,
  user_id,
  shop_id,
  role_id,
  title,
  id
) => {
  return await axios.put(`${baseUrl}updateDeviceAccessGroup/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Title: title,
    RoleId: role_id,
    Id: id,
  });
};

export const deleteDevicesAccessService = async (
  shop_id,
  login_key,
  user_id,
  id
) => {
  return await axios.put(`${baseUrl}deleteDeviceAccessGroup/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
  });
};
export const getCheckInCheckOutListService = async (
  shop_id,
  login_key,
  user_id,
  date_from,
  date_to
) => {
  return await axios.put(`${baseUrl}enterExitList/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
    DateFrom: date_from,
    DateTo: date_to,
  });
};

export const updateCheckInCheckOutService = async (
  shop_id,
  login_key,
  user_id,
  id,
  date_from,
  date_to,
  low_time_work
) => {
  return await axios.put(`${baseUrl}updateEnterExit/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    DateFrom: date_from,
    DateTo: date_to,
    LowTimeWork: low_time_work,
  });
};

export const deleteCheckInCheckOutService = async (
  shop_id,
  login_key,
  user_id,
  id
) => {
  return await axios.put(`${baseUrl}deleteEnterExit/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
  });
};

export const getEnterExitListByIdService = async (
  shop_id,
  login_key,
  user_id,
  date_from,
  date_to,
  employee_id
) => {
  return await axios.put(`${baseUrl}enterExitListByid/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
    DateFrom: date_from,
    DateTo: date_to,
    EmployeeId: employee_id,
  });
};
