import * as actionTypes from "../ActionTypes";

const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_employees: false,
  imployees_list: [],
  selected_shop_employees: null,
  visible_drivers_popup: false,
  drivers_list: [],
  selected_driver: null,
  driver_with_unique_id_list: [],
  driver_shop_lust: [],
  show_div: false,
  device_access_group_list: [],
  selected_access_group: null,
  device_roles_list: [],
  check_in_check_out_list: [],
  enter_exit_list_by_employee_id: [],
};

const employeesRedusers = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: false,
      };
    case actionTypes.CREATE_EMPLOYEES:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.CREATE_EMPLOYEES_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.CREATE_EMPLOYEES_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.GET_SHOP_EMPLOYEES:
      return {
        ...state,
        loading_employees: true,
      };
    case actionTypes.GET_SHOP_EMPLOYEES_SUCCESSFULL:
      return {
        ...state,
        loading_employees: false,
        imployees_list: action.payload,
      };
    case actionTypes.GET_SHOP_EMPLOYEES_FAILED:
      return {
        ...state,
        loading_employees: false,
        imployees_list: [],
      };
    case actionTypes.UPDATE_EMPLOYEES:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.UPDATE_EMPLOYEES_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.UPDATE_EMPLOYEES_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.SELECTED_SHOP_EMPLOYEES:
      return {
        ...state,
        selected_shop_employees: action.payload,
      };
    case actionTypes.DELETE_SHOP_EMPLOYEES:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.DELETE_SHOP_EMPLOYEES_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.DELETE_SHOP_EMPLOYEES_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.VISIBLE_DRIVERS_POPUP:
      return {
        ...state,
        visible_drivers_popup: action.payload,
        show_div: false,
      };
    case actionTypes.GET_DRIVERS_LIST:
      return {
        ...state,
        loading_employees: true,
      };
    case actionTypes.GET_DRIVERS_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_employees: false,
        drivers_list: action.payload,
      };
    case actionTypes.GET_DRIVERS_LIST_FAILED:
      return {
        ...state,
        loading_employees: false,
        drivers_list: [],
      };
    case actionTypes.CREATE_DRIVER:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.CREATE_DRIVER_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.CREATE_DRIVER_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };

    case actionTypes.UPDATE_DRIVER:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.UPDATE_DRIVER_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.UPDATE_DRIVER_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.DELETE_DRIVER:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.DELETE_DRIVER_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.DELETE_DRIVER_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.SELECTED_DRIVER:
      return {
        ...state,
        selected_driver: action.payload,
      };
    case actionTypes.DRIVER_WITH_UNIQUE_ID:
      return {
        ...state,
        loading_employees: true,
      };
    case actionTypes.DRIVER_WITH_UNIQUE_ID_SUCCESSFULL:
      return {
        ...state,
        loading_employees: false,
        driver_with_unique_id_list: action.payload,
        show_div: false,
      };
    case actionTypes.DRIVER_WITH_UNIQUE_ID_FAILED:
      return {
        ...state,
        driver_with_unique_id_list: [],
        loading_employees: false,
        show_div: true,
      };
    case actionTypes.CREATE_DRIVER_SHOP:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.CREATE_DRIVER_SHOP_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
        driver_with_unique_id_list: [],
        visible_drivers_popup: false,
      };
    case actionTypes.CREATE_DRIVER_SHOP_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.DRIVER_SHOP_LIST:
      return {
        ...state,
        loading_employees: true,
      };
    case actionTypes.DRIVER_SHOP_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_employees: false,
        driver_shop_lust: action.payload,
      };
    case actionTypes.DRIVER_SHOP_LIST_FAILED:
      return {
        ...state,
        driver_shop_lust: [],
        loading_employees: false,
      };
    case actionTypes.DELETE_DRIVER_SHOP:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.DELETE_DRIVER_SHOP_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
        visible_drivers_popup: false,
        driver_with_unique_id_list: [],
      };
    case actionTypes.DELETE_DRIVER_SHOP_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.DEVICE_ACCESS_GROUP_LIST:
      return {
        ...state,
        loading_employees: true,
      };
    case actionTypes.DEVICE_ACCESS_GROUP_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_employees: false,
        device_access_group_list: action.payload,
      };
    case actionTypes.DEVICE_ROLE_LIST:
      return {
        ...state,
        device_roles_list: action.payload,
      };
    case actionTypes.DEVICE_ACCESS_GROUP_LIST_FAILED:
      return {
        ...state,
        device_access_group_list: [],
        loading_employees: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.SELECTED_ACCESS_GROUP:
      return {
        ...state,
        selected_access_group: action.payload,
      };
    case actionTypes.UPDATE_DEVICE_ACCESS_GROUP:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.UPDATE_DEVICE_ACCESS_GROUP_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.UPDATE_DEVICE_ACCESS_GROUP_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.CREATE_DEVICE_ACCESS_GROUP:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.CREATE_DEVICE_ACCESS_GROUP_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.CREATE_DEVICE_ACCESS_GROUP_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };

    case actionTypes.DELETE_DEVICE_ACCESS_GROUP:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.DELETE_DEVICE_ACCESS_GROUP_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.DELETE_DEVICE_ACCESS_GROUP_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.GET_CHECK_IC_CHECK_OUT_LIST:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.GET_CHECK_IC_CHECK_OUT_LIST_SUCCESSFULL:
      return {
        ...state,
        check_in_check_out_list: action.payload,
        result_failed: false,
        result_message: "",
        loading_employees: false,
      };
    case actionTypes.GET_CHECK_IC_CHECK_OUT_LIST_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
        check_in_check_out_list: [],
      };
    case actionTypes.UPDATE_CHECK_IC_CHECK_OUT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.UPDATE_CHECK_IC_CHECK_OUT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.UPDATE_CHECK_IC_CHECK_OUT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };

    case actionTypes.DELETE_CHECK_IC_CHECK_OUT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: true,
      };
    case actionTypes.DELETE_CHECK_IC_CHECK_OUT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.DELETE_CHECK_IC_CHECK_OUT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_employees: false,
      };
    case actionTypes.GET_ENTER_EXIT_LIST_BY_EMPLOYEE:
      return {
        ...state,
        loading_employees: true,
      };
    case actionTypes.GET_ENTER_EXIT_LIST_BY_EMPLOYEE_SUCCESSFULL:
      return {
        ...state,
        loading_employees: false,
        enter_exit_list_by_employee_id: action.payload,
      };
    case actionTypes.GET_ENTER_EXIT_LIST_BY_EMPLOYEE_FAILED:
      return {
        ...state,
        enter_exit_list_by_employee_id: [],
        loading_employees: false,
      };

    default:
      return state;
  }
};
export default employeesRedusers;
