import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../components/Loader";
import { primaryColor } from "../../../constants/Colors";
import { image_Url } from "../../../constants/BaseUrl";
import {
  showWorkStationMainButtonsPopupAction,
  editWorkStationMainButtonsDetailsAction,
} from "../../../redux/WorksStations/Actions";
const MainButtonsEdit = () => {
  const dispatch = useDispatch();
  const { selected_main_button, loading_worksStations } = useSelector(
    (state) => state.worksStationsReduser
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [href, setHref] = useState("");
  const [key1, setKey1] = useState("");
  const [image, setImage] = useState("");
  const [buttonNmae, setButtonName] = useState("");
  const [buttonSort, setButtonSort] = useState(1);
  const [visible, setVisible] = useState(false);
  const [enable, setEnable] = useState(false);
  const [lastImageName, setLastImageName] = useState("");
  const image_href1 = selected_shop[0].Domain;
  const onSelectPicture = (e) => {
    setImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    setKey1(e);
  };
  const formData = new FormData();
  const handleEditMainButtonDetails = () => {
    if (href == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.choose_potho}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (image != "" && image.size > 400000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.image_size_error}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (buttonNmae == "" || buttonSort == 0) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      formData.append("ShopId", selected_shop[0].ShopId);
      formData.append("LoginKey", login_key);
      formData.append(" UserId", user_id);
      formData.append("WorkstationId", selected_main_button.WorkstationId);
      formData.append("Id", selected_main_button.Id);
      formData.append("ButtonCode", selected_main_button.ButtonCode);
      formData.append("ButtonName", buttonNmae ? buttonNmae : "");
      formData.append("Visible", visible ? 1 : 0);
      formData.append("Enable", enable ? 1 : 0);
      formData.append("ButtonImage", image ? image : "");
      formData.append("LastImageName", lastImageName ? lastImageName : "");
      formData.append("SortId", buttonSort);
      dispatch(
        editWorkStationMainButtonsDetailsAction(
          formData,
          selected_shop[0].ShopId,
          login_key,
          user_id,
          selected_main_button.WorkstationId
        )
      );
    }
  };
  useEffect(() => {
    if (selected_main_button) {
      if (selected_main_button.ButtonName) {
        setButtonName(selected_main_button.ButtonName);
      } else {
        setButtonName("");
      }
      if (selected_main_button.Visible) {
        setVisible(true);
      } else {
        setVisible(false);
      }
      if (selected_main_button.Enable) {
        setEnable(true);
      } else {
        setEnable(false);
      }
      if (selected_main_button.ButtonImageUrl) {
        setHref(
          `${image_Url}${image_href1}/${selected_main_button.ButtonImageUrl}`
        );
        setLastImageName(selected_main_button.ButtonImageUrl);
      } else {
        setHref("");
        setLastImageName("");
      }
      if (selected_main_button.SortId) {
        setButtonSort(selected_main_button.SortId);
      }
    }
  }, [selected_main_button]);

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showWorkStationMainButtonsPopupAction(false))}
    >
      <div
        className="create_shop_popup_content_container edit-main-button-popup-container"
        onClick={(i) => i.stopPropagation()}
      >
        <div className="edit-main-button-container-close-icon">
          {" "}
          <i
            onClick={() =>
              dispatch(showWorkStationMainButtonsPopupAction(false))
            }
            class="fa fa-times"
            aria-hidden="true"
          ></i>
        </div>
        <div className="work-station-main-button-container-orginal-name">
          <h5>{selected_main_button.ButtonOriginalName}</h5>
        </div>
        <div
          className=" work-station-main-button-container-change-image-section"
          style={{ position: "relative" }}
        >
          {" "}
          <div
            className=" work-station-main-button-container-image"
            //   onMouseOver={() => handleMouseOver()}
            //   onMouseLeave={() => handleMouseOut()}
            id="profile-container-delete-icon-image"
          >
            <img
              src={
                href
                  ? href
                  : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAP8AAADGCAMAAAAqo6adAAAALVBMVEXQ0NDw8PDU1NTW1tbv7+/b29vY2Njd3d3R0dHr6+vo6Oji4uLm5ubz8/Pj4+OXsBnXAAAE2klEQVR4nO2d23arMAxE65AGkjbn/z/3hHq1pYkvkixhC2keC+nSHo2BYHDe3kzr1LuAznJ+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+23J+22rjX6bLZVJtYUPxl3/nEO73EM63eeGraF+R+efrg/xb9/DBWdSOIvJPW/qombewnUTjn5/h1wx8Mpe2i0j880vzvwy4cRe3gyj8afyHFBpA4L9k6B8J0HcUJPCfs/whTPwVygrP/1HAD1eBEkWF5y/hh3ARqFFSaP7swS9K2yEQzX8t9/+u7EoYy7+Ujn4r/7tImWLC8k/l+AdtXwSw/KkrX80HAHZ+ZWdALH/x7G+A33r/32vHP2Xfgv34j1P1/K/sAhh9/Xer9F+iSEGh+SsHAGXDn/D9rzwAtN0AwPMXvwAqu/oj3f8pfQNUNxdEKPiUpb/rmwOgNCx3A/T+j708cZECmz4HaMQnzv9MiZOAwvC/kef/ls9n+qu2M18U+YA93bbzv2dlt71+1HDCWubP6zoOzrcPnb1f1XjCXh7iKaST1F2wMMv5bcv5bcv5bcv5bcv5bcv5bcv5bcv5bWtM/v3uKQ3Jf9lvGnVE/nV+aa/HKAbkj9NrOxkwHv/37OI+Q2A4/t/J1V0SMBr/dm55DwMG4/87tb7DEBiL//nJAvkEDMX/+mCFuAEj8aeeK5EeAgPxpx+rEU7AOPy5p4pkDRiGf8rgCw+BUfjzL9XKJmAQ/hK+qAFj8OfDLz0EhuAvd1/UgBH46/hyBgzAXwu/qAH9+SHdlzOgOz8UX8iA3vyw8MsZ0Jkf3n0hA/ry4/AlDOjKjwm/kAHM/Kh/h+2+hAG8/DPmUp2Cz24AK//6ahy4Pnz4JQzg5I9vBgITQOs+uwGM/N9LA4AMoHaf2wA+/t/3QgH1teCzGsDGv30ttpoAevi5DeDi/7suRsWAtu6zGsDE//xGZLG+dnw+A3j4X98JLySgNfysBrDwpxaFyRrA0X0+Azj4068DZ+rjwmcygIE/tyBCMgE84eczoJ0/vyJSwgC+7jMZ0MxfWg/lpT5efI7lJlr/Q3k5yKcEcOMzJKCRv7Yc2B8D+PHbDWjjr64Gtq1PAr95CDR9vLIW6pd+EiCD35qAFv7qWnhbA6TwGw1o4K+Hf1OfHH7bEKB/FhL+qIssflMC6L//gKlPFj+c6QZQ+aHhj/VVFg1sF7mNxA/Cw7+TqAng+/2LvqIOAcb1rzqL1knW37/oK1ICCPzjhT+KNATw/EOGP4qymOGB8ClDgH39667CDwEk/3tvwpqw/cTtP3T4o5AJQPEP3/2AHgIYfg34ATkEEDsrCH8UJgFwfiXdD7ghAObXg/8wAN5V6J5qwh8FNgC4o6burwIPARi/Nnz4EADtpiz8UTADIHvp6/4q2BAA8OvEBw6B+j4qwx8FMKC6i9burwIMgRq/ZnzIEKjsoDj8UTUDytt1d39VLQHFzfrxqwaUtqoPf1TRgMLGI3R/VTEB+W1HwS8bkN10kPBH5Q3IbTlO91flE5DZcCz8ggHpvx8q/FEZA5J/Plr3V2USkPrrfD+i0rfFU/yn5Yg6JQPQe/2D3nJ+23J+23J+23J+23J+23J+23J+23J+2zr9B+K1Q1EvhtkdAAAAAElFTkSuQmCC"
              }
              // src={`${href}?${imageHash}`}
              // key={imageHash}
              // onLoad={loadImage}
              id="profile_image"
              className="profile_image"
            />
          </div>{" "}
          <p>{lang.banner_image_size}</p>
          <div className=" work-station-main-button-container-change-image-input">
            <input
              type="file"
              name="file"
              id="file"
              class="inputfile"
              accept="image/*"
              onChange={(e) => onSelectPicture(e)}
            />
            <label for="file">
              {" "}
              <i className="fas fa-camera"></i>
              <span> Change Photo</span>
            </label>
          </div>
        </div>
        <br />
        <div className=" work-station-main-button-container-input">
          <Box
            style={{
              width: "95%",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
            // className="menu-list-container-inputs"
            component="form"
            // sx={{
            //   "& > :not(style)": { m: 1, display: "flex" },
            // }}

            // noValidate
            autoComplete="off"
          >
            <TextField
              key={1}
              label={`* ${lang.button_name}`}
              sx={{
                "& label.Mui-focused": {
                  color: primaryColor,
                },
                "&:hover label": {
                  color: primaryColor,
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "15px",
                  height: "45px",
                  color: "black",

                  "& fieldset": {
                    borderColor: "grey",
                  },
                  "&:hover fieldset": {
                    borderColor: primaryColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: primaryColor,
                  },
                },
              }}
              size="small"
              InputLabelProps={{
                sx: {
                  fontSize: 16,
                  // top: -7,
                  "&.MuiInputLabel-shrink": { top: 0 },
                  "&.Mui-focused": {
                    color: primaryColor,
                  },
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                },
              }}
              value={buttonNmae}
              onChange={(e) => setButtonName(e.target.value)}
              style={{
                width: "48%",
                marginTop: "30px",
              }}
            />
            <TextField
              key={2}
              label="* Sort"
              sx={{
                "& label.Mui-focused": {
                  color: primaryColor,
                },
                "&:hover label": {
                  color: primaryColor,
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "15px",
                  height: "45px",
                  color: "black",

                  "& fieldset": {
                    borderColor: "grey",
                  },
                  "&:hover fieldset": {
                    borderColor: primaryColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: primaryColor,
                  },
                },
              }}
              size="small"
              InputLabelProps={{
                sx: {
                  fontSize: 16,
                  // top: -7,
                  "&.MuiInputLabel-shrink": { top: 0 },
                  "&.Mui-focused": {
                    color: primaryColor,
                  },
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                },
              }}
              value={buttonSort}
              onChange={(e) => setButtonSort(e.target.value.replace(/\D/g, ""))}
              style={{
                width: "48%",
                marginTop: "30px",
              }}
              inputProps={{ maxLength: 2 }}
            />
          </Box>
          {/* <input /> */}
        </div>
        <div className="work-station-main-button-container-checkbox">
          <FormGroup className="work-station-main-button-FormGroup">
            <FormControlLabel
              control={
                <Checkbox
                  checked={visible}
                  onChange={() => setVisible(!visible)}
                  sx={{
                    "&.Mui-checked": {
                      color: primaryColor,
                    },
                  }}
                />
              }
              style={{ width: "100%" }}
              label={lang.visible}
            />
          </FormGroup>{" "}
          <FormGroup className="work-station-main-button-FormGroup">
            <FormControlLabel
              control={
                <Checkbox
                  checked={enable}
                  onChange={() => setEnable(!enable)}
                  sx={{
                    "&.Mui-checked": {
                      color: primaryColor,
                    },
                  }}
                />
              }
              label={lang.enable}
              style={{ width: "100%" }}
            />
          </FormGroup>
        </div>
        {/* <div className=" work-station-main-button-container-checkbox"></div> */}
        <div className=" work-station-main-button-container-save-button">
          <button onClick={() => handleEditMainButtonDetails()}>
            {lang.save}
          </button>
        </div>
      </div>
      <Toaster />
      {loading_worksStations ? <Loader /> : null}
    </div>
  );
};

export default MainButtonsEdit;
