import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const KioskSettings = () => {

  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);
  
  return (
    <div
     className="index-countainer-div"
    >
      <h1 className="index-h1">
        Kiosk settings
      </h1>

      <h1 style={{ textAlign: "center" }}>Comming soon</h1>
    </div>
  );
};

export default KioskSettings;
