import * as actionTypes from "../ActionTypes";
const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading: false,
  financial_order_list: [],
  visible_refund_popup: false,
  selected_financial_order: null,
  start_date: "",
  end_date: "",
  selected_shop: null,
  financial_refund_list: [],
  financial_debt_list: [],
  visible_create_debt_popup: false,
};

const financialReduser = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_employees: false,
      };
    case actionTypes.GET_FINANCIAL_ORDER_LIST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_FINANCIAL_ORDER_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        financial_order_list: action.payload,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.GET_FINANCIAL_ORDER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        financial_order_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.VISIBLE_REFUND_POPPUP:
      return {
        ...state,
        visible_refund_popup: action.payload[0],
        selected_financial_order: action.payload[1],
        start_date: action.payload[2],
        end_date: action.payload[3],
        selected_shop: action.payload[4],
      };

    case actionTypes.REFUND_ORDER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REFUND_ORDER_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        result_successfull: true,
        result_message: action.payload,
        visible_refund_popup: false,
        visible_create_debt_popup: false,
      };
    case actionTypes.REFUND_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.GET_FINANCIAL_REFUND_LIST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_FINANCIAL_REFUND_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        financial_refund_list: action.payload,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.GET_FINANCIAL_DEBT_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        financial_debt_list: action.payload,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.GET_FINANCIAL_REFUND_LIST_FAILED:
      return {
        ...state,
        loading: false,
        financial_refund_list: [],
        financial_debt_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.VISIBLE_CREATE_DEBT_POPUP:
      return {
        ...state,
        visible_create_debt_popup: action.payload[0],
        selected_shop: action.payload[1],
        start_date: action.payload[2],
        end_date: action.payload[3],
      };
    case actionTypes.SEND_DEBT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEND_DEBT_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        result_successfull: true,
        result_message: action.payload,
        // visible_refund_popup: false,
        // visible_create_debt_popup: false,
      };
    case actionTypes.SEND_DEBT_FAILED:
      return {
        ...state,
        loading: false,
        result_failed: true,
        result_message: action.payload,
      };
    default:
      return state;
  }
};
export default financialReduser;
