import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import App from "./App";
const Main = () => {
  return (
    <div className="main_container">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home/*" element={<App />} />
      </Routes>
    </div>
  );
};

export default Main;
