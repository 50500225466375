import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import PromotionsEdit from "../../../../components/PromotionsEdit";
import PromotionsList from "../../../../components/PromotionsList";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import Loader from "../../../../components/Loader";
const Promotions = () => {
  const dispatch = useDispatch();
  const {
    loading_appSettings,
    result_successfull,
    result_message,
    result_failed,
  } = useSelector((state) => state.appSettingsRedusers);
  const [clickedItem1, setClickedItem1] = useState(1);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message != "") {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <div
      // className=" promotion_countaining-div"
      id="countaining mobile-container-promotion"
      style={{
        height: "89.5%",
        width: width1 <= 560 ? "100%" : null,
      }}
    >
      {clickedItem1 == 1 ? (
        <PromotionsList setClickedItem1={(i) => setClickedItem1(i)} />
      ) : clickedItem1 == 2 ? (
        <PromotionsEdit setClickedItem1={(i) => setClickedItem1(i)} />
      ) : null}
      {loading_appSettings ? <Loader /> : null}
    </div>
  );
};

export default Promotions;
