export const english_main_nav_general_menu = [
  {
    id: 1,
    title: "Privacy Policy",
    icon: "",
    expanded: false,
    path: "",
    sublist: [],
    role_id: 91,
  },
  {
    id: 2,
    title: "External Company",
    icon: "",
    expanded: false,
    path: "externalcompany",
    sublist: [],
    role_id: 92,
  },
  {
    id: 3,
    title: "Default submenu",
    icon: "",
    expanded: false,
    path: "publicsubmenu",
    role_id: 93,
    sublist: [
      // {
      //   id: 31,
      //   title: "Topping",
      //   path: "defaultsubmenu/topping",
      //   icon: "fa fa-sliders",
      // },
      // {
      //   id: 32,
      //   title: "Dressing",
      //   path: "defaultsubmenu/dressing",
      //   icon: "fa fa-caret-square-o-down",
      // },
    ],
  },
  {
    id: 4,
    title: "Caller id numbers",
    icon: "",
    expanded: false,
    path: "calleridnumbers",
    role_id: 94,
    sublist: [
      // {
      //   id: 31,
      //   title: "Topping",
      //   path: "defaultsubmenu/topping",
      //   icon: "fa fa-sliders",
      // },
      // {
      //   id: 32,
      //   title: "Dressing",
      //   path: "defaultsubmenu/dressing",
      //   icon: "fa fa-caret-square-o-down",
      // },
    ],
  },
];
export const turkish_main_nav_general_menu = [];
export const persian_main_nav_general_menu = [];
