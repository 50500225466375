import * as actionTypes from "../ActionTypes";

export const getCustomersListAction = (
  login_key,
  user_id,
  shop_id,
  pageNumber,
  search_text,
  search_type
) => {
  return {
    type: actionTypes.GET_CUSTOMERS_LIST,
    payload: {
      login_key,
      user_id,
      shop_id,
      pageNumber,
      search_text,
      search_type,
    },
  };
};
export const getCustomersListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_CUSTOMERS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getCustomersListFailedAction = (data) => {
  return {
    type: actionTypes.GET_CUSTOMERS_LIST_FAILED,
    payload: data,
  };
};

//GET CUSTOMERS EXPORT LIST

export const getCustomersExportListAction = (
  login_key,
  user_id,
  shop_id,
  search_text,
  search_type
) => {
  return {
    type: actionTypes.GET_CUSTOMERS_EXPORT_LIST,
    payload: {
      login_key,
      user_id,
      shop_id,
      search_text,
      search_type,
    },
  };
};
export const getCustomersExportSuccesffullListAction = (data) => {
  return {
    type: actionTypes.GET_CUSTOMERS_EXPORT_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getCustomersExportFailedListAction = (data) => {
  return {
    type: actionTypes.GET_CUSTOMERS_EXPORT_LIST_FAILED,
    payload: data,
  };
};
export const editCustomerAction = (
  login_key,
  user_id,
  shop_id,
  customer_id,
  customer_address_id,
  first_name,
  last_name,
  email,
  mobile,
  address,
  post_code,
  delivery_charge,
  pageNumber,
  search_text,
  search_type
) => {
  return {
    type: actionTypes.EDIT_CUSTOMER,
    payload: {
      login_key,
      user_id,
      shop_id,
      customer_id,
      customer_address_id,
      first_name,
      last_name,
      email,
      mobile,
      address,
      post_code,
      delivery_charge,
      pageNumber,
      search_text,
      search_type,
    },
  };
};
export const editCustomerSuccessfullAction = (data) => {
  return {
    type: actionTypes.EDIT_CUSTOMER_SUCCESSFULL,
    payload: data,
  };
};
export const editCustomerFailedAction = (data) => {
  return {
    type: actionTypes.EDIT_CUSTOMER_FAILED,
    payload: data,
  };
};

export const deleteCustomerAction = (
  login_key,
  user_id,
  shop_id,
  cutomer_id,
  pageNumber,
  search_text,
  search_type
) => {
  return {
    type: actionTypes.DELETE_CUSTOMER,
    payload: {
      login_key,
      user_id,
      shop_id,
      cutomer_id,
      pageNumber,
      search_text,
      search_type,
    },
  };
};

export const deleteCustomerSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_CUSTOMER_SUCCESSFULL,
    payload: data,
  };
};
export const deleteCustomerFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_CUSTOMER_FAILED,
    payload: data,
  };
};

export const showDeleteCustomerPopupAction = (data) => {
  return {
    type: actionTypes.SHOW_DELETE_CUTOMER_POPUP,
    payload: data,
  };
};
export const deletingCustomerInfoAction = (data) => {
  return {
    type: actionTypes.DELETING_CUSTOMER_INFO,
    payload: data,
  };
};
