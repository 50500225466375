import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";

import OpenCloseTitleList from "./OpenCloseTitleList";
import OpenCloseTimeMainList from "./OpenCloseTimeMainList";
import { List } from "../pages/settings/WebSetting/Componenets/List";
import { getOpenCloseTimeListAction } from "../redux/settings/Action";
import { setDefaultAction } from "../redux/menu/Actions";
import Loader from "./Loader";
const OpenCloseTimeList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { imployees_list } = useSelector((state) => state.employeesRedusers);

  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    loading_appSettings,
    open_close_time_list,
    result_successfull,
    result_failed,
    result_message,
  } = useSelector((state) => state.appSettingsRedusers);
  const [id, setId] = useState("container-scroll");
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getOpenCloseTimeListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (!open_close_time_list) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  return (
    <div
      className="countaining-div "
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
    >
      <div className="menu_list_header_container" id="container-scroll">
        <div
          className="margin_menu_normal_horizontal"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div style={{ height: "15%", paddingTop: "10px" }}>
            <OpenCloseTitleList />
          </div>
          <div
            style={{
              height: "85%",
              borderTop: "2px solid grey",
              // borderBottom: "2px solid grey",
            }}
          >
            {List.length > 0 ? (
              <div>
                <OpenCloseTimeMainList
                  id={id}
                  list={List}
                  openCloseTimeList={open_close_time_list}
                />
              </div>
            ) : null}
          </div>
        </div>
        {/* {loading_appSettings ? <Loader /> : null} */}
      </div>
    </div>
  );
};

export default OpenCloseTimeList;
