import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
import {
  getAllAppSettingsAction,
  updateShopSettingAppPaymentAction,
} from "../../../../redux/settings/Action";

const Payment = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();

  const { setting_app_payment } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const { payment_method, payment_method_id } = useSelector(
    (state) => state.categoryList
  );

  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);

  const [collectionDefaultPeymentMethod, setCollectionDefaultPeymentMethod] =
    useState("");
  const [deliveryDefaultPeymentMethod, setDeliveryDefaultPeymentMethod] =
    useState("");
  const [eatInDefaultPaymentMethod, setEatInDefaultPaymentMethod] =
    useState("");
  const [takeawayDefaultPaymentMethod, setTakeawayDefaultPaymentMethod] =
    useState("");
  const [showNotPaidInCollection, setShowNotPaidInCollection] = useState(1);
  const [ShowNotPaidInDelivery, setShowNotPaidInDelivery] = useState(1);
  const [showNotPaidInEatIn, setShowNotPaidInEatIn] = useState(1);
  const [showNotPaidInTakeaway, setShowNotPaidInTakeaway] = useState(1);
  const [usePeymentMethod, setUsePeymentMethod] = useState(1);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleSaveClicked = () => {
    dispatch(
      updateShopSettingAppPaymentAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        usePeymentMethod ? 1 : 0,
        deliveryDefaultPeymentMethod.value,
        collectionDefaultPeymentMethod.value,
        takeawayDefaultPaymentMethod.value,
        eatInDefaultPaymentMethod.value,
        ShowNotPaidInDelivery ? 1 : 0,
        showNotPaidInCollection ? 1 : 0,
        showNotPaidInTakeaway ? 1 : 0,
        showNotPaidInEatIn ? 1 : 0
      )
    );
  };

  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleSaveClicked();
    },
  }));

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    usePeymentMethod,
    deliveryDefaultPeymentMethod,
    collectionDefaultPeymentMethod,
    takeawayDefaultPaymentMethod,
    eatInDefaultPaymentMethod,
    ShowNotPaidInDelivery,
    showNotPaidInCollection,
    showNotPaidInTakeaway,
    showNotPaidInEatIn,
  ]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getAllAppSettingsAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (setting_app_payment.length > 0) {
      let collection_default_peyment_method = [];
      let delivery_default_payment_method = [];
      let eatIn_default_payment_method = [];
      let takeaway_default_payment_mathod = [];
      for (let i = 0; i < setting_app_payment.length; i++) {
        if (setting_app_payment[i].CollectionDefaultPeymentMethod) {
          if (
            payment_method_id.includes(
              parseInt(setting_app_payment[i].CollectionDefaultPeymentMethod)
            )
          ) {
            let index = payment_method_id.indexOf(
              parseInt(setting_app_payment[i].CollectionDefaultPeymentMethod)
            );
            collection_default_peyment_method = {
              label: payment_method[index].label,
              value: payment_method[index].value,
            };
          }
        }
        if (setting_app_payment[i].DeliveryDefaultPeymentMethod) {
          if (
            payment_method_id.includes(
              parseInt(setting_app_payment[i].DeliveryDefaultPeymentMethod)
            )
          ) {
            let index = payment_method_id.indexOf(
              parseInt(setting_app_payment[i].DeliveryDefaultPeymentMethod)
            );
            delivery_default_payment_method = {
              label: payment_method[index].label,
              value: payment_method[index].value,
            };
          }
        }
        if (setting_app_payment[i].EatInDefaultPeymentMethod) {
          if (
            payment_method_id.includes(
              parseInt(setting_app_payment[i].EatInDefaultPeymentMethod)
            )
          ) {
            let index = payment_method_id.indexOf(
              parseInt(setting_app_payment[i].EatInDefaultPeymentMethod)
            );
            eatIn_default_payment_method = {
              label: payment_method[index].label,
              value: payment_method[index].value,
            };
          }
        }
        if (setting_app_payment[i].TakeawayDefaultPeymentMethod) {
          if (
            payment_method_id.includes(
              parseInt(setting_app_payment[i].TakeawayDefaultPeymentMethod)
            )
          ) {
            let index = payment_method_id.indexOf(
              parseInt(setting_app_payment[i].TakeawayDefaultPeymentMethod)
            );
            takeaway_default_payment_mathod = {
              label: payment_method[index].label,
              value: payment_method[index].value,
            };
          }
        }
      }

      setting_app_payment[0].TakeawayDefaultPeymentMethod =
        takeaway_default_payment_mathod;
      setting_app_payment[0].EatInDefaultPeymentMethod =
        eatIn_default_payment_method;
      setting_app_payment[0].DeliveryDefaultPeymentMethod =
        delivery_default_payment_method;
      setting_app_payment[0].CollectionDefaultPeymentMethod =
        collection_default_peyment_method;
      setCollectionDefaultPeymentMethod(
        setting_app_payment[0].CollectionDefaultPeymentMethod
      );

      setDeliveryDefaultPeymentMethod(
        setting_app_payment[0].DeliveryDefaultPeymentMethod
      );
      setEatInDefaultPaymentMethod(
        setting_app_payment[0].EatInDefaultPeymentMethod
      );
      setTakeawayDefaultPaymentMethod(
        setting_app_payment[0].TakeawayDefaultPeymentMethod
      );
      setShowNotPaidInCollection(
        setting_app_payment[0].ShowNotPaidInCollection
      );
      setShowNotPaidInDelivery(setting_app_payment[0].ShowNotPaidInDelivery);
      setShowNotPaidInEatIn(setting_app_payment[0].ShowNotPaidInEatIn);
      setShowNotPaidInTakeaway(setting_app_payment[0].ShowNotPaidInTakeaway);
      setUsePeymentMethod(setting_app_payment[0].UsePeymentMethod);
    }
  }, [setting_app_payment]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.collection_default_peyment_method}</h6>
              <Select
                options={payment_method}
                defaultValue={collectionDefaultPeymentMethod}
                isSearchable={false}
                onChange={setCollectionDefaultPeymentMethod}
                value={collectionDefaultPeymentMethod}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.delivery_default_payment_method}</h6>
              <Select
                options={payment_method}
                defaultValue={deliveryDefaultPeymentMethod}
                isSearchable={false}
                onChange={setDeliveryDefaultPeymentMethod}
                value={deliveryDefaultPeymentMethod}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.eatIn_default_payment_method}</h6>
              <Select
                options={payment_method}
                defaultValue={eatInDefaultPaymentMethod}
                isSearchable={false}
                onChange={setEatInDefaultPaymentMethod}
                value={eatInDefaultPaymentMethod}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>

          <div className="ASOP-div1 ">
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.takeaway_default_payment_mathod}</h6>
              <Select
                options={payment_method}
                defaultValue={takeawayDefaultPaymentMethod}
                isSearchable={false}
                onChange={setTakeawayDefaultPaymentMethod}
                value={takeawayDefaultPaymentMethod}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div
              className="div-chechbox-p ASOP-div1-input-text countainer-checkbox1
        "
            >
              {/* <input
                id="check60"
                type="checkbox"
                checked={showNotPaidInCollection}
                onChange={() =>
                  setShowNotPaidInCollection(!showNotPaidInCollection)
                }
              />
              <label for="check60" className="checkmark"></label>
              <h6 className="h6">{lang.show_not_paid_in_collection}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showNotPaidInCollection}
                      onChange={() =>
                        setShowNotPaidInCollection(!showNotPaidInCollection)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_not_paid_in_collection}
                />
              </FormGroup>
            </div>
            <div
              className="div-chechbox-p ASOP-div1-input-text countainer-checkbox1
       "
            >
              {/* <input
                id="check61"
                type="checkbox"
                checked={ShowNotPaidInDelivery}
                onChange={() =>
                  setShowNotPaidInDelivery(!ShowNotPaidInDelivery)
                }
              />
              <label for="check61" className="checkmark"></label>
              <h6 className="h6">{lang.show_not_paid_in_Delivery}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ShowNotPaidInDelivery}
                      onChange={() =>
                        setShowNotPaidInDelivery(!ShowNotPaidInDelivery)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_not_paid_in_Delivery}
                />
              </FormGroup>
            </div>
          </div>

          <div className="ASOP-div1">
            <div
              className="div-chechbox-p ASOP-div1-input-text
          "
            >
              {/* <input
                id="check62"
                type="checkbox"
                // className="checkbox"
                style={{ margin: 0 }}
                checked={showNotPaidInEatIn}
                onChange={() => setShowNotPaidInEatIn(!showNotPaidInEatIn)}
              />
              <label for="check62" className="checkmark"></label>
              <h6 className="h6">{lang.show_not_paid_in_eatIn}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showNotPaidInEatIn}
                      onChange={() =>
                        setShowNotPaidInEatIn(!showNotPaidInEatIn)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_not_paid_in_eatIn}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p ASOP-div1-input-text">
              {/* <input
                id="check63"
                type="checkbox"
                // className="checkbox"
                style={{ margin: 0 }}
                checked={showNotPaidInTakeaway}
                onChange={() =>
                  setShowNotPaidInTakeaway(!showNotPaidInTakeaway)
                }
              />
              <label for="check63" className="checkmark"></label>
              <h6 className="h6">{lang.show_not_paid_in_takeaway}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showNotPaidInTakeaway}
                      onChange={() =>
                        setShowNotPaidInTakeaway(!showNotPaidInTakeaway)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_not_paid_in_takeaway}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p ASOP-div1-input-text">
              {/* <input
                id="check64"
                type="checkbox"
                // className="checkbox"
                style={{ margin: 0 }}
                checked={usePeymentMethod}
                onChange={() => setUsePeymentMethod(!usePeymentMethod)}
              />
              <label for="check64" className="checkmark"></label>
              <h6 className="h6">{lang.use_peyment_method}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usePeymentMethod}
                      onChange={() => setUsePeymentMethod(!usePeymentMethod)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.use_peyment_method}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Payment;
