import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import ListHeader from "./ListHeader";
import { normal_input_height } from "../constants/Sizes";
import { main_nav_button_color } from "../constants/Colors";
import SaveMenuButton from "./SaveMenuButton";
import { primaryColor, main_nav_back_color } from "../constants/Colors";
import { createUsersAction, updateUsersAction } from "../redux/Users/Actions";
import { shopListByGroupIdAction } from "../redux/Users/Actions";
const styles = {
  inputRoot: {
    color: "green",
    margin: 10,
    padding: 40,
    border: "1px solid red",
  },
};
const OwnersEdit = ({ setClickedItem, classes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_users_list_owners, owners_list } = useSelector(
    (state) => state.usersReduser
  );
  // console.log("owners_list", owners_list);
  const {
    dashboard_accessUser,
    dashboard_accessUser_id,
    dashboard_shop_group,
    dashboard_shop_group_id,
  } = useSelector((state) => state.categoryList);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { new_shop_list, result_successfull } = useSelector(
    (state) => state.usersReduser
  );
  const [loginOTP, setLoginOTP] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gropList, setGroupList] = useState([]);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHash, setPasswordHash] = useState("");
  const [active, setActive] = useState(false);
  const [shopGrop, setShopGrop] = useState(null);
  const [newShopList, setnEWShopList] = useState([]);
  const [shopList, setShopList] = useState(null);
  const [mobile, setMobile] = useState("");
  const [shoGroupList, setShopGroupList] = useState([]);
  const [focusedInputKey, setFocusedInputKey] = useState(null);

  const customStyles = {
    control: (base, state) => ({
      ...base,

      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,

      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const CssTextField = styled(TextField)({
    "&.MuiTextField-root": {
      // border: "1px solid red",
    },
    "& label": {
      color: "red",
      marginTop: 0,
      fontSize: "17px",
    },
    "& label.Mui-focused": {
      color: primaryColor,
      // marginTop: "0px",
    },
    "&:hover label": {
      color: primaryColor,
    },

    " & .MuiInputBase-input": {
      // border: "1px solid green",
    },
    "& .MuiOutlinedInput-root": {
      color: "grey",
      fontSize: "15px",
      height: normal_input_height,
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& fieldset": {
        borderColor: "grey",
      },
      "& .MuiInputBase-root ": {
        backgroundColor: "red",
        border: "1px solid red",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });

  const handleShopList = (list, item) => {
    setShopList(list);
    setShopGrop(null);
  };
  const handleShopGroup = (list, item) => {
    setShopList(null);
    setShopGrop(list);
  };
  const getSHA256Hash = async (input) => {
    const textAsBuffer = new TextEncoder().encode(input);

    const hashBuffer = await window.crypto.subtle.digest(
      "SHA-256",
      textAsBuffer
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((item) => item.toString(16).padStart(2, "0"))
      .join("");
    return hash;
  };
  (async () => {
    const hash = await getSHA256Hash(password);
  })();
  const handleCreateAndSave = async () => {
    const hash_password = await getSHA256Hash(password);
    let dashboard_accessUser_id_list_to_server_array = [];
    if (gropList) {
      for (let i = 0; i < gropList.length; i++) {
        dashboard_accessUser_id_list_to_server_array.push(gropList[i].value);
      }
    }
    if (selected_users_list_owners) {
      if (
        !gropList ||
        firstName == "" ||
        lastName == "" ||
        userName == "" ||
        password == "" ||
        (shopList == null && shopGrop == null)
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        if (loginOTP) {
          if (mobile == "") {
            toast.error(
              <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          } else {
            dispatch(
              updateUsersAction(
                login_key,
                user_id,
                selected_users_list_owners.UserId,
                10001202,
                firstName.replaceAll(`"`, `\"\"`),
                lastName.replaceAll(`"`, `\"\"`),
                active ? 1 : 0,
                shopGrop ? shopGrop.value.toString() : "",
                userName.replaceAll(`"`, `\"\"`),
                password == "11111111"
                  ? passwordHash.replaceAll(`"`, `\"\"`)
                  : hash_password.replaceAll(`"`, `\"\"`),
                dashboard_accessUser_id_list_to_server_array.toString(),
                shopList ? shopList.value : 0,
                // shopList ? shopList.value : 0
                mobile ? mobile : "",
                loginOTP ? 1 : 0,
                0
              )
            );
          }
        } else {
          dispatch(
            updateUsersAction(
              login_key,
              user_id,
              selected_users_list_owners.UserId,
              10001202,
              firstName.replaceAll(`"`, `\"\"`),
              lastName.replaceAll(`"`, `\"\"`),
              active ? 1 : 0,
              shopGrop ? shopGrop.value.toString() : "",
              userName.replaceAll(`"`, `\"\"`),
              password == "11111111"
                ? passwordHash.replaceAll(`"`, `\"\"`)
                : hash_password.replaceAll(`"`, `\"\"`),
              dashboard_accessUser_id_list_to_server_array.toString(),
              shopList ? shopList.value : 0,
              mobile ? mobile : "",
              loginOTP ? 1 : 0,
              0
              // shopList ? shopList.value : 0
            )
          );
        }
      }
    } else {
      if (
        firstName == "" ||
        lastName == "" ||
        userName == "" ||
        password == "" ||
        !gropList ||
        (shopList == null && shopGrop == null)
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        if (loginOTP) {
          if (mobile == "") {
            toast.error(
              <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          } else {
            dispatch(
              createUsersAction(
                login_key,
                user_id,
                10001202,
                firstName.replaceAll(`"`, `\"\"`),
                lastName.replaceAll(`"`, `\"\"`),
                active ? 1 : 0,
                shopGrop ? shopGrop.value.toString() : String(0),
                userName.replaceAll(`"`, `\"\"`),
                hash_password.replaceAll(`"`, `\"\"`),
                dashboard_accessUser_id_list_to_server_array.toString(),
                shopList ? shopList.value : 0,
                mobile ? mobile : "",
                loginOTP ? 1 : 0,
                0
              )
            );
          }
        } else {
          dispatch(
            createUsersAction(
              login_key,
              user_id,
              10001202,
              firstName.replaceAll(`"`, `\"\"`),
              lastName.replaceAll(`"`, `\"\"`),
              active ? 1 : 0,
              shopGrop ? shopGrop.value.toString() : String(0),
              userName.replaceAll(`"`, `\"\"`),
              hash_password.replaceAll(`"`, `\"\"`),
              dashboard_accessUser_id_list_to_server_array.toString(),
              shopList ? shopList.value : 0,
              mobile ? mobile : "",
              loginOTP ? 1 : 0,
              0
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    if (selected_users_list_owners) {
      setFirstName(selected_users_list_owners.FirstName);
      setLastName(selected_users_list_owners.LastName);
      setUserName(selected_users_list_owners.UserName);
      if (selected_users_list_owners.Pass.length > 20) {
        setPasswordHash(selected_users_list_owners.Pass);
        setPassword(11111111);
      } else {
        setPassword(selected_users_list_owners.Pass);
      }

      setActive(selected_users_list_owners.Active);
      setGroupList(selected_users_list_owners.access);
      if (selected_users_list_owners.ShopGroupId.length != 0) {
        setShopGrop(selected_users_list_owners.ShopGroupId);
      } else {
        setShopGrop(null);
      }
      if (selected_users_list_owners.ShopId.length != 0) {
        setShopList(selected_users_list_owners.ShopId);
      } else {
        setShopList(null);
      }
      if (selected_users_list_owners.Mobile) {
        setMobile(selected_users_list_owners.Mobile);
      } else {
        setMobile("");
      }
      setLoginOTP(selected_users_list_owners.LoginOTP);
    }
  }, [selected_users_list_owners]);

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleCreateAndSave();
        // let dashboard_accessUser_id_list_to_server_array = [];

        // if (gropList) {
        //   for (let i = 0; i < gropList.length; i++) {
        //     dashboard_accessUser_id_list_to_server_array.push(
        //       gropList[i].value
        //     );
        //   }
        // }
        // if (selected_users_list_owners) {
        //   if (
        //     firstName == "" ||
        //     lastName == "" ||
        //     userName == "" ||
        //     password == "" ||
        //     !gropList ||
        //     !shopGrop
        //   ) {
        //     toast.error(
        //       <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        //       {
        //         position: "top-center",
        //         duration: 3000,
        //         style: { backgroundColor: "red", color: "white" },
        //         icon: (
        //           <i
        //             style={{ fontSize: 40 }}
        //             className="fa fa-times-circle"
        //             aria-hidden="true"
        //           ></i>
        //         ),
        //       }
        //     );
        //   } else {
        //     dispatch(
        //       updateUsersAction(
        //         login_key,
        //         user_id,
        //         selected_users_list_owners.UserId,
        //         10001202,
        //         firstName.replaceAll(`"`, `\"\"`),
        //         lastName.replaceAll(`"`, `\"\"`),
        //         active ? 1 : 0,
        //         shopGrop.value,
        //         userName.replaceAll(`"`, `\"\"`),
        //         password.replaceAll(`"`, `\"\"`),
        //         dashboard_accessUser_id_list_to_server_array.toString()
        //       )
        //     );
        //   }
        // }
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [firstName, lastName, active, shopGrop, userName, password, gropList]);
  useEffect(() => {
    const copy_dashboard_shop_group = [...dashboard_shop_group];
    if (copy_dashboard_shop_group.length > 0) {
      const sortArrey = copy_dashboard_shop_group.sort(function (a, b) {
        return a.SortId - b.SortId;
      });
      setShopGroupList(sortArrey);
    }
  }, [dashboard_shop_group]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <ListHeader
        title={selected_users_list_owners ? lang.edit_owner : lang.create_owner}
        show_back={true}
        clickedItem={() => setClickedItem(1)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {/* <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "50px",
              marginBottom: "-50px",
            }}
          >
            <div className="sm-m"></div>
            <div className="sm-m"></div>
            <div className="sm-m">
              <h6 className=" h6">* {lang.access}</h6>
            </div>
          </div> */}
          <div className="sm-div1">
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.first_name}</h6>
              <input
                className="sm-input input-padding"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.last_name}</h6>
              <input
                className="sm-input input-padding"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            {/* <Box
              component="form"
              // sx={{
              //   "& > :not(style)": { m: 1, width: "35ch", height: "20ch" },
              // }}
              // noValidate
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
              autoComplete="on"
            >
              <CssTextField
                key={1}
                label={lang.first_name}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
                onBlur={() => setFocusedInputKey(null)}
                // classes={{ root: classes.customTextField }}
                className="textfield"
              />
              <CssTextField
                key={2}
                label={lang.last_name}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <div className="sm-m">
                <Select
                  options={dashboard_accessUser}
                  defaultValue={gropList}
                  //   isSearchable={false}
                  onChange={setGroupList}
                  value={gropList}
                  isMulti={true}
                  styles={customStyles}
                  isClearable={true}
                  isSearchable={false}
                />
              </div>
            </Box> */}
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.access}</h6>
              <Select
                options={dashboard_accessUser}
                defaultValue={gropList}
                isSearchable={false}
                onChange={(list, item) => setGroupList(list)}
                value={gropList}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              // marginTop: "50px",
              marginBottom: "-50px",
            }}
          >
            <div className="sm-m"></div>
            <div className="sm-m"></div>
            <div className="sm-m">
              <h6 className=" h6">* {lang.shop_group}</h6>
            </div>
          </div> */}
          <div className="sm-div2">
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.user_name}</h6>
              <input
                className="sm-input input-padding"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.pass}</h6>
              <input
                type="password"
                className="sm-input input-padding"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setPassword("")}
                maxLength={20}
              />
            </div>{" "}
            <div
              className="sm-m  div-chechbox-p countainer-checkbox1"
              // style={{ marginTop: "3px" }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={() => setActive(!active)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>
            {/* <Box
              component="form"
              // sx={{
              //   "& > :not(style)": { m: 1, width: "35ch", height: "20ch" },
              // }}
              // noValidate
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              autoComplete="on"
            >
              <CssTextField
                key={3}
                label={lang.user_name}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 3 ? true : false}
                onFocus={() => setFocusedInputKey(3)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                key={4}
                label={lang.pass}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 4 ? true : false}
                onFocus={() => setFocusedInputKey(4)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box> */}
          </div>
          <div className="sm-div2">
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.shop_list}</h6>
              <Select
                options={new_shop_list}
                defaultValue={shopList}
                isSearchable={false}
                onChange={handleShopList}
                value={shopList}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.shop_group}</h6>
              <Select
                options={shoGroupList}
                defaultValue={shopGrop}
                //   isSearchable={false}
                onChange={handleShopGroup}
                value={shopGrop}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
                isSearchable={false}
              />
            </div>

            <div className="sm-m  ">
              <h6 className="submenuEdit-titles h6">
                {" "}
                {loginOTP ? `* ${lang.mobile}` : lang.mobile}
              </h6>
              <input
                className="sm-input input-padding"
                value={mobile}
                maxLength={11}
                onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
              />
            </div>
          </div>
          <div className="sm-m  ">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={loginOTP}
                    onChange={() => setLoginOTP(!loginOTP)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.login_otp}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={handleCreateAndSave} />
    </div>
  );
};

export default OwnersEdit;
