import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import moment from "moment";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
import {
  getPaymentLinkListAction,
  updatePaymentLinkAction,
} from "../../../../redux/settings/Action";
import { expire_time } from "../../WebSetting/Componenets/List";
const PaymentLink = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { payment_method, payment_method_id } = useSelector(
    (state) => state.categoryList
  );
  const { get_payment_link_list } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const [adminFee, setAdminFee] = useState("");
  const [domain, setDomain] = useState("");
  const [message, setMessage] = useState("");
  const [takeaway, setTakeaway] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [collection, setCollection] = useState(false);
  const [eateIn, setEatIn] = useState(false);
  const [askBeforeSendingPaymentLink, setAskBeforeSendingPaymentLink] =
    useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [expireTime, setExpireTime] = useState(null);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getPaymentLinkListAction(login_key, user_id, selected_shop[0].ShopId)
        );
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    let payment_methods = [];
    let expire_time1 = [];
    let copy_expire_time = [...expire_time];
    let expire_time_label = [];
    for (let index = 0; index < copy_expire_time.length; index++) {
      expire_time_label.push(copy_expire_time[index].label);
    }
    if (get_payment_link_list) {
      if (get_payment_link_list.length > 0) {
        setAdminFee(get_payment_link_list[0].AdminFee);
        setAskBeforeSendingPaymentLink(
          get_payment_link_list[0].AskBeforeSendingTextMessage
        );
        setCollection(get_payment_link_list[0].CollectionByPayeat);
        setDelivery(get_payment_link_list[0].DeliveryByPayeat);
        setDomain(get_payment_link_list[0].Domain);
        setEatIn(get_payment_link_list[0].EatInByPayeat);
        setMessage(get_payment_link_list[0].Message);
        setTakeaway(get_payment_link_list[0].TakeawayByPayeat);
        if (get_payment_link_list[0].PaymentMethod) {
          if (
            payment_method_id.includes(
              parseInt(get_payment_link_list[0].PaymentMethod)
            )
          ) {
            const index = payment_method_id.indexOf(
              parseInt(get_payment_link_list[0].PaymentMethod)
            );
            payment_methods = {
              label: payment_method[index].label,
              value: payment_method[index].value,
            };
          }
          setPaymentMethod(payment_methods);
        }
        if (get_payment_link_list[0].ExpireTimeMin) {
          if (
            expire_time_label.includes(
              parseInt(get_payment_link_list[0].ExpireTimeMin.split(":")[1])
            )
          ) {
            const index = expire_time_label.indexOf(
              parseInt(get_payment_link_list[0].ExpireTimeMin.split(":")[1])
            );

            expire_time1 = {
              label: expire_time[index].label,
              value: expire_time[index].value,
            };
          }
          setExpireTime(expire_time1);
        }
      }
    }
  }, [get_payment_link_list]);

  const handleSavePaymentLink = () => {
    if (
      paymentMethod == null ||
      message == "" ||
      adminFee == "" ||
      domain == "" ||
      expireTime == null
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updatePaymentLinkAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          delivery ? 1 : 0,
          collection ? 1 : 0,
          takeaway ? 1 : 0,
          eateIn ? 1 : 0,
          `00:${expireTime.label}:00`,
          paymentMethod.value,
          askBeforeSendingPaymentLink ? 1 : 0,
          message,
          domain,
          adminFee
        )
      );
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleSavePaymentLink();
    },
  }));

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSavePaymentLink();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    domain,
    adminFee,
    message,
    askBeforeSendingPaymentLink,
    delivery,
    collection,
    eateIn,
    takeaway,
    expireTime,
    paymentMethod,
  ]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">* {lang.admin_fee}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={adminFee}
                onChange={(e) =>
                  setAdminFee(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>

            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">* {lang.domain}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">* {lang.payment_method}</h6>
              <Select
                options={payment_method}
                defaultValue={paymentMethod}
                isSearchable={false}
                onChange={(list, item) => setPaymentMethod(list)}
                value={paymentMethod}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>

            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">* {lang.expire_time}</h6>
              <Select
                options={expire_time}
                defaultValue={expireTime}
                isSearchable={false}
                onChange={(list, item) => setExpireTime(list)}
                value={expireTime}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="ASOP-div3">
            <div className=" div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={delivery}
                      onChange={(e) => setDelivery(!delivery)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.delivery}
                />
              </FormGroup>
            </div>
            <div className=" div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={collection}
                      onChange={(e) => setCollection(!collection)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.collection}
                />
              </FormGroup>
            </div>
            <div className=" div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={eateIn}
                      onChange={(e) => setEatIn(!eateIn)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.eat_in}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div3-1 div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={takeaway}
                      onChange={(e) => setTakeaway(!takeaway)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.takeaway}
                />
              </FormGroup>
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "45%" }}>
              <h6 className="h6">* {lang.message}</h6>
              <textarea
                style={{
                  width: "100%",
                }}
                label={lang.closed_order_message}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div
              className="ASOP-div1-input-text div-chechbox-p"
              style={{ marginTop: "50px", width: "45%" }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={askBeforeSendingPaymentLink}
                      onChange={() =>
                        setAskBeforeSendingPaymentLink(
                          !askBeforeSendingPaymentLink
                        )
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.asck_before_sending_payment_link}
                />
              </FormGroup>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
});

export default PaymentLink;
