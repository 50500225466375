import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import ListHeader from "../components/ListHeader";
import MenuListItem from "../components/MenuListItem";
import AddMenuItem from "../components/AddMenuItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import toast, { Toaster } from "react-hot-toast";
import { primaryColor } from "../constants/Colors";
import {
  getCategoryOptionsListAction,
  setSelectedCategoryOptionAction,
  createCategoryOptionAction,
  deleteCategoryOptionAction,
  selctedCategoryOptionCrust,
  clickedItemAction,
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
  getCategoryOptionCrustAction,
  selectedOtemInSomeLists,
  updateCategoryOptionCrustAction,
  sendCategoryOptionAction,
} from "../redux/menu/Actions";

const OptionsList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const {
    category_list,
    selected_category,
    category_options_list,
    loading_category,
    vat_id,
    vat,
    selected_category_option,
    change_selected_button_border_color_on_focus,
    result_successfull,
    category_option_crust_list,
    selected_item_in_all_lists,
    selected_item_in_some_lists,
  } = useSelector((state) => state.categoryList);
  const [emptyInput, setEmptyInput] = useState(false);
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  const [optionList, setOptionList] = useState([]);
  const [appTitle, setAppTitle] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [secondLanguage, setSecondLanguage] = useState("");
  const [mainPrice, setMainPrice] = useState(0);
  const [optionCrustsList, setOptionCrustsList] = useState([]);
  const [crustSecondLanguage, setCrustSecondLanguage] = useState("");
  const [crustMainPrice, setCrustMainPrice] = useState(0);
  const [crustAppTitle, setCrustAppTitle] = useState(0);
  const [crustWebTitle, setCrustWebTitle] = useState(0);
  const [userAccesses, setUserAccesses] = useState([]);
  const [userType, setUserType] = useState(0);
  const [rolesId, setRolesId] = useState([]);
  const handleAddOption = (t, d, a) => {
    dispatch(
      createCategoryOptionAction(
        login_key,
        user_id,
        selected_category.ShopId,
        selected_category.Id,
        t.replaceAll(`"`, `\"\"`),
        d.replaceAll(`"`, `\"\"`),
        a ? parseFloat(a) : 0
      )
    );
    // setEmptyInput(true);
  };
  const handleDeleteOption = (id) => {
    dispatch(
      deleteCategoryOptionAction(
        login_key,
        user_id,
        selected_category.ShopId,
        id,
        selected_category.Id
      )
    );
  };
  const handleCrustSaveClicked = () => {
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else
      dispatch(
        updateCategoryOptionCrustAction(
          login_key,
          user_id,
          selected_item_in_some_lists.Id,
          selected_item_in_some_lists.ShopId,
          selected_item_in_some_lists.OptionId,
          crustAppTitle.replaceAll(`"`, `\"\"`),
          crustWebTitle
            ? crustAppTitle.replaceAll(`"`, `\"\"`)
            : crustAppTitle.replaceAll(`"`, `\"\"`),
          "",
          "",
          "",
          crustSecondLanguage.replaceAll(`"`, `\"\"`),
          crustMainPrice ? parseFloat(crustMainPrice) : 0,
          0,
          0,
          0,
          0,
          0,
          0,
          "",
          "",
          30,
          1
        )
      );
  };
  const handleSaveClicked = () => {
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else
      dispatch(
        sendCategoryOptionAction(
          login_key,
          user_id,
          selected_item_in_all_lists.Id,
          selected_item_in_all_lists.ShopId,
          selected_item_in_all_lists.CategoryId,
          appTitle.replaceAll(`"`, `\"\"`),
          "",
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          "",
          "",
          "",
          secondLanguage.replaceAll(`"`, `\"\"`),
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          "",
          "",
          1,
          mainPrice ? parseFloat(mainPrice) : 0,
          0,
          0,
          0
        )
      );
  };
  const handleClick = (item, key) => {
    setClickedItem(key);
    let vatList = null;
    // SET DEFAULT VAT LIST
    if (item.VATId) {
      if (vat_id.includes(parseInt(item.VATId))) {
        let index = vat_id.indexOf(parseInt(item.VATId));
        vatList = {
          value: vat[index].value,
          label: vat[index].label,
        };
      }
    }

    dispatch(setSelectedCategoryOptionAction({ ...item, VATId: vatList }));
  };
  const handleSelectedOption = (item) => {
    let vatList = null;
    // SET DEFAULT VAT LIST
    if (item.VATId) {
      if (vat_id.includes(parseInt(item.VATId))) {
        let index = vat_id.indexOf(parseInt(item.VATId));
        vatList = {
          value: vat[index].value,
          label: vat[index].label,
        };
      }
    }

    dispatch(setSelectedCategoryOptionAction({ ...item, VATId: vatList }));
  };
  const handleEditIconClick = (item) => {
    const copy_product_list = [...optionList];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.Id == copy_product_list[i].Id) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setOptionList(copy_product_list);
    dispatch(selectedOtemInAllLists(item));
  };

  const handleCloseIcon = (item) => {
    const copy_product_list = [...optionList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setOptionList(copy_product_list);
  };

  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...optionList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
    }
    setOptionList(copy_product_list);
  };

  const handleShowDeleteBox = (item) => {
    const copy_list = [...optionList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setOptionList(copy_list);
  };

  const handleCancelDeleteBox = (item) => {
    const copy_list = [...optionList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setOptionList(copy_list);
  };
  const handleShowOptions = (item) => {
    const copy_list = [...optionList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showCrusts = true;
      } else {
        copy_list[i].showCrusts = false;
      }
    }
    setOptionList(copy_list);
    handleSelectedOption(item);
  };
  const handleCloseOptions = (item) => {
    const copy_options_list = [...optionList];
    for (let index = 0; index < copy_options_list.length; index++) {
      if (item.Id == copy_options_list[index].Id) {
        copy_options_list[index].showCrusts = false;
      }
    }
    setOptionList(copy_options_list);
  };
  const handleEditcrust = (element) => {
    dispatch(selectedOtemInSomeLists(element));
    const copy_option_crust_list = [...optionCrustsList];
    for (let i = 0; i < copy_option_crust_list.length; i++) {
      if (element.Id == copy_option_crust_list[i].Id) {
        copy_option_crust_list[i].showEditBox = true;
      } else {
        copy_option_crust_list[i].showEditBox = false;
      }
    }
    setOptionCrustsList(copy_option_crust_list);
  };
  const handleCloseOptionCrustEditBox = (element) => {
    const copy_option_crust_list = [...optionCrustsList];
    for (let i = 0; i < copy_option_crust_list.length; i++) {
      if (element.Id == copy_option_crust_list[i].Id) {
        copy_option_crust_list[i].showEditBox = false;
      }
    }
    setOptionCrustsList(copy_option_crust_list);
  };
  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    dispatch(
      getCategoryOptionsListAction(
        login_key,
        user_id,
        selected_category.ShopId,
        selected_category.Id
      )
    );
  }, []);

  useEffect(() => {
    if (selected_category) {
      dispatch(
        getCategoryOptionsListAction(
          login_key,
          user_id,
          selected_category.ShopId,
          selected_category.Id
        )
      );
    }
  }, [selected_category]);
  useEffect(() => {
    if (category_options_list.length > 0) {
      setOptionList(category_options_list);
    }
  }, [category_options_list]);
  useEffect(() => {
    if (selected_category_option) {
      dispatch(
        getCategoryOptionCrustAction(
          login_key,
          user_id,
          selected_category_option.ShopId,
          selected_category_option.Id,
          selected_category_option.CategoryId
        )
      );
    }
  }, [selected_category_option]);

  useEffect(() => {
    if (category_option_crust_list.length > 0) {
      setOptionCrustsList(category_option_crust_list);
    }
  }, [category_option_crust_list]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_product_list = [...optionList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (copy_product_list[index].showEditBox) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setOptionList(copy_product_list);
    dispatch(justTitleAndPriceAction(1));
    return () => {
      dispatch(justTitleAndPriceAction(0));
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(selectedOtemInAllLists(null));
      dispatch(selectedOtemInSomeLists(null));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);

  useEffect(() => {
    let roles_id = [];
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          setUserType(userAccesses[0].UserType);
          roles_id.push(userAccesses[i].RoleId);
        }
      }
    }
    setRolesId(roles_id);
  }, [userAccesses]);

  useEffect(() => {
    if (selected_item_in_all_lists) {
      if (selected_item_in_all_lists.AppTitle) {
        setAppTitle(selected_item_in_all_lists.AppTitle);
      } else {
        setAppTitle("");
      }
      if (selected_item_in_all_lists.WebTitle) {
        setWebTitle(selected_item_in_all_lists.WebTitle);
      } else {
        setWebTitle("");
      }
      if (selected_item_in_all_lists.Price) {
        setMainPrice(selected_item_in_all_lists.Price);
      } else {
        setMainPrice(0);
      }
      if (selected_item_in_all_lists.SecondLanguage) {
        setSecondLanguage(selected_item_in_all_lists.SecondLanguage);
      } else {
        setSecondLanguage("");
      }
    }
  }, [selected_item_in_all_lists]);

  useEffect(() => {
    if (selected_item_in_some_lists) {
      setCrustMainPrice(selected_item_in_some_lists.Price);
      setCrustAppTitle(selected_item_in_some_lists.AppTitle);
      setCrustWebTitle(selected_item_in_some_lists.WebTitle);
      setCrustSecondLanguage(selected_item_in_some_lists.SecondLanguage);
    }
  }, [selected_item_in_some_lists]);

  return (
    <div className="countaining-div" onClick={handleCloseEditBoxWithOnBlur}>
      <ListHeader title={lang.category_options_list} show_back={false} />
      {userType == 10001202 && rolesId.includes(17) && !rolesId.includes(1) ? (
        <>
          <div className="menu_list_header_container">
            {optionList.length > 0 ? (
              <div>
                {optionList.map((item, index) => (
                  <div>
                    {item.showEditBox ? (
                      <div
                        className="menu_list_item_container box_shadow menu-list-container"
                        onClick={(i) => i.stopPropagation()}
                      >
                        <Box
                          component="form"
                          className="menu-list-container-inputs"
                          autoComplete="on"
                        >
                          <TextField
                            key={1}
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",

                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            label={lang.app_title}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,
                                "&.MuiInputLabel-shrink": { top: 0 },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                                "& label.Mui-focused": {
                                  color: primaryColor,
                                },
                                "&:hover label": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            value={appTitle}
                            // defaultValue={number == 9 ? postCode : appTitle}
                            onChange={(e) => setAppTitle(e.target.value)}
                            style={{
                              width: "27%",
                            }}
                            autoFocus={focusedInputKey == 1 ? true : false}
                            onFocus={() => setFocusedInputKey(1)}
                          />
                          <TextField
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",
                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            key={2}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,
                                "&.MuiInputLabel-shrink": { top: 0 },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            label={lang.web_title}
                            value={webTitle}
                            onChange={(e) => setWebTitle(e.target.value)}
                            style={{
                              width: "27%",
                            }}
                            autoFocus={focusedInputKey == 2 ? true : false}
                            onFocus={() => setFocusedInputKey(2)}
                          />
                          <TextField
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",
                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            key={4}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,

                                "&.MuiInputLabel-shrink": {
                                  top: 0,
                                },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            label={lang.secoundLanguage}
                            value={secondLanguage}
                            onChange={(e) => setSecondLanguage(e.target.value)}
                            style={{
                              width: "27%",
                            }}
                            autoFocus={false}
                            onFocus={() => setFocusedInputKey(3)}
                          />
                          <TextField
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",
                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            key={4}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,

                                "&.MuiInputLabel-shrink": {
                                  top: 0,
                                },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            label={lang.mainprice}
                            value={mainPrice}
                            onChange={(e) => setMainPrice(e.target.value)}
                            style={{
                              width: "15%",
                            }}
                            autoFocus={false}
                            onFocus={() => setFocusedInputKey(3)}
                          />
                        </Box>

                        <div className="menu-list-container-edit-box-icons">
                          <div
                            className="menu-list-container-icon"
                            onClick={() => handleSaveClicked()}
                          >
                            <i
                              class="fa fa-check menu-list-fa-check"
                              aria-hidden="true"
                              style={{ fontSize: " 23px" }}
                            ></i>
                          </div>
                          <div
                            className="menu-list-container-icon"
                            onClick={() => handleCloseIcon(item)}
                          >
                            <i
                              class="fa fa-times menu-list-fa-times"
                              aria-hidden="true"
                              style={{ fontSize: " 23px" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="menu_list_item_container">
                        <div
                          className="menu_list_title_container"
                          style={{
                            marginRight: "-15px",
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: "18px",
                          }}
                          onClick={(i) => i.stopPropagation()}
                        >
                          <div
                            style={{
                              width: "65%",
                              display: "flex",
                            }}
                          >
                            <div
                              className="menu-list-continer-title"
                              style={{
                                width: "48%",
                              }}
                            >
                              <h6
                                style={{
                                  margin: 0,
                                }}
                              >
                                {item.AppTitle}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="menu-list-continer-price-and-delete-and-edit-icons"
                            style={{ width: "25%" }}
                          >
                            {item.Price ? (
                              <h6
                                style={{
                                  margin: "0px 13px 0px 0px",
                                }}
                              >
                                £{item.Price}
                              </h6>
                            ) : null}

                            <i
                              class="fa fa-pencil"
                              aria-hidden="true"
                              onClick={() => handleEditIconClick(item)}
                            ></i>
                            <div className="owners-menu-container-products-options">
                              <p style={{ margin: 0 }}>{lang.crusts}</p>

                              {item.showCrusts ? (
                                <i
                                  class="fa fa-caret-down"
                                  aria-hidden="true"
                                  onClick={() => handleCloseOptions(item)}
                                ></i>
                              ) : (
                                <i
                                  class="fa fa-caret-up"
                                  aria-hidden="true"
                                  onClick={() => handleShowOptions(item)}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {item.showCrusts
                      ? optionCrustsList
                        ? optionCrustsList.length > 0
                          ? optionCrustsList.map((element, index) => (
                              <div style={{ padding: "0px 15px" }}>
                                {element.showEditBox ? (
                                  <div
                                    className="menu_list_item_container box_shadow menu-list-container"
                                    onClick={(i) => i.stopPropagation()}
                                  >
                                    <Box
                                      component="form"
                                      className="menu-list-container-inputs"
                                      autoComplete="on"
                                    >
                                      <TextField
                                        key={1}
                                        sx={{
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            fontSize: "15px",
                                            height: "40px",
                                            color: "black",

                                            "& fieldset": {
                                              borderColor: "grey",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: primaryColor,
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: primaryColor,
                                            },
                                          },
                                        }}
                                        size="small"
                                        label={lang.app_title}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: 16,
                                            // top: -7,
                                            "&.MuiInputLabel-shrink": {
                                              top: 0,
                                            },
                                            "&.Mui-focused": {
                                              color: primaryColor,
                                            },
                                            "& label.Mui-focused": {
                                              color: primaryColor,
                                            },
                                            "&:hover label": {
                                              color: primaryColor,
                                            },
                                          },
                                        }}
                                        value={crustAppTitle}
                                        // defaultValue={number == 9 ? postCode : appTitle}
                                        onChange={(e) =>
                                          setCrustAppTitle(e.target.value)
                                        }
                                        style={{
                                          width: "27%",
                                        }}
                                        autoFocus={
                                          focusedInputKey == 1 ? true : false
                                        }
                                        onFocus={() => setFocusedInputKey(1)}
                                      />
                                      <TextField
                                        sx={{
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            fontSize: "15px",
                                            height: "40px",
                                            color: "black",
                                            "& fieldset": {
                                              borderColor: "grey",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: primaryColor,
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: primaryColor,
                                            },
                                          },
                                        }}
                                        size="small"
                                        key={2}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: 16,
                                            // top: -7,
                                            "&.MuiInputLabel-shrink": {
                                              top: 0,
                                            },
                                            "&.Mui-focused": {
                                              color: primaryColor,
                                            },
                                          },
                                        }}
                                        label={lang.web_title}
                                        value={crustWebTitle}
                                        onChange={(e) =>
                                          setCrustWebTitle(e.target.value)
                                        }
                                        style={{
                                          width: "27%",
                                        }}
                                        autoFocus={
                                          focusedInputKey == 2 ? true : false
                                        }
                                        onFocus={() => setFocusedInputKey(2)}
                                      />
                                      <TextField
                                        sx={{
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            fontSize: "15px",
                                            height: "40px",
                                            color: "black",
                                            "& fieldset": {
                                              borderColor: "grey",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: primaryColor,
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: primaryColor,
                                            },
                                          },
                                        }}
                                        size="small"
                                        key={4}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: 16,
                                            // top: -7,

                                            "&.MuiInputLabel-shrink": {
                                              top: 0,
                                            },
                                            "&.Mui-focused": {
                                              color: primaryColor,
                                            },
                                          },
                                        }}
                                        label={lang.secoundLanguage}
                                        value={crustSecondLanguage}
                                        onChange={(e) =>
                                          setCrustSecondLanguage(e.target.value)
                                        }
                                        style={{
                                          width: "27%",
                                        }}
                                        autoFocus={false}
                                        onFocus={() => setFocusedInputKey(3)}
                                      />
                                      <TextField
                                        sx={{
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                          "& .MuiOutlinedInput-root": {
                                            fontSize: "15px",
                                            height: "40px",
                                            color: "black",
                                            "& fieldset": {
                                              borderColor: "grey",
                                            },
                                            "&:hover fieldset": {
                                              borderColor: primaryColor,
                                            },
                                            "&.Mui-focused fieldset": {
                                              borderColor: primaryColor,
                                            },
                                          },
                                        }}
                                        size="small"
                                        key={4}
                                        InputLabelProps={{
                                          sx: {
                                            fontSize: 16,
                                            // top: -7,

                                            "&.MuiInputLabel-shrink": {
                                              top: 0,
                                            },
                                            "&.Mui-focused": {
                                              color: primaryColor,
                                            },
                                          },
                                        }}
                                        label={lang.mainprice}
                                        value={crustMainPrice}
                                        onChange={(e) =>
                                          setCrustMainPrice(e.target.value)
                                        }
                                        style={{
                                          width: "15%",
                                        }}
                                        autoFocus={false}
                                        onFocus={() => setFocusedInputKey(3)}
                                      />
                                    </Box>

                                    <div className="menu-list-container-edit-box-icons">
                                      <div
                                        className="menu-list-container-icon"
                                        onClick={() => handleCrustSaveClicked()}
                                      >
                                        <i
                                          class="fa fa-check menu-list-fa-check"
                                          aria-hidden="true"
                                          style={{ fontSize: " 23px" }}
                                        ></i>
                                      </div>
                                      <div
                                        className="menu-list-container-icon"
                                        onClick={() =>
                                          handleCloseOptionCrustEditBox(element)
                                        }
                                      >
                                        <i
                                          class="fa fa-times menu-list-fa-times"
                                          aria-hidden="true"
                                          style={{ fontSize: " 23px" }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="menu_list_item_container">
                                    <div
                                      className="menu_list_title_container"
                                      style={{
                                        marginRight: "-15px",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingBottom: "18px",
                                        backgroundColor: "#dddddd",
                                      }}
                                      onClick={(i) => i.stopPropagation()}
                                    >
                                      <div
                                        style={{
                                          width: "75%",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          className="menu-list-continer-title"
                                          style={{
                                            width: "48%",
                                          }}
                                        >
                                          <h6
                                            style={{
                                              margin: 0,
                                            }}
                                          >
                                            {element.AppTitle}
                                          </h6>
                                        </div>
                                      </div>
                                      <div
                                        className="menu-list-continer-price-and-delete-and-edit-icons"
                                        style={{ width: "15%" }}
                                      >
                                        {element.Price ? (
                                          <h6
                                            style={{
                                              margin: "0px 13px 0px 0px",
                                            }}
                                          >
                                            £{element.Price}
                                          </h6>
                                        ) : null}

                                        <i
                                          class="fa fa-pencil"
                                          aria-hidden="true"
                                          onClick={() =>
                                            handleEditcrust(element)
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))
                          : null
                        : null
                      : null}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <AddMenuItem
            button_title={lang.addOption}
            buttonClick={(t, d, a) => handleAddOption(t, d, a)}
            // empty={emptyInput}
            // setEmptyInput={(i) => setEmptyInput(i)}
            result_successfull={result_successfull}
          />
        </>
      ) : (
        <>
          <div className="menu_list_header_container">
            <div>
              {optionList.length > 0 ? (
                <div style={{ marginBottom: "150px" }}>
                  {optionList.map((item, index) => (
                    <div key={index}>
                      {!item.IsDeleted ? (
                        <MenuListItem
                          field_number={2}
                          item_title={item.AppTitle}
                          item_price={item.Price}
                          field_1_title={item.AppTitle}
                          field_2_title={`£${item.Price}`}
                          btn_number={2}
                          bt_1_title={lang.edit}
                          bt_1_click={() => {
                            handleClick(item, 7);
                            dispatch(clickedItemAction(7));
                          }}
                          bt_2_title={lang.crust}
                          bt_2_click={() => {
                            handleClick(item, 10);
                            dispatch(selctedCategoryOptionCrust(null));
                            dispatch(clickedItemAction(10));
                          }}
                          show_delete_box={() => handleShowDeleteBox(item)}
                          cancel_delete_box={() => handleCancelDeleteBox(item)}
                          show_delete={true}
                          delete_click={() => handleDeleteOption(item.Id)}
                          show_edit_icon={true}
                          number={1}
                          selected={selected_category_option}
                          item={item}
                          click_edit_icon={() => handleEditIconClick(item)}
                          click_close_icon={() => handleCloseIcon(item)}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : (
                <h2 className="no-option">{lang.noOption}</h2>
              )}
            </div>
          </div>
          <AddMenuItem
            button_title={lang.addOption}
            buttonClick={(t, d, a) => handleAddOption(t, d, a)}
            // empty={emptyInput}
            setEmptyInput={(i) => setEmptyInput(i)}
            result_successfull={result_successfull}
          />
        </>
      )}
    </div>
  );
};

export default OptionsList;
