import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Loader from "../../../../components/Loader";
import { primaryColor } from "../../../../constants/Colors";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  getWebSettingAction,
  updateWebSettingAction,
} from "../../../../redux/settings/Action";
import { image_Url } from "../../../../constants/BaseUrl";
const Setting = forwardRef((props, forwardedRef) => {
  // const image_href = "http://37.32.4.85:";
  // const image_href = "http://45.131.139.146";
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    loading_appSettings,
    result_successfull,
    result_message,
    result_failed,
  } = useSelector((state) => state.appSettingsRedusers);
  const { web_setting_list } = useSelector(
    (state) => state.appSettingsRedusers
  );
  // const image_href = selected_shop[0].Domain;
  const [domain, setDomaun] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [appStoreUrl, setAppStoreUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [bannerTextLanguage, setBannerTextLanuage] = useState("");
  const [instgramUrl, setInstgramUrl] = useState("");
  const [googlePlayUrl, setGooglePlayUrl] = useState("");
  const [postCodeMessage, setPostCodeMessage] = useState("");
  const [src, setSrc] = useState("");
  const [url, setUrl] = useState("");
  const [href, setHref] = useState("");
  const [socialMediaImageUrl, setSocialMediaImageUrl] = useState("");
  const [socialMediaImageFile, setSocialMediaImageFile] = useState("");
  const [lastSocialMediaImageUrl, setLastSocialMediaImageUrl] = useState("");
  const [socialMediaImageDimensions, setSocialMediaImageDimensions] = useState(
    {}
  );
  const [favicon, setfavicon] = useState("");
  const [lastFavIconName, setLastFavIconName] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [lastBannerImageName, setLastBannerImageName] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [lastBackgroundImageName, setLastBackgroundImageName] = useState("");
  const [showBannerImage, setShowBannerImage] = useState(false);
  const [backgroundRepeat, setBackgroundRepeat] = useState(false);
  const [showBackgroundImage, setShowBackgroundImage] = useState(true);
  const [urlDimensions, setUrlDimensions] = useState({});
  const [hrefDimensions, setHrefDimensions] = useState({});
  const [srcDimensions, setSrcDimensions] = useState({});
  const [desctopBgImageDimensions, setDesctopBgImageDimensions] = useState({});
  const [mobileBgDimensions, setMobileBgDimensions] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  const [key1, setKey1] = useState("");
  const [key2, setKey2] = useState("");
  const [key3, setKey3] = useState("");
  const [key4, setKey4] = useState("");
  const [key5, setKey5] = useState("");
  const [key6, setKey6] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [desctopBackgroundImage, setDesctopBackgroundImage] = useState("");
  const [mobileBackgroundImage, setMobileBackgroundImage] = useState("");
  const [desctopBackgroundImageUrl, setDesctopBackgroundImageUrl] =
    useState("");
  const [mobileBackgroundImageUrl, setMobileBackgroundImageUrl] = useState("");
  const [lastDesctopBackgroundImageUrl, setLastDesctopBackgroundImageUrl] =
    useState("");
  const [lastMobileBackgroundImageUrl, setLastMobileBackgroundImageUrl] =
    useState("");

  const imageUrl = src;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl1 = url;
  const loadImage1 = () => {
    const img = new Image();
    img.src = imageUrl1;

    img.onload = () => {
      setUrlDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl2 = href;
  const loadImage2 = () => {
    const img = new Image();
    img.src = imageUrl2;

    img.onload = () => {
      setHrefDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl4 = desctopBackgroundImage;
  const loadDesctopBgImage = () => {
    const img = new Image();
    img.src = imageUrl4;

    img.onload = () => {
      setDesctopBgImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl5 = mobileBackgroundImage;
  const loadMobileBgImage = () => {
    const img = new Image();
    img.src = imageUrl5;

    img.onload = () => {
      setMobileBgDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl6 = socialMediaImageUrl;
  const loadSocialMediaImage = () => {
    const img = new Image();
    img.src = imageUrl6;

    img.onload = () => {
      setSocialMediaImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  let formData = new FormData();

  const handleEditWebSetting = () => {
    if (domain == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      /^https?:\/\//.test(domain) ||
      /\/$/.test(domain) ||
      /^www\./.test(domain)
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.domain_format_error}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (favicon != "" && favicon.size > 200000) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.fav_icon_size_error}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (favicon != "" && srcDimensions.width != srcDimensions.height) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.verify_with_height_in_fav_icon}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (favicon != "" && favicon.name.split(".")[1] != "ico") {
      toast.error(<h5> &nbsp;&nbsp;{lang.favicon_format}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (bannerImage != "" && bannerImage.size > 400000) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.banner_image_size_error}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      bannerImage != "" &&
      urlDimensions.width < urlDimensions.height * 2
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.verify_with_height_in_banner_image}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (backgroundImage != "" && backgroundImage.size > 400000) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.bachground_image_size_error}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      desctopBackgroundImageUrl != "" &&
      desctopBackgroundImageUrl.size > 1024000
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.desctop_background_image_size}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      desctopBackgroundImageUrl != "" &&
      !(
        desctopBgImageDimensions.width == 1920 &&
        desctopBgImageDimensions.height == 1080
      )
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.desctop_background_image_size_dimensions}
          &nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      mobileBackgroundImageUrl != "" &&
      mobileBackgroundImageUrl.size > 1024000
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.mobile_background_image_size}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      mobileBackgroundImageUrl != "" &&
      !(mobileBgDimensions.width == 1080 && mobileBgDimensions.height == 1920)
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.mobile_background_image_size_dimenssions}
          &nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      socialMediaImageFile != "" &&
      socialMediaImageFile.size > 1024000
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.social_media_image_size}
          &nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      socialMediaImageFile != "" &&
      !(
        socialMediaImageDimensions.width == 1200 &&
        socialMediaImageDimensions.height == 630
      )
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.social_media_size_dimenssions}
          &nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("ShopId", selected_shop[0].ShopId);
      formData.append("MailProfile", "333");
      formData.append("Domain", domain);
      formData.append("FavIcom", favicon ? favicon : "");
      formData.append("BannerText", bannerText ? bannerText : "");
      formData.append(
        "BannerTextL2",
        bannerTextLanguage ? bannerTextLanguage : ""
      );
      formData.append(
        "BannerImage",
        bannerImage
          ? bannerImage.name.split(".")[1] == "gif"
            ? require(bannerImage)
            : bannerImage
          : ""
      );
      formData.append("ShowBannerImage", showBannerImage ? 1 : 0);
      formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
      formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
      formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
      formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
      formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
      formData.append("PostcodeMessage", postCodeMessage);
      formData.append(
        "BackgroundImage",
        backgroundImage ? backgroundImage : ""
      );
      formData.append("ShowBackgroundImage", showBackgroundImage ? 1 : 0);
      formData.append("BackgroundRepeat", backgroundRepeat ? 1 : 0);
      formData.append(
        "LastFavIcomName",
        lastFavIconName ? lastFavIconName : ""
      );
      formData.append(
        "LastBannerImageName",
        lastBannerImageName ? lastBannerImageName : ""
      );
      formData.append(
        "LastBackgroundImageName",
        lastBackgroundImageName ? lastBackgroundImageName : ""
      );
      formData.append("DeleteImage", 0);
      formData.append("SubDomain", subdomain ? subdomain : "");

      formData.append(
        "LpBackgroundImage",
        desctopBackgroundImageUrl ? desctopBackgroundImageUrl : ""
      );
      formData.append(
        "LpMobileBackgroundImage",
        mobileBackgroundImageUrl ? mobileBackgroundImageUrl : ""
      );
      formData.append(
        "LastLpBackgroundImage",
        lastDesctopBackgroundImageUrl ? lastDesctopBackgroundImageUrl : ""
      );
      formData.append(
        "LastLpMobileBackgroundImage",
        lastMobileBackgroundImageUrl ? lastMobileBackgroundImageUrl : ""
      );
      formData.append(
        "SocialMediaImage",
        socialMediaImageFile ? socialMediaImageFile : ""
      );
      formData.append(
        " LastSocialMediaImage",
        lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
      );

      dispatch(
        updateWebSettingAction(
          formData,
          login_key,
          user_id,
          selected_shop[0].ShopId,
          "333",
          domain,
          favicon ? favicon : "",
          bannerText,
          bannerTextLanguage,
          bannerImage ? bannerImage : "",
          showBannerImage ? 1 : 0,
          facebookUrl,
          twitterUrl,
          instgramUrl,
          appStoreUrl,
          googlePlayUrl,
          postCodeMessage,
          backgroundImage ? backgroundImage : "",
          showBackgroundImage ? 1 : 0,
          backgroundRepeat ? 1 : 0,
          lastFavIconName,
          lastBannerImageName,
          lastBackgroundImageName
        )
      );
    }
  };

  const handleDeleteImageAndUpdate = (key) => {
    if (key == 1) {
      if (key1) {
        key1.target.value = null;
      }
      setSrc("");
      setfavicon("");
      if (web_setting_list[0].FavIcom != "") {
        if (`${image_Url}${baseUrl}/${web_setting_list[0].FavIcom}` != src) {
          setSrc(`${image_Url}${baseUrl}/${web_setting_list[0].FavIcom}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("MailProfile", "333");
          formData.append("Domain", domain);
          formData.append("FavIcom", "");
          formData.append("BannerText", bannerText ? bannerText : "");
          formData.append(
            "BannerTextL2",
            bannerTextLanguage ? bannerTextLanguage : ""
          );
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append("ShowBannerImage", showBannerImage ? 1 : 0);
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append("PostcodeMessage", postCodeMessage);
          formData.append(
            "BackgroundImage",
            backgroundImage ? backgroundImage : ""
          );
          formData.append("ShowBackgroundImage", showBackgroundImage ? 1 : 0);
          formData.append("BackgroundRepeat", backgroundRepeat ? 1 : 0);
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBackgroundImageName",
            lastBackgroundImageName ? lastBackgroundImageName : ""
          );
          formData.append("DeleteImage", key);
          formData.append("SubDomain", subdomain ? subdomain : "");
          formData.append(
            "LpBackgroundImage",
            desctopBackgroundImageUrl ? desctopBackgroundImageUrl : ""
          );
          formData.append(
            "LpMobileBackgroundImage",
            mobileBackgroundImageUrl ? mobileBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpBackgroundImage",
            lastDesctopBackgroundImageUrl ? lastDesctopBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpMobileBackgroundImage",
            lastMobileBackgroundImageUrl ? lastMobileBackgroundImageUrl : ""
          );
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          dispatch(
            updateWebSettingAction(
              formData,
              login_key,
              user_id,
              selected_shop[0].ShopId,
              "333",
              domain,
              favicon ? favicon : "",
              bannerText,
              bannerTextLanguage,
              bannerImage ? bannerImage : "",
              showBannerImage ? 1 : 0,
              facebookUrl,
              twitterUrl,
              instgramUrl,
              appStoreUrl,
              googlePlayUrl,
              postCodeMessage,
              backgroundImage ? backgroundImage : "",
              showBackgroundImage ? 1 : 0,
              backgroundRepeat ? 1 : 0,
              lastFavIconName,
              lastBannerImageName,
              lastBackgroundImageName
            )
          );
        }
      }
    } else if (key == 2) {
      if (key2) {
        key2.target.value = null;
      }

      setUrl("");
      setBannerImage("");
      if (web_setting_list[0].BannerImage != "") {
        if (
          `${image_Url}${baseUrl}/${web_setting_list[0].BannerImage}` != url
        ) {
          setUrl(`${image_Url}${baseUrl}/${web_setting_list[0].BannerImage}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("MailProfile", "333");
          formData.append("Domain", domain);
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", bannerText ? bannerText : "");
          formData.append(
            "BannerTextL2",
            bannerTextLanguage ? bannerTextLanguage : ""
          );
          formData.append("BannerImage", "");
          formData.append("ShowBannerImage", showBannerImage ? 1 : 0);
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append("PostcodeMessage", postCodeMessage);
          formData.append(
            "BackgroundImage",
            backgroundImage ? backgroundImage : ""
          );
          formData.append("ShowBackgroundImage", showBackgroundImage ? 1 : 0);
          formData.append("BackgroundRepeat", backgroundRepeat ? 1 : 0);
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBackgroundImageName",
            lastBackgroundImageName ? lastBackgroundImageName : ""
          );
          formData.append("DeleteImage", key);
          formData.append("SubDomain", subdomain ? subdomain : "");
          formData.append(
            "LpBackgroundImage",
            desctopBackgroundImageUrl ? desctopBackgroundImageUrl : ""
          );
          formData.append(
            "LpMobileBackgroundImage",
            mobileBackgroundImageUrl ? mobileBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpBackgroundImage",
            lastDesctopBackgroundImageUrl ? lastDesctopBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpMobileBackgroundImage",
            lastMobileBackgroundImageUrl ? lastMobileBackgroundImageUrl : ""
          );
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          dispatch(
            updateWebSettingAction(
              formData,
              login_key,
              user_id,
              selected_shop[0].ShopId,
              "333",
              domain,
              favicon ? favicon : "",
              bannerText,
              bannerTextLanguage,
              bannerImage ? bannerImage : "",
              showBannerImage ? 1 : 0,
              facebookUrl,
              twitterUrl,
              instgramUrl,
              appStoreUrl,
              googlePlayUrl,
              postCodeMessage,
              backgroundImage ? backgroundImage : "",
              showBackgroundImage ? 1 : 0,
              backgroundRepeat ? 1 : 0,
              lastFavIconName,
              lastBannerImageName,
              lastBackgroundImageName
            )
          );
        }
      }
    } else if (key == 3) {
      if (key3) {
        key3.target.value = null;
      }
      setHref("");
      setBackgroundImage("");
      if (web_setting_list[0].BackgroundImage != "") {
        if (
          `${image_Url}${baseUrl}/${web_setting_list[0].BackgroundImage}` !=
          href
        ) {
          setHref(
            `${image_Url}${baseUrl}/${web_setting_list[0].BackgroundImage}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("MailProfile", "333");
          formData.append("Domain", domain);
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", bannerText ? bannerText : "");
          formData.append(
            "BannerTextL2",
            bannerTextLanguage ? bannerTextLanguage : ""
          );
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append("ShowBannerImage", showBannerImage ? 1 : 0);
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append("PostcodeMessage", postCodeMessage);
          formData.append("BackgroundImage", "");
          formData.append("ShowBackgroundImage", showBackgroundImage ? 1 : 0);
          formData.append("BackgroundRepeat", backgroundRepeat ? 1 : 0);
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBackgroundImageName",
            lastBackgroundImageName ? lastBackgroundImageName : ""
          );
          formData.append("DeleteImage", key);
          formData.append("SubDomain", subdomain ? subdomain : "");
          formData.append(
            "LpBackgroundImage",
            desctopBackgroundImageUrl ? desctopBackgroundImageUrl : ""
          );
          formData.append(
            "LpMobileBackgroundImage",
            mobileBackgroundImageUrl ? mobileBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpBackgroundImage",
            lastDesctopBackgroundImageUrl ? lastDesctopBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpMobileBackgroundImage",
            lastMobileBackgroundImageUrl ? lastMobileBackgroundImageUrl : ""
          );
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          dispatch(
            updateWebSettingAction(
              formData,
              login_key,
              user_id,
              selected_shop[0].ShopId,
              "333",
              domain,
              favicon ? favicon : "",
              bannerText,
              bannerTextLanguage,
              bannerImage ? bannerImage : "",
              showBannerImage ? 1 : 0,
              facebookUrl,
              twitterUrl,
              instgramUrl,
              appStoreUrl,
              googlePlayUrl,
              postCodeMessage,
              backgroundImage ? backgroundImage : "",
              showBackgroundImage ? 1 : 0,
              backgroundRepeat ? 1 : 0,
              lastFavIconName,
              lastBannerImageName,
              lastBackgroundImageName
            )
          );
        }
      }
    } else if (key == 4) {
      if (key4) {
        key4.target.value = null;
      }
      setDesctopBackgroundImage("");
      setDesctopBackgroundImageUrl("");
      if (web_setting_list[0].LpBackgroundImage != "") {
        if (
          `${image_Url}${baseUrl}/${web_setting_list[0].LpBackgroundImage}` !=
          desctopBackgroundImage
        ) {
          setDesctopBackgroundImage(
            `${image_Url}${baseUrl}/${web_setting_list[0].LpBackgroundImage}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("MailProfile", "333");
          formData.append("Domain", domain);
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", bannerText ? bannerText : "");
          formData.append(
            "BannerTextL2",
            bannerTextLanguage ? bannerTextLanguage : ""
          );
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append("ShowBannerImage", showBannerImage ? 1 : 0);
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append("PostcodeMessage", postCodeMessage);
          formData.append("BackgroundImage", "");
          formData.append("ShowBackgroundImage", showBackgroundImage ? 1 : 0);
          formData.append("BackgroundRepeat", backgroundRepeat ? 1 : 0);
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBackgroundImageName",
            lastBackgroundImageName ? lastBackgroundImageName : ""
          );
          formData.append("DeleteImage", key);
          formData.append("SubDomain", subdomain ? subdomain : "");
          formData.append("LpBackgroundImage", "");
          formData.append(
            "LpMobileBackgroundImage",
            mobileBackgroundImageUrl ? mobileBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpBackgroundImage",
            lastDesctopBackgroundImageUrl ? lastDesctopBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpMobileBackgroundImage",
            lastMobileBackgroundImageUrl ? lastMobileBackgroundImageUrl : ""
          );
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          dispatch(
            updateWebSettingAction(
              formData,
              login_key,
              user_id,
              selected_shop[0].ShopId,
              "333",
              domain,
              favicon ? favicon : "",
              bannerText,
              bannerTextLanguage,
              bannerImage ? bannerImage : "",
              showBannerImage ? 1 : 0,
              facebookUrl,
              twitterUrl,
              instgramUrl,
              appStoreUrl,
              googlePlayUrl,
              postCodeMessage,
              backgroundImage ? backgroundImage : "",
              showBackgroundImage ? 1 : 0,
              backgroundRepeat ? 1 : 0,
              lastFavIconName,
              lastBannerImageName,
              lastBackgroundImageName
            )
          );
        }
      }
    } else if (key == 5) {
      if (key5) {
        key5.target.value = null;
      }
      setMobileBackgroundImage("");
      setMobileBackgroundImageUrl("");
      if (web_setting_list[0].LpMobileBackgroundImage != "") {
        if (
          `${image_Url}${baseUrl}/${web_setting_list[0].LpMobileBackgroundImage}` !=
          mobileBackgroundImage
        ) {
          setMobileBackgroundImage(
            `${image_Url}${baseUrl}/${web_setting_list[0].LpMobileBackgroundImage}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("MailProfile", "333");
          formData.append("Domain", domain);
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", bannerText ? bannerText : "");
          formData.append(
            "BannerTextL2",
            bannerTextLanguage ? bannerTextLanguage : ""
          );
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append("ShowBannerImage", showBannerImage ? 1 : 0);
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append("PostcodeMessage", postCodeMessage);
          formData.append("BackgroundImage", "");
          formData.append("ShowBackgroundImage", showBackgroundImage ? 1 : 0);
          formData.append("BackgroundRepeat", backgroundRepeat ? 1 : 0);
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBackgroundImageName",
            lastBackgroundImageName ? lastBackgroundImageName : ""
          );
          formData.append("DeleteImage", key);
          formData.append("SubDomain", subdomain ? subdomain : "");
          formData.append(
            "LpBackgroundImage",
            desctopBackgroundImageUrl ? desctopBackgroundImageUrl : ""
          );
          formData.append("LpMobileBackgroundImage", "");
          formData.append(
            "LastLpBackgroundImage",
            lastDesctopBackgroundImageUrl ? lastDesctopBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpMobileBackgroundImage",
            lastMobileBackgroundImageUrl ? lastMobileBackgroundImageUrl : ""
          );
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          dispatch(
            updateWebSettingAction(
              formData,
              login_key,
              user_id,
              selected_shop[0].ShopId,
              "333",
              domain,
              favicon ? favicon : "",
              bannerText,
              bannerTextLanguage,
              bannerImage ? bannerImage : "",
              showBannerImage ? 1 : 0,
              facebookUrl,
              twitterUrl,
              instgramUrl,
              appStoreUrl,
              googlePlayUrl,
              postCodeMessage,
              backgroundImage ? backgroundImage : "",
              showBackgroundImage ? 1 : 0,
              backgroundRepeat ? 1 : 0,
              lastFavIconName,
              lastBannerImageName,
              lastBackgroundImageName
            )
          );
        }
      }
    } else if (key == 6) {
      if (key6) {
        key6.target.value = null;
      }
      setSocialMediaImageFile("");
      setSocialMediaImageUrl("");
      if (web_setting_list[0].SocialMediaImage != "") {
        if (
          `${image_Url}${baseUrl}/${web_setting_list[0].SocialMediaImage}` !=
          socialMediaImageUrl
        ) {
          setSocialMediaImageUrl(
            `${image_Url}${baseUrl}/${web_setting_list[0].SocialMediaImage}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("MailProfile", "333");
          formData.append("Domain", domain);
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", bannerText ? bannerText : "");
          formData.append(
            "BannerTextL2",
            bannerTextLanguage ? bannerTextLanguage : ""
          );
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append("ShowBannerImage", showBannerImage ? 1 : 0);
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append("PostcodeMessage", postCodeMessage);
          formData.append("BackgroundImage", "");
          formData.append("ShowBackgroundImage", showBackgroundImage ? 1 : 0);
          formData.append("BackgroundRepeat", backgroundRepeat ? 1 : 0);
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBackgroundImageName",
            lastBackgroundImageName ? lastBackgroundImageName : ""
          );
          formData.append("DeleteImage", key);
          formData.append("SubDomain", subdomain ? subdomain : "");
          formData.append(
            "LpBackgroundImage",
            desctopBackgroundImageUrl ? desctopBackgroundImageUrl : ""
          );
          formData.append("LpMobileBackgroundImage", "");
          formData.append(
            "LastLpBackgroundImage",
            lastDesctopBackgroundImageUrl ? lastDesctopBackgroundImageUrl : ""
          );
          formData.append(
            "LastLpMobileBackgroundImage",
            lastMobileBackgroundImageUrl ? lastMobileBackgroundImageUrl : ""
          );
          formData.append("SocialMediaImage", "");
          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          dispatch(
            updateWebSettingAction(
              formData,
              login_key,
              user_id,
              selected_shop[0].ShopId,
              "333",
              domain,
              favicon ? favicon : "",
              bannerText,
              bannerTextLanguage,
              bannerImage ? bannerImage : "",
              showBannerImage ? 1 : 0,
              facebookUrl,
              twitterUrl,
              instgramUrl,
              appStoreUrl,
              googlePlayUrl,
              postCodeMessage,
              backgroundImage ? backgroundImage : "",
              showBackgroundImage ? 1 : 0,
              backgroundRepeat ? 1 : 0,
              lastFavIconName,
              lastBannerImageName,
              lastBackgroundImageName
            )
          );
        }
      }
    }
  };
  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleEditWebSetting();
    },
  }));
  const onSelectSrc = (e) => {
    setfavicon(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }
    setKey1(e);
  };

  const onSelectUrl = (e) => {
    setBannerImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
    setKey2(e);
  };

  const onSelectHref = (e) => {
    setBackgroundImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    setKey3(e);
  };
  const onSelectDesctopBackgroundImage = (e) => {
    setDesctopBackgroundImageUrl(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setDesctopBackgroundImage(URL.createObjectURL(e.target.files[0]));
    }
    setKey4(e);
  };
  const onSelectMobileBackgroundImage = (e) => {
    setMobileBackgroundImageUrl(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setMobileBackgroundImage(URL.createObjectURL(e.target.files[0]));
    }
    setKey5(e);
  };
  const onSelectSocialMediaImageUrl = (e) => {
    setSocialMediaImageFile(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSocialMediaImageUrl(URL.createObjectURL(e.target.files[0]));
    }
    setKey6(e);
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      const image_href = selected_shop[0].Domain;
      // const image_href = selected_shop[0].SubDomain;
      dispatch(
        getWebSettingAction(login_key, user_id, selected_shop[0].ShopId)
      );
      setBaseUrl(image_href);
    }
  }, [selected_shop]);
  useEffect(() => {
    if (web_setting_list.length > 0) {
      if (web_setting_list[0].Domain) {
        setDomaun(web_setting_list[0].Domain);
      } else {
        setDomaun("");
      }
      if (web_setting_list[0].BannerText) {
        setBannerText(web_setting_list[0].BannerText);
      } else {
        setBannerText("");
      }
      if (web_setting_list[0].TwitterUrl) {
        setTwitterUrl(web_setting_list[0].TwitterUrl);
      } else {
        setTwitterUrl("");
      }

      if (web_setting_list[0].AppStoreUrl) {
        setAppStoreUrl(web_setting_list[0].AppStoreUrl);
      } else {
        setAppStoreUrl("");
      }
      if (web_setting_list[0].FacebookUrl) {
        setFacebookUrl(web_setting_list[0].FacebookUrl);
      } else {
        setFacebookUrl("");
      }
      if (web_setting_list[0].BannerTextL2) {
        setBannerTextLanuage(web_setting_list[0].BannerTextL2);
      } else {
        setBannerTextLanuage("");
      }
      if (web_setting_list[0].InstagramUrl) {
        setInstgramUrl(web_setting_list[0].InstagramUrl);
      } else {
        setInstgramUrl("");
      }
      if (web_setting_list[0].GooglePlayUrl) {
        setGooglePlayUrl(web_setting_list[0].GooglePlayUrl);
      } else {
        setGooglePlayUrl("");
      }
      if (web_setting_list[0].PostcodeMessage) {
        setPostCodeMessage(web_setting_list[0].PostcodeMessage);
      } else {
        setPostCodeMessage("");
      }
      {
        web_setting_list[0].FavIcom
          ? setSrc(`${image_Url}${baseUrl}/${web_setting_list[0].FavIcom}`)
          : setSrc("");
      }
      {
        web_setting_list[0].BannerImage
          ? setUrl(`${image_Url}${baseUrl}/${web_setting_list[0].BannerImage}`)
          : setUrl("");
      }
      {
        web_setting_list[0].BackgroundImage
          ? setHref(
              `${image_Url}${baseUrl}/${web_setting_list[0].BackgroundImage}`
            )
          : setHref("");
      }
      setShowBannerImage(web_setting_list[0].ShowBannerImage);
      setBackgroundRepeat(web_setting_list[0].BackgroundRepeat);
      setShowBackgroundImage(web_setting_list[0].ShowBackgroundImage);
      setLastBackgroundImageName(web_setting_list[0].BackgroundImage);
      setLastBannerImageName(web_setting_list[0].BannerImage);
      setLastFavIconName(web_setting_list[0].FavIcom);
      if (web_setting_list[0].SubDomain) {
        setSubdomain(web_setting_list[0].SubDomain);
      } else {
        setSubdomain("");
      }
      if (web_setting_list[0].LpBackgroundImage) {
        setDesctopBackgroundImage(
          `${image_Url}${baseUrl}/${web_setting_list[0].LpBackgroundImage}`
        );
        setLastDesctopBackgroundImageUrl(web_setting_list[0].LpBackgroundImage);
      } else {
        setDesctopBackgroundImage("");
        setLastDesctopBackgroundImageUrl("");
      }
      if (web_setting_list[0].LpMobileBackgroundImage) {
        setMobileBackgroundImage(
          `${image_Url}${baseUrl}/${web_setting_list[0].LpMobileBackgroundImage}`
        );
        setLastMobileBackgroundImageUrl(
          web_setting_list[0].LpMobileBackgroundImage
        );
      } else {
        setMobileBackgroundImage("");
        setLastMobileBackgroundImageUrl("");
      }
      if (web_setting_list[0].SocialMediaImage) {
        setSocialMediaImageUrl(
          `${image_Url}${baseUrl}/${web_setting_list[0].SocialMediaImage}`
        );
        setLastSocialMediaImageUrl(web_setting_list[0].SocialMediaImage);
      } else {
        setSocialMediaImageUrl("");
        setLastSocialMediaImageUrl("");
      }
    }
  }, [web_setting_list]);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleEditWebSetting();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    domain,
    favicon,
    bannerText,
    bannerTextLanguage,
    backgroundImage,
    showBannerImage,
    facebookUrl,
    twitterUrl,
    instgramUrl,
    appStoreUrl,
    googlePlayUrl,
    postCodeMessage,
    backgroundImage,
    showBackgroundImage,
    backgroundRepeat,
    lastBackgroundImageName,
    lastBannerImageName,
    lastFavIconName,
    desctopBackgroundImageUrl,
    mobileBackgroundImageUrl,
  ]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container ">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">
                * {lang.domain}(
                <span style={{ color: "grey", fontSize: "14px" }}>
                  example.com
                </span>
                )
              </h6>
              <input
                className="ASOP-input-text input-padding"
                value={domain}
                onChange={(e) => setDomaun(e.target.value)}
                placeholder="example.com"
              />
              <span style={{ color: "grey", fontSize: "11.3px" }}>
                Not using http:// or https:// in the prefix and / in the suffix
              </span>
            </div>
            {/* <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.banner_text}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={bannerText}
                onChange={(e) => setBannerText(e.target.value)}
              />
            </div> */}
            {/* <div className="ASOP-div1-input-text">
              <h6 className="h6">
                {" "}
                * {lang.sub_domain}(
                <span style={{ color: "grey", fontSize: "14px" }}>
                  example.com
                </span>
                )
              </h6>
              <input
                className="ASOP-input-text input-padding"
                value={subdomain}
                onChange={(e) => setSubdomain(e.target.value)}
              />
              <span style={{ color: "grey", fontSize: "11.3px" }}>
                Not using http:// or https:// in the prefix and / in the suffix
              </span>
            </div> */}
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.twitter_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={twitterUrl}
                onChange={(e) => setTwitterUrl(e.target.value)}
              />
            </div>{" "}
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.post_code_message}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={postCodeMessage}
                onChange={(e) => setPostCodeMessage(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.app_store_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={appStoreUrl}
                onChange={(e) => setAppStoreUrl(e.target.value)}
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.facebook_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={facebookUrl}
                onChange={(e) => setFacebookUrl(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.banner_text_language}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={bannerTextLanguage}
                onChange={(e) => setBannerTextLanuage(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.instgram_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={instgramUrl}
                onChange={(e) => setInstgramUrl(e.target.value)}
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.google_play_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={googlePlayUrl}
                onChange={(e) => setGooglePlayUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.favicon}</h6>
              <div style={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectSrc(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {src ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(1)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    src
                      ? src
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {lang.image_size}{" "}
                <span style={{ marginLeft: "20px" }}>
                  {lang.fav_icon_dimensions}
                </span>
              </p>
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.banner_image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectUrl(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {url ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(2)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    url
                      ? url
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  // src="https://animated-gif-creator.com/images/03/solar-system-animation-in-powerpoint-animated-presentation_1.gif"
                  className="images-style"
                  onLoad={loadImage1}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {lang.banner_image_size}{" "}
                <span style={{ marginLeft: "20px" }}>
                  {lang.banner_image_dimensions}
                </span>
              </p>
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.banner_mobile}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectHref(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {href ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(3)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    href
                      ? href
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage2}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">{lang.banner_image_size}</p>
            </div>
          </div>
          <div className="ASOP-div1">
            <div className=" div-chechbox-p ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={backgroundRepeat}
                      onChange={() => setBackgroundRepeat(!backgroundRepeat)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.background_repeact}
                />
              </FormGroup>
            </div>
            <div className=" div-chechbox-p ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBannerImage}
                      onChange={() => setShowBannerImage(!showBannerImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_banner_image}
                />
              </FormGroup>
            </div>
            <div className=" div-chechbox-p ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBackgroundImage}
                      onChange={() =>
                        setShowBackgroundImage(!showBackgroundImage)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_mobile_banner_image}
                />
              </FormGroup>
            </div>
          </div>{" "}
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.social_media_image}</h6>
              <div style={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectSocialMediaImageUrl(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {socialMediaImageUrl ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(6)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    socialMediaImageUrl
                      ? socialMediaImageUrl
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadSocialMediaImage}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {" "}
                {lang.desctop_and_mobile_size}{" "}
                <span style={{ marginLeft: "20px" }}>
                  {" "}
                  {lang.social_media_dimensions}
                </span>
              </p>
            </div>
          </div>
          <div className="list_header_container-general">
            <div>
              <h6 id="general_text" style={{ margin: 0 }}>
                {lang.landing_page}
              </h6>
            </div>
          </div>
          {/* <div className="web-setting-container-landing-page">
            <h5>{lang.landing_page}</h5> */}
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6">{lang.background_image}</h6>
              <div style={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectDesctopBackgroundImage(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {desctopBackgroundImage ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(4)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    desctopBackgroundImage
                      ? desctopBackgroundImage
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadDesctopBgImage}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {lang.desctop_and_mobile_size}
                <span style={{ marginLeft: "20px" }}>
                  {" "}
                  {lang.desctop_background_dimensions}
                </span>
              </p>
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.mobile_bg_image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectMobileBackgroundImage(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {mobileBackgroundImage ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(5)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    mobileBackgroundImage
                      ? mobileBackgroundImage
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadMobileBgImage}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {lang.desctop_and_mobile_size}{" "}
                <span style={{ marginLeft: "20px" }}>
                  {lang.mobile_background_dimensions}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* {loading_appSettings ? <Loader /> : null} */}
    </div>
  );
});

export default Setting;
