import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import store from "store2";
import * as action_types from "../ActionTypes";
import {
  loginService,
  updateProfileService,
  sendOTPLoginService,
  verifyOTPLoginService,
  activeTwoFactorService,
} from "../../services/LoginServices";
import {
  loginSuccessfull,
  loginFailed,
  updateProfileSuccessfullAction,
  updateProfileFailedAction,
  receiveLoginResponse,
  sendOTPLoginFailedAction,
  sendOTPLoginSuccessfullAction,
  verifyOTPLoginSuccessfullAction,
  verifyOTPLoginFailedAction,
  activeTwoFactorAuthFailedAction,
  activeTwoFactorAuthSuccessfullAction,
} from "./Actions";

export function* login({ payload }) {
  try {
    const response = yield call(
      loginService,
      payload.lat,
      payload.long,
      payload.user_name,
      payload.password
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.UserAccesses) {
          store.set("login_response", response.data);

          yield put(loginSuccessfull(response.data));
        } else {
          store.remove("login_response");
          yield put(loginFailed("No access defined for this user."));
        }
      } else {
        store.remove("login_response");
        yield put(loginFailed(response.data.ResultMessage));
      }
    } else {
      store.remove("login_response");
      yield put(loginFailed(response.data.ResultMessage));
    }
  } catch (error) {
    store.remove("login_response");

    yield put(loginFailed(error.message));
  }
}

function* updateProfile({ payload }) {
  try {
    const response = yield call(
      updateProfileService,
      payload.login_key,
      payload.profile_image,
      payload.id,
      payload.first_name,
      payload.last_name,
      payload.mobile,
      payload.address,
      payload.lastImageProfileName
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateProfileSuccessfullAction(response.data.ResultMessage));
        if (response.data.user_info) {
          if (response.data.user_info.length > 0) {
            yield put(receiveLoginResponse(response.data.user_info));
          }
        }
      } else {
        yield put(updateProfileFailedAction());
      }
    } else {
      yield put(updateProfileFailedAction());
    }
  } catch (error) {
    yield put(updateProfileFailedAction(error.message));
  }
}
function* sendOTPLogin({ payload }) {
  try {
    const response = yield call(
      sendOTPLoginService,
      payload.login_key,
      payload.user_id,
      payload.mobile
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(sendOTPLoginSuccessfullAction(response.data.ResultMessage));
      } else {
        yield put(sendOTPLoginFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(sendOTPLoginFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(sendOTPLoginFailedAction(error.message));
  }
}

function* verifyOTPLogin({ payload }) {
  try {
    const response = yield call(
      verifyOTPLoginService,
      payload.login_key,
      payload.user_id,
      payload.mobile,
      payload.OTP_code
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(verifyOTPLoginSuccessfullAction(response.data.ResultMessage));
      } else {
        yield put(verifyOTPLoginFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(verifyOTPLoginFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(verifyOTPLoginFailedAction(error.message));
  }
}
function* activeTwoFactor({ payload }) {
  try {
    const response = yield call(
      activeTwoFactorService,
      payload.login_key,
      payload.user_id,
      payload.active_two_factor
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          activeTwoFactorAuthSuccessfullAction(
            "2-step authentication is enabled"
          )
        );
      } else {
        yield put(activeTwoFactorAuthFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(activeTwoFactorAuthFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(activeTwoFactorAuthFailedAction(error.message));
  }
}
export function* watchActiveTwoFactor() {
  yield takeLatest(action_types.ACTIVE_TWO_FACTOR_AUTH, activeTwoFactor);
}
export function* watchVerifyOTPLogin() {
  yield takeLatest(action_types.VERIFY_OTP_LOGIN, verifyOTPLogin);
}
export function* watchSendOTPLogin() {
  yield takeLatest(action_types.SEND_OPT_LOGIN, sendOTPLogin);
}
export function* watchUpdateProfile() {
  yield takeLatest(action_types.UPDATE_PROFILE, updateProfile);
}
export function* watchLogin() {
  yield takeLatest(action_types.LOGIN, login);
}
export default function* rootLogin() {
  yield all([
    fork(watchLogin),
    fork(watchUpdateProfile),
    fork(watchSendOTPLogin),
    fork(watchVerifyOTPLogin),
    fork(watchActiveTwoFactor),
  ]);
}
