import React, { useRef, useState, forwardRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ListHeader from "../components/ListHeader";
import SaveMenuButton from "../components/SaveMenuButton";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import { Availability } from "../constants/DropDownList";
import {
  number_of_row_culmn,
  number_of_culmn,
} from "../constants/NumberOfRowAndCulmn";
import { sendProductSettingsAction } from "../redux/product/Action";
import { image_Url } from "../constants/BaseUrl";
import { showProductAvailabilityPopupAction } from "../redux/product/Action";
const ProductsEdit = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef(false);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    category_list,
    selected_category,
    show_hide,
    printer_list,
    report_section,
    product_group,
    vat,
    font_family,
    font_size,
    link_to,
    result_successfull,
  } = useSelector((state) => state.categoryList);
  const { product_list, selected_Product } = useSelector(
    (state) => state.productList
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    selected_submenu,
    submenu_list,
    fixed_submenu_list,
    fixed_submenu_ids,
  } = useSelector((state) => state.submenuRedusers);
  // const image_href = "http://37.32.4.85:";

  const [src, setSrc] = useState(null);
  const [url, setUrl] = useState(null);
  const [href, setHref] = useState(null);

  const [defaultVatForAllOption, setDefaultVatForAllOption] = useState(true);
  const [appTitle, setAppTitle] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [kioskTitle, setKioskTitle] = useState("");
  const [kioskDesc, setKioskDesc] = useState("");
  const [width, setwidth] = useState(16);
  const [height, setHeight] = useState(20);
  const [appSort, setAppsort] = useState(1);
  const [webSort, setWebsort] = useState(1);
  const [kioskSort, setKiosksort] = useState(1);
  const [fontSize, setFontSize] = useState(null);
  const [submenusFontSize, setSubmenusFontSize] = useState(null);
  const [fontFamily, setFontFamily] = useState(null);
  const [closeWhen, setCloseWhen] = useState(0);
  const [secondLanguage, setSecondLanguage] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#f5a623");
  const [titleColor, setTitleColor] = useState("#bd10e0");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const [mainPrice, setMainPrice] = useState(0);
  const [collectionPrice, setCollectionPrice] = useState(0);
  const [takeawayPrice, setTakeawayPrice] = useState(0);
  const [eatInPrice, setEatInPrice] = useState(0);
  const [appImage, setAppImage] = useState("");
  const [showAppImage, setShowAppImage] = useState(false);
  const [webImage, setWebImage] = useState("");
  const [showWebImage, setShowWebImage] = useState(false);
  const [kioskImage, setkioskImage] = useState("");
  const [showKioskImage, setShowKioskImage] = useState(false);
  const [selectedVat, setSelectedVat] = useState(null);
  const [reportSection, setReportSection] = useState(null);
  const [selectedPrinterList, setSelectedPrinterList] = useState([]);
  const [productGroup, setProductGroup] = useState(null);
  const [customTitle, setCustomTitle] = useState("");
  const [customTitleInLast, setCustomTitleInLast] = useState(false);
  const [titleInLast, setTitleInLast] = useState(false);
  const [printLabel, setPrintLabel] = useState(false);
  const [suggested, setSuggested] = useState(false);
  const [popular, setPopular] = useState(false);
  const [automaticallyDisplaySubmenu, setAutomaticallyDisplaySubmenu] =
    useState(false);
  const [excludeAutoDiscount, setExcludeAutoDiscount] = useState(false);
  const [linkto, setLinkto] = useState(0);
  const [autoDisplaySubMenu, setAutoDisplaySubMenu] = useState(false);
  const [appShowHideValues, setAppShowHideValues] = useState([]);
  const [webShowHideValues, setWebShowHideValues] = useState([]);
  const [kioskShowHideValues, setKioskShowHideValues] = useState([]);
  const [lastAppImageName, setLastAppImageName] = useState("");
  const [lastWebImageName, setLastWebImageName] = useState("");
  const [lastKioskImageName, setLastKiokImageName] = useState("");
  const [numberOfSubmenusRow, setNumberOfSubmenusRow] = useState(null);
  const [numberOfSubmenusCulmn, setNumberOfSubmenusCulmn] = useState(null);

  const [srcDimensions, setSrcDimensions] = useState({});
  const [urlDimensions, setUrlDimensions] = useState({});
  const [hrefDimensions, setHrefDimensions] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  const [calories, setCalories] = useState(0);
  const [key1, setKey1] = useState("");
  const [key2, setKey2] = useState("");
  const [key3, setKey3] = useState("");
  const [availability, setAvailability] = useState({
    value: 1,
    label: "Always",
  });
  const [fixedItemList, setFixedItemList] = useState([]);
  const [selectedFixedItem, setSelectedFixedItem] = useState(null);

  const [deleteImageStatus, setDeleteImageStatus] = useState(false);
  // const image_href = selected_shop[0].SubDomain;
  const image_href = selected_shop[0].Domain;

  const imageUrl = src;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl1 = href;
  const loadImage1 = () => {
    const img = new Image();
    img.src = imageUrl1;

    img.onload = () => {
      setUrlDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const imageUrl2 = href;
  const loadImage2 = () => {
    const img = new Image();
    img.src = imageUrl2;

    img.onload = () => {
      setHrefDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleInputBackgroundColor = (e) => {
    setBackgroundColor(e.target.value);
  };
  const handleInputTitleColor = (e) => {
    setTitleColor(e.target.value);
  };

  const handleClose = () => {
    setShowColorPicker(false);
  };

  const handleCloseInputColor = () => {
    setShowSketchPicker(false);
  };
  const handleChangeComplete = (color) => {
    setBackgroundColor(color.hex);
    document.getElementById("input_backgroundColor").value = color.hex;
  };

  const handleOnclickeInput = () => {
    setShowSketchPicker(!showSketchPicker);
    setShowColorPicker(false);
  };
  const handleChangeCompleteColor = (color) => {
    setTitleColor(color.hex);
    document.getElementById("inputColor").value = color.hex;
  };
  const handleOnclickeInputColor = () => {
    setShowColorPicker(!showColorPicker);
    setShowSketchPicker(false);
  };

  const onSelectFile = (e) => {
    setAppImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }
    setKey1(e);
  };
  const onSelectImage = (e) => {
    setWebImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
    setKey2(e);
  };
  const onSelectPicture = (e) => {
    setkioskImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    setKey3(e);
  };

  const handleProductAvailability = (list) => {
    setAvailability(list);
  };
  const formData = new FormData();
  const handleSaveClicked = () => {
    let app_visible_to_server_array = [];
    let web_visible_to_server_array = [];
    let kiosk_visible_to_server_array = [];
    let printer_list_to_server_array = [];

    if (appShowHideValues) {
      for (let i = 0; i < appShowHideValues.length; i++) {
        app_visible_to_server_array.push(appShowHideValues[i].value);
      }
    }
    if (webShowHideValues) {
      for (let i = 0; i < webShowHideValues.length; i++) {
        web_visible_to_server_array.push(webShowHideValues[i].value);
      }
    }
    if (kioskShowHideValues) {
      for (let i = 0; i < kioskShowHideValues.length; i++) {
        kiosk_visible_to_server_array.push(kioskShowHideValues[i].value);
      }
    }
    if (selectedPrinterList) {
      for (let i = 0; i < selectedPrinterList.length; i++) {
        printer_list_to_server_array.push(selectedPrinterList[i].value);
      }
    }
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (appImage.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.app_image_size}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (webImage.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.web_image_size}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (urlDimensions.width < urlDimensions.height) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.verify_with_height_in_webimage}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (kioskImage.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.kiosk_image_size}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (hrefDimensions.width < hrefDimensions.height) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.verify_with_height_in_kioskimage}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("Id", selected_Product.Id);
      formData.append("ShopId", selected_shop[0].ShopId);
      formData.append("AppTitle", appTitle);
      formData.append("AppDescription", appDesc);
      formData.append(
        "WebTitle",
        webTitle
          ? webTitle.replaceAll(`"`, `\"\"`)
          : appTitle.replaceAll(`"`, `\"\"`)
      );
      formData.append("WebDescription", webDesc);
      formData.append(
        "KioskTitle",
        kioskTitle
          ? kioskTitle.replaceAll(`"`, `\"\"`)
          : appTitle.replaceAll(`"`, `\"\"`)
      );
      formData.append("KioskDescription", kioskDesc);
      formData.append("SecondLanguage", secondLanguage);
      formData.append("AppVisible", app_visible_to_server_array.toString());
      formData.append("WebVisible", web_visible_to_server_array.toString());
      formData.append("KioskVisible", kiosk_visible_to_server_array.toString());
      formData.append("AmountDelivery", mainPrice ? parseFloat(mainPrice) : 0);
      formData.append(
        "AmountCollection",
        collectionPrice ? parseFloat(collectionPrice) : 0
      );
      formData.append(
        "AmountTakeaway",
        takeawayPrice ? parseFloat(takeawayPrice) : 0
      );
      formData.append("AmountEatIn", eatInPrice ? parseFloat(eatInPrice) : 0);
      formData.append("PrinterId", printer_list_to_server_array.toString());
      formData.append("ReportSection", reportSection ? reportSection.value : 0);
      formData.append("IsShowOptions", 0);
      formData.append("ProductGroup", productGroup ? productGroup.value : 0);
      formData.append("VatId", selectedVat ? selectedVat.value : 0);
      formData.append("IsSugessted", suggested ? 1 : 0);
      formData.append("IsPopular", popular ? 1 : 0);
      formData.append("IsDifOptionVat", 0);
      formData.append("UnitId", 0);
      formData.append("Barcode", "");
      formData.append("CustomTitle", customTitle.replaceAll(`"`, `\"\"`));
      formData.append("CloseCount", closeWhen ? parseInt(closeWhen) : 0);
      formData.append("CustomTitleInLast", customTitleInLast ? 1 : 0);
      formData.append("TitleInLast", titleInLast ? 1 : 0);
      formData.append("linkto", linkto ? linkto.value : 0);
      formData.append(
        "IsAutoDisplaySubMenu",
        automaticallyDisplaySubmenu ? 1 : 0
      );
      formData.append("PrintLabel", printLabel ? 1 : 0);
      formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
      formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
      formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
      formData.append("AppBgColor", backgroundColor);
      formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
      formData.append(
        "AppTitleFontFamily",
        fontFamily ? fontFamily.value.toString() : String(4)
      );
      formData.append("AppTitleColor", titleColor);

      formData.append(
        "NumberOfSubmenusColumn",
        numberOfSubmenusCulmn ? parseInt(numberOfSubmenusCulmn.value) : 4
      );
      formData.append(
        "NumberOfSubmenusRow",
        numberOfSubmenusRow ? parseInt(numberOfSubmenusRow.value) : 6
      );
      formData.append("AppImageUrl", appImage);
      formData.append("AppVisibleImage", showAppImage ? 1 : 0);
      formData.append("WebImageUrl", webImage);
      formData.append("WebVisibleImage", showWebImage ? 1 : 0);
      formData.append("KioskImageUrl", kioskImage);
      formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
      formData.append(
        "SetDefaultVatForAllOptions",
        defaultVatForAllOption ? 1 : 0
      );
      // formData.append("LastKioskImageUrlName", lastKioskImageName);
      formData.append("LastAppImageUrlName", lastAppImageName);
      formData.append("LastWebImageUrlName", lastWebImageName);
      formData.append(
        "NumberOfItemsColumn",
        numberOfSubmenusCulmn ? parseInt(numberOfSubmenusCulmn) : 4
      );
      formData.append(
        "NumberOfItemsRow",
        numberOfSubmenusRow ? parseInt(numberOfSubmenusRow) : 6
      );
      formData.append("JustTitleAndPrice", 0);
      formData.append(
        "SubmenusFontSize",
        submenusFontSize ? submenusFontSize.value : 9
      );
      formData.append("Calories", calories ? parseFloat(calories) : 0);
      formData.append("AlwaysOpen", availability.value == 0 ? 0 : 1);
      formData.append("DeleteImage", 0);
      formData.append(
        "FixedItemId",
        selectedFixedItem ? selectedFixedItem.value : 0
      );
      dispatch(
        sendProductSettingsAction(
          formData,
          selected_category.Id,
          login_key,
          user_id,
          selected_Product.Id,
          selected_shop[0].ShopId,
          0,
          appTitle.replaceAll(`"`, `\"\"`),
          appDesc.replaceAll(`"`, `\"\"`),
          webTitle.replaceAll(`"`, `\"\"`),
          webDesc.replaceAll(`"`, `\"\"`),
          kioskTitle.replaceAll(`"`, `\"\"`),
          kioskDesc.replaceAll(`"`, `\"\"`),
          secondLanguage.replaceAll(`"`, `\"\"`),
          app_visible_to_server_array.toString(),
          web_visible_to_server_array.toString(),
          kiosk_visible_to_server_array.toString(),
          mainPrice ? parseFloat(mainPrice) : 0,
          collectionPrice ? parseFloat(collectionPrice) : 0,
          takeawayPrice ? parseFloat(takeawayPrice) : 0,
          eatInPrice ? parseFloat(eatInPrice) : 0,
          selectedPrinterList ? printer_list_to_server_array.toString() : "",
          reportSection ? reportSection.value : 0,
          0,
          productGroup ? productGroup.value : 0,
          selectedVat ? selectedVat.value : 0,
          suggested ? 1 : 0,
          popular ? 1 : 0,
          0,
          0,
          "",
          customTitle.replaceAll(`"`, `\"\"`),
          closeWhen ? parseInt(closeWhen) : 0,
          customTitleInLast ? 1 : 0,
          titleInLast ? 1 : 0,
          linkto ? linkto.value : 0,
          automaticallyDisplaySubmenu ? 1 : 0,
          printLabel ? 1 : 0,
          appSort ? parseInt(appSort) : 0,
          webSort ? parseInt(webSort) : 0,
          kioskSort ? parseInt(kioskSort) : 0,
          backgroundColor,
          fontSize ? fontSize.value : 30,
          fontFamily ? fontFamily.value.toString() : String(4),
          titleColor,
          width ? parseInt(width) : 0,
          height ? parseInt(height) : 0,
          appImage,
          showAppImage ? 1 : 0,
          webImage,
          showWebImage ? 1 : 0,
          kioskImage,
          showKioskImage ? 1 : 0,
          defaultVatForAllOption ? 1 : 0
        )
      );
    }
  };

  const onSelectAppVisible = (list, item) => {
    if (item.action == "select-option") {
      if (item.option.value == 5) {
        setAppShowHideValues([{ value: 5, label: "All" }]);
      } else if (item.option.value == 0) {
        setAppShowHideValues([{ value: 0, label: "None" }]);
      } else {
        let id_list = [];
        for (let i = 0; i < list.length; i++) {
          id_list.push(list[i].value);
        }

        if (id_list.includes(5) || id_list.includes(0)) {
          setAppShowHideValues([item.option]);
        } else {
          setAppShowHideValues(list);
        }
      }
    } else if (item.action == "remove-value") {
      setAppShowHideValues(list);
    }
  };

  const onSelectWebVisible = (list, item) => {
    if (item.action == "select-option") {
      if (item.option.value == 5) {
        setWebShowHideValues([{ value: 5, label: "All" }]);
      } else if (item.option.value == 0) {
        setWebShowHideValues([{ value: 0, label: "None" }]);
      } else {
        let id_list = [];
        for (let i = 0; i < list.length; i++) {
          id_list.push(list[i].value);
        }

        if (id_list.includes(5) || id_list.includes(0)) {
          setWebShowHideValues([item.option]);
        } else {
          setWebShowHideValues(list);
        }
      }
    } else if (item.action == "remove-value") {
      setWebShowHideValues(list);
    }
  };
  const onSelectKioskVisible = (list, item) => {
    if (item.action == "select-option") {
      if (item.option.value == 5) {
        setKioskShowHideValues([{ value: 5, label: "All" }]);
      } else if (item.option.value == 0) {
        setKioskShowHideValues([{ value: 0, label: "None" }]);
      } else {
        let id_list = [];
        for (let i = 0; i < list.length; i++) {
          id_list.push(list[i].value);
        }

        if (id_list.includes(5) || id_list.includes(0)) {
          setKioskShowHideValues([item.option]);
        } else {
          setKioskShowHideValues(list);
        }
      }
    } else if (item.action == "remove-value") {
      setKioskShowHideValues(list);
    }
  };
  const handleDeleteImage = (key) => {
    let app_visible_to_server_array = [];
    let web_visible_to_server_array = [];
    let kiosk_visible_to_server_array = [];
    let printer_list_to_server_array = [];

    if (appShowHideValues) {
      for (let i = 0; i < appShowHideValues.length; i++) {
        app_visible_to_server_array.push(appShowHideValues[i].value);
      }
    }
    if (webShowHideValues) {
      for (let i = 0; i < webShowHideValues.length; i++) {
        web_visible_to_server_array.push(webShowHideValues[i].value);
      }
    }
    if (kioskShowHideValues) {
      for (let i = 0; i < kioskShowHideValues.length; i++) {
        kiosk_visible_to_server_array.push(kioskShowHideValues[i].value);
      }
    }
    if (selectedPrinterList) {
      for (let i = 0; i < selectedPrinterList.length; i++) {
        printer_list_to_server_array.push(selectedPrinterList[i].value);
      }
    }

    if (key == 1) {
      if (key1) {
        key1.target.value = null;
      }
      setSrc("");
      setAppImage("");
      if (selected_Product.AppImageUrl != "") {
        if (
          `${image_Url}${image_href}/${selected_Product.AppImageUrl}` != src
        ) {
          setSrc(`${image_Url}${image_href}/${selected_Product.AppImageUrl}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_Product.Id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("AppTitle", appTitle);
          formData.append("AppDescription", appDesc);
          formData.append(
            "WebTitle",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("WebDescription", webDesc);
          formData.append(
            "KioskTitle",
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("KioskDescription", kioskDesc);
          formData.append("SecondLanguage", secondLanguage);
          formData.append("AppVisible", app_visible_to_server_array.toString());
          formData.append("WebVisible", web_visible_to_server_array.toString());
          formData.append(
            "KioskVisible",
            kiosk_visible_to_server_array.toString()
          );
          formData.append(
            "AmountDelivery",
            mainPrice ? parseFloat(mainPrice) : 0
          );
          formData.append(
            "AmountCollection",
            collectionPrice ? parseFloat(collectionPrice) : 0
          );
          formData.append(
            "AmountTakeaway",
            takeawayPrice ? parseFloat(takeawayPrice) : 0
          );
          formData.append(
            "AmountEatIn",
            eatInPrice ? parseFloat(eatInPrice) : 0
          );
          formData.append("PrinterId", printer_list_to_server_array.toString());
          formData.append(
            "ReportSection",
            reportSection ? reportSection.value : 0
          );
          formData.append("IsShowOptions", 0);
          formData.append(
            "ProductGroup",
            productGroup ? productGroup.value : 0
          );
          formData.append("VatId", selectedVat ? selectedVat.value : 0);
          formData.append("IsSugessted", suggested ? 1 : 0);
          formData.append("IsPopular", popular ? 1 : 0);
          formData.append("IsDifOptionVat", 0);
          formData.append("UnitId", 0);
          formData.append("Barcode", "");
          formData.append("CustomTitle", customTitle.replaceAll(`"`, `\"\"`));
          formData.append("CloseCount", closeWhen ? parseInt(closeWhen) : 0);
          formData.append("CustomTitleInLast", customTitleInLast ? 1 : 0);
          formData.append("TitleInLast", titleInLast ? 1 : 0);
          formData.append("linkto", linkto ? linkto.value : 0);
          formData.append(
            "IsAutoDisplaySubMenu",
            automaticallyDisplaySubmenu ? 1 : 0
          );
          formData.append("PrintLabel", printLabel ? 1 : 0);
          formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData.append("AppBgColor", backgroundColor);
          formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
          formData.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData.append("AppTitleColor", titleColor);

          formData.append(
            "NumberOfSubmenusColumn",
            numberOfSubmenusCulmn ? parseInt(numberOfSubmenusCulmn.value) : 4
          );
          formData.append(
            "NumberOfSubmenusRow",
            numberOfSubmenusRow ? parseInt(numberOfSubmenusRow.value) : 6
          );
          formData.append("AppImageUrl", "");
          formData.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData.append("WebImageUrl", webImage ? webImage : "");
          formData.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData.append("KioskImageUrl", kioskImage ? kioskImage : "");
          formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData.append(
            "SetDefaultVatForAllOptions",
            defaultVatForAllOption ? 1 : 0
          );
          // formData.append("LastKioskImageUrlName", lastKioskImageName);
          formData.append("LastAppImageUrlName", lastAppImageName);
          formData.append("LastWebImageUrlName", lastWebImageName);
          formData.append(
            "NumberOfItemsColumn",
            numberOfSubmenusCulmn ? parseInt(numberOfSubmenusCulmn) : 4
          );
          formData.append(
            "NumberOfItemsRow",
            numberOfSubmenusRow ? parseInt(numberOfSubmenusRow) : 6
          );
          formData.append("JustTitleAndPrice", 0);
          formData.append(
            "SubmenusFontSize",
            submenusFontSize ? submenusFontSize.value : 9
          );
          formData.append("Calories", calories ? parseFloat(calories) : 0);
          formData.append("AlwaysOpen", availability.value == 0 ? 0 : 1);
          formData.append("DeleteImage", 1);
          formData.append(
            "FixedItemId",
            selectedFixedItem ? selectedFixedItem.value : 0
          );
          dispatch(
            sendProductSettingsAction(
              formData,
              selected_category.Id,
              login_key,
              user_id,
              selected_Product.Id,
              selected_shop[0].ShopId,
              0,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              app_visible_to_server_array.toString(),
              web_visible_to_server_array.toString(),
              kiosk_visible_to_server_array.toString(),
              mainPrice ? parseFloat(mainPrice) : 0,
              collectionPrice ? parseFloat(collectionPrice) : 0,
              takeawayPrice ? parseFloat(takeawayPrice) : 0,
              eatInPrice ? parseFloat(eatInPrice) : 0,
              selectedPrinterList
                ? printer_list_to_server_array.toString()
                : "",
              reportSection ? reportSection.value : 0,
              0,
              productGroup ? productGroup.value : 0,
              selectedVat ? selectedVat.value : 0,
              suggested ? 1 : 0,
              popular ? 1 : 0,
              0,
              0,
              "",
              customTitle.replaceAll(`"`, `\"\"`),
              closeWhen ? parseInt(closeWhen) : 0,
              customTitleInLast ? 1 : 0,
              titleInLast ? 1 : 0,
              linkto ? linkto.value : 0,
              automaticallyDisplaySubmenu ? 1 : 0,
              printLabel ? 1 : 0,
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              appImage,
              showAppImage ? 1 : 0,
              webImage,
              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0,
              defaultVatForAllOption ? 1 : 0
            )
          );
        }
      }
    } else if (key == 2) {
      if (key2) {
        key2.target.value = null;
      }

      setUrl("");
      setWebImage("");
      if (selected_Product.WebImageUrl != "") {
        if (
          `${image_Url}${image_href}/${selected_Product.WebImageUrl}` != url
        ) {
          setUrl(`${image_Url}${image_href}/${selected_Product.WebImageUrl}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_Product.Id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("AppTitle", appTitle);
          formData.append("AppDescription", appDesc);
          formData.append(
            "WebTitle",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("WebDescription", webDesc);
          formData.append(
            "KioskTitle",
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("KioskDescription", kioskDesc);
          formData.append("SecondLanguage", secondLanguage);
          formData.append("AppVisible", app_visible_to_server_array.toString());
          formData.append("WebVisible", web_visible_to_server_array.toString());
          formData.append(
            "KioskVisible",
            kiosk_visible_to_server_array.toString()
          );
          formData.append(
            "AmountDelivery",
            mainPrice ? parseFloat(mainPrice) : 0
          );
          formData.append(
            "AmountCollection",
            collectionPrice ? parseFloat(collectionPrice) : 0
          );
          formData.append(
            "AmountTakeaway",
            takeawayPrice ? parseFloat(takeawayPrice) : 0
          );
          formData.append(
            "AmountEatIn",
            eatInPrice ? parseFloat(eatInPrice) : 0
          );
          formData.append("PrinterId", printer_list_to_server_array.toString());
          formData.append(
            "ReportSection",
            reportSection ? reportSection.value : 0
          );
          formData.append("IsShowOptions", 0);
          formData.append(
            "ProductGroup",
            productGroup ? productGroup.value : 0
          );
          formData.append("VatId", selectedVat ? selectedVat.value : 0);
          formData.append("IsSugessted", suggested ? 1 : 0);
          formData.append("IsPopular", popular ? 1 : 0);
          formData.append("IsDifOptionVat", 0);
          formData.append("UnitId", 0);
          formData.append("Barcode", "");
          formData.append("CustomTitle", customTitle.replaceAll(`"`, `\"\"`));
          formData.append("CloseCount", closeWhen ? parseInt(closeWhen) : 0);
          formData.append("CustomTitleInLast", customTitleInLast ? 1 : 0);
          formData.append("TitleInLast", titleInLast ? 1 : 0);
          formData.append("linkto", linkto ? linkto.value : 0);
          formData.append(
            "IsAutoDisplaySubMenu",
            automaticallyDisplaySubmenu ? 1 : 0
          );
          formData.append("PrintLabel", printLabel ? 1 : 0);
          formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData.append("AppBgColor", backgroundColor);
          formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
          formData.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData.append("AppTitleColor", titleColor);

          formData.append(
            "NumberOfSubmenusColumn",
            numberOfSubmenusCulmn ? parseInt(numberOfSubmenusCulmn.value) : 4
          );
          formData.append(
            "NumberOfSubmenusRow",
            numberOfSubmenusRow ? parseInt(numberOfSubmenusRow.value) : 6
          );
          formData.append("AppImageUrl", appImage ? appImage : "");
          formData.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData.append("WebImageUrl", "");
          formData.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData.append("KioskImageUrl", kioskImage ? kioskImage : "");
          formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData.append(
            "SetDefaultVatForAllOptions",
            defaultVatForAllOption ? 1 : 0
          );
          // formData.append("LastKioskImageUrlName", lastKioskImageName);
          formData.append("LastAppImageUrlName", lastAppImageName);
          formData.append("LastWebImageUrlName", lastWebImageName);
          formData.append(
            "NumberOfItemsColumn",
            numberOfSubmenusCulmn ? parseInt(numberOfSubmenusCulmn) : 4
          );
          formData.append(
            "NumberOfItemsRow",
            numberOfSubmenusRow ? parseInt(numberOfSubmenusRow) : 6
          );
          formData.append("JustTitleAndPrice", 0);
          formData.append(
            "SubmenusFontSize",
            submenusFontSize ? submenusFontSize.value : 9
          );
          formData.append("Calories", calories ? parseFloat(calories) : 0);
          formData.append("AlwaysOpen", availability.value == 0 ? 0 : 1);
          formData.append("DeleteImage", 2);
          formData.append(
            "FixedItemId",
            selectedFixedItem ? selectedFixedItem.value : 0
          );
          dispatch(
            sendProductSettingsAction(
              formData,
              selected_category.Id,
              login_key,
              user_id,
              selected_Product.Id,
              selected_shop[0].ShopId,
              0,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              app_visible_to_server_array.toString(),
              web_visible_to_server_array.toString(),
              kiosk_visible_to_server_array.toString(),
              mainPrice ? parseFloat(mainPrice) : 0,
              collectionPrice ? parseFloat(collectionPrice) : 0,
              takeawayPrice ? parseFloat(takeawayPrice) : 0,
              eatInPrice ? parseFloat(eatInPrice) : 0,
              selectedPrinterList
                ? printer_list_to_server_array.toString()
                : "",
              reportSection ? reportSection.value : 0,
              0,
              productGroup ? productGroup.value : 0,
              selectedVat ? selectedVat.value : 0,
              suggested ? 1 : 0,
              popular ? 1 : 0,
              0,
              0,
              "",
              customTitle.replaceAll(`"`, `\"\"`),
              closeWhen ? parseInt(closeWhen) : 0,
              customTitleInLast ? 1 : 0,
              titleInLast ? 1 : 0,
              linkto ? linkto.value : 0,
              automaticallyDisplaySubmenu ? 1 : 0,
              printLabel ? 1 : 0,
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              appImage,
              showAppImage ? 1 : 0,
              webImage,
              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0,
              defaultVatForAllOption ? 1 : 0
            )
          );
        }
      }
    } else if (key == 3) {
      if (key3) {
        key3.target.value = null;
      }

      setHref("");
      setkioskImage("");
      if (selected_Product.KioskImageUrl != "") {
        if (
          `${image_Url}${image_href}/${selected_Product.KioskImageUrl}` != href
        ) {
          setHref(
            `${image_Url}${image_href}/${selected_Product.KioskImageUrl}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_Product.Id);
          formData.append("ShopId", selected_shop[0].ShopId);
          formData.append("AppTitle", appTitle);
          formData.append("AppDescription", appDesc);
          formData.append(
            "WebTitle",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("WebDescription", webDesc);
          formData.append(
            "KioskTitle",
            kioskTitle
              ? kioskTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`)
          );
          formData.append("KioskDescription", kioskDesc);
          formData.append("SecondLanguage", secondLanguage);
          formData.append("AppVisible", app_visible_to_server_array.toString());
          formData.append("WebVisible", web_visible_to_server_array.toString());
          formData.append(
            "KioskVisible",
            kiosk_visible_to_server_array.toString()
          );
          formData.append(
            "AmountDelivery",
            mainPrice ? parseFloat(mainPrice) : 0
          );
          formData.append(
            "AmountCollection",
            collectionPrice ? parseFloat(collectionPrice) : 0
          );
          formData.append(
            "AmountTakeaway",
            takeawayPrice ? parseFloat(takeawayPrice) : 0
          );
          formData.append(
            "AmountEatIn",
            eatInPrice ? parseFloat(eatInPrice) : 0
          );
          formData.append("PrinterId", printer_list_to_server_array.toString());
          formData.append(
            "ReportSection",
            reportSection ? reportSection.value : 0
          );
          formData.append("IsShowOptions", 0);
          formData.append(
            "ProductGroup",
            productGroup ? productGroup.value : 0
          );
          formData.append("VatId", selectedVat ? selectedVat.value : 0);
          formData.append("IsSugessted", suggested ? 1 : 0);
          formData.append("IsPopular", popular ? 1 : 0);
          formData.append("IsDifOptionVat", 0);
          formData.append("UnitId", 0);
          formData.append("Barcode", "");
          formData.append("CustomTitle", customTitle.replaceAll(`"`, `\"\"`));
          formData.append("CloseCount", closeWhen ? parseInt(closeWhen) : 0);
          formData.append("CustomTitleInLast", customTitleInLast ? 1 : 0);
          formData.append("TitleInLast", titleInLast ? 1 : 0);
          formData.append("linkto", linkto ? linkto.value : 0);
          formData.append(
            "IsAutoDisplaySubMenu",
            automaticallyDisplaySubmenu ? 1 : 0
          );
          formData.append("PrintLabel", printLabel ? 1 : 0);
          formData.append("AppSortId", appSort ? parseInt(appSort) : 0);
          formData.append("WebSortId", webSort ? parseInt(webSort) : 0);
          formData.append("KioskSortId", kioskSort ? parseInt(kioskSort) : 0);
          formData.append("AppBgColor", backgroundColor);
          formData.append("AppTitleFontSize", fontSize ? fontSize.value : 30);
          formData.append(
            "AppTitleFontFamily",
            fontFamily ? fontFamily.value.toString() : String(4)
          );
          formData.append("AppTitleColor", titleColor);

          formData.append(
            "NumberOfSubmenusColumn",
            numberOfSubmenusCulmn ? parseInt(numberOfSubmenusCulmn.value) : 4
          );
          formData.append(
            "NumberOfSubmenusRow",
            numberOfSubmenusRow ? parseInt(numberOfSubmenusRow.value) : 6
          );
          formData.append("AppImageUrl", appImage ? appImage : "");
          formData.append("AppVisibleImage", showAppImage ? 1 : 0);
          formData.append("WebImageUrl", webImage ? webImage : "");
          formData.append("WebVisibleImage", showWebImage ? 1 : 0);
          formData.append("KioskImageUrl", "");
          formData.append("KioskVisibleImage", showKioskImage ? 1 : 0);
          formData.append(
            "SetDefaultVatForAllOptions",
            defaultVatForAllOption ? 1 : 0
          );
          // formData.append("LastKioskImageUrlName", lastKioskImageName);
          formData.append("LastAppImageUrlName", lastAppImageName);
          formData.append("LastWebImageUrlName", lastWebImageName);
          formData.append(
            "NumberOfItemsColumn",
            numberOfSubmenusCulmn ? parseInt(numberOfSubmenusCulmn) : 4
          );
          formData.append(
            "NumberOfItemsRow",
            numberOfSubmenusRow ? parseInt(numberOfSubmenusRow) : 6
          );
          formData.append("JustTitleAndPrice", 0);
          formData.append(
            "SubmenusFontSize",
            submenusFontSize ? submenusFontSize.value : 9
          );
          formData.append("Calories", calories ? parseFloat(calories) : 0);
          formData.append("AlwaysOpen", availability.value == 0 ? 0 : 1);
          formData.append("DeleteImage", 3);
          formData.append(
            "FixedItemId",
            selectedFixedItem ? selectedFixedItem.value : 0
          );
          dispatch(
            sendProductSettingsAction(
              formData,
              selected_category.Id,
              login_key,
              user_id,
              selected_Product.Id,
              selected_shop[0].ShopId,
              0,
              appTitle.replaceAll(`"`, `\"\"`),
              appDesc.replaceAll(`"`, `\"\"`),
              webTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              app_visible_to_server_array.toString(),
              web_visible_to_server_array.toString(),
              kiosk_visible_to_server_array.toString(),
              mainPrice ? parseFloat(mainPrice) : 0,
              collectionPrice ? parseFloat(collectionPrice) : 0,
              takeawayPrice ? parseFloat(takeawayPrice) : 0,
              eatInPrice ? parseFloat(eatInPrice) : 0,
              selectedPrinterList
                ? printer_list_to_server_array.toString()
                : "",
              reportSection ? reportSection.value : 0,
              0,
              productGroup ? productGroup.value : 0,
              selectedVat ? selectedVat.value : 0,
              suggested ? 1 : 0,
              popular ? 1 : 0,
              0,
              0,
              "",
              customTitle.replaceAll(`"`, `\"\"`),
              closeWhen ? parseInt(closeWhen) : 0,
              customTitleInLast ? 1 : 0,
              titleInLast ? 1 : 0,
              linkto ? linkto.value : 0,
              automaticallyDisplaySubmenu ? 1 : 0,
              printLabel ? 1 : 0,
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              backgroundColor,
              fontSize ? fontSize.value : 30,
              fontFamily ? fontFamily.value.toString() : String(4),
              titleColor,
              width ? parseInt(width) : 0,
              height ? parseInt(height) : 0,
              appImage,
              showAppImage ? 1 : 0,
              webImage,
              showWebImage ? 1 : 0,
              kioskImage,
              showKioskImage ? 1 : 0,
              defaultVatForAllOption ? 1 : 0
            )
          );
        }
      }
    }
  };
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    let rows_column_value = [];
    let availability = null;
    let copy_Availability = [...Availability];
    let fixed_submenu = null;
    for (let i = 0; i < number_of_row_culmn.length; i++) {
      rows_column_value.push(number_of_row_culmn[i].value);
    }
    let number_of_row = null;
    let number_of_column = null;
    if (selected_Product) {
      setAppTitle(selected_Product.AppTitle);
      setWebTitle(selected_Product.WebTitle);
      setKioskTitle(selected_Product.KioskTitle);
      setAppDesc(selected_Product.AppDescription);
      setWebDesc(selected_Product.WebDescription);
      setKioskDesc(selected_Product.KioskDescription);
      setwidth(selected_Product.AppTileWidth);
      setHeight(selected_Product.AppTileHeight);
      setBackgroundColor(selected_Product.AppBgColor);
      setTitleColor(selected_Product.AppTitleColor);
      setFontFamily(selected_Product.AppTitleFontFamily);
      setFontSize(selected_Product.AppTitleFontSize);
      setAppsort(selected_Product.AppSortId);
      setWebsort(selected_Product.WebSortId);
      setKiosksort(selected_Product.KioskSortId);
      setSecondLanguage(selected_Product.SecondLanguage);
      // setSrc(
      //   `${image_href}${700 + selected_category.ShopId}/${
      //     selected_category.AppImageUrl
      //   }`
      // );
      // setLastAppImageName(selected_category.AppImageUrl);
      // setUrl(
      //   `${image_href}${700 + selected_category.ShopId}/${
      //     selected_category.WebImageUrl
      //   }`
      // );
      if (selected_Product.AlwaysOpen) {
        availability = {
          value: copy_Availability[0].value,
          label: copy_Availability[0].label,
        };
      } else {
        availability = {
          value: copy_Availability[1].value,
          label: copy_Availability[1].label,
        };
      }
      setAvailability(availability);
      if (selected_Product.AppImageUrl) {
        setSrc(`${image_Url}${image_href}/${selected_Product.AppImageUrl}`);
      } else {
        setSrc("");
      }

      setLastAppImageName(selected_category.AppImageUrl);
      if (selected_Product.WebImageUrl) {
        setUrl(`${image_Url}${image_href}/${selected_Product.WebImageUrl}`);
      } else {
        setUrl("");
      }

      setLastAppImageName(selected_Product.AppImageUrl);
      setLastWebImageName(selected_Product.WebImageUrl);
      setLastKiokImageName(selected_Product.KioskImageUrl);
      if (selected_Product.KioskImageUrl) {
        setHref(`${image_Url}${image_href}/${selected_Product.KioskImageUrl}`);
      } else {
        setHref("");
      }

      setShowAppImage(selected_Product.AppVisibleImage);
      setShowWebImage(selected_Product.WebVisibleImage);
      setShowKioskImage(selected_Product.KioskVisibleImage);
      setMainPrice(selected_Product.AmountDelivery);
      setCollectionPrice(selected_Product.AmountCollection);
      setTakeawayPrice(selected_Product.AmountTakeaway);
      setEatInPrice(selected_Product.AmountEatIn);
      setSelectedVat(selected_Product.VatId);
      setReportSection(selected_Product.ReportSection);
      setSelectedPrinterList(selected_Product.PrinterId);
      setProductGroup(selected_Product.ProductGroup);
      setCustomTitle(selected_Product.CustomTitle);
      setCustomTitleInLast(selected_Product.CustomTitleInLast);
      setTitleInLast(selected_Product.TitleInLast);
      setPrintLabel(selected_Product.PrintLabel);
      setLinkto(selected_Product.linkto);
      setDefaultVatForAllOption(selected_Product.SetDefaultVatForAllOptions);
      setAutoDisplaySubMenu(selected_Product.IsAutoDisplaySubMenu);
      setAppShowHideValues(selected_Product.AppVisible);
      setWebShowHideValues(selected_Product.WebVisible);
      setKioskShowHideValues(selected_Product.KioskVisible);
      setAutomaticallyDisplaySubmenu(selected_Product.IsAutoDisplaySubMenu);
      setPrintLabel(selected_Product.PrintLabel);
      setCloseWhen(selected_Product.CloseCount);
      setPopular(selected_Product.IsPopular);
      setSuggested(selected_Product.IsSugessted);
      setSubmenusFontSize(selected_Product.SubmenusFontSize);
      if (selected_Product.FixedItemId) {
        if (
          fixed_submenu_ids.includes(parseInt(selected_Product.FixedItemId))
        ) {
          let index = fixed_submenu_ids.indexOf(
            parseInt(selected_Product.FixedItemId)
          );
          fixed_submenu = {
            value: fixed_submenu_list[index].value,
            label: fixed_submenu_list[index].label,
          };
        }
      } else {
        fixed_submenu = {
          value: fixed_submenu_list[0].value,
          label: fixed_submenu_list[0].label,
        };
      }
      if (selected_Product.NumberOfSubmenusColumn) {
        if (
          rows_column_value.includes(
            parseInt(selected_Product.NumberOfSubmenusColumn)
          )
        ) {
          let index = rows_column_value.indexOf(
            parseInt(selected_Product.NumberOfSubmenusColumn)
          );
          number_of_column = {
            value: number_of_row_culmn[index].value,
            label: number_of_row_culmn[index].label,
          };
        }
      }
      if (selected_Product.NumberOfSubmenusRow) {
        if (
          rows_column_value.includes(
            parseInt(selected_Product.NumberOfSubmenusRow)
          )
        ) {
          let index = rows_column_value.indexOf(
            parseInt(selected_Product.NumberOfSubmenusRow)
          );
          number_of_row = {
            value: number_of_row_culmn[index].value,
            label: number_of_row_culmn[index].label,
          };
        }
      }
      setNumberOfSubmenusCulmn(number_of_column);
      setNumberOfSubmenusRow(number_of_row);
      setCalories(selected_Product.Calories);
      setSelectedFixedItem(fixed_submenu);
    }
  }, [selected_Product]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveClicked();
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    appTitle,
    appDesc,
    webTitle,
    webDesc,
    kioskTitle,
    kioskDesc,
    secondLanguage,
    mainPrice,
    collectionPrice,
    takeawayPrice,
    eatInPrice,
    selectedPrinterList,
    reportSection,
    productGroup,
    selectedVat,
    customTitle,
    closeWhen,
    customTitleInLast,
    titleInLast,
    linkto,
    automaticallyDisplaySubmenu,
    printLabel,
    appSort,
    webSort,
    kioskSort,
    backgroundColor,
    fontSize,
    fontFamily,
    titleColor,
    width,
    height,
    appImage,
    showAppImage,
    webImage,
    showWebImage,
    kioskImage,
    showKioskImage,
    appShowHideValues,
    webShowHideValues,
    kioskShowHideValues,
    suggested,
    popular,
    numberOfSubmenusCulmn,
    numberOfSubmenusRow,
  ]);

  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(2);
    }
    didMountRef.current = true;
  }, [selected_shop]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(2);
    }
  }, [result_successfull]);
  useEffect(() => {
    formData.delete("LoginKey");
    formData.delete("UserId");
    formData.delete("Id");
    formData.delete("ShopId");
    formData.delete("AppTitle");
    formData.delete("AppDescription");
    formData.delete("WebTitle");
    formData.delete("WebDescription");
    formData.delete("KioskTitle");
    formData.delete("KioskDescription");
    formData.delete("SecondLanguage");
    formData.delete("AppVisible");
    formData.delete("WebVisible");
    formData.delete("KioskVisible");
    formData.delete("AmountDelivery");
    formData.delete("AmountCollection");
    formData.delete("AmountTakeaway");
    formData.delete("AmountEatIn");
    formData.delete("PrinterId");
    formData.delete("ReportSection");
    formData.delete("IsShowOptions");
    formData.delete("ProductGroup");
    formData.delete("VatId");
    formData.delete("IsSugessted");
    formData.delete("IsPopular");
    formData.delete("IsDifOptionVat");
    formData.delete("UnitId");
    formData.delete("Barcode");
    formData.delete("CustomTitle");
    formData.delete("CloseCount");
    formData.delete("CustomTitleInLast");
    formData.delete("TitleInLast");
    formData.delete("linkto");
    formData.delete("IsAutoDisplaySubMenu");
    formData.delete("PrintLabel");
    formData.delete("AppSortId");
    formData.delete("WebSortId");
    formData.delete("KioskSortId");
    formData.delete("AppBgColor");
    formData.delete("AppTitleFontSize");
    formData.delete("AppTitleFontFamily");
    formData.delete("AppTitleColor");
    formData.delete("AppTileWidth");
    formData.delete("AppTileHeight");
    formData.delete("AppImageUrl");
    formData.delete("AppVisibleImage");
    formData.delete("WebImageUrl");
    formData.delete("WebVisibleImage");
    formData.delete("KioskImageUrl");
    formData.delete("KioskVisibleImage");
    formData.delete("SetDefaultVatForAllOptions");
    formData.delete("NumberOfItemsColumn");
    formData.delete("NumberOfItemsRow");
    formData.delete("NumberOfSubmenusColumn");
    formData.delete("NumberOfSubmenusRow");
    formData.delete("SubmenusFontSize");
    formData.delete("Calories");
    formData.delete("AlwaysOpen");
  }, []);

  return (
    <div className="countaining-div">
      {/* BACK & TITLE */}
      <ListHeader
        title={`${lang.product_edit} / ${selected_Product.AppTitle}`}
        show_back={true}
        clickedItem={() => setClickedItem(2)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {/* TITLES */}
          <div className="div-10">
            <div className="div-10-1">
              <h6 className="h6">{lang.mainprice}</h6>
              <input
                className="div-10-input input-padding"
                type="text"
                value={mainPrice}
                onChange={(e) =>
                  setMainPrice(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="div-10-1">
              <h6 className="h6">{lang.collectionPrice}</h6>
              <input
                className="div-10-input input-padding"
                type="text"
                value={collectionPrice}
                onChange={(e) =>
                  setCollectionPrice(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="div-10-1">
              <h6 className="h6">{lang.takeawayPrice}</h6>
              <input
                className="div-10-input input-padding"
                type="text"
                value={takeawayPrice}
                onChange={(e) =>
                  setTakeawayPrice(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="div-10-1">
              <h6 className="h6">{lang.eatinPrice}</h6>
              <input
                className="div-10-input input-padding"
                type="text"
                value={eatInPrice}
                onChange={(e) =>
                  setEatInPrice(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
          </div>
          {/* CUSTOM TITLE */}
          <div
            className="div-14"
            style={{
              alignItems: "flex-end",
            }}
          >
            <div className="text">
              <h6 className="h6">{lang.customTitle}</h6>
              <input
                className="div-11-inputText input-padding"
                type="text"
                value={customTitle}
                onChange={(e) => setCustomTitle(e.target.value)}
              />
            </div>
            <div className="text ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customTitleInLast}
                      onChange={() => setCustomTitleInLast(!customTitleInLast)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.customTitle_inLast}
                />
              </FormGroup>
            </div>
            <div className="text ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={titleInLast}
                      onChange={() => setTitleInLast(!titleInLast)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.productTitle_inLast}
                />
              </FormGroup>
            </div>
          </div>
          <div className="div-12 ">
            {/* VAT */}
            <div className="text ">
              <h6 className="autocomplete-title h6 ">{lang.vat}</h6>
              <Select
                options={vat}
                defaultValue={selectedVat}
                onChange={(list, item) => {
                  setSelectedVat(list);
                }}
                value={selectedVat}
                styles={customStyles}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            {/* REPORT SECTION */}
            <div className="text ">
              <h6 className="autocomplete-title h6">{lang.printerSection}</h6>
              <Select
                options={report_section}
                defaultValue={reportSection}
                isSearchable={false}
                onChange={(list, item) => setReportSection(list)}
                value={reportSection}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* PRODUCT GROUP */}
            <div className="text ">
              <h6 className="autocomplete-title h6">{lang.productGroup}</h6>
              <Select
                options={product_group}
                defaultValue={productGroup}
                isSearchable={false}
                onChange={(list, item) => setProductGroup(list)}
                value={productGroup}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="div-13 div-13-product">
            {/* PRINTER */}
            <div className="text">
              <h6 className="show-hide h6">{lang.printer}</h6>
              <Select
                options={printer_list}
                defaultValue={selectedPrinterList}
                isSearchable={false}
                onChange={(list, item) => setSelectedPrinterList(list)}
                value={selectedPrinterList}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* LINK TO */}
            <div className="text">
              <h6 className="show-hide h6">{lang.linkTo}</h6>
              <Select
                options={link_to}
                defaultValue={linkto}
                isSearchable={false}
                onChange={(list, item) => setLinkto(list)}
                value={linkto}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="div-14">
            <div className="text">
              <h6 className="h6">{lang.closeWhen}</h6>
              <input
                className="div-14-inputText input-padding"
                value={closeWhen}
                type="text"
                onChange={(e) =>
                  setCloseWhen(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
            <div className="text">
              <h6 className="h6">{lang.calories}</h6>
              <input
                className="div-14-inputText input-padding"
                value={calories}
                type="text"
                onChange={(e) =>
                  setCalories(e.target.value.replace(/[^\d\.]+/g, ""))
                }
              />
            </div>
            <div className="text">
              <h6 className="h6"> {lang.secoundLanguage}</h6>
              <input
                className="div-14-inputText input-padding"
                value={secondLanguage}
                type="text"
                onChange={(e) => setSecondLanguage(e.target.value)}
              />
            </div>
          </div>
          {/* CKECK BOXES */}
          <div className="div-14">
            <div
              className="text"
              style={{
                width: "50%",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={automaticallyDisplaySubmenu}
                      onChange={() =>
                        setAutomaticallyDisplaySubmenu(
                          !automaticallyDisplaySubmenu
                        )
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.automaticallyDisplay_subMenu}
                />
              </FormGroup>
            </div>
            <div className="text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={defaultVatForAllOption}
                      onChange={() =>
                        setDefaultVatForAllOption(!defaultVatForAllOption)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.set_default_vat}
                />
              </FormGroup>
            </div>
          </div>
          <div className="div-15">
            <div className="text ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={suggested}
                      onChange={() => setSuggested(!suggested)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.suggested}
                />
              </FormGroup>
            </div>
            <div className="text ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={popular}
                      onChange={() => setPopular(!popular)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.popular}
                />
              </FormGroup>
            </div>
            <div className="text  ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printLabel}
                      onChange={() => setPrintLabel(!printLabel)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.printLabel}
                />
              </FormGroup>
            </div>
          </div>
          <div className="div-1 ">
            <div className="text">
              <h6 className="show-hide h6">{lang.availability}</h6>
              <div className="container_availability_dropdown">
                <div style={{ width: "80%" }}>
                  <Select
                    options={Availability}
                    defaultValue={availability}
                    isSearchable={false}
                    onChange={handleProductAvailability}
                    value={availability}
                    isMulti={false}
                    styles={customStyles}
                    isClearable={false}
                  />
                </div>
                <button
                  onClick={() =>
                    dispatch(showProductAvailabilityPopupAction(true))
                  }
                  disabled={
                    availability
                      ? availability.value == 1
                        ? true
                        : false
                      : false
                  }
                >
                  {lang.edit}
                </button>
              </div>
            </div>
          </div>
          <div className="list_header_container-general">
            {/* <div>
              <h6 id="general_text">{lang.general}</h6>
            </div> */}
          </div>
          <div className="div-1 ">
            <div className="text">
              <h6 className="title h6">{lang.app_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setAppTitle(e.target.value)}
                  type="text"
                  value={appTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setAppDesc(e.target.value)}
                  type="text"
                  value={appDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
            <div className="text">
              <h6 className="title h6">{lang.web_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setWebTitle(e.target.value)}
                  type="text"
                  value={webTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setWebDesc(e.target.value)}
                  type="text"
                  value={webDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            <div className="text ">
              <h6 className="title h6">{lang.kiosk_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setKioskTitle(e.target.value)}
                  type="text"
                  value={kioskTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setKioskDesc(e.target.value)}
                  type="text"
                  value={kioskDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
          </div>
          <div className="div-2">
            {/* APP SHOW  HIDE */}
            <div className="text">
              <h6 className="show-hide h6">{lang.show_hide}</h6>
              <Select
                options={show_hide}
                defaultValue={appShowHideValues}
                isSearchable={false}
                onChange={onSelectAppVisible}
                value={appShowHideValues}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>

            {/* WEB SHOW  HIDE  */}
            <div className="text">
              <h6 className="show-hide h6 ">{lang.show_hide}</h6>
              <Select
                options={show_hide}
                defaultValue={webShowHideValues}
                isSearchable={false}
                onChange={onSelectWebVisible}
                value={webShowHideValues}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* KIOSK SHOW  HIDE  */}
            <div className="text">
              <h6 className="show-hide h6">{lang.show_hide}</h6>
              <Select
                options={show_hide}
                defaultValue={kioskShowHideValues}
                isSearchable={false}
                onChange={onSelectKioskVisible}
                value={kioskShowHideValues}
                isMulti={true}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          {/* SORT */}
          <div className="div-3">
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={appSort}
                onChange={(e) => setAppsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={webSort}
                onChange={(e) => setWebsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={kioskSort}
                onChange={(e) =>
                  setKiosksort(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
          </div>
          {/* IMAGE */}
          <div className="div-4 ">
            {/* APP IMAGE */}
            <div className=" text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  id="upload"
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectFile(e)}
                  className="file-i"
                />
              </div>
              <div className="image-div" style={{ position: "relative" }}>
                {src ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImage(1)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    src
                      ? src
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
            {/* WEB IMAGE */}
            <div className=" text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectImage(e)}
                  className="file-i"
                />
              </div>
              <div className="image-div" style={{ position: "relative" }}>
                {url ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImage(2)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    url
                      ? url
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  style={{ objectFit: "contain", backgroundColor: "#F7F7F6" }}
                  onLoad={loadImage1}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
            {/* KIOSK IMAGE */}
            <div className=" text">
              <h6 className="p-image h6">{lang.image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectPicture(e)}
                  className="file-i"
                />
              </div>
              <div className="image-div" style={{ position: "relative" }}>
                {href ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImage(3)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    href
                      ? href
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  style={{ objectFit: "contain", backgroundColor: "#F7F7F6" }}
                  onLoad={loadImage2}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
          </div>
          {/* VISIBLE IMAGE */}
          <div className="div-5">
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAppImage}
                      onChange={() => setShowAppImage(!showAppImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showWebImage}
                      onChange={() => setShowWebImage(!showWebImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
            <div className="text div-chechbox-p ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showKioskImage}
                      onChange={() => setShowKioskImage(!showKioskImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.showImage}
                />
              </FormGroup>
            </div>
          </div>
          {selected_category.MealDeal ? (
            <div className="div-5 ">
              <div className="text">
                <h6 className="h6-text h6">{lang.fixed_submenu}</h6>
                <Select
                  options={fixed_submenu_list}
                  defaultValue={selectedFixedItem}
                  isSearchable={false}
                  onChange={(list, item) => {
                    setSelectedFixedItem(list);
                  }}
                  value={selectedFixedItem}
                  styles={customStyles}
                  isClearable={false}
                />
              </div>
            </div>
          ) : null}
          <div className="div-9">
            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.number_of_submenus_culmn}</h6>
              <Select
                options={number_of_culmn}
                defaultValue={numberOfSubmenusCulmn}
                isSearchable={false}
                onChange={(list, item) => {
                  setNumberOfSubmenusCulmn(list);
                }}
                value={numberOfSubmenusCulmn}
                styles={customStyles}
                isClearable={false}
                isMulti={false}
              />
            </div>
            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.number_of_submenus_row}</h6>
              <Select
                options={number_of_row_culmn}
                defaultValue={numberOfSubmenusRow}
                isSearchable={false}
                onChange={(list, item) => {
                  setNumberOfSubmenusRow(list);
                }}
                value={numberOfSubmenusRow}
                styles={customStyles}
                isClearable={false}
                isMulti={false}
              />
            </div>
            {/* BACKGROUND COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.backgroundColor}</h6>
              <div className="bachgroundColor-div">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: backgroundColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInput}
                  Value={backgroundColor}
                  onChange={handleInputBackgroundColor}
                  id="input_backgroundColor"
                />
              </div>
              {showSketchPicker ? (
                <div
                  className="SketchPicker-div"
                  onMouseLeave={handleCloseInputColor}
                >
                  <SketchPicker
                    color={backgroundColor}
                    onChangeComplete={handleChangeComplete}
                  />
                </div>
              ) : null}
            </div>
            {/* TITLE COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.fontColor}</h6>
              <div className="bachgroundColor-div">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: titleColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInputColor}
                  Value={titleColor}
                  onChange={handleInputTitleColor}
                  id="inputColor"
                />
              </div>
              {showColorPicker ? (
                <div className="SketchPicker-div" onMouseLeave={handleClose}>
                  <SketchPicker
                    color={titleColor}
                    onChangeComplete={handleChangeCompleteColor}
                  />
                </div>
              ) : null}
            </div>

            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.submenus_font_size}</h6>
              <Select
                options={font_size}
                defaultValue={submenusFontSize}
                isSearchable={false}
                onChange={(list, item) => setSubmenusFontSize(list)}
                value={submenusFontSize}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            {/* FONT FAMILY */}
            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.fontFamily}</h6>
              <Select
                options={font_family}
                defaultValue={fontFamily}
                isSearchable={false}
                onChange={(list, item) => {
                  setFontFamily(list);
                }}
                value={fontFamily}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          {/* PRICES */}
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSaveClicked()} />
    </div>
  );
};

export default ProductsEdit;
