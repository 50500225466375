import * as actionType from "../ActionTypes";

export const getPrivacyPolicyListAction = (login_key, user_id) => {
  return {
    type: actionType.GET_PRIVACY_POLICY_LIST,
    payload: { login_key, user_id },
  };
};
export const getPrivacyPolicyListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_PRIVACY_POLICY_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getPrivacyPolicyListFailedAction = (data) => {
  return {
    type: actionType.GET_PRIVACY_POLICY_LIST_FAILED,
    payload: data,
  };
};

export const updatePrivacyPolicyAction = (
  login_key,
  user_id,
  privacy_policy
) => {
  return {
    type: actionType.UPDATE_PRIVACY_POLICY,
    payload: { login_key, user_id, privacy_policy },
  };
};
export const updatePrivacyPolicySuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_PRIVACY_POLICY_SUCCESSFULL,
    payload: data,
  };
};
export const updatePrivacyPolicyFailedAction = (data) => {
  return {
    type: actionType.UPDATE_PRIVACY_POLICY_FAILED,
    payload: data,
  };
};

// EXTERNAL COMPANY
export const getExternalCompanyListAction = (login_key, user_id) => {
  return {
    type: actionType.GET_EXTERNAL_COMPANY_LIST,
    payload: { login_key, user_id },
  };
};
export const getExternalCompanyListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_EXTERNAL_COMPANY_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getExternalCompanyListFailedAction = (data) => {
  return {
    type: actionType.GET_EXTERNAL_COMPANY_LIST_FAILED,
    payload: data,
  };
};

export const updateExternalCompanyAction = (
  formData,
  login_key,
  user_id,
  external_company_id,
  title,
  shop_group_id,
  profile_image,
  last_profile_image_name
) => {
  return {
    type: actionType.UPDATE_EXTERNAL_COMPANY,
    payload: {
      formData,
      login_key,
      user_id,
      external_company_id,
      title,
      shop_group_id,
      profile_image,
      last_profile_image_name,
    },
  };
};
export const updateExternalCompanySuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_EXTERNAL_COMPANY_SUCCESSFULL,
    payload: data,
  };
};
export const updateExternalCompanyFailedAction = (data) => {
  return {
    type: actionType.UPDATE_EXTERNAL_COMPANY_FAILED,
    payload: data,
  };
};

export const showExternalCompanyShopsListPopupAction = (data) => {
  return {
    type: actionType.SHOW_EXTENRAL_COMPANY_SHOPS_LIST_POPUP,
    payload: data,
  };
};

export const selectedExternalCompanyAction = (data) => {
  return {
    type: actionType.SELECTED_EXTERNAL_COMPANY,
    payload: data,
  };
};

// SUBMNEU PUBLIC LIST
export const getSubmenuPublicListActiom = (login_key, user_id) => {
  return {
    type: actionType.GET_SUBMENU_PUBLIC_LIST,
    payload: { login_key, user_id },
  };
};
export const getSubmenuPublicListSuccessfullActiom = (data) => {
  return {
    type: actionType.GET_SUBMENU_PUBLIC_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getSubmenuPublicListFailedActiom = (data) => {
  return {
    type: actionType.GET_SUBMENU_PUBLIC_LIST_FAILED,
    payload: data,
  };
};
export const selectedPublicSubmneuAction = (data) => {
  return {
    type: actionType.SELECTED_PUBLIC_SUBMENU,
    payload: data,
  };
};

// SUBITEM PUBLIC LIST
export const getSubItemPublicListAction = (login_key, user_id, sub_id) => {
  return {
    type: actionType.GET_SUBITEM_PUBLIC_LIST,
    payload: { login_key, user_id, sub_id },
  };
};
export const getSubItemPublicListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_SUBITEM_PUBLIC_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getSubItemPublicListFailedAction = (data) => {
  return {
    type: actionType.GET_SUBITEM_PUBLIC_LIST_FAILED,
    payload: data,
  };
};
export const selectedSubItemPublicAction = (data) => {
  return {
    type: actionType.SELECTED_SUBITEM_PUBLIC,
    payload: data,
  };
};
//CREATE PUBLIC SUBITEM
export const createPublicSubItemAction = (
  login_key,
  user_id,
  sub_id,
  title,
  description
) => {
  return {
    type: actionType.CREATE_PUBLIC_SUBITEM,
    payload: { login_key, user_id, sub_id, title, description },
  };
};
export const createPublicSubItemSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_PUBLIC_SUBITEM_SUCCESSFULL,
    payload: data,
  };
};
export const createPublicSubItemFailedAction = (data) => {
  return {
    type: actionType.UPDATE_PUBLIC_SUBITEM_FAILED,
    payload: data,
  };
};

//UPDATE PUBLIC SUBITEM
export const updatePublicSubItemAction = (
  formData,
  login_key,
  user_id,
  item_id,
  app_title,
  app_desc,
  sub_id
) => {
  return {
    type: actionType.UPDATE_PUBLIC_SUBITEM,
    payload: {
      formData,
      login_key,
      user_id,
      item_id,
      app_title,
      app_desc,
      sub_id,
    },
  };
};
export const updatePublicSubItemSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_PUBLIC_SUBITEM_SUCCESSFULL,
    payload: data,
  };
};
export const updatePublicSubItemFailedAction = (data) => {
  return {
    type: actionType.UPDATE_PUBLIC_SUBITEM_FAILED,
    payload: data,
  };
};
//DELETE PUBLIC SUBITEM
export const deletePublicSubItemAction = (
  login_key,
  user_id,
  item_id,
  sub_id
) => {
  return {
    type: actionType.DELETE_PUBLIC_SUBITEM,
    payload: { login_key, user_id, item_id, sub_id },
  };
};
export const deletePublicSubItemSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_PUBLIC_SUBITEM_SUCCESSFULL,
    payload: data,
  };
};
export const deletePublicSubItemFailedAction = (data) => {
  return {
    type: actionType.DELETE_PUBLIC_SUBITEM_FAILED,
    payload: data,
  };
};

//GET CUSTOMERS CALL LIST
export const getCustomersCallListAction = (
  login_key,
  user_id,
  shop_id,
  text_search
) => {
  return {
    type: actionType.GET_CUSTOMERS_CALL_LIST,
    payload: { login_key, user_id, shop_id, text_search },
  };
};
export const getCustomersCallListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_CUSTOMERS_CALL_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getCustomersCallListFailedAction = (data) => {
  return {
    type: actionType.GET_CUSTOMERS_CALL_LIST_FAILED,
    payload: data,
  };
};
