import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/Store";
import App from "./App";
import Login from "./pages/login";
import Main from "./Main";
import "./Styles.css";
import "./Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

render(
  <BrowserRouter>
    <Provider store={store}>
      <Main />
      {/* <App /> */}
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
