import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const tabelAreaServices = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}tableAreaList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const createTabelAreaServices = async (
  login_key,
  user_id,
  shop_id,
  tabel_name,
  parent_id,
  position,
  percount,
  app_visible,
  kiosk_visible
) => {
  return await axios.post(`${baseUrl}createTableArea/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    TableName: tabel_name,
    ParentId: parent_id,
    Position: position,
    PerCount: percount,
    AppVisible: app_visible,
    KioskVisible: kiosk_visible,
  });
};

export const updateTabelAreaServices = async (
  login_key,
  user_id,
  shop_id,
  id,
  tabel_name,
  parent_id,
  position,
  percount,
  app_visible,
  kiosk_visible
) => {
  return await axios.put(`${baseUrl}updateTableArea/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    TableName: tabel_name,
    ParentId: parent_id,
    Position: position,
    PerCount: percount,
    AppVisible: app_visible,
    KioskVisible: kiosk_visible,
  });
};

export const allowedPostCodeListServices = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}allowedPostcodeList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const createAllowedPostCodeServices = async (
  login_key,
  user_id,
  shop_id,
  post_code,
  delivery_charge,
  delivey_min_charge
) => {
  return await axios.post(`${baseUrl}createAllowedPostcode/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Postcode: post_code,
    DeliveryCharge: delivery_charge,
    DeliveryMinCharge: delivey_min_charge,
  });
};
export const updateAllowedPostCodeServices = async (
  login_key,
  user_id,
  shop_id,
  id,
  post_code,
  delivery_charge,
  delivey_min_charge
) => {
  return await axios.put(`${baseUrl}updateAllowedPostcode/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    Postcode: post_code,
    DeliveryCharge: delivery_charge,
    DeliveryMinCharge: delivey_min_charge,
  });
};
export const deleteAllowedPostCodeServices = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.delete(`${baseUrl}deleteAllowedPostcode/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      Id: id,
    },
  });
};
export const vatLisServices = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}vatList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const createVatServices = async (
  login_key,
  user_id,
  shop_id,
  vat_name,
  vat_value
) => {
  return await axios.post(`${baseUrl}createVat/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    VatName: vat_name,
    VatValue: vat_value,
  });
};
export const updateVatServices = async (
  login_key,
  user_id,
  shop_id,
  id,
  vat_name,
  vat_value
) => {
  return await axios.put(`${baseUrl}updateVat/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    VatName: vat_name,
    VatValue: vat_value,
  });
};

export const getHalfAndHalfService = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}getHalfAndHalf/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const updateHalfAndHalfService = async (
  login_key,
  user_id,
  shop_id,
  half_id,
  max_price,
  by_percenet,
  equal,
  first_half,
  second_half
) => {
  return await axios.put(`${baseUrl}updateHalfAndHalf/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    IdHalf: half_id,
    MaxPrice: max_price,
    ByPercent: by_percenet,
    Equal: equal,
    FristHalf: first_half,
    Secondhalf: second_half,
  });
};
export const deleteVatService = async (login_key, user_id, shop_id, id) => {
  return await axios.delete(`${baseUrl}deleteVat/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      Id: id,
    },
  });
};

//CARIIER BAG
export const getCarrierBagListService = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}carrierBagGet/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const updateCarrierBagService = async (
  login_key,
  user_id,
  shop_id,
  id,
  cariier_bag_amount,
  cariier_bag_title,
  active_web_cariier_bag,
  active_app_cariier_bag,
  active_kiosk_cariier_bag
) => {
  return await axios.put(`${baseUrl}updateCarrierBag/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    CarrierBagAmount: cariier_bag_amount,
    CarrierBagTitle: cariier_bag_title,
    ActiveWebCarrierBag: active_web_cariier_bag,
    ActiveAppCarrierBag: active_app_cariier_bag,
    ActiveKioskCarrierBag: active_kiosk_cariier_bag,
  });
};
//SHORT CUT BUTTON
export const getShortCutButtonService = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}shortcutButtonList/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
  });
};

export const updateShortCutButtonService = async (
  login_key,
  user_id,
  shop_id,
  id,
  cat_name_a,
  cat_id_a,
  cat_active_a,
  cat_name_b,
  cat_id_b,
  cat_active_b,
  submenu_name_a,
  submenu_id_a,
  submenu_active_a
) => {
  return await axios.put(`${baseUrl}updateShortcutButton/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    CategoryNameA: cat_name_a,
    CategoryIdA: cat_id_a,
    CategoryActiveA: cat_active_a,
    CategoryNameB: cat_name_b,
    CategoryIdB: cat_id_b,
    CategoryActiveB: cat_active_b,
    SubmenuNameA: submenu_name_a,
    SubmenuIdA: submenu_id_a,
    SubmenuActiveA: submenu_active_a,
  });
};

//ABOUT US
export const getAboutUsListService = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}aboutUsGet/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
  });
};
export const updateAboutUsService = async (
  login_key,
  user_id,
  shop_id,
  message
) => {
  return await axios.put(`${baseUrl}updateAboutUs/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
    Description: message,
  });
};

// DELIVERY BY AREA
export const getByAreaListService = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}byAreaList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

//CREATE BY AREA
export const createByAreaListService = async (
  login_key,
  user_id,
  shop_id,
  area_name,
  delivery_charge,
  post_code
) => {
  return await axios.post(`${baseUrl}createByArea/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    AreaName: area_name,
    DeliveryCharge: delivery_charge,
    Postcode: post_code,
  });
};

//UPDATE BY AREA
export const updateByAreaListService = async (
  login_key,
  user_id,
  shop_id,
  id,
  area_name,
  delivery_charge,
  post_code
) => {
  return await axios.put(`${baseUrl}updateByArea/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    ShopId: shop_id,
    AreaName: area_name,
    DeliveryCharge: delivery_charge,
    Postcode: post_code,
  });
};

//DELETE BY AREA
export const deleteByAreaListService = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.delete(`${baseUrl}deleteByArea/`, {
    headers: {
      Authorization: "***",
    },
    data: { LoginKey: login_key, UserId: user_id, Id: id, ShopId: shop_id },
  });
};

// DELIVERY AREA MAP
export const getDeliveryAreaMapListService = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}areaMapList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const createDeliveryAreaMapService = async (
  login_key,
  user_id,
  shop_id,
  color,
  name,
  minAmount,
  delivery_fee,
  polygon,
  is_active
) => {
  return await axios.put(`${baseUrl}createAreaMap/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Title: name,
    IsActive: is_active,
    Color: color,
    MinAmount: minAmount,
    DeliveryCharge: delivery_fee,
    Polygon: polygon,
  });
};
export const updateDeliveryAreaMapService = async (
  login_key,
  user_id,
  shop_id,
  id,
  color,
  name,
  minAmount,
  delivery_fee,
  polygon,
  is_active
) => {
  return await axios.put(`${baseUrl}updateAreaMap/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    Title: name,
    IsActive: is_active,
    Color: color,
    MinAmount: minAmount,
    DeliveryCharge: delivery_fee,
    Polygon: polygon,
  });
};
export const deleteDeliveryAreaMapService = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.put(`${baseUrl}deleteAreaMap/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
  });
};

//POST CODE FOF
export const getAllowedPostCodeFOFListServices = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}allowedPostcodeFOFList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const createAllowedPostCodeFOFServices = async (
  login_key,
  user_id,
  shop_id,
  post_code,
  delivery_charge,
  delivey_min_charge
) => {
  return await axios.post(`${baseUrl}createAllowedPostcodeFOF/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Postcode: post_code,
    DeliveryCharge: delivery_charge,
    DeliveryMinCharge: delivey_min_charge,
  });
};
export const updateAllowedPostCodeFOFServices = async (
  login_key,
  user_id,
  shop_id,
  id,
  post_code,
  delivery_charge,
  delivey_min_charge
) => {
  return await axios.put(`${baseUrl}updateAllowedPostcodeFOF/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    Postcode: post_code,
    DeliveryCharge: delivery_charge,
    DeliveryMinCharge: delivey_min_charge,
  });
};
export const deleteAllowedPostCodeFOFServices = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.delete(`${baseUrl}deleteAllowedPostcodeFOF/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      Id: id,
    },
  });
};
