import React, { useState, useEffect } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import WorksStationsList from "../../components/WorksStationsList";
import Loader from "../../components/Loader";
import { setDefaultAction } from "../../redux/menu/Actions";
import WorksStationsEdit from "../../components/WorksStationsEdit";
import WorksStationsCreate from "../../components/WorkStationCreate";
import MainButton from "./component/MainButtonsList";
const WorksStations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const {
    result_successfull,
    result_failed,
    result_message,
    loading_worksStations,
  } = useSelector((state) => state.worksStationsReduser);

  const [clickedItem, setClickedItem] = useState(1);
  const [changingPage, setChangingPage] = useState(false);
  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="common-styles mobile-container-workstation-list"
      style={{
        height: window.innerHeight - 160,
      }}
    >
      <div className="container-components-div">
        {clickedItem == 1 ? (
          <WorksStationsList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 2 ? (
          <WorksStationsEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 3 ? (
          <WorksStationsCreate setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 4 ? (
          <MainButton setClickedItem={(i) => setClickedItem(i)} />
        ) : null}
        {loading_worksStations ? <Loader /> : null}
      </div>
      <Toaster />
    </div>
  );
};

export default WorksStations;
