import * as actionTypes from "../ActionTypes";

const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_worksStations: false,
  worksStation_list: [],
  selected_worksStations_list: null,
  get_woeksStation_status_list: [],
  loading_update_worksStations_status: false,
  work_station_main_button_list: [],
  show_work_station_main_buttons_popup: false,
  selected_main_button: null,
  WS_Ids: [],
  work_station_list_for_dojo: [],
};

const worksStationsReduser = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_worksStations: false,
        loading_update_worksStations_status: false,
      };

    case actionTypes.GET_WORKS_STATION_LIST:
      return {
        ...state,
        loading_worksStations: true,
      };

    case actionTypes.GET_WORKS_STATION_LIST_SUCCESSFULL:
      let ws_ids = [];
      let dojo_work_station = [];
      if (action.payload) {
        if (action.payload.length > 0) {
          for (let i = 0; i < action.payload.length; i++) {
            ws_ids.push(action.payload[i].WorkStationId);
            dojo_work_station.push({
              label: action.payload[i].WorkStationName,
              value: action.payload[i].WorkStationId,
            });
          }
        } else {
          dojo_work_station = [];
        }
      } else {
        dojo_work_station = [];
      }

      return {
        ...state,
        loading_worksStations: false,
        worksStation_list: action.payload,
        WS_Ids: ws_ids,
        work_station_list_for_dojo: dojo_work_station,
      };
    case actionTypes.GET_WORKS_STATION_LIST_FAILED:
      return {
        ...state,
        loading_worksStations: false,
        worksStation_list: [],
        work_station_list_for_dojo: [],
      };
    case actionTypes.CREATE_WORKS_STATION:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_worksStations: true,
      };

    case actionTypes.CREATE_WORKS_STATION_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_worksStations: false,
      };
    case actionTypes.CREATE_WORKS_STATION_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_worksStations: false,
      };
    case actionTypes.SEND_WORK_STATION:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_worksStations: true,
      };

    case actionTypes.SEND_WORK_STATION_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_worksStations: false,
      };
    case actionTypes.SEND_WORK_STATION_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_worksStations: false,
      };
    case actionTypes.DELETE_WORKS_STATION:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_worksStations: true,
      };

    case actionTypes.DELETE_WORKS_STATION_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_worksStations: false,
      };
    case actionTypes.DELETE_WORKS_STATION_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_worksStations: false,
      };
    case actionTypes.SELECTE_WORKS_STATION:
      return {
        ...state,
        selected_worksStations_list: action.payload,
      };

    case actionTypes.GET_WORKSTATION_STATUS_LIST:
      return {
        ...state,
        loading_worksStations: true,
      };
    case actionTypes.GET_WORKSTATION_STATUS_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_worksStations: false,
        get_woeksStation_status_list: action.payload,
      };
    case actionTypes.GET_WORKSTATION_STATUS_LIST_FAILED:
      return {
        ...state,
        loading_worksStations: false,
        get_woeksStation_status_list: [],
        result_failed: true,
        result_message: action.payload,
      };

    case actionTypes.UPDATE_WORKSTATION_STATUS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_update_worksStations_status: true,
      };
    case actionTypes.UPDATE_WORKSTATION_STATUS_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_update_worksStations_status: false,
      };
    case actionTypes.UPDATE_WORKSTATION_STATUS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        // loading_worksStations: false,
        loading_update_worksStations_status: false,
      };
    case actionTypes.GET_WORK_STATION_MAIN_BUTTONS_LIST:
      return {
        ...state,
        loading_worksStations: true,
      };
    case actionTypes.GET_WORK_STATION_MAIN_BUTTONS_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_worksStations: false,
        result_successfull: true,
        result_message: "",
        work_station_main_button_list: action.payload,
      };
    case actionTypes.GET_WORK_STATION_MAIN_BUTTONS_LIST_FAILED:
      return {
        ...state,
        loading_worksStations: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        work_station_main_button_list: [],
      };

    case actionTypes.EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS:
      return {
        ...state,
        loading_worksStations: true,
        result_failed: false,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS_SUCCESSFULL:
      return {
        ...state,
        loading_worksStations: false,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        show_work_station_main_buttons_popup: false,
      };
    case actionTypes.EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS_FAILED:
      return {
        ...state,
        loading_worksStations: false,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
      };
    case actionTypes.SHOW_WORK_STATION_MAIN_BUTTONS_POPUP:
      return {
        ...state,
        show_work_station_main_buttons_popup: action.payload,
      };
    case actionTypes.SELECTED_MAIN_BUTTON:
      return {
        ...state,
        selected_main_button: action.payload,
      };
    default:
      return state;
  }
};
export default worksStationsReduser;
