import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import GroupsEdit from "../../../components/GroupsEdit";
import GroupsList from "../../../components/GroupsList";
import Loader from "../../../components/Loader";
import { setDefaultAction } from "../../../redux/menu/Actions";
const Groups = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickedItem, setClickedItem] = useState(1);
  const {
    result_failed,
    result_message,
    result_successfull,
    loading_employees,
  } = useSelector((state) => state.employeesRedusers);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="common-styles"
      style={{
        height: window.innerHeight - 160,
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        {clickedItem == 1 ? (
          <GroupsList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 2 ? (
          <GroupsEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : null}
        {loading_employees ? <Loader /> : null}
      </div>
      <Toaster />
    </div>
  );
};

export default Groups;
