import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const getWorksStationsServices = async (login_key, user_id, shopId) => {
  return await axios.put(`${baseUrl}getworkstationlistbyshopid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
  });
};
export const createWorksStationsServices = async (
  login_key,
  user_id,
  shopId,
  work_station_name,
  device_id,
  device_type,
  active,
  licence_course,
  is_expired,
  active_secound_screen,
  get_order_online,
  shop_front_work_station,
  is_kitchen,
  password_protected_till_opening,
  print_customer_ticket,
  caller_id,
  has_cash_drawer
) => {
  return await axios.post(`${baseUrl}createworkstation/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    WorkStationName: work_station_name,
    DeviceId: device_id,
    DeviceType: device_type,
    Active: active,
    LicenceCourse: licence_course,
    IsExpired: is_expired,
    ActiveSecondScreen: active_secound_screen,
    GetOrderOnline: get_order_online,
    ShopFrontWorkStation: shop_front_work_station,
    IsKitchen: is_kitchen,
    PasswordProtectedTillOpening: password_protected_till_opening,
    PrintCustomerTicket: print_customer_ticket,
    CallerId: caller_id,
    HasCashDrawer: has_cash_drawer,
  });
};

export const updateWorksStationsServices = async (
  login_key,
  user_id,
  id,
  shopId,
  work_station_name,
  device_id,
  device_type,
  active,
  // expire_date,
  licence_course,
  active_secound_screen,
  get_order_online,
  shop_front_work_station,
  is_kitchen,
  password_protected_till_opening,
  print_customer_ticket,
  caller_id,
  has_cash_drawer
) => {
  return await axios.put(`${baseUrl}updateworkstation/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    ShopId: shopId,
    WorkStationName: work_station_name,
    DeviceId: device_id,
    DeviceType: device_type,
    Active: active,
    // LicenceCourse: expire_date,
    IsExpired: licence_course,
    ActiveSecondScreen: active_secound_screen,
    GetOrderOnline: get_order_online,
    ShopFrontWorkStation: shop_front_work_station,
    IsKitchen: is_kitchen,
    PasswordProtectedTillOpening: password_protected_till_opening,
    PrintCustomerTicket: print_customer_ticket,
    CallerId: caller_id,
    HasCashDrawer: has_cash_drawer,
  });
};

export const deleteWorksStationsServices = async (
  login_key,
  user_id,
  shop_id,
  workStation_id
) => {
  return await axios.delete(`${baseUrl}deleteworkstation/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      WorkstationId: workStation_id,
    },
  });
};

export const getWorksStationStatusService = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}deviceSyncStatusList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const updateWorksStationStatusService = async (
  login_key,
  user_id,
  shop_id,
  workStation_id
) => {
  return await axios.put(`${baseUrl}updateSyncStatus/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    WorkstationId: workStation_id,
  });
};

export const getWorkStationMainButtonsListService = async (
  shop_id,
  login_key,
  user_id,
  work_station_id
) => {
  return await axios.put(`${baseUrl}mainButtonsList/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
    WorkstationId: work_station_id,
  });
};
export const editWorkStationMainButtonsDetailsService = async (
  formData,
  shop_id,
  login_key,
  user_id,
  work_station_id,
  id,
  button_code,
  button_name,
  visible,
  enable,
  button_image,
  last_image_name
) => {
  return await axios.post(`${baseUrl}mainButtonUpdate/`, formData);
};
