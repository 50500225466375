import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./Loader";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  getNewSubmenuIdAction,
  importSubItemAction,
  //   showImportSubmenuPopupAction,
  showImportSubmenuPopupAction,
} from "../redux/submenu/Action";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import {
  getSubItemPublicListAction,
  getSubmenuPublicListActiom,
} from "../redux/MainNavGeneral/Actions";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import {
  getCategoriesListAction,
  setDefaultAction,
} from "../redux/menu/Actions";
import {
  getSubmenuItemAction,
  createSubmenuAction,
} from "../redux/submenu/Action";
import { getProductAction } from "../redux/product/Action";
const ImportSubmenuPopup = () => {
  const dispatch = useDispatch();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    submenu_public_list,
    selected_public_submneu,
    dropdown_submenu_public_list,
    subitem_public_list,
  } = useSelector((state) => state.MainNavGeneralRedusers);
  const {
    loading_submenu,
    result_message,
    result_failed,
    result_successfull,
    selected_submenu,
    submenu_item_list,
    is_created,
    new_submenu_id,
    submenu_list,
  } = useSelector((state) => state.submenuRedusers);
  const { category_list, selected_category, loading_category } = useSelector(
    (state) => state.categoryList
  );
  const { product_list, selected_Product } = useSelector(
    (state) => state.productList
  );

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { is_dressing } = useSelector((state) => state.submenuRedusers);
  const [submenuPublicList, setSubmenuPublicList] = useState([]);

  const [subItemPublicList, setSubItemPublicList] = useState([]);
  const [selectedSubmenuIds, setSelectedSubmenuIds] = useState([]);
  const [allSelect, setAllSelect] = useState(false);
  const [existedItems, setExistedItems] = useState([]);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const [subItemPublicIds, setSubItemPublicIds] = useState([]);
  const [catList, setCatList] = useState([]);
  const [catIds, setCatIds] = useState([]);
  const [importCategory, setImportCategory] = useState(false);
  const [mainItemList, setMainItemList] = useState([]);
  const [mainListOfDropDown, setMainListOfDropdown] = useState([]);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [focusedInputKey, setFocusedInputKey] = useState(1);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 43,
      boxShadow: "none",
      // zIndex: "5555555",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      zIndex: "555555555",
      maxHeight: 220,

      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",

      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleSelectAllItems = (e) => {
    e.preventDefault();
    let copy_list = [...subItemPublicList];

    if (allSelect) {
      for (let i = 0; i < copy_list.length; i++) {
        copy_list[i].selected = false;

        setSelectedSubmenuIds([]);
      }
    } else {
      for (let i = 0; i < copy_list.length; i++) {
        copy_list[i].selected = true;
        if (!subItemPublicIds.includes(copy_list[i].Id)) {
          selectedSubmenuIds.push(String(copy_list[i].Id));
        }
      }
    }
    setAllSelect(!allSelect);
    setSubItemPublicList(copy_list);
  };

  const handleSelectItem = (item) => {
    let copy_list = [...subItemPublicList];
    for (let i = 0; i < copy_list.length; i++) {
      if (copy_list[i].Id == item.Id) {
        if (copy_list[i].selected) {
          copy_list[i].selected = false;
          let new_list = selectedSubmenuIds.filter(
            (i) => parseInt(i) != parseInt(item.Id)
          );

          setSelectedSubmenuIds(new_list);
        } else {
          copy_list[i].selected = true;
          if (!subItemPublicIds.includes(item.Id)) {
            selectedSubmenuIds.push(item.Id);
          }
        }
      }
    }

    setSubItemPublicList(copy_list);
  };
  const handleImportSubItem = () => {
    if (subItemPublicIds.length > 0) {
      if (selectedSubmenuIds.length > 0) {
        dispatch(
          importSubItemAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selectedSubmenuIds.length > 0 ? selectedSubmenuIds.toString() : "",
            selected_submenu.Id,
            importCategory ? 1 : 0
          )
        );
      } else {
        toast.error(<h5> &nbsp;&nbsp;{lang.exited_items}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else {
      if (selectedSubmenuIds.length > 0) {
        dispatch(
          importSubItemAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selectedSubmenuIds.length > 0 ? selectedSubmenuIds.toString() : "",
            selected_submenu.Id,
            importCategory ? 1 : 0
          )
        );
      } else {
        toast.error(<h5> &nbsp;&nbsp;{lang.select_items}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  };

  const handleSaveAndImport = () => {
    let last_item = submenu_list[submenu_list.length - 1];
    // console.log("selectedSubmenuIds", selectedSubmenuIds);
    if (selectedSubmenu) {
      if (title == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (selectedSubmenuIds.length > 0) {
        dispatch(
          createSubmenuAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            title ? title : "",
            desc ? desc : "",
            0,
            selectedSubmenuIds.length > 0 ? selectedSubmenuIds.toString() : "",
            importCategory ? 1 : 0,
            1
          )
        );
      } else {
        toast.error(<h5> &nbsp;&nbsp;{lang.select_item}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: {
            backgroundColor: "red",
            color: "white",
            // zIndex: "99999999999999",
          },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getCategoriesListAction(login_key, user_id, selected_shop[0].ShopId, 0)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    let new_list = [];
    const copy_mainListOfDropDown = [...mainListOfDropDown];
    if (copy_mainListOfDropDown) {
      if (copy_mainListOfDropDown.length > 0) {
        for (let i = 0; i < copy_mainListOfDropDown.length; i++) {
          if (is_dressing) {
            new_list.push(copy_mainListOfDropDown[i]);
          } else {
            if (!copy_mainListOfDropDown[i].IsDressing) {
              new_list.push(copy_mainListOfDropDown[i]);
            }
          }
        }
      }
    }

    if (new_list.length > 0) {
      setSelectedSubmenu(new_list[0]);
    } else {
      setSelectedSubmenu(null);
    }

    setSubmenuPublicList(new_list);
  }, [is_dressing, mainListOfDropDown]);

  useEffect(() => {
    if (selectedSubmenu) {
      setTitle(selectedSubmenu.label);
      if (importCategory) {
        dispatch(
          getProductAction(
            login_key,
            user_id,
            selectedSubmenu.ShopId,
            selectedSubmenu.Id
          )
        );
      } else {
        dispatch(
          getSubItemPublicListAction(login_key, user_id, selectedSubmenu.value)
        );
      }
    }
  }, [selectedSubmenu]);

  useEffect(() => {
    let cat_list = [];
    let cat_ids = [];
    if (category_list) {
      if (category_list.length > 0) {
        for (let i = 0; i < category_list.length; i++) {
          cat_list.push({
            ...category_list[i],
            label: category_list[i].AppTitle,
            value: category_list[i].Id,
          });
          cat_ids.push(category_list[i].Id);
        }
      }
      setCatIds(cat_ids);
      setCatList(cat_list);
    }
  }, [category_list]);

  useEffect(() => {
    if (importCategory) {
      if (product_list) {
        if (product_list.length > 0) {
          setMainItemList(product_list);
          setSubItemPublicList(product_list);
        } else {
          setMainItemList([]);
          setSubItemPublicList([]);
        }
      } else {
        setMainItemList([]);
        setSubItemPublicList([]);
      }
    } else {
      if (subitem_public_list) {
        if (subitem_public_list.length > 0) {
          setMainItemList(subitem_public_list);
          setSubItemPublicList(subitem_public_list);
        } else {
          setMainItemList([]);
          setSubItemPublicList([]);
        }
      } else {
        setMainItemList([]);
        setSubItemPublicList([]);
      }
    }
    setSelectedSubmenuIds([]);
  }, [subitem_public_list, product_list, importCategory]);

  // useEffect(() => {
  //   let new_list = [];
  //   let selected_ids = [];
  //   const copy_subItemPublicList = [...mainItemList];
  //   if (copy_subItemPublicList) {
  //     if (copy_subItemPublicList.length > 0) {
  //       for (let index = 0; index < copy_subItemPublicList.length; index++) {
  //         if (submenu_item_list) {
  //           if (submenu_item_list.length > 0) {
  //             for (let i = 0; i < submenu_item_list.length; i++) {
  //               if (submenu_item_list[i].SubItemPublicId) {
  //                 new_list.push(submenu_item_list[i].SubItemPublicId);
  //                 if (
  //                   submenu_item_list[i].SubItemPublicId ==
  //                   copy_subItemPublicList[index].Id
  //                 ) {
  //                   copy_subItemPublicList[index].selected = true;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }

  //       for (let index = 0; index < copy_subItemPublicList.length; index++) {
  //         if (copy_subItemPublicList[index].selected) {
  //           selected_ids.push(copy_subItemPublicList[index].Id);
  //         }
  //       }
  //       if (copy_subItemPublicList.length == selected_ids.length) {
  //         setAllSelect(true);
  //       } else {
  //         setAllSelect(false);
  //       }

  //       setSubItemPublicIds(new_list);
  //       setSubItemPublicList(copy_subItemPublicList);
  //     } else {
  //       setSubItemPublicList([]);
  //     }
  //   } else {
  //     setSubItemPublicList([]);
  //   }
  // }, [mainItemList, importCategory]);

  useEffect(() => {
    if (importCategory) {
      if (catList) {
        if (catList.length > 0) {
          setMainListOfDropdown(catList);
        } else {
          setMainListOfDropdown([]);
        }
      } else {
        setMainListOfDropdown([]);
      }
    } else {
      if (dropdown_submenu_public_list) {
        if (dropdown_submenu_public_list.length > 0) {
          setMainListOfDropdown(dropdown_submenu_public_list);
        } else {
          setMainListOfDropdown([]);
        }
      } else {
        setMainListOfDropdown([]);
      }
    }
  }, [dropdown_submenu_public_list, catList, importCategory]);

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showImportSubmenuPopupAction(false))}
    >
      <div
        className="item-list-popup-container-all-section"
        onClick={(i) => i.stopPropagation()}
      >
        <div className="item-list-popup">
          <div
            className="shop-group-container-submenu-dropdown"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ width: "71%" }}>
              <Select
                options={submenuPublicList}
                defaultValue={selectedSubmenu}
                isSearchable={false}
                onChange={(list, item) => setSelectedSubmenu(list)}
                value={selectedSubmenu}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />{" "}
            </div>
            {is_dressing ? null : (
              <button onClick={() => setImportCategory(!importCategory)}>
                {importCategory ? "Import submenu" : "Import category"}
              </button>
            )}
          </div>
          <div
            className="shop-group-container-submenu-dropdown"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "25px 0px 15px 0px",
            }}
          >
            <Box
              component="form"
              style={{ width: "100%", zIndex: 0 }}
              className="menu-list-container-inputs"
              autoComplete="on"
            >
              <TextField
                key={1}
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",

                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                label={`*${lang.title}`}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": { top: 0, zIndex: "0" },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                    "& label.Mui-focused": {
                      color: primaryColor,
                    },
                    "&:hover label": {
                      color: primaryColor,
                    },
                  },
                }}
                value={title}
                // defaultValue={number == 9 ? postCode : appTitle}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  width: "49%",
                }}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
              />
              <TextField
                key={1}
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",

                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                label={lang.description}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": { top: 0, zIndex: "0" },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                    "& label.Mui-focused": {
                      color: primaryColor,
                    },
                    "&:hover label": {
                      color: primaryColor,
                    },
                  },
                }}
                value={desc}
                // defaultValue={number == 9 ? postCode : appTitle}
                onChange={(e) => setDesc(e.target.value)}
                style={{
                  width: "49%",
                }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
              />
            </Box>
          </div>
          <div className="shop-group-container-main">
            {subItemPublicList ? (
              subItemPublicList.length > 1 ? (
                <div
                  className="item-list-popup-container-all-select-checkbox "
                  onClick={(e) => handleSelectAllItems(e)}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allSelect}
                          onChange={(e) => handleSelectAllItems(e)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                        />
                      }
                      label={lang.all}
                    />
                  </FormGroup>
                </div>
              ) : null
            ) : null}

            <div className="shop_group_container_shops_list item-list-popup-container-all-items">
              <div className=" item-list-popup-container-any-item">
                {subItemPublicList ? (
                  subItemPublicList.length > 0 ? (
                    subItemPublicList.map((item, index) =>
                      index % 2 == 0 ? (
                        <div key={index} onClick={() => handleSelectItem(item)}>
                          <div
                            className=" shop_group_container_any_shop item-list-popup-any-item"
                            style={{
                              backgroundColor: item.InProductSubmenu
                                ? "#bdbdbd"
                                : null,
                            }}
                          >
                            {" "}
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      item.selected ||
                                      subItemPublicIds.includes(item.Id)
                                        ? true
                                        : false
                                    }
                                    // onChange={() => handleSelectItem(item)}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: primaryColor,
                                      },
                                    }}
                                  />
                                }
                                // label={lang.incloud_half_and_half}
                              />
                            </FormGroup>
                            <p>{item.AppTitle}</p>
                          </div>
                        </div>
                      ) : null
                    )
                  ) : (
                    <div className="item-list-popup-container-no-item-text">
                      <h2 id="noOption">{lang.noOption}</h2>
                    </div>
                  )
                ) : (
                  <div className="item-list-popup-container-no-item-text">
                    <h2 id="noOption">{lang.noOption}</h2>
                  </div>
                )}
              </div>
              <div className="item-list-popup-container-any-item">
                {subItemPublicList
                  ? subItemPublicList.map((item, index) =>
                      index % 2 != 0 ? (
                        <div key={index} onClick={() => handleSelectItem(item)}>
                          <div
                            className=" shop_group_container_any_shop item-list-popup-any-item"
                            style={{
                              backgroundColor: item.InProductSubmenu
                                ? "#bdbdbd"
                                : null,
                            }}
                          >
                            {" "}
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      item.selected ||
                                      subItemPublicIds.includes(item.Id)
                                        ? true
                                        : false
                                    }
                                    // onChange={() => handleSelectItem(item)}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: primaryColor,
                                      },
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                            <p>{item.AppTitle}</p>
                          </div>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            </div>
          </div>
          <div className=" item-list-popup-container-buttons">
            <button
              onClick={() => dispatch(showImportSubmenuPopupAction(false))}
            >
              cancel
            </button>
            <button type="" onClick={() => handleSaveAndImport()}>
              {/* {new_submenu_id ? "Import" : "Save"} */}
              {lang.save}
            </button>
          </div>
        </div>
      </div>
      <Toaster />
      {loading_submenu || loading_category ? <Loader /> : null}
    </div>
  );
};

export default ImportSubmenuPopup;
