import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const getPrivacyPolicyService = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}privacyPolicyGet/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};

export const updatePrivacyPolicyService = async (
  login_key,
  user_id,
  privacy_policy
) => {
  return await axios.put(`${baseUrl}updatePrivacyPolicy/`, {
    LoginKey: login_key,
    UserId: user_id,
    PrivacyPolicy: privacy_policy,
  });
};

// EXTERNAL COMPANY

export const getExternalCompanyService = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}externalCompanyList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};

export const updateExternalCompanyService = async (
  formData,
  login_key,
  user_id,
  external_company_id,
  title,
  shop_group_id,
  profile_image,
  last_profile_image_name
) => {
  return await axios.post(
    `${baseUrl}updateExternalCompany/`,
    formData
    // LoginKey: login_key,
    // UserId: user_id,
    // ExternalCompanyId: external_company_id,
    // Title: title,
    // ShopGroupId: shop_group_id,
    // ProfileImage: profile_image,
    // LastProfileImageName: last_profile_image_name,
  );
};
//PUBLIC SUBMENU
export const getSubmenuPublicListService = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}subMenuPublicList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};

//SUBITEM PUBLIC
export const getSubItemPublicListService = async (
  login_key,
  user_id,
  sub_id
) => {
  return await axios.put(`${baseUrl}subItemPublicList/`, {
    LoginKey: login_key,
    UserId: user_id,
    SubMenuId: sub_id,
  });
};

//CREATE SUBITEM
export const createPublicSubItemService = async (
  login_key,
  user_id,
  sub_id,
  title,
  description
) => {
  return await axios.put(`${baseUrl}createSubItemPublic/`, {
    LoginKey: login_key,
    UserId: user_id,
    SubMenuId: sub_id,
    AppTitle: title,
    AppDescription: description,
  });
};
//UPDATE SUBITEM
export const updatePublicSubItemService = async (
  formData
  // login_key,
  // user_id,
  // item_id,
  // app_title,
  // app_desc
) => {
  return await axios.post(
    `${baseUrl}updateSubItemPublic/`,
    formData
    // {
    // LoginKey: login_key,
    // UserId: user_id,
    // SubItemId: item_id,
    // AppTitle: app_title,
    // AppDescription: app_desc,
    // }
  );
};
//DELETE SUBITEM
export const deletePublicSubItemService = async (
  login_key,
  user_id,
  item_id
) => {
  return await axios.put(`${baseUrl}deleteSubItemPublic/`, {
    LoginKey: login_key,
    UserId: user_id,
    SubItemId: item_id,
  });
};

// GET CUSTOMERS CALL LIST
export const getCustomersCallListService = async (
  login_key,
  user_id,
  shop_id,
  text_search
) => {
  return await axios.put(`${baseUrl}customerCallListExport/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    txtSearch: text_search,
  });
};
