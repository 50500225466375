import * as actionTypes from "../ActionTypes";

const initialStates = {
  appSettings_orderProccess_list: [],
  result_successfull: false,
  result_failed: false,
  result_failed_promotion_update: false,
  result_message: "",
  loading_appSettings: false,
  setting_app_payment: [],
  setting_app_orderprocess: [],
  setting_app_colour: [],
  setting_app_display: [],
  setting_app_printer: [],
  setting_app_taking: [],
  setting_app_passwordprotect: [],
  deleivery_charge_type_list: [],
  selected_custom_charge: null,
  selected_delivery_charge: null,
  visible_cusom_charge: false,
  get_delivery_setting_list: [],
  code_detail_list: [],
  visible_code_detail: false,
  selected_code_detail: null,
  changed_code_detail_list: [],
  selected_group_id: 1,
  web_setting_list: [],
  open_close_time_list: [],
  web_payment_list: [],
  web_setting_stripe_list: [],
  get_shop_setting_time_gap_list: [],
  get_web_delivery_list: [],
  get_maintenance_list: [],
  maintenance_message_list: [],
  get_payment_link_list: [],
  get_notification_email_list: [],
  show_notification_email_popup: false,
  selected_notification_email: null,
  get_promotions_list: [],
  selected_promotion: null,
  number_of_prints_list: [],
  get_payment_dojo_setting_list: [],
  selected_dojo_setting: null,
  show_stripe_popup: false,
  show_second_screen_image_details_popup: false,
  second_screen_images_list: [],
  selected_second_screen_image: null,
  second_screen_image_delete_popup: false,
  adyen_list: [],
  sales_report_list: [],
  meta_data_list: [],
};

const appSettingsRedusers = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: false,
        result_failed_promotion_update: false,
      };
    case actionTypes.GET_ALL_APP_SETTINGS:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_ALL_APP_SETTINGS_SUCCESSFULL:
      return {
        ...state,
        setting_app_payment: action.payload[0]
          ? action.payload[0]
          : state.setting_app_payment,
        setting_app_orderprocess: action.payload[1]
          ? action.payload[1]
          : state.setting_app_orderprocess,
        setting_app_colour: action.payload[2]
          ? action.payload[2]
          : state.setting_app_colour,
        setting_app_display: action.payload[3]
          ? action.payload[3]
          : state.setting_app_display,
        setting_app_printer: action.payload[4]
          ? action.payload[4]
          : state.setting_app_printer,
        setting_app_taking: action.payload[5]
          ? action.payload[5]
          : state.setting_app_taking,
        setting_app_passwordprotect: action.payload[6]
          ? action.payload[6]
          : state.setting_app_passwordprotect,
        sales_report_list: action.payload[7]
          ? action.payload[7]
          : state.sales_report_list,
        loading_appSettings: false,
      };
    case actionTypes.GET_ALL_APP_SETTINGS_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        setting_app_payment: [],
        setting_app_orderprocess: [],
        setting_app_colour: [],
        setting_app_display: [],
        setting_app_printer: [],
        setting_app_taking: [],
        setting_app_passwordprotect: [],
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_PAYMENT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_PAYMENT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_PAYMENT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_COLOUR:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_COLOUR_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_COLOUR_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_DISPLAY:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_DISPLAY_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_DISPLAY_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_DELIVERY_CHARGE:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_DELIVERY_CHARGE_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
        visible_cusom_charge: false,
      };
    case actionTypes.UPDATE_DELIVERY_CHARGE_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.GET_DELIVERY_CHARGE_TYPE:
      return {
        ...state,

        loading_appSettings: true,
      };
    case actionTypes.GET_DELIVERY_CHARGE_TYPE_SUCCESSFULL:
      return {
        ...state,
        deleivery_charge_type_list: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.GET_DELIVERY_CHARGE_TYPE_FAILED:
      return {
        ...state,
        deleivery_charge_type_list: [],
        loading_appSettings: false,
      };
    case actionTypes.CRETAE_DELIVERY_CHARGE:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.CRETAE_DELIVERY_CHARGE_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
        visible_cusom_charge: false,
      };
    case actionTypes.CRETAE_DELIVERY_CHARGE_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.DELETE_DELIVERY_CHARGE:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.DELETE_DELIVERY_CHARGE_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.DELETE_DELIVERY_CHARGE_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.SELECTED_DELIVERY_CHARGE_TYPE:
      return {
        ...state,
        selected_custom_charge: action.payload,
      };
    case actionTypes.VISIBLE_CUTOM_CHARGE:
      return {
        ...state,
        visible_cusom_charge: action.payload,
      };
    case actionTypes.GET_DELIVERY_SETTING:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_DELIVERY_SETTING_SUCCESSFULL:
      return {
        ...state,
        get_delivery_setting_list: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.GET_DELIVERY_SETTING_FAILED:
      return {
        ...state,
        get_delivery_setting_list: [],
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_DELIVERY_SETTING:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_DELIVERY_SETTING_SUCCESFFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
        visible_cusom_charge: false,
      };
    case actionTypes.UPDATE_DELIVERY_SETTING_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.CODE_DETAIL_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.CODE_DETAIL_LIST_SUCCESSFULL:
      return {
        ...state,
        code_detail_list: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.CODE_DETAIL_LIST_FAILED:
      return {
        ...state,
        code_detail_list: [],
        loading_appSettings: false,
      };
    case actionTypes.CREATE_CODE_DETAIL:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.CREATE_CODE_DETAIL_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
        visible_code_detail: false,
      };
    case actionTypes.CREATE_CODE_DETAIL_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_CODE_DETAIL:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_CODE_DETAIL_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
        visible_code_detail: false,
      };
    case actionTypes.UPDATE_CODE_DETAIL_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.DELETE_CODE_DETAIL:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.DELETE_CODE_DETAIL_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.DELETE_CODE_DETAIL_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.VISIBLE_CODE_DETAIL:
      return {
        ...state,
        visible_code_detail: action.payload,
      };
    case actionTypes.SELECTED_CODE_DETEIL:
      return {
        ...state,
        selected_code_detail: action.payload,
      };
    case actionTypes.CHANGED_CODE_DETAIL_LIST:
      return {
        ...state,
        changed_code_detail_list: action.payload,
      };
    case actionTypes.SELECTED_GROUP_ID:
      return {
        ...state,
        selected_group_id: action.payload,
      };
    case actionTypes.GET_WEB_SETTING:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_WEB_SETTING_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        web_setting_list: action.payload,
      };
    case actionTypes.GET_WEB_SETTING_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        web_setting_list: [],
      };
    case actionTypes.UPDATE_WEB_SETTING:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_WEB_SETTING_SUCCESFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_WEB_SETTING_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.GET_OPEN_CLOSE_TIME_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_OPEN_CLOSE_TIME_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        open_close_time_list: action.payload,
      };

    case actionTypes.GET_OPEN_CLOSE_TIME_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        open_close_time_list: [],
      };
    case actionTypes.UPDATE_OPEN_CLOSE_TIME:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };
    case actionTypes.UPDATE_OPEN_CLOSE_TIME_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionTypes.UPDATE_OPEN_CLOSE_TIME_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };
    case actionTypes.CREATE_OPEN_CLOSE_TIME:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };
    case actionTypes.CREATE_OPEN_CLOSE_TIME_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionTypes.CREATE_OPEN_CLOSE_TIME_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };
    case actionTypes.GET_WEB_PAYMENT_SETTING_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_WEB_PAYMENT_SETTING_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        web_payment_list: action.payload,
      };
    case actionTypes.GET_WEB_PAYMENT_SETTING_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_failed: true,
        web_payment_list: [],
      };
    case actionTypes.UPDATE_WEB_PAYMENT_SETTING:
      return {
        ...state,
        loading_appSettings: true,
        result_message: "",
        result_failed: false,
        result_successfull: false,
      };
    case actionTypes.UPDATE_WEB_PAYMENT_SETTING_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_failed: false,
        result_successfull: true,
      };
    case actionTypes.UPDATE_WEB_PAYMENT_SETTING_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_failed: true,
        result_successfull: false,
      };
    //STRIPE
    case actionTypes.GET_STRIPE_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_STRIPE_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        web_setting_stripe_list: action.payload,
        result_message: action.payload,
      };
    case actionTypes.GET_STRIPE_LIST_FAILED:
      return {
        ...state,
        web_setting_stripe_list: [],
        loading_appSettings: false,
        result_message: action.payload,
        result_failed: true,
      };
    case actionTypes.UPDATE_STRIPE:
      return {
        ...state,
        loading_appSettings: true,
        result_message: "",
        result_failed: false,
        result_successfull: false,
      };
    case actionTypes.UPDATE_STRIPE_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_failed: false,
        result_successfull: true,
      };
    case actionTypes.UPDATE_STRIPE_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_failed: true,
        result_successfull: false,
      };
    case actionTypes.SHOW_STRIPE_POPUP:
      return {
        ...state,
        show_stripe_popup: action.payload,
      };
    case actionTypes.GET_SHOP_SETTING_TIME_GAP_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_SHOP_SETTING_TIME_GAP_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        get_shop_setting_time_gap_list: action.payload,
      };
    case actionTypes.GET_SHOP_SETTING_TIME_GAP_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        get_shop_setting_time_gap_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_SHOP_SETTING_TIME_GAP:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_message: false,
        result_successfull: false,
      };
    case actionTypes.UPDATE_SHOP_SETTING_TIME_GAP_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionTypes.UPDATE_SHOP_SETTING_TIME_GAP_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };

    //WEB DELIVERY

    case actionTypes.GET_WEB_DELIVERY_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_WEB_DELIVERY_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        get_web_delivery_list: action.payload,
      };
    case actionTypes.GET_WEB_DELIVERY_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        get_web_delivery_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_WEB_DELIVERY:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_message: false,
        result_successfull: false,
      };
    case actionTypes.UPDATE_WEB_DELIVERY_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionTypes.UPDATE_WEB_DELIVERY_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };
    case actionTypes.GET_MAINTENANCE_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_MAINTENANCE_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        get_maintenance_list: action.payload,
      };
    case actionTypes.GET_MAINTENANCE_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        get_maintenance_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_MAINTENANCE:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };
    case actionTypes.UPDATE_MAINTENANCE_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionTypes.UPDATE_MAINTENANCE_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };
    case actionTypes.MAINTENACE_MESSAGE_LIST:
      return {
        ...state,
        maintenance_message_list: action.payload,
      };
    case actionTypes.GET_PAYMENT_LINK:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.GET_PAYMENT_LINK_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        get_payment_link_list: action.payload,
      };
    case actionTypes.GET_PAYMENT_LINK_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
        get_payment_link_list: [],
      };

    case actionTypes.UPDATE_PAYMENT_LINK:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };
    case actionTypes.UPDATE_PAYMENT_LINK_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionTypes.UPDATE_PAYMENT_LINK_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };

    //NOTIFICATION EMAIL
    case actionTypes.GET_NOTIFICATION_EMAIL_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_NOTIFICATION_EMAIL_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        get_notification_email_list: action.payload,
      };
    case actionTypes.GET_NOTIFICATION_EMAIL_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        get_notification_email_list: [],
        result_failed: true,
      };
    case actionTypes.CREATE_NOTIFICATION_EMAIL:
      return {
        ...state,
        loading_appSettings: true,
        result_message: "",
        result_successfull: false,
        result_failed: false,
      };
    case actionTypes.CREATE_NOTIFICATION_EMAIL_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
        show_notification_email_popup: false,
      };
    case actionTypes.CREATE_NOTIFICATION_EMAIL_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
        show_notification_email_popup: false,
      };
    case actionTypes.UPDATE_NOTIFICATION_EMAIL:
      return {
        ...state,
        loading_appSettings: true,
        result_message: "",
        result_successfull: false,
        result_failed: false,
      };
    case actionTypes.UPDATE_NOTIFICATION_EMAIL_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
        show_notification_email_popup: false,
      };
    case actionTypes.UPDATE_NOTIFICATION_EMAIL_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
      };
    case actionTypes.DELETE_NOTIFICATION_EMAIL:
      return {
        ...state,
        loading_appSettings: true,
        result_message: "",
        result_successfull: false,
        result_failed: false,
      };
    case actionTypes.DELETE_NOTIFICATION_EMAIL_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
      };
    case actionTypes.DELETE_NOTIFICATION_EMAIL_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
      };
    case actionTypes.SHOW_NOTIFICATION_EMAIL_POPUP:
      return {
        ...state,
        show_notification_email_popup: action.payload,
      };
    case actionTypes.SELECTED_NOTIFICATION_EMAIL:
      return {
        ...state,
        selected_notification_email: action.payload,
      };
    case actionTypes.GET_PROMOTION_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_PROMOTION_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_successfull: false,
        result_message: "",

        get_promotions_list: action.payload,
      };
    case actionTypes.GET_PROMOTION_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        get_promotions_list: [],
      };
    case actionTypes.CREATE_PROMOTION:
      return {
        ...state,
        loading_appSettings: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.CREATE_PROMOTION_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.CREATE_PROMOTION_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_PROMOTION:
      return {
        ...state,
        loading_appSettings: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        result_failed_promotion_update: false,
      };
    case actionTypes.UPDATE_PROMOTION_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        result_failed_promotion_update: false,
      };
    case actionTypes.UPDATE_PROMOTION_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        result_failed_promotion_update: true,
      };
    case actionTypes.DELETE_PROMOTION:
      return {
        ...state,
        loading_appSettings: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.DELETE_PROMOTION_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.DELETE_PROMOTION_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.SELECTED_PROMOTION:
      return {
        ...state,
        selected_promotion: action.payload,
      };
    case actionTypes.GET_NUMBERS_OF_PRINTS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.GET_NUMBERS_OF_PRINTS_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: "",
        loading_appSettings: false,
        number_of_prints_list: action.payload,
      };
    case actionTypes.GET_NUMBERS_OF_PRINTS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
        number_of_prints_list: [],
      };
    case actionTypes.UPDATE_NUMBER_OF_PRINTS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_NUMBER_OF_PRINTS_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_NUMBER_OF_PRINTS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };

    //PAYMENT DOJO SETTING

    case actionTypes.GET_PAYMENT_DOJO_SETTING_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_PAYMENT_DOJO_SETTING_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_message: "",
        get_payment_dojo_setting_list: action.payload,
      };
    case actionTypes.GET_PAYMENT_DOJO_SETTING_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_message: action.payload,
        get_payment_dojo_setting_list: [],
      };
    case actionTypes.UPDATE_PAYMENT_DOJO_SETTING:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_PAYMENT_DOJO_SETTING_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_PAYMENT_DOJO_SETTING_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.CREATE_PAYMENT_DOJO_SETTING:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.CREATE_PAYMENT_DOJO_SETTING_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.CREATE_PAYMENT_DOJO_SETTING_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.DELETE_PAYMENT_DOJO_SETTING:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_appSettings: true,
      };
    case actionTypes.DELETE_PAYMENT_DOJO_SETTING_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.DELETE_PAYMENT_DOJO_SETTING_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_appSettings: false,
      };
    case actionTypes.SELECTED_PAYMENT_DOJO_SETTING:
      return {
        ...state,
        selected_dojo_setting: action.payload,
      };
    case actionTypes.SHOW_SECOND_SCREEN_IMAGE_DETAILS_POPUP:
      return {
        ...state,
        show_second_screen_image_details_popup: action.payload,
      };

    // SECOND SCREEN
    case actionTypes.GET_SECOND_SCREEN_IMAGES_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_SECOND_FULL_SCREEN_IMAGES_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        second_full_screen_images_list: action.payload,
      };
    case actionTypes.GET_SECOND_HALF_SCREEN_IMAGES_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        second_half_screen_images_list: action.payload,
      };
    case actionTypes.GET_SECOND_SCREEN_IMAGES_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        second_half_screen_images_list: [],
        second_full_screen_images_list: [],
        result_message: action.payload,
        result_failed: true,
      };

    case actionTypes.CREATE_SECOND_SCREEN_IMAGE:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.CREATE_SECOND_SCREEN_IMAGE_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        show_second_screen_image_details_popup: false,
      };
    case actionTypes.CREATE_SECOND_SCREEN_IMAGE_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
      };
    case actionTypes.DELETE_SECOND_SCREEN_EACH_PHOTO:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.DELETE_SECOND_SCREEN_EACH_PHOTO_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        second_screen_image_delete_popup: false,
      };
    case actionTypes.DELETE_SECOND_SCREEN_EACH_PHOTO_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
      };
    case actionTypes.SELECTED_SECOND_SCREEN_IMAGE:
      return {
        ...state,
        selected_second_screen_image: action.payload,
      };
    case actionTypes.SECOND_SCREEN_IMAGE_DELETE_POPUP:
      return {
        ...state,
        second_screen_image_delete_popup: action.payload,
      };

    //WEB SETTING ADYEN
    case actionTypes.GET_ADYEN_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_ADYEN_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        adyen_list: action.payload,
      };
    case actionTypes.GET_ADYEN_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        adyen_list: [],
      };
    case actionTypes.UPDATE_ADYEN:
      return {
        ...state,
        loading_appSettings: true,
        result_failed: false,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.UPDATE_ADYEN_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_ADYEN_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
      };

    //SALE REPORT
    case actionTypes.UPDATE_SALES_REPORT:
      return {
        ...state,
        loading_appSettings: true,
        result_message: "",
        result_successfull: false,
        result_failed: false,
      };

    case actionTypes.UPDATE_SALES_REPORT_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
      };
    case actionTypes.UPDATE_SALES_REPORT_FAILED:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
        loading_appSettings: false,
      };
    // META DATA
    case actionTypes.GET_META_DATA_LIST:
      return {
        ...state,
        loading_appSettings: true,
      };
    case actionTypes.GET_META_DATA_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_appSettings: false,
        meta_data_list: action.payload,
      };
    case actionTypes.GET_META_DATA_LIST_FAILED:
      return {
        ...state,
        loading_appSettings: false,
        meta_data_list: [],
      };
    case actionTypes.UPDATE_META_DATA:
      return {
        ...state,
        result_message: "",
        result_successfull: false,
        result_failed: false,
        loading_appSettings: true,
      };
    case actionTypes.UPDATE_META_DATA_SUCCESSFULL:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: true,
        result_failed: false,
        loading_appSettings: false,
      };
    case actionTypes.UPDATE_META_DATA_FAILED:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: false,
        result_failed: true,
        loading_appSettings: false,
      };

    default:
      return state;
  }
};
export default appSettingsRedusers;
