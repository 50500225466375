import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../../components/Loader";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
import {
  getWebPaymentSettingListAction,
  updateWebPaymentSettingAction,
} from "../../../../redux/settings/Action";
import { setDefaultAction } from "../../../../redux/menu/Actions";
const Payments = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    loading_appSettings,
    web_payment_list,
    result_failed,
    result_successfull,
    result_message,
  } = useSelector((state) => state.appSettingsRedusers);
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [label, setLabel] = useState("");
  const [amount, setAmount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [webPayemntList, setWebPaymentList] = useState([]);
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "black",
      fontSize: "15px",
      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getWebPaymentSettingListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId
        )
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (web_payment_list.length > 0) {
      setVisible(web_payment_list[0].CreditCardSurchargeVisible);
      if (web_payment_list[0].CreditCardSurchargeLabel) {
        setLabel(web_payment_list[0].CreditCardSurchargeLabel);
      } else {
        setLabel("");
      }
      if (web_payment_list[0].CreditCardSurcharge) {
        setAmount(web_payment_list[0].CreditCardSurcharge);
      } else {
        setAmount("");
      }
    }
  }, [web_payment_list]);
  const handleSavePayment = () => {
    if (visible) {
      if (amount == "" || label == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateWebPaymentSettingAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            web_payment_list[0].Id,
            web_payment_list[0].DeliveryPaymentMethod[0].value,
            web_payment_list[0].DeliveryPaymentMethod[1].value,
            web_payment_list[0].DeliveryPaymentMethod[2].value,
            web_payment_list[0].CollectionPaymentMethod[0].value,
            web_payment_list[0].CollectionPaymentMethod[1].value,
            web_payment_list[0].CollectionPaymentMethod[2].value,
            amount ? parseFloat(amount) : 0,
            label,
            visible ? 1 : 0
          )
        );
      }
    } else {
      dispatch(
        updateWebPaymentSettingAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          web_payment_list[0].Id,
          web_payment_list[0].DeliveryPaymentMethod[0].value,
          web_payment_list[0].DeliveryPaymentMethod[1].value,
          web_payment_list[0].DeliveryPaymentMethod[2].value,
          web_payment_list[0].CollectionPaymentMethod[0].value,
          web_payment_list[0].CollectionPaymentMethod[1].value,
          web_payment_list[0].CollectionPaymentMethod[2].value,
          amount ? parseFloat(amount) : 0,
          label,
          visible ? 1 : 0
        )
      );
    }
  };
  const handleSetDeliveryPaymentMethodCheckbox = (item) => {
    const copy_web_payment_list = [...webPayemntList];

    for (
      let i = 0;
      i < copy_web_payment_list[0].DeliveryPaymentMethod.length;
      i++
    ) {
      if (item.Id == copy_web_payment_list[0].DeliveryPaymentMethod[i].Id) {
        if (copy_web_payment_list[0].DeliveryPaymentMethod[i].value == 1) {
          copy_web_payment_list[0].DeliveryPaymentMethod[i].value = 0;
        } else {
          copy_web_payment_list[0].DeliveryPaymentMethod[i].value = 1;
        }
      }
    }
    setWebPaymentList(copy_web_payment_list);
  };
  const handleSetCollectionPaymentMethodCheckbox = (item) => {
    const copy_web_payment_list = [...webPayemntList];

    for (
      let i = 0;
      i < copy_web_payment_list[0].CollectionPaymentMethod.length;
      i++
    ) {
      if (item.Id == copy_web_payment_list[0].CollectionPaymentMethod[i].Id) {
        if (copy_web_payment_list[0].CollectionPaymentMethod[i].value == 1) {
          copy_web_payment_list[0].CollectionPaymentMethod[i].value = 0;
        } else {
          copy_web_payment_list[0].CollectionPaymentMethod[i].value = 1;
        }
      }
    }
    setWebPaymentList(copy_web_payment_list);
  };
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      if (!web_payment_list) {
        dispatch(setDefaultAction());
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    if (web_payment_list.length > 0) {
      setWebPaymentList(web_payment_list);
    }
  }, [web_payment_list]);
  useImperativeHandle(forwardedRef, () => ({
    savePayments: () => {
      handleSavePayment();
    },
  }));
  const handleTryAgain = () => {
    dispatch(
      getWebPaymentSettingListAction(
        login_key,
        user_id,
        selected_shop[0].ShopId
      )
    );
  };

  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     if (event.key === "Enter") {
  //       handleSavePayment();
  //     }
  //   };

  //   document.addEventListener("keydown", keyDownHandler);

  //   return () => {
  //     document.removeEventListener("keydown", keyDownHandler);
  //   };
  // }, [
  //   webPayemntList[0].DeliveryPaymentMethod[0].value,
  //   webPayemntList[0].DeliveryPaymentMethod[1].value,
  //   webPayemntList[0].DeliveryPaymentMethod[2].value,
  //   webPayemntList[0].CollectionPaymentMethod[0].value,
  //   webPayemntList[0].CollectionPaymentMethod[1].value,
  //   webPayemntList[0].CollectionPaymentMethod[2].value,
  //   amount,
  //   label,
  //   visible,
  // ]);
  return (
    <div
      className="countaining-div "
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      {web_payment_list.length > 0 ? (
        <div
          className="menu_list_header_container"
          style={{
            paddingTop: "20px",
          }}
        >
          <div className="margin_menu_normal_horizontal">
            <div className="web_payment_container_payment_methods_box">
              <h5>{lang.payment_maethod}</h5>
              <div className="web_payment_container">
                <div style={{ width: "50%" }}>
                  <h6>{lang.delivery}</h6>
                  <div className="web_payment_container_delivery_collection_box">
                    {webPayemntList.length > 0
                      ? webPayemntList[0].DeliveryPaymentMethod.map(
                          (item, index) => (
                            <FormGroup sx={{ marginRight: "20px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={item.value == 1 ? true : false}
                                    onChange={() =>
                                      handleSetDeliveryPaymentMethodCheckbox(
                                        item
                                      )
                                    }
                                    sx={{
                                      "&.Mui-checked": {
                                        color: primaryColor,
                                      },
                                    }}
                                  />
                                }
                                label={item.title}
                              />
                            </FormGroup>
                          )
                        )
                      : null}
                  </div>
                </div>
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <h6>{lang.collection}</h6>
                  <div className="web_payment_container_delivery_collection_box">
                    {webPayemntList.length > 0
                      ? webPayemntList[0].CollectionPaymentMethod.map(
                          (item, index) => (
                            <FormGroup sx={{ marginRight: "20px" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={item.value == 1 ? true : false}
                                    onChange={() =>
                                      handleSetCollectionPaymentMethodCheckbox(
                                        item
                                      )
                                    }
                                    sx={{
                                      // "& .MuiSvgIcon-root": { marginLeft: "10px" },
                                      "&.Mui-checked": {
                                        color: primaryColor,
                                      },
                                    }}
                                  />
                                }
                                label={item.title}
                              />
                            </FormGroup>
                          )
                        )
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h5 className="web_payment_h5_sure_charge">{lang.admin_fee}</h5>
              <div className="web_payment_container_sure_charge_box">
                <Box
                  className="web_payment_mui_box"
                  component="form"
                  // sx={{
                  //   "& > :not(style)": { m: 1, width: "35ch", height: "20ch" },
                  // }}
                  // noValidate

                  autoComplete="on"
                >
                  <CssTextField
                    InputLabelProps={{
                      sx: {
                        fontSize: 16,
                        top: -5,
                        "&.MuiInputLabel-shrink": { top: 0 },
                        // "&.Mui-focused": {
                        //   color: primary_color,
                        // },
                      },
                    }}
                    key={1}
                    label={visible ? `* ${lang.label}` : lang.label}
                    value={label}
                    defaultValue={label}
                    onChange={(e) => setLabel(e.target.value)}
                    style={{
                      width: "43.5%",
                    }}
                    autoFocus={focusedInputKey == 1 ? true : false}
                    onFocus={() => setFocusedInputKey(1)}
                    onBlur={() => setFocusedInputKey(null)}
                  />
                  <CssTextField
                    InputLabelProps={{
                      sx: {
                        fontSize: 16,
                        top: -5,
                        "&.MuiInputLabel-shrink": { top: 0 },
                        // "&.Mui-focused": {
                        //   color: primary_color,
                        // },
                      },
                    }}
                    key={2}
                    label={visible ? `* ${lang.amount}` : lang.amount}
                    value={amount}
                    onChange={(e) =>
                      setAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                    }
                    style={{
                      width: "43.5%",
                    }}
                    autoFocus={focusedInputKey == 2 ? true : false}
                    onFocus={() => setFocusedInputKey(2)}
                    onBlur={() => setFocusedInputKey(null)}
                  />
                </Box>

                <div className="sm-m  div-chechbox-p  web_payment_container_visible_chechbox">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={visible}
                          onChange={() => setVisible(!visible)}
                          sx={{
                            "&.Mui-checked": {
                              color: primaryColor,
                            },
                          }}
                        />
                      }
                      label={lang.visible}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : loading_appSettings ? (
        <div className="loader_component_container"></div>
      ) : (
        <div
          className="loader_component_container"
          style={{
            flexDirection: "column",

            zIndex: "3",
          }}
        >
          <p style={{ fontSize: "30px" }}>{lang.no_data}</p>
          <button
            className="web_payment_try_again_button"
            onClick={() => handleTryAgain()}
          >
            {lang.try_again}
          </button>
        </div>
      )}
    </div>
  );
});

export default Payments;
