import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import { Close } from "@material-ui/icons";
import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import MenuListItem from "./MenuListItem";
import { Navigate, useNavigate } from "react-router-dom";
import { marginNormalMenuHorizontal } from "../constants/Sizes";
import {
  getPromotionListAction,
  selectedPromotionAction,
  deletePromotionsAction,
  updatePromotionAction,
} from "../redux/settings/Action";
import ListHeader from "./ListHeader";
import { alpha, styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { primaryColor } from "../constants/Colors";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
} from "../redux/menu/Actions";
import {
  promotion_type_list,
  promotion_rate_option_list,
} from "../constants/DropDownList";
import Loader from "./Loader";
const PromotionsList = ({ setClickedItem1 }) => {
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: primaryColor,
      "&:hover": {
        backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: primaryColor,
    },
  }));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const {
    get_promotions_list,
    result_failed_promotion_update,
    selected_promotion,
    loading_appSettings,
  } = useSelector((state) => state.appSettingsRedusers);

  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  // const [item1, setItem1] = useState(null);
  const [promotionList, setPromotionList] = useState([]);
  const [promotionList1, setPromotionList1] = useState([]);

  const handleCreatePromotion = () => {
    setClickedItem1(2);
    dispatch(selectedPromotionAction(null));
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...promotionList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setPromotionList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...promotionList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setPromotionList(copy_list);
  };
  const handleDeleted = (item) => {
    dispatch(deletePromotionsAction(login_key, user_id, item.ShopId, item.Id));
  };
  const hanldeChangePromotionStatusAndUpdate = (item) => {
    setClickedItem1(1);
    const copy_promotionList = [...promotionList];
    if (copy_promotionList) {
      if (copy_promotionList.length > 0) {
        for (let i = 0; i < copy_promotionList.length; i++) {
          if (copy_promotionList[i].Id == item.Id) {
            copy_promotionList[i].swithStatus =
              !copy_promotionList[i].swithStatus;
            dispatch(
              updatePromotionAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                copy_promotionList[i].Id,
                copy_promotionList[i].Title,
                copy_promotionList[i].Description,
                copy_promotionList[i].StartDate
                  ? moment(copy_promotionList[i].StartDate).format(
                      "YYYY-MM-DD 05:00:00"
                    )
                  : "",
                copy_promotionList[i].EndDate
                  ? moment(copy_promotionList[i].EndDate).format(
                      "YYYY-MM-DD 04:59:59"
                    )
                  : "",
                copy_promotionList[i].DayOfWeek0,
                copy_promotionList[i].DayOfWeek1,
                copy_promotionList[i].DayOfWeek2,
                copy_promotionList[i].DayOfWeek3,
                copy_promotionList[i].DayOfWeek4,
                copy_promotionList[i].DayOfWeek5,
                copy_promotionList[i].DayOfWeek6,
                copy_promotionList[i].Code,
                copy_promotionList[i].SaleMethod,
                copy_promotionList[i].Rate,
                copy_promotionList[i].RateOption,
                copy_promotionList[i].MinOrder,
                copy_promotionList[i].swithStatus ? 1 : 0,
                copy_promotionList[i].ExcludeMealDeals,
                copy_promotionList[i].UsesPerPromotion,
                copy_promotionList[i].UsesPerCustomer,
                "",
                copy_promotionList[i].ShowInSite
              )
            );
          }
        }
      }
    }
    setPromotionList(copy_promotionList);
  };
  const hanldeChangeExcludeMealDealsAndUpdate = (item) => {
    setClickedItem1(1);
    const copy_promotionList = [...promotionList];
    if (copy_promotionList) {
      if (copy_promotionList.length > 0) {
        for (let i = 0; i < copy_promotionList.length; i++) {
          if (copy_promotionList[i].Id == item.Id) {
            copy_promotionList[i].swithExcludeMealDeals =
              !copy_promotionList[i].swithExcludeMealDeals;

            dispatch(
              updatePromotionAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                copy_promotionList[i].Id,
                copy_promotionList[i].Title,
                copy_promotionList[i].Description,
                copy_promotionList[i].StartDate
                  ? moment(copy_promotionList[i].StartDate).format(
                      "YYYY-MM-DD 05:00:00"
                    )
                  : "",
                copy_promotionList[i].EndDate
                  ? moment(copy_promotionList[i].EndDate).format(
                      "YYYY-MM-DD 04:59:59"
                    )
                  : "",
                copy_promotionList[i].DayOfWeek0,
                copy_promotionList[i].DayOfWeek1,
                copy_promotionList[i].DayOfWeek2,
                copy_promotionList[i].DayOfWeek3,
                copy_promotionList[i].DayOfWeek4,
                copy_promotionList[i].DayOfWeek5,
                copy_promotionList[i].DayOfWeek6,
                copy_promotionList[i].Code,
                copy_promotionList[i].SaleMethod,
                copy_promotionList[i].Rate,
                copy_promotionList[i].RateOption,
                copy_promotionList[i].MinOrder,
                copy_promotionList[i].Status,
                copy_promotionList[i].swithExcludeMealDeals ? 1 : 0,
                copy_promotionList[i].UsesPerPromotion,
                copy_promotionList[i].UsesPerCustomer,
                "",
                copy_promotionList[i].ShowInSite
              )
            );
          }
        }
      }
    }
    setPromotionList(copy_promotionList);
  };
  const handleClickOnEditButton = (item) => {
    const copy_promotion_type_list = [...promotion_type_list];
    let copy_promotion_type_list_value = [];
    let sale_method = null;

    const copy_promotion_rate_option_list = [...promotion_rate_option_list];
    let copy_promotion_rate_option_list_value = [];
    let rate_option = null;
    for (let i = 0; i < copy_promotion_type_list.length; i++) {
      copy_promotion_type_list_value.push(copy_promotion_type_list[i].value);
    }
    for (let i = 0; i < copy_promotion_rate_option_list.length; i++) {
      copy_promotion_rate_option_list_value.push(
        copy_promotion_rate_option_list[i].value
      );
    }
    if (item.SaleMethod) {
      if (copy_promotion_type_list_value.includes(parseInt(item.SaleMethod))) {
        let index = copy_promotion_type_list_value.indexOf(
          parseInt(item.SaleMethod)
        );

        sale_method = {
          value: copy_promotion_type_list[index].value,
          label: copy_promotion_type_list[index].label,
        };
      }
    }
    if (item.RateOption == 1) {
      rate_option = {
        value: copy_promotion_rate_option_list[1].value,
        label: copy_promotion_rate_option_list[1].label,
      };
    } else {
      rate_option = {
        value: copy_promotion_rate_option_list[0].value,
        label: copy_promotion_rate_option_list[0].label,
      };
    }
    setClickedItem1(2);

    dispatch(
      selectedPromotionAction({
        ...item,
        RateOption: rate_option,
        SaleMethod: sale_method,
      })
    );
  };

  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getPromotionListAction(login_key, user_id, selected_shop[0].ShopId)
        );
      }
    }
  }, [selected_shop]);
  useEffect(() => {
    if (get_promotions_list) {
      if (get_promotions_list.length > 0) {
        setPromotionList(get_promotions_list);
      } else {
        setPromotionList([]);
      }
    } else {
      setPromotionList([]);
    }
  }, [get_promotions_list]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(selectedOtemInAllLists(null));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    if (result_failed_promotion_update) {
      if (selected_shop) {
        if (selected_shop.length > 0) {
          dispatch(
            getPromotionListAction(login_key, user_id, selected_shop[0].ShopId)
          );
        }
      }
    }
  }, [result_failed_promotion_update]);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return width1 <= 560 ? (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ marginBottom: "15px" }}>
        <ListHeader title={lang.promotions_list} show_back={false} />
      </div>
      <div
        className="menu_list_header_container"
        style={{ paddingBottom: "110px" }}
      >
        <div className="margin_menu_normal_horizontal">
          {get_promotions_list.length > 0 ? (
            get_promotions_list.map((item, index) => (
              <div style={{ height: "100%" }}>
                <SwipeToRevealActions
                  hideDotsButton
                  height="160px"
                  containerStyle={{
                    paddingBottom: "12px",
                    backgroundColor: "white",
                  }}
                  actionButtons={[
                    {
                      content: (
                        <div className="mobile-promotionlist-container-delete-swipe">
                          <span>DELETE</span>
                        </div>
                      ),
                      onClick: () => handleShowDeleteBox(item),
                    },
                  ]}
                  actionButtonMinWidth={80}
                >
                  <div
                    style={{
                      flex: 1,
                      // boxShadow:
                      //   "3px 3px 4px rgb(218, 216, 216), 0px 0px 2px rgb(255, 254, 249)",
                    }}
                    className="mobile-orders-container1"
                    onClick={() => handleClickOnEditButton(item)}
                  >
                    <div
                      className="mobile-orders-container-orderBox"
                      // onClick={() => handleSelectedOrder(item)}
                    >
                      <div className="mobile-orders-container-first-row">
                        <div className="mobile-orders-container-first-row-labels">
                          <p>{lang.title}</p>
                          <p style={{ flex: 0.8 }}>{lang.status}</p>
                          <p style={{ flex: 1.2 }}>{lang.exclude_meal_deals}</p>
                        </div>
                        <div className="mobile-orders-container-first-row-details">
                          <p>{item.Title}</p>
                          <p style={{ flex: 0.8 }}>
                            {" "}
                            <GreenSwitch
                              checked={item.swithStatus}
                              onChange={() =>
                                hanldeChangePromotionStatusAndUpdate(item)
                              }
                            />
                          </p>
                          <p style={{ flex: 1.2 }}>
                            {" "}
                            <GreenSwitch
                              checked={item.swithExcludeMealDeals}
                              onChange={() =>
                                hanldeChangeExcludeMealDealsAndUpdate(item)
                              }
                            />
                          </p>
                        </div>
                      </div>

                      <div className="mobile-orders-container-first-row">
                        <div className="mobile-orders-container-first-row-labels">
                          <p>{lang.code}</p>
                          <p style={{ flex: 0.8 }}>{lang.rate}</p>
                          <p style={{ flex: 1.2 }}>{lang.min_order}</p>
                        </div>
                        <div className="mobile-orders-container-first-row-details">
                          <p>{item.Code}</p>
                          <p style={{ flex: 0.8 }}>{item.Rate}</p>
                          <p style={{ flex: 1.2 }}>{item.MinOrder}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwipeToRevealActions>
                {item.showDeleteBox ? (
                  <Dialog open={true} maxWidth="sm" fullWidth>
                    <DialogTitle></DialogTitle>
                    <Box position="absolute" top={0} right={0}>
                      <IconButton>
                        <Close
                          className="mobile-workstation-IconButton"
                          style={{ fontSize: "27px" }}
                          onClick={() => handleCancelDeleteBox(item)}
                        />
                      </IconButton>
                    </Box>
                    <DialogContent>
                      <Typography style={{ fontSize: "18px" }}>
                        {" "}
                        {lang.delete_message}
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <div className=" mobile-workstation-delete-box">
                        <button onClick={() => handleCancelDeleteBox(item)}>
                          {lang.cancel}
                        </button>
                        <button onClick={() => handleDeleted(item)}>
                          {lang.ok}
                        </button>
                      </div>
                    </DialogActions>{" "}
                    {loading_appSettings ? <Loader /> : null}
                    <Toaster />
                  </Dialog>
                ) : null}
              </div>
            ))
          ) : (
            <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
              <h4>{lang.noOption}</h4>
              <span
                style={{ width: "172px" }}
                onClick={() => handleCreatePromotion()}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                <p>{lang.create_promotion}</p>
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {promotionList.length > 0 ? (
          <div
            className="mobile-container-save-button"
            onClick={() => handleCreatePromotion()}
          >
            <button type="">{lang.create_promotion}</button>
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "100%",
      }}
    >
      {promotionList ? (
        promotionList.length > 0 ? (
          <div className="list_header_container promotion_list_container_header ">
            <div>
              <h6>{lang.title}</h6>
              <h6> {lang.status}</h6>
              <h6>{lang.exclude_meal_deals}</h6>
              <h6>{lang.code}</h6>
              <h6>{lang.rate}</h6>
              <h6>{lang.min_order}</h6>
            </div>
          </div>
        ) : null
      ) : null}
      <div className="menu_list_header_container ">
        {promotionList.length > 0 ? (
          <div>
            {promotionList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={6}
                  field_1_title={item.Title}
                  field_2_title={item.swithStatus}
                  field_3_title={item.swithExcludeMealDeals}
                  field_4_title={item.Code}
                  field_5_title={item.Rate}
                  field_6_title={`£ ${item.MinOrder.toFixed(2)}`}
                  field_2_click={() =>
                    hanldeChangePromotionStatusAndUpdate(item)
                  }
                  field_3_click={() =>
                    hanldeChangeExcludeMealDealsAndUpdate(item)
                  }
                  item_title={item.Title}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleClickOnEditButton(item)}
                  show_delete={true}
                  // delete_click={() => handleDeleteVat(item)}
                  show_edit_icon={false}
                  // selected={selected_vat_list}
                  item={item}
                  selected={selected_promotion}
                  number={15}
                  delete_click={() => handleDeleted(item)}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                  promotiom_status_oncgange={() =>
                    hanldeChangePromotionStatusAndUpdate(item)
                  }
                  exclude_meal_deals_onchange={() =>
                    hanldeChangeExcludeMealDealsAndUpdate(item)
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              style={{ width: "172px" }}
              onClick={() => handleCreatePromotion()}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_promotion}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {promotionList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
            onClick={() => handleCreatePromotion()}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_promotion}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PromotionsList;
