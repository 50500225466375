import * as actionTypes from "../ActionTypes";
import store from "store2";
export const visibleCreateShopPopupAction = (data) => {
  return {
    type: actionTypes.VISIBLE_SHOP_CREATE_POPUP,
    payload: data,
  };
};

// CREATE SHOP

export const createShopAction = (
  login_key,
  admin_id,
  user_id,
  title,
  mobile,
  address,
  postcode,
  city,
  user_type
) => {
  return {
    type: actionTypes.CREATE_SHOP,
    payload: {
      login_key,
      admin_id,
      user_id,
      title,
      mobile,
      address,
      postcode,
      city,
      user_type,
    },
  };
};
export const createShopSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_SHOP_SUCCESSFULL,
    payload: data,
  };
};

export const createShopFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_SHOP_FAILED,
    payload: data,
  };
};
export const setDefaultAction = () => {
  return {
    type: actionTypes.SET_DEFAULT_CREATE_SHOP_STATUS,
  };
};
// GET SHOPS LIST
export const getShopsListAction = (
  login_key,
  user_id,
  shop_id,
  admin_id,
  user_type
) => {
  return {
    type: actionTypes.GET_SHOPS_LIST,
    payload: { login_key, user_id, shop_id, admin_id, user_type },
  };
};
export const getShopsListSuccessfullAction = (shops_list, selected_shop) => {
  store.set("selectedShop", selected_shop);
  return {
    type: actionTypes.GET_SHOPS_LIST_SUCCESSFULL,
    payload: { shops_list, selected_shop },
  };
};
export const getShopsListFailedAction = (data) => {
  return {
    type: actionTypes.GET_SHOPS_LIST_FAILED,
    payload: data,
  };
};

export const getShopDetailAction = (login_key, user_id, shop_id) => {
  return {
    type: actionTypes.GET_SHOP_DETAIL,
    payload: { login_key, user_id, shop_id },
  };
};
export const getShopDetailSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_SHOP_DETAIL_SUCCESSFULL,
    payload: data,
  };
};
export const getShopDetailFailedAction = () => {
  return {
    type: actionTypes.GET_SHOP_DETAIL_FAILED,
  };
};
export const updateShopAction = (
  formData,
  login_key,
  user_id,
  shop_id,
  title,
  admin_id,
  user_name,
  pass,
  mobile,
  phone_number,
  fax_number,
  address,
  city,
  post_code,
  active,
  include_android_app,
  include_ios_app,
  send_sms_header,
  send_email_header,
  logo
) => {
  return {
    type: actionTypes.UPDATE_SHOP,
    payload: {
      formData,
      login_key,
      user_id,
      shop_id,
      title,
      admin_id,
      user_name,
      pass,
      mobile,
      phone_number,
      fax_number,
      address,
      city,
      post_code,
      active,
      include_android_app,
      include_ios_app,
      send_sms_header,
      send_email_header,
      logo,
    },
  };
};

export const updateShopSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_SHOP_SUCCESSFULL,
    payload: data,
  };
};
export const updateShopFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_SHOP_FAILED,
    payload: data,
  };
};

export const getShopStatusListAction = (login_key, user_id) => {
  return {
    type: actionTypes.GET_SHOP_STATUS_LIST,
    payload: { login_key, user_id },
  };
};
export const getShopStatusListSucccesfullAction = (data) => {
  return {
    type: actionTypes.GET_SHOP_STATUS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getShopStatusListFailedAction = (data) => {
  return {
    type: actionTypes.GET_SHOP_STATUS_LIST_FAILED,
    payload: data,
  };
};

export const getNewShopListActin = (data) => {
  return {
    type: actionTypes.GET_NEW_SHOP_LIST,
    payload: data,
  };
};

// MESSAGE SUPPORT
export const showMessageSupportPopupAction = (data) => {
  return {
    type: actionTypes.SHOW_MESSAGE_SUPPORT_POPUP,
    payload: data,
  };
};

export const getMessageSupportListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionTypes.GET_MESSAGE_SUPPORT_LIST,
    payload: { login_key, user_id, shop_id },
  };
};

export const getMessageSupportListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_MESSAGE_SUPPORT_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getMessageSupportListFailedAction = (data) => {
  return {
    type: actionTypes.GET_MESSAGE_SUPPORT_LIST_FAILED,
    payload: data,
  };
};

export const updateMessageSupportAction = (
  login_key,
  user_id,
  shop_id,
  id,
  user_answer
) => {
  return {
    type: actionTypes.UPDATE_MESSAGE_SUPPORT,
    payload: { login_key, user_id, shop_id, id, user_answer },
  };
};
export const updateMessageSupportSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_MESSAGE_SUPPORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateMessageSupportFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_MESSAGE_SUPPORT_FAILED,
    payload: data,
  };
};

export const selectedShopStatusAction = (data) => {
  return {
    type: actionTypes.SELECTED_SHOP_STATUS,
    payload: data,
  };
};

export const generalSecondScreenShopListAction = (data) => {
  return {
    type: actionTypes.GENERAL_SECOND_SCREEN_SHOP_LIST,
    payload: data,
  };
};
