import React, { useEffect, useState, useRef } from "react";
import ListHeader from "./ListHeader";
import SaveMenuButton from "./SaveMenuButton";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import {
  updateDevicesAccessGroupAction,
  createDevicesAccessGroupAction,
} from "../redux/Employees/Actions";
import { primaryColor } from "../constants/Colors";
const GroupsEdit = ({ setClickedItem }) => {
  const didMountRef = useRef();
  const dispatch = useDispatch();

  const { device_roles_list, selected_access_group, result_successfull } =
    useSelector((state) => state.employeesRedusers);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);

  const [roleList, setRoleList] = useState([]);
  const [ids, setIds] = useState([]);
  const [title, setTilte] = useState("");
  const [roleCheckedItem, setRoleCheckedItem] = useState(false);
  const [checked, setChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [checked1, setChecked1] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [mainPageSubmenuStatus, setMainPageSubmenuStatus] = useState(false);
  const [accessoriesSubmenuStatus, setAccessoriesSubmenuStatus] =
    useState(false);

  const createAndUpdateDeviceAccessGroup = () => {
    let id = [];
    let id1 = [];
    let ID = [];
    let ID1 = [];
    let total_id = [];
    if (checked[0]) {
      id.push(2);
    } else {
      id.push();
    }
    if (checked[1]) {
      id.push(3);
    } else {
      id.push();
    }
    if (checked[2]) {
      id.push(4);
    } else {
      id.push();
    }
    if (checked[3]) {
      id.push(5);
    } else {
      id.push();
    }
    if (checked[4]) {
      id.push(6);
    } else {
      id.push();
    }
    if (checked[5]) {
      id.push(7);
    } else {
      id.push();
    }
    if (checked[6]) {
      id.push(8);
    } else {
      id.push();
    }
    if (checked[7]) {
      id.push(9);
    } else {
      id.push();
    }
    if (checked[8]) {
      id.push(10);
    } else {
      id.push();
    }
    if (checked[9]) {
      id.push(11);
    } else {
      id.push();
    }
    if (checked[10]) {
      id.push(12);
    } else {
      id.push();
    }
    if (checked[11]) {
      id.push(13);
    } else {
      id.push();
    }
    if (checked[12]) {
      id.push(14);
    } else {
      id.push();
    }
    if (checked[13]) {
      id.push(15);
    } else {
      id.push();
    }
    if (checked[14]) {
      id.push(16);
    } else {
      id.push();
    }
    if (checked[15]) {
      id.push(17);
    } else {
      id.push();
    }
    if (checked[16]) {
      id.push(28);
    } else {
      id.push();
    }
    if (checked[17]) {
      id.push(29);
    } else {
      id.push();
    }
    if (checked[18]) {
      id.push(30);
    } else {
      id.push();
    }
    if (checked[19]) {
      id.push(31);
    } else {
      id.push();
    }
    if (checked1[0]) {
      id1.push(19);
    } else {
      id1.push();
    }
    if (checked1[1]) {
      id1.push(20);
    } else {
      id1.push();
    }
    if (checked1[2]) {
      id1.push(21);
    } else {
      id1.push();
    }
    if (checked1[3]) {
      id1.push(22);
    } else {
      id1.push();
    }
    if (checked1[4]) {
      id1.push(23);
    } else {
      id1.push();
    }
    if (checked1[5]) {
      id1.push(24);
    } else {
      id1.push();
    }
    if (checked1[6]) {
      id1.push(25);
    } else {
      id1.push();
    }
    if (checked1[7]) {
      id1.push(26);
    } else {
      id1.push();
    }
    if (checked1[8]) {
      id1.push(27);
    } else {
      id1.push();
    }

    if (id.length == 20) {
      ID.push(1);
    } else if (id.length == 0) {
      ID.push();
    } else {
      for (let i = 0; i < id.length; i++) {
        ID.push(id[i]);
      }
    }
    if (id1.length == 9) {
      ID1.push(18);
    } else if (id1.length == 0) {
      ID1.push();
    } else {
      for (let i = 0; i < id1.length; i++) {
        ID1.push(id1[i]);
      }
    }
    if (ID.length > 0) {
      for (let i = 0; i < ID.length; i++) {
        total_id.push(ID[i]);
      }
    }
    if (ID1.length > 0) {
      for (let i = 0; i < ID1.length; i++) {
        total_id.push(ID1[i]);
      }
    }

    setIds(total_id);

    if (selected_access_group) {
      if (title == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                class="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateDevicesAccessGroupAction(
            0,
            login_key,
            user_id,
            total_id.toString(),
            title,
            selected_access_group.Id
          )
        );
      }
    } else {
      if (title == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                class="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          createDevicesAccessGroupAction(
            0,
            login_key,
            user_id,
            total_id.toString(),
            title
          )
        );
      }
    }
  };

  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);

  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     if (event.key === "Enter") {
  //       createAndUpdateDeviceAccessGroup();
  //     }
  //   };

  //   document.addEventListener("keydown", keyDownHandler);

  //   return () => {
  //     document.removeEventListener("keydown", keyDownHandler);
  //   };
  // }, [title, roleCheckedItem]);
  const handleChange1 = (event) => {
    setChecked([
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
    ]);
    setMainPageSubmenuStatus(true);
  };

  const handleChangeChecked1 = (event) => {
    setChecked1([
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
      event.target.checked,
    ]);
  };
  const handleChange2 = (event) => {
    setChecked([
      event.target.checked,
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange3 = (event) => {
    setChecked([
      checked[0],
      event.target.checked,

      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange4 = (event) => {
    setChecked([
      checked[0],

      checked[1],
      event.target.checked,
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange5 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      event.target.checked,
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange6 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      event.target.checked,
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange7 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      event.target.checked,
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };

  const handleChange8 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      event.target.checked,
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange9 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      event.target.checked,
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange10 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      event.target.checked,
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange11 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      event.target.checked,
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange12 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      event.target.checked,
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange13 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      event.target.checked,
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange14 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      event.target.checked,
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange15 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      event.target.checked,
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange16 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      event.target.checked,
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange17 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      event.target.checked,
      checked[16],
      checked[17],
      checked[18],
      checked[19],
    ]);
  };
  const handleChange18 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      event.target.checked,
      checked[17],
      checked[18],
      checked[19],
    ]);
  };

  const handleChange28 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      event.target.checked,
      checked[18],
      checked[19],
    ]);
  };
  const handleChange29 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      event.target.checked,
      checked[19],
    ]);
  };
  const handleChange30 = (event) => {
    setChecked([
      checked[0],
      checked[1],
      checked[2],
      checked[3],
      checked[4],
      checked[5],
      checked[6],
      checked[7],
      checked[8],
      checked[9],
      checked[10],
      checked[11],
      checked[12],
      checked[13],
      checked[14],
      checked[15],
      checked[16],
      checked[17],
      checked[18],
      event.target.checked,
    ]);
  };
  const handleChange19 = (event) => {
    setChecked1([
      event.target.checked,
      checked1[1],
      checked1[2],
      checked1[3],
      checked1[4],
      checked1[5],
      checked1[6],
      checked1[7],
      checked1[8],
      checked1[9],
    ]);
  };
  const handleChange20 = (event) => {
    setChecked1([
      checked1[0],
      event.target.checked,
      checked1[2],
      checked1[3],
      checked1[4],
      checked1[5],
      checked1[6],
      checked1[7],
      checked1[8],
      checked1[9],
    ]);
  };
  const handleChange21 = (event) => {
    setChecked1([
      checked1[0],

      checked1[1],
      event.target.checked,
      checked1[3],
      checked1[4],
      checked1[5],
      checked1[6],
      checked1[7],
      checked1[8],
      checked1[9],
    ]);
  };
  const handleChange22 = (event) => {
    setChecked1([
      checked1[0],
      checked1[1],
      checked1[2],
      event.target.checked,
      checked1[4],
      checked1[5],
      checked1[6],
      checked1[7],
      checked1[8],
      checked1[9],
    ]);
  };
  const handleChange23 = (event) => {
    setChecked1([
      checked1[0],
      checked1[1],
      checked1[2],
      checked1[3],
      event.target.checked,
      checked1[5],
      checked1[6],
      checked1[7],
      checked1[8],
      checked1[9],
    ]);
  };
  const handleChange24 = (event) => {
    setChecked1([
      checked1[0],
      checked1[1],
      checked1[2],
      checked1[3],
      checked1[4],
      event.target.checked,
      checked1[6],
      checked1[7],
      checked1[8],
      checked1[9],
    ]);
  };

  const handleChange25 = (event) => {
    setChecked1([
      checked1[0],
      checked1[1],
      checked1[2],
      checked1[3],
      checked1[4],
      checked1[5],
      event.target.checked,
      checked1[7],
      checked1[8],
      checked1[9],
    ]);
  };
  const handleChange26 = (event) => {
    setChecked1([
      checked1[0],
      checked1[1],
      checked1[2],
      checked1[3],
      checked1[4],
      checked1[5],
      checked1[6],
      event.target.checked,
      checked1[8],
      checked1[9],
    ]);
  };
  const handleChange27 = (event) => {
    setChecked1([
      checked1[0],
      checked1[1],
      checked1[2],
      checked1[3],
      checked1[4],
      checked1[5],
      checked1[6],
      checked1[7],
      event.target.checked,
      checked1[9],
    ]);
  };

  const children = (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "space-between",
        // alignItems: "center",
        width: "100%",
        ml: 3,
      }}
    >
      <FormControlLabel
        style={{ width: "200px" }}
        label="Delivery"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[0]}
            onChange={handleChange2}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Collection"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[1]}
            onChange={handleChange3}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Takeaway"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[2]}
            onChange={handleChange4}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Eat-in"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[3]}
            onChange={handleChange5}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Takings"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[4]}
            onChange={handleChange6}
          />
        }
      />

      <FormControlLabel
        style={{ width: "200px" }}
        label="Chck In"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[5]}
            onChange={handleChange7}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Eat-In List"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[6]}
            onChange={handleChange8}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="List All Order"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[7]}
            onChange={handleChange9}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Assign Driver"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[8]}
            onChange={handleChange10}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Driver Analysis"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[9]}
            onChange={handleChange11}
          />
        }
      />

      <FormControlLabel
        style={{ width: "200px" }}
        label="Driver Direction"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[10]}
            onChange={handleChange12}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Find Customer"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[11]}
            onChange={handleChange13}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Setting"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[12]}
            onChange={handleChange14}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Dashboard"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[13]}
            onChange={handleChange15}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Help"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[14]}
            onChange={handleChange16}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Sync"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[15]}
            onChange={handleChange17}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Collection List"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[16]}
            onChange={handleChange18}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Set Aside"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[17]}
            onChange={handleChange28}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="Log out"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[18]}
            onChange={handleChange29}
          />
        }
      />
      <FormControlLabel
        style={{ width: "200px" }}
        label="No Sale"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked[19]}
            onChange={handleChange30}
          />
        }
      />
    </Box>
  );
  const children1 = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        label="Edit Order"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[0]}
            onChange={handleChange19}
          />
        }
      />
      <FormControlLabel
        label="Cancel Order"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[1]}
            onChange={handleChange20}
          />
        }
      />
      <FormControlLabel
        label="Edit Line"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[2]}
            onChange={handleChange21}
          />
        }
      />
      <FormControlLabel
        label="Discount"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[3]}
            onChange={handleChange22}
          />
        }
      />
      <FormControlLabel
        label="Extra Charge"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[4]}
            onChange={handleChange23}
          />
        }
      />

      <FormControlLabel
        label="Ceneral Item"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[5]}
            onChange={handleChange24}
          />
        }
      />
      <FormControlLabel
        label="Convert To"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[6]}
            onChange={handleChange25}
          />
        }
      />
      <FormControlLabel
        label="Free"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[7]}
            onChange={handleChange26}
          />
        }
      />
      <FormControlLabel
        label="Z Report"
        control={
          <Checkbox
            style={{
              color: primaryColor,
            }}
            sx={{
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
            checked={checked1[8]}
            onChange={handleChange27}
          />
        }
      />
    </Box>
  );

  useEffect(() => {
    let role_list = [...device_roles_list];
    if (selected_access_group) {
      let ids = [];
      let IDS = [];
      let numberArray = selected_access_group.RoleId.split(",");

      if (role_list.length > 0) {
        for (let i = 0; i < role_list.length; i++) {
          ids.push(role_list[i].RoleId);
          if (ids.length > 0) {
            for (let ind = 0; ind < numberArray.length; ind++) {
              IDS = numberArray.map(Number);

              if (IDS) {
                if (IDS.length > 0) {
                  if (IDS.includes(1)) {
                    setChecked([
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                    ]);
                  } else {
                    setChecked([
                      IDS.includes(2) ? true : false,
                      IDS.includes(3) ? true : false,
                      IDS.includes(4) ? true : false,
                      IDS.includes(5) ? true : false,
                      IDS.includes(6) ? true : false,
                      IDS.includes(7) ? true : false,
                      IDS.includes(8) ? true : false,
                      IDS.includes(9) ? true : false,
                      IDS.includes(10) ? true : false,
                      IDS.includes(11) ? true : false,
                      IDS.includes(12) ? true : false,
                      IDS.includes(13) ? true : false,
                      IDS.includes(14) ? true : false,
                      IDS.includes(15) ? true : false,
                      IDS.includes(16) ? true : false,
                      IDS.includes(17) ? true : false,
                      IDS.includes(28) ? true : false,
                      IDS.includes(29) ? true : false,
                      IDS.includes(30) ? true : false,
                      IDS.includes(31) ? true : false,
                    ]);
                  }
                  if (IDS.includes(18)) {
                    setChecked1([
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                      true,
                    ]);
                  } else {
                    setChecked1([
                      IDS.includes(19) ? true : false,
                      IDS.includes(20) ? true : false,
                      IDS.includes(21) ? true : false,
                      IDS.includes(22) ? true : false,
                      IDS.includes(23) ? true : false,
                      IDS.includes(24) ? true : false,
                      IDS.includes(25) ? true : false,
                      IDS.includes(26) ? true : false,
                      IDS.includes(27) ? true : false,
                    ]);
                  }
                }
              }
            }
          }
        }
        setTilte(selected_access_group.Title);
        setRoleList(role_list);
      }
    } else {
      setRoleList(role_list);
    }
  }, [selected_access_group]);

  return (
    <div className="countaining-groups-div">
      <div>
        <ListHeader
          title={
            selected_access_group
              ? lang.staff_access_edit
              : lang.create_staff_access
          }
          show_back={true}
          clickedItem={() => setClickedItem(1)}
        />
      </div>
      <div className="menu_list_header_container" style={{ overflowX: "auto" }}>
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div2">
            <div className="sm-m">
              <h6 className="h6">* {lang.title}</h6>
              <input
                className="sm-input input-padding"
                value={title}
                onChange={(e) => setTilte(e.target.value)}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <FormControlLabel
                label="Main page"
                control={
                  <Checkbox
                    style={{
                      color: primaryColor,
                    }}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                    size="medium"
                    checked={
                      checked[0] &&
                      checked[1] &&
                      checked[2] &&
                      checked[3] &&
                      checked[4] &&
                      checked[5] &&
                      checked[6] &&
                      checked[7] &&
                      checked[8] &&
                      checked[9] &&
                      checked[10] &&
                      checked[11] &&
                      checked[12] &&
                      checked[13] &&
                      checked[14] &&
                      checked[15] &&
                      checked[16] &&
                      checked[17] &&
                      checked[18] &&
                      checked[19]
                    }
                    indeterminate={checked[0] !== checked[1]}
                    onChange={handleChange1}
                  />
                }
              />
              <div
                className="container_123"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {children}
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <FormControlLabel
                label="Accessories"
                control={
                  <Checkbox
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                    style={{
                      color: primaryColor,
                    }}
                    size="medium"
                    checked={
                      checked1[0] &&
                      checked1[1] &&
                      checked1[2] &&
                      checked1[3] &&
                      checked1[4] &&
                      checked1[5] &&
                      checked1[6] &&
                      checked1[7] &&
                      checked1[8]
                    }
                    indeterminate={checked1[0] !== checked1[1]}
                    onChange={handleChangeChecked1}
                  />
                }
              />
              {children1}
            </div>
          </div>
        </div>
      </div>

      <SaveMenuButton clickButton={createAndUpdateDeviceAccessGroup} />
    </div>
  );
};

export default GroupsEdit;
