import * as actionTypes from "../ActionTypes";

const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_devices_worksStations: false,
  loading_devices: false,
  devices_worksStation_list: [],
  licencs_list: [],
  get_licence_list_by_workStaion_id: [],
  selectd_licence: null,
  visible_create_licence_popup: false,
  show_main_second_screen_shop_group_popup: false,
  general_second_full_screen_list: [],
  general_second_half_screen_list: [],
  general_second_screen_shop_group_list: [],
  general_second_screen_create_popup: false,
  selected_screen: 1,
};

const devicesWorkStationReduser = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_devices_worksStations: false,
        visible_create_licence_popup: false,
      };
    case actionTypes.GET_WORKSTATION_LIST_ALL:
      return {
        ...state,
        loading_devices_worksStations: true,
      };
    case actionTypes.GET_WORKSTATION_LIST_ALL_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        devices_worksStation_list: action.payload,
      };
    case actionTypes.GET_WORKSTATION_LIST_ALL_FAILED:
      return {
        ...state,
        devices_worksStation_list: [],
        loading_devices_worksStations: false,
        result_message: action.payload,
        result_failed: true,
      };
    case actionTypes.GET_LICENCE_WORK_STATION_LIST:
      return {
        ...state,
        loading_devices_worksStations: true,
      };
    case actionTypes.SELECTD_LICENCE_LIST:
      return {
        ...state,
        selectd_licence: action.payload,
      };
    case actionTypes.GET_LICENCE_WORK_STATION_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        licencs_list: action.payload,
      };
    case actionTypes.GET_LICENCE_WORK_STATION_LIST_FAILED:
      return {
        ...state,
        loading_devices_worksStations: false,
        licencs_list: [],
      };
    case actionTypes.CREATE_LICENCE:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_devices_worksStations: true,
      };
    case actionTypes.CREATE_LICENCE_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_devices_worksStations: false,
        // visible_create_licence_popup:false
      };
    case actionTypes.CREATE_LICENCE_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_devices_worksStations: false,
      };
    case actionTypes.VISIBLE_CRETE_LICENCE_POPUP:
      return {
        ...state,
        visible_create_licence_popup: action.payload,
      };
    case actionTypes.GET_LICENCE_LIST_BY_WORKSTATION_ID:
      return {
        ...state,
        loading_devices_worksStations: true,
      };
    case actionTypes.GET_LICENCE_LIST_BY_WORKSTATION_ID_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        get_licence_list_by_workStaion_id: action.payload,
      };
    case actionTypes.GET_LICENCE_LIST_BY_WORKSTATION_ID_FAILED:
      return {
        ...state,
        loading_devices_worksStations: false,
        get_licence_list_by_workStaion_id: [],
      };

    // GENERAL SECOND SCREEN

    case actionTypes.SHOW_MAIN_SECOND_SCREEN_SHOP_GROUP_POPUP:
      return {
        ...state,
        show_main_second_screen_shop_group_popup: action.payload,
      };
    case actionTypes.GET_GENERAL_SECOND_SCREEN_LIST:
      return {
        ...state,
        loading_devices_worksStations: true,
      };
    case actionTypes.GET_GENERAL_SECOND_FULL_SCREEN_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        general_second_full_screen_list: action.payload,
      };
    case actionTypes.GET_GENERAL_SECOND_HALF_SCREEN_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        general_second_half_screen_list: action.payload,
      };
    case actionTypes.GET_GENERAL_SECOND_SCREEN_LIST_FAILED:
      return {
        ...state,
        loading_devices_worksStations: false,
        general_second_half_screen_list: [],
        general_second_full_screen_list: [],
      };

    case actionTypes.CREATE_GENERAL_SECOND_SCREEN_IMAGE:
      return {
        ...state,
        loading_devices_worksStations: true,
      };
    case actionTypes.CREATE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.CREATE_GENERAL_SECOND_SCREEN_IMAGE_FAILED:
      return {
        ...state,
        loading_devices_worksStations: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };

    case actionTypes.DELETE_GENERAL_SECOND_SCREEN_IMAGE:
      return {
        ...state,
        loading_devices_worksStations: true,
      };
    case actionTypes.DELETE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.DELETE_GENERAL_SECOND_SCREEN_IMAGE_FAILED:
      return {
        ...state,
        loading_devices_worksStations: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };

    case actionTypes.UPDATE_GENERAL_SECOND_SCREEN_IMAGE:
      return {
        ...state,
        loading_devices_worksStations: true,
      };
    case actionTypes.UPDATE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_GENERAL_SECOND_SCREEN_IMAGE_FAILED:
      return {
        ...state,
        loading_devices_worksStations: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST:
      return {
        ...state,
        loading_devices_worksStations: true,
        general_second_screen_shop_group_list: [],
      };
    case actionTypes.GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        general_second_screen_shop_group_list: action.payload,
      };
    case actionTypes.GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST_FAILED:
      return {
        ...state,
        loading_devices_worksStations: false,
        general_second_screen_shop_group_list: [],
      };
    case actionTypes.UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP:
      return {
        ...state,
        loading_devices_worksStations: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP_SUCCESSFULL:
      return {
        ...state,
        loading_devices_worksStations: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        show_main_second_screen_shop_group_popup: false,
      };
    case actionTypes.UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP_FAILED:
      return {
        ...state,
        loading_devices_worksStations: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.GENEREL_SECOND_SCREEN_CREATE_POPUP:
      return {
        ...state,
        general_second_screen_create_popup: action.payload,
      };
    case actionTypes.SELECTED_SCREEN:
      return {
        ...state,
        selected_screen: action.payload,
      };
    default:
      return state;
  }
};
export default devicesWorkStationReduser;
