import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const appSettingServices = async (login_key, user_id, shopId) => {
  return await axios.put(`${baseUrl}getshopsettingapp/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
  });
};
export const updateShopSettingAppOrderProccessServices = async (
  login_key,
  user_id,
  shopId,
  prefix_yes_for_submenu,
  prefix_no_for_submenu,
  total_half_text,
  users_need_to_login_before_taking_order,
  use_table_area,
  ask_number_guests,
  ask_customer_on_collection,
  ask_customer_on_takeaway,
  show_tip_in_eat_in,
  show_tip_in_takeaway
) => {
  return await axios.put(`${baseUrl}UpdateShopSettingAppOrderProcess/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    PrefixYesForSubmenu: prefix_yes_for_submenu,
    PrefixNoForSubmenu: prefix_no_for_submenu,
    TotalHalfText: total_half_text,
    UsersNeedToLoginBeforeTakingOrder: users_need_to_login_before_taking_order,
    UseTableArea: use_table_area,
    AskNumberGuests: ask_number_guests,
    AskCustomerOnCollection: ask_customer_on_collection,
    AskCustomerOnTakeaway: ask_customer_on_takeaway,
    ShowTipInEatIn: show_tip_in_eat_in,
    ShowTipInTakeaway: show_tip_in_takeaway,
  });
};
export const updateShopSettingAppPaymentServices = async (
  login_key,
  user_id,
  shopId,
  use_peyment_method,
  delivery_default_peyment_method,
  collection_default_peyment_method,
  takeaway_default_peyment_method,
  eat_in_default_peyment_method,
  show_not_paid_in_delivery,
  show_not_paid_in_collection,
  show_not_paid_in_takeaway,
  show_not_paid_in_eatIn
) => {
  return await axios.put(`${baseUrl}UpdateShopSettingAppPayment/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    UsePeymentMethod: use_peyment_method,
    DeliveryDefaultPeymentMethod: delivery_default_peyment_method,
    CollectionDefaultPeymentMethod: collection_default_peyment_method,
    TakeawayDefaultPeymentMethod: takeaway_default_peyment_method,
    EatInDefaultPeymentMethod: eat_in_default_peyment_method,
    ShowNotPaidInDelivery: show_not_paid_in_delivery,
    ShowNotPaidInCollection: show_not_paid_in_collection,
    ShowNotPaidInTakeaway: show_not_paid_in_takeaway,
    ShowNotPaidInEatIn: show_not_paid_in_eatIn,
  });
};
export const updateShopSettingAppColourServices = async (
  login_key,
  user_id,
  shopId,
  display_order_lasting_morethan_minutes,
  order_queue_list_colour,
  assing_driver_flashing_colour,
  printed_orders_colour
) => {
  return await axios.put(`${baseUrl}UpdateShopSettingAppcolour/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    DisplayOrderLastingMorethanMinutes: display_order_lasting_morethan_minutes,
    OrderQueueListColour: order_queue_list_colour,
    AssingDriverFlashingColour: assing_driver_flashing_colour,
    PrintedOrdersColour: printed_orders_colour,
  });
};
export const updateShopSettingAppDisplayServices = async (
  login_key,
  user_id,
  shopId,
  display_previous_order,
  show_basket_in_second_screen,
  delivery_description,
  collection_description,
  takeaway_description,
  eatIn_description,
  web_del_description,
  web_coll_description,
  kiosk_takeaway_description,
  kiosk_eatIn_description,
  show_barcode,
  show_not_paid_finishing_order_in_collection,
  show_not_paid_finishing_order_in_eatIn,
  shop_opening_time,
  shop_closing_time,
  second_language
) => {
  return await axios.put(`${baseUrl}UpdateShopSettingAppDisplay/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    DisplayPreviousOrder: display_previous_order,
    ShowBasketInSecondScreen: show_basket_in_second_screen,
    DeliveryDescription: delivery_description,
    CollectionDescription: collection_description,
    TakeawayDescription: takeaway_description,
    EatInDescription: eatIn_description,
    WebDelDescription: web_del_description,
    WebCollDescription: web_coll_description,
    KioskTakeawayDescription: kiosk_takeaway_description,
    KioskEatInDescription: kiosk_eatIn_description,
    ShowBarcode: show_barcode,
    ShowNotPaidFinishingOrderInCollection:
      show_not_paid_finishing_order_in_collection,
    ShowNotPaidFinishingOrderInEatIn: show_not_paid_finishing_order_in_eatIn,
    ShopOpeningTime: shop_opening_time,
    ShopClosingTime: shop_closing_time,
    SecondLanguage: second_language,
  });
};

export const updateShopSettingAppPasswordProtectServices = async (
  login_key,
  user_id,
  shopId,
  driver_float,
  driver_payment,
  takings,
  edit_order,
  driver_analysis,
  order_line_add_change,
  data_export_to_excel,
  discount,
  timeSheet,
  cancel_order,
  maintenance,
  list_all_order,
  system_setup
) => {
  return await axios.put(`${baseUrl}UpdateShopSettingApppasswordprotect/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    PPDriverFloat: driver_float,
    PPDriverPayment: driver_payment,
    PPTakings: takings,
    PPEditOrder: edit_order,
    PPDriverAnalysis: driver_analysis,
    PPOrderLineAddChange: order_line_add_change,
    PPDataExportToExcel: data_export_to_excel,
    PPDiscount: discount,
    PPTimeSheet: timeSheet,
    PPCancelOrder: cancel_order,
    PPMaintenance: maintenance,
    PPListAllOrder: list_all_order,
    PPSystemSetup: system_setup,
  });
};

export const getDeliveryChargeTypeServices = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}customChargeList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const createDeliveryChargeServices = async (
  login_key,
  user_id,
  shop_id,
  title,
  delivery_charge,
  background_color
) => {
  return await axios.post(`${baseUrl}createCustomCharge/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Title: title,
    DeliveryCharge: delivery_charge,
    BackgrandColor: background_color,
  });
};
export const updateDeliveryChargeServices = async (
  login_key,
  user_id,
  shop_id,
  id,
  title,
  delivery_charge,
  background_color
) => {
  return await axios.put(`${baseUrl}updateCustomCharge/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    Title: title,
    DeliveryCharge: delivery_charge,
    BackgrandColor: background_color,
  });
};
export const deleteDeliveryChargeServices = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.delete(`${baseUrl}deleteCustomCharge/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      Id: id,
    },
  });
};
export const getDeliverySettingServices = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}deliveryAppSettingGet/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
export const updateDeliverySettingServices = async (
  login_key,
  user_id,
  shop_id,
  id,

  default_delivery_charge_type_id,
  spicific_amount,
  Print_free_delivery_message
) => {
  return await axios.put(`${baseUrl}updateDeliveryAppSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,

    DefaultDeliveryChargeTypeId: default_delivery_charge_type_id,
    SpicificAmount: spicific_amount,
    PrintFreeDeliveryMessage: Print_free_delivery_message,
  });
};
export const codeDetailListServices = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}codeDetailList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const createCodeDetailServices = async (
  login_key,
  user_id,
  shop_id,
  group_id,
  title,
  active,
  sort_id,
  id
) => {
  return await axios.post(`${baseUrl}createCodeDetail/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    GroupId: group_id,
    Title: title,
    Active: active,
    SortId: sort_id,
    IsPublicSave: id,
  });
};

export const updateCodeDetailServices = async (
  login_key,
  user_id,
  shop_id,
  id,
  group_id,
  title,
  active,
  sort_id
) => {
  return await axios.put(`${baseUrl}updateCodeDetail/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    GroupId: group_id,
    Title: title,
    Active: active,
    SortId: sort_id,
  });
};
export const deleteCodeDetailServices = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.delete(`${baseUrl}deleteCodeDetail/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      Id: id,
    },
  });
};

export const getPaymentLinkListService = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}getPaymentLink/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const updatePaymentLinkService = async (
  login_key,
  user_id,
  shop_id,
  delivery_by_payeat,
  collection_by_payeat,
  takeaway_by_payeat,
  eatIn_by_payeat,
  expire_time_min,
  payment_method,
  ask_before_sending_text_message,
  message,
  domain,
  admin_fee
) => {
  return await axios.put(`${baseUrl}updateshopsettingpaymentlinksms/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    DeliveryByPayeat: delivery_by_payeat,
    CollectionByPayeat: collection_by_payeat,
    TakeawayByPayeat: takeaway_by_payeat,
    EatInByPayeat: eatIn_by_payeat,
    ExpireTimeMin: expire_time_min,
    PaymentMethod: payment_method,
    AskBeforeSendingTextMessage: ask_before_sending_text_message,
    Message: message,
    Domain: domain,
    AdminFee: admin_fee,
  });
};
// GET NUMBER OF PRINTS
export const getNumberOfPrintsService = async (
  login_key,
  user_id,
  shop_id,
  works_stations_id
) => {
  return await axios.put(`${baseUrl}printersNumberCopyList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    WorkstationId: works_stations_id,
  });
};
export const updateNumberOfPrintService = async (
  login_key,
  user_id,
  shop_id,
  works_stations_id,
  delivery_count,
  collection_count,
  takeaway_count,
  eat_in_count
) => {
  return await axios.put(`${baseUrl}updatePrintersNumberCopy/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    WorkstationId: works_stations_id,
    DeliveryCount: delivery_count,
    CollectionCount: collection_count,
    TakeawayCount: takeaway_count,
    EatInCount: eat_in_count,
  });
};

export const getPaymentDojoSettingListService = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}getPaymentDojoSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const createPaymentDojoSettingService = async (
  login_key,
  user_id,
  shop_id,
  title,
  Tid,
  api_url,
  api_key,
  currency,
  software_house_id,
  installer_id,
  active,
  workstation_id
) => {
  return await axios.put(`${baseUrl}createPaymentDojoSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Title: title,
    TId: Tid,
    ApiUrl: api_url,
    ApiKey: api_key,
    Currency: currency,
    Software_House_Id: software_house_id,
    Installer_Id: installer_id,
    Active: active,
    WorkstationId: workstation_id,
  });
};

export const updatePaymentDojoSettingService = async (
  login_key,
  user_id,
  shop_id,
  id,
  title,
  Tid,
  api_url,
  api_key,
  currency,
  software_house_id,
  installer_id,
  active,
  workstation_id
) => {
  return await axios.put(`${baseUrl}updatePaymentDojoSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    Title: title,
    TId: Tid,
    ApiUrl: api_url,
    ApiKey: api_key,
    Currency: currency,
    Software_House_Id: software_house_id,
    Installer_Id: installer_id,
    Active: active,
    WorkstationId: workstation_id,
  });
};

export const deletePaymentDojoSettingService = async (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return await axios.put(`${baseUrl}deletePaymentDojoSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
  });
};

export const getSecondScreenImagesListService = async (
  shop_id,
  login_key,
  user_id
) => {
  return await axios.put(`${baseUrl}secondScreenImageList/`, {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
  });
};

export const createSecondScreenImagesService = async (formData) => {
  return await axios.post(`${baseUrl}createSecondScreenImage/`, formData);
};

export const updateSecondScreenEachPhotoSettingService = async (
  login_key,
  user_id,
  shop_id,
  id,
  sort_id,
  active,
  duration
) => {
  return await axios.put(`${baseUrl}updateSecondScreenImage/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    SortId: sort_id,
    Active: active,
    Duration: duration,
  });
};

export const deleteSecondScreenEachPhotoService = async (
  shop_id,
  login_key,
  user_id,
  id,
  last_image_url
) => {
  return await axios.put(`${baseUrl}deleteSecondScreenImage/`, {
    // headers: {
    //   Authorization: "***",
    // },
    // data: {
    ShopId: shop_id,
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    LastImageUrlName: last_image_url,
    // },
  });
};

//SALE REPORT
export const updateSalesReportServise = async (
  login_key,
  user_id,
  shop_id,
  show_categories_on_zprint
) => {
  return await axios.put(`${baseUrl}updateSettingAppSaleReport/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ShowCategoriesOnZReport: show_categories_on_zprint,
  });
};
