import React from "react";
import { useSelector, useDispatch } from "react-redux";

const DevicesHeaderList = ({ button_key, licence_view }) => {
  const { lang } = useSelector((state) => state.selectedLanguage);

  return (
    <div>
      {button_key ? (
        <div className="header-list-container-div">
          <div className="devics-item-list-p">
            <p
              style={{
                width: "78%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {lang.shop}
            </p>
          </div>
          <p className="devics-item-list-p">{lang.channel}</p>
          <p className="devics-item-list-p ">{lang.ws_name}</p>
          <p className="devics-item-list-p">{lang.expire_date}</p>

          {/* <p className="devics-item-list-p">{lang.status}</p> */}
          <p className="devics-item-list-p">{lang.days_left}</p>
          <p className="devics-item-list-p"></p>
          <p className="devics-item-list-p"></p>
        </div>
      ) : licence_view ? (
        <div className="header-list-container-div">
          <p className="devics-item-list-p">{lang.expired}</p>
          <p className="devics-item-list-p">{lang.reserve}</p>
          <p className="devics-item-list-p ">{lang.licence_course}</p>
          <p className="devics-item-list-p">{lang.start_date}</p>
          <p className="devics-item-list-p">{lang.expire_date}</p>
          <p className="devics-item-list-p">{lang.status}</p>
        </div>
      ) : (
        <div className="header-list-container-div">
          <div className="devics-item-list-p">
            <p
              style={{
                width: "78%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {lang.shop}
            </p>
          </div>
          <p className="devics-item-list-p">{lang.channel}</p>
          <p className="devics-item-list-p ">{lang.ws_name}</p>
          <p className="devics-item-list-p">{lang.expire_date}</p>
          <p className="devics-item-list-p">{lang.days_left}</p>
          <p className="devics-item-list-p">{lang.status}</p>{" "}
          <p className="devics-item-list-p">{lang.connection}</p>
        </div>
      )}
    </div>
  );
};

export default DevicesHeaderList;
