import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";
//CREATE SHOP
export const createShopService = async (
  login_key,
  admin_id,
  user_id,
  title,
  mobile,
  address,
  postcode,
  city
) => {
  return await axios.post(`${baseUrl}createshop/`, {
    LoginKey: login_key,
    Title: title,
    AdminId: admin_id,
    Mobile: mobile,
    Address: address,
    Postcode: postcode,
    UserId: user_id,
    City: city,
  });
};
//GET SHOPS LIST
export const getShopsListService = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}getshopslist/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};

//GET DETAIL SHOP
export const getDetailShopServices = async (login_key, user_id, shop_id) => {
  return await axios.put(`${baseUrl}getShopDetail/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};
// const formData = new FormData();
export const updateShopServices = async (
  formData,
  login_key,
  user_id,
  shop_id,
  title,
  admin_id,
  user_name,
  pass,
  mobile,
  phone_number,
  fax_number,
  address,
  city,
  post_code,
  active,
  include_android_app,
  include_ios_app,
  send_sms_header,
  send_email_header,
  logo,
  lastLogoName
) => {
  // formData.append("LoginKey", login_key);
  // formData.append("UserId", user_id);
  // formData.append("ShopId", shop_id);
  // formData.append("Title", title);
  // formData.append("AdminId", admin_id);
  // formData.append("UserName", user_name);
  // formData.append("Pass", pass);
  // formData.append("Mobile", mobile);
  // formData.append("PhoneNumber", phone_number);
  // formData.append("FaxNumber", fax_number);
  // formData.append("Address", address);
  // formData.append("City", city);
  // formData.append("Postcode", post_code);
  // formData.append("IsActive", active);
  // formData.append("IncludeAndroidApp", include_android_app);
  // formData.append("IncludeIOSApp", include_ios_app);
  // formData.append("SendSmsHeader", send_sms_header);
  // formData.append("SendEmailHeader", send_email_header);
  // formData.append("Logo", logo);
  // formData.append("LastLogoName", lastLogoName);
  // if(logo!==null){
  // formData.append('Logo',logo);}else{
  //   formData.append('Logo','')
  // }

  return await axios.post(
    `${baseUrl}updateShop/`,
    formData
    // {
    //   LoginKey:login_key,
    //   UserId:user_id,
    //   ShopId:shop_id,
    //   Title:title,
    //   AdminId:admin_id,
    //   UserName:user_name,
    //   Pass:pass,
    //   Mobile:mobile,
    //   PhoneNumber:phone_number,
    //   FaxNumber:fax_number,
    //   Address:address,
    //   City:city,
    //   Postcode:post_code,
    //   IsActive:active,
    //   IncludeAndroidApp:include_android_app,
    //   IncludeIOSApp:include_ios_app,
    //   SendSmsHeader:send_sms_header,
    //   SendEmailHeader:send_email_header,
    //   Logo:logo,
    // ,{
    //   headers: {
    //     "Content-Type": `multipart/form-data;`,
    //    }}
    // }
  );
};

export const getShopStatusListServices = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}shopStatusList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};

//MESSAGE SUPPORT
export const getMessageSupportListService = async (
  login_key,
  user_id,
  shop_id
) => {
  return await axios.put(`${baseUrl}messageSupportList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
  });
};

export const updateMessageSupportService = async (
  login_key,
  user_id,
  shop_id,
  id,
  user_answer
) => {
  return await axios.put(`${baseUrl}updateMessageSupport/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    Id: id,
    UserAnswer: user_answer,
  });
};
