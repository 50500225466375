import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";
export const getFinancialOrderListService = async (
  login_key,
  user_id,
  shop_id,
  start_date,
  end_date
) => {
  return await axios.put(`${baseUrl}financialOrderList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    StartDate: start_date,
    EndDate: end_date,
  });
};

export const refundOrderService = async (
  login_key,
  user_id,
  shop_id,
  order_id,
  refund_amount,
  refund_note,
  device_type
) => {
  return await axios.put(`${baseUrl}financialRefoundOrderCreate/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    OrderId: order_id,
    RefoundAmount: refund_amount,
    RefoundNote: refund_note,
    DeviceType: device_type,
  });
};

export const getFinancialRefundListService = async (
  login_key,
  user_id,
  shop_id,
  start_date,
  end_date
) => {
  return await axios.put(`${baseUrl}financialRefoundList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    StartDate: start_date,
    EndDate: end_date,
  });
};

export const sendDebtService = async (
  login_key,
  user_id,
  shop_id,
  amount,
  refund_note,
  order_id
) => {
  return await axios.put(
    `${baseUrl}paymentsitesapies/transferConnectedAccount/`,
    {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      RefoundNote: refund_note,
      RefoundAmount: amount,
      RefoundId: order_id,
    }
  );
};
