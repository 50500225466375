import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { SketchPicker } from "react-color";
import validator from "validator";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import ListHeader from "./ListHeader";
import { image_Url } from "../constants/BaseUrl";
import { primaryColor, main_nav_back_color } from "../constants/Colors";
import { main_nav_button_color } from "../constants/Colors";
import SaveMenuButton from "../components/SaveMenuButton";
import {
  updateImployersListAction,
  createImployersListAction,
} from "../redux/Employees/Actions";

const EmployeesEdit = ({ setClickedItem }) => {
  // const image_href = "http://37.32.4.85:800/";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    gender,
    gender_id,
    job,
    job_id,
    device_access_group,
    device_access_group_id,
  } = useSelector((state) => state.categoryList);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop_employees, imployees_list, result_successfull } =
    useSelector((state) => state.employeesRedusers);
  const image_href = selected_shop[0].Domain;
  const [genderId, setGenderId] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [newJob, setNewJob] = useState(null);
  const [national, setNational] = useState(0);
  const [mobile, setMobile] = useState(null);
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [userName, setUserName] = useState("");
  const [pass, setPass] = useState("");
  const [married, setMarried] = useState(false);
  const [insurance, setInsurance] = useState("");

  const [active, setActive] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [singleUser, setSingleUser] = useState(false);
  const [appImage, setAppImage] = useState("");
  const [dateOfBreath, setDateOfBreath] = useState(new Date());
  const [dateIn, setDateIn] = useState(new Date());
  const [dateOut, setDateOut] = useState(new Date());

  const [src, setSrc] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#f5a623");
  const [titleColor, setTitleColor] = useState("#bd10e0");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const [lastUrlImage, setLastUrlImage] = useState("");
  const [srcDimensions, setSrcDimensions] = useState({});
  const [baseUrl, setBaseUrl] = useState("");
  const [key1, setKey1] = useState("");
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),

    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const imageUrl = src;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const onSelectFile = (e) => {
    setAppImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }
    setKey1(e);
  };
  const handleInputBackgroundColor = (e) => {
    setBackgroundColor(e.target.value);
  };
  const handleInputTitleColor = (e) => {
    setTitleColor(e.target.value);
  };

  const handleClose = () => {
    setShowColorPicker(false);
  };

  const handleCloseInputColor = () => {
    setShowSketchPicker(false);
  };
  const handleChangeComplete = (color) => {
    setBackgroundColor(color.hex);
    document.getElementById("input_backgroundColor").value = color.hex;
  };

  const handleOnclickeInput = () => {
    setShowSketchPicker(!showSketchPicker);
    setShowColorPicker(false);
  };
  const handleChangeCompleteColor = (color) => {
    setTitleColor(color.hex);
    document.getElementById("inputColor").value = color.hex;
  };
  const handleOnclickeInputColor = () => {
    setShowColorPicker(!showColorPicker);
    setShowSketchPicker(false);
  };
  const formData = new FormData();

  const handleSaveAndCreate = () => {
    if (selected_shop_employees) {
      if (
        employeeName == "" ||
        mobile == "" ||
        address == "" ||
        postCode == "" ||
        userName == "" ||
        pass == "" ||
        !newJob ||
        !groupId
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (!validator.isInt(String(pass))) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.incorrect_pss_format}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (pass.length < 4) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.password_characters}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (appImage.size > 200000) {
        toast.error(<h5> &nbsp;&nbsp;{lang.size_image}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("Id", selected_shop_employees.Id);
        formData.append("ShopId", selected_shop_employees.ShopId);
        formData.append("GenderId", 1);
        formData.append("EmployeeName", employeeName.replaceAll(`"`, `\"\"`));
        formData.append("Job", newJob.value);
        formData.append("NationalId", national ? national : 0);
        formData.append("Mobile", mobile);
        formData.append("Telephone", telephone ? telephone : 0);
        formData.append("Email", email);
        formData.append("Address", address.replaceAll(`"`, `\"\"`));
        formData.append("PostCode", postCode);
        formData.append("UserName", userName.replaceAll(`"`, `\"\"`));
        formData.append("Pass", pass);
        formData.append("DOB", moment(dateOfBreath).format("YYYY-MM-DD"));
        formData.append("Married", married ? 1 : 0);
        formData.append("InsuranceId", insurance ? parseInt(insurance) : 0);
        formData.append("DateIn", moment(dateIn).format("YYYY-MM-DD"));
        formData.append("DateOut", moment(dateOut).format("YYYY-MM-DD"));
        formData.append("Active", active ? 1 : 0);
        formData.append("GroupId", groupId.value);
        formData.append("IsAdmin", isAdmin ? 1 : 0);
        formData.append("SingleUser", singleUser ? 1 : 0);
        formData.append("BtnColor", backgroundColor);
        formData.append("BtnFontColor", titleColor);
        formData.append("UrlImage", appImage);
        formData.append("LastUrlImageName", lastUrlImage);
        formData.append("DeleteImage", 0);
        dispatch(
          updateImployersListAction(
            formData,
            login_key,
            user_id,
            selected_shop_employees.Id,
            selected_shop_employees.ShopId,
            1,
            employeeName.replaceAll(`"`, `\"\"`),
            newJob.value,
            national ? national : 0,
            mobile,
            telephone ? telephone : 0,
            email,
            address.replaceAll(`"`, `\"\"`),
            postCode,
            userName.replaceAll(`"`, `\"\"`),
            pass,
            moment(dateOfBreath).format("YYYY-MM-DD"),
            married ? 1 : 0,
            insurance ? parseInt(insurance) : 0,
            moment(dateIn).format("YYYY-MM-DD"),
            moment(dateOut).format("YYYY-MM-DD"),
            active ? 1 : 0,
            groupId.value,
            isAdmin ? 1 : 0,
            singleUser ? 1 : 0,
            backgroundColor,
            titleColor,
            appImage
          )
        );
      }
    } else {
      if (
        employeeName == "" ||
        mobile == "" ||
        address == "" ||
        postCode == "" ||
        userName == "" ||
        pass == "" ||
        !newJob ||
        !groupId
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (!validator.isInt(String(pass))) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.incorrect_pss_format}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (pass.length < 4) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.password_characters}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (appImage.size > 200000) {
        toast.error(<h5> &nbsp;&nbsp;{lang.size_image}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("ShopId", selected_shop[0].ShopId);
        formData.append("GenderId", 1);
        formData.append("EmployeeName", employeeName.replaceAll(`"`, `\"\"`));
        formData.append("Job", newJob ? newJob.value : 1);
        formData.append("NationalId", national ? national : 0);
        formData.append("Mobile", mobile);
        formData.append("Telephone", telephone ? telephone : 0);
        formData.append("Email", email);
        formData.append("Address", address.replaceAll(`"`, `\"\"`));
        formData.append("PostCode", postCode);
        formData.append("UserName", userName.replaceAll(`"`, `\"\"`));
        formData.append("Pass", pass);
        formData.append("DOB", moment(dateOfBreath).format("YYYY-MM-DD"));
        formData.append("Married", married ? 1 : 0);
        formData.append("InsuranceId", insurance ? parseInt(insurance) : 0);
        formData.append("DateIn", moment(dateIn).format("YYYY-MM-DD"));
        formData.append("DateOut", moment(dateOut).format("YYYY-MM-DD"));
        formData.append("Active", active ? 1 : 0);
        formData.append("GroupId", groupId ? groupId.value : 1);
        formData.append("IsAdmin", isAdmin ? 1 : 0);
        formData.append("SingleUser", singleUser ? 1 : 0);
        formData.append("BtnColor", backgroundColor);
        formData.append("BtnFontColor", titleColor);
        formData.append("UrlImage", appImage);

        dispatch(
          createImployersListAction(
            formData,
            login_key,
            user_id,
            selected_shop[0].ShopId,
            1,
            employeeName.replaceAll(`"`, `\"\"`),
            newJob.value,
            national ? national : 0,
            mobile,
            telephone ? telephone : 0,
            email,
            address.replaceAll(`"`, `\"\"`),
            postCode,
            userName.replaceAll(`"`, `\"\"`),
            pass,
            moment(dateOfBreath).format("YYYY-MM-DD"),
            married ? 1 : 0,
            insurance ? parseInt(insurance) : 0,
            moment(dateIn).format("YYYY-MM-DD"),
            moment(dateOut).format("YYYY-MM-DD"),
            active ? 1 : 0,
            groupId.value,
            isAdmin ? 1 : 0,
            singleUser ? 1 : 0,
            backgroundColor,
            titleColor,
            appImage
          )
        );
      }
    }
  };
  const handleDeleteImage = () => {
    if (key1) {
      key1.target.value = null;
    }
    setSrc("");
    setAppImage("");
    if (selected_shop_employees) {
      if (selected_shop_employees.UrlImage != "") {
        if (
          `${image_Url}${image_href}/${selected_shop_employees.UrlImage}` != src
        ) {
          setSrc(
            `${image_Url}${image_href}/${selected_shop_employees.UrlImage}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Id", selected_shop_employees.Id);
          formData.append("ShopId", selected_shop_employees.ShopId);
          formData.append("GenderId", genderId.value);
          formData.append("EmployeeName", employeeName.replaceAll(`"`, `\"\"`));
          formData.append("Job", newJob.value);
          formData.append("NationalId", national ? national : 0);
          formData.append("Mobile", mobile);
          formData.append("Telephone", telephone ? telephone : 0);
          formData.append("Email", email);
          formData.append("Address", address.replaceAll(`"`, `\"\"`));
          formData.append("PostCode", postCode);
          formData.append("UserName", userName.replaceAll(`"`, `\"\"`));
          formData.append("Pass", pass);
          formData.append("DOB", moment(dateOfBreath).format("YYYY-MM-DD"));
          formData.append("Married", married ? 1 : 0);
          formData.append("InsuranceId", insurance ? parseInt(insurance) : 0);
          formData.append("DateIn", moment(dateIn).format("YYYY-MM-DD"));
          formData.append("DateOut", moment(dateOut).format("YYYY-MM-DD"));
          formData.append("Active", active ? 1 : 0);
          formData.append("GroupId", groupId.value);
          formData.append("IsAdmin", isAdmin ? 1 : 0);
          formData.append("SingleUser", singleUser ? 1 : 0);
          formData.append("BtnColor", backgroundColor);
          formData.append("BtnFontColor", titleColor);
          formData.append("UrlImage", "");
          formData.append("LastUrlImageName", lastUrlImage);
          formData.append("DeleteImage", 1);
          dispatch(
            updateImployersListAction(
              formData,
              login_key,
              user_id,
              selected_shop_employees.Id,
              selected_shop_employees.ShopId,
              genderId.value,
              employeeName.replaceAll(`"`, `\"\"`),
              newJob.value,
              national ? national : 0,
              mobile,
              telephone ? telephone : 0,
              email,
              address.replaceAll(`"`, `\"\"`),
              postCode,
              userName.replaceAll(`"`, `\"\"`),
              pass,
              moment(dateOfBreath).format("YYYY-MM-DD"),
              married ? 1 : 0,
              insurance ? parseInt(insurance) : 0,
              moment(dateIn).format("YYYY-MM-DD"),
              moment(dateOut).format("YYYY-MM-DD"),
              active ? 1 : 0,
              groupId.value,
              isAdmin ? 1 : 0,
              singleUser ? 1 : 0,
              backgroundColor,
              titleColor,
              appImage
            )
          );
        }
      }
    }
  };
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (selected_shop_employees) {
      setActive(selected_shop_employees.Active);
      setAddress(selected_shop_employees.Address);
      setBackgroundColor(selected_shop_employees.BtnColor);
      setTitleColor(selected_shop_employees.BtnFontColor);
      setDateOfBreath(new Date(selected_shop_employees.DOB));
      setDateIn(new Date(selected_shop_employees.DateIn));
      setDateOut(new Date(selected_shop_employees.DateOut));
      setEmail(selected_shop_employees.Email);
      setEmployeeName(selected_shop_employees.EmployeeName);
      setGenderId(selected_shop_employees.GenderId);
      setGroupId(selected_shop_employees.GroupId);
      setInsurance(selected_shop_employees.InsuranceId);
      setIsAdmin(selected_shop_employees.IsAdmin);
      setNewJob(selected_shop_employees.Job);
      setMarried(selected_shop_employees.Married);
      setMobile(selected_shop_employees.Mobile);
      setNational(selected_shop_employees.NationalId);
      setPass(selected_shop_employees.Pass);
      setPostCode(selected_shop_employees.PostCode);
      setSingleUser(selected_shop_employees.SingleUser);
      setTelephone(selected_shop_employees.Telephone);
      if (selected_shop_employees.UrlImage) {
        setSrc(`${image_Url}${image_href}/${selected_shop_employees.UrlImage}`);
      } else {
        setSrc("");
      }

      setLastUrlImage(selected_shop_employees.UrlImage);
      setUserName(selected_shop_employees.UserName);
    } else {
      setActive(false);
      setAddress("");
      setBackgroundColor(backgroundColor);
      setTitleColor(titleColor);
      setDateOfBreath(new Date());
      setDateIn(new Date());
      setDateOut(new Date());
      setEmail("");
      setEmployeeName("");
      setGenderId(null);
      setGroupId(null);
      setInsurance("");
      setIsAdmin();
      setNewJob(null);
      setMarried("");
      setMobile("");
      setNational("");
      setPass("");
      setPostCode("");
      setSingleUser();
      setTelephone("");
      setAppImage("");
      setUserName("");
    }
  }, [selected_shop_employees]);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveAndCreate();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    genderId,
    employeeName,
    newJob,
    national,
    mobile,
    telephone,
    email,
    address,
    postCode,
    userName,
    pass,
    dateOfBreath,
    married,
    insurance,
    dateIn,
    dateOut,
    active,
    groupId,
    groupId,
    isAdmin,
    singleUser,
    backgroundColor,
    titleColor,
    appImage,
  ]);

  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);
  useEffect(() => {
    formData.delete("LoginKey");
    formData.delete("UserId");
    formData.delete("ShopId");
    formData.delete("GenderId");
    formData.delete("EmployeeName");
    formData.delete("Job");
    formData.delete("NationalId");
    formData.delete("Mobile");
    formData.delete("Telephone");
    formData.delete("Email");
    formData.delete("Address");
    formData.delete("PostCode");
    formData.delete("UserName");
    formData.delete("Pass");
    formData.delete("DOB");
    formData.delete("Married");
    formData.delete("InsuranceId");
    formData.delete("DateIn");
    formData.delete("DateOut");
    formData.delete("Active");
    formData.delete("GroupId");
    formData.delete("IsAdmin");
    formData.delete("SingleUser");
    formData.delete("BtnColor");
    formData.delete("BtnFontColor");
    formData.delete("UrlImage");
  }, []);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);
  // useEffect(() => {
  //   if (selected_shop) {
  //     if (selected_shop.length > 0) {
  //       let base_url = `otopartnercentre.com:${
  //         700 + selected_shop[0].ShopId
  //       }/var/www/shop${selected_shop[0].ShopId}/`;
  //       setBaseUrl(base_url);
  //     }
  //   }
  // }, [selected_shop]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
    >
      <div style={{ marginTop: "10px" }}>
        <ListHeader
          title={
            selected_shop_employees ? lang.imployees_edit : lang.create_employee
          }
          show_back={true}
          clickedItem={() => setClickedItem(1)}
        />
      </div>
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div2">
            <div className="sm-m">
              <h6 className="h6">* {lang.employee_name}</h6>
              <input
                className="sm-input input-padding"
                value={employeeName}
                onChange={(e) => setEmployeeName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">* {lang.mobile}</h6>
              <input
                className="sm-input input-padding"
                maxLength={11}
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">* {lang.job}</h6>
              <Select
                options={job}
                defaultValue={newJob}
                isSearchable={false}
                onChange={(i, d) => setNewJob(i)}
                value={newJob}
                // isMulti={true}
                styles={customStyles}
              />
            </div>
          </div>

          <div className="sm-div2">
            <div className="sm-m">
              <h6 className="h6">* {lang.address}</h6>
              <input
                className="sm-input input-padding"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">* {lang.postcode}</h6>
              <input
                className="sm-input input-padding"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
                minLength={4}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">{lang.telephone}</h6>
              <input
                className="sm-input input-padding"
                value={telephone}
                onChange={(e) =>
                  setTelephone(e.target.value.replace(/\D/g, ""))
                }
                maxLength={11}
              />
            </div>
          </div>

          <div className="sm-div2">
            <div className="sm-m">
              <h6 className="h6">* {lang.user_name}</h6>
              <input
                className="sm-input input-padding"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">{lang.email}</h6>
              <input
                className="sm-input input-padding"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">* {lang.pass}</h6>
              <input
                className="sm-input input-padding"
                value={pass}
                type="password"
                onChange={(e) => setPass(e.target.value.replace(/\D/g, ""))}
              />
            </div>
          </div>

          <div className="sm-div2">
            {/* <div className="sm-m">
              <h6 className="h6">{lang.national}</h6>
              <input
                className="sm-input input-padding"
                value={national}
                onChange={(e) => setNational(e.target.value.replace(/\D/g, ""))}
              />
            </div> */}
            <div className="sm-m">
              <h6 className="h6">{lang.in_suranced}</h6>
              <input
                className="sm-input input-padding"
                value={insurance}
                onChange={(e) =>
                  setInsurance(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
            {/* <div className="sm-m">
              <h6 className="h6">* {lang.gender_id}</h6>
              <Select
                options={gender}
                defaultValue={genderId}
                isSearchable={false}
                onChange={(i, d) => setGenderId(i)}
                value={genderId}
                // isMulti={true}
                styles={customStyles}
              />
            </div> */}
            <div className="sm-m">
              <h6 className="h6">* {lang.permission_previllage}</h6>
              <Select
                options={device_access_group}
                defaultValue={groupId}
                isSearchable={false}
                onChange={(i, d) => setGroupId(i)}
                value={groupId}
                // isMulti={true}
                styles={customStyles}
              />
            </div>
          </div>
          {/* <div className="sm-div2">
            <div className="sm-m">
              <h6 className="h6">* {lang.gender_id}</h6>
              <Select
                options={gender}
                defaultValue={genderId}
                isSearchable={false}
                onChange={(i, d) => setGenderId(i)}
                value={genderId}
                // isMulti={true}
                styles={customStyles}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">* {lang.group}</h6>
              <Select
                options={device_access_group}
                defaultValue={groupId}
                isSearchable={false}
                onChange={(i, d) => setGroupId(i)}
                value={groupId}
                // isMulti={true}
                styles={customStyles}
              />
            </div>
          </div> */}

          <div className="sm-div2">
            <div className="sm-m div-chechbox-p">
              {/* <input
                id="check83"
                type="checkbox"
                // className="checkbox"
                checked={active}
                onChange={() => setActive(!active)}
                style={{ margin: 0 }}
              />
              <label for="check83" className="checkmark"></label>
              <h6 className="h6 ">{lang.active}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={() => setActive(!active)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>

            <div className="sm-m div-chechbox-p">
              {/* <input
                id="check84"
                type="checkbox"
                // className="checkbox"
                checked={singleUser}
                onChange={() => setSingleUser(!singleUser)}
                style={{ margin: 0 }}
              />
              <label for="check84" className="checkmark"></label>
              <h6 className="h6 ">{lang.single_user}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={singleUser}
                      onChange={() => setSingleUser(!singleUser)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.single_user}
                />
              </FormGroup>
            </div>

            <div className="sm-m div-chechbox-p">
              {/* <input
                id="check85"
                type="checkbox"
                // className="checkbox"
                style={{ margin: 0 }}
                checked={isAdmin}
                onChange={() => setIsAdmin(!isAdmin)}
              />
              <label for="check85" className="checkmark"></label>
              <h6 className="h6 ">{lang.is_admin}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAdmin}
                      onChange={() => setIsAdmin(!isAdmin)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.is_admin}
                />
              </FormGroup>
            </div>
            {/* <div className="sm-m div-chechbox-p">
              
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setMarried(!married)}
                      checked={married}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.married}
                />
              </FormGroup>
            </div> */}
            {/* ***** */}
          </div>
          <div className=" sm-m employee-edit-image">
            <h6 className="employees-image-p h6">{lang.image}</h6>
            <div>
              <input
                id="upload"
                type="file"
                accept="image/*"
                onChange={(e) => onSelectFile(e)}
                className="employees-image-input"
              />
            </div>
            <div className="imployees-image" style={{ position: "relative" }}>
              {src ? (
                <div
                  className="container-image-delete-icon"
                  onClick={() => handleDeleteImage()}
                >
                  <i
                    style={{
                      fontSize: "20px",
                      color: "red",
                      marginLeft: "1px",
                    }}
                    class="fa fa-times"
                    aria-hidden="true"
                  ></i>
                </div>
              ) : null}

              <img
                style={{ backgroundColor: "#F7F7F6" }}
                src={
                  src
                    ? src
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                }
                className="images-style"
                onLoad={loadImage}
              />
            </div>

            <p className="image-default-size">{lang.image_size}</p>
          </div>
          <div className="employees-colors-div">
            {/* BACKGROUND COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.backgroundColor}</h6>
              <div className="employees-background-color">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: backgroundColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInput}
                  Value={backgroundColor}
                  onChange={handleInputBackgroundColor}
                  id="input_backgroundColor"
                />
              </div>
              {showSketchPicker ? (
                <div
                  className="SketchPicker-div"
                  onMouseLeave={handleCloseInputColor}
                >
                  <SketchPicker
                    color={backgroundColor}
                    onChangeComplete={handleChangeComplete}
                  />
                </div>
              ) : null}
            </div>
            {/* TITLE COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.fontColor}</h6>
              <div className="employees-background-color">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: titleColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInputColor}
                  Value={titleColor}
                  onChange={handleInputTitleColor}
                  id="inputColor"
                />
              </div>
              {showColorPicker ? (
                <div className="SketchPicker-div" onMouseLeave={handleClose}>
                  <SketchPicker
                    color={titleColor}
                    onChangeComplete={handleChangeCompleteColor}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="sm-div2">
            {/* <div className="sm-m">
              <h6 className="h6">{lang.dob}</h6>
              <Calendar
                onChange={(i) => {
                  setDateOfBreath(i);
                }}
                value={dateOfBreath}
              />
          
            </div> */}
            <div className="sm-m">
              <h6 className="h6">{lang.date_in}</h6>
              <Calendar
                onChange={(i) => {
                  setDateIn(i);
                }}
                value={dateIn}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">{lang.date_out}</h6>
              <Calendar
                onChange={(i) => {
                  setDateOut(i);
                }}
                value={dateOut}
              />
            </div>
          </div>
        </div>
      </div>

      <SaveMenuButton clickButton={() => handleSaveAndCreate()} />

      <Toaster />
    </div>
  );
};

export default EmployeesEdit;
