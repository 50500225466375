import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import RootSaga from "./Sagas";

import { Reducers } from "./Reducers";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore(
  {
    reducer: Reducers,
    middleware: [sagaMiddleware],
  }
  // , applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(RootSaga);

export default store;
