import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import validator from "validator";

import { primaryColor } from "../../../../constants/Colors";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import {
  getHalfAndHalfAction,
  updateHalfAndHalfAction,
} from "../../../../redux/GeneralSetting/Actions";
import Loader from "../../../../components/Loader";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import toast, { Toaster } from "react-hot-toast";
const EditHalf = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    result_successfull,
    result_failed,
    result_message,
    loading_post_code,
    half_and_half_list,
  } = useSelector((state) => state.tabelAreaRedusers);
  const [firstHalf, setFirstHalf] = useState("");
  const [secondHalf, setSecondHalf] = useState("");
  // const [equal, setEqual] = useState(false);
  // const [maxPrice, setMaxPrice] = useState(false);
  // const [byPercent, setByPercent] = useState(false);
  const [radioButton, setRadioBurron] = useState("By percent");

  const handleUpdateHalfAndHalf = () => {
    if (!firstHalf == "" && !validator.isInt(String(firstHalf))) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.the_fisrt_half_format_is_incorrect}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (firstHalf > 100) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.the_percentage_cannot_be_more_than_one_hundred}
          &nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateHalfAndHalfAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          // half_and_half_list[0].IdHalf,
          half_and_half_list
            ? half_and_half_list.length > 0
              ? half_and_half_list[0].IdHalf
              : 0
            : 0,
          radioButton == "Max price" ? 1 : 0,
          radioButton == "By percent" ? 1 : 0,
          radioButton == "Equal" ? 1 : 0,
          firstHalf ? parseInt(firstHalf) : 0,
          secondHalf ? parseInt(100 - firstHalf) : 0
        )
      );
    }
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getHalfAndHalfAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (half_and_half_list.length > 0) {
      let radio = "";
      for (let i = 0; i < half_and_half_list.length; i++) {
        if (half_and_half_list[0].MaxPrice == 1) {
          radio = "Max price";
        } else if (half_and_half_list[0].ByPercent == 1) {
          radio = "By percent";
        } else if (half_and_half_list[0].Equal) {
          radio = "Equal";
        }
      }
      setFirstHalf(half_and_half_list[0].FristHalf);
      setSecondHalf(half_and_half_list[0].Secondhalf);
      setRadioBurron(radio);
    }
  }, [half_and_half_list]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        if (radioButton == "") {
          toast.error(
            <h5>
              {" "}
              &nbsp;&nbsp;{lang.select_one_of_the_checkboxes}&nbsp;&nbsp;{" "}
            </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  className="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        } else if (!firstHalf == "" && !validator.isInt(String(firstHalf))) {
          toast.error(
            <h5>
              {" "}
              &nbsp;&nbsp;{
                lang.the_fisrt_half_format_is_incorrect
              }&nbsp;&nbsp;{" "}
            </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  className="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        } else if (firstHalf > 100) {
          toast.error(
            <h5>
              {" "}
              &nbsp;&nbsp;{lang.the_percentage_cannot_be_more_than_one_hundred}
              &nbsp;&nbsp;{" "}
            </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  className="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        } else {
          dispatch(
            updateHalfAndHalfAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              half_and_half_list[0].IdHalf,
              radioButton == "Max price" ? 1 : 0,
              radioButton == "By percent" ? 1 : 0,
              radioButton == "Equal" ? 1 : 0,
              firstHalf ? parseInt(firstHalf) : 0,
              secondHalf ? parseInt(secondHalf) : 0
            )
          );
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [radioButton, firstHalf, secondHalf]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: window.innerHeight - 220,
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="SIE-div2-chechbox">
            <div className="div-chechbox-p sm-m">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      value="Equal"
                      checked={radioButton === "Equal"}
                      onChange={(e) => setRadioBurron(e.target.value)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.equal}
                />
              </RadioGroup>
            </div>
            <div className="div-chechbox-p sm-m">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={radioButton === "Max price"}
                      value="Max price"
                      onChange={(e) => setRadioBurron(e.target.value)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.max_price}
                />
              </RadioGroup>
            </div>
            <div className=" sm-m div-chechbox-p">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  control={
                    <Radio
                      value="By percent"
                      checked={radioButton === "By percent"}
                      onChange={(e) => setRadioBurron(e.target.value)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.by_percenet}
                />
              </RadioGroup>
            </div>
          </div>
          {radioButton == "By percent" ? (
            <div className="submenuItemEdit_secoundGroupDropDown">
              <div
                className="submenuItemEdit-multiselect-div"
                style={{ width: "35%" }}
              >
                <h6 className=" h6">{lang.first_half}</h6>
                <input
                  className="sm-input input-padding"
                  value={firstHalf}
                  onChange={(e) =>
                    setFirstHalf(e.target.value.replace(/[^\d\.]+/g, ""))
                  }
                />
              </div>
              <div
                className="submenuItemEdit-multiselect-div"
                style={{ width: "35%" }}
              >
                <h6 className="h6 ">{lang.second_half}</h6>
                <input
                  className="sm-input input-padding"
                  value={100 - firstHalf}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleUpdateHalfAndHalf()} />
      {loading_post_code ? <Loader /> : null}
    </div>
  );
};

export default EditHalf;
