import * as actionTypes from "../ActionTypes";

export const getFinancialOrderListAction = (
  login_key,
  user_id,
  shop_id,
  start_date,
  end_date
) => {
  return {
    type: actionTypes.GET_FINANCIAL_ORDER_LIST,
    payload: { login_key, user_id, shop_id, start_date, end_date },
  };
};
export const getFinancialOrderListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_FINANCIAL_ORDER_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getFinancialOrderListFailedAction = (data) => {
  return {
    type: actionTypes.GET_FINANCIAL_ORDER_LIST_FAILED,
    payload: data,
  };
};

export const visibleRefunPopupAction = ([
  data,
  item,
  startDate,
  endDate,
  selected_shop,
]) => {
  return {
    type: actionTypes.VISIBLE_REFUND_POPPUP,
    payload: [data, item, startDate, endDate, selected_shop],
  };
};

export const refundOrderAction = (
  login_key,
  user_id,
  shop_id,
  order_id,
  refund_amount,
  refund_note,
  device_type,
  start_date,
  end_date,
  selected_shop,
  orderlist_or_financial
) => {
  return {
    type: actionTypes.REFUND_ORDER,
    payload: {
      login_key,
      user_id,
      shop_id,
      order_id,
      refund_amount,
      refund_note,
      device_type,
      start_date,
      end_date,
      selected_shop,
      orderlist_or_financial,
    },
  };
};

export const refundOrderSuccessfullAction = (data) => {
  return {
    type: actionTypes.REFUND_ORDER_SUCCESSFULL,
    payload: data,
  };
};

export const refundOrderFailedAction = (data) => {
  return {
    type: actionTypes.REFUND_ORDER_FAILED,
    payload: data,
  };
};

export const getFinancialRefundListAction = (
  login_key,
  user_id,
  shop_id,
  start_date,
  end_date
) => {
  return {
    type: actionTypes.GET_FINANCIAL_REFUND_LIST,
    payload: { login_key, user_id, shop_id, start_date, end_date },
  };
};

export const getFinancialRefundListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_FINANCIAL_REFUND_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getFinancialDebtListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_FINANCIAL_DEBT_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getFinancialRefundListFailedAction = (data) => {
  return {
    type: actionTypes.GET_FINANCIAL_REFUND_LIST_FAILED,
    payload: data,
  };
};

export const visibleCreateDebtPopupAction = ([
  data,
  selected_shop,
  startDate,
  endDate,
]) => {
  return {
    type: actionTypes.VISIBLE_CREATE_DEBT_POPUP,
    payload: [data, selected_shop, startDate, endDate],
  };
};

export const sendDebtAction = (
  login_key,
  user_id,
  shop_id,
  amount,
  refund_note,
  order_id,
  selectedShop,
  startDate,
  endDate
) => {
  return {
    type: actionTypes.SEND_DEBT,
    payload: {
      login_key,
      user_id,
      shop_id,
      amount,
      refund_note,
      order_id,
      selectedShop,
      startDate,
      endDate,
    },
  };
};
export const sendDebtSuccessfullAction = (data) => {
  return {
    type: actionTypes.SEND_DEBT_SUCCESSFULL,
    payload: data,
  };
};
export const sendDebtFailedAction = (data) => {
  return {
    type: actionTypes.SEND_DEBT_FAILED,
    payload: data,
  };
};
