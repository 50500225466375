import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import store from "store2";
import * as action_types from "../ActionTypes";
import {
  getPrivacyPolicyListAction,
  getPrivacyPolicyListSuccessfullAction,
  getPrivacyPolicyListFailedAction,
  updatePrivacyPolicySuccessfullAction,
  updatePrivacyPolicyFailedAction,
  getExternalCompanyListFailedAction,
  getExternalCompanyListSuccessfullAction,
  updateExternalCompanyFailedAction,
  updateExternalCompanySuccessfullAction,
  getExternalCompanyListAction,
  getSubmenuPublicListFailedActiom,
  getSubmenuPublicListSuccessfullActiom,
  getSubItemPublicListFailedAction,
  getSubItemPublicListSuccessfullAction,
  createPublicSubItemSuccessfullAction,
  createPublicSubItemFailedAction,
  updatePublicSubItemSuccessfullAction,
  updatePublicSubItemFailedAction,
  deletePublicSubItemSuccessfullAction,
  deletePublicSubItemFailedAction,
  getSubmenuPublicListActiom,
  getSubItemPublicListAction,
  getCustomersCallListFailedAction,
  getCustomersCallListSuccessfullAction,
} from "./Actions";
import {
  getPrivacyPolicyService,
  updatePrivacyPolicyService,
  getExternalCompanyService,
  updateExternalCompanyService,
  getSubmenuPublicListService,
  getSubItemPublicListService,
  createPublicSubItemService,
  updatePublicSubItemService,
  deletePublicSubItemService,
  getCustomersCallListService,
} from "../../services/MainNavGeneralServices";

function* getPrivacyPolicy({ payload }) {
  try {
    const response = yield call(
      getPrivacyPolicyService,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.privacy_policy) {
          if (response.data.privacy_policy.length > 0) {
            yield put(
              getPrivacyPolicyListSuccessfullAction(
                response.data.privacy_policy
              )
            );
          } else {
            yield put(getPrivacyPolicyListFailedAction(""));
          }
        } else {
          yield put(getPrivacyPolicyListFailedAction(""));
        }
      } else {
        yield put(
          getPrivacyPolicyListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getPrivacyPolicyListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getPrivacyPolicyListFailedAction(error.message));
  }
}
function* updatePrivacyPolicy({ payload }) {
  try {
    const response = yield call(
      updatePrivacyPolicyService,
      payload.login_key,
      payload.user_id,
      payload.privacy_policy
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updatePrivacyPolicySuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getPrivacyPolicyListAction(payload.login_key, payload.user_id)
        );
      } else {
        yield put(updatePrivacyPolicyFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updatePrivacyPolicyFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updatePrivacyPolicyFailedAction(error.message));
  }
}
// EXTERNAL COMPANY

function* getExternalCompany({ payload }) {
  try {
    const response = yield call(
      getExternalCompanyService,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.CompanyList) {
          if (response.data.CompanyList.length > 0) {
            yield put(
              getExternalCompanyListSuccessfullAction(response.data.CompanyList)
            );
          } else {
            yield put(getExternalCompanyListFailedAction(""));
          }
        } else {
          yield put(getExternalCompanyListFailedAction(""));
        }
      } else {
        yield put(
          getExternalCompanyListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getExternalCompanyListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getExternalCompanyListFailedAction(error.message));
  }
}
function* updateExternalCompany({ payload }) {
  try {
    const response = yield call(
      updateExternalCompanyService,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.external_company_id,
      payload.title,
      payload.shop_group_id,
      payload.profile_image,
      payload.last_profile_image_name
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateExternalCompanySuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getExternalCompanyListAction(payload.login_key, payload.user_id)
        );
      } else {
        yield put(
          updateExternalCompanyFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateExternalCompanyFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateExternalCompanyFailedAction(error.message));
  }
}
// SUBMENU PUBLIC
function* getSubmenuPublic({ payload }) {
  try {
    const response = yield call(
      getSubmenuPublicListService,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Submenus_public_list) {
          if (response.data.Submenus_public_list.length > 0) {
            yield put(
              getSubmenuPublicListSuccessfullActiom(
                response.data.Submenus_public_list
              )
            );
          } else {
            yield put(getSubmenuPublicListFailedActiom(""));
          }
        } else {
          yield put(getSubmenuPublicListFailedActiom(""));
        }
      } else {
        yield put(
          getSubmenuPublicListFailedActiom(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getSubmenuPublicListFailedActiom(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getSubmenuPublicListFailedActiom(error.message));
  }
}
// SUBITEM PUBLIC
function* getSubItemPublic({ payload }) {
  try {
    const response = yield call(
      getSubItemPublicListService,
      payload.login_key,
      payload.user_id,
      payload.sub_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.sub_item_list) {
          if (response.data.sub_item_list.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.sub_item_list.length; i++) {
              my_list.push({
                ...response.data.sub_item_list[i],
                showEditBox: false,
                showDeleteBox: false,
                selected: false,
              });
            }
            yield put(getSubItemPublicListSuccessfullAction(my_list));
          } else {
            yield put(getSubItemPublicListFailedAction(""));
          }
        } else {
          yield put(getSubItemPublicListFailedAction(""));
        }
      } else {
        yield put(
          getSubItemPublicListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getSubItemPublicListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getSubItemPublicListFailedAction(error.message));
  }
}

//CREATE PUBLIC SUBITEM
function* createPublicSubItem({ payload }) {
  try {
    const response = yield call(
      createPublicSubItemService,
      payload.login_key,
      payload.user_id,
      payload.sub_id,
      payload.title,
      payload.description
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createPublicSubItemSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getSubItemPublicListAction(
            payload.login_key,
            payload.user_id,
            payload.sub_id
          )
        );
      } else {
        yield put(createPublicSubItemFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createPublicSubItemFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(createPublicSubItemFailedAction(error.message));
  }
}

//UPDATE PUBLIC SUBITEM
function* updatePublicSubItem({ payload }) {
  try {
    const response = yield call(
      updatePublicSubItemService,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.item_id,
      payload.app_title,
      payload.app_desc,
      payload.sub_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updatePublicSubItemSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getSubItemPublicListAction(
            payload.login_key,
            payload.user_id,
            payload.sub_id
          )
        );
      } else {
        yield put(updatePublicSubItemFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updatePublicSubItemFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updatePublicSubItemFailedAction(error.message));
  }
}
//DELETE PUBLIC SUBITEM

function* deletePublicSubItem({ payload }) {
  try {
    const response = yield call(
      deletePublicSubItemService,
      payload.login_key,
      payload.user_id,
      payload.item_id,
      payload.sub_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deletePublicSubItemSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getSubItemPublicListAction(
            payload.login_key,
            payload.user_id,
            payload.sub_id
          )
        );
      } else {
        yield put(deletePublicSubItemFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deletePublicSubItemFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deletePublicSubItemFailedAction(error.message));
  }
}
function* getCustomersCallList({ payload }) {
  try {
    const response = yield call(
      getCustomersCallListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.text_search
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.call_list) {
          if (response.data.call_list.length > 0) {
            yield put(
              getCustomersCallListSuccessfullAction(response.data.call_list)
            );
          } else {
            yield put(getCustomersCallListFailedAction(""));
          }
        } else {
          yield put(getCustomersCallListFailedAction(""));
        }
      } else {
        yield put(
          getCustomersCallListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getCustomersCallListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getCustomersCallListFailedAction(error.message));
  }
}
export function* watchGetCustomersCallList() {
  yield takeLatest(action_types.GET_CUSTOMERS_CALL_LIST, getCustomersCallList);
}
export function* watchDeleteSubItemPublicList() {
  yield takeLatest(action_types.DELETE_PUBLIC_SUBITEM, deletePublicSubItem);
}
export function* watchUpdateSubItemPublicList() {
  yield takeLatest(action_types.UPDATE_PUBLIC_SUBITEM, updatePublicSubItem);
}
export function* watchCreateSubItemPublicList() {
  yield takeLatest(action_types.CREATE_PUBLIC_SUBITEM, createPublicSubItem);
}
export function* watchGetSubItemPublicList() {
  yield takeLatest(action_types.GET_SUBITEM_PUBLIC_LIST, getSubItemPublic);
}
export function* watchGetSubmenuPublicList() {
  yield takeLatest(action_types.GET_SUBMENU_PUBLIC_LIST, getSubmenuPublic);
}
export function* watchGetExternalCompany() {
  yield takeLatest(action_types.GET_EXTERNAL_COMPANY_LIST, getExternalCompany);
}
export function* watchUpdateExternalCompany() {
  yield takeLatest(action_types.UPDATE_EXTERNAL_COMPANY, updateExternalCompany);
}
export function* watchGetPrivacyPolicy() {
  yield takeLatest(action_types.GET_PRIVACY_POLICY_LIST, getPrivacyPolicy);
}
export function* watchUpdatePrivacyPolicy() {
  yield takeLatest(action_types.UPDATE_PRIVACY_POLICY, updatePrivacyPolicy);
}

export default function* rootGeneral() {
  yield all([
    fork(watchGetPrivacyPolicy),
    fork(watchUpdatePrivacyPolicy),
    fork(watchGetExternalCompany),
    fork(watchUpdateExternalCompany),
    fork(watchGetSubmenuPublicList),
    fork(watchGetSubItemPublicList),
    fork(watchCreateSubItemPublicList),
    fork(watchUpdateSubItemPublicList),
    fork(watchDeleteSubItemPublicList),
    fork(watchGetCustomersCallList),
  ]);
}
