import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Radio from "@mui/material/Radio";
import moment from "moment";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  getShopSettingTimeGapListAction,
  updateShopSettingTimeGapAction,
} from "../../../../redux/settings/Action";
import Loader from "../../../../components/Loader";
import { times, hours, minutes, segmentations } from "../Componenets/List";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
const TimeGap = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    loading_appSettings,
    result_successfull,
    result_failed,
    result_message,
    get_shop_setting_time_gap_list,
  } = useSelector((state) => state.appSettingsRedusers);

  const [ordinaryDelivery, setOrdinaryDelivery] = useState(null);
  const [crowdedDelivery, setCrowdedDelivery] = useState(null);
  const [ordinaeryCollection, setOrdinaryCollection] = useState(null);
  const [crowdedCollection, setCrowdedCollection] = useState(null);
  const [active, setActive] = useState(false);
  const [displayTimeGapInApp, setDisplayTimeGapInApp] = useState(false);
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [segmentationDelivery, setSegmentationDelivery] = useState(null);
  const [segmentationCollection, setSegmentationCollection] = useState(null);
  const [scrollStatus, setScrollStatus] = useState(false);
  const customStyles = {
    control: (base, state) => ({
      width: "100%",

      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleSaveTimeGap = () => {
    if (
      ordinaryDelivery == null ||
      crowdedDelivery == null ||
      ordinaeryCollection == null ||
      crowdedCollection == null ||
      segmentationCollection == null ||
      segmentationDelivery == null ||
      hour == null ||
      minute == null
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateShopSettingTimeGapAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          ordinaryDelivery.label,
          crowdedDelivery.label,
          ordinaeryCollection.label,
          crowdedCollection.label,
          `${hour.label}${":"}${minute.label}`,
          displayTimeGapInApp ? 1 : 0,
          active,
          segmentationDelivery.label,
          segmentationCollection.label
        )
      );
    }
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getShopSettingTimeGapListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId
        )
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    const copy_times = [...times];
    const times_label = [];
    const copy_hours = [...hours];
    const copy_minutes = [...minutes];
    const hour1 = [];
    const min1 = [];
    let normal_delivery;
    let busy_delivery;
    let normal_collection;
    let busy_collection;
    let delivet_segmentation;
    let collection_segmentation;
    for (let i = 0; i < copy_times.length; i++) {
      times_label.push(copy_times[i].label);
    }
    for (let i = 0; i < copy_hours.length; i++) {
      hour1.push(copy_hours[i].value);
    }
    for (let i = 0; i < copy_minutes.length; i++) {
      min1.push(copy_minutes[i].value);
    }
    if (get_shop_setting_time_gap_list.length > 0) {
      if (get_shop_setting_time_gap_list[0].NormalDelivery) {
        if (
          times_label.includes(
            parseInt(get_shop_setting_time_gap_list[0].NormalDelivery)
          )
        ) {
          let index = times_label.indexOf(
            parseInt(get_shop_setting_time_gap_list[0].NormalDelivery)
          );
          normal_delivery = {
            value: copy_times[index].value,
            label: copy_times[index].label,
          };
        }
      } else {
        normal_delivery = null;
      }
      if (get_shop_setting_time_gap_list[0].BusyDelivery) {
        if (
          times_label.includes(
            parseInt(get_shop_setting_time_gap_list[0].BusyDelivery)
          )
        ) {
          let index = times_label.indexOf(
            parseInt(get_shop_setting_time_gap_list[0].BusyDelivery)
          );
          busy_delivery = {
            value: copy_times[index].value,
            label: copy_times[index].label,
          };
        }
      } else {
        busy_delivery = null;
      }
      if (get_shop_setting_time_gap_list[0].NormalCollection) {
        if (
          times_label.includes(
            parseInt(get_shop_setting_time_gap_list[0].NormalCollection)
          )
        ) {
          let index = times_label.indexOf(
            parseInt(get_shop_setting_time_gap_list[0].NormalCollection)
          );
          normal_collection = {
            value: copy_times[index].value,
            label: copy_times[index].label,
          };
        }
      } else {
        normal_collection = null;
      }
      if (get_shop_setting_time_gap_list[0].BusyCollection) {
        if (
          times_label.includes(
            parseInt(get_shop_setting_time_gap_list[0].BusyCollection)
          )
        ) {
          let index = times_label.indexOf(
            parseInt(get_shop_setting_time_gap_list[0].BusyCollection)
          );
          busy_collection = {
            value: copy_times[index].value,
            label: copy_times[index].label,
          };
        }
      } else {
        busy_collection = null;
      }
      if (get_shop_setting_time_gap_list[0].DeliveryTimeSegmentation) {
        if (
          times_label.includes(
            parseInt(get_shop_setting_time_gap_list[0].DeliveryTimeSegmentation)
          )
        ) {
          let index = times_label.indexOf(
            parseInt(get_shop_setting_time_gap_list[0].DeliveryTimeSegmentation)
          );
          delivet_segmentation = {
            value: copy_times[index].value,
            label: copy_times[index].label,
          };
        }
      } else {
        delivet_segmentation = null;
      }
      if (get_shop_setting_time_gap_list[0].CollectionTimeSegmentation) {
        if (
          times_label.includes(
            parseInt(
              get_shop_setting_time_gap_list[0].CollectionTimeSegmentation
            )
          )
        ) {
          let index = times_label.indexOf(
            parseInt(
              get_shop_setting_time_gap_list[0].CollectionTimeSegmentation
            )
          );
          collection_segmentation = {
            value: copy_times[index].value,
            label: copy_times[index].label,
          };
        }
      } else {
        collection_segmentation = null;
      }
      if (get_shop_setting_time_gap_list[0].ExpireTime) {
        let hour2 = "00";
        let min2 = "00";
        const expire_time =
          get_shop_setting_time_gap_list[0].ExpireTime.split(":");

        if (hour1.includes(parseInt(expire_time[0]))) {
          let index = hour1.indexOf(parseInt(expire_time[0]));
          hour2 = {
            value: copy_hours[index].value,
            label: copy_hours[index].label,
          };
        }
        if (min1.includes(parseInt(expire_time[1]))) {
          let index = min1.indexOf(parseInt(expire_time[1]));
          min2 = {
            value: copy_minutes[index].value,
            label: copy_minutes[index].label,
          };
        }
        setHour(hour2);
        setMinute(min2);
        get_shop_setting_time_gap_list[0].ExpireTime = `${hour2.label}:${min2}`;
      } else {
        get_shop_setting_time_gap_list[0].DisplayTimeGap = null;
      }
      get_shop_setting_time_gap_list[0].NormalDelivery = normal_delivery;
      get_shop_setting_time_gap_list[0].BusyDelivery = busy_delivery;
      get_shop_setting_time_gap_list[0].NormalCollection = normal_collection;
      get_shop_setting_time_gap_list[0].BusyCollection = busy_collection;
      get_shop_setting_time_gap_list[0].CollectionTimeSegmentation =
        collection_segmentation;
      get_shop_setting_time_gap_list[0].DeliveryTimeSegmentation =
        delivet_segmentation;
      setOrdinaryDelivery(get_shop_setting_time_gap_list[0].NormalDelivery);
      setCrowdedDelivery(get_shop_setting_time_gap_list[0].BusyDelivery);
      setOrdinaryCollection(get_shop_setting_time_gap_list[0].NormalCollection);
      setCrowdedCollection(get_shop_setting_time_gap_list[0].BusyCollection);
      setDisplayTimeGapInApp(get_shop_setting_time_gap_list[0].DisplayTimeGap);

      setDisplayTimeGapInApp(get_shop_setting_time_gap_list[0].DisplayTimeGap);
      setActive(get_shop_setting_time_gap_list[0].ActiveTimeGap);
      setSegmentationCollection(
        get_shop_setting_time_gap_list[0].CollectionTimeSegmentation
      );
      setSegmentationDelivery(
        get_shop_setting_time_gap_list[0].DeliveryTimeSegmentation
      );
    }
  }, [get_shop_setting_time_gap_list]);

  useImperativeHandle(forwardedRef, () => ({
    saveTimeGap: () => {
      handleSaveTimeGap();
    },
  }));
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      if (!get_shop_setting_time_gap_list) {
        dispatch(setDefaultAction());
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  useEffect(() => {
    if (scrollStatus) {
      document.getElementById("container_scroll").scroll({
        top: document.getElementById("container_scroll").scrollHeight,
        behavior: "smooth",
      });
    } else {
      document.getElementById("container_scroll").scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [scrollStatus]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container" id="container_scroll">
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div2">
            <p style={{ width: "10%" }}></p>
            <div className="time_gap_container_raddio_button">
              <RadioGroup
                className="time_gap_radio_group"
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  //   value={item.Id}
                  control={
                    <Radio
                      checked={active == 1 ? true : false}
                      value={1}
                      onChange={(e) => setActive(e.target.value)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.ordinary_time}
                />
              </RadioGroup>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                className="time_gap_radio_group"
              >
                <FormControlLabel
                  //   value={item.Id}
                  control={
                    <Radio
                      checked={active == 2 ? true : false}
                      value={2}
                      onChange={(e) => setActive(e.target.value)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.crowded_time}
                />
              </RadioGroup>
            </div>
          </div>
          <div className="sm-div2">
            <p className="time_gap_delivery_collection_title_styles">
              * {lang.delivery}:
            </p>
            <div className="time_gap_container_delivery_collection_dropDown">
              <div style={{ width: "49%" }}>
                <Select
                  options={times}
                  //   defaultValue={kioskShowHideValues}
                  isSearchable={false}
                  onChange={(list, item) => setOrdinaryDelivery(list)}
                  value={ordinaryDelivery}
                  //   isMulti={true}
                  styles={customStyles}
                  isClearable={false}
                />
              </div>
              <div style={{ width: "49%" }}>
                <Select
                  options={times}
                  //   defaultValue={kioskShowHideValues}
                  isSearchable={false}
                  onChange={(list, item) => setCrowdedDelivery(list)}
                  value={crowdedDelivery}
                  //   isMulti={true}
                  styles={customStyles}
                  isClearable={false}
                />
              </div>
            </div>
          </div>
          <div className="sm-div2">
            <p className="time_gap_delivery_collection_title_styles">
              * {lang.collection}:
            </p>
            <div className="time_gap_container_delivery_collection_dropDown">
              <div style={{ width: "49%" }}>
                <Select
                  options={times}
                  //   defaultValue={kioskShowHideValues}
                  isSearchable={false}
                  onChange={(list, item) => setOrdinaryCollection(list)}
                  value={ordinaeryCollection}
                  //   isMulti={true}
                  styles={customStyles}
                  isClearable={false}
                />
              </div>
              <div style={{ width: "49%" }}>
                <Select
                  options={times}
                  //   defaultValue={kioskShowHideValues}
                  isSearchable={false}
                  onChange={(list, item) => setCrowdedCollection(list)}
                  value={crowdedCollection}
                  //   isMulti={true}
                  styles={customStyles}
                  isClearable={false}
                />
              </div>
            </div>
          </div>

          <div className="sm-div2">
            <div className="sm-m  div-chechbox-p ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={displayTimeGapInApp}
                      onChange={() =>
                        setDisplayTimeGapInApp(!displayTimeGapInApp)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active_in_app}
                />
              </FormGroup>
            </div>

            <div className="time_gap_container_expire_time">
              <p>* {lang.expire_crowded_time_after} :</p>
              <div className="time_gap_container_hour_minute">
                <div className="time_gap_container_hour_minute_dropDown">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      options={hours}
                      //   defaultValue={kioskShowHideValues}
                      isSearchable={false}
                      onChange={(list, item) => setHour(list)}
                      value={hour}
                      //   isMulti={true}
                      placeholder="0"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={customStyles}
                      isClearable={false}
                    />
                  </div>
                  <p>{lang.hour}</p>
                </div>
                <div className="time_gap_container_hour_minute_dropDown">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      options={minutes}
                      //   defaultValue={kioskShowHideValues}
                      isSearchable={false}
                      onChange={(list, item) => setMinute(list)}
                      value={minute}
                      //   isMulti={true}
                      placeholder="0"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={customStyles}
                      isClearable={false}
                    />
                  </div>
                  <p>{lang.minute}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="sm-div2">
            <div
              style={{
                width: "45%",
                marginBottom: scrollStatus ? "200px" : null,
              }}
            >
              <p>* {lang.delivery_segmentation}</p>
              <Select
                options={segmentations}
                //   defaultValue={kioskShowHideValues}
                isSearchable={false}
                onChange={(list, item) => setSegmentationDelivery(list)}
                value={segmentationDelivery}
                //   isMulti={true}
                styles={customStyles}
                isClearable={false}
                onMenuOpen={() => setScrollStatus(true)}
                onMenuClose={() => setScrollStatus(false)}
              />
            </div>
            <div
              style={{
                marginBottom: scrollStatus ? "140px" : null,
                width: "45%",
              }}
            >
              <p>* {lang.collection_segmentation}</p>
              <Select
                options={segmentations}
                //   defaultValue={kioskShowHideValues}
                isSearchable={false}
                onChange={(list, item) => setSegmentationCollection(list)}
                value={segmentationCollection}
                //   isMulti={true}
                styles={customStyles}
                isClearable={false}
                onMenuOpen={() => setScrollStatus(true)}
                onMenuClose={() => setScrollStatus(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TimeGap;
