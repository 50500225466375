import React, { useState, useEffect } from "react";
import useScrollbarSize from "react-scrollbar-size";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, NavLink } from "react-router-dom";
import Loader from "../../../../components/Loader";
import toast, { Toaster } from "react-hot-toast";
import {
  primaryColor,
  white,
  main_nav_button_color,
} from "../../../../constants/Colors";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  getTabelAreaAction,
  selectedAreaAction,
  selectedAreaEmptyAction,
  selectedTabelsAction,
} from "../../../../redux/GeneralSetting/Actions";
import { changeSelectedItemColorOnFocusAction } from "../../../../redux/menu/Actions";
import TabelsList from "./TabelsList";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";
import { padding_menu_list_outer } from "../../../../constants/Sizes";
import TabelEdit from "./TabelEdit";
import AreaEdit from "./AreaEdit";

const General = () => {
  const navigate = useNavigate();
  const { height, width } = useScrollbarSize();
  const dispatch = useDispatch();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const {
    tabel_area_list,
    loading_tabel_area,
    result_failed,
    result_successfull,
    result_message,
    get_tabel_area_list_loading,
    selected_area,
    tabels_list,
    selected_tabel,
  } = useSelector((state) => state.tabelAreaRedusers);
  const { change_selected_item_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const { lang } = useSelector((state) => state.selectedLanguage);

  const [clickedItem, setClickedItem] = useState(1);

  const handleEditItem = (item, key) => {
    setClickedItem(key);
    dispatch(selectedAreaAction(item));
    dispatch(selectedTabelsAction(null));
  };
  const handleCreateItem = (key) => {
    setClickedItem(key);
    dispatch(selectedAreaEmptyAction(null));
    dispatch(selectedAreaAction(null));
  };
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getTabelAreaAction(login_key, user_id, selected_shop[0].ShopId, 0, 0)
      );
    }
  }, [selected_shop]);
  const lastItem = tabel_area_list[tabel_area_list.length - 1];
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      if (!selected_area || !selected_tabel) {
        setClickedItem(1);
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="c-div"
      style={{
        height: window.innerHeight - 220,
      }}
      onClick={() => dispatch(changeSelectedItemColorOnFocusAction(false))}
    >
      <div
        className="s-div"
        style={{
          borderLeft: ".3px solid white",
          borderRight: `.3px solid${main_nav_button_color}`,
        }}
      >
        <div id="idd" className="menu_list_header_container">
          {/* <div className="categort_header_container">
            <h6>{lang.area_list}</h6>
          </div> */}
          <div>
            {tabel_area_list.length > 0 ? (
              <div style={{ marginBottom: "57px" }}>
                {tabel_area_list.map((item, index) => (
                  <div key={index}>
                    {/* {item.IsDeleted ? null : ( */}
                    <div>
                      <div className="category_list_container">
                        <div
                          style={{
                            backgroundColor:
                              item.AppVisible && item.KioskVisible
                                ? white
                                : (!item.AppVisible && item.KioskVisible) ||
                                  (item.AppVisible && !item.KioskVisible)
                                ? "#f5f5f5"
                                : "#e4e4e4",
                            border: selected_area
                              ? selected_area.Id == item.Id
                                ? "2px solid #f38321"
                                : null
                              : null,
                          }}
                          //  onClick={()=>handleEditItem(item,1)}
                          className="menu-div ganaral_app_setting_counteine_tabel_name general-menu-div "
                        >
                          <div
                            style={{ width: "90%", height: "100%" }}
                            onClick={() => handleEditItem(item, 1)}
                          >
                            <p
                              id="p1"
                              className="category_title 
                          general-category-title"
                            >
                              {item.TableName}
                            </p>
                          </div>
                          <i
                            style={{
                              color: change_selected_item_color_on_focus
                                ? selected_area
                                  ? selected_area.Id == item.Id
                                    ? "#f38321"
                                    : null
                                  : null
                                : null,
                            }}
                            class="fa fa-pencil"
                            aria-hidden="true"
                            onClick={() => handleEditItem(item, 3)}
                          ></i>
                        </div>
                      </div>
                    </div>
                    {/* // )} */}
                  </div>
                ))}
              </div>
            ) : (
              <div
                className="div_submenu_add_container"
                style={{
                  zIndex: 1,
                  // marginTop: "200p",
                  position: "absolute",
                  left: "0px",
                  right: "0px",
                }}
              >
                <h4>{lang.noOption}</h4>
                <span onClick={() => handleCreateItem(3)}>
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  <p>{lang.add_area}</p>
                </span>
              </div>
            )}
            {tabel_area_list.length > 0 ? (
              <div
                className="div_submenu_add_container_fixed"
                onClick={() => handleCreateItem(3)}
                style={{
                  right:
                    (window.innerWidth * 2) / 100 +
                    width +
                    marginNormalMenuHorizontal,
                  bottom: "19px",
                  left: "340.5px",
                }}
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
                <p>{lang.add_area}</p>
              </div>
            ) : null}
          </div>
        </div>
        {get_tabel_area_list_loading ? <Loader /> : null}
      </div>

      <div className="Menu-div_countainer-component">
        {clickedItem == 1 ? (
          <div className=" Menu-div-countainer-CategoryEdit_component">
            {!get_tabel_area_list_loading ? (
              <TabelsList setClickedItem={(i) => setClickedItem(i)} />
            ) : null}
          </div>
        ) : clickedItem == 2 ? (
          <TabelEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 3 ? (
          <AreaEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : null}
        {loading_tabel_area ? <Loader /> : null}
      </div>
    </div>
  );
};

export default General;
