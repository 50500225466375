import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  createSubmenuSuccessfullActiion,
  createSubmenuFailedAction,
  getSubmenuAction,
  getSubmenuSuccessfullAction,
  getSubmenuFailedAction,
  deleteSubmenuFailedAction,
  deleteSubmenuSuccessfullAction,
  updateSubmenuFailedAction,
  updateSubmenuSuccessfullAction,
  createSubmenuItemAction,
  createSubmenuItemFailedAction,
  createSubmenuItemSuccessfullAction,
  getSubmenuItemFailedAction,
  getSubmenuItemSuccessfullAction,
  getSubmenuItemAction,
  deleteSubmenuItemAction,
  updateSubmenuItemEditFailedAction,
  deleteSubmenuItemSuccessfullAction,
  deleteSubmenuItemFailedAction,
  updateSubmenuItemEditAction,
  updateSubmenuItemEditSuccessfullAction,
  importSubItemFailedAction,
  importSubItemSuccessfullAction,
  showSelectItemPopupAction,
  importSubItemAction,
  getNewSubmenuIdAction,
  updateProductSubmenuWebSortSuccessfullAction,
  updateProductSubmenuWebSortFailedAction,
  updateProductSubmenuAppSortFailedAction,
  updateProductSubmenuAppSortSuccessfullAction,
  updateSubmenuItemWebSortSuccessfullAction,
  updateSubmenuItemWebSortFailedAction,
  updateSubmenuItemAppSortSuccessfullAction,
  updateSubmenuItemAppSortFailedAction,
} from "./Action";
import {
  getSubmenuServices,
  createSubmenuServices,
  deleteSubmenuServices,
  updateSubmnuServices,
  createSubmenuItemServices,
  getSubmenuItemServices,
  deleteSubmenuItemServices,
  updateSubmenuItemEditServices,
  importSubItemService,
  updateSubmenuSortService,
  updateProductSubmenuWebSortService,
  updateProductSubmenuAppSortService,
  updateSubmenuItemWebSortService,
  updateSubmenuItemAppSortService,
} from "../../services/SubmenuServices";
import { getDropDownAction } from "../menu/Actions";
export function* createSubmenu({ payload }) {
  try {
    const responsive = yield call(
      createSubmenuServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.title,
      payload.description,
      payload.Include_Dressing,
      payload.ids,
      payload.import_category,
      payload.import_from_popup
    );

    if (responsive.status == 200) {
      if (responsive.data.ResultCode == 1) {
        yield put(getNewSubmenuIdAction(responsive.data.Id));
        if (payload.Include_Dressing) {
          yield put(getSubmenuItemSuccessfullAction([]));
          yield put(showSelectItemPopupAction(true));
        } else {
          if (!payload.import_from_popup) {
            yield put(
              createSubmenuSuccessfullActiion(responsive.data.ResultMessage)
            );
          } else {
            yield put(
              importSubItemAction(
                payload.login_key,
                payload.user_id,
                payload.shopId,
                payload.ids,
                responsive.data.Id,
                payload.import_category
              )
            );
          }
        }
        yield put(
          getSubmenuAction(
            payload.login_key,
            payload.user_id,
            payload.shopId,
            0
          )
        );
        yield put(
          getDropDownAction(payload.login_key, payload.user_id, payload.shopId)
        );
      } else {
        yield put(createSubmenuFailedAction(responsive.data.ResultMessage));
      }
    } else {
      yield put(createSubmenuFailedAction("Server error, try again"));
    }
  } catch (error) {
    yield put(createSubmenuFailedAction(error.message));
  }
}

export function* getSubmenu({ payload }) {
  try {
    const response = yield call(
      getSubmenuServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.product_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Submenuslist) {
          if (response.data.Submenuslist.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.Submenuslist.length; i++) {
              if (!response.data.Submenuslist[i].IsDeleted) {
                my_list.push({
                  ...response.data.Submenuslist[i],
                  selected: false,
                  showEditBox: false,
                  showDeleteBox: false,
                  showItems: false,
                });
              }
            }
            yield put(getSubmenuSuccessfullAction(my_list));
          } else {
            yield put(getSubmenuFailedAction(""));
          }
        } else {
          yield put(getSubmenuFailedAction(""));
        }
      } else {
        yield put(getSubmenuFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getSubmenuFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getSubmenuFailedAction(error.message));
  }
}

export function* deleteSubmenu({ payload }) {
  try {
    const responsive = yield call(
      deleteSubmenuServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.submenuId
    );
    if (responsive.status == 200) {
      if (responsive.data.ResultCode == 1) {
        yield put(
          deleteSubmenuSuccessfullAction(responsive.data.ResultMessage)
        );
        yield put(
          getSubmenuAction(
            payload.login_key,
            payload.user_id,
            payload.shopId,
            0
          )
        );
        yield put(
          getDropDownAction(payload.login_key, payload.user_id, payload.shopId)
        );
      } else {
        yield put(deleteSubmenuFailedAction(responsive.data.ResultMessage));
      }
    } else {
      yield put(deleteSubmenuFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(deleteSubmenuFailedAction(error.message));
  }
}

function* updateSubmenu({ payload }) {
  try {
    const response = yield call(
      updateSubmnuServices,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shopId,
      payload.app_title,
      payload.app_description,
      payload.web_title,
      payload.web_description,
      payload.kiosk_title,
      payload.kiosk_description,
      payload.secound_language,
      payload.min_select,
      payload.max_select,
      payload.is_override_price,
      payload.extra_q,
      payload.yes_no_q,
      payload.join_before,
      payload.name_first,
      payload.short_name,
      payload.app_vissible,
      payload.web_visible,
      payload.kiosk_visible,
      payload.app_sort_id,
      payload.web_sort_id,
      payload.kiosk_sort_id,
      payload.just_title_and_price,
      payload.number_of_items_column,
      payload.number_of_items_row,
      payload.items_font_size,
      payload.fixed
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateSubmenuSuccessfullAction(response.data.ResultMessage));
        yield put(
          getSubmenuAction(
            payload.login_key,
            payload.user_id,
            payload.shopId,
            0
          )
        );
        yield put(
          getDropDownAction(payload.login_key, payload.user_id, payload.shopId)
        );
      } else {
        yield put(updateSubmenuFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateSubmenuFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(updateSubmenuFailedAction(error.message));
  }
}

function* createSubmenuItem({ payload }) {
  try {
    const response = yield call(
      createSubmenuItemServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.submenuId,
      payload.app_title,
      payload.app_description,
      payload.amount
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createSubmenuItemSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getSubmenuItemAction(
            payload.login_key,
            payload.user_id,
            payload.shopId,
            payload.submenuId
          )
        );
      } else {
        yield put(createSubmenuItemFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createSubmenuFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(createSubmenuItemFailedAction(error.message));
  }
}
function* getSubmenuItem({ payload }) {
  try {
    const response = yield call(
      getSubmenuItemServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.submenuId
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.SubItemslist.length > 0) {
          let my_list = [];
          for (let i = 0; i < response.data.SubItemslist.length; i++) {
            my_list.push({
              ...response.data.SubItemslist[i],
              showEditBox: false,
              showDeleteBox: false,
            });
          }
          yield put(getSubmenuItemSuccessfullAction(my_list));
        } else {
          yield put(getSubmenuItemFailedAction(""));
        }
      } else {
        yield put(getSubmenuItemFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getSubmenuItemFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    put(getSubmenuItemFailedAction(error.message));
  }
}

function* deleteSubmenuItem({ payload }) {
  try {
    const response = yield call(
      deleteSubmenuItemServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.id,
      payload.submenuId
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteSubmenuItemSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getSubmenuItemAction(
            payload.login_key,
            payload.user_id,
            payload.shopId,
            payload.submenuId
          )
        );
      } else {
        yield put(deleteSubmenuItemFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteSubmenuItemFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteSubmenuItemFailedAction(error.message));
  }
}
function* updateSubmenuItemEdit({ payload }) {
  try {
    const response = yield call(
      updateSubmenuItemEditServices,
      payload.formData2,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shopId,
      payload.submenuId,
      payload.price_level_collec_take,
      payload.price_level_eat_in,
      payload.price_level_main,
      payload.app_title,
      payload.app_description,
      payload.web_title,
      payload.web_description,
      payload.kiosk_title,
      payload.kiosk_description,
      payload.secound_language,
      payload.amount_main,
      payload.amount_collection,
      payload.amount_takeaway,
      payload.amount_eat_in,
      payload.app_vissible,
      payload.web_visible,
      payload.kiosk_visible,
      payload.app_bg_color,
      payload.app_title_font_size,
      payload.app_title_font_family,
      payload.app_title_color,
      payload.vatId,
      payload.is_dif_option_vat,
      payload.unitId,
      payload.barcode,
      payload.stock,
      payload.custom_title,
      payload.close_count,
      payload.printer_id,
      payload.report_section,
      payload.product_group,
      payload.app_title_width,
      payload.app_title_height,
      payload.link_to,
      payload.app_sort_id,
      payload.web_sort_id,
      payload.kiosk_sort_id,
      payload.app_image_url,
      payload.app_visible_image,
      payload.web_image_url,
      payload.web_visible_image,
      payload.kiosk_image_url,
      payload.kiosk_visible_image
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateSubmenuItemEditSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getSubmenuItemAction(
            payload.login_key,
            payload.user_id,
            payload.shopId,
            payload.submenuId
          )
        );
      } else {
        yield put(
          updateSubmenuItemEditFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateSubmenuItemEditFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateSubmenuItemEditFailedAction(error.message));
  }
}
function* importSubItem({ payload }) {
  try {
    const response = yield call(
      importSubItemService,

      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.sub_item_id,
      payload.sunmenu_id,
      payload.import_category
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(importSubItemSuccessfullAction(response.data.ResultMessage));
        yield put(
          getSubmenuItemAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.sunmenu_id
          )
        );
      } else {
        yield put(importSubItemFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(importSubItemFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(importSubItemFailedAction(error.message));
  }
}

//UPDATE SUBMENU ITEM SORT
function* updateSubmenuItemWebSort({ payload }) {
  try {
    const response = yield call(
      updateSubmenuItemWebSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.sub_item_id,
      payload.old_sort_id,
      payload.new_sort_id,
      payload.submenu_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateSubmenuItemWebSortSuccessfullAction("Done"));
        yield put(
          getSubmenuItemAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.submenu_id
          )
        );
      } else {
        yield put(
          updateSubmenuItemWebSortFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateSubmenuItemWebSortFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateSubmenuItemWebSortFailedAction(error.message));
  }
}
function* updateSubmenuItemAppSort({ payload }) {
  try {
    const response = yield call(
      updateSubmenuItemAppSortService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.sub_item_id,
      payload.old_sort_id,
      payload.new_sort_id,
      payload.submenu_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateSubmenuItemAppSortSuccessfullAction("Done"));
        yield put(
          getSubmenuItemAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.submenu_id
          )
        );
      } else {
        yield put(
          updateSubmenuItemAppSortFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateSubmenuItemAppSortFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateSubmenuItemAppSortFailedAction(error.message));
  }
}
export function* watchUpdateSubmenuItemAppSort() {
  yield takeLatest(
    action_types.UPDATE_SUBMENU_ITEM_APP_SORT,
    updateSubmenuItemAppSort
  );
}
export function* watchUpdateSubmenuItemWebSort() {
  yield takeLatest(
    action_types.UPDATE_SUBMENU_ITEM_WEB_SORT,
    updateSubmenuItemWebSort
  );
}
export function* watchImportSubItem() {
  yield takeEvery(action_types.IMPORT_SUBITEM, importSubItem);
}

export function* watchUpdateSubmenuItemEdit() {
  yield takeEvery(action_types.UPDATE_SUBMENU_ITEM_EDIT, updateSubmenuItemEdit);
}

export function* watchDeleteSubmenuItem() {
  yield takeLatest(action_types.DELETE_SUBMENU_ITEM, deleteSubmenuItem);
}
export function* watchGetSubmenuItem() {
  yield takeLatest(action_types.GET_SUBMENU_ITEM, getSubmenuItem);
}
export function* watchCreateSubmenuItem() {
  yield takeLatest(action_types.CREATE_SUBMENU_ITEM, createSubmenuItem);
}

export function* watchUpdateSubmenu() {
  yield takeLatest(action_types.UPDATE_SUBMENU, updateSubmenu);
}

export function* watchDeleteSubmenu() {
  yield takeLatest(action_types.DELETE_SUBMENU, deleteSubmenu);
}
export function* watchCreateSubmenu() {
  yield takeLatest(action_types.CREATE_SUBMENU, createSubmenu);
}

export function* watchGetSubmenu() {
  yield takeLatest(action_types.GET_SUBMENU, getSubmenu);
}
export default function* rootSubmenu() {
  yield all([
    fork(watchCreateSubmenu),
    fork(watchGetSubmenu),
    fork(watchDeleteSubmenu),
    fork(watchUpdateSubmenu),
    fork(watchCreateSubmenuItem),
    fork(watchGetSubmenuItem),
    fork(watchDeleteSubmenuItem),
    fork(watchUpdateSubmenuItemEdit),
    fork(watchImportSubItem),
    fork(watchUpdateSubmenuItemWebSort),
    fork(watchUpdateSubmenuItemAppSort),
  ]);
}
