import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  getSaleOverviewListFailedAction,
  getPayMethodSuccessfulllAction,
  getSaleMethodSuccessfullAction,
} from "./Actions";

import { getSalesOvrviewListService } from "../../services/SalesService";

function* getSalesOverViewList({ payload }) {
  try {
    const response = yield call(
      getSalesOvrviewListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.from_date,
      payload.to_date
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.SALE_METHOD) {
          if (response.data.SALE_METHOD.length > 0) {
            yield put(
              getSaleMethodSuccessfullAction(response.data.SALE_METHOD)
            );
          } else {
            yield put(getSaleOverviewListFailedAction(""));
          }
        } else {
          yield put(getSaleOverviewListFailedAction(""));
        }
        if (response.data.paymethod) {
          if (response.data.paymethod.length > 0) {
            yield put(getPayMethodSuccessfulllAction(response.data.paymethod));
          } else {
            yield put(getSaleOverviewListFailedAction(""));
          }
        } else {
          yield put(getSaleOverviewListFailedAction(""));
        }
      } else {
        yield put(getSaleOverviewListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getSaleOverviewListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getSaleOverviewListFailedAction(error.message));
  }
}
export function* watchGetSalesOverViewList() {
  yield takeLatest(action_types.GET_SALES_OVERVIEW_LIST, getSalesOverViewList);
}
export default function* rootSales() {
  yield all([fork(watchGetSalesOverViewList)]);
}
