import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import MenuListItem from "../components/MenuListItem";
import ListHeader from "../components/ListHeader";
import AddMenuItem from "../components/AddMenuItem";
import { main_nav_button_color } from "../constants/Colors";
import { priceLevel } from "../constants/PriceLevel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { primaryColor } from "../constants/Colors";
import {
  createSubmenuItemAction,
  getSubmenuItemAction,
  deleteSubmenuItemAction,
  selectedSubmenuItemAction,
  showSelectItemPopupAction,
  submenuItemSortNumberAction,
  updateSubmenuItemWebSortAction,
  updateSubmenuItemAppSortAction,
  updateSubmenuItemEditAction,
} from "../redux/submenu/Action";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
  changeProductSortLabelAction,
  selectedOtemInSomeLists,
} from "../redux/menu/Actions";
import {
  getSubItemPublicListAction,
  getSubmenuPublicListActiom,
} from "../redux/MainNavGeneral/Actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const SubmenuItem = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef(false);
  const ref = useRef(null);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    printer_list,
    printer_list_id,
    report_section,
    report_section_id,
    product_group,
    product_group_id,
    vat,
    vat_id,
    font_family,
    font_family_id,
    font_size,
    font_size_id,
    link_to,
    link_to_id,
    selected_category,
    change_selected_button_border_color_on_focus,
  } = useSelector((state) => state.categoryList);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const {
    // change_selected_button_border_color_on_focus,
    selected_item_in_all_lists,
    selected_item_in_some_lists,
  } = useSelector((state) => state.categoryList);
  const { selected_Product, change_product_sort_label } = useSelector(
    (state) => state.productList
  );

  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    submenu_item_list,
    selected_submenu,
    selected_submenu_item,
    result_successfull,
    is_dressing,
    sub_item_sort_number,
  } = useSelector((state) => state.submenuRedusers);
  const [submenuList, setSubmenuList] = useState([]);
  const [sortOnWebOrApp, setSortOnWebOrApp] = useState(0);
  const [userAccesses, setUserAccesses] = useState([]);
  const [userType, setUserType] = useState(0);
  const [rolesId, setRolesId] = useState([]);
  const [focused, setFocused] = useState(false);
  const [itemPrice, setItemPrice] = useState(0);
  const [itemAppTitle, setItemAppTitle] = useState("");
  const [itemWebTitle, setItemWebTitle] = useState("");
  const [itemSecondLangusge, setItemSecondLanguage] = useState("");
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  // const [emptyInput, setEmptyInput] = useState(false);
  const handleSortButtons = (key) => {
    // dispatch(selectedOtemInAllLists(null));
    dispatch(selectedSubmenuItemAction(null));
    dispatch(changeProductSortLabelAction(true));
    if (key == 1) {
      setSortOnWebOrApp(1);
    } else {
      setSortOnWebOrApp(2);
    }
  };
  const handleDragEnd = (result) => {
    const copy_list = [...submenuList];
    // dispatch(changeSortLableAction(true));
    if (sortOnWebOrApp == 1) {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setSubmenuList(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];

      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateSubmenuItemWebSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.WebSortId,
            new_item.WebSortId,
            selected_submenu.Id
          )
        );
      }
    } else {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderData);
      setSubmenuList(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];
      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateSubmenuItemAppSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.AppSortId,
            new_item.AppSortId,
            selected_submenu.Id
          )
        );
      }
    }
  };
  const handleAddSubmenuItem = (t, d, a) => {
    dispatch(
      createSubmenuItemAction(
        login_key,
        user_id,
        selected_submenu.ShopId,
        selected_submenu.Id,
        t.replaceAll(`"`, `\"\"`),
        d.replaceAll(`"`, `\"\"`),
        a ? parseFloat(a) : 0
      )
    );
    // setEmptyInput(true);
  };

  const handleClickItem = (item, key) => {
    setClickedItem(key);
    let printerList = [];
    let reportSectionList = null;
    let productGroupList = null;
    let vatList = null;
    let fontFamilyList = null;
    let fontSizeList = null;
    let linkToList = null;
    let price_level_eat_in = null;
    let price_level_main = null;
    let price_level_collec_take = null;
    let price_level_eat_in_id = [];
    let price_level_collec_take_id = [];
    let price_level_main_id = [];
    // SET DEFAULT PRINTER LIST
    if (item.PrinterId) {
      for (let i = 0; i < item.PrinterId.length; i++) {
        if (printer_list_id.includes(parseInt(item.PrinterId[i]))) {
          let index = printer_list_id.indexOf(parseInt(item.PrinterId[i]));
          printerList.push({
            label: printer_list[index].label,
            value: printer_list[index].value,
          });
        }
      }
    }

    // SET DEFAULT REPORT SECTION LIST
    if (item.ReportSection) {
      if (report_section_id.includes(parseInt(item.ReportSection))) {
        let index = report_section_id.indexOf(parseInt(item.ReportSection));
        reportSectionList = {
          label: report_section[index].label,
          value: report_section[index].value,
        };
      }
    }

    // SET DEFAULT RRODUCT GROUP LIST
    if (item.ProductGroup) {
      if (product_group_id.includes(parseInt(item.ProductGroup))) {
        let index = product_group_id.indexOf(parseInt(item.ProductGroup));
        productGroupList = {
          label: product_group[index].label,
          value: product_group[index].value,
        };
      }
    }

    // SET DEFAULT VAT LIST
    if (item.VatId) {
      if (vat_id.includes(parseInt(item.VatId))) {
        let index = vat_id.indexOf(parseInt(item.VatId));
        vatList = {
          label: vat[index].label,
          value: vat[index].value,
        };
      }
    }

    // SET DEFAULT FONT FAMILY LIST
    if (item.AppTitleFontFamily) {
      if (font_family_id.includes(parseInt(item.AppTitleFontFamily))) {
        let index = font_family_id.indexOf(parseInt(item.AppTitleFontFamily));
        fontFamilyList = {
          label: font_family[index].label,
          value: font_family[index].value,
        };
      }
    }

    // SET DEFAULT FONT SIZE LIST
    if (item.AppTitleFontSize) {
      if (font_size_id.includes(parseInt(item.AppTitleFontSize))) {
        let index = font_size_id.indexOf(parseInt(item.AppTitleFontSize));
        fontSizeList = {
          label: font_size[index].label,
          value: font_size[index].value,
        };
      }
    }
    // SET DEFAULT LINK TO LIST
    if (item.Linkto) {
      if (link_to_id.includes(parseInt(item.Linkto))) {
        let index = link_to_id.indexOf(parseInt(item.Linkto));
        linkToList = {
          label: link_to[index].label,
          value: link_to[index].value,
        };
      }
    }
    if (item.PriceLevelEatIn) {
      for (let i = 0; i < priceLevel.length; i++) {
        price_level_eat_in_id.push(priceLevel[i].value);
        if (price_level_eat_in_id.includes(parseInt(item.PriceLevelEatIn))) {
          let index = price_level_eat_in_id.indexOf(
            parseInt(item.PriceLevelEatIn)
          );
          price_level_eat_in = {
            label: priceLevel[index].label,
            value: priceLevel[index].value,
          };
        }
      }
    }
    if (item.PriceLevelCollTake) {
      for (let i = 0; i < priceLevel.length; i++) {
        price_level_collec_take_id.push(priceLevel[i].value);
        if (
          price_level_collec_take_id.includes(parseInt(item.PriceLevelCollTake))
        ) {
          let index = price_level_collec_take_id.indexOf(
            parseInt(item.PriceLevelCollTake)
          );
          price_level_collec_take = {
            label: priceLevel[index].label,
            value: priceLevel[index].value,
          };
        }
      }
    }
    if (item.PriceLevelMain) {
      for (let i = 0; i < priceLevel.length; i++) {
        price_level_main_id.push(priceLevel[i].value);
        if (price_level_main_id.includes(parseInt(item.PriceLevelMain))) {
          let index = price_level_main_id.indexOf(
            parseInt(item.PriceLevelMain)
          );
          price_level_main = {
            label: priceLevel[index].label,
            value: priceLevel[index].value,
          };
        }
      }
    }

    dispatch(
      selectedSubmenuItemAction({
        ...item,
        PrinterId: printerList,
        ReportSection: reportSectionList,
        ProductGroup: productGroupList,
        VatId: vatList,
        AppTitleFontSize: fontSizeList,
        AppTitleFontFamily: fontFamilyList,
        Linkto: linkToList,
        PriceLevelEatIn: price_level_eat_in,
        PriceLevelMain: price_level_main,
        PriceLevelCollTake: price_level_collec_take,
      })
    );
  };
  const handeDeleteSubmenuItem = (item) => {
    dispatch(
      deleteSubmenuItemAction(
        login_key,
        user_id,
        item.ShopId,
        item.Id,
        item.SubMenuId
      )
    );
  };
  const handleEditIconClick = (item) => {
    const copy_product_list = [...submenuList];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.Id == copy_product_list[i].Id) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_list);
    dispatch(selectedOtemInAllLists(item));
  };

  const handleCloseIcon = (item) => {
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
    }

    setSubmenuList(copy_product_list);
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...submenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setSubmenuList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...submenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setSubmenuList(copy_list);
  };
  const handleShowSelectItemPopup = () => {
    dispatch(showSelectItemPopupAction(true));

    dispatch(getSubmenuPublicListActiom(login_key, user_id));
  };
  const handleEditItemPrice = (element) => {
    dispatch(selectedOtemInSomeLists(element));
    const copy_product_option_list = [...submenuList];
    for (let i = 0; i < copy_product_option_list.length; i++) {
      if (element.Id == copy_product_option_list[i].Id) {
        copy_product_option_list[i].showEditBox = true;
      } else {
        copy_product_option_list[i].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_option_list);
  };
  const formData = new FormData();
  const handleSaveItem = () => {
    if (itemAppTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("Id", selected_item_in_some_lists.Id);
      formData.append("ShopId", selected_item_in_some_lists.ShopId);
      formData.append("SubMenuId", selected_item_in_some_lists.SubMenuId);
      formData.append("PriceLevelCollTake", 0);
      formData.append("PriceLevelEatIn", 0);
      formData.append("PriceLevelMain", 0);
      formData.append("AppTitle", itemAppTitle.replaceAll(`"`, `\"\"`));
      formData.append("AppDescription", "");
      formData.append(
        "WebTitle",
        itemAppTitle
          ? itemWebTitle.replaceAll(`"`, `\"\"`)
          : itemAppTitle.replaceAll(`"`, `\"\"`)
      );
      formData.append("WebDescription", "");
      formData.append("KioskTitle", "");
      formData.append("KioskDescription", "");
      formData.append(
        "SecondLanguage",
        itemSecondLangusge.replaceAll(`"`, `\"\"`)
      );
      formData.append("AmountMain", itemPrice ? parseFloat(itemPrice) : 0);
      formData.append("AmountCollection", 0);
      formData.append("AmountTakeaway", 0);
      formData.append("AmountEatIn", 0);
      formData.append("AppVisible", 0);
      formData.append("WebVisible", 0);
      formData.append("KioskVisible", 0);
      formData.append("AppBgColor", "");
      formData.append("AppTitleFontSize", 30);
      formData.append("AppTitleFontFamily", String(4));
      formData.append("AppTitleColor", "");
      formData.append("VatId", 0);
      formData.append("IsDifOptionVat", 0);
      formData.append("UnitId", 0);
      formData.append("Barcode", "");
      formData.append("Stock", 0);
      formData.append("CustomTitle", "");
      formData.append("CloseCount", 0);
      formData.append("PrinterId", "");
      formData.append("ReportSection", 0);
      formData.append("ProductGroup", 0);
      formData.append("AppTileWidth", 0);
      formData.append("AppTileHeight", 0);
      formData.append("Linkto", 1);
      formData.append("AppSortId", 0);
      formData.append("WebSortId", 0);
      formData.append("KioskSortId", 0);
      formData.append("AppImageUrl", "");
      formData.append("AppVisibleImage", 0);
      formData.append("WebImageUrl", "");
      formData.append("WebVisibleImage", 0);
      formData.append("KioskImageUrl", "");
      formData.append("KioskVisibleImage", 0);
      formData.append("LastAppImageUrlName", "");
      formData.append("JustTitleAndPrice", 1);
      formData.append("DeleteImage", 0);
      dispatch(
        updateSubmenuItemEditAction(
          formData,
          login_key,
          user_id,
          selected_item_in_some_lists.Id,
          selected_item_in_some_lists.ShopId,
          selected_item_in_some_lists.SubMenuId
        )
      );
    }
  };

  const handleCloseItemEditBox = (element) => {
    const copy_product_option_list = [...submenuList];
    for (let i = 0; i < copy_product_option_list.length; i++) {
      if (element.Id == copy_product_option_list[i].Id) {
        copy_product_option_list[i].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_option_list);
  };
  useEffect(() => {
    if (selected_submenu) {
      dispatch(
        getSubmenuItemAction(
          login_key,
          user_id,
          selected_submenu.ShopId,
          selected_submenu.Id
        )
      );
    }
  }, [selected_submenu]);

  useEffect(() => {
    if (submenu_item_list) {
      if (submenu_item_list.length > 0) {
        setSubmenuList(submenu_item_list);
      } else {
        setSubmenuList([]);
      }
    } else {
      setSubmenuList([]);
    }
  }, [submenu_item_list]);

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (copy_product_list[index].showEditBox) {
        copy_product_list[index].showEditBox = false;
      }
    }
    dispatch(justTitleAndPriceAction(1));
    setSubmenuList(copy_product_list);

    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(selectedOtemInAllLists(null));
      dispatch(justTitleAndPriceAction(0));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    dispatch(getSubmenuPublicListActiom(login_key, user_id));
  }, []);

  useEffect(() => {
    const copy_product_list = [...submenu_item_list];

    if (sortOnWebOrApp == 1) {
      const sortArrey = copy_product_list.sort(function (a, b) {
        return a.WebSortId - b.WebSortId;
      });

      if (change_product_sort_label) {
        dispatch(changeProductSortLabelAction(false));
        dispatch(selectedSubmenuItemAction(copy_product_list[0]));
        for (let i = 0; i < copy_product_list.length; i++) {
          copy_product_list[i].collapse = i == 0 ? true : false;
        }
      }
      setSubmenuList(sortArrey);
    } else {
      const sortArrey = copy_product_list.sort(function (a, b) {
        return a.AppSortId - b.AppSortId;
      });

      if (change_product_sort_label) {
        dispatch(changeProductSortLabelAction(false));
        dispatch(selectedSubmenuItemAction(sortArrey[0]));
        for (let i = 0; i < sortArrey.length; i++) {
          sortArrey[i].collapse = i == 0 ? true : false;
        }
      }

      setSubmenuList(sortArrey);
    }
    return () => {
      dispatch(submenuItemSortNumberAction(sortOnWebOrApp));
    };
  }, [sortOnWebOrApp, submenu_item_list, change_product_sort_label]);

  useEffect(() => {
    if (sub_item_sort_number == 2) {
      setSortOnWebOrApp(2);
    } else {
      setSortOnWebOrApp(1);
    }
  }, []);

  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);

  useEffect(() => {
    let roles_id = [];
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          setUserType(userAccesses[0].UserType);
          roles_id.push(userAccesses[i].RoleId);
        }
      }
    }
    setRolesId(roles_id);
  }, [userAccesses]);
  useEffect(() => {
    if (selected_item_in_some_lists) {
      setItemPrice(selected_item_in_some_lists.AmountMain);
      setItemAppTitle(selected_item_in_some_lists.AppTitle);
      setItemWebTitle(selected_item_in_some_lists.WebTitle);
      setItemSecondLanguage(selected_item_in_some_lists.SecondLanguage);
    }
  }, [selected_item_in_some_lists]);
  return (
    <div
      ref={ref}
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
      onClick={handleCloseEditBoxWithOnBlur}
    >
      <ListHeader
        title={lang.item_list}
        show_back={is_dressing ? false : true}
        clickedItem={() => setClickedItem(1)}
        sort_switch={true}
        sort_click={(key) => handleSortButtons(key)}
        sort_key={sortOnWebOrApp}
      />
      {userType == 10001202 && rolesId.includes(17) && !rolesId.includes(1) ? (
        <>
          <div className="menu_list_header_container">
            {submenuList.length > 0 ? (
              <div>
                {submenuList.map((element, index) => (
                  <div>
                    {element.showEditBox ? (
                      <div
                        className="menu_list_item_container box_shadow menu-list-container"
                        onClick={(i) => i.stopPropagation()}
                      >
                        <Box
                          component="form"
                          className="menu-list-container-inputs"
                          autoComplete="on"
                        >
                          <TextField
                            key={1}
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",

                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            label={lang.app_title}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,
                                "&.MuiInputLabel-shrink": {
                                  top: 0,
                                },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                                "& label.Mui-focused": {
                                  color: primaryColor,
                                },
                                "&:hover label": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            value={itemAppTitle}
                            // defaultValue={number == 9 ? postCode : appTitle}
                            onChange={(e) => setItemAppTitle(e.target.value)}
                            style={{
                              width: "27%",
                            }}
                            autoFocus={focusedInputKey == 1 ? true : false}
                            onFocus={() => setFocusedInputKey(1)}
                          />
                          <TextField
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",
                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            key={2}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,
                                "&.MuiInputLabel-shrink": {
                                  top: 0,
                                },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            label={lang.web_title}
                            value={itemWebTitle}
                            onChange={(e) => setItemWebTitle(e.target.value)}
                            style={{
                              width: "27%",
                            }}
                            autoFocus={focusedInputKey == 2 ? true : false}
                            onFocus={() => setFocusedInputKey(2)}
                          />
                          <TextField
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",
                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            key={4}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,

                                "&.MuiInputLabel-shrink": {
                                  top: 0,
                                },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            label={lang.secoundLanguage}
                            value={itemSecondLangusge}
                            onChange={(e) =>
                              setItemSecondLanguage(e.target.value)
                            }
                            style={{
                              width: "27%",
                            }}
                            autoFocus={false}
                            onFocus={() => setFocusedInputKey(3)}
                          />
                          <TextField
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "40px",
                                color: "black",
                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            key={4}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,

                                "&.MuiInputLabel-shrink": {
                                  top: 0,
                                },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            label={lang.mainprice}
                            value={itemPrice}
                            onChange={(e) => setItemPrice(e.target.value)}
                            style={{
                              width: "15%",
                            }}
                            autoFocus={false}
                            onFocus={() => setFocusedInputKey(3)}
                          />
                        </Box>

                        <div className="menu-list-container-edit-box-icons">
                          <div
                            className="menu-list-container-icon"
                            onClick={() => handleSaveItem()}
                          >
                            <i
                              class="fa fa-check menu-list-fa-check"
                              aria-hidden="true"
                              style={{ fontSize: " 23px" }}
                            ></i>
                          </div>
                          <div
                            className="menu-list-container-icon"
                            onClick={() => handleCloseItemEditBox(element)}
                          >
                            <i
                              class="fa fa-times menu-list-fa-times"
                              aria-hidden="true"
                              style={{ fontSize: " 23px" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="menu_list_item_container">
                        <div
                          className="menu_list_title_container"
                          style={{
                            marginRight: "-15px",
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: "18px",
                            backgroundColor: "#dddddd",
                          }}
                          onClick={(i) => i.stopPropagation()}
                        >
                          <div
                            style={{
                              width: "80%",
                              display: "flex",
                            }}
                          >
                            <div
                              className="menu-list-continer-title"
                              style={{
                                width: "48%",
                              }}
                            >
                              <h6
                                style={{
                                  margin: 0,
                                }}
                              >
                                {element.AppTitle}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="menu-list-continer-price-and-delete-and-edit-icons"
                            style={{ width: "12%" }}
                          >
                            {element.AmountMain ? (
                              <h6
                                style={{
                                  margin: "0px 13px 0px 0px",
                                }}
                              >
                                £{element.AmountMain}
                              </h6>
                            ) : null}

                            <i
                              class="fa fa-pencil"
                              aria-hidden="true"
                              onClick={() => handleEditItemPrice(element)}
                            ></i>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          {/* <AddMenuItem
            // empty={emptyInput}
            // setEmptyInput={(i) => setEmptyInput(i)}
            button_title={lang.addSubmenu}
            buttonClick={(t, d, a) => handleOnclickButton(t, d, a)}
            result_successfull={result_successfull}
          /> */}
        </>
      ) : (
        <>
          <div className="menu_list_header_container">
            <div>
              {submenuList.length > 0 ? (
                <div style={{ marginBottom: "150px" }}>
                  {" "}
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {submenuList.map((item, index) => (
                            <Draggable
                              key={item.Id}
                              draggableId={item.Id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={item.id}
                                >
                                  {!item.IsDeleted ? (
                                    <MenuListItem
                                      field_number={2}
                                      item_title={item.AppTitle}
                                      item_price={item.AmountMain}
                                      field_1_title={item.AppTitle}
                                      field_2_title={`£${item.AmountMain}`}
                                      btn_number={1}
                                      bt_1_title={lang.edit}
                                      bt_1_click={() =>
                                        handleClickItem(item, 4)
                                      }
                                      show_delete={true}
                                      delete_click={() =>
                                        handeDeleteSubmenuItem(item)
                                      }
                                      show_edit_icon={true}
                                      selected={selected_submenu_item}
                                      item={item}
                                      number={7}
                                      click_edit_icon={() =>
                                        handleEditIconClick(item)
                                      }
                                      click_close_icon={() =>
                                        handleCloseIcon(item)
                                      }
                                      show_delete_box={() =>
                                        handleShowDeleteBox(item)
                                      }
                                      cancel_delete_box={() =>
                                        handleCancelDeleteBox(item)
                                      }
                                    />
                                  ) : null}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : (
                <h2 className="no-option-sm">{lang.noOption}</h2>
              )}
            </div>
          </div>
          <AddMenuItem
            number={3}
            button_title={lang.addItem}
            buttonClick={(t, d, a) => handleAddSubmenuItem(t, d, a)}
            buttonClick1={() => handleShowSelectItemPopup()}
            // empty={emptyInput}
            // setEmptyInput={(i) => setEmptyInput(i)}
            result_successfull={result_successfull}
          />
        </>
      )}
    </div>
  );
};

export default SubmenuItem;
