import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { visiblePostCodePopup } from "../../../../redux/GeneralSetting/Actions";
import useScrollbarSize from "react-scrollbar-size";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import {
  createAllowedPostCodeAction,
  updateAllowedPostCodeAction,
} from "../../../../redux/GeneralSetting/Actions";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import { border } from "@mui/system";
const PostCodePopup = () => {
  const postCodeRef = useRef();
  const deliveyChargeRef = useRef();
  const deliveyMinChargeRef = useRef();

  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_allowed_post_code } = useSelector(
    (state) => state.tabelAreaRedusers
  );

  const [postCode, setPostCode] = useState("");
  const [deliveyCharge, setDeliveyCharge] = useState("");
  const [deliveyMinCharge, setDeliveyMinCharge] = useState("");
  const handleCreateAllowed = () => {
    if (selected_allowed_post_code) {
      if (postCode == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        deliveyCharge != "" &&
        !validator.isFloat(String(deliveyCharge))
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_delivery_charge_format_is_incorrect}
            &nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        deliveyMinCharge != "" &&
        !validator.isFloat(String(deliveyMinCharge))
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_delivery_min_charge_format_is_incorrect}
            &nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateAllowedPostCodeAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_allowed_post_code.Id,
            postCode,
            deliveyCharge ? parseFloat(deliveyCharge) : 0,
            deliveyMinCharge ? parseFloat(deliveyMinCharge) : 0
          )
        );
      }
    } else {
      if (postCode == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        deliveyCharge != "" &&
        !validator.isFloat(String(deliveyCharge))
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_delivery_charge_format_is_incorrect}
            &nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        deliveyMinCharge != "" &&
        !validator.isFloat(String(deliveyMinCharge))
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_delivery_min_charge_format_is_incorrect}
            &nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          createAllowedPostCodeAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            postCode,
            deliveyCharge ? parseFloat(deliveyCharge) : 0,
            deliveyMinCharge ? parseFloat(deliveyMinCharge) : 0
          )
        );
      }
    }
  };

  const handlePostCodeKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      deliveyMinChargeRef.current.focus();
    }
  };
  const handleDelivetMinChargeKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      deliveyChargeRef.current.focus();
    }
  };
  const handleDelivetChargeKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      handleCreateAllowed();
    }
  };

  useEffect(() => {
    if (selected_allowed_post_code) {
      setPostCode(selected_allowed_post_code.Postcode);
      setDeliveyCharge(selected_allowed_post_code.DeliveryCharge);
      setDeliveyMinCharge(selected_allowed_post_code.DeliveryMinCharge);
    }
  }, [selected_allowed_post_code]);

  return (
    <div className="create_shop_popup_container">
      <div className="create_shop_popup_content_container post-code-content-container">
        <div className=" create-shop-close-div-post-code">
          <i
            onClick={() => dispatch(visiblePostCodePopup(false))}
            style={{ fontSize: 25 }}
            aria-hidden="true"
            className="fa fa-times post-code-close-icon"
          ></i>
          <p className="post-code-p">Add post code</p>
        </div>
        <div className="container-input-div">
          <div
            className="create_shop_inputs_container post-code-create_shop_inputs_container"
            style={{ height: "13%", margin: "25px auto " }}
          >
            <form>
              <h6 className="h6 post-code-h6">* {lang.post_code}</h6>
              <input
                type="text"
                value={postCode}
                onChange={(str) => setPostCode(str.target.value.toUpperCase())}
                autoFocus
                ref={postCodeRef}
                onKeyDown={handlePostCodeKeyDown}
              />
            </form>
          </div>
          <div
            className="create_shop_inputs_container"
            style={{ height: "13%", margin: " 45px auto" }}
          >
            <form>
              <h6 className="h6 post-code-h6">{lang.delivey_min_charge}</h6>
              <input
                type="text"
                value={deliveyMinCharge}
                onChange={(str) =>
                  setDeliveyMinCharge(
                    str.target.value.replace(/[^\d\.,£]+/g, "")
                  )
                }
                ref={deliveyMinChargeRef}
                onKeyDown={handleDelivetMinChargeKeyDown}
              />
            </form>
          </div>
          <div
            className="create_shop_inputs_container"
            style={{ height: "13%", margin: "25px auto " }}
          >
            <form>
              <h6 className="h6 post-code-h6">{lang.deviry_charge}</h6>
              <input
                type="text"
                value={deliveyCharge}
                onChange={(str) =>
                  setDeliveyCharge(str.target.value.replace(/[^\d\.,£]+/g, ""))
                }
                ref={deliveyChargeRef}
                onKeyDown={handleDelivetChargeKeyDown}
              />
            </form>
          </div>
        </div>
        <div className="post-code-popup-buttons-container">
          <button
            // disabled={selectedSubmenuIds.length > 0 ? false : true}
            onClick={handleCreateAllowed}
            id="popup_add"
            // onKeyDown={handleSaveKeyDown}
          >
            {lang.save}
          </button>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default PostCodePopup;
