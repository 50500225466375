import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import ListHeader from "../../../../components/ListHeader";
import MenuListItem from "../../../../components/MenuListItem";
import AddMenuItem from "../../../../components/AddMenuItem";
import {
  selectedTabelsAction,
  selectedAreaAction,
} from "../../../../redux/GeneralSetting/Actions";
import { changeSelectedButtonBorderColorOnFocus } from "../../../../redux/menu/Actions";
import toast, { Toaster } from "react-hot-toast";
import { getDeliveyChargeTypeAction } from "../../../../redux/settings/Action";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";
import useScrollbarSize from "react-scrollbar-size";
import {
  visibleCusomChargeAction,
  deleteDeliveryChargeAction,
  selectedDeliveryChargeType,
} from "../../../../redux/settings/Action";
import { setDefaultAction } from "../../../../redux/menu/Actions";
const CustomChargeList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { height, width } = useScrollbarSize();
  const { lang } = useSelector((state) => state.selectedLanguage);

  const {
    loading_appSettings,
    result_message,
    result_failed,
    result_successfull,
    deleivery_charge_type_list,
    selected_custom_charge,
  } = useSelector((state) => state.appSettingsRedusers);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const [customChargeList, setCustomChargeList] = useState([]);
  const handleEditCustom = (item) => {
    dispatch(visibleCusomChargeAction(true));
    dispatch(selectedDeliveryChargeType(item));
  };

  const handleCreateCusomCharge = () => {
    dispatch(visibleCusomChargeAction(true));
    dispatch(selectedDeliveryChargeType(null));
  };
  // const lastItem=tabel_area_list[tabel_area_list.length-1];
  const handleDeleteCustomCharge = (item) => {
    dispatch(
      deleteDeliveryChargeAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        item.Id
      )
    );
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...customChargeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      }
    }
    setCustomChargeList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...customChargeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setCustomChargeList(copy_list);
  };
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getDeliveyChargeTypeAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    if (deleivery_charge_type_list) {
      if (deleivery_charge_type_list.length > 0) {
        setCustomChargeList(deleivery_charge_type_list);
      } else {
        setCustomChargeList([]);
      }
    } else {
      setCustomChargeList([]);
    }
  }, [deleivery_charge_type_list]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
      id="countaining"
    >
      <div className="menu_list_header_container">
        {/* <ListHeader title={selected_area?selected_area.TableName:lang.tabels_list} show_back={false} /> */}

        {customChargeList.length > 0 ? (
          <div style={{ marginBottom: "57px" }}>
            {customChargeList.map((item, index) => (
              <div key={index}>
                {/* {!item.IsDeleted ? ( */}
                <MenuListItem
                  field_number={2}
                  field_1_title={item.Title}
                  field_2_title={` £${item.DeliveryCharge.toFixed(2)}`}
                  item_title={item.Title}
                  btn_number={1}
                  custom_delivery_charge={item.DeliveryCharge}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleEditCustom(item)}
                  show_delete={true}
                  delete_click={() => handleDeleteCustomCharge(item)}
                  backgroundColors={true}
                  items={item}
                  show_edit_icon={false}
                  selected={selected_custom_charge}
                  item={item}
                  number={0}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
                {/* // ) : null} */}
              </div>
            ))}
          </div>
        ) : (
          <div
            className="div_submenu_add_container"
            style={{ zIndex: 1 }}
            onClick={handleCreateCusomCharge}
          >
            <h4>{lang.noOption}</h4>
            <span style={{ width: 195 }}>
              <i class="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.custom_charge}</p>
            </span>
          </div>
        )}
      </div>
      {customChargeList.length > 0 ? (
        <div
          className="div_submenu_add_container_fixed"
          onClick={handleCreateCusomCharge}
          style={{
            width: 195,
            right:
              (window.innerWidth * 2) / 100 +
              width +
              marginNormalMenuHorizontal,
            bottom: "19px",
          }}
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
          <p>{lang.custom_charge}</p>
        </div>
      ) : null}
    </div>
  );
};

export default CustomChargeList;
