import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import FinancialSideBar from "../../components/FinancialSideBar";
import { setDefaultAction } from "../../redux/menu/Actions";
import Refund from "./components/Refund";
import Debt from "./components/Debt";
const Financial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );

  const [userAccesses, setUserAccesses] = useState([]);
  const [rolesid, setRolesId] = useState([]);
  const [userType, setUserType] = useState("");

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);
  // useEffect(() => {
  //   if (profile_list) {
  //     if (profile_list.length > 0) {
  //       setUserAccesses(profile_list);
  //     }
  //   }
  // }, [profile_list]);
  // useEffect(() => {
  //   let roles_id = [];
  //   let user_type = "";
  //   if (userAccesses) {
  //     if (userAccesses.length > 0) {
  //       for (let i = 0; i < userAccesses.length; i++) {
  //         roles_id.push(userAccesses[i].RoleId);
  //         user_type = userAccesses[i].UserType;
  //       }
  //       setRolesId(roles_id);
  //       setUserType(user_type);
  //     }
  //   }
  // }, [userAccesses]);
  //   useEffect(() => {
  //     if (result_successfull) {
  //       dispatch(setDefaultAction());
  //       toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
  //         position: "top-center",
  //         duration: 3000,
  //         style: { backgroundColor: "green", color: "white" },
  //         icon: (
  //           <i
  //             style={{ fontSize: 40 }}
  //             class="fa fa-check-circle"
  //             aria-hidden="true"
  //           ></i>
  //         ),
  //       });
  //     } else if (result_failed) {
  //       dispatch(setDefaultAction());
  //       if (result_message) {
  //         toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
  //           position: "top-center",
  //           duration: 3000,
  //           style: { backgroundColor: "red", color: "white" },
  //           icon: (
  //             <i
  //               style={{ fontSize: 40 }}
  //               class="fa fa-times-circle"
  //               aria-hidden="true"
  //             ></i>
  //           ),
  //         });
  //       }
  //     }
  //   }, [result_successfull, result_failed]);
  return (
    <div className="dashborard-index-countainer-div">
      <FinancialSideBar />

      <Routes>
        <Route path="refund" element={<Refund />} />
        <Route path="debt" element={<Debt />} />
      </Routes>
      <Toaster />
    </div>
  );
};

export default Financial;
