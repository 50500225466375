import * as actionType from "../ActionTypes";

export const getTabelAreaAction = (
  login_key,
  user_id,
  shop_id,
  parent_id,
  id
) => {
  return {
    type: actionType.GET_TABEL_AREA,
    payload: {
      login_key,
      user_id,
      shop_id,
      parent_id,
      id,
    },
  };
};

export const getTabelAreaSucccessfullACtion = (data) => {
  return {
    type: actionType.GET_TABEL_AREA_SUCCESSFULL,
    payload: data,
  };
};
export const getTabelAreaFailedAction = () => {
  return {
    type: actionType.GET_TABEL_AREA_FAILED,
  };
};
export const selectedTabelsAction = (data) => {
  return {
    type: actionType.SELECTED_TABEL,
    payload: data,
  };
};
export const showAddTablePopupAction = (data) => {
  return {
    type: actionType.SHOW_ADD_TABLE_POPUP,
    payload: data,
  };
};
export const createAreaAction = (
  login_key,
  user_id,
  shop_id,
  tabel_name,
  parent_id,
  position,
  percount,
  app_visible,
  kiosk_visible
) => {
  return {
    type: actionType.CREATE_AREA,
    payload: {
      login_key,
      user_id,
      shop_id,
      tabel_name,
      parent_id,
      position,
      percount,
      app_visible,
      kiosk_visible,
    },
  };
};
export const createAreaSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_AREA_SUCCESSFULL,
    payload: data,
  };
};

export const createAreaFailedAction = (data) => {
  return {
    type: actionType.CREATE_AREA_FAILED,
    payload: data,
  };
};

export const createTabelAction = (
  login_key,
  user_id,
  shop_id,
  tabel_name,
  parent_id,
  position,
  percount,
  app_visible,
  kiosk_visible
) => {
  return {
    type: actionType.CREATE_TABEL,
    payload: {
      login_key,
      user_id,
      shop_id,
      tabel_name,
      parent_id,
      position,
      percount,
      app_visible,
      kiosk_visible,
    },
  };
};
export const createTabelSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_TABEL_SUCCESSFULL,
    payload: data,
  };
};

export const createTabelFailedAction = (data) => {
  return {
    type: actionType.CREATE_TABEL_FAILED,
    payload: data,
  };
};
export const updateAreaAction = (
  login_key,
  user_id,
  shop_id,
  id,
  tabel_name,
  parent_id,
  position,
  percount,
  app_visible,
  kiosk_visible
) => {
  return {
    type: actionType.UPDATE_AREA,
    payload: {
      login_key,
      user_id,
      id,
      shop_id,
      tabel_name,
      parent_id,
      position,
      percount,
      app_visible,
      kiosk_visible,
    },
  };
};
export const updateAreaSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_AREA_SUCCESSFULL,
    payload: data,
  };
};

export const updateAreaFailedAction = (data) => {
  return {
    type: actionType.UPDATE_AREA_FAILED,
    payload: data,
  };
};
export const updateTabelAction = (
  login_key,
  user_id,
  shop_id,
  id,
  tabel_name,
  parent_id,
  position,
  percount,
  app_visible,
  kiosk_visible
) => {
  return {
    type: actionType.UPDATE_TABEL,
    payload: {
      login_key,
      user_id,
      id,
      shop_id,
      tabel_name,
      parent_id,
      position,
      percount,
      app_visible,
      kiosk_visible,
    },
  };
};
export const updateTabelSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_TABEL_SUCCESSFULL,
    payload: data,
  };
};

export const updateTabelFailedAction = (data) => {
  return {
    type: actionType.UPDATE_TABEL_FAILED,
    payload: data,
  };
};

export const selectedAreaAction = (data) => {
  return {
    type: actionType.SELECTTED_AREA,
    payload: data,
  };
};
export const selectedAreaEmptyAction = (data) => {
  return {
    type: actionType.SELECTTED_AREA_EMPTY,
    payload: data,
  };
};

export const visiblePostCodePopup = (data) => {
  return {
    type: actionType.VISIBLE_POST_CODE_POPUP,
    payload: data,
  };
};

export const allowedPostCodeListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.ALLOWE_POST_CODE_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const allowedPostCodeListSuccessfullAction = (data) => {
  return {
    type: actionType.ALLOWE_POST_CODE_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const allowedPostCodeListFailedAction = () => {
  return {
    type: actionType.ALLOWE_POST_CODE_LIST_FAILED,
  };
};
export const createAllowedPostCodeAction = (
  login_key,
  user_id,
  shop_id,
  post_code,
  delivery_charge,
  delivey_min_charge
) => {
  return {
    type: actionType.CREATE_ALLOWED_POST_CODE,
    payload: {
      login_key,
      user_id,
      shop_id,
      post_code,
      delivery_charge,
      delivey_min_charge,
    },
  };
};
export const createAllowedPostCodeSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_ALLOWED_POST_CODE_SUCCESSFULL,
    payload: data,
  };
};
export const createAllowedPostCodeFailedAction = (data) => {
  return {
    type: actionType.CREATE_ALLOWED_POST_CODE_FAILED,
    payload: data,
  };
};
export const updateAllowedPostCodeAction = (
  login_key,
  user_id,
  shop_id,
  id,
  post_code,
  delivery_charge,
  delivey_min_charge
) => {
  return {
    type: actionType.UPDATE_ALLOWED_POST_CODE,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      post_code,
      delivery_charge,
      delivey_min_charge,
    },
  };
};
export const updateAllowedPostCodeSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_ALLOWED_POST_CODE_SUCCESSFULL,
    payload: data,
  };
};
export const updateAllowedPostCodeFailedAction = (data) => {
  return {
    type: actionType.UPDATE_ALLOWED_POST_CODE_FAILED,
    payload: data,
  };
};
export const deleteAllowedPostCodeAction = (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return {
    type: actionType.DELETE_ALLOWED_POST_CODE,
    payload: { login_key, user_id, shop_id, id },
  };
};
export const deleyeAllowedPostCodeSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_ALLOWED_POST_CODE_SUCCESSFULL,
    payload: data,
  };
};
export const deleteAllowedPostCodeFailedAction = (data) => {
  return {
    type: actionType.DELETE_ALLOWED_POST_CODE_FAILED,
    payload: data,
  };
};

export const selectedAllowedPostCode = (data) => {
  return {
    type: actionType.SELECTED_ALLOWED_POST_CODE,
    payload: data,
  };
};
export const vatListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.VAT_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const vatListSuccessfullAction = (data) => {
  return {
    type: actionType.VAT_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const vatListFailedAction = () => {
  return {
    type: actionType.VAT_LIST_FAILED,
  };
};
export const createVatAction = (
  login_key,
  user_id,
  shop_id,
  vat_name,
  vat_value
) => {
  return {
    type: actionType.CREATE_VAT,
    payload: {
      login_key,
      user_id,
      shop_id,
      vat_name,
      vat_value,
    },
  };
};
export const createVatSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_VAT_SUCCESSFULL,
    payload: data,
  };
};
export const createVatFailedAction = (data) => {
  return {
    type: actionType.CREATE_VAT_FAILED,
    payload: data,
  };
};
export const updateVatAction = (
  login_key,
  user_id,
  shop_id,
  id,
  vat_name,
  vat_value
) => {
  return {
    type: actionType.UPDATE_VAT,
    payload: { login_key, user_id, shop_id, id, vat_name, vat_value },
  };
};
export const updateVatSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_VAT_SUCCESSFULL,
    payload: data,
  };
};
export const updateVatFailedAction = (data) => {
  return {
    type: actionType.UPDATE_VAT_FAILED,
    payload: data,
  };
};

export const deleteVatAction = (login_key, user_id, shop_id, id) => {
  return {
    type: actionType.DELETE_VAT,
    payload: { login_key, user_id, shop_id, id },
  };
};
export const deleteVatSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_VAT_SUCCESSFULL,
    payload: data,
  };
};
export const deleteVatFailedAction = (data) => {
  return {
    type: actionType.DELETE_VAT_FAILED,
    payload: data,
  };
};
export const visibleVatPopupAction = (data) => {
  return {
    type: actionType.VISIBLE_VAT_POPUP,
    payload: data,
  };
};
export const selectedVatAction = (data) => {
  return {
    type: actionType.SELECTED_VAT,
    payload: data,
  };
};
export const getHalfAndHalfAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_HALF_AND_HALF,
    payload: {
      login_key,
      user_id,
      shop_id,
    },
  };
};
export const getHalfAndHalfSuccessfullAction = (data) => {
  return {
    type: actionType.GET_HALF_AND_HALF_SUCCESSFULL,
    payload: data,
  };
};
export const getHalfAndHalfFailedAction = () => {
  return {
    type: actionType.GET_HALF_AND_HALF_FAILED,
  };
};
export const updateHalfAndHalfAction = (
  login_key,
  user_id,
  shop_id,
  half_id,
  max_price,
  by_percenet,
  equal,
  first_half,
  second_half
) => {
  return {
    type: actionType.UPDATE_HALF_AND_HALF,
    payload: {
      login_key,
      user_id,
      shop_id,
      half_id,
      max_price,
      by_percenet,
      equal,
      first_half,
      second_half,
    },
  };
};
export const updateHalfAndHalfSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_HALF_AND_HALF_SUCCESSFULL,
    payload: data,
  };
};
export const updateHalfAndHalfFailedAction = (data) => {
  return {
    type: actionType.UPDATE_HALF_AND_HALF_FAILED,
    payload: data,
  };
};

//CARIIER BAG
export const getCarrierBagListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GENERAL_SETTING_GET_CARRIER_BAG_LIST,
    payload: {
      login_key,
      user_id,
      shop_id,
    },
  };
};
export const getCarrierBagListSuccessfullAction = (data) => {
  return {
    type: actionType.GENERAL_SETTING_GET_CARRIER_BAG_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getCarrierBagListFailedAction = (data) => {
  return {
    type: actionType.GENERAL_SETTING_GET_CARRIER_BAG_LIST_FAILED,
    payload: data,
  };
};
export const updateCarrierBagAction = (
  login_key,
  user_id,
  shop_id,
  id,
  cariier_bag_amount,
  cariier_bag_title,
  active_web_cariier_bag,
  active_app_cariier_bag,
  active_kiosk_cariier_bag
) => {
  return {
    type: actionType.GENERAL_SETTING_UPDATE_CARRIER_BAG,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      cariier_bag_amount,
      cariier_bag_title,
      active_web_cariier_bag,
      active_app_cariier_bag,
      active_kiosk_cariier_bag,
    },
  };
};
export const updateCarrierBagSuccessfullAction = (data) => {
  return {
    type: actionType.GENERAL_SETTING_UPDATE_CARRIER_BAG_SUCCESSFULL,
    payload: data,
  };
};
export const updateCarrierBagFailedAction = (data) => {
  return {
    type: actionType.GENERAL_SETTING_UPDATE_CARRIER_BAG_FAILED,
    payload: data,
  };
};

//SHORT CUT BUTTON
export const getShortCutButtonListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_SHORT_CUT_BUTTON_LIST,
    payload: { login_key, user_id, shop_id },
  };
};

export const getShortCutButtonListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_SHORT_CUT_BUTTON_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getShortCutButtonListFailedAction = (data) => {
  return {
    type: actionType.GET_SHORT_CUT_BUTTON_LIST_FAILED,
    payload: data,
  };
};

export const updateShortCutButtonAction = (
  login_key,
  user_id,
  shop_id,
  id,
  cat_name_a,
  cat_id_a,
  cat_active_a,
  cat_name_b,
  cat_id_b,
  cat_active_b,
  submenu_name_a,
  submenu_id_a,
  submenu_active_a
) => {
  return {
    type: actionType.UPDATE_SHORT_CUT_BUTTON,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      cat_name_a,
      cat_id_a,
      cat_active_a,
      cat_name_b,
      cat_id_b,
      cat_active_b,
      submenu_name_a,
      submenu_id_a,
      submenu_active_a,
    },
  };
};
export const updateShortCutButtonSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SHORT_CUT_BUTTON_SUCCESSFULL,
    payload: data,
  };
};
export const updateShortCutButtonFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SHORT_CUT_BUTTON_FAILED,
    payload: data,
  };
};

//ABOUT US
export const getAboutUsListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_ABOUT_US_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getAboutUsListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_ABOUT_US_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getAboutUsListFailedAction = (data) => {
  return {
    type: actionType.GET_ABOUT_US_LIST_FAILED,
    payload: data,
  };
};
export const updateAboutUsAction = (login_key, user_id, shop_id, message) => {
  return {
    type: actionType.UPDATE_ABOUT_US,
    payload: { login_key, user_id, shop_id, message },
  };
};
export const updateAboutUsSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_ABOUT_US_SUCCESSFULL,
    payload: data,
  };
};
export const updateAboutUsFailedAction = (data) => {
  return {
    type: actionType.UPDATE_ABOUT_US_FAILED,
    payload: data,
  };
};

//DELIVERY CHARGE BY AREA
export const getByAreaListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_BY_AREA_LIST,
    payload: {
      login_key,
      user_id,
      shop_id,
    },
  };
};

export const getByAreaListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_BY_AREA_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getByAreaListFailedAction = (data) => {
  return {
    type: actionType.GET_BY_AREA_LIST_FAILED,
    payload: data,
  };
};
//CREATE BY AREA
export const createByAreaAction = (
  login_key,
  user_id,
  shop_id,
  area_name,
  delivery_charge,
  post_code
) => {
  return {
    type: actionType.CREATE_BY_AREA,
    payload: {
      login_key,
      user_id,
      shop_id,
      area_name,
      delivery_charge,
      post_code,
    },
  };
};

export const createByAreaSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_BY_AREA_SUCCESSFULL,
    payload: data,
  };
};

export const createByAreaFailedAction = (data) => {
  return {
    type: actionType.CREATE_BY_AREA_FAILED,
    payload: data,
  };
};

//UPDATE BY AREA
export const updateByAreaAction = (
  login_key,
  user_id,
  shop_id,
  id,
  area_name,
  delivery_charge,
  post_code
) => {
  return {
    type: actionType.UPDATE_BY_AREA,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      area_name,
      delivery_charge,
      post_code,
    },
  };
};

export const updateByAreaSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_BY_AREA_SUCCESSFULL,
    payload: data,
  };
};

export const updateByAreaFailedAction = (data) => {
  return {
    type: actionType.UPDATE_BY_AREA_FAILED,
    payload: data,
  };
};

//DELETE BY AREA
export const deleteByAreaAction = (login_key, user_id, shop_id, id) => {
  return {
    type: actionType.DELETE_BY_AREA,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
    },
  };
};

export const deleteByAreaSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_BY_AREA_SUCCESSFULL,
    payload: data,
  };
};

export const deleteByAreaFailedAction = (data) => {
  return {
    type: actionType.DELETE_BY_AREA_FAILED,
    payload: data,
  };
};

//SELECTED DELIVERY BY AREA
export const selectedDeliveryByAreaAction = (data) => {
  return {
    type: actionType.SELECTED_DELIVERY_BY_AREA,
    payload: data,
  };
};
export const showCreateDeliveryByAreaPopupAction = (data) => {
  return {
    type: actionType.SHOW_CREATE_DELIVERY_BY_AREA_POPUP,
    payload: data,
  };
};

// DELIVERY AREA MAP
export const getDeliveryAreaMapListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionType.GET_DELIVERY_AREA_MAP_LIST,
    payload: { login_key, user_id, shop_id },
  };
};

export const getDeliveryAreaMapListSuccessfullAction = (data) => {
  return {
    type: actionType.GET_DELIVERY_AREA_MAP_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getDeliveryAreaMapListFailedAction = (data) => {
  return {
    type: actionType.GET_DELIVERY_AREA_MAP_LIST_FAILED,
    payload: data,
  };
};

//CREATE DELIVERY AREA MAP
export const createDeliveryAreaMapAction = (
  login_key,
  user_id,
  shop_id,
  color,
  name,
  minAmount,
  delivery_fee,
  polygon,
  is_active
) => {
  return {
    type: actionType.CREATE_DELIVERY_AREA_MAP,
    payload: {
      login_key,
      user_id,
      shop_id,
      color,
      name,
      minAmount,
      delivery_fee,
      polygon,
      is_active,
    },
  };
};
export const createDeliveryAreaMapSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_DELIVERY_AREA_MAP_SUCCESSFULL,
    payload: data,
  };
};
export const createDeliveryAreaMapFailedAction = (data) => {
  return {
    type: actionType.CREATE_DELIVERY_AREA_MAP_FAILED,
    payload: data,
  };
};

//UPDATE DELIVERY AREA MAP
export const updateDeliveryAreaMapAction = (
  login_key,
  user_id,
  shop_id,
  id,
  color,
  name,
  minAmount,
  delivery_fee,
  polygon,
  is_active
) => {
  return {
    type: actionType.UPDATE_DELIVERY_AREA_MAP,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      color,
      name,
      minAmount,
      delivery_fee,
      polygon,
      is_active,
    },
  };
};
export const updateDeliveryAreaMapSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_DELIVERY_AREA_MAP_SUCCESSFULL,
    payload: data,
  };
};
export const updateDeliveryAreaMapFailedAction = (data) => {
  return {
    type: actionType.UPDATE_DELIVERY_AREA_MAP_FAILED,
    payload: data,
  };
};

//DELETE DELIVERY AREA MAP
export const deleteDeliveryAreaMapAction = (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return {
    type: actionType.DELETE_DELIVERY_AREA_MAP,
    payload: { login_key, user_id, shop_id, id },
  };
};
export const deleteDeliveryAreaMapSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_DELIVERY_AREA_MAP_SUCCESSFULL,
    payload: data,
  };
};
export const deleteDeliveryAreaMapFailedAction = (data) => {
  return {
    type: actionType.DELETE_DELIVERY_AREA_MAP_FAILED,
    payload: data,
  };
};

// DEFAULT MAP CENTER
export const defaultMapCenterAction = (data) => {
  return {
    type: actionType.DEFAULT_MAP_CENTER,
    payload: data,
  };
};

// POST CODE FOF
export const getAllowedPostCodeFOFListAction = (
  login_key,
  user_id,
  shop_id
) => {
  return {
    type: actionType.GET_ALLOWED_POST_CODE_FOF_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getAllowedPostCodeFOFSuccessfullListAction = (data) => {
  return {
    type: actionType.GET_ALLOWED_POST_CODE_FOF_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getAllowedPostCodeFOFFailedListAction = (data) => {
  return {
    type: actionType.GET_ALLOWED_POST_CODE_FOF_LIST_FAILED,
    payload: data,
  };
};

export const createAllowedPostCodeFOFAction = (
  login_key,
  user_id,
  shop_id,
  post_code,
  delivery_charge,
  delivey_min_charge
) => {
  return {
    type: actionType.CREATE_ALLOWED_POST_CODE_FOF,
    payload: {
      login_key,
      user_id,
      shop_id,
      post_code,
      delivery_charge,
      delivey_min_charge,
    },
  };
};

export const createAllowedPostCodeFOFSucceesfullAction = (data) => {
  return {
    type: actionType.CREATE_ALLOWED_POST_CODE_FOF_SUCCESSFULL,
    payload: data,
  };
};

export const createAllowedPostCodeFOFFailedAction = (data) => {
  return {
    type: actionType.CREATE_ALLOWED_POST_CODE_FOF_FAILED,
    payload: data,
  };
};

export const updateAllowedPostCodeFOFAction = (
  login_key,
  user_id,
  shop_id,
  id,
  post_code,
  delivery_charge,
  delivey_min_charge
) => {
  return {
    type: actionType.UPDATE_ALLOWED_POST_CODE_FOF,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
      post_code,
      delivery_charge,
      delivey_min_charge,
    },
  };
};

export const updateAllowedPostCodeFOFSucceesfullAction = (data) => {
  return {
    type: actionType.UPDATE_ALLOWED_POST_CODE_FOF_SUCCESSFULL,
    payload: data,
  };
};

export const updateAllowedPostCodeFOFFailedAction = (data) => {
  return {
    type: actionType.UPDATE_ALLOWED_POST_CODE_FOF_FAILED,
    payload: data,
  };
};

export const deleteAllowedPostCodeFOFAction = (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return {
    type: actionType.DELETE_ALLOWED_POST_CODE_FOF,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
    },
  };
};

export const deleteAllowedPostCodeFOFSucceesfullAction = (data) => {
  return {
    type: actionType.DELETE_ALLOWED_POST_CODE_FOF_SUCCESSFULL,
    payload: data,
  };
};

export const deleteAllowedPostCodeFOFFailedAction = (data) => {
  return {
    type: actionType.DELETE_ALLOWED_POST_CODE_FOF_FAILED,
    payload: data,
  };
};

export const selectedAllowedPostCodeFOFAction = (data) => {
  return {
    type: actionType.SELECTED_ALLOWED_POST_CODE_FOF,
    payload: data,
  };
};
