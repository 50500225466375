import React, { useState, useEffect, lazy } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-bootstrap";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import store from "store2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Navigate, useNavigate } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import orderList from "./List";
import { Status } from "./List";
import { Stores } from "./List";
import { Channels } from "./List";
import OrderListItem from "../../components/OrderListItem ";
import ListHeader from "../../components/ListHeader";
import { main_nav_button_color, white } from "../../constants/Colors";
import { primaryColor, main_nav_back_color } from "../../constants/Colors";
import HeaderList from "../../components/HeaderList";
import {
  ordersListAction,
  showOrderListPopupAction,
  selectedOrderListAction,
  backToOrderAction,
} from "../../redux/Orders/Actions";
import { mobile_order_status } from "../../constants/OrderStatus";
import Loader from "../../components/Loader";
import moment from "moment";
import { setDefaultAction } from "../../redux/menu/Actions";
const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { orders_list, loading_orders, back_to_order } = useSelector(
    (state) => state.ordersReduser
  );
  const { device_type, device_type_id, order_status, order_status_id } =
    useSelector((state) => state.categoryList);
  const {
    selected_financial_order,
    result_message,
    result_successfull,
    result_failed,
  } = useSelector((state) => state.financialReduser);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [orderLists, setOrderLists] = useState([]);
  const [orderLists1, setOrderLists1] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orderStuss, setOrderStatuss] = useState([]);
  const [statusAll, setStatusAll] = useState([]);
  const [channelAll, setChannelAll] = useState([]);
  const [channelValues, setChannelValues] = useState([]);
  const [statusValues, setStatusValues] = useState([]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  // const handleClickedSelectedStatus = (list, item) => {

  //   setSelectedStatus(list);
  //   const List = [];
  //   const copy_list = [...orders_list];

  //   if (item.action == "select-option") {
  //     if (list.length == 1) {
  //       for (let i = 0; i < Status.length; i++) {
  //         let myIndex = Status.indexOf(list[0]);

  //         for (let i = 0; i < copy_list.length; i++) {
  //           if (myIndex + 1 == copy_list[i].status_code) {
  //             List.push(copy_list[i]);
  //           }
  //         }
  //       }
  //       setOrderLists(List);
  //     } else if (list.length == 2) {
  //       for (let i = 0; i < Status.length; i++) {
  //         let myIndex = Status.indexOf(list[0]);
  //         let index = Status.indexOf(list[1]);
  //         for (let i = 0; i < copy_list.length; i++) {
  //           if (
  //             index + 1 == copy_list[i].status_code ||
  //             myIndex + 1 == copy_list[i].status_code
  //           ) {
  //             List.push(copy_list[i]);
  //           }
  //         }
  //       }
  //       setOrderLists(List);
  //     } else if (list.length == 3) {
  //       for (let i = 0; i < Status.length; i++) {
  //         let myIndex = Status.indexOf(list[0]);
  //         let index = Status.indexOf(list[1]);
  //         let status_index = Status.indexOf(list[2]);
  //         for (let i = 0; i < copy_list.length; i++) {
  //           if (
  //             index + 1 == copy_list[i].status_code ||
  //             myIndex + 1 == copy_list[i].status_code ||
  //             status_index + 1 == copy_list[i].status_code
  //           ) {
  //             List.push(copy_list[i]);
  //           }
  //         }
  //       }
  //       setOrderLists(List);
  //     } else {
  //       setOrderLists(orderList_status);
  //     }
  //   } else if (item.action == "remove-value") {
  //     if (list.length == 0) {
  //       setOrderLists(orderList_status);
  //     } else if (list.length == 1) {
  //       for (let i = 0; i < Status.length; i++) {
  //         let myIndex = Status.indexOf(list[0]);
  //         for (let i = 0; i < copy_list.length; i++) {
  //           if (myIndex + 1 == copy_list[i].status_code) {
  //             List.push(copy_list[i]);
  //           }
  //         }
  //       }
  //       setOrderLists(List);
  //     } else if (list.length == 2) {
  //       for (let i = 0; i < Status.length; i++) {
  //         let myIndex = Status.indexOf(list[0]);
  //         let index = Status.indexOf(list[1]);
  //         for (let i = 0; i < copy_list.length; i++) {
  //           if (
  //             index + 1 == copy_list[i].status_code ||
  //             myIndex + 1 == copy_list[i].status_code
  //           ) {
  //             List.push(copy_list[i]);
  //           }
  //         }
  //       }
  //       setOrderLists(List);
  //     } else {
  //       for (let i = 0; i < Status.length; i++) {
  //         let myIndex = Status.indexOf(list[0]);
  //         let index = Status.indexOf(list[1]);
  //         let status_index = Status.indexOf(list[2]);
  //         for (let i = 0; i < copy_list.length; i++) {
  //           if (
  //             index + 1 == copy_list[i].status_code ||
  //             myIndex + 1 == copy_list[i].status_code ||
  //             status_index + 1 == copy_list[i].status_code
  //           ) {
  //             List.push(copy_list[i]);
  //           }
  //         }
  //       }
  //       setOrderLists(List);
  //     }
  //   }
  // };
  // const handleFillteredWithStatus = (list, item) => {
  //   setSelectedStatus(list);
  //   let arrey = [];
  //   let values = [];
  //   let my_value = [];
  //   const newStatus = [...Status];
  //   const newOrderList = [...orders_list];
  //   const fillteredOrderListWithChannel_copy = [
  //     ...fillteredOrderListWithChannel,
  //   ];
  //   if (item.action == "select-option") {
  //     if (list.length > 0) {
  //       list.forEach((element, index2) => {
  //         values.push(element.value);
  //       });
  //       if (!values.includes(1) || item.option.value != 1) {
  //         const filltered = list.filter((option) => option.value != 1);
  //         for (let index3 = 0; index3 < filltered.length; index3++) {
  //           my_value.push(filltered[index3].value);
  //         }
  //         if (fillteredOrderListWithChannel_copy.length > 0) {
  //           for (
  //             let index1 = 0;
  //             index1 < fillteredOrderListWithChannel_copy.length;
  //             index1++
  //           ) {
  //             if (values.length > 0) {
  //               const filltered = list.filter((option) => option.value != 1);
  //               for (let ind = 0; ind < values.length; ind++) {
  //                 if (
  //                   values[ind] ==
  //                   fillteredOrderListWithChannel_copy[index1].OrderStatus
  //                 ) {
  //                   arrey.push(fillteredOrderListWithChannel_copy[index1]);
  //                   setSelectedStatus(filltered);
  //                 }
  //               }
  //             }
  //           }
  //         } else if (newOrderList.length > 0) {
  //           for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //             if (values.length > 0) {
  //               for (let ind = 0; ind < values.length; ind++) {
  //                 if (values[ind] == newOrderList[index1].OrderStatus) {
  //                   arrey.push(newOrderList[index1]);
  //                   setSelectedStatus(filltered);
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         setOrderLists(arrey);
  //         setStatusValues(my_value);
  //       } else if (values.includes(1) || item.option.value == 1) {
  //         const filtered = list.filter((element) => element.value == 1);
  //         for (let index3 = 0; index3 < filtered.length; index3++) {
  //           my_value.push(filtered[index3].value);
  //         }
  //         if (newOrderList.length > 0) {
  //           for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //             if (channelValues.length > 0) {

  //               for (let ind = 0; ind < channelValues.length; ind++) {
  //                 if (channelValues[ind] == newOrderList[index1].DeviceType) {
  //                   arrey.push(newOrderList[index1]);
  //                   setSelectedStatus({ value: 1, label: "All" });
  //                 }
  //               }
  //               setOrderLists(arrey);
  //             } else {
  //               setSelectedStatus({ value: 1, label: "All" });
  //               setOrderLists(orders_list);
  //             }
  //           }
  //         }
  //         setStatusValues(my_value);
  //       }
  //     } else {
  //       setOrderLists(orders_list);
  //     }
  //     setFillteredOrderListWithStatus(arrey);
  //   } else if (item.action == "remove-value") {
  //     if (list.length > 0) {
  //       list.forEach((element, index2) => {
  //         values.push(element.value);
  //       });
  //       if (fillteredOrderListWithChannel_copy.length > 0) {
  //         for (
  //           let index1 = 0;
  //           index1 < fillteredOrderListWithChannel_copy.length;
  //           index1++
  //         ) {
  //           if (values.length > 0) {
  //             for (let ind = 0; ind < values.length; ind++) {
  //               if (
  //                 values[ind] ==
  //                 fillteredOrderListWithChannel_copy[index1].OrderStatus
  //               ) {
  //                 arrey.push(fillteredOrderListWithChannel_copy[index1]);
  //               }
  //             }
  //           }
  //         }
  //       } else if (newOrderList.length > 0) {
  //         for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //           if (values.length > 0) {
  //             for (let ind = 0; ind < values.length; ind++) {
  //               if (values[ind] == newOrderList[index1].OrderStatus) {
  //                 arrey.push(newOrderList[index1]);
  //               }
  //             }
  //           }
  //         }
  //       }
  //       setFillteredOrderListWithStatus(arrey);
  //       setOrderLists(arrey);
  //     } else {
  //       if (newOrderList.length > 0) {
  //         for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //           if (channelValues.length > 0) {
  //             for (let ind = 0; ind < channelValues.length; ind++) {
  //               if (!channelValues.includes(7) || channelValues[ind] != 7) {
  //                 if (channelValues[ind] == newOrderList[index1].DeviceType) {
  //                   arrey.push(newOrderList[index1]);
  //                 }
  //                 setOrderLists(arrey);
  //               } else {
  //                 setOrderLists(orders_list);
  //               }
  //             }
  //           } else {
  //             setOrderLists(orders_list);
  //           }
  //         }
  //       }

  //       setFillteredOrderListWithStatus([]);
  //     }
  //     setStatusValues(values);
  //   } else if (item.action == "clear") {
  //     if (newOrderList.length > 0) {
  //       for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //         if (channelValues.length > 0) {
  //           for (let ind = 0; ind < channelValues.length; ind++) {
  //             if (!channelValues.includes(7) || channelValues[ind] != 7) {
  //               if (channelValues[ind] == newOrderList[index1].DeviceType) {
  //                 arrey.push(newOrderList[index1]);
  //               }
  //               setOrderLists(arrey);
  //             } else {
  //               setOrderLists(orders_list);
  //             }
  //           }
  //         } else {
  //           setOrderLists(orders_list);
  //         }
  //       }
  //     }
  //     setStatusValues([]);
  //     setFillteredOrderListWithStatus([]);
  //   }
  // };
  // const handleFillteredWithChannels = (list, item) => {
  //   let arrey = [];
  //   let values = [];
  //   let my_value = [];
  //   setSelectedChannel(list);
  //   const newOrderList = [...orders_list];
  //   const fillteredOrderListWithStatus_copy = [...fillteredOrderListWithStatus];
  //   if (item.action == "select-option") {
  //     if (list.length > 0) {
  //       list.forEach((element, index2) => {
  //         values.push(element.value);
  //       });
  //       if (!values.includes(7) || item.option.value != 7) {
  //         const filltered = list.filter((option) => option.value != 7);
  //         for (let index3 = 0; index3 < filltered.length; index3++) {
  //           my_value.push(filltered[index3].value);
  //         }
  //         if (fillteredOrderListWithStatus_copy.length > 0) {
  //           for (
  //             let index2 = 0;
  //             index2 < fillteredOrderListWithStatus_copy.length;
  //             index2++
  //           ) {
  //             if (values.length > 0) {
  //               for (let ind = 0; ind < values.length; ind++) {
  //                 if (
  //                   values[ind] ==
  //                   fillteredOrderListWithStatus_copy[index2].DeviceType
  //                 ) {
  //                   arrey.push(fillteredOrderListWithStatus_copy[index2]);
  //                   setSelectedChannel(filltered);
  //                 }
  //               }
  //             }
  //           }
  //         } else if (newOrderList.length > 0) {
  //           for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //             if (values.length > 0) {
  //               for (let ind = 0; ind < values.length; ind++) {
  //                 if (values[ind] == newOrderList[index1].DeviceType) {
  //                   arrey.push(newOrderList[index1]);
  //                   setSelectedChannel(filltered);
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         setOrderLists(arrey);
  //       } else {
  //         const filltered = list.filter((element) => element.value == 7);
  //         for (let i = 0; i < filltered.length; i++) {
  //           my_value.push(filltered[i].value);
  //         }
  //         if (statusValues.length > 0) {
  //           for (let index = 0; index < statusValues.length; index++) {
  //             if (statusValues.includes(1) || statusValues[index].value == 1) {
  //               setOrderLists(orders_list);
  //               setSelectedChannel({ value: 7, label: "All" });
  //             } else {
  //               if (newOrderList.length > 0) {
  //                 for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //                   if (
  //                     statusValues[index] == newOrderList[index1].OrderStatus
  //                   ) {
  //                     arrey.push(newOrderList[index1]);
  //                   }
  //                   setOrderLists(arrey);
  //                 }
  //               }
  //               setSelectedChannel({ value: 7, label: "All" });
  //             }
  //           }
  //         } else {
  //           setOrderLists(orders_list);
  //           setSelectedChannel({ value: 7, label: "All" });
  //         }
  //       }
  //     } else {
  //       setOrderLists(orders_list);
  //     }
  //     setChannelValues(my_value);
  //     setFillteredOrderListWithChannel(arrey);
  //   } else if (item.action == "remove-value") {
  //     if (list.length > 0) {
  //       list.forEach((element, index2) => {
  //         values.push(element.value);
  //       });
  //       if (fillteredOrderListWithStatus_copy.length > 0) {
  //         for (
  //           let index1 = 0;
  //           index1 < fillteredOrderListWithStatus_copy.length;
  //           index1++
  //         ) {
  //           if (values.length > 0) {
  //             for (let ind = 0; ind < values.length; ind++) {
  //               if (
  //                 values[ind] ==
  //                 fillteredOrderListWithStatus_copy[index1].DeviceType
  //               ) {
  //                 arrey.push(fillteredOrderListWithStatus_copy[index1]);
  //               }
  //             }
  //           }
  //         }
  //       } else if (newOrderList.length > 0) {
  //         for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //           if (values.length > 0) {
  //             for (let ind = 0; ind < values.length; ind++) {
  //               if (values[ind] == newOrderList[index1].DeviceType) {
  //                 arrey.push(newOrderList[index1]);
  //               }
  //             }
  //           }
  //         }
  //       }
  //       setFillteredOrderListWithChannel(arrey);
  //       setOrderLists(arrey);
  //     } else {
  //       if (newOrderList.length > 0) {
  //         for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //           if (statusValues.length > 0) {
  //             for (let ind = 0; ind < statusValues.length; ind++) {
  //               if (!statusValues.includes(1) || statusValues[ind] != 1) {
  //                 if (statusValues[ind] == newOrderList[index1].OrderStatus) {
  //                   arrey.push(newOrderList[index1]);
  //                 }
  //                 setOrderLists(arrey);
  //               } else {
  //                 setOrderLists(orders_list);
  //               }
  //             }
  //           } else {
  //             setOrderLists(orders_list);
  //           }
  //         }
  //       }
  //       setFillteredOrderListWithChannel([]);
  //     }
  //     setChannelValues(values);
  //   } else if (item.action == "clear") {
  //     if (newOrderList.length > 0) {
  //       for (let index1 = 0; index1 < newOrderList.length; index1++) {
  //         if (statusValues.length > 0) {
  //           for (let ind = 0; ind < statusValues.length; ind++) {
  //             if (!statusValues.includes(1) || statusValues[ind] != 1) {
  //               if (statusValues[ind] == newOrderList[index1].OrderStatus) {
  //                 arrey.push(newOrderList[index1]);
  //               }
  //               setOrderLists(arrey);
  //             } else {
  //               setOrderLists(orders_list);
  //             }
  //           }
  //         } else {
  //           setOrderLists(orders_list);
  //         }
  //       }
  //     }
  //     setChannelValues([]);
  //     setFillteredOrderListWithChannel([]);
  //   }
  // };

  const handleFilterByStatus = (list, item) => {
    let arrey = [];
    let values = [];
    let all_value = [];
    const newOrderList = [...orders_list];
    let statusFiltered = [];
    setSelectedStatus(list);

    const fillteredList = list.filter((element) => element.value != 1);
    const fillteredList1 = list.filter((element) => element.value == 1);
    for (let index7 = 0; index7 < fillteredList1.length; index7++) {
      all_value.push(fillteredList1[index7].value);
    }
    for (let index8 = 0; index8 < fillteredList.length; index8++) {
      values.push(fillteredList[index8].value);
    }
    setStatusValues(values);
    setStatusAll(all_value);
    if (item.action == "select-option") {
      if (values.length > 0) {
        if (item.option.value == 1) {
          setSelectedStatus({ value: 1, label: "All " });
          if (!channelValues.length > 0 || channelAll[0] == 7) {
            setOrderLists(orders_list);
          } else if (channelValues.length > 0) {
            for (let index2 = 0; index2 < newOrderList.length; index2++) {
              for (let index3 = 0; index3 < channelValues.length; index3++) {
                if (channelValues[index3] == newOrderList[index2].DeviceType) {
                  arrey.push(newOrderList[index2]);
                }
              }
              setOrderLists(arrey);
            }
          }
          setStatusValues([]);
        } else {
          setStatusAll([]);
          const filter = list.filter((element) => element.value != 1);
          setSelectedStatus(filter);
          if (newOrderList.length > 0) {
            for (let index0 = 0; index0 < newOrderList.length; index0++) {
              for (let i = 0; i < values.length; i++) {
                if (values[i] == newOrderList[index0].OrderStatus) {
                  statusFiltered.push(newOrderList[index0]);
                  // arrey.push(newOrderList[index0]);
                }
              }
            }
            if (!channelValues.length > 0 || channelAll[0] == 7) {
              setOrderLists(statusFiltered);
            } else if (channelValues.length > 0) {
              for (let index2 = 0; index2 < channelValues.length; index2++) {
                for (let index3 = 0; index3 < statusFiltered.length; index3++) {
                  if (
                    channelValues[index2] == statusFiltered[index3].DeviceType
                  ) {
                    arrey.push(statusFiltered[index3]);
                  }
                }
              }
              setOrderLists(arrey);
            }
          }
        }
      } else {
        if (!channelValues.length > 0 || channelAll[0] == 7) {
          setOrderLists(orders_list);
        } else if (channelValues.length > 0) {
          for (let index = 0; index < newOrderList.length; index++) {
            for (let index1 = 0; index1 < channelValues.length; index1++) {
              if (channelValues[index1] == newOrderList[index].DeviceType) {
                arrey.push(newOrderList[index]);
              }
            }
          }
          setOrderLists(arrey);
        }
        statusFiltered.push([]);
      }
    } else if (item.action == "remove-value") {
      if (list.length > 0) {
        if (newOrderList.length > 0) {
          for (let index0 = 0; index0 < newOrderList.length; index0++) {
            for (let i = 0; i < values.length; i++) {
              if (values[i] == newOrderList[index0].OrderStatus) {
                statusFiltered.push(newOrderList[index0]);
                // arrey.push(newOrderList[index0]);
              }
            }
          }
          if (!channelValues.length > 0 || channelAll[0] == 7) {
            setOrderLists(statusFiltered);
          } else if (channelValues.length > 0) {
            for (let index2 = 0; index2 < channelValues.length; index2++) {
              for (let index3 = 0; index3 < statusFiltered.length; index3++) {
                if (
                  channelValues[index2] == statusFiltered[index3].DeviceType
                ) {
                  arrey.push(statusFiltered[index3]);
                  setOrderLists(arrey);
                }
              }
            }
          }
        }
      } else {
        if (!channelValues.length > 0 || channelAll[0] == 7) {
          setOrderLists(orders_list);
        } else if (channelValues.length > 0) {
          for (let index = 0; index < newOrderList.length; index++) {
            for (let index1 = 0; index1 < channelValues.length; index1++) {
              if (channelValues[index1] == newOrderList[index].DeviceType) {
                arrey.push(newOrderList[index]);
              }
            }
          }
          setOrderLists(arrey);
        }
        statusFiltered.push([]);
      }
    } else if (item.action == "clear") {
      if (!channelValues.length > 0 || channelAll[0] == 7) {
        setOrderLists(orders_list);
      } else if (channelValues.length > 0) {
        for (let index = 0; index < channelValues.length; index++) {
          for (let index1 = 0; index1 < newOrderList.length; index1++) {
            if (channelValues[index] == newOrderList[index1].DeviceType) {
              arrey.push(newOrderList[index1]);
            }
          }
        }
        setOrderLists(arrey);
      }
      statusFiltered.push([]);
    }
  };

  const handleFilterByChannels = (list, item) => {
    let arrey = [];
    let values = [];
    let all_value = [];
    const newOrderList = [...orders_list];
    let channelFilltered = [];
    setSelectedChannel(list);
    const fillteredList = list.filter((element) => element.value != 7);
    for (let index = 0; index < fillteredList.length; index++) {
      values.push(fillteredList[index].value);
    }
    const fillteredList1 = list.filter((element) => element.value == 7);
    for (let index1 = 0; index1 < fillteredList1.length; index1++) {
      all_value.push(fillteredList1[index1].value);
    }
    setChannelAll(all_value);
    setChannelValues(values);
    if (item.action == "select-option") {
      if (values.length > 0) {
        if (item.option.value == 7) {
          const filter = list.filter((element) => element.value == 7);
          setSelectedChannel(filter);
          if (!statusValues.length > 0 || statusAll[0] == 1) {
            setOrderLists(orders_list);
          } else if (statusValues.length > 0) {
            for (let index2 = 0; index2 < newOrderList.length; index2++) {
              for (let index3 = 0; index3 < statusValues.length; index3++) {
                if (statusValues[index3] == newOrderList[index2].OrderStatus) {
                  arrey.push(newOrderList[index2]);
                }
              }
              setOrderLists(arrey);
            }
          }
          setChannelValues([]);
        } else {
          setChannelAll([]);
          const filter = list.filter((element) => element.value != 7);
          setSelectedChannel(filter);
          if (newOrderList.length > 0) {
            for (let index0 = 0; index0 < newOrderList.length; index0++) {
              for (let i = 0; i < values.length; i++) {
                if (values[i] == newOrderList[index0].DeviceType) {
                  channelFilltered.push(newOrderList[index0]);
                }
              }
            }
            if (!statusValues.length > 0 || statusAll[0] == 1) {
              setOrderLists(channelFilltered);
            } else if (statusValues.length > 0) {
              for (let index2 = 0; index2 < statusValues.length; index2++) {
                for (
                  let index3 = 0;
                  index3 < channelFilltered.length;
                  index3++
                ) {
                  if (
                    statusValues[index2] == channelFilltered[index3].OrderStatus
                  ) {
                    arrey.push(channelFilltered[index3]);
                  }
                }
              }
              setOrderLists(arrey);
            }
          }
        }
      }
    } else if (item.action == "remove-value") {
      if (list.length > 0) {
        if (newOrderList.length > 0) {
          for (let index0 = 0; index0 < newOrderList.length; index0++) {
            for (let i = 0; i < values.length; i++) {
              if (values[i] == newOrderList[index0].DeviceType) {
                channelFilltered.push(newOrderList[index0]);
              }
            }
          }
          if (!statusValues.length > 0 || statusAll[0] == 1) {
            setOrderLists(channelFilltered);
          } else if (statusValues.length > 0) {
            for (let index2 = 0; index2 < statusValues.length; index2++) {
              for (let index3 = 0; index3 < channelFilltered.length; index3++) {
                if (
                  statusValues[index2] == channelFilltered[index3].OrderStatus
                ) {
                  arrey.push(channelFilltered[index3]);
                }
              }
            }
            setOrderLists(arrey);
          }
        }
      } else {
        if (!statusValues.length > 0 || statusAll[0] == 1) {
          setOrderLists(orders_list);
        } else if (statusValues.length > 0) {
          for (let index = 0; index < newOrderList.length; index++) {
            for (let index1 = 0; index1 < statusValues.length; index1++) {
              if (statusValues[index1] == newOrderList[index].OrderStatus) {
                arrey.push(newOrderList[index]);
              }
            }
          }
          setOrderLists(arrey);
        }
        channelFilltered.push([]);
      }
    } else if (item.action == "clear") {
      if (!statusValues.length > 0 || statusAll[0] == 1) {
        setOrderLists(orders_list);
      } else if (statusValues.length > 0) {
        for (let index = 0; index < statusValues.length; index++) {
          for (let index1 = 0; index1 < newOrderList.length; index1++) {
            if (statusValues[index] == newOrderList[index1].OrderStatus) {
              arrey.push(newOrderList[index1]);
            }
          }
          setOrderLists(arrey);
        }
      }
      channelFilltered.push([]);
    }
  };
  const handleSelectedOrder = (item) => {
    // dispatch(showOrderListPopupAction(true));
    navigate("orderdetails");
    dispatch(
      selectedOrderListAction({
        ...item,
        orderList_start_date: startDate,
        orderList_end_date: endDate,
        financial_order: false,
      })
    );
  };
  const handleSearchOrderByDate = () => {
    if (startDate != "" && endDate != "") {
      if (
        moment(startDate).format("YYYY-MM-DD") <=
        moment(endDate).format("YYYY-MM-DD")
      ) {
        store.set("startDate", startDate);
        store.set("endDate", endDate);
        if (selected_shop.length > 0) {
          dispatch(
            ordersListAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              startDate ? moment(startDate).format("YYYY-MM-DD HH:mm") : "",
              endDate ? moment(endDate).format("YYYY-MM-DD HH:mm") : ""
            )
          );
          setSelectedChannel([]);
          setSelectedStatus([]);
        }
      } else {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{
              lang.start_date_end_date_in_order_list
            }&nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 5000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      }
    }
  };

  const handleSearchBox = (e) => {
    const copy_orders_list = [...orderLists1];

    let newList = [];
    if (e.target.value == "") {
      setOrderLists(orders_list);
    } else {
      newList = copy_orders_list.filter((item) => {
        if (item.Mail) {
          let lc = item.Mail.toLowerCase();
          const filter = e.target.value.toLowerCase();
          return lc.includes(filter);
        }
      });
      setOrderLists(newList);
    }
  };
  useEffect(() => {
    if (orders_list) {
      let orderStatus = [];
      let devicesType = [];
      orders_list.forEach((element) => {
        orderStatus.push(element.OrderStatus);
        devicesType.push(element.DeviceTypeName);
      });
      setOrderStatuss(orderStatus);
      setOrderLists(orders_list);
      setOrderLists1(orders_list);
    }
  }, [orders_list]);

  useEffect(() => {
    if (!back_to_order) {
      store.remove("startDate");
      store.remove("endDate");
      dispatch(selectedOrderListAction(null));
      if (selected_shop.length > 0) {
        dispatch(
          ordersListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            new Date() ? moment(new Date()).format("YYYY-MM-DD HH:mm") : "",
            new Date() ? moment(new Date()).format("YYYY-MM-DD HH:mm") : ""
          )
        );
      }
      setSelectedChannel([]);
      setSelectedStatus([]);
      setStartDate(new Date());
      setEndDate(new Date());
    } else {
      dispatch(backToOrderAction(null));
      const start_date = store.get("startDate");
      const end_date = store.get("endDate");

      if (start_date) {
        setStartDate(new Date(moment(start_date).format("YYYY-MM-DD")));
      }
      if (end_date) {
        setEndDate(new Date(moment(end_date).format("YYYY-MM-DD")));
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <div
      className="countaining-div common-styles orders-container-div"
      style={{
        borderLeftWidth: width1 <= 560 ? "none" : 0.3,
        borderLeftColor: width1 <= 560 ? "none" : main_nav_button_color,
        borderLeftStyle: width1 <= 560 ? "none" : "solid",
        // height: window.innerHeight - 160,
      }}
      id="countaining"
    >
      <ListHeader
        title={
          orderLists.length == 0 || orderLists.length == 1
            ? `${orderLists.length} ${lang.order}`
            : `${orderLists.length} ${lang.orders}`
        }
        show_back={false}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="order-container-search-box">
            <i class="fa fa-search" aria-hidden="true"></i>{" "}
            <input
              placeholder="Search by email"
              onChange={(e) => handleSearchBox(e)}
            />
          </div>
          {width1 <= 560 ? (
            <>
              <div className="mobile-orders-container">
                <div className="mobile-orders-container-date">
                  {" "}
                  <div style={{ width: "38%", height: "40px" }}>
                    <h6 className="h6">{lang.from}</h6>

                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={startDate}
                      onChange={(e) => setStartDate(e)}
                      className="date_picker"
                      calendarClassName="calendarClassName"
                    />
                  </div>
                  <div style={{ width: "38%", height: "40px" }}>
                    <h6 className="h6">{lang.to}</h6>

                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={endDate}
                      onChange={(e) => setEndDate(e)}
                      className="date_picker"
                    />
                  </div>
                  <div
                    style={{
                      width: "20%",
                      height: "40px",
                      marginTop: "27px",
                    }}
                    onClick={handleSearchOrderByDate}
                  >
                    <button
                      style={{
                        border: `1px solid ${primaryColor}`,
                        backgroundColor: primaryColor,
                        width: "100%",
                        color: white,
                        height: "100%",
                        borderRadius: "2px",
                      }}
                    >
                      Result
                    </button>
                  </div>
                </div>{" "}
                <div className="mobile-orders-container-filters">
                  <div style={{ width: "49%" }}>
                    <h6 className="h6">{lang.status}</h6>
                    <Select
                      options={order_status}
                      defaultValue={selectedStatus}
                      isSearchable={false}
                      onChange={handleFilterByStatus}
                      value={selectedStatus}
                      isMulti={true}
                      styles={customStyles}
                      // className="select"
                    />
                  </div>
                  <div style={{ width: "49%" }}>
                    <h6 className="h6">{lang.channels}</h6>
                    <Select
                      options={device_type}
                      defaultValue={selectedChannel}
                      isSearchable={false}
                      onChange={handleFilterByChannels}
                      value={selectedChannel}
                      isMulti={true}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: "25px",
                }}
              >
                {orderLists.length > 0 ? (
                  orderLists.map((item, index) => (
                    <div
                      className="mobile-orders-container1"
                      key={item.OrderId}
                    >
                      <div
                        className="mobile-show-order-status"
                        style={{
                          backgroundColor:
                            item.OrderStatus == mobile_order_status.Accepted
                              ? "rgb(106, 249, 225)"
                              : item.OrderStatus == mobile_order_status.Rejected
                              ? "rgb(250, 134, 134)"
                              : item.OrderStatus == mobile_order_status.Cancelld
                              ? "rgb(255, 90, 90)"
                              : item.OrderStatus == mobile_order_status.Failed
                              ? "rgb(250, 196, 196)"
                              : item.OrderStatus ==
                                mobile_order_status.Completed
                              ? "#f38321"
                              : item.OrderStatus == mobile_order_status.Finish
                              ? "rgb(247, 58, 247)"
                              : item.OrderStatus ==
                                mobile_order_status.Not_printed
                              ? "#009EFF"
                              : "yellow",
                        }}
                      ></div>
                      <div
                        className="mobile-orders-container-orderBox"
                        onClick={() => handleSelectedOrder(item)}
                      >
                        <div className="mobile-orders-container-first-row">
                          <div className="mobile-orders-container-first-row-labels">
                            <p>{lang.orderId_status}</p>
                            <p>{lang.order_date}</p>
                            <p>{lang.total}</p>
                          </div>
                          <div className="mobile-orders-container-first-row-details">
                            <div>
                              <p>{item.OrderId}</p>
                              <p>{item.OrderStatusName}</p>
                            </div>
                            <p>
                              {moment(item.OrderDate).format(
                                "YYYY/MM/DD-HH:mm"
                              )}
                            </p>
                            <p>£{item.ForPay.toFixed(2)}</p>
                          </div>
                        </div>

                        <div className="mobile-orders-container-first-row">
                          <div className="mobile-orders-container-first-row-labels">
                            <p>{lang.payment_method}</p>
                            <p>{lang.sale_method}</p>
                            <p>{lang.customer}</p>
                          </div>
                          <div className="mobile-orders-container-first-row-details">
                            <p>{item.PayMethodName}</p>
                            <p>{item.SaleMethodName}</p>
                            <p>{item.CustomerFullName}</p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  ))
                ) : (
                  <div className="mobile-container-noOrder-text">
                    <h4>{lang.no_order}</h4>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="sm-div2">
                <div style={{ width: "22%" }}>
                  <h6 className="h6">{lang.status}</h6>
                  <Select
                    options={order_status}
                    defaultValue={selectedStatus}
                    isSearchable={false}
                    onChange={handleFilterByStatus}
                    value={selectedStatus}
                    isMulti={true}
                    styles={customStyles}
                    // className="select"
                  />
                </div>
                <div style={{ width: "22%" }}>
                  <h6 className="h6">{lang.channels}</h6>
                  <Select
                    options={device_type}
                    defaultValue={selectedChannel}
                    isSearchable={false}
                    onChange={handleFilterByChannels}
                    value={selectedChannel}
                    isMulti={true}
                    styles={customStyles}
                  />
                </div>
                <div
                  style={{
                    width: "22%",
                    height: "40px",
                  }}
                >
                  <h6 className="h6">{lang.from}</h6>

                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={(e) => setStartDate(e)}
                    className="date_picker"
                    withPortal={100}
                    calendarClassName="calendarClassName"
                  />
                </div>
                <div style={{ width: "22%", height: "40px" }}>
                  <h6 className="h6">{lang.to}</h6>

                  <DatePicker
                    withPortal={100}
                    dateFormat="yyyy-MM-dd"
                    selected={endDate}
                    onChange={(e) => setEndDate(e)}
                    className="date_picker"
                    // calendarClassName="calendarClassName"
                  />
                </div>
                <div
                  style={{
                    width: "10%",
                    height: "40px",
                    marginTop: "27px",
                  }}
                  onClick={handleSearchOrderByDate}
                >
                  <button
                    style={{
                      border: `1px solid ${primaryColor}`,
                      backgroundColor: primaryColor,
                      width: "100%",
                      color: white,
                      height: "100%",
                      borderRadius: "2px",
                    }}
                  >
                    Result
                  </button>
                </div>
              </div>
              <div>
                <HeaderList />
                {orderLists.length > 0
                  ? orderLists.map((item, index) => (
                      <OrderListItem
                        index1={index}
                        item={item}
                        order_id={item.OrderId}
                        invice_id={item.InvoiceId}
                        status={item.OrderStatus}
                        status_name={item.OrderStatusName}
                        channels={item.DeviceTypeName}
                        requested_for={item.OrderDate}
                        sale_mathod={item.SaleMethodName}
                        // store_name={item.Store_name}
                        post_code={item.CustomerPostCode}
                        order_amount={item.ForPay.toFixed(2)}
                        payment_status={item.PayMethodName}
                        customer={item.CustomerFullName}
                        sale_mathod_number={item.SaleMethod}
                        on_click={() => handleSelectedOrder(item)}
                      />
                    ))
                  : null}
              </div>
            </>
          )}
        </div>
      </div>
      <Toaster />
      {loading_orders ? <Loader /> : null}
    </div>
  );
};

export default Orders;
