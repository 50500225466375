import React, { useState, useEffect } from "react";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SaveMenuButton from "../../../components/SaveMenuButton";
import {
  getWePosSettingListAction,
  updateWePosSettingAction,
} from "../../../redux/WePos/Actions";
import { new_domain } from "../../../constants/BaseUrl";
import { image_Url } from "../../../constants/BaseUrl";
const WePosSetting = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    loading_appSettings,
    result_successfull,
    result_message,
    result_failed,
    fof_list,
  } = useSelector((state) => state.WePosRedusers);

  // const image_href = selected_shop[0].Domain;
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [mobile, setMobile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [appStoreUrl, setAppStoreUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [bannerTextLanguage, setBannerTextLanuage] = useState("");
  const [instgramUrl, setInstgramUrl] = useState("");
  const [googlePlayUrl, setGooglePlayUrl] = useState("");
  const [postCodeMessage, setPostCodeMessage] = useState("");
  const [src, setSrc] = useState("");
  const [url, setUrl] = useState("");
  const [href, setHref] = useState("");
  const [socialMediaImageUrl, setSocialMediaImageUrl] = useState("");
  const [socialMediaImageFile, setSocialMediaImageFile] = useState("");
  const [lastSocialMediaImageUrl, setLastSocialMediaImageUrl] = useState("");
  const [socialMediaImageDimensions, setSocialMediaImageDimensions] = useState(
    {}
  );
  const [logoImageFile, setLogoImageFile] = useState("");
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const [logoImageDimensions, setLogoImageDimensions] = useState({});
  const [lastLogoImageUrl, setLastLogoImageUrl] = useState("");

  const [favicon, setfavicon] = useState("");
  const [lastFavIconName, setLastFavIconName] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [lastBannerImageName, setLastBannerImageName] = useState("");
  const [bannerMobileImage, setBannerMobile] = useState("");
  const [lastBannerMobileUrl, setLastBannerMobileUrl] = useState("");
  const [urlDimensions, setUrlDimensions] = useState({});
  const [hrefDimensions, setHrefDimensions] = useState({});
  const [srcDimensions, setSrcDimensions] = useState({});
  const [domain, setDomain] = useState("");
  const [key1, setKey1] = useState("");
  const [key2, setKey2] = useState("");
  const [key3, setKey3] = useState("");
  const [key4, setKey4] = useState("");
  const [key5, setKey5] = useState("");

  const imageUrl = src;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl1 = url;
  const loadImage1 = () => {
    const img = new Image();
    img.src = imageUrl1;

    img.onload = () => {
      setUrlDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl2 = href;
  const loadImage2 = () => {
    const img = new Image();
    img.src = imageUrl2;

    img.onload = () => {
      setHrefDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const imageUrl4 = logoImageUrl;
  const loadLogoImage = () => {
    const img = new Image();
    img.src = imageUrl4;

    img.onload = () => {
      setLogoImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const imageUrl5 = socialMediaImageUrl;
  const loadSocialMediaImage = () => {
    const img = new Image();
    img.src = imageUrl5;

    img.onload = () => {
      setSocialMediaImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };
  const onSelectSrc = (e) => {
    setfavicon(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }
    setKey1(e);
  };

  const onSelectUrl = (e) => {
    setBannerImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setUrl(URL.createObjectURL(e.target.files[0]));
    }
    setKey2(e);
  };

  const onSelectHref = (e) => {
    setBannerMobile(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    setKey3(e);
  };

  const onSelectLogoImageUrl = (e) => {
    setLogoImageFile(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setLogoImageUrl(URL.createObjectURL(e.target.files[0]));
    }
    setKey4(e);
  };
  const onSelectSocialMediaImageUrl = (e) => {
    setSocialMediaImageFile(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setSocialMediaImageUrl(URL.createObjectURL(e.target.files[0]));
    }
    setKey5(e);
  };
  let formData = new FormData();

  const handleSaveClicked = () => {
    if (title == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (favicon != "" && favicon.size > 200000) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.fav_icon_size_error}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (favicon != "" && srcDimensions.width != srcDimensions.height) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.verify_with_height_in_fav_icon}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (favicon != "" && favicon.name.split(".")[1] != "ico") {
      toast.error(<h5> &nbsp;&nbsp;{lang.favicon_format}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (logoImageFile != "" && logoImageFile.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.logo_size_error}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (bannerImage != "" && bannerImage.size > 400000) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.banner_image_size_error}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      bannerImage != "" &&
      urlDimensions.width < urlDimensions.height * 2
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.verify_with_height_in_banner_image}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      socialMediaImageFile != "" &&
      socialMediaImageFile.size > 1024000
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.social_media_image_size}
          &nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      socialMediaImageFile != "" &&
      !(
        socialMediaImageDimensions.width == 1200 &&
        socialMediaImageDimensions.height == 630
      )
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.social_media_size_dimenssions}
          &nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (bannerMobileImage != "" && bannerMobileImage.size > 400000) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.bachground_image_size_error}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("Title", title ? title : "");
      formData.append("Description", desc ? desc : "");
      formData.append("Mobile", mobile ? mobile : "");
      formData.append("PhoneNumber", phoneNumber ? phoneNumber : "");
      formData.append("FavIcom", favicon ? favicon : "");
      formData.append("BannerText", "");
      formData.append(
        "BannerImage",
        bannerImage
          ? bannerImage
          : // .name.split(".")[1] == "gif"
            // ? require(bannerImage)
            // : bannerImage
            ""
      );
      formData.append(
        "BannerMobile",
        bannerMobileImage ? bannerMobileImage : ""
      );
      formData.append(
        "SocialMediaImage",
        socialMediaImageFile ? socialMediaImageFile : ""
      );
      formData.append("Logo", logoImageFile ? logoImageFile : "");
      formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
      formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
      formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
      formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
      formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
      formData.append(
        "LastFavIcomName",
        lastFavIconName ? lastFavIconName : ""
      );
      formData.append("LastLogoName", lastLogoImageUrl ? lastLogoImageUrl : "");
      formData.append(
        "LastBannerImageName",
        lastBannerImageName ? lastBannerImageName : ""
      );
      formData.append(
        "LastBannerMobileName",
        lastBannerMobileUrl ? lastBannerMobileUrl : ""
      );

      formData.append(
        " LastSocialMediaImage",
        lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
      );
      formData.append("DeleteImage", 0);

      dispatch(updateWePosSettingAction(formData, login_key, user_id));
    }
  };

  const handleDeleteImageAndUpdate = (key) => {
    if (key == 1) {
      if (key1) {
        key1.target.value = null;
      }
      setSrc("");
      setfavicon("");
      if (fof_list[0].FavIcom != "") {
        if (`${image_Url}${domain}/${fof_list[0].FavIcom}` != src) {
          setSrc(`${image_Url}${domain}/${fof_list[0].FavIcom}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Title", title ? title : "");
          formData.append("Description", desc ? desc : "");
          formData.append("Mobile", mobile ? mobile : "");
          formData.append("PhoneNumber", phoneNumber ? phoneNumber : "");
          formData.append("FavIcom", "");
          formData.append("BannerText", "");
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append(
            "BannerMobile",
            bannerMobileImage ? bannerMobileImage : ""
          );
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append("Logo", logoImageFile ? logoImageFile : "");
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastLogoName",
            lastLogoImageUrl ? lastLogoImageUrl : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBannerMobileName",
            lastBannerMobileUrl ? lastBannerMobileUrl : ""
          );

          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          formData.append("DeleteImage", key);

          dispatch(updateWePosSettingAction(formData, login_key, user_id));
        }
      }
    } else if (key == 2) {
      if (key2) {
        key2.target.value = null;
      }

      setUrl("");
      setBannerImage("");
      if (fof_list[0].BannerImage != "") {
        if (`${image_Url}${domain}/${fof_list[0].BannerImage}` != url) {
          setUrl(`${image_Url}${domain}/${fof_list[0].BannerImage}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Title", title ? title : "");
          formData.append("Description", desc ? desc : "");
          formData.append("Mobile", mobile ? mobile : "");
          formData.append("PhoneNumber", phoneNumber ? phoneNumber : "");
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", "");
          formData.append("BannerImage", "");
          formData.append(
            "BannerMobile",
            bannerMobileImage ? bannerMobileImage : ""
          );
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append("Logo", logoImageFile ? logoImageFile : "");
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastLogoName",
            lastLogoImageUrl ? lastLogoImageUrl : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBannerMobileName",
            lastBannerMobileUrl ? lastBannerMobileUrl : ""
          );

          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          formData.append("DeleteImage", key);

          dispatch(updateWePosSettingAction(formData, login_key, user_id));
        }
      }
    } else if (key == 3) {
      if (key3) {
        key3.target.value = null;
      }
      setBannerMobile("");
      setHref("");
      if (fof_list[0].BannerMobile != "") {
        if (`${image_Url}${domain}/${fof_list[0].BannerMobile}` != href) {
          setHref(`${image_Url}${domain}/${fof_list[0].BannerMobile}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Title", title ? title : "");
          formData.append("Description", desc ? desc : "");
          formData.append("Mobile", mobile ? mobile : "");
          formData.append("PhoneNumber", phoneNumber ? phoneNumber : "");
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", "");
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append("BannerMobile", "");
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append("Logo", logoImageFile ? logoImageFile : "");
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastLogoName",
            lastLogoImageUrl ? lastLogoImageUrl : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBannerMobileName",
            lastBannerMobileUrl ? lastBannerMobileUrl : ""
          );

          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          formData.append("DeleteImage", key);

          dispatch(updateWePosSettingAction(formData, login_key, user_id));
        }
      }
    } else if (key == 4) {
      if (key4) {
        key4.target.value = null;
      }
      setLogoImageFile("");
      setLogoImageUrl("");
      if (fof_list[0].Logo != "") {
        if (`${image_Url}${domain}/${fof_list[0].Logo}` != logoImageUrl) {
          setLogoImageUrl(`${image_Url}${domain}/${fof_list[0].Logo}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Title", title ? title : "");
          formData.append("Description", desc ? desc : "");
          formData.append("Mobile", mobile ? mobile : "");
          formData.append("PhoneNumber", phoneNumber ? phoneNumber : "");
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", "");
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append(
            "BannerMobile",
            bannerMobileImage ? bannerMobileImage : ""
          );
          formData.append(
            "SocialMediaImage",
            socialMediaImageFile ? socialMediaImageFile : ""
          );
          formData.append("Logo", "");
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastLogoName",
            lastLogoImageUrl ? lastLogoImageUrl : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBannerMobileName",
            lastBannerMobileUrl ? lastBannerMobileUrl : ""
          );

          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          formData.append("DeleteImage", key);

          dispatch(updateWePosSettingAction(formData, login_key, user_id));
        }
      }
    } else if (key == 5) {
      if (key5) {
        key5.target.value = null;
      }
      setSocialMediaImageFile("");
      setSocialMediaImageUrl("");
      if (fof_list[0].SocialMediaImage != "") {
        if (
          `${image_Url}${domain}/${fof_list[0].SocialMediaImage}` !=
          socialMediaImageUrl
        ) {
          setSocialMediaImageUrl(
            `${image_Url}${domain}/${fof_list[0].SocialMediaImage}`
          );
        } else {
          formData.append("LoginKey", login_key);
          formData.append("UserId", user_id);
          formData.append("Title", title ? title : "");
          formData.append("Description", desc ? desc : "");
          formData.append("Mobile", mobile ? mobile : "");
          formData.append("PhoneNumber", phoneNumber ? phoneNumber : "");
          formData.append("FavIcom", favicon ? favicon : "");
          formData.append("BannerText", "");
          formData.append(
            "BannerImage",
            bannerImage
              ? bannerImage.name.split(".")[1] == "gif"
                ? require(bannerImage)
                : bannerImage
              : ""
          );
          formData.append(
            "BannerMobile",
            bannerMobileImage ? bannerMobileImage : ""
          );
          formData.append("SocialMediaImage", "");
          formData.append("Logo", logoImageFile ? logoImageFile : "");
          formData.append("FacebookUrl", facebookUrl ? facebookUrl : "");
          formData.append("TwitterUrl", twitterUrl ? twitterUrl : "");
          formData.append("InstagramUrl", instgramUrl ? instgramUrl : "");
          formData.append("AppStoreUrl", appStoreUrl ? appStoreUrl : "");
          formData.append("GooglePlayUrl", googlePlayUrl ? googlePlayUrl : "");
          formData.append(
            "LastFavIcomName",
            lastFavIconName ? lastFavIconName : ""
          );
          formData.append(
            "LastLogoName",
            lastLogoImageUrl ? lastLogoImageUrl : ""
          );
          formData.append(
            "LastBannerImageName",
            lastBannerImageName ? lastBannerImageName : ""
          );
          formData.append(
            "LastBannerMobileName",
            lastBannerMobileUrl ? lastBannerMobileUrl : ""
          );

          formData.append(
            " LastSocialMediaImage",
            lastSocialMediaImageUrl ? lastSocialMediaImageUrl : ""
          );
          formData.append("DeleteImage", key);

          dispatch(updateWePosSettingAction(formData, login_key, user_id));
        }
      }
    }
  };

  useEffect(() => {
    if (fof_list) {
      if (fof_list.length > 0) {
        if (fof_list[0].Domain) {
          setDomain(fof_list[0].Domain);
        } else {
          setDomain("");
        }
        if (fof_list[0].Title) {
          setTitle(fof_list[0].Title);
        } else {
          setTitle("");
        }
        if (fof_list[0].Description) {
          setDesc(fof_list[0].Description);
        } else {
          setDesc("");
        }
        if (fof_list[0].Mobile) {
          setMobile(fof_list[0].Mobile);
        } else {
          setMobile("");
        }
        if (fof_list[0].PhoneNumber) {
          setPhoneNumber(fof_list[0].PhoneNumber);
        } else {
          setPhoneNumber("");
        }
        if (fof_list[0].InstagramUrl) {
          setInstgramUrl(fof_list[0].InstagramUrl);
        } else {
          setInstgramUrl("");
        }
        if (fof_list[0].TwitterUrl) {
          setTwitterUrl(fof_list[0].TwitterUrl);
        } else {
          setTwitterUrl("");
        }
        if (fof_list[0].AppStoreUrl) {
          setAppStoreUrl(fof_list[0].AppStoreUrl);
        } else {
          setAppStoreUrl("");
        }
        if (fof_list[0].GooglePlayUrl) {
          setGooglePlayUrl(fof_list[0].GooglePlayUrl);
        } else {
          setGooglePlayUrl("");
        }
        if (fof_list[0].FacebookUrl) {
          setFacebookUrl(fof_list[0].FacebookUrl);
        } else {
          setFacebookUrl("");
        }
        if (fof_list[0].FavIcom) {
          setSrc(`${image_Url}${fof_list[0].Domain}/${fof_list[0].FavIcom}`);
          setLastFavIconName(fof_list[0].FavIcom);
        } else {
          setSrc("");
          setLastFavIconName("");
        }
        if (fof_list[0].Logo) {
          setLogoImageUrl(
            `${image_Url}${fof_list[0].Domain}/${fof_list[0].Logo}`
          );
          setLastLogoImageUrl(fof_list[0].Logo);
        } else {
          setLastLogoImageUrl("");
          setLogoImageUrl("");
        }
        if (fof_list[0].BannerImage) {
          setUrl(
            `${image_Url}${fof_list[0].Domain}/${fof_list[0].BannerImage}`
          );
          setLastBannerImageName(fof_list[0].BannerImage);
        } else {
          setBannerImage("");
          setLastBannerImageName("");
        }
        if (fof_list[0].SocialMediaImage) {
          setSocialMediaImageUrl(
            `${image_Url}${fof_list[0].Domain}/${fof_list[0].SocialMediaImage}`
          );
          setLastSocialMediaImageUrl(fof_list[0].SocialMediaImage);
        } else {
          setSocialMediaImageUrl("");
          setLastSocialMediaImageUrl("");
        }
        if (fof_list[0].BannerMobile) {
          setHref(
            `${image_Url}${fof_list[0].Domain}/${fof_list[0].BannerMobile}`
          );
          setLastBannerMobileUrl(fof_list[0].BannerMobile);
        } else {
          setHref("");
          setLastBannerMobileUrl("");
        }
      }
    }
  }, [fof_list]);

  useEffect(() => {
    if (login_key && user_id) {
      dispatch(getWePosSettingListAction(login_key, user_id));
    }
  }, []);

  return (
    <div
      className="countaining-div "
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {" "}
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">* {lang.title}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>{" "}
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.mobile}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={mobile}
                onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
                maxLength={11}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.phone_number}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={phoneNumber}
                onChange={(e) =>
                  setPhoneNumber(e.target.value.replace(/\D/g, ""))
                }
                maxLength={11}
              />
            </div>{" "}
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.instgram_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={instgramUrl}
                onChange={(e) => setInstgramUrl(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.twitter_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={twitterUrl}
                onChange={(e) => setTwitterUrl(e.target.value)}
              />
            </div>{" "}
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.app_store_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={appStoreUrl}
                onChange={(e) => setAppStoreUrl(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.google_play_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={googlePlayUrl}
                onChange={(e) => setGooglePlayUrl(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.facebook_url}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={facebookUrl}
                onChange={(e) => setFacebookUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            {" "}
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.favicon}</h6>
              <div style={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectSrc(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {src ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(1)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    src
                      ? src
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {lang.image_size}{" "}
                <span style={{ marginLeft: "20px" }}>
                  {lang.fav_icon_dimensions}
                </span>
              </p>
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.logo}</h6>
              <div style={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectLogoImageUrl(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {logoImageUrl ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(4)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    logoImageUrl
                      ? logoImageUrl
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadLogoImage}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {" "}
                {lang.image_size}{" "}
                {/* <span style={{ marginLeft: "20px" }}>
                  {" "}
                  {lang.social_media_dimensions}
                </span> */}
              </p>
            </div>{" "}
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.banner_image}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectUrl(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {url ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(2)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    url
                      ? url
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  // src="https://animated-gif-creator.com/images/03/solar-system-animation-in-powerpoint-animated-presentation_1.gif"
                  className="images-style"
                  onLoad={loadImage1}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {lang.banner_image_size}{" "}
                <span style={{ marginLeft: "20px" }}>
                  {lang.banner_image_dimensions}
                </span>
              </p>
            </div>
          </div>{" "}
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.social_media_image}</h6>
              <div style={{ width: "100%" }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectSocialMediaImageUrl(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {socialMediaImageUrl ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(5)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    socialMediaImageUrl
                      ? socialMediaImageUrl
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadSocialMediaImage}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">
                {" "}
                {lang.desctop_and_mobile_size}{" "}
                <span style={{ marginLeft: "20px" }}>
                  {" "}
                  {lang.social_media_dimensions}
                </span>
              </p>
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="p-image h6"> {lang.banner_mobile}</h6>
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectHref(e)}
                  className="web-setting-input-type-file"
                />
              </div>
              <div
                className="web-setting-container-image-div"
                style={{ position: "relative" }}
              >
                {href ? (
                  <div
                    className="container-image-delete-icon"
                    onClick={() => handleDeleteImageAndUpdate(3)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <img
                  src={
                    href
                      ? href
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  onLoad={loadImage2}
                  style={{ backgroundColor: "#F7F7F6" }}
                />
              </div>
              <p className="image-default-size">{lang.banner_image_size}</p>
            </div>
          </div>
          {/* <div className="ASOP-div1">
            <div className=" div-chechbox-p ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={backgroundRepeat}
                      onChange={() => setBackgroundRepeat(!backgroundRepeat)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.background_repeact}
                />
              </FormGroup>
            </div>
            <div className=" div-chechbox-p ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBannerImage}
                      onChange={() => setShowBannerImage(!showBannerImage)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_banner_image}
                />
              </FormGroup>
            </div>
            <div className=" div-chechbox-p ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBackgroundImage}
                      onChange={() =>
                        setShowBackgroundImage(!showBackgroundImage)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_mobile_banner_image}
                />
              </FormGroup>
            </div>
          </div>{" "} */}
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSaveClicked()} />
    </div>
  );
};

export default WePosSetting;
