import {
  visibleRefunPopupAction,
  refundOrderAction,
  visibleCreateDebtPopupAction,
} from "../../../redux/financial/Actions";
import { useState, useEffect } from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { primaryColor } from "../../../constants/Colors";
import { setDefaultAction } from "../../../redux/menu/Actions";
import Loader from "../../../components/Loader";
const DebtPopup = () => {
  const dispatch = useDispatch();
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  const [amount, setAmount] = useState(0);
  const [reasonText, setReasonText] = useState("");

  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    selected_financial_order,
    loading,
    start_date,
    end_date,
    selected_shop,
  } = useSelector((state) => state.financialReduser);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const handleConfirmRefudOrder = () => {
    if (!selected_shop || selected_shop == 999999) {
      toast.error(<h5> &nbsp;&nbsp;{lang.choose_shop}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      dispatch(
        refundOrderAction(
          login_key,
          user_id,
          selected_shop,
          0,
          amount,
          reasonText,
          1,
          moment(start_date).format("YYYY-MM-DD hh:mm:ss"),
          moment(end_date).format("YYYY-MM-DD hh:mm:ss"),
          selected_shop,
          0
        )
      );
    }
  };
  useEffect(() => {
    if (selected_financial_order) {
      setAmount(selected_financial_order.ForPay);
    }
  }, [selected_financial_order]);

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(visibleCreateDebtPopupAction([false, null]))}
    >
      <div
        onClick={(i) => i.stopPropagation()}
        className="create_shop_popup_content_container"
        style={{
          zIndex: "1000",
          width: "35%",
          height: "80%",
        }}
      >
        <div className="refund-popup-container-refund-text">
          <h5>{lang.create_debit}</h5>{" "}
        </div>

        <div className="refund-popup-container">
          <div className="refund-popup-container-inputs">
            {" "}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mt: 5, display: "flex" },
              }}
              className="refund-popup-box"
              // className="menu-list-container-inputs"
              autoComplete="on"
            >
              <TextField
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                key={2}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                value={amount}
                // defaultValue={amount}
                onChange={(e) =>
                  setAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
                label="* Amount"
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                className="refund-popup-textfield"
              />

              <TextField
                label="* Title"
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                key={3}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": {
                      top: 0,
                    },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                autoFocus={focusedInputKey == 3 ? true : null}
                onFocus={() => setFocusedInputKey(3)}
              />
            </Box>
          </div>
          <div className="refund-popup-container-save-button">
            <button
              type=""
              disabled={
                amount && amount != 0 && reasonText != "" ? false : true
              }
              style={{
                backgroundColor:
                  amount && amount != 0 && reasonText != ""
                    ? null
                    : "rgb(184, 184, 184)",
              }}
              onClick={handleConfirmRefudOrder}
            >
              {lang.confirm}
            </button>
          </div>
        </div>
      </div>{" "}
      {loading ? <Loader /> : null}
    </div>
  );
};

export default DebtPopup;
