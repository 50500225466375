import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";
const amir = {
  orders: [
    {
      id: 2,
      title: "Menu",
      icon: "fa fa-bars",
      expanded: false,
      path: "",
    },
    {
      id: 3,
      title: "Submenu",
      icon: "fa fa-caret-square-o-down",
      expanded: false,
      path: "submenu",
    },
  ],
};
//CREATE SUBMENU
export const getTakingsService = async () => {
  // console.log(`${JSON.stringify(admin)}`);
  return await axios.put(`${baseUrl}device/orderspost/`, {
    ShopId: 1,
    LoginKey: "123",
    DeviceId: "aaa",
    OrdersList: amir,
  });
};
