import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

// GET CATEGORY
export const getCategoryServices = async (login_key, user_id, id) => {
  return await axios.put(`${baseUrl}categorieslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: id,
  });
};
export const createCategoryServices = async (login_key, user_id, id, title) => {
  return await axios.post(`${baseUrl}createcategory/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: id,
    Title: title,
  });
};
export const deleteCategoryServices = async (
  login_key,
  user_id,
  shop_id,
  category_id
) => {
  return await axios.delete(`${baseUrl}deletecategory/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      CategoryId: category_id,
    },
  });
};
export const getDropdownServices = async (login_key, user_id, id) => {
  return await axios.put(`${baseUrl}generaldropdown/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: id,
  });
};

export const sendCategorySettingsServices = async (
  formData,
  login_key,
  user_id,
  id,
  shop_id,
  app_title,
  app_desc,
  web_title,
  web_desc,
  kiosk_title,
  kiosk_desc,
  second_language,
  app_visible,
  web_visible,
  kiosk_visible,
  app_sort,
  web_sort,
  kiosk_sort,
  printer_id,
  report_section,
  product_group,
  include_half,
  vat_id,
  app_bg_color,
  app_title_fontsize,
  app_title_font_family,
  app_title_color,
  app_title_width,
  app_title_height,
  app_image,
  app_visible_image,
  web_image,
  web_visible_image,
  kiosk_image,
  kiosk_visible_image
) => {
  return await axios.post(
    `${baseUrl}updatecategory/`,
    formData
    // {
    //   LoginKey:login_key,
    //   UserId:user_id,
    //   Id: id,
    //   ShopId: shop_id,
    //   AppTitle: app_title,
    //   AppDescription: app_desc,
    //   WebTitle: web_title,
    //   WebDescription: web_desc,
    //   KioskTitle: kiosk_title,
    //   KioskDescription: kiosk_desc,
    //   SecondLanguage: second_language,
    //   AppVisible: app_visible,
    //   WebVisible: web_visible,
    //   KioskVisible: kiosk_visible,
    //   AppSortId: app_sort,
    //   WebSortId: web_sort,
    //   KioskSortId: kiosk_sort,
    //   PrinterId: printer_id,
    //   ReportSection: report_section,
    //   ProductGroup: product_group,
    //   IncludeHalfAndHalf: include_half,
    //   VatId: vat_id,
    //   IsSugessted: is_suggested,
    //   IsPopular: is_popular,
    //   AppBgColor: app_bg_color,
    //   AppTitleFontSize: app_title_fontsize,
    //   AppTitleFontFamily: app_title_font_family,
    //   AppTitleColor: app_title_color,
    //   AppTileWidth: app_title_width,
    //   AppTileHeight: app_title_height,
    //   AppImageUrl: app_image,
    //   AppVisibleImage: app_visible_image,
    //   WebImageUrl: web_image,
    //   WebVisibleImage: web_visible_image,
    //   KioskImageUrl: kiosk_image,
    //   KioskVisibleImage: kiosk_visible_image,
    // }

    // headers: { "Content-Type": "multipart/form-data" }}
  );
};
// CATEGORY OPTION
export const getCategoryOptionsServices = async (
  login_key,
  user_id,
  shop_id,
  category_id
) => {
  return await axios.put(`${baseUrl}optionslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    CategoryId: category_id,
  });
};
export const createCategoryOptionsServices = async (
  login_key,
  user_id,
  shop_id,
  category_id,
  title,
  desc,
  amount
) => {
  return await axios.post(`${baseUrl}createoption/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    CategoryId: category_id,
    Title: title,
    Description: desc,
    Price: amount,
  });
};
export const sendCategoryOptionsServices = async (
  login_key,
  user_id,
  option_id,
  shop_id,
  cat_id,
  app_title,
  short_name,
  web_title,
  web_desc,
  kiosk_title,
  kiosk_desc,
  second_language,
  multi_select_price,
  price_level_main_1,
  price_level_main_2,
  price_level_main_3,
  price_level_main_4,
  price_level_main_5,
  price_level_main_6,
  price_level_main_7,
  price_level_main_8,
  price_level_collection_1,
  price_level_collection_2,
  price_level_collection_3,
  price_level_collection_4,
  price_level_collection_5,
  price_level_collection_6,
  price_level_collection_7,
  price_level_collection_8,
  price_level_eatin_1,
  price_level_eatin_2,
  price_level_eatin_3,
  price_level_eatin_4,
  price_level_eatin_5,
  price_level_eatin_6,
  price_level_eatin_7,
  price_level_eatin_8,
  vat,
  visible_app,
  visible_web,
  visible_kiosk,
  stock,
  sort_app,
  sort_web,
  sort_kiosk,
  font_size_app,
  bg_color_app,
  font_color_app,
  just_title_and_price,
  amount_delivery,
  amount_collection,
  amount_takeaway,
  amount_eatIn
) => {
  return await axios.put(`${baseUrl}updateoption/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: option_id,
    ShopId: shop_id,
    CategoryId: cat_id,
    AppTitle: app_title,
    ShortName: short_name,
    WebTitle: web_title,
    WebDescription: web_desc,
    KioskTitle: kiosk_title,
    KioskDescription: kiosk_desc,
    SecondLanguage: second_language,
    MultiSelectPrice: multi_select_price,
    PriceLevelMain1: price_level_main_1,
    PriceLevelMain2: price_level_main_2,
    PriceLevelMain3: price_level_main_3,
    PriceLevelMain4: price_level_main_4,
    PriceLevelMain5: price_level_main_5,
    PriceLevelMain6: price_level_main_6,
    PriceLevelMain7: price_level_main_7,
    PriceLevelMain8: price_level_main_8,
    PriceLevelCollTake1: price_level_collection_1,
    PriceLevelCollTake2: price_level_collection_2,
    PriceLevelCollTake3: price_level_collection_3,
    PriceLevelCollTake4: price_level_collection_4,
    PriceLevelCollTake5: price_level_collection_5,
    PriceLevelCollTake6: price_level_collection_6,
    PriceLevelCollTake7: price_level_collection_7,
    PriceLevelCollTake8: price_level_collection_8,
    PriceLevelEatIn1: price_level_eatin_1,
    PriceLevelEatIn2: price_level_eatin_2,
    PriceLevelEatIn3: price_level_eatin_3,
    PriceLevelEatIn4: price_level_eatin_4,
    PriceLevelEatIn5: price_level_eatin_5,
    PriceLevelEatIn6: price_level_eatin_6,
    PriceLevelEatIn7: price_level_eatin_7,
    PriceLevelEatIn8: price_level_eatin_8,
    VATId: vat,
    AppVisible: visible_app,
    WebVisible: visible_web,
    KioskVisible: visible_kiosk,
    Stock: stock,
    AppSortId: sort_app,
    WebSortId: sort_web,
    KioskSortId: sort_kiosk,
    AppFontSize: font_size_app,
    AppBgColor: bg_color_app,
    AppFontColor: font_color_app,
    JustTitleAndPrice: just_title_and_price,
    Price: amount_delivery,
    CollectionPrice: amount_collection,
    TakeawayPrice: amount_takeaway,
    EatInPrice: amount_eatIn,
  });
};
export const deleteCategoryOptionsServices = async (
  login_key,
  user_id,
  shop_id,
  option_id
) => {
  return await axios.delete(`${baseUrl}deleteoption/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      OptionId: option_id,
    },
  });
};
export const getCategoryOptionCrustServices = async (
  login_key,
  user_id,
  shop_id,
  option_id
) => {
  return await axios.put(`${baseUrl}crustslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    OptionId: option_id,
  });
};
export const createCategoryOptionCrustServices = async (
  login_key,
  user_id,
  shop_id,
  option_id,
  app_title,
  desc,
  amount
) => {
  return await axios.post(`${baseUrl}createcrust/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    OptionId: option_id,
    AppTitle: app_title,
    Price: amount,
    WebDescription: desc,
  });
};
export const deleteCategoryOptionCrustServices = async (
  login_key,
  user_id,
  shop_id,
  crust_id
) => {
  return await axios.delete(`${baseUrl}deletecrust/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      CrustId: crust_id,
    },
  });
};
export const updateCategoryOptionCrustServices = async (
  login_key,
  user_id,
  id,
  shop_id,
  option_id,
  app_title,
  web_title,
  web_desc,
  kiosk_title,
  kiosk_desc,
  second_language,
  price,
  app_visible,
  web_visible,
  kiosk_visible,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  app_bg_color,
  app_font_color,
  app_title_fontsize,
  just_title_and_price
) => {
  return await axios.put(`${baseUrl}updatecrust/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    ShopId: shop_id,
    OptionId: option_id,
    AppTitle: app_title,
    WebTitle: web_title,
    WebDescription: web_desc,
    KioskTitle: kiosk_title,
    KioskDescription: kiosk_desc,
    SecondLanguage: second_language,
    Price: price,
    AppVisible: app_visible,
    WebVisible: web_visible,
    KioskVisible: kiosk_visible,
    AppSortId: app_sort_id,
    WebSortId: web_sort_id,
    KioskSortId: kiosk_sort_id,
    AppBgColor: app_bg_color,
    AppFontColor: app_font_color,
    AppFontSize: app_title_fontsize,
    JustTitleAndPrice: just_title_and_price,
  });
};

//PRODUCT OPEN CLOSE TIME
export const createProductOpenCloseTimeService = async (
  login_key,
  user_id,
  shop_id,
  product_id,
  days_of_week
) => {
  return await axios.put(`${baseUrl}createProductOpenCloseTime/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductId: product_id,
    DaysOfWeek: days_of_week,
  });
};

// GET PRODUCT OPEN CLOSE LIST
export const getProductOpenCloseListService = async (
  login_key,
  user_id,
  shop_id,
  product_id
) => {
  return await axios.put(`${baseUrl}productOpenCloseList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductId: product_id,
  });
};

// CATEGORY OPEN CLOSE TIME
export const createCategoryOpenCloseTimeService = async (
  login_key,
  user_id,
  shop_id,
  cat_id,
  days_of_week
) => {
  return await axios.put(`${baseUrl}createCategoryOpenCloseTime/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    CategoryId: cat_id,
    DaysOfWeek: days_of_week,
  });
};

// GET CATEGORY OPEN CLOSE LIST
export const getCategoryOpenCloseListService = async (
  login_key,
  user_id,
  shop_id,
  cat_id
) => {
  return await axios.put(`${baseUrl}categoryOpenCloseList/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    CategoryId: cat_id,
  });
};

export const editCtaegoryStatusService = async (
  login_key,
  user_id,
  shop_id,
  cat_id,
  cat_status
) => {
  return await axios.put(`${baseUrl}updateCategoryVisible/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    CategoryId: cat_id,
    WebVisible: cat_status,
  });
};

export const editProductStatusService = async (
  login_key,
  user_id,
  shop_id,
  product_status,
  product_id
) => {
  return await axios.put(`${baseUrl}updateProductVisible/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductId: product_id,
    WebVisible: product_status,
  });
};

export const updateCategorySortService = async (
  login_key,
  user_id,
  shop_id,
  cat_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(
    `${baseUrl}updateCategoryWebSortid/
  `,
    {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      CategoryId: cat_id,
      OldSortId: old_sort_id,
      NewSortId: new_sort_id,
    }
  );
};

export const updateCategoryAppSortService = async (
  login_key,
  user_id,
  shop_id,
  cat_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(
    `${baseUrl}updateCategoryAppSortid/
  `,
    {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      CategoryId: cat_id,
      OldSortId: old_sort_id,
      NewSortId: new_sort_id,
    }
  );
};
// UPDATE PRODUCT SORT
export const updateProductSortService = async (
  login_key,
  user_id,
  shop_id,
  product_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(`${baseUrl}updateProductWebSortid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductId: product_id,
    OldSortId: old_sort_id,
    NewSortId: new_sort_id,
  });
};

export const updateProductAppSortService = async (
  login_key,
  user_id,
  shop_id,
  product_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(`${baseUrl}updateProductAppSortid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductId: product_id,
    OldSortId: old_sort_id,
    NewSortId: new_sort_id,
  });
};

//UPDATE SUBMENU SORT
export const updateProductSubmenuWebSortService = async (
  login_key,
  user_id,
  shop_id,
  product_submenu_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(`${baseUrl}updateProductSubmenuWebSortid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductSubmenuId: product_submenu_id,
    OldSortId: old_sort_id,
    NewSortId: new_sort_id,
  });
};
export const updateProductSubmenuAppSortService = async (
  login_key,
  user_id,
  shop_id,
  product_submenu_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(`${baseUrl}updateProductSubmenuAppSortid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductSubmenuId: product_submenu_id,
    OldSortId: old_sort_id,
    NewSortId: new_sort_id,
  });
};

//UPDATE OPTION SORT
export const updateProductOptionWebSortService = async (
  login_key,
  user_id,
  shop_id,
  product_option_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(`${baseUrl}updateProductOptionWebSortid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductOptionId: product_option_id,
    OldSortId: old_sort_id,
    NewSortId: new_sort_id,
  });
};
export const updateProductOptionAppSortService = async (
  login_key,
  user_id,
  shop_id,
  product_option_id,
  old_sort_id,
  new_sort_id
) => {
  return await axios.put(`${baseUrl}updateProductOptionAppSortid/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductOptionId: product_option_id,
    OldSortId: old_sort_id,
    NewSortId: new_sort_id,
  });
};
