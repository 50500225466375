import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import store from "store2";
import ClipLoader from "react-spinners/ClipLoader";
import QRCode from "react-qr-code";
import axios from "axios";
import LoaderMini from "../../components/LoaderMini";
import {
  loginAction,
  setDefaultLogin,
  sendLoginParameterToProfile,
  sendOTPLoginAction,
  verifyOTPLoginAction,
  activeTwoFactorAuthAction,
} from "../../redux/login/Actions";
import { primaryColor, main_nav_back_color } from "../../constants/Colors";
import { normal_input_height } from "../../constants/Sizes";
import { setDefaultAction } from "../../redux/menu/Actions";

const Login = () => {
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  const dispatch = useDispatch();
  const userRef = useRef(null);
  const passRef = useRef(null);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const loging_response = store.get("login_response");
  // console.log("loging_response", loging_response);
  const {
    result_login_successfull,
    result_failed,
    result_message,
    loading_mini,
    result_send_otp_login_successfull,
    result_verify_otp_login_successfull,
    send_sms_loading_mini,
    enable_two_factor,
  } = useSelector((state) => state.loginReducer);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [lat, setLat] = useState("");
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const [long, setLong] = useState("");
  const [count, setCount] = useState("00:00");
  const [showVerificationCodeInput, setShowVerificationCodeInput] =
    useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [focusedPsswordInput, setFocusedPasswordInput] = useState(1);
  const [twoFactorValidate, setTwoFactorValidate] = useState(false);
  const [showTwoFactorDetails, setShowTwoFactorDetails] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const navigate = useNavigate();
  // const CssTextField = styled(TextField)({
  //   "& label.Mui-focused": {
  //     color: primaryColor,
  //   },
  //   "&&:hover label": {
  //     color: primaryColor,
  //   },
  //   "& .MuiOutlinedInput-root": {
  //     color: "grey",
  //     fontSize: "15px",
  //     height: "45px",
  //     marginBottom: "40px",
  //     "& fieldset": {
  //       borderColor: "grey",
  //     },
  //     "&:hover fieldset": {
  //       borderColor: primaryColor,
  //     },
  //     "&.Mui-focused fieldset": {
  //       borderColor: primaryColor,
  //     },
  //   },
  // });
  const CssTextField = styled(TextField)({
    // "&.MuiTextField-root": {
    //   border: "1px solid red",
    // },
    // "& label": {},
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },

    // " & .MuiInputBase-input": {
    //   border: "1px solid green",
    // },
    "& .MuiOutlinedInput-root": {
      color: "grey",
      borderColor: primaryColor,

      "& fieldset": {
        // borderColor: "grey",
      },
      "& .MuiInputBase-root ": {
        backgroundColor: "red",
        // border: "1px solid red",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });

  const getSHA256Hash = async (input) => {
    const textAsBuffer = new TextEncoder().encode(input);
    // console.log("111", textAsBuffer);
    const hashBuffer = await window.crypto.subtle.digest(
      "SHA-256",
      textAsBuffer
    );
    // console.log("222", hashBuffer);
    // const hashArray1 = new Uint8Array(hashBuffer[0]);
    // console.log("hashArray1", hashArray1);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    const hash = hashArray
      .map((item) => item.toString(16).padStart(2, "0"))
      .join("");

    // console.log("i", input);
    // const textAsBuffer = new TextEncoder().encode(input);
    // console.log("111", textAsBuffer);
    // const hashBuffer = await window.crypto.subtle.digest(
    //   "SHA-256",
    //   textAsBuffer
    // );

    // console.log("222", hashBuffer);

    // const hashArray = Array.from(new Uint8Array(hashBuffer));
    // const hash = hashArray
    //   .map((item) => item.toString(16).padStart(2, "0"))
    //   .join("");

    // console.log("333", hashArray);

    // console.log("444", hash);
    // const hexPairs = hash.match(/.{1,2}/g);
    // for (let i = 0; i < hexPairs.length; i++) {
    //   console.log(parseInt(hexPairs[i], 16));
    // }

    // const buffer = new ArrayBuffer(hashArray.length);
    // const uint8Array = new Uint8Array(buffer);
    // for (let i = 0; i < hashArray.length; i++) {
    //   uint8Array[i] = hashArray[i];
    // }

    // const arr = new Uint8Array(buffer, 0, 16);
    // console.log("int8Array", arr);
    return hash;
  };

  const handleLoginClicked = async () => {
    const password_hash = await getSHA256Hash(password);

    if (showTwoFactorDetails) {
      if (twoFactorCode) {
        const encodedParams = new URLSearchParams();
        encodedParams.set("secret", "2MMU3NUSA6ZQ5IA3");
        encodedParams.set("code", twoFactorCode);

        const options = {
          method: "POST",
          url: "https://otp-authenticator.p.rapidapi.com/validate/",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            "X-RapidAPI-Key":
              "aba298177bmsh9a90ed30afb1e72p1f7dfcjsn205d80e463cd",
            "X-RapidAPI-Host": "otp-authenticator.p.rapidapi.com",
          },
          data: encodedParams,
        };

        try {
          const response = await axios.request(options);

          if (response.data == "True") {
            setTwoFactorValidate(true);
            dispatch(
              activeTwoFactorAuthAction(
                loging_response.LoginKey,
                loging_response.UserAccesses[0].UserId,
                1
              )
            );
          } else {
            setTwoFactorValidate(false);

            toast.error(
              <h5 style={{ marginTop: "7px" }}>
                {" "}
                &nbsp;&nbsp;{lang.incorrect_code}&nbsp;&nbsp;{" "}
              </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    class="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        toast.error(
          <h5 style={{ marginTop: "7px" }}>
            {" "}
            &nbsp;&nbsp;{lang.enter_code}&nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                class="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      }
    } else {
      if (showVerificationCodeInput) {
        if (verificationCode) {
          dispatch(
            verifyOTPLoginAction(
              loging_response.LoginKey,
              loging_response.UserAccesses[0].UserId,
              loging_response.UserAccesses[0].Mobile,
              verificationCode
            )
          );
        } else {
          toast.error(
            <h5> &nbsp;&nbsp;{lang.enter_verification_code}&nbsp;&nbsp; </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  className="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        }
      } else {
        if (userName == "" || password == "") {
          toast.error(
            <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  className="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        } else {
          dispatch(
            loginAction(
              lat,
              long,
              userName.replaceAll(`"`, `\"\"`),
              password_hash.replaceAll(`"`, `\"\"`)
            )
          );
        }
      }
    }

    store.set("saved_time", new Date());
  };

  const handleUserKeydown = (event) => {
    if (event.key === "Enter") {
      if (userName) {
        setFocusedPasswordInput(2);
        passRef.current.focus();
      } else {
        setFocusedPasswordInput(1);
      }
    }
  };
  const handlePassKeydown = (event) => {
    if (event.key === "Enter") {
      if (!password || password == " ") {
        if (!userName) {
          setFocusedPasswordInput(1);
        } else {
          setFocusedPasswordInput(2);
        }
      } else {
        if (!userName) {
          setFocusedPasswordInput(1);
        } else {
          event.preventDefault();
          handleLoginClicked();
          setFocusedPasswordInput(0);
        }
      }
    }
  };

  const handleResendCode = () => {
    if (count != "00:00") {
    } else {
      setResendLoading(true);
      dispatch(
        sendOTPLoginAction(
          loging_response.LoginKey,
          loging_response.UserAccesses[0].UserId,
          loging_response.UserAccesses[0].Mobile
        )
      );
      let myTime = 59;
      const timer = setInterval(() => {
        if (myTime > -1) {
          if (myTime > 9) {
            setCount(`00:${myTime}`);
            myTime = myTime - 1;
          } else {
            setCount(`00:0${myTime}`);
            myTime = myTime - 1;
          }
        } else {
          clearInterval(timer);
        }
      }, 1000);
    }
  };

  const startTimer = () => {
    let myTime = 59;
    const timer = setInterval(() => {
      if (myTime > -1) {
        if (myTime > 9) {
          setCount(`00:${myTime}`);
          myTime = myTime - 1;
        } else {
          setCount(`00:0${myTime}`);
          myTime = myTime - 1;
        }
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
      });
    }
  }, []);

  (async () => {
    const hash = await getSHA256Hash(password);

    store.set("password", hash);
  })();
  // store.set("password", password);
  store.set("user_name", userName);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (result_login_successfull) {
      // navigate("/home/orders");
      const user_info = localStorage.getItem("user_info");

      if (loging_response) {
        if (loging_response.UserAccesses) {
          if (loging_response.UserAccesses.length > 0) {
            if (loging_response.UserAccesses[0].UserType == 10001202) {
              if (loging_response.UserAccesses[0].LoginOTP) {
                if (user_info == loging_response.UserAccesses[0].UserName) {
                  setShowVerificationCodeInput(false);
                  navigate("/home");
                } else {
                  if (loging_response.UserAccesses[0].Mobile) {
                    dispatch(
                      sendOTPLoginAction(
                        loging_response.LoginKey,
                        loging_response.UserAccesses[0].UserId,
                        loging_response.UserAccesses[0].Mobile
                      )
                    );
                  } else {
                    setShowVerificationCodeInput(false);
                    toast.error(
                      <h5 style={{ marginTop: "7px" }}>
                        {" "}
                        &nbsp;&nbsp;{
                          lang.no_number_for_send_code
                        }&nbsp;&nbsp;{" "}
                      </h5>,
                      {
                        position: "top-center",
                        duration: 3000,
                        style: { backgroundColor: "red", color: "white" },
                        icon: (
                          <i
                            style={{ fontSize: 30 }}
                            className="fa fa-times"
                            aria-hidden="true"
                          ></i>
                        ),
                      }
                    );
                  }
                }
              } else {
                setShowVerificationCodeInput(false);
                navigate("/home");
              }
            } else if (loging_response.UserAccesses[0].UserType == 10001203) {
              if (loging_response.UserAccesses[0].TwoFactor) {
                setShowTwoFactorDetails(true);
                if (loging_response.UserAccesses[0].SetupTwoFactor) {
                  setShowQRCode(false);
                } else {
                  setShowQRCode(true);
                }
              } else {
                navigate("/home");
                localStorage.removeItem("user_info");
              }
            } else {
              navigate("/home");
              setShowVerificationCodeInput(false);
              localStorage.removeItem("user_info");
            }
          }
        }
      }
    }
  }, [result_login_successfull]);

  useEffect(() => {
    if (result_send_otp_login_successfull) {
      setShowVerificationCodeInput(true);

      toast.error(
        <h5 style={{ marginTop: "7px" }}>
          {" "}
          &nbsp;&nbsp;{lang.verification_code_send}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "#1e9419", color: "white" },
          icon: (
            <i
              style={{ fontSize: 30 }}
              className="fa fa-check"
              aria-hidden="true"
            ></i>
          ),
        }
      );
      if (!resendLoading) {
        startTimer();
      }
    }
  }, [result_send_otp_login_successfull]);

  useEffect(() => {
    if (result_verify_otp_login_successfull) {
      navigate("/home");
      if (loging_response) {
        if (loging_response.UserAccesses) {
          if (loging_response.UserAccesses.length > 0) {
            localStorage.setItem(
              "user_info",
              // JSON.stringify()
              loging_response.UserAccesses[0].UserName
            );
          }
        }
      }
    }
  }, [result_verify_otp_login_successfull]);

  useEffect(() => {
    if (resendLoading && result_send_otp_login_successfull) {
      setResendLoading(false);
    }
  }, [resendLoading, result_send_otp_login_successfull]);

  useEffect(() => {
    dispatch(sendLoginParameterToProfile([lat, long, userName, password]));
  }, [lat, long, userName, password]);

  useEffect(() => {
    if (result_failed) {
      dispatch(setDefaultLogin());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_failed]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (enable_two_factor) {
      // dispatch(setDefaultLogin());
      // toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
      //   position: "top-center",
      //   duration: 3000,
      //   style: { backgroundColor: "green", color: "white" },
      //   icon: (
      //     <i
      //       style={{ fontSize: 40 }}
      //       class="fa fa-check-circle"
      //       aria-hidden="true"
      //     ></i>
      //   ),
      // });
      navigate("/home");
      setShowQRCode(false);
      localStorage.removeItem("user_info");
    }
  }, [enable_two_factor]);

  // window.addEventListener("load", function () {
  //   setTimeout(function () {
  //     document.getElementById("input").value = "";
  //     document.getElementById("input1").value = "";
  //   }, 500);
  // });
  const options = [
    {
      label: "آیتم 1",
      options: [
        { value: "subitem1-1", label: "زیر آیتم 1-1" },
        { value: "subitem1-2", label: "زیر آیتم 1-2" },
      ],
    },
    {
      label: "آیتم 2",
      options: [
        { value: "subitem2-1", label: "زیر آیتم 2-1" },
        { value: "subitem2-2", label: "زیر آیتم 2-2" },
      ],
    },
  ];

  return width1 <= 560 ? (
    <div
      className="mobile-container-login-page"
      style={{
        height: window.innerHeight,
      }}
    >
      <div className="mobile-container-logo">
        <img
          alt=""
          src="images/L-032.png"
          width=""
          height={55}
          style={{ marginRight: 30 }}
        />
      </div>
      <div
        style={{
          width: "100%",
          flex: 1,
        }}
      >
        <div className="login_inputs_container">
          <h6>{lang.user_name}</h6>
          <input
            type="text"
            autoFocus
            ref={userRef}
            value={userName}
            onKeyDown={handleUserKeydown}
            onChange={(i) => setUserName(i.target.value)}
          />
          <h6>{lang.pass}</h6>
          <input
            type="password"
            ref={passRef}
            onKeyDown={handlePassKeydown}
            value={password}
            onChange={(i) => setPassword(i.target.value)}
          />
          {showTwoFactorDetails ? (
            showQRCode ? (
              <div className="login-container-qrcode">
                <div>
                  <h6>2-Factor Authentication</h6>
                  <img
                    src="https://hackerstotal.com/qrler.php?size=200x200&data=otpauth%3A%2F%2Ftotp%2FDashboard%3AR2002%3Fsecret%3D2MMU3NUSA6ZQ5IA3%26issuer%3DDashboard&ecc=M"
                    alt="123"
                  />
                </div>

                <input
                  autoFocus
                  value={twoFactorCode}
                  onChange={(e) =>
                    setTwoFactorCode(e.target.value.replace(/\D/g, ""))
                  }
                />
              </div>
            ) : (
              <div className="login-container-2factor-auth-code-input">
                <div>
                  <h6>2-Factor Authentication code</h6>
                </div>

                <input
                  autoFocus
                  value={twoFactorCode}
                  onChange={(e) =>
                    setTwoFactorCode(e.target.value.replace(/\D/g, ""))
                  }
                />
              </div>
            )
          ) : null}
          {showVerificationCodeInput ? (
            <>
              <h6>{lang.verification_code}</h6>
              <input
                // ref={passRef}
                onKeyDown={handlePassKeydown}
                value={verificationCode}
                onChange={(i) => setVerificationCode(i.target.value)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "0px",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    width: "25%",
                    color: "red",
                  }}
                >
                  {count}
                </span>
                <div
                  style={{
                    display: "flex",
                    width: "70%",
                    textAlign: "left",
                    cursor: "pointer",
                    color: count == "00:00" ? "red" : "gray",
                    opacity: count != "00:00" ? 0.5 : 1,
                  }}
                  onClick={handleResendCode}
                >
                  {" "}
                  {send_sms_loading_mini ? (
                    <div style={{ paddingLeft: "17px", paddingTop: "10px" }}>
                      <ClipLoader
                        color={"#a8a7a7"}
                        loading={true}
                        cssOverride={override}
                        size={20}
                      />{" "}
                    </div>
                  ) : (
                    <u>{lang.resend_code}</u>
                  )}
                </div>
              </div>
            </>
          ) : null}

          <button
            onKeyDown={(e) => (e.key === "Enter" ? handleLoginClicked : null)}
            disabled={loading_mini ? true : false}
            onClick={handleLoginClicked}
          >
            {loading_mini ? (
              resendLoading ? (
                lang.login
              ) : (
                <LoaderMini />
              )
            ) : showVerificationCodeInput || showTwoFactorDetails ? (
              lang.login
            ) : (
              lang.continue
            )}
          </button>
        </div>
      </div>
      {/* <div className="login_logo_container "></div> */}
      {/* {result_login_successfull ? (
        <Navigate to="/home/orders" replace={true} />
      ) : null}
      <Toaster /> */}
      <Toaster />
    </div>
  ) : (
    <div
      className="container-login-page"
      style={{
        height: " 754px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "60%",
          height: "100%",
        }}
      >
        <div className="login_inputs_container">
          <h6>{lang.user_name}</h6>
          {/* <form method="post" action=""> */}
          <input
            id="input"
            type="text"
            autoFocus
            ref={userRef}
            value={userName}
            onKeyDown={handleUserKeydown}
            onChange={(i) => setUserName(i.target.value)}
          />

          <h6>{lang.pass}</h6>
          <input
            id="input1"
            autocomplete="new_password"
            type="password"
            ref={passRef}
            onKeyDown={handlePassKeydown}
            value={password}
            onChange={(i) => setPassword(i.target.value)}
          />
          {/* </form> */}
          {showTwoFactorDetails ? (
            showQRCode ? (
              <div className="login-container-qrcode">
                <div>
                  <h6>2-Factor Authentication</h6>
                  <img
                    src="https://hackerstotal.com/qrler.php?size=200x200&data=otpauth%3A%2F%2Ftotp%2FDashboard%3AR2002%3Fsecret%3D2MMU3NUSA6ZQ5IA3%26issuer%3DDashboard&ecc=M"
                    alt="123"
                  />
                </div>

                <input
                  autoFocus
                  value={twoFactorCode}
                  onChange={(e) =>
                    setTwoFactorCode(e.target.value.replace(/\D/g, ""))
                  }
                />
              </div>
            ) : (
              <div className="login-container-2factor-auth-code-input">
                <div>
                  <h6>2-Factor Authentication code</h6>
                </div>

                <input
                  autoFocus
                  value={twoFactorCode}
                  onChange={(e) =>
                    setTwoFactorCode(e.target.value.replace(/\D/g, ""))
                  }
                />
              </div>
            )
          ) : null}
          {showVerificationCodeInput ? (
            <>
              <h6>{lang.verification_code}</h6>
              <input
                // ref={passRef}
                onKeyDown={handlePassKeydown}
                value={verificationCode}
                onChange={(i) => setVerificationCode(i.target.value)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "0px",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    width: "25%",
                    color: "red",
                  }}
                >
                  {count}
                </span>
                <div
                  style={{
                    display: "flex",
                    width: "70%",
                    textAlign: "left",
                    cursor: "pointer",
                    color: count == "00:00" ? "red" : "gray",
                    opacity: count != "00:00" ? 0.5 : 1,
                  }}
                  onClick={handleResendCode}
                >
                  {" "}
                  {send_sms_loading_mini ? (
                    <div style={{ paddingLeft: "17px", paddingTop: "10px" }}>
                      <ClipLoader
                        color={"#a8a7a7"}
                        loading={true}
                        cssOverride={override}
                        size={20}
                      />{" "}
                    </div>
                  ) : (
                    <u>{lang.resend_code}</u>
                  )}
                </div>
              </div>
            </>
          ) : null}

          <button
            onKeyDown={(e) => (e.key === "Enter" ? handleLoginClicked : null)}
            disabled={loading_mini ? true : false}
            onClick={handleLoginClicked}
          >
            {loading_mini ? (
              resendLoading ? (
                lang.login
              ) : (
                <LoaderMini />
              )
            ) : showVerificationCodeInput || showTwoFactorDetails ? (
              lang.login
            ) : (
              lang.continue
            )}
          </button>
        </div>
      </div>
      {/* {result_verify_otp_login_successfull ? (
        <Navigate to="/home" replace={true} />
      ) : null} */}
      <Toaster />
    </div>
  );
};

export default Login;
