import * as actionTypes from "../ActionTypes";

const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_users: false,
  owners_list: [],
  employees_list: [],
  selected_users_list_owners: null,
  selected_users_list_employees: null,
  visible_shop_group_popup: false,
  shop_group_list: [],
  selected_shop_group: null,
  shop_list_by_group_id: [],
  selected_shop_ids: [],
  shop_ids: [],
  new_shop_list: [],
};
const usersReduser = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_users: false,
      };
    case actionTypes.GET_USERS_LIST:
      return {
        ...state,
        loading_users: true,
      };
    case actionTypes.GET_EMPLOYEES_LIST:
      return {
        ...state,
        loading_users: false,
        employees_list: action.payload,
      };
    case actionTypes.GET_OWNERS_LIST:
      return {
        ...state,
        loading_users: false,
        owners_list: action.payload,
      };
    case actionTypes.GET_USERS_LIST_FAILED:
      return {
        ...state,
        loading_users: false,
        owners_list: [],
        employees_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.SELECTED_USERS_OWNERS:
      return {
        ...state,
        selected_users_list_owners: action.payload,
      };
    case actionTypes.SELECTED_USERS_EMPLOYEES:
      return {
        ...state,
        selected_users_list_employees: action.payload,
      };
    case actionTypes.CREATE_USERS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_users: true,
      };
    case actionTypes.CREATE_USERS_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_users: false,
      };
    case actionTypes.CREATE_USERS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_users: false,
      };
    case actionTypes.UPDATE_USERS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_users: true,
      };
    case actionTypes.UPDATE_USERS_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_users: false,
      };
    case actionTypes.UPDATE_USERS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_users: false,
      };
    case actionTypes.DELETE_USERS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_users: true,
      };
    case actionTypes.DELETE_USERS_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_users: false,
      };
    case actionTypes.DELETE_USERS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_users: false,
      };
    case actionTypes.VISIBLE_SHOP_GROUP_POPUP:
      return {
        ...state,
        visible_shop_group_popup: action.payload,
      };
    case actionTypes.SHOP_GROUP_LIST:
      return {
        ...state,
        loading_users: true,
      };
    case actionTypes.SHOP_GROUP_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_users: false,
        shop_group_list: action.payload,
      };
    case actionTypes.SHOP_GROUP_LIST_FAILED:
      return {
        ...state,
        loading_users: false,
        shop_group_list: [],
      };
    case actionTypes.CREATE_SHOP_GROUP:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_users: true,
      };
    case actionTypes.CREATE_SHOP_GROUP_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_users: false,
        visible_shop_group_popup: false,
      };
    case actionTypes.CREATE_SHOP_GROUP_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_users: false,
      };
    case actionTypes.UPDATE_SHOP_GROUP:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_users: true,
      };
    case actionTypes.UPDATE_SHOP_GROUP_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_users: false,
        visible_shop_group_popup: false,
      };
    case actionTypes.UPDATE_SHOP_GROUP_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_users: false,
      };

    case actionTypes.DELETE_SHOP_GROUP:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_users: true,
      };
    case actionTypes.DELETE_SHOP_GROUP_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_users: false,
      };
    case actionTypes.DELETE_SHOP_GROUP_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_users: false,
      };

    case actionTypes.SELECTED_SHOP_GROUP:
      return {
        ...state,
        selected_shop_group: action.payload,
      };
    case actionTypes.SHOP_LIST_BY_GROUP_ID:
      return {
        ...state,
        loading_users: true,
      };
    case actionTypes.SHOP_LIST_BY_GROUP_ID_SUCCESSFULL:
      let shopIds = [];
      if (action.payload) {
        for (let i = 0; i < action.payload.length; i++) {
          shopIds.push(action.payload[i].ShopId);
        }
      }
      return {
        ...state,
        loading_users: false,
        shop_list_by_group_id: action.payload,
        shop_ids: shopIds,
      };
    case actionTypes.SHOP_LIST_BY_GROUP_ID_FAILED:
      return {
        ...state,
        loading_users: false,
        shop_list_by_group_id: [],
      };
    case actionTypes.SELECTED_SHOP_IDS:
      return {
        ...state,
        selected_shop_ids: action.payload,
      };
    case actionTypes.NEW_SHOP_LIST:
      return {
        ...state,
        new_shop_list: action.payload,
      };
    default:
      return state;
  }
};
export default usersReduser;
