import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";

import SwipeToRevealActions from "react-swipe-to-reveal-actions";
import toast, { Toaster } from "react-hot-toast";
import ListHeader from "../components/ListHeader";
import MenuListItem from "../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../constants/Sizes";
import { main_nav_button_color } from "../constants/Colors";
import {
  getWorksStationsAction,
  selecteWorksStationsAction,
  deleteWorksStationsAction,
  deleteWorksStationsSuccessfullAction,
} from "../redux/WorksStations/Actions";
import Loader from "./Loader";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  changeSelectedButtonBorderColorOnFocus,
  clickedItemAction,
} from "../redux/menu/Actions";
const WorksStationsList = ({ setClickedItem, setChangingPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  let downx;

  const { height, width } = useScrollbarSize();
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { loading_worksStations } = useSelector(
    (state) => state.worksStationsReduser
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const { selected_shop } = useSelector((state) => state.shopReducer);

  const { selected_worksStations_list, worksStation_list } = useSelector(
    (state) => state.worksStationsReduser
  );

  const {
    device_type,
    device_type_id,
    change_selected_button_border_color_on_focus,
    callerId_values,
    callerId_ids,
  } = useSelector((state) => state.categoryList);

  const { lang } = useSelector((state) => state.selectedLanguage);
  const [workStationList, setWorkStationList] = useState([]);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [item1, setItem1] = useState(null);

  const handleClickedItem = (item, key) => {
    setClickedItem(key);
    let new_divice_type = null;
    let new_caller_id = null;
    if (device_type_id.includes(parseInt(item.DeviceType))) {
      let index = device_type_id.indexOf(parseInt(item.DeviceType));
      new_divice_type = {
        value: device_type[index].value,
        label: device_type[index].label,
      };
    }

    if (callerId_ids.includes(parseInt(item.CallerId))) {
      let index = callerId_ids.indexOf(parseInt(item.CallerId));
      new_caller_id = {
        value: callerId_values[index].value,
        label: callerId_values[index].label,
      };
    }

    dispatch(
      selecteWorksStationsAction({
        ...item,
        DeviceType: new_divice_type,
        CallerId: new_caller_id,
      })
    );
  };

  const handleCreateItem = (key) => {
    setClickedItem(key);
    dispatch(selecteWorksStationsAction(null));
  };

  const handleDeletedItem = (item) => {
    dispatch(
      deleteWorksStationsAction(
        login_key,
        user_id,
        item.ShopId,
        item.WorkStationId
      )
    );
  };

  const handleShowDeleteBox = (item) => {
    const copy_list = [...workStationList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.WorkStationId == copy_list[i].WorkStationId) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setWorkStationList(copy_list);
  };

  const handleCancelDeleteBox = (item) => {
    const copy_list = [...workStationList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.WorkStationId == copy_list[i].WorkStationId) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setWorkStationList(copy_list);
  };

  const onPointerMove = (e) => {
    const newx = e.clientX;

    if (newx - downx < -30) {
      ref.current.style.transform = "translate(-60px)";
    } else {
      ref.current.style.transform = "translate(0px)";
    }
  };

  const onpointerDown = (e) => {
    downx = e.clientX;
    ref.current.addEventListener("pointermove", onPointerMove);
  };

  const onPointerUp = () => {
    ref.current.removeEventListener("pointermove", onPointerMove);
  };

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (worksStation_list) {
      if (worksStation_list.length > 0) {
        setWorkStationList(worksStation_list);
      } else {
        setWorkStationList([]);
      }
    } else {
      setWorkStationList([]);
    }
  }, [worksStation_list]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getWorksStationsAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);

    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return width1 <= 560 ? (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ marginBottom: "15px" }}>
        <ListHeader title={lang.workstation_list} show_back={false} />
      </div>
      <div
        className="menu_list_header_container"
        style={{ paddingBottom: "110px" }}
      >
        <div className="margin_menu_normal_horizontal">
          {" "}
          {workStationList.length > 0 ? (
            workStationList.map((item, index) => (
              <div className="mobile-workstation-container-div">
                {" "}
                <SwipeToRevealActions
                  hideDotsButton
                  actionButtons={[
                    {
                      content: (
                        <div>
                          <span>DELETE</span>
                        </div>
                      ),
                      onClick: () => handleShowDeleteBox(item),
                    },
                  ]}
                  actionButtonMinWidth={70}
                >
                  <div
                    className="mobile-workstation-container-details-and-delete-box"
                    style={{ position: "relative", flex: 1 }}
                    onPointerCancel={() => setItem1(item)}
                  >
                    <div
                      className="mobile-workstion-container-item"
                      // ref={ref}
                      onClick={() => handleClickedItem(item, 2)}
                      style={{
                        transform: `translateX(${
                          isExpanded ? "-70px" : "0px"
                        })`,
                        transition: " all 0.25s ease",
                      }}
                    >
                      <p>{item.WorkStationName}</p>
                      <p style={{ color: item.Active ? "green" : "red" }}>
                        {item.Active ? lang.active : lang.de_active}
                      </p>
                    </div>
                    {/* <div className="mobile-workstion-container-delete-button">
                      <button>{lang.delete}</button>
                    </div> */}
                  </div>
                </SwipeToRevealActions>
                {item.showDeleteBox ? (
                  <Dialog open={true} maxWidth="sm" fullWidth>
                    <DialogTitle></DialogTitle>
                    <Box position="absolute" top={0} right={0}>
                      <IconButton>
                        <Close
                          className="mobile-workstation-IconButton"
                          j
                          style={{ fontSize: "27px" }}
                          onClick={() => handleCancelDeleteBox(item)}
                        />
                      </IconButton>
                    </Box>
                    <DialogContent>
                      <Typography style={{ fontSize: "18px" }}>
                        {" "}
                        {lang.delete_message}
                      </Typography>
                    </DialogContent>
                    <DialogActions>
                      <div className=" mobile-workstation-delete-box">
                        <button onClick={() => handleCancelDeleteBox(item)}>
                          {lang.cancel}
                        </button>
                        <button onClick={() => handleDeletedItem(item)}>
                          {lang.ok}
                        </button>
                      </div>
                    </DialogActions>{" "}
                    {loading_worksStations ? <Loader /> : null}
                    <Toaster />
                  </Dialog>
                ) : null}
              </div>
            ))
          ) : (
            <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
              <h4>{lang.noOption}</h4>
              <span
                style={{ width: "172px" }}
                onClick={() => handleCreateItem(3)}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                <p>{lang.create_workstation}</p>
              </span>
            </div>
          )}
        </div>
        <div>
          {/* STICKY ADD SUBMENU BUTTON */}
          {workStationList.length > 0 ? (
            <div
              className="mobile-container-save-button"
              onClick={() => handleCreateItem(3)}
            >
              <button type="">{lang.create_workstation}</button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <div
      className="countaining-div "
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      {/* BACK & TITLE */}
      {/* <ListHeader title={lang.works_station_list} show_back={false} /> */}
      <div className="menu_list_header_container">
        {workStationList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {workStationList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={item.WorkStationName}
                  item_title={item.WorkStationName}
                  btn_number={2}
                  active={item.Active}
                  bt_1_title={lang.main_buttons}
                  bt_1_click={() => {
                    handleClickedItem(item, 4);
                    dispatch(clickedItemAction(4));
                  }}
                  bt_2_title={lang.edit}
                  bt_2_click={() => {
                    handleClickedItem(item, 2);
                    dispatch(clickedItemAction(2));
                  }}
                  show_delete={true}
                  delete_click={() => handleDeletedItem(item)}
                  show_edit_icon={false}
                  selected={selected_worksStations_list}
                  item={item}
                  number={0}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                  show_status={true}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              style={{ width: "172px" }}
              onClick={() => handleCreateItem(3)}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_workstation}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {workStationList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={() => handleCreateItem(3)}
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_workstation}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WorksStationsList;
