import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  getAdyenListAction,
  updateAdyenAction,
} from "../../../../redux/settings/Action";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
const Adyen = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const {
    result_successfull,
    result_message,
    result_failed,
    web_setting_stripe_list,
    loading_appSettings,
    adyen_list,
  } = useSelector((state) => state.appSettingsRedusers);

  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [apiKey, setApiKey] = useState("");
  const [account1, setAccount1] = useState("");
  const [webhook, setWebhook] = useState("");
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "black",
      fontSize: "15px",

      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });
  const handleSave = () => {
    if (apiKey == "" || account1 == "" || webhook == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateAdyenAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          adyen_list[0] ? adyen_list[0].Id : 0,
          webhook,
          apiKey,
          account1
        )
      );
    }
  };
  useImperativeHandle(forwardedRef, () => ({
    saveAyden: () => {
      handleSave();
    },
  }));
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getAdyenListAction(login_key, user_id, selected_shop[0].ShopId)
        );
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (adyen_list) {
      if (adyen_list.length > 0) {
        if (adyen_list[0].ApiKey) {
          setApiKey(adyen_list[0].ApiKey);
        } else {
          setApiKey("");
        }
        if (adyen_list[0].Account) {
          setAccount1(adyen_list[0].Account);
        } else {
          setAccount1("");
        }
        if (adyen_list[0].Webhook) {
          setWebhook(adyen_list[0].Webhook);
        } else {
          setWebhook("");
        }
      } else {
        setWebhook("");
        setAccount1("");
        setApiKey("");
      }
    } else {
      setWebhook("");
      setAccount1("");
      setApiKey("");
    }
  }, [adyen_list]);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());

      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      {" "}
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              // className="general_setting_carrier_bag"
              component="form"
              // sx={{
              //   "& > :not(style)": { height: "9ch" },
              // }}
              // noValidate

              autoComplete="on"
            >
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={1}
                label={`* ${lang.api_key}`}
                value={apiKey}
                defaultValue={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                style={{
                  width: "45%",
                }}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={2}
                label={`* ${lang.account} `}
                value={account1}
                defaultValue={account1}
                onChange={(e) => setAccount1(e.target.value)}
                style={{
                  width: "45%",
                }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box>
          </div>
          <div className="ASOP-div1">
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              // className="general_setting_carrier_bag"
              component="form"
              // sx={{
              //   "& > :not(style)": { height: "9ch" },
              // }}
              // noValidate

              autoComplete="on"
            >
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                key={3}
                label={`* ${lang.webhook}`}
                value={webhook}
                onChange={(e) => setWebhook(e.target.value)}
                style={{
                  width: "45%",
                }}
                autoFocus={focusedInputKey == 3 ? true : false}
                onFocus={() => setFocusedInputKey(3)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Adyen;
