export const Availability = [
  {
    value: 1,
    label: "Always",
  },
  {
    value: 0,
    label: "Selected Hours",
  },
];
export const product_availability_time = [
  {
    value: 1,
    label: "05:00",
    isDisabled: false,
  },
  {
    value: 2,
    label: "05:30",
    isDisabled: false,
  },
  {
    value: 3,
    label: "06:00",
    isDisabled: false,
  },
  {
    value: 4,
    label: "06:30",
    isDisabled: false,
  },
  {
    value: 5,
    label: "07:00",
    isDisabled: false,
  },
  {
    value: 6,
    label: "07:30",
    isDisabled: false,
  },
  {
    value: 7,
    label: "08:00",
    isDisabled: false,
  },
  {
    value: 8,
    label: "08:30",
    isDisabled: false,
  },
  {
    value: 9,
    label: "09:00",
    isDisabled: false,
  },
  {
    value: 10,
    label: "09:30",
    isDisabled: false,
  },
  {
    value: 11,
    label: "10:00",
    isDisabled: false,
  },
  {
    value: 12,
    label: "10:30",
    isDisabled: false,
  },
  {
    value: 13,
    label: "11:00",
    isDisabled: false,
  },
  {
    value: 14,
    label: "11:30",
    isDisabled: false,
  },
  {
    value: 15,
    label: "12:00",
    isDisabled: false,
  },
  {
    value: 16,
    label: "12:30",
    isDisabled: false,
  },
  {
    value: 17,
    label: "13:00",
    isDisabled: false,
  },
  {
    value: 18,
    label: "13:30",
    isDisabled: false,
  },
  {
    value: 19,
    label: "14:00",
    isDisabled: false,
  },
  {
    value: 20,
    label: "14:30",
    isDisabled: false,
  },

  {
    value: 21,
    label: "15:00",
    isDisabled: false,
  },
  {
    value: 22,
    label: "15:30",
    isDisabled: false,
  },
  {
    value: 23,
    label: "16:00",
    isDisabled: false,
  },
  {
    value: 24,
    label: "16:30",
    isDisabled: false,
  },
  {
    value: 25,
    label: "17:00",
    isDisabled: false,
  },
  {
    value: 26,
    label: "17:30",
    isDisabled: false,
  },
  {
    value: 27,
    label: "18:00",
    isDisabled: false,
  },
  {
    value: 28,
    label: "18:30",
    isDisabled: false,
  },
  {
    value: 29,
    label: "19:00",
    isDisabled: false,
  },
  {
    value: 30,
    label: "19:30",
    isDisabled: false,
  },
  {
    value: 31,
    label: "20:00",
    isDisabled: false,
  },
  {
    value: 32,
    label: "20:30",
    isDisabled: false,
  },
  {
    value: 33,
    label: "21:00",
    isDisabled: false,
  },
  {
    value: 34,
    label: "21:30",
    isDisabled: false,
  },
  {
    value: 35,
    label: "22:00",
    isDisabled: false,
  },
  {
    value: 36,
    label: "22:30",
    isDisabled: false,
  },
  {
    value: 37,
    label: "23:00",
    isDisabled: false,
  },
  {
    value: 38,
    label: "23:30",
    isDisabled: false,
  },
  {
    value: 39,
    label: "23:59",
    isDisabled: false,
  },
  {
    value: 40,
    label: "01:00 after midnight",
    isDisabled: false,
  },
  {
    value: 41,
    label: "01:30 after midnight",
    isDisabled: false,
  },
  {
    value: 42,
    label: "02:00 after midnight",
    isDisabled: false,
  },
  {
    value: 43,
    label: "02:30 after midnight",
    isDisabled: false,
  },
  {
    value: 44,
    label: "03:00 after midnight",
    isDisabled: false,
  },
  {
    value: 45,
    label: "03:30 after midnight",
    isDisabled: false,
  },
  {
    value: 46,
    label: "04:00 after midnight",
    isDisabled: false,
  },
  {
    value: 47,
    label: "04:30 after midnight",
    isDisabled: false,
  },
  {
    value: 48,
    label: "04:59 after midnight",
    isDisabled: false,
  },
];

export const promotion_type_list = [
  {
    value: 1000106,
    label: "Collection Only",
  },
  {
    value: 1000105,
    label: "Delivery Only",
  },
  {
    value: 1,
    label: "Delivery & Collection ",
  },
];

export const promotion_rate_option_list = [
  {
    value: 0,
    label: "Fixed",
  },
  {
    value: 1,
    label: "Percentage",
  },
];
