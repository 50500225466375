export const english_divices_menu = [
  {
    id: 1,
    title: "Devices",
    icon: "fa fa-user",
    expanded: false,
    path: "",
    sublist: [],
    access_id: 40,
  },
  {
    id: 2,
    title: "Licence",
    icon: "fa fa-user-circle-o",
    expanded: false,
    path: "licence",
    sublist: [],
    isDisabled: false,
    access_id: 41,
  },
  {
    id: 3,
    title: "Second Screen",
    icon: "fa fa-desktop",
    expanded: false,
    path: "secondscreen",
    sublist: [],
    isDisabled: false,
    access_id: 42,
  },
];
export const turkish_divices_menu = [];
export const persian_divices_menu = [];
