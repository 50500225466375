import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllAppSettingsAction,
  updateShopSettingAppOrderProcessAction,
} from "../../../../redux/settings/Action";
import { primaryColor } from "../../../../constants/Colors";
const OrderProccess = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();

  const { setting_app_orderprocess } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);

  const [totalHalfText, setTotalHalfText] = useState("");
  const [prefixNoForseSubmenu, setPrefixNoForseSubmenu] = useState("");
  const [prefixYesForseSubmenu, setPrefixYesForseSubmenu] = useState("");
  const [useTableArea, setUseTableArea] = useState(0);
  const [showTiplnEatIn, setShowTiplnEatIn] = useState(0);
  const [askNumberGuests, setAskNumberGuests] = useState(0);
  const [showTiplnTakeaway, setShowTiplnTakeaway] = useState(0);
  const [askCustomerOnTakaway, setAskCustomerOnTakaway] = useState(0);
  const [askCustomerOnCollection, setAskCustomerOnCollection] = useState(0);
  const [
    usersNeedToLoginBeforeTakingOrder,
    setUsersNeedToLoginBeforeTakingOrder,
  ] = useState(true);

  const handleSaveClicked = () => {
    dispatch(
      updateShopSettingAppOrderProcessAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        prefixYesForseSubmenu.replaceAll(`"`, `\"\"`),
        prefixNoForseSubmenu.replaceAll(`"`, `\"\"`),
        totalHalfText.replaceAll(`"`, `\"\"`),
        usersNeedToLoginBeforeTakingOrder ? 1 : 0,
        useTableArea ? 1 : 0,
        askNumberGuests ? 1 : 0,
        askCustomerOnCollection ? 1 : 0,
        askCustomerOnTakaway ? 1 : 0,
        showTiplnEatIn ? 1 : 0,
        showTiplnTakeaway ? 1 : 0
      )
    );
  };

  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleSaveClicked();
    },
  }));

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    prefixYesForseSubmenu,
    prefixNoForseSubmenu,
    totalHalfText,
    usersNeedToLoginBeforeTakingOrder,
    useTableArea,
    askNumberGuests,
    askCustomerOnCollection,
    askCustomerOnTakaway,
    showTiplnEatIn,
    showTiplnTakeaway,
  ]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getAllAppSettingsAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (setting_app_orderprocess.length > 0) {
      setPrefixYesForseSubmenu(setting_app_orderprocess[0].PrefixYesForSubmenu);
      setPrefixNoForseSubmenu(setting_app_orderprocess[0].PrefixNoForSubmenu);
      setTotalHalfText(setting_app_orderprocess[0].TotalHalfText);
      setUsersNeedToLoginBeforeTakingOrder(
        setting_app_orderprocess[0].UsersNeedToLoginBeforeTakingOrder
      );
      setUseTableArea(setting_app_orderprocess[0].UseTableArea);
      setAskNumberGuests(setting_app_orderprocess[0].AskNumberGuests);
      setAskCustomerOnCollection(
        setting_app_orderprocess[0].AskCustomerOnCollection
      );
      setAskCustomerOnTakaway(
        setting_app_orderprocess[0].AskCustomerOnTakeaway
      );
      setShowTiplnEatIn(setting_app_orderprocess[0].ShowTipInEatIn);
      setShowTiplnTakeaway(setting_app_orderprocess[0].ShowTipInTakeaway);
    }
  }, [setting_app_orderprocess]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container_tabel_list">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.total_half_text}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={totalHalfText}
                onChange={(e) => setTotalHalfText(e.target.value)}
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.prefix_no_forse_submenu}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={prefixNoForseSubmenu}
                onChange={(e) => setPrefixNoForseSubmenu(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.prefix_yes_forse_submenu}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={prefixYesForseSubmenu}
                onChange={(e) => setPrefixYesForseSubmenu(e.target.value)}
              />
            </div>
          </div>
          {/* *** */}

          {/* INPUT CHECKBOX 3 */}
          <div className="ASOP-div2">
            <div className=" div-chechbox-p">
              {/* <input
                id="check53"
                type="checkbox"
                checked={askCustomerOnTakaway}
                onChange={(e) => setAskCustomerOnTakaway(!askCustomerOnTakaway)}
              />
              <label for="check53" className="checkmark"></label>
              <h6 className="h6"> {lang.ask_customer_on_takaway}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={askCustomerOnTakaway}
                      onChange={(e) =>
                        setAskCustomerOnTakaway(!askCustomerOnTakaway)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.ask_customer_on_takaway}
                />
              </FormGroup>
            </div>

            <div className=" div-chechbox-p" style={{ marginRight: "-15px" }}>
              {/* <input
                id="check54"
                type="checkbox"
                checked={askCustomerOnCollection}
                onChange={(e) =>
                  setAskCustomerOnCollection(!askCustomerOnCollection)
                }
              />
              <label for="check54" className="checkmark"></label>
              <h6 className="h6"> {lang.ask_customer_on_collection}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={askCustomerOnCollection}
                      onChange={(e) =>
                        setAskCustomerOnCollection(!askCustomerOnCollection)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.ask_customer_on_collection}
                />
              </FormGroup>
            </div>

            <div className=" div-chechbox-p " style={{ marginRight: "11px" }}>
              {/* <input
                id="check55"
                type="checkbox"
                checked={usersNeedToLoginBeforeTakingOrder}
                onChange={(e) =>
                  setUsersNeedToLoginBeforeTakingOrder(
                    !usersNeedToLoginBeforeTakingOrder
                  )
                }
              />
              <label for="check55" className="checkmark"></label>
              <h6 className="h6">
                {lang.users_need_to_login_before_taking_order}

              </h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usersNeedToLoginBeforeTakingOrder}
                      onChange={(e) =>
                        setUsersNeedToLoginBeforeTakingOrder(
                          !usersNeedToLoginBeforeTakingOrder
                        )
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.user_lock_screen}
                />
              </FormGroup>
            </div>
          </div>
          {/* *** */}

          {/* INPUT  CHECKBOX 4 */}
          <div className="ASOP-div3">
            <div className=" div-chechbox-p">
              {/* <input
                id="check56"
                type="checkbox"
                checked={useTableArea}
                onChange={(e) => setUseTableArea(!useTableArea)}
              />
              <label for="check56" className="checkmark"></label>
              <h6 className="h6"> {lang.use_table_area}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useTableArea}
                      onChange={(e) => setUseTableArea(!useTableArea)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.use_table_area}
                />
              </FormGroup>
            </div>
            <div className=" div-chechbox-p">
              {/* <input
                id="check57"
                type="checkbox"
                checked={showTiplnEatIn}
                onChange={(e) => setShowTiplnEatIn(!showTiplnEatIn)}
              />
              <label for="check57" className="checkmark"></label>
              <h6 className="h6">{lang.show_tipln_eatIn}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showTiplnEatIn}
                      onChange={(e) => setShowTiplnEatIn(!showTiplnEatIn)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_tipln_eatIn}
                />
              </FormGroup>
            </div>
            <div className=" div-chechbox-p">
              {/* <input
                id="check58"
                type="checkbox"
                checked={askNumberGuests}
                onChange={(e) => setAskNumberGuests(!askNumberGuests)}
              />
              <label for="check58" className="checkmark"></label>
              <h6 className="h6">{lang.ask_number_guests}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={askNumberGuests}
                      onChange={(e) => setAskNumberGuests(!askNumberGuests)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.ask_number_guests}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div3-1 div-chechbox-p">
              {/* <input
                id="check59"
                type="checkbox"
                checked={showTiplnTakeaway}
                onChange={(e) => setShowTiplnTakeaway(!showTiplnTakeaway)}
              />
              <label for="check59" className="checkmark"></label>
              <h6 className="h6">{lang.show_tipln_takeaway}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showTiplnTakeaway}
                      onChange={(e) => setShowTiplnTakeaway(!showTiplnTakeaway)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_tipln_takeaway}
                />
              </FormGroup>
            </div>
          </div>
          {/* *** */}
        </div>
      </div>
    </div>
  );
});

export default OrderProccess;
