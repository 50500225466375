import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePopperTooltip } from "react-popper-tooltip";
import store from "store2";
import InputAdornment from "@mui/material/InputAdornment";
// import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import {
  showMessageSupportPopupAction,
  getMessageSupportListAction,
  updateMessageSupportAction,
} from "../redux/shop/Actions";
import { setDefaultAction } from "../redux/menu/Actions";
import {
  main_nav_button_color,
  primaryColor,
  white,
} from "../constants/Colors";
import image from "../AssetsImages/icons8-send-48.png";
import Loader from "./Loader";
import moment from "moment";
const ShopMessageSupportPopup = () => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      closeOnOutsideClick: true,
    });
  const dispatch = useDispatch();
  const {
    selected_shop_status,
    get_message_support_list,
    show_message_support_popup_loader,
    result_successfull,
    result_failed,
    result_message,
    show_message_support_popup,
  } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [messageSupportList, setMessageSupportList] = useState([]);
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [loginToPgae, SetLoginToPage] = useState(false);
  const loging_response = store.get("login_response");
  const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: "white",
          },

          "&$focused $notchedOutline": {
            borderColor: "white",
          },
        },
        notchedOutline: {
          borderColor: "white",
        },
      },
    },
  });

  const handleChangeUserMessage = (e, item) => {
    const copy_messageSupportList = [...messageSupportList];
    if (copy_messageSupportList) {
      if (copy_messageSupportList.length > 0) {
        for (let i = 0; i < copy_messageSupportList.length; i++) {
          if (item.Id == copy_messageSupportList[i].Id) {
            copy_messageSupportList[i].UserAnswer = e.target.value;
          }
        }
      }
    }
    setMessageSupportList(copy_messageSupportList);
  };
  const handleShowEditBox = (item) => {
    const copy_messageSupportList = [...messageSupportList];
    if (copy_messageSupportList) {
      if (copy_messageSupportList.length > 0) {
        for (let i = 0; i < copy_messageSupportList.length; i++) {
          if (item.Id == copy_messageSupportList[i].Id) {
            copy_messageSupportList[i].show_edit_box = true;
          } else {
            copy_messageSupportList[i].show_edit_box = false;
          }
        }
      }
    }
    setMessageSupportList(copy_messageSupportList);
  };
  const handleCloseEditBox = () => {
    const copy_messageSupportList = [...messageSupportList];
    if (copy_messageSupportList) {
      if (copy_messageSupportList.length > 0) {
        for (let i = 0; i < copy_messageSupportList.length; i++) {
          if (copy_messageSupportList[i].show_edit_box) {
            copy_messageSupportList[i].show_edit_box = false;

            dispatch(
              getMessageSupportListAction(
                login_key,
                user_id,
                selected_shop_status.ShopId
              )
            );
          }
        }
      }
    }
    setMessageSupportList(copy_messageSupportList);
  };
  const handleSendAnswer = (item) => {
    if (item.UserAnswer != "") {
      dispatch(
        updateMessageSupportAction(
          login_key,
          user_id,
          selected_shop_status.ShopId,
          item.Id,
          item.UserAnswer
        )
      );
    } else {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };

  useEffect(() => {
    if (get_message_support_list) {
      if (get_message_support_list.length > 0) {
        setMessageSupportList(get_message_support_list);
      } else {
        setMessageSupportList();
      }
    } else {
      setMessageSupportList([]);
    }
  }, [get_message_support_list]);

  useEffect(() => {
    SetLoginToPage(true);
    return () => {
      SetLoginToPage(false);
    };
  }, []);
  useEffect(() => {
    if (loginToPgae) {
      document.getElementById("id").scroll({
        top: document.querySelector("#id").scrollHeight,
        behavior: "smooth",
      });
    }
  }, [loginToPgae]);
  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showMessageSupportPopupAction(false))}
    >
      <div
        className="create_shop_popup_content_container shop_message_support_list_container"
        id="id"
        onClick={(i) => {
          i.stopPropagation();
          handleCloseEditBox();
        }}
      >
        <div className="shop_message_support_container">
          <div className="shop_message_support_container_header">
            <h5> {lang.messages}</h5>
          </div>
          <div className="shop_message_support_container_list">
            {messageSupportList
              ? messageSupportList.length > 0
                ? messageSupportList.map((item, index) => (
                    <div className="shop_message_support_container_items">
                      <div className="shop_message_support_container_items_header_section">
                        <div className="shop_message_support_container_items_image_section">
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrAJXYVvgQ3IzaDqi24kE9uWI4tB1De2R8Og&usqp=CAU"
                            ref={setTriggerRef}
                          ></img>
                        </div>
                        <div className="shop_message_support_container_items_info_section">
                          <h6 style={{ margin: 0 }}> {item.StaffName}</h6>
                          <p>
                            {moment(item.DateSend).format("YYYY-MM-DD HH:mm")}
                          </p>
                        </div>
                      </div>
                      <div className="shop_message_support_container_items_question_section">
                        <h6 className="shop_message_support_h6">
                          {item.MessageSupport}
                        </h6>

                        {item.hasـanـanswer ? (
                          <div className="shop_message_support_container_answered_section">
                            <div className="shop_message_support_container_answer_header">
                              <div className="shop_message_support_container_items_image_section">
                                <img
                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrAJXYVvgQ3IzaDqi24kE9uWI4tB1De2R8Og&usqp=CAU"
                                  ref={setTriggerRef}
                                ></img>
                              </div>
                              <div className="shop_message_support_container_answer_header_info">
                                <h6 style={{ margin: 0 }}>
                                  {" "}
                                  {item.SupportName}
                                </h6>
                                <p>
                                  {moment(item.AnswerDate).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </p>
                              </div>
                            </div>
                            {item.show_edit_box ? (
                              <div
                                className="shop_message_support_container_answer_MuiThemeProvider"
                                onClick={(i) => i.stopPropagation()}
                              >
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    key={item.Id}
                                    multiline
                                    size="small"
                                    maxRows={10}
                                    margin="none"
                                    style={{
                                      width: "100%",
                                    }}
                                    variant="outlined"
                                    label=""
                                    autoFocus={
                                      focusedInputKey == item.Id ? true : false
                                    }
                                    onFocus={() => setFocusedInputKey(item.Id)}
                                    value={item.UserAnswer}
                                    onChange={(e) =>
                                      handleChangeUserMessage(e, item)
                                    }
                                  />
                                </MuiThemeProvider>

                                <div
                                  className="shop_message_support_container_send_image"
                                  onClick={() => handleSendAnswer(item)}
                                >
                                  <img src={image} />
                                </div>
                              </div>
                            ) : (
                              <div className="shop_message_support_container_typed_answer">
                                <div className="shop_message_support_container_typed_answer_div">
                                  <h6>{item.UserAnswer}</h6>
                                </div>
                                {loging_response.UserAccesses[0].UserName ==
                                item.SupportName ? (
                                  <i
                                    class="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={(i) => {
                                      i.stopPropagation();
                                      handleShowEditBox(item);
                                    }}
                                  ></i>
                                ) : null}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="shop_message_support_container_answer_MuiThemeProvider2">
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                key={item.Id}
                                multiline
                                size="small"
                                maxRows={10}
                                margin="none"
                                style={{ width: "100%" }}
                                variant="outlined"
                                label=""
                                autoFocus={
                                  focusedInputKey == item.Id ? true : false
                                }
                                onFocus={() => setFocusedInputKey(item.Id)}
                                value={item.UserAnswer}
                                onChange={(e) =>
                                  handleChangeUserMessage(e, item)
                                }
                                floatingLabelStyle={{ color: "red" }}
                                placeholder="Answer"
                              />
                            </MuiThemeProvider>

                            <div
                              className="shop_message_support_container_send_image"
                              onClick={() => handleSendAnswer(item)}
                            >
                              <img src={image} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                : null
              : null}
          </div>
        </div>
        <Toaster />
      </div>
      {/* {show_message_support_popup_loader ? <Loader /> : null} */}
    </div>
  );
};

export default ShopMessageSupportPopup;
