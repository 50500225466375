import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import { Navigate, useNavigate } from "react-router-dom";

import { changeSelectedButtonBorderColorOnFocus } from "../redux/menu/Actions";
import ListHeader from "../components/ListHeader";
import MenuListItem from "../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../constants/Sizes";
import { main_nav_button_color } from "../constants/Colors";
import { getUsersListAction, deleteUsersAction } from "../redux/Users/Actions";

import { selectedUsersEmployeesAction } from "../redux/Users/Actions";
const Employees = ({ setClickedItem }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { height, width } = useScrollbarSize();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { employees_list, selected_users_list_employees } = useSelector(
    (state) => state.usersReduser
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    dashboard_accessUser,
    dashboard_accessUser_id,
    dashboard_shop_group,
    dashboard_shop_group_id,
    change_selected_button_border_color_on_focus,
  } = useSelector((state) => state.categoryList);

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const [employeeList, setEmployeeList] = useState([]);
  const handleClickedItem = (item, key) => {
    let shopGroup_list = [];

    let DashboardAccessUserList = [];
    let DashboardShopGroupList = [];
    if (item.access) {
      for (let i = 0; i < item.access.length; i++) {
        if (item.access[i].AccessGroupId == "1") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 1) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 1,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "2") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 2) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 2,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "3") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 3) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 3,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "4") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 4) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 4,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "5") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 5) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 5,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "6") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 6) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 6,
              });
            }
          }
        } else if (item.access[i].AccessGroupId == "7") {
          for (let index = 0; index < dashboard_accessUser.length; index++) {
            if (dashboard_accessUser[index].value == 7) {
              DashboardAccessUserList.push({
                label: dashboard_accessUser[index].label,
                value: 7,
              });
            }
          }
        }
      }
    }
    if (item.ShopGroupId) {
      shopGroup_list = item.ShopGroupId.split(",");

      for (let i = 0; i < shopGroup_list.length; i++) {
        if (dashboard_shop_group_id.includes(parseInt(shopGroup_list[i]))) {
          let index = dashboard_shop_group_id.indexOf(
            parseInt(shopGroup_list[i])
          );

          DashboardShopGroupList.push({
            value: dashboard_shop_group[index].value,
            label: dashboard_shop_group[index].label,
          });
        }
      }
    }

    dispatch(
      selectedUsersEmployeesAction({
        ...item,
        access: DashboardAccessUserList,
        ShopGroupId: DashboardShopGroupList,
      })
    );
    setClickedItem(key);
  };
  const handleDeleteItem = (item) => {
    dispatch(deleteUsersAction(login_key, user_id, item.UserId, item.UserType));
  };

  const handleCreateItem = (key) => {
    setClickedItem(key);
    dispatch(selectedUsersEmployeesAction(null));
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...employeeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.UserId == copy_list[i].UserId) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setEmployeeList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...employeeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.UserId == copy_list[i].UserId) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setEmployeeList(copy_list);
  };

  useEffect(() => {
    dispatch(getUsersListAction(login_key, user_id, 10001203));
  }, []);

  useEffect(() => {
    let employee_list = [];
    if (employees_list.length > 0) {
      for (let i = 0; i < employees_list.length; i++) {
        employee_list.push({
          ...employees_list[i],
          showDeleteBox: false,
        });
      }

      setEmployeeList(employee_list);
    }
  }, [employees_list]);
  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  return (
    <div
      className="countaining-div"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      {/* BACK & TITLE */}
      <ListHeader title={lang.employees_list} show_back={false} />
      <div className="menu_list_header_container">
        {employeeList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {employeeList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={`${item.FirstName} ${item.LastName}`}
                  item_title={`${item.FirstName} ${item.LastName}`}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleClickedItem(item, 2)}
                  show_delete={true}
                  delete_click={() => handleDeleteItem(item)}
                  show_edit_icon={false}
                  selected={selected_users_list_employees}
                  item={item}
                  number={0}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              style={{ width: "160px" }}
              onClick={() => handleCreateItem(2)}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_employee}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {employeeList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={() => handleCreateItem(2)}
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_employee}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Employees;
