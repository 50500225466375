import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Carousel, { consts } from "react-elastic-carousel";
import toast, { Toaster } from "react-hot-toast";
import { usePopperTooltip } from "react-popper-tooltip";
import { image_Url } from "../../../../constants/BaseUrl";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  showSecondScreenImageDetailsPopupAction,
  getSecondScreenImagesListAction,
  createSecondScreenImagesAction,
  selectedSecondScreenImageAction,
  secondScreenImageDeletePopupAction,
  updateSecondScreenEachPhotoSettingAction,
} from "../../../../redux/settings/Action";
import Loader from "../../../../components/Loader";
import {
  generalSecondScreenCreatePopupAction,
  selectedScreenAction,
} from "../../../../redux/devices/Actions";
import {
  white,
  primaryColor,
  main_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../../../../constants/Colors";
import { Navigate, useNavigate } from "react-router-dom";
const SecondScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [href, setHref] = useState("");
  const [image, setImage] = useState("");
  const [active, setActive] = useState(true);
  const [sort, setSort] = useState("");
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  const [imagesList, setImagesList] = useState([]);
  const [srcDimensions, setSrcDimensions] = useState({});
  const [secondScreenImagesList, setSecondScreenImagesList] = useState([]);
  const [domain, setDomain] = useState("");
  const [duration, setDuration] = useState(2);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const type = consts.PREV;
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const {
    loading_appSettings,
    second_full_screen_images_list,
    second_half_screen_images_list,
    selected_second_screen_image,
  } = useSelector((state) => state.appSettingsRedusers);

  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 330, itemsToShow: 1, itemsToScroll: 1, pagination: false },
    { width: 450, itemsToShow: 1, itemsToScroll: 1, pagination: false },
    { width: 580, itemsToShow: 2, itemsToScroll: 1, pagination: false },
    { width: 800, itemsToShow: 2, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 3, pagination: false, itemsToScroll: 1 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 1, pagination: false },
  ];
  const onSelectPicture = (e) => {
    let images_list = [];
    if (imagesList.length > 0) {
      images_list = imagesList;
    } else {
      images_list = [];
    }
    setImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    images_list.push({ url: URL.createObjectURL(e.target.files[0]) });
    setImagesList(images_list);
  };

  const imageUrl = href;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setSrcDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const handleDeleteImage = (item) => {
    dispatch(generalSecondScreenCreatePopupAction(false));
    dispatch(secondScreenImageDeletePopupAction(true));
  };

  const hanldeEdit = () => {
    dispatch(
      updateSecondScreenEachPhotoSettingAction(
        login_key,
        user_id,
        selected_second_screen_image.ShopId,
        selected_second_screen_image.Id,
        sort ? sort : 0,
        active ? 1 : 0,
        duration ? duration : 2
      )
    );
  };

  const formData = new FormData();
  const handleCreateNewImage = () => {
    let width = srcDimensions.height * 0.75;
    let width1 = srcDimensions.height * 0.8;
    if (image == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.choose_potho}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      if (srcDimensions.width >= width && srcDimensions.width <= width1) {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("ShopId", selected_shop[0].ShopId);
        formData.append("SortId", 0);
        formData.append("Active", 1);
        formData.append("ImageFile", image ? image : "");
        dispatch(
          createSecondScreenImagesAction(
            formData,
            login_key,
            user_id,
            selected_shop[0].ShopId
          )
        );
      } else {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.second_screen_width_height}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      }
    }
  };
  const hendleItemClicked = (key, path) => {
    setSelectedItemId(key);
    // navigate(path);
  };
  const handlCarouselOnchange = (item) => {
    let copy_secondScreenImagesList = [...secondScreenImagesList];
    if (copy_secondScreenImagesList) {
      if (copy_secondScreenImagesList.length > 0) {
        for (let i = 0; i < copy_secondScreenImagesList.length; i++) {
          if (item.index == i) {
            dispatch(
              selectedSecondScreenImageAction(copy_secondScreenImagesList[i])
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        const image_href = selected_shop[0].Domain;
        dispatch(
          getSecondScreenImagesListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId
          )
        );
        setDomain(image_href);
      }
    }
  }, [selected_shop, selectedItemId]);

  useEffect(() => {
    if (selectedItemId == 1) {
      if (second_full_screen_images_list) {
        if (second_full_screen_images_list.length > 0) {
          setSecondScreenImagesList(second_full_screen_images_list);
          for (let i = 0; i < second_full_screen_images_list.length; i++) {
            if (activeImageIndex == i) {
              dispatch(
                selectedSecondScreenImageAction(
                  second_full_screen_images_list[i]
                )
              );
            }
          }
        } else {
          setSecondScreenImagesList([]);
        }
      } else {
        setSecondScreenImagesList([]);
      }
    } else {
      if (second_half_screen_images_list) {
        if (second_half_screen_images_list.length > 0) {
          setSecondScreenImagesList(second_half_screen_images_list);
          for (let i = 0; i < second_half_screen_images_list.length; i++) {
            if (activeImageIndex == i) {
              dispatch(
                selectedSecondScreenImageAction(
                  second_half_screen_images_list[i]
                )
              );
            }
          }
        } else {
          setSecondScreenImagesList([]);
        }
      } else {
        setSecondScreenImagesList([]);
      }
    }
  }, [second_full_screen_images_list, second_half_screen_images_list]);

  useEffect(() => {
    if (selected_second_screen_image) {
      setActive(selected_second_screen_image.Active);
      setSort(selected_second_screen_image.SortId);
      setDuration(selected_second_screen_image.Duration);
    }
  }, [selected_second_screen_image]);

  useEffect(() => {
    if (secondScreenImagesList) {
      if (secondScreenImagesList.length > 0) {
        dispatch(selectedSecondScreenImageAction(secondScreenImagesList[0]));
      }
    }
  }, []);
  useEffect(() => {
    dispatch(selectedScreenAction(1));
  }, []);

  return (
    <div
      className="countaining-div "
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      {" "}
      <div
        className="menu_list_header_container setting-second-screen"
        id="container"
        style={{ height: "100%" }}
      >
        {" "}
        <div className="second-screnn-sidbar">
          <button
            type=""
            key={1}
            onClick={() => {
              hendleItemClicked(1, "halfscreen");
              dispatch(selectedScreenAction(1));
            }}
            style={{
              backgroundColor:
                selectedItemId == 1 ? primaryColor : main_nav_back_color,
              color: selectedItemId == 1 ? white : "gray",
              borderWidth: selectedItemId == 1 ? 0 : 0.3,
            }}
          >
            Full screen
          </button>
          <button
            type=""
            key={2}
            onClick={() => {
              hendleItemClicked(2, "halfscreen");
              dispatch(selectedScreenAction(0));
            }}
            style={{
              backgroundColor:
                selectedItemId == 2 ? primaryColor : main_nav_back_color,
              color: selectedItemId == 2 ? white : "gray",
              borderWidth: selectedItemId == 2 ? 0 : 0.3,
            }}
          >
            {" "}
            Half screen
          </button>
        </div>
        <div
          // className="margin_menu_normal_horizontal "
          style={{ height: "100%", flex: 1 }}
        >
          {selectedItemId == 1 ? (
            secondScreenImagesList ? (
              secondScreenImagesList.length > 0 ? (
                <>
                  <div className="second-screen-container">
                    <div className="second-full-screen-container-images-slider">
                      {" "}
                      <Carousel
                        breakPoints={breakPoints}
                        itemsToShow={1}
                        className="second-screen-carousel"
                        onChange={(item) => {
                          handlCarouselOnchange(item);
                          setActiveImageIndex(item.index);
                        }}
                        initialActiveIndex={0}
                      >
                        {secondScreenImagesList.map((item, index) => (
                          <div
                            key={index}
                            className=" second-full-screen-container-images-list"
                          >
                            <img
                              src={`${image_Url}${domain}/${item.ImageUrl}`}
                              alt=""
                            />
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <div className="second-full-screen-container-settings">
                      <div className=" second-full-screen-container-box-checkbox12 ">
                        <Box
                          component="form"
                          // sx={{
                          //   "& > :not(style)": { m: 1, display: "flex" },
                          // }}
                          className="second-screen-image-popup-box"
                          // className="menu-list-container-inputs"
                          autoComplete="on"
                        >
                          <TextField
                            key={1}
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "45px",
                                color: "black",

                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            label={lang.sort}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,
                                "&.MuiInputLabel-shrink": { top: 0 },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                                "& label.Mui-focused": {
                                  color: primaryColor,
                                },
                                "&:hover label": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            value={sort}
                            // defaultValue={number == 9 ? postCode : appTitle}
                            onChange={(e) => setSort(e.target.value)}
                            autoFocus={focusedInputKey == 1 ? true : false}
                            onFocus={() => setFocusedInputKey(1)}
                          />
                          <TextField
                            key={2}
                            sx={{
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontSize: "15px",
                                height: "45px",
                                color: "black",

                                "& fieldset": {
                                  borderColor: "grey",
                                },
                                "&:hover fieldset": {
                                  borderColor: primaryColor,
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: primaryColor,
                                },
                              },
                            }}
                            size="small"
                            label={lang.duration}
                            InputLabelProps={{
                              sx: {
                                fontSize: 16,
                                // top: -7,
                                "&.MuiInputLabel-shrink": { top: 0 },
                                "&.Mui-focused": {
                                  color: primaryColor,
                                },
                                "& label.Mui-focused": {
                                  color: primaryColor,
                                },
                                "&:hover label": {
                                  color: primaryColor,
                                },
                              },
                            }}
                            value={duration}
                            // defaultValue={number == 9 ? postCode : appTitle}
                            onChange={(e) => setDuration(e.target.value)}
                            autoFocus={focusedInputKey == 2 ? true : false}
                            onFocus={() => setFocusedInputKey(2)}
                          />
                        </Box>
                        <FormGroup className="second-screen-container-checkbox-formGroup">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={active}
                                onChange={() => setActive(!active)}
                                sx={{
                                  "&.Mui-checked": {
                                    color: primaryColor,
                                  },
                                }}
                              />
                            }
                            label={lang.active}
                          />
                        </FormGroup>
                      </div>
                      <div className="  second-full-screen-container-all-buttons">
                        {" "}
                        <div className="second-screen-container-buttons">
                          <button type="" onClick={() => hanldeEdit()}>
                            {lang.save}
                          </button>
                          <button type="" onClick={() => handleDeleteImage()}>
                            {lang.delete}
                          </button>
                        </div>
                        <div className="second-screen-container-add-new-image">
                          <button
                            type=""
                            onClick={() => {
                              dispatch(
                                showSecondScreenImageDetailsPopupAction(true)
                              );

                              dispatch(
                                generalSecondScreenCreatePopupAction(false)
                              );
                            }}
                          >
                            {" "}
                            {lang.add_new_image}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="second-screen-container-noImage-button">
                  <div className="second-screen-container-container-create-button">
                    <button
                      type=""
                      onClick={() =>
                        dispatch(showSecondScreenImageDetailsPopupAction(true))
                      }
                    >
                      <i className="fas fa-camera"></i> {lang.add_image}{" "}
                    </button>
                  </div>
                </div>
              )
            ) : null
          ) : secondScreenImagesList ? (
            secondScreenImagesList.length > 0 ? (
              <>
                <div className="second-screen-container">
                  <div className=" second-half-screen-container-images-slider">
                    {" "}
                    <Carousel
                      breakPoints={breakPoints}
                      itemsToShow={1}
                      className="second-screen-carousel"
                      // onNextEnd={() =>
                      //   secondScreenImagesList.length > 0
                      //     ? secondScreenImagesList.map((item, index) =>
                      //         console.log("item", item)
                      //       )
                      //     : null
                      // }
                      onChange={(item) => {
                        handlCarouselOnchange(item);
                        setActiveImageIndex(item.index);
                      }}
                      initialActiveIndex={0}
                    >
                      {secondScreenImagesList.map((item, index) => (
                        <div
                          key={index}
                          className="second-half-screen-container-images-list"
                        >
                          <img
                            src={`${image_Url}${domain}/${item.ImageUrl}`}
                            alt=""
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <div className=" second-half-screen-container-settings">
                    <div className=" second-half-screen-container-box-checkbox12">
                      <Box
                        component="form"
                        // sx={{
                        //   "& > :not(style)": { m: 1, display: "flex" },
                        // }}
                        className="second-screen-image-popup-box"
                        // className="menu-list-container-inputs"
                        autoComplete="on"
                      >
                        <TextField
                          key={1}
                          sx={{
                            "& label.Mui-focused": {
                              color: primaryColor,
                            },
                            "&:hover label": {
                              color: primaryColor,
                            },
                            "& .MuiOutlinedInput-root": {
                              fontSize: "15px",
                              height: "45px",
                              color: "black",

                              "& fieldset": {
                                borderColor: "grey",
                              },
                              "&:hover fieldset": {
                                borderColor: primaryColor,
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: primaryColor,
                              },
                            },
                          }}
                          size="small"
                          label={lang.sort}
                          InputLabelProps={{
                            sx: {
                              fontSize: 16,
                              // top: -7,
                              "&.MuiInputLabel-shrink": { top: 0 },
                              "&.Mui-focused": {
                                color: primaryColor,
                              },
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                            },
                          }}
                          value={sort}
                          // defaultValue={number == 9 ? postCode : appTitle}
                          onChange={(e) => setSort(e.target.value)}
                          autoFocus={focusedInputKey == 1 ? true : false}
                          onFocus={() => setFocusedInputKey(1)}
                        />
                        <TextField
                          key={2}
                          sx={{
                            "& label.Mui-focused": {
                              color: primaryColor,
                            },
                            "&:hover label": {
                              color: primaryColor,
                            },
                            "& .MuiOutlinedInput-root": {
                              fontSize: "15px",
                              height: "45px",
                              color: "black",

                              "& fieldset": {
                                borderColor: "grey",
                              },
                              "&:hover fieldset": {
                                borderColor: primaryColor,
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: primaryColor,
                              },
                            },
                          }}
                          size="small"
                          label={lang.duration}
                          InputLabelProps={{
                            sx: {
                              fontSize: 16,
                              // top: -7,
                              "&.MuiInputLabel-shrink": { top: 0 },
                              "&.Mui-focused": {
                                color: primaryColor,
                              },
                              "& label.Mui-focused": {
                                color: primaryColor,
                              },
                              "&:hover label": {
                                color: primaryColor,
                              },
                            },
                          }}
                          value={duration}
                          // defaultValue={number == 9 ? postCode : appTitle}
                          onChange={(e) => setDuration(e.target.value)}
                          autoFocus={focusedInputKey == 2 ? true : false}
                          onFocus={() => setFocusedInputKey(2)}
                        />
                      </Box>
                      <FormGroup className="second-screen-container-checkbox-formGroup">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={active}
                              onChange={() => setActive(!active)}
                              sx={{
                                "&.Mui-checked": {
                                  color: primaryColor,
                                },
                              }}
                            />
                          }
                          label={lang.active}
                        />
                      </FormGroup>
                    </div>
                    <div className="  second-half-screen-container-all-buttons">
                      {" "}
                      <div className="second-screen-container-buttons">
                        <button type="" onClick={() => hanldeEdit()}>
                          {lang.save}
                        </button>
                        <button type="" onClick={() => handleDeleteImage()}>
                          {lang.delete}
                        </button>
                      </div>
                      <div className="second-screen-container-add-new-image">
                        <button
                          type=""
                          onClick={() => {
                            dispatch(
                              showSecondScreenImageDetailsPopupAction(true)
                            );

                            dispatch(
                              generalSecondScreenCreatePopupAction(false)
                            );
                          }}
                        >
                          {" "}
                          {lang.add_new_image}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="second-screen-container-noImage-button">
                <div className="second-screen-container-container-create-button">
                  <button
                    type=""
                    onClick={() =>
                      dispatch(showSecondScreenImageDetailsPopupAction(true))
                    }
                  >
                    <i className="fas fa-camera"></i> {lang.add_image}
                  </button>
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default SecondScreen;
