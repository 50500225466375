import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MenuListItem from "../../../components/MenuListItem";
import ListHeader from "../../../components/ListHeader";
import AddMenuItem from "../../../components/AddMenuItem";
import { main_nav_button_color } from "../../../constants/Colors";
import { priceLevel } from "../../../constants/PriceLevel";
import {
  createSubmenuItemAction,
  getSubmenuItemAction,
  deleteSubmenuItemAction,
  selectedSubmenuItemAction,
} from "../../../redux/submenu/Action";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
} from "../../../redux/menu/Actions";
import {
  createPublicSubItemAction,
  deletePublicSubItemAction,
  getSubItemPublicListAction,
  selectedSubItemPublicAction,
} from "../../../redux/MainNavGeneral/Actions";
const SubmenuItem = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef(false);
  const ref = useRef(null);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const {
    submenu_public_list,
    selected_public_submneu,
    subitem_public_list,
    selected_subitem_public,
    result_failed,
    result_message,
    result_successfull,
    loading,
  } = useSelector((state) => state.MainNavGeneralRedusers);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);

  const [submenuList, setSubmenuList] = useState([]);
  const [emptyInput, setEmptyInput] = useState(false);
  const handleClickItem = (item, key) => {
    setClickedItem(key);
    dispatch(selectedSubItemPublicAction(item));
  };
  const handleAddSubmenuItem = (t, d, a) => {
    dispatch(
      createPublicSubItemAction(
        login_key,
        user_id,
        selected_public_submneu.Id,
        t.replaceAll(`"`, `\"\"`),
        d.replaceAll(`"`, `\"\"`)
      )
    );
  };

  const handeDeleteSubmenuItem = (item) => {
    dispatch(
      deletePublicSubItemAction(login_key, user_id, item.Id, item.SubMenuId)
    );
  };
  const handleEditIconClick = (item) => {
    const copy_product_list = [...submenuList];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.Id == copy_product_list[i].Id) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_list);
    dispatch(selectedOtemInAllLists(item));
  };

  const handleCloseIcon = (item) => {
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setSubmenuList(copy_product_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
    }

    setSubmenuList(copy_product_list);
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...submenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setSubmenuList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...submenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setSubmenuList(copy_list);
  };

  useEffect(() => {
    dispatch(
      getSubItemPublicListAction(login_key, user_id, selected_public_submneu.Id)
    );
  }, []);

  useEffect(() => {
    if (subitem_public_list) {
      if (subitem_public_list.length > 0) {
        setSubmenuList(subitem_public_list);
      } else {
        setSubmenuList([]);
      }
    } else {
      setSubmenuList([]);
    }
  }, [subitem_public_list]);

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_product_list = [...submenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (copy_product_list[index].showEditBox) {
        copy_product_list[index].showEditBox = false;
      }
    }
    dispatch(justTitleAndPriceAction(1));
    setSubmenuList(copy_product_list);
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(selectedOtemInAllLists(null));
      dispatch(justTitleAndPriceAction(0));
    };
  }, [change_selected_button_border_color_on_focus]);

  return (
    <div
      ref={ref}
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
      onClick={handleCloseEditBoxWithOnBlur}
    >
      <ListHeader
        title={lang.item_list}
        show_back={true}
        clickedItem={() => setClickedItem(1)}
      />
      <div className="menu_list_header_container">
        <div>
          {submenuList.length > 0 ? (
            <div style={{ marginBottom: "150px" }}>
              {submenuList.map((item, index) => (
                <div key={index}>
                  {!item.IsDeleted ? (
                    <MenuListItem
                      field_number={1}
                      item_title={item.AppTitle}
                      // item_price={item.AmountMain}
                      field_1_title={item.AppTitle}
                      // field_2_title={`£${item.AmountMain}`}
                      btn_number={1}
                      bt_1_title={lang.edit}
                      bt_1_click={() => handleClickItem(item, 3)}
                      show_delete={true}
                      delete_click={() => handeDeleteSubmenuItem(item)}
                      show_edit_icon={true}
                      selected={selected_subitem_public}
                      item={item}
                      number={15}
                      click_edit_icon={() => handleEditIconClick(item)}
                      click_close_icon={() => handleCloseIcon(item)}
                      show_delete_box={() => handleShowDeleteBox(item)}
                      cancel_delete_box={() => handleCancelDeleteBox(item)}
                    />
                  ) : null}
                </div>
              ))}
            </div>
          ) : (
            <h2 className="no-option-sm">{lang.noOption}</h2>
          )}
        </div>
      </div>
      <AddMenuItem
        number={21}
        button_title={lang.addItem}
        buttonClick={(t, d, a) => handleAddSubmenuItem(t, d, a)}
        // empty={emptyInput}
        setEmptyInput={(i) => setEmptyInput(i)}
        result_successfull={result_successfull}
      />
    </div>
  );
};

export default SubmenuItem;
