import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  main_nav_button_color,
  main_nav_back_color,
  primaryColor,
  white,
} from "../../../../constants/Colors";
import Loader from "../../../../components/Loader";
import toast, { Toaster } from "react-hot-toast";
import ListHeader from "../../../../components/ListHeader";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import PoctCodeList from "./PoctCodeList";

const PostCode = () => {
  const dispatch = useDispatch();
  const [clickedItem, setClickedItem] = useState(1);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    result_successfull,
    result_failed,
    result_message,
    loading_post_code,
  } = useSelector((state) => state.tabelAreaRedusers);
  useEffect(() => {
    if (result_successfull) {
      // dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      // dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);
  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: window.innerHeight - 220,
      }}
    >
      {clickedItem == 1 ? (
        <PoctCodeList setClickedItem={(i) => setClickedItem(i)} />
      ) : null}
      {loading_post_code ? <Loader /> : null}
    </div>
  );
};

export default PostCode;
