import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  orderListFailedAction,
  orderListSuccessfullAction,
  getOrderDetailFailedAction,
  getOrderDetailSuccessfullAction,
  getOrderListAppShopsFailedAction,
  getOrderListAppShopsPendingSuccessfullAction,
  getOrderListAppShopsFailedSuccessfullAction,
  getOrderListAppShopsInCompleteSuccessfullAction,
  getOrderListAppShopsNotPrintedSuccessfullAction,
  getOrderListAppShopsRejectedSuccessfullAction,
} from "./Actions";

import {
  orderListsServices,
  getOrderDetailServices,
  getOrderListAppShopsServices,
} from "../../services/OrdersServices";

function* orderList({ payload }) {
  try {
    const response = yield call(
      orderListsServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.start_date,
      payload.end_date
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.order_list.length > 0) {
          yield put(
            orderListSuccessfullAction(response.data.order_list.reverse())
          );
        } else {
          yield put(orderListFailedAction());
        }
      } else {
        yield put(orderListFailedAction());
      }
    } else {
      yield put(orderListFailedAction());
    }
  } catch (error) {
    yield put(orderListFailedAction());
  }
}
function* getOrderDetail({ payload }) {
  try {
    const response = yield call(
      getOrderDetailServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.order_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.get_order_online.length > 0) {
          yield put(
            getOrderDetailSuccessfullAction(response.data.get_order_online)
          );
        } else {
          yield put(getOrderDetailFailedAction(response.data.ResultMessage));
        }
      } else {
        yield put(getOrderDetailFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getOrderDetailFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getOrderDetailFailedAction(error.message));
  }
}
export function* getOrderListAppShops({ payload }) {
  try {
    const response = yield call(
      getOrderListAppShopsServices,
      payload.login_key,
      payload.user_id,
      payload.order_status
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Order_List_All_Shops) {
          if (response.data.Order_List_All_Shops.length > 0) {
            for (
              let i = 0;
              i < response.data.Order_List_All_Shops.length;
              i++
            ) {
              if (
                response.data.Order_List_All_Shops[i].OrderStatus == 1000403
              ) {
                yield put(
                  getOrderListAppShopsPendingSuccessfullAction(
                    response.data.Order_List_All_Shops
                  )
                );
              } else if (
                response.data.Order_List_All_Shops[i].OrderStatus == 1000401
              ) {
                yield put(
                  getOrderListAppShopsFailedSuccessfullAction(
                    response.data.Order_List_All_Shops
                  )
                );
              } else if (
                response.data.Order_List_All_Shops[i].OrderStatus == 1000402
              ) {
                yield put(
                  getOrderListAppShopsInCompleteSuccessfullAction(
                    response.data.Order_List_All_Shops
                  )
                );
              } else if (
                response.data.Order_List_All_Shops[i].OrderStatus == 1000409
              ) {
                yield put(
                  getOrderListAppShopsNotPrintedSuccessfullAction(
                    response.data.Order_List_All_Shops
                  )
                );
              } else if (
                response.data.Order_List_All_Shops[i].OrderStatus == 1000405
              ) {
                yield put(
                  getOrderListAppShopsRejectedSuccessfullAction(
                    response.data.Order_List_All_Shops
                  )
                );
              }
            }
          } else {
            yield put(getOrderListAppShopsFailedAction(""));
          }
        } else {
          yield put(getOrderListAppShopsFailedAction(""));
        }
      } else {
        yield put(
          getOrderListAppShopsFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getOrderListAppShopsFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getOrderListAppShopsFailedAction(error.message));
  }
}
export function* watchGetOrderListAppShops() {
  yield takeLatest(action_types.GET_ORDER_LIST_APP_SHOPS, getOrderListAppShops);
}
export function* watchGetOrderDetail() {
  yield takeLatest(action_types.GET_ORDER_DETAIL, getOrderDetail);
}

export function* watchOrderList() {
  yield takeLatest(action_types.ORDERS_LIST, orderList);
}

export default function* rootOrder() {
  yield all([
    fork(watchOrderList),
    fork(watchGetOrderDetail),
    fork(watchGetOrderListAppShops),
  ]);
}
