export const english_menu = [
  // {
  //   id: 1,
  //   title: "Home",
  //   icon: "fa fa-home",
  //   expanded: false,
  //   path: "",
  //   sublist: [],
  // },

  {
    id: 2,
    title: "Menu",
    icon: "fa fa-bars",
    expanded: true,
    path: "",
    sublist: [
      {
        id: 21,
        title: "Category",
        path: "",
        icon: "fa fa-sliders",
      },
      {
        id: 22,
        title: "Submenu",
        path: "submenu",
        icon: "fa fa-caret-square-o-down",
      },
      {
        id: 23,
        title: "Dressing",
        path: "dressing",
        icon: "fa fa-caret-square-o-down",
      },
    ],
    isDisabled: false,
    access_id: 1,
  },
  {
    id: 10,
    title: "Menu Status",
    icon: "fa fa-bars",
    expanded: false,
    path: "menustatus",
    sublist: [],
    isDisabled: false,
  },
  // {
  //   id: 3,
  //   title: "Submenu",
  //   icon: "fa fa-caret-square-o-down",
  //   expanded: false,
  //   path: "submenu",
  //   sublist: [],
  // },
  {
    id: 4,
    title: "Settings",
    icon: "fa fa-cog",
    // icon: "fas fa-pound-sign",
    expanded: false,
    path: "shopSetting",
    // path: "/billing/servicecharges",
    sublist: [
      {
        id: 39,
        title: "Shop",
        path: "shopSetting",
        icon: "fa-solid fa-gears",
        isDisabled: false,

        //  icon: "fab fa-cc-stripe"
      },
      {
        id: 40,
        title: "App",
        path: "appSettings",
        icon: "fa fa-android",
        isDisabled: false,
        access_id: 2,
        //  icon: "fab fa-cc-stripe"
      },
      {
        id: 41,
        title: "General",
        path: "general",
        icon: "fa fa-home",
        isDisabled: false,
        access_id: 3,
        //  icon: "fas fa-wallet"
      },

      {
        id: 42,
        title: "Web",
        path: "webSettings",
        icon: "fa fa-internet-explorer",
        isDisabled: false,
        access_id: 5,
        //  icon: "fab fa-cc-stripe"
      },
      {
        id: 43,
        title: "Koisk",
        path: "kioskSettings",
        icon: "fa fa-tv-alt",
        isDisabled: false,
        access_id: 4,
        //  icon: "fab fa-cc-stripe"
      },
      {
        id: 44,
        title: "Coding",
        path: "codingSetting",
        icon: "fa-solid fa-layer-group",
        isDisabled: false,
        access_id: 6,
        //  icon: "fab fa-cc-stripe"
      },
    ],
    isDisabled: false,
  },
  {
    id: 5,
    title: "Employees",
    icon: "fa fa-user-circle-o",
    expanded: false,
    path: "employees",
    sublist: [],
    isDisabled: false,
    access_id: 7,
  },
  {
    id: 6,
    title: "Works Staitions",
    icon: "fa fa-television",
    expanded: false,
    path: "worksStations",
    sublist: [],
    isDisabled: false,
    access_id: 8,
  },
  {
    id: 7,
    title: "Orders",
    icon: "fa fa-shopping-cart",
    expanded: false,
    path: "orders",
    sublist: [],
    isDisabled: false,
    access_id: 9,
  },
  {
    id: 8,
    title: "Sales",
    icon: "fa fa-bar-chart",
    expanded: false,
    path: "sales/overview",
    sublist: [
      {
        id: 31,
        title: "Overview",
        path: "sales/overview",
        icon: "fa fa-line-chart",
      },
      {
        id: 32,
        title: "Detail",
        path: "sales/detail",
        icon: "fa fa-info",
      },
      // {
      //   id: 33,
      //   title: "This Week",
      //   path: "/Settings/LegaL",
      //   icon: "",
      // },
      // {
      //   id: 34,
      //   title: "Last Week",
      //   path: "/Settings/LegaL",
      //   icon: "",
      // },
    ],
    isDisabled: false,
    access_id: 10,
  },
  {
    id: 9,
    title: "Customers",
    icon: "fa fa-users",
    expanded: false,
    path: "custpmers",
    sublist: [],
    isDisabled: false,
    access_id: 11,
  },
  {
    id: 11,
    title: "Refund",
    icon: "fas fa-hand-holding-usd",
    expanded: false,
    path: "refunds",
    sublist: [],
    isDisabled: false,
    access_id: 12,
  },
  // {
  //   id: 12,
  //   title: "Setup",
  //   icon: "fa fa-wrench",
  //   expanded: false,
  //   path: "Setup",
  //   sublist: [],
  //   isDisabled: false,
  //   access_id: 13,
  // },
];
export const turkish_menu = [];
export const persian_menu = [];
