import * as actionTypes from "../ActionTypes";
const initialStates = {
  sale_method_list: [],
  pay_method_list: [],
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_sales: false,
};
const salesRedusers = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.GET_SALES_OVERVIEW_LIST:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_sales: true,
      };
    case actionTypes.GET_SALE_METHOD_LIST_SUCCESSFULL:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_sales: false,
        sale_method_list: action.payload,
      };
    case actionTypes.GET_PAY_METHOD_LIST_SUCCESSFULL:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_sales: false,
        pay_method_list: action.payload,
      };
    case actionTypes.GET_SALES_OVERVIEW_LIST_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_sales: false,
        pay_method_list: [],
        sale_method_list: [],
      };

    default:
      return state;
  }
};
export default salesRedusers;
