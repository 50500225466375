import React, { useState, useEffect, useRef } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import toast, { Toaster } from "react-hot-toast";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ListHeader from "../components/ListHeader";
import SaveMenuButton from "../components/SaveMenuButton";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import { updateCategoryOptionCrustAction } from ".././redux/menu/Actions";

const CategoryOptionCrustEdit = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_category_option_crust, result_successfull } = useSelector(
    (state) => state.categoryList
  );
  const { font_size, selected_category } = useSelector(
    (state) => state.categoryList
  );

  const [appTitle, setAppTitle] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [kioskTitle, setKioskTitle] = useState("");
  const [kioskDesc, setKioskDesc] = useState("");
  const [appSort, setAppsort] = useState(1);
  const [webSort, setWebsort] = useState(1);
  const [kioskSort, setKiosksort] = useState(1);
  const [fontSize, setFontSize] = useState(null);
  const [appVisible, setAppVisible] = useState(false);
  const [webVisible, setWebVisible] = useState(false);
  const [kioskVisible, setKioskVisible] = useState(false);
  const [secondLanguage, setSecondLanguage] = useState("");
  const [price, setPrice] = useState();

  const [backgroundColor, setBackgroundColor] = useState("#14aeba");
  const [titleColor, setTitleColor] = useState("#d0021b");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showSketchPicker, setShowSketchPicker] = useState(false);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleInputBackgroundColor = (e) => {
    setBackgroundColor(e.target.value);
  };
  const handleInputTitleColor = (e) => {
    setTitleColor(e.target.value);
  };
  const handleClose = () => {
    setShowColorPicker(false);
  };

  const handleCloseInputColor = () => {
    setShowSketchPicker(false);
  };
  const handleChangeCompleteBackgroundColor = (color) => {
    setBackgroundColor(color.hex);
    document.getElementById("input_backgroundColor").value = color.hex;
  };

  const handleOnclickeInput = () => {
    setShowSketchPicker(!showSketchPicker);
    setShowColorPicker(false);
  };
  const handleChangeCompleteTitleColor = (color) => {
    setTitleColor(color.hex);
    document.getElementById("inputColor").value = color.hex;
  };
  const handleOnclickeInputColor = () => {
    setShowColorPicker(!showColorPicker);
    setShowSketchPicker(false);
  };

  const handleSaveClicked = () => {
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else
      dispatch(
        updateCategoryOptionCrustAction(
          login_key,
          user_id,
          selected_category_option_crust.Id,
          selected_category_option_crust.ShopId,
          selected_category_option_crust.OptionId,
          appTitle.replaceAll(`"`, `\"\"`),
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          webDesc.replaceAll(`"`, `\"\"`),
          kioskTitle
            ? kioskTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          kioskDesc.replaceAll(`"`, `\"\"`),
          secondLanguage.replaceAll(`"`, `\"\"`),
          price ? parseFloat(price) : 0,
          appVisible ? 1 : 0,
          webVisible ? 1 : 0,
          kioskVisible ? 1 : 0,
          appSort ? parseInt(appSort) : 0,
          webSort ? parseInt(webSort) : 0,
          kioskSort ? parseInt(kioskSort) : 0,
          backgroundColor,
          titleColor,
          fontSize ? fontSize.value : 30,
          0
        )
      );
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        if (appTitle == "") {
          toast.error(
            <h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>,
            {
              position: "top-center",
              duration: 3000,
              style: { backgroundColor: "red", color: "white" },
              icon: (
                <i
                  style={{ fontSize: 40 }}
                  class="fa fa-times-circle"
                  aria-hidden="true"
                ></i>
              ),
            }
          );
        } else
          dispatch(
            updateCategoryOptionCrustAction(
              login_key,
              user_id,
              selected_category_option_crust.Id,
              selected_category_option_crust.ShopId,
              selected_category_option_crust.OptionId,
              appTitle.replaceAll(`"`, `\"\"`),
              webTitle
                ? webTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              webDesc.replaceAll(`"`, `\"\"`),
              kioskTitle
                ? kioskTitle.replaceAll(`"`, `\"\"`)
                : appTitle.replaceAll(`"`, `\"\"`),
              kioskDesc.replaceAll(`"`, `\"\"`),
              secondLanguage.replaceAll(`"`, `\"\"`),
              price ? parseFloat(price) : 0,
              appVisible ? 1 : 0,
              webVisible ? 1 : 0,
              kioskVisible ? 1 : 0,
              appSort ? parseInt(appSort) : 0,
              webSort ? parseInt(webSort) : 0,
              kioskSort ? parseInt(kioskSort) : 0,
              backgroundColor,
              titleColor,
              fontSize ? fontSize.value : 30,
              0
            )
          );
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    appTitle,
    webTitle,
    webDesc,
    kioskTitle,
    kioskDesc,
    secondLanguage,
    price,
    appVisible,
    webVisible,
    kioskVisible,
    appSort,
    webSort,
    kioskSort,
    backgroundColor,
    titleColor,
    fontSize,
  ]);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (selected_category_option_crust) {
      setAppTitle(selected_category_option_crust.AppTitle);
      setWebTitle(selected_category_option_crust.WebTitle);
      setWebDesc(selected_category_option_crust.WebDescription);
      setKioskTitle(selected_category_option_crust.KioskTitle);
      setKioskDesc(selected_category_option_crust.KioskDescription);
      setAppsort(selected_category_option_crust.AppSortId);
      setWebsort(selected_category_option_crust.WebSortId);
      setKiosksort(selected_category_option_crust.KioskSortId);
      setAppVisible(selected_category_option_crust.AppVisible);
      setWebVisible(selected_category_option_crust.WebVisible);
      setKioskVisible(selected_category_option_crust.KioskVisible);
      setBackgroundColor(selected_category_option_crust.AppBgColor);
      setTitleColor(selected_category_option_crust.AppFontColor);
      setFontSize(selected_category_option_crust.AppFontSize);
      setSecondLanguage(selected_category_option_crust.SecondLanguage);
      setPrice(selected_category_option_crust.Price);
    }
  }, [selected_category_option_crust]);
  useEffect(() => {
    if (selected_category && didMountRef.current) {
      setClickedItem(3);
    }
    didMountRef.current = true;
  }, [selected_category]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(10);
    }
  }, [result_successfull]);
  return (
    <div className="countaining-div">
      <ListHeader
        title={lang.category_option_crust_edit}
        show_back={true}
        clickedItem={() => setClickedItem(10)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="div-1">
            {/* APP TITLE */}
            <div className="text">
              <h6 className="title h6">{lang.app_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setAppTitle(e.target.value)}
                  type="text"
                  value={appTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setAppDesc(e.target.value)}
                  type="text"
                  value={appDesc}
                  className="input-21"
                  placeholder={lang.placeholderDesc}
                  disabled
                />
              </div>
            </div>

            {/* WEB TTILE */}
            <div className="text">
              <h6 className="title h6">{lang.web_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setWebTitle(e.target.value)}
                  type="text"
                  value={webTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setWebDesc(e.target.value)}
                  type="text"
                  value={webDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>

            {/* KIOSK TITLE */}
            <div className="text ">
              <h6 className="title h6">{lang.kiosk_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setKioskTitle(e.target.value)}
                  type="text"
                  value={kioskTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setKioskDesc(e.target.value)}
                  type="text"
                  value={kioskDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
          </div>

          <div className="div-3-crust">
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                type="text"
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={appSort}
                onChange={(e) => setAppsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                type="text"
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={webSort}
                onChange={(e) => setWebsort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort}</h6>
              <input
                type="text"
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={kioskSort}
                onChange={(e) =>
                  setKiosksort(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
          </div>
          <div className="COCE-div9">
            {/* VISIBLE ON APP */}
            <div className="div-chechbox-p text">
              {/* <input
                id="check37"
                type="checkbox"
                // className="checkbox"
                checked={appVisible}
                onChange={() => setAppVisible(!appVisible)}
                style={{ margin: 0 }}
              />
              <label for="check37" className="checkmark"></label>
              <h6 className="h6">{lang.visible_on_app}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appVisible}
                      onChange={() => setAppVisible(!appVisible)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_app}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON WEB */}
            <div className="div-chechbox-p text">
              {/* <input
                id="check38"
                type="checkbox"
                // className="checkbox"
                checked={webVisible}
                onChange={() => setWebVisible(!webVisible)}
                style={{ margin: 0 }}
              />
              <label for="check38" className="checkmark"></label>
              <h6 className="h6">{lang.visible_on_web}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={webVisible}
                      onChange={() => setWebVisible(!webVisible)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_web}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON KIOSK */}
            <div className="div-chechbox-p text">
              {/* <input
                id="check39"
                type="checkbox"
                // className="checkbox"
                checked={kioskVisible}
                onChange={() => setKioskVisible(!kioskVisible)}
                style={{ margin: 0 }}
              />
              <label for="check39" className="checkmark"></label>
              <h6 className="h6">{lang.visible_on_kiosk}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={kioskVisible}
                      onChange={() => setKioskVisible(!kioskVisible)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_kiosk}
                />
              </FormGroup>
            </div>
            {/* ***** */}
          </div>
          <div className="div-9-crust">
            {/* BACKGROUND  COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.backgroundColor}</h6>
              <div className="bachgroundColor-div">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: backgroundColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInput}
                  Value={backgroundColor}
                  onChange={handleInputBackgroundColor}
                  id="input_backgroundColor"
                />
              </div>
              {showSketchPicker ? (
                <div
                  className="SketchPicker-div"
                  onMouseLeave={handleCloseInputColor}
                >
                  <SketchPicker
                    color={backgroundColor}
                    onChangeComplete={handleChangeCompleteBackgroundColor}
                  />
                </div>
              ) : null}
            </div>

            {/* FONT COLOR */}
            <div className="countaining-colorPicker">
              <h6 className="h6">{lang.fontColor}</h6>
              <div className="bachgroundColor-div">
                <div
                  className="bachgroundColor"
                  style={{ backgroundColor: titleColor }}
                ></div>
                <input
                  className="backgroundColor-input"
                  onMouseOver={handleOnclickeInputColor}
                  Value={titleColor}
                  onChange={handleInputTitleColor}
                  id="inputColor"
                />
              </div>
              {showColorPicker ? (
                <div className="SketchPicker-div" onMouseLeave={handleClose}>
                  <SketchPicker
                    color={titleColor}
                    onChangeComplete={handleChangeCompleteTitleColor}
                  />
                </div>
              ) : null}
            </div>

            {/* FONT SIZE */}
            <div className="Appearance_information">
              <h6 className="h6-text h6">{lang.fontSize}</h6>
              <Select
                options={font_size}
                defaultValue={fontSize}
                isSearchable={false}
                onChange={(list, item) => setFontSize(list)}
                value={fontSize}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="div-7-crust">
            {/* PRINTER */}
            <div>
              <h6 className="Secound-language h6">{lang.price}</h6>
              <input
                type="text"
                className="input-w input-padding"
                value={price}
                onChange={(e) =>
                  setPrice(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>

            <div>
              <h6 className="Secound-language h6">{lang.secoundLanguage}</h6>
              <input
                type="text"
                className="input-w input-padding"
                value={secondLanguage}
                onChange={(e) => setSecondLanguage(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <SaveMenuButton clickButton={() => handleSaveClicked()} />
    </div>
  );
};

export default CategoryOptionCrustEdit;
