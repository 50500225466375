import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { padding_menu_list_outer } from "../constants/Sizes";
import { primaryColor } from "../constants/Colors";
const AddMenuItem = ({
  buttonClick,
  button_title,
  result_successfull,
  setEmptyInput,
  number,
  buttonClick1,
  is_app,
  is_web,
}) => {
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const amountRef = useRef(null);
  const { height, width } = useScrollbarSize();

  const { lang } = useSelector((state) => state.selectedLanguage);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("");
  const [isApp, setIsApp] = useState(true);
  const [isWeb, setIsWeb] = useState(true);
  const [focusedInput, setFocusedInput] = useState(0);
  const [focusedInput1, setFocusedInput1] = useState(0);
  useEffect(() => {
    if (result_successfull) {
      setTitle("");
      setDesc("");
      setAmount("");
      titleRef.current.focus();
      // setEmptyInput(1);
    }
  }, [result_successfull]);

  // useEffect(() => {
  //   const keyDownHandler = (event) => {
  //     if (event.key === "Enter") {
  //     }
  //   };

  //   document.addEventListener("keydown", keyDownHandler);

  //   return () => {
  //     document.removeEventListener("keydown", keyDownHandler);
  //   };
  // }, [title, desc]);
  const handleTiltleInput = (event) => {
    if (event.key === "Enter") {
      setFocusedInput(1);
      if (title == "") {
        titleRef.current.focus();
      } else {
        if (number != 2) {
          if (amount == "") {
            amountRef.current.focus();
          } else {
            if (desc == "") {
              descRef.current.focus();
            } else {
              event.preventDefault();
              buttonClick(title, desc, amount);
            }
          }
        } else {
          if (desc == "") {
            descRef.current.focus();
          } else {
            event.preventDefault();
            buttonClick(title, desc, amount);
          }
        }
      }
    }
  };
  const handleDescInput = (event) => {
    if (event.key === "Enter") {
      setFocusedInput(2);
      if (title == "") {
        titleRef.current.focus();
      } else {
        if (number != 2) {
          if (
            (focusedInput == 3 && focusedInput1 == 3) ||
            (focusedInput == 3 && focusedInput1 == 1)
          ) {
            event.preventDefault();
            buttonClick(title, desc, amount);
          } else {
            if (amount == "") {
              amountRef.current.focus();
            } else {
              event.preventDefault();
              buttonClick(title, desc, amount);
            }
          }
        } else {
          event.preventDefault();
          buttonClick(title, desc, amount);
        }
      }
    }
  };
  const handleAmountInput = (event) => {
    if (event.key === "Enter") {
      setFocusedInput(3);
      if (title == "") {
        titleRef.current.focus();
      } else {
        if (
          (focusedInput == 1 && focusedInput1 == 2) ||
          (focusedInput == 2 && focusedInput1 == 2)
        ) {
          event.preventDefault();
          buttonClick(title, desc, amount);
        } else {
          if (desc == "") {
            descRef.current.focus();
          } else {
            event.preventDefault();
            buttonClick(title, desc, amount);
          }
        }
      }
    }
  };

  return (
    <div
      className="add_menu_list_item_main_container"
      style={{
        paddingRight: width + padding_menu_list_outer,
        paddingLeft: padding_menu_list_outer,

        // right: (window.innerWidth * 2) / 100 + width - 1,
        // width:
        //   ((window.innerWidth - (window.innerWidth * 4) / 100 - 220) * 75) /
        //     100 -
        //   width +
        //   1,
      }}
    >
      <input
        key={1}
        value={title}
        onChange={(i) => setTitle(i.target.value)}
        onClick={() => setFocusedInput1(1)}
        type="text"
        placeholder={lang.title}
        ref={titleRef}
        onKeyDown={handleTiltleInput}
        autoFocus={focusedInput == 1 ? true : false}
        style={{
          width:
            number == 2
              ? "35%"
              : number == 21
              ? "42%"
              : number == 3
              ? "24%"
              : // : number == 4
                // ? "19%"
                "26.5%",
        }}
      />
      {number == 21 ? null : number != 2 ? (
        <input
          key={3}
          style={{
            margin: 0,
            width:
              number == 2
                ? "42%"
                : number == 3
                ? "24%"
                : // : number == 4
                  // ? "19%"
                  "26.5%",
          }}
          value={amount}
          onClick={() => setFocusedInput1(3)}
          onChange={(i) => setAmount(i.target.value.replace(/[^\d\.,£]+/g, ""))}
          type="text"
          placeholder={lang.amount}
          ref={amountRef}
          autoFocus={focusedInput == 3 ? true : false}
          onKeyDown={handleAmountInput}
        />
      ) : null}
      <input
        key={2}
        style={{
          margin: 0,
          width:
            number == 2
              ? "35%"
              : number == 21
              ? "42%"
              : number == 3
              ? "24%"
              : // : number == 4
                // ? "19%"
                "26.5%",
        }}
        value={desc}
        onClick={() => setFocusedInput1(2)}
        onChange={(i) => setDesc(i.target.value)}
        type="text"
        placeholder={lang.desc}
        ref={descRef}
        autoFocus={focusedInput == 2 ? true : false}
        onKeyDown={handleDescInput}
      />
      {is_web ? (
        // <div className="text div-chechbox-p">
        <FormGroup style={{ marginLeft: "10px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isWeb}
                onChange={() => setIsWeb(!isWeb)}
                sx={{
                  "&.Mui-checked": {
                    color: primaryColor,
                  },
                }}
              />
            }
            label={lang.web}
          />
        </FormGroup>
      ) : // </div>
      null}
      {is_web ? (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isApp}
                onChange={() => setIsApp(!isApp)}
                sx={{
                  "&.Mui-checked": {
                    color: primaryColor,
                  },
                }}
              />
            }
            label={lang.app}
          />
        </FormGroup>
      ) : null}
      <button
        value={title}
        disabled={title ? false : true}
        onClick={() => buttonClick(title, desc, amount, isWeb, isApp)}
        type="submit"
        onBlur={() => setEmptyInput(false)}
        style={{ width: number == 3 || number == 2 ? "13%" : null }}
      >
        {button_title}
      </button>{" "}
      {number == 3 ? (
        <button
          style={{ width: "12%" }}
          onClick={() => buttonClick1()}
          type="submit"
          onBlur={() => setEmptyInput(false)}
        >
          Select item
        </button>
      ) : number == 2 ? (
        <button
          style={{ width: "15%" }}
          onClick={() => buttonClick1()}
          type="submit"
          onBlur={() => setEmptyInput(false)}
        >
          Import Submenu
        </button>
      ) : null}
    </div>
  );
};

export default AddMenuItem;
