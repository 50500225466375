import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import { primaryColor } from "../../../../constants/Colors";
import {
  visibleCodeDetailAction,
  updateCodeDetailAction,
  createCodeDetailAction,
} from "../../../../redux/settings/Action";
import { setDefaultAction } from "../../../../redux/menu/Actions";

const CodeDetailPopup = () => {
  const titleRef = useRef();
  const sortRef = useRef();
  const activeRef = useRef();
  const dispatch = useDispatch();

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    selected_code_detail,
    result_message,
    result_failed,
    result_successfull,
    code_detail_list,
    changed_code_detail_list,
    selected_group_id,
  } = useSelector((state) => state.appSettingsRedusers);
  const lastItem =
    changed_code_detail_list[changed_code_detail_list.length - 1];

  const [title, setTitle] = useState("");
  const [sort, setSort] = useState(null);
  const [active, setActive] = useState(true);

  const handleCreateAndEditCodeDetail = (id) => {
    if (selected_code_detail) {
      if (title == "" || sort == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (!validator.isInt(String(sort))) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.sort_format_is_incorrect}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateCodeDetailAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_code_detail.Id,
            selected_code_detail.GroupId,
            title.replaceAll(`"`, `\"\"`),
            active ? 1 : 0,
            parseInt(sort)
          )
        );
      }
    } else {
      if (title == "" || sort == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (!validator.isInt(String(sort))) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.sort_format_is_incorrect}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          createCodeDetailAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_group_id,
            title.replaceAll(`"`, `\"\"`),
            active ? 1 : 0,
            sort ? parseInt(sort) : 1,
            id
          )
        );
      }
    }
  };
  const handleTitleKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      sortRef.current.focus();
    }
  };
  const handleSortKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      handleCreateAndEditCodeDetail();
    }
  };

  useEffect(() => {
    if (selected_code_detail) {
      setTitle(selected_code_detail.Title);
      setActive(selected_code_detail.Active);
      setSort(selected_code_detail.SortId);
    } else {
      if (lastItem) {
        setSort(lastItem.SortId + 1);
      } else {
        setSort(1);
      }
    }
  }, [selected_code_detail]);

  return (
    <div className="create_shop_popup_container">
      <div className="create_shop_popup_content_container post-code-content-container">
        <div className=" create-shop-close-div-post-code">
          <i
            onClick={() => dispatch(visibleCodeDetailAction(false))}
            style={{ fontSize: 25 }}
            aria-hidden="true"
            className="fa fa-times post-code-close-icon"
          ></i>
          <p className="post-code-p">{lang.code_detail}</p>
        </div>
        <div className="container-input-div">
          <div
            className="create_shop_inputs_container post-code-create_shop_inputs_container"
            style={{ height: "13%", margin: "25px auto " }}
          >
            <form>
              <h6 className="h6 post-code-h6">* {lang.title}</h6>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                autoFocus
                ref={titleRef}
                onKeyDown={handleTitleKeyDown}
              />
            </form>
          </div>
          <div
            className="create_shop_inputs_container"
            style={{ height: "13%", margin: " 45px auto" }}
          >
            <form>
              <h6 className="h6 post-code-h6">{lang.sort}</h6>
              <input
                type="text"
                value={sort}
                onChange={(e) => setSort(e.target.value.replace(/\D/g, ""))}
                ref={sortRef}
                onKeyDown={handleSortKeyDown}
              />
            </form>
          </div>

          <div
            className="
            vat-container-checkbox-div"
          >
            {/* <form>
              <input
                id="check9"
                // className="checkbox"
                type="checkbox"
                checked={active}
                onChange={() => setActive(!active)}
              />
              <label for="check9" className="checkmark"></label>
              <h6 className=" h6">{lang.active}</h6>
            </form> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={() => setActive(!active)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.active}
              />
            </FormGroup>
          </div>
        </div>
        {selected_code_detail ? (
          <div className="post-code-popup-buttons-container">
            <button
              // disabled={selectedSubmenuIds.length > 0 ? false : true}
              onClick={() => handleCreateAndEditCodeDetail(2)}
              id="popup_add"
              // onKeyDown={handleSaveKeyDown}
            >
              {lang.save}
            </button>
          </div>
        ) : (
          <div className="coding-container-save-buttons">
            <button
              onClick={() => handleCreateAndEditCodeDetail(1)}
              // disabled={selectedSubmenuIds.length > 0 ? false : true}
              id="popup_add"
            >
              {lang.for_all_shops}
            </button>
            <button
              onClick={() => handleCreateAndEditCodeDetail(0)}
              // disabled={selectedSubmenuIds.length > 0 ? false : true}
              id="popup_add"
            >
              {lang.only_this_shop}
            </button>
          </div>
        )}
      </div>
      <Toaster />
    </div>
  );
};

export default CodeDetailPopup;
