export const List = [
  {
    id: 1,
    shop_id: 1,
    day_of_week: 0,
    open_delivery: 0,
    open_collection: 0,
    delivery_open_time1: "None",
    delivery_open_time2: "None",
    delivery_open_time3: "None",
    delivery_close_time1: "None",
    delivery_close_time2: "None",
    delivery_close_time3: "None",
    collection_open_time1: "None",
    collection_open_time2: "None",
    collection_open_time3: "None",
    collection_close_time1: "None",
    collection_close_time2: "None",
    collection_close_time3: "None",
    sublist: [
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "second time" },
      ],
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "third time" },
      ],
    ],
    showIcon: false,
    switch: false,
    mainItem: [
      { label: "None", value: 1 },

      { label: "None", value: 2 },

      { label: "None", value: 3 },

      { label: "None", value: 4 },
    ],
  },
  {
    id: 2,
    shop_id: 1,
    day_of_week: 1,
    open_delivery: 0,
    open_collection: 0,
    delivery_open_time1: "None",
    delivery_open_time2: "None",
    delivery_open_time3: "None",
    delivery_close_time1: "None",
    delivery_close_time2: "None",
    delivery_close_time3: "None",
    collection_open_time1: "None",
    collection_open_time2: "None",
    collection_open_time3: "None",
    collection_close_time1: "None",
    collection_close_time2: "None",
    collection_close_time3: "None",
    sublist: [
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "second time" },
      ],
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "third time" },
      ],
    ],
    showIcon: false,
    switch: false,
    mainItem: [
      { label: "None", value: 1 },

      { label: "None", value: 2 },

      { label: "None", value: 3 },

      { label: "None", value: 4 },
    ],
  },
  {
    id: 3,
    shop_id: 1,
    day_of_week: 2,
    open_delivery: 0,
    open_collection: 0,
    delivery_open_time1: "None",
    delivery_open_time2: "None",
    delivery_open_time3: "None",
    delivery_close_time1: "None",
    delivery_close_time2: "None",
    delivery_close_time3: "None",
    collection_open_time1: "None",
    collection_open_time2: "None",
    collection_open_time3: "None",
    collection_close_time1: "None",
    collection_close_time2: "None",
    collection_close_time3: "None",
    sublist: [
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "second time" },
      ],
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "third time" },
      ],
    ],
    showIcon: false,
    switch: false,
    mainItem: [
      { label: "None", value: 1 },

      { label: "None", value: 2 },

      { label: "None", value: 3 },

      { label: "None", value: 4 },
    ],
  },
  {
    id: 4,
    shop_id: 1,
    day_of_week: 3,
    open_delivery: 0,
    open_collection: 0,
    delivery_open_time1: "None",
    delivery_open_time2: "None",
    delivery_open_time3: "None",
    delivery_close_time1: "None",
    delivery_close_time2: "None",
    delivery_close_time3: "None",
    collection_open_time1: "None",
    collection_open_time2: "None",
    collection_open_time3: "None",
    collection_close_time1: "None",
    collection_close_time2: "None",
    collection_close_time3: "None",
    sublist: [
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "second time" },
      ],
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "third time" },
      ],
    ],
    showIcon: false,
    switch: false,
    mainItem: [
      { label: "None", value: 1 },

      { label: "None", value: 2 },

      { label: "None", value: 3 },

      { label: "None", value: 4 },
    ],
  },
  {
    id: 5,
    shop_id: 1,
    day_of_week: 4,
    open_delivery: 0,
    open_collection: 0,
    delivery_open_time1: "None",
    delivery_open_time2: "None",
    delivery_open_time3: "None",
    delivery_close_time1: "None",
    delivery_close_time2: "None",
    delivery_close_time3: "None",
    collection_open_time1: "None",
    collection_open_time2: "None",
    collection_open_time3: "None",
    collection_close_time1: "None",
    collection_close_time2: "None",
    collection_close_time3: "None",
    sublist: [
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "second time" },
      ],
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "third time" },
      ],
    ],
    showIcon: false,
    switch: false,
    mainItem: [
      { label: "None", value: 1 },

      { label: "None", value: 2 },

      { label: "None", value: 3 },

      { label: "None", value: 4 },
    ],
  },
  {
    id: 6,
    shop_id: 1,
    day_of_week: 5,
    open_delivery: 0,
    open_collection: 0,
    delivery_open_time1: "None",
    delivery_open_time2: "None",
    delivery_open_time3: "None",
    delivery_close_time1: "None",
    delivery_close_time2: "None",
    delivery_close_time3: "None",
    collection_open_time1: "None",
    collection_open_time2: "None",
    collection_open_time3: "None",
    collection_close_time1: "None",
    collection_close_time2: "None",
    collection_close_time3: "None",
    sublist: [
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "second time" },
      ],
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "third time" },
      ],
    ],
    showIcon: false,
    switch: false,
    mainItem: [
      { label: "None", value: 1 },

      { label: "None", value: 2 },

      { label: "None", value: 3 },

      { label: "None", value: 4 },
    ],
  },
  {
    id: 7,
    shop_id: 1,
    day_of_week: 6,
    open_delivery: 0,
    open_collection: 0,
    delivery_open_time1: "None",
    delivery_open_time2: "None",
    delivery_open_time3: "None",
    delivery_close_time1: "None",
    delivery_close_time2: "None",
    delivery_close_time3: "None",
    collection_open_time1: "None",
    collection_open_time2: "None",
    collection_open_time3: "None",
    collection_close_time1: "None",
    collection_close_time2: "None",
    collection_close_time3: "None",
    sublist: [
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "second time" },
      ],
      [
        { label: "None", value: 1 },

        { label: "None", value: 2 },

        { label: "None", value: 3 },

        { label: "None", value: 4 },
        { title: "third time" },
      ],
    ],
    showIcon: false,
    switch: false,
    mainItem: [
      { label: "None", value: 1 },

      { label: "None", value: 2 },

      { label: "None", value: 3 },

      { label: "None", value: 4 },
    ],
  },
];
export const delivery_collection_time = [
  { value: 145, label: "None", isDisabled: false },
  { value: 1, label: "05:00", isDisabled: false },
  { value: 2, label: "05:10", isDisabled: false },
  { value: 3, label: "05:20", isDisabled: false },
  { value: 4, label: "05:30", isDisabled: false },
  { value: 5, label: "05:40", isDisabled: false },

  { value: 6, label: "05:50", isDisabled: false },

  { value: 7, label: "06:00", isDisabled: false },
  { value: 8, label: "06:10", isDisabled: false },
  { value: 9, label: "06:20", isDisabled: false },
  { value: 10, label: "06:30", isDisabled: false },
  { value: 11, label: "06:40", isDisabled: false },

  { value: 12, label: "06:50", isDisabled: false },

  { value: 13, label: "07:00", isDisabled: false },
  { value: 14, label: "07:10", isDisabled: false },
  { value: 15, label: "07:20", isDisabled: false },
  { value: 16, label: "07:30", isDisabled: false },
  { value: 17, label: "07:40", isDisabled: false },

  { value: 18, label: "07:50", isDisabled: false },

  { value: 19, label: "08:00", isDisabled: false },
  { value: 20, label: "08:10", isDisabled: false },
  { value: 21, label: "08:20", isDisabled: false },
  { value: 22, label: "08:30", isDisabled: false },
  { value: 23, label: "08:40", isDisabled: false },

  { value: 24, label: "08:50", isDisabled: false },

  { value: 25, label: "09:00", isDisabled: false },
  { value: 26, label: "09:10", isDisabled: false },
  { value: 27, label: "09:20", isDisabled: false },
  { value: 28, label: "09:30", isDisabled: false },
  { value: 29, label: "09:40", isDisabled: false },

  { value: 30, label: "09:50", isDisabled: false },

  { value: 31, label: "10:00", isDisabled: false },
  { value: 32, label: "10:10", isDisabled: false },
  { value: 33, label: "10:20", isDisabled: false },
  { value: 34, label: "10:30", isDisabled: false },
  { value: 35, label: "10:40", isDisabled: false },

  { value: 36, label: "10:50", isDisabled: false },
  { value: 37, label: "11:00", isDisabled: false },
  { value: 38, label: "11:10", isDisabled: false },
  { value: 39, label: "11:20", isDisabled: false },
  { value: 40, label: "11:30", isDisabled: false },

  { value: 41, label: "11:40", isDisabled: false },
  { value: 42, label: "11:50", isDisabled: false },
  { value: 43, label: "12:00", isDisabled: false },
  { value: 44, label: "12:10", isDisabled: false },
  { value: 45, label: "12:20", isDisabled: false },
  { value: 46, label: "12:30", isDisabled: false },
  { value: 47, label: "12:40", isDisabled: false },
  { value: 48, label: "12:50", isDisabled: false },
  { value: 49, label: "13:00", isDisabled: false },
  { value: 50, label: "13:10", isDisabled: false },
  { value: 51, label: "13:20", isDisabled: false },
  { value: 52, label: "13:30", isDisabled: false },
  { value: 53, label: "13:40", isDisabled: false },
  { value: 54, label: "13:50", isDisabled: false },
  { value: 55, label: "14:00", isDisabled: false },
  { value: 56, label: "14:10", isDisabled: false },
  { value: 57, label: "14:20", isDisabled: false },
  { value: 58, label: "14:30", isDisabled: false },
  { value: 59, label: "14:40", isDisabled: false },
  { value: 60, label: "14:50", isDisabled: false },
  { value: 61, label: "15:00", isDisabled: false },
  { value: 62, label: "15:10", isDisabled: false },
  { value: 63, label: "15:20", isDisabled: false },
  { value: 64, label: "15:30", isDisabled: false },
  { value: 65, label: "15:40", isDisabled: false },
  { value: 66, label: "15:50", isDisabled: false },
  { value: 67, label: "16:00", isDisabled: false },
  { value: 68, label: "16:10", isDisabled: false },
  { value: 69, label: "16:20", isDisabled: false },
  { value: 70, label: "16:30", isDisabled: false },
  { value: 71, label: "16:40", isDisabled: false },
  { value: 72, label: "16:50", isDisabled: false },
  { value: 73, label: "17:00", isDisabled: false },
  { value: 74, label: "17:10", isDisabled: false },
  { value: 75, label: "17:20", isDisabled: false },
  { value: 76, label: "17:30", isDisabled: false },
  { value: 77, label: "17:40", isDisabled: false },
  { value: 78, label: "17:50", isDisabled: false },
  { value: 79, label: "18:00", isDisabled: false },
  { value: 80, label: "18:10", isDisabled: false },
  { value: 81, label: "18:20", isDisabled: false },
  { value: 82, label: "18:30", isDisabled: false },
  { value: 83, label: "18:40", isDisabled: false },
  { value: 84, label: "18:50", isDisabled: false },
  { value: 85, label: "19:00", isDisabled: false },
  { value: 86, label: "19:10", isDisabled: false },
  { value: 87, label: "19:20", isDisabled: false },
  { value: 88, label: "19:30", isDisabled: false },
  { value: 89, label: "19:40", isDisabled: false },
  { value: 90, label: "19:50", isDisabled: false },
  { value: 91, label: "20:00", isDisabled: false },
  { value: 92, label: "20:10", isDisabled: false },
  { value: 93, label: "20:20", isDisabled: false },
  { value: 94, label: "20:30", isDisabled: false },
  { value: 95, label: "20:40", isDisabled: false },
  { value: 96, label: "20:50", isDisabled: false },
  { value: 97, label: "21:00", isDisabled: false },
  { value: 98, label: "21:10", isDisabled: false },
  { value: 99, label: "21:20", isDisabled: false },
  { value: 100, label: "21:30", isDisabled: false },
  { value: 101, label: "21:40", isDisabled: false },
  { value: 102, label: "21:50", isDisabled: false },
  { value: 103, label: "22:00", isDisabled: false },
  { value: 104, label: "22:10", isDisabled: false },
  { value: 105, label: "22:20", isDisabled: false },
  { value: 106, label: "22:30", isDisabled: false },
  { value: 107, label: "22:40", isDisabled: false },
  { value: 108, label: "22:50", isDisabled: false },
  { value: 109, label: "23:00", isDisabled: false },
  { value: 110, label: "23:10", isDisabled: false },
  { value: 111, label: "23:20", isDisabled: false },
  { value: 112, label: "23:30", isDisabled: false },
  { value: 113, label: "23:40", isDisabled: false },
  { value: 114, label: "23:50", isDisabled: false },

  { value: 115, label: "after midnight 00:10", isDisabled: false },
  { value: 116, label: "after midnight 00:20", isDisabled: false },
  { value: 117, label: "after midnight 00:30", isDisabled: false },

  { value: 118, label: "after midnight 00:40", isDisabled: false },
  { value: 119, label: "after midnight 00:50", isDisabled: false },
  { value: 120, label: "after midnight 01:00", isDisabled: false },

  { value: 121, label: "after midnight 01:10", isDisabled: false },
  { value: 122, label: "after midnight 01:20", isDisabled: false },
  { value: 123, label: "after midnight 01:30", isDisabled: false },
  { value: 124, label: "after midnight 01:40", isDisabled: false },

  { value: 125, label: "after midnight 01:50", isDisabled: false },
  { value: 126, label: "after midnight 02:00", isDisabled: false },

  { value: 127, label: "after midnight 02:10", isDisabled: false },
  { value: 128, label: "after midnight 02:20", isDisabled: false },
  { value: 129, label: "after midnight 02:30", isDisabled: false },
  { value: 130, label: "after midnight 02:40", isDisabled: false },

  { value: 131, label: "after midnight 02:50", isDisabled: false },
  { value: 132, label: "after midnight 03:00", isDisabled: false },
  { value: 133, label: "after midnight 03:10", isDisabled: false },
  { value: 134, label: "after midnight 03:20", isDisabled: false },
  { value: 135, label: "after midnight 03:30", isDisabled: false },

  { value: 136, label: "after midnight 03:40", isDisabled: false },
  { value: 137, label: "after midnight 03:50", isDisabled: false },
  { value: 138, label: "after midnight 04:00", isDisabled: false },
  { value: 139, label: "after midnight 04:10", isDisabled: false },
  { value: 140, label: "after midnight 04:20", isDisabled: false },

  { value: 141, label: "after midnight 04:30", isDisabled: false },
  { value: 142, label: "after midnight 04:40", isDisabled: false },
  { value: 143, label: "after midnight 04:50", isDisabled: false },
  { value: 144, label: "after midnight 04:59", isDisabled: false },
];

export const times = [
  { value: 1, label: 5 },
  { value: 2, label: 10 },
  { value: 3, label: 15 },
  { value: 4, label: 20 },
  { value: 5, label: 25 },
  { value: 6, label: 30 },
  { value: 7, label: 35 },
  { value: 8, label: 40 },
  { value: 9, label: 45 },
  { value: 10, label: 50 },
  { value: 11, label: 55 },
  { value: 12, label: 60 },
  { value: 13, label: 65 },
  { value: 14, label: 70 },
  { value: 15, label: 75 },
  { value: 16, label: 80 },
  { value: 17, label: 85 },
  { value: 18, label: 90 },
  { value: 19, label: 95 },
  { value: 20, label: 100 },
  { value: 21, label: 105 },
  { value: 22, label: 110 },
  { value: 23, label: 115 },
  { value: 24, label: 120 },
];
export const segmentations = [
  { value: 1, label: 5 },
  { value: 2, label: 10 },
  { value: 3, label: 15 },
  { value: 4, label: 20 },
  { value: 5, label: 25 },
  { value: 6, label: 30 },
];
export const expire_time = [
  { value: 1, label: 5 },
  { value: 2, label: 10 },
  { value: 3, label: 15 },
  { value: 4, label: 20 },
  { value: 5, label: 25 },
  { value: 6, label: 30 },
];
export const hours = [
  { value: 0, label: "00" },
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: " 03" },
  { value: 4, label: "04" },
  { value: 5, label: "05 " },
  { value: 6, label: " 06" },
  { value: 7, label: " 07" },
  { value: 8, label: "08 " },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: " 11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15 " },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: " 20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
];
export const minutes = [
  { value: 0, label: "00" },
  { value: 1, label: "01" },
  { value: 2, label: " 02" },
  { value: 3, label: " 03" },
  { value: 4, label: "04" },
  { value: 5, label: " 05" },
  { value: 6, label: " 06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: " 12" },
  { value: 13, label: "13" },
  { value: 14, label: " 14" },
  { value: 15, label: " 15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25 " },
  { value: 26, label: "26 " },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: " 29" },
  { value: 30, label: "30" },
  { value: 31, label: "31 " },
  { value: 32, label: "32 " },
  { value: 33, label: "33 " },
  { value: 34, label: "34" },
  { value: 35, label: "35" },
  { value: 36, label: "36" },
  { value: 37, label: "37" },
  { value: 38, label: "38" },
  { value: 39, label: "39" },
  { value: 40, label: "40" },
  { value: 41, label: "41" },
  { value: 42, label: "42" },
  { value: 43, label: "43" },
  { value: 44, label: "44" },
  { value: 45, label: "45" },
  { value: 46, label: "46" },
  { value: 47, label: " 47" },
  { value: 48, label: "48" },
  { value: 49, label: "49 " },
  { value: 50, label: "50" },
  { value: 51, label: "51" },
  { value: 52, label: "52" },
  { value: 53, label: "53" },
  { value: 54, label: "54" },
  { value: 55, label: "55" },
  { value: 56, label: "56" },
  { value: 57, label: "57" },
  { value: 58, label: "58" },
  { value: 59, label: "59" },
];
export const Maintenace_type = [
  {
    value: 1,
    label: "For ever",
  },
  {
    value: 2,
    label: "Until next day pre order",
  },
  {
    value: 3,
    label: "Until selected date",
  },
];

export const force_close_order = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 1000105,
    label: "Only delivery",
  },
  {
    value: 1000106,
    label: "Only collection",
  },
  {
    value: 1,
    label: "Close all",
  },
];
export const Account_Type = [
  {
    value: 1001,
    label: "Standard",
  },
  { value: 1002, label: "Express" },
  { value: 1003, label: "Custom" },
];
export const Stripe_Mode = [
  {
    value: 0,
    label: "Test Mode",
  },
  { value: 1, label: "Live Mode" },
];
