import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const GetWePosSettingListService = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}getFofSetting/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};
export const updateWePosSettingService = async (formData) => {
  return await axios.post(`${baseUrl}updateFofSetting/`, formData);
};

// META DATA
export const getWePosMetaDataListService = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}fofMetaList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};

export const updateWePosMetaDataService = async (
  login_key,
  user_id,
  meta_title,
  meta_desc,
  meta_keywords
) => {
  return await axios.put(`${baseUrl}updateFofMeta/`, {
    LoginKey: login_key,
    UserId: user_id,
    MetaTitle: meta_title,
    MetaDescription: meta_desc,
    MetaKey: meta_keywords,
  });
};
