import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import SideBar from "../../components/SideBar";
import Menu from "../menus/Menu";
import Home from "../home";
import Submenu from "../shop/Submenu";
import Order from "../orders/Order";
import AppSettings from "../settings/AppSettings";
import WebSettings from "../settings/WebSetting";
import KioskSettings from "../settings/KioskSettings";
import General from "../settings/GeneralSetting/GeneralTap";
import Employees from "./employees/EmployessTab";
import WorksStations from "../Works Stations";
import Sales from "../dashboard/sales";
import Customers from "./Cusomers";
import Coding from "../settings/CodingSetting/index";
import Shop from "../settings/ShopSetting";
import Profile from "../../components/Profile";
import WorkStationsTAb from "../Works Stations/WorkStationTabs";
import Overview from "./sales/components/Overview";
import SalesDetail from "./sales/components/SalesDetail";
import MenuStatus from "../menus/menuStatus";
import Refund from "../finamcial/components/Refund";
import Debt from "../finamcial/components/Debt";
import RefundList from "../../components/RefundList";
import MaintenanceMode from "../settings/WebSetting/Componenets/Maintenance mode";
import PromotionsList from "../../components/PromotionsList";
import PromotionsEdit from "../../components/PromotionsEdit";
import Promotions from "../settings/WebSetting/Componenets/Promotions";
import OrderDetails from "../orders/OrderDetails";
import Dressing from "../dressing/dressing";
const Dashboard = () => {
  const [width1, setWidth1] = React.useState(window.innerWidth);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <div className="dashborard-index-countainer-div">
      {width1 > 560 ? <SideBar /> : null}

      <Routes>
        <Route path="profile" element={<Profile />} />
        {/* <Route path="" element={<Home />} /> */}
        <Route path="" element={<Menu />} />
        <Route path="menustatus" element={<MenuStatus />} />
        <Route path="submenu" element={<Submenu />} />
        <Route path="dressing" element={<Dressing />} />
        <Route path="orders" element={<Order />} />
        <Route path="orders/orderdetails" element={<OrderDetails />} />
        <Route path="shopSetting" element={<Shop />} />
        <Route path="appSettings" element={<AppSettings />} />
        <Route path="webSettings" element={<WebSettings />} />
        <Route path="kioskSettings" element={<KioskSettings />} />
        <Route path="sales/overview" element={<Overview />} />
        <Route path="sales/detail" element={<SalesDetail />} />
        <Route path="general" element={<General />} />
        <Route path="codingSetting" element={<Coding />} />
        <Route path="employees" element={<Employees />} />
        <Route path="worksStations" element={<WorkStationsTAb />} />
        {/* <Route path="sales/overview" element={<Sales />} /> */}
        <Route path="custpmers" element={<Customers />} />
        <Route path="financial/refund" element={<Refund />} />
        <Route path="financial/debt" element={<Debt />} />
        <Route path="refunds" element={<RefundList />} />
        <Route path="webSetting/maintanance" element={<MaintenanceMode />} />
        <Route path="webSetting/promotion" element={<Promotions />} />
      </Routes>
    </div>
  );
};

export default Dashboard;
