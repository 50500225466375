import React, { useEffect, useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  main_nav_button_color,
  main_nav_back_color,
  primaryColor,
  white,
} from "../../../constants/Colors";
import Payments from "./Componenets/Payments";
import Setting from "./Componenets/Setting";
import Loader from "../../../components/Loader";
import SaveMenuButton from "../../../components/SaveMenuButton";
import { setDefaultAction } from "../../../redux/menu/Actions";
import OpenCloseTime from "./Componenets/index";
import Stripe from "./Componenets/Stripe";
import TimeGap from "./Componenets/TimeGap";
import Delivery from "./Componenets/Delivery";
import MaintenanceMode from "./Componenets/Maintenance mode";

import Carousel, { consts } from "react-elastic-carousel";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import EmailNotification from "./Componenets/EmailNotification";
import Promotions from "./Componenets/Promotions";
import { selectedPromotionAction } from "../../../redux/settings/Action";
import Adyen from "./Componenets/Adyen";
import Meta from "./Componenets/Meta";
const WebSettings = () => {
  const dispatch = useDispatch();
  const settingRef = useRef(null);
  const paymentRef = useRef(null);
  const stripeRef = useRef(null);
  const timeGapRef = useRef(null);
  const deliveryRef = useRef(null);
  const maintenanceModeRef = useRef(null);
  const promotionsRef = useRef(null);
  const adyenRef = useRef(null);
  const metaRef = useRef(null);
  const navigate = useNavigate();
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const {
    loading_appSettings,
    result_successfull,
    result_message,
    result_failed,
    web_payment_list,
  } = useSelector((state) => state.appSettingsRedusers);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [clickedItem, setClickedItem] = useState(1);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  const type = consts.PREV;

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i
          class="fa fa-chevron-left"
          aria-hidden="true"
          style={{ fontSize: "20px", marginTop: "5px" }}
        ></i>
      ) : (
        <i
          class="fa fa-chevron-right"
          aria-hidden="true"
          style={{
            fontSize: "20px",
            marginTop: "5px",
            marginLeft: "-16px",
          }}
        ></i>
      );
    return (
      <button onClick={onClick} disabled={isEdge} style={{ border: "none" }}>
        {pointer}
      </button>
    );
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 330, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 450, itemsToShow: 3, itemsToScroll: 2, pagination: false },
    { width: 580, itemsToShow: 4, itemsToScroll: 2, pagination: false },
    { width: 800, itemsToShow: 5, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 6, pagination: false },
    { width: 1150, itemsToShow: 7, itemsToScroll: 2, pagination: false },
  ];

  return (
    <div
      className="countaining-div countaining-div-11"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className=" menu_list_header_container_tabel_list">
        <div className=" main_nav_container" id="">
          <div
            className="settings_tab_buttons_container  web-setting-container-Carousel"

            // id="settings_tab_buttons_container"
          >
            <Carousel
              // className="styling-example"
              breakPoints={breakPoints}
              itemsToShow={6}
              className="carousel"
              renderArrow={myArrow}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <div direction="row">
                    {pages.map((page) => {
                      // const isActivePage = activePage === page;
                      return (
                        <div
                          style={{ display: "none" }}
                          key={page}
                          onClick={() => onClick(page)}
                          // active={isActivePage}
                        />
                      );
                    })}
                  </div>
                );
              }}
            >
              <button
                key={1}
                onClick={() => setClickedItem(1)}
                style={{
                  backgroundColor: clickedItem == 1 ? primaryColor : white,
                  color: clickedItem == 1 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 1 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.setting}
              </button>
              <button
                key={7}
                onClick={() => setClickedItem(7)}
                style={{
                  backgroundColor: clickedItem == 7 ? primaryColor : white,
                  color: clickedItem == 7 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 7 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.maintenance_mode}
              </button>
              <button
                key={3}
                onClick={() => setClickedItem(3)}
                style={{
                  backgroundColor: clickedItem == 3 ? primaryColor : white,
                  color: clickedItem == 3 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 3 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.payments}
              </button>
              <button
                key={2}
                onClick={() => setClickedItem(2)}
                style={{
                  backgroundColor: clickedItem == 2 ? primaryColor : white,
                  color: clickedItem == 2 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 2 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.open_close_time}
              </button>
              <button
                key={5}
                onClick={() => setClickedItem(5)}
                style={{
                  backgroundColor: clickedItem == 5 ? primaryColor : white,
                  color: clickedItem == 5 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 5 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.time_gap}
              </button>
              <button
                key={8}
                onClick={() => {
                  setClickedItem(8);
                  dispatch(selectedPromotionAction(null));
                }}
                style={{
                  backgroundColor: clickedItem == 8 ? primaryColor : white,
                  color: clickedItem == 8 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 8 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.promotions}
              </button>
              <button
                key={9}
                onClick={() => setClickedItem(9)}
                style={{
                  backgroundColor: clickedItem == 9 ? primaryColor : white,
                  color: clickedItem == 9 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 9 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.email_notification}
              </button>
              {/* <button
                key={4}
                onClick={() => setClickedItem(4)}
                style={{
                  backgroundColor: clickedItem == 4 ? primaryColor : white,
                  color: clickedItem == 4 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 4 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.stripe}
              </button> */}
              <button
                key={10}
                onClick={() => setClickedItem(10)}
                style={{
                  backgroundColor: clickedItem == 10 ? primaryColor : white,
                  color: clickedItem == 10 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 10 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.adyen}
              </button>
              <button
                key={11}
                onClick={() => setClickedItem(11)}
                style={{
                  backgroundColor: clickedItem == 11 ? primaryColor : white,
                  color: clickedItem == 11 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 11 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.meta}
              </button>
            </Carousel>
          </div>
        </div>

        {clickedItem == 1 ? <Setting ref={settingRef} /> : null}
        {clickedItem == 2 ? <OpenCloseTime ref={settingRef} /> : null}
        {clickedItem == 3 ? <Payments ref={paymentRef} /> : null}
        {clickedItem == 4 ? <Stripe ref={stripeRef} /> : null}
        {clickedItem == 5 ? <TimeGap ref={timeGapRef} /> : null}
        {clickedItem == 6 ? <Delivery ref={deliveryRef} /> : null}
        {clickedItem == 7 ? <MaintenanceMode ref={maintenanceModeRef} /> : null}
        {clickedItem == 9 ? (
          <EmailNotification ref={maintenanceModeRef} />
        ) : clickedItem == 8 ? (
          <Promotions ref={promotionsRef} />
        ) : clickedItem == 10 ? (
          <Adyen ref={adyenRef} />
        ) : null}
        {clickedItem == 11 ? <Meta ref={metaRef} /> : null}
      </div>
      {clickedItem == 2 || clickedItem == 9 || clickedItem == 8 ? null : ( // || !web_payment_list.length > 0
        <SaveMenuButton
          clickButton={() =>
            clickedItem == 1
              ? settingRef.current.saveSettings()
              : clickedItem == 3
              ? paymentRef.current.savePayments()
              : clickedItem == 4
              ? stripeRef.current.saveStripe()
              : clickedItem == 5
              ? timeGapRef.current.saveTimeGap()
              : clickedItem == 6
              ? deliveryRef.current.saveDelivery()
              : clickedItem == 7
              ? maintenanceModeRef.current.saveMaintenance()
              : clickedItem == 10
              ? adyenRef.current.saveAyden()
              : clickedItem == 11
              ? metaRef.current.saveMetaData()
              : null
          }
        />
      )}

      {loading_appSettings ? <Loader /> : null}
    </div>
  );
};

export default WebSettings;
