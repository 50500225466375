import * as actionTypes from "../ActionTypes";

export const getWorksStationsAction = (login_key, user_id, shopId) => {
  return {
    type: actionTypes.GET_WORKS_STATION_LIST,
    payload: { login_key, user_id, shopId },
  };
};
export const getWorksStationsSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_WORKS_STATION_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getWorksStationsFailedAction = () => {
  return {
    type: actionTypes.GET_WORKS_STATION_LIST_FAILED,
  };
};

export const createWorksStationsAction = (
  login_key,
  user_id,
  shopId,
  work_station_name,
  device_id,
  device_type,
  active,
  licence_course,
  is_expired,
  active_secound_screen,
  get_order_online,
  shop_front_work_station,
  is_kitchen,
  password_protected_till_opening,
  print_customer_ticket,
  caller_id,
  has_cash_drawer
) => {
  return {
    type: actionTypes.CREATE_WORKS_STATION,
    payload: {
      login_key,
      user_id,
      shopId,
      work_station_name,
      device_id,
      device_type,
      active,
      licence_course,
      is_expired,
      active_secound_screen,
      get_order_online,
      shop_front_work_station,
      is_kitchen,
      password_protected_till_opening,
      print_customer_ticket,
      caller_id,
      has_cash_drawer,
    },
  };
};
export const createWorksStationsSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_WORKS_STATION_SUCCESSFULL,
    payload: data,
  };
};
export const createWorksStationsFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_WORKS_STATION_FAILED,
    payload: data,
  };
};

export const sendWorksStationsAction = (
  login_key,
  user_id,
  id,
  shopId,
  work_station_name,
  device_id,
  device_type,
  active,
  // licence_course,
  is_expired,
  active_secound_screen,
  get_order_online,
  shop_front_work_station,
  is_kitchen,
  password_protected_till_opening,
  print_customer_ticket,
  caller_id,
  has_cash_drawer
) => {
  return {
    type: actionTypes.SEND_WORK_STATION,
    payload: {
      login_key,
      user_id,
      id,
      shopId,
      work_station_name,
      device_id,
      device_type,
      active,
      // licence_course,
      is_expired,
      active_secound_screen,
      get_order_online,
      shop_front_work_station,
      is_kitchen,
      password_protected_till_opening,
      print_customer_ticket,
      caller_id,
      has_cash_drawer,
    },
  };
};
export const sendWorksStationsSuccessfullAction = (data) => {
  return {
    type: actionTypes.SEND_WORK_STATION_SUCCESSFULL,
    payload: data,
  };
};
export const sendWorksStationsFailedAction = (data) => {
  return {
    type: actionTypes.SEND_WORK_STATION_FAILED,
    payload: data,
  };
};

export const deleteWorksStationsAction = (
  login_key,
  user_id,
  shopId,
  workStation_id
) => {
  return {
    type: actionTypes.DELETE_WORKS_STATION,
    payload: { login_key, user_id, shopId, workStation_id },
  };
};
export const deleteWorksStationsSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_WORKS_STATION_SUCCESSFULL,
    payload: data,
  };
};
export const deleteWorksStationsFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_WORKS_STATION_FAILED,
    payload: data,
  };
};
export const selecteWorksStationsAction = (data) => {
  return {
    type: actionTypes.SELECTE_WORKS_STATION,
    payload: data,
  };
};

//GET WORKSTATION STATUS
export const getWorksStationStatusListAction = (
  login_key,
  user_id,
  shop_id
) => {
  return {
    type: actionTypes.GET_WORKSTATION_STATUS_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const getWorksStationStatusListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_WORKSTATION_STATUS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getWorksStationStatusListFailedAction = (data) => {
  return {
    type: actionTypes.GET_WORKSTATION_STATUS_LIST_FAILED,
    payload: data,
  };
};

export const updateWorksStationStatusAction = (
  login_key,
  user_id,
  shop_id,
  workStation_id
) => {
  return {
    type: actionTypes.UPDATE_WORKSTATION_STATUS,
    payload: { login_key, user_id, shop_id, workStation_id },
  };
};
export const updateWorksStationStatusSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_WORKSTATION_STATUS_SUCCESSFULL,
    payload: data,
  };
};
export const updateWorksStationStatusFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_WORKSTATION_STATUS_FAILED,
    payload: data,
  };
};

// WORK STATION MAIN BUTTONS

export const getWorkStationMainButtonsListAction = (
  shop_id,
  login_key,
  user_id,
  work_station_id
) => {
  return {
    type: actionTypes.GET_WORK_STATION_MAIN_BUTTONS_LIST,
    payload: { shop_id, login_key, user_id, work_station_id },
  };
};
export const getWorkStationMainButtonsListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_WORK_STATION_MAIN_BUTTONS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getWorkStationMainButtonsListFailedAction = (data) => {
  return {
    type: actionTypes.GET_WORK_STATION_MAIN_BUTTONS_LIST_FAILED,
    payload: data,
  };
};

export const editWorkStationMainButtonsDetailsAction = (
  formData,
  shop_id,
  login_key,
  user_id,
  work_station_id,
  id,
  button_code,
  button_name,
  visible,
  enable,
  button_image,
  last_image_name
) => {
  return {
    type: actionTypes.EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS,
    payload: {
      formData,
      shop_id,
      login_key,
      user_id,
      work_station_id,
      id,
      button_code,
      button_name,
      visible,
      enable,
      button_image,
      last_image_name,
    },
  };
};
export const editWorkStationMainButtonsDetailsSuccessfullAction = (data) => {
  return {
    type: actionTypes.EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS_SUCCESSFULL,
    payload: data,
  };
};
export const editWorkStationMainButtonsDetailsFailedAction = (data) => {
  return {
    type: actionTypes.EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS_FAILED,
    payload: data,
  };
};

export const showWorkStationMainButtonsPopupAction = (data) => {
  return {
    type: actionTypes.SHOW_WORK_STATION_MAIN_BUTTONS_POPUP,
    payload: data,
  };
};
export const selectedMainButtonAction = (data) => {
  return {
    type: actionTypes.SELECTED_MAIN_BUTTON,
    payload: data,
  };
};
