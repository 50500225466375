import React, { useState, useEffect } from "react";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../../../constants/Colors";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersCallListAction } from "../../../redux/MainNavGeneral/Actions";
import { getShopsListAction } from "../../../redux/shop/Actions";
import { shopListByGroupIdAction } from "../../../redux/Users/Actions";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const CallerIdNumbers = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData: Object[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 45,
      minHeight: 45,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),

    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const dispatch = useDispatch();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    result_failed,
    result_message,
    result_successfull,
    loading,
    customers_call_list,
  } = useSelector((state) => state.MainNavGeneralRedusers);
  const { shops_list } = useSelector((state) => state.shopReducer);
  const [shopsList, setShopsList] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [phoneNumberList, setPhoneNumberList] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const handleSelectShopAndGetCustomersCallList = (item) => {
    dispatch(getCustomersCallListAction(login_key, user_id, item.ShopId, ""));
    setSelectedShop(item);
  };
  const handleSearchListByMobile = () => {
    if (searchText != "") {
      dispatch(
        getCustomersCallListAction(
          login_key,
          user_id,
          selectedShop.ShopId,
          searchText
        )
      );
    }
  };
  useEffect(() => {
    dispatch(getShopsListAction(login_key, user_id));
    dispatch(shopListByGroupIdAction(login_key, user_id, 0));
  }, []);
  useEffect(() => {
    if (shops_list) {
      if (shops_list.length > 0) {
        setSelectedShop(shops_list[0]);
        setShopsList(shops_list);
      }
    }
  }, [shops_list]);
  useEffect(() => {
    if (customers_call_list) {
      if (customers_call_list.length > 0) {
        setPhoneNumberList(customers_call_list);
      } else {
        setPhoneNumberList([]);
      }
    } else {
      setPhoneNumberList([]);
    }
  }, [customers_call_list]);

  useEffect(() => {
    if (searchText == "" && selectedShop) {
      dispatch(
        getCustomersCallListAction(
          login_key,
          user_id,
          selectedShop.ShopId,
          searchText
        )
      );
    }
  }, [searchText, shopsList]);

  useEffect(() => {
    const new_list = [];
    if (phoneNumberList) {
      if (phoneNumberList.length > 0) {
        for (let i = 0; i < phoneNumberList.length; i++) {
          new_list.push({ Mobile: phoneNumberList[i].Mobile });
        }
      }
    }
    setExcelList(new_list);
  }, [phoneNumberList]);

  return (
    <div
      className="countaining-div "
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <div className="menu_list_header_container">
        <div
          className="margin_menu_normal_horizontal"
          style={{ height: "100%" }}
        >
          <div className="caller-numbers-container ">
            <div className="sm-m">
              <h6 className="h6">* {lang.shop_list}</h6>
              <Select
                options={shopsList}
                defaultValue={selectedShop}
                isSearchable={false}
                onChange={(i, d) => handleSelectShopAndGetCustomersCallList(i)}
                value={selectedShop}
                // isMulti={true}
                styles={customStyles}
              />
            </div>
            <div className=" callerId-numbers-container-search-export-box">
              <div className=" callerId-numbers-container-search-box">
                <i class="fa fa-search" aria-hidden="true"></i>
                <input
                  // onFocus={() => {
                  //   setShowSearchTypeBox(true);
                  //   SetChangeStylesOnFocuse(true);
                  // }}
                  value={searchText}
                  onChange={(e) =>
                    setSearchText(e.target.value.replace(/\D/g, ""))
                  }
                  placeholder="Search by mobile "
                />
              </div>
              <div className="callerId-numbers-container-buttons">
                <button type="" onClick={handleSearchListByMobile}>
                  Search
                </button>
                <button
                  type=""
                  onClick={(e) => exportToCSV(excelList, "callerId-numbers")}
                >
                  {" "}
                  Export to Excel
                </button>{" "}
              </div>
            </div>
          </div>

          {phoneNumberList ? (
            phoneNumberList.length > 0 ? (
              <div className="callerid-numbers-container-main">
                {phoneNumberList.map((item, index) => (
                  <div
                    className="callerid-numbers-container-list"
                    style={{
                      backgroundColor: index % 2 == 0 ? "#f2f4f6" : "white",
                    }}
                  >
                    {item.Mobile}
                  </div>
                ))}
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CallerIdNumbers;
