import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDefaultAction } from "../../redux/menu/Actions";
import OTOSideBar from "./components/WePosSideBar";
import { Routes, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import OTOSetting from "./components/WePosSetting";
import MetaData from "./components/MetaData";
import Loader from "../../components/Loader";
const WePos = () => {
  const dispatch = useDispatch();
  const { result_failed, result_message, result_successfull, loading } =
    useSelector((state) => state.WePosRedusers);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());

      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  return (
    <div className="dashborard-index-countainer-div">
      <OTOSideBar />
      <Routes>
        <Route path="" element={<OTOSetting />} />
        <Route path="metadata" element={<MetaData />} />
      </Routes>
      {loading ? <Loader /> : null}
      <Toaster />
    </div>
  );
};

export default WePos;
