export const delivery_charge_type_side_bar = [
  { id: 1, title: "Settings", expanded: false },
  { id: 3, title: "Custom Charge", expanded: false },
];
export const general_delivery_charge = [
  { id: 1, title: "Settings", expanded: false },
  { id: 2, title: "Post Code", expanded: false },
  { id: 3, title: "FOF Post Code", expanded: false },
  { id: 4, title: "Area", expanded: false },
];
