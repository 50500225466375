import * as actionTypes from "../ActionTypes";

const initialStates = {
  category_list: [],

  selected_category: null,
  selected_category_option: null,
  category_options_list: [],
  show_hide: [],
  show_hide_web: [],
  show_hide_kiosk: [],
  printer_list: [],
  printer_list_id: [],
  report_section: [],
  report_section_id: [],
  product_group: [],
  product_group_id: [],
  vat: [],
  vat_id: [],
  font_family: [],
  font_family_id: [],
  font_size: [],
  font_size_id: [],
  link_to: [],
  link_to_id: [],
  gender: [],
  gender_id: [],
  job: [],
  job_id: [],
  device_type: [],
  device_access_group: [],
  device_access_group_id: [],
  device_type_id: [],
  order_status: [],
  order_status_id: [],
  callerId_values: [],
  callerId_ids: [],
  second_language: [],
  second_language_id: [],
  updating_successfull: false,
  updating_failed: false,
  loading_category_settings: false,
  category_update_message: "",
  get_category_list_loading: false,
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_category: false,
  product_option_list: [],
  selected_product_option_list: null,
  category_option_crust_list: [],
  selected_category_option_crust: null,
  licence_options_id: [],
  licence_options: [],
  dashboard_accessUser: [],
  dashboard_accessUser_id: [],
  dashboard_shop_group: [],
  dashboard_shop_group_id: [],
  payment_method: [],
  payment_method_id: [],
  delivery_charge_type: [],
  delivery_charge_type_id: [],
  handle_enter_key: false,
  change_selected_button_border_color_on_focus: false,
  change_selected_item_color_on_focus: false,
  clicked_item: 0,
  selected_item_in_all_lists: null,
  selected_item_in_some_lists: null,
  edit_box_parameters: [],
  just_title_and_price: 0,
  send_all_items_to_app: null,
  result_create_successfull: false,
  new_category_id: null,
  tab_status: false,
  get_product_open_close_list: [],
  product_open_close_time_popup: false,
  product_open_close_result_successfull: false,
  result_category_visible_successfull: false,
  get_category_open_close_list: [],
  show_category_availability_popup: false,
  category_open_close_time_popup_loading: false,
  change_sort_label: false,
  sort_number: 0,
  product_submenu_sort_number: 0,
  product_option_sort_number: 0,
};

const categoryList = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        loading_category_settings: false,
        get_category_list_loading: false,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: false,
        result_create_successfull: false,
        product_open_close_time_popup: false,
        product_open_close_result_successfull: false,
        result_category_visible_successfull: false,
        change_sort_label: false,
        category_open_close_time_popup_loading: false,
      };

    case actionTypes.TAB_STATUS:
      return {
        ...state,
        tab_status: action.payload,
      };
    case actionTypes.NEW_CATEGORY_ID:
      return {
        ...state,
        new_category_id: action.payload,
      };
    // CREATE PRODUCT
    case actionTypes.SELECTED_ITEM_IN_ALL_LISTS:
      return {
        ...state,
        selected_item_in_all_lists: action.payload,
      };
    case actionTypes.SELECTED_ITEM_IN_SOME_LISTS:
      return {
        ...state,
        selected_item_in_some_lists: action.payload,
      };
    case actionTypes.EDIT_BOX_PARAMETERS:
      return {
        ...state,
        edit_box_parameters: action.payload,
      };
    case actionTypes.JUST_TITLE_AND_PRICE:
      return {
        ...state,
        just_title_and_price: action.payload,
      };
    case actionTypes.SEND_ALL_ITEMS_TO_APP:
      return {
        ...state,
        send_all_items_to_app: action.payload,
      };
    case actionTypes.CREATE_PRODUCT_SAGA:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.CREATE_PRODUCT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.CREATE_PRODUCT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    // DELETE CATEGORY
    case actionTypes.DELETE_CATEGORY:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.DELETE_CATEGORY_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload.message,
        loading_category: false,
      };
    case actionTypes.DELETE_CATEGORY_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload.message,
        loading_category: false,
      };
    case actionTypes.GET_CATEGORY_SAGA:
      return {
        ...state,
        get_category_list_loading: true,
        // category_list: [],
      };
    case actionTypes.GET_CATEGORIES_FAILED:
      return {
        ...state,
        get_category_list_loading: false,
        category_list: [],

        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.CREATE_CATEGORY_SAGA:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
        result_create_successfull: false,
      };
    case actionTypes.SELECTED_CATEGORY:
      let my_list = [];
      let showHideWeb = [];
      let showHideKiosk = [];
      let printerList = [];
      let reportSectionList = null;
      let productGroupList = null;
      let vatList = null;
      let fontFamilyList = null;
      let fontSizeList = null;

      if (action.payload) {
        //  SET DEFAULT APP VISIBLE
        for (let i = 0; i < action.payload.AppVisible.length; i++) {
          if (action.payload.AppVisible[i] == "0") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 0) {
                my_list.push({ value: 0, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.AppVisible[i] == "1") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 1) {
                my_list.push({ value: 1, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.AppVisible[i] == "2") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 2) {
                my_list.push({ value: 2, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.AppVisible[i] == "3") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 3) {
                my_list.push({ value: 3, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.AppVisible[i] == "4") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 4) {
                my_list.push({ value: 4, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.AppVisible[i] == "5") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 5) {
                my_list.push({ value: 5, label: state.show_hide[i].label });
              }
            }
          }
        }

        //  SET DEFAULT WEB VISIBLE
        for (let i = 0; i < action.payload.WebVisible.length; i++) {
          if (action.payload.WebVisible[i] == "0") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 0) {
                showHideWeb.push({ value: 0, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.WebVisible[i] == "1") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 1) {
                showHideWeb.push({ value: 1, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.WebVisible[i] == "2") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 2) {
                showHideWeb.push({ value: 2, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.WebVisible[i] == "3") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 3) {
                showHideWeb.push({ value: 3, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.WebVisible[i] == "4") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 4) {
                showHideWeb.push({ value: 4, label: state.show_hide[i].label });
              }
            }
          } else if (action.payload.WebVisible[i] == "5") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 5) {
                showHideWeb.push({ value: 5, label: state.show_hide[i].label });
              }
            }
          }
        }
        //  SET DEFAULT KIOSK VISIBLE
        for (let i = 0; i < action.payload.KioskVisible.length; i++) {
          if (action.payload.KioskVisible[i] == "0") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 0) {
                showHideKiosk.push({
                  value: 0,
                  label: state.show_hide[i].label,
                });
              }
            }
          } else if (action.payload.KioskVisible[i] == "1") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 1) {
                showHideKiosk.push({
                  value: 1,
                  label: state.show_hide[i].label,
                });
              }
            }
          } else if (action.payload.KioskVisible[i] == "2") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 2) {
                showHideKiosk.push({
                  value: 2,
                  label: state.show_hide[i].label,
                });
              }
            }
          } else if (action.payload.KioskVisible[i] == "3") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 3) {
                showHideKiosk.push({
                  value: 3,
                  label: state.show_hide[i].label,
                });
              }
            }
          } else if (action.payload.KioskVisible[i] == "4") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 4) {
                showHideKiosk.push({
                  value: 4,
                  label: state.show_hide[i].label,
                });
              }
            }
          } else if (action.payload.KioskVisible[i] == "5") {
            for (let i = 0; i < state.show_hide.length; i++) {
              if (state.show_hide[i].value == 5) {
                showHideKiosk.push({
                  value: 5,
                  label: state.show_hide[i].label,
                });
              }
            }
          }
        }

        // SET DEFAULT PRINTER LIST
        if (action.payload.PrinterId) {
          for (let i = 0; i < action.payload.PrinterId.length; i++) {
            if (
              state.printer_list_id.includes(
                parseInt(action.payload.PrinterId[i])
              )
            ) {
              let index = state.printer_list_id.indexOf(
                parseInt(action.payload.PrinterId[i])
              );
              printerList.push({
                value: state.printer_list[index].value,
                label: state.printer_list[index].label,
              });
            }
          }
        }
        // SET DEFAULT REPORT SECTION LIST
        if (action.payload.ReportSection) {
          if (
            state.report_section_id.includes(
              parseInt(action.payload.ReportSection)
            )
          ) {
            let index = state.report_section_id.indexOf(
              parseInt(action.payload.ReportSection)
            );
            reportSectionList = {
              value: state.report_section[index].value,
              label: state.report_section[index].label,
            };
          }
        }
        // SET DEFAULT RRODUCT GROUP LIST
        if (action.payload.ProductGroup) {
          if (
            state.product_group_id.includes(
              parseInt(action.payload.ProductGroup)
            )
          ) {
            let index = state.product_group_id.indexOf(
              parseInt(action.payload.ProductGroup)
            );
            productGroupList = {
              value: state.product_group[index].value,
              label: state.product_group[index].label,
            };
          }
        }
        // SET DEFAULT VAT LIST
        if (action.payload.VatId) {
          if (state.vat_id.includes(parseInt(action.payload.VatId))) {
            let index = state.vat_id.indexOf(parseInt(action.payload.VatId));
            vatList = {
              value: state.vat[index].value,
              label: state.vat[index].label,
            };
          }
        }
        // SET DEFAULT FONT FAMILY LIST
        if (action.payload.AppTitleFontFamily) {
          if (
            state.font_family_id.includes(
              parseInt(action.payload.AppTitleFontFamily)
            )
          ) {
            let index = state.font_family_id.indexOf(
              parseInt(action.payload.AppTitleFontFamily)
            );
            fontFamilyList = {
              value: state.font_family[index].value,
              label: state.font_family[index].label,
            };
          }
        }
        // SET DEFAULT FONT SIZE LIST
        if (action.payload.AppTitleFontSize) {
          if (
            state.font_size_id.includes(
              parseInt(action.payload.AppTitleFontSize)
            )
          ) {
            let index = state.font_size_id.indexOf(
              parseInt(action.payload.AppTitleFontSize)
            );
            fontSizeList = {
              value: state.font_size[index].value,
              label: state.font_size[index].label,
            };
          }
        }
      }

      return {
        ...state,
        selected_category: action.payload
          ? {
              ...action.payload,
              AppVisible: my_list,
              WebVisible: showHideWeb,
              KioskVisible: showHideKiosk,
              PrinterId: printerList,
              ReportSection: reportSectionList,
              ProductGroup: productGroupList,
              VatId: vatList,
              AppTitleFontSize: fontSizeList,
              AppTitleFontFamily: fontFamilyList,
            }
          : null,
      };
    case actionTypes.GET_CATEGORIES_SUCCESSFULL:
      return {
        ...state,
        category_list: action.payload,

        get_category_list_loading: false,
      };
    case actionTypes.CREATE_CATEGORY_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_create_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
        handle_enter_key: false,
      };
    case actionTypes.CREATE_CATEGORY_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
        result_create_successfull: false,
      };
    case actionTypes.GET_DROPDOWN_SUCCESSFULL:
      let printerListId = [];
      let reportSectiontId = [];
      let productGroupId = [];
      let vatId = [];
      let fontFamilyId = [];
      let fontSizeId = [];
      let linkToId = [];
      let genderId = [];
      let jobId = [];
      let deviceTypeId = [];
      let deviceAccessGroupId = [];
      let secondLanguageId = [];
      let orderStatusId = [];
      let callerId = [];
      let licenceOptionsId = [];
      let DashboardAccessUserId = [];
      let DashboardShopGroupId = [];
      let paymentmethoId = [];
      let deliveryChargeTypeId = [];
      let myList = [];
      // GET PRINTER  LIST  ID
      if (action.payload[0].data) {
        for (let i = 0; i < action.payload[0].data.length; i++) {
          printerListId.push(action.payload[0].data[i].value);
        }
      }
      // GET REPORT  SECTION IDS
      if (action.payload[2].data) {
        for (let i = 0; i < action.payload[2].data.length; i++) {
          reportSectiontId.push(action.payload[2].data[i].value);
        }
      }
      // GET PRODUCT GROUP IDS
      if (action.payload[3].data) {
        for (let i = 0; i < action.payload[3].data.length; i++) {
          productGroupId.push(action.payload[3].data[i].value);
        }
      }

      // GET VAT IDS
      if (action.payload[4].data) {
        for (let i = 0; i < action.payload[4].data.length; i++) {
          vatId.push(action.payload[4].data[i].value);
        }
      }
      // GET FONT FAMILY IDS
      if (action.payload[5].data) {
        for (let i = 0; i < action.payload[5].data.length; i++) {
          fontFamilyId.push(action.payload[5].data[i].value);
        }
      }
      // GET FONT SIZES IDS
      if (action.payload[6].data) {
        for (let i = 0; i < action.payload[6].data.length; i++) {
          fontSizeId.push(action.payload[6].data[i].value);
        }
      }
      // GET LINK TO IDS
      if (action.payload[11].data) {
        for (let i = 0; i < action.payload[11].data.length; i++) {
          linkToId.push(action.payload[11].data[i].value);
        }
      }

      // GENDER
      if (action.payload[7].data) {
        for (let i = 0; i < action.payload[7].data.length; i++) {
          genderId.push(action.payload[7].data[i].value);
        }
      }

      // JOB
      if (action.payload[8].data) {
        for (let i = 0; i < action.payload[8].data.length; i++) {
          jobId.push(action.payload[8].data[i].value);
        }
      }
      // DEVICE TYPE
      if (action.payload[9].data) {
        for (let i = 0; i < action.payload[9].data.length; i++) {
          deviceTypeId.push(action.payload[9].data[i].value);
        }
      }
      // DEVICE TYPE
      if (action.payload[12].data) {
        for (let i = 0; i < action.payload[12].data.length; i++) {
          deviceAccessGroupId.push(action.payload[12].data[i].value);
        }
      }

      //LICEMCE OPTIONS
      if (action.payload[10].data) {
        for (let i = 0; i < action.payload[10].data.length; i++) {
          licenceOptionsId.push(action.payload[10].data[i].value);
        }
      }
      //DashboardAccessUser
      if (action.payload[13].data) {
        for (let i = 0; i < action.payload[13].data.length; i++) {
          DashboardAccessUserId.push(action.payload[13].data[i].value);
        }
      }
      //DashboardShopGroup
      if (action.payload[14].data) {
        for (let i = 0; i < action.payload[14].data.length; i++) {
          DashboardShopGroupId.push(action.payload[14].data[i].value);
        }
      }

      if (action.payload[15].data) {
        for (let i = 0; i < action.payload[15].data.length; i++) {
          paymentmethoId.push(action.payload[15].data[i].PayMethodId);
          myList.push({
            value: action.payload[15].data[i].PayMethodId,
            label: action.payload[15].data[i].PayMethodName,
          });
        }
      }

      if (action.payload[16].data) {
        for (let i = 0; i < action.payload[16].data.length; i++) {
          deliveryChargeTypeId.push(action.payload[16].data[i].value);
        }
      }
      if (action.payload[17].data) {
        for (let i = 0; i < action.payload[17].data.length; i++) {
          orderStatusId.push(action.payload[17].data[i].value);
        }
      }
      if (action.payload[18].data) {
        for (let i = 0; i < action.payload[18].data.length; i++) {
          callerId.push(action.payload[18].data[i].value);
        }
      }
      if (action.payload[19].data) {
        for (let i = 0; i < action.payload[19].data.length; i++) {
          secondLanguageId.push(action.payload[19].data[i].value);
        }
      }

      return {
        ...state,
        show_hide: action.payload[1].data,
        printer_list: action.payload[0].data,
        printer_list_id: printerListId,
        report_section: action.payload[2].data,
        report_section_id: reportSectiontId,
        product_group: action.payload[3].data,
        product_group_id: productGroupId,
        vat: action.payload[4].data,
        vat_id: vatId,
        font_family: action.payload[5].data,
        font_family_id: fontFamilyId,
        font_size: action.payload[6].data,
        font_size_id: fontSizeId,
        link_to: action.payload[11].data,
        link_to_id: linkToId,
        gender: action.payload[7].data,
        gender_id: genderId,
        job: action.payload[8].data,
        job_id: jobId,
        device_type: action.payload[9].data,
        device_type_id: deviceTypeId,
        licence_options: action.payload[10].data,
        licence_options_id: licenceOptionsId,
        device_access_group: action.payload[12].data,
        device_access_group_id: deviceAccessGroupId,
        dashboard_accessUser: action.payload[13].data,
        dashboard_accessUser_id: DashboardAccessUserId,
        dashboard_shop_group: action.payload[14].data,
        dashboard_shop_group_id: DashboardShopGroupId,
        payment_method: myList,
        payment_method_id: paymentmethoId,
        delivery_charge_type: action.payload[16].data,
        delivery_charge_type_id: deliveryChargeTypeId,
        order_status: action.payload[17].data,
        order_status_id: orderStatusId,
        callerId_ids: callerId,
        callerId_values: action.payload[18].data,
        second_language: action.payload[19].data,
        second_language_id: secondLanguageId,
      };

    case actionTypes.GET_DROPDOWN_FAILED:
      return {
        ...state,
        show_hide: [],
        printer_list: [],
        report_section: [],
        product_group: [],
        vat: [],
      };
    case actionTypes.SEND_CATEGORY_SETTINGS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.SEND_PRODUCT_SETTINGS:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.GET_PRODUCTS:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.GET_PRODUCT_SUBMENU:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.SEND_CATEGORY_SETTINGS_SUCCESSFULL:
      // let my_cat_list = [];
      // for (let i = 0; i < state.category_list.length; i++) {
      //   if (state.category_list[i].Id == action.payload.update_category.Id) {
      //     my_cat_list = [...state.category_list];
      //     my_cat_list[i] = {
      //       ...my_cat_list[i],

      //       AppTitle: action.payload.update_category.AppTitle,
      //       AppDescription: action.payload.update_category.AppDescription,
      //       WebTitle: action.payload.update_category.WebTitle,
      //       WebDescription: action.payload.update_category.WebDescription,
      //       KioskTitle: action.payload.update_category.KioskTitle,
      //       KioskDescription: action.payload.update_category.KioskDescription,
      //       SecondLanguage: action.payload.update_category.SecondLanguage,
      //       AppVisible: action.payload.update_category.AppVisible,
      //       WebVisible: action.payload.update_category.WebVisible,
      //       KioskVisible: action.payload.update_category.KioskVisible,
      //       AppSortId: action.payload.update_category.AppSortId,
      //       WebSortId: action.payload.update_category.WebSortId,
      //       KioskSortId: action.payload.update_category.KioskSortId,
      //       PrinterId: action.payload.update_category.PrinterId,
      //       ReportSection: action.payload.update_category.ReportSection,
      //       ProductGroup: action.payload.update_category.ProductGroup,
      //       IncludeHalfAndHalf:
      //         action.payload.update_category.IncludeHalfAndHalf,
      //       VatId: action.payload.update_category.VatId,
      //       IsSugessted: action.payload.update_category.IsSugessted,
      //       IsPopular: action.payload.update_category.IsPopular,
      //       AppBgColor: action.payload.update_category.AppBgColor,
      //       AppTitleFontSize: action.payload.update_category.AppTitleFontSize,
      //       AppTitleFontFamily:
      //         action.payload.update_category.AppTitleFontFamily,
      //       AppTitleColor: action.payload.update_category.AppTitleColor,
      //       AppTileWidth: action.payload.update_category.AppTileWidth,
      //       AppTileHeight: action.payload.update_category.AppTileHeight,
      //       AppImageUrl: action.payload.update_category.AppImageUrl,
      //       AppVisibleImage: action.payload.update_category.AppVisibleImage,
      //       WebImageUrl: action.payload.update_category.WebImageUrl,
      //       WebVisibleImage: action.payload.update_category.WebVisibleImage,
      //       KioskImageUrl: action.payload.update_category.KioskImageUrl,
      //       KioskVisibleImage: action.payload.update_category.KioskVisibleImage,
      //     };
      //   }
      // }

      return {
        ...state,
        // category_list: my_cat_list,
        // updating_successfull: true,
        // updating_failed: false,
        // loading_category_settings: false,
        // category_update_message: action.payload.resultMesage,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload.resultMesage,
        loading_category: false,
      };
    case actionTypes.SEND_PRODUCT_SETTINGS_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload.resultMesage,
        loading_category: false,
      };
    case actionTypes.GET_PRODUCTS_SUCCESSFULL:
      return {
        ...state,
        loading_category: false,
      };
    case actionTypes.GET_PRODUCT_SUBMENU_SUCCESSFULL:
      return {
        ...state,
        loading_category: false,
      };
    case actionTypes.GET_PRODUCTS_FAILED:
      return {
        ...state,
        loading_category: false,
      };
    case actionTypes.GET_PRODUCT_SUBMENU_FAILED:
      return {
        ...state,
        loading_category: false,
      };
    case actionTypes.SEND_CATEGORY_SETTINGS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.SEND_PRODUCT_SETTINGS_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    //GET CATEGORY OPTIONS
    case actionTypes.GET_CATEGORY_OPTIONS:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.GET_CATEGORY_OPTIONS_SUCCESSFULL:
      return {
        ...state,
        category_options_list: action.payload,
        loading_category: false,
      };
    case actionTypes.GET_CATEGORY_OPTIONS_FAILED:
      return {
        ...state,
        category_options_list: [],
        loading_category: false,
        result_failed: true,
        resultMesage: action.payload,
      };
    case actionTypes.SET_SELECTED_CATEGORY_OPTION:
      return {
        ...state,
        selected_category_option: action.payload,
      };
    //CREATE CATEGORY OPTIONS
    case actionTypes.CREATE_CATEGORY_OPTION:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.CREATE_CATEGORY_OPTION_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.CREATE_CATEGORY_OPTION_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    //SEND CATEGORY OPTIONS
    case actionTypes.SEND_CATEGORY_OPTION:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.SEND_CATEGORY_OPTION_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.SEND_CATEGORY_OPTION_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    //DELETE CATEGORY OPTIONS
    case actionTypes.DELETE_CATEGORY_OPTION:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.DELETE_CATEGORY_OPTION_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.DELETE_CATEGORY_OPTION_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    //DELETE PRODUCT
    case actionTypes.DELETE_PRODUCT:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.DELETE_PRODUCT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.DELETE_PRODUCT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    //DELETE PRODUCT SUBMENU
    case actionTypes.DELETE_PRODUCT_SUBMENU:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.DELETE_PRODUCT_SUBMENU_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.DELETE_PRODUCT_SUBMENU_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    //ADD PRODUCT SUBMENU
    case actionTypes.ADD_PRODUCT_SUBMENU:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.ADD_PRODUCT_SUBMENU_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.ADD_PRODUCT_SUBMENU_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    // PRODUCT  OPTION
    case actionTypes.GET_PRODUCT_OPTION:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.GET_PRODUCT_OPTION_SUCCESSFULL:
      return {
        ...state,
        product_option_list: action.payload,
        loading_category: false,
      };
    case actionTypes.SELECTED_PRODUCT_OPTION_LIST:
      return {
        ...state,
        selected_product_option_list: action.payload,
      };

    case actionTypes.GET_PRODUCT_OPTION_FAILED:
      return {
        ...state,
        product_option_list: [],
        loading_category: false,
      };
    case actionTypes.SEND_PRODUCT_OPTION_EDIT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.SEND_PRODUCT_OPTION_EDIT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.SEND_PRODUCT_OPTION_EDIT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.SEND_PRODUCT_OPTION_EDIT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };

    case actionTypes.SEND_PRODUCT_OPTION_EDIT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };

    case actionTypes.UPDATE_PRODUCT_SUBMENU_EDIT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_PRODUCT_SUBMENU_EDIT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_PRODUCT_SUBMENU_EDIT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.GET_CATEGORY_OPTION_CRUST:
      return {
        ...state,
        loading_category: true,
      };
    case actionTypes.GET_CATEGORY_OPTION_CRUST_SUCCESSFULL:
      return {
        ...state,
        loading_category: false,
        category_option_crust_list: action.payload,
      };
    case actionTypes.GET_CATEGORY_OPTION_CRUST_FAILED:
      return {
        ...state,
        loading_category: false,
        category_option_crust_list: [],
      };
    case actionTypes.CREATE_CATEGORY_OPTION_CRUST:
      return {
        ...state,
        loading_category: true,
        result_failed: false,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.CREATE_CATEGORY_OPTION_CRUST_SUCCESSFULL:
      return {
        ...state,
        loading_category: false,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
      };
    case actionTypes.CREATE_CATEGORY_OPTION_CRUST_FAILED:
      return {
        ...state,
        loading_category: false,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
      };
    case actionTypes.DELETE_CATEGORY_OPTION_CRUST:
      return {
        ...state,
        loading_category: true,
        result_failed: false,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.DELETE_CATEGORY_OPTION_CRUST_SUCCESSFULL:
      return {
        ...state,

        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.DELETE_CATEGORY_OPTION_CRUST_FAILED:
      return {
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.SELECTED_CATEGORY_OPTION_CRUST:
      return {
        ...state,
        selected_category_option_crust: action.payload,
      };

    case actionTypes.UPDATE_CATEGORY_OPTION_CRUST:
      return {
        ...state,
        loading_category: true,
        result_failed: false,
        result_successfull: false,
        result_message: "",
      };
    case actionTypes.UPDATE_CATEGORY_OPTION_CRUST_SUCCESSFULL:
      return {
        ...state,

        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_CATEGORY_OPTION_CRUST_FAILED:
      return {
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.CHANGE_SELECTED_BUTTON_BORDER_COLOR_ON_FOCUS:
      return {
        ...state,
        change_selected_button_border_color_on_focus: action.payload,
      };
    case actionTypes.CHANGE_SELECTED_ITEM_COLOR_ON_FOCUS:
      return {
        ...state,
        change_selected_item_color_on_focus: action.payload,
      };
    case actionTypes.CLICKED_ITEM:
      return {
        ...state,
        clicked_item: action.payload,
      };
    case actionTypes.CREATE_PRODUCT_OPEN_CLOSE_TIME:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        product_open_close_result_successfull: false,
        result_message: "",
        product_open_close_time_popup: true,
      };
    case actionTypes.CREATE_PRODUCT_OPEN_CLOSE_TIME_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        product_open_close_result_successfull: true,
        result_message: action.payload,
        loading_category: false,
        product_open_close_time_popup: false,
      };
    case actionTypes.CREATE_PRODUCT_OPEN_CLOSE_TIME_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        product_open_close_result_successfull: false,
        loading_category: false,
        product_open_close_time_popup: false,
      };
    //GET PRODUCT OPEN CLOSE LIST
    case actionTypes.GET_PRODUCT_OPEN_CLOSE_LIST:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        product_open_close_time_popup: true,
      };

    case actionTypes.GET_PRODUCT_OPEN_CLOSE_LIST_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        get_product_open_close_list: action.payload,
        product_open_close_time_popup: false,
      };

    case actionTypes.GET_PRODUCT_OPEN_CLOSE_LIST_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: false,
        get_product_open_close_list: [],
        product_open_close_time_popup: false,
      };

    case actionTypes.EDIT_CATEGORY_STATUS:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
        result_category_visible_successfull: false,
      };
    case actionTypes.EDIT_CATEGORY_STATUS_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
        result_category_visible_successfull: true,
      };
    case actionTypes.EDIT_CATEGORY_STATUS_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: false,
        result_category_visible_successfull: false,
      };
    case actionTypes.EDIT_PRODUCT_STATUS:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.EDIT_PRODUCT_STATUS_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.EDIT_PRODUCT_STATUS_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: false,
      };

    case actionTypes.CREATE_CATEGORY_OPEN_CLOSE_TIME:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        product_open_close_result_successfull: false,
        result_message: "",
        show_category_availability_popup: true,
        category_open_close_time_popup_loading: true,
      };
    case actionTypes.CREATE_CATEGORY_OPEN_CLOSE_TIME_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        product_open_close_result_successfull: true,
        result_message: action.payload,
        loading_category: false,
        show_category_availability_popup: false,
        category_open_close_time_popup_loading: false,
      };
    case actionTypes.CREATE_CATEGORY_OPEN_CLOSE_TIME_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        product_open_close_result_successfull: false,
        loading_category: false,
        show_category_availability_popup: true,
        category_open_close_time_popup_loading: false,
      };
    //GET CATEGORY OPEN CLOSE LIST
    case actionTypes.GET_CATEGORY_OPEN_CLOSE_LIST:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        category_open_close_time_popup_loading: true,
      };
    case actionTypes.GET_CATEGORY_OPEN_CLOSE_LIST_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        get_category_open_close_list: action.payload,
        category_open_close_time_popup_loading: false,
      };
    case actionTypes.GET_CATEGORY_OPEN_CLOSE_LIST_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: false,
        get_category_open_close_list: [],
        category_open_close_time_popup_loading: false,
      };
    case actionTypes.SHOW_CATEGORY_AVAILABILITY_POPUP:
      return {
        ...state,
        show_category_availability_popup: action.payload,
      };

    //UPDATE CATEGORY WEB SORT
    case actionTypes.UPDATE_CATEGORY_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_CATEGORY_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_CATEGORY_SORT_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: true,
      };

    //UPDATE CATEGORY APP SORT
    case actionTypes.UPDATE_CATEGORY_APP_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_CATEGORY_APP_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
        change_sort_label: true,
      };
    case actionTypes.UPDATE_CATEGORY_APP_SORT_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: true,
      };

    case actionTypes.CHANGE_SORT_LABEL:
      return {
        ...state,
        change_sort_label: action.payload,
      };
    //UPDATE PRODUCT WEB SORT
    case actionTypes.UPDATE_PRODUCT_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_PRODUCT_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_PRODUCT_SORT_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: true,
      };

    //UPDATE CATEGORY APP SORT
    case actionTypes.UPDATE_PRODUCT_APP_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_PRODUCT_APP_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
        change_product_sort_label: true,
      };
    case actionTypes.UPDATE_PRODUCT_APP_SORT_FAILED:
      return {
        ...state,
        result_failed: true,
        result_successfull: false,
        result_message: action.payload,
        loading_category: true,
      };
    case actionTypes.CHANGE_PRODUCT_SORT_LABEL:
      return {
        ...state,
        change_product_sort_label: action.payload,
      };
    case actionTypes.SORT_NUMBER:
      return {
        ...state,
        sort_number: action.payload,
      };
    //UPDATE SUBMENU WEB SORT
    case actionTypes.UPDATE_PRODUCT_SUBMENU_WEB_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_PRODUCT_SUBMENU_WEB_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_PRODUCT_SUBMENU_WEB_SORT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_PRODUCT_SUBMENU_APP_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_PRODUCT_SUBMENU_APP_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_PRODUCT_SUBMENU_APP_SORT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.PRODUCT_SUBMENU_SORT_NUMBER:
      return {
        ...state,
        product_submenu_sort_number: action.payload,
      };

    //UPDATE OPTION WEB SORT
    case actionTypes.UPDATE_PRODUCT_OPTION_WEB_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_PRODUCT_OPTION_WEB_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_PRODUCT_OPTION_WEB_SORT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_PRODUCT_OPTION_APP_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_category: true,
      };
    case actionTypes.UPDATE_PRODUCT_OPTION_APP_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.UPDATE_PRODUCT_OPTION_APP_SORT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_category: false,
      };
    case actionTypes.PRODUCT_OPTION_SORT_NUMBER:
      return {
        ...state,
        product_option_sort_number: action.payload,
      };
    default:
      return state;
  }
};
export default categoryList;
