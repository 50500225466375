import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../../components/Loader";
import DatePicker from "react-datepicker";
// import "react-calendar/dist/Calendar.css";
import Carousel, { consts } from "react-elastic-carousel";

import { main_nav_button_color } from "../../../../constants/Colors";
import { getSaleOverviewListAction } from "../../../../redux/sales/Actions";
const Overview = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    sale_method_list,
    pay_method_list,
    result_successfull,
    result_failed,
    result_message,
    loading_sales,
  } = useSelector((state) => state.salesRedusers);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const [selectedCourseKey, setSelectedCourseKey] = useState(1);
  const [selectedDayRange, setSelectedDayRange] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const handleGetSales = (key) => {
    setSelectedCourseKey(key);
    let date = new Date();

    let newDate = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
      newDate.setDate(newDate.getDate() - 1);
    }

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay();

    let decreased_days = day == 0 ? day + 6 : day - 1;
    let new_date = newDate;

    new_date.setDate(new_date.getDate() - decreased_days);

    let daysLength = new Date(year, month + 1, 0).getDate();
    let lastmonth = new Date(year, 12, 0).getDate();
    let this_month =
      month < 9 ? `${year}-0${month + 1}-01` : `${year}-${month + 1}-01`;
    if (key == 1) {
      setStartDate(date);
      setEndDate(date);
    } else if (key == 2) {
      setStartDate(new_date);
      setEndDate(date);
    } else if (key == 3) {
      setStartDate(new Date(this_month));
      setEndDate(date);
    } else if (key == 4) {
      setStartDate(new Date(`${year}-01-01`));
      setEndDate(date);
    } else if (key == 5) {
      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
    }

    if (
      moment(startDate).format("YYYY-MM-DD") <=
      moment(endDate).format("YYYY-MM-DD")
    ) {
      if (selected_shop) {
        if (selected_shop.length > 0) {
          dispatch(
            getSaleOverviewListAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              key == 1
                ? moment(date).format("YYYY-MM-DD")
                : key == 2
                ? moment(new_date).format("YYYY-MM-DD")
                : key == 3
                ? this_month
                : key == 4
                ? `${year}-01-01`
                : key == 5
                ? moment(startDate).format("YYYY-MM-DD")
                : "",
              key == 1
                ? moment(date).format("YYYY-MM-DD")
                : key == 2
                ? moment(date).format("YYYY-MM-DD")
                : key == 3
                ? moment(date).format("YYYY-MM-DD")
                : key == 4
                ? moment(date).format("YYYY-MM-DD")
                : key == 5
                ? moment(endDate).format("YYYY-MM-DD")
                : ""
            )
          );
        }
      }
    } else {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.start_date_end_date_in_order_list}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 5000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };

  useEffect(() => {
    let date = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
    }
    setStartDate(date);
    setEndDate(date);
    if (selected_shop) {
      if (selected_shop.length > 0) {
        setSelectedCourseKey(1);

        dispatch(
          getSaleOverviewListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            moment(date).format("YYYY-MM-DD"),
            moment(date).format("YYYY-MM-DD")
          )
        );
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    let sum = 0;
    let count = 0;
    if (sale_method_list) {
      if (sale_method_list.length > 0) {
        for (let i = 0; i < sale_method_list.length; i++) {
          sum = sum + sale_method_list[i].Total;
          count = count + sale_method_list[i].OrderCount;
        }
      }
    }
    setTotal(sum);
    setCount(count);
  }, [sale_method_list, pay_method_list]);

  const type = consts.PREV;

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i
          class="fa fa-chevron-left"
          aria-hidden="true"
          style={{ fontSize: "20px", marginTop: "5px" }}
        ></i>
      ) : (
        <i
          class="fa fa-chevron-right"
          aria-hidden="true"
          style={{
            fontSize: "20px",
            marginTop: "5px",
            marginLeft: "-16px",
          }}
        ></i>
      );
    return (
      <button onClick={onClick} disabled={isEdge} style={{ border: "none" }}>
        {pointer}
      </button>
    );
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 330, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 450, itemsToShow: 3, itemsToScroll: 2, pagination: false },
    { width: 580, itemsToShow: 4, itemsToScroll: 2, pagination: false },
    { width: 800, itemsToShow: 5, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 5, pagination: false },
    { width: 1150, itemsToShow: 5, itemsToScroll: 2, pagination: false },
  ];
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <div
      className="countaining-div countaining-div-11"
      id="countaining"
      style={{
        borderLeftWidth: width1 <= 560 ? "none" : 0.3,
        borderLeftColor: width1 <= 560 ? "none" : main_nav_button_color,
        borderLeftStyle: width1 <= 560 ? "none" : "solid",
        backgroundColor: "#f1f1f1",
      }}
    >
      <div
        className=" menu_list_header_container_tabel_list mobile-overview-container-all-sections"
        style={{
          overflowY: "auto",
        }}
      >
        {width1 <= 560 ? (
          <>
            <div className="sale_overview_container margin_menu_normal_horizontal mobile-overview-container1">
              <div className=" mobile-overview-container-courses">
                <button
                  style={{
                    backgroundColor: selectedCourseKey == 1 ? "red" : null,
                    color: selectedCourseKey == 1 ? "white" : null,
                  }}
                  onClick={() => handleGetSales(1)}
                >
                  {lang.today}
                </button>
                <button
                  style={{
                    backgroundColor: selectedCourseKey == 2 ? "red" : null,
                    color: selectedCourseKey == 2 ? "white" : null,
                  }}
                  onClick={() => handleGetSales(2)}
                >
                  {lang.week}
                </button>
                <button
                  style={{
                    backgroundColor: selectedCourseKey == 3 ? "red" : null,
                    color: selectedCourseKey == 3 ? "white" : null,
                  }}
                  onClick={() => handleGetSales(3)}
                >
                  {lang.month}
                </button>
                <button
                  style={{
                    backgroundColor: selectedCourseKey == 4 ? "red" : null,
                    color: selectedCourseKey == 4 ? "white" : null,
                  }}
                  onClick={() => handleGetSales(4)}
                >
                  {lang.year}
                </button>
              </div>
              <div className="mobile-overview-container-dates">
                <div style={{ width: "37%" }}>
                  <h6 className="h6">{lang.from}</h6>

                  <DatePicker
                    placeholderText="From"
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={(e) => setStartDate(e)}
                    className="date_picker_sales"

                    // calendarClassName="calendarClassName"
                  />
                </div>
                <div style={{ width: "37%" }}>
                  <h6 className="h6">{lang.to}</h6>

                  <DatePicker
                    placeholderText="To"
                    dateFormat="yyyy-MM-dd"
                    selected={endDate}
                    onChange={(e) => setEndDate(e)}
                    className="date_picker_sales"
                  />
                </div>
                <div className="sales-container-result-button mobbile-container-result-button">
                  <button onClick={() => handleGetSales(5)}>Result</button>
                </div>
              </div>
              <br />
              <div style={{ flex: 1 }}>
                <div
                  className="mobile-overview-container-sale-method"
                  style={{
                    marginTop: sale_method_list
                      ? !sale_method_list.length > 0
                        ? "150px"
                        : null
                      : "150px",
                  }}
                >
                  <h5>{lang.sale_method}:</h5>
                  <div className="mobile-overview-container-sale-method-details">
                    {" "}
                    {sale_method_list ? (
                      sale_method_list.length > 0 ? (
                        sale_method_list.map((item, index) => (
                          <div className=" mobile-overview-container-any-sale-method">
                            <p>{item.SaleMethodName}</p>
                            <div>
                              <h4>£{item.Total.toFixed(2)}</h4>
                              <p>
                                <span>{item.OrderCount}</span>{" "}
                                {item.OrderCount == 0 || item.OrderCount == 1
                                  ? "order"
                                  : "orders"}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h4>{lang.no_order}</h4>
                      )
                    ) : (
                      <h4>{lang.no_order}</h4>
                    )}
                  </div>
                </div>
                <div className="mobile-overview-container-sale-method">
                  <h5>{lang.payment_maethod}:</h5>
                  <div className="mobile-overview-container-sale-method-details">
                    {" "}
                    {pay_method_list ? (
                      pay_method_list.length > 0 ? (
                        pay_method_list.map((item, index) => (
                          <div className=" mobile-overview-container-any-sale-method">
                            <p>{item.PaymethodName}</p>
                            <div>
                              <h4>£{item.Total.toFixed(2)}</h4>
                              <p>
                                <span>{item.OrderCount}</span>{" "}
                                {item.OrderCount == 0 || item.OrderCount == 1
                                  ? "order"
                                  : "orders"}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h4>{lang.no_order}</h4>
                      )
                    ) : (
                      <h4>{lang.no_order}</h4>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="sale_overview_container_Total mobile-overview-container-total-box">
              <div>
                <h5>£{total ? total.toFixed(2) : "00.00"}</h5>
                <h6 style={{ margin: 0 }}>
                  {count ? count : 0}{" "}
                  {count == 0 || count == 1 ? "order" : "orders"}
                </h6>
              </div>
            </div>
          </>
        ) : (
          <div className="margin_menu_normal_horizontal sale_overview_container">
            <div className="sale_overview_container_calander">
              <div className="sale_overview_container_calander_courses">
                <button
                  style={{
                    backgroundColor: selectedCourseKey == 1 ? "red" : null,
                    color: selectedCourseKey == 1 ? "white" : null,
                  }}
                  onClick={() => handleGetSales(1)}
                >
                  {lang.today}
                </button>
                <button
                  style={{
                    backgroundColor: selectedCourseKey == 2 ? "red" : null,
                    color: selectedCourseKey == 2 ? "white" : null,
                  }}
                  onClick={() => handleGetSales(2)}
                >
                  {lang.week}
                </button>
                <button
                  style={{
                    backgroundColor: selectedCourseKey == 3 ? "red" : null,
                    color: selectedCourseKey == 3 ? "white" : null,
                  }}
                  onClick={() => handleGetSales(3)}
                >
                  {lang.month}
                </button>
                <button
                  style={{
                    backgroundColor: selectedCourseKey == 4 ? "red" : null,
                    color: selectedCourseKey == 4 ? "white" : null,
                  }}
                  onClick={() => handleGetSales(4)}
                >
                  {lang.year}
                </button>
              </div>

              <div className="sale_overview_container_from_to">
                <div style={{ width: "40%" }}>
                  <h6 className="h6">{lang.from}</h6>

                  <DatePicker
                    placeholderText="From"
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={(e) => setStartDate(e)}
                    className="date_picker_sales"
                    // calendarClassName="calendarClassName"
                  />
                </div>
                <div style={{ width: "40%" }}>
                  <h6 className="h6">{lang.to}</h6>

                  <DatePicker
                    placeholderText="To"
                    dateFormat="yyyy-MM-dd"
                    selected={endDate}
                    onChange={(e) => setEndDate(e)}
                    className="date_picker_sales"
                  />
                </div>
                <div className="sales-container-result-button">
                  <button onClick={() => handleGetSales(5)}>Result</button>
                </div>
              </div>
              <div className="sale_overview_container_Total">
                <h6 className="h6">{lang.total}</h6>
                <div>
                  <h5>£{total ? total.toFixed(2) : "00.00"}</h5>
                  <h6>
                    {count ? count : 0}{" "}
                    {count == 0 || count == 1 ? "order" : "orders"}
                  </h6>
                </div>
              </div>
            </div>

            <div className="sale_overview_container_saleMethod">
              <h6>{lang.sale_method}:</h6>
              <div className="sale_overview_container_saleMethods">
                {sale_method_list ? (
                  sale_method_list.length > 0 ? (
                    sale_method_list.length > 5 ? (
                      <Carousel
                        breakPoints={breakPoints}
                        itemsToShow={5}
                        className="carousel"
                        renderArrow={myArrow}
                        renderPagination={({ pages, activePage, onClick }) => {
                          return (
                            <div direction="row">
                              {pages.map((page) => {
                                // const isActivePage = activePage === page;
                                return (
                                  <div
                                    style={{ display: "none" }}
                                    key={page}
                                    onClick={() => onClick(page)}
                                    // active={isActivePage}
                                  />
                                );
                              })}
                            </div>
                          );
                        }}
                      >
                        {sale_method_list.map((item, index) => (
                          <div
                            className="sale_overview_container_any_salemethod"
                            style={{ height: "138px" }}
                          >
                            <p>{item.SaleMethodName}</p>
                            <div>
                              <h4>£{item.Total.toFixed(2)}</h4>
                              <p>
                                <span>{item.OrderCount}</span>{" "}
                                {item.OrderCount == 0 || item.OrderCount == 1
                                  ? "order"
                                  : "orders"}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      sale_method_list.map((item, index) => (
                        <div className="sale_overview_container_any_salemethod">
                          <p>{item.SaleMethodName}</p>
                          <div>
                            <h4>£{item.Total.toFixed(2)}</h4>
                            <p>
                              <span>{item.OrderCount}</span>{" "}
                              {item.OrderCount == 0 || item.OrderCount == 1
                                ? "order"
                                : "orders"}
                            </p>
                          </div>
                        </div>
                      ))
                    )
                  ) : (
                    // </Carousel>
                    <h4 className="sales_h3_no_order">{lang.no_order}</h4>
                  )
                ) : (
                  <h4 className="sales_h3_no_order">{lang.no_order}</h4>
                )}
              </div>
            </div>

            <div className="sale_overview_container_saleMethod">
              <h6>{lang.payment_maethod}:</h6>
              <div className="sale_overview_container_saleMethods">
                {pay_method_list ? (
                  pay_method_list.length > 0 ? (
                    pay_method_list.length > 5 ? (
                      <Carousel
                        breakPoints={breakPoints}
                        itemsToShow={5}
                        className="carousel"
                        renderArrow={myArrow}
                        renderPagination={({ pages, activePage, onClick }) => {
                          return (
                            <div direction="row">
                              {pages.map((page) => {
                                // const isActivePage = activePage === page;
                                return (
                                  <div
                                    style={{ display: "none" }}
                                    key={page}
                                    onClick={() => onClick(page)}
                                    // active={isActivePage}
                                  />
                                );
                              })}
                            </div>
                          );
                        }}
                      >
                        {pay_method_list.map(
                          (item, index) => (
                            // item.PaymethodId != 2000101 ? (
                            <div
                              className="sale_overview_container_any_salemethod"
                              style={{ height: "138px" }}
                            >
                              {" "}
                              <p>{item.PaymethodName}</p>
                              <div>
                                <h4>£{item.Total.toFixed(2)}</h4>
                                <p>
                                  <span>{item.OrderCount}</span>{" "}
                                  {item.OrderCount == 0 || item.OrderCount == 1
                                    ? "order"
                                    : "orders"}
                                </p>
                              </div>
                            </div>
                          )
                          // ) : null
                        )}
                      </Carousel>
                    ) : (
                      pay_method_list.map(
                        (item, index) => (
                          // item.PaymethodId != 2000101 ? (
                          <div className="sale_overview_container_any_salemethod">
                            {" "}
                            <p>{item.PaymethodName}</p>
                            <div>
                              <h4>£{item.Total.toFixed(2)}</h4>
                              <p>
                                <span>{item.OrderCount}</span>{" "}
                                {item.OrderCount == 0 || item.OrderCount == 1
                                  ? "order"
                                  : "orders"}
                              </p>
                            </div>
                          </div>
                        )
                        // ) : null
                      )
                    )
                  ) : (
                    <h4 className="sales_h3_no_order">{lang.no_order}</h4>
                  )
                ) : (
                  <h4 className="sales_h3_no_order">{lang.no_order}</h4>
                )}
              </div>
            </div>

            {/* <div className="sale_overview_container_total_box">
            <div className="sale_overview_container_total">
              <h4>{lang.total}</h4>
              <div>
                <h1>
                  £
                  {deliverySaleMethod
                    ? collectionSaleMethod
                      ? (
                          deliverySaleMethod.Total + collectionSaleMethod.Total
                        ).toFixed()
                      : "00.00"
                    : "00.00"}
                </h1>
                <h6>
                  {deliverySaleMethod
                    ? collectionSaleMethod
                      ? deliverySaleMethod.OrderCount +
                        collectionSaleMethod.OrderCount
                      : 0
                    : 0}{" "}
                  orders
                </h6>
              </div>
            </div>
          </div> */}

            {/* <div className="sale_overview_container_date">
              {moment(selectedDayRange).format("YYYY-MM-DD") ==
              moment(new Date()).format("YYYY-MM-DD") ? (
                <div className="sale_overview_container_choose_date_header_section_today_date">
                  {" "}
                  <p>Choose date</p>
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="sale_overview_container_choose_date_header_section">
                  <p>
                    From:{" "}
                    {selectedDayRange[0]
                      ? moment(selectedDayRange[0]).format("YYYY-MM-DD")
                      : null}{" "}
                  </p>{" "}
                  <p style={{ marginLeft: "15px" }}>
                    To:{" "}
                    {selectedDayRange[1]
                      ? moment(selectedDayRange[1]).format("YYYY-MM-DD")
                      : null}
                  </p>
                </div>
              )}

              <div className="sale_overview_container_choose_date_main_section">
                {" "}
                <Calendar
                  value={selectedDayRange}
                  onChange={setSelectedDayRange}
                  selectRange={true}
                />
              </div>
            </div> */}
          </div>
        )}
      </div>
      {loading_sales ? <Loader /> : null}
    </div>
  );
};

export default Overview;
