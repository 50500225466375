import React, { useState, useEffect } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";

import SubmenuEdit from "../../components/SubmenuEdit";
import SubmenuList from "../../components/SubmenuList";
import Loader from "../../components/Loader";
import SubmenuItem from "../../components/SubmenuItem";
import SubmenuItemEdit from "../../components/SubmenItemEdit";

import { setDefaultAction } from "../../redux/menu/Actions";
import {
  createSubmenuAction,
  getSubmenuAction,
  selectedSubmenuAction,
} from "../../redux/submenu/Action";
import DressingList from "../../components/DressingList";
import DressingEdit from "../../components/DressingEdit";

const override: CSSProperties = {
  display: "block",
  margin: "0px auto",
  borderColor: "red",
};

const Dressing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const {
    loading_submenu,
    result_message,
    result_failed,
    result_successfull,
    submenu_list,
  } = useSelector((state) => state.submenuRedusers);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);

  const [dressingList, setDressingList] = useState([]);
  let [color, setColor] = useState("red");
  const [clickedItem, setClickedItem] = useState(1);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="common-styles"
      style={{
        height: window.innerHeight - 160,
      }}
    >
      <div className="container-components-div">
        {clickedItem == 1 ? (
          <DressingList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 2 ? (
          <DressingEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 3 ? (
          <SubmenuItem setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 4 ? (
          <SubmenuItemEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 5 ? (
          <SubmenuItem setClickedItem={(i) => setClickedItem(i)} />
        ) : null}
      </div>
      {loading_submenu ? <Loader /> : null}

      <Toaster />
    </div>
  );
};

export default Dressing;
