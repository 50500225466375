import React, { useState, useEffect } from "react";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import SaveMenuButton from "../../../components/SaveMenuButton";
import {
  getPrivacyPolicyListAction,
  updatePrivacyPolicyAction,
} from "../../../redux/MainNavGeneral/Actions";
const PrivacyPolicyEdit = () => {
  const dispatch = useDispatch();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);

  const { privacy_policy_list } = useSelector(
    (state) => state.MainNavGeneralRedusers
  );

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (login_key && user_id) {
      dispatch(getPrivacyPolicyListAction(login_key, user_id));
    }
  }, []);

  useEffect(() => {
    if (privacy_policy_list) {
      if (privacy_policy_list.length > 0) {
        setMessage(privacy_policy_list[0].PrivacyPolicy);
      }
    }
  }, [privacy_policy_list]);
  const handleSave = () => {
    dispatch(updatePrivacyPolicyAction(login_key, user_id, message));
  };

  return (
    <div
      className="countaining-div "
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div style={{ marginTop: "25px" }}>
            <div className="general-setting-about-container">
              <h6>{lang.pricavy_policy}</h6>
              <textarea
                className="general-privacy-policy-textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>{" "}
      <SaveMenuButton clickButton={handleSave} />
    </div>
  );
};

export default PrivacyPolicyEdit;
