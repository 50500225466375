import React, { useRef, useState, forwardRef, useEffect } from "react";

import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ListHeader from "../components/ListHeader";
import SaveMenuButton from "../components/SaveMenuButton";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import { sendCategoryOptionAction } from "../redux/menu/Actions";

const CategoryOptions = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    selected_category_option,
    vat,
    selected_category,
    result_successfull,
  } = useSelector((state) => state.categoryList);
  const [appTitle, setAppTitle] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [kioskTitle, setKioskTitle] = useState("");
  const [kioskDesc, setKioskDesc] = useState("");
  const [appSort, setAppSort] = useState(0);
  const [webSort, setWebSort] = useState(0);
  const [kioskSort, setKioskSort] = useState(0);
  const [visibleOnApp, setVisibleOnApp] = useState(true);
  const [visibleOnWeb, setVisibleOnWeb] = useState(true);
  const [visibleOnKiosk, setVisibleOnKiosk] = useState(true);
  const [multiSelectPrice, setMultiSelectPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [selectedVat, setSelectedVat] = useState(null);
  const [secondLanguage, setSecondLanguage] = useState("");
  const [amountDelivery, setAmountDelivery] = useState(0);
  const [amountCollection, setAmountCollection] = useState(0);
  const [amountTakeaway, setAmountTakeaway] = useState(0);
  const [amountEtIn, setAmountEtIn] = useState(0);
  const [priceLevel_1_main, setPriceLevel_1_main] = useState(0);
  const [priceLevel_1_collection, setPriceLevel_1_collection] = useState(0);
  const [priceLevel_1_eatin, setPriceLevel_1_eatin] = useState(0);
  const [priceLevel_2_main, setPriceLevel_2_main] = useState(0);
  const [priceLevel_2_collection, setPriceLevel_2_collection] = useState(0);
  const [priceLevel_2_eatin, setPriceLevel_2_eatin] = useState(0);
  const [priceLevel_3_main, setPriceLevel_3_main] = useState(0);
  const [priceLevel_3_collection, setPriceLevel_3_collection] = useState(0);
  const [priceLevel_3_eatin, setPriceLevel_3_eatin] = useState(0);
  const [priceLevel_4_main, setPriceLevel_4_main] = useState(0);
  const [priceLevel_4_collection, setPriceLevel_4_collection] = useState(0);
  const [priceLevel_4_eatin, setPriceLevel_4_eatin] = useState(0);
  const [priceLevel_5_main, setPriceLevel_5_main] = useState(0);
  const [priceLevel_5_collection, setPriceLevel_5_collection] = useState(0);
  const [priceLevel_5_eatin, setPriceLevel_5_eatin] = useState(0);
  const [priceLevel_6_main, setPriceLevel_6_main] = useState(0);
  const [priceLevel_6_collection, setPriceLevel_6_collection] = useState(0);
  const [priceLevel_6_eatin, setPriceLevel_6_eatin] = useState(0);
  const [priceLevel_7_main, setPriceLevel_7_main] = useState(0);
  const [priceLevel_7_collection, setPriceLevel_7_collection] = useState(0);
  const [priceLevel_7_eatin, setPriceLevel_7_eatin] = useState(0);
  const [priceLevel_8_main, setPriceLevel_8_main] = useState(0);
  const [priceLevel_8_collection, setPriceLevel_8_collection] = useState(0);
  const [priceLevel_8_eatin, setPriceLevel_8_eatin] = useState(0);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleSaveClicked = () => {
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else
      dispatch(
        sendCategoryOptionAction(
          login_key,
          user_id,
          selected_category_option.Id,
          selected_category_option.ShopId,
          selected_category_option.CategoryId,
          appTitle.replaceAll(`"`, `\"\"`),
          appDesc.replaceAll(`"`, `\"\"`),
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          webDesc.replaceAll(`"`, `\"\"`),
          kioskTitle
            ? kioskTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`),
          kioskDesc.replaceAll(`"`, `\"\"`),
          secondLanguage.replaceAll(`"`, `\"\"`),
          multiSelectPrice ? parseFloat(multiSelectPrice) : 0,
          priceLevel_1_main ? parseFloat(priceLevel_1_main) : 0,
          priceLevel_2_main ? parseFloat(priceLevel_2_main) : 0,
          priceLevel_3_main ? parseFloat(priceLevel_3_main) : 0,
          priceLevel_4_main ? parseFloat(priceLevel_4_main) : 0,
          priceLevel_5_main ? parseFloat(priceLevel_5_main) : 0,
          priceLevel_6_main ? parseFloat(priceLevel_6_main) : 0,
          priceLevel_7_main ? parseFloat(priceLevel_7_main) : 0,
          priceLevel_8_main ? parseFloat(priceLevel_8_main) : 0,
          priceLevel_1_collection ? parseFloat(priceLevel_1_collection) : 0,
          priceLevel_2_collection ? parseFloat(priceLevel_2_collection) : 0,
          priceLevel_3_collection ? parseFloat(priceLevel_3_collection) : 0,
          priceLevel_4_collection ? parseFloat(priceLevel_4_collection) : 0,
          priceLevel_5_collection ? parseFloat(priceLevel_5_collection) : 0,
          priceLevel_6_collection ? parseFloat(priceLevel_6_collection) : 0,
          priceLevel_7_collection ? parseFloat(priceLevel_7_collection) : 0,
          priceLevel_8_collection ? parseFloat(priceLevel_8_collection) : 0,
          priceLevel_1_eatin ? parseFloat(priceLevel_1_eatin) : 0,
          priceLevel_2_eatin ? parseFloat(priceLevel_2_eatin) : 0,
          priceLevel_3_eatin ? parseFloat(priceLevel_3_eatin) : 0,
          priceLevel_4_eatin ? parseFloat(priceLevel_4_eatin) : 0,
          priceLevel_5_eatin ? parseFloat(priceLevel_5_eatin) : 0,
          priceLevel_6_eatin ? parseFloat(priceLevel_6_eatin) : 0,
          priceLevel_7_eatin ? parseFloat(priceLevel_7_eatin) : 0,
          priceLevel_8_eatin ? parseFloat(priceLevel_8_eatin) : 0,
          selectedVat ? selectedVat.value : 0,
          visibleOnApp ? 1 : 0,
          visibleOnWeb ? 1 : 0,
          visibleOnKiosk ? 1 : 0,
          0,
          appSort ? parseInt(appSort) : 0,
          webSort ? parseInt(webSort) : 0,
          kioskSort ? parseInt(kioskSort) : 0,
          0,
          "",
          "",
          0,
          amount ? parseFloat(amount) : 0,
          amountCollection ? parseFloat(amountCollection) : 0,
          amountTakeaway ? parseFloat(amountTakeaway) : 0,
          amountEtIn ? parseFloat(amountEtIn) : 0
        )
      );
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    appTitle,
    appDesc,
    webTitle,
    webDesc,
    kioskTitle,
    kioskDesc,
    amountCollection,
    amountDelivery,
    amountEtIn,
    amountTakeaway,
    multiSelectPrice,
    priceLevel_1_main,
    priceLevel_2_main,
    priceLevel_3_main,
    priceLevel_4_main,
    priceLevel_5_main,
    priceLevel_6_main,
    priceLevel_7_main,
    priceLevel_8_main,
    priceLevel_1_collection,
    priceLevel_2_collection,
    priceLevel_3_collection,
    priceLevel_4_collection,
    priceLevel_5_collection,
    priceLevel_6_collection,
    priceLevel_7_collection,
    priceLevel_8_collection,
    priceLevel_1_eatin,
    priceLevel_2_eatin,
    priceLevel_3_eatin,
    priceLevel_4_eatin,
    priceLevel_5_eatin,
    priceLevel_6_eatin,
    priceLevel_7_eatin,
    priceLevel_8_eatin,
    selectedVat,
    visibleOnApp,
    visibleOnWeb,
    visibleOnKiosk,
    appSort,
    webSort,
    kioskSort,
    secondLanguage,
  ]);

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (selected_category_option) {
      setAppTitle(selected_category_option.AppTitle);
      setAppDesc(selected_category_option.ShortName);
      setWebTitle(selected_category_option.WebTitle);
      setWebDesc(selected_category_option.WebDescription);
      setKioskTitle(selected_category_option.KioskTitle);
      setKioskDesc(selected_category_option.KioskDescription);
      setAppSort(selected_category_option.AppSortId);
      setWebSort(selected_category_option.WebSortId);
      setKioskSort(selected_category_option.KioskSortId);
      setMultiSelectPrice(selected_category_option.MultiSelectPrice);
      setSelectedVat(selected_category_option.VATId);
      setVisibleOnApp(selected_category_option.AppVisible);
      setVisibleOnWeb(selected_category_option.WebVisible);
      setVisibleOnKiosk(selected_category_option.KioskVisible);
      setPriceLevel_1_main(selected_category_option.PriceLevelMain1);
      setPriceLevel_2_main(selected_category_option.PriceLevelMain2);
      setPriceLevel_3_main(selected_category_option.PriceLevelMain3);
      setPriceLevel_4_main(selected_category_option.PriceLevelMain4);
      setPriceLevel_5_main(selected_category_option.PriceLevelMain5);
      setPriceLevel_6_main(selected_category_option.PriceLevelMain6);
      setPriceLevel_7_main(selected_category_option.PriceLevelMain7);
      setPriceLevel_8_main(selected_category_option.PriceLevelMain8);
      setPriceLevel_1_collection(selected_category_option.PriceLevelCollTake1);
      setPriceLevel_2_collection(selected_category_option.PriceLevelCollTake2);
      setPriceLevel_3_collection(selected_category_option.PriceLevelCollTake3);
      setPriceLevel_4_collection(selected_category_option.PriceLevelCollTake4);
      setPriceLevel_5_collection(selected_category_option.PriceLevelCollTake5);
      setPriceLevel_6_collection(selected_category_option.PriceLevelCollTake6);
      setPriceLevel_7_collection(selected_category_option.PriceLevelCollTake7);
      setPriceLevel_8_collection(selected_category_option.PriceLevelCollTake8);
      setPriceLevel_1_eatin(selected_category_option.PriceLevelEatIn1);
      setPriceLevel_2_eatin(selected_category_option.PriceLevelEatIn2);
      setPriceLevel_3_eatin(selected_category_option.PriceLevelEatIn3);
      setPriceLevel_4_eatin(selected_category_option.PriceLevelEatIn4);
      setPriceLevel_5_eatin(selected_category_option.PriceLevelEatIn5);
      setPriceLevel_6_eatin(selected_category_option.PriceLevelEatIn6);
      setPriceLevel_7_eatin(selected_category_option.PriceLevelEatIn7);
      setPriceLevel_8_eatin(selected_category_option.PriceLevelEatIn8);
      setAmount(selected_category_option.Price);
      setAmountCollection(selected_category_option.CollectionPrice);
      setAmountEtIn(selected_category_option.EatInPrice);
      setAmountTakeaway(selected_category_option.TakeawayPrice);
      setSecondLanguage(selected_category_option.SecondLanguage);
    }
  }, [selected_category_option]);

  useEffect(() => {
    if (selected_category && didMountRef.current) {
      setClickedItem(3);
    }
    didMountRef.current = true;
  }, [selected_category]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(3);
    }
  }, [result_successfull]);

  return (
    <div className="countaining-div">
      {/* BACK & TITLE */}
      <ListHeader
        title={lang.category_options_edit}
        show_back={true}
        clickedItem={() => setClickedItem(3)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {/* TITLES */}
          <div className="div-1 ">
            {/* APP TITLES */}
            <div className="text">
              <h6 className="title h6">{lang.app_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setAppTitle(e.target.value)}
                  type="text"
                  value={appTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setAppDesc(e.target.value)}
                  type="text"
                  value={appDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
            {/* WEB TITLE */}
            <div className="text">
              <h6 className="title h6">{lang.web_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setWebTitle(e.target.value)}
                  type="text"
                  value={webTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setWebDesc(e.target.value)}
                  type="text"
                  value={webDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
            {/* KIOSK TITLE */}
            <div
              className="text "
              // className="category-option-dropdown-div
            >
              <h6 className="title h6">{lang.kiosk_title}</h6>
              <div className="div-1-1 ">
                <input
                  onChange={(e) => setKioskTitle(e.target.value)}
                  type="text"
                  value={kioskTitle}
                  className="input-1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setKioskDesc(e.target.value)}
                  type="text"
                  value={kioskDesc}
                  className="input-2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
          </div>

          {/* SHOW HIDE */}
          <div className="div-2">
            {/* VISIBLE ON APP */}
            <div className=" div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnApp}
                      onChange={() => setVisibleOnApp(!visibleOnApp)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_app}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON WEB */}
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnWeb}
                      onChange={() => setVisibleOnWeb(!visibleOnWeb)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_web}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON KIOSK */}
            <div className="div-chechbox-p text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnKiosk}
                      onChange={() => setVisibleOnKiosk(!visibleOnKiosk)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_kiosk}
                />
              </FormGroup>
            </div>
            {/* ***** */}
          </div>

          {/* SORT */}
          <div className="div-3 ">
            <div className="text">
              <h6 className="sort-title h6">{lang.sort_app}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                type="text"
                onChange={(e) => setAppSort(e.target.value.replace(/\D/g, ""))}
                value={appSort}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort_web}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={webSort}
                type="text"
                onChange={(e) => setWebSort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort_kiosk}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={kioskSort}
                type="text"
                onChange={(e) =>
                  setKioskSort(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
          </div>
          <div className="div-3 ">
            <div className="text">
              <h6 className="h6"> {lang.secoundLanguage}</h6>
              <input
                className="div-14-inputText input-padding"
                value={secondLanguage}
                type="text"
                onChange={(e) => setSecondLanguage(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div className="list_header_container-general category-0ption-general-div">
            <div>
              <h6 id="general_text">{lang.general}</h6>
            </div>
          </div>
          <div className="poe-div4">
            <div className="poe-div4-div">
              <h6 className="poe-div4-h6 h6"> {lang.amount_delivery}</h6>
              <input
                className="poe-input input-padding"
                value={amount}
                onChange={(e) =>
                  setAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="poe-div4-div">
              <h6 className="poe-div4-h6 h6">{lang.amount_collection}</h6>
              <input
                className="poe-input input-padding"
                value={amountCollection}
                onChange={(e) =>
                  setAmountCollection(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="poe-div4-div">
              <h6 className="poe-div4-h6 h6">{lang.amount_takeaway}</h6>
              <input
                className="poe-input input-padding"
                onChange={(e) =>
                  setAmountTakeaway(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
                value={amountTakeaway}
              />
            </div>
            <div className="poe-div4-div">
              <h6 className="poe-div4-h6 h6">{lang.amount_eatIn}</h6>
              <input
                className="poe-input input-padding"
                value={amountEtIn}
                onChange={(e) =>
                  setAmountEtIn(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
          </div>
          <div className="div-6-categoryOption">
            <div className="text" style={{ width: "49%" }}>
              <h6 className="h6 categoryOption-text">
                {lang.multi_select_price}
              </h6>
              <input
                className="div-6-categoryOption-input input-padding"
                value={multiSelectPrice}
                type="text"
                onChange={(e) =>
                  setMultiSelectPrice(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            {/* <div className="text">
              <h6 className="h6 categoryOption-text">{lang.amount}</h6>
              <input
                className="div-6-categoryOption-input input-padding"
                value={amount}
                type="text"
                onChange={(e) =>
                  setAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div> */}
            <div className="text" style={{ width: "49%" }}>
              <h6 className="h6 categoryOption-content">{lang.vat}</h6>
              <Select
                options={vat}
                defaultValue={selectedVat}
                onChange={(list, item) => {
                  setSelectedVat(list);
                }}
                value={selectedVat}
                styles={customStyles}
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="div-7-categoryOption">
            <div className="div-7-categoryOption-div-h6">
              <h6 className="h6">{lang.main}</h6>
              <h6 className="h6 div-7-categoryOption-Takeaway">
                {lang.takeaway}//{lang.collection}
              </h6>
              <h6 className="h6 div-7-categoryOption-eatin">{lang.eat_in}</h6>
            </div>
            <div className="div-7-1-categoryOption">
              <p>{lang.price_level}1</p>
              <div
                className="div-7-2-categoryOption"
                style={{
                  marginLeft: "19px",
                }}
              >
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_1_main}
                  onChange={(e) =>
                    setPriceLevel_1_main(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_1_collection}
                  onChange={(e) =>
                    setPriceLevel_1_collection(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_1_eatin}
                  onChange={(e) =>
                    setPriceLevel_1_eatin(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
              </div>
            </div>
            <div className="div-7-1-categoryOption">
              <p>{lang.price_level}2</p>
              <div className="div-7-2-categoryOption">
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_2_main}
                  onChange={(e) =>
                    setPriceLevel_2_main(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_2_collection}
                  onChange={(e) =>
                    setPriceLevel_2_collection(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_2_eatin}
                  onChange={(e) =>
                    setPriceLevel_2_eatin(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
              </div>
            </div>
            <div className="div-7-1-categoryOption">
              <p>{lang.price_level}3</p>
              <div className="div-7-2-categoryOption">
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_3_main}
                  onChange={(e) =>
                    setPriceLevel_3_main(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_3_collection}
                  onChange={(e) =>
                    setPriceLevel_3_collection(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_3_eatin}
                  onChange={(e) =>
                    setPriceLevel_3_eatin(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
              </div>
            </div>
            <div className="div-7-1-categoryOption">
              <p>{lang.price_level}4</p>
              <div className="div-7-2-categoryOption">
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_4_main}
                  onChange={(e) =>
                    setPriceLevel_4_main(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_4_collection}
                  onChange={(e) =>
                    setPriceLevel_4_collection(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_4_eatin}
                  onChange={(e) =>
                    setPriceLevel_4_eatin(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
              </div>
            </div>
            <div className="div-7-1-categoryOption">
              <p>{lang.price_level}5</p>
              <div className="div-7-2-categoryOption">
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_5_main}
                  onChange={(e) =>
                    setPriceLevel_5_main(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_5_collection}
                  onChange={(e) =>
                    setPriceLevel_5_collection(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_5_eatin}
                  onChange={(e) =>
                    setPriceLevel_5_eatin(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
              </div>
            </div>
            <div className="div-7-1-categoryOption">
              <p>{lang.price_level}6</p>
              <div className="div-7-2-categoryOption">
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_6_main}
                  onChange={(e) =>
                    setPriceLevel_6_main(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_6_collection}
                  onChange={(e) =>
                    setPriceLevel_6_collection(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_6_eatin}
                  onChange={(e) =>
                    setPriceLevel_6_eatin(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
              </div>
            </div>
            <div className="div-7-1-categoryOption">
              <p>{lang.price_level}7</p>
              <div className="div-7-2-categoryOption">
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_7_main}
                  onChange={(e) =>
                    setPriceLevel_7_main(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_7_collection}
                  onChange={(e) =>
                    setPriceLevel_7_collection(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_7_eatin}
                  onChange={(e) =>
                    setPriceLevel_7_eatin(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
              </div>
            </div>
            <div className="div-7-1-categoryOption">
              <p>{lang.price_level}8</p>
              <div className="div-7-2-categoryOption">
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_8_main}
                  onChange={(e) =>
                    setPriceLevel_8_main(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_8_collection}
                  onChange={(e) =>
                    setPriceLevel_8_collection(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
                <input
                  className="div-7-categoryOption-input input-padding"
                  type="text"
                  value={priceLevel_8_eatin}
                  onChange={(e) =>
                    setPriceLevel_8_eatin(
                      e.target.value.replace(/[^\d\.,£]+/g, "")
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSaveClicked()} />
    </div>

    // </div>
  );
};

export default CategoryOptions;
