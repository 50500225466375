import React, { useEffect, useState, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import store from "store2";
import { profile } from "../constants/Profile";
import { visibleCreateShopPopupAction } from "../redux/shop/Actions";
import { setDefaultLogin } from "../redux/login/Actions";
import { getDropDownAction } from ".././redux/menu/Actions";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Select from "react-select";
import ListItem from "@mui/material/ListItem";
// import { primary_color } from "../constants/Colors";
import {
  getShopsListSuccessfullAction,
  getNewShopListActin,
  getShopsListAction,
} from "../redux/shop/Actions";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../constants/Colors";
const TopNav = () => {
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shopList, setShopList] = useState(null);
  const [shopsList, setShopsList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const [userAccesses, setUserAccesses] = useState([]);
  const [rolesid, setRolesId] = useState([]);
  const [userType, setUserType] = useState(0);
  const [accessShopId, setAccessShopId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(1);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const { shop_access_list, result_login_successfull, user_type } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop, shops_list } = useSelector(
    (state) => state.shopReducer
  );
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <i
        style={{ fontSize: 30, marginRight: 10 }}
        class="fa fa-user-circle-o"
        aria-hidden="true"
      ></i>
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </span>
  ));
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // width: "250px",
      height: 40,
      minHeight: " 40px",
      overflowY: "auto",

      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      overflowY: "auto",
      maxHeight: "250px",
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleVisibleCreateNewShop = () => {
    dispatch(visibleCreateShopPopupAction(true));
  };
  const handleChangeDropDownValue = (str, sl) => {
    dispatch(getShopsListSuccessfullAction(shops_list, [str]));
  };
  const handleProfilePage = (item) => {
    navigate(item.path);
  };
  const handleSelectedItem = (key) => {
    setSelectedItem(key);
  };

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  // console.log(document.getElementById("top_nav_profile_container").offsetWidth);
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        setShopList(selected_shop[0]);
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (shopList) {
      dispatch(getDropDownAction(login_key, user_id, shopList.ShopId));
    }
  }, [shopList]);

  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);
  useEffect(() => {
    let roles_id = [];
    let access_shop_id = null;
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          roles_id.push(userAccesses[i].RoleId);
          access_shop_id = userAccesses[i].AccessShopId;
          setUserType(userAccesses[0].UserType);
        }
        setRolesId(roles_id);
        setAccessShopId(access_shop_id);
      }
    }
  }, [userAccesses]);

  useEffect(() => {
    let copy_shopsList = [...shops_list];
    let shop_list = [];
    if (userType != "") {
      if (userType == 10001201) {
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        } else {
          if (selected_shop) {
            if (selected_shop.length > 0) {
              if (selected_shop[0].ShopId == copy_shopsList[0].ShopId) {
                setShopList(copy_shopsList[0]);
              } else {
                setShopList(selected_shop[0]);
              }
            }
          }
        }

        setShopsList(copy_shopsList);
      } else {
        if (copy_shopsList) {
          if (copy_shopsList.length > 0) {
            for (let i = 0; i < copy_shopsList.length; i++) {
              if (accessShopId) {
                if (accessShopId == copy_shopsList[i].ShopId) {
                  shop_list.push(copy_shopsList[i]);
                }
              } else {
                if (shop_access_list) {
                  if (shop_access_list.length > 0) {
                    for (
                      let index = 0;
                      index < shop_access_list.length;
                      index++
                    ) {
                      if (
                        copy_shopsList[i].ShopId ==
                        shop_access_list[index].ShopId
                      ) {
                        shop_list.push(copy_shopsList[i]);
                        if (
                          selected_shop[0].ShopId == copy_shopsList[0].ShopId
                        ) {
                          setShopList(shop_list[0]);
                        } else {
                          setShopList(selected_shop[0]);
                        }
                      }
                    }
                  }
                }
              }
            }

            setShopsList(shop_list);
            dispatch(getNewShopListActin(shop_list));
          }
        }
      }
    } else {
      if (selected_shop) {
        if (selected_shop.length > 0) {
          if (selected_shop[0].ShopId == copy_shopsList[0].ShopId) {
            setShopList(selected_shop[0]);
          } else {
            setShopList(selected_shop[0]);
          }
        }
      }

      setShopsList(copy_shopsList);
      // setShopList(copy_shopsList[0]);
    }
  }, [shop_access_list, accessShopId, shops_list]);
  const loging_response = store.get("login_response");
  useEffect(() => {
    if (shopsList) {
      if (shopsList.length > 0) {
        dispatch(getShopsListSuccessfullAction(shops_list, [shopList]));
      }
    }
  }, [shopsList, shops_list]);
  return width1 <= 560 ? (
    <div className="tap_nav_container">
      <div className="mobile-container-shop-list-dropdown">
        {shopsList ? (
          shopsList.length > 0 ? (
            (shopsList.length == 1 && !accessShopId) || accessShopId ? (
              shopsList.map((item) => (
                <div className="side-bar-first-div-1">{item.label}</div>
              ))
            ) : (
              <div className="side-bar-first-div">
                <Select
                  options={shopsList}
                  defaultValue={shopList}
                  isSearchable={true}
                  onChange={handleChangeDropDownValue}
                  value={shopList}
                  // isMulti={true}
                  styles={customStyles}
                />
              </div>
            )
          ) : null
        ) : null}
      </div>
      <div className="container-drawer-icon">
        <i
          className="fa fa-bars"
          aria-hidden="true"
          onClick={() => setOpenDrawer(true)}
        ></i>
      </div>{" "}
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List
          onClick={() => setOpenDrawer(false)}
          style={{
            width: 250,
            fontSize: 14,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="drawer_menu_item_main_container">
            <ListItem
              onClick={() => {
                navigate("orders");
                setSelectedItem(1);
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: selectedItem == 1 ? primaryColor : null,
                color: selectedItem == 1 ? white : null,
              }}
            >
              <div className="responsive-container-listitem-details">
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                <p style={{ margin: 0 }}>{lang.orders}</p>
              </div>
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("menustatus");
                setSelectedItem(2);
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: selectedItem == 2 ? primaryColor : null,
                color: selectedItem == 2 ? white : null,
              }}
            >
              <div className="responsive-container-listitem-details">
                <i className="fa fa-bars" aria-hidden="true"></i>
                <p style={{ margin: 0 }}>{lang.menu_status}</p>
              </div>
            </ListItem>

            <ListItem
              onClick={() => {
                navigate("sales/overview");
                setSelectedItem(3);
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: selectedItem == 3 ? primaryColor : null,
                color: selectedItem == 3 ? white : null,
              }}
            >
              <div className="responsive-container-listitem-details">
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
                <p style={{ margin: 0 }}>{lang.sales}</p>
              </div>
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("webSetting/maintanance");
                setSelectedItem(4);
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: selectedItem == 4 ? primaryColor : null,
                color: selectedItem == 4 ? white : null,
              }}
            >
              <div className="responsive-container-listitem-details">
                <i className="fa fa-wrench" aria-hidden="true"></i>
                <p style={{ margin: 0 }}>{lang.maintenance}</p>
              </div>
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("webSetting/promotion");
                setSelectedItem(5);
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: selectedItem == 5 ? primaryColor : null,
                color: selectedItem == 5 ? white : null,
              }}
            >
              <div className="responsive-container-listitem-details">
                <i class="fas fa-percentage"></i>
                <p style={{ margin: 0 }}>{lang.promotions}</p>
              </div>
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("worksStations", { state: { id: 1 } });
                setSelectedItem(6);
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: selectedItem == 6 ? primaryColor : null,
                color: selectedItem == 6 ? white : null,
              }}
            >
              <div className="responsive-container-listitem-details">
                <i class="fa fa-television"></i>
                <p style={{ margin: 0 }}>{lang.workstation_status}</p>
              </div>
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("worksStations", { state: { id: 2 } });
                setSelectedItem(7);
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: selectedItem == 7 ? primaryColor : null,
                color: selectedItem == 7 ? white : null,
              }}
            >
              <div className="responsive-container-listitem-details">
                <i class="fa fa-television"></i>
                <p style={{ margin: 0 }}>{lang.workstation_list}</p>
              </div>
            </ListItem>
          </div>
          <div>
            <ListItem
              onClick={() => {
                dispatch(setDefaultLogin());
                navigate("/");
                store.remove("login_response");
                store.remove("selectedShop");
              }}
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="responsive-container-listitem-details">
                <img
                  style={{ width: "25px", height: "25px" }}
                  src="/images/logout3.jpeg"
                  alt=""
                />{" "}
                {/* <i class="fas fa-sign-out-alt"></i> */}
                <h5 style={{ margin: 0 }}>{lang.log_out}</h5>
              </div>
            </ListItem>
          </div>
        </List>
      </Drawer>
    </div>
  ) : (
    <div className="tap_nav_container">
      {/* <img
        alt=""
        src="images/shop.jpg"
        width="200"
        height={65}
        className="d-inline-block align-top"
        style={{ marginRight: 30, borderRadius: "3px" }}
      /> */}

      <div className="top_nav_container_shopTilte">
        <h4>
          {selected_shop
            ? selected_shop.length > 0
              ? selected_shop[0].Title
              : null
            : null}
        </h4>
      </div>
      <div
        className="top_nav_profile_container"
        style={{ width: "231px", justifyContent: "flex-end" }}
      >
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            {/* <span
            onClick={() => dispatch(visibleCreateShopPopupAction(true))}
            >
              <i
                style={{ fontSize: 30, marginRight: 10 }}
                class="fa fa-user-circle-o"
                aria-hidden="true"
              ></i>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span> */}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* {profile.map((item) => (
              <Dropdown.Item
                // variant="success"
                onClick={() => {
                  handleProfilePage(item);
                }}
              >
                {item.title}
              </Dropdown.Item>
            ))}
            <hr /> */}
            <Dropdown.Item
              onClick={() => {
                dispatch(setDefaultLogin());
                navigate("/");
                store.remove("login_response");
                store.remove("selectedShop");
              }}
            >
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* CREATE NEW BUTTON  */}
        {userType == 10001201 ? (
          <div className="create_new_shop_container">
            <button onClick={handleVisibleCreateNewShop}>
              <i class="fa fa-plus" aria-hidden="true"></i>
              {lang.create_new_shop}
            </button>
          </div>
        ) : rolesid ? (
          rolesid.length > 0 ? (
            rolesid.includes(70) ? (
              <div className="create_new_shop_container">
                <button onClick={handleVisibleCreateNewShop}>
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  {lang.create_new_shop}
                </button>
              </div>
            ) : null
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default TopNav;
