import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import { usePopperTooltip } from "react-popper-tooltip";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import format from "date-fns/format";
import moment from "moment";
import Loader from "../../../../components/Loader";
import {
  primaryColor,
  white,
  main_nav_button_color,
  main_nav_back_color,
} from "../../../../constants/Colors";
import { Maintenace_type, force_close_order } from "./List";
import {
  getMaintenanceListAction,
  updateMaintenanceAction,
} from "../../../../redux/settings/Action";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import SaveMenuButton from "../../../../components/SaveMenuButton";
const MaintenanceMode = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      closeOnOutsideClick: true,
    });
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const {
    loading_appSettings,
    web_payment_list,
    result_failed,
    result_successfull,
    result_message,
    maintenance_message_list,
    get_maintenance_list,
  } = useSelector((state) => state.appSettingsRedusers);
  const [maintenanceType, setMaintenanceType] = useState(null);
  const [activeDateAndTimePicker, setActiveDateAndTimePicker] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [maintenanceReasonesList, setMaintenanceReasonesList] = useState([]);
  const [maintenanceReasones, setMaintenanceReasones] = useState(null);
  const [forceCloseOrder, SetForceCloseOrder] = useState(null);
  const [date, setDate] = useState(new Date());
  const [previousDate, setPreviousDate] = useState(new Date());

  const [enableAsap, setEnableAsap] = useState(true);
  const [onlyAsap, setOnlyAsap] = useState(false);
  const [preeOrder, setPreeOrder] = useState(true);
  const [nextDayPreeOrder, setNextDayPreeOrder] = useState(false);
  const [preeOrderMessage, setPreeOrderMessage] = useState("");
  const [closedOrderMessage, setClosedOrderMessage] = useState("");
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [scrollStatus, setScrollStatus] = useState(false);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "black",
      fontSize: "15px",
      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      zIndex: "3333333",
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const handleSelectedMaintenanceType = (list) => {
    setMaintenanceType(list);
    const copy_Maintenace_type = [...Maintenace_type];

    for (let i = 0; i < copy_Maintenace_type.length; i++) {
      if (list.value == copy_Maintenace_type[i].value) {
        if (copy_Maintenace_type[i].label == "Until selected date") {
          setActiveDateAndTimePicker(true);
        } else {
          setActiveDateAndTimePicker(false);
        }
      }
    }
  };
  const handleMaintenanceCheckbox = () => {
    let copy_maintenanceReasonesList = [...maintenanceReasonesList];
    let copy_maintenanceType = [...Maintenace_type];
    setMaintenanceMode(!maintenanceMode);
    if (!maintenanceMode) {
      if (copy_maintenanceReasonesList) {
        for (let i = 0; i < copy_maintenanceReasonesList.length; i++) {
          setMaintenanceReasones(copy_maintenanceReasonesList[0]);
        }
      }
      if (copy_maintenanceType) {
        for (let i = 0; i < copy_maintenanceType.length; i++) {
          setMaintenanceType(copy_maintenanceType[1]);
        }
      }
    } else {
      setMaintenanceReasones(null);
      setMaintenanceType(null);
    }
  };

  const handleSaveMaintenance = () => {
    if (maintenanceMode && maintenanceReasones == null) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (preeOrder) {
      if (preeOrderMessage == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (forceCloseOrder == null) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateMaintenanceAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            maintenanceMode ? 1 : 0,
            maintenanceReasones ? maintenanceReasones.label : "",
            forceCloseOrder ? parseInt(forceCloseOrder.value) : 0,
            onlyAsap ? 1 : 0,
            closedOrderMessage ? closedOrderMessage : "",
            enableAsap ? 1 : 0,
            nextDayPreeOrder ? 1 : 0,
            preeOrder ? 1 : 0,
            preeOrderMessage ? preeOrderMessage : "",
            maintenanceType ? parseInt(maintenanceType.value) : 2,

            maintenanceMode
              ? parseInt(maintenanceType.value) == 3
                ? date
                  ? date.$d
                    ? moment(date.$d).format("YYYY-MM-DD hh:mm")
                    : moment(date).format("YYYY-MM-DD hh:mm")
                  : moment(previousDate).format("YYYY-MM-DD hh:mm")
                : moment(previousDate).format("YYYY-MM-DD hh:mm")
              : moment(previousDate).format("YYYY-MM-DD hh:mm")
          )
        );
      }
    } else {
      if (forceCloseOrder == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateMaintenanceAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            maintenanceMode ? 1 : 0,
            maintenanceReasones ? maintenanceReasones.label : "",
            forceCloseOrder ? parseInt(forceCloseOrder.value) : 0,
            onlyAsap ? 1 : 0,
            closedOrderMessage ? closedOrderMessage : "",
            enableAsap ? 1 : 0,
            nextDayPreeOrder ? 1 : 0,
            preeOrder ? 1 : 0,
            preeOrderMessage ? preeOrderMessage : "",
            maintenanceType ? parseInt(maintenanceType.value) : 2,
            maintenanceMode
              ? parseInt(maintenanceType.value) == 3
                ? date
                  ? date.$d
                    ? moment(date.$d).format("YYYY-MM-DD hh:mm")
                    : moment(date).format("YYYY-MM-DD hh:mm")
                  : moment(previousDate).format("YYYY-MM-DD hh:mm")
                : moment(previousDate).format("YYYY-MM-DD hh:mm")
              : moment(previousDate).format("YYYY-MM-DD hh:mm")
          )
        );
      }
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    saveMaintenance: () => {
      handleSaveMaintenance();
    },
  }));

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getMaintenanceListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    const secondMaintenanceList = [];
    let copy_maintenance_message_list = [...maintenance_message_list];
    if (copy_maintenance_message_list.length > 0) {
      for (let i = 0; i < copy_maintenance_message_list.length; i++) {
        secondMaintenanceList.push({
          value: copy_maintenance_message_list[i].Id,
          label: copy_maintenance_message_list[i].MaintenanceMessage,
        });
      }
    }
    setMaintenanceReasonesList(secondMaintenanceList);
  }, [maintenance_message_list]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      if (!get_maintenance_list) {
        dispatch(setDefaultAction());
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    let force_close_order_values = [];
    let forceClose = null;
    let copy_force_close_order = [...force_close_order];

    let maintenance_message_labels = [];
    let maintenanceReasone = null;
    let copy_maintenance_message_list = [...maintenanceReasonesList];

    let maintenance_type_values = [];
    let maintenanceType1 = null;
    let copy_maintenance_type_list = [...Maintenace_type];

    for (let i = 0; i < copy_maintenance_type_list.length; i++) {
      maintenance_type_values.push(copy_maintenance_type_list[i].value);
    }
    for (let i = 0; i < copy_force_close_order.length; i++) {
      force_close_order_values.push(copy_force_close_order[i].value);
    }
    for (let i = 0; i < copy_maintenance_message_list.length; i++) {
      maintenance_message_labels.push(copy_maintenance_message_list[i].label);
    }

    if (get_maintenance_list.length > 0) {
      setMaintenanceMode(get_maintenance_list[0].MaintenanceMode);
      setPreeOrder(get_maintenance_list[0].PreOrder);
      if (get_maintenance_list[0].PreOrderMessage) {
        setPreeOrderMessage(get_maintenance_list[0].PreOrderMessage);
      } else {
        setPreeOrderMessage("");
      }

      setNextDayPreeOrder(get_maintenance_list[0].NextDayPreOrder);
      if (get_maintenance_list[0].ShowASAP) {
        setEnableAsap(get_maintenance_list[0].ShowASAP);
      } else {
        setEnableAsap(false);
      }
      if (get_maintenance_list[0].IsOnlyASAP) {
        setOnlyAsap(get_maintenance_list[0].IsOnlyASAP);
      } else {
        setOnlyAsap(false);
      }

      if (get_maintenance_list[0].CloseOrderMessage) {
        setClosedOrderMessage(get_maintenance_list[0].CloseOrderMessage);
      } else {
        setClosedOrderMessage("");
      }

      if (get_maintenance_list[0].MaintenanceOpenShopDate) {
        setDate(get_maintenance_list[0].MaintenanceOpenShopDate);
        setPreviousDate(get_maintenance_list[0].MaintenanceOpenShopDate);
      } else {
        setDate(new Date());
        setPreviousDate(new Date());
      }

      if (
        get_maintenance_list[0].ForceCloseOrder ||
        get_maintenance_list[0].ForceCloseOrder == 0
      ) {
        if (
          force_close_order_values.includes(
            parseInt(get_maintenance_list[0].ForceCloseOrder)
          )
        ) {
          let index = force_close_order_values.indexOf(
            parseInt(get_maintenance_list[0].ForceCloseOrder)
          );
          forceClose = {
            value: copy_force_close_order[index].value,
            label: copy_force_close_order[index].label,
          };
        }

        SetForceCloseOrder(forceClose);
      }
      if (get_maintenance_list[0].MaintenanceMessage) {
        if (
          maintenance_message_labels.includes(
            String(get_maintenance_list[0].MaintenanceMessage)
          )
        ) {
          let index = maintenance_message_labels.indexOf(
            String(get_maintenance_list[0].MaintenanceMessage)
          );
          maintenanceReasone = {
            value: copy_maintenance_message_list[index].value,
            label: copy_maintenance_message_list[index].label,
          };
        }
        setMaintenanceReasones(maintenanceReasone);
      }
      if (get_maintenance_list[0].MaintenanceType) {
        if (get_maintenance_list[0].MaintenanceType == 3) {
          setActiveDateAndTimePicker(true);
        } else {
          setActiveDateAndTimePicker(false);
        }
        if (
          maintenance_type_values.includes(
            parseInt(get_maintenance_list[0].MaintenanceType)
          )
        ) {
          let index = maintenance_type_values.indexOf(
            parseInt(get_maintenance_list[0].MaintenanceType)
          );
          maintenanceType1 = {
            value: copy_maintenance_type_list[index].value,
            label: copy_maintenance_type_list[index].label,
          };
        }
        setMaintenanceType(maintenanceType1);
      }
    }
  }, [get_maintenance_list]);

  useEffect(() => {
    if (scrollStatus) {
      document.getElementById("container_scroll").scroll({
        top: document.getElementById("container_scroll").scrollHeight,
        behavior: "smooth",
      });
    } else {
      document.getElementById("container_scroll").scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [scrollStatus]);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return width1 <= 560 ? (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container" id="container_scroll">
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div2">
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={maintenanceMode}
                      onChange={handleMaintenanceCheckbox}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.maintenance_mode}
                />
              </FormGroup>
            </div>
          </div>
          <div className="sm-div2">
            <div
              className="sm-m container-maintenance-reasones"
              style={{ width: "100%" }}
            >
              <h6 className="sort-title h6">{lang.maintenance_reasones}</h6>
              <Select
                options={maintenanceReasonesList}
                defaultValue={maintenanceReasones}
                isSearchable={false}
                onChange={(list, item) => setMaintenanceReasones(list)}
                value={maintenanceReasones}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
                isDisabled={maintenanceMode ? false : true}
              />
            </div>
          </div>
          <div className="sm-div2">
            <div className=" sm-m" style={{ width: "100%" }}>
              <h6 className="sort-title h6">{lang.maintenance_type}</h6>
              <Select
                options={Maintenace_type}
                defaultValue={maintenanceType}
                isSearchable={false}
                onChange={(list, item) => handleSelectedMaintenanceType(list)}
                value={maintenanceType}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
                isDisabled={maintenanceMode ? false : true}
              />
            </div>
          </div>
          <div className="sm-div2">
            {" "}
            <div className=" sm-m" style={{ width: "49%", marginTop: "25px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DesktopDatePicker
                    label="Date "
                    inputFormat="YYYY-MM-DD"
                    value={date}
                    onChange={(i) => setDate(i)}
                    renderInput={(params) => <TextField {...params} />}
                    InputProps={{
                      sx: {
                        height: "45px",

                        // "& .MuiSvgIcon-root": {
                        //  ,
                        // },
                      },
                    }}
                    disabled={
                      maintenanceMode
                        ? activeDateAndTimePicker
                          ? false
                          : true
                        : true
                    }
                  />
                </Stack>
              </LocalizationProvider>
            </div>
            <div className=" sm-m" style={{ width: "49%", marginTop: "25px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <TimePicker
                    label="Time"
                    value={date}
                    // inputFormat="HH:mm"
                    onChange={(i) => setDate(i)}
                    renderInput={(params) => <TextField {...params} />}
                    InputProps={{
                      sx: {
                        height: "45px",

                        // "& .MuiSvgIcon-root": {
                        //  ,
                        // },
                      },
                    }}
                    disabled={
                      maintenanceMode
                        ? activeDateAndTimePicker
                          ? false
                          : true
                        : true
                    }
                  />
                </Stack>
              </LocalizationProvider>
            </div>
          </div>
          <div className="sm-div2">
            <div className=" sm-m" style={{ width: "100%" }}>
              {" "}
              <h6 className="h6">* {lang.pree_order_message}</h6>
              <textarea
                style={{
                  width: "100%",
                }}
                value={preeOrderMessage}
                defaultValue={preeOrderMessage}
                onChange={(e) => setPreeOrderMessage(e.target.value)}
              />
            </div>
          </div>
          <div className="sm-div2">
            <div className=" sm-m" style={{ width: "100%" }}>
              {" "}
              <h6 className=" h6">{lang.closed_order_message}</h6>
              <textarea
                style={{
                  width: "100%",
                }}
                label={lang.closed_order_message}
                value={closedOrderMessage}
                onChange={(e) => setClosedOrderMessage(e.target.value)}
              />
            </div>
          </div>
          <div className="sm-div2">
            <div
              className=" sm-m"
              style={{
                marginBottom: scrollStatus ? "140px" : null,
                width: "100%",
              }}
            >
              <h6 className="sort-title h6">* {lang.force_close_order}</h6>
              <Select
                options={force_close_order}
                defaultValue={forceCloseOrder}
                isSearchable={false}
                onChange={(list, item) => SetForceCloseOrder(list)}
                value={forceCloseOrder}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
                onMenuOpen={() => setScrollStatus(true)}
                onMenuClose={() => setScrollStatus(false)}
              />
            </div>
          </div>
          <div className="sm-div2">
            <div className=" sm-m" style={{ width: "49%" }}>
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableAsap}
                      onChange={() => setEnableAsap(!enableAsap)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.enable_asap}
                />
              </FormGroup>
            </div>
            <div className=" sm-m" style={{ width: "49%" }}>
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onlyAsap}
                      onChange={() => setOnlyAsap(!onlyAsap)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.only_asap}
                />
              </FormGroup>
            </div>
          </div>
          <div className="sm-div2">
            <div className=" sm-m" style={{ width: "49%" }}>
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={preeOrder}
                      onChange={() => setPreeOrder(!preeOrder)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.pree_order}
                />
              </FormGroup>
            </div>
            <div className=" sm-m" style={{ width: "49%" }}>
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nextDayPreeOrder}
                      onChange={() => setNextDayPreeOrder(!nextDayPreeOrder)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.next_day_pree_order}
                />
              </FormGroup>
            </div>
          </div>{" "}
        </div>
      </div>
      <div className="mobile-container-save-button">
        <button type="" onClick={() => handleSaveMaintenance()}>
          {lang.save}
        </button>
      </div>{" "}
      {loading_appSettings ? <Loader /> : null}
    </div>
  ) : (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container" id="container_scroll">
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div2">
            <div className=" sm-m container-maintenance-mode-checkbox">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={maintenanceMode}
                      onChange={handleMaintenanceCheckbox}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.maintenance_mode}
                />
              </FormGroup>
            </div>
            <div className="sm-m container-maintenance-reasones">
              <h6 className="sort-title h6">{lang.maintenance_reasones}</h6>
              <Select
                options={maintenanceReasonesList}
                defaultValue={maintenanceReasones}
                isSearchable={false}
                onChange={(list, item) => setMaintenanceReasones(list)}
                value={maintenanceReasones}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
                isDisabled={maintenanceMode ? false : true}
              />
            </div>
          </div>
          <div
            className="sm-div2"
            style={{
              alignItems: "flex-end",
            }}
          >
            <div className=" sm-m">
              <h6 className="sort-title h6">{lang.maintenance_type}</h6>
              <Select
                options={Maintenace_type}
                defaultValue={maintenanceType}
                isSearchable={false}
                onChange={(list, item) => handleSelectedMaintenanceType(list)}
                value={maintenanceType}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
                isDisabled={maintenanceMode ? false : true}
              />
            </div>
            <div className=" sm-m">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <DesktopDatePicker
                    label="Date "
                    value={dayjs(date)}
                    onChange={(i) => setDate(i)}
                    renderInput={(params) => <TextField {...params} />}
                    InputProps={{
                      sx: {
                        height: "45px",

                        // "& .MuiSvgIcon-root": {
                        //  ,
                        // },
                      },
                    }}
                    disabled={
                      maintenanceMode
                        ? activeDateAndTimePicker
                          ? false
                          : true
                        : true
                    }
                  />
                </Stack>
              </LocalizationProvider>
            </div>
            <div className=" sm-m">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <TimePicker
                    label="Time"
                    value={date}
                    // inputFormat="HH:mm"
                    onChange={(i) => setDate(i)}
                    renderInput={(params) => <TextField {...params} />}
                    InputProps={{
                      sx: {
                        height: "45px",

                        // "& .MuiSvgIcon-root": {
                        //  ,
                        // },
                      },
                    }}
                    disabled={
                      maintenanceMode
                        ? activeDateAndTimePicker
                          ? false
                          : true
                        : true
                    }
                  />
                </Stack>
              </LocalizationProvider>
            </div>
          </div>
          <div className="sm-div2">
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableAsap}
                      onChange={() => setEnableAsap(!enableAsap)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.enable_asap}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onlyAsap}
                      onChange={() => setOnlyAsap(!onlyAsap)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.only_asap}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={preeOrder}
                      onChange={() => setPreeOrder(!preeOrder)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.pree_order}
                />
              </FormGroup>
            </div>
            <div className=" sm-m">
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nextDayPreeOrder}
                      onChange={() => setNextDayPreeOrder(!nextDayPreeOrder)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.next_day_pree_order}
                />
              </FormGroup>
            </div>
          </div>
          <div className="sm-div2">
            <div className=" sm-m">
              {" "}
              <h6 className="h6">* {lang.pree_order_message}</h6>
              <textarea
                style={{
                  width: "100%",
                }}
                value={preeOrderMessage}
                defaultValue={preeOrderMessage}
                onChange={(e) => setPreeOrderMessage(e.target.value)}
              />
            </div>
            <div className=" sm-m">
              {" "}
              <h6 className=" h6">{lang.closed_order_message}</h6>
              <textarea
                style={{
                  width: "100%",
                }}
                label={lang.closed_order_message}
                value={closedOrderMessage}
                onChange={(e) => setClosedOrderMessage(e.target.value)}
              />
            </div>

            <div
              className=" sm-m"
              style={{
                marginBottom: scrollStatus ? "140px" : null,
              }}
            >
              <h6 className="sort-title h6">* {lang.force_close_order}</h6>
              <Select
                options={force_close_order}
                defaultValue={forceCloseOrder}
                isSearchable={false}
                onChange={(list, item) => SetForceCloseOrder(list)}
                value={forceCloseOrder}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
                onMenuOpen={() => setScrollStatus(true)}
                onMenuClose={() => setScrollStatus(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MaintenanceMode;
