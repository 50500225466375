import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  main_nav_button_color,
  main_nav_back_color,
  primaryColor,
  white,
} from "../../../constants/Colors";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import CodeDetail from "./Component/CodeDetail";
import { setDefaultAction } from "../../../redux/menu/Actions";
import {
  selectedGroupIdAction,
  selectedCodeDetailAction,
} from "../../../redux/settings/Action";
const CodingSetting = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    loading_appSettings,
    result_message,
    result_failed,
    result_successfull,
    code_detail_list,
  } = useSelector((state) => state.appSettingsRedusers);
  const [clickedItem1, setClickedItem] = useState(1);
  const [codeDetailList1, setCodeDetailList] = useState([]);
  const handleGroupId1 = () => {
    setClickedItem(1);
    dispatch(selectedGroupIdAction(1));
    dispatch(selectedCodeDetailAction(null));
  };
  const handleGroupId2 = () => {
    setClickedItem(2);
    dispatch(selectedGroupIdAction(2));
    dispatch(selectedCodeDetailAction(null));
  };
  const handleGroupId3 = () => {
    setClickedItem(3);
    dispatch(selectedGroupIdAction(5));
    dispatch(selectedCodeDetailAction(null));
  };
  const handleGroupId4 = () => {
    setClickedItem(4);
    dispatch(selectedGroupIdAction(4));
    dispatch(selectedCodeDetailAction(null));
  };
  const handleGroupId5 = () => {
    setClickedItem(5);
    dispatch(selectedGroupIdAction(6));
    dispatch(selectedCodeDetailAction(null));
  };
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    let codeDetailList = [];
    if (clickedItem1 == 1) {
      dispatch(selectedGroupIdAction(1));
      if (code_detail_list.length > 0) {
        for (let i = 0; i < code_detail_list.length; i++) {
          if (code_detail_list[i].GroupId == 1) {
            codeDetailList.push(code_detail_list[i]);
          }
        }
        setCodeDetailList(codeDetailList);
      }
    }
  }, [code_detail_list]);
  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <div className="menu_list_header_container_tabel_list">
        <div className="main_nav_container">
          <div className="settings_tab_buttons_container">
            <button
              key={1}
              onClick={handleGroupId1}
              style={{
                backgroundColor: clickedItem1 == 1 ? primaryColor : white,
                color: clickedItem1 == 1 ? white : main_nav_button_color,
                borderWidth: clickedItem1 == 1 ? 0 : 0.3,
                fontSize: "13.4px",
              }}
              className="main_nav_buttons"
            >
              {lang.dicount_reasones}
            </button>
            <button
              key={2}
              onClick={handleGroupId2}
              style={{
                backgroundColor: clickedItem1 == 2 ? primaryColor : white,
                color: clickedItem1 == 2 ? white : main_nav_button_color,
                borderWidth: clickedItem1 == 2 ? 0 : 0.3,
                fontSize: "13.4px",
              }}
              className="main_nav_buttons"
            >
              {lang.food_group_section}
            </button>
            <button
              key={3}
              onClick={handleGroupId3}
              style={{
                backgroundColor: clickedItem1 == 3 ? primaryColor : white,
                color: clickedItem1 == 3 ? white : main_nav_button_color,
                borderWidth: clickedItem1 == 3 ? 0 : 0.3,
                fontSize: "13.4px",
                fontWeight: "400",
              }}
              className="main_nav_buttons"
            >
              {lang.cancel_reasone}
            </button>
            <button
              key={4}
              onClick={handleGroupId4}
              style={{
                backgroundColor: clickedItem1 == 4 ? primaryColor : white,
                color: clickedItem1 == 4 ? white : main_nav_button_color,
                borderWidth: clickedItem1 == 4 ? 0 : 0.3,
                fontSize: "13.4px",
                fontWeight: "400",
              }}
              className="main_nav_buttons"
            >
              {lang.refund_reasone}
            </button>
            <button
              key={5}
              onClick={handleGroupId5}
              style={{
                backgroundColor: clickedItem1 == 5 ? primaryColor : white,
                color: clickedItem1 == 5 ? white : main_nav_button_color,
                borderWidth: clickedItem1 == 5 ? 0 : 0.3,
                fontSize: "13.4px",
                fontWeight: "400",
              }}
              className="main_nav_buttons"
            >
              {lang.kitchen_message}
            </button>
            {/* <button
              key={4}
              onClick={handleGroupId4}
              style={{
                backgroundColor: clickedItem1 == 4 ? primaryColor : white,
                color: clickedItem1 == 4 ? white : main_nav_button_color,
                borderWidth: clickedItem1 == 4 ? 0 : 0.3,
                fontSize: "13.4px",
                fontWeight: "400",
              }}
              className="main_nav_buttons"
            >
              {lang.discount_money_value}
            </button> */}
          </div>
        </div>
        {clickedItem1 == 1 ||
        clickedItem1 == 2 ||
        clickedItem1 == 3 ||
        clickedItem1 == 4 ||
        clickedItem1 == 5 ? (
          <CodeDetail codeDetailList1={codeDetailList1} />
        ) : null}
        {loading_appSettings ? <Loader /> : null}
      </div>
    </div>
  );
};

export default CodingSetting;
