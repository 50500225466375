import * as actionTypes from "../ActionTypes";
import * as string from "../../constants/Strings";
import * as menu from "../../constants/MainMenu";
import * as userMenu from "../../constants/MainUsersMenu";
import * as devicesMenu from "../../constants/MainDevicesMenu"
const initialStates = {
  lang: string.english,
  main_menu: menu.english_menu,
users_menu:userMenu.english_users_menu,
devices_menu:devicesMenu.english_divices_menu
};

const selectedLanguage = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.ENGLISH:
      return {
        ...state,
        lang: string.english,
        mainMenu: menu.english_menu,
        users_menu:userMenu.english_users_menu,
        devices_menu:devicesMenu.english_divices_menu
      };
    case actionTypes.TURKISH:
      return {
        ...state,
        lang: string.turkish,
        mainMenu: menu.turkish_menu,
        users_menu:userMenu.turkish_users_menu,
        devices_menu:devicesMenu.turkish_divices_menu
      };
    case actionTypes.PERSIAN:
      return {
        ...state,
        lang: string.persian,
        mainMenu: menu.persian_menu,
        users_menu:userMenu.persian_users_menu,

      };

    default:
      return state;
  }
};
export default selectedLanguage;
