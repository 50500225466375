import React, { useState, useEffect, useRef } from "react";
import ListHeader from "../../../../components/ListHeader";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import validator from "validator";
import toast, { Toaster } from "react-hot-toast";
import {
  createTabelAction,
  updateTabelAction,
} from "../../../../redux/GeneralSetting/Actions";
import {
  visibleCusomChargeAction,
  createDeliveryChargeAction,
  updateDeliveryChargeAction,
} from "../../../../redux/settings/Action";
import { setDefaultAction } from "../../../../redux/menu/Actions";
const DeliveryChargePopup = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const titleRef = useRef();
  const deliveryChargeRef = useRef();

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_custom_charge } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const {
    loading_appSettings,
    result_message,
    result_failed,
    result_successfull,
  } = useSelector((state) => state.appSettingsRedusers);
  const [title, setTitle] = useState("");

  const [deliveryCharge, setDeliveryCharge] = useState("");
  const [orderQueueListColour, setOrderQueueListColour] = useState("#d0021b");
  const [
    showColorPickerOrderQueueListColour,
    setShowColorPickerOrderQueueListColour,
  ] = useState(false);

  const handleTitleKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      deliveryChargeRef.current.focus();
    }
  };
  const handleDeliverChargeKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      handleCreateAndUpdate();
    }
  };

  const handleCreateAndUpdate = () => {
    if (selected_custom_charge) {
      if (title == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        !deliveryCharge == "" &&
        !validator.isFloat(String(deliveryCharge))
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_delivery_charge_format_is_incorrect}
            &nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateDeliveryChargeAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_custom_charge.Id,
            title.replaceAll(`"`, `\"\"`),
            deliveryCharge ? parseFloat(deliveryCharge) : 0,
            orderQueueListColour
          )
        );
      }
    } else if (title == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (
      !deliveryCharge == "" &&
      !validator.isFloat(String(deliveryCharge))
    ) {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{
            lang.the_delivery_charge_format_is_incorrect
          }&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        createDeliveryChargeAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          title.replaceAll(`"`, `\"\"`),
          deliveryCharge ? parseFloat(deliveryCharge) : 0,
          orderQueueListColour
        )
      );
    }
  };
  const handleInputOrderQueueListColour = (e) => {
    setOrderQueueListColour(e.target.value);
  };
  const handleChangeCompleteOrderQueueListColour = (color) => {
    setOrderQueueListColour(color.hex);
    document.getElementById("QueueListColour").value = color.hex;
  };
  const handleOnclickeInputOrderQueueListColour = () => {
    setShowColorPickerOrderQueueListColour(
      !showColorPickerOrderQueueListColour
    );
  };
  const handleCloseOrderQueueListColour = () => {
    setShowColorPickerOrderQueueListColour(false);
  };
  useEffect(() => {
    if (selected_custom_charge) {
      setTitle(selected_custom_charge.Title);
      setDeliveryCharge(selected_custom_charge.DeliveryCharge);
      setOrderQueueListColour(selected_custom_charge.BackgrandColor);
    }
  }, [selected_custom_charge]);
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <div className="create_shop_popup_container">
      <div
        className="create_shop_popup_content_container post-code-content-container"
        style={{ marginTop: "-60px" }}
      >
        <div className=" create-shop-close-div-post-code">
          <i
            onClick={() => dispatch(visibleCusomChargeAction(false))}
            style={{ fontSize: 25 }}
            aria-hidden="true"
            className="fa fa-times post-code-close-icon"
          ></i>
          <p className="post-code-p">{lang.custom_charge_title}</p>
        </div>
        <div className="container-input-div">
          <div
            className="create_shop_inputs_container post-code-create_shop_inputs_container"
            style={{ height: "13%", margin: "25px auto " }}
          >
            <form>
              <h6 className="h6 post-code-h6">* {lang.title}</h6>
              <input
                type="text"
                value={title}
                onChange={(str) => setTitle(str.target.value)}
                autoFocus
                ref={titleRef}
                onKeyDown={handleTitleKeyDown}
              />
            </form>
          </div>
          <div
            className="create_shop_inputs_container"
            style={{ height: "13%", margin: " 45px auto" }}
          >
            <form>
              <h6 className="h6 post-code-h6">Delivery charge</h6>
              <input
                type="text"
                value={deliveryCharge}
                onChange={(str) =>
                  setDeliveryCharge(str.target.value.replace(/[^\d\.,£]+/g, ""))
                }
                ref={deliveryChargeRef}
                onKeyDown={handleDeliverChargeKeyDown}
              />
            </form>
          </div>
          <div
            style={{
              position: "relative",
              width: " 90%",
              margin: " 50px auto",
            }}
          >
            <h6 className="h6 color-h6">{lang.color}</h6>
            <div className="bachgroundColor-div">
              <div
                className="bachgroundColor"
                style={{
                  backgroundColor: orderQueueListColour,
                  width: "22px",
                  height: "22px",
                }}
              ></div>
              <input
                className="backgroundColor-input"
                onMouseOver={handleOnclickeInputOrderQueueListColour}
                Value={orderQueueListColour}
                onChange={handleInputOrderQueueListColour}
                id="QueueListColour"
              />
            </div>
            {showColorPickerOrderQueueListColour ? (
              <div
                className="COLOUR-SketchPicker-div"
                onMouseLeave={handleCloseOrderQueueListColour}
              >
                <SketchPicker
                  color={orderQueueListColour}
                  onChangeComplete={handleChangeCompleteOrderQueueListColour}
                  className="SketchPicker"
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="post-code-popup-buttons-container">
          <button
            // onKeyDown={handleSaveKeyDown}
            // disabled={selectedSubmenuIds.length > 0 ? false : true}
            onClick={handleCreateAndUpdate}
            id="popup_add"
          >
            {lang.save}
          </button>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default DeliveryChargePopup;
