import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

export const usersServices = async (login_key, user_id, user_type) => {
  return await axios.put(`${baseUrl}userslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    UserType: user_type,
  });
};
export const createUsersServices = async (
  login_key,
  user_id,
  user_type,
  first_name,
  last_name,
  active,
  shop_group_id,
  user_name,
  pass,
  access_group_id,
  shop_id,
  mobile,
  login_otp,
  two_factor
) => {
  return await axios.post(`${baseUrl}createuser/`, {
    LoginKey: login_key,
    UserId: user_id,
    UserType: user_type,
    FirstName: first_name,
    LastName: last_name,
    Active: active,
    ShopGroupId: shop_group_id,
    UserName: user_name,
    Pass: pass,
    AccessGroupId: access_group_id,
    ShopId: shop_id,
    Mobile: mobile,
    LoginOTP: login_otp,
    TwoFactor: two_factor,
  });
};
export const updateUsersServices = async (
  login_key,
  user_id,
  id,
  user_type,
  first_name,
  last_name,
  active,
  shop_group_id,
  user_name,
  pass,
  access_group_id,
  shop_id,
  mobile,
  login_otp,
  two_factor
) => {
  return await axios.put(`${baseUrl}updateuser/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    UserType: user_type,
    FirstName: first_name,
    LastName: last_name,
    Active: active,
    ShopGroupId: shop_group_id,
    UserName: user_name,
    Pass: pass,
    AccessGroupId: access_group_id,
    ShopId: shop_id,
    Mobile: mobile,
    LoginOTP: login_otp,
    TwoFactor: two_factor,
  });
};

export const deleteUsersservices = async (login_key, user_id, id) => {
  return await axios.delete(`${baseUrl}deleteuser/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      Id: id,
    },
  });
};
export const shopGroupListServices = async (login_key, user_id) => {
  return await axios.put(`${baseUrl}shopGroupList/`, {
    LoginKey: login_key,
    UserId: user_id,
  });
};
export const createShopGroupServices = async (
  login_key,
  user_id,
  group_name,
  shops_group,
  sort_id
) => {
  return await axios.post(`${baseUrl}createShopGroup/`, {
    LoginKey: login_key,
    UserId: user_id,
    GroupName: group_name,
    ShopsGroup: shops_group,
    SortId: sort_id,
  });
};
export const updateShopGroupServices = async (
  login_key,
  user_id,
  group_id,
  group_name,
  shops_group,
  sort_id
) => {
  return await axios.put(`${baseUrl}updateShopGroup/`, {
    LoginKey: login_key,
    UserId: user_id,
    GroupId: group_id,
    GroupName: group_name,
    ShopsGroup: shops_group,
    SortId: sort_id,
  });
};
export const deleteShopGroupServices = async (login_key, user_id, group_id) => {
  return await axios.delete(`${baseUrl}deleteShopGroup/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      GroupId: group_id,
    },
  });
};
export const shopListByGroupIdSevices = async (
  login_key,
  user_id,
  group_id
) => {
  return await axios.put(`${baseUrl}shopListBygroupid/`, {
    LoginKey: login_key,
    UserId: user_id,
    GroupId: group_id,
  });
};
