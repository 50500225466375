import React, { useState } from "react";

import ExternalCompanyEdit from "./ExternalCompanyEdit";
import ExternalCompanyList from "./ExternalCompanyList";

const ExternalCompany = () => {
  const [clickedItem, setClickedItem] = useState(1);
  return (
    <div
      className="common-styles"
      style={{
        height: window.innerHeight - 160,
      }}
    >
      <div className="container-components-div">
        {clickedItem == 1 ? (
          <ExternalCompanyList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 2 ? (
          <ExternalCompanyEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : null}
      </div>
    </div>
  );
};

export default ExternalCompany;
