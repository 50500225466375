import * as actionTypes from "../ActionTypes";

export const getWorkStationListAllAction = (login_key, user_id) => {
  return {
    type: actionTypes.GET_WORKSTATION_LIST_ALL,
    payload: { login_key, user_id },
  };
};

export const getWorkStationListAllSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_WORKSTATION_LIST_ALL_SUCCESSFULL,
    payload: data,
  };
};
export const getWorkStationListAllFailedAction = (data) => {
  return {
    type: actionTypes.GET_WORKSTATION_LIST_ALL_FAILED,
    payload: data,
  };
};
export const selectedLicenceAction = (data) => {
  return {
    type: actionTypes.SELECTD_LICENCE_LIST,
    payload: data,
  };
};
export const getLicenceWorkStationListAction = (login_key, user_id) => {
  return {
    type: actionTypes.GET_LICENCE_WORK_STATION_LIST,
    payload: { login_key, user_id },
  };
};
export const getLicenceWorkStationListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_LICENCE_WORK_STATION_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getLicenceWorkStationListFailedAction = () => {
  return {
    type: actionTypes.GET_LICENCE_WORK_STATION_LIST_FAILED,
  };
};

export const createLicenceAction = (
  login_key,
  shop_id,
  workStation_id,
  device_id,
  licence_course,
  user_id
) => {
  return {
    type: actionTypes.CREATE_LICENCE,
    payload: {
      login_key,
      shop_id,
      workStation_id,
      device_id,
      licence_course,
      user_id,
    },
  };
};
export const createLicenceSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_LICENCE_SUCCESSFULL,
    payload: data,
  };
};
export const createLicenceFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_LICENCE_FAILED,
    payload: data,
  };
};
export const visibleCreateLicencePopup = (data) => {
  return {
    type: actionTypes.VISIBLE_CRETE_LICENCE_POPUP,
    payload: data,
  };
};
export const getLicenceListByWorkStaionIdAction = (
  login_key,
  user_id,
  workStation_id
) => {
  return {
    type: actionTypes.GET_LICENCE_LIST_BY_WORKSTATION_ID,
    payload: { login_key, user_id, workStation_id },
  };
};
export const getLicenceListByWorkStaionIdSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_LICENCE_LIST_BY_WORKSTATION_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getLicenceListByWorkStaionIdFailedAction = () => {
  return {
    type: actionTypes.GET_LICENCE_LIST_BY_WORKSTATION_ID_FAILED,
  };
};

// MAIN SECOND SCREEN

export const showMainSecondScreenShopGroupPopupAction = (data) => {
  return {
    type: actionTypes.SHOW_MAIN_SECOND_SCREEN_SHOP_GROUP_POPUP,
    payload: data,
  };
};
export const getGeneralSecondScreenListAction = (login_key, user_id) => {
  return {
    type: actionTypes.GET_GENERAL_SECOND_SCREEN_LIST,
    payload: { login_key, user_id },
  };
};
export const getGeneralSecondFullScreenListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_GENERAL_SECOND_FULL_SCREEN_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getGeneralSecondHalfScreenListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_GENERAL_SECOND_HALF_SCREEN_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getGeneralSecondScreenListFailedAction = (data) => {
  return {
    type: actionTypes.GET_GENERAL_SECOND_SCREEN_LIST_FAILED,
    payload: data,
  };
};

export const createGeneralSecondScreenImageAction = (
  formData,
  login_key,
  user_id
) => {
  return {
    type: actionTypes.CREATE_GENERAL_SECOND_SCREEN_IMAGE,
    payload: { formData, login_key, user_id },
  };
};
export const createGeneralSecondScreenImageSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL,
    payload: data,
  };
};
export const createGeneralSecondScreenImageFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_GENERAL_SECOND_SCREEN_IMAGE_FAILED,
    payload: data,
  };
};

export const deleteGeneralSecondScreenImageAction = (
  login_key,
  user_id,
  id,
  last_image_url
) => {
  return {
    type: actionTypes.DELETE_GENERAL_SECOND_SCREEN_IMAGE,
    payload: { login_key, user_id, id, last_image_url },
  };
};
export const deleteGeneralSecondScreenImageSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL,
    payload: data,
  };
};
export const deleteGeneralSecondScreenImageFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_GENERAL_SECOND_SCREEN_IMAGE_FAILED,
    payload: data,
  };
};

export const updateGeneralSecondScreenImageAction = (
  login_key,
  user_id,
  id,
  sort_id,
  active,
  duration
) => {
  return {
    type: actionTypes.UPDATE_GENERAL_SECOND_SCREEN_IMAGE,
    payload: { login_key, user_id, id, sort_id, active, duration },
  };
};
export const updateGeneralSecondScreenImageSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL,
    payload: data,
  };
};
export const updateGeneralSecondScreenImageFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_GENERAL_SECOND_SCREEN_IMAGE_FAILED,
    payload: data,
  };
};

export const generalSecondScreenShopGroupListAction = (login_key, user_id) => {
  return {
    type: actionTypes.GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST,
    payload: { login_key, user_id },
  };
};
export const generalSecondScreenShopGroupListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const generalSecondScreenShopGroupListFailedAction = (data) => {
  return {
    type: actionTypes.GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST_FAILED,
    payload: data,
  };
};

export const updateGeneralSecondScreenShopGroupAction = (
  login_key,
  user_id,
  id,
  shop_ids
) => {
  return {
    type: actionTypes.UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP,
    payload: { login_key, user_id, id, shop_ids },
  };
};
export const updateGeneralSecondScreenShopGroupSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP_SUCCESSFULL,
    payload: data,
  };
};

export const updateGeneralSecondScreenShopGroupFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP_FAILED,
    payload: data,
  };
};

export const generalSecondScreenCreatePopupAction = (data) => {
  return {
    type: actionTypes.GENEREL_SECOND_SCREEN_CREATE_POPUP,
    payload: data,
  };
};

export const selectedScreenAction = (data) => {
  return {
    type: actionTypes.SELECTED_SCREEN,
    payload: data,
  };
};
