import * as actionType from "../ActionTypes";

const initialStates = {
  result_failed: false,
  result_message: "",
  result_successfull: false,
  loading: false,
  privacy_policy_list: [],
  external_company_list: [],
  show_external_company_shops_list: false,
  selected_external_company: null,
  submenu_public_list: [],
  selected_public_submneu: null,
  subitem_public_list: [],
  selected_subitem_public: null,
  dropdown_submenu_public_list: [],
  customers_call_list: [],
};

const MainNavGeneralRedusers = (state = initialStates, action) => {
  switch (action.type) {
    case actionType.SET_DEFAULT:
      return {
        ...state,
        result_failed: false,
        result_message: "",
        result_successfull: false,
        loading: false,
      };
    case actionType.GET_PRIVACY_POLICY_LIST:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_PRIVACY_POLICY_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        privacy_policy_list: action.payload,
      };
    case actionType.GET_PRIVACY_POLICY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        privacy_policy_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionType.UPDATE_PRIVACY_POLICY:
      return {
        ...state,
        loading: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };

    case actionType.UPDATE_PRIVACY_POLICY_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionType.UPDATE_PRIVACY_POLICY_FAILED:
      return {
        ...state,
        loading: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };

    //EXTERNAL COMPANY
    case actionType.GET_EXTERNAL_COMPANY_LIST:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_EXTERNAL_COMPANY_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        external_company_list: action.payload,
      };
    case actionType.GET_EXTERNAL_COMPANY_LIST_FAILED:
      return {
        ...state,
        loading: false,
        external_company_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionType.UPDATE_EXTERNAL_COMPANY:
      return {
        ...state,
        loading: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };

    case actionType.UPDATE_EXTERNAL_COMPANY_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionType.UPDATE_EXTERNAL_COMPANY_FAILED:
      return {
        ...state,
        loading: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };
    case actionType.SHOW_EXTENRAL_COMPANY_SHOPS_LIST_POPUP:
      return {
        ...state,
        show_external_company_shops_list: action.payload,
      };
    case actionType.SELECTED_EXTERNAL_COMPANY:
      return {
        ...state,
        selected_external_company: action.payload,
      };
    // SUBMENU PUBLIC
    case actionType.GET_SUBMENU_PUBLIC_LIST:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_SUBMENU_PUBLIC_LIST_SUCCESSFULL:
      let new_list = [];

      if (action.payload) {
        if (action.payload.length > 0) {
          for (let i = 0; i < action.payload.length; i++) {
            new_list.push({
              ...action.payload[i],
              label: action.payload[i].Title,
              value: action.payload[i].Id,
            });
          }
        }
      }

      return {
        ...state,
        loading: false,
        submenu_public_list: action.payload,
        dropdown_submenu_public_list: new_list,
      };
    case actionType.GET_SUBMENU_PUBLIC_LIST_FAILED:
      return {
        ...state,
        loading: false,
        submenu_public_list: [],
        result_failed: true,
        result_message: action.payload,
        dropdown_submenu_public_list: [],
      };
    case actionType.SELECTED_PUBLIC_SUBMENU:
      return {
        ...state,
        selected_public_submneu: action.payload,
      };

    //SUBITEM PUBLIC
    case actionType.GET_SUBITEM_PUBLIC_LIST:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_SUBITEM_PUBLIC_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        subitem_public_list: action.payload,
      };

    case actionType.GET_SUBITEM_PUBLIC_LIST_FAILED:
      return {
        ...state,
        loading: false,
        subitem_public_list: [],
        result_failed: true,
        result_message: action.payload,
      };
    case actionType.SELECTED_SUBITEM_PUBLIC:
      return {
        ...state,
        selected_subitem_public: action.payload,
      };

    //CREATE PUBLIC SUBITEM
    case actionType.CREATE_PUBLIC_SUBITEM:
      return {
        ...state,
        loading: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };

    case actionType.CREATE_PUBLIC_SUBITEM_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionType.CREATE_PUBLIC_SUBITEM_FAILED:
      return {
        ...state,
        loading: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };

    //UPDATE PUBLIC SUBITEM
    case actionType.UPDATE_PUBLIC_SUBITEM:
      return {
        ...state,
        loading: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };

    case actionType.UPDATE_PUBLIC_SUBITEM_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionType.UPDATE_PUBLIC_SUBITEM_FAILED:
      return {
        ...state,
        loading: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };

    //DELETE PUBLIC SUBITEM
    case actionType.DELETE_PUBLIC_SUBITEM:
      return {
        ...state,
        loading: true,
        result_failed: false,
        result_message: "",
        result_successfull: false,
      };

    case actionType.DELETE_PUBLIC_SUBITEM_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        result_failed: false,
        result_message: action.payload,
        result_successfull: true,
      };
    case actionType.DELETE_PUBLIC_SUBITEM_FAILED:
      return {
        ...state,
        loading: false,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
      };
    case actionType.GET_CUSTOMERS_CALL_LIST:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_CUSTOMERS_CALL_LIST_SUCCESSFULL:
      return {
        ...state,
        loading: false,
        customers_call_list: action.payload,
      };
    case actionType.GET_CUSTOMERS_CALL_LIST_FAILED:
      return {
        ...state,
        loading: false,
        customers_call_list: [],
      };

    default:
      return state;
  }
};

export default MainNavGeneralRedusers;
