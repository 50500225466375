import React, { useState, useEffect, lazy } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-bootstrap";
import Select from "react-select";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import orderList from "../../orders/List";

import { getLicenceWorkStationListAction } from "../../../redux/devices/Actions";
import OrderListItem from "../../../components/OrderListItem ";
import ListHeader from "../../../components/ListHeader";
import { main_nav_button_color } from "../../../constants/Colors";
import { primaryColor, main_nav_back_color } from "../../../constants/Colors";
import DevicesHeaderList from "../../../components/DevicesHeaderList";
import DevicesListItem from "../../../components/DevicesListItem";
import { changeSelectedButtonBorderColorOnFocus } from "../../../redux/menu/Actions";
// import { DaysLeft } from "../../orders/List";

const Days_Left = [
  { value: 1, label: "0-5" },
  {
    value: 2,
    label: "5-10",
  },
  { value: 3, label: "All" },
];

const LicenceList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { licencs_list, loading_devices_worksStations, selectd_licence } =
    useSelector((state) => state.devicesWorkStationReduser);

  const [licenceList, setLicenceList] = useState([]);

  const [daysLeft, setDaysLeft] = useState({ value: 3, label: "All" });
  const [licenceWorksStationList, setLicenceWorksStationList] = useState([]);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 500,
      backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleDaysLeft = (list, item) => {
    setDaysLeft(list);
    const copy_days_letf = [...Days_Left];
    const copy_licenceWorksStationList = [...licenceWorksStationList];

    let my_list = [];
    if (item.action == "select-option") {
      if (list.value == 1) {
        for (let i = 0; i < Days_Left.length; i++) {
          if (list.value == Days_Left[i].value) {
            for (
              let index = 0;
              index < copy_licenceWorksStationList.length;
              index++
            ) {
              if (copy_licenceWorksStationList[index].Daysleft) {
                if (copy_licenceWorksStationList[index].Daysleft < 6) {
                  my_list.push(copy_licenceWorksStationList[index]);
                }
              } else {
                my_list.push(copy_licenceWorksStationList[index]);
              }
            }
          }
        }
      } else if (list.value == 2) {
        for (let i = 0; i < Days_Left.length; i++) {
          if (list.value == Days_Left[i].value) {
            for (
              let index = 0;
              index < copy_licenceWorksStationList.length;
              index++
            ) {
              if (copy_licenceWorksStationList[index].Daysleft) {
                if (copy_licenceWorksStationList[index].Daysleft > 5) {
                  if (copy_licenceWorksStationList[index].Daysleft <= 10) {
                    my_list.push(copy_licenceWorksStationList[index]);
                  }
                }
              }
            }
          }
        }
      } else if (list.value == 3) {
        for (let i = 0; i < Days_Left.length; i++) {
          if (list.value == Days_Left[i].value) {
            for (
              let index = 0;
              index < copy_licenceWorksStationList.length;
              index++
            ) {
              my_list.push(copy_licenceWorksStationList[index]);
            }
          }
        }
      }
    }
    setLicenceList(my_list);
  };
  useEffect(() => {
    dispatch(getLicenceWorkStationListAction(login_key, user_id));
  }, []);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (licencs_list) {
      if (licencs_list.length > 0) {
        const sortArrey = licencs_list.sort(function (a, b) {
          return a.Daysleft - b.Daysleft;
        });
        setLicenceWorksStationList(sortArrey);
        setLicenceList(sortArrey);
      }
    }
  }, [licencs_list]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
      id="countaining"
    >
      <ListHeader title={lang.licence} show_back={false} licence_view={false} />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div
            className="sm-div2"
            style={{ marginBottom: "35pxpx", marginTop: "35px" }}
          >
            <div className="sm-m" style={{ width: "35%" }}>
              <h6 className="h6">{lang.days_left}</h6>
              <Select
                options={Days_Left}
                defaultValue={daysLeft}
                isSearchable={false}
                onChange={handleDaysLeft}
                // value={daysLeft}
                isMulti={false}
                styles={customStyles}
              />
            </div>
            {/* <div className="sm-m">
              <h6 className="h6">{lang.days_left}</h6>
              <Select
                options={Days_Left}
                defaultValue={daysLeft}
                isSearchable={false}
                onChange={handleDaysLeft}
                // value={daysLeft}
                isMulti={false}
                styles={customStyles}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">{lang.days_left}</h6>
              <Select
                options={Days_Left}
                defaultValue={daysLeft}
                isSearchable={false}
                onChange={handleDaysLeft}
                // value={daysLeft}
                isMulti={false}
                styles={customStyles}
              />
            </div> */}
          </div>
          <div>
            <DevicesHeaderList button_key={true} />
            {licenceList.map((item, index) => (
              <DevicesListItem
                shop_name={item.ShopName}
                active={item.Active}
                device_type={item.DeviceTypeName}
                expire_date={item.ExpireDate}
                work_station_name={item.WorkStationName}
                button_key={true}
                days_left={item.Daysleft}
                setClickedItem={setClickedItem}
                licence_view={false}
                item={item}
                logo={item.Logo}
                selected={selectd_licence}
                domain={item.Domain}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenceList;
