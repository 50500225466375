import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { orderList } from "../pages/orders/List.js";
import { objects } from "../pages/orders/List.js";
import { primaryColor, white } from "../constants/Colors";
import {
  selectedLicenceAction,
  visibleCreateLicencePopup,
} from "../redux/devices/Actions";
import { clickedItemAction } from "../redux/menu/Actions";
import { Badge } from "react-bootstrap";
import { image_Url } from "../constants/BaseUrl";
const DevicesListItem = ({
  shop_name,
  active,
  device_type,
  expire_date,
  work_station_name,
  button_key,
  days_left,
  setClickedItem,
  start_date,
  expired,
  reserve,
  licence_course,
  licence_view,
  item,
  logo,
  selected,
  domain,
}) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { change_selected_button_border_color_on_focus, clicked_item } =
    useSelector((state) => state.categoryList);
  const { selected_shop } = useSelector((state) => state.shopReducer);

  // const image_href = "https://otopartnercentre.com/";
  const handleSelectedLicense = () => {
    setClickedItem(2);
    dispatch(selectedLicenceAction(item));
    dispatch(clickedItemAction(2));
  };
  const handleSelecteLicenses = () => {
    dispatch(selectedLicenceAction(item));
    dispatch(visibleCreateLicencePopup(true));
    dispatch(clickedItemAction(3));
  };

  return (
    <div>
      {button_key ? (
        <div
          className="order-list-container-div"
          style={{
            backgroundColor:
              days_left <= 5
                ? "rgb(255, 248, 246)"
                : days_left <= 10
                ? "rgb(255, 238, 235)"
                : " #fee5db",
          }}
        >
          <div
            className="devics-item-list-p "
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="shop-logo"
              style={{
                width: "30%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                alt=""
                // src={`${image_href}${logo}`}
                src={
                  logo
                    ? `${image_Url}${domain}/${logo}`
                    : "https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder.jpg"
                }
                style={{
                  border: !logo ? "1px solid rgb(225, 225, 225)" : null,
                }}
                width=""
                height={55}
                className="shop-logo"
              />
            </div>
            <p
              style={{
                width: "70%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                marginTop: "16px",
              }}
            >
              {shop_name}
            </p>
          </div>
          <p className="devics-item-list-p device-list-p">{device_type}</p>
          <p className="devics-item-list-p device-list-p">
            {work_station_name}
          </p>
          <p className="devics-item-list-p device-list-p">
            {!expire_date ? 0 : moment(expire_date).format("DD/MM/YYYY")}
          </p>
          {/* {active == 1 ? (
            <p
              className="devics-item-list-p device-list-p"
              style={{ color: "green", marginLeft: "5px" }}
            >
              {lang.active}
            </p>
          ) : (
            <p
              className="devics-item-list-p device-list-p"
              style={{ color: "red", marginLeft: "5px" }}
            >
              {lang.de_active}
            </p>
          )} */}
          <p
            className="devics-item-list-p device-list-p"
            style={{ marginRight: "-9px" }}
          >
            {!days_left ? (
              // <svg
              //   style={{
              //     width: "100%",
              //   }}
              // >
              //   <circle fill="#ff0000" stroke="none" cx="75" cy="75" r="13">
              //     <animate
              //       attributeName="opacity"
              //       dur="1s"
              //       values="0;1;0"
              //       repeatCount="indefinite"
              //       begin="0.1"
              //     />
              //   </circle>
              // </svg>
              <span
                bg="warning"
                text="dark"
                pill
                className="badge badge1"
                style={{
                  animationDelay: "0s",
                  width: "27px",
                  height: "27px",
                  paddingTop: "7px",
                  fontSize: "15px",
                  borderRadius: "15px",

                  color: "black",
                }}
              >
                <p></p>
              </span>
            ) : days_left < 10 ? (
              <span
                bg="warning"
                text="dark"
                pill
                className="badge"
                style={{
                  animationDelay: "0s",
                  width: "27px",
                  height: "27px",
                  paddingTop: "7px",
                  fontSize: "15px",
                  borderRadius: "15px",
                  color: "black",
                }}
              >
                {days_left}
              </span>
            ) : (
              days_left
            )}
          </p>
          <div className="devics-item-list-button">
            {active == 1 ? (
              <button
                onClick={handleSelecteLicenses}
                style={{
                  backgroundColor:
                    clicked_item == 3
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? primaryColor
                          : null
                        : null
                      : null,
                  border:
                    clicked_item == 3
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? "1px solid #f38321"
                          : null
                        : null
                      : null,
                  color:
                    clicked_item == 3
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? white
                          : null
                        : null
                      : null,
                }}
              >
                {lang.re_new}
              </button>
            ) : (
              <button disabled>{lang.re_new}</button>
            )}
          </div>
          <div className="devics-item-list-button">
            <button
              onClick={handleSelectedLicense}
              style={{
                backgroundColor:
                  clicked_item == 2
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.WorkStationId == item.WorkStationId
                        ? primaryColor
                        : null
                      : null
                    : null,
                border:
                  clicked_item == 2
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.WorkStationId == item.WorkStationId
                        ? "1px solid #f38321"
                        : null
                      : null
                    : null,
                color:
                  clicked_item == 2
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.WorkStationId == item.WorkStationId
                        ? white
                        : null
                      : null
                    : null,
              }}
            >
              {lang.vew}
            </button>
          </div>
        </div>
      ) : licence_view ? (
        <div
          className="order-list-container-div"
          style={{
            backgroundColor:
              reserve == 1
                ? "rgb(249, 247, 252)"
                : expired == 1
                ? "#fef3f3"
                : active == 1
                ? "#fff9fa"
                : null,
          }}
        >
          <p className="devics-item-list-p device-list-p">{expired}</p>
          <p className="devics-item-list-p device-list-p">{reserve}</p>
          <p className="devics-item-list-p device-list-p">{licence_course}</p>
          <p className="devics-item-list-p device-list-p">
            {moment(start_date).format("DD/MM/YYYY")}
          </p>
          <p className="devics-item-list-p device-list-p">
            {moment(expire_date).format("DD/MM/YYYY")}
          </p>
          {active == 1 ? (
            <p
              className="devics-item-list-p device-list-p"
              style={{ color: "green" }}
            >
              {lang.active}
            </p>
          ) : (
            <p
              className="devics-item-list-p device-list-p"
              style={{ color: "red" }}
            >
              {lang.de_active}
            </p>
          )}
        </div>
      ) : (
        <div
          className="order-list-container-div"
          style={{
            backgroundColor:
              days_left <= 5
                ? "rgb(255, 248, 246)"
                : days_left <= 10
                ? "rgb(255, 238, 235)"
                : " #fee5db",
          }}
        >
          <div
            className="devics-item-list-p "
            style={{
              width: "31%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="shop-logo"
              style={{
                width: "30%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                alt=""
                src={
                  logo
                    ? `${image_Url}${domain}/${logo}`
                    : "https://socialimpact.com/wp-content/uploads/2021/03/logo-placeholder.jpg"
                }
                style={{
                  border: !logo ? "1px solid rgb(225, 225, 225)" : null,
                }}
                width=""
                height={55}
                className="shop-logo"
              />
            </div>
            <p
              style={{
                width: "70%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                marginTop: "16px",
              }}
            >
              {shop_name}
            </p>
          </div>
          <p className="devics-item-list-p device-list-p">{device_type}</p>
          <p className="devics-item-list-p device-list-p">
            {work_station_name}
          </p>
          <p className="devics-item-list-p device-list-p ">
            {!expire_date ? 0 : moment(expire_date).format("DD/MM/YYYY")}
          </p>
          <p className="devics-item-list-p device-list-p">
            {!days_left ? (
              <span
                bg="warning"
                text="dark"
                pill
                className="badge badge1"
                style={{
                  animationDelay: "0s",
                  width: "27px",
                  height: "27px",
                  paddingTop: "7px",
                  fontSize: "15px",
                  borderRadius: "15px",

                  color: "black",
                }}
              >
                <p></p>
              </span>
            ) : // <svg
            //   style={{
            //     width: "100%",
            //   }}
            // >
            //   <circle fill="#ff0000" stroke="none" cx="88" cy="80" r="13">
            //     <animate
            //       attributeName="opacity"
            //       dur="1s"
            //       values="0;1;0"
            //       repeatCount="indefinite"
            //       begin="0.1"
            //     />
            //   </circle>
            // </svg>

            days_left < 10 ? (
              <span
                // bg="warning"
                text="dark"
                pill
                className="badge"
                style={{
                  animationDelay: "0s",
                  width: "27px",
                  height: "27px",
                  paddingTop: "7px",
                  fontSize: "15px",
                  borderRadius: "15px",
                  color: "black",
                }}
              >
                {days_left}
              </span>
            ) : (
              days_left
            )}
          </p>
          {active == 1 ? (
            <p
              className="devics-item-list-p device-list-p"
              style={{ color: "green" }}
            >
              {lang.active}
            </p>
          ) : (
            <p
              className="devics-item-list-p device-list-p"
              style={{ color: "red" }}
            >
              {lang.de_active}
            </p>
          )}
          <p
            className="devics-item-list-p device-list-p"
            style={{
              color: item.LastConnection
                ? moment(item.LastConnection)
                    .add(2, "minutes")
                    .format("YYYY-MM-DD HH:mm:ss") >
                  moment(item.DateNow).format("YYYY-MM-DD HH:mm:ss")
                  ? "green"
                  : "red"
                : null,
              // color: "white",
            }}
          >
            {item.LastConnection
              ? moment(item.LastConnection)
                  .add(2, "minutes")
                  .format("YYYY-MM-DD HH:mm:ss") >
                moment(item.DateNow).format("YYYY-MM-DD HH:mm:ss")
                ? "Connected"
                : "Disconnected"
              : null}
          </p>
        </div>
      )}
    </div>
  );
};

export default DevicesListItem;
