import React, { useState } from "react";

import PublicSubmneu from "./PublicSubmenu";
import ItemsList from "./ItemsList";
import ItemsEdit from "./ItemsEdit";
const SubmenuIndex = () => {
  const [clickedItem, setClickedItem] = useState(1);
  return (
    <div
      className="common-styles"
      style={{
        height: window.innerHeight - 160,
      }}
    >
      <div className="container-components-div">
        {clickedItem == 1 ? (
          <PublicSubmneu setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 2 ? (
          <ItemsList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 3 ? (
          <ItemsEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : null}
      </div>
    </div>
  );
};

export default SubmenuIndex;
