import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { SketchPicker } from "react-color";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ListHeader from "../../../components/ListHeader";
import SaveMenuButton from "../../../components/SaveMenuButton";
import { priceLevel } from "../../../constants/PriceLevel";
import { image_Url } from "../../../constants/BaseUrl";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../constants/Colors";
import { updatePublicSubItemAction } from "../../../redux/MainNavGeneral/Actions";
import { dashboard_domain } from "../../../constants/BaseUrl";
const SubmenuItemEdit = ({ setClickedItem }) => {
  const didMountRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    submenu_public_list,
    selected_public_submneu,
    subitem_public_list,
    selected_subitem_public,
    result_failed,
    result_message,
    result_successfull,
    loading,
  } = useSelector((state) => state.MainNavGeneralRedusers);
  const [href, setHref] = useState(null);
  const [appTitle, setAppTitle] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [lastImageName, setLastImageName] = useState("");
  const [image, setImage] = useState("");
  const [visibleImage, setVisibleImage] = useState(true);
  const onSelectPicture = (e) => {
    setImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    // setKey2(e);
  };
  const formData = new FormData();
  const handleSave = () => {
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (image.size > 200000) {
      toast.error(<h5> &nbsp;&nbsp;{lang.image_size}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("SubItemId", selected_subitem_public.Id);
      formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
      formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
      formData.append("ImageUrl", image ? image : "");
      formData.append("DeleteImage", 0);
      formData.append("LastImageUrlName", lastImageName ? lastImageName : "");
      formData.append("JustTitleAndPrice", 0);
      formData.append("VisibleImage", visibleImage ? 1 : 0);
      dispatch(
        updatePublicSubItemAction(
          formData,
          login_key,
          user_id,
          selected_subitem_public.Id,
          appTitle.replaceAll(`"`, `\"\"`),
          appDesc.replaceAll(`"`, `\"\"`),
          selected_subitem_public.SubMenuId
        )
      );
    }
  };

  const handleDeleteImage = () => {
    setHref("");
    setImage("");
    if (selected_subitem_public.ImageUrl != "") {
      if (`${dashboard_domain}${selected_subitem_public.ImageUrl}` != href) {
        setHref(`${dashboard_domain}${selected_subitem_public.ImageUrl}`);
      } else {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("SubItemId", selected_subitem_public.Id);
        formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
        formData.append("AppDescription", appDesc.replaceAll(`"`, `\"\"`));
        formData.append("ImageUrl", image ? image : "");
        formData.append("DeleteImage", 1);
        formData.append("LastImageUrlName", lastImageName ? lastImageName : "");
        formData.append("JustTitleAndPrice", 0);
        formData.append("VisibleImage", visibleImage ? 1 : 0);
        dispatch(
          updatePublicSubItemAction(
            formData,
            login_key,
            user_id,
            selected_subitem_public.Id,
            appTitle.replaceAll(`"`, `\"\"`),
            appDesc.replaceAll(`"`, `\"\"`),
            selected_subitem_public.SubMenuId
          )
        );
      }
    }
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSave();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [priceLevel, appTitle, appDesc]);

  useEffect(() => {
    if (selected_subitem_public) {
      setAppTitle(selected_subitem_public.AppTitle);
      setAppDesc(selected_subitem_public.AppDescription);
      setLastImageName(selected_subitem_public.ImageUrl);
      setVisibleImage(selected_subitem_public.VisibleImage);
      if (selected_subitem_public.ImageUrl) {
        setHref(`${dashboard_domain}${selected_subitem_public.ImageUrl}`);
      } else {
        setHref("");
      }
    }
  }, [selected_subitem_public]);

  useEffect(() => {
    if (didMountRef.current) {
      setClickedItem(2);
    }
    didMountRef.current = true;
  }, [selected_shop]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(2);
    }
  }, [result_successfull]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <ListHeader
        title={lang.item_edit}
        show_back={true}
        clickedItem={() => setClickedItem(2)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div1 ">
            {/* APP TITLE */}
            <div className="general-item-container-divs">
              <h6 className="title h6">{lang.app_title}</h6>
              <div className="sm-div1-2  ">
                <input
                  onChange={(e) => setAppTitle(e.target.value)}
                  type="text"
                  value={appTitle}
                  className="sm-div1-input1"
                  placeholder={lang.placeholderTitle}
                />
                <br />
                <input
                  onChange={(e) => setAppDesc(e.target.value)}
                  type="text"
                  value={appDesc}
                  className="sm-div1-input2"
                  placeholder={lang.placeholderDesc}
                />
              </div>
            </div>
            <div className="general-item-container-divs">
              <div
                className="general-item-container-image-tag "
                style={{ position: "relative" }}
              >
                {href ? (
                  <div
                    className=" general-default-item-container-delet-image-icon"
                    onClick={() => handleDeleteImage()}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        // marginLeft: "1px",
                        color: "red",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}
                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  className="images-style"
                  src={
                    href
                      ? href
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  id="profile_image"
                />
              </div>{" "}
              <p className="image-default-size">{lang.image_size}</p>
              <div className=" general-item-container-choose-photo ">
                <div>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    class="inputfile"
                    accept="image/*"
                    onChange={(e) => onSelectPicture(e)}
                  />
                  <label for="file">
                    <i className="fas fa-camera"></i>
                    <span> Choose Photo</span>
                  </label>
                </div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={visibleImage}
                        onChange={() => setVisibleImage(!visibleImage)}
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.showImage}
                  />
                </FormGroup>
              </div>
            </div>

            {/* <div className="general-item-container-image">
              <h6 className="p-image h6">{lang.image}</h6>
              <div className="general-item-container-image-choose-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onSelectPicture(e)}
                  className="SIE-file-i"
                />
              </div>
              <div
                className="SIE-image-item general-item-container-image-tag"
                style={{ position: "relative" }}
              >
                {href ? (
                  <div
                    className="container-image-delete-icon"
                    // onClick={() => handleDeleteImage(3)}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "1px",
                      }}
                      class="fa fa-times"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}
                <img
                  style={{ backgroundColor: "#F7F7F6" }}
                  src={
                    href
                      ? href
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCd8u8v0V1He_XG9A2CKVv_ZYo2IC3hehmqg&usqp=CAU"
                  }
                  className="images-style"
                  // onLoad={loadImage1}
                />
              </div>
              <p className="image-default-size">{lang.image_size}</p>
            </div> */}
          </div>{" "}
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSave()} />
    </div>
  );
};

export default SubmenuItemEdit;
