import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  createProductSuccessfulAction,
  createProductFailedAction,
  getProductSuccessfullAction,
  getProductAction,
  getProductFailedAction,
  sendProductSettingsFailedAction,
  sendProductSettingsSuccessfulAction,
  getProductOptionsFailedAction,
  getProductOptionsSuccessfullAction,
  deleteProductSuccessfulAction,
  deleteProductFailedAction,
  getProductSubmenuSuccessfulAction,
  getProductSubmenuFailedAction,
  sendProductOptionEditAction,
  sendProductOptionSuccessfullAction,
  sendProductOptionEditFailedAction,
  addProductSubmenuSuccessfulAction,
  addProductSubmenuFailedAction,
  getProductSubmenuAction,
  deleteProductSubmenuSuccessfulAction,
  deleteProductSubmenuFailedAction,
  updateProductSubmenuEditAction,
  updateProductSubmenuEditFailedAction,
  updateProductSubmenuEditSuccessfullAction,
  getProductOptionsAction,
} from "./Action";
import { getSubmenuAction } from "../submenu/Action";
import {
  createProductServices,
  getProductServices,
  sendProductSettingsServices,
  getProductOptionsService,
  deleteProductService,
  getProductSubmenuService,
  sendProductOptionEditServices,
  addProductSubmenuService,
  deleteProductSubmenuService,
  updateProductSubmenuEditServices,
} from "../../services/ProductService";

import { justTitleAndPriceAction } from "../menu/Actions";
function* getProductsList({ payload }) {
  try {
    const response = yield call(
      getProductServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.categoryId
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Productslist) {
          if (response.data.Productslist.length > 0) {
            let my_list = [];

            for (let i = 0; i < response.data.Productslist.length; i++) {
              if (!response.data.Productslist[i].IsDeleted)
                my_list.push({
                  ...response.data.Productslist[i],
                  AppVisible: response.data.Productslist[i].AppVisible
                    ? response.data.Productslist[i].AppVisible.split(",")
                    : [],
                  KioskVisible: response.data.Productslist[i].KioskVisible
                    ? response.data.Productslist[i].KioskVisible.split(",")
                    : [],
                  WebVisible: response.data.Productslist[i].WebVisible
                    ? response.data.Productslist[i].WebVisible.split(",")
                    : [],
                  PrinterId: response.data.Productslist[i].PrinterId
                    ? response.data.Productslist[i].PrinterId.split(",")
                    : [],
                  showEditBox: false,
                  showDeleteBox: false,
                });
            }

            yield put(getProductSuccessfullAction(my_list));
          } else {
            yield put(getProductFailedAction());
          }
        } else {
          yield put(getProductFailedAction());
        }
      } else {
        yield put(getProductFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getProductFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    put(getProductFailedAction(error.message));
  }
}
function* createProduct({ payload }) {
  try {
    const response = yield call(
      createProductServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.categoryId,
      payload.title,
      payload.describtion,
      payload.amount
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(createProductSuccessfulAction(response.data.ResultMessage));
        yield put(
          getProductAction(
            payload.login_key,
            payload.user_id,
            payload.shopId,
            payload.categoryId
          )
        );
      } else {
        yield put(createProductFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createProductFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(createProductFailedAction(error.message));
  }
}
function* sendProductSettings({ payload }) {
  try {
    const response = yield call(
      sendProductSettingsServices,
      payload.formData,
      payload.cat_id,
      payload.login_key,
      payload.user_id,
      payload.product_id,
      payload.shop_id,
      payload.just_title_and_price,
      payload.app_title,
      payload.app_description,
      payload.web_title,
      payload.web_description,
      payload.kiosk_title,
      payload.kiosk_description,
      payload.second_language,
      payload.app_visible,
      payload.web_visible,
      payload.kiosk_visible,
      payload.amount_delivery,
      payload.amount_collection,
      payload.amount_takeaway,
      payload.amount_eatIn,
      payload.printer_id,
      payload.report_section,
      payload.is_show_options,
      payload.product_group,
      payload.vat_id,
      payload.is_suggested,
      payload.is_popular,
      payload.is_dif_option_vat,
      payload.unit_id,
      payload.barcode,
      payload.custom_title,
      payload.close_count,
      payload.custom_title_in_last,
      payload.title_in_last,
      payload.link_to,
      payload.is_auto_display_sub_menu,
      payload.print_label,
      payload.app_sort_id,
      payload.web_sort_id,
      payload.kiosk_sort_id,
      payload.app_bg_color,
      payload.app_title_font_size,
      payload.app_title_font_family,
      payload.app_title_color,
      payload.app_tile_width,
      payload.app_tile_height,
      payload.app_image_url,
      payload.app_visible_image,
      payload.web_image_url,
      payload.web_visible_image,
      payload.kiosk_image_url,
      payload.kiosk_visible_image,
      payload.default_vat_for_all_option
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          sendProductSettingsSuccessfulAction(response.data.ResultMessage)
        );
        yield put(justTitleAndPriceAction(payload.just_title_and_price));
        yield put(
          getProductAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(sendProductSettingsFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(sendProductSettingsFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(sendProductSettingsFailedAction(error.message));
  }
}

// GET PRODUCT  OPTION
function* getProductOptionList({ payload }) {
  try {
    const response = yield call(
      getProductOptionsService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_id,
      payload.categoryId
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.ProductOptionslist) {
          if (response.data.ProductOptionslist.length > 0) {
            let my_list = [];

            for (let i = 0; i < response.data.ProductOptionslist.length; i++) {
              if (!response.data.ProductOptionslist[i].IsDeleted) {
                my_list.push({
                  ...response.data.ProductOptionslist[i],
                  showEditBox: false,
                });
              }
            }
            yield put(getProductOptionsSuccessfullAction(my_list));
          } else {
            yield put(getProductOptionsFailedAction(""));
          }
        } else {
          yield put(getProductOptionsFailedAction(""));
        }
      } else {
        yield put(getProductOptionsFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getProductOptionsFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getProductOptionsFailedAction(error.message));
  }
}
// DELETE PRODUCT
function* deleteProduct({ payload }) {
  try {
    const response = yield call(
      deleteProductService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(deleteProductSuccessfulAction(response.data.ResultMessage));
        yield put(
          getProductAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.cat_id
          )
        );
      } else {
        yield put(deleteProductFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteProductFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(deleteProductFailedAction(error.message));
  }
}
//GET PRODUCT SUBMENU
function* getProductSubmenu({ payload }) {
  try {
    const response = yield call(
      getProductSubmenuService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.ProductSubmenuslist) {
          if (response.data.ProductSubmenuslist.length > 0) {
            let new_list = [];
            for (let i = 0; i < response.data.ProductSubmenuslist.length; i++) {
              if (!response.data.ProductSubmenuslist[i].IsDeleted) {
                new_list.push({
                  ...response.data.ProductSubmenuslist[i],
                  showEditBox: false,
                  showDeleteBox: false,
                });
              }
            }
            yield put(getProductSubmenuSuccessfulAction(new_list));
          } else {
            yield put(getProductSubmenuFailedAction(""));
          }
        } else {
          yield put(getProductSubmenuFailedAction(""));
        }
      } else {
        yield put(getProductSubmenuFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getProductSubmenuFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getProductSubmenuFailedAction(error.message));
  }
}
//ADD PRODUCT SUBMENU
function* addProductSubmenu({ payload }) {
  try {
    const response = yield call(
      addProductSubmenuService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.submenu_id,
      payload.product_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          addProductSubmenuSuccessfulAction(response.data.ResultMessage)
        );
        yield put(
          getProductSubmenuAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id
          )
        );
      } else {
        yield put(addProductSubmenuFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(addProductSubmenuFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(addProductSubmenuFailedAction(error.message));
  }
}
//DELETE PRODUCT SUBMENU
function* deleteProductSubmenu({ payload }) {
  try {
    const response = yield call(
      deleteProductSubmenuService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.product_id,
      payload.product_submenu_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteProductSubmenuSuccessfulAction(response.data.ResultMessage)
        );
        yield put(
          getProductSubmenuAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id
          )
        );
      } else {
        yield put(
          deleteProductSubmenuFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(deleteProductSubmenuFailedAction("Server error, try again"));
    }
  } catch (error) {
    yield put(deleteProductSubmenuFailedAction(error.message));
  }
}

function* sendProductOptionEdit({ payload }) {
  try {
    const response = yield call(
      sendProductOptionEditServices,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shop_id,
      payload.product_id,
      payload.option_id,
      payload.app_title,
      payload.web_title,
      payload.kiosk_title,
      payload.amount_delivery,
      payload.amount_collection,
      payload.amount_takeaway,
      payload.amount_eatIn,
      payload.vat_id,
      payload.stock,
      payload.swap_title_position,
      payload.app_visible,
      payload.web_visible,
      payload.kisok_visible,
      payload.app_sort_id,
      payload.web_sort_id,
      payload.kiosk_sort_id,
      payload.just_title_and_price,
      payload.calories
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          sendProductOptionSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getProductOptionsAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id,
            payload.cat_id
          )
        );
      } else {
        yield put(
          sendProductOptionEditFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(sendProductOptionEditFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(sendProductOptionEditFailedAction(error.message));
  }
}
function* updateProductSubmenuEdit({ payload }) {
  try {
    const response = yield call(
      updateProductSubmenuEditServices,
      payload.formData1,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shop_id,
      payload.product_id,
      payload.submenu_id,
      payload.app_title,
      payload.app_description,
      payload.web_title,
      payload.web_description,
      payload.kiosk_title,
      payload.kiosk_description,
      payload.second_language,
      payload.is_free,
      payload.amount,
      payload.min_select,
      payload.max_select,
      payload.app_visible,
      payload.web_visible,
      payload.kiosk_visible,
      payload.app_sort_id,
      payload.web_sort_id,
      payload.kiosk_sort_id,
      payload.app_bg_color,
      payload.app_title_font_size,
      payload.app_title_font_family,
      payload.app_title_color,
      payload.app_tile_width,
      payload.app_tile_height,
      payload.is_first,
      payload.printer_id,
      payload.is_after,
      payload.count_item_for_use_amount,
      payload.app_image_url,
      payload.app_visible_image,
      payload.web_image_url,
      payload.web_visible_image,
      payload.kiosk_image_url,
      payload.kiosk_visible_image,
      payload.one_of_each_item
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateProductSubmenuEditSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getProductSubmenuAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.product_id
          )
        );
      } else {
        yield put(
          updateProductSubmenuEditFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateProductSubmenuEditFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateProductSubmenuEditFailedAction(error.message));
  }
}
export function* watchProductSubmenuEdit() {
  yield takeLatest(
    action_types.UPDATE_PRODUCT_SUBMENU_EDIT,
    updateProductSubmenuEdit
  );
}

export function* watchSendProductOptionEdit() {
  yield takeLatest(
    action_types.SEND_PRODUCT_OPTION_EDIT,
    sendProductOptionEdit
  );
}
export function* wathGetProduct() {
  yield takeLatest(action_types.GET_PRODUCTS, getProductsList);
}
export function* wathCreateProduct() {
  yield takeLatest(action_types.CREATE_PRODUCT_SAGA, createProduct);
}
export function* wathPostProduct() {
  yield takeLatest(action_types.SEND_PRODUCT_SETTINGS, sendProductSettings);
}
export function* wathGetProductOptionsList() {
  yield takeLatest(action_types.GET_PRODUCT_OPTION, getProductOptionList);
}
export function* watchDeleteProduct() {
  yield takeLatest(action_types.DELETE_PRODUCT, deleteProduct);
}
export function* watchGetProductSubmenu() {
  yield takeLatest(action_types.GET_PRODUCT_SUBMENU, getProductSubmenu);
}
export function* watchAddProductSubmenu() {
  yield takeLatest(action_types.ADD_PRODUCT_SUBMENU, addProductSubmenu);
}
export function* watchDeleteProductSubmenu() {
  yield takeLatest(action_types.DELETE_PRODUCT_SUBMENU, deleteProductSubmenu);
}
export default function* rootProduct() {
  yield all([
    fork(wathGetProduct),
    fork(wathCreateProduct),
    fork(wathPostProduct),
    fork(wathGetProductOptionsList),
    fork(watchDeleteProduct),
    fork(watchGetProductSubmenu),
    fork(watchSendProductOptionEdit),
    fork(watchAddProductSubmenu),
    fork(watchDeleteProductSubmenu),
    fork(watchProductSubmenuEdit),
  ]);
}
