import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showDeleteCustomerPopupAction,
  deleteCustomerAction,
} from "../../../redux/Customers/Actions";
const DeleteCustomerPopup = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { deleting_customer_info, show_delete_customer_popup, customers_list } =
    useSelector((state) => state.customersRedusers);

  const handleDeleted = () => {
    dispatch(
      deleteCustomerAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        deleting_customer_info[0].CustomerId,
        deleting_customer_info[1],
        deleting_customer_info[2],
        deleting_customer_info[3]
      )
    );
  };

  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showDeleteCustomerPopupAction(false))}
    >
      <div
        className="delete_customer_popup_container "
        onClick={(i) => i.stopPropagation()}
      >
        <div className="delete_customer_popup_container_icon">
          <i
            class="fa fa-times"
            aria-hidden="true"
            onClick={() => dispatch(showDeleteCustomerPopupAction(false))}
          ></i>
        </div>
        <div className="delete_customer_popup ">
          <h6 style={{ fontSize: "19px" }}>{lang.delete_message}</h6>
          <div
            className="menu-list-container-cancel-and-ok-button"
            // onClick={() => handleCancelDeleteBox(item)}
          >
            <button
              onClick={() => dispatch(showDeleteCustomerPopupAction(false))}
            >
              {lang.cancel}
            </button>
            <button onClick={() => handleDeleted()}>{lang.ok}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCustomerPopup;
