import React from "react";

import {
  main_nav_button_color,
  primaryColor,
  white,
  main_nav_back_color,
} from "../../../constants/Colors";
import { useState, useEffect } from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import HeaderList from "../../../components/HeaderList";
import { shopListByGroupIdAction } from "../../../redux/Users/Actions";
import OrderListItem from "../../../components/OrderListItem ";
import {
  getFinancialOrderListAction,
  visibleRefunPopupAction,
} from "../../../redux/financial/Actions";
import { setDefaultAction } from "../../../redux/menu/Actions";
import Loader from "../../../components/Loader";
import { codeDetailListAction } from "../../../redux/settings/Action";
import {
  Sale_method,
  financial_order_sale_method,
} from "../../../constants/OrderStatus";
const Refund = () => {
  const dispatch = useDispatch();
  const { orders_list, loading_orders } = useSelector(
    (state) => state.ordersReduser
  );
  const { loading, financial_order_list } = useSelector(
    (state) => state.financialReduser
  );
  const {
    selected_financial_order,
    result_message,
    result_successfull,
    result_failed,
  } = useSelector((state) => state.financialReduser);
  const { shop_list_by_group_id } = useSelector((state) => state.usersReduser);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { payment_method, payment_method_id } = useSelector(
    (state) => state.categoryList
  );

  const { lang } = useSelector((state) => state.selectedLanguage);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [shopList, setShopList] = useState([]);
  const [financialOrderList, setFinancialOrderList] = useState([]);
  const [financialOrderList1, setFinancialOrderList1] = useState([]);
  const [selectedCourseKey, setSelectedCourseKey] = useState(0);
  const [saleMethod, setSaleMethod] = useState({
    label: "Web delivery",
    value: 1000105,
  });
  const [selectedShop, setSelectedShop] = useState({
    GroupId: 0,
    value: 999999,
    label: "All",
    selected: false,
  });
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  // const handleGetList = () => {
  //   dispatch(
  //     getFinancialOrderListAction(
  //       login_key,
  //       user_id,
  //       selectedShop.value,
  //       moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
  //       moment(endDate).format("YYYY-MM-DD hh:mm:ss")
  //     )
  //   );
  // };

  const handleGetRefundList = (key) => {
    setSaleMethod({ label: "Web delivery", value: 1000105 });
    setSelectedCourseKey(key);

    let date = new Date();
    let newDate = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
      newDate.setDate(newDate.getDate() - 1);
    }
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay();
    let decreased_days = day == 0 ? day + 6 : day - 1;
    let new_date = newDate;
    new_date.setDate(new_date.getDate() - decreased_days);

    let this_month =
      month < 9
        ? `${year}-0${month + 1}-01 ${hours + ":" + minutes + ":" + seconds} `
        : `${year}-${month + 1}-01 ${hours + ":" + minutes + ":" + seconds}`;

    if (key == 1) {
      setStartDate(new_date);
      setEndDate(date);
    } else if (key == 2) {
      setStartDate(new Date(this_month));
      setEndDate(date);
    }

    if (
      moment(startDate).format("YYYY-MM-DD HH:mm:ss") <=
      moment(endDate).format("YYYY-MM-DD HH:mm:ss")
    ) {
      if (selectedShop) {
        dispatch(
          getFinancialOrderListAction(
            login_key,
            user_id,
            selectedShop.value,
            key == 1
              ? moment(new_date).format("YYYY-MM-DD HH:mm:ss")
              : key == 2
              ? this_month
              : key == 3
              ? moment(startDate).format("YYYY-MM-DD HH:mm:ss")
              : "",
            key == 1
              ? moment(date).format("YYYY-MM-DD HH:mm:ss")
              : key == 2
              ? moment(date).format("YYYY-MM-DD HH:mm:ss")
              : key == 3
              ? moment(endDate).format("YYYY-MM-DD HH:mm:ss")
              : ""
          )
        );
      }
    } else {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.start_date_end_date_in_order_list}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 5000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };

  const handleVisibleRefundPopup = (item) => {
    dispatch(
      visibleRefunPopupAction([
        true,
        { ...item, financial_order: true },
        startDate,
        endDate,
        selectedShop.value,
      ])
    );
    dispatch(codeDetailListAction(login_key, user_id, item.ShopId));
  };

  const handleFilterBySaleMethod = (list, item) => {
    let my_list = [];
    setSaleMethod(list);
    const copy_financialOrderList = [...financialOrderList1];
    if (list.value == 1) {
      my_list = copy_financialOrderList;
    } else {
      if (copy_financialOrderList) {
        if (copy_financialOrderList.length > 0) {
          for (let i = 0; i < copy_financialOrderList.length; i++) {
            if (copy_financialOrderList[i].SaleMethod == list.value) {
              my_list.push(copy_financialOrderList[i]);
            }
          }
        }
      }
    }
    setFinancialOrderList(my_list);
  };
  // const handleGetSales = (key) => {
  //   setSelectedCourseKey(key);
  //   setDebitFilter({ label: "Debit", value: 2 });
  //   let date = new Date();
  //   let newDate = new Date();
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   let seconds = date.getSeconds();
  //   if (hours < 5) {
  //     date.setDate(date.getDate() - 1);
  //     newDate.setDate(newDate.getDate() - 1);
  //   }

  //   // console.log("Time:", hours + ":" + minutes + ":" + seconds);
  //   let year = date.getFullYear();
  //   let month = date.getMonth();
  //   let day = date.getDay();
  //   let decreased_days = day == 0 ? day + 6 : day - 1;
  //   let new_date = newDate;
  //   new_date.setDate(new_date.getDate() - decreased_days);

  //   let this_month =
  //     month < 9
  //       ? `${year}-0${month + 1}-01 ${hours + ":" + minutes + ":" + seconds} `
  //       : `${year}-${month + 1}-01 ${hours + ":" + minutes + ":" + seconds}`;

  //   if (key == 1) {
  //     setStartDate(new_date);
  //     setEndDate(date);
  //   } else if (key == 2) {
  //     setStartDate(new Date(this_month));
  //     setEndDate(date);
  //   }

  //   if (
  //     moment(startDate).format("YYYY-MM-DD HH:mm:ss") <=
  //     moment(endDate).format("YYYY-MM-DD HH:mm:ss")
  //   ) {
  //     if (selected_shop) {
  //       if (selected_shop.length > 0) {
  //         dispatch(
  //           getFinancialRefundListAction(
  //             login_key,
  //             user_id,
  //             selected_shop[0].ShopId,
  //             key == 1
  //               ? moment(new_date).format("YYYY-MM-DD HH:mm:ss")
  //               : key == 2
  //               ? this_month
  //               : key == 3
  //               ? moment(startDate).format("YYYY-MM-DD HH:mm:ss")
  //               : "",
  //             key == 1
  //               ? moment(date).format("YYYY-MM-DD HH:mm:ss")
  //               : key == 2
  //               ? moment(date).format("YYYY-MM-DD HH:mm:ss")
  //               : key == 3
  //               ? moment(endDate).format("YYYY-MM-DD HH:mm:ss")
  //               : ""
  //           )
  //         );
  //       }
  //     }
  //   } else {
  //     toast.error(
  //       <h5>
  //         {" "}
  //         &nbsp;&nbsp;{lang.start_date_end_date_in_order_list}&nbsp;&nbsp;{" "}
  //       </h5>,
  //       {
  //         position: "top-center",
  //         duration: 5000,
  //         style: { backgroundColor: "red", color: "white" },
  //         icon: (
  //           <i
  //             style={{ fontSize: 40 }}
  //             className="fa fa-times-circle"
  //             aria-hidden="true"
  //           ></i>
  //         ),
  //       }
  //     );
  //   }
  // };
  useEffect(() => {
    let myList = [];
    let new_list = [];
    if (shop_list_by_group_id) {
      if (shop_list_by_group_id.length > 0) {
        myList.push({
          GroupId: 0,
          ShopId: 999999,
          Title: "All",
          selected: false,
        });
        for (let i = 0; i < shop_list_by_group_id.length; i++) {
          myList.push({ ...shop_list_by_group_id[i] });
        }
      }
    }
    if (myList) {
      if (myList.length > 0) {
        for (let i = 0; i < myList.length; i++) {
          new_list.push({
            ...myList[i],
            label:
              myList[i].ShopId == 999999
                ? myList[i].Title
                : `${myList[i].ShopId}  ${myList[i].Title}`,
            value: myList[i].ShopId,
          });
        }
      }
    }
    setShopList(new_list);
  }, [shop_list_by_group_id]);

  useEffect(() => {
    dispatch(shopListByGroupIdAction(login_key, user_id, 0));
  }, []);

  useEffect(() => {
    let date = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
    }
    setStartDate(date);
    setEndDate(date);

    if (login_key && user_id) {
      dispatch(
        getFinancialOrderListAction(
          login_key,
          user_id,
          selectedShop.value,
          moment(date).format("YYYY-MM-DD HH:mm:ss"),
          moment(date).format("YYYY-MM-DD HH:mm:ss")
        )
      );
    }
  }, []);

  useEffect(() => {
    setSaleMethod({ label: "Web delivery", value: 1000105 });
    let new_list = [];
    if (financial_order_list) {
      if (financial_order_list.length > 0) {
        for (let i = 0; i < financial_order_list.length; i++) {
          if (financial_order_list[i].SaleMethod == saleMethod.value) {
            new_list.push(financial_order_list[i]);
          }
        }
        setFinancialOrderList(new_list);
        setFinancialOrderList1(financial_order_list);
      } else {
        setFinancialOrderList([]);
        setFinancialOrderList1([]);
      }
    } else {
      setFinancialOrderList([]);
      setFinancialOrderList1([]);
    }
  }, [financial_order_list]);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return width1 <= 560 ? (
    <div className="countaining-div common-styles">
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          {" "}
          <div style={{ width: "100%" }}>
            <h6 className="h6">{lang.shop_list}</h6>
            <Select
              options={shopList}
              defaultValue={selectedShop}
              isSearchable={true}
              onChange={(list, item) => {
                setSelectedShop(list);
                setSelectedCourseKey(0);
              }}
              value={selectedShop}
              isMulti={false}
              styles={customStyles}
              // className="select"
            />
          </div>{" "}
          <div
            className="mobile-orders-container-date"
            style={{ margin: "15px 0px 15px 0px" }}
          >
            <div style={{ width: "48%" }}>
              <h6 className="h6">{lang.sale_method}</h6>
              <Select
                options={financial_order_sale_method}
                defaultValue={saleMethod}
                isSearchable={false}
                onChange={(list, item) => handleFilterBySaleMethod(list, item)}
                value={saleMethod}
                isMulti={false}
                styles={customStyles}
                // className="select"
              />
            </div>
            <div
              className="dashboard-refund-container-any-filter-section"
              style={{ width: "50%" }}
            >
              <button
                style={{
                  backgroundColor: selectedCourseKey == 1 ? primaryColor : null,
                  color: selectedCourseKey == 1 ? "white" : null,
                  border: selectedCourseKey == 1 ? "none" : null,
                }}
                onClick={() => handleGetRefundList(1)}
              >
                Week
              </button>
              <button
                style={{
                  backgroundColor: selectedCourseKey == 2 ? primaryColor : null,
                  color: selectedCourseKey == 2 ? "white" : null,
                  border: selectedCourseKey == 2 ? "none" : null,
                }}
                onClick={() => handleGetRefundList(2)}
              >
                Month
              </button>
            </div>
          </div>
          <div className="mobile-orders-container-date">
            <div style={{ width: "38%", height: "40px" }}>
              <h6 className="h6">{lang.from}</h6>

              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(e) => setStartDate(e)}
                className="date_picker"
                calendarClassName="calendarClassName"
              />
            </div>
            <div style={{ width: "38%", height: "40px" }}>
              <h6 className="h6">{lang.to}</h6>

              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={endDate}
                onChange={(e) => setEndDate(e)}
                className="date_picker"
              />
            </div>
            <div
              style={{
                width: "20%",
                height: "40px",
                marginTop: "27px",
              }}
              onClick={() => handleGetRefundList(3)}
            >
              <button
                style={{
                  border: `1px solid ${primaryColor}`,
                  backgroundColor: primaryColor,
                  width: "100%",
                  color: white,
                  height: "100%",
                  borderRadius: "2px",
                }}
              >
                Result
              </button>
            </div>
          </div>
          <div style={{ paddingTop: "15px" }}>
            {financialOrderList
              ? financialOrderList.length > 0
                ? financialOrderList.map((item, index) => (
                    <div
                      className="mobile-orders-container1"
                      key={item.OrderId}
                    >
                      <div className="mobile-orders-container-orderBox">
                        <div className="mobile-orders-container-first-row">
                          <div className="mobile-orders-container-first-row-labels">
                            <p>{lang.orderId}</p>
                            <p>{lang.shop_name}</p>
                            <p>{lang.order_date}</p>
                          </div>
                          <div className="mobile-orders-container-first-row-details">
                            <p>{item.OrderId}</p>

                            <p>
                              {item.ShopTitle}
                              {/* {moment(item.OrderDate).format("YYYY/MM/DD-HH:mm")} */}
                            </p>
                            <p>
                              {moment(item.OrderDate).format("MM/DD-HH:mm")}
                            </p>
                          </div>
                        </div>

                        <div className="mobile-refund-container-second-row">
                          <div className="mobile-refund-container-second-row-datas">
                            <div className="mobile-orders-container-first-row-labels">
                              <p>{lang.total}</p>
                              <p>{lang.sale_method}</p>
                            </div>
                            <div className="mobile-orders-container-first-row-details">
                              <p>£ {item.ForPay.toFixed(2)}</p>
                              <p>{item.SaleMethodName}</p>
                            </div>{" "}
                          </div>
                          <div className="mobile-refund-container-second-row-container-button">
                            <button
                              disabled={item.RefoundList}
                              type=""
                              onClick={() => handleVisibleRefundPopup(item)}
                              style={{
                                backgroundColor: item.RefoundList
                                  ? "rgb(11, 163, 11)"
                                  : null,
                              }}
                            >
                              {item.RefoundList ? lang.refunded : lang.refund}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null
              : null}
          </div>
        </div>
      </div>{" "}
      {loading ? <Loader /> : null}
    </div>
  ) : (
    <div
      className="countaining-div common-styles"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
      id="countaining"
    >
      {" "}
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div
            className="sm-div2"
            style={{ marginTop: "10px", marginBottom: "0px" }}
          >
            <div style={{ width: "20%" }}>
              <h6 className="h6">{lang.shop_list}</h6>
              <Select
                options={shopList}
                defaultValue={selectedShop}
                isSearchable={true}
                onChange={(list, item) => {
                  setSelectedShop(list);
                  setSelectedCourseKey(0);
                }}
                value={selectedShop}
                isMulti={false}
                styles={customStyles}
                // className="select"
              />
            </div>
            <div style={{ width: "15%" }}>
              <h6 className="h6">{lang.sale_method}</h6>
              <Select
                options={financial_order_sale_method}
                defaultValue={saleMethod}
                isSearchable={false}
                onChange={(list, item) => handleFilterBySaleMethod(list, item)}
                value={saleMethod}
                isMulti={false}
                styles={customStyles}
                // className="select"
              />
            </div>
            <div className="dashboard-refund-container-any-filter-section">
              <button
                style={{
                  backgroundColor: selectedCourseKey == 1 ? primaryColor : null,
                  color: selectedCourseKey == 1 ? "white" : null,
                  border: selectedCourseKey == 1 ? "none" : null,
                }}
                onClick={() => handleGetRefundList(1)}
              >
                {lang.week}
              </button>
              <button
                style={{
                  backgroundColor: selectedCourseKey == 2 ? primaryColor : null,
                  color: selectedCourseKey == 2 ? "white" : null,
                  border: selectedCourseKey == 2 ? "none" : null,
                }}
                onClick={() => handleGetRefundList(2)}
              >
                {lang.month}
              </button>
            </div>
            <div style={{ width: "15%", height: "40px" }}>
              <h6 className="h6">{lang.from}</h6>

              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(e) => setStartDate(e)}
                className="date_picker"
                calendarClassName="calendarClassName"
              />
            </div>
            <div style={{ width: "15%", height: "40px" }}>
              <h6 className="h6">{lang.to}</h6>

              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={endDate}
                onChange={(e) => setEndDate(e)}
                className="date_picker"
              />
            </div>
            <div
              style={{
                width: "10%",
                height: "40px",
                marginTop: "27px",
              }}
              // onClick={handleSearchOrderByDate}
            >
              <button
                style={{
                  border: `1px solid ${primaryColor}`,
                  backgroundColor: primaryColor,
                  width: "100%",
                  color: white,
                  height: "100%",
                  borderRadius: "2px",
                }}
                onClick={() => handleGetRefundList(3)}
              >
                {lang.result}
              </button>
            </div>
          </div>
          <div style={{ marginTop: 0 }}>
            <HeaderList number={3} />
            {financialOrderList
              ? financialOrderList.length > 0
                ? financialOrderList.map((item, index) => (
                    <OrderListItem
                      index1={index}
                      item={item}
                      order_id={item.OrderId}
                      invice_id={item.InvoiceId}
                      status={item.OrderStatus}
                      status_name={item.OrderStatusName}
                      channels={item.DeviceTypeName}
                      requested_for={item.OrderDate}
                      sale_mathod={item.SaleMethodName}
                      // store_name={item.Store_name}
                      shop_name={item.ShopTitle}
                      post_code={item.CustomerPostCode}
                      order_amount={item.ForPay.toFixed(2)}
                      payment_status={item.PayMethodName}
                      customer={item.CustomerFullName}
                      sale_mathod_number={item.SaleMethodName}
                      number={3}
                      on_click1={() => handleVisibleRefundPopup(item)}
                    />
                  ))
                : null
              : null}
          </div>
        </div>
      </div>
      {loading ? <Loader /> : null}
    </div>
  );
};

export default Refund;
