export const ENGLISH = "ENGLISH";
export const TURKISH = "TURKISH";
export const PERSIAN = "PERSIAN";

// GET CATEGORY
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORIES_SUCCESSFULL = "GET_CATEGORIES_SUCCESSFULL";
export const GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED";
export const GET_CATEGORY_SAGA = "GET_CATEGORY_SAGA";

//EDIT CATEGORY STATUS
export const EDIT_CATEGORY_STATUS = "EDIT_CATEGORY_STATUS";
export const EDIT_CATEGORY_STATUS_SUCCESSFULL =
  "EDIT_CATEGORY_STATUS_SUCCESSFULL";
export const EDIT_CATEGORY_STATUS_FAILED = "EDIT_CATEGORY_STATUS_FAILED ";

// POST CATEGORY
export const SEND_CATEGORY_SETTINGS = "SEND_CATEGORY_SETTINGS";
export const SEND_CATEGORY_SETTINGS_SUCCESSFULL =
  "SEND_CATEGORY_SETTINGS_SUCCESSFULL";
export const SEND_CATEGORY_SETTINGS_FAILED = "SEND_CATEGORY_SETTINGS_FAILED";

// CREATE CATEGORY
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SAGA = "CREATE_CATEGORY_SAGA";
export const CREATE_CATEGORY_SUCCESSFULL = "CREATE_CATEGORY_SUCCESSFULL";
export const CREATE_CATEGORY_FAILED = "CREATE_CATEGORY_FAILED";

// DELETEE CATEGORY
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESSFULL = "DELETE_CATEGORY_SUCCESSFULL";
export const DELETE_CATEGORY_FAILED = "DELETE_CATEGORY_FAILED";
export const SET_DEFAULT = "SET_DEFAULT";

// GET DROPDOWN DATA
export const GET_DROPDOWN = "GET_DROPDOWN";
export const GET_DROPDOWN_SUCCESSFULL = "GET_DROPDOWN_SUCCESSFULL";
export const GET_DROPDOWN_FAILED = "GET_DROPDOWN_FAILED";
export const TEST_ACTION = "TEST_ACTION";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";

//GET PRODUCT
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SAGA = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESSFULL = "GET_PRODUCTS_SUCCESSFULL";
export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";

// EDIT PRODUCT STATUS
export const EDIT_PRODUCT_STATUS = "EDIT_PRODUCT_STATUS";
export const EDIT_PRODUCT_STATUS_SUCCESSFULL =
  "EDIT_PRODUCT_STATUS_SUCCESSFULL";
export const EDIT_PRODUCT_STATUS_FAILED = "EDIT_PRODUCT_STATUS_FAILED";

//CREATE PRODUCT
export const CREATE_PRODUCT_SAGA = "CREATE_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESSFULL = "CREATE_PRODUCT_SUCCESSFULL";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";

//POST PRODUCT
export const SEND_PRODUCT_SETTINGS = "SEND_PRODUCT_SETTINGS";
export const SEND_PRODUCT_SETTINGS_SUCCESSFULL =
  "SEND_PRODUCT_SETTINGS_SUCCESSFULL";
export const SEND_PRODUCT_SETTINGS_FAILED = "SEND_PRODUCT_SETTINGS_FAILED";

export const SELECTED_PRODUCT = "SELECTED_PRODUCT";

//DELETE PRODUCT
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESSFULL = "DELETE_PRODUCT_SUCCESSFULL";
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";

//CATEGORY OPTIONS
export const GET_CATEGORY_OPTIONS = "GET_CATEGORY_OPTIONS";
export const GET_CATEGORY_OPTIONS_SUCCESSFULL =
  "GET_CATEGORY_OPTIONS_SUCCESSFULL";
export const GET_CATEGORY_OPTIONS_FAILED = "GET_CATEGORY_OPTIONS_FAILED";
export const SET_SELECTED_CATEGORY_OPTION = "SET_SELECTED_CATEGORY_OPTION";
// CREATE OPTIONS
export const CREATE_CATEGORY_OPTION = "CREATE_OPTION";
export const CREATE_CATEGORY_OPTION_SUCCESSFULL =
  "CREATE_CATEGORY_OPTION_SUCCESSFULL";
export const CREATE_CATEGORY_OPTION_FAILED = "CREATE_CATEGORY_OPTION_FAILED";
// SAVE  CATEGORY  OPTION
export const SEND_CATEGORY_OPTION = "SEND_CATEGORY_OPTION";
export const SEND_CATEGORY_OPTION_SUCCESSFULL =
  "SEND_CATEGORY_OPTION_SUCCESSFULL";
export const SEND_CATEGORY_OPTION_FAILED = "SEND_CATEGORY_OPTION_FAILED";
// DELETE  CATEGORY  OPTION
export const DELETE_CATEGORY_OPTION = "DELETE_CATEGORY_OPTION";
export const DELETE_CATEGORY_OPTION_SUCCESSFULL =
  "DELETE_CATEGORY_OPTION_SUCCESSFULL";
export const DELETE_CATEGORY_OPTION_FAILED = "DELETE_CATEGORY_OPTION_FAILED";
// GET PRODUCT  OPTION
export const GET_PRODUCT_OPTION = "GET_PRODUCT_OPTION";
export const GET_PRODUCT_OPTION_SUCCESSFULL = "GET_PRODUCT_OPTION_SUCCESSFULL";
export const GET_PRODUCT_OPTION_FAILED = "GET_PRODUCT_OPTION_FAILED";
// GET PRODUCT SUBMENU
export const GET_PRODUCT_SUBMENU = "GET_PRODUCT_SUBMENU";
export const GET_PRODUCT_SUBMENU_SUCCESSFULL =
  "GET_PRODUCT_SUBMENU_SUCCESSFULL";
export const GET_PRODUCT_SUBMENU_FAILED = "GET_PRODUCT_SUBMENU_FAILED";
// ADD PRODUCT SUBMENU
export const ADD_PRODUCT_SUBMENU = "ADD_PRODUCT_SUBMENU";
export const ADD_PRODUCT_SUBMENU_SUCCESSFULL =
  "ADD_PRODUCT_SUBMENU_SUCCESSFULL";
export const ADD_PRODUCT_SUBMENU_FAILED = "ADD_PRODUCT_SUBMENU_FAILED";

//SELECTED PRODUCT SUBMENU
export const SELECTED_PRODUCT_SUBMENU = "SELECTED_PRODUCT_SUBMENU";

//SELECTED PRODUCT OPTION LIST
export const SELECTED_PRODUCT_OPTION_LIST = "SELECTED_PRODUCT_OPTION_LIST";
//CREATE SUBMENU
export const CREATE_SUBMENU = "CREATE_SUBMENU";
export const CREATE_SUBMENU_SUCCESSFULL = "CREATE_SUBMENU_SUCCESSFULL";
export const CREATE_SUBMENU_FAILED = "CREATE_SUBMENU_FAILED";

//GET SUBMENU
export const GET_SUBMENU = "GET_SUBMENU";
export const GET_SUBMENU_SUCCESSFULL = " GET_SUBMENU_SUCCESSFULL";
export const GET_SUBMENU_FAILED = "GET_SUBMENU_FAILED";
export const SELECTED_SUBMENU = "SELECTED_SUBMENU";

// DELETED SUBMENU

export const DELETE_SUBMENU = "DELETE_SUBMENU";
export const DELETE_SUBMENU_SUCCESSFULL = "DELETE_SUBMENU_SUCCESSFULL";
export const DELETE_SUBMENU_FAILED = "DELETE_SUBMENU_FAILED";
export const VISIBLE_PRODUCT_SUBMENU_POPUP = "VISIBLE_PRODUCT_SUBMENU_POPUP";

//UPDATE SUBMENU
export const UPDATE_SUBMENU = "UPDATE_SUBMENU";
export const UPDATE_SUBMENU_SUCCESSFULL = "UPDATE_SUBMENU_SUCCESSFULL";
export const UPDATE_SUBMENU_FAILED = "UPDATE_SUBMENU_FAILED";

//SAVE PRODUCT OPTION EDIT

export const SEND_PRODUCT_OPTION_EDIT = "SEND_PRODUCT_OPTION_EDIT";
export const SEND_PRODUCT_OPTION_EDIT_SUCCESSFULL =
  "SEND_PRODUCT_OPTION_EDIT_SUCCESSFULL";
export const SEND_PRODUCT_OPTION_EDIT_FAILED =
  "SEND_PRODUCT_OPTION_EDIT_FAILED";

//GET ALL APP SETTING
export const GET_ALL_APP_SETTINGS = "GET_ALL_APP_SETTINGS";
export const GET_ALL_APP_SETTINGS_SUCCESSFULL =
  "GET_ALL_APP_SETTINGS_SUCCESSFULL";
export const GET_ALL_APP_SETTINGS_FAILED = "GET_ALL_APP_SETTINGS_FAILED";

//DELETE PRODUCT SUBMENU
export const DELETE_PRODUCT_SUBMENU = "DELETE_PRODUCT_SUBMENU";
export const DELETE_PRODUCT_SUBMENU_SUCCESSFULL =
  "DELETE_PRODUCT_SUBMENU_SUCCESSFULL";
export const DELETE_PRODUCT_SUBMENU_FAILED = "DELETE_PRODUCT_SUBMENU_FAILED";

//UPDATE PRODUCT SUBMENU EDIT
export const UPDATE_PRODUCT_SUBMENU_EDIT = "UPDATE_PRODUCT_SUBMENU_EDIT";
export const UPDATE_PRODUCT_SUBMENU_EDIT_SUCCESSFULL =
  "UPDATE_PRODUCT_SUBMENU_EDIT_SUCCESSFULL";
export const UPDATE_PRODUCT_SUBMENU_EDIT_FAILED =
  "UPDATE_PRODUCT_SUBMENU_EDIT_FAILED";

//CREATE SHOP
export const CREATE_SHOP = "CREATE_SHOP";
export const CREATE_SHOP_SUCCESSFULL = "CREATE_SHOP_SUCCESSFULL";
export const CREATE_SHOP_FAILED = "CREATE_SHOP_FAILED";
export const VISIBLE_SHOP_CREATE_POPUP = "VISIBLE_SHOP_CREATE_POPUP";
export const SET_DEFAULT_CREATE_SHOP_STATUS = "SET_DEFAULT_CREATE_SHOP_STATUS";

//GET SHOPS LIST
export const GET_SHOPS_LIST = "GET_SHOPS_LIST";
export const GET_SHOPS_LIST_SUCCESSFULL = "GET_SHOPS_LIST_SUCCESSFULL";
export const GET_SHOPS_LIST_FAILED = "GET_SHOPS_LIST_FAILED";

//CREATE SUBMENU ITEM

export const CREATE_SUBMENU_ITEM = "CRETE_SUBMENU_ITEM";
export const CREATE_SUBMENU_ITEM_SUCCESSFULL = "CRETE_SUBMENU_ITEM_SUCCESSFULL";
export const CREATE_SUBMENU_ITEM_FAILED = "CREATE_SUBMENU_ITEM_FAILED";

//GET SUBMENU ITEM

export const GET_SUBMENU_ITEM = "GET_SUBMENU_ITEM";
export const GET_SUBMENU_ITEM_SUCCESSFULL = "GET_SUBMENU_ITEM_SUCCESSFULL";
export const GET_SUBMENU_ITEM_FAILED = "GET_SUBMENU_ITEM_FAILED";

//SELECTED SUBMENU ITEM
export const SELECTED_SUBMENU_ITEM = "SELECTED_SUBMENU_ITEM";

//DELETE SUBMENU ITEM

export const DELETE_SUBMENU_ITEM = "DELETE_SUBMENU_ITEM";
export const DELETE_SUBMENU_ITEM_SUCCESSFULL =
  "DELETE_SUBMENU_ITEM_SUCCESSFULL";
export const DELETE_SUBMENU_ITEM_FAILED = "DELETE_SUBMENU_ITEM_FAILED";

//UPDATE SUBMENU ITEM EDIT
export const UPDATE_SUBMENU_ITEM_EDIT = "UPDATE_SUBMENU_ITEM_EDIT";
export const UPDATE_SUBMENU_ITEM_EDIT_SUCCESSFULL =
  "UPDATE_SUBMENU_ITEM_EDIT_SUCCESSFULL";
export const UPDATE_SUBMENU_ITEM_EDIT_FAILED = "UPDATE_SUBMENU_ITEM_EDIT_DATA";

//UPDATE APP SETTING ORDER PROCCESS

export const UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS =
  "UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS";
export const UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS_SUCCESSFULL =
  "UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS_SUCCESSFULL";
export const UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS_FAILED =
  "UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS_FAILED";

//UPDATE APP SETTING ORDER PROCCESS

export const UPDATE_SHOP_SETTING_APP_PAYMENT =
  "UPDATE_SHOP_SETTING_APP_PAYMENT";
export const UPDATE_SHOP_SETTING_APP_PAYMENT_SUCCESSFULL =
  "UPDATE_SHOP_SETTING_APP_PAYMENT_SUCCESSFULL";
export const UPDATE_SHOP_SETTING_APP_PAYMENT_FAILED =
  "UPDATE_SHOP_SETTING_APP_PAYMENT_FAILED";

//UPDATE SHOP SETTING APP COLOUR
export const UPDATE_SHOP_SETTING_APP_COLOUR = "UPDATE_SHOP_SETTING_APP_COLOUR";
export const UPDATE_SHOP_SETTING_APP_COLOUR_SUCCESSFULL =
  "UPDATE_SHOP_SETTING_APP_COLOUR_SUCCESSFULL";
export const UPDATE_SHOP_SETTING_APP_COLOUR_FAILED =
  "UPDATE_SHOP_SETTING_APP_COLOUR_FAILED";

//UPDATE SHOP SETTING APP DISPLAY
export const UPDATE_SHOP_SETTING_APP_DISPLAY =
  "UPDATE_SHOP_SETTING_APP_DISPLAY";
export const UPDATE_SHOP_SETTING_APP_DISPLAY_SUCCESSFULL =
  "UPDATE_SHOP_SETTING_APP_DISPLAY_SUCCESSFULL";
export const UPDATE_SHOP_SETTING_APP_DISPLAY_FAILED =
  "UPDATE_SHOP_SETTING_APP_DISPLAY_FAILED";

//UPDATE SHOP SETTING APP DISPLAY
export const UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT =
  "UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT";
export const UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT_SUCCESSFULL =
  "UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT_SUCCESSFULL";
export const UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT_FAILED =
  "UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT_FAILED";

//GET CATEGORY OPTION CRUST
export const GET_CATEGORY_OPTION_CRUST = "GET_CATEGORY_OPTION_CRUST";
export const GET_CATEGORY_OPTION_CRUST_SUCCESSFULL =
  "GET_CATEGORY_OPTION_CRUST_SUCCESSFULL";
export const GET_CATEGORY_OPTION_CRUST_FAILED =
  "GET_CATEGORY_OPTION_CRUST_FAILED";

//CREATE CATEGORY OPTION CRUST
export const CREATE_CATEGORY_OPTION_CRUST = "CREATE_CATEGORY_OPTION_CRUST";
export const CREATE_CATEGORY_OPTION_CRUST_SUCCESSFULL =
  "CREATE_CATEGORY_OPTION_CRUST_SUCCESSFULL";
export const CREATE_CATEGORY_OPTION_CRUST_FAILED =
  "CREATE_CATEGORY_OPTION_CRUST_FAILED";

//DELETE CATEGORY OPTION CRUST
export const DELETE_CATEGORY_OPTION_CRUST = "DELETE_CATEGORY_OPTION_CRUST";
export const DELETE_CATEGORY_OPTION_CRUST_SUCCESSFULL =
  "DELETE_CATEGORY_OPTION_CRUST_SUCCESSFULL";
export const DELETE_CATEGORY_OPTION_CRUST_FAILED =
  "DELETE_CATEGORY_OPTION_CRUST_FAILED";

//SELECTED CATEGORY OPTION CRUST
export const SELECTED_CATEGORY_OPTION_CRUST = "SELECTED_CATEGORY_OPTION_CRUST";
export const SELECTED_CATEGORY_OPTION_CRUST_SUCCESSFULL =
  "SELECTED_CATEGORY_OPTION_CRUST_SUCCESSFULL";
export const SELECTED_CATEGORY_OPTION_CRUST_FAILED =
  "SELECTED_CATEGORY_OPTION_CRUST_FAILED";

//UPDATE CATEGORY OPTION CRUST
export const UPDATE_CATEGORY_OPTION_CRUST = "UPDATE_CATEGORY_OPTION_CRUST";
export const UPDATE_CATEGORY_OPTION_CRUST_SUCCESSFULL =
  "UPDATE_CATEGORY_OPTION_CRUST_SUCCESSFULL";
export const UPDATE_CATEGORY_OPTION_CRUST_FAILED =
  "UPDATE_CATEGORY_OPTION_CRUST_FAILED";

//GET EMPLOYEES LIST
export const GET_SHOP_EMPLOYEES = "GET_SHOP_EMPLOYEES";
export const GET_SHOP_EMPLOYEES_SUCCESSFULL = "GET_SHOP_EMPLOYEES_SUCCESSFULL";
export const GET_SHOP_EMPLOYEES_FAILED = "GET_SHOP_EMPLOYEES_FAILED";

//CREATE EMPLOYEES LIST
export const CREATE_EMPLOYEES = "CREATE_EMPLOYEES";
export const CREATE_EMPLOYEES_SUCCESSFULL = "CREATE_EMPLOYEES_SUCCESSFULL";
export const CREATE_EMPLOYEES_FAILED = "CREATE_EMPLOYEES_FAILED";

//UPDATE SHOP EMPLOYEES EDIT
export const UPDATE_EMPLOYEES = "UPDATE_EMPLOYEES";
export const UPDATE_EMPLOYEES_SUCCESSFULL = "UPDATE_EMPLOYEES_SUCCESSFULL";
export const UPDATE_EMPLOYEES_FAILED = "UPDATE_EMPLOYEES_FAILED";

//SELECTED SHOP EMPLOYEES
export const SELECTED_SHOP_EMPLOYEES = "SELECTED_SHOP_EMPLOYEES";

//DELETE SHOP EMPLOYEES
export const DELETE_SHOP_EMPLOYEES = "DELETE_SHOP_EMPLOYEES";
export const DELETE_SHOP_EMPLOYEES_SUCCESSFULL =
  "DELETE_SHOP_EMPLOYEES_SUCCESSFULL";
export const DELETE_SHOP_EMPLOYEES_FAILED = "DELETE_SHOP_EMPLOYEES_FAILED";

//GET WORKS STATIONS
export const GET_WORKS_STATION_LIST = "GET_WORKS_STATION_LIST";
export const GET_WORKS_STATION_LIST_SUCCESSFULL =
  "GET_WORKS_STATION_LIST_SUCCESSFULL";
export const GET_WORKS_STATION_LIST_FAILED = "GET_WORKS_STATION_LIST_FAILED";

//CREATE WORKS STATIONS
export const CREATE_WORKS_STATION = "CREATE_WORKS_STATION";
export const CREATE_WORKS_STATION_SUCCESSFULL =
  "CREATE_WORKS_STATION_SUCCESSFULL";
export const CREATE_WORKS_STATION_FAILED = "CREATE_WORKS_STATION_FAILED";

//UPDATE WORKS STATIONS
export const SEND_WORK_STATION = "SEND_WORK_STATION";
export const SEND_WORK_STATION_SUCCESSFULL = "SEND_WORK_STATION_SUCCESSFULL";
export const SEND_WORK_STATION_FAILED = "SEND_WORK_STATION_FAILED";

//DELETE WORKS STATIONS
export const DELETE_WORKS_STATION = "DELETE_WORKS_STATION";
export const DELETE_WORKS_STATION_SUCCESSFULL =
  "DELETE_WORKS_STATION_SUCCESSFULL";
export const DELETE_WORKS_STATION_FAILED = "DELETE_WORKS_STATION_FAILED";
//Login
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESSFULL = "LOGIN_SUCCESSFULL";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SET_DEFAULT_LOGIN = "SET_DEFAULT_LOGIN";

//SELECTE WORKS STATIONS
export const SELECTE_WORKS_STATION = "SELECTE_WORKS_STATION";
export const TEST_SERVICE = "TEST_SERVICE";

//GET USERS
export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USERS_LIST_SUCCESSFUL = "GET_USERS_LIST_SUCCESSFUL";
export const GET_USERS_LIST_FAILED = "GET_USERS_LIST_SUCCESSFUL";

//CREATE USERS
export const CREATE_USERS = "CREATE_USERS";
export const CREATE_USERS_SUCCESSFULL = "CREATE_USERS_SUCCESSFULL";
export const CREATE_USERS_FAILED = "CREATE_USERS_FAILED";

//SELECTED USERS
export const SELECTED_USERS_EMPLOYEES = "SELECTED_USERS_EMPLOYEES";
export const SELECTED_USERS_OWNERS = "SELECTED_USERS_OWNERS";

//UAPDATE USERS
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USERS_SUCCESSFULL = "UPDATE_USERS_SUCCESSFULL";
export const UPDATE_USERS_FAILED = "UPDATE_USERS_FAILED";

//DELETE USERS
export const DELETE_USERS = "DELETE_USERS";
export const DELETE_USERS_SUCCESSFULL = "DELETE_USERS_SUCCESSFULL";
export const DELETE_USERS_FAILED = "DELETE_USERS_FAILED";

//GET EMPLOYEES LIST
export const GET_EMPLOYEES_LIST = "GET_EMPLOYEES_LIST";

//GET OWNERS LIST
export const GET_OWNERS_LIST = "GET_OWNERS_LIST";

//GET WORK STATION LIST ALL
export const GET_WORKSTATION_LIST_ALL = "GET_WORKSTATION_LIST_ALL";
export const GET_WORKSTATION_LIST_ALL_SUCCESSFULL =
  "GET_WORKSTATION_LIST_ALL_SUCCESSFULL";
export const GET_WORKSTATION_LIST_ALL_FAILED =
  "GET_WORKSTATION_LIST_ALL_FAILED";

//GET LICENCE WORK STATION LIST
export const GET_LICENCE_WORK_STATION_LIST = "GET_LICENCE_WORK_STATION_LIST";
export const GET_LICENCE_WORK_STATION_LIST_SUCCESSFULL =
  "GET_LICENCE_WORK_STATION_LIST_SUCCESSFULL";
export const GET_LICENCE_WORK_STATION_LIST_FAILED =
  "GET_LICENCE_WORK_STATION_LIST_FAILED";

//CREATE LICENCE WORK STATION
export const CREATE_LICENCE = "CREATE_LICENCE";
export const CREATE_LICENCE_SUCCESSFULL = "CREATE_LICENCE_SUCCESSFULL";
export const CREATE_LICENCE_FAILED = "CREATE_LICENCE_FAILED";
export const VISIBLE_CRETE_LICENCE_POPUP = "VISIBLE_CRETE_LICENCE_POPUP";

//GET TABEL AREA
export const GET_TABEL_AREA = "GET_TABEL_AREA";
export const GET_TABEL_AREA_SUCCESSFULL = "GET_TABEL_AREA_SUCCESSFULL";
export const GET_TABEL_AREA_FAILED = "GET_TABEL_AREA_FAILED";

//CREATE TABEL AREA
export const CREATE_AREA = "CREATE_AREA";
export const CREATE_AREA_SUCCESSFULL = "CREATE_AREA_SUCCESSFULL";
export const CREATE_AREA_FAILED = "CREATE_AREA_FAILED";

export const SHOW_ADD_TABLE_POPUP = "SHOW_ADD_TABLE_POPUP=";

//CREATE TABEL AREA
export const CREATE_TABEL = "CREATE_TABEL";
export const CREATE_TABEL_SUCCESSFULL = "CREATE_TABEL_SUCCESSFULL";
export const CREATE_TABEL_FAILED = "CREATE_TABEL_FAILED";
//UPDATE AREA TABEL
export const UPDATE_AREA = "UPDATE_AREA";
export const UPDATE_AREA_SUCCESSFULL = "UPDATE_AREA_SUCCESSFULL";
export const UPDATE_AREA_FAILED = "UPDATE_AREA_FAILED";

//UPDATE TABEL
export const UPDATE_TABEL = "UPDATE_TABEL";
export const UPDATE_TABEL_SUCCESSFULL = "UPDATE_TABEL_SUCCESSFULL";
export const UPDATE_TABEL_FAILED = "UPDATE_TABEL_FAILED";
//SELECTED AREA
export const SELECTTED_AREA = "SELECTTED_AREA";

//SELECTED TABEL
export const SELECTED_TABEL = "SELECTED_TABEL";
export const SELECTTED_AREA_EMPTY = "SELECTTED_AREA_EMPTY";

// GET LICENCE LIST BY WORKSTATION ID
export const GET_LICENCE_LIST_BY_WORKSTATION_ID =
  "GET_LICENCE_LIST_BY_WORKSTATION_ID";
export const GET_LICENCE_LIST_BY_WORKSTATION_ID_SUCCESSFULL =
  "GET_LICENCE_LIST_BY_WORKSTATION_ID_SUCCESSFULL";
export const GET_LICENCE_LIST_BY_WORKSTATION_ID_FAILED =
  "GET_LICENCE_LIST_BY_WORKSTATION_ID_FAILED";

//SELECTED LICENCE LIST
export const SELECTD_LICENCE_LIST = "SELECTD_LICENCE_LIST";

//VISIBLE POST CODE POPUP
export const VISIBLE_POST_CODE_POPUP = "VISIBLE_POST_CODE_POPUP";

//ALLOWED POST CODE LIST
export const ALLOWE_POST_CODE_LIST = "ALLOWE_POST_CODE_LIST";
export const ALLOWE_POST_CODE_LIST_SUCCESSFULL =
  "ALLOWE_POST_CODE_LIST_SUCCESSFULL";
export const ALLOWE_POST_CODE_LIST_FAILED = "ALLOWE_POST_CODE_LIST_FAILED";

//CREATE ALLOWED POST CODE
export const CREATE_ALLOWED_POST_CODE = "CREATE_ALLOWED_POST_CODE";
export const CREATE_ALLOWED_POST_CODE_SUCCESSFULL =
  "CREATE_ALLOWED_POST_CODE_SUCCESSFULL";
export const CREATE_ALLOWED_POST_CODE_FAILED =
  "CREATE_ALLOWED_POST_CODE_FAILED";

//UPDATE ALLOWED POST CODE
export const UPDATE_ALLOWED_POST_CODE = "UPDATE_ALLOWED_POST_CODE";
export const UPDATE_ALLOWED_POST_CODE_SUCCESSFULL =
  "UPDATE_ALLOWED_POST_CODE_SUCCESSFULL";
export const UPDATE_ALLOWED_POST_CODE_FAILED =
  "UPDATE_ALLOWED_POST_CODE_FAILED";

//DELETE ALLOWED POST CODE
export const DELETE_ALLOWED_POST_CODE = "DELETE_ALLOWED_POST_CODE";
export const DELETE_ALLOWED_POST_CODE_SUCCESSFULL =
  "DELETE_ALLOWED_POST_CODE_SUCCESSFULL";
export const DELETE_ALLOWED_POST_CODE_FAILED =
  "DELETE_ALLOWED_POST_CODE_FAILED";

//SELECTED ALLOWED POST CODE
export const SELECTED_ALLOWED_POST_CODE = "SELECTED_ALLOWED_POST_CODE";

//VAT LIST
export const VAT_LIST = "VAT_LIST";
export const VAT_LIST_SUCCESSFULL = "VAT_LIST_SUCCESSFULL";
export const VAT_LIST_FAILED = "VAT_LIST_FAILED";

//CREATE VAT
export const CREATE_VAT = "CREATE_VAT";
export const CREATE_VAT_SUCCESSFULL = "CREATE_VAT_SUCCESSFULL";
export const CREATE_VAT_FAILED = "CREATE_VAT_FAILED";

//UPDATE VAT
export const UPDATE_VAT = "UPDATE_VAT";
export const UPDATE_VAT_SUCCESSFULL = "UPDATE_VAT_SUCCESSFULL";
export const UPDATE_VAT_FAILED = "UPDATE_VAT_FAILED";

//UPDATE VAT
export const DELETE_VAT = "DELETE_VAT";
export const DELETE_VAT_SUCCESSFULL = "DELETE_VAT_SUCCESSFULL";
export const DELETE_VAT_FAILED = "DELETE_VAT_FAILED";

//VISIBLE VAT POPUP
export const VISIBLE_VAT_POPUP = "VISIBLE_VAT_POPUP";

//SELECTED VAT
export const SELECTED_VAT = "SELECTED_VAT";

//GET HALF AND HALF
export const GET_HALF_AND_HALF = "GET_HALF_AND_HALF";
export const GET_HALF_AND_HALF_SUCCESSFULL = "GET_HALF_AND_HALF_SUCCESSFULL";
export const GET_HALF_AND_HALF_FAILED = "GET_HALF_AND_HALF_FAILED";

//UPDATE HALF AND HALF
export const UPDATE_HALF_AND_HALF = "UPDATE_HALF_AND_HALF";
export const UPDATE_HALF_AND_HALF_SUCCESSFULL =
  "UPDATE_HALF_AND_HALF_SUCCESSFULL";
export const UPDATE_HALF_AND_HALF_FAILED = "UPDATE_HALF_AND_HALF_FAILED";

// VISIBLE DRIVERS POPUP
export const VISIBLE_DRIVERS_POPUP = "VISIBLE_DRIVERS_POPUP";

//GET DRIVERS LIST
export const GET_DRIVERS_LIST = "GET_DRIVERS_LIST";
export const GET_DRIVERS_LIST_SUCCESSFULL = "GET_DRIVERS_LIST_SUCCESSFULL";
export const GET_DRIVERS_LIST_FAILED = "GET_DRIVERS_LIST_FAILED";

//CREATE DRIVER
export const CREATE_DRIVER = "CREATE_DRIVER";
export const CREATE_DRIVER_SUCCESSFULL = "CREATE_DRIVER_SUCCESSFULL";
export const CREATE_DRIVER_FAILED = "CREATE_DRIVER_FAILED";

//UPDATE DRIVER
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const UPDATE_DRIVER_SUCCESSFULL = "UPDATE_DRIVER_SUCCESSFULL";
export const UPDATE_DRIVER_FAILED = "UPDATE_DRIVER_FAILED";

//DELETE DERIVER
export const DELETE_DRIVER = "DELETE_DRIVER";
export const DELETE_DRIVER_SUCCESSFULL = "DELETE_DRIVER_SUCCESSFULL";
export const DELETE_DRIVER_FAILED = "DELETE_DRIVER_FAILED";

//SELECTED DRIVER
export const SELECTED_DRIVER = "SELECTED_DRIVER";

//DRIVER WITH UNIQUE ID
export const DRIVER_WITH_UNIQUE_ID = "DRIVER_WITH_UNIQUE_ID";
export const DRIVER_WITH_UNIQUE_ID_SUCCESSFULL =
  "DRIVER_WITH_UNIQUE_ID_SUCCESSFULL";
export const DRIVER_WITH_UNIQUE_ID_FAILED = "DRIVER_WITH_UNIQUE_ID_FAILED";

//DRIVER SHOP LIST
export const DRIVER_SHOP_LIST = "DRIVER_SHOP_LIST";
export const DRIVER_SHOP_LIST_SUCCESSFULL = "DRIVER_SHOP_LIST_SUCCESSFULL";
export const DRIVER_SHOP_LIST_FAILED = "DRIVER_SHOP_LIST_FAILED";

//CREATE DRIVER SHOP
export const CREATE_DRIVER_SHOP = "CREATE_DRIVER_SHOP";
export const CREATE_DRIVER_SHOP_SUCCESSFULL = "CREATE_DRIVER_SHOP_SUCCESSFULL";
export const CREATE_DRIVER_SHOP_FAILED = "CREATE_DRIVER_SHOP_FAILED";
//DELETE DRIVER SHOP
export const DELETE_DRIVER_SHOP = "DELETE_DRIVER_SHOP";
export const DELETE_DRIVER_SHOP_SUCCESSFULL = "DELETE_DRIVER_SHOP_SUCCESSFULL";
export const DELETE_DRIVER_SHOP_FAILED = "DELETE_DRIVER_SHOP_FAILED";

//GET DELIVEY SHARGE TYPE
export const GET_DELIVERY_CHARGE_TYPE = "GET_DELIVERY_CHARGE_TYPE";
export const GET_DELIVERY_CHARGE_TYPE_SUCCESSFULL =
  "GET_DELIVERY_CHARGE_TYPE_SUCCESSFULL";
export const GET_DELIVERY_CHARGE_TYPE_FAILED =
  "GET_DELIVERY_CHARGE_TYPE_FAILED";

//UPDATE DELIVERY CHARGE
export const UPDATE_DELIVERY_CHARGE = "UPDATE_DELIVERY_CHARGE";
export const UPDATE_DELIVERY_CHARGE_SUCCESSFULL =
  "UPDATE_DELIVERY_CHARGE_SUCCESSFULL";
export const UPDATE_DELIVERY_CHARGE_FAILED = "UPDATE_DELIVERY_CHARGE_FAILED";

//CREATE DELIVERY CHARGE
export const CRETAE_DELIVERY_CHARGE = "CRETAE_DELIVERY_CHARGE";
export const CRETAE_DELIVERY_CHARGE_SUCCESSFULL =
  "CRETAE_DELIVERY_CHARGE_SUCCESSFULL";
export const CRETAE_DELIVERY_CHARGE_FAILED = "CRETAE_DELIVERY_CHARGE_FAILED";

//DELETE DELIVERY CHARGE
export const DELETE_DELIVERY_CHARGE = "DELETE_DELIVERY_CHARGE";
export const DELETE_DELIVERY_CHARGE_SUCCESSFULL =
  "DELETE_DELIVERY_CHARGE_SUCCESSFULL";
export const DELETE_DELIVERY_CHARGE_FAILED = "DELETE_DELIVERY_CHARGE_FAILED";

//SELECTED APP SETTINGS TYPE
export const SELECTED_DELIVERY_CHARGE_TYPE = "SELECTED_DELIVERY_CHARGE_TYPE";

//VISIBLE CUSTOM CHARGE
export const VISIBLE_CUTOM_CHARGE = "VISIBLE_CUTOM_CHARGE";

//GET DELIVERY SETTING
export const GET_DELIVERY_SETTING = "GET_DELIVERY_SETTING";
export const GET_DELIVERY_SETTING_SUCCESSFULL =
  "GET_DELIVERY_SETTING_SUCCESSFULL";
export const GET_DELIVERY_SETTING_FAILED = "GET_DELIVERY_SETTING_FAILED";

//UPDATE DELIVERY SETTING
export const UPDATE_DELIVERY_SETTING = "UPDATE_DELIVERY_SETTING";
export const UPDATE_DELIVERY_SETTING_SUCCESFFULL =
  "UPDATE_DELIVERY_SETTING_SUCCESFFULL";
export const UPDATE_DELIVERY_SETTING_FAILED = "UPDATE_DELIVERY_SETTING_FAILED";

//CODE DETAIL LIST
export const CODE_DETAIL_LIST = "CODE_DETAIL_LIST";
export const CODE_DETAIL_LIST_SUCCESSFULL = "CODE_DETAIL_LIST_SUCCESSFULL";
export const CODE_DETAIL_LIST_FAILED = "CODE_DETAIL_LIST_FAILED";

//CREATE CODE DETAIL
export const CREATE_CODE_DETAIL = "CREATE_CODE_DETAIL";
export const CREATE_CODE_DETAIL_SUCCESSFULL = "CREATE_CODE_DETAIL_SUCCESSFULL";
export const CREATE_CODE_DETAIL_FAILED = "CREATE_CODE_DETAIL_FAILED";

//UPDATE CODE DETAIL
export const UPDATE_CODE_DETAIL = "UPDATE_CODE_DETAIL";
export const UPDATE_CODE_DETAIL_SUCCESSFULL = "UPDATE_CODE_DETAIL_SUCCESSFULL";
export const UPDATE_CODE_DETAIL_FAILED = "UPDATE_CODE_DETAIL_FAILED";

//DELETE CODE DETAIL
export const DELETE_CODE_DETAIL = "DELETE_CODE_DETAIL";
export const DELETE_CODE_DETAIL_SUCCESSFULL = "DELETE_CODE_DETAIL_SUCCESSFULL";
export const DELETE_CODE_DETAIL_FAILED = "DELETE_CODE_DETAIL_FAILED";

//VISIBLE CODE DETAIL
export const VISIBLE_CODE_DETAIL = "VISIBLE_CODE_DETAIL";

//SELECTED CODE DETAIL
export const SELECTED_CODE_DETEIL = "SELECTED_CODE_DETEIL";

//CHANGED CODE DETAIL LIST
export const CHANGED_CODE_DETAIL_LIST = "CHANGED_CODE_DETAIL_LIST";

// SELECTED GROUP ID
export const SELECTED_GROUP_ID = "SELECTED_GROUP_ID";

//GET SHOP DETAIL
export const GET_SHOP_DETAIL = "GET_SHOP_DETAIL";
export const GET_SHOP_DETAIL_SUCCESSFULL = "GET_SHOP_DETAIL_SUCCESSFULL";
export const GET_SHOP_DETAIL_FAILED = "GET_SHOP_DETAIL_FAILED";

//UPDATE SHOP
export const UPDATE_SHOP = "UPDATE_SHOP";
export const UPDATE_SHOP_SUCCESSFULL = "UPDATE_SHOP_SUCCESSFULL";
export const UPDATE_SHOP_FAILED = "UPDATE_SHOP_FAILED";

//VISIBLE SHOP GROUP
export const VISIBLE_SHOP_GROUP_POPUP = "VISIBLE_SHOP_GROUP_POPUP";

//SHOP GROUP LIST
export const SHOP_GROUP_LIST = "SHOP_GROUP_LIST";
export const SHOP_GROUP_LIST_SUCCESSFULL = "SHOP_GROUP_LIST_SUCCESSFULL";
export const SHOP_GROUP_LIST_FAILED = "SHOP_GROUP_LIST_FAILED";

//CREATE SHOP GROUP
export const CREATE_SHOP_GROUP = "CREATE_SHOP_GROUP";
export const CREATE_SHOP_GROUP_SUCCESSFULL = "CREATE_SHOP_GROUP_SUCCESSFULL";
export const CREATE_SHOP_GROUP_FAILED = "CREATE_SHOP_GROUP_FAILED";

//DELETE SHOP GROUP
export const DELETE_SHOP_GROUP = "DELETE_SHOP_GROUP";
export const DELETE_SHOP_GROUP_SUCCESSFULL = "DELETE_SHOP_GROUP_SUCCESSFULL";
export const DELETE_SHOP_GROUP_FAILED = "DELETE_SHOP_GROUP_FAILED";

//UPDATE SHOP GROUP
export const UPDATE_SHOP_GROUP = "UPDATE_SHOP_GROUP";
export const UPDATE_SHOP_GROUP_SUCCESSFULL = "UPDATE_SHOP_GROUP_SUCCESSFULL";
export const UPDATE_SHOP_GROUP_FAILED = "UPDATE_SHOP_GROUP_FAILED";

//SELECTED SHOP GROUP
export const SELECTED_SHOP_GROUP = "SELECTED_SHOP_GROUP";

//SHOP LIST BY GROUP ID
export const SHOP_LIST_BY_GROUP_ID = "SHOP_LIST_BY_GROUP_ID";
export const SHOP_LIST_BY_GROUP_ID_SUCCESSFULL =
  "SHOP_LIST_BY_GROUP_ID_SUCCESSFULL";
export const SHOP_LIST_BY_GROUP_ID_FAILED = "SHOP_LIST_BY_GROUP_ID_FAILED";

//SELECTED SHOP_IDS
export const SELECTED_SHOP_IDS = "SELECTE_SHOP_IDS";

//NEW SHOP LIST IN OWNERS
export const NEW_SHOP_LIST = "NEW_SHOP_LIST";

export const HANDLE_ENTER_KEY = "HANDLE_ENTER_KEY";

//UPDATE PROFILE
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESSFULL = "UPDATE_PROFILE_SUCCESSFULL";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

//SEND LOGIN PARAMETERS TO PROFILE
export const SEND_LOGIN_PARAMETERS_TO_PROFILE =
  "SEND_LOGIN_PARAMETERS_TO_PROFILE";

//SET_DEFAULT_PROFILE
export const SET_DEFAULT_PROFILE = "SET_DEFAULT_PROFILES";

//RECEIVE LOGIN RESPONSE
export const RECEIVE_LOGIN_RESPONSE = "RECEIVE_LOGIN_RESPONSE";

// //SEND IMAGE FORMAT
// export const SEND_IMAGE_FORMAT = "SEND_IMAGE_FORMAT";

// //SHOW FROFILE IMAGE
// export const SHOW_PROFILE_IMAGE="SHOW_PROFILE_IMAGE"

//ORDERS
export const ORDERS_LIST = "ORDERS_LIST";
export const ORDERS_LIST_SUCCESSFULL = "ORDERS_LIST_SUCCESSFULL";
export const ORDERS_LIST_FAILED = "ORDERS_LIST_FAILED";
export const SELECTED_ORDER_LIST = "SELECTED_ORDER_LIST";
//ORDER LIST POPUP
export const SHOW_ORDER_LIST_POPUP = "SHOW_ORDER_LIST_POPUP";

//GET ORDER DETAIL
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";
export const GET_ORDER_DETAIL_SUCCESSFULL = "GET_ORDER_DETAIL_SUCCESSFULL";
export const GET_ORDER_DETAIL_FAILED = "GET_ORDER_DETAIL_FAILED";

//WEB SETTINGS
export const GET_WEB_SETTING = "GET_WEB_SETTING";
export const GET_WEB_SETTING_SUCCESSFULL = "GET_WEB_SETTING_SUCCESSFULL";
export const GET_WEB_SETTING_FAILED = "GET_WEB_SETTING_FAILED";

//UPDATE WEB SETTING
export const UPDATE_WEB_SETTING = "UPDATE_WEB_SETTING";
export const UPDATE_WEB_SETTING_SUCCESFULL = "UPDATE_WEB_SETTING_SUCCESFFULL";
export const UPDATE_WEB_SETTING_FAILED = "UPDATE_WEB_SETTING_FAILED";

//DEVICE ACCESS GROUP LIST
export const DEVICE_ACCESS_GROUP_LIST = "DEVICE_ACCESS_GROUP_LIST";
export const DEVICE_ACCESS_GROUP_LIST_SUCCESSFULL =
  "DEVICE_ACCESS_GROUP_LIST_SUCCESSFULL ";
export const DEVICE_ACCESS_GROUP_LIST_FAILED =
  "DEVICE_ACCESS_GROUP_LIST_FAILED";
export const SELECTED_ACCESS_GROUP = "SELECTED_ACCESS_GROUP";
export const DEVICE_ROLE_LIST = "DEVICE_ROLE_LIST";

//CREATE DEVICE ACCESS GROUP
export const CREATE_DEVICE_ACCESS_GROUP = "CREATE_DEVICE_ACCESS_GROUP";
export const CREATE_DEVICE_ACCESS_GROUP_SUCCESSFULL =
  "CREATE_DEVICE_ACCESS_GROUP_SUCCESSFULL";
export const CREATE_DEVICE_ACCESS_GROUP_FAILED =
  "CREATE_DEVICE_ACCESS_GROUP_FAILED";

//UPDATE DEVICE ACCESS GROUP
export const UPDATE_DEVICE_ACCESS_GROUP = "UPDATE_DEVICE_ACCESS_GROUP";
export const UPDATE_DEVICE_ACCESS_GROUP_SUCCESSFULL =
  "UPDATE_DEVICE_ACCESS_GROUP_SUCCESSFULL";
export const UPDATE_DEVICE_ACCESS_GROUP_FAILED =
  "CUPDATE_DEVICE_ACCESS_GROUP_FAILED";

//DELETE DEVICE ACCESS GROUP
export const DELETE_DEVICE_ACCESS_GROUP = "DELETE_DEVICE_ACCESS_GROUP";
export const DELETE_DEVICE_ACCESS_GROUP_SUCCESSFULL =
  "DELETE_DEVICE_ACCESS_GROUP_SUCCESSFULL";
export const DELETE_DEVICE_ACCESS_GROUP_FAILED =
  "DELETE_DEVICE_ACCESS_GROUP_FAILED";

//ORDER LIST APP SHOPS
export const GET_ORDER_LIST_APP_SHOPS = "GET_ORDER_LIST_APP_SHOPS";

export const GET_ORDER_LIST_APP_SHOPS_PENDING_SUCCESSFULL =
  "GET_ORDER_LIST_APP_SHOPS_PENDING_SUCCESSFULL ";
export const GET_ORDER_LIST_APP_SHOPS_FAILED_SUCCESSFULL =
  "GET_ORDER_LIST_APP_SHOPS_FAILED_SUCCESSFULL ";
export const GET_ORDER_LIST_APP_SHOPS_INCOMPLETE_SUCCESSFULL =
  "GET_ORDER_LIST_APP_SHOPS_INCOMPLETE_SUCCESSFULL ";
export const GET_ORDER_LIST_APP_SHOPS_NOTPRINTED_SUCCESSFULL =
  "GET_ORDER_LIST_APP_SHOPS_NOTPRINTED_SUCCESSFULL ";
export const GET_ORDER_LIST_APP_SHOPS_REJECTED_SUCCESSFULL =
  "GET_ORDER_LIST_APP_SHOPS_REJECTED_SUCCESSFULL ";
export const GET_ORDER_LIST_APP_SHOPS_FAILED =
  "GET_ORDER_LIST_APP_SHOPS_FAILED";

//GET SHOP STATUS LIST
export const GET_SHOP_STATUS_LIST = "GET_SHOP_STATUS_LIST";
export const GET_SHOP_STATUS_LIST_SUCCESSFULL =
  "GET_SHOP_STATUS_LIST_SUCCESSFULL ";
export const GET_SHOP_STATUS_LIST_FAILED = "GET_SHOP_STATUS_LIST_FAILED";

// GET OPEN CLOSE TIME LIST
export const GET_OPEN_CLOSE_TIME_LIST = "GET_OPEN_CLOSE_TIME_LIST";
export const GET_OPEN_CLOSE_TIME_LIST_SUCCESSFULL =
  "GET_OPEN_CLOSE_TIME_LIST_SUCCESSFULL";
export const GET_OPEN_CLOSE_TIME_LIST_FAILED =
  "GET_OPEN_CLOSE_TIME_LIST_FAILED";
// export const OPEN_CLOSE_LIST2 = "OPEN_CLOSE_LIST2";

//UPDATE OPEN CLOSE TIME LIST
export const UPDATE_OPEN_CLOSE_TIME = "UPDATE_OPEN_CLOSE_TIME";
export const UPDATE_OPEN_CLOSE_TIME_SUCCESSFULL =
  "UPDATE_OPEN_CLOSE_TIME_SUCCESSFULL";
export const UPDATE_OPEN_CLOSE_TIME_FAILED = "UPDATE_OPEN_CLOSE_TIME_FAILED";

//CREATE OPEN CLOSE TIME LIST
export const CREATE_OPEN_CLOSE_TIME = "CREATE_OPEN_CLOSE_TIME";
export const CREATE_OPEN_CLOSE_TIME_SUCCESSFULL =
  "CREATE_OPEN_CLOSE_TIME_SUCCESSFULL";
export const CREATE_OPEN_CLOSE_TIME_FAILED = "CREATE_OPEN_CLOSE_TIME_FAILED";

//GET PAYMENT SETTING
export const GET_WEB_PAYMENT_SETTING_LIST = "GET_WEB_PAYMENT_SETTING_LIST";
export const GET_WEB_PAYMENT_SETTING_LIST_SUCCESSFULL =
  "GET_WEB_PAYMENT_SETTING_LIST_SUCCESSFULL";
export const GET_WEB_PAYMENT_SETTING_LIST_FAILED =
  "GET_WEB_PAYMENT_SETTING_LIST_FAILED";

//UPDATE PAYMENT SETTING
export const UPDATE_WEB_PAYMENT_SETTING = "UPDATE_WEB_PAYMENT_SETTING";
export const UPDATE_WEB_PAYMENT_SETTING_SUCCESSFULL =
  "UPDATE_WEB_PAYMENT_SETTING_SUCCESSFULL";
export const UPDATE_WEB_PAYMENT_SETTING_FAILED =
  "UPDATE_WEB_PAYMENT_SETTING_FAILED";

//GENERAL SETTING GET CARRIER BAG
export const GENERAL_SETTING_GET_CARRIER_BAG_LIST =
  "GENERAL_SETTING_GET_CARRIER_BAG_LIST";
export const GENERAL_SETTING_GET_CARRIER_BAG_LIST_SUCCESSFULL =
  "GENERAL_SETTING_GET_CARRIER_BAG_LIST_SUCCESSFULL";
export const GENERAL_SETTING_GET_CARRIER_BAG_LIST_FAILED =
  "GENERAL_SETTING_GET_CARRIER_BAG_LIST_FAILED";

//GENERAL SETTING UPDATE CARIIER BAG
export const GENERAL_SETTING_UPDATE_CARRIER_BAG =
  "GENERAL_SETTING_UPDATE_CARRIER_BAG";
export const GENERAL_SETTING_UPDATE_CARRIER_BAG_SUCCESSFULL =
  "GENERAL_SETTING_UPDATE_CARRIER_BAG_SUCCESSFULL";
export const GENERAL_SETTING_UPDATE_CARRIER_BAG_FAILED =
  "GENERAL_SETTING_UPDATE_CARRIER_BAG_FAILED";

//WEB SETTING GET STTRIPE LIST
export const GET_STRIPE_LIST = "GET_STRIPE_LIST";
export const GET_STRIPE_LIST_SUCCESSFULL = "GET_STRIPE_LIST_SUCCESSFULL";
export const GET_STRIPE_LIST_FAILED = "GET_STRIPE_LIST_FAILED";

// UPDATE SWEB SETTING STRIPE
export const UPDATE_STRIPE = "UPDATE_STRIPE";
export const UPDATE_STRIPE_SUCCESSFULL = "UPDATE_STRIPE_SUCCESSFULL";
export const UPDATE_STRIPE_FAILED = "UPDATE_STRIPE_FAILED";

export const SHOW_STRIPE_POPUP = "SHOW_STRIPE_POPUP";
//CHANGE SELECTED BUTTON BORDER COLOR ON FOCUS
export const CHANGE_SELECTED_BUTTON_BORDER_COLOR_ON_FOCUS =
  "CHANGE_SELECTED_BUTTON_BORDER_COLOR_ON_FOCUS";

export const CHANGE_SELECTED_ITEM_COLOR_ON_FOCUS =
  "CHANGE_SELECTED_ITEM_COLOR_ON_FOCUS";
//CLICKED ITEM
export const CLICKED_ITEM = "CLICKED_ITEM";

//GET SHOP SETTINH TIME GSP
export const GET_SHOP_SETTING_TIME_GAP_LIST = "GET_SHOP_SETTING_TIME_GAP_LIST";
export const GET_SHOP_SETTING_TIME_GAP_LIST_SUCCESSFULL =
  "GET_SHOP_SETTING_TIME_GAP_LIST_SUCCESSFULL ";
export const GET_SHOP_SETTING_TIME_GAP_LIST_FAILED =
  "GET_SHOP_SETTING_TIME_GAP_LIST_FAILED";

// UPDATE SHOP SETTING TIME GAP
export const UPDATE_SHOP_SETTING_TIME_GAP = "UPDATE_SHOP_SETTING_TIME_GAP";
export const UPDATE_SHOP_SETTING_TIME_GAP_SUCCESSFULL =
  "UPDATE_SHOP_SETTING_TIME_GAP_SUCCESSFULL";
export const UPDATE_SHOP_SETTING_TIME_GAP_FAILED =
  "UPDATE_SHOP_SETTING_TIME_GAP_FAILED";

//EDIT BOX
export const SELECTED_ITEM_IN_ALL_LISTS = "SELECTED_ITEM_IN_ALL_LISTS";
export const EDIT_BOX_PARAMETERS = "EDIT_BOX_PARAMETERS";
export const SELECTED_ITEM_IN_SOME_LISTS = "SELECTED_ITEM_IN_SOME_LISTS";

//JUST TITLE AND
export const JUST_TITLE_AND_PRICE = "JUST_TITLE_AND_PRICE";

//SEND ALL ITEM TO APP
export const SEND_ALL_ITEMS_TO_APP = "SEND_ALL_ITEMS_TO_APP";

//WEB DELIVERY
export const GET_WEB_DELIVERY_LIST = "GET_WEB_DELIVERY_LIST";
export const GET_WEB_DELIVERY_LIST_SUCCESSFULL =
  "GET_WEB_DELIVERY_LIST_SUCCESSFULL";
export const GET_WEB_DELIVERY_LIST_FAILED = "GET_WEB_DELIVERY_LIST_FAILED";

//UPDATE WEB DELIVERY
export const UPDATE_WEB_DELIVERY = "UPDATE_WEB_DELIVERY";
export const UPDATE_WEB_DELIVERY_SUCCESSFULL =
  "UPDATE_WEB_DELIVERY_SUCCESSFULL";
export const UPDATE_WEB_DELIVERY_FAILED = "UPDATE_WEB_DELIVERY_FAILED ";

//GET MAINTENANCE LIST
export const GET_MAINTENANCE_LIST = "GET_MAINTENANCE_LIST";
export const GET_MAINTENANCE_LIST_SUCCESSFULL =
  "GET_MAINTENANCE_LIST_SUCCESSFULL";
export const GET_MAINTENANCE_LIST_FAILED = "GET_MAINTENANCE_LIST_FAILED";
export const MAINTENACE_MESSAGE_LIST = "MAINETNACE_MESSAGE_LIST";

//UPDATE MAINTENANCE MODE
export const UPDATE_MAINTENANCE = "UPDATE_MAINTENANCE";
export const UPDATE_MAINTENANCE_SUCCESSFULL = "UPDATE_MAINTENANCE_SUCCESSFULL";
export const UPDATE_MAINTENANCE_FAILED = "UPDATE_MAINTENANCE_FAILED";

//NEW CATEGORY ID
export const NEW_CATEGORY_ID = "NEW_CATEGORY_ID";

// GET CUSTOMERS LIST
export const GET_CUSTOMERS_LIST = "GET_CUSTOMERS_LIST";
export const GET_CUSTOMERS_LIST_SUCCESSFULL = "GET_CUSTOMERS_LIST_SUCCESSFULL";
export const GET_CUSTOMERS_LIST_FAILED = "GET_CUSTOMERS_LIST_FAILED";

// GET CUSTOMERS EXPORT LIST
export const GET_CUSTOMERS_EXPORT_LIST = "GET_CUSTOMERS_EXPORT_LIST";
export const GET_CUSTOMERS_EXPORT_LIST_SUCCESSFULL =
  "GET_CUSTOMERS_EXPORT_LIST_SUCCESSFULL";
export const GET_CUSTOMERS_EXPORT_LIST_FAILED =
  "GET_CUSTOMERS_EXPORT_LIST_FAILED";

//EDIT CUSTOMER
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESSFULL = "EDIT_CUSTOMER_SUCCESSFULL";
export const EDIT_CUSTOMER_FAILED = "EDIT_CUSTOMER_FAILED";

//DELETE CUSTOMER
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESSFULL = "DELETE_CUSTOMER_SUCCESSFULL";
export const DELETE_CUSTOMER_FAILED = "DELETE_CUSTOMER_FAILED";

//SHOD DELETE CUSTOMER POPUP
export const SHOW_DELETE_CUTOMER_POPUP = "SHOW_DELETE_CUTOMER_POPUP";

//DELETING CUSTOMER INFO
export const DELETING_CUSTOMER_INFO = "DELETING_CUSTOMER_INFO";

//NEW SHOP LIST
export const GET_NEW_SHOP_LIST = "GET_NEW_SHOP_LIST";

// GET PAYMENT LINK
export const GET_PAYMENT_LINK = "GET_PAYMENT_LINK";
export const GET_PAYMENT_LINK_SUCCESSFULL = "GET_PAYMENT_LINK_SUCCESSFULL";
export const GET_PAYMENT_LINK_FAILED = "GET_PAYMENT_LINK_FAILED";

//UPDATE PAYMENT LINK SMS
export const UPDATE_PAYMENT_LINK = "UPDATE_PAYMENT_LINK";
export const UPDATE_PAYMENT_LINK_SUCCESSFULL =
  "UPDATE_PAYMENT_LINK_SUCCESSFULL";
export const UPDATE_PAYMENT_LINK_FAILED = "UPDATE_PAYMENT_LINK_FAILED";

//TAB STATUS
export const TAB_STATUS = "TAB_STATUS";

//EMAIL NOTIFICATION
export const GET_NOTIFICATION_EMAIL_LIST = "GET_NOTIFICATION_EMAIL_LIST";
export const GET_NOTIFICATION_EMAIL_LIST_SUCCESSFULL =
  "GET_NOTIFICATION_EMAIL_LIST_SUCCESSFULL";
export const GET_NOTIFICATION_EMAIL_LIST_FAILED =
  "GET_NOTIFICATION_EMAIL_LIST_FAILED";

export const CREATE_NOTIFICATION_EMAIL = "CREATE_NOTIFICATION_EMAIL";
export const CREATE_NOTIFICATION_EMAIL_SUCCESSFULL =
  "CREATE_NOTIFICATION_EMAIL_SUCCESSFULL";
export const CREATE_NOTIFICATION_EMAIL_FAILED =
  "CREATE_NOTIFICATION_EMAIL_FAILED";

export const UPDATE_NOTIFICATION_EMAIL = "UPDATE_NOTIFICATION_EMAIL ";
export const UPDATE_NOTIFICATION_EMAIL_SUCCESSFULL =
  "UPDATE_NOTIFICATION_EMAIL_SUCCESSFULL";
export const UPDATE_NOTIFICATION_EMAIL_FAILED =
  "UPDATE_NOTIFICATION_EMAIL_FAILED";

export const DELETE_NOTIFICATION_EMAIL = "DELETE_NOTIFICATION_EMAIL ";
export const DELETE_NOTIFICATION_EMAIL_SUCCESSFULL =
  "DELETE_NOTIFICATION_EMAIL_SUCCESSFULL";
export const DELETE_NOTIFICATION_EMAIL_FAILED =
  "DELETE_NOTIFICATION_EMAIL_FAILED";
export const SHOW_NOTIFICATION_EMAIL_POPUP = "SHOW_NOTIFICATION_EMAIL_POPUP";

export const SELECTED_NOTIFICATION_EMAIL = "SELECTED_NOTIFICATION_EMAIL";

//SHOW PRODUCT AVAILABILITY POPUP
export const SHOW_PRODUCT_AVAILABILITY_POPUP =
  "SHOW_PRODUCT_AVAILABILITY_POPUP";

//CREATE PRODUCT OPEN CLOSE TIME
export const CREATE_PRODUCT_OPEN_CLOSE_TIME = "CREATE_PRODUCT_OPEN_CLOSE_TIME";
export const CREATE_PRODUCT_OPEN_CLOSE_TIME_SUCCESSFULL =
  "CREATE_PRODUCT_OPEN_CLOSE_TIME_SUCCESSFULL";
export const CREATE_PRODUCT_OPEN_CLOSE_TIME_FAILED =
  "CREATE_PRODUCT_OPEN_CLOSE_TIME_FAILED";

//GET PRODUCT OPEN CLOSE LIST
export const GET_PRODUCT_OPEN_CLOSE_LIST = "GET_PRODUCT_OPEN_CLOSE_LIST";
export const GET_PRODUCT_OPEN_CLOSE_LIST_SUCCESSFULL =
  "GET_PRODUCT_OPEN_CLOSE_LIST__SUCCESSFULL";
export const GET_PRODUCT_OPEN_CLOSE_LIST_FAILED =
  "GET_PRODUCT_OPEN_CLOSE_LIST_FAILED";

//WEB PROMOTION
export const GET_PROMOTION_LIST = "GET_PROMOTION_LIST";
export const GET_PROMOTION_LIST_SUCCESSFULL = "GET_PROMOTION_LIST_SUCCESSFULL ";
export const GET_PROMOTION_LIST_FAILED = "GET_PROMOTION_LIST_FAILED";

export const CREATE_PROMOTION = "CREATE_PROMOTION";
export const CREATE_PROMOTION_SUCCESSFULL = "CREATE_PROMOTION_SUCCESSFULL";
export const CREATE_PROMOTION_FAILED = "CREATE_PROMOTION_FAILED";

export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const UPDATE_PROMOTION_SUCCESSFULL = "UPDATE_PROMOTION_SUCCESSFULL";
export const UPDATE_PROMOTION_FAILED = "UPDATE_PROMOTION_FAILED";

export const DELETE_PROMOTION = "DELETE_PROMOTION";
export const DELETE_PROMOTION_SUCCESSFULL = "DELETE_PROMOTION_SUCCESSFULL";
export const DELETE_PROMOTION_FAILED = "DELETE_PROMOTION_FAILED";

export const SELECTED_PROMOTION = "SELECTED_PROMOTION";

//WORKSTATION STATUS
export const GET_WORKSTATION_STATUS_LIST = "GET_WORKSTATION_STATUS_LIST";
export const GET_WORKSTATION_STATUS_LIST_SUCCESSFULL =
  "GET_WORKSTATION_STATUS_LIST_SUCCESSFULL";
export const GET_WORKSTATION_STATUS_LIST_FAILED =
  "GET_WORKSTATION_STATUS_LIST_FAILED";

export const UPDATE_WORKSTATION_STATUS = "UPDATE_WORKSTATION_STATUS";
export const UPDATE_WORKSTATION_STATUS_SUCCESSFULL =
  "UPDATE_WORKSTATION_STATUS_SUCCESSFULL";
export const UPDATE_WORKSTATION_STATUS_FAILED =
  "UPDATE_WORKSTATION_STATUS_FAILED";

//MESSAGE SUPPORT
export const SHOW_MESSAGE_SUPPORT_POPUP = "SHOW_MESSAGE_SUPPORT_POPUP";

export const GET_MESSAGE_SUPPORT_LIST = "GET_MESSAGE_SUPPORT_LIST";
export const GET_MESSAGE_SUPPORT_LIST_SUCCESSFULL =
  "GET_MESSAGE_SUPPORT_LIST_SUCCESSFULL";
export const GET_MESSAGE_SUPPORT_LIST_FAILED =
  "GET_MESSAGE_SUPPORT_LIST_FAILED";

export const UPDATE_MESSAGE_SUPPORT = "UPDATE_MESSAGE_SUPPORT";
export const UPDATE_MESSAGE_SUPPORT_SUCCESSFULL =
  "UPDATE_MESSAGE_SUPPORT_SUCCESSFULL";
export const UPDATE_MESSAGE_SUPPORT_FAILED = "UPDATE_MESSAGE_SUPPORT_FAILED";

// SELECTED SHOP STATUS
export const SELECTED_SHOP_STATUS = "SELECTED_SHOP_STATUS";

// GET SALE_OVERVIEW LIST
export const GET_SALES_OVERVIEW_LIST = "GET_SALES_OVERVIEW_LIST";
export const GET_PAY_METHOD_LIST_SUCCESSFULL =
  "GET_PAY_METHOD_LIST_SUCCESSFULL";
export const GET_SALE_METHOD_LIST_SUCCESSFULL =
  "GET_SALE_METHOD_LIST_SUCCESSFULL";
export const GET_SALES_OVERVIEW_LIST_FAILED = "GET_SALES_OVERVIEW_LIST_FAILED";

// GET NUMNER OF PRINTS LIST
export const GET_NUMBERS_OF_PRINTS = "GET_NUMBERS_OF_PRINTS";
export const GET_NUMBERS_OF_PRINTS_SUCCESSFULL =
  "GET_NUMBERS_OF_PRINTS_SUCCESSFULL";
export const GET_NUMBERS_OF_PRINTS_FAILED = "GET_NUMBERS_OF_PRINTS_FAILED";

// UPDATE NUMBER OF PRINTS
export const UPDATE_NUMBER_OF_PRINTS = "UPDATE_NUMBER_OF_PRINTS";
export const UPDATE_NUMBER_OF_PRINTS_SUCCESSFULL =
  "UPDATE_NUMBER_OF_PRINTS_SUCCESSFULL";
export const UPDATE_NUMBER_OF_PRINTS_FAILED = "UPDATE_NUMBER_OF_PRINTS_FAILED";

//GET WORK STATION MAUN BUTTONS
export const GET_WORK_STATION_MAIN_BUTTONS_LIST =
  "GET_WORK_STATION_MAIN_BUTTONS_LIST";
export const GET_WORK_STATION_MAIN_BUTTONS_LIST_SUCCESSFULL =
  "GET_WORK_STATION_MAIN_BUTTONS_LIST_SUCCESSFULL";
export const GET_WORK_STATION_MAIN_BUTTONS_LIST_FAILED =
  "GET_WORK_STATION_MAIN_BUTTONS_LIST_FAILED";

// EDIT WORK STATION MAUN BUTTONS DETAILES
export const EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS =
  "EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS";
export const EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS_SUCCESSFULL =
  "EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS_SUCCESSFULL";
export const EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS_FAILED =
  "EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS_FAILED";
export const SHOW_WORK_STATION_MAIN_BUTTONS_POPUP =
  "SHOW_WORK_STATION_MAIN_BUTTONS_POPUP";
export const SELECTED_MAIN_BUTTON = "SELECTED_MAIN_BUTTON";

//CHECK IN /CHECK OUT
export const GET_CHECK_IC_CHECK_OUT_LIST = "GET_CHECK_IC_CHECK_OUT_LIST";
export const GET_CHECK_IC_CHECK_OUT_LIST_SUCCESSFULL =
  "GET_CHECK_IC_CHECK_OUT_LIST_SUCCESSFULL";
export const GET_CHECK_IC_CHECK_OUT_LIST_FAILED =
  "GET_CHECK_IC_CHECK_OUT_LIST_FAILED";

export const UPDATE_CHECK_IC_CHECK_OUT = "UPDATE_CHECK_IC_CHECK_OUT";
export const UPDATE_CHECK_IC_CHECK_OUT_SUCCESSFULL =
  "UPDATE_CHECK_IC_CHECK_OUT_SUCCESSFULL";
export const UPDATE_CHECK_IC_CHECK_OUT_FAILED =
  "UPDATE_CHECK_IC_CHECK_OUT_FAILED";

export const DELETE_CHECK_IC_CHECK_OUT = "DELETE_CHECK_IC_CHECK_OUT";
export const DELETE_CHECK_IC_CHECK_OUT_SUCCESSFULL =
  "DELETE_CHECK_IC_CHECK_OUT_SUCCESSFULL";
export const DELETE_CHECK_IC_CHECK_OUT_FAILED =
  "DELETE_CHECK_IC_CHECK_OUT_FAILED";

//FINANCIAL ORDER LIST
export const GET_FINANCIAL_ORDER_LIST = "GET_FINANCIAL_ORDER_LIST";
export const GET_FINANCIAL_ORDER_LIST_SUCCESSFULL =
  "GET_FINANCIAL_ORDER_LIST_SUCCESSFULL";
export const GET_FINANCIAL_ORDER_LIST_FAILED =
  "GET_FINANCIAL_ORDER_LIST_FAILED";

export const VISIBLE_REFUND_POPPUP = "VISIBLE_REFUND_POPPUP";

export const REFUND_ORDER = "REFUND_ORDER";
export const REFUND_ORDER_SUCCESSFULL = "REFUND_ORDER_SUCCESSFULL";
export const REFUND_ORDER_FAILED = "REFUND_ORDER_FAILED";

export const GET_FINANCIAL_REFUND_LIST = "GET_FINANCIAL_REFUND_LIST";
export const GET_FINANCIAL_REFUND_LIST_SUCCESSFULL =
  "GET_FINANCIAL_REFUND_LIST_SUCCESSFULL";
export const GET_FINANCIAL_DEBT_LIST_SUCCESSFULL =
  "GET_FINANCIAL_DEBT_LIST_SUCCESSFULL";
export const GET_FINANCIAL_REFUND_LIST_FAILED =
  "GET_FINANCIAL_REFUND_LIST_FAILED";

export const VISIBLE_CREATE_DEBT_POPUP = "VISIBLE_CREATE_DEBT_POPUP";

export const SEND_DEBT = "SEND_DEBT";
export const SEND_DEBT_SUCCESSFULL = "SEND_DEBT_SUCCESSFULL ";
export const SEND_DEBT_FAILED = "SEND_DEBT_FAILED";

// PAYMENT DOJO
export const GET_PAYMENT_DOJO_SETTING_LIST = "GET_PAYMENT_DOJO_SETTING_LIST";
export const GET_PAYMENT_DOJO_SETTING_LIST_SUCCESSFULL =
  "GET_PAYMENT_DOJO_SETTING_LIST_SUCCESSFULL";
export const GET_PAYMENT_DOJO_SETTING_LIST_FAILED =
  "GET_PAYMENT_DOJO_SETTING_LIST_FAILED";

export const CREATE_PAYMENT_DOJO_SETTING = "CREATE_PAYMENT_DOJO_SETTING";
export const CREATE_PAYMENT_DOJO_SETTING_SUCCESSFULL =
  "CREATE_PAYMENT_DOJO_SETTING_SUCCESSFULL";
export const CREATE_PAYMENT_DOJO_SETTING_FAILED =
  "CREATE_PAYMENT_DOJO_SETTING_FAILED";

export const UPDATE_PAYMENT_DOJO_SETTING = "UPDATE_PAYMENT_DOJO_SETTING";
export const UPDATE_PAYMENT_DOJO_SETTING_SUCCESSFULL =
  "UPDATE_PAYMENT_DOJO_SETTING_SUCCESSFULL";
export const UPDATE_PAYMENT_DOJO_SETTING_FAILED =
  "UPDATE_PAYMENT_DOJO_SETTING_FAILED";

export const DELETE_PAYMENT_DOJO_SETTING = "DELETE_PAYMENT_DOJO_SETTING";
export const DELETE_PAYMENT_DOJO_SETTING_SUCCESSFULL =
  "DELETE_PAYMENT_DOJO_SETTING_SUCCESSFULL";
export const DELETE_PAYMENT_DOJO_SETTING_FAILED =
  "DELETE_PAYMENT_DOJO_SETTING_FAILED";

export const SELECTED_PAYMENT_DOJO_SETTING = "SELECTED_PAYMENT_DOJO_SETTING";

// CATEGORY OPEN CLOSE TIME
export const CREATE_CATEGORY_OPEN_CLOSE_TIME =
  "CREATE_CATEGORY_OPEN_CLOSE_TIME";
export const CREATE_CATEGORY_OPEN_CLOSE_TIME_SUCCESSFULL =
  "CREATE_CATEGORY_OPEN_CLOSE_TIME_SUCCESSFULL";
export const CREATE_CATEGORY_OPEN_CLOSE_TIME_FAILED =
  "CREATE_CATEGORY_OPEN_CLOSE_TIME_FAILED";
export const GET_CATEGORY_OPEN_CLOSE_LIST = "GET_CATEGORY_OPEN_CLOSE_LIST";
export const GET_CATEGORY_OPEN_CLOSE_LIST_SUCCESSFULL =
  "GET_CATEGORY_OPEN_CLOSE_LIST_SUCCESSFULL";
export const GET_CATEGORY_OPEN_CLOSE_LIST_FAILED =
  "GET_CATEGORY_OPEN_CLOSE_LIST_FAILED";
export const SHOW_CATEGORY_AVAILABILITY_POPUP =
  "SHOW_CATEGORY_AVAILABILITY_POPUP";
// SECOND SCREEN
export const SHOW_SECOND_SCREEN_IMAGE_DETAILS_POPUP =
  "SHOW_SECOND_SCREEN_IMAGE_DETAILS_POPUP";
export const GET_SECOND_SCREEN_IMAGES_LIST = "GET_SECOND_SCREEN_IMAGES_LIST";

export const GET_SECOND_FULL_SCREEN_IMAGES_LIST_SUCCESSFULL =
  "GET_SECOND_FULL_SCREEN_IMAGES_LIST_SUCCESSFULL";

export const GET_SECOND_HALF_SCREEN_IMAGES_LIST_SUCCESSFULL =
  "GET_SECOND_HALF_SCREEN_IMAGES_LIST_SUCCESSFULL";

export const GET_SECOND_SCREEN_IMAGES_LIST_FAILED =
  "GET_SECOND_SCREEN_IMAGES_LIST_FAILED";

export const CREATE_SECOND_SCREEN_IMAGE = "CREATE_SECOND_SCREEN_IMAGE";
export const CREATE_SECOND_SCREEN_IMAGE_SUCCESSFULL =
  "CREATE_SECOND_SCREEN_IMAGE_SUCCESSFULL";
export const CREATE_SECOND_SCREEN_IMAGE_FAILED =
  "CREATE_SECOND_SCREEN_IMAGE_FAILED";

export const UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING =
  "UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING";
export const UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING_SUCCESSFULL =
  "UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING_SUCCESSFULL";
export const UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING_FAILED =
  "UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING_FAILED";

export const DELETE_SECOND_SCREEN_EACH_PHOTO =
  "DELETE_SECOND_SCREEN_EACH_PHOTO";
export const DELETE_SECOND_SCREEN_EACH_PHOTO_SUCCESSFULL =
  "DELETE_SECOND_SCREEN_EACH_PHOTO_SUCCESSFULL";
export const DELETE_SECOND_SCREEN_EACH_PHOTO_FAILED =
  "DELETE_SECOND_SCREEN_EACH_PHOTO_FAILED";
export const SELECTED_SECOND_SCREEN_IMAGE = "SELECTED_SECOND_SCREEN_IMAGE";

export const SECOND_SCREEN_IMAGE_DELETE_POPUP =
  "SECOND_SCREEN_IMAGE_DELETE_POPUP";

// ET PAY
export const GET_ET_PAY_LIST = "GET_ET_PAY_LIST";
export const GET_ET_PAY_LIST_SUCCESSFULL = "GET_ET_PAY_LIST_SUCCESSFULL";
export const GET_ET_PAY_LIST_FAILED = "GET_ET_PAY_LIST_FAILED";

export const UPDATE_ET_PAY = "UPDATE_ET_PAY";
export const UPDATE_ET_PAY_SUCCESSFULL = "UPDATE_ET_PAY_SUCCESSFULL";
export const UPDATE_ET_PAY_FAILED = "UPDATE_ET_PAY_FAILED";

export const CREATE_ET_PAY = "CREATE_ET_PAY";
export const CREATE_ET_PAY_SUCCESSFULL = "CREATE_ET_PAY_SUCCESSFULL";
export const CREATE_ET_PAY_FAILED = "CREATE_ET_PAY_FAILED";

export const DELETE_ET_PAY = "DELETE_ET_PAY";
export const DELETE_ET_PAY_SUCCESSFULL = "DELETE_ET_PAY_SUCCESSFULL";
export const DELETE_ET_PAY_FAILED = "DELETE_ET_PAY_FAILED";

// SETTING APP SALES REPORT

export const UPDATE_SALES_REPORT = " UPDATE_SALES_REPORT";
export const UPDATE_SALES_REPORT_SUCCESSFULL =
  " UPDATE_SALES_REPORT_SUCCESSFULL";
export const UPDATE_SALES_REPORT_FAILED = " UPDATE_SALES_REPORT_FAILED";

// GENERAL SECOND SCREEN
export const SHOW_MAIN_SECOND_SCREEN_SHOP_GROUP_POPUP =
  "SHOW_MAIN_SECOND_SCREEN_SHOP_GROUP_POPUP";

export const GET_GENERAL_SECOND_SCREEN_LIST = "GET_GENERAL_SECOND_SCREEN_LIST";
export const GET_GENERAL_SECOND_FULL_SCREEN_LIST_SUCCESSFULL =
  "GET_GENERAL_SECOND_FULL_SCREEN_LIST_SUCCESSFULL";

export const GET_GENERAL_SECOND_HALF_SCREEN_LIST_SUCCESSFULL =
  "GET_GENERAL_SECOND_HALF_SCREEN_LIST_SUCCESSFULL";

export const GET_GENERAL_SECOND_SCREEN_LIST_FAILED =
  "GET_GENERAL_SECOND_SCREEN_LIST_FAILED";

export const CREATE_GENERAL_SECOND_SCREEN_IMAGE =
  "CREATE_GENERAL_SECOND_SCREEN_IMAGE";
export const CREATE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL =
  "CREATE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL";

export const CREATE_GENERAL_SECOND_SCREEN_IMAGE_FAILED =
  "CREATE_GENERAL_SECOND_SCREEN_IMAGE_FAILED";

export const DELETE_GENERAL_SECOND_SCREEN_IMAGE =
  "DELETE_GENERAL_SECOND_SCREEN_IMAGE";
export const DELETE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL =
  "DELETE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL";
export const DELETE_GENERAL_SECOND_SCREEN_IMAGE_FAILED =
  "DELETE_GENERAL_SECOND_SCREEN_IMAGE_FAILED";

export const UPDATE_GENERAL_SECOND_SCREEN_IMAGE =
  "UPDATE_GENERAL_SECOND_SCREEN_IMAGE";
export const UPDATE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL =
  "UPDATE_GENERAL_SECOND_SCREEN_IMAGE_SUCCESSFULL";
export const UPDATE_GENERAL_SECOND_SCREEN_IMAGE_FAILED =
  "UPDATE_GENERAL_SECOND_SCREEN_IMAGE_FAILED";

export const GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST =
  "GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST";
export const GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST_SUCCESSFULL =
  "GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST_SUCCESSFULL";
export const GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST_FAILED =
  "GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST_FAILED";

export const UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP =
  "UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP";
export const UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP_SUCCESSFULL =
  "UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP_SUCCESSFULL";
export const UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP_FAILED =
  "UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP_FAILED";
export const GENERAL_SECOND_SCREEN_SHOP_LIST =
  "GENERAL_SECOND_SCREEN_SHOP_LIST";

export const GENEREL_SECOND_SCREEN_CREATE_POPUP =
  "GENEREL_SECOND_SCREEN_CREATE_POPUP";

export const SELECTED_SCREEN = "SELECTED_SCREEN";

// WEB SETTING ADYEN
export const GET_ADYEN_LIST = "GET_ADYEN_LIST";
export const GET_ADYEN_LIST_SUCCESSFULL = "GET_ADYEN_LIST_SUCCESSFULL";
export const GET_ADYEN_LIST_FAILED = "GET_ADYEN_LIST_FAILED";

export const UPDATE_ADYEN = "UPDATE_ADYEN";
export const UPDATE_ADYEN_SUCCESSFULL = "UPDATE_ADYEN_SUCCESSFULL";
export const UPDATE_ADYEN_FAILED = "UPDATE_ADYEN_FAILED";

//SHORT CUT BUTTON LIST
export const GET_SHORT_CUT_BUTTON_LIST = "GET_SHORT_CUT_BUTTON_LIST";
export const GET_SHORT_CUT_BUTTON_LIST_SUCCESSFULL =
  "GET_SHORT_CUT_BUTTON_LIST_SUCCESSFULL";
export const GET_SHORT_CUT_BUTTON_LIST_FAILED =
  "GET_SHORT_CUT_BUTTON_LIST_FAILED";

export const UPDATE_SHORT_CUT_BUTTON = "UPDATE_SHORT_CUT_BUTTON";
export const UPDATE_SHORT_CUT_BUTTON_SUCCESSFULL =
  "UPDATE_SHORT_CUT_BUTTON_SUCCESSFULL";
export const UPDATE_SHORT_CUT_BUTTON_FAILED = "UPDATE_SHORT_CUT_BUTTON_FAILED";

//ABOUT US
export const GET_ABOUT_US_LIST = "GET_ABOUT_US_LIST";
export const GET_ABOUT_US_LIST_SUCCESSFULL = "GET_ABOUT_US_LIST_SUCCESSFULL";
export const GET_ABOUT_US_LIST_FAILED = "GET_ABOUT_US_LIST_FAILED";

export const UPDATE_ABOUT_US = "UPDATE_ABOUT_US ";
export const UPDATE_ABOUT_US_SUCCESSFULL = "UPDATE_ABOUT_US_SUCCESSFULL";
export const UPDATE_ABOUT_US_FAILED = "UPDATE_ABOUT_US_FAILED";
export const BACK_TO_ORDER = "BACK_TO_ORDER";

//SEND OTP LOGIN
export const SEND_OPT_LOGIN = "SEND_OPT_LOGIN";
export const SEND_OPT_LOGIN_SUCCESSFULL = "SEND_OPT_LOGIN_SUCCESSFULL";
export const SEND_OPT_LOGIN_FAILED = "SEND_OPT_LOGIN_FAILED";

// VERIFY OTP LOGIN
export const VERIFY_OTP_LOGIN = "VERIFY_OTP_LOGIN";
export const VERIFY_OTP_LOGIN_SUCCESSFULL = "VERIFY_OTP_LOGIN_SUCCESSFULL";
export const VERIFY_OTP_LOGIN_FAILED = "VERIFY_OTP_LOGIN_FAILED";

//MAIN NAV GENERAL PRIVACY POLICY
export const GET_PRIVACY_POLICY_LIST = "GET_PRIVACY_POLICY_LIST";
export const GET_PRIVACY_POLICY_LIST_SUCCESSFULL =
  "GET_PRIVACY_POLICY_LIST_SUCCESSFULL";
export const GET_PRIVACY_POLICY_LIST_FAILED = "GET_PRIVACY_POLICY_LIST_FAILED";

export const UPDATE_PRIVACY_POLICY = "UPDATE_PRIVACY_POLICY";
export const UPDATE_PRIVACY_POLICY_SUCCESSFULL =
  "UPDATE_PRIVACY_POLICY_SUCCESSFULL";
export const UPDATE_PRIVACY_POLICY_FAILED = "UPDATE_PRIVACY_POLICY_FAILED";

// GET ENTER EXIT LIST BY EMPLOYEE ID
export const GET_ENTER_EXIT_LIST_BY_EMPLOYEE =
  "GET_ENTER_EXIT_LIST_BY_EMPLOYEE";
export const GET_ENTER_EXIT_LIST_BY_EMPLOYEE_SUCCESSFULL =
  "GET_ENTER_EXIT_LIST_BY_EMPLOYEE_SUCCESSFULL";
export const GET_ENTER_EXIT_LIST_BY_EMPLOYEE_FAILED =
  "GET_ENTER_EXIT_LIST_BY_EMPLOYEE_FAILED";

// SHOW SETUP POPUP
export const SHOW_SETUP_POPUP = "SHOW_SETUP_POPUP";
export const RESET_CASH_ORDER = "RESET_CASH_ORDER";
export const RESET_CASH_ORDER_SUCCESSFULL = "RESET_CASH_ORDER_SUCCESSFULL";
export const RESET_CASH_ORDER_FAILED = "RESET_CASH_ORDER_FAILED";

//MAIN NAV GENERAL EXTERNAL COMPANY
export const GET_EXTERNAL_COMPANY_LIST = "GET_EXTERNAL_COMPANY_LIST ";
export const GET_EXTERNAL_COMPANY_LIST_SUCCESSFULL =
  "GET_EXTERNAL_COMPANY_LIST_SUCCESSFULL";
export const GET_EXTERNAL_COMPANY_LIST_FAILED =
  "GET_EXTERNAL_COMPANY_LIST_FAILED";

export const UPDATE_EXTERNAL_COMPANY = "UPDATE_EXTERNAL_COMPANY";
export const UPDATE_EXTERNAL_COMPANY_SUCCESSFULL =
  "UPDATE_EXTERNAL_COMPANY_SUCCESSFULL";
export const UPDATE_EXTERNAL_COMPANY_FAILED = "UPDATE_EXTERNAL_COMPANY_FAILED";

export const SELECTED_EXTERNAL_COMPANY = "SELECTED_EXTERNAL_COMPANY";
export const SHOW_EXTENRAL_COMPANY_SHOPS_LIST_POPUP =
  "SHOW_EXTENRAL_COMPANY_SHOPS_LIST_POPUP";

// ACTIVE TWO FACTOR
export const ACTIVE_TWO_FACTOR_AUTH = "ACTIVE_TWO_FACTOR_AUTH";
export const ACTIVE_TWO_FACTOR_AUTH_SUCCESSFULL =
  "ACTIVE_TWO_FACTOR_AUTH_SUCCESSFULL";
export const ACTIVE_TWO_FACTOR_AUTH_FAILED = "ACTIVE_TWO_FACTOR_AUTH_FAILED";

//SUBMENU PUBLIC LIST
export const GET_SUBMENU_PUBLIC_LIST = "GET_SUBMENU_PUBLIC_LIST";
export const GET_SUBMENU_PUBLIC_LIST_SUCCESSFULL =
  "GET_SUBMENU_PUBLIC_LIST_SUCCESSFULL";
export const GET_SUBMENU_PUBLIC_LIST_FAILED = "GET_SUBMENU_PUBLIC_LIST_FAILED";
export const SELECTED_PUBLIC_SUBMENU = "SELECTED_PUBLIC_SUBMENU";

//SUBITEM PUBLIC LIST
export const GET_SUBITEM_PUBLIC_LIST = "GET_SUBITEM_PUBLIC_LIST";
export const GET_SUBITEM_PUBLIC_LIST_SUCCESSFULL =
  "GET_SUBITEM_PUBLIC_LIST_SUCCESSFULL";
export const GET_SUBITEM_PUBLIC_LIST_FAILED = "GET_SUBITEM_PUBLIC_LIST_FAILED";
export const SELECTED_SUBITEM_PUBLIC = "SELECTED_SUBITEM_PUBLIC";

//CREATE SUBITEM PUBLIC
export const CREATE_PUBLIC_SUBITEM = "CREATE_PUBLIC_SUBITEM";
export const CREATE_PUBLIC_SUBITEM_SUCCESSFULL =
  "CREATE_PUBLIC_SUBITEM_SUCCESSFULL ";
export const CREATE_PUBLIC_SUBITEM_FAILED = "CREATE_PUBLIC_SUBITEM_FAILED";

//UPDATE SUBITEM PUBLIC
export const UPDATE_PUBLIC_SUBITEM = "UPDATE_PUBLIC_SUBITEM";
export const UPDATE_PUBLIC_SUBITEM_SUCCESSFULL =
  "UPDATE_PUBLIC_SUBITEMM_SUCCESSFULL ";
export const UPDATE_PUBLIC_SUBITEM_FAILED = "UPDATE_PUBLIC_SUBITEM_FAILED";

//DELETE SUBITEM PUBLIC
export const DELETE_PUBLIC_SUBITEM = "DELETE_PUBLIC_SUBITEM";
export const DELETE_PUBLIC_SUBITEM_SUCCESSFULL =
  "DELETE_PUBLIC_SUBITEM_SUCCESSFULL ";
export const DELETE_PUBLIC_SUBITEM_FAILED = "DELETE_PUBLIC_SUBITEM_FAILED";

//GET CUSTOMERS CALL LISTS
export const GET_CUSTOMERS_CALL_LIST = "GET_CUSTOMERS_CALL_LIST";
export const GET_CUSTOMERS_CALL_LIST_SUCCESSFULL =
  "GET_CUSTOMERS_CALL_LIST_SUCCESSFULL";
export const GET_CUSTOMERS_CALL_LIST_FAILED = "GET_CUSTOMERS_CALL_LIST_FAILED";

// WEPOS SETTING
export const GET_WEPOS_SETTING_LIST = "GET_WEPOS_SETTING_LIST";
export const GET_WEPOS_SETTING_LIST_SUCCESSFULL =
  "GET_WEPOS_SETTING_LIST_SUCCESSFULL ";
export const GET_WEPOS_SETTING_LIST_FAILED = "GET_WEPOS_SETTING_LIST_FAILED";

export const UPDATE_WEPOS_SETTING = "UPDATE_WEPOS_SETTING";
export const UPDATE_WEPOS_SETTING_SUCCESSFULL =
  "UPDATE_WEPOS_SETTING_SUCCESSFULL";
export const UPDATE_WEPOS_SETTING_FAILED = "UPDATE_WEPOS_SETTING_FAILED";

//WEPOS META DATA
export const GET_WEPOS_META_DATA_LIST = "GET_WEPOS_META_DATA_LIST";
export const GET_WEPOS_META_DATA_LIST_SUCCESSFULL =
  "GET_WEPOS_META_DATA_LIST_SUCCESSFULL";
export const GET_WEPOS_META_DATA_LIST_FAILED =
  "GET_WEPOS_META_DATA_LIST_FAILED";

export const UPDATE_WEPOS_META_DATA = "UPDATE_WEPOS_META_DATA";
export const UPDATE_WEPOS_META_DATA_SUCCESSFULL =
  "UPDATE_WEPOS_META_DATA_SUCCESSFULL";
export const UPDATE_WEPOS_META_DATA_FAILED = "UPDATE_WEPOS_META_DATA_FAILED";

//UPDATE CATEGORY SORT
export const UPDATE_CATEGORY_SORT = "UPDATE_CATEGORY_SORT";
export const UPDATE_CATEGORY_SORT_SUCCESSFULL =
  "UPDATE_CATEGORY_SORT_SUCCESSFULL";
export const UPDATE_CATEGORY_SORT_FAILED = "UPDATE_CATEGORY_SORT_FAILED";

//UPDATE CATEGORY SORT
export const UPDATE_CATEGORY_APP_SORT = "UPDATE_CATEGORY_APP_SORT";
export const UPDATE_CATEGORY_APP_SORT_SUCCESSFULL =
  "UPDATE_CATEGORY_APP_SORT_SUCCESSFULL";
export const UPDATE_CATEGORY_APP_SORT_FAILED =
  "UPDATE_CATEGORY_APP_SORT_FAILED";

//CHANGE SORT LABLE
export const CHANGE_SORT_LABEL = "CHANGE_SORT_LABEL";

//UPDATE PRODUCT SORT
export const UPDATE_PRODUCT_SORT = "UPDATE_PRODUCT_SORT";
export const UPDATE_PRODUCT_SORT_SUCCESSFULL =
  "UPDATE_PRODUCT_SORT_SUCCESSFULL";
export const UPDATE_PRODUCT_SORT_FAILED = "UPDATE_PRODUCT_SORT_FAILED";

//UPDATE PRODUCT APP SORT
export const UPDATE_PRODUCT_APP_SORT = "UPDATE_PRODUCT_APP_SORT";
export const UPDATE_PRODUCT_APP_SORT_SUCCESSFULL =
  "UPDATE_PRODUCT_APP_SORT_SUCCESSFULL";
export const UPDATE_PRODUCT_APP_SORT_FAILED = "UPDATE_PRODUCT_APP_SORT_FAILED";

// PRODUCT_CHANGE_SORT_LABEL
export const CHANGE_PRODUCT_SORT_LABEL = "CHANGE_PRODUCT_SORT_LABEL";
export const SORT_NUMBER = "SORT_NUMBER";

//UPDATE PRODUCT SUBMENU SORT
export const UPDATE_PRODUCT_SUBMENU_WEB_SORT =
  "UPDATE_PRODUCT_SUBMENU_WEB_SORT";
export const UPDATE_PRODUCT_SUBMENU_WEB_SORT_SUCCESSFULL =
  "UPDATE_PRODUCT_SUBMENU_WEB_SORT_SUCCESSFULL";
export const UPDATE_PRODUCT_SUBMENU_WEB_SORT_FAILED =
  "UPDATE_PRODUCT_SUBMENU_WEB_SORT_FAILED";

export const UPDATE_PRODUCT_SUBMENU_APP_SORT =
  "UPDATE_PRODUCT_SUBMENU_APP_SORT";
export const UPDATE_PRODUCT_SUBMENU_APP_SORT_SUCCESSFULL =
  "UPDATE_PRODUCT_SUBMENU_APP_SORT_SUCCESSFULL";
export const UPDATE_PRODUCT_SUBMENU_APP_SORT_FAILED =
  "UPDATE_PRODUCT_SUBMENU_APP_SORT_FAILED";
export const PRODUCT_SUBMENU_SORT_NUMBER = "PRODUCT_SUBMENU_SORT_NUMBER";

//UPDATE PRODUCT OPTION SORT
export const UPDATE_PRODUCT_OPTION_WEB_SORT = "UPDATE_PRODUCT_OPTION_WEB_SORT";
export const UPDATE_PRODUCT_OPTION_WEB_SORT_SUCCESSFULL =
  "UPDATE_PRODUCT_OPTION_WEB_SORT_SUCCESSFULL";
export const UPDATE_PRODUCT_OPTION_WEB_SORT_FAILED =
  "UPDATE_PRODUCT_OPTION_WEB_SORT_FAILED";

export const UPDATE_PRODUCT_OPTION_APP_SORT = "UPDATE_PRODUCT_OPTION_APP_SORT";
export const UPDATE_PRODUCT_OPTION_APP_SORT_SUCCESSFULL =
  "UPDATE_PRODUCT_OPTION_APP_SORT_SUCCESSFULL";
export const UPDATE_PRODUCT_OPTION_APP_SORT_FAILED =
  "UPDATE_PRODUCT_OPTION_APP_SORT_FAILED";

export const PRODUCT_OPTION_SORT_NUMBER = "PRODUCT_OPTION_SORT_NUMBER";

// UPDATE SUBMENU ITEM SORT
export const UPDATE_SUBMENU_ITEM_WEB_SORT = "UPDATE_SUBMENU_ITEM_WEB_SORT";
export const UPDATE_SUBMENU_ITEM_WEB_SORT_SUCCESSFULL =
  "UPDATE_SUBMENU_ITEM_WEB_SORT_SUCCESSFULL";
export const UPDATE_SUBMENU_ITEM_WEB_SORT_FAILED =
  "UPDATE_SUBMENU_ITEM_WEB_SORT_FAILED";

export const UPDATE_SUBMENU_ITEM_APP_SORT = "UPDATE_SUBMENU_ITEM_APP_SORT";
export const UPDATE_SUBMENU_ITEM_APP_SORT_SUCCESSFULL =
  "UPDATE_SUBMENU_ITEM_APP_SORT_SUCCESSFULL";
export const UPDATE_SUBMENU_ITEM_APP_SORT_FAILED =
  "UPDATE_SUBMENU_ITEM_APP_SORT_FAILED";

export const SUBMENU_ITEM_SORT_NUMBER = "SUBMENU_ITEM_SORT_NUMBER";

// SHOW SELECT ITEM POPUP
export const SHOW_SELECT_ITEM_POPUP = "SHOW_SELECT_ITEM_POPUP";
export const IS_DRESSING = "IS_DRESSING";

//IMPORT SUBITEM
export const IMPORT_SUBITEM = "IMPORT_SUBITEM";
export const IMPORT_SUBITEM_SUCCESSFULL = "IMPORT_SUBITEM_SUCCESSFULL";
export const IMPORT_SUBITEM_FAILED = "IMPORT_SUBITEM_FAILED";

//SHOW IMPORT SUBMENU POPUP
export const SHOW_IMPORT_SUBMENU_POPUP = "SHOW_IMPORT_SUBMENU_POPUP";

//NEW SUBMENU ID
export const GET_NEW_SUBMENU_ID = "GET_NEW_SUBMENU_ID";

// DELIVERY CHARGE BY AREA
export const GET_BY_AREA_LIST = "GET_BY_AREA_LIST";
export const GET_BY_AREA_LIST_SUCCESSFULL = "GET_BY_AREA_LIST_SUCCESSFULL";
export const GET_BY_AREA_LIST_FAILED = "GET_BY_AREA_LIST_FAILED";

//CREATE BY AREA LIST
export const CREATE_BY_AREA = "CREATE_BY_AREA";
export const CREATE_BY_AREA_SUCCESSFULL = "CREATE_BY_AREA_SUCCESSFULL";
export const CREATE_BY_AREA_FAILED = "CREATE_BY_AREA_FAILED";

// UPDATE BY AREA LIST
export const UPDATE_BY_AREA = "UPDATE_BY_AREA";
export const UPDATE_BY_AREA_SUCCESSFULL = "UPDATE_BY_AREA_SUCCESSFULL";
export const UPDATE_BY_AREA_FAILED = "UPDATE_BY_AREA_FAILED";

// DELETE BY AREA
export const DELETE_BY_AREA = "DELETE_BY_AREA";
export const DELETE_BY_AREA_SUCCESSFULL = "DELETE_BY_AREA_SUCCESSFULL";
export const DELETE_BY_AREA_FAILED = "DELETE_BY_AREA_FAILED";

//SELECTED AREA
export const SELECTED_DELIVERY_BY_AREA = "SELECTED_DELIVERY_BY_AREA";
export const SHOW_CREATE_DELIVERY_BY_AREA_POPUP =
  "HOW_CREATE_DELIVERY_BY_AREA_POPUP";

// DELIVERY AREA MAP LIST
export const GET_DELIVERY_AREA_MAP_LIST = "GET_DELIVERY_AREA_MAP_LIST";
export const GET_DELIVERY_AREA_MAP_LIST_SUCCESSFULL =
  "GET_DELIVERY_AREA_MAP_LIST_SUCCESSFULL";
export const GET_DELIVERY_AREA_MAP_LIST_FAILED =
  "GET_DELIVERY_AREA_MAP_LIST_FAILED";

//CREATE DELIVERY AREA MAP
export const CREATE_DELIVERY_AREA_MAP = "CREATE_DELIVERY_AREA_MAP";
export const CREATE_DELIVERY_AREA_MAP_SUCCESSFULL =
  "CREATE_DELIVERY_AREA_MAP_SUCCESSFULL";
export const CREATE_DELIVERY_AREA_MAP_FAILED =
  "CREATE_DELIVERY_AREA_MAP_FAILED ";

//UPDATE DELIVERY AREA MAP
export const UPDATE_DELIVERY_AREA_MAP = "UPDATE_DELIVERY_AREA_MAP";
export const UPDATE_DELIVERY_AREA_MAP_SUCCESSFULL =
  "UPDATE_DELIVERY_AREA_MAP_SUCCESSFULL";
export const UPDATE_DELIVERY_AREA_MAP_FAILED =
  "UPDATE_DELIVERY_AREA_MAP_FAILED";

//DELETE DELIVERY AREA MAP
export const DELETE_DELIVERY_AREA_MAP = "DELETE_DELIVERY_AREA_MAP";
export const DELETE_DELIVERY_AREA_MAP_SUCCESSFULL =
  "DELETE_DELIVERY_AREA_MAP_SUCCESSFULL";
export const DELETE_DELIVERY_AREA_MAP_FAILED =
  "DELETE_DELIVERY_AREA_MAP_FAILED";

// DEFAULT MAP CENTER
export const DEFAULT_MAP_CENTER = "DEFAULT_MAP_CENTER";

// FOF POST CODE
export const GET_ALLOWED_POST_CODE_FOF_LIST = "GET_ALLOWED_POST_CODE_FOF_LIST";
export const GET_ALLOWED_POST_CODE_FOF_LIST_SUCCESSFULL =
  "GET_ALLOWED_POST_CODE_FOF_LIST_SUCCESSFULL ";
export const GET_ALLOWED_POST_CODE_FOF_LIST_FAILED =
  "GET_ALLOWED_POST_CODE_FOF_LIST_FAILED ";

export const CREATE_ALLOWED_POST_CODE_FOF = "CREATE_ALLOWED_POST_CODE_FOF";
export const CREATE_ALLOWED_POST_CODE_FOF_SUCCESSFULL =
  "CREATE_ALLOWED_POST_CODE_FOF_SUCCESSFULL ";
export const CREATE_ALLOWED_POST_CODE_FOF_FAILED =
  "CREATE_ALLOWED_POST_CODE_FOF_FAILED";

export const UPDATE_ALLOWED_POST_CODE_FOF = "UPDATE_ALLOWED_POST_CODE_FOF";
export const UPDATE_ALLOWED_POST_CODE_FOF_SUCCESSFULL =
  "UPDATE_ALLOWED_POST_CODE_FOF_SUCCESSFULL";
export const UPDATE_ALLOWED_POST_CODE_FOF_FAILED =
  "UPDATE_ALLOWED_POST_CODE_FOF_FAILED";

export const DELETE_ALLOWED_POST_CODE_FOF = "DELETE_ALLOWED_POST_CODE_FOF";
export const DELETE_ALLOWED_POST_CODE_FOF_SUCCESSFULL =
  "DELETE_ALLOWED_POST_CODE_FOF_SUCCESSFULL";
export const DELETE_ALLOWED_POST_CODE_FOF_FAILED =
  "DELETE_ALLOWED_POST_CODE_FOF_FAILED";

export const SELECTED_ALLOWED_POST_CODE_FOF = "SELECTED_ALLOWED_POST_CODE_FOF";

//GET META DATA
export const GET_META_DATA_LIST = "GET_META_DATA_LIST";
export const GET_META_DATA_LIST_SUCCESSFULL = "GET_META_DATA_LIST_SUCCESSFULL";
export const GET_META_DATA_LIST_FAILED = "GET_META_DATA_LIST_FAILED";

export const UPDATE_META_DATA = "UPDATE_META_DATA";
export const UPDATE_META_DATA_SUCCESSFULL = "UPDATE_META_DATA_SUCCESSFULL";
export const UPDATE_META_DATA_FAILED = "UPDATE_META_DATA_FAILED";
