import React from "react";
import {
  visibleRefunPopupAction,
  refundOrderAction,
} from "../../../redux/financial/Actions";
import { useState, useEffect } from "react";
import moment from "moment";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../constants/Colors";
import { setDefaultAction } from "../../../redux/menu/Actions";
import Loader from "../../../components/Loader";
const RefundPopup = () => {
  const dispatch = useDispatch();
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  const [amount, setAmount] = useState(0);
  const [reasonText, setReasonText] = useState(null);
  const [acceptResponsibility, setAcceptResponsibility] = useState(true);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const [codeDetailsList, setCoddeDetailsList] = useState([]);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    selected_financial_order,
    loading,
    start_date,
    end_date,
    selected_shop,
  } = useSelector((state) => state.financialReduser);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { code_detail_list } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      zIndex: "3333333",
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      border: ".1px solid #d4d2d2",
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleConfirmRefudOrder = () => {
    dispatch(
      refundOrderAction(
        login_key,
        user_id,
        selected_financial_order.ShopId,
        selected_financial_order.OrderId,
        amount,
        reasonText ? reasonText.label : "",
        1,
        moment(start_date).format("YYYY-MM-DD hh:mm:ss"),
        moment(end_date).format("YYYY-MM-DD hh:mm:ss"),
        selected_shop,
        selected_financial_order.financial_order ? 0 : 1
      )
    );
  };

  useEffect(() => {
    if (selected_financial_order) {
      setAmount(selected_financial_order.ForPay);
    }
  }, [selected_financial_order]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  useEffect(() => {
    let code_detail = [];

    if (code_detail_list) {
      if (code_detail_list.length > 0) {
        for (let i = 0; i < code_detail_list.length; i++) {
          if (code_detail_list[i].GroupId == 4) {
            code_detail.push({
              ...code_detail_list[i],
              label: code_detail_list[i].Title,
              value: code_detail_list[i].Id,
            });
          }
        }
      }
      setCoddeDetailsList(code_detail);
    }
  }, [code_detail_list]);

  return width1 <= 560 ? (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(visibleRefunPopupAction([false, null]))}
    >
      <div
        onClick={(i) => i.stopPropagation()}
        className="create_shop_popup_content_container"
        style={{
          zIndex: "1000",
          width: "93%",
          height: "90%",
        }}
      >
        <div className="refund-popup-container-refund-text mobile-refund-popup-header">
          <h5>{lang.refund}</h5>
          <i
            className="fa fa-times"
            onClick={() => dispatch(visibleRefunPopupAction([false, null]))}
          ></i>
        </div>
        <div className="refund-popup-container">
          <div className="refund-popup-container-inputs">
            {" "}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mt: 5, display: "flex" },
              }}
              className="refund-popup-box"
              // className="menu-list-container-inputs"
              autoComplete="on"
            >
              <TextField
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                key={2}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                value={amount}
                // defaultValue={amount}
                onChange={(e) =>
                  setAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
                label="* Amount"
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                className="refund-popup-textfield"
              />

              {/* <TextField
                label="* Reason"
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                key={3}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": {
                      top: 0,
                    },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                autoFocus={focusedInputKey == 3 ? true : null}
                onFocus={() => setFocusedInputKey(3)}
              /> */}
            </Box>{" "}
            <div style={{ marginTop: "25px" }}>
              <h6 className="sort-title h6">* {lang.reason}</h6>
              <Select
                options={codeDetailsList}
                defaultValue={reasonText}
                isSearchable={false}
                onChange={(list, item) => setReasonText(list)}
                value={reasonText}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <FormGroup style={{ marginTop: "40px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptResponsibility}
                    onChange={() =>
                      setAcceptResponsibility(!acceptResponsibility)
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.responsibility}
              />
            </FormGroup>
          </div>
          <div className="refund-popup-container-save-button">
            <button
              type=""
              disabled={
                amount &&
                amount != 0 &&
                reasonText != "" &&
                acceptResponsibility
                  ? false
                  : true
              }
              style={{
                backgroundColor:
                  amount &&
                  amount != 0 &&
                  reasonText != "" &&
                  acceptResponsibility
                    ? null
                    : "rgb(184, 184, 184)",
              }}
              onClick={handleConfirmRefudOrder}
            >
              {lang.confirm}
            </button>
          </div>
        </div>
      </div>
      {loading ? <Loader /> : null}
    </div>
  ) : (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(visibleRefunPopupAction([false, null]))}
    >
      <div
        onClick={(i) => i.stopPropagation()}
        className="create_shop_popup_content_container"
        style={{
          zIndex: "1000",
          width: "35%",
          height: "80%",
        }}
      >
        <div className="refund-popup-container-refund-text">
          <h5>{lang.refund}</h5>{" "}
        </div>

        <div className="refund-popup-container">
          <div className="refund-popup-container-inputs">
            {" "}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mt: 5, display: "flex" },
              }}
              className="refund-popup-box"
              // className="menu-list-container-inputs"
              autoComplete="on"
            >
              <TextField
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                key={2}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                value={amount}
                // defaultValue={amount}
                onChange={(e) =>
                  setAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
                label="* Amount"
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                className="refund-popup-textfield"
              />

              {/* <TextField
                label="* Reason"
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "45px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                key={3}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": {
                      top: 0,
                    },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                autoFocus={focusedInputKey == 3 ? true : null}
                onFocus={() => setFocusedInputKey(3)}
              /> */}
            </Box>
            <div style={{ marginTop: "25px" }}>
              <h6 className="sort-title h6">* {lang.reason}</h6>
              <Select
                options={codeDetailsList}
                defaultValue={reasonText}
                isSearchable={false}
                onChange={(list, item) => setReasonText(list)}
                value={reasonText}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <FormGroup style={{ marginTop: "40px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptResponsibility}
                    onChange={() =>
                      setAcceptResponsibility(!acceptResponsibility)
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.responsibility}
              />
            </FormGroup>
          </div>
          <div className="refund-popup-container-save-button">
            <button
              type=""
              disabled={
                amount && amount != 0 && reasonText && acceptResponsibility
                  ? false
                  : true
              }
              style={{
                backgroundColor:
                  amount && amount != 0 && reasonText && acceptResponsibility
                    ? null
                    : "rgb(184, 184, 184)",
              }}
              onClick={handleConfirmRefudOrder}
            >
              {lang.confirm}
            </button>
          </div>
        </div>
      </div>{" "}
      {loading ? <Loader /> : null}
    </div>
  );
};

export default RefundPopup;
