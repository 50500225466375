import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";

import { padding_menu_list_outer } from "../constants/Sizes";
const SaveMenuButton = ({ clickButton }) => {
  const { height, width } = useScrollbarSize();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_category } = useSelector((state) => state.categoryList);

  return (
    <div
      className="add_menu_list_item_main_container"
      style={{
        paddingRight: width + padding_menu_list_outer,
        paddingLeft: padding_menu_list_outer,
      }}
    >
      <div id="save_button_div">
        <button
          // disabled={selected_category ? false : true}
          id="save_button_menu"
          onClick={clickButton}
        >
          {lang.save}
        </button>
      </div>
    </div>
  );
};

export default SaveMenuButton;
