import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import store from "store2";
import ListHeader from "../components/ListHeader";
import MenuListItem from "../components/MenuListItem";
import AddMenuItem from "../components/AddMenuItem";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  createProductAction,
  getProductAction,
  selectedProductAction,
  deleteProductAction,
  selectedProductOptionListAction,
  selectedProductSubmenuAction,
  sendProductSettingsAction,
} from "../redux/product/Action";
import {
  changeSelectedButtonBorderColorOnFocus,
  clickedItemAction,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
  selectedOtemInSomeLists,
  updateProductSortAction,
  changeProductSortLabelAction,
  updateProductAppSortAction,
  sortNumberAction,
} from "../redux/menu/Actions";
import { receiveLoginResponse } from "../redux/login/Actions";
import { primaryColor } from "../constants/Colors";
import {
  getProductOptionsAction,
  sendProductOptionEditAction,
} from "../redux/product/Action";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const ProductsList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { product_list, selected_Product, change_product_sort_label } =
    useSelector((state) => state.productList);
  const {
    category_list,
    selected_category,
    show_hide,
    show_hide_web,
    show_hide_kiosk,
    printer_list,
    printer_list_id,
    report_section,
    report_section_id,
    product_group,
    product_group_id,
    vat,
    vat_id,
    font_family,
    font_family_id,
    font_size,
    font_size_id,
    link_to,
    link_to_id,
    change_selected_button_border_color_on_focus,
    selected_item_in_all_lists,
    selected_item_in_some_lists,
    result_successfull,
    product_option_list,
    sort_number,
  } = useSelector((state) => state.categoryList);
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  const [minSelect, setMinSelect] = useState(1);
  const [maxSelect, setMaxSelect] = useState(1);
  const [productList, setProductList] = useState([]);
  const [appTitle, setAppTitle] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [secondLanguage, setSecondLanguage] = useState("");
  const [mainPrice, setMainPrice] = useState(0);
  const [optionPrice, setOptionPrice] = useState(0);
  const [optionTitle, setOptionTitle] = useState("");
  const [titleProduct, setTitleProduct] = useState("");
  const [descProduct, setDescProduct] = useState("");
  const [id, setId] = useState(0);
  const [userAccesses, setUserAccesses] = useState([]);
  const [userType, setUserType] = useState(0);
  const [productOptionList, setProductOptionList] = useState([]);
  const [rolesId, setRolesId] = useState([]);
  const [sortOnWebOrApp, setSortOnWebOrApp] = useState(0);
  const [sortNumber, setSortNumber] = useState(0);
  // const [emptyInput, setEmptyInput] = useState(false);
  const handleOnclickButton = (title, desc, amount, is_web, is_app) => {
    dispatch(
      createProductAction(
        login_key,
        user_id,
        selected_category.ShopId,
        selected_category.Id,
        title.replaceAll(`"`, `\"\"`),
        desc.replaceAll(`"`, `\"\"`),
        amount ? parseFloat(amount) : 0,
        is_web ? 1 : 0,
        is_app ? 1 : 0
      )
    );

    // setEmptyInput(true);
    document.getElementById("countaining").scroll({
      top: document.getElementById("countaining").scrollHeight,
      behavior: "smooth",
    });
  };
  const handleSortButtons = (key) => {
    // dispatch(selectedOtemInAllLists(null));
    dispatch(selectedProductAction(null));
    dispatch(changeProductSortLabelAction(true));
    if (key == 1) {
      setSortOnWebOrApp(1);
    } else {
      setSortOnWebOrApp(2);
    }
  };
  //   useEffect(() => {
  //     const keyDownHandler = (event) => {
  //       if (event.key === "Enter") {
  //  dispatch(
  //       createProductAction(
  //         login_key, user_id,
  //         selected_category.ShopId,
  //         selected_category.Id,
  //         handleOnclickButton.title.replaceAll(`"`, `\"\"`),
  //         handleOnclickButton.desc.replaceAll(`"`, `\"\"`)
  //       )
  //     );
  //       }
  //     };

  //     document.addEventListener("keydown", keyDownHandler);

  //     return () => {
  //       document.removeEventListener("keydown", keyDownHandler);
  //     };
  //   }, [titleProduct, descProduct]);
  const handleDeleted = (item) => {
    dispatch(
      deleteProductAction(
        login_key,
        user_id,
        item.ShopId,
        item.Id,
        item.CategoryId
      )
    );
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...productList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setProductList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...productList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setProductList(copy_list);
  };
  const handleOnchangeInputTitle = (event) => {
    setTitleProduct(event.target.value);
  };

  const handleOnchangeInputDesc = (event) => {
    setDescProduct(event.target.value);
  };

  const handleClick = (item, key) => {
    setClickedItem(key);

    let showHideApp = [];

    let showHideWeb = [];
    let showHideKiosk = [];
    let printerList = [];
    let reportSectionList = null;
    let productGroupList = null;
    let vatList = null;
    let fontFamilyList = null;
    let fontSizeList = null;
    let submenusFontSizeList = null;
    let linkToList = null;

    //  SET DEFAULT APP VISIBLE
    for (let i = 0; i < item.AppVisible.length; i++) {
      if (item.AppVisible[i] == "0") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 0) {
            showHideApp.push({ label: show_hide[i].label, value: 0 });
          }
        }
      } else if (item.AppVisible[i] == "1") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 1) {
            showHideApp.push({ label: show_hide[i].label, value: 1 });
          }
        }
      } else if (item.AppVisible[i] == "2") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 2) {
            showHideApp.push({ label: show_hide[i].label, value: 2 });
          }
        }
      } else if (item.AppVisible[i] == "3") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 3) {
            showHideApp.push({ label: show_hide[i].label, value: 3 });
          }
        }
      } else if (item.AppVisible[i] == "4") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 4) {
            showHideApp.push({ label: show_hide[i].label, value: 4 });
          }
        }
      } else if (item.AppVisible[i] == "5") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 5) {
            showHideApp.push({ label: show_hide[i].label, value: 5 });
          }
        }
      }
    }

    //  SET DEFAULT WEB VISIBLE
    for (let i = 0; i < item.WebVisible.length; i++) {
      if (item.WebVisible[i] == "0") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 0) {
            showHideWeb.push({ label: show_hide[i].label, value: 0 });
          }
        }
      } else if (item.WebVisible[i] == "1") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 1) {
            showHideWeb.push({ label: show_hide[i].label, value: 1 });
          }
        }
      } else if (item.WebVisible[i] == "2") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 2) {
            showHideWeb.push({ label: show_hide[i].label, value: 2 });
          }
        }
      } else if (item.WebVisible[i] == "3") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 3) {
            showHideWeb.push({ label: show_hide[i].label, value: 3 });
          }
        }
      } else if (item.WebVisible[i] == "4") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 4) {
            showHideWeb.push({ label: show_hide[i].label, value: 4 });
          }
        }
      } else if (item.WebVisible[i] == "5") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 5) {
            showHideWeb.push({ label: show_hide[i].label, value: 5 });
          }
        }
      }
    }

    //  SET DEFAULT KIOSK VISIBLE
    for (let i = 0; i < item.KioskVisible.length; i++) {
      if (item.KioskVisible[i] == "0") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 0) {
            showHideKiosk.push({ label: show_hide[i].label, value: 0 });
          }
        }
      } else if (item.KioskVisible[i] == "1") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 1) {
            showHideKiosk.push({ label: show_hide[i].label, value: 1 });
          }
        }
      } else if (item.KioskVisible[i] == "2") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 2) {
            showHideKiosk.push({ label: show_hide[i].label, value: 2 });
          }
        }
      } else if (item.KioskVisible[i] == "3") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 3) {
            showHideKiosk.push({ label: show_hide[i].label, value: 3 });
          }
        }
      } else if (item.KioskVisible[i] == "4") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 4) {
            showHideKiosk.push({ label: show_hide[i].label, value: 4 });
          }
        }
      } else if (item.KioskVisible[i] == "5") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 5) {
            showHideKiosk.push({ label: show_hide[i].label, value: 5 });
          }
        }
      }
    }
    // SET DEFAULT PRINTER LIST
    if (item.PrinterId) {
      for (let i = 0; i < item.PrinterId.length; i++) {
        if (printer_list_id.includes(parseInt(item.PrinterId[i]))) {
          let index = printer_list_id.indexOf(parseInt(item.PrinterId[i]));
          printerList.push({
            label: printer_list[index].label,
            value: printer_list[index].value,
          });
        }
      }
    }

    // SET DEFAULT REPORT SECTION LIST
    if (item.ReportSection) {
      if (report_section_id.includes(parseInt(item.ReportSection))) {
        let index = report_section_id.indexOf(parseInt(item.ReportSection));
        reportSectionList = {
          label: report_section[index].label,
          value: report_section[index].value,
        };
      }
    }

    // SET DEFAULT RRODUCT GROUP LIST
    if (item.ProductGroup) {
      if (product_group_id.includes(parseInt(item.ProductGroup))) {
        let index = product_group_id.indexOf(parseInt(item.ProductGroup));
        productGroupList = {
          label: product_group[index].label,
          value: product_group[index].value,
        };
      }
    }

    // SET DEFAULT VAT LIST
    if (item.VatId) {
      if (vat_id.includes(parseInt(item.VatId))) {
        let index = vat_id.indexOf(parseInt(item.VatId));
        vatList = {
          label: vat[index].label,
          value: vat[index].value,
        };
      }
    }

    // SET DEFAULT FONT FAMILY LIST
    if (item.AppTitleFontFamily) {
      if (font_family_id.includes(parseInt(item.AppTitleFontFamily))) {
        let index = font_family_id.indexOf(parseInt(item.AppTitleFontFamily));
        fontFamilyList = {
          label: font_family[index].label,
          value: font_family[index].value,
        };
      }
    }

    // SET DEFAULT FONT SIZE LIST
    if (item.AppTitleFontSize) {
      if (font_size_id.includes(parseInt(item.AppTitleFontSize))) {
        let index = font_size_id.indexOf(parseInt(item.AppTitleFontSize));
        fontSizeList = {
          label: font_size[index].label,
          value: font_size[index].value,
        };
      }
    }
    if (item.SubmenusFontSize) {
      if (font_size_id.includes(parseInt(item.SubmenusFontSize))) {
        let index = font_size_id.indexOf(parseInt(item.SubmenusFontSize));
        submenusFontSizeList = {
          label: font_size[index].label,
          value: font_size[index].value,
        };
      }
    }

    // SET DEFAULT LINK TO LIST
    if (item.linkto) {
      if (link_to_id.includes(parseInt(item.linkto))) {
        let index = link_to_id.indexOf(parseInt(item.linkto));
        linkToList = {
          label: link_to[index].label,
          value: link_to[index].value,
        };
      }
    }

    dispatch(
      selectedProductAction({
        ...item,
        AppVisible: showHideApp,
        WebVisible: showHideWeb,
        KioskVisible: showHideKiosk,
        PrinterId: printerList,
        ReportSection: reportSectionList,
        ProductGroup: productGroupList,
        VatId: vatList,
        AppTitleFontSize: fontSizeList,
        SubmenusFontSize: submenusFontSizeList,
        AppTitleFontFamily: fontFamilyList,
        linkto: linkToList,
      })
    );
  };
  const handleSelectedProduct = (item) => {
    let showHideApp = [];

    let showHideWeb = [];
    let showHideKiosk = [];
    let printerList = [];
    let reportSectionList = null;
    let productGroupList = null;
    let vatList = null;
    let fontFamilyList = null;
    let fontSizeList = null;
    let submenusFontSizeList = null;
    let linkToList = null;

    //  SET DEFAULT APP VISIBLE
    for (let i = 0; i < item.AppVisible.length; i++) {
      if (item.AppVisible[i] == "0") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 0) {
            showHideApp.push({ label: show_hide[i].label, value: 0 });
          }
        }
      } else if (item.AppVisible[i] == "1") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 1) {
            showHideApp.push({ label: show_hide[i].label, value: 1 });
          }
        }
      } else if (item.AppVisible[i] == "2") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 2) {
            showHideApp.push({ label: show_hide[i].label, value: 2 });
          }
        }
      } else if (item.AppVisible[i] == "3") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 3) {
            showHideApp.push({ label: show_hide[i].label, value: 3 });
          }
        }
      } else if (item.AppVisible[i] == "4") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 4) {
            showHideApp.push({ label: show_hide[i].label, value: 4 });
          }
        }
      } else if (item.AppVisible[i] == "5") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 5) {
            showHideApp.push({ label: show_hide[i].label, value: 5 });
          }
        }
      }
    }

    //  SET DEFAULT WEB VISIBLE
    for (let i = 0; i < item.WebVisible.length; i++) {
      if (item.WebVisible[i] == "0") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 0) {
            showHideWeb.push({ label: show_hide[i].label, value: 0 });
          }
        }
      } else if (item.WebVisible[i] == "1") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 1) {
            showHideWeb.push({ label: show_hide[i].label, value: 1 });
          }
        }
      } else if (item.WebVisible[i] == "2") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 2) {
            showHideWeb.push({ label: show_hide[i].label, value: 2 });
          }
        }
      } else if (item.WebVisible[i] == "3") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 3) {
            showHideWeb.push({ label: show_hide[i].label, value: 3 });
          }
        }
      } else if (item.WebVisible[i] == "4") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 4) {
            showHideWeb.push({ label: show_hide[i].label, value: 4 });
          }
        }
      } else if (item.WebVisible[i] == "5") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 5) {
            showHideWeb.push({ label: show_hide[i].label, value: 5 });
          }
        }
      }
    }

    //  SET DEFAULT KIOSK VISIBLE
    for (let i = 0; i < item.KioskVisible.length; i++) {
      if (item.KioskVisible[i] == "0") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 0) {
            showHideKiosk.push({ label: show_hide[i].label, value: 0 });
          }
        }
      } else if (item.KioskVisible[i] == "1") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 1) {
            showHideKiosk.push({ label: show_hide[i].label, value: 1 });
          }
        }
      } else if (item.KioskVisible[i] == "2") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 2) {
            showHideKiosk.push({ label: show_hide[i].label, value: 2 });
          }
        }
      } else if (item.KioskVisible[i] == "3") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 3) {
            showHideKiosk.push({ label: show_hide[i].label, value: 3 });
          }
        }
      } else if (item.KioskVisible[i] == "4") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 4) {
            showHideKiosk.push({ label: show_hide[i].label, value: 4 });
          }
        }
      } else if (item.KioskVisible[i] == "5") {
        for (let i = 0; i < show_hide.length; i++) {
          if (show_hide[i].value == 5) {
            showHideKiosk.push({ label: show_hide[i].label, value: 5 });
          }
        }
      }
    }
    // SET DEFAULT PRINTER LIST
    if (item.PrinterId) {
      for (let i = 0; i < item.PrinterId.length; i++) {
        if (printer_list_id.includes(parseInt(item.PrinterId[i]))) {
          let index = printer_list_id.indexOf(parseInt(item.PrinterId[i]));
          printerList.push({
            label: printer_list[index].label,
            value: printer_list[index].value,
          });
        }
      }
    }

    // SET DEFAULT REPORT SECTION LIST
    if (item.ReportSection) {
      if (report_section_id.includes(parseInt(item.ReportSection))) {
        let index = report_section_id.indexOf(parseInt(item.ReportSection));
        reportSectionList = {
          label: report_section[index].label,
          value: report_section[index].value,
        };
      }
    }

    // SET DEFAULT RRODUCT GROUP LIST
    if (item.ProductGroup) {
      if (product_group_id.includes(parseInt(item.ProductGroup))) {
        let index = product_group_id.indexOf(parseInt(item.ProductGroup));
        productGroupList = {
          label: product_group[index].label,
          value: product_group[index].value,
        };
      }
    }

    // SET DEFAULT VAT LIST
    if (item.VatId) {
      if (vat_id.includes(parseInt(item.VatId))) {
        let index = vat_id.indexOf(parseInt(item.VatId));
        vatList = {
          label: vat[index].label,
          value: vat[index].value,
        };
      }
    }

    // SET DEFAULT FONT FAMILY LIST
    if (item.AppTitleFontFamily) {
      if (font_family_id.includes(parseInt(item.AppTitleFontFamily))) {
        let index = font_family_id.indexOf(parseInt(item.AppTitleFontFamily));
        fontFamilyList = {
          label: font_family[index].label,
          value: font_family[index].value,
        };
      }
    }

    // SET DEFAULT FONT SIZE LIST
    if (item.AppTitleFontSize) {
      if (font_size_id.includes(parseInt(item.AppTitleFontSize))) {
        let index = font_size_id.indexOf(parseInt(item.AppTitleFontSize));
        fontSizeList = {
          label: font_size[index].label,
          value: font_size[index].value,
        };
      }
    }
    if (item.SubmenusFontSize) {
      if (font_size_id.includes(parseInt(item.SubmenusFontSize))) {
        let index = font_size_id.indexOf(parseInt(item.SubmenusFontSize));
        submenusFontSizeList = {
          label: font_size[index].label,
          value: font_size[index].value,
        };
      }
    }

    // SET DEFAULT LINK TO LIST
    if (item.linkto) {
      if (link_to_id.includes(parseInt(item.linkto))) {
        let index = link_to_id.indexOf(parseInt(item.linkto));
        linkToList = {
          label: link_to[index].label,
          value: link_to[index].value,
        };
      }
    }

    dispatch(
      selectedProductAction({
        ...item,
        AppVisible: showHideApp,
        WebVisible: showHideWeb,
        KioskVisible: showHideKiosk,
        PrinterId: printerList,
        ReportSection: reportSectionList,
        ProductGroup: productGroupList,
        VatId: vatList,
        AppTitleFontSize: fontSizeList,
        SubmenusFontSize: submenusFontSizeList,
        AppTitleFontFamily: fontFamilyList,
        linkto: linkToList,
      })
    );
  };
  const handleEditIconClick = (item) => {
    dispatch(selectedOtemInAllLists(item));
    const copy_product_list = [...productList];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.Id == copy_product_list[i].Id) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setProductList(copy_product_list);
  };
  const handleEditOptionPrice = (element) => {
    dispatch(selectedOtemInSomeLists(element));
    const copy_product_option_list = [...productOptionList];
    for (let i = 0; i < copy_product_option_list.length; i++) {
      if (element.Id == copy_product_option_list[i].Id) {
        copy_product_option_list[i].showEditBox = true;
      } else {
        copy_product_option_list[i].showEditBox = false;
      }
    }
    setProductOptionList(copy_product_option_list);
  };

  const handleCloseOptions = (item) => {
    const copy_product_list = [...productList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showOptions = false;
      }
    }
    setProductList(copy_product_list);
  };
  const handleCloseIcon = (item) => {
    const copy_product_list = [...productList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setProductList(copy_product_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...productList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
      // dispatch(justTitleAndPriceAction(0));
    }

    setProductList(copy_product_list);
  };
  const formData = new FormData();

  const handleSaveClicked = () => {
    if (appTitle == "") {
      toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      formData.append("LoginKey", login_key);
      formData.append("UserId", user_id);
      formData.append("Id", selected_item_in_all_lists.Id);
      formData.append("ShopId", selected_shop[0].ShopId);
      formData.append("AppTitle", appTitle);
      formData.append("AppDescription", "");
      formData.append(
        "WebTitle",
        webTitle
          ? webTitle.replaceAll(`"`, `\"\"`)
          : appTitle.replaceAll(`"`, `\"\"`)
      );
      formData.append("WebDescription", "");
      formData.append("KioskTitle", "");
      formData.append("KioskDescription", "");
      formData.append("SecondLanguage", secondLanguage ? secondLanguage : "");
      formData.append("AppVisible", "");
      formData.append("WebVisible", "");
      formData.append("KioskVisible", "");
      formData.append("AmountDelivery", mainPrice ? mainPrice : 0);
      formData.append("AmountCollection", 0);
      formData.append("AmountTakeaway", 0);
      formData.append("AmountEatIn", 0);
      formData.append("PrinterId", "");
      formData.append("ReportSection", 0);
      formData.append("IsShowOptions", 0);
      formData.append("ProductGroup", 0);
      formData.append("VatId", 0);
      formData.append("IsSugessted", 0);
      formData.append("IsPopular", 0);
      formData.append("IsDifOptionVat", 0);
      formData.append("UnitId", 0);
      formData.append("Barcode", "");
      formData.append("CustomTitle", "");
      formData.append("CloseCount", 0);
      formData.append("CustomTitleInLast", 0);
      formData.append("TitleInLast", 0);
      formData.append("linkto", 0);
      formData.append("IsAutoDisplaySubMenu", 0);
      formData.append("PrintLabel", 0);
      formData.append("AppSortId", 0);
      formData.append("WebSortId", 0);
      formData.append("KioskSortId", 0);
      formData.append("AppBgColor", "");
      formData.append("AppTitleFontSize", 0);
      formData.append("AppTitleFontFamily", "");
      formData.append("AppTitleColor", "");
      formData.append("NumberOfSubmenusColumn", 4);
      formData.append("NumberOfSubmenusRow", 6);
      formData.append("AppImageUrl", "");
      formData.append("AppVisibleImage", 0);
      formData.append("WebImageUrl", "");
      formData.append("WebVisibleImage", 0);
      formData.append("KioskImageUrl", "");
      formData.append("KioskVisibleImage", 0);
      formData.append("SetDefaultVatForAllOptions", 0);
      formData.append("LastAppImageUrlName", "");
      formData.append("LastWebImageUrlName", "");
      formData.append("NumberOfItemsColumn", 4);
      formData.append("NumberOfItemsRow", 6);
      formData.append("JustTitleAndPrice", 1);
      formData.append("SubmenusFontSize", 9);
      formData.append("Calories", 0);
      formData.append("AlwaysOpen", 0);
      formData.append("DeleteImage", 0);
      formData.append("FixedItemId", 0);
      dispatch(
        sendProductSettingsAction(
          formData,
          selected_category.Id,
          login_key,
          user_id,
          selected_item_in_all_lists.Id,
          selected_category.ShopId,
          1,
          mainPrice
        )
      );
    }
  };
  const handleSaveOption = () => {
    dispatch(
      sendProductOptionEditAction(
        login_key,
        user_id,
        selected_item_in_some_lists.Id,
        selected_item_in_some_lists.ShopId,
        selected_item_in_some_lists.ProductId,
        selected_item_in_some_lists.OptionId,
        appTitle.replaceAll(`"`, `\"\"`),
        webTitle.replaceAll(`"`, `\"\"`),
        "",
        optionPrice ? parseFloat(optionPrice) : 0,
        0,
        0,
        0,
        0,
        2,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        selected_category.Id,
        0
        // 1
      )
    );
  };
  const handleShowOptions = (item) => {
    const copy_list = [...productList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showOptions = true;
      } else {
        copy_list[i].showOptions = false;
      }
    }
    setProductList(copy_list);
    handleSelectedProduct(item);
  };
  const handleCloseOptionEditBox = (element) => {
    const copy_product_option_list = [...productOptionList];
    for (let i = 0; i < copy_product_option_list.length; i++) {
      if (element.Id == copy_product_option_list[i].Id) {
        copy_product_option_list[i].showEditBox = false;
      }
    }
    setProductOptionList(copy_product_option_list);
  };
  const handleDragEnd = (result) => {
    const copy_list = [...productList];
    // dispatch(changeSortLableAction(true));
    if (sortOnWebOrApp == 1) {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setProductList(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];

      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateProductSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.WebSortId,
            new_item.WebSortId,
            selected_category.Id
          )
        );
      }
    } else {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setProductList(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];
      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateProductAppSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.AppSortId,
            new_item.AppSortId,
            selected_category.Id
          )
        );
      }
    }
  };
  useEffect(() => {
    if (selected_category) {
      dispatch(
        getProductAction(
          login_key,
          user_id,
          selected_category.ShopId,
          selected_category.Id
        )
      );
    }
  }, []);

  useEffect(() => {
    if (product_list) {
      setProductList(product_list);
    } else {
      setProductList([]);
    }
  }, [product_list]);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);
  // useEffect(() => {
  //   const user_access = store.get("userAccess");
  //   if (!result_login_successfull) {
  //     if (user_access) {
  //       if (user_access.length > 0) {
  //         if (user_access[0].UserType != 10001201) {
  //           navigate("/");
  //         } else {
  //           dispatch(receiveLoginResponse(user_access));
  //         }
  //       }
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (selected_category) {
      dispatch(
        getProductAction(
          login_key,
          user_id,
          selected_category.ShopId,
          selected_category.Id
        )
      );
    } else {
      setProductList([]);
    }
  }, [selected_category]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_product_list = [...productList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (copy_product_list[index].showEditBox) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setProductList(copy_product_list);
    dispatch(justTitleAndPriceAction(1));

    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(justTitleAndPriceAction(0));
      dispatch(selectedOtemInAllLists(null));
      dispatch(selectedOtemInSomeLists(null));
    };
  }, [change_selected_button_border_color_on_focus]);
  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);

  useEffect(() => {
    let roles_id = [];
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          setUserType(userAccesses[0].UserType);
          roles_id.push(userAccesses[i].RoleId);
        }
      }
    }
    setRolesId(roles_id);
  }, [userAccesses]);

  useEffect(() => {
    if (selected_item_in_all_lists) {
      if (selected_item_in_all_lists.AppTitle) {
        setAppTitle(selected_item_in_all_lists.AppTitle);
      } else {
        setAppTitle("");
      }
      if (selected_item_in_all_lists.WebTitle) {
        setWebTitle(selected_item_in_all_lists.WebTitle);
      } else {
        setWebTitle("");
      }
      if (selected_item_in_all_lists.AmountDelivery) {
        setMainPrice(selected_item_in_all_lists.AmountDelivery);
      } else {
        setMainPrice(0);
      }
      if (selected_item_in_all_lists.SecondLanguage) {
        setSecondLanguage(selected_item_in_all_lists.SecondLanguage);
      } else {
        setSecondLanguage("");
      }
    }
  }, [selected_item_in_all_lists]);
  useEffect(() => {
    if (selected_Product) {
      dispatch(
        getProductOptionsAction(
          login_key,
          user_id,
          selected_Product.ShopId,
          selected_Product.Id,
          selected_Product.CategoryId
        )
      );
    }
  }, [selected_Product]);

  useEffect(() => {
    if (product_option_list.length > 0) {
      setProductOptionList(product_option_list);
    }
  }, [product_option_list]);
  useEffect(() => {
    if (selected_item_in_some_lists) {
      setOptionPrice(selected_item_in_some_lists.AmountDelivery);
      setOptionTitle(selected_item_in_some_lists.AppTitle);
      setSecondLanguage(selected_item_in_some_lists.SecondLanguage);
    }
  }, [selected_item_in_some_lists]);

  useEffect(() => {
    const copy_product_list = [...product_list];

    if (sortOnWebOrApp == 1) {
      const sortArrey = copy_product_list.sort(function (a, b) {
        return a.WebSortId - b.WebSortId;
      });

      if (change_product_sort_label) {
        dispatch(changeProductSortLabelAction(false));
        dispatch(selectedProductAction(copy_product_list[0]));
        for (let i = 0; i < copy_product_list.length; i++) {
          copy_product_list[i].collapse = i == 0 ? true : false;
        }
      }
      setProductList(sortArrey);
    } else {
      const sortArrey = copy_product_list.sort(function (a, b) {
        return a.AppSortId - b.AppSortId;
      });

      if (change_product_sort_label) {
        dispatch(changeProductSortLabelAction(false));
        dispatch(selectedProductAction(sortArrey[0]));
        for (let i = 0; i < sortArrey.length; i++) {
          sortArrey[i].collapse = i == 0 ? true : false;
        }
      }

      setProductList(sortArrey);
    }
    return () => {
      setSortNumber(sortOnWebOrApp);
      dispatch(sortNumberAction(sortOnWebOrApp));
    };
  }, [sortOnWebOrApp, product_list, change_product_sort_label]);
  useEffect(() => {
    if (sort_number == 2) {
      setSortOnWebOrApp(2);
    } else {
      setSortOnWebOrApp(1);
    }
  }, []);

  return (
    <div
      className="countaining-div"
      id="countaining"
      onClick={handleCloseEditBoxWithOnBlur}
    >
      <ListHeader
        title={lang.products_list}
        show_back={false}
        sort_switch={true}
        sort_click={(key) => handleSortButtons(key)}
        sort_key={sortOnWebOrApp}
      />
      {userType == 10001202 && rolesId.includes(17) && !rolesId.includes(1) ? (
        <>
          <div className="menu_list_header_container">
            {productList.length > 0 ? (
              <div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {productList.map((item, index) => (
                          <Draggable
                            key={item.Id}
                            draggableId={item.Id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div>
                                  {item.showEditBox ? (
                                    <div
                                      className="menu_list_item_container box_shadow menu-list-container"
                                      onClick={(i) => i.stopPropagation()}
                                    >
                                      <Box
                                        component="form"
                                        className="menu-list-container-inputs"
                                        autoComplete="on"
                                      >
                                        <TextField
                                          key={1}
                                          sx={{
                                            "& label.Mui-focused": {
                                              color: primaryColor,
                                            },
                                            "&:hover label": {
                                              color: primaryColor,
                                            },
                                            "& .MuiOutlinedInput-root": {
                                              fontSize: "15px",
                                              height: "40px",
                                              color: "black",

                                              "& fieldset": {
                                                borderColor: "grey",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: primaryColor,
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: primaryColor,
                                              },
                                            },
                                          }}
                                          size="small"
                                          label={lang.app_title}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: 16,
                                              // top: -7,
                                              "&.MuiInputLabel-shrink": {
                                                top: 0,
                                              },
                                              "&.Mui-focused": {
                                                color: primaryColor,
                                              },
                                              "& label.Mui-focused": {
                                                color: primaryColor,
                                              },
                                              "&:hover label": {
                                                color: primaryColor,
                                              },
                                            },
                                          }}
                                          value={appTitle}
                                          // defaultValue={number == 9 ? postCode : appTitle}
                                          onChange={(e) =>
                                            setAppTitle(e.target.value)
                                          }
                                          style={{
                                            width: "27%",
                                          }}
                                          autoFocus={
                                            focusedInputKey == 1 ? true : false
                                          }
                                          onFocus={() => setFocusedInputKey(1)}
                                        />
                                        <TextField
                                          sx={{
                                            "& label.Mui-focused": {
                                              color: primaryColor,
                                            },
                                            "&:hover label": {
                                              color: primaryColor,
                                            },
                                            "& .MuiOutlinedInput-root": {
                                              fontSize: "15px",
                                              height: "40px",
                                              color: "black",
                                              "& fieldset": {
                                                borderColor: "grey",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: primaryColor,
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: primaryColor,
                                              },
                                            },
                                          }}
                                          size="small"
                                          key={2}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: 16,
                                              // top: -7,
                                              "&.MuiInputLabel-shrink": {
                                                top: 0,
                                              },
                                              "&.Mui-focused": {
                                                color: primaryColor,
                                              },
                                            },
                                          }}
                                          label={lang.web_title}
                                          value={webTitle}
                                          onChange={(e) =>
                                            setWebTitle(e.target.value)
                                          }
                                          style={{
                                            width: "27%",
                                          }}
                                          autoFocus={
                                            focusedInputKey == 2 ? true : false
                                          }
                                          onFocus={() => setFocusedInputKey(2)}
                                        />
                                        <TextField
                                          sx={{
                                            "& label.Mui-focused": {
                                              color: primaryColor,
                                            },
                                            "&:hover label": {
                                              color: primaryColor,
                                            },
                                            "& .MuiOutlinedInput-root": {
                                              fontSize: "15px",
                                              height: "40px",
                                              color: "black",
                                              "& fieldset": {
                                                borderColor: "grey",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: primaryColor,
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: primaryColor,
                                              },
                                            },
                                          }}
                                          size="small"
                                          key={4}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: 16,
                                              // top: -7,

                                              "&.MuiInputLabel-shrink": {
                                                top: 0,
                                              },
                                              "&.Mui-focused": {
                                                color: primaryColor,
                                              },
                                            },
                                          }}
                                          label={lang.secoundLanguage}
                                          value={secondLanguage}
                                          onChange={(e) =>
                                            setSecondLanguage(e.target.value)
                                          }
                                          style={{
                                            width: "27%",
                                          }}
                                          autoFocus={false}
                                          onFocus={() => setFocusedInputKey(3)}
                                        />
                                        <TextField
                                          sx={{
                                            "& label.Mui-focused": {
                                              color: primaryColor,
                                            },
                                            "&:hover label": {
                                              color: primaryColor,
                                            },
                                            "& .MuiOutlinedInput-root": {
                                              fontSize: "15px",
                                              height: "40px",
                                              color: "black",
                                              "& fieldset": {
                                                borderColor: "grey",
                                              },
                                              "&:hover fieldset": {
                                                borderColor: primaryColor,
                                              },
                                              "&.Mui-focused fieldset": {
                                                borderColor: primaryColor,
                                              },
                                            },
                                          }}
                                          size="small"
                                          key={4}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: 16,
                                              // top: -7,

                                              "&.MuiInputLabel-shrink": {
                                                top: 0,
                                              },
                                              "&.Mui-focused": {
                                                color: primaryColor,
                                              },
                                            },
                                          }}
                                          label={lang.mainprice}
                                          value={mainPrice}
                                          onChange={(e) =>
                                            setMainPrice(e.target.value)
                                          }
                                          style={{
                                            width: "15%",
                                          }}
                                          autoFocus={false}
                                          onFocus={() => setFocusedInputKey(3)}
                                        />
                                      </Box>

                                      <div className="menu-list-container-edit-box-icons">
                                        <div
                                          className="menu-list-container-icon"
                                          onClick={() => handleSaveClicked()}
                                        >
                                          <i
                                            class="fa fa-check menu-list-fa-check"
                                            aria-hidden="true"
                                            style={{ fontSize: " 23px" }}
                                          ></i>
                                        </div>
                                        <div
                                          className="menu-list-container-icon"
                                          onClick={() => handleCloseIcon(item)}
                                        >
                                          <i
                                            class="fa fa-times menu-list-fa-times"
                                            aria-hidden="true"
                                            style={{ fontSize: " 23px" }}
                                          ></i>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="menu_list_item_container">
                                      <div
                                        className="menu_list_title_container"
                                        style={{
                                          marginRight: "-15px",
                                          display: "flex",
                                          alignItems: "center",
                                          paddingBottom: "18px",
                                        }}
                                        onClick={(i) => i.stopPropagation()}
                                      >
                                        <div
                                          style={{
                                            width: "65%",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            className="menu-list-continer-title"
                                            style={{
                                              width: "48%",
                                            }}
                                          >
                                            <h6
                                              style={{
                                                margin: 0,
                                              }}
                                            >
                                              {item.AppTitle}
                                            </h6>
                                          </div>
                                        </div>
                                        <div
                                          className="menu-list-continer-price-and-delete-and-edit-icons"
                                          style={{ width: "25%" }}
                                        >
                                          {item.AmountDelivery ? (
                                            <h6
                                              style={{
                                                margin: "0px 13px 0px 0px",
                                              }}
                                            >
                                              £{item.AmountDelivery}
                                            </h6>
                                          ) : null}

                                          <i
                                            class="fa fa-pencil"
                                            aria-hidden="true"
                                            onClick={() =>
                                              handleEditIconClick(item)
                                            }
                                          ></i>
                                          <div className="owners-menu-container-products-options">
                                            <p style={{ margin: 0 }}>
                                              {lang.options}
                                            </p>

                                            {item.showOptions ? (
                                              <i
                                                class="fa fa-caret-down"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  handleCloseOptions(item)
                                                }
                                              ></i>
                                            ) : (
                                              <i
                                                class="fa fa-caret-up"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  handleShowOptions(item)
                                                }
                                              ></i>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {item.showOptions
                                    ? productOptionList
                                      ? productOptionList.length > 0
                                        ? productOptionList.map(
                                            (element, index) => (
                                              <div
                                                style={{ padding: "0px 15px" }}
                                              >
                                                {element.showEditBox ? (
                                                  <div
                                                    className="menu_list_item_container box_shadow menu-list-container"
                                                    onClick={(i) =>
                                                      i.stopPropagation()
                                                    }
                                                  >
                                                    <Box
                                                      component="form"
                                                      // sx={{
                                                      //   "& > :not(style)": { m: 1, display: "flex" },
                                                      // }}

                                                      className="menu-list-container-inputs"
                                                      autoComplete="on"
                                                    >
                                                      {/* <TextField
                                      key={1}
                                      sx={{
                                        "& label.Mui-focused": {
                                          color: primaryColor,
                                        },
                                        "&:hover label": {
                                          color: primaryColor,
                                        },
                                        "& .MuiOutlinedInput-root": {
                                          fontSize: "15px",
                                          height: "40px",
                                          color: "black",

                                          "& fieldset": {
                                            borderColor: "grey",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: primaryColor,
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: primaryColor,
                                          },
                                        },
                                      }}
                                      size="small"
                                      label={lang.app_title}
                                      InputLabelProps={{
                                        sx: {
                                          fontSize: 16,
                                          // top: -7,
                                          "&.MuiInputLabel-shrink": { top: 0 },
                                          "&.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "& label.Mui-focused": {
                                            color: primaryColor,
                                          },
                                          "&:hover label": {
                                            color: primaryColor,
                                          },
                                        },
                                      }}
                                      value={appTitle}
                                      // defaultValue={number == 9 ? postCode : appTitle}
                                      onChange={(e) =>
                                        setAppTitle(e.target.value)
                                      }
                                      style={{
                                        width: "32%",
                                      }}
                                      autoFocus={
                                        focusedInputKey == 1 ? true : false
                                      }
                                      onFocus={() => setFocusedInputKey(1)}
                                    />*/}
                                                      <div className="product-list-product-option-edit-box-container-option-title">
                                                        {optionTitle}
                                                      </div>
                                                      <TextField
                                                        sx={{
                                                          "& label.Mui-focused":
                                                            {
                                                              color:
                                                                primaryColor,
                                                            },
                                                          "&:hover label": {
                                                            color: primaryColor,
                                                          },
                                                          "& .MuiOutlinedInput-root":
                                                            {
                                                              fontSize: "15px",
                                                              height: "40px",
                                                              color: "black",
                                                              "& fieldset": {
                                                                borderColor:
                                                                  "grey",
                                                              },
                                                              "&:hover fieldset":
                                                                {
                                                                  borderColor:
                                                                    primaryColor,
                                                                },
                                                              "&.Mui-focused fieldset":
                                                                {
                                                                  borderColor:
                                                                    primaryColor,
                                                                },
                                                            },
                                                        }}
                                                        size="small"
                                                        key={3}
                                                        InputLabelProps={{
                                                          sx: {
                                                            fontSize: 16,
                                                            // top: -7,

                                                            "&.MuiInputLabel-shrink":
                                                              {
                                                                top: 0,
                                                              },
                                                            "&.Mui-focused": {
                                                              color:
                                                                primaryColor,
                                                            },
                                                          },
                                                        }}
                                                        label={lang.mainprice}
                                                        value={optionPrice}
                                                        onChange={(e) =>
                                                          setOptionPrice(
                                                            e.target.value
                                                          )
                                                        }
                                                        style={{
                                                          width: "25%",
                                                        }}
                                                        autoFocus={false}
                                                        onFocus={() =>
                                                          setFocusedInputKey(3)
                                                        }
                                                      />
                                                    </Box>

                                                    <div className="menu-list-container-edit-box-icons">
                                                      <div
                                                        className="menu-list-container-icon"
                                                        onClick={() =>
                                                          handleSaveOption()
                                                        }
                                                      >
                                                        <i
                                                          class="fa fa-check menu-list-fa-check"
                                                          aria-hidden="true"
                                                          style={{
                                                            fontSize: " 23px",
                                                          }}
                                                        ></i>
                                                      </div>
                                                      <div
                                                        className="menu-list-container-icon"
                                                        onClick={() =>
                                                          handleCloseOptionEditBox(
                                                            element
                                                          )
                                                        }
                                                      >
                                                        <i
                                                          class="fa fa-times menu-list-fa-times"
                                                          aria-hidden="true"
                                                          style={{
                                                            fontSize: " 23px",
                                                          }}
                                                        ></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="menu_list_item_container">
                                                    <div
                                                      className="menu_list_title_container"
                                                      style={{
                                                        marginRight: "-15px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        paddingBottom: "18px",
                                                        backgroundColor:
                                                          "#dddddd",
                                                      }}
                                                      onClick={(i) =>
                                                        i.stopPropagation()
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          width: "65%",
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <div
                                                          className="menu-list-continer-title"
                                                          style={{
                                                            width: "80%",
                                                          }}
                                                        >
                                                          <h6
                                                            style={{
                                                              margin: 0,
                                                            }}
                                                          >
                                                            {element.AppTitle}
                                                          </h6>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="menu-list-continer-price-and-delete-and-edit-icons"
                                                        style={{ width: "15%" }}
                                                      >
                                                        {element.AmountDelivery ? (
                                                          <h6
                                                            style={{
                                                              margin:
                                                                "0px 13px 0px 0px",
                                                            }}
                                                          >
                                                            £
                                                            {
                                                              element.AmountDelivery
                                                            }
                                                          </h6>
                                                        ) : null}

                                                        <i
                                                          class="fa fa-pencil"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleEditOptionPrice(
                                                              element
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            )
                                          )
                                        : null
                                      : null
                                    : null}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            ) : null}
          </div>
          <AddMenuItem
            number={4}
            is_app={false}
            is_web={false}
            // empty={emptyInput}
            // setEmptyInput={(i) => setEmptyInput(i)}
            button_title={lang.addProduct}
            buttonClick={(t, d, a, is_web, is_app) =>
              handleOnclickButton(t, d, a, is_web, is_app)
            }
            result_successfull={result_successfull}
          />
        </>
      ) : (
        <>
          <div className="menu_list_header_container">
            {productList.length > 0 ? (
              <div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="list">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {productList.map((item, index) => (
                          <Draggable
                            key={item.Id}
                            draggableId={item.Id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {!item.IsDeleted ? (
                                  <MenuListItem
                                    field_number={2}
                                    item_title={item.AppTitle}
                                    item_price={item.AmountDelivery}
                                    field_1_title={item.AppTitle}
                                    field_2_title={`£${item.AmountDelivery}`}
                                    btn_number={3}
                                    bt_1_title={lang.edit}
                                    bt_1_click={() => {
                                      handleClick(item, 4);
                                      dispatch(clickedItemAction(4));
                                    }}
                                    bt_2_title={lang.options}
                                    bt_2_click={() => {
                                      handleClick(item, 5);
                                      dispatch(
                                        selectedProductOptionListAction(null)
                                      );
                                      dispatch(clickedItemAction(5));
                                    }}
                                    bt_3_title={lang.subMenu}
                                    bt_3_click={() => {
                                      handleClick(item, 6);
                                      dispatch(
                                        selectedProductSubmenuAction(null)
                                      );
                                      dispatch(clickedItemAction(6));
                                    }}
                                    show_delete={true}
                                    delete_click={() => handleDeleted(item)}
                                    show_delete_box={() =>
                                      handleShowDeleteBox(item)
                                    }
                                    cancel_delete_box={() =>
                                      handleCancelDeleteBox(item)
                                    }
                                    show_edit_icon={true}
                                    selected={selected_Product}
                                    item={item}
                                    click_edit_icon={() =>
                                      handleEditIconClick(item)
                                    }
                                    click_close_icon={() =>
                                      handleCloseIcon(item)
                                    }
                                    on_blur={() =>
                                      handleCloseEditBoxWithOnBlur()
                                    }
                                    number={3}
                                  />
                                ) : null}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            ) : (
              // <div></div>
              <h2 className="no-option" id="noOption">
                {lang.noOption}
              </h2>
            )}
          </div>
          <AddMenuItem
            // empty={emptyInput}
            // setEmptyInput={(i) => setEmptyInput(i)}
            number={4}
            is_app={false}
            is_web={false}
            button_title={lang.addProduct}
            buttonClick={(t, d, a, is_web, is_app) =>
              handleOnclickButton(t, d, a, is_web, is_app)
            }
            result_successfull={result_successfull}
          />
        </>
      )}
    </div>
  );
};

export default ProductsList;
