import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  getMetaDataListAction,
  updateMetaDataAction,
} from "../../../../redux/settings/Action";
import { setDefaultAction } from "../../../../redux/menu/Actions";
const Meta = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { meta_data_list, result_message, result_successfull, result_failed } =
    useSelector((state) => state.appSettingsRedusers);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeyWords, setMetaKeyWords] = useState("");

  useImperativeHandle(forwardedRef, () => ({
    saveMetaData: () => {
      handleEditMetaData();
    },
  }));
  const handleEditMetaData = () => {
    if (metaTitle == "" || metaDesc == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateMetaDataAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          metaTitle,
          metaDesc,
          metaKeyWords ? metaKeyWords : ""
        )
      );
    }
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getMetaDataListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (meta_data_list) {
      if (meta_data_list.length > 0) {
        if (meta_data_list[0].MetaTitle) {
          setMetaTitle(meta_data_list[0].MetaTitle);
        } else {
          setMetaTitle("");
        }
        if (meta_data_list[0].MetaDescription) {
          setMetaDesc(meta_data_list[0].MetaDescription);
        } else {
          setMetaDesc("");
        }
        if (meta_data_list[0].MetaKey) {
          setMetaKeyWords(meta_data_list[0].MetaKey);
        } else {
          setMetaKeyWords("");
        }
      } else {
        setMetaTitle("");
        setMetaKeyWords("");
        setMetaDesc("");
      }
    } else {
      setMetaTitle("");
      setMetaKeyWords("");
      setMetaDesc("");
    }
  }, [meta_data_list]);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "46%" }}>
              <h6 className="h6">* {lang.meta_title}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={metaTitle}
                onChange={(e) => setMetaTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "46%" }}>
              <h6 className="h6">* {lang.meta_desc}</h6>
              <textarea
                className="input-padding meta-textarea"
                value={metaDesc}
                onChange={(e) => setMetaDesc(e.target.value)}
                rows={6}
              />
            </div>
            <div className="ASOP-div1-input-text" style={{ width: "46%" }}>
              <h6 className="h6">
                {lang.meta_keywords}
                <span className="meta-keywords-example-text">
                  {" "}
                  (example : test1 , test2 , ...)
                </span>
              </h6>
              <textarea
                className="input-padding meta-textarea"
                value={metaKeyWords}
                onChange={(e) => setMetaKeyWords(e.target.value)}
                rows={6}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Meta;
