import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";
import ClipLoader from "react-spinners/ClipLoader";
import { priceLevel } from "../constants/PriceLevel";
import Loader from "../components/Loader";
import { primaryColor } from "../constants/Colors";
import { showProductSubmenuListPopup } from "../redux/product/Action";
import { getSubmenuAction } from "../redux/submenu/Action";
import { addProductSubmenuAction } from "../redux/product/Action";

const ProductSubmenuListPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useScrollbarSize();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { submenu_list, loding_submenu_popup } = useSelector(
    (state) => state.submenuRedusers
  );
  const { product_list, selected_Product } = useSelector(
    (state) => state.productList
  );

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [subMenuList, setSubMenuList] = useState([]);
  const [selectedSubmenuIds, setSelectedSubmenuIds] = useState([]);

  const handleSelectItem = (item) => {
    let copy_list = [...subMenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (copy_list[i].Id == item.Id) {
        if (copy_list[i].selected) {
          copy_list[i].selected = false;
          let new_list = selectedSubmenuIds.filter(
            (i) => parseInt(i) != parseInt(item.Id)
          );

          setSelectedSubmenuIds(new_list);
        } else {
          copy_list[i].selected = true;
          selectedSubmenuIds.push(String(item.Id));
        }
      }
    }

    setSubMenuList(copy_list);
  };

  const handleAddSubmenu = () => {
    setSubMenuList([]);
    setSelectedSubmenuIds([]);
    dispatch(showProductSubmenuListPopup(false));
    dispatch(
      addProductSubmenuAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        selectedSubmenuIds.toString(),
        selected_Product.Id
      )
    );
  };
  const handleCancel = () => {
    setSubMenuList([]);
    setSelectedSubmenuIds([]);
    dispatch(showProductSubmenuListPopup(false));
  };

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (submenu_list.length > 0) {
      setSubMenuList(submenu_list);
    }
  }, [submenu_list]);

  useEffect(() => {
    dispatch(
      getSubmenuAction(
        login_key,
        user_id,
        selected_Product.ShopId,
        selected_Product.Id
      )
    );
  }, []);

  return (
    <div className="sub_menu_list_popup_container">
      <div className="submenu_popup_buttons_list_container product-submenu-list-popup-first-div">
        <div className="submenu_popup_list_container">
          {subMenuList.map((item, index) => (
            <div key={index}>
              {!item.IsDeleted ? (
                <div
                  className="product_submenu_popup_list_container"
                  style={{
                    backgroundColor: item.InProductSubmenu ? "#bdbdbd" : null,
                  }}
                >
                  {item.AppTitle}
                  <button
                    onClick={() => handleSelectItem(item)}
                    style={{
                      backgroundColor: item.selected ? primaryColor : null,
                      color: item.selected ? "white" : null,
                    }}
                  >
                    {lang.select}
                  </button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <div className="submenu_popup_buttons_container">
          <div
            style={{
              marginRight: 16 + width,
            }}
          >
            <button id="popup_cancel" onClick={handleCancel}>
              {lang.cancel}
            </button>
            <button
              disabled={selectedSubmenuIds.length > 0 ? false : true}
              onClick={handleAddSubmenu}
              id="popup_add"
            >
              {lang.add}
            </button>
          </div>
        </div>
        {loding_submenu_popup ? <Loader /> : null}
      </div>
    </div>
  );
};

export default ProductSubmenuListPopup;
