import React, { useState, useRef, useEffect } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import Switch from "@mui/material/Switch";
import useScrollbarSize from "react-scrollbar-size";
import { useDispatch, useSelector } from "react-redux";
import { LanguageSharp } from "@material-ui/icons";
import { Navigate, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import { red } from "@mui/material/colors";
import store from "store2";
import { alpha, styled } from "@mui/material/styles";
import CategoryEdit from "../../components/CategoryEdit";
import ProductsListStatus from "../../components/ProductsListStatus";
import CategoryOptions from "../../components/CategoryOptions";
import ProductsOptions from "../../components/ProductsOptions";
import ProductOptionEdit from "../../components/ProductOptionEdit";
import ProductsEdit from "../../components/ProductsEdit";
import ProductSubmenu from "../../components/ProductSubmenu";
import { padding_menu_list_outer } from "../../constants/Sizes";
import OptionsList from "../../components/OptionsList";
import ProductSubmenuEdit from "../../components/ProductSubmenuEdit";
import CategoryOptionCrustList from "../../components/CategoryOptionCrustList";
import CategoryOptionCrustEdit from "../../components/CategoryOptionCrustEdit";
import { receiveLoginResponse, loginAction } from "../../redux/login/Actions";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
} from "../../constants/Colors";
import { items } from "./itemDropdown";
import Loader from "../../components/Loader";

import {
  createCategoryAction,
  selectedCategoryAction,
  getCategoriesListAction,
  getCategoriesSuccessfulAction,
  getDropDownAction,
  deleteCategoryAction,
  setDefaultAction,
  handleEmterKeyAction,
  setSelectedCategoryOptionAction,
  editCategoryStatusAction,
  updateCategorySortAction,
  updateCategoryAppSortAction,
  changeSortLableAction,
} from "../../redux/menu/Actions";
import { getShopsListAction } from "../../redux/shop/Actions";
import {
  selectedProductAction,
  getProductAction,
} from "../../redux/product/Action";
import MobileProductsList from "./mobileComponent/MobileProductList";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BsAndroid2 } from "react-icons/bs";
import { FaInternetExplorer } from "react-icons/fa";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
const usefullScreenPadding = (window.innerWidth * 2) / 100;

const MenuStatus = () => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      closeOnOutsideClick: true,
    });
  const catRef = useRef();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useScrollbarSize();
  const myRef = useRef(null);
  const { result_login_successfull, refresh_login_loader } = useSelector(
    (state) => state.loginReducer
  );
  const { product_list } = useSelector((state) => state.productList);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    category_list,
    change_sort_label,
    selected_category,
    show_hide,
    printer_list,
    report_section,
    product_group,
    vat,
    loading_category_settings,
    product_open_close_result_successfull,
    get_category_list_loading,
    result_successfull,
    result_failed,
    result_message,
    loading_category,
    handle_enter_key,
    result_create_successfull,
    new_category_id,
  } = useSelector((state) => state.categoryList);

  const user_access = store.get("userAccess");
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const [categoryName, setCategoryName] = useState("");
  const [id, setId] = useState(0);
  const [item, setItem] = useState("");
  const [category, setCategory] = useState([]);
  const [category1, setCategory1] = useState([]);
  const [clickedItem, setClickedItem] = useState(2);
  const [clickedItem1, setClickedItem1] = useState(1);
  const [controlInputFocuse, setControlInputFocuse] = useState(false);
  const [productListInMenuStatus, setProductListInMenuStatus] = useState([]);
  let [color, setColor] = useState("red");
  const [sortOnWebOrApp, setSortOnWebOrApp] = useState(1);

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: primaryColor,
      "&:hover": {
        backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: primaryColor,
    },
  }));
  const handleCollapseItemInMobile = (item, index) => {
    dispatch(selectedCategoryAction(item));

    setClickedItem1(2);
    const copy_category = [...category];
    for (let i = 0; i < copy_category.length; i++) {
      if (index == i) {
        copy_category[i].collapse = !item.collapse;
      } else {
        copy_category[i].collapse = false;
      }

      dispatch(getCategoriesSuccessfulAction(copy_category));
    }
  };
  const handleCollapseItem = (item, index) => {
    dispatch(selectedCategoryAction(item));

    setClickedItem(2);
    const copy_category = [...category];
    for (let i = 0; i < copy_category.length; i++) {
      if (index == i) {
        copy_category[i].collapse = !item.collapse;
      } else {
        copy_category[i].collapse = false;
      }

      dispatch(getCategoriesSuccessfulAction(copy_category));
    }
  };
  const hanleEditCategoeyVisible = (item) => {
    setProductListInMenuStatus([]);
    const copy_category = [...category];
    if (copy_category) {
      if (copy_category.length > 0) {
        for (let i = 0; i < copy_category.length; i++) {
          if (copy_category[i].Id == item.Id) {
            if (item.WebVisible[0] == "0") {
              item.WebVisible[0] = "5";
            } else {
              item.WebVisible[0] = "0";
            }
          }
          dispatch(
            editCategoryStatusAction(
              login_key,
              user_id,
              item.ShopId,
              item.Id,
              item.WebVisible[0].toString()
            )
          );
        }
      }
    }
    setCategory(copy_category);
  };
  const handleDragEnd = (result) => {
    const copy_list = [...category];
    // dispatch(changeSortLableAction(true));
    if (sortOnWebOrApp == 1) {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setCategory(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];

      dispatch(
        updateCategorySortAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          previous_item.Id,
          previous_item.WebSortId,
          new_item.WebSortId
        )
      );
    } else {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setCategory(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];

      dispatch(
        updateCategoryAppSortAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          previous_item.Id,
          previous_item.AppSortId,
          new_item.AppSortId
        )
      );
    }
  };
  const handleSortButtons = (key) => {
    dispatch(changeSortLableAction(true));
    if (key == 1) {
      setSortOnWebOrApp(1);
    } else {
      setSortOnWebOrApp(2);
    }
  };
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        if (controlInputFocuse == true && categoryName != "") {
          dispatch(
            createCategoryAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              categoryName.replaceAll(`"`, `\"\"`)
            )
          );
        }
      }
      // setControlInputFocuse(handle_enter_key)
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [categoryName]);

  useEffect(() => {
    if (category_list.length > 0) {
      setCategory(category_list);
    } else {
      setCategory([]);
      dispatch(selectedCategoryAction(null));
    }
  }, [category_list]);

  useEffect(() => {
    if (selected_shop) {
      if (selected_shop != "S") {
        if (selected_shop.length > 0) {
          //   inputRef.current.focus();
          dispatch(
            getCategoriesListAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              0
            )
          );
        }
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (result_successfull || product_open_close_result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      setCategoryName("");
      document.getElementById("");
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [
    result_successfull,
    result_failed,
    product_open_close_result_successfull,
  ]);

  const loging_response = store.get("login_response");

  useEffect(() => {
    if (category) {
      if (category1) {
        if (category1.length > 0) {
          if (category.length > 0) {
            if (category.length > category1.length) {
              document.getElementById("idd").scroll({
                top: document.querySelector("#idd").scrollHeight,
                behavior: "smooth",
              });
              setCategory1([]);
            } else if (category.length < category1.length) {
              document.getElementById("idd").scroll({
                top: 0,
                behavior: "smooth",
              });
              setCategory1([]);
            }
          }
        }
      }
    }
  }, [category]);
  useEffect(() => {
    if (product_list) {
      if (product_list.length > 0) {
        setProductListInMenuStatus(product_list);
      }
    }
  }, [product_list]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  useEffect(() => {
    const copy_category_list = [...category_list];
    if (sortOnWebOrApp == 1) {
      const sortArrey = copy_category_list.sort(function (a, b) {
        return a.WebSortId - b.WebSortId;
      });

      if (change_sort_label) {
        dispatch(changeSortLableAction(false));
        dispatch(selectedCategoryAction(copy_category_list[0]));
        for (let i = 0; i < copy_category_list.length; i++) {
          copy_category_list[i].collapse = i == 0 ? true : false;
        }
      }
      setCategory(sortArrey);
    } else {
      const sortArrey = copy_category_list.sort(function (a, b) {
        return a.AppSortId - b.AppSortId;
      });

      if (change_sort_label) {
        dispatch(changeSortLableAction(false));
        dispatch(selectedCategoryAction(sortArrey[0]));
        for (let i = 0; i < sortArrey.length; i++) {
          sortArrey[i].collapse = i == 0 ? true : false;
        }
      }
      setCategory(sortArrey);
    }
  }, [sortOnWebOrApp, category_list, change_sort_label]);

  return width1 <= 560 ? (
    <div
      className="c-div container-menu-status"
      // style={{
      //   height: window.innerHeight - 160,
      // }}
    >
      {clickedItem1 == 1 ? (
        <div className="mobile-s-div">
          <div ref={myRef} id="idd" className="menu_list_header_container">
            <div className="categort_header_container">
              <div>
                <h6>{lang.category_list}</h6>
              </div>
              <div className="category-list-header-cotainer-switch-buttons">
                <button
                  onClick={() => handleSortButtons(1)}
                  style={{
                    backgroundColor: sortOnWebOrApp == 1 ? primaryColor : null,
                    color: sortOnWebOrApp == 1 ? white : null,
                  }}
                >
                  <i class="fa fa-internet-explorer" aria-hidden="true"></i>{" "}
                  {lang.web_sort}
                </button>
                <button
                  onClick={() => handleSortButtons(2)}
                  style={{
                    backgroundColor: sortOnWebOrApp == 2 ? primaryColor : null,
                    color: sortOnWebOrApp == 2 ? white : null,
                  }}
                >
                  <i class="fa fa-android" aria-hidden="true"></i>{" "}
                  {lang.app_sort}
                </button>
              </div>
            </div>
            {!get_category_list_loading ? (
              <div>
                {category.length > 0 ? (
                  <div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="list">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {category.map((item, index) => (
                              <Draggable
                                key={item.Id}
                                draggableId={item.Id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {item.IsDeleted ? null : (
                                      <div>
                                        <div className="category_list_container">
                                          <div
                                            onClick={() =>
                                              handleCollapseItemInMobile(
                                                item,
                                                index
                                              )
                                            }
                                            className="menu-div"
                                            style={{
                                              height: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? 50
                                                  : 50
                                                : 50,
                                              backgroundColor: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? main_nav_back_color
                                                  : white
                                                : null,
                                              borderWidth: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? 0
                                                  : 1
                                                : 1,
                                              padding: "2px 0px 2px 10px",
                                            }}
                                          >
                                            <p
                                              className="category_title"
                                              style={{
                                                width: "86%",
                                              }}
                                            >
                                              {item.AppTitle}
                                            </p>
                                            <GreenSwitch
                                              checked={
                                                item.WebVisible[0] == "0"
                                                  ? false
                                                  : true
                                              }
                                              onChange={(i) => {
                                                hanleEditCategoeyVisible(item);
                                                setClickedItem1(1);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {/* {category.map((item, index) => (
                      <div key={index}>
                        {item.IsDeleted ? null : (
                          <div>
                            <div className="category_list_container">
                              <div
                                onClick={() =>
                                  handleCollapseItemInMobile(item, index)
                                }
                                className="menu-div"
                                style={{
                                  height: selected_category
                                    ? selected_category.Id == item.Id
                                      ? 50
                                      : 50
                                    : 50,
                                  backgroundColor: selected_category
                                    ? selected_category.Id == item.Id
                                      ? main_nav_back_color
                                      : null
                                    : null,
                                  borderWidth: selected_category
                                    ? selected_category.Id == item.Id
                                      ? 0
                                      : 1
                                    : 1,
                                  padding: "2px 0px 2px 10px",
                                }}
                              >
                                <p
                                  className="category_title"
                                  style={{
                                    width: "86%",
                                  }}
                                >
                                  {item.AppTitle}
                                </p>
                                <GreenSwitch
                                  checked={
                                    item.WebVisible[0] == "0" ? false : true
                                  }
                                  onChange={(i) => {
                                    hanleEditCategoeyVisible(item);
                                    setClickedItem1(1);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))} */}
                  </div>
                ) : (
                  <p className="add-p">{lang.NoOptions_title}</p>
                )}
              </div>
            ) : null}
            {/* ADD BUTTON */}
          </div>

          {get_category_list_loading ? <Loader /> : null}
        </div>
      ) : (
        <MobileProductsList
          setClickedItem1={(i) => setClickedItem1(i)}
          productListInMenuStatus={productListInMenuStatus}
        />
      )}
      {loading_category ? <Loader /> : null}
    </div>
  ) : (
    <div
      className="c-div container-menu-status"
      // style={{
      //   height: window.innerHeight - 160,
      // }}
    >
      <div className="s-div">
        <div ref={myRef} id="idd" className="menu_list_header_container">
          <div className="categort_header_container">
            <div>
              <h6>{lang.category_list}</h6>
            </div>
            <div className="category-list-header-cotainer-switch-buttons">
              <button
                onClick={() => handleSortButtons(1)}
                style={{
                  backgroundColor: sortOnWebOrApp == 1 ? primaryColor : null,
                  color: sortOnWebOrApp == 1 ? white : null,
                }}
              >
                {/* <i class="fa fa-internet-explorer" aria-hidden="true"></i>{" "} */}
                <FaInternetExplorer style={{ margin: "0px 2px 4px 0px" }} />
                {lang.web}
              </button>
              <button
                onClick={() => handleSortButtons(2)}
                style={{
                  backgroundColor: sortOnWebOrApp == 2 ? primaryColor : null,
                  color: sortOnWebOrApp == 2 ? white : null,
                }}
              >
                {/* <i class="fa fa-android" aria-hidden="true"></i>{" "} */}

                <BsAndroid2 style={{ margin: "0px 2px 4px 0px" }} />
                {lang.app}
              </button>
            </div>
          </div>
          {!get_category_list_loading ? (
            <div>
              {category.length > 0 ? (
                <div>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {category.map((item, index) => (
                            <Draggable
                              key={item.Id}
                              draggableId={item.Id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {item.IsDeleted ? null : (
                                    <div>
                                      <div className="category_list_container">
                                        <div
                                          onClick={() =>
                                            handleCollapseItem(item, index)
                                          }
                                          className="menu-div"
                                          style={{
                                            height: selected_category
                                              ? selected_category.Id == item.Id
                                                ? 50
                                                : 50
                                              : 50,
                                            backgroundColor: selected_category
                                              ? selected_category.Id == item.Id
                                                ? main_nav_back_color
                                                : white
                                              : null,
                                            borderWidth: selected_category
                                              ? selected_category.Id == item.Id
                                                ? 0
                                                : 1
                                              : 1,
                                            padding: "2px 0px 2px 10px",
                                          }}
                                        >
                                          <p
                                            className="category_title"
                                            style={{
                                              width: "86%",
                                            }}
                                          >
                                            {item.AppTitle}
                                          </p>
                                          <GreenSwitch
                                            checked={
                                              item.WebVisible[0] == "0"
                                                ? false
                                                : true
                                            }
                                            onChange={(i) =>
                                              hanleEditCategoeyVisible(item)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : (
                <p className="add-p">{lang.NoOptions_title}</p>
              )}
            </div>
          ) : null}
        </div>

        {get_category_list_loading ? <Loader /> : null}
      </div>

      <div className="Menu-div_countainer-component">
        {clickedItem == 2 ? (
          <ProductsListStatus
            setClickedItem={(i) => setClickedItem(i)}
            productListInMenuStatus={productListInMenuStatus}
          />
        ) : null}
        {loading_category ? <Loader /> : null}
      </div>

      <Toaster />
    </div>
  );
};

export default MenuStatus;
