import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { alpha, styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { usePopperTooltip } from "react-popper-tooltip";
import validator from "validator";
import { createTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import "react-popper-tooltip/dist/styles.css";
import { SketchPicker } from "react-color";
import {
  changeSelectedButtonBorderColorOnFocus,
  sendCategoryOptionAction,
  updateCategoryOptionCrustAction,
  justTitleAndPriceAction,
  sendAllItemsToAppAction,
} from "../redux/menu/Actions";
import {
  updateSubmenuAction,
  updateSubmenuItemEditAction,
} from "../redux/submenu/Action";
import { primaryColor, white } from "../constants/Colors";
import {
  sendProductSettingsAction,
  getProductAction,
  updateProductSubmenuEditAction,
  sendProductOptionEditAction,
} from "../redux/product/Action";
import {
  updateAllowedPostCodeAction,
  updateAllowedPostCodeFOFAction,
  updateByAreaAction,
} from "../redux/GeneralSetting/Actions";
import { updatePublicSubItemAction } from "../redux/MainNavGeneral/Actions";
const MenuListItem = ({
  item_title,
  item_price,
  item_mobile,
  item_id,
  item_lastName,
  btn_number,
  bt_1_title,
  bt_1_click,
  bt_2_title,
  bt_2_click,
  bt_3_title,
  bt_3_click,
  bt_4_title,
  bt_4_click,
  delete_click,
  show_delete,
  show_edit_icon,
  without_key,
  selected,
  item,
  number,
  click_edit_icon,
  click_close_icon,
  on_blur,
  show_delete_box,
  cancel_delete_box,
  delivery_charge,
  delivery_min_charge,
  promotiom_status_oncgange,
  show_status,
  item_uniqe_id,
  exclude_meal_deals_onchange,
  custom_delivery_charge,
  show_id,
  active,
  field_number,
  field_1_title,
  field_2_title,
  field_3_title,
  field_4_title,
  field_5_title,
  field_6_title,
  field_2_click,
  field_3_click,
  show_field_icon,
}) => {
  const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: "white",
          },

          "&$focused $notchedOutline": {
            borderColor: "white",
          },
        },
        notchedOutline: {
          borderColor: "white",
        },
      },
    },
  });
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: primaryColor,
      "&:hover": {
        backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: primaryColor,
    },
  }));
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      closeOnOutsideClick: true,
    });
  const dispatch = useDispatch();
  const {
    change_selected_button_border_color_on_focus,
    clicked_item,
    selected_item_in_all_lists,
    result_successfull,
    selected_category,
    just_title_and_price,
    send_all_items_to_app,
  } = useSelector((state) => state.categoryList);
  const { lang } = useSelector((state) => state.selectedLanguage);

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const [backgroundColor, setBackgroundColor] = useState("#14aeba");
  const [appTitle, setAppTitle] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [mainPrice, setMainPrice] = useState(0);
  const [focusedInputKey, setFocusedInputKey] = useState(1);
  const [postCode, setPostCode] = useState("");
  const [deliveyCharge, setDeliveyCharge] = useState(0);
  const [deliveyMinCharge, setDeliveyMinCharge] = useState(0);
  const [minSelect, setMinSelect] = useState(1);
  const [maxSelect, setMaxSelect] = useState(1);
  const [desc, setDesc] = useState("");
  const [areaName, setAreaName] = useState("");
  const [showSketchPicker, setShowSketchPicker] = useState(false);
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "15px",
      height: "40px",
      color: "black",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });

  const handleEditBox = async () => {
    await click_edit_icon();
  };

  const formData = new FormData();
  const formData1 = new FormData();
  const formData2 = new FormData();
  const handleSaveClicked = () => {
    if (number == 3) {
      if (appTitle == "") {
        toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("Id", selected_item_in_all_lists.Id);
        formData.append("ShopId", selected_shop[0].ShopId);
        formData.append("AppTitle", appTitle);
        formData.append("AppDescription", "");
        formData.append(
          "WebTitle",
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`)
        );
        formData.append("WebDescription", "");
        formData.append("KioskTitle", "");
        formData.append("KioskDescription", "");
        formData.append("SecondLanguage", "");
        formData.append("AppVisible", "");
        formData.append("WebVisible", "");
        formData.append("KioskVisible", "");
        formData.append("AmountDelivery", mainPrice ? mainPrice : 0);
        formData.append("AmountCollection", 0);
        formData.append("AmountTakeaway", 0);
        formData.append("AmountEatIn", 0);
        formData.append("PrinterId", "");
        formData.append("ReportSection", 0);
        formData.append("IsShowOptions", 0);
        formData.append("ProductGroup", 0);
        formData.append("VatId", 0);
        formData.append("IsSugessted", 0);
        formData.append("IsPopular", 0);
        formData.append("IsDifOptionVat", 0);
        formData.append("UnitId", 0);
        formData.append("Barcode", "");
        formData.append("CustomTitle", "");
        formData.append("CloseCount", 0);
        formData.append("CustomTitleInLast", 0);
        formData.append("TitleInLast", 0);
        formData.append("linkto", 0);
        formData.append("IsAutoDisplaySubMenu", 0);
        formData.append("PrintLabel", 0);
        formData.append("AppSortId", 0);
        formData.append("WebSortId", 0);
        formData.append("KioskSortId", 0);
        formData.append("AppBgColor", backgroundColor);
        formData.append("AppTitleFontSize", 0);
        formData.append("AppTitleFontFamily", "");
        formData.append("AppTitleColor", "");
        formData.append("NumberOfSubmenusColumn", 4);
        formData.append("NumberOfSubmenusRow", 6);
        // formData.append("AppTileWidth", 0);
        // formData.append("AppTileHeight", 0);
        formData.append("AppImageUrl", "");
        formData.append("AppVisibleImage", 0);
        formData.append("WebImageUrl", "");
        formData.append("WebVisibleImage", 0);
        formData.append("KioskImageUrl", "");
        formData.append("KioskVisibleImage", 0);
        formData.append("SetDefaultVatForAllOptions", 0);
        formData.append("LastAppImageUrlName", "");
        formData.append("LastWebImageUrlName", "");
        formData.append("NumberOfItemsColumn", 4);
        formData.append("NumberOfItemsRow", 6);
        formData.append("JustTitleAndPrice", 1);
        formData.append("SubmenusFontSize", 9);
        formData.append("Calories", 0);
        formData.append("AlwaysOpen", 0);
        formData.append("DeleteImage", 0);
        formData.append("FixedItemId", 0);
        dispatch(
          sendProductSettingsAction(
            formData,
            selected_category.Id,
            login_key,
            user_id,
            selected_item_in_all_lists.Id,
            selected_category.ShopId,
            1,
            mainPrice
          )
        );
      }
    } else if (number == 4) {
      if (appTitle == "") {
        toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else {
        formData1.append("LoginKey", login_key);
        formData1.append("UserId", user_id);
        formData1.append("Id", selected_item_in_all_lists.Id);
        formData1.append("ShopId", selected_item_in_all_lists.ShopId);
        formData1.append("ProductId", selected_item_in_all_lists.ProductId);
        formData1.append("SubMenuId", selected_item_in_all_lists.SubMenuId);
        formData1.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
        formData1.append("AppDescription", "");
        formData1.append(
          "WebTitle",
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`)
        );
        formData1.append("WebDescription", "");
        formData1.append("KioskTitle", "");
        formData1.append("KioskDescription", "");
        formData1.append("SecondLanguage", "");
        formData1.append("IsFree", 0);
        formData1.append("Amount", mainPrice ? mainPrice : 0);
        formData1.append("MinSelect", 0);
        formData1.append("MaxSelect", 0);
        formData1.append("AppVisible", 0);
        formData1.append("WebVisible", 0);
        formData1.append("KioskVisible", 0);
        formData1.append("AppSortId", 0);
        formData1.append("WebSortId", 0);
        formData1.append("KioskSortId", 0);
        formData1.append("AppBgColor", "");
        formData1.append("AppTitleFontSize", 0);
        formData1.append("AppTitleFontFamily", "");
        formData1.append("AppTitleColor", "");
        formData1.append("AppTileWidth", 0);
        formData1.append("AppTileHeight", 0);
        formData1.append("IsFrist", 0);
        formData1.append("PrinterId", "");
        formData1.append("IsAfter", 0);
        formData1.append("CountItemForUseAmount", 0);
        formData1.append("AppImageUrl", "");
        formData1.append("AppVisibleImage", 0);
        formData1.append("WebImageUrl", "");
        formData1.append("WebVisibleImage", 0);
        formData1.append("KioskImageUrl", "");
        formData1.append("KioskVisibleImage", 0);
        formData1.append("OneOfEachItem", 0);

        formData1.append("LastAppImageUrlName", "");
        formData1.append("LastWebImageUrlName", "");
        formData1.append("JustTitleAndPrice", 1);
        formData1.append("DeleteImage", 0);
        dispatch(
          updateProductSubmenuEditAction(
            formData1,
            login_key,
            user_id,
            selected_item_in_all_lists.Id,
            selected_item_in_all_lists.ShopId,
            selected_item_in_all_lists.ProductId,
            selected_item_in_all_lists.SubMenuId
          )
        );
      }
    } else if (number == 5) {
      dispatch(
        sendProductOptionEditAction(
          login_key,
          user_id,
          selected_item_in_all_lists.Id,
          selected_item_in_all_lists.ShopId,
          selected_item_in_all_lists.ProductId,
          selected_item_in_all_lists.OptionId,
          appTitle.replaceAll(`"`, `\"\"`),
          webTitle.replaceAll(`"`, `\"\"`),
          "",
          mainPrice ? parseFloat(mainPrice) : 0,
          0,
          0,
          0,
          0,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          selected_category.Id,
          0
        )
      );
    } else if (number == 1) {
      if (appTitle == "") {
        toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else
        dispatch(
          sendCategoryOptionAction(
            login_key,
            user_id,
            selected_item_in_all_lists.Id,
            selected_item_in_all_lists.ShopId,
            selected_item_in_all_lists.CategoryId,
            appTitle.replaceAll(`"`, `\"\"`),
            "",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`),
            "",
            "",
            "",
            "",
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            "",
            "",
            1,
            mainPrice ? parseFloat(mainPrice) : 0,
            0,
            0,
            0
          )
        );
    } else if (number == 6) {
      if (appTitle == "") {
        toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else
        dispatch(
          updateCategoryOptionCrustAction(
            login_key,
            user_id,
            selected_item_in_all_lists.Id,
            selected_item_in_all_lists.ShopId,
            selected_item_in_all_lists.OptionId,
            appTitle.replaceAll(`"`, `\"\"`),
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`),
            "",
            "",
            "",
            "",
            mainPrice ? parseFloat(mainPrice) : 0,
            0,
            0,
            0,
            0,
            0,
            0,
            "",
            "",
            0,
            0,
            1
          )
        );
    } else if (number == 2) {
      if (appTitle == "") {
        toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else
        dispatch(
          updateSubmenuAction(
            login_key,
            user_id,
            selected_item_in_all_lists.Id,
            selected_item_in_all_lists.ShopId,
            appTitle.replaceAll(`"`, `\"\"`),
            "",
            webTitle
              ? webTitle.replaceAll(`"`, `\"\"`)
              : appTitle.replaceAll(`"`, `\"\"`),
            "",
            "",
            "",
            "",
            minSelect ? parseInt(minSelect) : 0,
            maxSelect ? parseInt(maxSelect) : 0,
            0,
            0,
            0,
            0,
            0,
            "",
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1,
            4,
            6,
            9,
            0
          )
        );
    } else if (number == 7) {
      if (appTitle == "") {
        toast.error(<h5> &nbsp;&nbsp;{lang.fill_app_title}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else {
        formData2.append("LoginKey", login_key);
        formData2.append("UserId", user_id);
        formData2.append("Id", selected_item_in_all_lists.Id);
        formData2.append("ShopId", selected_item_in_all_lists.ShopId);
        formData2.append("SubMenuId", selected_item_in_all_lists.SubMenuId);
        formData2.append("PriceLevelCollTake", 0);
        formData2.append("PriceLevelEatIn", 0);
        formData2.append("PriceLevelMain", 0);
        formData2.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
        formData2.append("AppDescription", "");
        formData2.append(
          "WebTitle",
          webTitle
            ? webTitle.replaceAll(`"`, `\"\"`)
            : appTitle.replaceAll(`"`, `\"\"`)
        );
        formData2.append("WebDescription", "");
        formData2.append("KioskTitle", "");
        formData2.append("KioskDescription", "");
        formData2.append("SecondLanguage", "");
        formData2.append("AmountMain", mainPrice ? parseFloat(mainPrice) : 0);
        formData2.append("AmountCollection", 0);
        formData2.append("AmountTakeaway", 0);
        formData2.append("AmountEatIn", 0);
        formData2.append("AppVisible", 0);
        formData2.append("WebVisible", 0);
        formData2.append("KioskVisible", 0);
        formData2.append("AppBgColor", "");
        formData2.append("AppTitleFontSize", 30);
        formData2.append("AppTitleFontFamily", String(0));
        formData2.append("AppTitleColor", "");
        formData2.append("VatId", 0);
        formData2.append("IsDifOptionVat", 0);
        formData2.append("UnitId", 0);
        formData2.append("Barcode", "");
        formData2.append("Stock", 0);
        formData2.append("CustomTitle", "");
        formData2.append("CloseCount", 0);
        formData2.append("PrinterId", "");
        formData2.append("ReportSection", 0);
        formData2.append("ProductGroup", 0);
        formData2.append("AppTileWidth", 0);
        formData2.append("AppTileHeight", 0);
        formData2.append("Linkto", 0);
        formData2.append("AppSortId", 0);
        formData2.append("WebSortId", 0);
        formData2.append("KioskSortId", 0);
        formData2.append("AppImageUrl", "");
        formData2.append("AppVisibleImage", 0);
        formData2.append("WebImageUrl", "");
        formData2.append("WebVisibleImage", 0);
        formData2.append("KioskImageUrl", "");
        formData2.append("KioskVisibleImage", 0);
        formData2.append("LastAppImageUrlName", "");
        formData2.append("JustTitleAndPrice", 1);
        formData2.append("DeleteImage", 0);
        dispatch(
          updateSubmenuItemEditAction(
            formData2,
            login_key,
            user_id,
            selected_item_in_all_lists.Id,
            selected_item_in_all_lists.ShopId,
            selected_item_in_all_lists.SubMenuId
          )
        );
      }
    } else if (number == 9) {
      if (postCode == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        deliveyCharge != "" &&
        !validator.isFloat(String(deliveyCharge))
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_delivery_charge_format_is_incorrect}
            &nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        deliveyMinCharge != "" &&
        !validator.isFloat(String(deliveyMinCharge))
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_delivery_min_charge_format_is_incorrect}
            &nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateAllowedPostCodeAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_item_in_all_lists.Id,
            postCode,
            deliveyCharge ? parseFloat(deliveyCharge) : 0,
            deliveyMinCharge ? parseFloat(deliveyMinCharge) : 0
          )
        );
      }
    } else if (number == 10) {
      if (areaName == "" || postCode == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (
        deliveyCharge != "" &&
        !validator.isFloat(String(deliveyCharge))
      ) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_delivery_charge_format_is_incorrect}
            &nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateByAreaAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_item_in_all_lists.Id,
            areaName,
            deliveyCharge ? parseFloat(deliveyCharge) : 0,
            postCode
          )
        );
      }
    } else if (number == 15) {
      if (appTitle == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        formData.append("LoginKey", login_key);
        formData.append("UserId", user_id);
        formData.append("SubItemId", selected_item_in_all_lists.Id);
        formData.append("AppTitle", appTitle.replaceAll(`"`, `\"\"`));
        formData.append(
          "AppDescription",
          desc ? desc.replaceAll(`"`, `\"\"`) : ""
        );
        formData.append("ImageUrl", "");
        formData.append("DeleteImage", 0);
        formData.append("LastImageUrlName", "");
        formData.append("JustTitleAndPrice", 1);
        formData.append("VisibleImage", 1);
        dispatch(
          updatePublicSubItemAction(
            formData,
            login_key,
            user_id,
            selected_item_in_all_lists.Id,
            appTitle,
            desc ? desc : "",
            selected_item_in_all_lists.SubMenuId
          )
        );
      }
    } else if (number == 16) {
      if (postCode == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateAllowedPostCodeFOFAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_item_in_all_lists.Id,
            postCode,
            0,
            0
          )
        );
      }
    }
  };

  const handleTilte = (e) => {
    if (number == 9 || number == 16) {
      setPostCode(e.target.value.toUpperCase());
    } else if (number == 10) {
      setAreaName(e.target.value);
    } else {
      setAppTitle(e.target.value);
    }
  };
  const handleDesc = (e) => {
    setDesc(e.target.value);
  };
  const handleSecondInput = (e) => {
    if (number == 9) {
      setDeliveyMinCharge(e.target.value.replace(/[^\d\.,£]+/g, ""));
    } else if (number == 10) {
      setPostCode(e.target.value.toUpperCase());
    } else {
      setWebTitle(e.target.value);
    }
  };

  const handleThirdInput = (e) => {
    if (number == 9 || number == 10) {
      setDeliveyCharge(e.target.value.replace(/[^\d\.,£]+/g, ""));
    } else {
      setMainPrice(e.target.value);
    }
  };
  const handleOnclickeInput = () => {
    setShowSketchPicker(!showSketchPicker);
  };
  const handleChangeCompleteBackgroundColor = (color) => {
    setBackgroundColor(color.hex);
    document.getElementById("input_backgroundColor").value = color.hex;
  };
  const handleCloseInputColor = () => {
    setShowSketchPicker(false);
  };
  const handleInputBackgroundColor = (e) => {
    setBackgroundColor(e.target.value);
  };

  useEffect(() => {
    if (selected_item_in_all_lists) {
      if (selected_item_in_all_lists.AppBgColor) {
        setBackgroundColor(selected_item_in_all_lists.AppBgColor);
      } else {
        setBackgroundColor("#14aeba");
      }
      if (selected_item_in_all_lists.AppTitle) {
        setAppTitle(selected_item_in_all_lists.AppTitle);
      } else if (selected_item_in_all_lists.AreaName) {
        setAreaName(selected_item_in_all_lists.AreaName);
      }
      if (selected_item_in_all_lists.Postcode) {
        setPostCode(selected_item_in_all_lists.Postcode);
      } else {
        setPostCode("");
      }
      if (selected_item_in_all_lists.AppDescription) {
        setDesc(selected_item_in_all_lists.AppDescription);
      } else {
        setDesc("");
      }
      if (selected_item_in_all_lists.WebTitle) {
        setWebTitle(selected_item_in_all_lists.WebTitle);
      } else if (selected_item_in_all_lists.DeliveryCharge) {
        setDeliveyCharge(selected_item_in_all_lists.DeliveryCharge);
      }
      if (selected_item_in_all_lists.AmountDelivery) {
        setMainPrice(selected_item_in_all_lists.AmountDelivery);
      } else if (selected_item_in_all_lists.Price) {
        setMainPrice(selected_item_in_all_lists.Price);
      } else if (selected_item_in_all_lists.Price) {
        setMainPrice(selected_item_in_all_lists.Price);
      } else if (selected_item_in_all_lists.AmountMain) {
        setMainPrice(selected_item_in_all_lists.AmountMain);
      } else if (
        selected_item_in_all_lists.DeliveryMinCharge ||
        selected_item_in_all_lists.DeliveryMinCharge == 0
      ) {
        setDeliveyMinCharge(selected_item_in_all_lists.DeliveryMinCharge);
      }
      if (number == 2) {
        setMinSelect(selected_item_in_all_lists.MinSelect);
        setMaxSelect(selected_item_in_all_lists.MaxSelect);
      }
    }
  }, [selected_item_in_all_lists]);

  useEffect(() => {
    if (result_successfull) {
      if (item.showEditBox) {
        item.showEditBox = false;
      }
    }
  }, [result_successfull]);

  useEffect(() => {
    if (item) {
      dispatch(sendAllItemsToAppAction(item));
    }
  }, [item]);

  return number != 0 ? (
    item.showEditBox ? (
      <div
        className="menu_list_item_container box_shadow menu-list-container"
        onClick={(i) => i.stopPropagation()}
        style={{ position: "relative" }}
      >
        {number == 16 ? (
          <Box
            component="form"
            // sx={{
            //   "& > :not(style)": { m: 1, display: "flex" },
            // }}

            className="menu-list-container-inputs"
            autoComplete="on"
          >
            <TextField
              key={1}
              sx={{
                "& label.Mui-focused": {
                  color: primaryColor,
                },
                "&:hover label": {
                  color: primaryColor,
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "15px",
                  height: "40px",
                  color: "black",

                  "& fieldset": {
                    borderColor: "grey",
                  },
                  "&:hover fieldset": {
                    borderColor: primaryColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: primaryColor,
                  },
                },
              }}
              size="small"
              label={`*${lang.post_code}`}
              InputLabelProps={{
                sx: {
                  fontSize: 16,
                  // top: -7,
                  "&.MuiInputLabel-shrink": { top: 0 },
                  "&.Mui-focused": {
                    color: primaryColor,
                  },
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                },
              }}
              value={postCode}
              // defaultValue={number == 9 ? postCode : appTitle}
              onChange={(e) => handleTilte(e)}
              style={{
                width: "100%",
              }}
              autoFocus={focusedInputKey == 1 ? true : false}
              onFocus={() => setFocusedInputKey(1)}
            />
          </Box>
        ) : number == 15 ? (
          <Box
            component="form"
            // sx={{
            //   "& > :not(style)": { m: 1, display: "flex" },
            // }}

            className="menu-list-container-inputs"
            autoComplete="on"
          >
            <TextField
              key={1}
              sx={{
                "& label.Mui-focused": {
                  color: primaryColor,
                },
                "&:hover label": {
                  color: primaryColor,
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "15px",
                  height: "40px",
                  color: "black",

                  "& fieldset": {
                    borderColor: "grey",
                  },
                  "&:hover fieldset": {
                    borderColor: primaryColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: primaryColor,
                  },
                },
              }}
              size="small"
              label={`*${lang.app_title}`}
              InputLabelProps={{
                sx: {
                  fontSize: 16,
                  // top: -7,
                  "&.MuiInputLabel-shrink": { top: 0 },
                  "&.Mui-focused": {
                    color: primaryColor,
                  },
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                },
              }}
              value={appTitle}
              // defaultValue={number == 9 ? postCode : appTitle}
              onChange={(e) => handleTilte(e)}
              style={{
                width: "49%",
              }}
              autoFocus={focusedInputKey == 1 ? true : false}
              onFocus={() => setFocusedInputKey(1)}
            />
            <TextField
              key={1}
              sx={{
                "& label.Mui-focused": {
                  color: primaryColor,
                },
                "&:hover label": {
                  color: primaryColor,
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "15px",
                  height: "40px",
                  color: "black",

                  "& fieldset": {
                    borderColor: "grey",
                  },
                  "&:hover fieldset": {
                    borderColor: primaryColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: primaryColor,
                  },
                },
              }}
              size="small"
              label={lang.description}
              InputLabelProps={{
                sx: {
                  fontSize: 16,
                  // top: -7,
                  "&.MuiInputLabel-shrink": { top: 0 },
                  "&.Mui-focused": {
                    color: primaryColor,
                  },
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                },
              }}
              value={desc}
              // defaultValue={number == 9 ? postCode : appTitle}
              onChange={(e) => handleDesc(e)}
              style={{
                width: "49%",
              }}
              autoFocus={focusedInputKey == 2 ? true : false}
              onFocus={() => setFocusedInputKey(2)}
            />
          </Box>
        ) : (
          <Box
            component="form"
            // sx={{
            //   "& > :not(style)": { m: 1, display: "flex" },
            // }}

            className="menu-list-container-inputs"
            autoComplete="on"
            style={{
              width: number == 3 ? "80%" : null,
            }}
          >
            <TextField
              key={1}
              sx={{
                "& label.Mui-focused": {
                  color: primaryColor,
                },
                "&:hover label": {
                  color: primaryColor,
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "15px",
                  height: "40px",
                  color: "black",

                  "& fieldset": {
                    borderColor: "grey",
                  },
                  "&:hover fieldset": {
                    borderColor: primaryColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: primaryColor,
                  },
                },
              }}
              size="small"
              label={
                number == 9
                  ? lang.post_code
                  : number == 10
                  ? `* ${lang.area}`
                  : lang.app_title
              }
              InputLabelProps={{
                sx: {
                  fontSize: 16,
                  // top: -7,
                  "&.MuiInputLabel-shrink": { top: 0 },
                  "&.Mui-focused": {
                    color: primaryColor,
                  },
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                },
              }}
              value={
                number == 9 ? postCode : number == 10 ? areaName : appTitle
              }
              // defaultValue={number == 9 ? postCode : appTitle}
              onChange={(e) => handleTilte(e)}
              style={{
                width: number == 10 ? "49%" : number != 2 ? "32%" : "24.5%",
              }}
              autoFocus={focusedInputKey == 1 ? true : false}
              onFocus={() => setFocusedInputKey(1)}
              disabled={number == 5 ? true : null}
            />
            {number != 10 ? (
              <TextField
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "40px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                disabled={number == 5 ? true : null}
                key={2}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                label={number == 9 ? lang.delivey_min_charge : lang.web_title}
                value={number == 9 ? deliveyMinCharge : webTitle}
                onChange={(e) => handleSecondInput(e)}
                style={{
                  width: number != 2 ? "32%" : "24.5%",
                }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
              />
            ) : null}

            {number != 2 ? (
              <TextField
                sx={{
                  "& label.Mui-focused": {
                    color: primaryColor,
                  },
                  "&:hover label": {
                    color: primaryColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "15px",
                    height: "40px",
                    color: "black",
                    "& fieldset": {
                      borderColor: "grey",
                    },
                    "&:hover fieldset": {
                      borderColor: primaryColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: primaryColor,
                    },
                  },
                }}
                size="small"
                key={3}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    // top: -7,

                    "&.MuiInputLabel-shrink": {
                      top: 0,
                    },
                    "&.Mui-focused": {
                      color: primaryColor,
                    },
                  },
                }}
                label={
                  number == 9 || number == 10
                    ? lang.deviry_charge
                    : lang.mainprice
                }
                value={number == 9 || number == 10 ? deliveyCharge : mainPrice}
                onChange={(e) => handleThirdInput(e)}
                style={{
                  width: number == 10 ? "49%" : "32%",
                }}
                autoFocus={focusedInputKey == 3 || number == 5 ? true : false}
                onFocus={() => setFocusedInputKey(3)}
              />
            ) : (
              <Box
                // sx={{
                //   "& > :not(style)": { m: 1, display: "flex" },
                // }}

                className="menu-list-container-inputs"
                autoComplete="on"
                style={{ width: "49%" }}
              >
                <TextField
                  sx={{
                    "& label.Mui-focused": {
                      color: primaryColor,
                    },
                    "&:hover label": {
                      color: primaryColor,
                    },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "15px",
                      height: "40px",
                      color: "black",
                      "& fieldset": {
                        borderColor: "grey",
                      },
                      "&:hover fieldset": {
                        borderColor: primaryColor,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor,
                      },
                    },
                  }}
                  size="small"
                  key={4}
                  label={lang.minSelect}
                  InputLabelProps={{
                    sx: {
                      fontSize: 16,
                      // top: -7,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primaryColor,
                      },
                    },
                  }}
                  defaultValue={minSelect}
                  onChange={(e) =>
                    setMinSelect(e.target.value.replace(/\D/g, ""))
                  }
                  style={{
                    width: "49%",
                  }}
                  autoFocus={focusedInputKey == 4 ? true : false}
                  onFocus={() => setFocusedInputKey(4)}
                />
                <TextField
                  sx={{
                    "& label.Mui-focused": {
                      color: primaryColor,
                    },
                    "&:hover label": {
                      color: primaryColor,
                    },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "15px",
                      height: "40px",
                      color: "black",
                      "& fieldset": {
                        borderColor: "grey",
                      },
                      "&:hover fieldset": {
                        borderColor: primaryColor,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor,
                      },
                    },
                  }}
                  size="small"
                  key={5}
                  InputLabelProps={{
                    sx: {
                      fontSize: 16,
                      // top: -7,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primaryColor,
                      },
                    },
                  }}
                  label={lang.maxSelect}
                  value={maxSelect}
                  onChange={(e) =>
                    setMaxSelect(e.target.value.replace(/\D/g, ""))
                  }
                  style={{
                    width: "49%",
                  }}
                  autoFocus={focusedInputKey == 5 ? true : false}
                  onFocus={() => setFocusedInputKey(5)}
                />
              </Box>
            )}
          </Box>
        )}

        <div
          className="menu-list-container-edit-box-icons"
          style={{ width: number == 3 ? "18%" : null }}
        >
          {number == 3 ? (
            <div
              className="menu-list-container-icon"
              style={{
                width: number == 3 ? "31%" : null,
                backgroundColor: backgroundColor,
                cursor: "pointer",
              }}
              onClick={handleOnclickeInput}
              onChange={handleInputBackgroundColor}
              // onMouseOver={handleOnclickeInput}
              id="input_backgroundColor"
            ></div>
          ) : null}
          {showSketchPicker ? (
            <div
              className="fast-edit-SketchPicker-div"
              onMouseLeave={handleCloseInputColor}
            >
              <SketchPicker
                color={backgroundColor}
                onChangeComplete={handleChangeCompleteBackgroundColor}
              />
            </div>
          ) : null}
          <div
            className="menu-list-container-icon"
            onClick={() => handleSaveClicked()}
            style={{ width: number == 3 ? "31%" : null }}
          >
            <i
              class="fa fa-check menu-list-fa-check"
              aria-hidden="true"
              style={{ fontSize: " 23px" }}
            ></i>
          </div>
          <div
            className="menu-list-container-icon"
            onClick={click_close_icon}
            style={{ width: number == 3 ? "31%" : null }}
          >
            <i
              class="fa fa-times menu-list-fa-times"
              aria-hidden="true"
              style={{ fontSize: " 23px" }}
            ></i>
          </div>
        </div>
      </div>
    ) : (
      <div className="menu_list_item_container">
        <div
          className="menu_list_title_container"
          style={{
            marginRight: without_key ? "-15px" : null,

            padding: number == 3 ? 0 : "",
          }}
          onClick={(i) => i.stopPropagation()}
        >
          {number == 3 ? (
            <span
              className="background-color-in-lists"
              style={{
                backgroundColor: item
                  ? item.AppBgColor
                    ? item.AppBgColor
                    : "transparent"
                  : null,
              }}
            ></span>
          ) : null}
          <div
            className="menu_list_title_container-first-section"
            style={
              {
                // width: number == 15 ? "96%" : number == 2 ? "90%" : "65%",
              }
            }
          >
            {field_number == 1 ? (
              <div className="menu_list_field-container">
                <h6>{field_1_title}</h6>
              </div>
            ) : field_number == 2 ? (
              <div className="menu_list_field-container_number_2">
                <h6 className="menu-list-title">{field_1_title}</h6>
                <h6>{field_2_title}</h6>
              </div>
            ) : field_number == 3 ? (
              <div className="menu_list_field-container">
                <h6>{field_1_title}</h6>{" "}
                <div>
                  <h6>{field_2_title}</h6>
                  <h6>{field_3_title}</h6>{" "}
                </div>
              </div>
            ) : field_number == 4 ? (
              <div className="menu_list_field-container">
                <h6>{field_1_title}</h6>
                <h6>{field_2_title}</h6>
                <h6>{field_3_title}</h6>
                <h6>{field_4_title}</h6>
              </div>
            ) : field_number == 5 ? (
              <div className="menu_list_field-container">
                <h6>{field_1_title}</h6>
                <h6>{field_2_title}</h6>
                <h6>{field_3_title}</h6>
                <h6>{field_4_title}</h6>
                <h6>{field_5_title}</h6>
              </div>
            ) : field_number == 6 ? (
              <div className="menu_list_field-container">
                <h6>{field_1_title}</h6>
                <h6 style={{ textAlign: "center" }}>
                  <GreenSwitch
                    checked={field_2_title}
                    onChange={field_2_click}
                  />
                </h6>
                <h6 style={{ textAlign: "center" }}>
                  <GreenSwitch
                    checked={field_3_title}
                    onChange={field_3_click}
                  />
                </h6>
                <h6 style={{ textAlign: "center" }}>{field_4_title}</h6>
                <h6 style={{ textAlign: "center" }}>{field_5_title}</h6>
                <h6 style={{ textAlign: "center" }}>{field_6_title}</h6>
              </div>
            ) : null}
            {/* <div
              className="menu-list-continer-title"
              style={{
                width:
                  number == 9
                    ? "21%"
                    : number == 15
                    ? "17%"
                    : number == 2
                    ? "48%"
                    : null,
              }}
            >
              {number == 12 ? (
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                    alignItems: "center",
                    // border: "1px solid brown",
                    justifyContent: "space-between",
                  }}
                >
                  <h6
                    style={{
                      margin: 0,
                      width: "16%",
                    }}
                  >
                    # {item_id}
                  </h6>

                  <h6
                    style={{
                      margin: 0,
                      maxWidth: "55%",
                    }}
                  >
                    {" "}
                    <i
                      class="fa fa-user-circle-o"
                      aria-hidden="true"
                      style={{ cursor: "auto" }}
                    ></i>
                    {`${item_title} ${item_lastName}`}
                  </h6>
                  <h6
                    style={{
                      margin: 0,
                      width: "30%",
                    }}
                  >
                    <i
                      class="fa fa-mobile"
                      aria-hidden="true"
                      style={{ fontSize: "21px", cursor: "auto" }}
                    ></i>
                    {item_mobile}
                  </h6>
                </div>
              ) : (
                <h6
                  className="menu-list-title"
                  style={{
                    margin: 0,
                  }}
                >
                  {item_title}
                </h6>
              )}
            </div>
            {number == 2 ? (
              <div
                style={{
                  width: "48%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6 style={{ margin: 0, textAlign: "center", width: "49%" }}>
                  {item.MinSelect}
                </h6>
                <h6 style={{ margin: 0, textAlign: "center", width: "49%" }}>
                  {item.MaxSelect}
                </h6>
              </div>
            ) : null}
            {number == 15 ? (
              <div
                style={{
                  display: "flex",
                  width: "83%",

                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "23%",

                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <GreenSwitch
                    checked={item.swithStatus}
                    onChange={promotiom_status_oncgange}
                  />
                </div>
                <div
                  style={{
                    width: "23%",

                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <GreenSwitch
                    checked={item.swithExcludeMealDeals}
                    onChange={exclude_meal_deals_onchange}
                  />
                </div>

                <p
                  style={{
                    margin: 0,
                    width: "23%",

                    textAlign: "center",
                    wordWrap: "break-word",
                    overflowWrap: "anywhere",
                  }}
                >
                  {item.Code}
                </p>
                <p
                  style={{
                    margin: 0,
                    width: "23%",

                    textAlign: "center",
                    wordWrap: "break-word",
                    overflowWrap: "anywhere",
                  }}
                >
                  {item.Rate}
                </p>
                <p
                  style={{
                    margin: 0,
                    width: "23%",

                    textAlign: "center",
                    wordWrap: "break-word",
                    overflowWrap: "anywhere",
                  }}
                >
                  £{item.MinOrder.toFixed(2)}
                </p>
              </div>
            ) : null}
            {number == 9 ? (
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  marginRight: "15px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    width: "50%",
                    color: "grey",
                  }}
                >
                  deviry charge : {delivery_charge ? delivery_charge : "0"}
                </p>
                <p
                  style={{
                    width: "50%",
                    color: "grey",
                    margin: 0,
                  }}
                >
                  delivery min charge :{" "}
                  {delivery_min_charge ? delivery_min_charge : "0"}
                </p>
              </div>
            ) : null}
            {item_price ? (
              <h6
                style={{
                  margin: "0px 13px 0px 0px",
                }}
              >
                £{item_price}
              </h6>
            ) : null} */}
          </div>

          <div
            className="menu-list-continer-price-and-delete-and-edit-icons"
            onblur={on_blur}
          >
            {show_delete ? (
              <div>
                <i
                  class="fas fa-trash"
                  onClick={show_delete_box}
                  ref={setTriggerRef}
                ></i>
                {item.showDeleteBox && (
                  <div
                    // onClick={(i) => i.stopPropagation()}

                    ref={setTooltipRef}
                    {...getTooltipProps({
                      className: "tooltip-container",
                      // interactive: true,
                    })}
                  >
                    <div
                      {...getArrowProps({
                        className: "tooltip-arrow",
                      })}
                    ></div>
                    <div className="delete-popup">
                      <h6 style={{ fontSize: "19px" }}>
                        {lang.delete_message}
                      </h6>
                      <div className="menu-list-container-cancel-and-ok-button">
                        <button onClick={cancel_delete_box}>
                          {lang.cancel}
                        </button>
                        <button onClick={delete_click}>{lang.ok}</button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {show_edit_icon ? (
              <i
                class="fa fa-pencil"
                aria-hidden="true"
                onClick={() => {
                  handleEditBox();
                }}
                style={{
                  color:
                    just_title_and_price == 1
                      ? selected_item_in_all_lists
                        ? selected_item_in_all_lists.Id
                          ? selected_item_in_all_lists.Id == item.Id
                            ? primaryColor
                            : null
                          : null
                        : null
                      : null,
                }}
              ></i>
            ) : null}
          </div>
        </div>

        <div style={{ height: "100%" }}>
          {btn_number == 1 ? (
            <div className="menu_list_item_buttons_container">
              <button
                key={1}
                onClick={bt_1_click}
                style={{
                  margin: 0,
                  backgroundColor:
                    change_selected_button_border_color_on_focus && selected
                      ? selected.Id
                        ? selected.Id == item.Id
                          ? primaryColor
                          : null
                        : selected.VatId
                        ? selected.VatId == item.VatId
                          ? primaryColor
                          : null
                        : selected.WorkStationId
                        ? selected.WorkStationId == item.WorkStationId
                          ? primaryColor
                          : null
                        : selected.UserId
                        ? selected.UserId == item.UserId
                          ? primaryColor
                          : null
                        : selected.DriverId
                        ? selected.DriverId == item.DriverId
                          ? primaryColor
                          : null
                        : selected.GroupId
                        ? selected.GroupId == item.GroupId
                          ? primaryColor
                          : null
                        : null
                      : null,
                  color:
                    change_selected_button_border_color_on_focus && selected
                      ? selected.Id
                        ? selected.Id == item.Id
                          ? white
                          : null
                        : selected.VatId
                        ? selected.VatId == item.VatId
                          ? white
                          : null
                        : selected.WorkStationId
                        ? selected.WorkStationId == item.WorkStationId
                          ? white
                          : null
                        : selected.UserId
                        ? selected.UserId == item.UserId
                          ? white
                          : null
                        : selected.DriverId
                        ? selected.DriverId == item.DriverId
                          ? white
                          : null
                        : selected.GroupId
                        ? selected.GroupId == item.GroupId
                          ? white
                          : null
                        : null
                      : null,
                  border:
                    change_selected_button_border_color_on_focus && selected
                      ? selected.Id
                        ? selected.Id == item.Id
                          ? "1px solid #f38321"
                          : null
                        : selected.VatId
                        ? selected.VatId == item.VatId
                          ? "1px solid #f38321"
                          : null
                        : selected.WorkStationId
                        ? selected.WorkStationId == item.WorkStationId
                          ? "1px solid #f38321"
                          : null
                        : selected.UserId
                        ? selected.UserId == item.UserId
                          ? "1px solid #f38321"
                          : null
                        : selected.DriverId
                        ? selected.DriverId == item.DriverId
                          ? "1px solid #f38321"
                          : null
                        : selected.GroupId
                        ? selected.GroupId == item.GroupId
                          ? "1px solid #f38321"
                          : null
                        : null
                      : null,
                }}
                // onBlur={() => changeSelectedButtonBorderColorOnFocus(false)}
              >
                {bt_1_title}
              </button>
            </div>
          ) : btn_number == 2 ? (
            <div className="menu_list_item_buttons_container">
              <button
                key={2}
                onClick={bt_2_click}
                style={{
                  backgroundColor:
                    number == 1
                      ? clicked_item == 10
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.Id == item.Id
                            ? primaryColor
                            : null
                          : null
                        : null
                      : clicked_item == 3
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? primaryColor
                          : null
                        : null
                      : null,
                  color:
                    number == 1
                      ? clicked_item == 10
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.Id == item.Id
                            ? white
                            : null
                          : null
                        : null
                      : clicked_item == 3
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? white
                          : null
                        : null
                      : null,
                  border:
                    number == 1
                      ? clicked_item == 10
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.Id == item.Id
                            ? "1px solid #f38321 "
                            : null
                          : null
                        : null
                      : clicked_item == 3
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? "1px solid #f38321 "
                          : null
                        : null
                      : null,
                }}
              >
                {bt_2_title}
              </button>
              <button
                key={1}
                onClick={bt_1_click}
                style={{
                  margin: 0,
                  backgroundColor:
                    number == 1
                      ? clicked_item == 7
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.Id == item.Id
                            ? primaryColor
                            : null
                          : null
                        : null
                      : number == 0
                      ? clicked_item == 4
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.WorkStationId == item.WorkStationId
                            ? white
                            : null
                          : null
                        : null
                      : clicked_item == 2
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? primaryColor
                          : null
                        : null
                      : null,
                  color:
                    number == 1
                      ? clicked_item == 7
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.Id == item.Id
                            ? white
                            : null
                          : null
                        : null
                      : number == 0
                      ? clicked_item == 4
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.WorkStationId == item.WorkStationId
                            ? white
                            : null
                          : null
                        : null
                      : clicked_item == 2
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? white
                          : null
                        : null
                      : null,
                  border:
                    number == 1
                      ? clicked_item == 7
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.Id == item.Id
                            ? "1px solid #f38321"
                            : null
                          : null
                        : null
                      : number == 0
                      ? clicked_item == 4
                        ? change_selected_button_border_color_on_focus &&
                          selected
                          ? selected.WorkStationId == item.WorkStationId
                            ? white
                            : null
                          : null
                        : null
                      : clicked_item == 2
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? "1px solid #f38321"
                          : null
                        : null
                      : null,
                }}
              >
                {bt_1_title}
              </button>
            </div>
          ) : btn_number == 3 ? (
            <div className="menu_list_item_buttons_container">
              <button
                key={3}
                onClick={bt_3_click}
                style={{
                  backgroundColor:
                    clicked_item == 6
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? primaryColor
                          : null
                        : null
                      : null,
                  color:
                    clicked_item == 6
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? white
                          : null
                        : null
                      : null,
                  border:
                    clicked_item == 6
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? "1px solid #f38321"
                          : null
                        : null
                      : null,
                }}
              >
                {bt_3_title}
              </button>
              <button
                key={2}
                onClick={bt_2_click}
                style={{
                  backgroundColor:
                    clicked_item == 5
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? primaryColor
                          : null
                        : null
                      : null,
                  color:
                    clicked_item == 5
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? white
                          : null
                        : null
                      : null,
                  border:
                    clicked_item == 5
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? "1px solid #f38321"
                          : null
                        : null
                      : null,
                }}
              >
                {bt_2_title}
              </button>
              <button
                key={1}
                onClick={bt_1_click}
                style={{
                  margin: 0,
                  backgroundColor:
                    clicked_item == 4
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? primaryColor
                          : null
                        : null
                      : null,
                  color:
                    clicked_item == 4
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? white
                          : null
                        : null
                      : null,
                  border:
                    clicked_item == 4
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? "1px solid #f38321"
                          : null
                        : null
                      : null,
                }}
              >
                {bt_1_title}
              </button>
            </div>
          ) : btn_number == 4 ? (
            <div className="menu_list_item_buttons_container">
              <button key={4} onClick={bt_4_click}>
                {bt_4_title}
              </button>
              <button key={3} onClick={bt_3_click}>
                {bt_3_title}
              </button>
              <button key={2} onClick={bt_2_click}>
                {bt_2_title}
              </button>
              <button key={1} onClick={bt_1_click} style={{ margin: 0 }}>
                {bt_1_title}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    )
  ) : (
    <div className="menu_list_item_container">
      <div
        className="menu_list_title_container"
        style={{
          marginRight: without_key ? "-15px" : null,
          // border:
          //   change_selected_button_border_color_on_focus && selected
          //     ? selected.Id == item.Id
          //       ? "1px solid red"
          //       : null
          //     : null,
        }}
      >
        <div
          className="menu_list_title_container-first-section"
          style={
            {
              // width: number == 15 ? "96%" : number == 2 ? "90%" : "65%",
            }
          }
        >
          {field_number == 1 ? (
            <div className="menu_list_field-container">
              <h6>{field_1_title}</h6>
            </div>
          ) : field_number == 2 ? (
            show_status ? (
              <div className="menu_list_field-container">
                <h6>{field_1_title}</h6>
                <h6>{field_2_title}</h6>
              </div>
            ) : (
              <div className="menu_list_field-container_number_2">
                <h6>{field_1_title}</h6>
                <h6>{field_2_title}</h6>
              </div>
            )
          ) : field_number == 3 ? (
            <div className="menu_list_field-container-number-3">
              <h6>{field_1_title}</h6>{" "}
              <div>
                {show_field_icon ? (
                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                ) : null}
                <h6>{field_2_title}</h6>
                {show_field_icon ? (
                  <i class="fa fa-phone-square" aria-hidden="true"></i>
                ) : null}
                <h6>{field_3_title}</h6>
              </div>
            </div>
          ) : field_number == 4 ? (
            <div className="menu_list_field-container">
              <h6>{field_1_title}</h6>
              <h6>{field_2_title}</h6>
              <h6>{field_3_title}</h6>
              <h6>{field_4_title}</h6>
            </div>
          ) : field_number == 5 ? (
            <div className="menu_list_field-container">
              <h6>{field_1_title}</h6>
              <h6>{field_2_title}</h6>
              <h6>{field_3_title}</h6>
              <h6>{field_4_title}</h6>
              <h6>{field_5_title}</h6>
            </div>
          ) : null}
        </div>

        {/* <div
          style={{
            width: show_id ? "40%" : "70%",
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <h6 className="menu-list-continer-title-1">{item_title}</h6>
          <h6 className="menu-list-continer-title-1" style={{ width: "54.7%" }}>
            {item_uniqe_id}
          </h6>
        </div>
        {show_id ? (
          <div
            style={{
              width: "47%",
              display: "flex",
              alignItems: "center",
              color: "grey",
            }}
          >
            <p style={{ marginTop: "8px", fontSize: "15px" }}>
              {lang.t_id} : {item.TId}
              {item.TerminalId}
            </p>
          </div>
        ) : null}
     
        {custom_delivery_charge ? (
          <h6
            className="menu-list-continer-title-1"
            style={{
              margin: 0,
              width: "63%",
              textAlign: "right",
              wordWrap: "break-word",
              overflowWrap: "anywhere",
            }}
          >
            {`£${custom_delivery_charge.toFixed(2)}`}
          </h6>
        ) : null} */}
        <div className="menu-list-continer-price-and-delete-and-edit-icons-1">
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: show_status
                ? "60%"
                : custom_delivery_charge
                ? "90%"
                : null,
              alignItems: "center",
            }}
          ></div> */}
          {show_status ? (
            <p
              style={{
                color: active ? "green" : "red",
                margin: 0,
              }}
            >
              {active ? "Active" : "Deactive"}
            </p>
          ) : null}
          {show_delete ? (
            // <i class="fas fa-trash" onClick={delete_click}></i>
            <div>
              <i
                class="fas fa-trash"
                onClick={show_delete_box}
                ref={setTriggerRef}
              ></i>
              {item.showDeleteBox && (
                <div
                  ref={setTooltipRef}
                  {...getTooltipProps({
                    className: "tooltip-container",
                    // interactive: true,
                  })}
                >
                  <div
                    {...getArrowProps({
                      className: "tooltip-arrow",
                    })}
                  ></div>
                  <div className="delete-popup ">
                    <h6 style={{ fontSize: "19px" }}>{lang.delete_message}</h6>
                    <div className="menu-list-container-cancel-and-ok-button">
                      <button onClick={cancel_delete_box}>{lang.cancel}</button>
                      <button onClick={delete_click}>{lang.ok}</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}
          {show_edit_icon ? (
            <i
              class="fa fa-pencil"
              aria-hidden="true"
              style={{ marginLeft: "10px" }}
            ></i>
          ) : null}
        </div>
      </div>
      <div style={{ height: "100%" }}>
        {btn_number == 1 ? (
          <div className="menu_list_item_buttons_container">
            <button
              key={1}
              onClick={bt_1_click}
              style={{
                margin: 0,
                backgroundColor:
                  change_selected_button_border_color_on_focus && selected
                    ? selected.Id
                      ? selected.Id == item.Id
                        ? primaryColor
                        : null
                      : selected.VatId
                      ? selected.VatId == item.VatId
                        ? primaryColor
                        : null
                      : selected.WorkStationId
                      ? selected.WorkStationId == item.WorkStationId
                        ? primaryColor
                        : null
                      : selected.UserId
                      ? selected.UserId == item.UserId
                        ? primaryColor
                        : null
                      : selected.DriverId
                      ? selected.DriverId == item.DriverId
                        ? primaryColor
                        : null
                      : selected.GroupId
                      ? selected.GroupId == item.GroupId
                        ? primaryColor
                        : null
                      : selected.ExternalCompanyId
                      ? selected.ExternalCompanyId == item.ExternalCompanyId
                        ? primaryColor
                        : null
                      : null
                    : null,
                color:
                  change_selected_button_border_color_on_focus && selected
                    ? selected.Id
                      ? selected.Id == item.Id
                        ? white
                        : null
                      : selected.VatId
                      ? selected.VatId == item.VatId
                        ? white
                        : null
                      : selected.WorkStationId
                      ? selected.WorkStationId == item.WorkStationId
                        ? white
                        : null
                      : selected.UserId
                      ? selected.UserId == item.UserId
                        ? white
                        : null
                      : selected.DriverId
                      ? selected.DriverId == item.DriverId
                        ? white
                        : null
                      : selected.GroupId
                      ? selected.GroupId == item.GroupId
                        ? white
                        : null
                      : selected.ExternalCompanyId
                      ? selected.ExternalCompanyId == item.ExternalCompanyId
                        ? white
                        : null
                      : null
                    : null,
                border:
                  change_selected_button_border_color_on_focus && selected
                    ? selected.Id
                      ? selected.Id == item.Id
                        ? "1px solid #f38321"
                        : null
                      : selected.VatId
                      ? selected.VatId == item.VatId
                        ? "1px solid #f38321"
                        : null
                      : selected.WorkStationId
                      ? selected.WorkStationId == item.WorkStationId
                        ? "1px solid #f38321"
                        : null
                      : selected.UserId
                      ? selected.UserId == item.UserId
                        ? "1px solid #f38321"
                        : null
                      : selected.DriverId
                      ? selected.DriverId == item.DriverId
                        ? "1px solid #f38321"
                        : null
                      : selected.GroupId
                      ? selected.GroupId == item.GroupId
                        ? "1px solid #f38321"
                        : null
                      : selected.ExternalCompanyId
                      ? selected.ExternalCompanyId == item.ExternalCompanyId
                        ? "1px solid #f38321"
                        : null
                      : null
                    : null,
              }}
              // onBlur={() => changeSelectedButtonBorderColorOnFocus(false)}
            >
              {bt_1_title}
            </button>
          </div>
        ) : btn_number == 2 ? (
          <div className="menu_list_item_buttons_container">
            <button
              key={2}
              onClick={bt_2_click}
              style={{
                backgroundColor:
                  number == 1
                    ? clicked_item == 10
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? primaryColor
                          : null
                        : null
                      : null
                    : number == 0
                    ? clicked_item == 2
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? primaryColor
                          : null
                        : null
                      : null
                    : clicked_item == 3
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? primaryColor
                        : null
                      : null
                    : null,
                color:
                  number == 1
                    ? clicked_item == 10
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? white
                          : null
                        : null
                      : null
                    : number == 0
                    ? clicked_item == 2
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? white
                          : null
                        : null
                      : null
                    : clicked_item == 3
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? white
                        : null
                      : null
                    : null,
                border:
                  number == 1
                    ? clicked_item == 10
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? "1px solid #f38321 "
                          : null
                        : null
                      : null
                    : number == 0
                    ? clicked_item == 2
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? "1px solid #f38321 "
                          : null
                        : null
                      : null
                    : clicked_item == 3
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? "1px solid #f38321 "
                        : null
                      : null
                    : null,
              }}
            >
              {bt_2_title}
            </button>
            <button
              key={1}
              disabled={
                number == 0
                  ? item.WorkStationId
                    ? item.DeviceType == 2
                      ? true
                      : false
                    : null
                  : null
              }
              onClick={bt_1_click}
              style={{
                margin: 0,
                backgroundColor:
                  number == 1
                    ? clicked_item == 7
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? primaryColor
                          : null
                        : null
                      : null
                    : number == 0
                    ? clicked_item == 4
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? primaryColor
                          : null
                        : null
                      : null
                    : clicked_item == 2
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? primaryColor
                        : null
                      : null
                    : null,
                color:
                  number == 1
                    ? clicked_item == 7
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? white
                          : null
                        : null
                      : null
                    : number == 0
                    ? clicked_item == 4
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? white
                          : null
                        : null
                      : null
                    : clicked_item == 2
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? white
                        : null
                      : null
                    : null,
                border:
                  number == 1
                    ? clicked_item == 7
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.Id == item.Id
                          ? "1px solid #f38321"
                          : null
                        : null
                      : null
                    : number == 0
                    ? clicked_item == 4
                      ? change_selected_button_border_color_on_focus && selected
                        ? selected.WorkStationId == item.WorkStationId
                          ? "1px solid #f38321"
                          : null
                        : null
                      : null
                    : clicked_item == 2
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? "1px solid #f38321"
                        : null
                      : null
                    : null,
              }}
            >
              {bt_1_title}
            </button>
          </div>
        ) : btn_number == 3 ? (
          <div className="menu_list_item_buttons_container">
            <button
              key={3}
              onClick={bt_3_click}
              style={{
                backgroundColor:
                  clicked_item == 6
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? primaryColor
                        : null
                      : null
                    : null,
                color:
                  clicked_item == 6
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? white
                        : null
                      : null
                    : null,
                border:
                  clicked_item == 6
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? "1px solid #f38321"
                        : null
                      : null
                    : null,
              }}
            >
              {bt_3_title}
            </button>
            <button
              key={2}
              onClick={bt_2_click}
              style={{
                backgroundColor:
                  clicked_item == 5
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? primaryColor
                        : null
                      : null
                    : null,
                color:
                  clicked_item == 5
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? white
                        : null
                      : null
                    : null,
                border:
                  clicked_item == 5
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? "1px solid #f38321"
                        : null
                      : null
                    : null,
              }}
            >
              {bt_2_title}
            </button>
            <button
              key={1}
              onClick={bt_1_click}
              style={{
                margin: 0,
                backgroundColor:
                  clicked_item == 4
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? primaryColor
                        : null
                      : null
                    : null,
                color:
                  clicked_item == 4
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? white
                        : null
                      : null
                    : null,
                border:
                  clicked_item == 4
                    ? change_selected_button_border_color_on_focus && selected
                      ? selected.Id == item.Id
                        ? "1px solid #f38321"
                        : null
                      : null
                    : null,
              }}
            >
              {bt_1_title}
            </button>
          </div>
        ) : btn_number == 4 ? (
          <div className="menu_list_item_buttons_container">
            <button key={4} onClick={bt_4_click}>
              {bt_4_title}
            </button>
            <button key={3} onClick={bt_3_click}>
              {bt_3_title}
            </button>
            <button key={2} onClick={bt_2_click}>
              {bt_2_title}
            </button>
            <button key={1} onClick={bt_1_click} style={{ margin: 0 }}>
              {bt_1_title}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MenuListItem;
