import { all, fork } from "redux-saga/effects";
import rootMenu from "./menu/Sagas";
import rootProduct from "./product/Sagas";
import rootSubmenu from "./submenu/sagas";
import rootAppSettings from "./settings/Sagas";
import rootShopEmployess from "./Employees/sagas";
import rootShopSaga from "./shop/Sagas";
import rootWorksStations from "./WorksStations/Sagas";
import rootLogin from "./login/Sagas";
import rootTakings from "./takings/Sagas";
import rootUsers from "./Users/Sagas";
import rootDevices from "./devices/Sagas";
import rootTabelArea from "./GeneralSetting/Sagas";
import rootOrder from "./Orders/Sagas";
import rootCustomer from "./Customers/Sagas";
import rootSales from "./sales/Sagas";
import rootFinancial from "./financial/Sagas";
import rootGeneral from "./MainNavGeneral/Sagas";
import rootSetupSaga from "./setup/Sagas";
import rootWePos from "./WePos/Sagas";
export default function* RootSaga() {
  yield all([
    fork(rootMenu),
    fork(rootProduct),
    fork(rootSubmenu),
    fork(rootAppSettings),
    fork(rootShopEmployess),
    fork(rootShopSaga),
    fork(rootWorksStations),
    fork(rootLogin),
    fork(rootTakings),
    fork(rootUsers),
    fork(rootDevices),
    fork(rootTabelArea),
    fork(rootOrder),
    fork(rootCustomer),
    fork(rootSales),
    fork(rootFinancial),
    fork(rootGeneral),
    fork(rootSetupSaga),
    fork(rootWePos),
  ]);
}
