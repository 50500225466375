import React, { useState, useEffect, lazy } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-bootstrap";
import Select from "react-select";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../components/Loader";
import orderList from "../../orders/List";

import { getWorkStationListAllAction } from "../../../redux/devices/Actions";
import OrderListItem from "../../../components/OrderListItem ";
import ListHeader from "../../../components/ListHeader";
import { main_nav_button_color } from "../../../constants/Colors";
import { primaryColor, main_nav_back_color } from "../../../constants/Colors";
import DevicesHeaderList from "../../../components/DevicesHeaderList";
import DevicesListItem from "../../../components/DevicesListItem";
import { Channels } from "../../orders/List";
const Devices = () => {
  const Device_Status = [
    { label: "Active", value: 1 },
    { label: "Deactive", value: 0 },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  const { devices_worksStation_list, loading_devices_worksStations } =
    useSelector((state) => state.devicesWorkStationReduser);

  const [deviceStatus, setDeviceStatus] = useState({
    label: "Active",
    value: 1,
  });
  const [channel, setChannel] = useState([]);
  const [deviceWorksStationList, setDeviceWorksStationList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [statusAll, setStatusAll] = useState([]);
  const [channelAll, setChannelAll] = useState([]);
  const [channelValues, setChannelValues] = useState([]);
  const [statusValues, setStatusValues] = useState(1);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 500,
      backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleActiveAndDeactive = (list, item) => {
    setDeviceStatus(list);
    const copy_deviceWorksStationList = [...deviceWorksStationList];
    let my_list = [];
    if (item.action == "select-option") {
      if (list.value == 1) {
        for (let i = 0; i < Device_Status.length; i++) {
          if (list.value == Device_Status[i].value) {
            for (
              let index = 0;
              index < copy_deviceWorksStationList.length;
              index++
            ) {
              if (copy_deviceWorksStationList[index].Active == 1) {
                my_list.push(copy_deviceWorksStationList[index]);
              }
            }
          }
        }
      } else if (list.value == 2) {
        for (let i = 0; i < Device_Status.length; i++) {
          if (list.value == Device_Status[i].value) {
            for (
              let index = 0;
              index < copy_deviceWorksStationList.length;
              index++
            ) {
              if (copy_deviceWorksStationList[index].Active == 0) {
                my_list.push(copy_deviceWorksStationList[index]);
              }
            }
          }
        }
      }
    }
    setDeviceList(my_list);
  };
  const handleFilterByStatus = (list, item) => {
    let arrey = [];
    let values;

    const newDeviceList = [...devices_worksStation_list];
    let statusFiltered = [];
    setDeviceStatus(list);
    if (list) {
      values = list.value;
    }

    setStatusValues(values);

    if (item.action == "select-option") {
      if (newDeviceList) {
        if (newDeviceList.length > 0) {
          for (let i = 0; i < newDeviceList.length; i++) {
            if (list.value == newDeviceList[i].Active) {
              statusFiltered.push(newDeviceList[i]);
            }
          }

          if (!channelValues.length > 0 || channelAll[0] == 7) {
            setDeviceList(statusFiltered);
          } else {
            if (channelValues.length > 0) {
              for (let index2 = 0; index2 < channelValues.length; index2++) {
                for (let index3 = 0; index3 < statusFiltered.length; index3++) {
                  if (
                    channelValues[index2] == statusFiltered[index3].DeviceType
                  ) {
                    arrey.push(statusFiltered[index3]);
                  }
                }
              }
            }

            setDeviceList(arrey);
          }
        }
      }
    }
  };
  const handleFilteredByChannels = (list, item) => {
    let arrey = [];
    let values = [];
    let all_value = [];
    const newDeviceList = [...devices_worksStation_list];
    let channelFilltered = [];
    setChannel(list);
    setChannelAll(all_value);
    setChannelValues(values);
    const fillteredList = list.filter((element) => element.value != 7);
    for (let index = 0; index < fillteredList.length; index++) {
      values.push(fillteredList[index].value);
    }
    const fillteredList1 = list.filter((element) => element.value == 7);
    for (let index1 = 0; index1 < fillteredList1.length; index1++) {
      all_value.push(fillteredList1[index1].value);
    }
    if (item.action == "select-option") {
      if (values.length > 0) {
        if (item.option.value == 7) {
          const filter = list.filter((element) => element.value == 7);
          setChannel(filter);
          if (statusValues == 1 || statusValues == 0) {
            for (let index2 = 0; index2 < newDeviceList.length; index2++) {
              if (statusValues == newDeviceList[index2].Active) {
                arrey.push(newDeviceList[index2]);
              }

              setDeviceList(arrey);
            }
          } else {
            setDeviceList(devices_worksStation_list);
          }
          setChannelValues([]);
        } else {
          setChannelAll([]);
          const filter = list.filter((element) => element.value != 7);
          setChannel(filter);
          if (newDeviceList.length > 0) {
            for (let index0 = 0; index0 < newDeviceList.length; index0++) {
              for (let i = 0; i < values.length; i++) {
                if (values[i] == newDeviceList[index0].DeviceType) {
                  channelFilltered.push(newDeviceList[index0]);
                }
              }
            }

            if (statusValues == 1 || statusValues == 0) {
              for (let index3 = 0; index3 < channelFilltered.length; index3++) {
                if (statusValues == channelFilltered[index3].Active) {
                  arrey.push(channelFilltered[index3]);
                }
              }
            } else {
              setDeviceList(channelFilltered);
            }

            setDeviceList(arrey);
          }
        }
      }
    } else if (item.action == "remove-value") {
      if (list.length > 0) {
        if (newDeviceList.length > 0) {
          for (let index0 = 0; index0 < newDeviceList.length; index0++) {
            for (let i = 0; i < values.length; i++) {
              if (values[i] == newDeviceList[index0].DeviceType) {
                channelFilltered.push(newDeviceList[index0]);
              }
            }
          }

          if (statusValues == 1 || statusValues == 0) {
            for (let index3 = 0; index3 < channelFilltered.length; index3++) {
              if (statusValues == channelFilltered[index3].Active) {
                arrey.push(channelFilltered[index3]);
              }
            }

            setDeviceList(arrey);
          } else {
            setDeviceList(channelFilltered);
          }
        }
      } else {
        if (statusValues == 0 || statusValues == 1) {
          for (let index = 0; index < newDeviceList.length; index++) {
            if (statusValues == newDeviceList[index].Active) {
              arrey.push(newDeviceList[index]);
            }
          }
          setDeviceList(arrey);
        } else {
          setDeviceList(devices_worksStation_list);
        }
        channelFilltered.push([]);
      }
    } else if (item.action == "clear") {
      if (statusValues == 1 || statusValues == 0) {
        for (let index1 = 0; index1 < newDeviceList.length; index1++) {
          if (statusValues == newDeviceList[index1].Active) {
            arrey.push(newDeviceList[index1]);
          }

          setDeviceList(arrey);
        }
      } else {
        setDeviceList(devices_worksStation_list);
      }
      channelFilltered.push([]);
    }
  };

  useEffect(() => {
    dispatch(getWorkStationListAllAction(login_key, user_id));
  }, []);

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (devices_worksStation_list) {
      if (devices_worksStation_list.length > 0) {
        const sortArrey = devices_worksStation_list.sort(function (a, b) {
          return a.Daysleft - b.Daysleft;
        });
        setDeviceWorksStationList(sortArrey);
        let my_list = [];
        for (let i = 0; i < sortArrey.length; i++) {
          if (sortArrey[i].Active == 1) {
            my_list.push(sortArrey[i]);
          }
        }
        setDeviceList(my_list);
      }
    }
  }, [devices_worksStation_list]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
      id="countaining"
    >
      <ListHeader title={lang.devuces} show_back={false} />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div
            className="sm-div2"
            style={{ marginBottom: "35px", marginTop: "35px" }}
          >
            <div
              className="sm-m"
              style={{
                width: "45%",
              }}
            >
              <h6 className="h6">{lang.status}</h6>
              <Select
                options={Device_Status}
                defaultValue={deviceStatus}
                isSearchable={false}
                onChange={handleFilterByStatus}
                // value={deviceStatus}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div
              className="sm-m"
              style={{
                width: "45%",
              }}
            >
              <h6 className="h6">{lang.channels}</h6>
              <Select
                options={Channels}
                defaultValue={channel}
                isSearchable={false}
                onChange={handleFilteredByChannels}
                value={channel}
                styles={customStyles}
                isClearable={true}
                isMulti={true}
              />
            </div>
            {/* <div className="sm-m">
              <h6 className="h6">{lang.status}</h6>
              <Select
                options={Device_Status}
                defaultValue={deviceStatus}
                isSearchable={false}
                onChange={handleActiveAndDeactive}
                // value={deviceStatus}
                styles={customStyles}
                isClearable={false}
              />
            </div> */}
          </div>

          <div>
            <DevicesHeaderList button_key={false} />
            {deviceList.map((item, index) => (
              <DevicesListItem
                shop_name={item.ShopName}
                active={item.Active}
                item={item}
                device_type={item.DeviceTypeName}
                expire_date={item.ExpireDate}
                work_station_name={item.WorkStationName}
                button_key={false}
                days_left={item.Daysleft}
                logo={item.Logo}
                domain={item.Domain}
              />
            ))}
          </div>
        </div>
        {/* <Toaster /> */}
        {loading_devices_worksStations ? <Loader /> : null}
      </div>
    </div>
  );
};

export default Devices;
