import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { primaryColor } from "../../../../constants/Colors";
import {
  getAllAppSettingsAction,
  updateSalesReportAction,
} from "../../../../redux/settings/Action";
const SalesReport = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [showCategoriesOnZprint, setShowCategoriesOnZprint] = useState(true);
  const { sales_report_list } = useSelector(
    (state) => state.appSettingsRedusers
  );

  const handleSave = () => {
    dispatch(
      updateSalesReportAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        showCategoriesOnZprint ? 1 : 0
      )
    );
  };
  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleSave();
    },
  }));
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getAllAppSettingsAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (sales_report_list) {
      if (sales_report_list.length > 0) {
        setShowCategoriesOnZprint(sales_report_list[0].ShowCategoriesOnZReport);
      } else {
        setShowCategoriesOnZprint(showCategoriesOnZprint);
      }
    } else {
      setShowCategoriesOnZprint(showCategoriesOnZprint);
    }
  }, [sales_report_list]);
  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div style={{ marginTop: "25px" }}>
            <div className=" div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showCategoriesOnZprint}
                      onChange={() =>
                        setShowCategoriesOnZprint(!showCategoriesOnZprint)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_category_on_zprint}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SalesReport;
