import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
const LoaderMini = () => {
  let [color, setColor] = useState("red");
  return (
    <div className="loader_mini_component_container">
      <ClipLoader
        color={"white"}
        loading={true}
        cssOverride={override}
        size={25}
      />
    </div>
  );
};

export default LoaderMini;
