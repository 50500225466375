import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../constants/Colors";
import { selectedLicenceAction } from "../redux/devices/Actions";
const DevicesSideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { devices_menu } = useSelector((state) => state.selectedLanguage);

  const [deviceList, setDeviceList] = useState([]);
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);
  const [userAccesses, setUserAccesses] = useState([]);
  const [rolesid, setRolesId] = useState([]);
  const [userType, setUserType] = useState("");
  const [id, setId] = useState(0);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const { tab_status } = useSelector((state) => state.categoryList);
  const handleCollapse = (item, myindex) => {
    if (item.id == 2) {
      dispatch(selectedLicenceAction(null));
    }
    navigate(item.id == 1 ? "" : item.path);
    if (item.path) {
      setSelectedPath(item.path);
    }
    const item_copy = { ...item };
    const deviceManu_copy = [...deviceList];

    deviceManu_copy.forEach((element, index) => {
      if (index == myindex) {
        item_copy.expanded = !item_copy.expanded;
        deviceManu_copy[myindex] = item_copy;
      } else {
        element.expanded = false;
        deviceManu_copy[index] = element;
      }
    });
    setDeviceList(deviceManu_copy);

    if (!item.expanded) {
      setSelectedTitleIndex(myindex);
    } else {
      setSelectedTitleIndex(null);
    }
  };

  useEffect(() => {
    if (devices_menu.length > 0) {
      setDeviceList(devices_menu);
    }
  }, [devices_menu]);
  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);
  useEffect(() => {
    let roles_id = [];
    let user_type = "";
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          roles_id.push(userAccesses[i].RoleId);
          user_type = userAccesses[i].UserType;
        }
        setRolesId(roles_id);
        setUserType(user_type);
      }
    }
  }, [userAccesses]);
  useEffect(() => {
    const copy_devices_list = [...deviceList];

    let new_devicesList = [];
    if (userType == "") {
      setDeviceList(devices_menu);
    } else {
      if (userType == 10001201) {
        setDeviceList(devices_menu);
      } else {
        if (copy_devices_list) {
          if (copy_devices_list.length > 0) {
            for (let i = 0; i < copy_devices_list.length; i++) {
              if (rolesid) {
                if (rolesid.length > 0) {
                  for (let ind = 0; ind < rolesid.length; ind++) {
                    if (rolesid[ind] == copy_devices_list[i].access_id) {
                      new_devicesList.push(copy_devices_list[i]);
                      navigate(new_devicesList[0].path);
                    }
                  }
                }
              }
            }

            setDeviceList(new_devicesList);
          }
        }
      }
    }
  }, [rolesid, devices_menu]);

  return (
    <div>
      <div className="user-sideBar-container-div">
        {deviceList.map((item, index) => (
          <div key={index}>
            <div
              className="user-sidebar-title"
              style={{
                backgroundColor:
                  selectedTitleIndex == index ? main_nav_back_color : null,
                borderRightColor: primaryColor,
                borderRightStyle: "solid",
                borderRightWidth: selectedTitleIndex == index ? 4 : 0,
              }}
              onClick={() => handleCollapse(item, index)}
            >
              <div className="sidebar_title_icon_container sidebar_content_color">
                <i class={item.icon} aria-hidden="true"></i>
                <h6 className="m-0 sidebar_content_color">{item.title}</h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevicesSideBar;
