import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showOrderListPopupAction,
  getOrderDetailAction,
} from "../redux/Orders/Actions";
import Loader from "./Loader";
import moment from "moment";
import {
  refundOrderAction,
  visibleRefunPopupAction,
} from "../redux/financial/Actions";
const OrderListPopup = () => {
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { loading_orders, selected_order_list, get_order_detail_list } =
    useSelector((state) => state.ordersReduser);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [width1, setWidth1] = React.useState(window.innerWidth);

  const handleRefunOrder = () => {
    dispatch(showOrderListPopupAction(false));
    dispatch(
      visibleRefunPopupAction([
        true,
        selected_order_list,
        moment(selected_order_list.orderList_start_date).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        moment(selected_order_list.orderList_end_date).format(
          "YYYY-MM-DD hh:mm:ss"
        ),
        selected_shop[0].ShopId,
      ])
    );
  };
  useEffect(() => {
    if (selected_order_list) {
      dispatch(
        getOrderDetailAction(
          login_key,
          user_id,
          selected_order_list.ShopId,
          selected_order_list.OrderId
        )
      );
    }
  }, [selected_order_list]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <div
      className="create_shop_popup_container"
      onClick={() => dispatch(showOrderListPopupAction(false))}
    >
      <div
        className="create_shop_popup_content_container"
        style={{
          backgroundColor: "rgb(245, 245, 245)",
          width: width1 <= 560 ? "95%" : "35%",
          overflowY: "auto",
        }}
        onClick={(i) => i.stopPropagation()}
      >
        <div className="order_detail_main_section_1" style={{}}>
          <div
            style={{
              borderBottom: "1px solid grey",
              height: width1 <= 560 ? "50px" : null,
              display: width1 <= 560 ? "flex" : null,
              alignItems: width1 <= 560 ? "flex-end" : null,
              justifyContent: width1 <= 560 ? "center" : null,
            }}
          >
            {width1 <= 560 ? (
              <div className=" create-shop-close-div">
                <i
                  onClick={() => dispatch(showOrderListPopupAction(false))}
                  style={{
                    fontSize: 33,
                    cursor: "pointer",
                    color: "red",
                    top: 5,
                    right: 10,
                    position: "absolute",
                  }}
                  class="fa fa-times"
                  aria-hidden="true"
                ></i>
              </div>
            ) : null}

            <h5 style={{ color: "grey" }}>Your order</h5>
          </div>
          <div
            style={{
              textAlign: "left",
              // borderBottom: "1px solid grey",
              padding: "10px 0px",
            }}
          >
            <div className="order_detail_ocntainer">
              <p>Order Id </p>
              <p>#{selected_order_list.OrderId}</p>
            </div>
            {selected_order_list.SaleMethodName ? (
              <div className="order_detail_ocntainer">
                <p>Sale Method </p>
                <p>{selected_order_list.SaleMethodName}</p>
              </div>
            ) : null}
            <div className="order_detail_ocntainer">
              <p>PayMethod </p>
              <p> {selected_order_list.PayMethodName}</p>
            </div>
            <div className="order_detail_ocntainer">
              <p>Customer </p>
              <p>{selected_order_list.CustomerFullName}</p>
            </div>{" "}
            <div className="order_detail_ocntainer">
              <p>Email </p>
              <p>{selected_order_list.Mail}</p>
            </div>
            <div className="order_detail_ocntainer">
              <p>Phone </p>
              <p>{selected_order_list.CustomerTelephone}</p>
            </div>
            {selected_order_list.SaleMethod == 1000101 ||
            selected_order_list.SaleMethod == 1000105 ? (
              <div className="order_detail_ocntainer_1">
                <p>Address :</p>
                <p
                  style={{
                    wordWrap: "break-word",
                    overflowWrap: "anywhere",
                  }}
                >
                  {selected_order_list.Flat}{" "}
                  {selected_order_list.CustomerAddress}
                </p>
              </div>
            ) : null}
            {selected_order_list.SpecialNote != "" ? (
              <div className="order_detail_ocntainer_1">
                <p>Special note :</p>
                <p
                  style={{
                    wordWrap: "break-word",

                    overflowWrap: "anywhere",
                  }}
                >
                  {selected_order_list.SpecialNote}
                </p>
              </div>
            ) : null}
          </div>
          <div
            // className="order_dateil_first_box"
            style={{
              borderTop: "1px solid grey",
              borderBottom: "1px solid grey",
            }}
          >
            {get_order_detail_list
              ? get_order_detail_list.length > 0
                ? get_order_detail_list.map((item, index) => (
                    <div
                      className="order_detail_"
                      style={{
                        padding: "15px 0px",
                      }}
                    >
                      <div className="basket_product_container">
                        <div className="basket_product_title_container">
                          <div id="product_number_container">
                            <h6 style={{ margin: 0 }}>{item.Count}</h6>
                          </div>
                          <h6 style={{ margin: 0 }}>
                            {item.CrustName ? item.CrustName : null}{" "}
                            {item.OptionName ? item.OptionName : null}{" "}
                            {item.IsGeneralItem
                              ? item.EditedTitle
                              : item.ProductName}
                          </h6>
                        </div>
                        <h6 style={{ margin: 0 }}>
                          £{item.SubTotal.toFixed(2)}
                          {/* {item.ProudctAmount
                            ? item.CrustAmount
                              ? `£${
                                  item.ProudctAmount.toFixed(2) +
                                  item.CrustAmount.toFixed(2)
                                }`
                              : `£${item.ProudctAmount.toFixed(2)} `
                            : item.OptionAmount
                            ? item.CrustAmount
                              ? `£${
                                  item.OptionAmount.toFixed(2) +
                                  item.CrustAmount.toFixed(2)
                                }`
                              : `£${item.OptionAmount.toFixed(2)}`
                            : `£0.00`} */}
                        </h6>
                      </div>
                      {item.order_detail_subitem_list ? (
                        item.order_detail_subitem_list.length > 0 ? (
                          <div className="basket_sublist_container">
                            {item.order_detail_subitem_list.map(
                              (sub_item, ind) => (
                                <div
                                  style={{
                                    padding: "4px 0px",
                                    width: "100%",
                                  }}
                                >
                                  <div className="basket_product_title_container">
                                    <p style={{ margin: 0 }}>
                                      {sub_item.ItemName
                                        ? sub_item.ItemName
                                        : null}
                                    </p>
                                  </div>
                                  <p style={{ margin: 0 }}>
                                    £{sub_item.ItemAmount.toFixed(2)}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  ))
                : null
              : null}

            {/* {basket_content.length - 1 > index ? <hr></hr> : null} */}
          </div>
          <div style={{ padding: "10px 0px" }}>
            <div className="order_detail_ocntainer">
              <p>Sub total </p>
              <p>£{selected_order_list.SubTotal.toFixed(2)}</p>
            </div>
            {selected_order_list.DeliveryCharge > 0 ? (
              <div className="order_detail_ocntainer">
                <p>Delivery fee </p>
                <p>£{selected_order_list.DeliveryCharge.toFixed(2)}</p>
              </div>
            ) : null}

            {selected_order_list.AdminFee > 0 ? (
              <div className="order_detail_ocntainer">
                <p>Admin fee </p>
                <p>£{selected_order_list.AdminFee.toFixed(2)}</p>{" "}
              </div>
            ) : null}
            {selected_order_list.CarrierBag > 0 ? (
              <div className="order_detail_ocntainer">
                <p>Carrier bag </p>
                <p>£{selected_order_list.CarrierBag.toFixed(2)}</p>
              </div>
            ) : null}
            {selected_order_list.ExtraChargeText ? (
              <div className="order_detail_ocntainer">
                <p>{selected_order_list.ExtraChargeText} </p>
                <p>£{selected_order_list.ExtraChargeAmount.toFixed(2)}</p>
              </div>
            ) : null}
            {selected_order_list.TotalDiscount > 0 ? (
              <div className="order_detail_ocntainer">
                <p>Discount </p>

                <p>£{selected_order_list.TotalDiscount.toFixed(2)}</p>
              </div>
            ) : null}

            {/* ) : null} */}
            <div className="order_detail_ocntainer">
              <p>Total </p>
              <p>£{selected_order_list.ForPay.toFixed(2)}</p>
            </div>
            <div className="order_detail_ocntainer">
              <p>Order date </p>
              <p>
                {" "}
                {moment(selected_order_list.OrderDate).format(
                  "YYYY/MM/DD-HH:mm"
                )}
              </p>
            </div>
            <div className="order_detail_ocntainer">
              <p>Delivery date </p>
              <p>
                {" "}
                {moment(selected_order_list.DeliveryTime).format(
                  "YYYY/MM/DD-HH:mm"
                )}
              </p>
            </div>
          </div>
          <div className="order-popup-container-refund-button">
            <button
              onClick={() => handleRefunOrder()}
              style={{
                backgroundColor: selected_order_list.RefoundList
                  ? "rgb(11, 163, 11)"
                  : null,
              }}
              disabled={selected_order_list.RefoundList ? true : false}
            >
              {selected_order_list.RefoundList ? lang.refunded : lang.refund}
            </button>
          </div>
        </div>

        {loading_orders ? <Loader /> : null}
      </div>
    </div>
  );
};

export default OrderListPopup;
