import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import store from "store2";
import {
  visibleCreateShopPopupAction,
  createShopAction,
  setDefaultAction,
} from "../redux/shop/Actions";

const CreateShop = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loging_response = store.get("login_response");
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { result_successfull, result_failed, result_message } = useSelector(
    (state) => state.shopReducer
  );

  const [shopName, setShopName] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");

  const handleCreateClicked = () => {
    if (
      shopName == "" ||
      phone == "" ||
      city == "" ||
      address == "" ||
      postcode == ""
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        createShopAction(
          login_key,
          0,
          user_id,
          shopName,
          phone,
          address,
          postcode,
          city,
          loging_response.UserAccesses[0].UserType
        )
      );
    }
  };

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  return (
    <div className="create_shop_popup_container">
      <div
        className="create_shop_popup_content_container"
        style={{
          zIndex: "1000",
        }}
      >
        {/* PAGE TITLE */}
        <div className="create_shop_header_container">
          <h5>{lang.create_new_shop}</h5>
        </div>

        {/* TITLE */}
        <div className="create_shop_inputs_container">
          <form>
            <h6>{lang.shop_name}</h6>
            <input
              type="text"
              value={shopName}
              onChange={(str) => setShopName(str.target.value)}
            />
          </form>
        </div>
        {/* PHONE */}
        <div className="create_shop_inputs_container">
          <form>
            <h6>{lang.mobile_phone}</h6>
            <input
              type="text"
              value={phone}
              onChange={(str) => setPhone(str.target.value)}
            />
          </form>
        </div>
        {/* ADDRESS */}
        <div className="create_shop_inputs_container">
          <form>
            <h6>{lang.address}</h6>
            <input
              type="text"
              value={address}
              onChange={(str) => setAddress(str.target.value)}
            />
          </form>
        </div>
        {/* POSTCODE */}
        <div className="create_shop_inputs_container">
          <form>
            <h6>{lang.postcode}</h6>
            <input
              type="text"
              value={postcode}
              onChange={(str) => setPostcode(str.target.value)}
            />
          </form>
        </div>
        {/* CITY */}
        <div className="create_shop_inputs_container">
          <form>
            <h6>{lang.city}</h6>
            <input
              type="text"
              value={city}
              onChange={(str) => setCity(str.target.value)}
            />
          </form>
        </div>

        {/* CREATE BUTTON */}
        <div className="create_shop_button_container">
          <button onClick={handleCreateClicked}>
            <h6>{lang.create}</h6>
          </button>
        </div>

        {/* CLOSE */}
        <div className=" create-shop-close-div">
          <i
            onClick={() => dispatch(visibleCreateShopPopupAction(false))}
            style={{ fontSize: 30, cursor: "pointer" }}
            class="fa fa-times"
            aria-hidden="true"
          ></i>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default CreateShop;
