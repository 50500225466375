import axios from "axios";
import { lang } from "moment";
import { baseUrl } from "../constants/BaseUrl";

export const loginService = async (lat, long, user_name, password) => {
  return await axios.put(`${baseUrl}loginuser/`, {
    Lat: lat,
    Long: long,
    UserName: user_name,
    Password: password,
  });
};

//UPDATE PROFILE
const formData = new FormData();
export const updateProfileService = async (
  login_key,
  profile_image,
  id,
  first_name,
  last_name,
  mobile,
  address,
  lastImageProfileName
) => {
  // formData.append("LoginKey", login_key);
  // formData.append("ProfileImage", profile_image);
  // formData.append("Id", id);
  // formData.append("FirstName", first_name);
  // formData.append("LastName", last_name);
  // formData.append("Mobile", mobile);
  // formData.append("Address", address);
  // formData.append("LastProfileImageName", lastImageProfileName);
  return await axios.post(
    `${baseUrl}updateProfile/`,
    login_key
    // {
    //   LoginKey:login_key,
    //   formData,
    //   Id:id,
    //   FirstName:first_name,
    //   LastName:last_name,
    //   Mobile:mobile,
    //   Address:address
    // }
  );
};

export const sendOTPLoginService = async (login_key, user_id, mobile) => {
  return await axios.put(`${baseUrl}sendOtpLogin/`, {
    LoginKey: login_key,
    UserId: user_id,
    Mobile: mobile,
  });
};

export const verifyOTPLoginService = async (
  login_key,
  user_id,
  mobile,
  OTP_code
) => {
  return await axios.put(`${baseUrl}verifyOtpLogin/`, {
    LoginKey: login_key,
    UserId: user_id,
    Mobile: mobile,
    OtpCode: OTP_code,
  });
};

// ACTIVE TWO FACTOR AUTH

export const activeTwoFactorService = async (
  login_key,
  user_id,
  active_two_factor
) => {
  return await axios.put(`${baseUrl}updateSetupTwoFactory/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: user_id,
    SetupTwoFactor: active_two_factor,
  });
};
