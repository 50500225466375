import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";

import {
  getCategoriesListAction,
  getDropDownAction,
} from "../redux/menu/Actions";
import Select from "react-select";
import store from "store2";
import {
  getShopsListSuccessfullAction,
  getNewShopListActin,
  getShopsListAction,
} from "../redux/shop/Actions";
import {
  getSubmenuAction,
  isDressingAction,
  selectedSubmenuAction,
} from "../redux/submenu/Action";
import { getWorksStationsAction } from "../redux/WorksStations/Actions";
import { selecteWorksStationsAction } from "../redux/WorksStations/Actions";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../constants/Colors";
import { showSetupPpopupAction } from "../redux/setup/Actions";
import { defaultMapCenterAction } from "../redux/GeneralSetting/Actions";

const options = [
  {
    value: "alllll",
    label: "alllll",
    id: 1,
    ghh: "hghgh",
    jhjhjh: "ytutsuc",
    hhgh: 6565,
  },
  {
    value: "none",
    label: "none",
    id: 2,
    ghh: "hghgh",
    jhjhjh: "ytutsuc",
    hhgh: 6565,
  },
  {
    value: "vanilla",
    label: "Vanilla",
    id: 3,
    ghh: "hghgh",
    jhjhjh: "ytutsuc",
    hhgh: 6565,
  },
  {
    value: "one",
    label: "one",
    id: 4,
    ghh: "hghgh",
    jhjhjh: "ytutsuc",
    hhgh: 6565,
  },
  {
    value: "two",
    label: "two",
    id: 5,
    ghh: "hghgh",
    jhjhjh: "ytutsuc",
    hhgh: 6565,
  },
];

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstUpdate = useRef(true);
  const { main_menu } = useSelector((state) => state.selectedLanguage);
  const { shops_list, selected_shop, result_successfull } = useSelector(
    (state) => state.shopReducer
  );
  const { show_setup_popup } = useSelector((state) => state.setupReduser);
  const [sideBarList, setSideBarList] = useState([]);
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);
  const [selectedSublistId, setSelectedSublistId] = useState(21);
  const [selectedPath, setSelectedPath] = useState("");
  const [shopList, setShopList] = useState(null);
  const [userAccesses, setUserAccesses] = useState([]);
  const [rolesid, setRolesId] = useState([]);
  const [accessShopId, setAccessShopId] = useState(null);
  const [shopsList, setShopsList] = useState([]);
  const [userType, setUserType] = useState("");
  const [selectAnotherShop, setSelectAnotherShop] = useState(false);
  const [firstUseEffectStatus, setFirstUseEffectStatus] = useState(false);
  const [shopListHeight, setShopListHeight] = useState(500);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const {
    login_key,
    user_id,
    profile_list,
    shop_access_list,
    result_login_successfull,
    user_type,
  } = useSelector((state) => state.loginReducer);
  const { tab_status } = useSelector((state) => state.categoryList);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 40,
      minHeight: " 40px",

      overflowY: "auto",

      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      overflowY: "auto",
      maxHeight: shopListHeight,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleCollapse = (item, myindex) => {
    const item_copy = { ...item };
    const menu_copy = [...sideBarList];

    if (item.id == 6) {
      dispatch(selecteWorksStationsAction(null));
    }
    if (item.id == 12) {
      dispatch(showSetupPpopupAction(true));
    }

    // navigate(item.id == 2 ? "" : item.path ? item.path : selectedPath);
    navigate(item.id == 2 ? "" : item.path);
    setSelectedSublistId(null);
    if (item.path) {
      setSelectedPath(item.path);
    }
    if (item.sublist.length > 0) {
      setSelectedSublistId(item.sublist[0].id);
    }

    menu_copy.forEach((element, index) => {
      if (index == myindex) {
        item_copy.expanded = !item_copy.expanded;
        menu_copy[myindex] = item_copy;
      } else {
        element.expanded = false;
        menu_copy[index] = element;
      }
    });

    setSideBarList(menu_copy);
    if (item.sublist.length > 0) {
      if (!item.expanded) {
        setSelectedTitleIndex(myindex);
      } else {
        setSelectedTitleIndex(null);
      }
    } else {
      setSelectedTitleIndex(myindex);
    }
  };

  const handleSubListClicked = (p, id) => {
    if (id == 41) {
      dispatch(
        getSubmenuAction(login_key, user_id, selected_shop[0].ShopId, 0)
      );
    }
    if (id == 22) {
      dispatch(selectedSubmenuAction(null));
      dispatch(isDressingAction(false));
    } else if (id == 23) {
      dispatch(isDressingAction(true));
    }
    navigate(p);
    setSelectedPath(p);
    setSelectedSublistId(id);
  };

  const handleChangeDropDownValue = (str, sl) => {
    dispatch(getShopsListSuccessfullAction(shops_list, [str]));

    // dispatch(getWorksStationsAction(login_key, user_id, [str][0].ShopId));

    // if (sl.option.id == 1) {
    //   setShopList([{ value: "all", label: "all", id: 1 }]);
    // } else if (sl.option.id == 2) {
    //   setShopList([{ value: "none", label: "none", id: 2 }]);
    // } else {
    //   let id_list = [];
    //   for (let i = 0; i < str.length; i++) {
    //     id_list.push(str[i].id);
    //   }
    //   if (id_list.includes(1) || id_list.includes(2)) {
    //     setShopList([sl.option]);
    //   } else {
    //     setShopList(str);
    //   }
    // }
  };
  useEffect(() => {
    if (!show_setup_popup) {
      let copy_sideBarList = [...sideBarList];
      if (copy_sideBarList) {
        if (copy_sideBarList.length > 0) {
          for (let i = 0; i < copy_sideBarList.length; i++) {
            if (i == 0) {
              setSelectedTitleIndex(0);
              navigate(copy_sideBarList[i].path);
            }
          }
        }
      }
    }
  }, [show_setup_popup]);
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        setShopList(selected_shop[0]);
        dispatch(
          getCategoriesListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            0
          )
        );
        dispatch(
          getSubmenuAction(login_key, user_id, selected_shop[0].ShopId, 0)
        );
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (main_menu) {
      setSideBarList(main_menu);
    }
  }, [main_menu]);

  useEffect(() => {
    if (shopList) {
      dispatch(getDropDownAction(login_key, user_id, shopList.ShopId));
    }
  }, [shopList]);

  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);
  useEffect(() => {
    let roles_id = [];
    let access_shop_id = null;
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          roles_id.push(userAccesses[i].RoleId);
          access_shop_id = userAccesses[i].AccessShopId;
          setUserType(userAccesses[0].UserType);
        }
        setRolesId(roles_id);
        setAccessShopId(access_shop_id);
      }
    }
  }, [userAccesses]);

  useEffect(() => {
    let new_sideBarList = [];
    let new_sideBarList1 = [];
    let new_sublist = [];
    let sub_list = [];
    const copy_sideBarList = [...sideBarList];
    if (userType == "") {
      setSideBarList(main_menu);
    } else {
      if (userType == 10001201) {
        setSideBarList(main_menu);
      } else {
        if (sideBarList) {
          if (sideBarList.length > 0) {
            for (let i = 0; i < copy_sideBarList.length; i++) {
              if (rolesid) {
                if (rolesid.length > 0) {
                  if (!rolesid.includes(1)) {
                    if (copy_sideBarList[i].id == 2) {
                      if (rolesid.includes(17)) {
                        new_sideBarList.push({
                          ...copy_sideBarList[i],
                          // icon: "",
                          // sublist: [],
                        });
                      } else {
                        new_sideBarList.push({
                          ...copy_sideBarList[i],
                          isDisabled: true,
                        });
                      }
                    }
                  } else {
                    if (copy_sideBarList[i].id == 2) {
                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  }
                  if (!rolesid.includes(13)) {
                    if (copy_sideBarList[i].id == 10) {
                      new_sideBarList.push({
                        ...copy_sideBarList[i],
                        isDisabled: true,
                      });
                    }
                  } else {
                    if (copy_sideBarList[i].id == 10) {
                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  }
                  if (
                    rolesid.includes(6) ||
                    rolesid.includes(5) ||
                    rolesid.includes(4) ||
                    rolesid.includes(3) ||
                    rolesid.includes(2) ||
                    rolesid.includes(16)
                  ) {
                    let copy_sublist = [...copy_sideBarList[i].sublist];

                    if (copy_sideBarList[i].id == 4) {
                      for (let ind = 0; ind < copy_sublist.length; ind++) {
                        if (!rolesid.includes(16)) {
                          if (copy_sublist[ind].id == 39) {
                            new_sublist.push({
                              ...copy_sublist[ind],
                              isDisabled: true,
                            });
                          }
                        } else {
                          if (copy_sublist[ind].id == 39) {
                            new_sublist.push(copy_sublist[ind]);
                            sub_list.push(copy_sublist[ind]);
                          }
                        }
                        if (!rolesid.includes(2)) {
                          if (copy_sublist[ind].id == 40) {
                            new_sublist.push({
                              ...copy_sublist[ind],
                              isDisabled: true,
                            });
                          }
                        } else {
                          if (copy_sublist[ind].id == 40) {
                            new_sublist.push(copy_sublist[ind]);
                            sub_list.push(copy_sublist[ind]);
                          }
                        }
                        if (!rolesid.includes(3)) {
                          if (copy_sublist[ind].id == 41) {
                            new_sublist.push({
                              ...copy_sublist[ind],
                              isDisabled: true,
                            });
                          }
                        } else {
                          if (copy_sublist[ind].id == 41) {
                            new_sublist.push(copy_sublist[ind]);
                            sub_list.push(copy_sublist[ind]);
                          }
                        }
                        if (!rolesid.includes(5)) {
                          if (copy_sublist[ind].id == 42) {
                            new_sublist.push({
                              ...copy_sublist[ind],
                              isDisabled: true,
                            });
                          }
                        } else {
                          if (copy_sublist[ind].id == 42) {
                            new_sublist.push(copy_sublist[ind]);
                            sub_list.push(copy_sublist[ind]);
                          }
                        }
                        if (!rolesid.includes(4)) {
                          if (copy_sublist[ind].id == 43) {
                            new_sublist.push({
                              ...copy_sublist[ind],
                              isDisabled: true,
                            });
                          }
                        } else {
                          if (copy_sublist[ind].id == 43) {
                            new_sublist.push(copy_sublist[ind]);
                            sub_list.push(copy_sublist[ind]);
                          }
                        }
                        if (!rolesid.includes(6)) {
                          if (copy_sublist[ind].id == 44) {
                            new_sublist.push({
                              ...copy_sublist[ind],
                              isDisabled: true,
                            });
                          }
                        } else {
                          if (copy_sublist[ind].id == 44) {
                            new_sublist.push(copy_sublist[ind]);
                            sub_list.push(copy_sublist[ind]);
                          }
                        }
                      }

                      copy_sideBarList[i].sublist = new_sublist;

                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  } else {
                    if (copy_sideBarList[i].id == 4) {
                      new_sideBarList.push({
                        ...copy_sideBarList[i],
                        isDisabled: true,
                      });
                    }
                  }

                  if (!rolesid.includes(7)) {
                    if (copy_sideBarList[i].id == 5) {
                      new_sideBarList.push({
                        ...copy_sideBarList[i],
                        isDisabled: true,
                      });
                    }
                  } else {
                    if (copy_sideBarList[i].id == 5) {
                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  }
                  if (!rolesid.includes(8) && !rolesid.includes(12)) {
                    if (copy_sideBarList[i].id == 6) {
                      new_sideBarList.push({
                        ...copy_sideBarList[i],
                        isDisabled: true,
                      });
                    }
                  } else {
                    if (copy_sideBarList[i].id == 6) {
                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  }

                  if (!rolesid.includes(9)) {
                    if (copy_sideBarList[i].id == 7) {
                      new_sideBarList.push({
                        ...copy_sideBarList[i],
                        isDisabled: true,
                      });
                    }
                  } else {
                    if (copy_sideBarList[i].id == 7) {
                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  }
                  if (!rolesid.includes(10)) {
                    if (copy_sideBarList[i].id == 8) {
                      new_sideBarList.push({
                        ...copy_sideBarList[i],
                        isDisabled: true,
                      });
                    }
                  } else {
                    if (copy_sideBarList[i].id == 8) {
                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  }
                  if (!rolesid.includes(11)) {
                    if (copy_sideBarList[i].id == 9) {
                      new_sideBarList.push({
                        ...copy_sideBarList[i],
                        isDisabled: true,
                      });
                    }
                  } else {
                    if (copy_sideBarList[i].id == 9) {
                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  }
                  if (!rolesid.includes(15)) {
                    if (copy_sideBarList[i].id == 12) {
                      new_sideBarList.push({
                        ...copy_sideBarList[i],
                        isDisabled: true,
                      });
                    }
                  } else {
                    if (copy_sideBarList[i].id == 12) {
                      new_sideBarList.push(copy_sideBarList[i]);
                    }
                  }
                }
              }
            }
            for (let index1 = 0; index1 < new_sideBarList.length; index1++) {
              if (!new_sideBarList[index1].isDisabled) {
                if (sub_list) {
                  if (sub_list.length > 0) {
                    new_sideBarList1.push({
                      ...new_sideBarList[index1],
                      path:
                        sub_list[0].parent_id == new_sideBarList[index1].id
                          ? sub_list[0].path
                          : new_sideBarList[index1].path,
                    });
                  } else {
                    new_sideBarList1.push(new_sideBarList[index1]);
                  }
                } else {
                  new_sideBarList1.push(new_sideBarList[index1]);
                }
              }
            }

            for (let index2 = 0; index2 < new_sideBarList1.length; index2++) {
              navigate(new_sideBarList1[0].path);
            }

            setSideBarList(new_sideBarList1);
          }
        }
      }
    }
  }, [rolesid, main_menu]);

  useEffect(() => {
    let copy_shopsList = [...shops_list];
    let shop_list = [];
    if (userType != "") {
      if (userType == 10001201) {
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        } else {
          if (selected_shop) {
            if (selected_shop.length > 0) {
              if (selected_shop[0].ShopId == copy_shopsList[0].ShopId) {
                setShopList(copy_shopsList[0]);
              } else {
                setShopList(selected_shop[0]);
              }
            }
          }
        }

        setShopsList(copy_shopsList);
      } else {
        if (copy_shopsList) {
          if (copy_shopsList.length > 0) {
            for (let i = 0; i < copy_shopsList.length; i++) {
              if (accessShopId) {
                if (accessShopId == copy_shopsList[i].ShopId) {
                  shop_list.push(copy_shopsList[i]);
                  setShopList(copy_shopsList[i]);
                }
              } else {
                if (shop_access_list) {
                  if (shop_access_list.length > 0) {
                    for (
                      let index = 0;
                      index < shop_access_list.length;
                      index++
                    ) {
                      if (
                        copy_shopsList[i].ShopId ==
                        shop_access_list[index].ShopId
                      ) {
                        shop_list.push(copy_shopsList[i]);
                        if (
                          selected_shop[0].ShopId == copy_shopsList[0].ShopId
                        ) {
                          setShopList(shop_list[0]);
                        } else {
                          setShopList(selected_shop[0]);
                        }
                      }
                    }
                  }
                }
              }
            }

            setShopsList(shop_list);
            dispatch(getNewShopListActin(shop_list));
          }
        }
      }
    } else {
      if (selected_shop) {
        if (selected_shop.length > 0) {
          if (selected_shop[0].ShopId == copy_shopsList[0].ShopId) {
            setShopList(selected_shop[0]);
          } else {
            setShopList(selected_shop[0]);
          }
        }
      }

      setShopsList(copy_shopsList);
      // setShopList(copy_shopsList[0]);
    }
  }, [shop_access_list, accessShopId, shops_list]);
  const loging_response = store.get("login_response");
  useEffect(() => {
    if (shopsList) {
      if (shopsList.length > 0) {
        dispatch(getShopsListSuccessfullAction(shops_list, [shopList]));
      }
    }
  }, [shopsList, shops_list]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1300) {
        setShopListHeight(400);
      } else {
        setShopListHeight(500);
      }
    }
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (window.innerWidth < 1300) {
      setShopListHeight(400);
    } else {
      setShopListHeight(500);
    }
  }, []);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  // useEffect(() => {
  //   if (shopList) {
  //     fromAddress(shopList.Postcode)
  //       .then(({ results }) => {
  //         const { lat, lng } = results[0].geometry.location;
  //         dispatch(defaultMapCenterAction({ lat: lat, lng: lng }));
  //       })
  //       .catch(console.error);
  //   }
  // }, [shopList]);

  return (
    <div
      style={{
        height: window.innerHeight - 160,
        // overflowY: "scroll",
        // marginRight: "10px", display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",

        // paddingLeft: "20px",
      }}
      className="container-main-nav"
    >
      {shopsList ? (
        shopsList.length > 0 ? (
          (shopsList.length == 1 && !accessShopId) || accessShopId ? (
            shopsList.map((item) => (
              <div className="side-bar-first-div-1">{item.label}</div>
            ))
          ) : (
            <div className="side-bar-first-div" style={{ marginLeft: "11px" }}>
              <Select
                options={shopsList}
                defaultValue={shopList}
                isSearchable={true}
                onChange={handleChangeDropDownValue}
                value={shopList}
                // isMulti={true}
                styles={customStyles}
              />
            </div>
          )
        ) : null
      ) : null}

      <div
        className="container_sidebar_title"
        style={{
          height: window.innerHeight - 210,
          width: "220px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          overflowY: "scroll",
          direction: "rtl",
        }}
      >
        {sideBarList.map((item, index) =>
          !item.isDisabled ? (
            <div
              style={{
                width: "220px",
              }}
            >
              <div
                onClick={() => handleCollapse(item, index)}
                className="sidebar_title"
                style={{
                  direction: "ltr",
                  width: "95%",
                  backgroundColor:
                    selectedTitleIndex == index ? main_nav_back_color : null,
                  borderRightColor: primaryColor,
                  borderRightStyle: "solid",
                  borderRightWidth: selectedTitleIndex == index ? 4 : 0,
                }}
              >
                <div className="sidebar_up_down_container">
                  {item.sublist.length > 0 ? (
                    <i
                      className={
                        item.expanded
                          ? "fa fa-chevron-down sidebar_content_color"
                          : "fa fa-chevron-right sidebar_content_color"
                      }
                      aria-hidden="true"
                    ></i>
                  ) : null}
                </div>
                <div className="sidebar_title_icon_container sidebar_content_color">
                  <i class={item.icon} aria-hidden="true"></i>
                  <h6 className="m-0 sidebar_content_color">{item.title}</h6>
                </div>
              </div>
              {item.expanded ? (
                <div>
                  {item.sublist.map((item) =>
                    !item.isDisabled ? (
                      <div
                        style={{
                          width: "95%",
                          backgroundColor:
                            selectedSublistId == item.id
                              ? main_nav_back_color
                              : null,
                          direction: "ltr",
                        }}
                        className="sidebar_li_subtitle"
                        onClick={() => handleSubListClicked(item.path, item.id)}
                      >
                        <div>
                          <i class={item.icon} aria-hidden="true"></i>
                          <p className="p-0 m-0">{item.title}</p>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
              ) : null}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default SideBar;
