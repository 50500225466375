import * as actionTypes from "../ActionTypes";

export const ordersListAction = (
  login_key,
  user_id,
  shop_id,
  start_date,
  end_date
) => {
  return {
    type: actionTypes.ORDERS_LIST,
    payload: { login_key, user_id, shop_id, start_date, end_date },
  };
};

export const orderListSuccessfullAction = (data) => {
  return {
    type: actionTypes.ORDERS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const orderListFailedAction = () => {
  return {
    type: actionTypes.ORDERS_LIST_FAILED,
  };
};
export const selectedOrderListAction = (data) => {
  return {
    type: actionTypes.SELECTED_ORDER_LIST,
    payload: data,
  };
};
export const showOrderListPopupAction = (data) => {
  return {
    type: actionTypes.SHOW_ORDER_LIST_POPUP,
    payload: data,
  };
};
export const getOrderDetailAction = (login_key, user_id, shop_id, order_id) => {
  return {
    type: actionTypes.GET_ORDER_DETAIL,
    payload: { login_key, user_id, shop_id, order_id },
  };
};
export const getOrderDetailSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_ORDER_DETAIL_SUCCESSFULL,
    payload: data,
  };
};
export const getOrderDetailFailedAction = () => {
  return {
    type: actionTypes.GET_ORDER_DETAIL_FAILED,
  };
};
export const getOrderListAppShopsAction = (
  login_key,
  user_id,
  order_status
) => {
  return {
    type: actionTypes.GET_ORDER_LIST_APP_SHOPS,
    payload: {
      login_key,
      user_id,
      order_status,
    },
  };
};

export const getOrderListAppShopsPendingSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_ORDER_LIST_APP_SHOPS_PENDING_SUCCESSFULL,
    payload: data,
  };
};
export const getOrderListAppShopsFailedSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_ORDER_LIST_APP_SHOPS_FAILED_SUCCESSFULL,
    payload: data,
  };
};
export const getOrderListAppShopsInCompleteSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_ORDER_LIST_APP_SHOPS_INCOMPLETE_SUCCESSFULL,
    payload: data,
  };
};
export const getOrderListAppShopsNotPrintedSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_ORDER_LIST_APP_SHOPS_NOTPRINTED_SUCCESSFULL,
    payload: data,
  };
};
export const getOrderListAppShopsRejectedSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_ORDER_LIST_APP_SHOPS_REJECTED_SUCCESSFULL,
    payload: data,
  };
};
export const getOrderListAppShopsFailedAction = (data) => {
  return {
    type: actionTypes.GET_ORDER_LIST_APP_SHOPS_FAILED,
    payload: data,
  };
};

export const backToOrderAction = (data) => {
  return {
    type: actionTypes.BACK_TO_ORDER,
    payload: data,
  };
};
