import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";

import SaveMenuButton from "../../../../components/SaveMenuButton";
import {
  getAllAppSettingsAction,
  updateShopSettingAppColourAction,
} from "../../../../redux/settings/Action";

const Colour = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { setting_app_colour } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const [
    displayOrderLastingMorethanMinutes,
    setDisplayOrderLastingMorethanMinutes,
  ] = useState("");
  const [printedOrdersColour, setPrintedOrdersColour] = useState("#d0021b");
  const [orderQueueListColour, setOrderQueueListColour] = useState("#d0021b");
  const [assingDeliverFlashingColour, setAssingDeliverFlashingColour] =
    useState("#d0021b");
  const [
    showColorPickerPrintedOrdersColour,
    setShowColorPickerPrintedOrdersColour,
  ] = useState(false);
  const [
    showColorPickerOrderQueueListColour,
    setShowColorPickerOrderQueueListColour,
  ] = useState(false);
  const [
    showColorPickerAssingDeliverFlashingColour,
    setShowColorPickerAssingDeliverFlashingColour,
  ] = useState(false);

  const handleInputPrintedOrdersColour = (e) => {
    setPrintedOrdersColour(e.target.value);
  };
  const handleClose = () => {
    setShowColorPickerPrintedOrdersColour(false);
  };
  const handleChangeCompletePrintedOrdersColour = (color) => {
    setPrintedOrdersColour(color.hex);
    document.getElementById("OrdersColour").value = color.hex;
  };
  const handleOnclickeInputPrintedOrdersColour = () => {
    setShowColorPickerPrintedOrdersColour(!showColorPickerPrintedOrdersColour);
    setShowColorPickerOrderQueueListColour(false);
    setShowColorPickerAssingDeliverFlashingColour(false);
  };

  const handleInputOrderQueueListColour = (e) => {
    setOrderQueueListColour(e.target.value);
  };
  const handleCloseOrderQueueListColour = () => {
    setShowColorPickerOrderQueueListColour(false);
  };
  const handleChangeCompleteOrderQueueListColour = (color) => {
    setOrderQueueListColour(color.hex);
    document.getElementById("QueueListColour").value = color.hex;
  };
  const handleOnclickeInputOrderQueueListColour = () => {
    setShowColorPickerOrderQueueListColour(
      !showColorPickerOrderQueueListColour
    );
    setShowColorPickerAssingDeliverFlashingColour(false);
    setShowColorPickerPrintedOrdersColour(false);
  };

  const handleInputAssingDeliverFlashingColour = (e) => {
    setAssingDeliverFlashingColour(e.target.value);
  };
  const handleCloseAssingDeliverFlashingColour = () => {
    setShowColorPickerAssingDeliverFlashingColour(false);
  };
  const handleChangeCompleteAssingDeliverFlashingColour = (color) => {
    setAssingDeliverFlashingColour(color.hex);
    document.getElementById("FlashingColour").value = color.hex;
  };
  const handleOnclickeInputAssingDeliverFlashingColour = () => {
    setShowColorPickerAssingDeliverFlashingColour(
      !showColorPickerOrderQueueListColour
    );
    setShowColorPickerPrintedOrdersColour(false);
    setShowColorPickerOrderQueueListColour(false);
  };

  const handleSaveClicked = () => {
    dispatch(
      updateShopSettingAppColourAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        displayOrderLastingMorethanMinutes
          ? parseInt(displayOrderLastingMorethanMinutes)
          : 0,
        orderQueueListColour,
        assingDeliverFlashingColour,
        printedOrdersColour
      )
    );
  };

  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleSaveClicked();
    },
  }));

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    displayOrderLastingMorethanMinutes,
    orderQueueListColour,
    assingDeliverFlashingColour,
    printedOrdersColour,
  ]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getAllAppSettingsAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (setting_app_colour.length > 0) {
      setDisplayOrderLastingMorethanMinutes(
        setting_app_colour[0].DisplayOrderLastingMorethanMinutes
      );
      setOrderQueueListColour(setting_app_colour[0].OrderQueueListColour);
      setAssingDeliverFlashingColour(
        setting_app_colour[0].AssingDriverFlashingColour
      );
      setPrintedOrdersColour(setting_app_colour[0].PrintedOrdersColour);
    }
  }, [setting_app_colour]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1-input-text colour-div">
            <h6 className="h6 AS-COLOR-first-h6">
              {" "}
              {lang.display_order_lasting_moretthan_minutes}
            </h6>
            <input
              className=" color-first-input input-padding"
              value={displayOrderLastingMorethanMinutes}
              onChange={(e) =>
                setDisplayOrderLastingMorethanMinutes(e.target.value)
              }
            />
          </div>

          <div
            className="ASOP-div1-input-text colour-div"
            style={{ position: "relative" }}
          >
            <h6 className="h6 color-h6">
              {lang.assing_driver_flashing_colour}
            </h6>
            <div className="bachgroundColor-div">
              <div
                className="bachgroundColor"
                style={{ backgroundColor: printedOrdersColour }}
              ></div>
              <input
                className="backgroundColor-input"
                onMouseOver={handleOnclickeInputPrintedOrdersColour}
                Value={printedOrdersColour}
                onChange={handleInputPrintedOrdersColour}
                id="OrdersColour"
              />
            </div>
            {showColorPickerPrintedOrdersColour ? (
              <div
                className="COLOUR-SketchPicker-div"
                onMouseLeave={handleClose}
              >
                <SketchPicker
                  color={printedOrdersColour}
                  onChangeComplete={handleChangeCompletePrintedOrdersColour}
                />
              </div>
            ) : null}
          </div>

          <div
            className="ASOP-div1-input-text colour-div"
            style={{ position: "relative" }}
          >
            <h6 className="h6 color-h6">{lang.order_queue_list_colour}</h6>
            <div className="bachgroundColor-div">
              <div
                className="bachgroundColor"
                style={{ backgroundColor: orderQueueListColour }}
              ></div>
              <input
                className="backgroundColor-input"
                onMouseOver={handleOnclickeInputOrderQueueListColour}
                Value={orderQueueListColour}
                onChange={handleInputOrderQueueListColour}
                id="QueueListColour"
              />
            </div>
            {showColorPickerOrderQueueListColour ? (
              <div
                className="COLOUR-SketchPicker-div"
                onMouseLeave={handleCloseOrderQueueListColour}
              >
                <SketchPicker
                  color={orderQueueListColour}
                  onChangeComplete={handleChangeCompleteOrderQueueListColour}
                />
              </div>
            ) : null}
          </div>

          <div
            className="ASOP-div1-input-text colour-div"
            style={{ position: "relative" }}
          >
            <h6 className="h6 color-h6">{lang.printed_orders_colour}</h6>
            <div className="bachgroundColor-div">
              <div
                className="bachgroundColor"
                style={{ backgroundColor: assingDeliverFlashingColour }}
              ></div>
              <input
                className="backgroundColor-input"
                onMouseOver={handleOnclickeInputAssingDeliverFlashingColour}
                Value={assingDeliverFlashingColour}
                onChange={handleInputAssingDeliverFlashingColour}
                id="FlashingColour"
              />
            </div>
            {showColorPickerAssingDeliverFlashingColour ? (
              <div
                className="COLOUR-SketchPicker-div"
                onMouseLeave={handleCloseAssingDeliverFlashingColour}
              >
                <SketchPicker
                  color={assingDeliverFlashingColour}
                  onChangeComplete={
                    handleChangeCompleteAssingDeliverFlashingColour
                  }
                />
              </div>
            ) : null}
          </div>
          {/* <SaveMenuButton  /> */}
        </div>
      </div>
    </div>
  );
});

export default Colour;
