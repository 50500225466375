import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import ListHeader from "../../components/ListHeader";
import Loader from "..//../components/Loader";
import toast, { Toaster } from "react-hot-toast";
import { marginNormalMenuHorizontal } from "../../constants/Sizes";
import MenuListItem from "../../components/MenuListItem";
import { main_nav_button_color } from "../../constants/Colors";
import { setDefaultAction } from "../../redux/menu/Actions";
import {
  visibleShopGroupPopupAction,
  shopGroupListAction,
  deleteShopGroupAction,
  selectedShopGroupAction,
  shopListByGroupIdAction,
} from "../../redux/Users/Actions";
import { changeSelectedButtonBorderColorOnFocus } from "../../redux/menu/Actions";
const ShopGroup = () => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { selected_shop, get_shop_detail } = useSelector(
    (state) => state.shopReducer
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    employees_list,
    visible_shop_group_popup,
    shop_group_list,
    selected_shop_group,
    loading_users,
    result_successfull,
    result_message,
    result_failed,
  } = useSelector((state) => state.usersReduser);
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );

  const [shopGroupList, setShopGroupList] = useState([]);
  const handleDeleteShopGroup = (item) => {
    dispatch(deleteShopGroupAction(login_key, user_id, item.GroupId));
  };
  const handleCreateShopGroup = () => {
    dispatch(visibleShopGroupPopupAction(true));
    dispatch(selectedShopGroupAction(null));
  };
  const handleEditShopGroup = (item) => {
    dispatch(visibleShopGroupPopupAction(true));
    dispatch(selectedShopGroupAction(item));
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...shopGroupList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.GroupId == copy_list[i].GroupId) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setShopGroupList(copy_list);
  };

  const handleCancelDeleteBox = (item) => {
    const copy_list = [...shopGroupList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.GroupId == copy_list[i].GroupId) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setShopGroupList(copy_list);
  };
  useEffect(() => {
    dispatch(shopGroupListAction(login_key, user_id));
  }, []);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    if (shop_group_list.length > 0) {
      const sortArrey = shop_group_list.sort(function (a, b) {
        return a.SortId - b.SortId;
      });
      setShopGroupList(sortArrey);
    }
  }, [shop_group_list]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  return (
    <div
      className="countaining-div"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <ListHeader title={lang.shop_group_list} show_back={false} />
      <div className="menu_list_header_container">
        {shopGroupList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {shopGroupList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={item.GroupName}
                  item_title={item.GroupName}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleEditShopGroup(item)}
                  show_delete={true}
                  delete_click={() => handleDeleteShopGroup(item)}
                  show_edit_icon={false}
                  selected={selected_shop_group}
                  item={item}
                  number={0}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span style={{ width: "170px" }} onClick={handleCreateShopGroup}>
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_shop_group}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {shopGroupList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={handleCreateShopGroup}
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_shop_group}</p>
          </div>
        ) : null}
      </div>
      {loading_users ? <Loader /> : null}
    </div>
  );
};

export default ShopGroup;
