export const order_status = {
  Accepted: 1000404,
  Rejected: 1000405,
  Pending: 1000403,
  Cancelld: 1000407,
  Completed: 1000408,
  Failed: 1000401,
  Finish: 1000406,
  inComplete: 1000402,
  Not_printed: 1000409,
};
export const mobile_order_status = {
  Accepted: 1000404,
  Rejected: 1000405,
  Pending: 1000403,
  Cancelld: 1000407,
  Completed: 1000408,
  Failed: 1000401,
  Finish: 1000406,
  inComplete: 1000402,
  Not_printed: 1000409,
};
export const Sale_method = [
  { label: "All", value: 5 },
  { label: "None", value: 1 },
  { label: "Delivery", value: 1000101 },
  { label: "Collection", value: 1000102 },
  { label: "Takeaway", value: 1000103 },
  { label: "Eat in", value: 1000104 },
  { label: "Web delivery", value: 1000105 },
  { label: "Web collection ", value: 1000106 },
];
export const financial_order_sale_method = [
  { label: "All", value: 1 },
  // { label: "Delivery", value: 1000101 },
  // { label: "Collection", value: 1000102 },
  // { label: "Takeaway", value: 1000103 },
  // { label: "Eat in", value: 1000104 },
  { label: "Web delivery", value: 1000105 },
  { label: "Web collection ", value: 1000106 },
];
export const financial_refund_sale_method = [
  { label: "All", value: 1 },
  // { label: "None", value: 1 },
  // { label: "Delivery", value: 1000101 },
  // { label: "Collection", value: 1000102 },
  // { label: "Takeaway", value: 1000103 },
  // { label: "Eat in", value: 1000104 },
  { label: "Web delivery", value: 1000105 },
  { label: "Web collection ", value: 1000106 },
  { label: "Other", value: 0 },
];
