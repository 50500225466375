import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  visibleDriversPopupAction,
  driverWithUniqueIdAction,
  createDriverShopAction,
} from "../../../redux/Employees/Actions";
import { setDefaultAction } from "../../../redux/menu/Actions";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
const DriversPopup = () => {
  const driverIdRef = useRef();
  const dispatch = useDispatch();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [showDiv, setShowDiv] = useState(false);
  const {
    driver_with_unique_id_list,
    drivers_list,
    result_successfull,
    result_message,
    result_failed,
    show_div,
  } = useSelector((state) => state.employeesRedusers);
  const [uniqueId, setUniqueId] = useState("");
  const handleDriverIdKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      handleCreateDriver();
    }
  };
  const handleCreateDriver = () => {
    if (!driver_with_unique_id_list.length > 0) {
      if (uniqueId == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (!validator.isFloat(String(uniqueId))) {
        toast.error(<h5> &nbsp;&nbsp;{lang.unique_id_wrong}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else {
        dispatch(
          driverWithUniqueIdAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            parseInt(uniqueId)
          )
        );
      }
    } else {
      dispatch(
        createDriverShopAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          driver_with_unique_id_list[0].DriverId
        )
      );
    }
  };
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  return (
    <div className="create_shop_popup_container">
      <div className="driver_popup_content_container ">
        <div
          className=" create-shop-close-div-post-code"
          style={{ height: "13%" }}
        >
          <i
            onClick={() => dispatch(visibleDriversPopupAction(false))}
            style={{ fontSize: 25 }}
            aria-hidden="true"
            className="fa fa-times post-code-close-icon"
          ></i>
          <h6 className="post-code-p" style={{ marginTop: "6%" }}>
            {lang.enter_driver_id_to_add_driver}
          </h6>
        </div>
        <div className="container-input-div driver-popup-container-input-div">
          <div className="create_shop_inputs_container driver_inputs_container">
            <form>
              <h6 className="h6 post-code-h6">{lang.driver_id} </h6>
              <input
                type="text"
                value={uniqueId}
                onChange={(e) => setUniqueId(e.target.value.replace(/\D/g, ""))}
                autoFocus
                ref={driverIdRef}
                onKeyDown={handleDriverIdKeyDown}
              />
            </form>
          </div>

          {show_div ? (
            !driver_with_unique_id_list.length > 0 ? (
              <div className="create_shop_inputs_container error-text-container-in-driver-popup">
                {lang.there_is_no_such_a_dirver}
              </div>
            ) : null
          ) : null}
          <div
            className="create_shop_inputs_container "
            style={{ margin: ".5% auto " }}
          >
            {lang.first_name} :{" "}
            {driver_with_unique_id_list.length > 0
              ? driver_with_unique_id_list[0].FirstName
              : null}
          </div>
          <div
            className="create_shop_inputs_container "
            style={{ margin: "1% auto " }}
          >
            {lang.last_name} :{" "}
            {driver_with_unique_id_list.length > 0
              ? driver_with_unique_id_list[0].LastName
              : null}
          </div>
          <div
            className="create_shop_inputs_container "
            style={{ margin: "1% auto " }}
          >
            {lang.mobile} :{" "}
            {driver_with_unique_id_list.length > 0
              ? driver_with_unique_id_list[0].Mobile
              : null}
          </div>
          <div
            className="post-code-popup-buttons-container"
            style={{ marginBottom: "-2%" }}
          >
            <button onClick={() => handleCreateDriver()} id="popup_add">
              {driver_with_unique_id_list.length > 0 ? lang.add : lang.search}
            </button>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default DriversPopup;
