import React from "react";
import { useSelector } from "react-redux";
const ShopStatusHeader = () => {
  const { lang } = useSelector((state) => state.selectedLanguage);
  return (
    <div className="shopStatusHeader_parent_div">
      <div className="shopStatusHeader_container_titles">
        <p
          style={{
            justifyContent: "flex-start",
          }}
        >
          {lang.shop_name}
        </p>{" "}
        <p>{lang.domain}</p>
        <p>{lang.messages}</p>
        <p>{lang.status}</p>
      </div>
    </div>
  );
};

export default ShopStatusHeader;
