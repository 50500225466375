import React, { useRef, useState, forwardRef, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ListHeader from "../components/ListHeader";
import SaveMenuButton from "../components/SaveMenuButton";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";
import { sendCategoryOptionAction } from "../redux/menu/Actions";
import { sendProductOptionEditAction } from "../redux/product/Action";

const ProductOptionEdit = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef(false);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    selected_category_option,
    vat,
    selected_category,
    selected_product_option_list,
    vat_id,
    result_successfull,
    // selected_product_option_list
  } = useSelector((state) => state.categoryList);

  const [appTitle, setAppTitle] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [kioskTitle, setKioskTitle] = useState("");
  const [appSort, setAppSort] = useState(1);
  const [webSort, setWebSort] = useState(1);
  const [kioskSort, setKioskSort] = useState(1);
  const [visibleOnApp, setVisibleOnApp] = useState(true);
  const [visibleOnWeb, setVisibleOnWeb] = useState(true);
  const [visibleOnKiosk, setVisibleOnKiosk] = useState(true);
  const [selectedVat, setSelectedVat] = useState(null);
  const [amountDelivery, setAmountDelivery] = useState(0);
  const [amountCollection, setAmountCollection] = useState(0);
  const [amountTakeaway, setAmountTakeaway] = useState(0);
  const [amountEtIn, setAmountEtIn] = useState(0);
  const [swapTitlePosition, setSwapTitlePosition] = useState(false);
  const [calories, setCalories] = useState("");
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleSavedClicked = () => {
    dispatch(
      sendProductOptionEditAction(
        login_key,
        user_id,
        selected_product_option_list.Id,
        selected_product_option_list.ShopId,
        selected_product_option_list.ProductId,
        selected_product_option_list.OptionId,
        appTitle.replaceAll(`"`, `\"\"`),
        webTitle.replaceAll(`"`, `\"\"`),
        kioskTitle.replaceAll(`"`, `\"\"`),
        amountDelivery ? parseFloat(amountDelivery) : 0,
        amountCollection ? parseFloat(amountCollection) : 0,
        amountTakeaway ? parseFloat(amountTakeaway) : 0,
        amountEtIn ? parseFloat(amountEtIn) : 0,
        selectedVat ? selectedVat.value : 0,
        2,
        swapTitlePosition ? 1 : 0,
        visibleOnApp ? 1 : 0,
        visibleOnWeb ? 1 : 0,
        visibleOnKiosk ? 1 : 0,
        appSort ? parseInt(appSort) : 0,
        webSort ? parseInt(webSort) : 0,
        kioskSort ? parseInt(kioskSort) : 0,
        0,
        selected_category.Id,
        calories ? parseFloat(calories) : 0
      )
    );
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSavedClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    appTitle,
    webTitle,
    kioskTitle,
    amountDelivery,
    amountCollection,
    amountTakeaway,
    amountEtIn,
    selectedVat,
    swapTitlePosition,
    visibleOnApp,
    visibleOnWeb,
    visibleOnKiosk,
    appSort,
    webSort,
    kioskSort,
  ]);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (selected_product_option_list) {
      setAppTitle(selected_product_option_list.AppTitle);
      setWebTitle(selected_product_option_list.WebTitle);
      setKioskTitle(selected_product_option_list.KioskTitle);
      setAmountDelivery(selected_product_option_list.AmountDelivery);
      setAmountCollection(selected_product_option_list.AmountCollection);
      setAmountTakeaway(selected_product_option_list.AmountTakeaway);
      setAmountEtIn(selected_product_option_list.AmountEatIn);
      setAppSort(selected_product_option_list.AppSortId);
      setKioskSort(selected_product_option_list.KioskSortId);
      setWebSort(selected_product_option_list.WebSortId);
      setSwapTitlePosition(selected_product_option_list.SwapTitlePosition);
      setVisibleOnApp(selected_product_option_list.AppVisible);
      setVisibleOnWeb(selected_product_option_list.WebVisible);
      setVisibleOnKiosk(selected_product_option_list.KioskVisible);
      setSelectedVat(selected_product_option_list.VatId);
      setCalories(selected_product_option_list.Calories);
    }
  }, [selected_product_option_list]);
  useEffect(() => {
    if (selected_category && didMountRef.current) {
      setClickedItem(2);
    }
    didMountRef.current = true;
  }, [selected_category]);

  useEffect(() => {
    if (result_successfull) {
      setClickedItem(5);
    }
  }, [result_successfull]);

  return (
    <div className="countaining-div">
      {/* BACK & TITLE */}
      <ListHeader
        title={lang.edit_product_option}
        show_back={true}
        clickedItem={() => setClickedItem(5)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="div-1 poe-div1">
            {/* APP TITLES */}
            <div className="poe-title-div ">
              <h6 className="poe-h6 h6">{lang.app_title}</h6>
              <input
                onChange={(e) => setAppTitle(e.target.value)}
                type="text"
                value={appTitle}
                className="poe-input11"
                placeholder={lang.placeholderTitle}
                disabled
              />
            </div>
            {/* WEB TITLE */}
            <div className="poe-title-div ">
              <h6 className="poe-h6 h6">{lang.web_title}</h6>
              <input
                onChange={(e) => setWebTitle(e.target.value)}
                type="text"
                value={webTitle}
                className="poe-input11"
                placeholder={lang.placeholderTitle}
                disabled
              />
            </div>
            {/* KIOSK TITLE */}
            <div className="poe-title-div ">
              <h6 className="poe-h6 h6">{lang.kiosk_title}</h6>
              <input
                onChange={(e) => setKioskTitle(e.target.value)}
                type="text"
                value={kioskTitle}
                className="poe-input11"
                placeholder={lang.placeholderTitle}
                disabled
              />
            </div>
          </div>
          {/* SHOW HIDE */}
          {/* SORT */}
          <div className="div-3">
            <div className="text">
              <h6 className="sort-title h6">{lang.sort_app}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                type="text"
                onChange={(e) => setAppSort(e.target.value.replace(/\D/g, ""))}
                value={appSort}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort_web}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={webSort}
                type="text"
                onChange={(e) => setWebSort(e.target.value.replace(/\D/g, ""))}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.sort_kiosk}</h6>
              <input
                className="sort-input input-padding"
                placeholder={lang.placeholderSort}
                value={kioskSort}
                type="text"
                onChange={(e) =>
                  setKioskSort(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
          </div>
          {/* ********** */}
          <div className="poe-div4">
            <div className="poe-div4-div">
              <h6 className="poe-div4-h6 h6"> {lang.amount_delivery}</h6>
              <input
                className="poe-input input-padding"
                value={amountDelivery}
                onChange={(e) =>
                  setAmountDelivery(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="poe-div4-div">
              <h6 className="poe-div4-h6 h6">{lang.amount_collection}</h6>
              <input
                className="poe-input input-padding"
                value={amountCollection}
                onChange={(e) =>
                  setAmountCollection(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
            <div className="poe-div4-div">
              <h6 className="poe-div4-h6 h6">{lang.amount_takeaway}</h6>
              <input
                className="poe-input input-padding"
                onChange={(e) =>
                  setAmountTakeaway(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
                value={amountTakeaway}
              />
            </div>
            <div className="poe-div4-div">
              <h6 className="poe-div4-h6 h6">{lang.amount_eatIn}</h6>
              <input
                className="poe-input input-padding"
                value={amountEtIn}
                onChange={(e) =>
                  setAmountEtIn(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div>
          </div>

          <div className="div6-poe">
            {/* VISIBLE ON APP */}
            <div className="poe-text div-chechbox-p">
              {/* <input
                id="check21"
                type="checkbox"
                // className="checkbox"
                checked={visibleOnApp}
                onChange={(e) => setVisibleOnApp(e.target.checked)}
                style={{ margin: 0 }}
              />
              <label for="check21" className="checkmark"></label>
              <h6 className="h6">{lang.visible_on_app}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnApp}
                      onChange={(e) => setVisibleOnApp(e.target.checked)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_app}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON WEB */}
            <div className="poe-text div-chechbox-p">
              {/* <input
                id="check22"
                type="checkbox"
                // className="checkbox"
                checked={visibleOnWeb}
                onChange={(e) => setVisibleOnWeb(e.target.checked)}
                style={{ margin: 0 }}
              />
              <label for="check22" className="checkmark"></label>
              <h6 className="h6">{lang.visible_on_web}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnWeb}
                      onChange={(e) => setVisibleOnWeb(e.target.checked)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_web}
                />
              </FormGroup>
            </div>
            {/* ***** */}
            {/* VISIBLE ON KIOSK */}
            <div className="poe-text div-chechbox-p">
              {/* <input
                id="check23"
                type="checkbox"
                // className="checkbox"
                checked={visibleOnKiosk}
                onChange={(e) => setVisibleOnKiosk(e.target.checked)}
                style={{ margin: 0 }}
              />
              <label for="check23" className="checkmark"></label>
              <h6 className="h6">{lang.visible_on_kiosk}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibleOnKiosk}
                      onChange={(e) => setVisibleOnKiosk(e.target.checked)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.visible_on_kiosk}
                />
              </FormGroup>
            </div>
            <div className="poe-text div-chechbox-p">
              {/* <input
                id="check24"
                type="checkbox"
                // className="checkbox"
                style={{ margin: 0 }}
                checked={swapTitlePosition}
                onChange={(e) => setSwapTitlePosition(e.target.checked)}
              />
              <label for="check24" className="checkmark"></label>
              <h6 className="h6">{lang.swap_title_position}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={swapTitlePosition}
                      onChange={(e) => setSwapTitlePosition(e.target.checked)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.swap_title_position}
                />
              </FormGroup>
            </div>

            {/* ***** */}
          </div>

          <div className="poe-div-multiselect">
            <div className="text">
              <h6 className="h6 categoryOption-content">{lang.vat}</h6>
              <Select
                options={vat}
                defaultValue={selectedVat}
                onChange={(list, item) => {
                  setSelectedVat(list);
                }}
                value={selectedVat}
                styles={customStyles}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            <div className="text">
              <h6 className="sort-title h6">{lang.calories}</h6>
              <input
                className="sort-input input-padding"
                type="text"
                onChange={(e) =>
                  setCalories(e.target.value.replace(/[^\d\.]+/g, ""))
                }
                value={calories}
              />
            </div>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSavedClicked()} />
    </div>
  );
};

export default ProductOptionEdit;
