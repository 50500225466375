import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  getTabelAreaFailedAction,
  getTabelAreaSucccessfullACtion,
  selectedTabelsAction,
  createAreaFailedAction,
  createAreaSuccessfullAction,
  createTabelFailedAction,
  createTabelSuccessfullAction,
  updateAreaFailedAction,
  updateAreaSuccessfullAction,
  updateTabelFailedAction,
  updateTabelSuccessfullAction,
  getTabelAreaAction,
  selectedAreaAction,
  allowedPostCodeListFailedAction,
  allowedPostCodeListSuccessfullAction,
  allowedPostCodeListAction,
  createAllowedPostCodeFailedAction,
  createAllowedPostCodeSuccessfullAction,
  updateAllowedPostCodeFailedAction,
  updateAllowedPostCodeSuccessfullAction,
  deleteAllowedPostCodeFailedAction,
  deleyeAllowedPostCodeSuccessfullAction,
  vatListAction,
  vatListSuccessfullAction,
  vatListFailedAction,
  updateVatFailedAction,
  createVatSuccessfullAction,
  createVatFailedAction,
  updateVatSuccessfullAction,
  getHalfAndHalfAction,
  getHalfAndHalfFailedAction,
  getHalfAndHalfSuccessfullAction,
  updateHalfAndHalfFailedAction,
  updateHalfAndHalfSuccessfullAction,
  deleteVatFailedAction,
  deleteVatSuccessfullAction,
  getCarrierBagListAction,
  getCarrierBagListSuccessfullAction,
  getCarrierBagListFailedAction,
  updateCarrierBagFailedAction,
  updateCarrierBagSuccessfullAction,
  getShortCutButtonListAction,
  getShortCutButtonListFailedAction,
  getShortCutButtonListSuccessfullAction,
  updateShortCutButtonFailedAction,
  updateShortCutButtonSuccessfullAction,
  getAboutUsListAction,
  getAboutUsListFailedAction,
  getAboutUsListSuccessfullAction,
  updateAboutUsFailedAction,
  updateAboutUsSuccessfullAction,
  getByAreaListAction,
  getByAreaListFailedAction,
  getByAreaListSuccessfullAction,
  updateByAreaSuccessfullAction,
  updateByAreaFailedAction,
  createByAreaFailedAction,
  createByAreaSuccessfullAction,
  deleteByAreaFailedAction,
  deleteByAreaSuccessfullAction,
  getDeliveryAreaMapListAction,
  getDeliveryAreaMapListFailedAction,
  getDeliveryAreaMapListSuccessfullAction,
  createDeliveryAreaMapFailedAction,
  createDeliveryAreaMapSuccessfullAction,
  updateDeliveryAreaMapFailedAction,
  updateDeliveryAreaMapSuccessfullAction,
  deleteDeliveryAreaMapSuccessfullAction,
  deleteDeliveryAreaMapFailedAction,
  getAllowedPostCodeFOFListAction,
  getAllowedPostCodeFOFFailedListAction,
  getAllowedPostCodeFOFSuccessfullListAction,
  createAllowedPostCodeFOFFailedAction,
  createAllowedPostCodeFOFSucceesfullAction,
  updateAllowedPostCodeFOFFailedAction,
  updateAllowedPostCodeFOFSucceesfullAction,
  deleteAllowedPostCodeFOFFailedAction,
  deleteAllowedPostCodeFOFSucceesfullAction,
} from "./Actions";

import {
  tabelAreaServices,
  createTabelAreaServices,
  updateTabelAreaServices,
  allowedPostCodeListServices,
  createAllowedPostCodeServices,
  updateAllowedPostCodeServices,
  deleteAllowedPostCodeServices,
  vatLisServices,
  createVatServices,
  updateVatServices,
  getHalfAndHalfService,
  updateHalfAndHalfService,
  deleteVatService,
  getCarrierBagListService,
  updateCarrierBagService,
  getShortCutButtonService,
  updateShortCutButtonService,
  getAboutUsListService,
  updateAboutUsService,
  getByAreaListService,
  createByAreaListService,
  updateByAreaListService,
  deleteByAreaListService,
  getDeliveryAreaMapListService,
  createDeliveryAreaMapService,
  updateDeliveryAreaMapService,
  deleteDeliveryAreaMapService,
  getAllowedPostCodeFOFListServices,
  createAllowedPostCodeFOFServices,
  updateAllowedPostCodeFOFServices,
  deleteAllowedPostCodeFOFServices,
} from "../../services/GeneralSetting";
import { getDropDownAction } from "../menu/Actions";
function* getTabelArea({ payload }) {
  try {
    const response = yield call(
      tabelAreaServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Area.length > 0) {
          let selected_area;
          let my_list = [];
          let tabel_select;

          if (payload.parent_id) {
            for (let i = 0; i < response.data.Area.length; i++) {
              if (
                response.data.Area[i].AppVisible == 1 &&
                response.data.Area[i].KioskVisible == 1
              ) {
                my_list.push(response.data.Area[i]);
              }
              if (response.data.Area[i].Id == payload.parent_id) {
                selected_area = response.data.Area[i];
                if (response.data.Area[i].Tables) {
                  for (
                    let index = 0;
                    index < response.data.Area[i].Tables.length;
                    index++
                  ) {
                    if (
                      payload.parent_id ==
                      response.data.Area[i].Tables[index].ParentId
                    ) {
                      if (
                        payload.id == response.data.Area[i].Tables[index].Id
                      ) {
                        tabel_select = response.data.Area[i].Tables[index];
                      }
                    }
                  }
                }
              }
            }
            for (let i = 0; i < response.data.Area.length; i++) {
              if (
                (response.data.Area[i].AppVisible == 0 &&
                  response.data.Area[i].KioskVisible == 1) ||
                (response.data.Area[i].AppVisible == 1 &&
                  response.data.Area[i].KioskVisible == 0)
              ) {
                my_list.push(response.data.Area[i]);
              }
              if (response.data.Area[i].Id == payload.parent_id) {
                selected_area = response.data.Area[i];
                if (response.data.Area[i].Tables) {
                  for (
                    let index = 0;
                    index < response.data.Area[i].Tables.length;
                    index++
                  ) {
                    if (
                      payload.parent_id ==
                      response.data.Area[i].Tables[index].ParentId
                    ) {
                      if (
                        payload.id == response.data.Area[i].Tables[index].Id
                      ) {
                        tabel_select = response.data.Area[i].Tables[index];
                      }
                    }
                  }
                }
              }
            }
            for (let i = 0; i < response.data.Area.length; i++) {
              if (
                response.data.Area[i].AppVisible == 0 &&
                response.data.Area[i].KioskVisible == 0
              ) {
                my_list.push(response.data.Area[i]);
              }
              if (response.data.Area[i].Id == payload.parent_id) {
                selected_area = response.data.Area[i];
                if (response.data.Area[i].Tables) {
                  for (
                    let index = 0;
                    index < response.data.Area[i].Tables.length;
                    index++
                  ) {
                    if (
                      payload.parent_id ==
                      response.data.Area[i].Tables[index].ParentId
                    ) {
                      if (
                        payload.id == response.data.Area[i].Tables[index].Id
                      ) {
                        tabel_select = response.data.Area[i].Tables[index];
                      }
                    }
                  }
                }
              }
            }
            yield put(selectedTabelsAction(tabel_select));
            yield put(getTabelAreaSucccessfullACtion(my_list));
            yield put(selectedAreaAction(selected_area));
          } else {
            for (let i = 0; i < response.data.Area.length; i++) {
              if (
                response.data.Area[i].AppVisible == 1 &&
                response.data.Area[i].KioskVisible == 1
              ) {
                my_list.push(response.data.Area[i]);
              }
              if (payload.id) {
                if (payload.id == response.data.Area[i].Id) {
                  selected_area = response.data.Area[i];
                } else {
                  selected_area = my_list[0];
                }
              } else {
                selected_area = my_list[0];
              }
            }
            for (let i = 0; i < response.data.Area.length; i++) {
              if (
                (response.data.Area[i].AppVisible == 0 &&
                  response.data.Area[i].KioskVisible == 1) ||
                (response.data.Area[i].AppVisible == 1 &&
                  response.data.Area[i].KioskVisible == 0)
              ) {
                my_list.push(response.data.Area[i]);
              }
              if (payload.id) {
                if (payload.id == response.data.Area[i].Id) {
                  selected_area = response.data.Area[i];
                } else {
                  selected_area = my_list[0];
                }
              } else {
                selected_area = my_list[0];
              }
            }
            for (let i = 0; i < response.data.Area.length; i++) {
              if (
                response.data.Area[i].AppVisible == 0 &&
                response.data.Area[i].KioskVisible == 0
              ) {
                my_list.push(response.data.Area[i]);
              }
              if (payload.id) {
                if (payload.id == response.data.Area[i].Id) {
                  selected_area = response.data.Area[i];
                } else {
                  selected_area = my_list[0];
                }
              } else {
                selected_area = my_list[0];
              }
            }
            yield put(getTabelAreaSucccessfullACtion(my_list));
            yield put(selectedAreaAction(selected_area));
          }
        } else {
          yield put(getTabelAreaFailedAction());
        }
      } else {
        yield put(getTabelAreaFailedAction());
      }
    } else {
      yield put(getTabelAreaFailedAction());
    }
  } catch (error) {
    yield put(getTabelAreaFailedAction(error.message));
  }
}

function* createArea({ payload }) {
  try {
    const response = yield call(
      createTabelAreaServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.tabel_name,
      payload.parent_id,
      payload.position,
      payload.percount,
      payload.app_visible,
      payload.kiosk_visible
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(createAreaSuccessfullAction(response.data[0].ResultMessage));
        yield put(
          getTabelAreaAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.parent_id,
            response.data[0].Id
          )
        );
      } else {
        yield put(createAreaFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createAreaFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createAreaFailedAction(error.message));
  }
}
function* createTabel({ payload }) {
  try {
    const response = yield call(
      createTabelAreaServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.tabel_name,
      payload.parent_id,
      payload.position,
      payload.percount,
      payload.app_visible,
      payload.kiosk_visible
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(createTabelSuccessfullAction(response.data[0].ResultMessage));
        yield put(
          getTabelAreaAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.parent_id,
            0
          )
        );
      } else {
        yield put(createTabelFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createTabelFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createTabelFailedAction(error.message));
  }
}
function* updateArea({ payload }) {
  try {
    const response = yield call(
      updateTabelAreaServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.tabel_name,
      payload.parent_id,
      payload.position,
      payload.percount,
      payload.app_visible,
      payload.kiosk_visible
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(updateAreaSuccessfullAction(response.data[0].ResultMessage));
        yield put(
          getTabelAreaAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.id,
            0
          )
        );
      } else {
        yield put(updateAreaFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(updateAreaFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(updateAreaFailedAction(error.message));
  }
}
function* updateTabel({ payload }) {
  try {
    const response = yield call(
      updateTabelAreaServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.tabel_name,
      payload.parent_id,
      payload.position,
      payload.percount,
      payload.app_visible,
      payload.kiosk_visible
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(updateTabelSuccessfullAction(response.data[0].ResultMessage));
        yield put(
          getTabelAreaAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.parent_id,
            payload.id
          )
        );
      } else {
        yield put(updateTabelFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(updateTabelFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(updateTabelFailedAction(error.message));
  }
}

function* allowedPostCode({ payload }) {
  try {
    const response = yield call(
      allowedPostCodeListServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Allowed_Delivery_Postcode_List.length > 0) {
          let my_list = [];
          for (
            let i = 0;
            i < response.data.Allowed_Delivery_Postcode_List.length;
            i++
          ) {
            my_list.push({
              ...response.data.Allowed_Delivery_Postcode_List[i],
              showDeleteBox: false,
              showEditBox: false,
            });
          }
          yield put(allowedPostCodeListSuccessfullAction(my_list));
        } else {
          yield put(allowedPostCodeListFailedAction());
        }
      } else {
        yield put(allowedPostCodeListFailedAction());
      }
    } else {
      yield put(allowedPostCodeListFailedAction());
    }
  } catch (error) {
    yield put(allowedPostCodeListFailedAction(error.message));
  }
}
function* createAllowedPostCode({ payload }) {
  try {
    const response = yield call(
      createAllowedPostCodeServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.post_code,
      payload.delivery_charge,
      payload.delivey_min_charge
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(
          createAllowedPostCodeSuccessfullAction(response.data[0].ResultMessage)
        );
        yield put(
          allowedPostCodeListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          createAllowedPostCodeFailedAction(response.data[0].ResultMessage)
        );
      }
    } else {
      yield put(
        createAllowedPostCodeFailedAction(response.data[0].ResultMessage)
      );
    }
  } catch (error) {
    yield put(createAllowedPostCodeFailedAction(error.message));
  }
}
function* deleteAllowedPostCode({ payload }) {
  try {
    const response = yield call(
      deleteAllowedPostCodeServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleyeAllowedPostCodeSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          allowedPostCodeListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          deleteAllowedPostCodeFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(deleteAllowedPostCodeFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteAllowedPostCodeFailedAction(error.message));
  }
}
function* updateAllowedPostCode({ payload }) {
  try {
    const response = yield call(
      updateAllowedPostCodeServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.post_code,
      payload.delivery_charge,
      payload.delivey_min_charge
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateAllowedPostCodeSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          allowedPostCodeListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateAllowedPostCodeFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateAllowedPostCodeFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateAllowedPostCodeFailedAction(error.message));
  }
}
function* vatList({ payload }) {
  try {
    const response = yield call(
      vatLisServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Vats_List.length > 0) {
          let my_list = [];
          for (let i = 0; i < response.data.Vats_List.length; i++) {
            my_list.push({
              ...response.data.Vats_List[i],
              showDeleteBox: false,
              showEditBox: false,
            });
          }
          yield put(vatListSuccessfullAction(my_list));
          // yield put(getDropDownAction(payload.login_key,
          //   payload.user_id,
          //   payload.shop_id,))
        } else {
          yield put(vatListFailedAction());
        }
      } else {
        yield put(vatListFailedAction());
      }
    } else {
      yield put(vatListFailedAction());
    }
  } catch (error) {
    yield put(vatListFailedAction(error.message));
  }
}
function* createVat({ payload }) {
  try {
    const response = yield call(
      createVatServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.vat_name,
      payload.vat_value
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(createVatSuccessfullAction(response.data[0].ResultMessage));
        yield put(
          vatListAction(payload.login_key, payload.user_id, payload.shop_id)
        );
        yield put(
          getDropDownAction(payload.login_key, payload.user_id, payload.shop_id)
        );
      } else {
        yield put(createVatFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createVatFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createVatFailedAction(error.message));
  }
}
function* updateVat({ payload }) {
  try {
    const response = yield call(
      updateVatServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.vat_name,
      payload.vat_value
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateVatSuccessfullAction(response.data.ResultMessage));
        yield put(
          vatListAction(payload.login_key, payload.user_id, payload.shop_id)
        );
        yield put(
          getDropDownAction(payload.login_key, payload.user_id, payload.shop_id)
        );
      } else {
        yield put(updateVatFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateVatFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateVatFailedAction(error.message));
  }
}

function* delteVat({ payload }) {
  try {
    const response = yield call(
      deleteVatService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(deleteVatSuccessfullAction(response.data.ResultMessage));
        yield put(
          vatListAction(payload.login_key, payload.user_id, payload.shop_id)
        );
        yield put(
          getDropDownAction(payload.login_key, payload.user_id, payload.shop_id)
        );
      } else {
        yield put(deleteVatFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteVatFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteVatFailedAction(error.message));
  }
}
function* getHalfAndHalf({ payload }) {
  try {
    const response = yield call(
      getHalfAndHalfService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.halfandhalf.length > 0) {
          yield put(getHalfAndHalfSuccessfullAction(response.data.halfandhalf));
        } else {
          yield put(getHalfAndHalfFailedAction());
        }
      } else {
        yield put(getHalfAndHalfFailedAction());
      }
    } else {
      yield put(getHalfAndHalfFailedAction());
    }
  } catch (error) {
    yield put(getHalfAndHalfFailedAction(error.message));
  }
}
function* updateHalfAndHalf({ payload }) {
  try {
    const response = yield call(
      updateHalfAndHalfService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.half_id,
      payload.max_price,
      payload.by_percenet,
      payload.equal,
      payload.first_half,
      payload.second_half
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateHalfAndHalfSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getHalfAndHalfAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateHalfAndHalfFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateHalfAndHalfFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateHalfAndHalfFailedAction(error.message));
  }
}
function* getCarrierBagList({ payload }) {
  try {
    const response = yield call(
      getCarrierBagListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.carrier_bag) {
          if (response.data.carrier_bag.length > 0) {
            yield put(
              getCarrierBagListSuccessfullAction(response.data.carrier_bag)
            );
          } else {
            yield put(
              getCarrierBagListFailedAction(response.data.ResultMessage)
            );
          }
        } else {
          yield put(getCarrierBagListFailedAction(response.data.ResultMessage));
        }
      } else {
        yield put(getCarrierBagListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getCarrierBagListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getCarrierBagListFailedAction(error.message));
  }
}
function* updateCarrierBag({ payload }) {
  try {
    const response = yield call(
      updateCarrierBagService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.cariier_bag_amount,
      payload.cariier_bag_title,
      payload.active_web_cariier_bag,
      payload.active_app_cariier_bag,
      payload.active_kiosk_cariier_bag
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateCarrierBagSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getCarrierBagListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateCarrierBagFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateCarrierBagFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateCarrierBagFailedAction(error.message));
  }
}
function* getShortCutButtonList({ payload }) {
  try {
    const response = yield call(
      getShortCutButtonService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.shortcut_buttons) {
          if (response.data.shortcut_buttons.length > 0) {
            yield put(
              getShortCutButtonListSuccessfullAction(
                response.data.shortcut_buttons
              )
            );
          } else {
            yield put(
              getShortCutButtonListFailedAction(response.data.ResultMessage)
            );
          }
        } else {
          yield put(
            getShortCutButtonListFailedAction(response.data.ResultMessage)
          );
        }
      } else {
        yield put(
          getShortCutButtonListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getShortCutButtonListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getShortCutButtonListFailedAction(error.message));
  }
}
function* updateShortCutButton({ payload }) {
  try {
    const response = yield call(
      updateShortCutButtonService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.cat_name_a,
      payload.cat_id_a,
      payload.cat_active_a,
      payload.cat_name_b,
      payload.cat_id_b,
      payload.cat_active_b,
      payload.submenu_name_a,
      payload.submenu_id_a,
      payload.submenu_active_a
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateShortCutButtonSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getShortCutButtonListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateShortCutButtonFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateShortCutButtonFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateShortCutButtonFailedAction(error.message));
  }
}
function* getAboutUsList({ payload }) {
  try {
    const response = yield call(
      getAboutUsListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.about_us) {
          if (response.data.about_us.length > 0) {
            yield put(getAboutUsListSuccessfullAction(response.data.about_us));
          } else {
            yield put(getAboutUsListFailedAction(""));
          }
        } else {
          yield put(getAboutUsListFailedAction(""));
        }
      } else {
        yield put(getAboutUsListFailedAction(response.data.ResultMessage));
      }
    } else {
      getAboutUsListFailedAction(response.data.ResultMessage);
    }
  } catch (error) {
    getAboutUsListFailedAction(error.message);
  }
}
function* updateAboutUs({ payload }) {
  try {
    const response = yield call(
      updateAboutUsService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.message
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateAboutUsSuccessfullAction(response.data.ResultMessage));
        yield put(
          getAboutUsListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateAboutUsFailedAction(response.data.ResultMessage));
      }
    } else {
      updateAboutUsFailedAction(response.data.ResultMessage);
    }
  } catch (error) {
    updateAboutUsFailedAction(error.message);
  }
}
// GET BY AREA LIST
function* getByAreaList({ payload }) {
  try {
    const response = yield call(
      getByAreaListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.ByArea_List) {
          if (response.data.ByArea_List.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.ByArea_List.length; i++) {
              my_list.push({
                ...response.data.ByArea_List[i],
                showDeleteBox: false,
                showEditBox: false,
              });
            }
            yield put(getByAreaListSuccessfullAction(my_list));
          } else {
            yield put(getByAreaListFailedAction(""));
          }
        } else {
          yield put(getByAreaListFailedAction(""));
        }
      } else {
        yield put(getByAreaListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getByAreaListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getByAreaListFailedAction(error.message));
  }
}
//CREATE BY AREA
function* createByArea({ payload }) {
  try {
    const response = yield call(
      createByAreaListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.area_name,
      payload.delivery_charge,
      payload.post_code
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(
          createByAreaSuccessfullAction(response.data[0].ResultMessage)
        );
        yield put(
          getByAreaListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(createByAreaFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createByAreaFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createByAreaFailedAction(error.message));
  }
}
//UPDATE BY AREA
function* updateByArea({ payload }) {
  try {
    const response = yield call(
      updateByAreaListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.area_name,
      payload.delivery_charge,
      payload.post_code
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateByAreaSuccessfullAction(response.data.ResultMessage));
        yield put(
          getByAreaListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateByAreaFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateByAreaFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateByAreaFailedAction(error.message));
  }
}
//DELETE BY AREA
function* deleteByArea({ payload }) {
  try {
    const response = yield call(
      deleteByAreaListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(deleteByAreaSuccessfullAction(response.data.ResultMessage));
        yield put(
          getByAreaListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(deleteByAreaFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteByAreaFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteByAreaFailedAction(error.message));
  }
}

//DELIEVRY AREA MAP
function* getDeliveryAreaMapList({ payload }) {
  try {
    const response = yield call(
      getDeliveryAreaMapListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Area_Map_List) {
          if (response.data.Area_Map_List.length > 0) {
            let new_list = [];
            for (let i = 0; i < response.data.Area_Map_List.length; i++) {
              new_list.push({
                ...response.data.Area_Map_List[i],
                Editable: false,
                ShowDeleteBox: false,
                ShowDetails: false,
                Draggable: false,
                IsCreate: 0,
              });
              yield put(getDeliveryAreaMapListSuccessfullAction(new_list));
            }
          } else {
            yield put(getDeliveryAreaMapListFailedAction(""));
          }
        } else {
          yield put(getDeliveryAreaMapListFailedAction(""));
        }
      } else {
        yield put(
          getDeliveryAreaMapListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getDeliveryAreaMapListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getDeliveryAreaMapListFailedAction(error.mssage));
  }
}
function* updateDeliveryAreaMap({ payload }) {
  try {
    const response = yield call(
      updateDeliveryAreaMapService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.color,
      payload.name,
      payload.minAmount,
      payload.delivery_fee,
      payload.polygon,
      payload.is_active
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateDeliveryAreaMapSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getDeliveryAreaMapListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateDeliveryAreaMapFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateDeliveryAreaMapFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateDeliveryAreaMapFailedAction(error.message));
  }
}
function* deleteDeliveryAreaMap({ payload }) {
  try {
    const response = yield call(
      deleteDeliveryAreaMapService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteDeliveryAreaMapSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getDeliveryAreaMapListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          deleteDeliveryAreaMapFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(deleteDeliveryAreaMapFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteDeliveryAreaMapFailedAction(error.message));
  }
}
function* createDeliveryAreaMap({ payload }) {
  try {
    const response = yield call(
      createDeliveryAreaMapService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.color,
      payload.name,
      payload.minAmount,
      payload.delivery_fee,
      payload.polygon,
      payload.is_active
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createDeliveryAreaMapSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getDeliveryAreaMapListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          createDeliveryAreaMapFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(createDeliveryAreaMapFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(createDeliveryAreaMapFailedAction(error.message));
  }
}
// POST CODE FOF
function* getAllowedPostCodeListFOF({ payload }) {
  try {
    const response = yield call(
      getAllowedPostCodeFOFListServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Allowed_Delivery_Postcode_fof_List) {
          if (response.data.Allowed_Delivery_Postcode_fof_List.length > 0) {
            let my_list = [];
            for (
              let i = 0;
              i < response.data.Allowed_Delivery_Postcode_fof_List.length;
              i++
            ) {
              my_list.push({
                ...response.data.Allowed_Delivery_Postcode_fof_List[i],
                showDeleteBox: false,
                showEditBox: false,
              });
            }
            yield put(getAllowedPostCodeFOFSuccessfullListAction(my_list));
          } else {
            yield put(getAllowedPostCodeFOFFailedListAction(""));
          }
        } else {
          yield put(getAllowedPostCodeFOFFailedListAction(""));
        }
      } else {
        yield put(
          getAllowedPostCodeFOFFailedListAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getAllowedPostCodeFOFFailedListAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getAllowedPostCodeFOFFailedListAction(error.message));
  }
}
function* createAllowedPostCodeFOF({ payload }) {
  try {
    const response = yield call(
      createAllowedPostCodeFOFServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.post_code,
      payload.delivery_charge,
      payload.delivey_min_charge
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(
          createAllowedPostCodeFOFSucceesfullAction(
            response.data[0].ResultMessage
          )
        );
        yield put(
          getAllowedPostCodeFOFListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          createAllowedPostCodeFOFFailedAction(response.data[0].ResultMessage)
        );
      }
    } else {
      yield put(
        createAllowedPostCodeFOFFailedAction(response.data[0].ResultMessage)
      );
    }
  } catch (error) {
    yield put(createAllowedPostCodeFOFFailedAction(error.message));
  }
}
function* deleteAllowedPostCodeFOF({ payload }) {
  try {
    const response = yield call(
      deleteAllowedPostCodeFOFServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteAllowedPostCodeFOFSucceesfullAction(response.data.ResultMessage)
        );
        yield put(
          getAllowedPostCodeFOFListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          deleteAllowedPostCodeFOFFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        deleteAllowedPostCodeFOFFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(deleteAllowedPostCodeFOFFailedAction(error.message));
  }
}
function* updateAllowedPostCodeFOF({ payload }) {
  try {
    const response = yield call(
      updateAllowedPostCodeFOFServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.post_code,
      payload.delivery_charge,
      payload.delivey_min_charge
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateAllowedPostCodeFOFSucceesfullAction(response.data.ResultMessage)
        );
        yield put(
          getAllowedPostCodeFOFListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateAllowedPostCodeFOFFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateAllowedPostCodeFOFFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateAllowedPostCodeFOFFailedAction(error.message));
  }
}
export function* watchUpdateAllowedPostCodeFOF() {
  yield takeLatest(
    action_types.UPDATE_ALLOWED_POST_CODE_FOF,
    updateAllowedPostCodeFOF
  );
}
export function* watchDeleteAllowedPostCodeFOF() {
  yield takeLatest(
    action_types.DELETE_ALLOWED_POST_CODE_FOF,
    deleteAllowedPostCodeFOF
  );
}
export function* watchCreateAllowedPostCodeFOF() {
  yield takeLatest(
    action_types.CREATE_ALLOWED_POST_CODE_FOF,
    createAllowedPostCodeFOF
  );
}
export function* watchGetAllowedPostCodeFOFList() {
  yield takeLatest(
    action_types.GET_ALLOWED_POST_CODE_FOF_LIST,
    getAllowedPostCodeListFOF
  );
}
export function* watchCreateDeliveryAreaMap() {
  yield takeLatest(
    action_types.CREATE_DELIVERY_AREA_MAP,
    createDeliveryAreaMap
  );
}
export function* watchDeleteDeliveryAreaMap() {
  yield takeLatest(
    action_types.DELETE_DELIVERY_AREA_MAP,
    deleteDeliveryAreaMap
  );
}
export function* watchUpdateDeliveryAreaMap() {
  yield takeLatest(
    action_types.UPDATE_DELIVERY_AREA_MAP,
    updateDeliveryAreaMap
  );
}
export function* watchGetDeliveryAreaMapList() {
  yield takeLatest(
    action_types.GET_DELIVERY_AREA_MAP_LIST,
    getDeliveryAreaMapList
  );
}
export function* watchDeleteByArea() {
  yield takeLatest(action_types.DELETE_BY_AREA, deleteByArea);
}
export function* watchUpdateByArea() {
  yield takeLatest(action_types.UPDATE_BY_AREA, updateByArea);
}
export function* watchCreateByArea() {
  yield takeLatest(action_types.CREATE_BY_AREA, createByArea);
}
export function* watchGetByList() {
  yield takeLatest(action_types.GET_BY_AREA_LIST, getByAreaList);
}
export function* watchGetAboutUsList() {
  yield takeLatest(action_types.GET_ABOUT_US_LIST, getAboutUsList);
}
export function* watchUpdateAboutUs() {
  yield takeLatest(action_types.UPDATE_ABOUT_US, updateAboutUs);
}
export function* watchUpdateShortCutButton() {
  yield takeLatest(action_types.UPDATE_SHORT_CUT_BUTTON, updateShortCutButton);
}
export function* watchGetShortCutButtonList() {
  yield takeLatest(
    action_types.GET_SHORT_CUT_BUTTON_LIST,
    getShortCutButtonList
  );
}
export function* watchUpdateCarrierBag() {
  yield takeLatest(
    action_types.GENERAL_SETTING_UPDATE_CARRIER_BAG,
    updateCarrierBag
  );
}
export function* watchGetCariierBagList() {
  yield takeLatest(
    action_types.GENERAL_SETTING_GET_CARRIER_BAG_LIST,
    getCarrierBagList
  );
}
export function* watchUpdateHalfAndHalf() {
  yield takeLatest(action_types.UPDATE_HALF_AND_HALF, updateHalfAndHalf);
}
export function* watchGetHalfAndHalf() {
  yield takeLatest(action_types.GET_HALF_AND_HALF, getHalfAndHalf);
}
export function* watchDeleteVat() {
  yield takeLatest(action_types.DELETE_VAT, delteVat);
}
export function* watchUpdateVat() {
  yield takeLatest(action_types.UPDATE_VAT, updateVat);
}
export function* watchCreateVat() {
  yield takeLatest(action_types.CREATE_VAT, createVat);
}
export function* watchVatList() {
  yield takeLatest(action_types.VAT_LIST, vatList);
}
export function* watchUpdateAllowedPostCode() {
  yield takeLatest(
    action_types.UPDATE_ALLOWED_POST_CODE,
    updateAllowedPostCode
  );
}
export function* watchDeleteAllowedPostCode() {
  yield takeLatest(
    action_types.DELETE_ALLOWED_POST_CODE,
    deleteAllowedPostCode
  );
}
export function* watchCreateAllowedPostCode() {
  yield takeLatest(
    action_types.CREATE_ALLOWED_POST_CODE,
    createAllowedPostCode
  );
}
export function* watchAllowedPostCode() {
  yield takeLatest(action_types.ALLOWE_POST_CODE_LIST, allowedPostCode);
}
export function* watchUpdateArea() {
  yield takeLatest(action_types.UPDATE_AREA, updateArea);
}
export function* watchUpdateTabel() {
  yield takeLatest(action_types.UPDATE_TABEL, updateTabel);
}
export function* watchCreateArea() {
  yield takeLatest(action_types.CREATE_AREA, createArea);
}
export function* watchCreateTabel() {
  yield takeLatest(action_types.CREATE_TABEL, createTabel);
}
export function* watchGetTabelArea() {
  yield takeLatest(action_types.GET_TABEL_AREA, getTabelArea);
}

export default function* rootTabelArea() {
  yield all([
    fork(watchGetTabelArea),
    fork(watchCreateArea),
    fork(watchCreateTabel),
    fork(watchUpdateArea),
    fork(watchUpdateTabel),
    fork(watchAllowedPostCode),
    fork(watchCreateAllowedPostCode),
    fork(watchUpdateAllowedPostCode),
    fork(watchDeleteAllowedPostCode),
    fork(watchUpdateVat),
    fork(watchVatList),
    fork(watchCreateVat),
    fork(watchDeleteVat),
    fork(watchGetHalfAndHalf),
    fork(watchUpdateHalfAndHalf),
    fork(watchGetCariierBagList),
    fork(watchUpdateCarrierBag),
    fork(watchGetShortCutButtonList),
    fork(watchUpdateShortCutButton),
    fork(watchGetAboutUsList),
    fork(watchUpdateAboutUs),
    fork(watchGetByList),
    fork(watchCreateByArea),
    fork(watchUpdateByArea),
    fork(watchDeleteByArea),
    fork(watchGetDeliveryAreaMapList),
    fork(watchUpdateDeliveryAreaMap),
    fork(watchDeleteDeliveryAreaMap),
    fork(watchCreateDeliveryAreaMap),
    fork(watchGetAllowedPostCodeFOFList),
    fork(watchCreateAllowedPostCodeFOF),
    fork(watchDeleteAllowedPostCodeFOF),
    fork(watchUpdateAllowedPostCodeFOF),
  ]);
}
