import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  devicesServices,
  licenceWorkStationServices,
  createLicenceServices,
  getLicenceListByWorkStaionIdServices,
  generalSecondScreenShopGroupListService,
  getGeneralSecondScreenListService,
  createGeneralSecondScreenService,
  updateGeneralSecondScreenService,
  deleteGeneralSecondScreenService,
  updateGeneralSecondScreenShopGroupService,
} from "../../services/DevicesServices";
import {
  getWorkStationListAllFailedAction,
  getWorkStationListAllSuccessfullAction,
  getLicenceWorkStationListFailedAction,
  getLicenceWorkStationListSuccessfullAction,
  createLicenceFailedAction,
  createLicenceSuccessfullAction,
  getLicenceListByWorkStaionIdFailedAction,
  getLicenceListByWorkStaionIdSuccessfullAction,
  getLicenceWorkStationListAction,
  getGeneralSecondScreenListAction,
  getGeneralSecondScreenListFailedAction,
  getGeneralSecondFullScreenListSuccessfullAction,
  getGeneralSecondHalfScreenListSuccessfullAction,
  createGeneralSecondScreenImageFailedAction,
  createGeneralSecondScreenImageSuccessfullAction,
  updateGeneralSecondScreenImageFailedAction,
  updateGeneralSecondScreenImageSuccessfullAction,
  deleteGeneralSecondScreenImageFailedAction,
  deleteGeneralSecondScreenImageSuccessfullAction,
  generalSecondScreenShopGroupListFailedAction,
  generalSecondScreenShopGroupListSuccessfullAction,
  generalSecondScreenShopGroupListAction,
  updateGeneralSecondScreenShopGroupFailedAction,
  updateGeneralSecondScreenShopGroupSuccessfullAction,
  createGeneralSecondScreenImageAction,
} from "./Actions";
import {
  secondScreenImageDeletePopupAction,
  showSecondScreenImageDetailsPopupAction,
} from "../settings/Action";

function* getDevicesList({ payload }) {
  try {
    const response = yield call(
      devicesServices,
      payload.login_key,
      payload.user_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.WorkstationList) {
          if (response.data.WorkstationList.length > 0) {
            yield put(
              getWorkStationListAllSuccessfullAction(
                response.data.WorkstationList
              )
            );
          } else {
            yield put(getWorkStationListAllFailedAction(""));
          }
        } else {
          yield put(getWorkStationListAllFailedAction(""));
        }
      } else {
        yield put(
          getWorkStationListAllFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getWorkStationListAllFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getWorkStationListAllFailedAction(error.message));
  }
}
function* licenceList({ payload }) {
  try {
    const response = yield call(
      licenceWorkStationServices,
      payload.login_key,
      payload.user_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.licence_workstations_list.length > 0) {
          yield put(
            getLicenceWorkStationListSuccessfullAction(
              response.data.licence_workstations_list
            )
          );
        } else yield put(getLicenceWorkStationListFailedAction());
      } else {
        yield put(getLicenceWorkStationListFailedAction());
      }
    } else {
      yield put(getLicenceWorkStationListFailedAction());
    }
  } catch (error) {
    yield put(getLicenceWorkStationListFailedAction(error.message));
  }
}
function* createLicence({ payload }) {
  try {
    const response = yield call(
      createLicenceServices,
      payload.login_key,
      payload.shop_id,
      payload.workStation_id,
      payload.device_id,
      payload.licence_course,
      payload.user_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(createLicenceSuccessfullAction(response.data.ResultMessage));
        yield put(
          getLicenceWorkStationListAction(payload.login_key, payload.user_id)
        );
      } else {
        yield put(createLicenceFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createLicenceFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(createLicenceFailedAction(error.message));
  }
}
function* getLicenceListByWorkStaionId({ payload }) {
  try {
    const response = yield call(
      getLicenceListByWorkStaionIdServices,
      payload.login_key,
      payload.user_id,
      payload.workStation_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.licence_workstations_list.length > 0) {
          yield put(
            getLicenceListByWorkStaionIdSuccessfullAction(
              response.data.licence_workstations_list
            )
          );
        } else {
          yield put(getLicenceListByWorkStaionIdFailedAction());
        }
      } else {
        yield put(getLicenceListByWorkStaionIdFailedAction());
      }
    } else {
      yield put(getLicenceListByWorkStaionIdFailedAction());
    }
  } catch (error) {
    yield put(getLicenceListByWorkStaionIdFailedAction(error.message));
  }
}
function* getGeneralSecondScreenList({ payload }) {
  try {
    const response = yield call(
      getGeneralSecondScreenListService,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.second_screen_images_public_list) {
          if (response.data.second_screen_images_public_list.length > 0) {
            let full_screen_list = [];
            let half_screen_list = [];
            for (
              let i = 0;
              i < response.data.second_screen_images_public_list.length;
              i++
            ) {
              if (
                response.data.second_screen_images_public_list[i]
                  .IsFullScreen == 1
              ) {
                full_screen_list.push(
                  response.data.second_screen_images_public_list[i]
                );
              } else {
                half_screen_list.push(
                  response.data.second_screen_images_public_list[i]
                );
              }
            }
            yield put(
              getGeneralSecondFullScreenListSuccessfullAction(full_screen_list)
            );
            yield put(
              getGeneralSecondHalfScreenListSuccessfullAction(half_screen_list)
            );
          } else {
            yield put(getGeneralSecondScreenListFailedAction());
          }
        } else {
          yield put(getGeneralSecondScreenListFailedAction());
        }
      } else {
        yield put(
          getGeneralSecondScreenListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getGeneralSecondScreenListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getGeneralSecondScreenListFailedAction(error.message));
  }
}
function* createGeneralSecondScreen({ payload }) {
  try {
    const response = yield call(
      createGeneralSecondScreenService,
      payload.formData,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createGeneralSecondScreenImageSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(showSecondScreenImageDetailsPopupAction(false));
        yield put(
          getGeneralSecondScreenListAction(payload.login_key, payload.user_id)
        );
      } else {
        yield put(
          createGeneralSecondScreenImageFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        createGeneralSecondScreenImageFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(createGeneralSecondScreenImageFailedAction(error.message));
  }
}
function* updateGeneralSecondScreen({ payload }) {
  try {
    const response = yield call(
      updateGeneralSecondScreenService,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.sort_id,
      payload.active,
      payload.duration
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateGeneralSecondScreenImageSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getGeneralSecondScreenListAction(payload.login_key, payload.user_id)
        );
      } else {
        yield put(
          updateGeneralSecondScreenImageFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        updateGeneralSecondScreenImageFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateGeneralSecondScreenImageFailedAction(error.message));
  }
}
function* deleteGeneralSecondScreen({ payload }) {
  try {
    const response = yield call(
      deleteGeneralSecondScreenService,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.last_image_url
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteGeneralSecondScreenImageSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getGeneralSecondScreenListAction(payload.login_key, payload.user_id)
        );
        yield put(secondScreenImageDeletePopupAction(false));
      } else {
        yield put(
          deleteGeneralSecondScreenImageFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        deleteGeneralSecondScreenImageFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(deleteGeneralSecondScreenImageFailedAction(error.message));
  }
}
function* getGeneralSecondScreenShopGroupList({ payload }) {
  try {
    const response = yield call(
      generalSecondScreenShopGroupListService,
      payload.login_key,
      payload.user_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.second_screen_shop_group_list) {
          if (response.data.second_screen_shop_group_list.length > 0) {
            yield put(
              generalSecondScreenShopGroupListSuccessfullAction(
                response.data.second_screen_shop_group_list
              )
            );
          } else {
            yield put(generalSecondScreenShopGroupListFailedAction());
          }
        } else {
          yield put(generalSecondScreenShopGroupListFailedAction());
        }
      } else {
        yield put(
          generalSecondScreenShopGroupListFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        generalSecondScreenShopGroupListFailedAction(
          response.data.ResultMessage
        )
      );
    }
  } catch (error) {
    yield put(generalSecondScreenShopGroupListFailedAction(error.message));
  }
}
function* updateGeneralSecondScreenShopGroup({ payload }) {
  try {
    const response = yield call(
      updateGeneralSecondScreenShopGroupService,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shop_ids
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateGeneralSecondScreenShopGroupSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          generalSecondScreenShopGroupListAction(
            payload.login_key,
            payload.user_id
          )
        );
      } else {
        yield put(
          updateGeneralSecondScreenShopGroupFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        updateGeneralSecondScreenShopGroupFailedAction(
          response.data.ResultMessage
        )
      );
    }
  } catch (error) {
    yield put(updateGeneralSecondScreenShopGroupFailedAction(error.message));
  }
}
export function* watchUpdateGeneralSecondScreenShopGroup() {
  yield takeLatest(
    action_types.UPDATE_GENERAL_SECOND_SCREEN_SHOP_GROUP,
    updateGeneralSecondScreenShopGroup
  );
}
export function* watchGetGeneralSecondScreenShopGroupList() {
  yield takeLatest(
    action_types.GENERAL_SECOND_SCREEN_SHOP_GROUP_LIST,
    getGeneralSecondScreenShopGroupList
  );
}
export function* watchDeleteGeneralSecondScreen() {
  yield takeLatest(
    action_types.DELETE_GENERAL_SECOND_SCREEN_IMAGE,
    deleteGeneralSecondScreen
  );
}
export function* watchUpdateGeneralSecondScreen() {
  yield takeLatest(
    action_types.UPDATE_GENERAL_SECOND_SCREEN_IMAGE,
    updateGeneralSecondScreen
  );
}
export function* watchCreateGeneralSecondScreen() {
  yield takeLatest(
    action_types.CREATE_GENERAL_SECOND_SCREEN_IMAGE,
    createGeneralSecondScreen
  );
}
export function* watchGetGeneralSecondScreenList() {
  yield takeLatest(
    action_types.GET_GENERAL_SECOND_SCREEN_LIST,
    getGeneralSecondScreenList
  );
}
export function* watchGetLicenceListByWorkStaionId() {
  yield takeLatest(
    action_types.GET_LICENCE_LIST_BY_WORKSTATION_ID,
    getLicenceListByWorkStaionId
  );
}
export function* watchCreateLicence() {
  yield takeLatest(action_types.CREATE_LICENCE, createLicence);
}
export function* watchLicenceList() {
  yield takeLatest(action_types.GET_LICENCE_WORK_STATION_LIST, licenceList);
}
export function* watchDevicesList() {
  yield takeLatest(action_types.GET_WORKSTATION_LIST_ALL, getDevicesList);
}
export default function* rootDevices() {
  yield all([
    fork(watchDevicesList),
    fork(watchLicenceList),
    fork(watchCreateLicence),
    fork(watchGetLicenceListByWorkStaionId),
    fork(watchGetGeneralSecondScreenList),
    fork(watchCreateGeneralSecondScreen),
    fork(watchUpdateGeneralSecondScreen),
    fork(watchDeleteGeneralSecondScreen),
    fork(watchGetGeneralSecondScreenShopGroupList),
    fork(watchUpdateGeneralSecondScreenShopGroup),
  ]);
}
