import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  main_nav_button_color,
  main_nav_back_color,
  primaryColor,
  white,
} from "../../../constants/Colors";
import Driver from "./derivers";
import Employees from "./index";
import Groups from "./GroupIndex";
import {
  selecteShopEmployees,
  selectedAccessGroupAction,
} from "../../../redux/Employees/Actions";
import Entry_Exit from "./entry-exit";
const EmployeesTab = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [clickedItem, setClickedItem] = useState(1);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <div className="menu_list_header_container_tabel_list">
        <div className="main_nav_container">
          <div className="settings_tab_buttons_container">
            <button
              key={1}
              onClick={() => {
                setClickedItem(1);
                dispatch(selecteShopEmployees(null));
              }}
              style={{
                backgroundColor: clickedItem == 1 ? primaryColor : white,
                color: clickedItem == 1 ? white : main_nav_button_color,
                borderWidth: clickedItem == 1 ? 0 : 0.3,
              }}
              className="main_nav_buttons"
            >
              Staff
            </button>

            <button
              key={2}
              onClick={() => setClickedItem(2)}
              style={{
                backgroundColor: clickedItem == 2 ? primaryColor : white,
                color: clickedItem == 2 ? white : main_nav_button_color,
                borderWidth: clickedItem == 2 ? 0 : 0.3,
              }}
              className="main_nav_buttons"
            >
              Drivers
            </button>
            <button
              key={3}
              onClick={() => setClickedItem(3)}
              style={{
                backgroundColor: clickedItem == 3 ? primaryColor : white,
                color: clickedItem == 3 ? white : main_nav_button_color,
                borderWidth: clickedItem == 3 ? 0 : 0.3,
              }}
              className="main_nav_buttons"
            >
              Entry / Exit
            </button>
          </div>
        </div>

        {clickedItem == 1 ? (
          <Employees />
        ) : clickedItem == 2 ? (
          <Driver />
        ) : clickedItem == 3 ? (
          <Entry_Exit />
        ) : null}
      </div>
    </div>
  );
};

export default EmployeesTab;
