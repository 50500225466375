import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { english_main_nav_general_menu } from "../../../constants/MainNavGenaralMenu";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../../../constants/Colors";
import {
  selectedExternalCompanyAction,
  selectedPublicSubmneuAction,
} from "../../../redux/MainNavGeneral/Actions";
const GeneralSideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { users_menu } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const [userAccesses, setUserAccesses] = useState([]);
  const [rolesid, setRolesId] = useState([]);
  const [userType, setUserType] = useState("");
  const [userSideBarList, setUserSideBarList] = useState([]);
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);
  const [selectedSublistId, setSelectedSublistId] = useState(31);
  const handleCollapse = (item, myindex) => {
    if (item.id == 2) {
      dispatch(selectedExternalCompanyAction(null));
    } else if (item.id == 3) {
      dispatch(selectedPublicSubmneuAction(null));
    }
    navigate(item.id == 1 ? "" : item.path);
    if (item.path) {
      setSelectedPath(item.path);
    }

    const copy_item = { ...item };
    const copy_userSideBarList = [...userSideBarList];
    copy_userSideBarList.forEach((element, index) => {
      if (myindex == index) {
        copy_item.expanded = !copy_item.expanded;
        copy_userSideBarList[myindex] = copy_item;
      } else {
        element.expanded = false;
        copy_userSideBarList[index] = element;
      }
    });
    //     if(item.title=="Employees"){
    // dispatch(getUsersListAction(login_key,10001203 ))
    //     }else{
    //       dispatch(getUsersListAction(login_key,10001202 ))
    //     }
    setUserSideBarList(copy_userSideBarList);

    if (!item.expanded) {
      setSelectedTitleIndex(myindex);
    } else {
      setSelectedTitleIndex(null);
    }
  };
  const handleSubListClicked = (p, id) => {
    // if (id == 41) {
    //   dispatch(
    //     getSubmenuAction(login_key, user_id, selected_shop[0].ShopId, 0)
    //   );
    // }
    // if (id == 22) {
    //   dispatch(selectedSubmenuAction(null));
    // }
    navigate(p);
    setSelectedPath(p);
    setSelectedSublistId(id);
  };

  useEffect(() => {
    if (english_main_nav_general_menu.length > 0) {
      setUserSideBarList(english_main_nav_general_menu);
    }
  }, [english_main_nav_general_menu]);

  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);
  useEffect(() => {
    let roles_id = [];
    let user_type = "";
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          roles_id.push(userAccesses[i].RoleId);
          user_type = userAccesses[i].UserType;
        }
        setRolesId(roles_id);
        setUserType(user_type);
      }
    }
  }, [userAccesses]);
  useEffect(() => {
    const copy_devices_list = [...userSideBarList];

    let new_sideBar_list = [];
    if (userType == "") {
      setUserSideBarList(english_main_nav_general_menu);
    } else {
      if (userType == 10001201) {
        setUserSideBarList(english_main_nav_general_menu);
      } else {
        if (copy_devices_list) {
          if (copy_devices_list.length > 0) {
            for (let i = 0; i < copy_devices_list.length; i++) {
              if (rolesid) {
                if (rolesid.length > 0) {
                  for (let ind = 0; ind < rolesid.length; ind++) {
                    if (rolesid[ind] == copy_devices_list[i].role_id) {
                      new_sideBar_list.push(copy_devices_list[i]);
                      navigate(new_sideBar_list[0].path);
                    }
                  }
                }
              }
            }

            setUserSideBarList(new_sideBar_list);
          }
        }
      }
    }
  }, [rolesid, english_main_nav_general_menu]);

  return (
    <div className="user-sideBar-container-div">
      {userSideBarList.map((item, index) => (
        <div key={index}>
          <div
            className="user-sidebar-title"
            style={{
              backgroundColor:
                selectedTitleIndex == index ? main_nav_back_color : null,
              borderRightColor: primaryColor,
              borderRightStyle: "solid",
              borderRightWidth: selectedTitleIndex == index ? 4 : 0,
            }}
            onClick={() => handleCollapse(item, index)}
          >
            {/* <div className="sidebar_up_down_container">
              {item.sublist.length > 0 ? (
                <i
                  className={
                    item.expanded
                      ? "fa fa-chevron-down sidebar_content_color"
                      : "fa fa-chevron-right sidebar_content_color"
                  }
                  aria-hidden="true"
                ></i>
              ) : null}
            </div> */}
            <div
              className="sidebar_title_icon_container sidebar_content_color"
              style={{ width: "100%" }}
            >
              <i class={item.icon} aria-hidden="true"></i>
              <h6 className="m-0 sidebar_content_color" style={{ flex: 1 }}>
                {item.title}
              </h6>
            </div>
          </div>
          {item.expanded ? (
            <div>
              {item.sublist.map((item) =>
                !item.isDisabled ? (
                  <div
                    style={{
                      width: "100%",
                      backgroundColor:
                        selectedSublistId == item.id
                          ? main_nav_back_color
                          : null,
                      direction: "ltr",
                    }}
                    className="sidebar_li_subtitle"
                    onClick={() => handleSubListClicked(item.path, item.id)}
                  >
                    <div>
                      <i class={item.icon} aria-hidden="true"></i>
                      <p className="p-0 m-0">{item.title}</p>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default GeneralSideBar;
