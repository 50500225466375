import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import {
  primaryColor,
  white,
  main_nav_button_color,
} from "../constants/Colors";
import { getUserTypeAction } from "../redux/login/Actions";
import { tabStatusAction } from "../redux/menu/Actions";
import Carousel, { consts } from "react-elastic-carousel";

const MainNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userAccesses, setUserAccesses] = useState([]);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [tabStatus, setTabStatus] = useState(false);
  const [rolesid, setRolesId] = useState([]);
  const [userType, setUserType] = useState("");
  const hendleItemClicked = (key, path) => {
    setSelectedItemId(key);
    navigate(path);
    setTabStatus(!tabStatus);
    dispatch(tabStatusAction(tabStatus));
  };
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i
          class="fa fa-chevron-left"
          aria-hidden="true"
          style={{
            fontSize: "20px",
            marginTop: "5px",
            // position: "absolute",
            // left: "70px",
            // top: "100px",
            // zIndex: "55555555",
          }}
        ></i>
      ) : (
        <i
          class="fa fa-chevron-right"
          aria-hidden="true"
          style={{
            fontSize: "20px",
            marginTop: "5px",
            marginLeft: "-16px",
          }}
        ></i>
      );
    return (
      <button
        onClick={onClick}
        disabled={isEdge}
        style={{ border: "none", display: isEdge ? "none" : "block" }}
      >
        {pointer}
      </button>
    );
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 330, itemsToShow: 2, itemsToScroll: 1, pagination: false },
    { width: 450, itemsToShow: 3, itemsToScroll: 1, pagination: false },
    { width: 580, itemsToShow: 4, itemsToScroll: 1, pagination: false },
    { width: 800, itemsToShow: 5, itemsToScroll: 1, pagination: false },
    { width: 850, itemsToShow: 6, pagination: false, itemsToScroll: 1 },
    { width: 1150, itemsToShow: 7, itemsToScroll: 1, pagination: false },
  ];
  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);
  useEffect(() => {
    let roles_id = [];
    let top_roles_id = [];
    let new_roles_list = [];
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          roles_id.push(userAccesses[i].RoleId);
          setUserType(userAccesses[i].UserType);
        }
      }
      if (roles_id) {
        if (roles_id.length > 0) {
          for (let ind = 0; ind < roles_id.length; ind++) {
            new_roles_list.push(roles_id[ind]);

            top_roles_id[0] = 100;

            if (
              roles_id[ind] == 20 ||
              roles_id[ind] == 60 ||
              roles_id[ind] == 30 ||
              roles_id[ind] == 40 ||
              roles_id[ind] == 41 ||
              roles_id[ind] == 50 ||
              roles_id[ind] == 75 ||
              roles_id[ind] == 12 ||
              roles_id[ind] == 11 ||
              roles_id[ind] == 10 ||
              roles_id[ind] == 9 ||
              roles_id[ind] == 6 ||
              roles_id[ind] == 5 ||
              roles_id[ind] == 4 ||
              roles_id[ind] == 1 ||
              roles_id[ind] == 3 ||
              roles_id[ind] == 2 ||
              roles_id[ind] == 80
            ) {
              top_roles_id.push(roles_id[ind]);
            }
          }

          new_roles_list.push(top_roles_id);
        }

        setRolesId(new_roles_list);
      }
    }
  }, [userAccesses]);

  return (
    <div
      className="main_nav_container"
      style={{
        backgroundColor: rolesid
          ? rolesid.length > 0
            ? rolesid[rolesid.length - 1].length == 1
              ? "#f6f6f6"
              : null
            : null
          : null,
      }}
    >
      <div className="main_nav_buttons_container">
        {" "}
        <Carousel
          showEmptySlots
          itemPosition={consts.START}
          // className="styling-example"
          breakPoints={breakPoints}
          itemsToShow={7}
          // className="carousel"
          renderArrow={myArrow}
          renderPagination={({ pages, activePage, onClick }) => {
            return (
              <div direction="row">
                {pages.map((page) => {
                  // const isActivePage = activePage === page;
                  return (
                    <div
                      style={{ display: "none" }}
                      key={page}
                      onClick={() => onClick(page)}
                      // active={isActivePage}
                    />
                  );
                })}
              </div>
            );
          }}
        >
          {/* DASHBOARD */}
          {userType == 10001201 ? (
            <button
              key={1}
              onClick={() => hendleItemClicked(1, "")}
              style={{
                backgroundColor: selectedItemId == 1 ? primaryColor : white,
                color: selectedItemId == 1 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 1 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.dashboard}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid[rolesid.length - 1] ? (
                rolesid[rolesid.length - 1].length > 1 ? (
                  rolesid[rolesid.length - 1].includes(100) ? (
                    <button
                      onAbort={() => console.log("frt")}
                      onLoad={() => console.log("frt")}
                      key={1}
                      onClick={() => hendleItemClicked(1, "")}
                      style={{
                        backgroundColor:
                          selectedItemId == 1 ? primaryColor : white,
                        color:
                          selectedItemId == 1 ? white : main_nav_button_color,
                        borderWidth: selectedItemId == 1 ? 0 : 0.3,
                        height: "40px",
                      }}
                      className="main_nav_buttons"
                    >
                      {lang.dashboard}
                      {/* {setItemList([])} */}
                    </button>
                  ) : null
                ) : null
              ) : null
            ) : null
          ) : null}
          {/* SHOPS STATUS */}
          {userType == 10001201 ? (
            <button
              key={2}
              onClick={() => hendleItemClicked(2, "Shopsstatus")}
              style={{
                backgroundColor: selectedItemId == 2 ? primaryColor : white,
                color: selectedItemId == 2 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 2 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.shops_status}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid.includes(20) ? (
                <button
                  key={2}
                  onClick={() => hendleItemClicked(2, "Shopsstatus")}
                  style={{
                    backgroundColor: selectedItemId == 2 ? primaryColor : white,
                    color: selectedItemId == 2 ? white : main_nav_button_color,
                    borderWidth: selectedItemId == 2 ? 0 : 0.3,
                    height: "40px",
                  }}
                  className="main_nav_buttons"
                >
                  {lang.shops_status}
                </button>
              ) : null
            ) : null
          ) : null}
          {/* USERS */}
          {userType == 10001201 ? (
            <button
              key={3}
              onClick={() => hendleItemClicked(3, "users")}
              style={{
                backgroundColor: selectedItemId == 3 ? primaryColor : white,
                color: selectedItemId == 3 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 3 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.users}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid.includes(30) ? (
                <button
                  key={3}
                  onClick={() => hendleItemClicked(3, "users")}
                  style={{
                    backgroundColor: selectedItemId == 3 ? primaryColor : white,
                    color: selectedItemId == 3 ? white : main_nav_button_color,
                    borderWidth: selectedItemId == 3 ? 0 : 0.3,
                    height: "40px",
                  }}
                  className="main_nav_buttons"
                >
                  {lang.users}
                </button>
              ) : null
            ) : null
          ) : null}
          {/* DEVICES */}
          {userType == 10001201 ? (
            <button
              key={4}
              onClick={() => hendleItemClicked(4, "devices")}
              style={{
                backgroundColor: selectedItemId == 4 ? primaryColor : white,
                color: selectedItemId == 4 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 4 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.devices}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid.includes(41) ||
              rolesid.includes(40) ||
              rolesid.includes(40 && 41) ? (
                <button
                  key={4}
                  onClick={() => hendleItemClicked(4, "devices")}
                  style={{
                    backgroundColor: selectedItemId == 4 ? primaryColor : white,
                    color: selectedItemId == 4 ? white : main_nav_button_color,
                    borderWidth: selectedItemId == 4 ? 0 : 0.3,
                    height: "40px",
                  }}
                  className="main_nav_buttons"
                >
                  {lang.devices}
                </button>
              ) : null
            ) : null
          ) : null}
          {/* TAKINGS */}
          {userType == 10001201 ? (
            <button
              key={5}
              onClick={() => hendleItemClicked(5, "financial/refund")}
              style={{
                backgroundColor: selectedItemId == 5 ? primaryColor : white,
                color: selectedItemId == 5 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 5 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.financial}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid.includes(80) ||
              rolesid.includes(81) ||
              rolesid.includes(80 && 81) ? (
                <button
                  key={5}
                  onClick={() => hendleItemClicked(5, "financial/refund")}
                  style={{
                    backgroundColor: selectedItemId == 5 ? primaryColor : white,
                    color: selectedItemId == 5 ? white : main_nav_button_color,
                    borderWidth: selectedItemId == 5 ? 0 : 0.3,
                    height: "40px",
                  }}
                  className="main_nav_buttons"
                >
                  {lang.financial}
                </button>
              ) : null
            ) : null
          ) : null}
          {/* ORDERS STATUS */}
          {userType == 10001201 ? (
            <button
              key={6}
              // onClick={() => navigate("ordersstatus")}
              onClick={() => hendleItemClicked(6, "ordersstatus")}
              style={{
                backgroundColor: selectedItemId == 6 ? primaryColor : white,
                color: selectedItemId == 6 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 6 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.orders_status}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid.includes(60) ? (
                <button
                  key={6}
                  // onClick={() => navigate("ordersstatus")}
                  onClick={() => hendleItemClicked(6, "ordersstatus")}
                  style={{
                    backgroundColor: selectedItemId == 6 ? primaryColor : white,
                    color: selectedItemId == 6 ? white : main_nav_button_color,
                    borderWidth: selectedItemId == 6 ? 0 : 0.3,
                    height: "40px",
                  }}
                  className="main_nav_buttons"
                >
                  {lang.orders_status}
                </button>
              ) : null
            ) : null
          ) : null}
          {userType == 10001201 ? (
            <button
              key={7}
              // onClick={() => navigate("ordersstatus")}
              onClick={() => hendleItemClicked(7, "gropus")}
              style={{
                backgroundColor: selectedItemId == 7 ? primaryColor : white,
                color: selectedItemId == 7 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 7 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.staff_access}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid.includes(75) ? (
                <button
                  key={7}
                  // onClick={() => navigate("ordersstatus")}
                  onClick={() => hendleItemClicked(7, "gropus")}
                  style={{
                    backgroundColor: selectedItemId == 7 ? primaryColor : white,
                    color: selectedItemId == 7 ? white : main_nav_button_color,
                    borderWidth: selectedItemId == 7 ? 0 : 0.3,
                    height: "40px",
                  }}
                  className="main_nav_buttons"
                >
                  {lang.staff_access}
                </button>
              ) : null
            ) : null
          ) : null}
          {userType == 10001201 ? (
            <button
              key={8}
              // onClick={() => navigate("ordersstatus")}
              onClick={() => hendleItemClicked(8, "generall")}
              style={{
                backgroundColor: selectedItemId == 8 ? primaryColor : white,
                color: selectedItemId == 8 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 8 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.general}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid.includes(90) ? (
                <button
                  key={8}
                  // onClick={() => navigate("ordersstatus")}
                  onClick={() => hendleItemClicked(8, "generall")}
                  style={{
                    backgroundColor: selectedItemId == 8 ? primaryColor : white,
                    color: selectedItemId == 8 ? white : main_nav_button_color,
                    borderWidth: selectedItemId == 8 ? 0 : 0.3,
                    height: "40px",
                  }}
                  className="main_nav_buttons"
                >
                  {lang.general}
                </button>
              ) : null
            ) : null
          ) : null}
          {userType == 10001201 ? (
            <button
              key={9}
              // onClick={() => navigate("ordersstatus")}
              onClick={() => hendleItemClicked(9, "wepos")}
              style={{
                backgroundColor: selectedItemId == 9 ? primaryColor : white,
                color: selectedItemId == 9 ? white : main_nav_button_color,
                borderWidth: selectedItemId == 9 ? 0 : 0.3,
                height: "40px",
              }}
              className="main_nav_buttons"
            >
              {lang.fof}
            </button>
          ) : rolesid ? (
            rolesid.length > 0 ? (
              rolesid.includes(110) ? (
                <button
                  key={9}
                  // onClick={() => navigate("ordersstatus")}
                  onClick={() => hendleItemClicked(9, "wepos")}
                  style={{
                    backgroundColor: selectedItemId == 9 ? primaryColor : white,
                    color: selectedItemId == 9 ? white : main_nav_button_color,
                    borderWidth: selectedItemId == 9 ? 0 : 0.3,
                    height: "40px",
                  }}
                  className="main_nav_buttons"
                >
                  {lang.fof}
                </button>
              ) : null
            ) : null
          ) : null}
        </Carousel>
      </div>
    </div>
  );
};

export default MainNav;
