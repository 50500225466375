import React, { useState, useEffect, useRef } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { primaryColor } from "../../../../constants/Colors";
import ListHeader from "../../../../components/ListHeader";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import {
  createTabelAction,
  updateTabelAction,
} from "../../../../redux/GeneralSetting/Actions";
const TabelEdit = ({ setClickedItem }) => {
  const didMountRef = useRef();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [appVisible, setAppVisible] = useState(true);
  const [kioskVisible, setKioskVisible] = useState(false);
  const [position, setPosition] = useState("");
  const [tabelName, setTabelName] = useState("");
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    tabel_area_list,
    loading_tabel_area,
    selected_area,
    selected_tabel,
    tabels_list,
    result_successfull,
  } = useSelector((state) => state.tabelAreaRedusers);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const handleCreateAndSaveTabel = () => {
    if (selected_tabel) {
      if (tabelName == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateTabelAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_tabel.Id,
            tabelName.replaceAll(`"`, `\"\"`),
            selected_area.Id,
            position ? parseInt(position) : 0,
            0,
            appVisible ? 1 : 0,
            kioskVisible ? 1 : 0
          )
        );
      }
    } else {
      if (tabelName == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          createTabelAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            tabelName.replaceAll(`"`, `\"\"`),
            selected_area.Id,
            position ? parseInt(position) : 0,
            0,
            appVisible ? 1 : 0,
            kioskVisible ? 1 : 0
          )
        );
      }
    }
  };

  useEffect(() => {
    if (selected_tabel) {
      setAppVisible(selected_tabel.AppVisible);
      setKioskVisible(selected_tabel.KioskVisible);
      setTabelName(selected_tabel.TableName);
      setPosition(selected_tabel.Position);
    }
  }, [selected_tabel]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleCreateAndSaveTabel();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [tabelName, appVisible, kioskVisible, position]);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);
  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);
  return (
    <div className="countaining-div">
      <div className="menu_list_header_container_tabel_list">
        <div style={{ marginTop: "10px" }}>
          <ListHeader
            title={
              selected_tabel
                ? `${selected_tabel.TableName} / ${lang.edit}`
                : lang.create_tabel
            }
            show_back={true}
            clickedItem={() => setClickedItem(1)}
          />
        </div>
        <div className="margin_menu_normal_horizontal">
          <div className="div-3">
            <div style={{ width: "40%" }}>
              <h6 className="Secound-language h6">* {lang.tabel_name}</h6>
              <input
                type="text"
                className="CE-secound-language-input input-padding"
                value={tabelName}
                onChange={(e) => setTabelName(e.target.value)}
              />
            </div>
            <div style={{ width: "40%" }}>
              <h6 className="Secound-language h6">{lang.position}</h6>
              <input
                type="text"
                className="CE-secound-language-input input-padding"
                value={position}
                onChange={(e) => setPosition(e.target.value.replace(/\D/g, ""))}
              />
            </div>
          </div>
          <div className="div-3">
            <div className="div-8-1 ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appVisible}
                      onChange={() => setAppVisible(!appVisible)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.app_visible}
                />
              </FormGroup>
            </div>
            <div className="div-8-1 ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={kioskVisible}
                      onChange={() => setKioskVisible(!kioskVisible)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.kiosk_visible}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleCreateAndSaveTabel()} />
    </div>
  );
};

export default TabelEdit;
