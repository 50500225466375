import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import EmployeesEdit from "../../../components/EmployeesEdit";
import EmployeesList from "../../../components/EmployeesList";
import Loader from "../../../components/Loader";
import { setDefaultAction } from "../../../redux/menu/Actions";
import AnyEmployeeEntryExitTimeReport from "./AnyEmployeeEntryExitTimeReport";
const Employees = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { result_failed, result_message, result_successfull } = useSelector(
    (state) => state.employeesRedusers
  );
  const { loading_employees } = useSelector((state) => state.employeesRedusers);

  const [create, setCreate] = useState(false);
  const [clickedItem, setClickedItem] = useState(1);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="common-styles"
      style={{
        height: window.innerHeight - 220,
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        {clickedItem == 1 ? (
          <EmployeesList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 2 ? (
          <EmployeesEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 3 ? (
          <AnyEmployeeEntryExitTimeReport
            setClickedItem={(i) => setClickedItem(i)}
          />
        ) : null}
        {loading_employees ? <Loader /> : null}
      </div>
      <Toaster />
    </div>
  );
};

export default Employees;
