import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import store from "store2";
import * as action_types from "../ActionTypes";
import { getDropDownAction } from "../menu/Actions";
import {
  getAllAppSettingsAction,
  getAllAppSettingsSuccessfullAction,
  getAllAppSettingFailedAction,
  updateShopSettingAppOrderProcessSuccessfullAction,
  updateShopSettingAppOrderProcessFailedAction,
  updateShopSettingAppPaymentFailedAction,
  updateShopSettingAppPaymentSuccessfullAction,
  updateShopSettingAppColourFailedAction,
  updateShopSettingAppColourSuccessfullAction,
  updateShopSettingAppDisplayFailedAction,
  updateShopSettingAppDisplaySuccessfullAction,
  updateShopSettingAppPasswordProtectFailedAction,
  updateShopSettingAppPasswordProtectSuccessfullAction,
  getDeliveyChargeTypeAction,
  getDeliveyChargeTypeFailedAction,
  getDeliveyChargeTypeSuccessfullAction,
  createDeliveryChargeSuccesffullAction,
  createDeliveryChargeFailedAction,
  updateDeliveryChargeFailedAction,
  updateDeliveryChargeSuccessfullAction,
  deleteDeliveryChargeFailedAction,
  deleteDeliveryChargeSuccesffullAction,
  getDeliverySettingFailedAction,
  getDeliverySettingSuccessfullAction,
  getDeliverySettingAction,
  updateDeliverySettingSuccessfullAction,
  updateDeliverySettingFailedAction,
  codeDetailListAction,
  codeDetailListFailedAction,
  codeDetailListSuccessfullAction,
  createCodeDetailSuccessfullAction,
  createCodeDetailFailedAction,
  updateCodeDetailSuccessfullAction,
  updateCodeDetailFailedAction,
  deleteCodeDetailFailedAction,
  deleteCodeDetailSuccessfullAction,
  getWebSettingFailedAction,
  getWebSettingSuccessfullAction,
  getWebSettingAction,
  updateWebSettingFailedAction,
  updateWebSettingSuccessfullAction,
  getOpenCloseTimeListAction,
  getOpenCloseTimeListFailedAction,
  getOpenCloseTimeListSuccessfullAction,
  updateOpenCloseTimeFailedAction,
  updateOpenCloseTimeSuccessfullAction,
  createOpenCloseTimeFailedAction,
  createOpenCloseTimeSuccessfullAction,
  getWebPaymentSettingListAction,
  getWebPaymentSettingListFailedAction,
  getWebPaymentSettingListSuccessfullAction,
  updateWebPaymentSettingFailedAction,
  updateWebPaymentSettingSuccessfullAction,
  getStripeListAction,
  getStripeListFailedAction,
  getStripeListSuccessfullAction,
  updateStripeFailedAction,
  updateStripeSuccessfullAction,
  getShopSettingTimeGapListAction,
  getShopSettingTimeGapListFailedAction,
  getShopSettingTimeGapListSuccessfullAction,
  updateShopSettingTimeGapFailedAction,
  updateShopSettingTimeGapSuccessfullAction,
  getWebDeliveryListAction,
  getWebDeliveryListFailedAction,
  getWebDeliveryListSuccessfullAction,
  updateWebDeliveryFailedAction,
  updateWebDeliverySuccessfullAction,
  getMaintenanceListAction,
  getMaintenanceListFailedAction,
  getMaintenanceListSuccessfullAction,
  updateMaintenanceFailedAction,
  updateMaintenanceSuccessfullAction,
  maintenanceMessageListAction,
  getPaymentLinkListAction,
  getPaymentLinkListFailedAction,
  getPaymentLinkListSuccessfullAction,
  updatePaymentLinkFailedAction,
  updatePaymentLinkSuccessfullAction,
  getNotificationEmailListAction,
  getNotificationEmailListFailedAction,
  getNotificationEmailListSuccessfullAction,
  createNotificationEmailFailedAction,
  createNotificationEmailSuccessfullAction,
  updateNotificationEmailFailedAction,
  updateNotificationEmailSuccessfullAction,
  deleteNotificationEmailFailedAction,
  deleteNotificationEmailSuccessfullAction,
  getPromotionListAction,
  getPromotionListFailedAction,
  getPromotionListSuccessfullAction,
  createPromotionFailedAction,
  createPromotionSuccessfullAction,
  updatePromotionFailedAction,
  updatePromotionSuccessfullAction,
  deletePromotionsFailedAction,
  deletePromotionsSuccessfullAction,
  getNumberOfPrintsListFailedAction,
  getNumberOfPrintsListSuccessfullAction,
  getNumberOfPrintsListAction,
  updateNumberOfPrintsFailedAction,
  updateNumberOfPrintsSuccessfullAction,
  getPaymentDojoListAction,
  getPaymentDojoListFailedAction,
  getPaymentDojoListSuccessfullAction,
  createPaymentDojoSettingFailedAction,
  createPaymentDojoSettingSuccessfullAction,
  updatePaymentDojoSettingFailedAction,
  updatePaymentDojoSettingSuccessfullAction,
  deletePaymentDojoSettingFailedAction,
  deletePaymentDojoSettingSuccessfullAction,
  getSecondScreenImagesListAction,
  getSecondScreenImagesListFailedAction,
  getSecondFullScreenImagesListSuccessfullAction,
  getSecondHalfScreenImagesListSuccessfullAction,
  updateSecondScreenEachPhotoSettingFailedAction,
  updateSecondScreenEachPhotoSettingSuccessfullAction,
  deleteSecondScreenEachPhotoFailedAction,
  deleteSecondScreenEachPhotoSuccessfullAction,
  createSecondScreenImagesAction,
  createSecondScreenImagesSuccessfullAction,
  createSecondScreenImagesFailedAction,
  getAdyenListAction,
  getAdyenListFailedAction,
  getAdyenListSuccessfullAction,
  updateAdyenFailedAction,
  updateAdyenSuccessfullAction,
  getETPayListAction,
  getETPayListFailedAction,
  getETPayListSuccessfullAction,
  updateETPayFailedAction,
  updateETPaySuccessfullAction,
  deleteETPaySuccessfullAction,
  deleteETPayFailedAction,
  createETPayFailedAction,
  createETPaySuccessfullAction,
  updateSalesReportFailedAction,
  updateSalesReportSuccessfullAction,
  getMetaDataListAction,
  getMetaDataListFailedAction,
  getMetaDataListSuccessfullAction,
  updateMetaDataFailedAction,
  updateMetaDataSuccessfullAction,
} from "../settings/Action";
import {
  appSettingServices,
  updateShopSettingAppOrderProccessServices,
  updateShopSettingAppPaymentServices,
  updateShopSettingAppColourServices,
  updateShopSettingAppDisplayServices,
  updateShopSettingAppPasswordProtectServices,
  getDeliveryChargeTypeServices,
  createDeliveryChargeServices,
  updateDeliveryChargeServices,
  deleteDeliveryChargeServices,
  getDeliverySettingServices,
  updateDeliverySettingServices,
  codeDetailListServices,
  createCodeDetailServices,
  deleteCodeDetailServices,
  updateCodeDetailServices,
  getPaymentLinkListService,
  updatePaymentLinkService,
  getNumberOfPrintsService,
  updateNumberOfPrintService,
  getPaymentDojoSettingListService,
  createPaymentDojoSettingService,
  updatePaymentDojoSettingService,
  deletePaymentDojoSettingService,
  getSecondScreenImagesListService,
  updateSecondScreenEachPhotoSettingService,
  updateSecondScreenImagesService,
  deleteSecondScreenEachPhotoService,
  createSecondScreenImagesService,
  updateSalesReportServise,
} from "../../services/AppSettingsServices";
import { getWorksStationsAction } from "../WorksStations/Actions";
import {
  getWebSettingServices,
  updateWebSettingServices,
  getOpenCloseTimeListService,
  updateOpenCloseTimeListService,
  createOpenCloseTimeService,
  getWebPaymentSettingService,
  updateWebPaymentSettingService,
  getStripeListservice,
  updateStripeservice,
  getShopSettingTimeGapService,
  updateShopSettingTimeGapService,
  getWebDeliveryListServices,
  updateWebDeliveryServices,
  getMaintenanceListServices,
  updateMaintenanceServices,
  getNotificationEmailSeevice,
  updateNotificationEmailService,
  createNotificationEmailService,
  deleteNotificationEmailService,
  getPromotionsListService,
  createPromotionService,
  updatePromotionService,
  deletePromotionService,
  getAdyenListService,
  updateAdyenService,
  getMetaDataListService,
  updateMetaDataService,
} from "../../services/WebSettingServices";
function* getAppSettings({ payload }) {
  try {
    const response = yield call(
      appSettingServices,
      payload.login_key,
      payload.user_id,
      payload.shopId
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        let all = [];
        all[0] = response.data.setting_app_payment;
        all[1] = response.data.setting_app_orderprocess;
        all[2] = response.data.setting_app_colour;
        all[3] = response.data.setting_app_display;
        all[4] = response.data.setting_app_printer;
        all[5] = response.data.setting_app_taking;
        all[6] = response.data.setting_app_passwordprotect;
        all[7] = response.data.setting_app_sale_report;
        yield put(getAllAppSettingsSuccessfullAction(all));
      } else {
        yield put(getAllAppSettingFailedAction());
      }
    } else {
      yield put(getAllAppSettingFailedAction());
    }
  } catch (error) {
    yield put(getAllAppSettingFailedAction());
  }
}
function* updateShopSettingAppOrderProccess({ payload }) {
  try {
    const response = yield call(
      updateShopSettingAppOrderProccessServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.prefix_yes_for_submenu,
      payload.prefix_no_for_submenu,
      payload.total_half_text,
      payload.users_need_to_login_before_taking_order,
      payload.use_table_area,
      payload.ask_number_guests,
      payload.ask_customer_on_collection,
      payload.ask_customer_on_takeaway,
      payload.show_tip_in_eat_in,
      payload.show_tip_in_takeaway
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateShopSettingAppOrderProcessSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getAllAppSettingsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(
          updateShopSettingAppOrderProcessFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        updateShopSettingAppOrderProcessFailedAction("Server error , try again")
      );
    }
  } catch (error) {
    yield put(updateShopSettingAppOrderProcessFailedAction(error.message));
  }
}
function* updateShopSettingAppPayment({ payload }) {
  try {
    const response = yield call(
      updateShopSettingAppPaymentServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.use_peyment_method,
      payload.delivery_default_peyment_method,
      payload.collection_default_peyment_method,
      payload.takeaway_default_peyment_method,
      payload.eat_in_default_peyment_method,
      payload.show_not_paid_in_delivery,
      payload.show_not_paid_in_collection,
      payload.show_not_paid_in_takeaway,
      payload.show_not_paid_in_eatIn
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateShopSettingAppPaymentSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getAllAppSettingsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(
          updateShopSettingAppPaymentFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateShopSettingAppPaymentFailedAction("Server error , try again")
      );
    }
  } catch (error) {
    yield put(updateShopSettingAppPaymentFailedAction(error.message));
  }
}

function* updateShopSettingAppColour({ payload }) {
  try {
    const response = yield call(
      updateShopSettingAppColourServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.display_order_lasting_morethan_minutes,
      payload.order_queue_list_colour,
      payload.assing_driver_flashing_colour,
      payload.printed_orders_colour
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateShopSettingAppColourSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getAllAppSettingsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(
          updateShopSettingAppColourFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateShopSettingAppColourFailedAction("Server error ,try again")
      );
    }
  } catch (error) {
    yield put(updateShopSettingAppColourFailedAction(error.message));
  }
}

function* updateShopSettingAppDisplay({ payload }) {
  try {
    const response = yield call(
      updateShopSettingAppDisplayServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.display_previous_order,
      payload.show_basket_in_second_screen,
      payload.delivery_description,
      payload.collection_description,
      payload.takeaway_description,
      payload.eatIn_description,
      payload.web_del_description,
      payload.web_coll_description,
      payload.kiosk_takeaway_description,
      payload.kiosk_eatIn_description,
      payload.show_barcode,
      payload.show_not_paid_finishing_order_in_collection,
      payload.show_not_paid_finishing_order_in_eatIn,
      payload.shop_opening_time,
      payload.shop_closing_time,
      payload.second_language
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateShopSettingAppDisplaySuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getAllAppSettingsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(
          updateShopSettingAppDisplayFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateShopSettingAppDisplayFailedAction("Server error, try again")
      );
    }
  } catch (error) {
    yield put(updateShopSettingAppDisplayFailedAction(error.message));
  }
}

function* updateSettingAppPsswordProtect({ payload }) {
  try {
    const response = yield call(
      updateShopSettingAppPasswordProtectServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.driver_float,
      payload.driver_payment,
      payload.takings,
      payload.edit_order,
      payload.driver_analysis,
      payload.order_line_add_change,
      payload.data_export_to_excel,
      payload.discount,
      payload.timeSheet,
      payload.cancel_order,
      payload.maintenance,
      payload.list_all_order,
      payload.system_setup
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateShopSettingAppPasswordProtectSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getAllAppSettingsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(
          updateShopSettingAppPasswordProtectFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        updateShopSettingAppPasswordProtectFailedAction(
          "Server error , try again"
        )
      );
    }
  } catch (error) {
    yield put(updateShopSettingAppPasswordProtectFailedAction(error.message));
  }
}
//DELIVERY CHRGE SAGAS

function* getDeliveryChargeType({ payload }) {
  try {
    const response = yield call(
      getDeliveryChargeTypeServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Delivery_Custom_Charge.length > 0) {
          let my_list = [];
          for (
            let i = 0;
            i < response.data.Delivery_Custom_Charge.length;
            i++
          ) {
            my_list.push({
              ...response.data.Delivery_Custom_Charge[i],
              showDeleteBox: false,
            });
          }
          yield put(getDeliveyChargeTypeSuccessfullAction(my_list));
        } else {
          yield put(getDeliveyChargeTypeFailedAction());
        }
      } else {
        yield put(getDeliveyChargeTypeFailedAction());
      }
    } else {
      yield put(getDeliveyChargeTypeFailedAction());
    }
  } catch (error) {
    yield put(getDeliveyChargeTypeFailedAction(error.message));
  }
}
function* createDeliveryCharge({ payload }) {
  try {
    const response = yield call(
      createDeliveryChargeServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.title,
      payload.delivery_charge,
      payload.background_color
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(
          createDeliveryChargeSuccesffullAction(response.data[0].ResultMessage)
        );
        yield put(
          getDeliveyChargeTypeAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          createDeliveryChargeFailedAction(response.data[0].ResultMessage)
        );
      }
    } else {
      yield put(
        createDeliveryChargeFailedAction(response.data[0].ResultMessage)
      );
    }
  } catch (error) {
    yield put(createDeliveryChargeFailedAction(error.message));
  }
}
function* updateDeliveryCharge({ payload }) {
  try {
    const response = yield call(
      updateDeliveryChargeServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.title,
      payload.delivery_charge,
      payload.background_color
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateDeliveryChargeSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getDeliveyChargeTypeAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateDeliveryChargeFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(updateDeliveryChargeFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateDeliveryChargeFailedAction(error.message));
  }
}
function* deleteDeliveryCharge({ payload }) {
  try {
    const response = yield call(
      deleteDeliveryChargeServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteDeliveryChargeSuccesffullAction(response.data.ResultMessage)
        );
        yield put(
          getDeliveyChargeTypeAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          deleteDeliveryChargeFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(deleteDeliveryChargeFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteDeliveryChargeFailedAction(error.message));
  }
}
function* getDeliverySetting({ payload }) {
  try {
    const response = yield call(
      getDeliverySettingServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Delivery_app_Setting_Get.length > 0) {
          yield put(
            getDeliverySettingSuccessfullAction(
              response.data.Delivery_app_Setting_Get
            )
          );
        } else {
          yield put(getDeliverySettingFailedAction());
        }
      } else {
        yield put(yield put(getDeliverySettingFailedAction()));
      }
    } else {
      yield put(getDeliverySettingFailedAction());
    }
  } catch (error) {
    yield put(getDeliverySettingFailedAction(error.message));
  }
}
function* updateDeliverySetting({ payload }) {
  try {
    const response = yield call(
      updateDeliverySettingServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,

      payload.default_delivery_charge_type_id,
      payload.spicific_amount,
      payload.Print_free_delivery_message
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateDeliverySettingSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getDeliverySettingAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateDeliverySettingFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      updateDeliverySettingFailedAction(response.data.ResultMessage);
    }
  } catch (error) {
    updateDeliverySettingFailedAction(error.message);
  }
}
function* codeDetailList({ payload }) {
  try {
    const response = yield call(
      codeDetailListServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.group_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.code_group_detail.length > 0) {
          let my_list = [];
          for (let i = 0; i < response.data.code_group_detail.length; i++) {
            my_list.push({
              ...response.data.code_group_detail[i],
              showDeleteBox: false,
            });
          }
          yield put(codeDetailListSuccessfullAction(my_list));
        } else {
          yield put(codeDetailListFailedAction());
        }
      } else {
        yield put(codeDetailListFailedAction());
      }
    } else {
      yield put(codeDetailListFailedAction());
    }
  } catch (error) {
    yield put(codeDetailListFailedAction(error.message));
  }
}
function* createCodeDetail({ payload }) {
  try {
    const response = yield call(
      createCodeDetailServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.group_id,
      payload.title,
      payload.active,
      payload.sort_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(
          createCodeDetailSuccessfullAction(response.data[0].ResultMessage)
        );

        yield put(
          codeDetailListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
        // yield put(
        //   getDropDownAction(payload.login_key, payload.user_id, payload.shopId)
        // );
      } else {
        yield put(createCodeDetailFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createCodeDetailFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createCodeDetailFailedAction(error.message));
  }
}
function* updateCodeDetail({ payload }) {
  try {
    const response = yield call(
      updateCodeDetailServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.group_id,
      payload.title,
      payload.active,
      payload.sort_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateCodeDetailSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          codeDetailListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
        yield put(
          getDropDownAction(payload.login_key, payload.user_id, payload.shopId)
        );
      } else {
        yield put(updateCodeDetailFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateCodeDetailFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateCodeDetailFailedAction(error.message));
  }
}
function* deleteCodeDetail({ payload }) {
  try {
    const response = yield call(
      deleteCodeDetailServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteCodeDetailSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          codeDetailListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(deleteCodeDetailFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteCodeDetailFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteCodeDetailFailedAction(error.message));
  }
}
function* getWebSetting({ payload }) {
  try {
    const response = yield call(
      getWebSettingServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.setting_web.length > 0) {
          yield put(getWebSettingSuccessfullAction(response.data.setting_web));
        } else {
          yield put(getWebSettingFailedAction());
        }
      } else {
        yield put(getWebSettingFailedAction());
      }
    } else {
      yield put(getWebSettingFailedAction());
    }
  } catch (error) {
    yield put(getWebSettingFailedAction());
  }
}
function* updateWebSetting({ payload }) {
  try {
    const response = yield call(
      updateWebSettingServices,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.mail_profile,
      payload.domain,
      payload.fav_icon,
      payload.banner_text,
      payload.banner_text_l2,
      payload.banner_image,
      payload.show_banner_image,
      payload.facebooh_url,
      payload.twittr_url,
      payload.instgram_url,
      payload.app_store_url,
      payload.google_play_url,
      payload.postcode_message,
      payload.background_image,
      payload.show_background_image,
      payload.background_repeate
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateWebSettingSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getWebSettingAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateWebSettingFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateWebSettingFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateWebSettingFailedAction(error.message));
  }
}
function* getOpenCloseTimeList({ payload }) {
  try {
    const response = yield call(
      getOpenCloseTimeListService,
      payload.login_key,
      payload.shop_id,
      payload.user_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.open_close_list) {
          if (response.data.open_close_list.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.open_close_list.length; i++) {
              let sub_list1 = [];
              let sub_list2 = [];
              let main_sub_list = [];
              let first_list = [];

              sub_list1[0] = {
                label:
                  response.data.open_close_list[i].delivery_open_time2 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].delivery_open_time2,
                value: 1,
              };
              sub_list1[1] = {
                label:
                  response.data.open_close_list[i].delivery_close_time2 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].delivery_close_time2,
                value: 2,
              };
              sub_list1[2] = {
                label:
                  response.data.open_close_list[i].collection_open_time2 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].collection_open_time2,
                value: 3,
              };
              sub_list1[3] = {
                label:
                  response.data.open_close_list[i].collection_close_time2 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].collection_close_time2,
                value: 4,
              };

              sub_list1[4] = { title: "second time" };
              sub_list2[0] = {
                label:
                  response.data.open_close_list[i].delivery_open_time3 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].delivery_open_time3,
                value: 1,
              };
              sub_list2[1] = {
                label:
                  response.data.open_close_list[i].delivery_close_time3 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].delivery_close_time3,
                value: 2,
              };
              sub_list2[2] = {
                label:
                  response.data.open_close_list[i].collection_open_time3 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].collection_open_time3,
                value: 3,
              };
              sub_list2[3] = {
                label:
                  response.data.open_close_list[i].collection_close_time3 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].collection_close_time3,
                value: 4,
              };

              sub_list2[4] = { title: "third time" };

              first_list[0] = {
                label:
                  response.data.open_close_list[i].delivery_open_time1 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].delivery_open_time1,
                value: 1,
              };
              first_list[1] = {
                label:
                  response.data.open_close_list[i].delivery_close_time1 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].delivery_close_time1,
                value: 2,
              };
              first_list[2] = {
                label:
                  response.data.open_close_list[i].collection_open_time1 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].collection_open_time1,
                value: 3,
              };
              first_list[3] = {
                label:
                  response.data.open_close_list[i].collection_close_time1 ==
                  "00:00"
                    ? "None"
                    : response.data.open_close_list[i].collection_close_time1,
                value: 4,
              };

              main_sub_list[1] = sub_list2;

              main_sub_list[0] = sub_list1;

              my_list.push({
                ...response.data.open_close_list[i],
                showSublistIcon: true,
                mainItem: first_list,
                sublist: main_sub_list,
                showSubList:
                  response.data.open_close_list[i].open_delivery_2 == 1 ||
                  response.data.open_close_list[i].open_delivery_3 == 1 ||
                  response.data.open_close_list[i].open_collection_2 == 1 ||
                  response.data.open_close_list[i].open_collection_3 == 1
                    ? true
                    : false,
              });
            }

            yield put(getOpenCloseTimeListSuccessfullAction(my_list));
          } else {
            yield put(
              getOpenCloseTimeListFailedAction(response.data.ResultMessage)
            );
          }
        } else {
          yield put(
            getOpenCloseTimeListFailedAction(response.data.ResultMessage)
          );
        }
      } else {
        yield put(
          getOpenCloseTimeListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getOpenCloseTimeListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getOpenCloseTimeListFailedAction(error.message));
  }
}
function* updateOpenCloseTime({ payload }) {
  try {
    const response = yield call(
      updateOpenCloseTimeListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.day_of_week,
      payload.delivery_open_time1,
      payload.delivery_close_time1,
      payload.delivery_open_time2,
      payload.delivery_close_time2,
      payload.delivery_open_time3,
      payload.delivery_close_time3,
      payload.collection_open_time1,
      payload.collection_close_time1,
      payload.collection_open_time2,
      payload.collection_close_time2,
      payload.collection_open_time3,
      payload.collection_close_time3,
      payload.open_delivery,
      payload.open_collection,
      payload.open_delivery_2,
      payload.open_collection_2,
      payload.open_delivery_3,
      payload.open_collection_3,
      payload.is_open_close_status,
      payload.myList
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateOpenCloseTimeSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getOpenCloseTimeListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateOpenCloseTimeFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateOpenCloseTimeFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateOpenCloseTimeFailedAction(error.message));
  }
}
function* createOpenCloseTime({ payload }) {
  try {
    const response = yield call(
      createOpenCloseTimeService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.day_of_week,
      payload.delivery_open_time1,
      payload.delivery_close_time1,
      payload.delivery_open_time2,
      payload.delivery_close_time2,
      payload.delivery_open_time3,
      payload.delivery_close_time3,
      payload.collection_open_time1,
      payload.collection_close_time1,
      payload.collection_open_time2,
      payload.collection_close_time2,
      payload.collection_open_time3,
      payload.collection_close_time3,
      payload.open_delivery,
      payload.open_collection,
      payload.open_delivery_2,
      payload.open_collection_2,
      payload.open_delivery_3,
      payload.open_collection_3
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createOpenCloseTimeSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getOpenCloseTimeListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(createOpenCloseTimeFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createOpenCloseTimeFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(createOpenCloseTimeFailedAction(error.massage));
  }
}
function* getWebPaymentSetting({ payload }) {
  try {
    const response = yield call(
      getWebPaymentSettingService,
      payload.login_key,
      payload.user_id,
      payload.shopId
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.payment_setting_get) {
          if (response.data.payment_setting_get.length > 0) {
            const copy_web_payment_list = [
              ...response.data.payment_setting_get,
            ];
            copy_web_payment_list[0] = {
              ...copy_web_payment_list[0],
              DeliveryPaymentMethod: JSON.parse(
                copy_web_payment_list[0].DeliveryPaymentMethod
              ),
              CollectionPaymentMethod: JSON.parse(
                copy_web_payment_list[0].CollectionPaymentMethod
              ),
            };

            yield put(
              getWebPaymentSettingListSuccessfullAction(copy_web_payment_list)
            );
          } else {
            yield put(
              getWebPaymentSettingListFailedAction(response.data.ResultMessage)
            );
          }
        } else {
          yield put(
            getWebPaymentSettingListFailedAction(response.data.ResultMessage)
          );
        }
      } else {
        yield put(
          getWebPaymentSettingListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getWebPaymentSettingListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getWebPaymentSettingListFailedAction(error.message));
  }
}
function* updateWebPaymentSetting({ payload }) {
  try {
    const response = yield call(
      updateWebPaymentSettingService,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.id,
      payload.delivery_payment_method_cash,
      payload.delivery_payment_method_stripe,
      payload.delivery_payment_method_pay_pal,
      payload.delivery_payment_method_adyen,
      payload.collection_patment_method_cash,
      payload.collection_patment_method_stripe,
      payload.collection_patment_method_pay_pal,
      payload.collection_patment_method_adyen,
      payload.credit_card_surcharge,
      payload.credit_card_surcharge_label,
      payload.credit_card_surcharge_visible
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateWebPaymentSettingSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getWebPaymentSettingListAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(
          updateWebPaymentSettingFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateWebPaymentSettingFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateWebPaymentSettingFailedAction(error.message));
  }
}

function* getStripeList({ payload }) {
  try {
    const response = yield call(
      getStripeListservice,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.stripe_setting_get) {
          if (response.data.stripe_setting_get.length > 0) {
            yield put(
              getStripeListSuccessfullAction(response.data.stripe_setting_get)
            );
          } else {
            yield put(getStripeListFailedAction(""));
          }
        } else {
          yield put(getStripeListFailedAction(""));
        }
      } else {
        yield put(getStripeListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getStripeListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getStripeListFailedAction(error.message));
  }
}
function* updateStripe({ payload }) {
  try {
    const response = yield call(
      updateStripeservice,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.secret_ley,
      payload.publishable_key,
      payload.account1,
      payload.account2,
      payload.active_day_of_acount1,
      payload.active_day_of_acount2,
      payload.webhook,
      payload.ofOrder,
      payload.statusPence,
      payload.accountType,
      payload.stripeMode
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateStripeSuccessfullAction(response.data.ResultMessage));
        yield put(
          getStripeListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateStripeFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateStripeFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    updateStripeFailedAction(error.message);
  }
}
function* getShopSettingTimeGap({ payload }) {
  try {
    const response = yield call(
      getShopSettingTimeGapService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.setting_Public_TimeGap) {
          if (response.data.setting_Public_TimeGap.length > 0) {
            yield put(
              getShopSettingTimeGapListSuccessfullAction(
                response.data.setting_Public_TimeGap
              )
            );
          } else {
            yield put(
              getShopSettingTimeGapListFailedAction(response.data.ResultMessage)
            );
          }
        } else {
          yield put(
            getShopSettingTimeGapListFailedAction(response.data.ResultMessage)
          );
        }
      } else {
        yield put(
          getShopSettingTimeGapListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getShopSettingTimeGapListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getShopSettingTimeGapListFailedAction(error.message));
  }
}
function* updateShopSettingTimeGap({ payload }) {
  try {
    const response = yield call(
      updateShopSettingTimeGapService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.normal_delivery,
      payload.busy_delivery,
      payload.normal_collection,
      payload.busy_collection,
      payload.expire_time,
      payload.display_time_gap_in_app,
      payload.active_time_gap,
      payload.delivery_time_segmentation,
      payload.collection_time_segmentantion
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateShopSettingTimeGapSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getShopSettingTimeGapListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateShopSettingTimeGapFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateShopSettingTimeGapFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateShopSettingTimeGapFailedAction(error.message));
  }
}

function* getWebDeliveryList({ payload }) {
  try {
    const response = yield call(
      getWebDeliveryListServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Delivery_web_Setting_Get) {
          if (response.data.Delivery_web_Setting_Get.length > 0) {
            yield put(
              getWebDeliveryListSuccessfullAction(
                response.data.Delivery_web_Setting_Get
              )
            );
          } else {
            yield put(
              getWebDeliveryListFailedAction(response.data.ResultMessage)
            );
          }
        } else {
          yield put(
            getWebDeliveryListFailedAction(response.data.ResultMessage)
          );
        }
      } else {
        yield put(getWebDeliveryListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getWebDeliveryListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getWebDeliveryListFailedAction(error.message));
  }
}
function* updateWebDelivery({ payload }) {
  try {
    const response = yield call(
      updateWebDeliveryServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.web_min_order_value,
      payload.web_min_order_charge_message,

      payload.web_min_free_delivery
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateWebDeliverySuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getWebDeliveryListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateWebDeliveryFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateWebDeliveryFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateWebDeliveryFailedAction(error.message));
  }
}
function* getMaintenanceList({ payload }) {
  try {
    const response = yield call(
      getMaintenanceListServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.maintenance_message_list) {
          if (response.data.maintenance_message_list.length > 0) {
            yield put(
              maintenanceMessageListAction(
                response.data.maintenance_message_list
              )
            );
          }
        }
        if (response.data.maintenance_get) {
          if (response.data.maintenance_get.length > 0) {
            yield put(
              getMaintenanceListSuccessfullAction(response.data.maintenance_get)
            );
          } else {
            yield put(
              getMaintenanceListFailedAction(response.data.ResultMessage)
            );
          }
        } else {
          yield put(
            getMaintenanceListFailedAction(response.data.ResultMessage)
          );
        }
      } else {
        yield put(getMaintenanceListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getMaintenanceListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getMaintenanceListFailedAction(error.message));
  }
}
function* updateMaintenance({ payload }) {
  try {
    const response = yield call(
      updateMaintenanceServices,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.maintenance_mode,
      payload.maintenance_message,
      payload.forse_close_order,
      payload.is_only_asap,
      payload.close_order_message,
      payload.show_asap,
      payload.next_day_pree_order,
      payload.pree_order,
      payload.pree_order_message,
      payload.maintenance_type,
      payload.maintenance_open_shop_date
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateMaintenanceSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getMaintenanceListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateMaintenanceFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateMaintenanceFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateMaintenanceFailedAction(error.message));
  }
}
//PAYMENT LINK SMS
function* getPaymentLink({ payload }) {
  try {
    const response = yield call(
      getPaymentLinkListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.payment_link_sms) {
          if (response.data.payment_link_sms.length > 0) {
            yield put(
              getPaymentLinkListSuccessfullAction(
                response.data.payment_link_sms
              )
            );
          } else {
            yield put(
              getPaymentLinkListFailedAction(response.data.ResultMessage)
            );
          }
        } else {
          yield put(
            getPaymentLinkListFailedAction(response.data.ResultMessage)
          );
        }
      } else {
        yield put(getPaymentLinkListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getPaymentLinkListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getPaymentLinkListFailedAction(error.message));
  }
}
function* updatePaymentLink({ payload }) {
  try {
    const response = yield call(
      updatePaymentLinkService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.delivery_by_payeat,
      payload.collection_by_payeat,
      payload.takeaway_by_payeat,
      payload.eatIn_by_payeat,
      payload.expire_time_min,
      payload.payment_method,
      payload.ask_before_sending_text_message,
      payload.message,
      payload.domain,
      payload.admin_fee
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updatePaymentLinkSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getPaymentLinkListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updatePaymentLinkFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updatePaymentLinkFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updatePaymentLinkFailedAction(error.message));
  }
}

// NOTIFICATION EMAIL
function* getNotificationEmail({ payload }) {
  try {
    const response = yield call(
      getNotificationEmailSeevice,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.notification_email_list) {
          if (response.data.notification_email_list.length > 0) {
            let my_list = [];
            for (
              let i = 0;
              i < response.data.notification_email_list.length;
              i++
            ) {
              my_list.push({
                ...response.data.notification_email_list[i],
                showDeleteBox: false,
              });
            }
            yield put(getNotificationEmailListSuccessfullAction(my_list));
          } else {
            yield put(getNotificationEmailListFailedAction(""));
          }
        } else {
          yield put(getNotificationEmailListFailedAction(""));
        }
      } else {
        yield put(
          getNotificationEmailListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getNotificationEmailListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getNotificationEmailListFailedAction(error.message));
  }
}
function* createNotificationEmail({ payload }) {
  try {
    const response = yield call(
      createNotificationEmailService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.email
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createNotificationEmailSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getNotificationEmailListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          createNotificationEmailFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        createNotificationEmailFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(createNotificationEmailFailedAction(error.message));
  }
}
function* updateNotificationEmail({ payload }) {
  try {
    const response = yield call(
      updateNotificationEmailService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.email,
      payload.id,
      payload.active
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateNotificationEmailSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getNotificationEmailListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateNotificationEmailFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateNotificationEmailFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateNotificationEmailFailedAction(error.message));
  }
}
function* deleteNotificationEmail({ payload }) {
  try {
    const response = yield call(
      deleteNotificationEmailService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteNotificationEmailSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getNotificationEmailListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          deleteNotificationEmailFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        deleteNotificationEmailFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(deleteNotificationEmailFailedAction(error.message));
  }
}
function* getPromotionList({ payload }) {
  try {
    const response = yield call(
      getPromotionsListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.promotions_List) {
          if (response.data.promotions_List.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.promotions_List.length; i++) {
              my_list.push({
                ...response.data.promotions_List[i],
                showEditBox: false,
                showDeleteBox: false,
                swithStatus: response.data.promotions_List[i].Status
                  ? true
                  : false,
                swithExcludeMealDeals: response.data.promotions_List[i]
                  .ExcludeMealDeals
                  ? true
                  : false,
              });
            }
            yield put(getPromotionListSuccessfullAction(my_list));
          } else {
            yield put(getPromotionListFailedAction(""));
          }
        } else {
          yield put(getPromotionListFailedAction(""));
        }
      } else {
        yield put(getPromotionListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getPromotionListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getPromotionListFailedAction(error.message));
  }
}
function* createPromotion({ payload }) {
  try {
    const response = yield call(
      createPromotionService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.title,
      payload.description,
      payload.start_date,
      payload.end_date,
      payload.day_of_week0,
      payload.day_of_week1,
      payload.day_of_week2,
      payload.day_of_week3,
      payload.day_of_week4,
      payload.day_of_week5,
      payload.day_of_week6,
      payload.code,
      payload.sale_method,
      payload.rate,
      payload.rate_option,
      payload.min_order,
      payload.status,
      payload.excludes_meal_deals,
      payload.uses_per_promotion,
      payload.uses_per_customer,
      payload.excluded_promotions,
      payload.show_in_site
    );
    if (response.status == 200) {
      if (response.data[0].ResultCode == 1) {
        yield put(
          createPromotionSuccessfullAction(response.data[0].ResultMessage)
        );
        yield put(
          getPromotionListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(createPromotionFailedAction(response.data[0].ResultMessage));
      }
    } else {
      yield put(createPromotionFailedAction(response.data[0].ResultMessage));
    }
  } catch (error) {
    yield put(createPromotionFailedAction(error.message));
  }
}
function* updatePromotion({ payload }) {
  try {
    const response = yield call(
      updatePromotionService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.title,
      payload.description,
      payload.start_date,
      payload.end_date,
      payload.day_of_week0,
      payload.day_of_week1,
      payload.day_of_week2,
      payload.day_of_week3,
      payload.day_of_week4,
      payload.day_of_week5,
      payload.day_of_week6,
      payload.code,
      payload.sale_method,
      payload.rate,
      payload.rate_option,
      payload.min_order,
      payload.status,
      payload.excludes_meal_deals,
      payload.uses_per_promotion,
      payload.uses_per_customer,
      payload.excluded_promotions,
      payload.show_in_site
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updatePromotionSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getPromotionListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updatePromotionFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updatePromotionFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updatePromotionFailedAction(error.message));
  }
}
function* deletePromotion({ payload }) {
  try {
    const response = yield call(
      deletePromotionService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deletePromotionsSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getPromotionListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(deletePromotionsFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deletePromotionsFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deletePromotionsFailedAction(error.message));
  }
}
function* getNumberOfPrints({ payload }) {
  try {
    const response = yield call(
      getNumberOfPrintsService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.works_stations_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.printer_Number_Copy_list) {
          if (response.data.printer_Number_Copy_list.length > 0) {
            yield put(
              getNumberOfPrintsListSuccessfullAction(
                response.data.printer_Number_Copy_list
              )
            );
          } else {
            yield put(getNumberOfPrintsListFailedAction(""));
          }
        } else {
          yield put(getNumberOfPrintsListFailedAction(""));
        }
      } else {
        yield put(
          getNumberOfPrintsListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getNumberOfPrintsListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getNumberOfPrintsListFailedAction(error.message));
  }
}
function* updateNumberOfPrints({ payload }) {
  try {
    const response = yield call(
      updateNumberOfPrintService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.works_stations_id,
      payload.delivery_count,
      payload.collection_count,
      payload.takeaway_count,
      payload.eat_in_count
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateNumberOfPrintsSuccessfullAction(response.data.ResultMessage)
        );
        store.set(
          "printer_Number_Copy_list",
          response.data.printer_Number_Copy_list
        );
        yield put(
          getNumberOfPrintsListSuccessfullAction(
            response.data.printer_Number_Copy_list
          )
        );
        // yield put(
        //   getNumberOfPrintsListAction(
        //     payload.login_key,
        //     payload.user_id,
        //     payload.shop_id,
        //     payload.works_stations_id
        //   )
        // );
      } else {
        yield put(updateNumberOfPrintsFailedAction(""));
      }
    } else {
      yield put(updateNumberOfPrintsFailedAction(""));
    }
  } catch (error) {
    yield put(updateNumberOfPrintsFailedAction(error.message));
  }
}
function* getPaymentDojoSetting({ payload }) {
  try {
    const response = yield call(
      getPaymentDojoSettingListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.dojo_setting_get) {
          if (response.data.dojo_setting_get.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.dojo_setting_get.length; i++) {
              my_list.push({
                ...response.data.dojo_setting_get[i],
                showDeleteBox: false,
              });
            }
            yield put(
              getWorksStationsAction(
                payload.login_key,
                payload.user_id,
                payload.shop_id
              )
            );
            yield put(getPaymentDojoListSuccessfullAction(my_list));
          } else {
            yield put(getPaymentDojoListFailedAction(""));
          }
        } else {
          yield put(getPaymentDojoListFailedAction(""));
        }
      } else {
        yield put(getPaymentDojoListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getPaymentDojoListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getPaymentDojoListFailedAction(error.message));
  }
}
function* updatePaymentDojoSetting({ payload }) {
  try {
    const response = yield call(
      updatePaymentDojoSettingService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.title,
      payload.Tid,
      payload.api_url,
      payload.api_key,
      payload.currency,
      payload.software_house_id,
      payload.installer_id,
      payload.active,
      payload.workstation_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updatePaymentDojoSettingSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getPaymentDojoListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updatePaymentDojoSettingFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updatePaymentDojoSettingFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updatePaymentDojoSettingFailedAction(error.message));
  }
}
function* createPaymentDojoSetting({ payload }) {
  try {
    const response = yield call(
      createPaymentDojoSettingService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.title,
      payload.Tid,
      payload.api_url,
      payload.api_key,
      payload.currency,
      payload.software_house_id,
      payload.installer_id,
      payload.active,
      payload.workstation_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createPaymentDojoSettingSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getPaymentDojoListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          createPaymentDojoSettingFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        createPaymentDojoSettingFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(createPaymentDojoSettingFailedAction(error.message));
  }
}
function* deletePaymentDojoSetting({ payload }) {
  try {
    const response = yield call(
      deletePaymentDojoSettingService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deletePaymentDojoSettingSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getPaymentDojoListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          deletePaymentDojoSettingFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        deletePaymentDojoSettingFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(deletePaymentDojoSettingFailedAction(error.message));
  }
}
function* getSecondScreenImagesList({ payload }) {
  try {
    const response = yield call(
      getSecondScreenImagesListService,
      payload.shop_id,
      payload.login_key,
      payload.user_id
    );

    if ((response.status = 200)) {
      if (response.data.ResultCode == 1) {
        if (response.data.second_screen_images_list) {
          if (response.data.second_screen_images_list.length > 0) {
            let full_screen_list = [];
            let half_screen_list = [];

            for (
              let i = 0;
              i < response.data.second_screen_images_list.length;
              i++
            ) {
              if (
                response.data.second_screen_images_list[i].IsFullScreen == 1
              ) {
                full_screen_list.push(
                  response.data.second_screen_images_list[i]
                );
              } else {
                half_screen_list.push(
                  response.data.second_screen_images_list[i]
                );
              }
            }

            yield put(
              getSecondFullScreenImagesListSuccessfullAction(full_screen_list)
            );
            yield put(
              getSecondHalfScreenImagesListSuccessfullAction(half_screen_list)
            );
          } else {
            yield put(getSecondScreenImagesListFailedAction(""));
          }
        } else {
          yield put(getSecondScreenImagesListFailedAction(""));
        }
      } else {
        yield put(
          getSecondScreenImagesListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getSecondScreenImagesListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getSecondScreenImagesListFailedAction(error.message));
  }
}
function* createSecondScreenImages({ payload }) {
  try {
    const response = yield call(
      createSecondScreenImagesService,
      payload.formData,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.sort_id,
      payload.active,
      payload.image_file
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          createSecondScreenImagesSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getSecondScreenImagesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          createSecondScreenImagesFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        createSecondScreenImagesFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(createSecondScreenImagesFailedAction(error.message));
  }
}
export function* updateSeconsdScreenEachPhotoSetting({ payload }) {
  try {
    const response = yield call(
      updateSecondScreenEachPhotoSettingService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.sort_id,
      payload.active,
      payload.duration
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateSecondScreenEachPhotoSettingSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getSecondScreenImagesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          updateSecondScreenEachPhotoSettingFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        updateSecondScreenEachPhotoSettingFailedAction(
          response.data.ResultMessage
        )
      );
    }
  } catch (error) {
    yield put(updateSecondScreenEachPhotoSettingFailedAction(error.message));
  }
}
export function* deleteSeconsdScreenEachPhotoSetting({ payload }) {
  try {
    const response = yield call(
      deleteSecondScreenEachPhotoService,
      payload.shop_id,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.last_image_url
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteSecondScreenEachPhotoSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getSecondScreenImagesListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(
          deleteSecondScreenEachPhotoFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        deleteSecondScreenEachPhotoFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(deleteSecondScreenEachPhotoFailedAction(error.message));
  }
}
function* getAdyenList({ payload }) {
  try {
    const response = yield call(
      getAdyenListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.adyen_setting_get) {
          if (response.data.adyen_setting_get.length > 0) {
            yield put(
              getAdyenListSuccessfullAction(response.data.adyen_setting_get)
            );
          } else {
            yield put(getAdyenListFailedAction(""));
          }
        } else {
          yield put(getAdyenListFailedAction(""));
        }
      } else {
        yield put(getAdyenListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getAdyenListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getAdyenListFailedAction(error.message));
  }
}
function* updateAdyen({ payload }) {
  try {
    const response = yield call(
      updateAdyenService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.id,
      payload.webhook,
      payload.api_key,
      payload.account_id
    );
    if ((response.status = 200)) {
      if (response.data.ResultCode == 1) {
        yield put(
          getAdyenListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
        yield put(updateAdyenSuccessfullAction(response.data.ResultMessage));
      } else {
        yield put(updateAdyenFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateAdyenFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateAdyenFailedAction(error.message));
  }
}

export function* updateSalesReport({ payload }) {
  try {
    const response = yield call(
      updateSalesReportServise,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.show_categories_on_zprint
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateSalesReportSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getAllAppSettingsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(updateSalesReportFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateSalesReportFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateSalesReportFailedAction(error.message));
  }
}
export function* getMetaDataList({ payload }) {
  try {
    const response = yield call(
      getMetaDataListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.meta_list) {
          if (response.data.meta_list.length > 0) {
            yield put(
              getMetaDataListSuccessfullAction(response.data.meta_list)
            );
          } else {
            yield put(getMetaDataListFailedAction(""));
          }
        } else {
          yield put(getMetaDataListFailedAction(""));
        }
      } else {
        yield put(getMetaDataListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getMetaDataListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getMetaDataListFailedAction(error.message));
  }
}
export function* updateMetaData({ payload }) {
  try {
    const response = yield call(
      updateMetaDataService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.meta_title,
      payload.meta_desc,
      payload.meta_keywords
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(updateMetaDataSuccessfullAction(response.data.ResultMessage));
        yield put(
          getMetaDataListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id
          )
        );
      } else {
        yield put(updateMetaDataFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateMetaDataFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getMetaDataListFailedAction(error.message));
  }
}
export function* watchUpdateMetaData() {
  yield takeLatest(action_types.UPDATE_META_DATA, updateMetaData);
}

export function* watchGetMetaDataList() {
  yield takeLatest(action_types.GET_META_DATA_LIST, getMetaDataList);
}

export function* watchUpdateSalesReport() {
  yield takeLatest(action_types.UPDATE_SALES_REPORT, updateSalesReport);
}

export function* watchUpdateAdyen() {
  yield takeLatest(action_types.UPDATE_ADYEN, updateAdyen);
}

export function* watchGetAdyenList() {
  yield takeLatest(action_types.GET_ADYEN_LIST, getAdyenList);
}

export function* watchDeleteSecondScreenImages() {
  yield takeLatest(
    action_types.DELETE_SECOND_SCREEN_EACH_PHOTO,
    deleteSeconsdScreenEachPhotoSetting
  );
}
export function* watchUpdateSecondScreenImages() {
  yield takeLatest(
    action_types.UPDATE_SECOND_SCREEN_EACH_PHOTO_SETTING,
    updateSeconsdScreenEachPhotoSetting
  );
}
export function* watchCreateSecondScreenImages() {
  yield takeLatest(
    action_types.CREATE_SECOND_SCREEN_IMAGE,
    createSecondScreenImages
  );
}
export function* watchGetSecondScreenImagesList() {
  yield takeLatest(
    action_types.GET_SECOND_SCREEN_IMAGES_LIST,
    getSecondScreenImagesList
  );
}
export function* watchDeletePaymentDojoSetting() {
  yield takeLatest(
    action_types.DELETE_PAYMENT_DOJO_SETTING,
    deletePaymentDojoSetting
  );
}

export function* watchCreatePaymentDojoSetting() {
  yield takeLatest(
    action_types.CREATE_PAYMENT_DOJO_SETTING,
    createPaymentDojoSetting
  );
}

export function* watchUpdatePaymentDojoSetting() {
  yield takeLatest(
    action_types.UPDATE_PAYMENT_DOJO_SETTING,
    updatePaymentDojoSetting
  );
}
export function* watchGetPaymentDojoSetting() {
  yield takeLatest(
    action_types.GET_PAYMENT_DOJO_SETTING_LIST,
    getPaymentDojoSetting
  );
}

export function* watchUpdateNumberOfPrints() {
  yield takeLatest(action_types.UPDATE_NUMBER_OF_PRINTS, updateNumberOfPrints);
}
export function* watchGetNumberOfPrints() {
  yield takeLatest(action_types.GET_NUMBERS_OF_PRINTS, getNumberOfPrints);
}
export function* watchDeletePromotion() {
  yield takeLatest(action_types.DELETE_PROMOTION, deletePromotion);
}
export function* watchUpdatePromotion() {
  yield takeLatest(action_types.UPDATE_PROMOTION, updatePromotion);
}
export function* watchCreatePromotion() {
  yield takeLatest(action_types.CREATE_PROMOTION, createPromotion);
}
export function* watchGetPromotionList() {
  yield takeLatest(action_types.GET_PROMOTION_LIST, getPromotionList);
}
export function* watchDeleteNotificationEmail() {
  yield takeLatest(
    action_types.DELETE_NOTIFICATION_EMAIL,
    deleteNotificationEmail
  );
}
export function* watchUpdateNotificationEmail() {
  yield takeLatest(
    action_types.UPDATE_NOTIFICATION_EMAIL,
    updateNotificationEmail
  );
}
export function* watchCreateNotificationEmail() {
  yield takeLatest(
    action_types.CREATE_NOTIFICATION_EMAIL,
    createNotificationEmail
  );
}
export function* watchGetNotificationEmail() {
  yield takeLatest(
    action_types.GET_NOTIFICATION_EMAIL_LIST,
    getNotificationEmail
  );
}
export function* watchUpdatePaymentLink() {
  yield takeLatest(action_types.UPDATE_PAYMENT_LINK, updatePaymentLink);
}
export function* watchGetPaymentLink() {
  yield takeLatest(action_types.GET_PAYMENT_LINK, getPaymentLink);
}
export function* watchGetMaintenanceList() {
  yield takeLatest(action_types.GET_MAINTENANCE_LIST, getMaintenanceList);
}
export function* watchUpdateMaintenance() {
  yield takeLatest(action_types.UPDATE_MAINTENANCE, updateMaintenance);
}
export function* name() {
  yield takeLatest(action_types.GET_MAINTENANCE_LIST, getMaintenanceList);
}
export function* watchGetWebDeliveryList() {
  yield takeLatest(action_types.GET_WEB_DELIVERY_LIST, getWebDeliveryList);
}
export function* watchUpdateWebDelivery() {
  yield takeLatest(action_types.UPDATE_WEB_DELIVERY, updateWebDelivery);
}
export function* watchUpdateShopSettingTimeGap() {
  yield takeLatest(
    action_types.UPDATE_SHOP_SETTING_TIME_GAP,
    updateShopSettingTimeGap
  );
}
export function* watchGetShopSettingTimeGap() {
  yield takeLatest(
    action_types.GET_SHOP_SETTING_TIME_GAP_LIST,
    getShopSettingTimeGap
  );
}
//STRIPE

export function* watchGetStripeList() {
  yield takeLatest(action_types.GET_STRIPE_LIST, getStripeList);
}
export function* watchUpdateStripe() {
  yield takeLatest(action_types.UPDATE_STRIPE, updateStripe);
}
export function* watchUpdateWebPaymentSetting() {
  yield takeLatest(
    action_types.UPDATE_WEB_PAYMENT_SETTING,
    updateWebPaymentSetting
  );
}
export function* watchGetWebPaymentSetting() {
  yield takeLatest(
    action_types.GET_WEB_PAYMENT_SETTING_LIST,
    getWebPaymentSetting
  );
}
export function* watchCreateOpenCloseTime() {
  yield takeLatest(action_types.CREATE_OPEN_CLOSE_TIME, createOpenCloseTime);
}
export function* watchUpdateOpenCloseTime() {
  yield takeLatest(action_types.UPDATE_OPEN_CLOSE_TIME, updateOpenCloseTime);
}
export function* watchGetOpenCloseTimeList() {
  yield takeLatest(action_types.GET_OPEN_CLOSE_TIME_LIST, getOpenCloseTimeList);
}
export function* watchUpdateWebSetting() {
  yield takeLatest(action_types.UPDATE_WEB_SETTING, updateWebSetting);
}
export function* watchGetWebSetting() {
  yield takeLatest(action_types.GET_WEB_SETTING, getWebSetting);
}
export function* watchDeleteCodeDetail() {
  yield takeLatest(action_types.DELETE_CODE_DETAIL, deleteCodeDetail);
}
export function* watchUpdateCodeDetail() {
  yield takeLatest(action_types.UPDATE_CODE_DETAIL, updateCodeDetail);
}
export function* watchCreateCodeDetail() {
  yield takeLatest(action_types.CREATE_CODE_DETAIL, createCodeDetail);
}
export function* watchCodeDetailList() {
  yield takeLatest(action_types.CODE_DETAIL_LIST, codeDetailList);
}

export function* watchUpdateDeliverySetting() {
  yield takeLatest(action_types.UPDATE_DELIVERY_SETTING, updateDeliverySetting);
}
export function* watchGetDeliverySetting() {
  yield takeLatest(action_types.GET_DELIVERY_SETTING, getDeliverySetting);
}
export function* watchDeleteDeliveryCharge() {
  yield takeLatest(action_types.DELETE_DELIVERY_CHARGE, deleteDeliveryCharge);
}
export function* watchUpdateDeliveryCharge() {
  yield takeLatest(action_types.UPDATE_DELIVERY_CHARGE, updateDeliveryCharge);
}
export function* watchCreateDeliveryCharge() {
  yield takeLatest(action_types.CRETAE_DELIVERY_CHARGE, createDeliveryCharge);
}
export function* watchGetDeliveryChargeType() {
  yield takeLatest(
    action_types.GET_DELIVERY_CHARGE_TYPE,
    getDeliveryChargeType
  );
}
export function* watchUpdateSettingAppPasswordProtect() {
  yield takeLatest(
    action_types.UPDATE_SHOP_SETTING_APP_PASSWORD_PROTECT,
    updateSettingAppPsswordProtect
  );
}
export function* watchUpdateShopSettingAppDisplay() {
  yield takeLatest(
    action_types.UPDATE_SHOP_SETTING_APP_DISPLAY,
    updateShopSettingAppDisplay
  );
}
export function* watchUpdateShopSettingAppColour() {
  yield takeLatest(
    action_types.UPDATE_SHOP_SETTING_APP_COLOUR,
    updateShopSettingAppColour
  );
}

export function* watchUpdateShopSettingAppPyment() {
  yield takeLatest(
    action_types.UPDATE_SHOP_SETTING_APP_PAYMENT,
    updateShopSettingAppPayment
  );
}

export function* watchUpdateShopSettingAppOrderProccess() {
  yield takeLatest(
    action_types.UPDATE_SHOP_SETTING_APP_ORDER_PROCCESS,
    updateShopSettingAppOrderProccess
  );
}

export function* watchGetAppSettings() {
  yield takeLatest(action_types.GET_ALL_APP_SETTINGS, getAppSettings);
}

export default function* rootAppSettings() {
  yield all([
    fork(watchGetAppSettings),
    fork(watchUpdateShopSettingAppOrderProccess),
    fork(watchUpdateShopSettingAppPyment),
    fork(watchUpdateShopSettingAppColour),
    fork(watchUpdateShopSettingAppDisplay),
    fork(watchUpdateSettingAppPasswordProtect),
    fork(watchGetDeliveryChargeType),
    fork(watchCreateDeliveryCharge),
    fork(watchUpdateDeliveryCharge),
    fork(watchDeleteDeliveryCharge),
    fork(watchGetDeliverySetting),
    fork(watchUpdateDeliverySetting),
    fork(watchCodeDetailList),
    fork(watchCreateCodeDetail),
    fork(watchUpdateCodeDetail),
    fork(watchDeleteCodeDetail),
    fork(watchGetWebSetting),
    fork(watchUpdateWebSetting),
    fork(watchGetOpenCloseTimeList),
    fork(watchUpdateOpenCloseTime),
    fork(watchCreateOpenCloseTime),
    fork(watchGetWebPaymentSetting),
    fork(watchUpdateWebPaymentSetting),
    fork(watchGetStripeList),
    fork(watchUpdateStripe),
    fork(watchGetShopSettingTimeGap),
    fork(watchUpdateShopSettingTimeGap),
    fork(watchGetWebDeliveryList),
    fork(watchUpdateWebDelivery),
    fork(watchUpdateMaintenance),
    fork(watchGetMaintenanceList),
    fork(watchGetPaymentLink),
    fork(watchUpdatePaymentLink),
    fork(watchGetNotificationEmail),
    fork(watchCreateNotificationEmail),
    fork(watchUpdateNotificationEmail),
    fork(watchDeleteNotificationEmail),
    fork(watchGetPromotionList),
    fork(watchCreatePromotion),
    fork(watchUpdatePromotion),
    fork(watchDeletePromotion),
    fork(watchGetNumberOfPrints),
    fork(watchUpdateNumberOfPrints),
    fork(watchGetPaymentDojoSetting),
    fork(watchUpdatePaymentDojoSetting),
    fork(watchCreatePaymentDojoSetting),
    fork(watchDeletePaymentDojoSetting),
    fork(watchDeleteSecondScreenImages),
    fork(watchGetSecondScreenImagesList),
    fork(watchCreateSecondScreenImages),
    fork(watchUpdateSecondScreenImages),
    fork(watchUpdateAdyen),
    fork(watchGetAdyenList),
    fork(watchUpdateSalesReport),
    fork(watchGetMetaDataList),
    fork(watchUpdateMetaData),
  ]);
}
