import React, { useState, useEffect } from "react";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
  top_nav_back_color,
} from "../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import SaveMenuButton from "../../../components/SaveMenuButton";
import {
  getWePosMetaDataListAction,
  updateWePosMetaDataAction,
} from "../../../redux/WePos/Actions";
const MetaData = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { fof_meta_data_list } = useSelector((state) => state.WePosRedusers);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [metaKeyWords, setMetaKeyWords] = useState("");
  useEffect(() => {
    if (login_key && user_id) {
      dispatch(getWePosMetaDataListAction(login_key, user_id));
    }
  }, []);
  const handleSaveClicked = () => {
    if (metaTitle == "" || metaDesc == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateWePosMetaDataAction(
          login_key,
          user_id,
          metaTitle,
          metaDesc,
          metaKeyWords ? metaKeyWords : ""
        )
      );
    }
  };
  useEffect(() => {
    if (fof_meta_data_list) {
      if (fof_meta_data_list.length > 0) {
        if (fof_meta_data_list[0].MetaTitle) {
          setMetaTitle(fof_meta_data_list[0].MetaTitle);
        } else {
          setMetaTitle("");
        }
        if (fof_meta_data_list[0].MetaDescription) {
          setMetaDesc(fof_meta_data_list[0].MetaDescription);
        } else {
          setMetaDesc("");
        }
        if (fof_meta_data_list[0].MetaKey) {
          setMetaKeyWords(fof_meta_data_list[0].MetaKey);
        } else {
          setMetaKeyWords("");
        }
      } else {
        setMetaTitle("");
        setMetaKeyWords("");
        setMetaDesc("");
      }
    } else {
      setMetaTitle("");
      setMetaKeyWords("");
      setMetaDesc("");
    }
  }, [fof_meta_data_list]);

  return (
    <div
      className="countaining-div "
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "46%" }}>
              <h6 className="h6">* {lang.meta_title}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={metaTitle}
                onChange={(e) => setMetaTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text" style={{ width: "46%" }}>
              <h6 className="h6">* {lang.meta_desc}</h6>
              <textarea
                className="input-padding meta-textarea"
                value={metaDesc}
                onChange={(e) => setMetaDesc(e.target.value)}
                rows={6}
              />
            </div>
            <div className="ASOP-div1-input-text" style={{ width: "46%" }}>
              <h6 className="h6">
                {lang.meta_keywords}
                <span className="meta-keywords-example-text">
                  {" "}
                  (example : test1 , test2 , ...)
                </span>
              </h6>
              <textarea
                className="input-padding meta-textarea"
                value={metaKeyWords}
                onChange={(e) => setMetaKeyWords(e.target.value)}
                rows={6}
              />
            </div>
          </div>
        </div>
      </div>{" "}
      <SaveMenuButton clickButton={() => handleSaveClicked()} />
    </div>
  );
};

export default MetaData;
