import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";

import {
  getWorksStationsSuccessfullAction,
  createWorksStationsFailedAction,
  createWorksStationsSuccessfullAction,
  sendWorksStationsFailedAction,
  sendWorksStationsSuccessfullAction,
  deleteWorksStationsFailedAction,
  deleteWorksStationsSuccessfullAction,
  getWorksStationsAction,
  getWorksStationsFailedAction,
  getWorksStationStatusListFailedAction,
  getWorksStationStatusListSuccessfullAction,
  getWorksStationStatusListAction,
  updateWorksStationStatusFailedAction,
  updateWorksStationStatusSuccessfullAction,
  getWorkStationMainButtonsListAction,
  getWorkStationMainButtonsListFailedAction,
  getWorkStationMainButtonsListSuccessfullAction,
  editWorkStationMainButtonsDetailsSuccessfullAction,
  editWorkStationMainButtonsDetailsFailedAction,
} from "../WorksStations/Actions";
import {
  getWorksStationsServices,
  createWorksStationsServices,
  updateWorksStationsServices,
  deleteWorksStationsServices,
  getWorksStationStatusService,
  updateWorksStationStatusService,
  getWorkStationMainButtonsListService,
  editWorkStationMainButtonsDetailsService,
} from "../../services/WorksStationsServices";
import { getNumberOfPrintsListAction } from "../settings/Action";
function* getWoeksStations({ payload }) {
  try {
    const response = yield call(
      getWorksStationsServices,
      payload.login_key,
      payload.user_id,
      payload.shopId
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Workstationlist) {
          if (response.data.Workstationlist.length > 0) {
            let response_list = [];
            for (let i = 0; i < response.data.Workstationlist.length; i++) {
              if (!response.data.Workstationlist[i].IsDeleted) {
                response_list.push({
                  ...response.data.Workstationlist[i],
                  showDeleteBox: false,
                });
              }
            }
            if (response_list) {
              if (response_list.length > 0) {
                yield put(
                  getNumberOfPrintsListAction(
                    payload.login_key,
                    payload.user_id,
                    payload.shopId,
                    response_list[0].WorkStationId
                  )
                );
              }
            }

            yield put(getWorksStationsSuccessfullAction(response_list));
          } else {
            yield put(getWorksStationsFailedAction(""));
          }
        } else {
          yield put(getWorksStationsFailedAction(""));
        }
      } else {
        yield put(getWorksStationsFailedAction());
      }
    } else {
      yield put(getWorksStationsFailedAction());
    }
  } catch (error) {
    yield put(getWorksStationsFailedAction(error.message));
  }
}
function* createWorksStations({ payload }) {
  try {
    const response = yield call(
      createWorksStationsServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.work_station_name,
      payload.device_id,
      payload.device_type,
      payload.active,
      payload.licence_course,
      payload.is_expired,
      payload.active_secound_screen,
      payload.get_order_online,
      payload.shop_front_work_station,
      payload.is_kitchen,
      payload.password_protected_till_opening,
      payload.print_customer_ticket,
      payload.caller_id,
      payload.has_cash_drawer
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          getWorksStationsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
        yield put(
          createWorksStationsSuccessfullAction(response.data.ResultMessage)
        );
      } else {
        yield put(createWorksStationsFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(createWorksStationsFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(createWorksStationsFailedAction(error.message));
  }
}

function* updateWorksStations({ payload }) {
  try {
    const response = yield call(
      updateWorksStationsServices,
      payload.login_key,
      payload.user_id,
      payload.id,
      payload.shopId,
      payload.work_station_name,
      payload.device_id,
      payload.device_type,
      payload.active,
      // payload.licence_course,
      payload.is_expired,
      payload.active_secound_screen,
      payload.get_order_online,
      payload.shop_front_work_station,
      payload.is_kitchen,
      payload.password_protected_till_opening,
      payload.print_customer_ticket,
      payload.caller_id,
      payload.has_cash_drawer
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          sendWorksStationsSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getWorksStationsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(sendWorksStationsFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(sendWorksStationsFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(sendWorksStationsFailedAction(error.message));
  }
}

function* deleteWorksStations({ payload }) {
  try {
    const response = yield call(
      deleteWorksStationsServices,
      payload.login_key,
      payload.user_id,
      payload.shopId,
      payload.workStation_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          deleteWorksStationsSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getWorksStationsAction(
            payload.login_key,
            payload.user_id,
            payload.shopId
          )
        );
      } else {
        yield put(deleteWorksStationsFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteWorksStationsFailedAction("Server error , try again"));
    }
  } catch (error) {
    yield put(deleteWorksStationsFailedAction(error.message));
  }
}
function* getWorksStationsStatusList({ payload }) {
  try {
    const response = yield call(
      getWorksStationStatusService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.device_sync_status_list) {
          if (response.data.device_sync_status_list.length > 0) {
            yield put(
              getWorksStationStatusListSuccessfullAction(
                response.data.device_sync_status_list
              )
            );
          } else {
            yield put(getWorksStationStatusListFailedAction(""));
          }
        } else {
          yield put(getWorksStationStatusListFailedAction(""));
        }
      } else {
        yield put(
          getWorksStationStatusListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getWorksStationStatusListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getWorksStationStatusListFailedAction(error.message));
  }
}
function* updateWorksStationsStatus({ payload }) {
  try {
    const response = yield call(
      updateWorksStationStatusService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.workStation_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateWorksStationStatusSuccessfullAction(response.data.ResultMessage)
        );
        // yield put(
        //   getWorksStationStatusListAction(
        //     payload.login_key,
        //     payload.user_id,
        //     payload.shop_id
        //   )
        // );
      } else {
        yield put(
          updateWorksStationStatusFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        updateWorksStationStatusFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(updateWorksStationStatusFailedAction(error.message));
  }
}
function* getWorkStationMainButtonList({ payload }) {
  try {
    const response = yield call(
      getWorkStationMainButtonsListService,
      payload.shop_id,
      payload.login_key,
      payload.user_id,
      payload.work_station_id
    );
    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.Main_Page_Button_List) {
          if (response.data.Main_Page_Button_List.length > 0) {
            yield put(
              getWorkStationMainButtonsListSuccessfullAction(
                response.data.Main_Page_Button_List
              )
            );
          } else {
            yield put(getWorkStationMainButtonsListFailedAction(""));
          }
        } else {
          yield put(getWorkStationMainButtonsListFailedAction(""));
        }
      } else {
        yield put(
          getWorkStationMainButtonsListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getWorkStationMainButtonsListFailedAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getWorkStationMainButtonsListFailedAction(error.message));
  }
}
function* editWorkStationMainButtonDetails({ payload }) {
  try {
    const response = yield call(
      editWorkStationMainButtonsDetailsService,
      payload.formData,
      payload.shop_id,
      payload.login_key,
      payload.user_id,
      payload.work_station_id,
      payload.id,
      payload.button_code,
      payload.button_name,
      payload.visible,
      payload.enable,
      payload.button_image,
      payload.last_image_name
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          editWorkStationMainButtonsDetailsSuccessfullAction(
            response.data.ResultMessage
          )
        );
        yield put(
          getWorkStationMainButtonsListAction(
            payload.shop_id,
            payload.login_key,
            payload.user_id,
            payload.work_station_id
          )
        );
      } else {
        yield put(
          editWorkStationMainButtonsDetailsFailedAction(
            response.data.ResultMessage
          )
        );
      }
    } else {
      yield put(
        editWorkStationMainButtonsDetailsFailedAction(
          response.data.ResultMessage
        )
      );
    }
  } catch (error) {
    yield put(editWorkStationMainButtonsDetailsFailedAction(error.message));
  }
}

export function* watchEditWorkStationMainButtonDetails() {
  yield takeLatest(
    action_types.EDIT_WORK_STATION_MAIN_BUTTONS_DETAILS,
    editWorkStationMainButtonDetails
  );
}
export function* watchGetWorkStationMainButtonList() {
  yield takeLatest(
    action_types.GET_WORK_STATION_MAIN_BUTTONS_LIST,
    getWorkStationMainButtonList
  );
}
export function* watchUpdateWorksStationsStatus() {
  yield takeLatest(
    action_types.UPDATE_WORKSTATION_STATUS,
    updateWorksStationsStatus
  );
}

export function* watchGetWorksStationsStatusList() {
  yield takeLatest(
    action_types.GET_WORKSTATION_STATUS_LIST,
    getWorksStationsStatusList
  );
}

export function* watchDeleteWoeksStations() {
  yield takeLatest(action_types.DELETE_WORKS_STATION, deleteWorksStations);
}

export function* watchUpdateWoeksStations() {
  yield takeLatest(action_types.SEND_WORK_STATION, updateWorksStations);
}

export function* watchCreateWoeksStations() {
  yield takeLatest(action_types.CREATE_WORKS_STATION, createWorksStations);
}
export function* watchGetWoeksStations() {
  yield takeLatest(action_types.GET_WORKS_STATION_LIST, getWoeksStations);
}

export default function* rootWorksStations() {
  yield all([
    fork(watchGetWoeksStations),
    fork(watchCreateWoeksStations),
    fork(watchUpdateWoeksStations),
    fork(watchDeleteWoeksStations),
    fork(watchGetWorksStationsStatusList),
    fork(watchUpdateWorksStationsStatus),
    fork(watchEditWorkStationMainButtonDetails),
    fork(watchGetWorkStationMainButtonList),
  ]);
}
