import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { primaryColor } from "../../../../constants/Colors";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import {
  getAllAppSettingsAction,
  updateShopSettingAppPasswordProtectAction,
} from "../../../../redux/settings/Action";

const PasswordProtect = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();

  const { setting_app_passwordprotect } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);

  const [cancelOrder, setCancelOrder] = useState(false);
  const [dataExportToExcel, setDataExportToExcel] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [driverAnalisis, setDriverAnalisis] = useState(false);
  const [driverFloat, setDriverFloat] = useState(false);
  const [driverPayment, setDriverPayment] = useState(false);
  const [editOrder, setEditOrder] = useState(false);
  const [listAllOrder, setListAllOrder] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [orderLineAddChange, setOrderLineAddChange] = useState(false);
  const [systemSetup, setSystemSetup] = useState(false);
  const [takings, setTakings] = useState(false);
  const [timeSheet, setTimeSheet] = useState(false);

  const handleSaveClicked = () => {
    dispatch(
      updateShopSettingAppPasswordProtectAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        driverFloat ? 1 : 0,
        driverPayment ? 1 : 0,
        takings ? 1 : 0,
        editOrder ? 1 : 0,
        driverAnalisis ? 1 : 0,
        orderLineAddChange ? 1 : 0,
        dataExportToExcel ? 1 : 0,
        discount ? 1 : 0,
        timeSheet ? 1 : 0,
        cancelOrder ? 1 : 0,
        maintenance ? 1 : 0,
        listAllOrder ? 1 : 0,
        systemSetup ? 1 : 0
      )
    );
  };

  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleSaveClicked();
    },
  }));

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    driverFloat,
    driverPayment,
    takings,
    editOrder,
    driverAnalisis,
    orderLineAddChange,
    dataExportToExcel,
    discount,
    timeSheet,
    cancelOrder,
    maintenance,
    listAllOrder,
    systemSetup,
  ]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getAllAppSettingsAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (setting_app_passwordprotect.length > 0) {
      setCancelOrder(setting_app_passwordprotect[0].PPCancelOrder);
      setDataExportToExcel(setting_app_passwordprotect[0].PPDataExportToExcel);
      setDiscount(setting_app_passwordprotect[0].PPDiscount);
      setDriverAnalisis(setting_app_passwordprotect[0].PPDriverAnalysis);
      setDriverFloat(setting_app_passwordprotect[0].PPDriverFloat);
      setDriverPayment(setting_app_passwordprotect[0].PPDriverPayment);
      setEditOrder(setting_app_passwordprotect[0].PPEditOrder);
      setListAllOrder(setting_app_passwordprotect[0].PPListAllOrder);
      setMaintenance(setting_app_passwordprotect[0].PPMaintenance);
      setOrderLineAddChange(
        setting_app_passwordprotect[0].PPOrderLineAddChange
      );
      setSystemSetup(setting_app_passwordprotect[0].PPSystemSetup);
      setTakings(setting_app_passwordprotect[0].PPTakings);
      setTimeSheet(setting_app_passwordprotect[0].PPTimeSheet);
    }
  }, [setting_app_passwordprotect]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        // borderLeftStyle: "solid",
        height: "89.5%",
      }}
    >
      <div className=".menu_list_header_container_tabel_list">
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text div-chechbox-p">
              {/* <input
                id="check70"
                type="checkbox"
                checked={cancelOrder}
                onChange={() => setCancelOrder(!cancelOrder)}
              />
              <label for="check70" className="checkmark"></label>
              <h6 className=" h6">{lang.cancel_order}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cancelOrder}
                      onChange={() => setCancelOrder(!cancelOrder)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.cancel_order}
                />
              </FormGroup>
            </div>
            {/* <div className="ASOP-div1-input-text div-chechbox-p">
            
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dataExportToExcel}
                      onChange={() => setDataExportToExcel(!dataExportToExcel)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.data_export_to_excel}
                />
              </FormGroup>
            </div> */}
            <div className="ASOP-div1-input-text div-chechbox-p">
              {/* <input
                id="check72"
                type="checkbox"
                checked={orderLineAddChange}
                onChange={() => setOrderLineAddChange(!orderLineAddChange)}
              />
              <label for="check72" className="checkmark"></label>
              <h6 className=" h6">{lang.order_line_add_change}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderLineAddChange}
                      onChange={() =>
                        setOrderLineAddChange(!orderLineAddChange)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.edit_basket_line}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text div-chechbox-p">
              {/* <input
                id="check73"
                type="checkbox"
                checked={driverAnalisis}
                onChange={() => setDriverAnalisis(!driverAnalisis)}
              />
              <label for="check73" className="checkmark"></label>
              <h6 className=" h6">{lang.driver_analysis}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={driverAnalisis}
                      onChange={() => setDriverAnalisis(!driverAnalisis)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.driver_analysis}
                />
              </FormGroup>
            </div>
          </div>
          {/* <div className="ASOP-div1">
            <div className="ASOP-div1-input-text div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={driverFloat}
                      onChange={() => setDriverFloat(!driverFloat)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.driver_float}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={driverPayment}
                      onChange={() => setDriverPayment(!driverPayment)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.driver_payment}
                />
              </FormGroup>
            </div>
          </div> */}
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text div-chechbox-p">
              {/* <input
                id="check79"
                type="checkbox"
                checked={systemSetup}
                onChange={() => setSystemSetup(!systemSetup)}
              />
              <label for="check79" className="checkmark"></label>
              <h6 className="h6">{lang.system_setup}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={systemSetup}
                      onChange={() => setSystemSetup(!systemSetup)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.setting}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text div-chechbox-p">
              {/* <input
                id="check80"
                type="checkbox"
                checked={discount}
                onChange={() => setDiscount(!discount)}
              />
              <label for="check80" className="checkmark"></label>
              <h6 className="h6">{lang.discount}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={discount}
                      onChange={() => setDiscount(!discount)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.discount}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text div-chechbox-p">
              {/* <input
                id="check81"
                type="checkbox"
                checked={takings}
                onChange={() => setTakings(!takings)}
              />
              <label for="check81" className="checkmark"></label>
              <h6 className="h6">{lang.takings}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={takings}
                      onChange={() => setTakings(!takings)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.sales_report}
                />
              </FormGroup>
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text div-chechbox-p">
              {/* <input
                id="check76"
                type="checkbox"
                checked={editOrder}
                onChange={() => setEditOrder(!editOrder)}
              />
              <label for="check76" className="checkmark"></label>
              <h6 className=" h6">{lang.edit_order}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editOrder}
                      onChange={() => setEditOrder(!editOrder)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.edit_order}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text div-chechbox-p">
              {/* <input
                id="check77"
                type="checkbox"
                checked={listAllOrder}
                onChange={() => setListAllOrder(!listAllOrder)}
              />
              <label for="check77" className="checkmark"></label>
              <h6 className=" h6">{lang.list_all_order}</h6> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={listAllOrder}
                      onChange={() => setListAllOrder(!listAllOrder)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.list_all_order}
                />
              </FormGroup>
            </div>
            {/* <div className="ASOP-div1-input-text div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={maintenance}
                      onChange={() => setMaintenance(!maintenance)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.maintenance}
                />
              </FormGroup>
            </div> */}
            <div className="ASOP-div1-input-text div-chechbox-p"></div>
          </div>

          {/* <div className="ASOP-div1-input-text div-chechbox-p">
            
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={timeSheet}
                    onChange={() => setTimeSheet(!timeSheet)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.timeSheet}
              />
            </FormGroup>
          </div> */}
        </div>
      </div>
    </div>
  );
});

export default PasswordProtect;
