import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { orderList } from "../pages/orders/List.js";
import { objects } from "../pages/orders/List.js";
import { showOrderListPopupAction } from "../redux/Orders/Actions";
import { order_status } from "../constants/OrderStatus.js";
import delivry_icon from "../AssetsImages/deliveryIcon.png";
import { primaryColor } from "../constants/Colors.js";
import { usePopperTooltip } from "react-popper-tooltip";
const OrderListItem = ({
  order_id,
  Refound_Date,
  RefoundNote,
  status,
  channels,
  requested_for,
  store_name,
  invice_id,
  order_amount,
  payment_status,
  customer,
  number,
  on_click,
  on_click1,
  index1,
  sale_mathod,
  item,
  post_code,
  sale_mathod_number,
  status_name,
  shop_name,
  show_send_box_onclick,
  close_send_box_onclick,
  dashboard_refund,
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      closeOnOutsideClick: true,
    });
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { show_order_list_popup, selected_order_list } = useSelector(
    (state) => state.ordersReduser
  );

  return number == 2 ? (
    <div
      onClick={on_click}
      className="order-list-container"
      style={{
        backgroundColor: index1 % 2 == 0 ? "#f2f4f6" : "white",
      }}
    >
      <div className="order-list-container-div-1 ">
        <div
          className="order-list-item-container-icon-status"
          style={{ width: "16.6%" }}
        >
          <div style={{ width: "35%" }}>
            <div
              className={
                status == order_status.inComplete
                  ? "badge2"
                  : status == order_status.Pending
                  ? "badge3"
                  : "order-list-div-icon-1"
              }
              style={{
                backgroundColor:
                  status == order_status.Accepted
                    ? "rgb(106, 249, 225)"
                    : status == order_status.Rejected
                    ? "rgb(250, 134, 134)"
                    : status == order_status.Cancelld
                    ? "rgb(255, 90, 90)"
                    : status == order_status.Failed
                    ? "rgb(250, 196, 196)"
                    : status == order_status.Completed
                    ? "#f38321"
                    : status == order_status.Finish
                    ? "rgb(247, 58, 247)"
                    : status == order_status.Not_printed
                    ? "#009EFF"
                    : null,
              }}
            >
              {sale_mathod_number == 1000101 ||
              sale_mathod_number == 1000105 ? (
                <img style={{ width: "21px" }} src={delivry_icon} />
              ) : (
                <i className="fa fa-shopping-bag"></i>
              )}
            </div>
          </div>
          <div style={{ width: "65%" }}>
            <p className="bottom-list-p" style={{ margin: 0 }}>
              {order_id}/{invice_id}
            </p>
          </div>
        </div>

        <p className="bottom-list-p-1" style={{ textAlign: "center" }}>
          {item.ShopName}/{item.ShopId}
        </p>
        <p className="bottom-list-p-1">
          {moment(requested_for).format("YYYY/MM/DD-HH:mm")}
        </p>

        <p className="bottom-list-p-1">£{order_amount}</p>
        <p
          className="bottom-list-p-1"
          style={{
            color: payment_status == "Unpaid" ? "red" : null,
          }}
        >
          {payment_status}
        </p>
        <p className="bottom-list-p-1">{customer}</p>
        <p className="bottom-list-p-1">{post_code}</p>
      </div>
    </div>
  ) : number == 3 ? (
    <div onClick={on_click}>
      <div
        className=" order-list-container-div-1"
        style={{
          backgroundColor: index1 % 2 == 0 ? "#f2f4f6" : "white",
          height: "70px",
        }}
      >
        <div
          className="order-list-item-container-icon-status"
          style={{ flex: 1 }}
        >
          <div style={{ width: "45%" }}>
            <div
              className={
                status == order_status.inComplete
                  ? "badge2"
                  : status == order_status.Pending
                  ? "badge3"
                  : "order-list-div-icon-1"
              }
              style={{
                // backgroundColor:
                //   status == order_status.Accepted
                //     ? "rgb(106, 249, 225)"
                //     : status == order_status.Rejected
                //     ? "rgb(250, 134, 134)"
                //     : status == order_status.Cancelld
                //     ? "rgb(255, 90, 90)"
                //     : status == order_status.Failed
                //     ? "rgb(250, 196, 196)"
                //     : status == order_status.Completed
                //     ? "#f38321"
                //     : status == order_status.Finish
                //     ? "rgb(247, 58, 247)"
                //     : status == order_status.Not_printed
                //     ? "#009EFF"
                //     : null,
                backgroundColor: "rgb(250, 134, 134)",
              }}
            >
              {sale_mathod_number == 1000101 ||
              sale_mathod_number == 1000105 ? (
                <img style={{ width: "21px" }} src={delivry_icon} />
              ) : (
                <i className="fa fa-shopping-bag"></i>
              )}
            </div>
          </div>
          <div style={{ width: "78%" }}>
            {/* <p
              className="bottom-list-p"
              style={{
                margin: 0,
                width: "100%",
                textAlign: "left",
              }}
            >
              {status_name}
            </p> */}
            <p className="bottom-list-p" style={{ margin: 0 }}>
              {order_id}
            </p>
          </div>
        </div>
        <p className="bottom-list-p-1" style={{ flex: 1 }}>
          {shop_name}
        </p>{" "}
        <p className="bottom-list-p-1" style={{ flex: 1 }}>
          {moment(requested_for).format("MM/DD-HH:mm")}
        </p>
        <p className="bottom-list-p-1" style={{ flex: 1 }}>
          £{order_amount}
        </p>
        <p className="bottom-list-p-1" style={{ flex: 1 }}>
          {sale_mathod}
        </p>
        {/* <p className="bottom-list-p-1"></p> */}
        <div className="bottom-list-p-1 " style={{ flex: 1 }}>
          <button
            className="refund-button"
            onClick={on_click1}
            disabled={item.RefoundList}
            style={{
              backgroundColor: item.RefoundList ? "rgb(11, 163, 11)" : null,
            }}
          >
            {" "}
            {item.RefoundList ? lang.refunded : lang.refund}
          </button>
        </div>
      </div>
    </div>
  ) : number == 4 ? (
    <div onClick={on_click}>
      <div
        className=" order-list-container-div-1"
        style={{
          backgroundColor: index1 % 2 == 0 ? "#f2f4f6" : "white",
          height: "70px",
        }}
      >
        <p className="bottom-list-p-1" style={{ flex: 1 }}>
          {shop_name}
        </p>{" "}
        <p className="bottom-list-p-1" style={{ flex: 1 }}>
          {RefoundNote}
        </p>{" "}
        <p className="bottom-list-p-1" style={{ flex: 1 }}>
          {moment(requested_for).format("MM/DD-HH:mm")}
        </p>
        <p className="bottom-list-p-1" style={{ flex: 1 }}>
          £{order_amount}
        </p>
        {/* <p className="bottom-list-p-1"></p> */}
        <div className="bottom-list-p-1 " style={{ flex: 1 }}>
          <button
            className="refund-button"
            onClick={show_send_box_onclick}
            disabled={
              dashboard_refund ? true : item.StatusId == 3 ? true : false
            }
            style={{
              backgroundColor: item.StatusId == 3 ? "rgb(11, 163, 11)" : null,
            }}
            ref={setTriggerRef}
          >
            {" "}
            <span> {item.StatusId == 3 ? lang.done : lang.debt}</span>
          </button>
          <div>
            {item.show_send_box && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: "tooltip-container",
                  // interactive: true,
                })}
              >
                <div
                  {...getArrowProps({
                    className: "tooltip-arrow",
                  })}
                ></div>
                <div className="delete-popup ">
                  <h6 style={{ fontSize: "19px" }}>{`${
                    lang.debt_message
                  } £${item.RefoundAmount.toFixed(2)} ?`}</h6>
                  <div className="menu-list-container-cancel-and-ok-button">
                    <button onClick={close_send_box_onclick}>
                      {lang.cancel}
                    </button>
                    <button onClick={on_click1}>{lang.ok}</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div onClick={on_click} className="order-list-container">
      <div
        className=" order-list-container-div-1"
        style={{
          backgroundColor: index1 % 2 == 0 ? "#f2f4f6" : "white",
          border:
            selected_order_list && item
              ? selected_order_list.OrderId == item.OrderId
                ? `2px solid ${primaryColor}`
                : null
              : null,
        }}
      >
        <div className="order-list-item-container-icon-status">
          <div style={{ width: "38%" }}>
            <div
              className={
                status == order_status.inComplete
                  ? "badge2"
                  : status == order_status.Pending
                  ? "badge3"
                  : "order-list-div-icon-1"
              }
              style={{
                backgroundColor:
                  status == order_status.Accepted
                    ? "rgb(106, 249, 225)"
                    : status == order_status.Rejected
                    ? "rgb(250, 134, 134)"
                    : status == order_status.Cancelld
                    ? "rgb(255, 90, 90)"
                    : status == order_status.Failed
                    ? "rgb(250, 196, 196)"
                    : status == order_status.Completed
                    ? "#f38321"
                    : status == order_status.Finish
                    ? "rgb(247, 58, 247)"
                    : status == order_status.Not_printed
                    ? "#009EFF"
                    : null,
              }}
            >
              {sale_mathod_number == 1000101 ||
              sale_mathod_number == 1000105 ? (
                <img style={{ width: "21px" }} src={delivry_icon} />
              ) : (
                <i className="fa fa-shopping-bag"></i>
              )}
            </div>
          </div>
          <div style={{ width: "78%" }}>
            <p
              className="bottom-list-p"
              style={{
                margin: 0,
                width: "100%",
                textAlign: "left",
              }}
            >
              {status_name}
            </p>
            <p className="bottom-list-p" style={{ margin: 0 }}>
              {order_id}/{invice_id}
            </p>
          </div>
        </div>

        <p className="bottom-list-p-1" style={{ width: "14.5%" }}>
          {channels}
        </p>
        <p className="bottom-list-p-1">
          {moment(requested_for).format("MM/DD-HH:mm")}
        </p>

        <p className="bottom-list-p-1">£{order_amount}</p>
        <p
          className="bottom-list-p-1"
          style={{ color: payment_status == "Unpaid" ? "red" : null }}
        >
          {payment_status}
        </p>
        <p className="bottom-list-p-1">{sale_mathod}</p>

        <p className="bottom-list-p-1" style={{ textAlign: "center" }}>
          {customer}
        </p>
        <p className="bottom-list-p-1">{post_code}</p>
      </div>
    </div>
  );
};

export default OrderListItem;
