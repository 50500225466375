import { NoEncryption } from "@material-ui/icons";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { main_nav_button_color } from "../constants/Colors";
import { primaryColor, main_nav_back_color } from "../constants/Colors";
import { Status } from "../pages/orders/List";
import { status } from "../pages/orders/List";
import { Channels } from "../pages/orders/List";
const HeaderList = ({ number }) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      with: 50,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused ? `1px solid ${primaryColor}` : `none`,
      // border:"none",
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
      "&:focus": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const { lang } = useSelector((state) => state.selectedLanguage);
  const [selectedStatus, setSelectedStatus] = useState({
    value: "5",
    label: "All",
  });
  const [selectedChannel, setSelectedChannel] = useState({
    value: 14,
    label: "All ",
  });
  return number == 2 ? (
    <div>
      <div className="header-list-container-div-1">
        <p className="header-list-p-1">{lang.orderId}</p>
        <p className="header-list-p-1">{lang.shop_id_shop_name}</p>

        <p className="header-list-p-1 ">{lang.order_date}</p>
        <p className="header-list-p-1">{lang.total}</p>
        <p className="header-list-p-1">{lang.payment_method}</p>
        <p className="header-list-p-1">{lang.customer}</p>
        <p className="header-list-p-1">{lang.post_code}</p>
      </div>
    </div>
  ) : number == 3 ? (
    <div>
      <div className="header-list-container-div-1">
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.orderId}
        </p>
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.shop_name}
        </p>
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.order_date}
        </p>
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.total}
        </p>
        {/* <p className="header-list-p-1">{lang.payment_method}</p> */}
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.sale_method}
        </p>

        {/* <p className="header-list-p-1"></p> */}
        <p className="header-list-p-1" style={{ flex: 1 }}></p>
      </div>
    </div>
  ) : number == 4 ? (
    <div>
      <div className="header-list-container-div-1">
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.shop_name}
        </p>
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.debt_reasone}
        </p>
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.create_date}
        </p>
        <p className="header-list-p-1" style={{ flex: 1 }}>
          {lang.total_debt}
        </p>
        {/* <p className="header-list-p-1">{lang.payment_method}</p> */}

        {/* <p className="header-list-p-1"></p> */}
        <p className="header-list-p-1" style={{ flex: 1 }}></p>
      </div>
    </div>
  ) : (
    <div>
      <div className="header-list-container-div-1">
        <p className="header-list-p-1">{lang.orderId_status}</p>

        <p className="header-list-p-1">{lang.channels}</p>
        <p className="header-list-p-1">{lang.order_date}</p>

        <p className="header-list-p-1">{lang.total}</p>
        <p className="header-list-p-1">{lang.payment_method}</p>
        <p className="header-list-p-1">{lang.sale_method}</p>
        <p className="header-list-p-1">{lang.customer}</p>
        <p className="header-list-p-1">{lang.post_code}</p>
      </div>
    </div>
  );
};

export default HeaderList;
