import React, { useState, useRef, useEffect, DragEvent } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import useScrollbarSize from "react-scrollbar-size";
import { useDispatch, useSelector } from "react-redux";
import { LanguageSharp } from "@material-ui/icons";
import { Navigate, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import store from "store2";

import CategoryEdit from "../../components/CategoryEdit";
import ProductsList from "../../components/ProductsList";
import CategoryOptions from "../../components/CategoryOptions";
import ProductsOptions from "../../components/ProductsOptions";
import ProductOptionEdit from "../../components/ProductOptionEdit";
import ProductsEdit from "../../components/ProductsEdit";
import ProductSubmenu from "../../components/ProductSubmenu";
import { padding_menu_list_outer } from "../../constants/Sizes";
import OptionsList from "../../components/OptionsList";
import ProductSubmenuEdit from "../../components/ProductSubmenuEdit";
import CategoryOptionCrustList from "../../components/CategoryOptionCrustList";
import CategoryOptionCrustEdit from "../../components/CategoryOptionCrustEdit";
import { receiveLoginResponse, loginAction } from "../../redux/login/Actions";
import {
  primaryColor,
  main_nav_button_color,
  white,
  top_nav_button_color,
  main_nav_back_color,
} from "../../constants/Colors";
import { items } from "./itemDropdown";
import Loader from "../../components/Loader";

import {
  createCategoryAction,
  selectedCategoryAction,
  getCategoriesListAction,
  getCategoriesSuccessfulAction,
  getDropDownAction,
  deleteCategoryAction,
  setDefaultAction,
  handleEmterKeyAction,
  setSelectedCategoryOptionAction,
  updateCategorySortAction,
  updateCategoryAppSortAction,
  changeSortLableAction,
} from "../../redux/menu/Actions";
import { getShopsListAction } from "../../redux/shop/Actions";
import { selectedProductAction } from "../../redux/product/Action";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BsAndroid2 } from "react-icons/bs";
import { FaInternetExplorer } from "react-icons/fa";
import { getSubmenuAction } from "../../redux/submenu/Action";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const usefullScreenPadding = (window.innerWidth * 2) / 100;

const Menu = () => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      closeOnOutsideClick: true,
    });
  const catRef = useRef();
  const inputRef = useRef();
  const dragitemRef = useRef(null);
  const dragOverItemRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useScrollbarSize();
  const myRef = useRef(null);
  const { result_login_successfull, refresh_login_loader } = useSelector(
    (state) => state.loginReducer
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    category_list,
    selected_category,
    show_hide,
    printer_list,
    report_section,
    product_group,
    vat,
    loading_category_settings,
    product_open_close_result_successfull,
    get_category_list_loading,
    result_successfull,
    result_failed,
    result_message,
    loading_category,
    handle_enter_key,
    result_create_successfull,
    new_category_id,
    change_sort_label,
  } = useSelector((state) => state.categoryList);
  const { profile_list } = useSelector((state) => state.loginReducer);
  const user_access = store.get("userAccess");
  const [categoryName, setCategoryName] = useState("");
  const [id, setId] = useState(0);
  const [item, setItem] = useState("");
  const [category, setCategory] = useState([]);
  const [category1, setCategory1] = useState([]);
  const [clickedItem, setClickedItem] = useState(2);
  const [controlInputFocuse, setControlInputFocuse] = useState(false);
  let [color, setColor] = useState("red");
  const [userAccesses, setUserAccesses] = useState([]);
  const [userType, setUserType] = useState(0);
  const [rolesId, setRolesId] = useState([]);
  const [sortOnWebOrApp, setSortOnWebOrApp] = useState(1);

  function toTitleCase(categoryName) {
    const titleCase = categoryName
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return titleCase;
  }
  const handleAddItems = () => {
    setCategory1(category_list);
    dispatch(
      createCategoryAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        toTitleCase(categoryName.replaceAll(`"`, `\"\"`))
      )
    );

    inputRef.current.focus();
    // document.getElementById("create_category_input").autofocus == true;
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        if (controlInputFocuse == true && categoryName != "") {
          dispatch(
            createCategoryAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              categoryName.replaceAll(`"`, `\"\"`)
            )
          );
        }
      }
      // setControlInputFocuse(handle_enter_key)
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [categoryName]);

  const handleChangeInput = (event) => {
    setCategoryName(event.target.value);
  };

  const handleCollapseItem = (item, index) => {
    dispatch(selectedCategoryAction(item));

    setClickedItem(2);
    const copy_category = [...category];
    for (let i = 0; i < copy_category.length; i++) {
      if (index == i) {
        copy_category[i].collapse = !item.collapse;
      } else {
        copy_category[i].collapse = false;
      }

      dispatch(getCategoriesSuccessfulAction(copy_category));
    }
  };

  const handleCollapseItemClicked = (item, key) => {
    dispatch(selectedProductAction(null));
    setClickedItem(key);
  };

  const handleDeletCategory = () => {
    setCategory1(category);
    dispatch(
      deleteCategoryAction(
        login_key,
        user_id,
        selected_category.ShopId,
        selected_category.Id
      )
    );
  };

  const handleDeleteBox = () => {
    const copy_category = [...category];
    if (copy_category) {
      if (copy_category.length > 0) {
        for (let i = 0; i < copy_category.length; i++) {
          if (selected_category.Id == copy_category[i].Id) {
            copy_category[i].showDeleteBox = true;
            selected_category.showDeleteBox = true;
          } else {
            copy_category[i].showDeleteBox = false;
          }
        }
      }
    }
    setCategory(copy_category);
  };
  const handleCloseDeleteBox = () => {
    if (selected_category) {
      dispatch(
        selectedCategoryAction({
          ...selected_category,
          showDeleteBox: false,
        })
      );
    }
  };
  const handleDragEnd = (result) => {
    const copy_list = [...category];
    // dispatch(changeSortLableAction(true));
    if (sortOnWebOrApp == 1) {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setCategory(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];
      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateCategorySortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.WebSortId,
            new_item.WebSortId
          )
        );
      }
    } else {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setCategory(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];
      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateCategoryAppSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.AppSortId,
            new_item.AppSortId
          )
        );
      }
    }
  };
  const handleSortButtons = (key) => {
    dispatch(changeSortLableAction(true));
    if (key == 1) {
      setSortOnWebOrApp(1);
    } else {
      setSortOnWebOrApp(2);
    }
  };
  // useEffect(() => {
  //   const password = store.get("password");
  //   const user_name = store.get("user_name");
  //   if (!result_login_successfull) {
  //     if (loging_response) {
  //       if (loging_response.UserAccesses) {
  //         if (loging_response.UserAccesses.length > 0) {
  //           if (loging_response.UserAccesses[0].UserType != 10001201) {
  //             navigate("/");
  //           } else {
  //             dispatch(
  //               loginAction(
  //                 "",
  //                 "",
  //                 user_name.replaceAll(`"`, `\"\"`),
  //                 password.replaceAll(`"`, `\"\"`)
  //               )
  //             );
  //           }
  //         }
  //       } else {
  //         navigate("/");
  //       }
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, []);
  // useEffect(() => {
  //   if (loging_response) {
  //     if (loging_response.ResultCode != 1) {
  //       navigate("/");
  //     } else {
  //       if (result_login_successfull) {
  //         dispatch(
  //           getShopsListAction(
  //             loging_response.LoginKey,
  //             loging_response.UserAccesses[0].UserId,
  //             0
  //           )
  //         );
  //       }
  //     }
  //   } else {
  //     navigate("/");
  //   }
  // }, [refresh_login_loader]);

  useEffect(() => {
    if (category_list.length > 0) {
      setCategory(category_list);
    } else {
      setCategory([]);

      dispatch(selectedCategoryAction(null));
    }
  }, [category_list]);

  useEffect(() => {
    const copy_category_list = [...category_list];
    if (sortOnWebOrApp == 1) {
      const sortArrey = copy_category_list.sort(function (a, b) {
        return a.WebSortId - b.WebSortId;
      });

      if (change_sort_label) {
        dispatch(changeSortLableAction(false));
        dispatch(selectedCategoryAction(copy_category_list[0]));
        for (let i = 0; i < copy_category_list.length; i++) {
          copy_category_list[i].collapse = i == 0 ? true : false;
        }
      }
      setCategory(sortArrey);
    } else {
      const sortArrey = copy_category_list.sort(function (a, b) {
        return a.AppSortId - b.AppSortId;
      });

      if (change_sort_label) {
        dispatch(changeSortLableAction(false));
        dispatch(selectedCategoryAction(sortArrey[0]));
        for (let i = 0; i < sortArrey.length; i++) {
          sortArrey[i].collapse = i == 0 ? true : false;
        }
      }

      setCategory(sortArrey);
    }
  }, [sortOnWebOrApp, category_list, change_sort_label]);

  useEffect(() => {
    if (selected_shop) {
      if (selected_shop != "S") {
        if (selected_shop.length > 0) {
          if (userType != 10001202) {
            inputRef.current.focus();
          }
          dispatch(
            getCategoriesListAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              0
            )
          );
          dispatch(
            getSubmenuAction(login_key, user_id, selected_shop[0].ShopId, 0)
          );
        }
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (result_successfull || product_open_close_result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      setCategoryName("");
      document.getElementById("");
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [
    result_successfull,
    result_failed,
    product_open_close_result_successfull,
  ]);

  const loging_response = store.get("login_response");

  useEffect(() => {
    if (category) {
      if (category1) {
        if (category1.length > 0) {
          if (category.length > 0) {
            if (category.length > category1.length) {
              document.getElementById("idd").scroll({
                top: document.querySelector("#idd").scrollHeight,
                behavior: "smooth",
              });
              setCategory1([]);
            } else if (category.length < category1.length) {
              document.getElementById("idd").scroll({
                top: 0,
                behavior: "smooth",
              });
              setCategory1([]);
            }
          }
        }
      }
    }
  }, [category]);

  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);

  useEffect(() => {
    let roles_id = [];
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          setUserType(userAccesses[0].UserType);
          roles_id.push(userAccesses[i].RoleId);
        }
      }
    }
    setRolesId(roles_id);
  }, [userAccesses]);

  return (
    <div
      className="c-div container-main-manu"
      style={{
        height: window.innerHeight - 160,
      }}
    >
      {userType == 10001202 && rolesId.includes(17) && !rolesId.includes(1) ? (
        <div className="s-div">
          <div
            ref={myRef}
            id="idd"
            className="menu_list_header_container"
            // style={{ paddingBottom: "20px" }}
          >
            <div className="categort_header_container">
              <div>
                <h6>{lang.categories}</h6>
              </div>
              <div className="category-list-header-cotainer-switch-buttons">
                <button
                  onClick={() => handleSortButtons(1)}
                  style={{
                    backgroundColor: sortOnWebOrApp == 1 ? primaryColor : null,
                    color: sortOnWebOrApp == 1 ? white : null,
                  }}
                >
                  <i class="fa fa-internet-explorer" aria-hidden="true"></i>{" "}
                  {lang.web}
                </button>
                <button
                  onClick={() => handleSortButtons(2)}
                  style={{
                    backgroundColor: sortOnWebOrApp == 2 ? primaryColor : null,
                    color: sortOnWebOrApp == 2 ? white : null,
                  }}
                >
                  <BsAndroid2 style={{ margin: "0px 2px 4px 0px" }} />{" "}
                  {lang.app}
                </button>
              </div>
            </div>
            {!get_category_list_loading ? (
              <div>
                {category.length > 0 ? (
                  <div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="list">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {category.map((item, index) => (
                              <Draggable
                                key={item.Id}
                                draggableId={item.Id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {/* {item.IsDeleted ? null : (
                                      <div>
                                        <div className="category_list_container">
                                          <div
                                            onClick={() =>
                                              handleCollapseItem(item, index)
                                            }
                                            className="menu-div"
                                            style={{
                                              height: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? 50
                                                  : 40
                                                : null,
                                              backgroundColor: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? main_nav_back_color
                                                  : white
                                                : null,
                                              borderWidth: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? 0
                                                  : 1
                                                : null,
                                            }}
                                          >
                                            <p className="category_title">
                                              {item.AppTitle}
                                            </p>
                                         
                                          </div>
                                        </div>
                                      </div>
                                    )} */}
                                    {item.IsDeleted ? null : (
                                      <div>
                                        <div className="category_list_container">
                                          <div
                                            onClick={() =>
                                              handleCollapseItem(item, index)
                                            }
                                            className="menu-div"
                                            style={{
                                              height: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? 50
                                                  : 40
                                                : null,
                                              backgroundColor: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? main_nav_back_color
                                                  : white
                                                : null,
                                              borderWidth: selected_category
                                                ? selected_category.Id ==
                                                  item.Id
                                                  ? 0
                                                  : 1
                                                : null,
                                              paddingLeft: 0,
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                height: "100%",
                                                alignItems: "center",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  width: "5px",
                                                  height: "100%",

                                                  backgroundColor:
                                                    sortOnWebOrApp == 1
                                                      ? item.WebVisible.includes(
                                                          "0"
                                                        )
                                                        ? "red"
                                                        : "transparent"
                                                      : item.AppVisible.includes(
                                                          "0"
                                                        )
                                                      ? "red"
                                                      : "transparent",
                                                  marginRight: "2px",
                                                }}
                                              ></span>
                                              <p className="category_title">
                                                {" "}
                                                {item.AppTitle}
                                              </p>
                                            </div>
                                            <i className="fa fa-caret-down caret-down-icon"></i>
                                          </div>
                                        </div>

                                        {item.collapse ? (
                                          <div>
                                            <div className="dropdown-div-ownermenu">
                                              <div
                                                onClick={() =>
                                                  handleCollapseItemClicked(
                                                    item,
                                                    2
                                                  )
                                                }
                                                className="menu_collapse_item"
                                                key={2}
                                                style={{
                                                  backgroundColor:
                                                    clickedItem == 2 ||
                                                    clickedItem == 4 ||
                                                    clickedItem == 5 ||
                                                    clickedItem == 6 ||
                                                    clickedItem == 8 ||
                                                    clickedItem == 9
                                                      ? primaryColor
                                                      : null,
                                                  color:
                                                    clickedItem == 2 ||
                                                    clickedItem == 4 ||
                                                    clickedItem == 5 ||
                                                    clickedItem == 6 ||
                                                    clickedItem == 8 ||
                                                    clickedItem == 9
                                                      ? white
                                                      : null,
                                                }}
                                              >
                                                {lang.products}
                                              </div>
                                              <div
                                                onClick={() => {
                                                  handleCollapseItemClicked(
                                                    item,
                                                    3
                                                  );
                                                  dispatch(
                                                    setSelectedCategoryOptionAction(
                                                      null
                                                    )
                                                  );
                                                }}
                                                className="menu_collapse_item"
                                                key={3}
                                                style={{
                                                  backgroundColor:
                                                    clickedItem == 3 ||
                                                    clickedItem == 7 ||
                                                    clickedItem == 10 ||
                                                    clickedItem == 11
                                                      ? primaryColor
                                                      : null,
                                                  color:
                                                    clickedItem == 3 ||
                                                    clickedItem == 7 ||
                                                    clickedItem == 10 ||
                                                    clickedItem == 11
                                                      ? white
                                                      : null,
                                                }}
                                              >
                                                {lang.options}
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {/* {category.map((item, index) => (
                      <div key={index}>
                        {item.IsDeleted ? null : (
                          <div>
                            <div className="category_list_container">
                              <div
                                onClick={() => handleCollapseItem(item, index)}
                                className="menu-div"
                                style={{
                                  height: selected_category
                                    ? selected_category.Id == item.Id
                                      ? 50
                                      : 40
                                    : null,
                                  backgroundColor: selected_category
                                    ? selected_category.Id == item.Id
                                      ? main_nav_back_color
                                      : white
                                    : null,
                                  borderWidth: selected_category
                                    ? selected_category.Id == item.Id
                                      ? 0
                                      : 1
                                    : null,
                                }}
                              >
                                <p className="category_title">
                                  {item.AppTitle}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))} */}
                  </div>
                ) : (
                  <p className="add-p">{lang.NoOptions_title}</p>
                )}
              </div>
            ) : null}
            {/* ADD BUTTON */}
          </div>
          <div
            className="add_category_main_container"
            style={{
              paddingRight: width + padding_menu_list_outer,
              paddingLeft: padding_menu_list_outer,
            }}
          >
            <div id="add_category_button_input_container">
              <input
                id="create_category_input"
                type="text"
                placeholder={lang.placeholder_categoeyName}
                className="addcategory-input"
                onChange={(e) => handleChangeInput(e)}
                value={toTitleCase(categoryName)}
                autoFocus
                ref={inputRef}
                onFocus={() => setControlInputFocuse(true)}
                onBlur={() => setControlInputFocuse(false)}
              />
              <button
                className="add-button"
                disabled={categoryName ? false : true}
                onClick={handleAddItems}
                value={lang.add_button_title}
              >
                {lang.add_button_title}
              </button>

              <button
                className="add-button"
                onClick={handleDeleteBox}
                value={lang.add_button_title}
                ref={setTriggerRef}
              >
                <i
                  style={{ fontSize: 20 }}
                  class="fa fa-trash-o"
                  aria-hidden="true"
                ></i>
                {selected_category ? (
                  selected_category.showDeleteBox ? (
                    <div
                      style={{
                        border: "1px solid red",
                        backgroundColor: "red",
                      }}
                      ref={setTooltipRef}
                      {...getTooltipProps({
                        className: "tooltip-container",
                      })}
                    >
                      <div
                        {...getArrowProps({
                          className: "tooltip-arrow",
                        })}
                      ></div>
                      <div className="delete-popup">
                        <h6 style={{ fontSize: "19px" }}>
                          {lang.delete_message}
                        </h6>
                        <div className="menu-list-container-cancel-and-ok-button">
                          <button onClick={() => handleCloseDeleteBox(item)}>
                            {lang.cancel}
                          </button>
                          <button onClick={handleDeletCategory}>
                            {lang.ok}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : null}
              </button>
            </div>
          </div>
          {get_category_list_loading ? <Loader /> : null}
        </div>
      ) : (
        <>
          <div className="s-div">
            <div ref={myRef} id="idd" className="menu_list_header_container">
              <div className="categort_header_container">
                <div>
                  <h6>{lang.categories}</h6>
                </div>
                <div className="category-list-header-cotainer-switch-buttons">
                  <button
                    onClick={() => handleSortButtons(1)}
                    style={{
                      backgroundColor:
                        sortOnWebOrApp == 1 ? primaryColor : null,
                      color: sortOnWebOrApp == 1 ? white : null,
                    }}
                  >
                    {/* <i class="fa fa-internet-explorer" aria-hidden="true"></i>{" "} */}
                    <FaInternetExplorer style={{ margin: "0px 2px 4px 0px" }} />
                    {lang.web}
                  </button>
                  <button
                    onClick={() => handleSortButtons(2)}
                    style={{
                      backgroundColor:
                        sortOnWebOrApp == 2 ? primaryColor : null,
                      color: sortOnWebOrApp == 2 ? white : null,
                    }}
                  >
                    {/* <i class="fa fa-android" aria-hidden="true"></i>{" "} */}

                    <BsAndroid2 style={{ margin: "0px 2px 4px 0px" }} />
                    {lang.app}
                  </button>
                </div>
              </div>
              {!get_category_list_loading ? (
                <div>
                  {category.length > 0 ? (
                    <div>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="list">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {category.map((item, index) => (
                                <Draggable
                                  key={item.Id}
                                  draggableId={item.Id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {item.IsDeleted ? null : (
                                        <div>
                                          <div className="category_list_container">
                                            <div
                                              onClick={() =>
                                                handleCollapseItem(item, index)
                                              }
                                              className="menu-div"
                                              style={{
                                                height: selected_category
                                                  ? selected_category.Id ==
                                                    item.Id
                                                    ? 50
                                                    : 40
                                                  : null,
                                                backgroundColor:
                                                  selected_category
                                                    ? selected_category.Id ==
                                                      item.Id
                                                      ? main_nav_back_color
                                                      : white
                                                    : null,
                                                borderWidth: selected_category
                                                  ? selected_category.Id ==
                                                    item.Id
                                                    ? 0
                                                    : 1
                                                  : null,
                                                paddingLeft: 0,
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  height: "100%",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <span
                                                  class={
                                                    sortOnWebOrApp == 1
                                                      ? item.WebVisible.includes(
                                                          "0"
                                                        )
                                                        ? "color-box"
                                                        : ""
                                                      : item.AppVisible.includes(
                                                          "0"
                                                        )
                                                      ? "color-box"
                                                      : ""
                                                  }
                                                  data-title={
                                                    sortOnWebOrApp == 1
                                                      ? item.WebVisible.includes(
                                                          "0"
                                                        )
                                                        ? "Hidden in Web list"
                                                        : ""
                                                      : item.AppVisible.includes(
                                                          "0"
                                                        )
                                                      ? "Hidden in App list"
                                                      : ""
                                                  }
                                                  style={{
                                                    width: "6px",
                                                    height: "100%",

                                                    backgroundColor:
                                                      sortOnWebOrApp == 1
                                                        ? item.WebVisible.includes(
                                                            "0"
                                                          )
                                                          ? "red"
                                                          : "transparent"
                                                        : item.AppVisible.includes(
                                                            "0"
                                                          )
                                                        ? "red"
                                                        : "transparent",
                                                    marginRight: "2px",
                                                  }}
                                                ></span>
                                                <p className="category_title">
                                                  {" "}
                                                  {item.AppTitle}
                                                </p>
                                              </div>
                                              <i className="fa fa-caret-down caret-down-icon"></i>{" "}
                                            </div>
                                          </div>

                                          {item.collapse ? (
                                            <div>
                                              <div className="dropdown-div">
                                                <div
                                                  onClick={() =>
                                                    handleCollapseItemClicked(
                                                      item,
                                                      1
                                                    )
                                                  }
                                                  className="menu_collapse_item"
                                                  key={1}
                                                  style={{
                                                    backgroundColor:
                                                      clickedItem == 1
                                                        ? primaryColor
                                                        : null,
                                                    color:
                                                      clickedItem == 1
                                                        ? white
                                                        : null,
                                                  }}
                                                >
                                                  {lang.settings}
                                                </div>
                                                <div
                                                  onClick={() =>
                                                    handleCollapseItemClicked(
                                                      item,
                                                      2
                                                    )
                                                  }
                                                  className="menu_collapse_item"
                                                  key={2}
                                                  style={{
                                                    backgroundColor:
                                                      clickedItem == 2 ||
                                                      clickedItem == 4 ||
                                                      clickedItem == 5 ||
                                                      clickedItem == 6 ||
                                                      clickedItem == 8 ||
                                                      clickedItem == 9
                                                        ? primaryColor
                                                        : null,
                                                    color:
                                                      clickedItem == 2 ||
                                                      clickedItem == 4 ||
                                                      clickedItem == 5 ||
                                                      clickedItem == 6 ||
                                                      clickedItem == 8 ||
                                                      clickedItem == 9
                                                        ? white
                                                        : null,
                                                  }}
                                                >
                                                  {lang.products}
                                                </div>
                                                <div
                                                  onClick={() => {
                                                    handleCollapseItemClicked(
                                                      item,
                                                      3
                                                    );
                                                    dispatch(
                                                      setSelectedCategoryOptionAction(
                                                        null
                                                      )
                                                    );
                                                  }}
                                                  className="menu_collapse_item"
                                                  key={3}
                                                  style={{
                                                    backgroundColor:
                                                      clickedItem == 3 ||
                                                      clickedItem == 7 ||
                                                      clickedItem == 10 ||
                                                      clickedItem == 11
                                                        ? primaryColor
                                                        : null,
                                                    color:
                                                      clickedItem == 3 ||
                                                      clickedItem == 7 ||
                                                      clickedItem == 10 ||
                                                      clickedItem == 11
                                                        ? white
                                                        : null,
                                                  }}
                                                >
                                                  {lang.options}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      {/* {list.map((item, index) => (
                        <Draggable draggableId={item} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {item}
                            </div>
                          )}
                        </Draggable>
                      ))} */}
                    </div>
                  ) : (
                    <p className="add-p">{lang.NoOptions_title}</p>
                  )}
                </div>
              ) : null}
              {/* ADD BUTTON */}
            </div>

            <div
              className="add_category_main_container"
              style={{
                paddingRight: width + padding_menu_list_outer,
                paddingLeft: padding_menu_list_outer,
              }}
            >
              <div id="add_category_button_input_container">
                <input
                  id="create_category_input"
                  type="text"
                  placeholder={lang.placeholder_categoeyName}
                  className="addcategory-input"
                  onChange={(e) => handleChangeInput(e)}
                  value={toTitleCase(categoryName)}
                  autoFocus
                  ref={inputRef}
                  onFocus={() => setControlInputFocuse(true)}
                  onBlur={() => setControlInputFocuse(false)}
                />
                <button
                  className="add-button"
                  disabled={categoryName ? false : true}
                  onClick={handleAddItems}
                  value={lang.add_button_title}
                >
                  {lang.add_button_title}
                </button>

                <button
                  className="add-button"
                  onClick={handleDeleteBox}
                  value={lang.add_button_title}
                  ref={setTriggerRef}
                >
                  <i
                    style={{ fontSize: 20 }}
                    class="fa fa-trash-o"
                    aria-hidden="true"
                  ></i>
                  {selected_category ? (
                    selected_category.showDeleteBox ? (
                      <div
                        style={{
                          border: "1px solid red",
                          backgroundColor: "red",
                        }}
                        ref={setTooltipRef}
                        {...getTooltipProps({
                          className: "tooltip-container",
                        })}
                      >
                        <div
                          {...getArrowProps({
                            className: "tooltip-arrow",
                          })}
                        ></div>
                        <div className="delete-popup">
                          <h6 style={{ fontSize: "19px" }}>
                            {lang.delete_message}
                          </h6>
                          <div className="menu-list-container-cancel-and-ok-button">
                            <button onClick={() => handleCloseDeleteBox(item)}>
                              {lang.cancel}
                            </button>
                            <button onClick={handleDeletCategory}>
                              {lang.ok}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </button>
              </div>
            </div>
            {get_category_list_loading ? <Loader /> : null}
          </div>
        </>
      )}

      <div className="Menu-div_countainer-component">
        {clickedItem == 1 ? (
          <div className=" Menu-div-countainer-CategoryEdit_component">
            {!get_category_list_loading ? (
              <CategoryEdit
                title={categoryName}
                // ref={catRef}
                ref={inputRef}
                controlInputFocuse={controlInputFocuse}
              />
            ) : null}
          </div>
        ) : clickedItem == 2 ? (
          <ProductsList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 3 ? (
          <OptionsList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 4 ? (
          <ProductsEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 5 ? (
          <ProductsOptions setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 7 ? (
          <CategoryOptions setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 8 ? (
          <ProductOptionEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 6 ? (
          <ProductSubmenu setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 9 ? (
          <ProductSubmenuEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 10 ? (
          <CategoryOptionCrustList setClickedItem={(i) => setClickedItem(i)} />
        ) : clickedItem == 11 ? (
          <CategoryOptionCrustEdit setClickedItem={(i) => setClickedItem(i)} />
        ) : null}
        {loading_category ? <Loader /> : null}
      </div>

      <Toaster />
    </div>
  );
};

export default Menu;
