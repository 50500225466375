import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ServiceCharges = () => {

  const navigate = useNavigate();
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);
  
  return (
    <div>
      <p>Service Charges</p>
    </div>
  );
};

export default ServiceCharges;
