import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Select from "react-select";
import moment from "moment";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";

import {
  getAllAppSettingsAction,
  updateShopSettingAppDisplayAction,
} from "../../../../redux/settings/Action";

const Display = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { setting_app_display } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { second_language, second_language_id } = useSelector(
    (state) => state.categoryList
  );
  const [deliveryDescription, setDeliveryDescription] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [takeawayDescription, setTakeawayDescription] = useState("");
  const [eatInDescription, setEatInDescription] = useState("");
  const [webDelDescription, setWebDelDescription] = useState("");
  const [webCollDescription, setWebCollDescription] = useState("");
  const [webEatInDescription, setWebEatInDescription] = useState("");
  const [kioskTakeawayDescription, setKioskTakeawayDescription] = useState("");
  const [kioskEatInDescription, setKioskEatInDescription] = useState("");
  const [shopOpeningTime, setShopOpeningTime] = useState("");
  const [shopClosingTime, setShopClosingTime] = useState("");
  const [displayPreviousOrder, setDisplayPreviousOrder] = useState();
  const [showBasketInSecondScreen, setShowBasketInSecondScreen] = useState();
  const [showBarcode, setShowBarcode] = useState();
  const [secondLanguage, setSecondLanguage] = useState({
    label: "Main Language",
    value: 1,
  });
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const [
    showNotPaidFinishingOrderInCollection,
    setShowNotPaidFinishingOrderInCollection,
  ] = useState();
  const [
    showNotPaidFinishingOrderInEatIn,
    setShowNotPaidFinishingOrderInEatIn,
  ] = useState();

  const handleSaveClicked = () => {
    dispatch(
      updateShopSettingAppDisplayAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        displayPreviousOrder ? 1 : 0,
        showBasketInSecondScreen ? 1 : 0,
        deliveryDescription.replaceAll(`"`, `\"\"`),
        collectionDescription.replaceAll(`"`, `\"\"`),
        takeawayDescription.replaceAll(`"`, `\"\"`),
        eatInDescription.replaceAll(`"`, `\"\"`),
        webDelDescription.replaceAll(`"`, `\"\"`),
        webCollDescription.replaceAll(`"`, `\"\"`),
        kioskTakeawayDescription.replaceAll(`"`, `\"\"`),
        kioskEatInDescription.replaceAll(`"`, `\"\"`),
        showBarcode ? 1 : 0,
        showNotPaidFinishingOrderInCollection ? 1 : 0,
        showNotPaidFinishingOrderInEatIn ? 1 : 0,
        shopOpeningTime,
        shopClosingTime,
        secondLanguage ? secondLanguage.value : 1
      )
    );
  };
  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleSaveClicked();
    },
  }));

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveClicked();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    displayPreviousOrder,
    showBasketInSecondScreen,
    deliveryDescription,
    collectionDescription,
    takeawayDescription,
    eatInDescription,
    webDelDescription,
    webCollDescription,
    kioskTakeawayDescription,
    kioskEatInDescription,
    showBarcode,
    showNotPaidFinishingOrderInCollection,
    showNotPaidFinishingOrderInEatIn,
    shopOpeningTime,
    shopClosingTime,
  ]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getAllAppSettingsAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    let second_languages = null;
    if (setting_app_display.length > 0) {
      setDeliveryDescription(setting_app_display[0].DeliveryDescription);
      setCollectionDescription(setting_app_display[0].CollectionDescription);
      setTakeawayDescription(setting_app_display[0].TakeawayDescription);
      setEatInDescription(setting_app_display[0].EatInDescription);
      setWebDelDescription(setting_app_display[0].WebDelDescription);
      setWebCollDescription(setting_app_display[0].WebCollDescription);
      setKioskTakeawayDescription(
        setting_app_display[0].KioskTakeawayDescription
      );
      setKioskEatInDescription(setting_app_display[0].KioskEatInDescription);
      const openingSplit = setting_app_display[0].ShopOpeningTime.split(".");
      const closingSplit = setting_app_display[0].ShopClosingTime.split(".");
      setShopOpeningTime(openingSplit[0]);
      setShopClosingTime(closingSplit[0]);
      setDisplayPreviousOrder(setting_app_display[0].DisplayPreviousOrder);
      setShowBasketInSecondScreen(
        setting_app_display[0].ShowBasketInSecondScreen
      );
      setShowBarcode(setting_app_display[0].ShowBarcode);
      setShowNotPaidFinishingOrderInCollection(
        setting_app_display[0].ShowNotPaidFinishingOrderInCollection
      );
      setShowNotPaidFinishingOrderInEatIn(
        setting_app_display[0].ShowNotPaidFinishingOrderInEatIn
      );
      if (setting_app_display[0].SecondLanguage) {
        if (
          second_language_id.includes(
            parseInt(setting_app_display[0].SecondLanguage)
          )
        ) {
          let index = second_language_id.indexOf(
            parseInt(setting_app_display[0].SecondLanguage)
          );
          second_languages = {
            label: second_language[index].label,
            value: second_language[index].value,
          };
        }
        setSecondLanguage(second_languages);
      } else {
        setSecondLanguage({
          label: "Main Language",
          value: 1,
        });
      }
    } else {
      setSecondLanguage({
        label: "Main Language",
        value: 1,
      });
    }
  }, [setting_app_display]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div
          lassName="margin_menu_normal_horizontal"
          style={{
            marginLeft: "16px",
            marginRight: "16px",
          }}
        >
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.delivery_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={deliveryDescription}
                onChange={(e) => setDeliveryDescription(e.target.value)}
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.web_del_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={webDelDescription}
                onChange={(e) => setWebDelDescription(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.kiosk_takeaway_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={kioskTakeawayDescription}
                onChange={(e) => setKioskTakeawayDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.collection_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={collectionDescription}
                onChange={(e) => setCollectionDescription(e.target.value)}
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.web_coll_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={webCollDescription}
                onChange={(e) => setWebCollDescription(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.kiosk_eatIn_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={kioskEatInDescription}
                onChange={(e) => setKioskEatInDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.takeaway_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={takeawayDescription}
                onChange={(e) => setTakeawayDescription(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.web_eatIn_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={webEatInDescription}
                onChange={(e) => setWebEatInDescription(e.target.value)}
              />
            </div>

            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.shop_closing_time}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={shopClosingTime}
                onChange={(e) =>
                  setShopClosingTime(e.target.value.replace(/[^\d\.,:]+/g, ""))
                }
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.eatIn_description}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={eatInDescription}
                onChange={(e) => setEatInDescription(e.target.value)}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.shop_opening_time}</h6>
              <input
                className="ASOP-input-text input-padding"
                value={shopOpeningTime}
                onChange={(e) =>
                  setShopOpeningTime(e.target.value.replace(/[^\d\.,:]+/g, ""))
                }
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6">{lang.second_language}</h6>
              <Select
                options={second_language}
                defaultValue={secondLanguage}
                isSearchable={false}
                onChange={setSecondLanguage}
                value={secondLanguage}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text div-chechbox-p ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBarcode}
                      onChange={() => setShowBarcode(!showBarcode)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_barcode}
                />
              </FormGroup>
            </div>

            <div className="ASOP-div1-input-text div-chechbox-p ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={displayPreviousOrder}
                      onChange={() =>
                        setDisplayPreviousOrder(!displayPreviousOrder)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.display_previous_order}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showBasketInSecondScreen}
                      onChange={() =>
                        setShowBasketInSecondScreen(!showBasketInSecondScreen)
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_basket_in_second_screen}
                />
              </FormGroup>
            </div>
          </div>
          <div className="ASOP-div1">
            <div className="ASOP-div1-input-text div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showNotPaidFinishingOrderInEatIn}
                      onChange={() =>
                        setShowNotPaidFinishingOrderInEatIn(
                          !showNotPaidFinishingOrderInEatIn
                        )
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_not_paid_finishing_order_in_eatIn}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text div-chechbox-p">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showNotPaidFinishingOrderInCollection}
                      onChange={() =>
                        setShowNotPaidFinishingOrderInCollection(
                          !showNotPaidFinishingOrderInCollection
                        )
                      }
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.show_not_paid_finishing_order_in_collection}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Display;
