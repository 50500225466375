import React,{useState} from 'react';
import CodeDetailList from './CodeDetailList';

const Custom = ({selectGroupId,codeDetailList1}) => {
    const [clickedItem, setClickedItem] = useState(1);
    return (  
        <div   className="countaining-div"
        id="countaining"
        style={{
          // borderLeftWidth: 0.3,
          // borderLeftColor: main_nav_button_color,
          // borderLeftStyle: "solid",
          height: window.innerHeight - 220,
        }}>
  {clickedItem==1?<CodeDetailList selectGroupId={selectGroupId}
   codeDetailList1={codeDetailList1}/>:null}
        </div>
    );
}
 
export default Custom;