import * as actionTypes from "../ActionTypes";
const initialStates = {
  show_setup_popup: false,
  result_successfull: false,
  result_failed: false,
  result_message: "",
  setup_loading: false,
};

const setupReduser = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        setup_loading: false,
      };
    case actionTypes.SHOW_SETUP_POPUP:
      return {
        ...state,
        show_setup_popup: action.payload,
      };
    case actionTypes.RESET_CASH_ORDER:
      return {
        ...state,
        result_message: "",
        result_successfull: false,
        setup_loading: true,
        result_failed: false,
      };
    case actionTypes.RESET_CASH_ORDER_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_message: action.payload,
        setup_loading: false,
        result_failed: false,
        // show_setup_popup: false,
      };
    case actionTypes.RESET_CASH_ORDER_FAILED:
      return {
        ...state,
        result_message: action.payload,
        result_successfull: false,
        setup_loading: false,
        result_failed: true,
      };
    default:
      return state;
  }
};
export default setupReduser;
