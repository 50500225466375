import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";

import ListHeader from "../components/ListHeader";
import MenuListItem from "../components/MenuListItem";
import ProductSubmenuListPopup from "./ProductSubmenuListPopup";
import { marginNormalMenuHorizontal } from "../constants/Sizes";
import {
  showProductSubmenuListPopup,
  getProductSubmenuAction,
  deleteProductSubmenuAction,
  selectedProductSubmenuAction,
} from "../redux/product/Action";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
  sortNumberAction,
  changeProductSortLabelAction,
  updateProductSubmenuAppSortAction,
  updateProductSubmenuWebSortAction,
  productSubmenuSortNumberAction,
} from "../redux/menu/Actions";
import { getSubmenuAction } from "../redux/submenu/Action";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const ProductSubmenu = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef(false);
  const { height, width } = useScrollbarSize();

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);

  const {
    category_list,
    selected_category,
    printer_list,
    printer_list_id,
    font_family,
    font_family_id,
    font_size,
    font_size_id,
    change_selected_button_border_color_on_focus,
    product_submenu_sort_number,
  } = useSelector((state) => state.categoryList);
  const {
    product_submenu_list,
    selected_Product,
    selected_Product_submenu,
    change_product_sort_label,
  } = useSelector((state) => state.productList);

  const [productSubmenuList, setProductSubmenuList] = useState([]);
  const [sortOnWebOrApp, setSortOnWebOrApp] = useState(0);
  const handleAddSubmenu = () => {
    dispatch(showProductSubmenuListPopup(true));
  };
  const handleSortButtons = (key) => {
    // dispatch(selectedOtemInAllLists(null));
    dispatch(selectedProductSubmenuAction(null));
    dispatch(changeProductSortLabelAction(true));
    if (key == 1) {
      setSortOnWebOrApp(1);
    } else {
      setSortOnWebOrApp(2);
    }
  };
  const handleDragEnd = (result) => {
    const copy_list = [...productSubmenuList];
    // dispatch(changeSortLableAction(true));
    if (sortOnWebOrApp == 1) {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setProductSubmenuList(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];

      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateProductSubmenuWebSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.WebSortId,
            new_item.WebSortId,
            selected_Product.Id
          )
        );
      }
    } else {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setProductSubmenuList(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];
      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateProductSubmenuAppSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.AppSortId,
            new_item.AppSortId,
            selected_Product.Id
          )
        );
      }
    }
  };

  const handleDeleteProductSubmenu = (item) => {
    dispatch(
      deleteProductSubmenuAction(
        login_key,
        user_id,
        item.ShopId,
        item.ProductId,
        item.Id
      )
    );
  };

  const handleClickedItem = (item, key) => {
    setClickedItem(key);
    let printerList = [];
    let fontFamilyList = null;
    let fontSizeList = null;

    if (item.PrinterId) {
      for (let i = 0; i < item.PrinterId.length; i++) {
        if (printer_list_id.includes(parseInt(item.PrinterId[i]))) {
          let index = printer_list_id.indexOf(parseInt(item.PrinterId[i]));
          printerList.push({
            label: printer_list[index].label,
            value: printer_list[index].value,
          });
        }
      }
    }
    // SET DEFAULT FONT FAMILY LIST
    if (item.AppTitleFontFamily) {
      if (font_family_id.includes(parseInt(item.AppTitleFontFamily))) {
        let index = font_family_id.indexOf(parseInt(item.AppTitleFontFamily));
        fontFamilyList = {
          label: font_family[index].label,
          value: font_family[index].value,
        };
      }
    }

    // SET DEFAULT FONT SIZE LIST
    if (item.AppTitleFontSize) {
      if (font_size_id.includes(parseInt(item.AppTitleFontSize))) {
        let index = font_size_id.indexOf(parseInt(item.AppTitleFontSize));
        fontSizeList = {
          label: font_size[index].label,
          value: font_size[index].value,
        };
      }
    }
    dispatch(
      selectedProductSubmenuAction({
        ...item,
        PrinterId: printerList,
        AppTitleFontSize: fontSizeList,
        AppTitleFontFamily: fontFamilyList,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getProductSubmenuAction(
        login_key,
        user_id,
        selected_Product.ShopId,
        selected_Product.Id
      )
    );
  }, []);

  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (selected_category && didMountRef.current) {
      setClickedItem(2);
    }
    didMountRef.current = true;
  }, [selected_category]);
  // useEffect(() => {
  //   if (selected_shop) {
  //     setClickedItem(1);
  //   }
  // }, [selected_shop]);
  const handleCloseIcon = (item) => {
    const copy_list = [...productSubmenuList];
    for (let index = 0; index < copy_list.length; index++) {
      if (item.Id == copy_list[index].Id) {
        copy_list[index].showEditBox = false;
      }
    }
    setProductSubmenuList(copy_list);
  };
  const handleEditIconClick = (item) => {
    const copy_list = [...productSubmenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showEditBox = true;
      } else {
        copy_list[i].showEditBox = false;
      }
    }
    setProductSubmenuList(copy_list);
    dispatch(selectedOtemInAllLists(item));
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...productSubmenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
    }

    setProductSubmenuList(copy_product_list);
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...productSubmenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setProductSubmenuList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...productSubmenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setProductSubmenuList(copy_list);
  };

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_list = [...productSubmenuList];
    for (let i = 0; i < copy_list.length; i++) {
      if (copy_list[i].showEditBox) {
        copy_list[i].showEditBox = false;
      }
    }
    dispatch(justTitleAndPriceAction(1));
    setProductSubmenuList(copy_list);
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(justTitleAndPriceAction(0));
      dispatch(selectedOtemInAllLists(null));
    };
  }, [change_selected_button_border_color_on_focus]);
  useEffect(() => {
    if (product_submenu_list.length > 0) {
      setProductSubmenuList(product_submenu_list);
    } else {
      setProductSubmenuList([]);
    }
  }, [product_submenu_list]);
  useEffect(() => {
    if (selected_Product) {
      dispatch(
        getSubmenuAction(
          login_key,
          user_id,
          selected_Product.ShopId,
          selected_Product.Id
        )
      );
    }
  }, [selected_Product, selected_shop]);

  useEffect(() => {
    const copy_product_list = [...product_submenu_list];

    if (sortOnWebOrApp == 1) {
      const sortArrey = copy_product_list.sort(function (a, b) {
        return a.WebSortId - b.WebSortId;
      });

      if (change_product_sort_label) {
        dispatch(changeProductSortLabelAction(false));
        dispatch(selectedProductSubmenuAction(copy_product_list[0]));
        for (let i = 0; i < copy_product_list.length; i++) {
          copy_product_list[i].collapse = i == 0 ? true : false;
        }
      }
      setProductSubmenuList(sortArrey);
    } else {
      const sortArrey = copy_product_list.sort(function (a, b) {
        return a.AppSortId - b.AppSortId;
      });

      if (change_product_sort_label) {
        dispatch(changeProductSortLabelAction(false));
        dispatch(selectedProductSubmenuAction(sortArrey[0]));
        for (let i = 0; i < sortArrey.length; i++) {
          sortArrey[i].collapse = i == 0 ? true : false;
        }
      }

      setProductSubmenuList(sortArrey);
    }
    return () => {
      dispatch(productSubmenuSortNumberAction(sortOnWebOrApp));
    };
  }, [sortOnWebOrApp, product_submenu_list, change_product_sort_label]);
  useEffect(() => {
    if (product_submenu_sort_number == 2) {
      setSortOnWebOrApp(2);
    } else {
      setSortOnWebOrApp(1);
    }
  }, []);

  return (
    <div className="countaining-div" onClick={handleCloseEditBoxWithOnBlur}>
      {/* BACK & TITLE */}
      <ListHeader
        title={lang.product_submenu_list}
        show_back={true}
        clickedItem={() => setClickedItem(2)}
        sort_switch={true}
        sort_click={(key) => handleSortButtons(key)}
        sort_key={sortOnWebOrApp}
      />
      <div className="menu_list_header_container">
        {productSubmenuList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {" "}
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {productSubmenuList.map((item, index) => (
                      <Draggable
                        key={item.Id}
                        draggableId={item.Id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={index}
                          >
                            {!item.IsDeleted ? (
                              <MenuListItem
                                field_number={2}
                                item_title={item.AppTitle}
                                item_price={item.Amount}
                                field_1_title={item.AppTitle}
                                field_2_title={`£${item.Amount}`}
                                btn_number={1}
                                bt_1_title={lang.edit}
                                bt_1_click={() => handleClickedItem(item, 9)}
                                show_delete={true}
                                delete_click={() =>
                                  handleDeleteProductSubmenu(item)
                                }
                                show_delete_box={() =>
                                  handleShowDeleteBox(item)
                                }
                                cancel_delete_box={() =>
                                  handleCancelDeleteBox(item)
                                }
                                show_edit_icon={true}
                                selected={selected_Product_submenu}
                                item={item}
                                click_edit_icon={() =>
                                  handleEditIconClick(item)
                                }
                                click_close_icon={() => handleCloseIcon(item)}
                                number={4}
                              />
                            ) : null}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div className="div_submenu_add_container" style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span onClick={handleAddSubmenu}>
              <i class="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.add_submenu}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {productSubmenuList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={handleAddSubmenu}
            style={{
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.add_submenu}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductSubmenu;
