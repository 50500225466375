import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import ListHeader from "../components/ListHeader";
import MenuListItem from "../components/MenuListItem";
import AddMenuItem from "../components/AddMenuItem";
import {
  getCategoryOptionCrustAction,
  createCategoryOptionCrustAction,
  deleteCategoryOptionCrustAction,
  selctedCategoryOptionCrust,
  getCategoryOptionsListAction,
  selectedOtemInAllLists,
} from ".././redux/menu/Actions";

import {
  changeSelectedButtonBorderColorOnFocus,
  justTitleAndPriceAction,
} from "../redux/menu/Actions";
const CategoryOptionCrustList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef(false);

  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    category_option_crust_list,
    font_size,
    font_size_id,
    selected_category_option,
    selected_category,
    change_selected_button_border_color_on_focus,
    selected_category_option_crust,
    result_successfull,
  } = useSelector((state) => state.categoryList);

  const [emptyInput, setEmptyInput] = useState(false);
  const [categoryOptionCrustListTemp, setCategoryOptionCrustListTemp] =
    useState([]);

  const handleAddOption = (t, d, a) => {
    dispatch(
      createCategoryOptionCrustAction(
        login_key,
        user_id,
        selected_category_option.ShopId,
        selected_category_option.Id,
        t.replaceAll(`"`, `\"\"`),
        d.replaceAll(`"`, `\"\"`),
        a ? parseFloat(a) : 0
      )
    );
    // setEmptyInput(true);
  };
  const handleClick = (item, key) => {
    setClickedItem(key);
    let fontSizeList = null;
    // SET DEFAULT FONT SIZE LIST
    if (item.AppFontSize) {
      if (font_size_id.includes(parseInt(item.AppFontSize))) {
        let index = font_size_id.indexOf(parseInt(item.AppFontSize));
        fontSizeList = {
          label: font_size[index].label,
          value: font_size[index].value,
        };
      }
    }
    dispatch(
      selctedCategoryOptionCrust({ ...item, AppFontSize: fontSizeList })
    );
  };
  const handleDeleteOption = (item) =>
    dispatch(
      deleteCategoryOptionCrustAction(
        login_key,
        user_id,
        item.ShopId,
        item.Id,
        item.OptionId
      )
    );

  const handleEditIconClick = (item) => {
    const copy_product_list = [...categoryOptionCrustListTemp];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.Id == copy_product_list[i].Id) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setCategoryOptionCrustListTemp(copy_product_list);
    dispatch(selectedOtemInAllLists(item));
  };

  const handleCloseIcon = (item) => {
    const copy_product_list = [...categoryOptionCrustListTemp];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setCategoryOptionCrustListTemp(copy_product_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...categoryOptionCrustListTemp];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
    }

    setCategoryOptionCrustListTemp(copy_product_list);
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...categoryOptionCrustListTemp];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setCategoryOptionCrustListTemp(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...categoryOptionCrustListTemp];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setCategoryOptionCrustListTemp(copy_list);
  };
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);
  useEffect(() => {
    dispatch(
      getCategoryOptionCrustAction(
        login_key,
        user_id,
        selected_category_option.ShopId,
        selected_category_option.Id,
        selected_category_option.CategoryId
      )
    );
  }, []);

  useEffect(() => {
    if (category_option_crust_list.length > 0) {
      setCategoryOptionCrustListTemp(category_option_crust_list);
    } else {
      setCategoryOptionCrustListTemp([]);
    }
  }, [category_option_crust_list]);

  useEffect(() => {
    if (selected_category && didMountRef.current) {
      setClickedItem(3);
    }
    didMountRef.current = true;
  }, [selected_category]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_product_list = [...categoryOptionCrustListTemp];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (copy_product_list[index].showEditBox) {
        copy_product_list[index].showEditBox = false;
      }
    }
    dispatch(justTitleAndPriceAction(1));
    setCategoryOptionCrustListTemp(copy_product_list);
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(selectedOtemInAllLists(null));
      dispatch(justTitleAndPriceAction(0));
    };
  }, [change_selected_button_border_color_on_focus]);

  return (
    <div className="countaining-div" onClick={handleCloseEditBoxWithOnBlur}>
      <ListHeader
        title={lang.category_option_crustList}
        show_back={true}
        clickedItem={() => setClickedItem(3)}
      />
      <div className="menu_list_header_container">
        <div>
          {categoryOptionCrustListTemp.length > 0 ? (
            <div style={{ marginBottom: "150px" }}>
              {categoryOptionCrustListTemp.map((item, index) => (
                <div key={index}>
                  {!item.IsDeleted ? (
                    <MenuListItem
                      field_number={2}
                      item_title={item.AppTitle}
                      item_price={item.Price}
                      field_1_title={item.AppTitle}
                      field_2_title={`£${item.Price}`}
                      btn_number={1}
                      bt_1_title={lang.edit}
                      bt_1_click={() => handleClick(item, 11)}
                      show_delete={true}
                      delete_click={() => handleDeleteOption(item)}
                      show_edit_icon={true}
                      selected={selected_category_option_crust}
                      item={item}
                      number={6}
                      click_edit_icon={() => handleEditIconClick(item)}
                      click_close_icon={() => handleCloseIcon(item)}
                      show_delete_box={() => handleShowDeleteBox(item)}
                      cancel_delete_box={() => handleCancelDeleteBox(item)}
                    />
                  ) : null}
                </div>
              ))}
            </div>
          ) : (
            <h2 className="no-option">{lang.noOption}</h2>
          )}
        </div>
      </div>
      <AddMenuItem
        button_title={lang.add_crust}
        buttonClick={(t, d, a) => handleAddOption(t, d, a)}
        // empty={emptyInput}
        setEmptyInput={(i) => setEmptyInput(i)}
        result_successfull={result_successfull}
      />
    </div>
  );
};

export default CategoryOptionCrustList;
