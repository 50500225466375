import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuListItem from "../../../../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";
import { getDropDownAction } from "../../../../redux/menu/Actions";
import useScrollbarSize from "react-scrollbar-size";
import {
  codeDetailListAction,
  createCodeDetailAction,
  visibleCodeDetailAction,
  selectedCodeDetailAction,
  chaangedCodeDetailListAction,
  deleteCodeDetailAction,
} from "../../../../redux/settings/Action";
import { changeSelectedButtonBorderColorOnFocus } from "../../../../redux/menu/Actions";
const CustomList = ({ codeDetailList1 }) => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);

  const { code_detail_list, selected_group_id, selected_code_detail } =
    useSelector((state) => state.appSettingsRedusers);
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );

  const [codeDetailList, setCodeDetailList] = useState(codeDetailList1);
  const handleEditCodeDetail = (item) => {
    dispatch(selectedCodeDetailAction(item));
    dispatch(visibleCodeDetailAction(true));
  };
  const deleteCodeDetail = (item) => {
    dispatch(
      deleteCodeDetailAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        item.Id
      )
    );
  };
  const handleCreateCodeDetail = () => {
    dispatch(selectedCodeDetailAction(null));
    dispatch(visibleCodeDetailAction(true));
    dispatch(chaangedCodeDetailListAction(codeDetailList));
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...codeDetailList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setCodeDetailList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...codeDetailList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    selectedCodeDetailAction(copy_list);
  };

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        codeDetailListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop, selected_group_id]);

  useEffect(() => {
    let my_list = [];

    for (let i = 0; i < code_detail_list.length; i++) {
      if (selected_group_id == code_detail_list[i].GroupId) {
        my_list.push(code_detail_list[i]);
      }
    }
    setCodeDetailList(my_list);
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getDropDownAction(login_key, user_id, selected_shop[0].ShopId)
        );
      }
    }
  }, [code_detail_list]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
      id="countaining"
    >
      <div className="menu_list_header_container">
        {codeDetailList.length > 0 ? (
          <div>
            {codeDetailList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={item.Title}
                  item_title={item.Title}
                  btn_number={1}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleEditCodeDetail(item)}
                  show_delete={true}
                  delete_click={() => deleteCodeDetail(item)}
                  show_edit_icon={false}
                  selected={selected_code_detail}
                  item={item}
                  number={0}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span
              style={{ minWidth: "250px" }}
              onClick={handleCreateCodeDetail}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>
                {selected_group_id == 1
                  ? lang.create_dicount_reasones
                  : selected_group_id == 2
                  ? lang.create_food_group_section
                  : selected_group_id == 5
                  ? lang.cancel_reasone
                  : selected_group_id == 4
                  ? lang.create_refund_reasone
                  : selected_group_id == 6
                  ? lang.create_kitchen_message
                  : null}
              </p>
            </span>
          </div>
        )}
      </div>
      {codeDetailList.length > 0 ? (
        <div
          className="div_submenu_add_container_fixed"
          onClick={handleCreateCodeDetail}
          style={{
            width: 250,
            right:
              (window.innerWidth * 2) / 100 +
              width +
              marginNormalMenuHorizontal,
          }}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          <p>
            {selected_group_id == 1
              ? lang.create_dicount_reasones
              : selected_group_id == 2
              ? lang.create_food_group_section
              : selected_group_id == 5
              ? lang.cancel_reasone
              : selected_group_id == 4
              ? lang.create_refund_reasone
              : selected_group_id == 6
              ? lang.create_kitchen_message
              : null}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default CustomList;
