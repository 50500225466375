import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import SaveMenuButton from "./SaveMenuButton";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";

import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../constants/Colors";

import ListHeader from "../components/ListHeader";
import {
  getDriversListAction,
  createDriverAction,
  updateDriverAction,
} from "../redux/Employees/Actions";

const DriversEdi = ({ setClickedItem }) => {
  // const image_href = "http://37.32.4.85:800/";
  const image_href = "https://otopartnercentre.com/";
  const dispatch = useDispatch();
  const {
    dashboard_accessUser,
    dashboard_accessUser_id,
    dashboard_shop_group,
    dashboard_shop_group_id,
  } = useSelector((state) => state.categoryList);
  const { drivers_list, selected_driver, result_successfull } = useSelector(
    (state) => state.employeesRedusers
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [firstName, setFirstName] = useState("");
  const [lastNmae, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(false);
  const [mobile, setMobile] = useState("");
  const [href, setHref] = useState("");
  const [image, setImage] = useState("");
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [lastImageUrlName, setLastImageUrlName] = useState("");
  const [imageDimensions, setImageDimensions] = useState({});
  const [key1, setKey1] = useState("");
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const imageUrl = href;
  const loadImage = () => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  const input_mobile = mobile === 0 || mobile ? mobile : "";
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "grey",
      fontSize: "15px",
      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });
  const formData = new FormData();
  const handleCreateAndEdit = () => {
    if (selected_driver) {
      if (
        firstName == "" ||
        lastNmae == "" ||
        userName == "" ||
        password == "" ||
        mobile == ""
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (image != "" && image.size > 200000) {
        toast.error(<h5> &nbsp;&nbsp;{lang.web_image_size}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else if (!validator.isInt(String(mobile))) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_mobile_format_is_incorrect}&nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (mobile.length < 11) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.mobile_length_incorrect}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        formData.append("LoginKey", login_key);
        formData.append(" UserId", user_id);
        formData.append("Id", selected_driver.DriverId);
        formData.append("FirstName", firstName.replaceAll(`"`, `\"\"`));
        formData.append("LastName", lastNmae.replaceAll(`"`, `\"\"`));
        formData.append("Active", active ? 1 : 0);
        formData.append("Mobile", mobile);
        formData.append("UserName", userName.replaceAll(`"`, `\"\"`));
        formData.append("Pass", password);
        formData.append("ImageUrl", image ? image : "");
        formData.append("LastImageUrlName", lastImageUrlName);
        formData.append("DeleteImage", 0);
        dispatch(
          updateDriverAction(
            formData,
            login_key,
            user_id,
            selected_driver.DriverId,
            firstName.replaceAll(`"`, `\"\"`),
            lastNmae.replaceAll(`"`, `\"\"`),
            active ? 1 : 0,
            mobile,
            userName.replaceAll(`"`, `\"\"`),
            password,
            image
          )
        );
      }
    } else {
      if (
        firstName == "" ||
        lastNmae == "" ||
        userName == "" ||
        password == "" ||
        mobile == ""
      ) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (image != "" && image.size > 200000) {
        toast.error(<h5> &nbsp;&nbsp;{lang.web_image_size}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else if (!validator.isInt(String(mobile))) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{lang.the_mobile_format_is_incorrect}&nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (mobile.length < 11) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.mobile_length_incorrect}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        formData.append("LoginKey", login_key);
        formData.append(" UserId", user_id);
        formData.append("FirstName", firstName.replaceAll(`"`, `\"\"`));
        formData.append("LastName", lastNmae.replaceAll(`"`, `\"\"`));
        formData.append("Active", active ? 1 : 0);
        formData.append("Mobile", mobile);
        formData.append("UserName", userName.replaceAll(`"`, `\"\"`));
        formData.append("Pass", password);
        formData.append("ImageUrl", image ? image : "");
        dispatch(
          createDriverAction(
            formData,
            login_key,
            user_id,
            firstName.replaceAll(`"`, `\"\"`),
            lastNmae.replaceAll(`"`, `\"\"`),
            active ? 1 : 0,
            mobile,
            userName.replaceAll(`"`, `\"\"`),
            password,
            image
          )
        );
      }
    }
  };
  const onSelectPicture = (e) => {
    setImage(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      setHref(URL.createObjectURL(e.target.files[0]));
    }
    setKey1(e);
  };
  const handleMouseOver = () => {
    if (href) {
      setShowDeleteIcon(true);
      document.getElementById("profile_image").style.filter = "brightness(.35)";
    }
  };
  const handleMouseOut = () => {
    setShowDeleteIcon(false);
    document.getElementById("profile_image").style.filter = "brightness(100%)";
  };
  const handleDeleteProfileImage = () => {
    setImage("");
    setHref("");
    if (key1) {
      key1.target.value = null;
    }

    if (selected_driver) {
      if (selected_driver.ImageUrl != "") {
        if (`${image_href}${selected_driver.ImageUrl}` != href) {
          setHref(`${image_href}${selected_driver.ImageUrl}`);
        } else {
          formData.append("LoginKey", login_key);
          formData.append(" UserId", user_id);
          formData.append("Id", selected_driver.DriverId);
          formData.append("FirstName", firstName.replaceAll(`"`, `\"\"`));
          formData.append("LastName", lastNmae.replaceAll(`"`, `\"\"`));
          formData.append("Active", active ? 1 : 0);
          formData.append("Mobile", mobile);
          formData.append("UserName", userName.replaceAll(`"`, `\"\"`));
          formData.append("Pass", password);
          formData.append("ImageUrl", "");
          formData.append("LastImageUrlName", lastImageUrlName);
          formData.append("DeleteImage", 1);
          dispatch(
            updateDriverAction(
              formData,
              login_key,
              user_id,
              selected_driver.DriverId,
              firstName.replaceAll(`"`, `\"\"`),
              lastNmae.replaceAll(`"`, `\"\"`),
              active ? 1 : 0,
              mobile,
              userName.replaceAll(`"`, `\"\"`),
              password,
              image
            )
          );
        }
      } else {
        setShowDeleteIcon(false);
      }
    }
  };
  useEffect(() => {
    if (selected_driver) {
      setFirstName(selected_driver.FirstName);
      setLastName(selected_driver.LastName);
      setMobile(selected_driver.Mobile);
      setUserName(selected_driver.UserName);
      setPassword(selected_driver.Pass);
      setActive(selected_driver.Active);

      if (
        selected_driver.ImageUrl &&
        selected_driver.ImageUrl !=
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRD5iROb1TgJ_rcl-6r-68v1yjtID052zxSkw&usqp=CAU"
      ) {
        setHref(`${image_href}${selected_driver.ImageUrl}`);
      } else {
        setHref("");
      }
      setLastImageUrlName(selected_driver.ImageUrl);
    }
  }, [selected_driver]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        // if (selected_driver) {
        //   if (
        //     firstName == "" ||
        //     lastNmae == "" ||
        //     userName == "" ||
        //     password == "" ||
        //     mobile == ""
        //   ) {
        //     toast.error(
        //       <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        //       {
        //         position: "top-center",
        //         duration: 3000,
        //         style: { backgroundColor: "red", color: "white" },
        //         icon: (
        //           <i
        //             style={{ fontSize: 40 }}
        //             className="fa fa-times-circle"
        //             aria-hidden="true"
        //           ></i>
        //         ),
        //       }
        //     );
        //   } else if (!validator.isInt(String(mobile))) {
        //     toast.error(
        //       <h5>
        //         {" "}
        //         &nbsp;&nbsp;{
        //           lang.the_mobile_format_is_incorrect
        //         }&nbsp;&nbsp;{" "}
        //       </h5>,
        //       {
        //         position: "top-center",
        //         duration: 3000,
        //         style: { backgroundColor: "red", color: "white" },
        //         icon: (
        //           <i
        //             style={{ fontSize: 40 }}
        //             className="fa fa-times-circle"
        //             aria-hidden="true"
        //           ></i>
        //         ),
        //       }
        //     );
        //   } else if (mobile.length < 11) {
        //     toast.error(
        //       <h5> &nbsp;&nbsp;{lang.mobile_length_incorrect}&nbsp;&nbsp; </h5>,
        //       {
        //         position: "top-center",
        //         duration: 3000,
        //         style: { backgroundColor: "red", color: "white" },
        //         icon: (
        //           <i
        //             style={{ fontSize: 40 }}
        //             className="fa fa-times-circle"
        //             aria-hidden="true"
        //           ></i>
        //         ),
        //       }
        //     );
        //   } else {
        //     formData.append("LoginKey", login_key);
        //     formData.append(" UserId", user_id);
        //     formData.append("Id", selected_driver.DriverId);
        //     formData.append("FirstName", firstName.replaceAll(`"`, `\"\"`));
        //     formData.append("LastName", lastNmae.replaceAll(`"`, `\"\"`));
        //     formData.append("Active", active ? 1 : 0);
        //     formData.append("Mobile", mobile);
        //     formData.append("UserName", userName.replaceAll(`"`, `\"\"`));
        //     formData.append("Pass", password);
        //     formData.append("ImageUrl", image);
        //     dispatch(updateDriverAction(formData));
        //   }
        // }
        handleCreateAndEdit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [firstName, lastNmae, active, mobile, userName, password, image]);
  useEffect(() => {
    formData.delete("LoginKey");
    formData.delete(" UserId");
    formData.delete("Id");
    formData.delete("FirstName");
    formData.delete("LastName");
    formData.delete("Active");
    formData.delete("Mobile");
    formData.delete("UserName");
    formData.delete("Pass");
    formData.delete("ImageUrl");
  }, []);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);

  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
    >
      <ListHeader
        title={lang.edit_drivers}
        //   title={
        //     selected_shop_employees ? lang.imployees_edit : lang.create_employee
        //   }
        show_back={true}
        clickedItem={() => setClickedItem(1)}
      />
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="driver-sm-div1 container-image-box">
            {" "}
            <div
              className="container-image drivers-container-image-closeIcon"
              onMouseOver={() => handleMouseOver()}
              onMouseLeave={() => handleMouseOut()}
              // style={{ width: "200px", height: "200px" }}
            >
              {href ? (
                showDeleteIcon ? (
                  <i
                    id="delete-icon"
                    class="fa fa-trash"
                    aria-hidden="true"
                    onClick={() => handleDeleteProfileImage()}
                  ></i>
                ) : null
              ) : null}{" "}
              <img
                id="profile_image"
                src={
                  href != ""
                    ? href
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRD5iROb1TgJ_rcl-6r-68v1yjtID052zxSkw&usqp=CAU"
                }
                onLoad={loadImage}
              />
            </div>
          </div>{" "}
          <div className="driver-sm-div1 container-picture-selector">
            <div className="container-input-select">
              {" "}
              <input
                type="file"
                name="file"
                id="file"
                class="inputfile"
                accept="image/*"
                onChange={(e) => onSelectPicture(e)}
              />
              <label
                for="file"
                style={{
                  height: "45px",
                }}
              >
                <i className="fas fa-camera"></i>
                <span> Change Photo</span>
              </label>
              <p className="image-default-size">{lang.image_size}</p>
            </div>
            <div
              style={{
                width: "28%",
              }}
            >
              <h6 className="submenuEdit-titles h6">Driver id</h6>
              <div className="sm-input-driver container-unique-id">
                {selected_driver ? selected_driver.UniqueId : null}
              </div>
            </div>
          </div>
          {/* <div className="sm-div1">
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.first_name}</h6>
              <input
                className="sm-input input-padding"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.last_name}</h6>

              <input
                className="sm-input input-padding"
                value={lastNmae}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="h6">* {lang.mobile}</h6>
              <input
                className="sm-input input-padding"
                maxLength={11}
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
          </div> */}
          <div
            className="sm-div1"
            style={{
              width: "100%",
            }}
          >
            <Box
              className="driver-container-textfield"
              component="form"
              // sx={{
              //   "& > :not(style)": { m: 1, display: "flex" },
              // }}

              // noValidate
              autoComplete="on"
            >
              <CssTextField
                key={1}
                label={lang.first_name1}
                // id="custom-css-outlined-input3"
                defaultValue={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
              />
              <CssTextField
                key={2}
                label={lang.last_name1}
                // id="custom-css-outlined-input4"
                defaultValue={lastNmae}
                onChange={(e) => setLastName(e.target.value)}
                style={{
                  width: "28%",
                }}
                // onClick={() => inref.current.focus()}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
              />
              <CssTextField
                key={3}
                label={lang.mobile1}
                // id="custom-css-outlined-input5"
                value={mobile}
                onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
                style={{
                  width: "28%",
                }}
                autoFocus={focusedInputKey == 3 ? true : false}
                onFocus={() => setFocusedInputKey(3)}
                // onInput={(e) => {
                //   e.target.value = Number.max(parseInt(e.target.value))
                //     .toString()
                //     .slice(0, 11);
                // }}
                // onInput={(e) => {
                //   e.target.value = Math.max(0, parseInt(e.target.value))
                //     .toString()
                //     .slice(0, 11);
                // }}
                inputProps={{ maxLength: 11 }}
              />
            </Box>
          </div>
          {/* <div className="sm-div2">
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.user_name}</h6>
              <input
                className="sm-input input-padding"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="sm-m">
              <h6 className="submenuEdit-titles h6">* {lang.pass}</h6>
              <input
                className="sm-input input-padding"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="sm-m  div-chechbox-p countainer-checkbox ">
              <input
                id="check40"
                type="checkbox"
                checked={active}
                onChange={() => setActive(!active)}
              />
              <label for="check40" className="checkmark"></label>
              <h6 className="  h6">{lang.active}</h6>
            </div>
          </div> */}
          <div className="sm-div2">
            <Box
              className="driver-container-textfield-2"
              component="form"
              // sx={{
              //   "& > :not(style)": { m: 1, width: "35ch", height: "20ch" },
              // }}
              // noValidate

              autoComplete="on"
            >
              <CssTextField
                key={4}
                label={lang.user_name1}
                defaultValue={userName}
                onChange={(e) => setUserName(e.target.value)}
                style={{
                  width: "43.5%",
                }}
                autoFocus={focusedInputKey == 4 ? true : false}
                onFocus={() => setFocusedInputKey(4)}
              />
              <CssTextField
                key={5}
                type="password"
                label={lang.pass1}
                // id="custom-css-outlined-input2"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "43.5%",
                }}
                autoFocus={focusedInputKey == 5 ? true : false}
                onFocus={() => setFocusedInputKey(5)}
              />
            </Box>

            <div className="sm-m  div-chechbox-p countainer-checkbox12 ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={() => setActive(!active)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleCreateAndEdit()} />
    </div>
  );
};

export default DriversEdi;
