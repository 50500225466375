import React, { Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import "datetime-picker-reactjs/dist/index.css";
import DatePicker from "react-datepicker";
import { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { usePopperTooltip } from "react-popper-tooltip";
import {
  getCheckInCheckOutListAction,
  updateCheckInCheckOutAction,
  deleteCheckIncheckOutAction,
} from "../../../redux/Employees/Actions";
import Loader from "../../../components/Loader";
import { setDefaultAction } from "../../../redux/menu/Actions";
const Entry_Exit = () => {
  const pdfref = useRef();
  const dispatch = useDispatch();
  const [id, setId] = useState(0);
  const [selectedCourseKey, setSelectedCourseKey] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [chechInCheckOutList, setChechInCheckOutList] = useState([]);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      interactive: true,
      closeOnOutsideClick: true,
    });
  const {
    check_in_check_out_list,
    loading_employees,
    result_failed,
    result_message,
    result_successfull,
  } = useSelector((state) => state.employeesRedusers);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);

  const handleShowDeleteBox = (item) => {
    setId(item.Id);
    const copy_chechInCheckOutList = [...chechInCheckOutList];
    if (copy_chechInCheckOutList) {
      if (copy_chechInCheckOutList.length > 0) {
        for (let i = 0; i < copy_chechInCheckOutList.length; i++) {
          if (item.Id == copy_chechInCheckOutList[i].Id) {
            copy_chechInCheckOutList[i].showDeleteBox = true;
          } else {
            copy_chechInCheckOutList[i].showDeleteBox = false;
          }
        }
      }
    }
    setChechInCheckOutList(copy_chechInCheckOutList);
  };
  const handleCancleDeleteBox = (item) => {
    const copy_chechInCheckOutList = [...chechInCheckOutList];
    if (copy_chechInCheckOutList) {
      if (copy_chechInCheckOutList.length > 0) {
        for (let i = 0; i < copy_chechInCheckOutList.length; i++) {
          if (item.Id == copy_chechInCheckOutList[i].Id) {
            copy_chechInCheckOutList[i].showDeleteBox = false;
          }
        }
      }
    }
    setChechInCheckOutList(copy_chechInCheckOutList);
  };
  const handleChangeInputAndDatePickerValue = (e, item, id) => {
    const copy_chechInCheckOutList = [...chechInCheckOutList];
    if (copy_chechInCheckOutList) {
      if (copy_chechInCheckOutList.length > 0) {
        for (let i = 0; i < copy_chechInCheckOutList.length; i++) {
          if (item.Id == copy_chechInCheckOutList[i].Id) {
            if (id == 1) {
              copy_chechInCheckOutList[i].DateEnter = e;
            } else if (id == 2) {
              copy_chechInCheckOutList[i].DateExit = e;
            } else if (id == 3) {
              copy_chechInCheckOutList[i].LowTimeWork = e.target.value.replace(
                /[^\d\:]+/g,
                ""
              );
            }
          }
        }
      }
    }
    setChechInCheckOutList(copy_chechInCheckOutList);
  };
  const handleSave = (item) => {
    let date = new Date();
    let newDate = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
      newDate.setDate(newDate.getDate() - 1);
    }

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay();

    let decreased_days = day == 0 ? day + 6 : day - 1;
    let new_date = newDate;

    new_date.setDate(new_date.getDate() - decreased_days);

    let this_month =
      month < 9 ? `${year}-0${month + 1}-01` : `${year}-${month + 1}-01`;
    dispatch(
      updateCheckInCheckOutAction(
        item.ShopId,
        login_key,
        item.UserId,
        item.Id,
        moment(item.DateEnter).format("YYYY-MM-DD HH:mm:ss "),
        moment(item.DateExit).format("YYYY-MM-DD HH:mm:ss "),

        item.LowTimeWork ? item.LowTimeWork : 0,
        user_id,

        selectedCourseKey == 1
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 2
          ? moment(new_date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 3
          ? this_month
          : selectedCourseKey == 4
          ? `${year}-01-01`
          : selectedCourseKey == 5
          ? moment(startDate).format("YYYY-MM-DD h:mm:ss")
          : "",
        selectedCourseKey == 1
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 2
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 3
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 4
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 5
          ? moment(endDate).format("YYYY-MM-DD h:mm:ss")
          : ""
      )
    );
  };
  const handleDelete = (item) => {
    let date = new Date();
    let newDate = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
      newDate.setDate(newDate.getDate() - 1);
    }

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay();

    let decreased_days = day == 0 ? day + 6 : day - 1;
    let new_date = newDate;

    new_date.setDate(new_date.getDate() - decreased_days);
    let this_month =
      month < 9 ? `${year}-0${month + 1}-01` : `${year}-${month + 1}-01`;
    dispatch(
      deleteCheckIncheckOutAction(
        item.ShopId,
        login_key,
        item.UserId,
        item.Id,
        user_id,
        selectedCourseKey == 1
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 2
          ? moment(new_date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 3
          ? this_month
          : selectedCourseKey == 4
          ? `${year}-01-01`
          : selectedCourseKey == 5
          ? moment(startDate).format("YYYY-MM-DD h:mm:ss")
          : "",
        selectedCourseKey == 1
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 2
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 3
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 4
          ? moment(date).format("YYYY-MM-DD h:mm:ss")
          : selectedCourseKey == 5
          ? moment(endDate).format("YYYY-MM-DD h:mm:ss")
          : ""
      )
    );
  };
  const handleGetEmployeePerformance = (key) => {
    setSelectedCourseKey(key);
    let date = new Date();

    let newDate = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
      newDate.setDate(newDate.getDate() - 1);
    }

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDay();

    let decreased_days = day == 0 ? day + 6 : day - 1;
    let new_date = newDate;
    let this_month =
      month < 9 ? `${year}-0${month + 1}-01` : `${year}-${month + 1}-01`;
    new_date.setDate(new_date.getDate() - decreased_days);

    // let daysLength = new Date(year, month + 1, 0).getDate();
    // let lastmonth = new Date(year, 12, 0).getDate();
    if (key == 1) {
      setStartDate(date);
      setEndDate(date);
    } else if (key == 2) {
      setStartDate(new_date);
      setEndDate(date);
    } else if (key == 3) {
      setStartDate(new Date(this_month));
      setEndDate(date);
    } else if (key == 4) {
      setStartDate(new Date(`${year}-01-01`));
      setEndDate(date);
    } else if (key == 5) {
      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
    }

    if (
      moment(startDate).format("YYYY-MM-DD") <=
      moment(endDate).format("YYYY-MM-DD")
    ) {
      if (selected_shop) {
        if (selected_shop.length > 0) {
          dispatch(
            getCheckInCheckOutListAction(
              selected_shop[0].ShopId,
              login_key,
              user_id,
              key == 1
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 2
                ? moment(new_date).format("YYYY-MM-DD h:mm:ss")
                : key == 3
                ? this_month
                : key == 4
                ? `${year}-01-01`
                : key == 5
                ? moment(startDate).format("YYYY-MM-DD h:mm:ss")
                : "",
              key == 1
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 2
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 3
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 4
                ? moment(date).format("YYYY-MM-DD h:mm:ss")
                : key == 5
                ? moment(endDate).format("YYYY-MM-DD h:mm:ss")
                : ""
            )
          );
        }
      }
    } else {
      toast.error(
        <h5>
          {" "}
          &nbsp;&nbsp;{lang.start_date_end_date_in_order_list}&nbsp;&nbsp;{" "}
        </h5>,
        {
          position: "top-center",
          duration: 5000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    }
  };
  const pdfDownload = async () => {
    // const input = pdfref.current;
    // html2canvas(input).then((canvas) => {
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF("p", "px", "a4", false, true, 2, 1.0);
    // const pdfwith = 170;
    // const pdfheigh = pdf.internal.pageSize.getHeight();
    // const imgwith = canvas.width;
    // const imgheight = canvas.height;
    // const ratio = Math.min(pdfwith / imgwith, pdfheigh / imgheight);
    // const imgx = (pdfwith - imgwith * ratio) / 2;
    // const imgy = 30;
    // pdf.addPage("PNG", "portrait");
    // pdf.addImage(imgData, "PNG", imgx, 0, imgwith * ratio, imgheight * ratio);
    // pdf.save("download123.pdf"); });
    var doc = new jsPDF();
    doc.autoTable({
      head: [["Name", "Check in", "Check out", "Duration", "Work deficit"]],
      headStyles: {
        halign: "center",
        cellWidth: 38,
        minCellHeight: 12,
        valign: "middle",
        fillColor: "#910d63",
      },
      margin: { right: 30, left: 10 },
    });
    if (check_in_check_out_list) {
      if (check_in_check_out_list.length > 0) {
        for (let i = 0; i < check_in_check_out_list.length; i++) {
          doc.autoTable({
            body: [
              [
                check_in_check_out_list[i].EmployeeName,
                moment(check_in_check_out_list[i].DateEnter).format(
                  "YYYY-MM-DD H:mm:ss"
                ),
                moment(check_in_check_out_list[i].DateExit).format(
                  "YYYY-MM-DD H:mm:ss"
                ),
                check_in_check_out_list[i].daily_operation,

                check_in_check_out_list[i].LowTimeWork == 0
                  ? "00:00:00"
                  : check_in_check_out_list[i].LowTimeWork,
              ],
            ],
            margin: { right: 30, left: 10 },
            columnStyles: {
              0: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 40.5,
                cellWidth: 38,
                minCellHeight: 11,
                valign: "middle",
              },
              1: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 50.5,
                cellWidth: 38,
                minCellHeight: 11,
                valign: "middle",
              },
              2: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 50.5,
                cellWidth: 38,
                minCellHeight: 11,
                valign: "middle",
              },
              3: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 20,
                cellWidth: 38,
                minCellHeight: 11,
                valign: "middle",
              },
              4: {
                halign: "center",
                fillColor: "#ebebeb",
                // cellWidth: 20,
                cellWidth: 38,
                minCellHeight: 11,
                valign: "middle",
              },
            },
            theme: "striped",
          });
        }
      }
    }

    doc.save("StaffReport.pdf");
    // const doc = new jsPDF();
    // doc.autoTable({ html: "#my-table" });
    // doc.save("table.pdf");
  };
  useEffect(() => {
    let date = new Date();
    let hours = date.getHours();
    if (hours < 5) {
      date.setDate(date.getDate() - 1);
    }
    setStartDate(date);
    setEndDate(date);
    if (selected_shop) {
      if (selected_shop.length > 0) {
        setSelectedCourseKey(1);
        dispatch(
          getCheckInCheckOutListAction(
            selected_shop[0].ShopId,
            login_key,
            user_id,
            // "2022-03-06 10:20:58",
            // "2023-08-06 10:20:58"
            moment(date).format("YYYY-MM-DD h:mm:ss"),
            moment(date).format("YYYY-MM-DD h:mm:ss")
          )
        );
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (check_in_check_out_list) {
      if (check_in_check_out_list.length > 0) {
        setChechInCheckOutList(check_in_check_out_list);
      } else {
        setChechInCheckOutList([]);
      }
    } else {
      setChechInCheckOutList([]);
    }
  }, [check_in_check_out_list]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  return (
    <div className="countaining-div">
      <div className=" enter-exit-container-buttons-date-picker">
        <div className=" enter-exit-container-buttons ">
          <button
            style={{
              backgroundColor: selectedCourseKey == 1 ? "red" : null,
              color: selectedCourseKey == 1 ? "white" : null,
            }}
            onClick={() => handleGetEmployeePerformance(1)}
          >
            {lang.today}
          </button>
          <button
            style={{
              backgroundColor: selectedCourseKey == 2 ? "red" : null,
              color: selectedCourseKey == 2 ? "white" : null,
            }}
            onClick={() => handleGetEmployeePerformance(2)}
          >
            {lang.week}
          </button>
          <button
            style={{
              backgroundColor: selectedCourseKey == 3 ? "red" : null,
              color: selectedCourseKey == 3 ? "white" : null,
            }}
            onClick={() => handleGetEmployeePerformance(3)}
          >
            {lang.month}
          </button>
          <button
            style={{
              backgroundColor: selectedCourseKey == 4 ? "red" : null,
              color: selectedCourseKey == 4 ? "white" : null,
            }}
            onClick={() => handleGetEmployeePerformance(4)}
          >
            {lang.year}
          </button>
        </div>

        <div className=" enter-exot-container-datefrom-dateto">
          <div style={{ width: "29%" }}>
            <h6 className="h6">{lang.from}</h6>

            <DatePicker
              placeholderText="From"
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={(e) => setStartDate(e)}
              className="date_picker_sales"
              // calendarClassName="calendarClassName"
            />
          </div>
          <div style={{ width: "29%" }}>
            <h6 className="h6">{lang.to}</h6>
            <DatePicker
              placeholderText="To"
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={(e) => setEndDate(e)}
              className="date_picker_sales"
            />
          </div>
          <div className="sales-container-result-button">
            <button onClick={() => handleGetEmployeePerformance(5)}>
              Result
            </button>
          </div>
          <div className="sales-container-result-button">
            <button
              // onClick={() => handleGetEmployeePerformance(5)}
              style={{
                backgroundColor: "rgb(55, 55, 247)",
                fontSize: "14px",
              }}
              onClick={pdfDownload}
            >
              {lang.export_to_pdf}
            </button>
          </div>
        </div>
      </div>
      <div className="entry-exit-container-main">
        <div>
          <div className="list_header_container entry-exit-container-header">
            <div className="entry-exit-header-container-inputs-datepicker-titles">
              <p className="entry-exit-container-name-text">{lang.name}</p>{" "}
              <p className="entry-exit-container-checkin-text">
                {lang.check_in}
              </p>
              <p className="entry-exit-container-checkin-text">
                {lang.check_out}
              </p>
              <p className="entry-exit-container-duration-text">
                {lang.duration}
              </p>
              <p className="entry-exit-container-duration-text">
                {lang.work_deficit}
              </p>
            </div>
            <p className="entry-exit-delete-icon-header"></p>
          </div>
        </div>
        <div className="menu_list_header_container " style={{ height: "89%" }}>
          <div
            className="margin_menu_normal_horizontal"
            // ref={pdfref}
            id="my-table"
          >
            {chechInCheckOutList
              ? chechInCheckOutList.length > 0
                ? chechInCheckOutList.map((item, index) => (
                    <div className="entry-exit-container">
                      <div className="entry-exit-container-main-section">
                        <div className="entry-exit-container-container-inputs-datepicker">
                          <div className="entry-exit-container-container-employee-name">
                            <p>{item.EmployeeName}</p>
                          </div>
                          <div className="entry-exit-container-container-datepicker">
                            <DatePicker
                              timeFormat="HH:mm:ss"
                              showTimeSelect
                              // selected={moment(item.DateEnter).format(
                              //   "yyyy-MM-dd  hh:mm:ss aa"
                              // )}

                              value={moment(item.DateEnter).format(
                                "YYYY-MM-DD H:mm:ss"
                              )}
                              // value={item.DateEnter}

                              onChange={(e) =>
                                handleChangeInputAndDatePickerValue(e, item, 1)
                              }
                              dateFormat="yyyy-MM-dd  hh:mm:ss "
                              className="date_picker entry-exit-date_picker"
                              calendarClassName="calendarClassName"
                            />{" "}
                            <DatePicker
                              showTimeSelect
                              value={moment(item.DateExit).format(
                                "YYYY-MM-DD H:mm:ss"
                              )}
                              onChange={(e) =>
                                handleChangeInputAndDatePickerValue(e, item, 2)
                              }
                              dateFormat="yyyy-MM-dd  hh:mm:ss "
                              className="date_picker entry-exit-date_picker"
                              calendarClassName="calendarClassName"
                            />
                          </div>
                          <div className="entry-exit-container-container-inputs">
                            <input
                              value={item.daily_operation}
                              disabled
                              className="entry-exit-disable-input"
                            />
                            <input
                              value={
                                item.LowTimeWork == 0
                                  ? "00:00"
                                  : item.LowTimeWork
                              }
                              type="time"
                              onChange={(e) =>
                                handleChangeInputAndDatePickerValue(e, item, 3)
                              }
                              className="entry-exit-input"
                            />
                          </div>
                        </div>
                        <div className="entry-exit-container-icons">
                          <div>
                            <i
                              class="fas fa-trash"
                              onClick={() => handleShowDeleteBox(item)}
                              ref={item.Id == id ? setTriggerRef : null}
                            ></i>
                            {item.showDeleteBox && (
                              <div
                                ref={setTooltipRef}
                                {...getTooltipProps({
                                  className: "tooltip-container",
                                  // interactive: true,
                                })}
                              >
                                <div
                                  {...getArrowProps({
                                    className: "tooltip-arrow",
                                  })}
                                ></div>
                                <div className="delete-popup ">
                                  <h6 style={{ fontSize: "19px" }}>
                                    {lang.delete_message}
                                  </h6>
                                  <div className="menu-list-container-cancel-and-ok-button">
                                    <button
                                      onClick={() =>
                                        handleCancleDeleteBox(item)
                                      }
                                    >
                                      {lang.cancel}
                                    </button>
                                    <button onClick={() => handleDelete(item)}>
                                      {lang.ok}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="entry-exit-container-save-button">
                        <button type="" onClick={() => handleSave(item)}>
                          {lang.save}
                        </button>
                      </div>
                    </div>
                  ))
                : null
              : null}
          </div>
        </div>{" "}
      </div>
      <Toaster />
      {loading_employees ? <Loader /> : null}
    </div>
  );
};

export default Entry_Exit;
