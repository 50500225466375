import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";

import { setDefaultAction } from "../../../../redux/menu/Actions";
import Loader from "../../../../components/Loader";
import {
  getCarrierBagListAction,
  updateCarrierBagAction,
} from "../../../../redux/GeneralSetting/Actions";
import { primaryColor } from "../../../../constants/Colors";
import SaveMenuButton from "../../../../components/SaveMenuButton";
const CarrierBag = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    cariier_bag_list,
    result_failed,
    result_successfull,
    result_message,
    loading_post_code,
  } = useSelector((state) => state.tabelAreaRedusers);
  const [label, setLabel] = useState("");
  const [amount, setAmount] = useState(0);
  const [activeOnWeb, setActiveOnWeb] = useState(false);
  const [activeInApp, setActiveInApp] = useState(false);
  const [activeInKiosk, setActiveInKiosk] = useState(false);
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "grey",
      fontSize: "15px",
      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getCarrierBagListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    if (cariier_bag_list.length > 0) {
      setLabel(cariier_bag_list[0].CarrierBagTitle);
      setAmount(cariier_bag_list[0].CarrierBagAmount);
      setActiveOnWeb(cariier_bag_list[0].ActiveWebCarrierBag);
      setActiveInApp(cariier_bag_list[0].ActiveAppCarrierBag);
      setActiveInKiosk(cariier_bag_list[0].ActiveKioskCarrierBag);
    }
  }, [cariier_bag_list]);
  const handleSaveCarrierBag = () => {
    if (activeInApp || activeInKiosk || activeOnWeb) {
      if (label == "" || amount == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (!validator.isFloat(String(amount))) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.amount_format_incorrect}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateCarrierBagAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            cariier_bag_list[0].Id ? cariier_bag_list[0].Id : 0,
            amount ? parseFloat(amount) : 0,
            label,
            activeOnWeb ? 1 : 0,
            activeInApp ? 1 : 0,
            activeInKiosk ? 1 : 0
          )
        );
      }
    } else if (amount != "" && !validator.isFloat(String(amount))) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.amount_format_incorrect}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateCarrierBagAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          cariier_bag_list[0].Id ? cariier_bag_list[0].Id : 0,
          amount ? parseFloat(amount) : 0,
          label,
          activeOnWeb ? 1 : 0,
          activeInApp ? 1 : 0,
          activeInKiosk ? 1 : 0
        )
      );
    }
  };
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSaveCarrierBag();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [amount, label, activeInApp, activeInKiosk, activeOnWeb]);
  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: window.innerHeight - 220,
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div2">
            <Box
              className="general_setting_carrier_bag"
              component="form"
              // sx={{
              //   "& > :not(style)": { m: 1, width: "35ch", height: "20ch" },
              // }}
              style={{ flexDirection: "row" }}
              // noValidate

              autoComplete="on"
            >
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    // "&.Mui-focused": {
                    //   color: primary_color,
                    // },
                  },
                }}
                key={1}
                label={`* ${lang.label}`}
                value={label}
                defaultValue={label}
                onChange={(e) => setLabel(e.target.value)}
                style={{
                  width: "47%",
                }}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
                onBlur={() => setFocusedInputKey(null)}
              />
              <CssTextField
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    top: -5,
                    "&.MuiInputLabel-shrink": { top: 0 },
                    // "&.Mui-focused": {
                    //   color: primary_color,
                    // },
                  },
                }}
                key={2}
                label={`* ${lang.amount}`}
                value={amount}
                onChange={(e) =>
                  setAmount(e.target.value.replace(/[^\d\.]+/g, ""))
                }
                style={{
                  width: "47%",
                }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
                onBlur={() => setFocusedInputKey(null)}
              />
            </Box>
          </div>
          <div className="sm-div2">
            <div className="sm-m  div-chechbox-p  ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activeOnWeb}
                      onChange={() => setActiveOnWeb(!activeOnWeb)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active_on_web}
                />
              </FormGroup>
            </div>
            <div className="sm-m  div-chechbox-p ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activeInApp}
                      onChange={() => setActiveInApp(!activeInApp)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active_in_app}
                />
              </FormGroup>
            </div>
            <div className="sm-m  div-chechbox-p ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activeInKiosk}
                      onChange={() => setActiveInKiosk(!activeInKiosk)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active_in_kiosk}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={() => handleSaveCarrierBag()} />
      {loading_post_code ? <Loader /> : null}
    </div>
  );
};

export default CarrierBag;
