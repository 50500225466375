import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  primaryColor,
  main_nav_button_color,
  main_nav_back_color,
} from "../../../../constants/Colors";
import Loader from "../../../../components/Loader";
import {
  getCategoriesListAction,
  setDefaultAction,
} from "../../../../redux/menu/Actions";
import { useDispatch, useSelector } from "react-redux";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import { getSubmenuAction } from "../../../../redux/submenu/Action";
import {
  getShortCutButtonListAction,
  updateShortCutButtonAction,
} from "../../../../redux/GeneralSetting/Actions";
const ShortCuts = () => {
  const dispatch = useDispatch();

  const [shortCutButtonList, setShortCutButtonList] = useState([]);
  const [focusedInputKey, setFocusedInputKey] = useState(null);
  const [mealDeals, setMealDeals] = useState(null);
  const [drinks, setDrinks] = useState(null);
  const [dressings, setDressings] = useState(null);
  const [mealDealsLabel, setMealDealsLabel] = useState("");
  const [drinksLabel, setDrinksLabel] = useState("");
  const [dressingsLabel, setDressingsLabel] = useState("");
  const [mealDealsActive, setMealDealsActive] = useState(false);
  const [drinksActive, setDrinksActive] = useState(false);
  const [dressingsActive, setDressingsActive] = useState(true);
  const [catList, setCatList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [catIds, setCatIds] = useState([]);
  const [subIds, setSubIds] = useState([]);
  const { category_list } = useSelector((state) => state.categoryList);
  const { submenu_list } = useSelector((state) => state.submenuRedusers);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    result_failed,
    result_successfull,
    result_message,
    loading_post_code,
    short_cut_button_list,
  } = useSelector((state) => state.tabelAreaRedusers);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,

      boxShadow: "none",

      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,

      zIndex: 888888888888999999,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: primaryColor,
    },
    "&:hover label": {
      color: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      color: "black",
      fontSize: "15px",
      height: "45px",
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
    },
  });
  const handleSaveShortCuButton = () => {
    dispatch(
      updateShortCutButtonAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        short_cut_button_list[0] ? short_cut_button_list[0].Id : 0,
        mealDeals ? mealDealsLabel : "",
        mealDeals ? mealDeals.value : 0,
        mealDeals ? (mealDealsActive ? 1 : 0) : 0,
        drinks ? drinksLabel : "",
        drinks ? drinks.value : 0,
        drinks ? (drinksActive ? 1 : 0) : 0,
        dressings ? dressingsLabel : "",
        dressings ? dressings.value : 0,
        dressings ? (dressingsActive ? 1 : 0) : 0
      )
    );
  };
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        // dispatch(
        //   getCategoriesListAction(
        //     login_key,
        //     user_id,
        //     selected_shop[0].ShopId,
        //     0
        //   )
        // );
        // dispatch(
        //   getSubmenuAction(login_key, user_id, selected_shop[0].ShopId, 0)
        // );
        dispatch(
          getShortCutButtonListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId
          )
        );
      }
    }
  }, [selected_shop, category_list, submenu_list]);
  useEffect(() => {
    let cat_list = [];
    let cat_ids = [];
    if (category_list) {
      if (category_list.length > 0) {
        for (let i = 0; i < category_list.length; i++) {
          cat_list.push({
            label: category_list[i].AppTitle,
            value: category_list[i].Id,
          });
          cat_ids.push(category_list[i].Id);
        }
      }
      setCatIds(cat_ids);
      setCatList(cat_list);
    }
  }, [category_list]);

  useEffect(() => {
    let sub_list = [];
    let sub_ids = [];
    if (submenu_list) {
      if (submenu_list.length > 0) {
        for (let i = 0; i < submenu_list.length; i++) {
          if (submenu_list[i].YesNoQ) {
            sub_list.push({
              label: submenu_list[i].AppTitle,
              value: submenu_list[i].Id,
            });
            sub_ids.push(submenu_list[i].Id);
          }
        }
      }
      setSubIds(sub_ids);
      setSubList(sub_list);
    }
  }, [submenu_list]);

  useEffect(() => {
    let category1 = {};
    let category2 = {};
    let submneu1 = {};
    if (short_cut_button_list) {
      if (short_cut_button_list.length > 0) {
        if (short_cut_button_list[0].CategoryNameA) {
          setMealDealsLabel(short_cut_button_list[0].CategoryNameA);
        } else {
          setMealDealsLabel("");
        }
        if (short_cut_button_list[0].CategoryNameB) {
          setDrinksLabel(short_cut_button_list[0].CategoryNameB);
        } else {
          setDrinksLabel("");
        }
        if (short_cut_button_list[0].SubmenuNameA) {
          setDressingsLabel(short_cut_button_list[0].SubmenuNameA);
        } else {
          setDressingsLabel("");
        }

        setMealDealsActive(short_cut_button_list[0].CategoryActiveA);
        setDrinksActive(short_cut_button_list[0].CategoryActiveB);
        setDressingsActive(short_cut_button_list[0].SubmenuActiveA);

        if (short_cut_button_list[0].CategoryIdA) {
          if (catIds.includes(parseInt(short_cut_button_list[0].CategoryIdA))) {
            let index = catIds.indexOf(
              parseInt(short_cut_button_list[0].CategoryIdA)
            );

            category1 = {
              label: catList[index].label,
              value: catList[index].value,
            };
            setMealDeals(category1);
          }
        }

        if (short_cut_button_list[0].CategoryIdB) {
          if (catIds.includes(parseInt(short_cut_button_list[0].CategoryIdB))) {
            let index = catIds.indexOf(
              parseInt(short_cut_button_list[0].CategoryIdB)
            );
            category2 = {
              label: catList[index].label,
              value: catList[index].value,
            };
            setDrinks(category2);
          }
        }
        if (short_cut_button_list[0].SubmenuIdA) {
          if (subIds.includes(parseInt(short_cut_button_list[0].SubmenuIdA))) {
            let index = subIds.indexOf(
              parseInt(short_cut_button_list[0].SubmenuIdA)
            );
            submneu1 = {
              label: subList[index].label,
              value: subList[index].value,
            };
            setDressings(submneu1);
          }
        }
      } else {
        setDressingsLabel("");
        setDrinksLabel("");
        setMealDealsLabel("");
        setMealDeals(null);
        setDressings(null);
        setDrinks(null);
        setMealDealsActive(true);
        setDrinksActive(true);
        setDressingsActive(true);
      }
    } else {
      setDressingsLabel("");
      setDrinksLabel("");
      setMealDealsLabel("");
      setMealDeals(null);
      setDressings(null);
      setDrinks(null);
      setMealDealsActive(true);
      setDrinksActive(true);
      setDressingsActive(true);
    }
  }, [short_cut_button_list]);
  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: window.innerHeight - 220,
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="sm-div2">
            {" "}
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.meal_deals}</h6>
              <Select
                options={catList}
                defaultValue={mealDeals}
                isSearchable={true}
                onChange={(list, item) => {
                  setMealDeals(list);
                  setMealDealsLabel(list.label);
                }}
                value={mealDeals}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.drinks}</h6>
              <Select
                options={catList}
                defaultValue={drinks}
                isSearchable={true}
                onChange={(list, item) => {
                  setDrinks(list);
                  setDrinksLabel(list.label);
                }}
                value={drinks}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="h6"> {lang.dressings}</h6>
              <Select
                options={subList}
                defaultValue={dressings}
                isSearchable={true}
                onChange={(list, item) => {
                  setDressings(list);
                  setDressingsLabel(list.label);
                }}
                value={dressings}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
                isDisabled={subList.length > 0 ? false : true}
              />
            </div>
          </div>
          <div className="sm-div2">
            {" "}
            <Box
              className="driver-container-textfield"
              component="form"
              autoComplete="on"
            >
              <CssTextField
                key={1}
                label={lang.meal_deals_label}
                defaultValue={mealDealsLabel}
                onChange={(e) => setMealDealsLabel(e.target.value)}
                style={{
                  width: "30%",
                  zIndex: 0,
                }}
                // value={mealDealsLabel}
                autoFocus={focusedInputKey == 1 ? true : false}
                onFocus={() => setFocusedInputKey(1)}
              />
              <CssTextField
                key={2}
                label={lang.drinks_label}
                defaultValue={drinksLabel}
                onChange={(e) => setDrinksLabel(e.target.value)}
                style={{
                  width: "30%",
                  zIndex: 0,
                }}
                autoFocus={focusedInputKey == 2 ? true : false}
                onFocus={() => setFocusedInputKey(2)}
              />
              <CssTextField
                key={3}
                label={lang.drewwings_label}
                value={dressingsLabel}
                onChange={(e) => setDressingsLabel(e.target.value)}
                style={{
                  width: "30%",
                  zIndex: 0,
                }}
                autoFocus={focusedInputKey == 3 ? true : false}
                onFocus={() => setFocusedInputKey(3)}
                disabled={true}
              />
            </Box>
          </div>
          <div className="sm-div2">
            <div className="ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mealDealsActive}
                      onChange={() => setMealDealsActive(!mealDealsActive)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={drinksActive}
                      onChange={() => setDrinksActive(!drinksActive)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>
            <div className="ASOP-div1-input-text">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={subList.length > 0 ? dressingsActive : false}
                      onChange={() => setDressingsActive(!dressingsActive)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                      disabled={subList.length > 0 ? false : true}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <SaveMenuButton clickButton={handleSaveShortCuButton} />
      {loading_post_code ? <Loader /> : null}
    </div>
  );
};

export default ShortCuts;
