import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import SaveMenuButton from "../../../../components/SaveMenuButton";
import {
  primaryColor,
  main_nav_back_color,
  main_nav_button_color,
} from "../../../../constants/Colors";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  getWebDeliveryListAction,
  updateWebDeliveryAction,
} from "../../../../redux/settings/Action";
import Loader from "../../../../components/Loader";

const Delivery = forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch();
  const { get_web_delivery_list } = useSelector(
    (state) => state.appSettingsRedusers
  );

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const { delivery_charge_type, delivery_charge_type_id } = useSelector(
    (state) => state.categoryList
  );
  const {
    loading_appSettings,
    result_successfull,
    result_message,
    result_failed,
    web_payment_list,
  } = useSelector((state) => state.appSettingsRedusers);

  const [minOrderValue, setMinOrderValue] = useState("");
  const [minOrderChargeMessage, setMinOrderChargeMessage] = useState("");
  const [spicificAmount, setSpicificAmount] = useState();
  useState("");
  const [printFreeDeliveryMessage, setPrintFreeDeliveryMessage] = useState("");
  useState("");
  const [defaultDeliveryChargeTypeId, setDefaultDeliveryChargeTypeId] =
    useState(null);

  const [
    minOrderAmountToFreeDeliveryCharge,
    setMinOrderAmountToFreeDeliveryCharge,
  ] = useState(0);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleUpdateDeliverySetting = () => {
    if (
      minOrderChargeMessage == "" ||
      printFreeDeliveryMessage == "" ||
      !defaultDeliveryChargeTypeId
    ) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        updateWebDeliveryAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          get_web_delivery_list[0].Id,
          minOrderValue ? parseInt(minOrderValue) : 0,
          minOrderChargeMessage ? minOrderChargeMessage : "",

          minOrderAmountToFreeDeliveryCharge
            ? parseFloat(minOrderAmountToFreeDeliveryCharge)
            : 0
        )
      );
    }
  };
  useEffect(() => {
    if (get_web_delivery_list.length > 0) {
      let defaultDeliveryCharge = [];
      for (let i = 0; i < get_web_delivery_list.length; i++) {
        if (get_web_delivery_list[i].web_DefaultDeliveryChargeTypeId) {
          if (
            delivery_charge_type_id.includes(
              parseInt(get_web_delivery_list[i].web_DefaultDeliveryChargeTypeId)
            )
          ) {
            let index = delivery_charge_type_id.indexOf(
              get_web_delivery_list[i].web_DefaultDeliveryChargeTypeId
            );
            defaultDeliveryCharge = {
              label: delivery_charge_type[index].label,
              value: delivery_charge_type[index].value,
            };
          }
        }
      }
      get_web_delivery_list[0].web_DefaultDeliveryChargeTypeId =
        defaultDeliveryCharge;
      setMinOrderValue(get_web_delivery_list[0].web_MinimumOrderValue);
      setMinOrderChargeMessage(
        get_web_delivery_list[0].web_MinimumOrderChargeMessage
      );
      setSpicificAmount(get_web_delivery_list[0].web_SpicificAmount);
      setPrintFreeDeliveryMessage(
        get_web_delivery_list[0].web_PrintFreeDeliveryMessage
      );
      setDefaultDeliveryChargeTypeId(
        get_web_delivery_list[0].web_DefaultDeliveryChargeTypeId
      );
      setMinOrderAmountToFreeDeliveryCharge(
        get_web_delivery_list[0].web_minimum_free_delivery
      );
    }
  }, [get_web_delivery_list]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getWebDeliveryListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useImperativeHandle(forwardedRef, () => ({
    saveDelivery: () => {
      handleUpdateDeliverySetting();
    },
  }));
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleUpdateDeliverySetting();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    printFreeDeliveryMessage,
    spicificAmount,
    defaultDeliveryChargeTypeId,
    minOrderValue,
    minOrderAmountToFreeDeliveryCharge,
  ]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    } else if (result_failed) {
      if (!get_web_delivery_list) {
        dispatch(setDefaultAction());
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="countaining-div "
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div className="div-3">
            {/* <div className="ASOP-div1-input-text">
              <h6 className="sort-title h6">{lang.spicific_amount}</h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={spicificAmount}
                onChange={(e) =>
                  setSpicificAmount(e.target.value.replace(/[^\d\.,£]+/g, ""))
                }
              />
            </div> */}
            <div className="ASOP-div1-input-text">
              <h6 className="sort-title h6">
                {lang.minimum_order_amount_to_free_delivery_charge}
              </h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={minOrderAmountToFreeDeliveryCharge}
                onChange={(e) =>
                  setMinOrderAmountToFreeDeliveryCharge(
                    e.target.value.replace(/[^\d\.,£]+/g, "")
                  )
                }
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="sort-title h6">{lang.min_order_value}</h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={minOrderValue}
                onChange={(e) =>
                  setMinOrderValue(e.target.value.replace(/\D/g, ""))
                }
              />
            </div>
            <div className="ASOP-div1-input-text">
              <h6 className="sort-title h6">
                * {lang.min_order_charge_message}
              </h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={minOrderChargeMessage}
                onChange={(e) => setMinOrderChargeMessage(e.target.value)}
              />
            </div>
          </div>
          <div className="div-3">
            {/* <div className="ASOP-div1-input-text">
              <h6 className="sort-title h6">
                * {lang.Print_free_delivery_message}
              </h6>
              <input
                type="text"
                className="sort-input input-padding"
                // placeholder={lang.placeholderSort}
                value={printFreeDeliveryMessage}
                onChange={(e) => setPrintFreeDeliveryMessage(e.target.value)}
              />
            </div> */}

            {/* <div className="ASOP-div1-input-text">
              <h6 className="sort-title h6">
                * {lang.default_delivery_charge_type_id}
              </h6>
              <Select
                options={delivery_charge_type}
                defaultValue={defaultDeliveryChargeTypeId}
                isSearchable={false}
                onChange={(list, item) => setDefaultDeliveryChargeTypeId(list)}
                value={defaultDeliveryChargeTypeId}
                isMulti={false}
                styles={customStyles}
                isClearable={false}
              />
            </div> */}
          </div>
        </div>
        <Toaster />
      </div>
      <SaveMenuButton clickButton={handleUpdateDeliverySetting} />
      {loading_appSettings ? <Loader /> : null}
    </div>
  );
});

export default Delivery;
