import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import { delivery_collection_time } from "../pages/settings/WebSetting/Componenets/List";
import {
  primaryColor,
  main_nav_button_color,
  main_nav_back_color,
} from "../constants/Colors";
import {
  updateOpenCloseTimeAction,
  createOpenCloseTimeAction,
  getOpenCloseTimeListAction,
} from "../redux/settings/Action";

import { setDefaultAction } from "../redux/menu/Actions";
const OpenCloseTimeMainList = ({ list, openCloseTimeList, id }) => {
  const dispatch = useDispatch();

  const [mainList, setMainList] = useState([]);
  const [dayOfWeek, setDayOfWeek] = useState([]);
  const [myList, setMyList] = useState([]);
  const [callMailList, setCallMainList] = useState(false);
  const [deliveryStart1List, setDeliveryStart1List] = useState([]);
  const [deliveryEnd1List, setDeliveryEnd1List] = useState([]);
  const [deliveryStart2List, setDeliveryStart2List] = useState([]);
  const [deliveryEnd2List, setDeliveryEnd2List] = useState([]);
  const [deliveryStart3List, setDeliveryStart3List] = useState([]);
  const [deliveryEnd3List, setDeliveryEnd3List] = useState([]);
  const [collectionStart1List, setCollectionStart1List] = useState([]);
  const [collectionEnd1List, setCollectionEnd1List] = useState([]);
  const [collectionStart2List, setCollectionStart2List] = useState([]);
  const [collectionEnd2List, setCollectionEnd2List] = useState([]);
  const [collectionStart3List, setCollectionStart3List] = useState([]);
  const [collectionEnd3List, setCollectionEnd3List] = useState([]);
  const [scrollStatus, setScrollStatus] = useState(false);
  const [indexOfOpenedSublist, setIndexOfOpendSublist] = useState(10);
  const [day, setDay] = useState(0);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,

      // borderBottom: "1px dashed #ccc",
      // color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { open_close_time_list, result_failed } = useSelector(
    (state) => state.appSettingsRedusers
  );

  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const handleSetMainDeliveryStart = (list, item, selected_time_index) => {
    let copy_list = [...mainList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.day_of_week == copy_list[i].day_of_week) {
        for (let index = 0; index < copy_list[i].mainItem.length; index++) {
          if (index == selected_time_index) {
            if (selected_time_index == 0) {
              if (list.label == "None") {
                copy_list[i].mainItem[1] = list;
              }
            } else if (selected_time_index == 2) {
              if (list.label == "None") {
                copy_list[i].mainItem[3] = list;
              }
            }

            copy_list[i].mainItem[index] = list;
          }
        }
      }
    }
    setMainList(copy_list);
  };

  const handleSetDeliveryTime = (list, mainItem, ind, selected_time_index) => {
    let copy_list = [...mainList];
    for (let i = 0; i < copy_list.length; i++) {
      if (mainItem.day_of_week == copy_list[i].day_of_week) {
        for (let index = 0; index < copy_list[i].sublist.length; index++) {
          if (index == ind) {
            for (
              let index2 = 0;
              index2 < copy_list[i].sublist[index].length;
              index2++
            ) {
              if (selected_time_index == index2) {
                if (ind == 0) {
                  if (selected_time_index == 0) {
                    if (list.label == "None") {
                      copy_list[i].sublist[index][1] = list;
                    }
                  } else if (selected_time_index == 2) {
                    if (list.label == "None") {
                      copy_list[i].sublist[index][3] = list;
                    }
                  }
                } else {
                  if (selected_time_index == 0) {
                    if (list.label == "None") {
                      copy_list[i].sublist[index][1] = list;
                    }
                  } else if (selected_time_index == 2) {
                    if (list.label == "None") {
                      copy_list[i].sublist[index][3] = list;
                    }
                  }
                }
                copy_list[i].sublist[index][index2] = list;
                setMainList(copy_list);
                return;
              }
            }
          }
        }
      }
    }
  };

  const handleClickeDownIcons = (id) => {
    let copy_list = [...mainList];
    for (let index = 0; index < copy_list.length; index++) {
      if (id == copy_list[index].day_of_week) {
        copy_list[index].showSubList = false;
      }
    }
    setMainList(copy_list);
  };
  const handleClickeRightIcons = (id) => {
    let copy_list = [...mainList];
    for (let index = 0; index < copy_list.length; index++) {
      if (id == copy_list[index].day_of_week) {
        copy_list[index].showSubList = true;
        // if (id == 6) {
        //   setScrollStatus(true);
        // } else {
        //   setScrollStatus(false);
        // }
      }
    }
    setMainList(copy_list);
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: primaryColor,
      "&:hover": {
        backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: primaryColor,
    },
  }));
  const handleCollectionSwith = (item) => {
    const copy_list = [...mainList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.day_of_week == copy_list[i].day_of_week) {
        if (copy_list[i].open_collection == 1) {
          copy_list[i].open_collection = 0;
          dispatch(
            updateOpenCloseTimeAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              item.id,
              item.day_of_week,

              item.mainItem[0].label != "None"
                ? item.mainItem[0].label.includes("after midnight")
                  ? `${
                      item.mainItem[0].label.split(" ")[
                        item.mainItem[0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[0].label}:00`
                : "00:00:00",

              item.mainItem[1].label != "None"
                ? item.mainItem[1].label.includes("after midnight")
                  ? `${
                      item.mainItem[1].label.split(" ")[
                        item.mainItem[1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[1].label}:00`
                : "00:00:00",

              item.sublist[0][0].label != "None"
                ? item.sublist[0][0].label.includes("after midnight")
                  ? `${
                      item.sublist[0][0].label.split(" ")[
                        item.sublist[0][0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][0].label}:00`
                : "00:00:00",

              item.sublist[0][1].label != "None"
                ? item.sublist[0][1].label.includes("after midnight")
                  ? `${
                      item.sublist[0][1].label.split(" ")[
                        item.sublist[0][1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][1].label}:00`
                : "00:00:00",

              item.sublist[1][0].label != "None"
                ? item.sublist[1][0].label.includes("after midnight")
                  ? `${
                      item.sublist[1][0].label.split(" ")[
                        item.sublist[1][0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][0].label}:00`
                : "00:00:00",

              item.sublist[1][1].label != "None"
                ? item.sublist[1][1].label.includes("after midnight")
                  ? `${
                      item.sublist[1][1].label.split(" ")[
                        item.sublist[1][1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][1].label}:00`
                : "00:00:00",

              item.mainItem[2].label != "None"
                ? item.mainItem[2].label.includes("after midnight")
                  ? `${
                      item.mainItem[2].label.split(" ")[
                        item.mainItem[2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[2].label}:00`
                : "00:00:00",

              item.mainItem[3].label != "None"
                ? item.mainItem[3].label.includes("after midnight")
                  ? `${
                      item.mainItem[3].label.split(" ")[
                        item.mainItem[3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[3].label}:00`
                : "00:00:00",

              item.sublist[0][2].label != "None"
                ? item.sublist[0][2].label.includes("after midnight")
                  ? `${
                      item.sublist[0][2].label.split(" ")[
                        item.sublist[0][2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][2].label}:00`
                : "00:00:00",

              item.sublist[0][3].label != "None"
                ? item.sublist[0][3].label.includes("after midnight")
                  ? `${
                      item.sublist[0][3].label.split(" ")[
                        item.sublist[0][3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][3].label}:00`
                : "00:00:00",

              item.sublist[1][2].label != "None"
                ? item.sublist[1][2].label.includes("after midnight")
                  ? `${
                      item.sublist[1][2].label.split(" ")[
                        item.sublist[1][2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][2].label}:00`
                : "00:00:00",

              item.sublist[1][3].label != "None"
                ? item.sublist[1][3].label.includes("after midnight")
                  ? `${
                      item.sublist[1][3].label.split(" ")[
                        item.sublist[1][3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][3].label}:00`
                : "00:00:00",

              item.open_delivery ? 1 : 0,
              item.open_collection ? 1 : 0,
              item.open_delivery_2 ? 1 : 0,
              item.open_collection_2 ? 1 : 0,
              item.open_delivery_3 ? 1 : 0,
              item.open_collection_3 ? 1 : 0,
              0
            )
          );
        } else {
          copy_list[i].open_collection = 1;
          if (dayOfWeek.includes(parseInt(item.day_of_week))) {
            dispatch(
              updateOpenCloseTimeAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                item.id,
                item.day_of_week,

                item.mainItem[0].label != "None"
                  ? item.mainItem[0].label.includes("after midnight")
                    ? `${
                        item.mainItem[0].label.split(" ")[
                          item.mainItem[0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[0].label}:00`
                  : "00:00:00",

                item.mainItem[1].label != "None"
                  ? item.mainItem[1].label.includes("after midnight")
                    ? `${
                        item.mainItem[1].label.split(" ")[
                          item.mainItem[1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[1].label}:00`
                  : "00:00:00",

                item.sublist[0][0].label != "None"
                  ? item.sublist[0][0].label.includes("after midnight")
                    ? `${
                        item.sublist[0][0].label.split(" ")[
                          item.sublist[0][0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][0].label}:00`
                  : "00:00:00",

                item.sublist[0][1].label != "None"
                  ? item.sublist[0][1].label.includes("after midnight")
                    ? `${
                        item.sublist[0][1].label.split(" ")[
                          item.sublist[0][1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][1].label}:00`
                  : "00:00:00",

                item.sublist[1][0].label != "None"
                  ? item.sublist[1][0].label.includes("after midnight")
                    ? `${
                        item.sublist[1][0].label.split(" ")[
                          item.sublist[1][0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][0].label}:00`
                  : "00:00:00",

                item.sublist[1][1].label != "None"
                  ? item.sublist[1][1].label.includes("after midnight")
                    ? `${
                        item.sublist[1][1].label.split(" ")[
                          item.sublist[1][1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][1].label}:00`
                  : "00:00:00",

                item.mainItem[2].label != "None"
                  ? item.mainItem[2].label.includes("after midnight")
                    ? `${
                        item.mainItem[2].label.split(" ")[
                          item.mainItem[2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[2].label}:00`
                  : "00:00:00",

                item.mainItem[3].label != "None"
                  ? item.mainItem[3].label.includes("after midnight")
                    ? `${
                        item.mainItem[3].label.split(" ")[
                          item.mainItem[3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[3].label}:00`
                  : "00:00:00",

                item.sublist[0][2].label != "None"
                  ? item.sublist[0][2].label.includes("after midnight")
                    ? `${
                        item.sublist[0][2].label.split(" ")[
                          item.sublist[0][2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][2].label}:00`
                  : "00:00:00",

                item.sublist[0][3].label != "None"
                  ? item.sublist[0][3].label.includes("after midnight")
                    ? `${
                        item.sublist[0][3].label.split(" ")[
                          item.sublist[0][3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][3].label}:00`
                  : "00:00:00",

                item.sublist[1][2].label != "None"
                  ? item.sublist[1][2].label.includes("after midnight")
                    ? `${
                        item.sublist[1][2].label.split(" ")[
                          item.sublist[1][2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][2].label}:00`
                  : "00:00:00",

                item.sublist[1][3].label != "None"
                  ? item.sublist[1][3].label.includes("after midnight")
                    ? `${
                        item.sublist[1][3].label.split(" ")[
                          item.sublist[1][3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][3].label}:00`
                  : "00:00:00",

                item.open_delivery ? 1 : 0,
                item.open_collection ? 1 : 0,
                item.open_delivery_2 ? 1 : 0,
                item.open_collection_2 ? 1 : 0,
                item.open_delivery_3 ? 1 : 0,
                item.open_collection_3 ? 1 : 0,
                0
              )
            );
          } else {
            dispatch(
              createOpenCloseTimeAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                item.day_of_week,

                item.mainItem[0].label != "None"
                  ? item.mainItem[0].label.includes("after midnight")
                    ? `${
                        item.mainItem[0].label.split(" ")[
                          item.mainItem[0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[0].label}:00`
                  : "00:00:00",

                item.mainItem[1].label != "None"
                  ? item.mainItem[1].label.includes("after midnight")
                    ? `${
                        item.mainItem[1].label.split(" ")[
                          item.mainItem[1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[1].label}:00`
                  : "00:00:00",

                item.sublist[0][0].label != "None"
                  ? item.sublist[0][0].label.includes("after midnight")
                    ? `${
                        item.sublist[0][0].label.split(" ")[
                          item.sublist[0][0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][0].label}:00`
                  : "00:00:00",

                item.sublist[0][1].label != "None"
                  ? item.sublist[0][1].label.includes("after midnight")
                    ? `${
                        item.sublist[0][1].label.split(" ")[
                          item.sublist[0][1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][1].label}:00`
                  : "00:00:00",

                item.sublist[1][0].label != "None"
                  ? item.sublist[1][0].label.includes("after midnight")
                    ? `${
                        item.sublist[1][0].label.split(" ")[
                          item.sublist[1][0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][0].label}:00`
                  : "00:00:00",

                item.sublist[1][1].label != "None"
                  ? item.sublist[1][1].label.includes("after midnight")
                    ? `${
                        item.sublist[1][1].label.split(" ")[
                          item.sublist[1][1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][1].label}:00`
                  : "00:00:00",

                item.mainItem[2].label != "None"
                  ? item.mainItem[2].label.includes("after midnight")
                    ? `${
                        item.mainItem[2].label.split(" ")[
                          item.mainItem[2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[2].label}:00`
                  : "00:00:00",

                item.mainItem[3].label != "None"
                  ? item.mainItem[3].label.includes("after midnight")
                    ? `${
                        item.mainItem[3].label.split(" ")[
                          item.mainItem[3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[3].label}:00`
                  : "00:00:00",

                item.sublist[0][2].label != "None"
                  ? item.sublist[0][2].label.includes("after midnight")
                    ? `${
                        item.sublist[0][2].label.split(" ")[
                          item.sublist[0][2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][2].label}:00`
                  : "00:00:00",

                item.sublist[0][3].label != "None"
                  ? item.sublist[0][3].label.includes("after midnight")
                    ? `${
                        item.sublist[0][3].label.split(" ")[
                          item.sublist[0][3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][3].label}:00`
                  : "00:00:00",
                item.sublist[1][2].label != "None"
                  ? item.sublist[1][2].label.includes("after midnight")
                    ? `${
                        item.sublist[1][2].label.split(" ")[
                          item.sublist[1][2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][2].label}:00`
                  : "00:00:00",

                item.sublist[1][3].label != "None"
                  ? item.sublist[1][3].label.includes("after midnight")
                    ? `${
                        item.sublist[1][3].label.split(" ")[
                          item.sublist[1][3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][3].label}:00`
                  : "00:00:00",

                item.open_delivery ? 1 : 0,
                item.open_collection ? 1 : 0,
                item.open_delivery_2 ? 1 : 0,
                item.open_collection_2 ? 1 : 0,
                item.open_delivery_3 ? 1 : 0,
                item.open_collection_3 ? 1 : 0
              )
            );
          }
        }
      }
    }

    setMainList(copy_list);
  };
  const handleDeliverySwith = (item) => {
    const copy_list = [...mainList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.day_of_week == copy_list[i].day_of_week) {
        if (copy_list[i].open_delivery == 1) {
          copy_list[i].open_delivery = 0;
          dispatch(
            updateOpenCloseTimeAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              item.id,
              item.day_of_week,

              item.mainItem[0].label != "None"
                ? item.mainItem[0].label.includes("after midnight")
                  ? `${
                      item.mainItem[0].label.split(" ")[
                        item.mainItem[0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[0].label}:00`
                : "00:00:00",

              item.mainItem[1].label != "None"
                ? item.mainItem[1].label.includes("after midnight")
                  ? `${
                      item.mainItem[1].label.split(" ")[
                        item.mainItem[1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[1].label}:00`
                : "00:00:00",

              item.sublist[0][0].label != "None"
                ? item.sublist[0][0].label.includes("after midnight")
                  ? `${
                      item.sublist[0][0].label.split(" ")[
                        item.sublist[0][0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][0].label}:00`
                : "00:00:00",

              item.sublist[0][1].label != "None"
                ? item.sublist[0][1].label.includes("after midnight")
                  ? `${
                      item.sublist[0][1].label.split(" ")[
                        item.sublist[0][1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][1].label}:00`
                : "00:00:00",

              item.sublist[1][0].label != "None"
                ? item.sublist[1][0].label.includes("after midnight")
                  ? `${
                      item.sublist[1][0].label.split(" ")[
                        item.sublist[1][0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][0].label}:00`
                : "00:00:00",

              item.sublist[1][1].label != "None"
                ? item.sublist[1][1].label.includes("after midnight")
                  ? `${
                      item.sublist[1][1].label.split(" ")[
                        item.sublist[1][1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][1].label}:00`
                : "00:00:00",

              item.mainItem[2].label != "None"
                ? item.mainItem[2].label.includes("after midnight")
                  ? `${
                      item.mainItem[2].label.split(" ")[
                        item.mainItem[2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[2].label}:00`
                : "00:00:00",

              item.mainItem[3].label != "None"
                ? item.mainItem[3].label.includes("after midnight")
                  ? `${
                      item.mainItem[3].label.split(" ")[
                        item.mainItem[3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[3].label}:00`
                : "00:00:00",

              item.sublist[0][2].label != "None"
                ? item.sublist[0][2].label.includes("after midnight")
                  ? `${
                      item.sublist[0][2].label.split(" ")[
                        item.sublist[0][2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][2].label}:00`
                : "00:00:00",

              item.sublist[0][3].label != "None"
                ? item.sublist[0][3].label.includes("after midnight")
                  ? `${
                      item.sublist[0][3].label.split(" ")[
                        item.sublist[0][3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][3].label}:00`
                : "00:00:00",

              item.sublist[1][2].label != "None"
                ? item.sublist[1][2].label.includes("after midnight")
                  ? `${
                      item.sublist[1][2].label.split(" ")[
                        item.sublist[1][2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][2].label}:00`
                : "00:00:00",

              item.sublist[1][3].label != "None"
                ? item.sublist[1][3].label.includes("after midnight")
                  ? `${
                      item.sublist[1][3].label.split(" ")[
                        item.sublist[1][3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][3].label}:00`
                : "00:00:00",

              item.open_delivery ? 1 : 0,
              item.open_collection ? 1 : 0,
              item.open_delivery_2 ? 1 : 0,
              item.open_collection_2 ? 1 : 0,
              item.open_delivery_3 ? 1 : 0,
              item.open_collection_3 ? 1 : 0,
              0
            )
          );
        } else {
          copy_list[i].open_delivery = 1;
          if (dayOfWeek.includes(parseInt(item.day_of_week))) {
            dispatch(
              updateOpenCloseTimeAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                item.id,
                item.day_of_week,

                item.mainItem[0].label != "None"
                  ? item.mainItem[0].label.includes("after midnight")
                    ? `${
                        item.mainItem[0].label.split(" ")[
                          item.mainItem[0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[0].label}:00`
                  : "00:00:00",

                item.mainItem[1].label != "None"
                  ? item.mainItem[1].label.includes("after midnight")
                    ? `${
                        item.mainItem[1].label.split(" ")[
                          item.mainItem[1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[1].label}:00`
                  : "00:00:00",

                item.sublist[0][0].label != "None"
                  ? item.sublist[0][0].label.includes("after midnight")
                    ? `${
                        item.sublist[0][0].label.split(" ")[
                          item.sublist[0][0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][0].label}:00`
                  : "00:00:00",

                item.sublist[0][1].label != "None"
                  ? item.sublist[0][1].label.includes("after midnight")
                    ? `${
                        item.sublist[0][1].label.split(" ")[
                          item.sublist[0][1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][1].label}:00`
                  : "00:00:00",

                item.sublist[1][0].label != "None"
                  ? item.sublist[1][0].label.includes("after midnight")
                    ? `${
                        item.sublist[1][0].label.split(" ")[
                          item.sublist[1][0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][0].label}:00`
                  : "00:00:00",

                item.sublist[1][1].label != "None"
                  ? item.sublist[1][1].label.includes("after midnight")
                    ? `${
                        item.sublist[1][1].label.split(" ")[
                          item.sublist[1][1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][1].label}:00`
                  : "00:00:00",

                item.mainItem[2].label != "None"
                  ? item.mainItem[2].label.includes("after midnight")
                    ? `${
                        item.mainItem[2].label.split(" ")[
                          item.mainItem[2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[2].label}:00`
                  : "00:00:00",

                item.mainItem[3].label != "None"
                  ? item.mainItem[3].label.includes("after midnight")
                    ? `${
                        item.mainItem[3].label.split(" ")[
                          item.mainItem[3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[3].label}:00`
                  : "00:00:00",

                item.sublist[0][2].label != "None"
                  ? item.sublist[0][2].label.includes("after midnight")
                    ? `${
                        item.sublist[0][2].label.split(" ")[
                          item.sublist[0][2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][2].label}:00`
                  : "00:00:00",

                item.sublist[0][3].label != "None"
                  ? item.sublist[0][3].label.includes("after midnight")
                    ? `${
                        item.sublist[0][3].label.split(" ")[
                          item.sublist[0][3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][3].label}:00`
                  : "00:00:00",

                item.sublist[1][2].label != "None"
                  ? item.sublist[1][2].label.includes("after midnight")
                    ? `${
                        item.sublist[1][2].label.split(" ")[
                          item.sublist[1][2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][2].label}:00`
                  : "00:00:00",

                item.sublist[1][3].label != "None"
                  ? item.sublist[1][3].label.includes("after midnight")
                    ? `${
                        item.sublist[1][3].label.split(" ")[
                          item.sublist[1][3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][3].label}:00`
                  : "00:00:00",

                item.open_delivery ? 1 : 0,
                item.open_collection ? 1 : 0,
                item.open_delivery_2 ? 1 : 0,
                item.open_collection_2 ? 1 : 0,
                item.open_delivery_3 ? 1 : 0,
                item.open_collection_3 ? 1 : 0,
                0
              )
            );
          } else {
            dispatch(
              createOpenCloseTimeAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                item.day_of_week,

                item.mainItem[0].label != "None"
                  ? item.mainItem[0].label.includes("after midnight")
                    ? `${
                        item.mainItem[0].label.split(" ")[
                          item.mainItem[0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[0].label}:00`
                  : "00:00:00",

                item.mainItem[1].label != "None"
                  ? item.mainItem[1].label.includes("after midnight")
                    ? `${
                        item.mainItem[1].label.split(" ")[
                          item.mainItem[1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[1].label}:00`
                  : "00:00:00",

                item.sublist[0][0].label != "None"
                  ? item.sublist[0][0].label.includes("after midnight")
                    ? `${
                        item.sublist[0][0].label.split(" ")[
                          item.sublist[0][0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][0].label}:00`
                  : "00:00:00",

                item.sublist[0][1].label != "None"
                  ? item.sublist[0][1].label.includes("after midnight")
                    ? `${
                        item.sublist[0][1].label.split(" ")[
                          item.sublist[0][1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][1].label}:00`
                  : "00:00:00",

                item.sublist[1][0].label != "None"
                  ? item.sublist[1][0].label.includes("after midnight")
                    ? `${
                        item.sublist[1][0].label.split(" ")[
                          item.sublist[1][0].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][0].label}:00`
                  : "00:00:00",

                item.sublist[1][1].label != "None"
                  ? item.sublist[1][1].label.includes("after midnight")
                    ? `${
                        item.sublist[1][1].label.split(" ")[
                          item.sublist[1][1].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][1].label}:00`
                  : "00:00:00",

                item.mainItem[2].label != "None"
                  ? item.mainItem[2].label.includes("after midnight")
                    ? `${
                        item.mainItem[2].label.split(" ")[
                          item.mainItem[2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[2].label}:00`
                  : "00:00:00",

                item.mainItem[3].label != "None"
                  ? item.mainItem[3].label.includes("after midnight")
                    ? `${
                        item.mainItem[3].label.split(" ")[
                          item.mainItem[3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.mainItem[3].label}:00`
                  : "00:00:00",

                item.sublist[0][2].label != "None"
                  ? item.sublist[0][2].label.includes("after midnight")
                    ? `${
                        item.sublist[0][2].label.split(" ")[
                          item.sublist[0][2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][2].label}:00`
                  : "00:00:00",

                item.sublist[0][3].label != "None"
                  ? item.sublist[0][3].label.includes("after midnight")
                    ? `${
                        item.sublist[0][3].label.split(" ")[
                          item.sublist[0][3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[0][3].label}:00`
                  : "00:00:00",
                item.sublist[1][2].label != "None"
                  ? item.sublist[1][2].label.includes("after midnight")
                    ? `${
                        item.sublist[1][2].label.split(" ")[
                          item.sublist[1][2].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][2].label}:00`
                  : "00:00:00",

                item.sublist[1][3].label != "None"
                  ? item.sublist[1][3].label.includes("after midnight")
                    ? `${
                        item.sublist[1][3].label.split(" ")[
                          item.sublist[1][3].label.split(" ").length - 1
                        ]
                      }:00`
                    : `${item.sublist[1][3].label}:00`
                  : "00:00:00",

                item.open_delivery ? 1 : 0,
                item.open_collection ? 1 : 0,
                item.open_delivery_2 ? 1 : 0,
                item.open_collection_2 ? 1 : 0,
                item.open_delivery_3 ? 1 : 0,
                item.open_collection_3 ? 1 : 0
              )
            );
          }
        }
      }
    }

    setMainList(copy_list);
  };
  const handleDeliverySwith1 = (item, index) => {
    const copy_list = [...mainList];

    for (let i = 0; i < copy_list.length; i++) {
      if (item.day_of_week == copy_list[i].day_of_week) {
        if (index == 1) {
          if (item.open_collection_2 == 1) {
            item.open_collection_2 = 0;
          } else {
            item.open_collection_2 = 1;
          }
        } else if (index == 2) {
          if (item.open_delivery_2 == 1) {
            item.open_delivery_2 = 0;
          } else {
            item.open_delivery_2 = 1;
          }
        } else if (index == 3) {
          if (item.open_collection_3 == 1) {
            item.open_collection_3 = 0;
          } else {
            item.open_collection_3 = 1;
          }
        } else {
          if (item.open_delivery_3 == 1) {
            item.open_delivery_3 = 0;
          } else {
            item.open_delivery_3 = 1;
          }
        }
      }

      dispatch(
        updateOpenCloseTimeAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          item.id,
          item.day_of_week,

          item.mainItem[0].label != "None"
            ? item.mainItem[0].label.includes("after midnight")
              ? `${
                  item.mainItem[0].label.split(" ")[
                    item.mainItem[0].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.mainItem[0].label}:00`
            : "00:00:00",

          item.mainItem[1].label != "None"
            ? item.mainItem[1].label.includes("after midnight")
              ? `${
                  item.mainItem[1].label.split(" ")[
                    item.mainItem[1].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.mainItem[1].label}:00`
            : "00:00:00",

          item.sublist[0][0].label != "None"
            ? item.sublist[0][0].label.includes("after midnight")
              ? `${
                  item.sublist[0][0].label.split(" ")[
                    item.sublist[0][0].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.sublist[0][0].label}:00`
            : "00:00:00",

          item.sublist[0][1].label != "None"
            ? item.sublist[0][1].label.includes("after midnight")
              ? `${
                  item.sublist[0][1].label.split(" ")[
                    item.sublist[0][1].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.sublist[0][1].label}:00`
            : "00:00:00",

          item.sublist[1][0].label != "None"
            ? item.sublist[1][0].label.includes("after midnight")
              ? `${
                  item.sublist[1][0].label.split(" ")[
                    item.sublist[1][0].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.sublist[1][0].label}:00`
            : "00:00:00",

          item.sublist[1][1].label != "None"
            ? item.sublist[1][1].label.includes("after midnight")
              ? `${
                  item.sublist[1][1].label.split(" ")[
                    item.sublist[1][1].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.sublist[1][1].label}:00`
            : "00:00:00",

          item.mainItem[2].label != "None"
            ? item.mainItem[2].label.includes("after midnight")
              ? `${
                  item.mainItem[2].label.split(" ")[
                    item.mainItem[2].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.mainItem[2].label}:00`
            : "00:00:00",

          item.mainItem[3].label != "None"
            ? item.mainItem[3].label.includes("after midnight")
              ? `${
                  item.mainItem[3].label.split(" ")[
                    item.mainItem[3].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.mainItem[3].label}:00`
            : "00:00:00",

          item.sublist[0][2].label != "None"
            ? item.sublist[0][2].label.includes("after midnight")
              ? `${
                  item.sublist[0][2].label.split(" ")[
                    item.sublist[0][2].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.sublist[0][2].label}:00`
            : "00:00:00",

          item.sublist[0][3].label != "None"
            ? item.sublist[0][3].label.includes("after midnight")
              ? `${
                  item.sublist[0][3].label.split(" ")[
                    item.sublist[0][3].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.sublist[0][3].label}:00`
            : "00:00:00",

          item.sublist[1][2].label != "None"
            ? item.sublist[1][2].label.includes("after midnight")
              ? `${
                  item.sublist[1][2].label.split(" ")[
                    item.sublist[1][2].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.sublist[1][2].label}:00`
            : "00:00:00",

          item.sublist[1][3].label != "None"
            ? item.sublist[1][3].label.includes("after midnight")
              ? `${
                  item.sublist[1][3].label.split(" ")[
                    item.sublist[1][3].label.split(" ").length - 1
                  ]
                }:00`
              : `${item.sublist[1][3].label}:00`
            : "00:00:00",

          item.open_delivery ? 1 : 0,
          item.open_collection ? 1 : 0,
          item.open_delivery_2 ? 1 : 0,
          item.open_collection_2 ? 1 : 0,
          item.open_delivery_3 ? 1 : 0,
          item.open_collection_3 ? 1 : 0,
          0
        )
      );
      setMainList(copy_list);
    }
  };

  const handleUpdateTimes = (item) => {
    setCallMainList(!callMailList);
    const copy_list = [...mainList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.day_of_week == copy_list[i].day_of_week) {
        if (dayOfWeek.includes(parseInt(item.day_of_week))) {
          dispatch(
            updateOpenCloseTimeAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              item.id,
              item.day_of_week,

              item.mainItem[0].label != "None"
                ? item.mainItem[0].label.includes("after midnight")
                  ? `${
                      item.mainItem[0].label.split(" ")[
                        item.mainItem[0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[0].label}:00`
                : "00:00:00",

              item.mainItem[1].label != "None"
                ? item.mainItem[1].label.includes("after midnight")
                  ? `${
                      item.mainItem[1].label.split(" ")[
                        item.mainItem[1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[1].label}:00`
                : "00:00:00",

              item.sublist[0][0].label != "None"
                ? item.sublist[0][0].label.includes("after midnight")
                  ? `${
                      item.sublist[0][0].label.split(" ")[
                        item.sublist[0][0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][0].label}:00`
                : "00:00:00",

              item.sublist[0][1].label != "None"
                ? item.sublist[0][1].label.includes("after midnight")
                  ? `${
                      item.sublist[0][1].label.split(" ")[
                        item.sublist[0][1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][1].label}:00`
                : "00:00:00",

              item.sublist[1][0].label != "None"
                ? item.sublist[1][0].label.includes("after midnight")
                  ? `${
                      item.sublist[1][0].label.split(" ")[
                        item.sublist[1][0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][0].label}:00`
                : "00:00:00",

              item.sublist[1][1].label != "None"
                ? item.sublist[1][1].label.includes("after midnight")
                  ? `${
                      item.sublist[1][1].label.split(" ")[
                        item.sublist[1][1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][1].label}:00`
                : "00:00:00",

              item.mainItem[2].label != "None"
                ? item.mainItem[2].label.includes("after midnight")
                  ? `${
                      item.mainItem[2].label.split(" ")[
                        item.mainItem[2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[2].label}:00`
                : "00:00:00",

              item.mainItem[3].label != "None"
                ? item.mainItem[3].label.includes("after midnight")
                  ? `${
                      item.mainItem[3].label.split(" ")[
                        item.mainItem[3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[3].label}:00`
                : "00:00:00",

              item.sublist[0][2].label != "None"
                ? item.sublist[0][2].label.includes("after midnight")
                  ? `${
                      item.sublist[0][2].label.split(" ")[
                        item.sublist[0][2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][2].label}:00`
                : "00:00:00",

              item.sublist[0][3].label != "None"
                ? item.sublist[0][3].label.includes("after midnight")
                  ? `${
                      item.sublist[0][3].label.split(" ")[
                        item.sublist[0][3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][3].label}:00`
                : "00:00:00",

              item.sublist[1][2].label != "None"
                ? item.sublist[1][2].label.includes("after midnight")
                  ? `${
                      item.sublist[1][2].label.split(" ")[
                        item.sublist[1][2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][2].label}:00`
                : "00:00:00",

              item.sublist[1][3].label != "None"
                ? item.sublist[1][3].label.includes("after midnight")
                  ? `${
                      item.sublist[1][3].label.split(" ")[
                        item.sublist[1][3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][3].label}:00`
                : "00:00:00",

              item.mainItem[0].label != "None" &&
                item.mainItem[1].label != "None"
                ? item.open_delivery
                  ? 1
                  : 0
                : 0,

              item.mainItem[2].label != "None" &&
                item.mainItem[3].label != "None"
                ? item.open_collection
                  ? 1
                  : 0
                : 0,
              item.sublist[0][0].label != "None" &&
                item.sublist[0][1].label != "None"
                ? item.open_delivery_2
                  ? 1
                  : 0
                : 0,
              item.sublist[0][2].label != "None" &&
                item.sublist[0][3].label != "None"
                ? item.open_collection_2
                  ? 1
                  : 0
                : 0,
              item.sublist[1][0].label != "None" &&
                item.sublist[1][1].label != "None"
                ? item.open_delivery_3
                  ? 1
                  : 0
                : 0,
              item.sublist[1][2].label != "None" &&
                item.sublist[1][3].label != "None"
                ? item.open_collection_3
                  ? 1
                  : 0
                : 0,
              0
            )
          );
        } else {
          dispatch(
            createOpenCloseTimeAction(
              login_key,
              user_id,
              selected_shop[0].ShopId,
              item.day_of_week,

              item.mainItem[0].label != "None"
                ? item.mainItem[0].label.includes("after midnight")
                  ? `${
                      item.mainItem[0].label.split(" ")[
                        item.mainItem[0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[0].label}:00`
                : "00:00:00",

              item.mainItem[1].label != "None"
                ? item.mainItem[1].label.includes("after midnight")
                  ? `${
                      item.mainItem[1].label.split(" ")[
                        item.mainItem[1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[1].label}:00`
                : "00:00:00",

              item.sublist[0][0].label != "None"
                ? item.sublist[0][0].label.includes("after midnight")
                  ? `${
                      item.sublist[0][0].label.split(" ")[
                        item.sublist[0][0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][0].label}:00`
                : "00:00:00",

              item.sublist[0][1].label != "None"
                ? item.sublist[0][1].label.includes("after midnight")
                  ? `${
                      item.sublist[0][1].label.split(" ")[
                        item.sublist[0][1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][1].label}:00`
                : "00:00:00",

              item.sublist[1][0].label != "None"
                ? item.sublist[1][0].label.includes("after midnight")
                  ? `${
                      item.sublist[1][0].label.split(" ")[
                        item.sublist[1][0].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][0].label}:00`
                : "00:00:00",

              item.sublist[1][1].label != "None"
                ? item.sublist[1][1].label.includes("after midnight")
                  ? `${
                      item.sublist[1][1].label.split(" ")[
                        item.sublist[1][1].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][1].label}:00`
                : "00:00:00",

              item.mainItem[2].label != "None"
                ? item.mainItem[2].label.includes("after midnight")
                  ? `${
                      item.mainItem[2].label.split(" ")[
                        item.mainItem[2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[2].label}:00`
                : "00:00:00",

              item.mainItem[3].label != "None"
                ? item.mainItem[3].label.includes("after midnight")
                  ? `${
                      item.mainItem[3].label.split(" ")[
                        item.mainItem[3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.mainItem[3].label}:00`
                : "00:00:00",

              item.sublist[0][2].label != "None"
                ? item.sublist[0][2].label.includes("after midnight")
                  ? `${
                      item.sublist[0][2].label.split(" ")[
                        item.sublist[0][2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][2].label}:00`
                : "00:00:00",

              item.sublist[0][3].label != "None"
                ? item.sublist[0][3].label.includes("after midnight")
                  ? `${
                      item.sublist[0][3].label.split(" ")[
                        item.sublist[0][3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[0][3].label}:00`
                : "00:00:00",

              item.sublist[1][2].label != "None"
                ? item.sublist[1][2].label.includes("after midnight")
                  ? `${
                      item.sublist[1][2].label.split(" ")[
                        item.sublist[1][2].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][2].label}:00`
                : "00:00:00",

              item.sublist[1][3].label != "None"
                ? item.sublist[1][3].label.includes("after midnight")
                  ? `${
                      item.sublist[1][3].label.split(" ")[
                        item.sublist[1][3].label.split(" ").length - 1
                      ]
                    }:00`
                  : `${item.sublist[1][3].label}:00`
                : "00:00:00",

              item.mainItem[0].label != "None" &&
                item.mainItem[1].label != "None"
                ? item.open_delivery
                  ? 1
                  : 0
                : 0,

              item.mainItem[2].label != "None" &&
                item.mainItem[3].label != "None"
                ? item.open_collection
                  ? 1
                  : 0
                : 0,
              item.sublist[0][0].label != "None" &&
                item.sublist[0][1].label != "None"
                ? item.open_delivery_2
                  ? 1
                  : 0
                : 0,
              item.sublist[0][2].label != "None" &&
                item.sublist[0][3].label != "None"
                ? item.open_collection_2
                  ? 1
                  : 0
                : 0,
              item.sublist[1][0].label != "None" &&
                item.sublist[1][1].label != "None"
                ? item.open_delivery_3
                  ? 1
                  : 0
                : 0,
              item.sublist[1][2].label != "None" &&
                item.sublist[1][3].label != "None"
                ? item.open_collection_3
                  ? 1
                  : 0
                : 0
            )
          );
        }
      }
    }

    setMainList(copy_list);
  };
  useEffect(() => {
    const copyList = [...list];

    const copy_mainList = [...open_close_time_list];
    let days_of_week = [];
    if (copy_mainList) {
      if (copy_mainList.length > 0) {
        setMyList(open_close_time_list);
        for (let i = 0; i < list.length; i++) {
          for (let index = 0; index < copy_mainList.length; index++) {
            days_of_week.push(copy_mainList[index].day_of_week);
            if (copyList[i].day_of_week == copy_mainList[index].day_of_week) {
              copyList[i] = copy_mainList[index];
            }
          }
        }
      }
    }
    setDayOfWeek(days_of_week);
    setMainList(copyList);
  }, [open_close_time_list]);

  useEffect(() => {
    if (open_close_time_list) {
      if (open_close_time_list.length > 0) {
        if (result_failed) {
          setMainList(myList);
        }
      }
    }
  }, [result_failed]);
  useEffect(() => {
    if (delivery_collection_time) {
      if (delivery_collection_time.length > 0) {
        setDeliveryStart1List(delivery_collection_time);
        setDeliveryEnd1List(delivery_collection_time);
        setDeliveryStart2List(delivery_collection_time);
        setDeliveryEnd2List(delivery_collection_time);
        setDeliveryStart3List(delivery_collection_time);
        setDeliveryEnd3List(delivery_collection_time);
        setCollectionStart1List(delivery_collection_time);
        setCollectionEnd1List(delivery_collection_time);
        setCollectionStart2List(delivery_collection_time);
        setCollectionEnd2List(delivery_collection_time);
        setCollectionStart3List(delivery_collection_time);
        setCollectionEnd3List(delivery_collection_time);
      }
    }
  }, [delivery_collection_time]);
  const handleFocusOnDropDown = (list, item, selected_time_index) => {
    let copy_list = [...mainList];
    let copy_delivery_collection_time = [...delivery_collection_time];
    let copy_deliveryEnd2 = [];

    let delivery_collection_labels = [];
    for (let i = 0; i < copy_delivery_collection_time.length; i++) {
      delivery_collection_labels.push(
        copy_delivery_collection_time[i].label.split(" ")[
          copy_delivery_collection_time[i].label.split(" ").length - 1
        ]
      );
    }
    for (let i = 0; i < copy_list.length; i++) {
      if (item.day_of_week == copy_list[i].day_of_week) {
        for (let index = 0; index < copy_list[i].mainItem.length; index++) {
          if (index == selected_time_index) {
            if (index == 0) {
              setDeliveryStart1List(copy_delivery_collection_time);
              setDeliveryEnd1List(copy_delivery_collection_time);
            } else if (index == 1) {
              if (copy_list[i].mainItem[0].label != "None") {
                if (
                  delivery_collection_labels.includes(
                    String(
                      copy_list[i].mainItem[0].label.split(" ")[
                        copy_list[i].mainItem[0].label.split(" ").length - 1
                      ]
                    )
                  )
                ) {
                  let my_index = delivery_collection_labels.indexOf(
                    String(
                      copy_list[i].mainItem[0].label.split(" ")[
                        copy_list[i].mainItem[0].label.split(" ").length - 1
                      ]
                    )
                  );
                  for (
                    let index2 = 0;
                    index2 < copy_delivery_collection_time.length;
                    index2++
                  ) {
                    if (my_index >= index2) {
                      copy_deliveryEnd2.push({
                        ...copy_delivery_collection_time[index2],
                        isDisabled: true,
                      });
                    } else {
                      copy_deliveryEnd2.push(
                        copy_delivery_collection_time[index2]
                      );
                    }
                  }
                  let arrey = [];
                  for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                    if (!copy_deliveryEnd2[i].isDisabled) {
                      arrey.push(copy_deliveryEnd2[i]);
                    }
                  }
                  setDeliveryEnd1List(arrey);
                }
              } else {
                for (
                  let index3 = 0;
                  index3 < copy_delivery_collection_time.length;
                  index3++
                ) {
                  copy_deliveryEnd2.push({
                    ...copy_delivery_collection_time[index3],
                    isDisabled: index3 == 0 ? false : true,
                  });
                }
                setDeliveryEnd1List(copy_deliveryEnd2);
              }
            } else if (index == 2) {
              setCollectionStart1List(copy_delivery_collection_time);
              setCollectionEnd1List(copy_delivery_collection_time);
            } else {
              if (copy_list[i].mainItem[2].label != "None") {
                if (
                  delivery_collection_labels.includes(
                    String(
                      copy_list[i].mainItem[2].label.split(" ")[
                        copy_list[i].mainItem[2].label.split(" ").length - 1
                      ]
                    )
                  )
                ) {
                  let my_index = delivery_collection_labels.indexOf(
                    String(
                      copy_list[i].mainItem[2].label.split(" ")[
                        copy_list[i].mainItem[2].label.split(" ").length - 1
                      ]
                    )
                  );
                  for (
                    let index2 = 0;
                    index2 < copy_delivery_collection_time.length;
                    index2++
                  ) {
                    if (my_index >= index2) {
                      copy_deliveryEnd2.push({
                        ...copy_delivery_collection_time[index2],
                        isDisabled: true,
                      });
                    } else {
                      copy_deliveryEnd2.push(
                        copy_delivery_collection_time[index2]
                      );
                    }
                  }
                  let array = [];
                  for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                    if (!copy_deliveryEnd2[i].isDisabled) {
                      array.push(copy_deliveryEnd2[i]);
                    }
                  }
                  setCollectionEnd1List(array);
                }
              } else {
                for (
                  let index3 = 0;
                  index3 < copy_delivery_collection_time.length;
                  index3++
                ) {
                  copy_deliveryEnd2.push({
                    ...copy_delivery_collection_time[index3],
                    isDisabled: index3 == 0 ? false : true,
                  });
                }
                setCollectionEnd1List(copy_deliveryEnd2);
              }
            }
          }
        }
      }
    }
    setMainList(copy_list);
  };
  const handleFocusOnSubListdropDown = (
    list,
    mainItem,
    ind,
    selected_time_index
  ) => {
    let copy_list = [...mainList];
    let copy_delivery_collection_time = [...delivery_collection_time];
    let copy_deliveryEnd2 = [];
    let delivery_collection_labels = [];
    for (let i = 0; i < copy_delivery_collection_time.length; i++) {
      delivery_collection_labels.push(
        copy_delivery_collection_time[i].label.split(" ")[
          copy_delivery_collection_time[i].label.split(" ").length - 1
        ]
      );
    }

    for (let i = 0; i < copy_list.length; i++) {
      if (mainItem.day_of_week == copy_list[i].day_of_week) {
        for (let index = 0; index < copy_list[i].sublist.length; index++) {
          if (index == ind) {
            if (ind == 0) {
              for (
                let index2 = 0;
                index2 < copy_list[i].sublist[index].length;
                index2++
              ) {
                if (selected_time_index == index2) {
                  if (selected_time_index == 0) {
                    if (copy_list[i].mainItem[1].label != "None") {
                      if (
                        delivery_collection_labels.includes(
                          String(
                            copy_list[i].mainItem[1].label.split(" ")[
                              copy_list[i].mainItem[1].label.split(" ").length -
                                1
                            ]
                          )
                        )
                      ) {
                        let myIndex = delivery_collection_labels.indexOf(
                          String(
                            copy_list[i].mainItem[1].label.split(" ")[
                              copy_list[i].mainItem[1].label.split(" ").length -
                                1
                            ]
                          )
                        );

                        for (
                          let index3 = 0;
                          index3 < copy_delivery_collection_time.length;
                          index3++
                        ) {
                          if (myIndex >= index3) {
                            copy_deliveryEnd2.push({
                              ...copy_delivery_collection_time[index3],
                              isDisabled: index3 == 0 ? false : true,
                            });
                          } else {
                            copy_deliveryEnd2.push(
                              copy_delivery_collection_time[index3]
                            );
                          }
                          let array = [];
                          for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                            if (!copy_deliveryEnd2[i].isDisabled) {
                              array.push(copy_deliveryEnd2[i]);
                            }
                          }
                          setDeliveryStart2List(array);
                        }
                      }
                    } else {
                      setDeliveryStart2List(copy_delivery_collection_time);
                      setDeliveryEnd2List(copy_delivery_collection_time);
                    }
                  } else if (selected_time_index == 1) {
                    if (copy_list[i].sublist[0][0].label != "None") {
                      if (
                        delivery_collection_labels.includes(
                          String(
                            copy_list[i].sublist[0][0].label.split(" ")[
                              copy_list[i].sublist[0][0].label.split(" ")
                                .length - 1
                            ]
                          )
                        )
                      ) {
                        let myIndex = delivery_collection_labels.indexOf(
                          String(
                            copy_list[i].sublist[0][0].label.split(" ")[
                              copy_list[i].sublist[0][0].label.split(" ")
                                .length - 1
                            ]
                          )
                        );
                        for (
                          let index3 = 0;
                          index3 < copy_delivery_collection_time.length;
                          index3++
                        ) {
                          if (myIndex >= index3) {
                            copy_deliveryEnd2.push({
                              ...copy_delivery_collection_time[index3],
                              isDisabled: true,
                            });
                          } else {
                            copy_deliveryEnd2.push(
                              copy_delivery_collection_time[index3]
                            );
                          }
                        }
                        let array = [];
                        for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                          if (!copy_deliveryEnd2[i].isDisabled) {
                            array.push(copy_deliveryEnd2[i]);
                          }
                        }

                        setDeliveryEnd2List(array);
                      }
                    } else {
                      for (
                        let index3 = 0;
                        index3 < copy_delivery_collection_time.length;
                        index3++
                      ) {
                        copy_deliveryEnd2.push({
                          ...copy_delivery_collection_time[index3],
                          isDisabled: index3 == 0 ? false : true,
                        });
                      }
                      setDeliveryEnd2List(copy_deliveryEnd2);
                    }
                  } else if (selected_time_index == 2) {
                    if (copy_list[i].mainItem[3].label != "None") {
                      if (
                        delivery_collection_labels.includes(
                          String(
                            copy_list[i].mainItem[3].label.split(" ")[
                              copy_list[i].mainItem[3].label.split(" ").length -
                                1
                            ]
                          )
                        )
                      ) {
                        let myIndex = delivery_collection_labels.indexOf(
                          String(
                            copy_list[i].mainItem[3].label.split(" ")[
                              copy_list[i].mainItem[3].label.split(" ").length -
                                1
                            ]
                          )
                        );
                        for (
                          let index3 = 0;
                          index3 < copy_delivery_collection_time.length;
                          index3++
                        ) {
                          if (myIndex >= index3) {
                            copy_deliveryEnd2.push({
                              ...copy_delivery_collection_time[index3],
                              isDisabled: index3 == 0 ? false : true,
                            });
                          } else {
                            copy_deliveryEnd2.push(
                              copy_delivery_collection_time[index3]
                            );
                          }
                          let array = [];
                          for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                            if (!copy_deliveryEnd2[i].isDisabled) {
                              array.push(copy_deliveryEnd2[i]);
                            }
                          }

                          setCollectionStart2List(array);
                        }
                      }
                    } else {
                      setCollectionStart2List(copy_delivery_collection_time);
                      setCollectionEnd2List(copy_delivery_collection_time);
                    }
                  } else {
                    if (copy_list[i].sublist[0][2].label != "None") {
                      if (
                        delivery_collection_labels.includes(
                          String(
                            copy_list[i].sublist[0][2].label.split(" ")[
                              copy_list[i].sublist[0][2].label.split(" ")
                                .length - 1
                            ]
                          )
                        )
                      ) {
                        let myIndex = delivery_collection_labels.indexOf(
                          String(
                            copy_list[i].sublist[0][2].label.split(" ")[
                              copy_list[i].sublist[0][2].label.split(" ")
                                .length - 1
                            ]
                          )
                        );
                        for (
                          let index3 = 0;
                          index3 < copy_delivery_collection_time.length;
                          index3++
                        ) {
                          if (myIndex >= index3) {
                            copy_deliveryEnd2.push({
                              ...copy_delivery_collection_time[index3],
                              isDisabled: true,
                            });
                          } else {
                            copy_deliveryEnd2.push(
                              copy_delivery_collection_time[index3]
                            );
                          }
                        }

                        let array = [];
                        for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                          if (!copy_deliveryEnd2[i].isDisabled) {
                            array.push(copy_deliveryEnd2[i]);
                          }
                        }

                        setCollectionEnd2List(array);
                      }
                    } else {
                      for (
                        let index3 = 0;
                        index3 < copy_delivery_collection_time.length;
                        index3++
                      ) {
                        copy_deliveryEnd2.push({
                          ...copy_delivery_collection_time[index3],
                          isDisabled: index3 == 0 ? false : true,
                        });
                      }
                      setCollectionEnd2List(copy_deliveryEnd2);
                    }
                  }
                }
              }
            } else {
              for (
                let index2 = 0;
                index2 < copy_list[i].sublist[index].length;
                index2++
              ) {
                if (selected_time_index == index2) {
                  if (selected_time_index == 0) {
                    if (copy_list[i].sublist[0][1].label != "None") {
                      if (
                        delivery_collection_labels.includes(
                          String(
                            copy_list[i].sublist[0][1].label.split(" ")[
                              copy_list[i].sublist[0][1].label.split(" ")
                                .length - 1
                            ]
                          )
                        )
                      ) {
                        let myIndex = delivery_collection_labels.indexOf(
                          String(
                            copy_list[i].sublist[0][1].label.split(" ")[
                              copy_list[i].sublist[0][1].label.split(" ")
                                .length - 1
                            ]
                          )
                        );
                        for (
                          let index3 = 0;
                          index3 < copy_delivery_collection_time.length;
                          index3++
                        ) {
                          if (myIndex >= index3) {
                            copy_deliveryEnd2.push({
                              ...copy_delivery_collection_time[index3],
                              isDisabled: index3 == 0 ? false : true,
                            });
                          } else {
                            copy_deliveryEnd2.push(
                              copy_delivery_collection_time[index3]
                            );
                          }
                          let array = [];
                          for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                            if (!copy_deliveryEnd2[i].isDisabled) {
                              array.push(copy_deliveryEnd2[i]);
                            }
                          }

                          setDeliveryStart3List(array);
                        }
                      }
                    } else {
                      setDeliveryStart3List(copy_delivery_collection_time);
                      setDeliveryEnd3List(copy_delivery_collection_time);
                    }
                  } else if (selected_time_index == 1) {
                    if (copy_list[i].sublist[1][0].label != "None") {
                      if (
                        delivery_collection_labels.includes(
                          String(
                            copy_list[i].sublist[1][0].label.split(" ")[
                              copy_list[i].sublist[1][0].label.split(" ")
                                .length - 1
                            ]
                          )
                        )
                      ) {
                        let myIndex = delivery_collection_labels.indexOf(
                          String(
                            copy_list[i].sublist[1][0].label.split(" ")[
                              copy_list[i].sublist[1][0].label.split(" ")
                                .length - 1
                            ]
                          )
                        );
                        for (
                          let index3 = 0;
                          index3 < copy_delivery_collection_time.length;
                          index3++
                        ) {
                          if (myIndex >= index3) {
                            copy_deliveryEnd2.push({
                              ...copy_delivery_collection_time[index3],
                              isDisabled: true,
                            });
                          } else {
                            copy_deliveryEnd2.push(
                              copy_delivery_collection_time[index3]
                            );
                          }
                        }
                        let array = [];
                        for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                          if (!copy_deliveryEnd2[i].isDisabled) {
                            array.push(copy_deliveryEnd2[i]);
                          }
                        }

                        setDeliveryEnd3List(array);
                      }
                    } else {
                      for (
                        let index3 = 0;
                        index3 < copy_delivery_collection_time.length;
                        index3++
                      ) {
                        copy_deliveryEnd2.push({
                          ...copy_delivery_collection_time[index3],
                          isDisabled: index3 == 0 ? false : true,
                        });
                      }
                      setDeliveryEnd3List(copy_deliveryEnd2);
                    }
                  } else if (selected_time_index == 2) {
                    if (copy_list[i].sublist[0][3].label != "None") {
                      if (
                        delivery_collection_labels.includes(
                          String(
                            copy_list[i].sublist[0][3].label.split(" ")[
                              copy_list[i].sublist[0][3].label.split(" ")
                                .length - 1
                            ]
                          )
                        )
                      ) {
                        let myIndex = delivery_collection_labels.indexOf(
                          String(
                            copy_list[i].sublist[0][3].label.split(" ")[
                              copy_list[i].sublist[0][3].label.split(" ")
                                .length - 1
                            ]
                          )
                        );
                        for (
                          let index3 = 0;
                          index3 < copy_delivery_collection_time.length;
                          index3++
                        ) {
                          if (myIndex >= index3) {
                            copy_deliveryEnd2.push({
                              ...copy_delivery_collection_time[index3],
                              isDisabled: index3 == 0 ? false : true,
                            });
                          } else {
                            copy_deliveryEnd2.push(
                              copy_delivery_collection_time[index3]
                            );
                          }
                          let array = [];
                          for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                            if (!copy_deliveryEnd2[i].isDisabled) {
                              array.push(copy_deliveryEnd2[i]);
                            }
                          }

                          setCollectionStart3List(array);
                        }
                      }
                    } else {
                      setCollectionStart3List(copy_delivery_collection_time);
                      setCollectionEnd3List(copy_delivery_collection_time);
                    }
                  } else {
                    if (copy_list[i].sublist[1][2].label != "None") {
                      if (
                        delivery_collection_labels.includes(
                          String(
                            copy_list[i].sublist[1][2].label.split(" ")[
                              copy_list[i].sublist[1][2].label.split(" ")
                                .length - 1
                            ]
                          )
                        )
                      ) {
                        let myIndex = delivery_collection_labels.indexOf(
                          String(
                            copy_list[i].sublist[1][2].label.split(" ")[
                              copy_list[i].sublist[1][2].label.split(" ")
                                .length - 1
                            ]
                          )
                        );
                        for (
                          let index3 = 0;
                          index3 < copy_delivery_collection_time.length;
                          index3++
                        ) {
                          if (myIndex >= index3) {
                            copy_deliveryEnd2.push({
                              ...copy_delivery_collection_time[index3],
                              isDisabled: true,
                            });
                          } else {
                            copy_deliveryEnd2.push(
                              copy_delivery_collection_time[index3]
                            );
                          }
                        }
                        let array = [];
                        for (let i = 0; i < copy_deliveryEnd2.length; i++) {
                          if (!copy_deliveryEnd2[i].isDisabled) {
                            array.push(copy_deliveryEnd2[i]);
                          }
                        }

                        setCollectionEnd3List(array);
                      }
                    } else {
                      for (
                        let index3 = 0;
                        index3 < copy_delivery_collection_time.length;
                        index3++
                      ) {
                        copy_deliveryEnd2.push({
                          ...copy_delivery_collection_time[index3],
                          isDisabled: index3 == 0 ? false : true,
                        });
                      }
                      setCollectionEnd3List(copy_deliveryEnd2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };
  const handleOpenMenu = (item, sub_index) => {
    if (item.day_of_week == 6) {
      setScrollStatus(true);
      if (sub_index == 0) {
        setIndexOfOpendSublist(0);
      } else if (sub_index == 1) {
        setIndexOfOpendSublist(1);
      } else {
        setIndexOfOpendSublist(10);
      }
    } else {
      setScrollStatus(false);
    }
  };
  const handleCloseMenu = () => {
    setScrollStatus(false);
  };
  useEffect(() => {
    if (scrollStatus) {
      document.getElementById(id).scroll({
        top: document.getElementById(id).scrollHeight,
        behavior: "smooth",
      });
    }
    // else {
    //   document.getElementById(id).scroll({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // }
  }, [scrollStatus]);
  const formSchema = Yup.object().shape({
    password: Yup.string().required("Password is mendatory"),
    confirmPwd: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  function onSubmit(data) {
    // console.log(JSON.stringify(data, null, 4));
    return false;
  }
  useEffect(() => {
    let date = new Date();
    let day1 = date.getDay();

    if (day1 == 0) {
      setDay(6);
    } else if (day1 == 1) {
      setDay(0);
    } else if (day1 == 2) {
      setDay(1);
    } else if (day1 == 3) {
      setDay(2);
    } else if (day1 == 4) {
      setDay(3);
    } else if (day1 == 5) {
      setDay(4);
    } else if (day1 == 6) {
      setDay(5);
    }
  }, [mainList]);

  return (
    <div className="open-close-parent-div">
      <div>
        {mainList.map((item, index) => (
          <div
            style={{
              backgroundColor: index % 2 == 0 ? "#f2f4f6" : "white",
              marginBottom:
                item.day_of_week == 6
                  ? scrollStatus
                    ? item.showSubList
                      ? indexOfOpenedSublist == 10
                        ? "110px"
                        : indexOfOpenedSublist == 0
                        ? "170px"
                        : "230px"
                      : "230px"
                    : null
                  : null,
            }}
          >
            <div
              className="open-close-container-div"
              style={{
                backgroundColor:
                  day == item.day_of_week
                    ? "#ffd4aa"
                    : index % 2 == 0
                    ? "#f2f4f6"
                    : "white",
              }}
            >
              <div className="open-close-container-day-of-week">
                {item.showSubList ? (
                  <i
                    class="fa fa-caret-down"
                    aria-hidden="true"
                    onClick={() => handleClickeDownIcons(item.day_of_week)}
                  ></i>
                ) : (
                  <i
                    class="fa fa-caret-right"
                    aria-hidden="true"
                    onClick={() => handleClickeRightIcons(item.day_of_week)}
                  ></i>
                )}
                <p>
                  {item.day_of_week == 0
                    ? "Monday"
                    : item.day_of_week == 1
                    ? "Tuesday"
                    : item.day_of_week == 2
                    ? "Wednesday"
                    : item.day_of_week == 3
                    ? "Thursday"
                    : item.day_of_week == 4
                    ? "Friday"
                    : item.day_of_week == 5
                    ? "Saturday"
                    : "Sunday"}
                </p>
              </div>
              <div className="open-close-general-width">
                <Select
                  // {...register("password")}
                  // className={`form-control open-close-time-input ${
                  //   errors.Select ? "is-invalid" : ""
                  // }`}
                  // name="password"
                  options={deliveryStart1List}
                  // className="open-close-time-input"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={item.mainItem[0]}
                  value={item.mainItem[0]}
                  onChange={(list, item1) =>
                    handleSetMainDeliveryStart(list, item, 0)
                  }
                  onFocus={() => handleFocusOnDropDown(list, item, 0)}
                  onMenuClose={() => handleCloseMenu(item)}
                  onMenuOpen={() => handleOpenMenu(item, 10)}
                  styles={customStyles}
                />
              </div>
              <div className="open-close-general-width">
                <Select
                  options={deliveryEnd1List}
                  className="open-close-time-input"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={item.mainItem[1]}
                  value={item.mainItem[1]}
                  onChange={(list) => handleSetMainDeliveryStart(list, item, 1)}
                  onFocus={() => handleFocusOnDropDown(list, item, 1)}
                  onMenuClose={() => handleCloseMenu(item)}
                  onMenuOpen={() => handleOpenMenu(item, 10)}
                  styles={customStyles}
                />
              </div>
              <div className="open-close-general-width">
                <Select
                  options={collectionStart1List}
                  className="open-close-time-input"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(list) => handleSetMainDeliveryStart(list, item, 2)}
                  value={item.mainItem[2]}
                  defaultValue={item.mainItem[2]}
                  onFocus={() => handleFocusOnDropDown(list, item, 2)}
                  onMenuClose={() => handleCloseMenu(item)}
                  onMenuOpen={() => handleOpenMenu(item, 10)}
                  styles={customStyles}
                />
              </div>
              <div className="open-close-general-width">
                <Select
                  options={collectionEnd1List}
                  className="open-close-time-input"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={item.mainItem[3]}
                  defaultValue={item.mainItem[3]}
                  onChange={(list) => handleSetMainDeliveryStart(list, item, 3)}
                  onFocus={() => handleFocusOnDropDown(list, item, 3)}
                  onMenuClose={() => handleCloseMenu(item)}
                  onMenuOpen={() => handleOpenMenu(item, 10)}
                  styles={customStyles}
                />
              </div>
              <div className="open-close-general-style">
                <button
                  onClick={() => handleUpdateTimes(item)}
                  // disabled={
                  //   (item.mainItem[2].label != "None" &&
                  //     item.mainItem[3].label != "None") ||
                  //   (item.mainItem[0].label != "None" &&
                  //     item.mainItem[1].label != "None")
                  //     ? false
                  //     : (item.sublist[0][2].label != "None" &&
                  //         item.sublist[0][3].label != "None") ||
                  //       (item.sublist[0][0].label != "None" &&
                  //         item.sublist[0][1].label != "None")
                  //     ? false
                  //     : (item.sublist[1][2].label != "None" &&
                  //         item.sublist[1][3].label != "None") ||
                  //       (item.sublist[1][0].label != "None" &&
                  //         item.sublist[1][1].label != "None")
                  //     ? false
                  //     : true
                  // }
                >
                  {lang.save}
                </button>
              </div>
              <div className="open-close-general-style">
                <GreenSwitch
                  disabled={
                    item.mainItem[2].label != "None" &&
                    item.mainItem[3].label != "None"
                      ? false
                      : true
                  }
                  checked={item.open_collection == 1 ? true : false}
                  onChange={() => handleCollectionSwith(item)}
                />
              </div>
              <div className="open-close-general-style">
                <GreenSwitch
                  disabled={
                    item.mainItem[0].label != "None" &&
                    item.mainItem[1].label != "None"
                      ? false
                      : true
                  }
                  checked={item.open_delivery == 1 ? true : false}
                  onChange={() => handleDeliverySwith(item)}
                />
              </div>
            </div>
            {item.showSubList ? (
              <div>
                <div>
                  <hr className="open-close-hr" />
                  <div
                    className="open-close-container-div"
                    style={{
                      backgroundColor:
                        day == item.day_of_week
                          ? "#ffd4aa"
                          : index % 2 == 0
                          ? "#f2f4f6"
                          : "white",
                    }}
                  >
                    <p className="open-close-general-style">
                      {item.sublist[0][4].title}
                    </p>

                    <div className="open-close-general-width">
                      <Select
                        options={deliveryStart2List}
                        className="open-close-time-input"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={item.sublist[0][0]}
                        defaultValue={item.sublist[0][0]}
                        onChange={(list) =>
                          handleSetDeliveryTime(list, item, 0, 0)
                        }
                        onFocus={() =>
                          handleFocusOnSubListdropDown(list, item, 0, 0)
                        }
                        onMenuClose={() => handleCloseMenu(item)}
                        onMenuOpen={() => handleOpenMenu(item, 0)}
                        styles={customStyles}
                      />
                    </div>
                    <div className="open-close-general-width">
                      <Select
                        options={deliveryEnd2List}
                        className="open-close-time-input"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={item.sublist[0][1]}
                        defaultValue={item.sublist[0][1]}
                        onChange={(list) =>
                          handleSetDeliveryTime(list, item, 0, 1)
                        }
                        onFocus={() =>
                          handleFocusOnSubListdropDown(list, item, 0, 1)
                        }
                        onMenuClose={() => handleCloseMenu(item)}
                        onMenuOpen={() => handleOpenMenu(item, 0)}
                        styles={customStyles}
                      />
                    </div>
                    <div className="open-close-general-width">
                      <Select
                        options={collectionStart2List}
                        className="open-close-time-input"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={item.sublist[0][2]}
                        defaultValue={item.sublist[0][2]}
                        onChange={(list) =>
                          handleSetDeliveryTime(list, item, 0, 2)
                        }
                        onFocus={() =>
                          handleFocusOnSubListdropDown(list, item, 0, 2)
                        }
                        onMenuClose={() => handleCloseMenu(item)}
                        onMenuOpen={() => handleOpenMenu(item, 0)}
                        styles={customStyles}
                      />
                    </div>
                    <div className="open-close-general-width">
                      <Select
                        options={collectionEnd2List}
                        className="open-close-time-input"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={item.sublist[0][3]}
                        defaultValue={item.sublist[0][3]}
                        onChange={(list) =>
                          handleSetDeliveryTime(list, item, 0, 3)
                        }
                        onFocus={() =>
                          handleFocusOnSubListdropDown(list, item, 0, 3)
                        }
                        onMenuClose={() => handleCloseMenu(item)}
                        onMenuOpen={() => handleOpenMenu(item, 0)}
                        styles={customStyles}
                      />
                    </div>
                    <p className="open-close-general-style"></p>
                    <div className="open-close-general-style">
                      <GreenSwitch
                        disabled={
                          item.sublist[0][2].label != "None" &&
                          item.sublist[0][3].label != "None"
                            ? false
                            : true
                        }
                        checked={item.open_collection_2 == 1 ? true : false}
                        onChange={() => handleDeliverySwith1(item, 1)}
                      />
                    </div>
                    <div className="open-close-general-style">
                      <GreenSwitch
                        disabled={
                          item.sublist[0][0].label != "None" &&
                          item.sublist[0][1].label != "None"
                            ? false
                            : true
                        }
                        checked={item.open_delivery_2 == 1 ? true : false}
                        onChange={() => handleDeliverySwith1(item, 2)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="open-close-hr" />
                  <div
                    className="open-close-container-div"
                    style={{
                      backgroundColor:
                        day == item.day_of_week
                          ? "#ffd4aa"
                          : index % 2 == 0
                          ? "#f2f4f6"
                          : "white",
                    }}
                  >
                    <p className="open-close-general-style">
                      {item.sublist[1][4].title}
                    </p>

                    <div className="open-close-general-width">
                      <Select
                        options={deliveryStart3List}
                        className="open-close-time-input"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={item.sublist[1][0]}
                        defaultValue={item.sublist[1][0]}
                        onChange={(list) =>
                          handleSetDeliveryTime(list, item, 1, 0)
                        }
                        onFocus={() =>
                          handleFocusOnSubListdropDown(list, item, 1, 0)
                        }
                        onMenuClose={() => handleCloseMenu(item)}
                        onMenuOpen={() => handleOpenMenu(item, 1)}
                        styles={customStyles}
                      />
                    </div>
                    <div className="open-close-general-width">
                      <Select
                        options={deliveryEnd3List}
                        className="open-close-time-input"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={item.sublist[1][1]}
                        defaultValue={item.sublist[1][1]}
                        onChange={(list) =>
                          handleSetDeliveryTime(list, item, 1, 1)
                        }
                        onFocus={() =>
                          handleFocusOnSubListdropDown(list, item, 1, 1)
                        }
                        onMenuClose={() => handleCloseMenu(item)}
                        onMenuOpen={() => handleOpenMenu(item, 1)}
                        styles={customStyles}
                      />
                    </div>
                    <div className="open-close-general-width">
                      <Select
                        options={collectionStart3List}
                        className="open-close-time-input"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={item.sublist[1][2]}
                        defaultValue={item.sublist[1][2]}
                        onChange={(list) =>
                          handleSetDeliveryTime(list, item, 1, 2)
                        }
                        onFocus={() =>
                          handleFocusOnSubListdropDown(list, item, 1, 2)
                        }
                        onMenuClose={() => handleCloseMenu(item)}
                        onMenuOpen={() => handleOpenMenu(item, 1)}
                        styles={customStyles}
                      />
                    </div>
                    <div className="open-close-general-width">
                      <Select
                        options={collectionEnd3List}
                        className="open-close-time-input"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        value={item.sublist[1][3]}
                        defaultValue={item.sublist[1][3]}
                        onChange={(list) =>
                          handleSetDeliveryTime(list, item, 1, 3)
                        }
                        onFocus={() =>
                          handleFocusOnSubListdropDown(list, item, 1, 3)
                        }
                        onMenuClose={() => handleCloseMenu(item)}
                        onMenuOpen={() => handleOpenMenu(item, 1)}
                        styles={customStyles}
                      />
                    </div>
                    <p className="open-close-general-style"></p>
                    <div className="open-close-general-style">
                      <GreenSwitch
                        disabled={
                          item.sublist[1][2].label != "None" &&
                          item.sublist[1][3].label != "None"
                            ? false
                            : true
                        }
                        checked={item.open_collection_3 == 1 ? true : false}
                        onChange={() => handleDeliverySwith1(item, 3)}
                      />
                    </div>
                    <div className="open-close-general-style">
                      <GreenSwitch
                        disabled={
                          item.sublist[1][0].label != "None" &&
                          item.sublist[1][1].label != "None"
                            ? false
                            : true
                        }
                        checked={item.open_delivery_3 == 1 ? true : false}
                        onChange={() => handleDeliverySwith1(item, 4)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      {/* <div className="container mt-5">
        <h2>React Confirm Password Validation Example</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Password</label>
            <input
              name="password"
              type="password"
              {...register("password")}
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              name="confirmPwd"
              type="password"
              {...register("confirmPwd")}
              className={`form-control ${
                errors.confirmPwd ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.confirmPwd?.message}</div>
          </div>
          <div className="mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div> */}
    </div>
  );
};

export default OpenCloseTimeMainList;
