import { ExposureTwoTone } from "@material-ui/icons";
import * as actionType from "../ActionTypes";

export const createSubmenuAction = (
  login_key,
  user_id,
  shopId,
  title,
  description,
  Include_Dressing,
  ids,
  import_category,
  import_from_popup
) => {
  return {
    type: actionType.CREATE_SUBMENU,
    payload: {
      login_key,
      user_id,
      shopId,
      title,
      description,
      Include_Dressing,
      ids,
      import_category,
      import_from_popup,
    },
  };
};
export const createSubmenuSuccessfullActiion = (data) => {
  return {
    type: actionType.CREATE_SUBMENU_SUCCESSFULL,
    payload: data,
  };
};
export const createSubmenuFailedAction = (data) => {
  return {
    type: actionType.CREATE_SUBMENU_FAILED,
    payload: data,
  };
};
export const getSubmenuAction = (login_key, user_id, shopId, product_id) => {
  return {
    type: actionType.GET_SUBMENU,
    payload: { login_key, user_id, shopId, product_id },
  };
};
export const getSubmenuSuccessfullAction = (data) => {
  return {
    type: actionType.GET_SUBMENU_SUCCESSFULL,
    payload: data,
  };
};
export const getSubmenuFailedAction = (data) => {
  return {
    type: actionType.GET_SUBMENU_FAILED,
    payload: data,
  };
};
export const selectedSubmenuAction = (data) => {
  return {
    type: actionType.SELECTED_SUBMENU,
    payload: data,
  };
};
export const deleteSubmenuaction = (login_key, user_id, shopId, submenuId) => {
  return {
    type: actionType.DELETE_SUBMENU,
    payload: { login_key, user_id, shopId, submenuId },
  };
};
export const deleteSubmenuSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_SUBMENU_SUCCESSFULL,
    payload: data,
  };
};
export const deleteSubmenuFailedAction = (data) => {
  return {
    type: actionType.DELETE_SUBMENU_FAILED,
    payload: data,
  };
};
export const updateSubmenuAction = (
  login_key,
  user_id,
  id,
  shopId,
  app_title,
  app_description,
  web_title,
  web_description,
  kiosk_title,
  kiosk_description,
  secound_language,
  min_select,
  max_select,
  is_override_price,
  extra_q,
  yes_no_q,
  join_before,
  name_first,
  short_name,
  app_vissible,
  web_visible,
  kiosk_visible,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  just_title_and_price,
  number_of_items_column,
  number_of_items_row,
  items_font_size,
  fixed
) => {
  return {
    type: actionType.UPDATE_SUBMENU,
    payload: {
      login_key,
      user_id,
      id,
      shopId,
      app_title,
      app_description,
      web_title,
      web_description,
      kiosk_title,
      kiosk_description,
      secound_language,
      min_select,
      max_select,
      is_override_price,
      extra_q,
      yes_no_q,
      join_before,
      name_first,
      short_name,
      app_vissible,
      web_visible,
      kiosk_visible,
      app_sort_id,
      web_sort_id,
      kiosk_sort_id,
      just_title_and_price,
      number_of_items_column,
      number_of_items_row,
      items_font_size,
      fixed,
    },
  };
};
export const updateSubmenuSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SUBMENU_SUCCESSFULL,
    payload: data,
  };
};
export const updateSubmenuFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SUBMENU_FAILED,
    payload: data,
  };
};
export const createSubmenuItemAction = (
  login_key,
  user_id,
  shopId,
  submenuId,
  app_title,
  app_description,
  amount
) => {
  return {
    type: actionType.CREATE_SUBMENU_ITEM,
    payload: {
      login_key,
      user_id,
      shopId,
      submenuId,
      app_title,
      app_description,
      amount,
    },
  };
};
export const createSubmenuItemSuccessfullAction = (data) => {
  return {
    type: actionType.CREATE_SUBMENU_ITEM_SUCCESSFULL,
    payload: data,
  };
};
export const createSubmenuItemFailedAction = (data) => {
  return {
    type: actionType.CREATE_SUBMENU_ITEM_FAILED,
    payload: data,
  };
};
export const getSubmenuItemAction = (login_key, user_id, shopId, submenuId) => {
  return {
    type: actionType.GET_SUBMENU_ITEM,
    payload: { login_key, user_id, shopId, submenuId },
  };
};
export const getSubmenuItemSuccessfullAction = (data) => {
  return {
    type: actionType.GET_SUBMENU_ITEM_SUCCESSFULL,
    payload: data,
  };
};
export const getSubmenuItemFailedAction = (data) => {
  return {
    type: actionType.GET_SUBMENU_ITEM_FAILED,
    payload: data,
  };
};
export const selectedSubmenuItemAction = (data) => {
  return {
    type: actionType.SELECTED_SUBMENU_ITEM,
    payload: data,
  };
};
export const deleteSubmenuItemAction = (
  login_key,
  user_id,
  shopId,
  id,
  submenuId
) => {
  return {
    type: actionType.DELETE_SUBMENU_ITEM,
    payload: { login_key, user_id, shopId, id, submenuId },
  };
};
export const deleteSubmenuItemSuccessfullAction = (data) => {
  return {
    type: actionType.DELETE_SUBMENU_ITEM_SUCCESSFULL,
    payload: data,
  };
};
export const deleteSubmenuItemFailedAction = (data) => {
  return {
    type: actionType.DELETE_SUBMENU_ITEM_FAILED,
    payload: data,
  };
};
export const updateSubmenuItemEditAction = (
  formData2,
  login_key,
  user_id,
  id,
  shopId,
  submenuId,
  price_level_collec_take,
  price_level_eat_in,
  price_level_main,
  app_title,
  app_description,
  web_title,
  web_description,
  kiosk_title,
  kiosk_description,
  secound_language,
  amount_main,
  amount_collection,
  amount_takeaway,
  amount_eat_in,
  app_vissible,
  web_visible,
  kiosk_visible,
  app_bg_color,
  app_title_font_size,
  app_title_font_family,
  app_title_color,
  vatId,
  is_dif_option_vat,
  unitId,
  barcode,
  stock,
  custom_title,
  close_count,
  printer_id,
  report_section,
  product_group,
  app_title_width,
  app_title_height,
  link_to,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  app_image_url,
  app_visible_image,
  web_image_url,
  web_visible_image,
  kiosk_image_url,
  kiosk_visible_image
) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_EDIT,
    payload: {
      formData2,
      login_key,
      user_id,
      id,
      shopId,
      submenuId,
      price_level_collec_take,
      price_level_eat_in,
      price_level_main,
      app_title,
      app_description,
      web_title,
      web_description,
      kiosk_title,
      kiosk_description,
      secound_language,
      amount_main,
      amount_collection,
      amount_takeaway,
      amount_eat_in,
      app_vissible,
      web_visible,
      kiosk_visible,
      app_bg_color,
      app_title_font_size,
      app_title_font_family,
      app_title_color,
      vatId,
      is_dif_option_vat,
      unitId,
      barcode,
      stock,
      custom_title,
      close_count,
      printer_id,
      report_section,
      product_group,
      app_title_width,
      app_title_height,
      link_to,
      app_sort_id,
      web_sort_id,
      kiosk_sort_id,
      app_image_url,
      app_visible_image,
      web_image_url,
      web_visible_image,
      kiosk_image_url,
      kiosk_visible_image,
    },
  };
};
export const updateSubmenuItemEditSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_EDIT_SUCCESSFULL,
    payload: data,
  };
};

export const updateSubmenuItemEditFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_EDIT_FAILED,
    payload: data,
  };
};

// Show select item popup
export const showSelectItemPopupAction = (data) => {
  return {
    type: actionType.SHOW_SELECT_ITEM_POPUP,
    payload: data,
  };
};
export const isDressingAction = (data) => {
  return {
    type: actionType.IS_DRESSING,
    payload: data,
  };
};

//IMPORT SUBITEM
export const importSubItemAction = (
  login_key,
  user_id,
  shop_id,
  sub_item_id,
  sunmenu_id,
  import_category
) => {
  return {
    type: actionType.IMPORT_SUBITEM,
    payload: {
      login_key,
      user_id,
      shop_id,
      sub_item_id,
      sunmenu_id,
      import_category,
    },
  };
};

export const importSubItemSuccessfullAction = (data) => {
  return {
    type: actionType.IMPORT_SUBITEM_SUCCESSFULL,
    payload: data,
  };
};

export const importSubItemFailedAction = (data) => {
  return {
    type: actionType.IMPORT_SUBITEM_FAILED,
    payload: data,
  };
};

// SHOW IMPORT SUBMENU POPUP
export const showImportSubmenuPopupAction = (data) => {
  return {
    type: actionType.SHOW_IMPORT_SUBMENU_POPUP,
    payload: data,
  };
};

//NEW SUBMENU ID
export const getNewSubmenuIdAction = (data) => {
  return {
    type: actionType.GET_NEW_SUBMENU_ID,
    payload: data,
  };
};

// UPDATE SUBMENU ITEM SORT
export const updateSubmenuItemWebSortAction = (
  login_key,
  user_id,
  shop_id,
  sub_item_id,
  old_sort_id,
  new_sort_id,
  submenu_id
) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_WEB_SORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      sub_item_id,
      old_sort_id,
      new_sort_id,
      submenu_id,
    },
  };
};
export const updateSubmenuItemWebSortSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_WEB_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateSubmenuItemWebSortFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_WEB_SORT_FAILED,
    payload: data,
  };
};

export const updateSubmenuItemAppSortAction = (
  login_key,
  user_id,
  shop_id,
  sub_item_id,
  old_sort_id,
  new_sort_id,
  submenu_id
) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_APP_SORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      sub_item_id,
      old_sort_id,
      new_sort_id,
      submenu_id,
    },
  };
};
export const updateSubmenuItemAppSortSuccessfullAction = (data) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_APP_SORT_SUCCESSFULL,
    payload: data,
  };
};

export const updateSubmenuItemAppSortFailedAction = (data) => {
  return {
    type: actionType.UPDATE_SUBMENU_ITEM_APP_SORT_FAILED,
    payload: data,
  };
};

export const submenuItemSortNumberAction = (data) => {
  return {
    type: actionType.SUBMENU_ITEM_SORT_NUMBER,
    payload: data,
  };
};
