import * as actionTypes from "../ActionTypes";

const initialStates = {
  result_successfull: false,
  result_failed: false,
  result_message: "",
  customers_list: [],
  loading: false,
  show_delete_customer_popup: false,
  deleting_customer_info: [],
  customers_export_list: [],
};
const customersRedusers = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        loading: false,
        result_message: "",
      };
    case actionTypes.GET_CUSTOMERS_LIST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_CUSTOMERS_LIST_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        loading: false,
        result_message: "",
        customers_list: action.payload,
        disable_search_type: true,
      };
    case actionTypes.GET_CUSTOMERS_LIST_FAILED:
      return {
        ...state,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
        loading: false,
        customers_list: [],
      };
    case actionTypes.EDIT_CUSTOMER:
      return {
        ...state,
        result_successfull: false,
        loading: true,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.EDIT_CUSTOMER_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        loading: false,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.EDIT_CUSTOMER_FAILED:
      return {
        ...state,
        result_successfull: false,
        loading: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.DELETE_CUSTOMER:
      return {
        ...state,
        result_successfull: false,
        loading: true,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.DELETE_CUSTOMER_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        loading: false,
        result_failed: false,
        result_message: action.payload,
        show_delete_customer_popup: false,
      };
    case actionTypes.DELETE_CUSTOMER_FAILED:
      return {
        ...state,
        result_successfull: false,
        loading: false,
        result_failed: true,
        result_message: action.payload,
      };
    case actionTypes.SHOW_DELETE_CUTOMER_POPUP:
      return {
        ...state,
        show_delete_customer_popup: action.payload,
      };
    case actionTypes.DELETING_CUSTOMER_INFO:
      return {
        ...state,
        deleting_customer_info: action.payload,
      };

    case actionTypes.GET_CUSTOMERS_EXPORT_LIST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_CUSTOMERS_EXPORT_LIST_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        loading: false,
        result_message: "",
        customers_export_list: action.payload,
      };
    case actionTypes.GET_CUSTOMERS_EXPORT_LIST_FAILED:
      return {
        ...state,
        result_failed: true,
        result_message: action.payload,
        result_successfull: false,
        loading: false,
        customers_export_list: [],
      };
    default:
      return state;
  }
};
export default customersRedusers;
