import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuListItem from "../../../../components/MenuListItem";
import Loader from "../../../../components/Loader";
import useScrollbarSize from "react-scrollbar-size";
import toast, { Toaster } from "react-hot-toast";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";
import {
  createAllowedPostCodeFOFAction,
  deleteAllowedPostCodeFOFAction,
  getAllowedPostCodeFOFListAction,
  selectedAllowedPostCodeFOFAction,
} from "../../../../redux/GeneralSetting/Actions";
import TextField from "@mui/material/TextField";
import { primaryColor } from "../../../../constants/Colors";
import Box from "@mui/material/Box";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
  setDefaultAction,
} from "../../../../redux/menu/Actions";
const FOFPostCode = () => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    result_successfull,
    result_failed,
    result_message,
    loading_post_code,
    post_code_fof_list,
  } = useSelector((state) => state.tabelAreaRedusers);
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const [postCodeList, setPostCodeList] = useState([]);
  const [showCreateBox, setShowCreateBox] = useState(false);
  const [showCreateBoxForMultipleItems, setShowCreateBoxForMultipleItems] =
    useState(false);
  const [postCode, setPostCode] = useState("");

  const handleEditPostCode = (item) => {
    dispatch(selectedAllowedPostCodeFOFAction(item));
  };
  const handleCreateFOFPostCode = () => {
    if (postCode == "") {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        createAllowedPostCodeFOFAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          postCode,
          0,
          0
        )
      );
    }
  };
  const handleShowCreateBox = (key) => {
    if (key == 1) {
      setShowCreateBox(true);
      setShowCreateBoxForMultipleItems(false);
    } else {
      setShowCreateBox(false);
      setShowCreateBoxForMultipleItems(true);
    }
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...postCodeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      }
    }
    setPostCodeList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...postCodeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setPostCodeList(copy_list);
  };
  const handleDeleteItem = (item) => {
    dispatch(
      deleteAllowedPostCodeFOFAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        item.Id
      )
    );
  };
  const handleCloseIcon = (item) => {
    const copy_product_list = [...postCodeList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setPostCodeList(copy_product_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...postCodeList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
      // dispatch(justTitleAndPriceAction(0));
    }

    setPostCodeList(copy_product_list);
  };
  const handleEditIconClick = (item) => {
    const copy_product_list = [...postCodeList];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.Id == copy_product_list[i].Id) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setPostCodeList(copy_product_list);
    dispatch(selectedOtemInAllLists(item));
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getAllowedPostCodeFOFListAction(
          login_key,
          user_id,
          selected_shop[0].ShopId
        )
      );
    }
  }, [selected_shop]);
  useEffect(() => {
    if (result_successfull) {
      setPostCode("");
      // dispatch(setDefaultAction);
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      // dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);
  useEffect(() => {
    if (post_code_fof_list) {
      if (post_code_fof_list.length > 0) {
        setPostCodeList(post_code_fof_list);
      } else {
        setPostCodeList([]);
      }
    } else {
      setPostCodeList([]);
    }
  }, [post_code_fof_list]);
  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);
  return (
    <div
      className="countaining-div common-styles"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
      id="countaining"
      onClick={() => {
        setShowCreateBox(false);
        setShowCreateBoxForMultipleItems(false);
      }}
    >
      <div className="menu_list_header_container" style={{ paddingBottom: 50 }}>
        {postCodeList.length > 0 ? (
          <div>
            {postCodeList.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setShowCreateBox(false);
                  setShowCreateBoxForMultipleItems(false);
                }}
              >
                <MenuListItem
                  field_number={1}
                  item_title={item.Postcode}
                  field_1_title={item.Postcode}
                  //   field_2_title={`${lang.delivery_charge} : ${item.DeliveryCharge}`}
                  //   field_3_title={`${lang.min_order_charge} : ${item.DeliveryMinCharge}`}
                  btn_number={0}
                  //   delivery_charge={item.DeliveryCharge}
                  //   delivery_min_charge={item.DeliveryMinCharge}
                  //   bt_1_title={lang.edit}
                  bt_1_click={() => handleEditPostCode(item)}
                  show_delete={true}
                  delete_click={() => handleDeleteItem(item)}
                  show_edit_icon={true}
                  //   selected={selected_allowed_post_code}
                  item={item}
                  number={16}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                  click_edit_icon={() => handleEditIconClick(item)}
                  on_blur={() => handleCloseEditBoxWithOnBlur()}
                  click_close_icon={() => handleCloseIcon(item)}
                />
              </div>
            ))}
          </div>
        ) : showCreateBox ? (
          <div
            className="fof-post-code-container-first-create-box "
            style={{ zIndex: 1 }}
          >
            <div
              className="fof-post-code-container-create-box"
              onClick={(i) => i.stopPropagation()}
            >
              {" "}
              <Box
                // component="form"
                className="fof-post-code-input-textbox"
                autoComplete="off"
              >
                <TextField
                  key={1}
                  sx={{
                    "& label.Mui-focused": {
                      color: primaryColor,
                    },
                    "&:hover label": {
                      color: primaryColor,
                    },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "15px",
                      height: "40px",
                      color: "black",

                      "& fieldset": {
                        borderColor: "grey",
                      },
                      "&:hover fieldset": {
                        borderColor: primaryColor,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor,
                      },
                    },
                  }}
                  size="small"
                  label={`* ${lang.post_code}`}
                  InputLabelProps={{
                    sx: {
                      fontSize: 16,
                      // top: -7,
                      "&.MuiInputLabel-shrink": { top: 0 },
                      "&.Mui-focused": {
                        color: primaryColor,
                      },
                      "& label.Mui-focused": {
                        color: primaryColor,
                      },
                      "&:hover label": {
                        color: primaryColor,
                      },
                    },
                  }}
                  style={{ width: "100%" }}
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value.toUpperCase())}
                  focused={postCode == "" ? true : false}
                />
              </Box>
              <div className="fof-post-code-container-buttons">
                <button onClick={() => handleCreateFOFPostCode()}>
                  <i
                    class="fa fa-check menu-list-fa-check"
                    aria-hidden="true"
                    style={{ fontSize: " 23px" }}
                  ></i>
                </button>
                <button
                  onClick={() => {
                    setShowCreateBox(false);
                    setShowCreateBoxForMultipleItems(false);
                  }}
                >
                  <i
                    class="fa fa-times menu-list-fa-times"
                    aria-hidden="true"
                    style={{ fontSize: " 23px" }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <div onClick={(i) => i.stopPropagation()}>
              <span
                style={{ width: "172px" }}
                onClick={() => handleShowCreateBox(1)}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                <p>{lang.create_post_code}</p>
              </span>
            </div>
          </div>
        )}
      </div>
      <div>
        {postCodeList.length > 0 ? (
          showCreateBoxForMultipleItems ? (
            <div
              className="fof-post-code-container-second-create-box"
              style={{
                right:
                  (window.innerWidth * 2) / 100 +
                  width +
                  marginNormalMenuHorizontal,
              }}
            >
              <div
                className="fof-post-code-container-create-box"
                onClick={(i) => i.stopPropagation()}
              >
                <Box
                  // component="form"
                  className="fof-post-code-input-textbox"
                  autoComplete="off"
                >
                  <TextField
                    key={1}
                    sx={{
                      "& label.Mui-focused": {
                        color: primaryColor,
                      },
                      "&:hover label": {
                        color: primaryColor,
                      },
                      "& .MuiOutlinedInput-root": {
                        fontSize: "15px",
                        height: "40px",
                        color: "black",

                        "& fieldset": {
                          borderColor: "grey",
                        },
                        "&:hover fieldset": {
                          borderColor: primaryColor,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: primaryColor,
                        },
                      },
                    }}
                    size="small"
                    label={`*  ${lang.post_code}`}
                    InputLabelProps={{
                      sx: {
                        fontSize: 16,
                        // top: -7,
                        "&.MuiInputLabel-shrink": { top: 0 },
                        "&.Mui-focused": {
                          color: primaryColor,
                        },
                        "& label.Mui-focused": {
                          color: primaryColor,
                        },
                        "&:hover label": {
                          color: primaryColor,
                        },
                      },
                    }}
                    style={{ width: "100%" }}
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value.toUpperCase())}
                    autoFocus
                    focused={postCode == "" ? true : false}
                  />
                </Box>
                <div className="fof-post-code-container-buttons">
                  <button onClick={() => handleCreateFOFPostCode()}>
                    <i
                      class="fa fa-check menu-list-fa-check"
                      aria-hidden="true"
                      style={{ fontSize: " 23px" }}
                    ></i>
                  </button>
                  <button
                    onClick={() => {
                      setShowCreateBox(false);
                      setShowCreateBoxForMultipleItems(false);
                    }}
                  >
                    <i
                      class="fa fa-times menu-list-fa-times"
                      aria-hidden="true"
                      style={{ fontSize: " 23px" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div onClick={(i) => i.stopPropagation()}>
              <div
                className="div_submenu_add_container_fixed"
                onClick={() => handleShowCreateBox(2)}
                style={{
                  width: 200,
                  right:
                    (window.innerWidth * 2) / 100 +
                    width +
                    marginNormalMenuHorizontal,
                }}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                <p>{lang.create_post_code}</p>
              </div>{" "}
            </div>
          )
        ) : null}
      </div>
      {loading_post_code ? <Loader /> : null}
    </div>
  );
};

export default FOFPostCode;
