import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  Fragment,
} from "react";
import Switch from "@mui/material/Switch";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../../components/Loader";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
// import { LoadScript, GoogleMap, DrawingManager } from "@react-google-maps/api";
import { primaryColor, white } from "../../../../constants/Colors";
import { alpha, styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import { red } from "@mui/material/colors";
import { usePopperTooltip } from "react-popper-tooltip";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Circle,
  Polygon,
  Marker,
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import {
  createDeliveryAreaMapAction,
  defaultMapCenterAction,
  deleteDeliveryAreaMapAction,
  getDeliveryAreaMapListAction,
  updateDeliveryAreaMapAction,
} from "../../../../redux/GeneralSetting/Actions";
import { setDefaultAction } from "../../../../redux/menu/Actions";
// import LoaderMini from "../../../../components/LoaderMini";
const data = [
  // {
  //   id: 1,
  //   name: "Zone 1",
  //   show_details: false,
  //   min_amount: 2,
  //   delivery_fee: 2.5,
  //   show_delete_box: false,
  //   is_deleted: false,
  //   hide_zone: false,
  //   editable: false,
  //   options: {
  //     strokeColor: "#ff0927",
  //     fillColor: "#ff0927",
  //     fillOpacity: 0.2,
  //     strokeOpacity: 0.8,
  //     strokeWeight: 3,
  //   },
  //   circle: {
  //     radius: 5000,
  //     latitude: "40.785091",
  //     longitude: "-73.968285",
  //   },
  //   polygon: [],
  // },
  // {
  //   id: 2,
  //   name: "Zone 2",
  //   show_details: false,
  //   min_amount: 2,
  //   delivery_fee: 2.5,
  //   show_delete_box: false,
  //   is_deleted: false,
  //   hide_zone: false,
  //   editable: false,
  //   options: {
  //     strokeColor: "#6802d0",
  //     fillColor: "#6802d0",
  //     fillOpacity: 0.2,
  //     strokeOpacity: 0.8,
  //     strokeWeight: 3,
  //   },
  //   circle: {
  //     radius: 6000,
  //     latitude: "40.6082",
  //     longitude: "-73.9442",
  //   },
  //   polygon: [],
  // },
  {
    id: 1,
    name: "Zone 3",
    show_details: false,
    min_amount: 2,
    delivery_fee: 2.5,
    show_delete_box: false,
    is_deleted: false,
    hide_zone: false,
    editable: false,
    strokeColor: "#d40d25",
    fillColor: "#d40d25",
    circle: null,
    polygon: [
      // {
      //   coord: [
      { lat: 55.948, lng: -3.205 },
      { lat: 55.952, lng: -3.196 },
      { lat: 55.955, lng: -3.205 },
      { lat: 55.952, lng: -3.215 },

      //   ],
      // },
    ],
  },
  {
    id: 2,
    name: "Zone 4",
    show_details: false,
    min_amount: 2,
    delivery_fee: 2.5,
    show_delete_box: false,
    is_deleted: false,
    hide_zone: false,
    editable: false,
    strokeColor: "#5f23bd",
    fillColor: "#5f23bd",
    circle: null,
    polygon: [
      // {
      //   coord: [
      { lat: 55.944, lng: -3.185 },
      { lat: 55.946, lng: -3.175 },
      { lat: 55.955, lng: -3.185 },
      { lat: 55.952, lng: -3.195 },
      //   ],
      // },
    ],
  },
  {
    id: 3,
    name: "Zone 5",
    show_details: false,
    min_amount: 2,
    delivery_fee: 2.5,
    show_delete_box: false,
    is_deleted: false,
    hide_zone: false,
    editable: false,
    strokeColor: "#022cd0",
    fillColor: "#022cd0",
    circle: null,
    polygon: [
      { lat: 55.96, lng: -3.22 }, // شمال غربی
      { lat: 55.96, lng: -3.15 }, // شمال شرقی
      { lat: 55.98, lng: -3.15 }, // جنوب شرقی
      { lat: 55.98, lng: -3.22 },
    ],
  },
  // {
  //   id: 5,
  //   name: "Zone 4",
  //   show_details: false,
  //   min_amount: 2,
  //   delivery_fee: 2.5,
  //   show_delete_box: false,
  //   is_deleted: false,
  //   hide_zone: false,
  //   editable: false,
  //   strokeColor: "#12a6e9",
  //   fillColor: "#12a6e9",
  //   circle: null,
  //   polygon: [
  //     // {
  //     //   coord: [
  //     { lat: 51.48, lng: -0.12 },
  //     { lat: 51.5, lng: -0.08 },
  //     { lat: 51.52, lng: -0.12 },
  //     { lat: 51.5, lng: -0.16 },
  //     //   ],
  //     // },
  //   ],
  // },
  // {
  //   id: 5,
  //   name: "Zone 5",
  //   show_details: false,
  //   min_amount: 2,
  //   delivery_fee: 2.5,
  //   show_delete_box: false,
  //   is_deleted: false,
  //   hide_zone: false,
  //   editable: false,
  //   options: {
  //     strokeColor: "#f5a623",
  //     fillColor: "#f5a623",
  //     fillOpacity: 0.2,
  //     strokeOpacity: 0.8,
  //     strokeWeight: 3,
  //   },
  //   circle: {
  //     radius: 3000,
  //     latitude: "40.6095",
  //     longitude: "-74.1002",
  //   },
  //   polygon: [],
  // },
];
setKey("AIzaSyBPmiaHpGuilUxoaaCALx9b_tz9yXAjr5M");
const MapComponent = withScriptjs(
  withGoogleMap(() => {
    // const handlePolygonEdit = (polygon) => {
    //   let copy_zonesList = [...zonesList];
    //   const path = polygon.getPath();
    //   const newCoords = [];
    //   path.forEach((latLng) => {
    //     newCoords.push({
    //       lat: latLng.lat(),
    //       lng: latLng.lng(),
    //     });
    //   });

    //   // Find the index of the edited polygon in the zonesList
    //   if (copy_zonesList.length > 0) {
    //     const updatedZonesList = copy_zonesList.map((zone) => {
    //       return {
    //         ...zone,
    //         polygon: zone.polygon.map((poly) => {
    //           if (poly.id_polygon === polygon.id) {
    //             return {
    //               ...poly,
    //               coord: newCoords,
    //             };
    //           }
    //           return poly;
    //         }),
    //       };
    //     });
    //     setZonesList(updatedZonesList);
    //   }

    //   // Update the state with the new zonesList

    //   // Show the updated coordinates in an alert
    //   const coordsString = newCoords
    //     .map((coord) => `Lat: ${coord.lat}, Lng: ${coord.lng}`)
    //     .join("\n");

    //   console.log("11", newCoords);
    // };

    // Function to add ref to polygon
    // function isPointInPolygon(point, polygon) {
    //   let x = point.lat,
    //     y = point.lng;
    //   let inside = false;

    //   for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    //     let xi = polygon[i].lat,
    //       yi = polygon[i].lng;
    //     let xj = polygon[j].lat,
    //       yj = polygon[j].lng;

    //     let intersect =
    //       yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    //     if (intersect) inside = !inside;
    //   }

    //   return inside;
    // }

    // const pointToCheck = { lat: 55.9533, lng: -3.1883 };

    // for (const zone of data) {
    //   if (isPointInPolygon(pointToCheck, zone.polygon)) {
    //     console.log(`Point is in ${zone.name}`);
    //   } else {
    //     console.log(`Point is not in ${zone.name}`);
    //   }
    // }

    // useEffect(() => {
    //   const point = { lat: 55.945, lng: -3.205 };

    //   checkIfPointInZones(point.lat, point.lng);
    // }, []);

    // const checkIfPointInZones = (lat, lng) => {
    //   if (!window.google) return;

    //   const point = new window.google.maps.LatLng(lat, lng);

    //   let foundInZone = false;

    //   data.forEach((zone) => {
    //     const polygon = new window.google.maps.Polygon({ paths: zone.polygon });

    //     if (window.google.maps.geometry.poly.containsLocation(point, polygon)) {
    //       // console.log(`Point is inside ${zone.name}`);
    //       foundInZone = true;
    //     }
    //   });

    //   if (!foundInZone) {
    //     // console.log("Point is not inside any zone");
    //   }
    // };

    const mapRef = useRef(null);
    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
      usePopperTooltip({
        interactive: true,
        closeOnOutsideClick: true,
      });
    const dispatch = useDispatch();
    const GreenSwitch = styled(Switch)(({ theme }) => ({
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: primaryColor,
        "&:hover": {
          backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
        },
      },
      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: primaryColor,
      },
    }));
    const { lang } = useSelector((state) => state.selectedLanguage);
    const [zones, setZones] = useState([]);
    const [mapCenter, setMapCenter] = useState(null);
    const [zoneColor, setZoneColor] = useState("#d0021b");
    const [showZoneColorPicker, setShowZoneColorPicker] = useState(false);
    const [zonesList, setZonesList] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedShape, setSelectedShape] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(0);
    const [showNewZone, setShowNewZone] = useState(false);
    const [zoneSettings, setZoneSettings] = useState({
      name: "",
      color: "#fc743a",
      minAmount: 0,
      deliveryFee: 0,
    });
    const [shapeType, setShapeType] = useState("");
    const {
      delivery_area_map_list,
      selected_delivery_area_map,
      is_created,
      loading_tabel_area,
      loading_post_code,
      result_failed,
      result_successfull,
      result_message,
      default_map_center,
    } = useSelector((state) => state.tabelAreaRedusers);
    const { login_key, user_id } = useSelector((state) => state.loginReducer);
    const { selected_shop } = useSelector((state) => state.shopReducer);
    const polygonRefs = useRef([]);
    const handleShowDetails = (item) => {
      const copy_zonesList = [...zonesList];
      if (copy_zonesList.length > 0) {
        for (let i = 0; i < copy_zonesList.length; i++) {
          if (item.Id == copy_zonesList[i].Id) {
            const bounds = new window.google.maps.LatLngBounds();
            item.Polygon.forEach((coord) => bounds.extend(coord));
            const center = bounds.getCenter();
            mapRef.current.panTo(center);
            if (copy_zonesList[i].ShowDetails) {
              copy_zonesList[i].ShowDetails = false;
              copy_zonesList[i].Editable = false;
              copy_zonesList[i].Draggable = false;
            } else {
              setSelectedZone(copy_zonesList[i]);
              copy_zonesList[i].ShowDetails = true;
              copy_zonesList[i].Editable = true;
              copy_zonesList[i].Draggable = true;
              // copy_zonesList[i].strokeWeight = 5;
            }
            setSelectedItemId(item.Id);
          } else {
            copy_zonesList[i].ShowDetails = false;
            copy_zonesList[i].Editable = false;
            copy_zonesList[i].Draggable = false;
            // copy_zonesList[i].strokeWeight = 3;
          }
        }
      }
      setZonesList(copy_zonesList);
    };
    const handleOnMouseOver = (item) => {
      const copy_zonesList = [...zonesList];
      if (copy_zonesList.length > 0) {
        for (let i = 0; i < copy_zonesList.length; i++) {
          if (item.Id == copy_zonesList[i].Id) {
            setSelectedZone(copy_zonesList[i]);
          } else {
            copy_zonesList[i].ShowDetails = false;
            copy_zonesList[i].Editable = false;
            copy_zonesList[i].Draggable = false;
            // copy_zonesList[i].strokeWeight = 3;
          }
        }
      }
      setZonesList(copy_zonesList);
    };

    const handleHideZone = (item) => {
      const copy_zonesList = [...zonesList];
      if (copy_zonesList.length > 0) {
        for (let i = 0; i < copy_zonesList.length; i++) {
          if (item.Id == copy_zonesList[i].Id) {
            if (copy_zonesList[i].IsActive) {
              copy_zonesList[i].IsActive = false;
            } else {
              copy_zonesList[i].IsActive = true;
            }
            if (!item.IsCreate) {
              if (item.Title == "" || item.Color == "") {
                toast.error(
                  <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
                  {
                    position: "top-center",
                    duration: 3000,
                    style: { backgroundColor: "red", color: "white" },
                    icon: (
                      <i
                        style={{ fontSize: 40 }}
                        className="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>
                    ),
                  }
                );
              } else if (item.Polygon == []) {
                toast.error(
                  <h5> &nbsp;&nbsp;{lang.selecte_area}&nbsp;&nbsp; </h5>,
                  {
                    position: "top-center",
                    duration: 3000,
                    style: { backgroundColor: "red", color: "white" },
                    icon: (
                      <i
                        style={{ fontSize: 40 }}
                        className="fa fa-times-circle"
                        aria-hidden="true"
                      ></i>
                    ),
                  }
                );
              } else {
                dispatch(
                  updateDeliveryAreaMapAction(
                    login_key,
                    user_id,
                    selected_shop[0].ShopId,
                    item.Id,
                    item.Color,
                    item.Title,
                    item.MinAmount,
                    item.DeliveryCharge,
                    item.Polygon,
                    item.IsActive
                  )
                );
              }
            }
          }
        }
      }
      setZonesList(copy_zonesList);
    };
    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const handleOnclickeInputZoneColor = () => {
      setShowZoneColorPicker(!showZoneColorPicker);
    };

    const handleCloseZoneColor = () => {
      setShowZoneColorPicker(false);
    };

    const handleChangeCompleteZoneColor = (color, item) => {
      const copy_zones = [...zonesList];
      setZoneColor(color);
      if (copy_zones) {
        if (copy_zones.length > 0) {
          for (let i = 0; i < copy_zones.length; i++) {
            if (item.Id == copy_zones[i].Id) {
              copy_zones[i].Color = color.hex;
              // copy_zones[i].fillColor = color.hex;
            }
          }
        }
      }
      setZonesList(copy_zones);
      document.getElementById("FlashingColour").value = color.hex;
    };

    const handleColorChange = (e, item) => {
      const newColor = e.target.value;
      // setZoneSettings({ ...zoneSettings, color: newColor });
      // if (selectedShape) {
      //   selectedShape.setOptions({
      //     fillColor: newColor,
      //   });
      // }
      const copy_zones = [...zonesList];
      // setZoneColor(color);
      if (copy_zones) {
        if (copy_zones.length > 0) {
          for (let i = 0; i < copy_zones.length; i++) {
            if (item.Id == copy_zones[i].Id) {
              copy_zones[i].Color = newColor;
              // copy_zones[i].fillColor = color.hex;
            }
          }
        }
      }
      setZonesList(copy_zones);
    };
    const handleOverlayComplete = (event) => {
      let new_list = [...zonesList];

      const { overlay, type } = event;

      if (type === window.google.maps.drawing.OverlayType.CIRCLE) {
        const newCircle = {
          type: "circle",
          center: overlay.getCenter().toJSON(),
          radius: overlay.getRadius(),
        };
        new_list.push({ newCircle });
        setZonesList(new_list);
        // setZones([...zones, newCircle]);
      } else if (type === window.google.maps.drawing.OverlayType.POLYGON) {
        const path = overlay
          .getPath()
          .getArray()
          .map((coord) => coord.toJSON());
        const newPolygon = {
          type: "polygon",
          path,
        };
        new_list.push({ newPolygon });
        setZonesList(new_list);
        // setZones([...zones, newPolygon]);
      }

      overlay.setMap(null);
    };

    const handlePolygonComplete = (polygon) => {
      window.google.maps.event.addListener(polygon.getPath(), "set_at", () =>
        handlePolygonEdit(polygon)
      );
      window.google.maps.event.addListener(polygon.getPath(), "insert_at", () =>
        handlePolygonEdit(polygon)
      );
      window.google.maps.event.addListener(polygon.getPath(), "remove_at", () =>
        handlePolygonEdit(polygon)
      );
    };

    const addPolygonRef = (ref, index) => {
      if (ref) {
        polygonRefs.current[index] = ref;
        window.google.maps.event.addListener(ref.getPath(), "set_at", () =>
          handlePolygonEdit(ref)
        );
        window.google.maps.event.addListener(ref.getPath(), "insert_at", () =>
          handlePolygonEdit(ref)
        );
        window.google.maps.event.addListener(ref.getPath(), "remove_at", () =>
          handlePolygonEdit(ref)
        );
      }
    };
    const handleDetails = (e, item, key) => {
      const copy_zones_list = [...zonesList];
      if (copy_zones_list.length > 0) {
        for (let i = 0; i < copy_zones_list.length; i++) {
          if (item.Id == copy_zones_list[i].Id) {
            if (key == 1) {
              copy_zones_list[i].Title = e.target.value;
            } else if (key == 2) {
              copy_zones_list[i].MinAmount = e.target.value.replace(
                /[^\d\.,£]+/g,
                ""
              );
            } else {
              copy_zones_list[i].DeliveryCharge = e.target.value.replace(
                /[^\d\.,£]+/g,
                ""
              );
            }
          }
        }
      }
      setZonesList(copy_zones_list);
    };
    const handlePolygonEdit = (polygon, item) => {
      let copy_zonesList = [...zonesList];
      const path = polygon.getPath();
      const newCoords = [];

      path.forEach((latLng) => {
        newCoords.push({
          lat: latLng.lat(),
          lng: latLng.lng(),
        });
      });
      if (copy_zonesList.length > 0) {
        for (let i = 0; i < copy_zonesList.length; i++) {
          if (copy_zonesList[i].Id == item.Id) {
            copy_zonesList[i].Polygon = newCoords;
          }
        }
      }

      setZonesList(copy_zonesList);

      const coordsString = newCoords
        .map((coord) => `Lat: ${coord.lat}, Lng: ${coord.lng}`)
        .join("\n");
      // alert(`Updated Polygon Coordinates:\n${coordsString}`);
    };

    const handlePolygonMouseUp = (index, item) => {
      const polygon = polygonRefs.current[index];
      handlePolygonEdit(polygon, item);
    };
    const handleAddNewZone = () => {
      const { lat, lng } = mapCenter;
      const size = 0.002;
      const zise1 = 0.0035;
      let new_polygon = [
        { lat: lat + size, lng: lng }, // شمال
        { lat: lat, lng: lng + zise1 }, // شرق
        { lat: lat - size, lng: lng }, // جنوب
        { lat: lat, lng: lng - zise1 }, // غرب
      ];
      setShowNewZone(!showNewZone);
      const new_list = [...zonesList];
      const randomColor = getRandomColor();
      const lastId = new_list.length > 0 ? new_list[new_list.length - 1].Id : 0;
      const newId = lastId + 1;
      new_list.push({
        Color: randomColor,
        DeliveryCharge: 0,
        Draggable: true,
        Editable: true,
        Id: newId,
        IsActive: 1,
        MinAmount: 0,
        // Polygon: [
        //   { lat: 56.412, lng: -5.4725 },
        //   { lat: 56.413, lng: -5.4705 },
        //   { lat: 56.412, lng: -5.4685 },
        //   { lat: 56.411, lng: -5.4705 },
        // ],
        Polygon: new_polygon,
        ShopId: selected_shop[0].ShopId,
        ShowDeleteBox: false,
        ShowDetails: true,
        Title: `Zone ${new_list.length + 1}`,
        IsCreate: 1,
      });

      setZonesList(new_list);
    };
    const handleCreateAndSave = (item, key) => {
      if (key == 1) {
        const copy_zonesList = [...zonesList];

        if (copy_zonesList.length > 0) {
          for (let i = 0; i < copy_zonesList.length; i++) {
            if (item.Id == copy_zonesList[i].Id) {
              if (item.IsCreate) {
                copy_zonesList.pop();
                setShowNewZone(false);
              } else {
                setSelectedZone(copy_zonesList[i]);
                copy_zonesList[i].ShowDetails = false;
                copy_zonesList[i].Editable = false;
                copy_zonesList[i].Draggable = false;
              }
            }
          }
        }
        setZonesList(copy_zonesList);
      } else {
        if (item.IsCreate) {
          if (item.Title == "" || item.Color == "") {
            toast.error(
              <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          } else if (item.Polygon == []) {
            toast.error(
              <h5> &nbsp;&nbsp;{lang.selecte_area}&nbsp;&nbsp; </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          } else {
            dispatch(
              createDeliveryAreaMapAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                item.Color,
                item.Title,
                item.MinAmount ? item.MinAmount : 0,
                item.DeliveryCharge ? item.DeliveryCharge : 0,
                item.Polygon,
                item.IsActive
              )
            );
          }
        } else {
          if (item.Title == "" || item.Color == "") {
            toast.error(
              <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          } else if (item.Polygon == []) {
            toast.error(
              <h5> &nbsp;&nbsp;{lang.selecte_area}&nbsp;&nbsp; </h5>,
              {
                position: "top-center",
                duration: 3000,
                style: { backgroundColor: "red", color: "white" },
                icon: (
                  <i
                    style={{ fontSize: 40 }}
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></i>
                ),
              }
            );
          } else {
            dispatch(
              updateDeliveryAreaMapAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                item.Id,
                item.Color,
                item.Title,
                item.MinAmount,
                item.DeliveryCharge,
                item.Polygon,
                item.IsActive
              )
            );
          }
        }
      }
    };
    const handleDeleteZone = (item, key) => {
      const copy_zones_list = [...zonesList];
      const lastItem = copy_zones_list[copy_zones_list.length - 1];
      if (copy_zones_list.length > 0) {
        for (let i = 0; i < copy_zones_list.length; i++) {
          if (item.Id == copy_zones_list[i].Id) {
            if (key == 1) {
              copy_zones_list[i].ShowDeleteBox = true;
            } else if (key == 2) {
              copy_zones_list[i].ShowDeleteBox = false;
            } else {
              if (item.IsCreate) {
                copy_zones_list.pop();
                setShowNewZone(false);
              } else {
                dispatch(
                  deleteDeliveryAreaMapAction(
                    login_key,
                    user_id,
                    selected_shop[0].ShopId,
                    item.Id
                  )
                );
              }
            }
          }
        }
      }
      setZonesList(copy_zones_list);
    };
    useEffect(() => {
      if (data) {
        if (data.length > 0) {
          setZonesList(data);
        } else {
          setZonesList([]);
        }
      } else {
        setZonesList([]);
      }
    }, [data]);

    useEffect(() => {
      if (selected_shop.length > 0) {
        dispatch(
          getDeliveryAreaMapListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId
          )
        );

        fromAddress(selected_shop[0].Postcode)
          .then(({ results }) => {
            const { lat, lng } = results[0].geometry.location;
            setMapCenter({ lat: lat, lng: lng });
            dispatch(defaultMapCenterAction({ lat: lat, lng: lng }));
          })
          .catch(
            setMapCenter({
              lat: 55.9533,
              lng: -3.1883,
            }),
            console.error
          );
        setShowNewZone(false);
      }
    }, [selected_shop]);

    useEffect(() => {
      if (is_created || result_successfull) {
        dispatch(setDefaultAction());
        setShowNewZone(false);
      }
    }, [is_created, result_successfull]);
    useEffect(() => {
      if (delivery_area_map_list) {
        if (delivery_area_map_list.length > 0) {
          setZonesList(delivery_area_map_list);
        } else {
          setZonesList([]);
        }
      } else {
        setZonesList([]);
      }
    }, [delivery_area_map_list]);

    useEffect(() => {
      if (result_successfull) {
        dispatch(setDefaultAction());
        toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "green", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      } else if (result_failed) {
        dispatch(setDefaultAction());
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }, [result_successfull, result_failed]);

    useEffect(() => {
      const copy_zones_list = [...zonesList];
      if (showNewZone) {
        const bounds = new window.google.maps.LatLngBounds();
        copy_zones_list[copy_zones_list.length - 1].Polygon.forEach((coord) =>
          bounds.extend(coord)
        );
        const center = bounds.getCenter();
        mapRef.current.panTo(center);
      }
    }, [showNewZone]);

    return (
      <div className="container-area">
        <div>
          <GoogleMap
            ref={mapRef}
            defaultZoom={15}
            center={
              mapCenter
                ? mapCenter
                : {
                    lat: 55.9533,
                    lng: -3.1883,
                  }
            }
          >
            {/* /
            {zonesList.map((place) => (
              <Fragment key={place.id}>
                {place.circle && (
                  <Circle
                    center={{
                      lat: parseFloat(place.circle.latitude),
                      lng: parseFloat(place.circle.longitude),
                    }}
                    radius={place.circle.radius}
                    options={place.options}
                    editable={place.editable}
                    draggable={place.editable}
                  />
                )}
              </Fragment>
            ))} */}
            {/* <DrawingManager
              onPolygonComplete={handlePolygonComplete}
              defaultOptions={{
                drawingControl: false,
                drawingControlOptions: {
                  drawingModes: ["polygon"],
                },
                polygonOptions: { editable: true, draggable: true },
              }}
            /> */}
            {zonesList.map((place, index) => (
              <Fragment key={place.id}>
                {
                  place.Polygon && (
                    // place.polygon.map((poly, my_index) => (
                    <Polygon
                      path={place.Polygon}
                      visible={place.IsActive ? true : false}
                      editable={place.Editable}
                      options={{
                        strokeColor: place.Color,
                        strokeOpacity: 0.8,
                        strokeWeight: place.Editable ? 5 : 3,
                        fillColor: place.Color,
                        fillOpacity: 0.35,
                      }}
                      draggable={place.Draggable}
                      // ref={(ref) => addPolygonRef(ref, my_index)}
                      ref={(ref) => (polygonRefs.current[index] = ref)}
                      onMouseUp={() => handlePolygonMouseUp(index, place)}
                      // onDragEnd={() => handlePolygonMouseUp(my_index)}
                    />
                  )
                  // ))
                }
              </Fragment>
            ))}
            {/* <Marker
              position={mapCenter}
              // label={{
              //   // text: "Point",
              //   color: "white",
              //   fontSize: "12px",
              //   fontWeight: "bold",
              // }}
            /> */}
          </GoogleMap>
        </div>
        <div className="deleivery-area-container-zones">
          {/* <div className="deleivery-area-container-deliverytext-switch">
            <h6>Delivery status</h6>
            <GreenSwitch
            // checked={item.swithExcludeMealDeals}
            // onChange={() => hanldeChangeExcludeMealDealsAndUpdate(item)}
            />
          </div> */}
          {zonesList
            ? zonesList.length > 0
              ? zonesList.map((item, index) => (
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    {" "}
                    <div className="deleivery-area-container-any-zone">
                      <div className=" deleivery-area-container-main-section">
                        <div
                          className=" deleivery-area-container-header-of-details"
                          onClick={() => handleShowDetails(item)}
                          onMouseOver={() => handleOnMouseOver(item)}
                        >
                          <div>
                            <span
                              style={{
                                backgroundColor: item
                                  ? item.IsActive
                                    ? item.Color
                                    : "#a3a3a3"
                                  : null,
                              }}
                            ></span>
                            <p
                              style={{
                                color: item.IsActive ? null : "#a3a3a3",
                                fontSize: "15px",
                                wordWrap: "break-word",
                                overflowWrap: "anywhere",
                              }}
                            >
                              {!item.IsActive
                                ? `${item.Title} (hidden)`
                                : item.Title}
                            </p>
                          </div>
                          {item.ShowDetails ? (
                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                          ) : (
                            <i
                              class="fa fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                          )}
                        </div>
                        {item.ShowDetails ? (
                          <div className="deleivery-area-container-details">
                            {/* <div className="deleivery-area-container-shape-buttons">
                              <button
                                type=""
                                onClick={() => setShapeType("circle")}
                                style={{
                                  backgroundColor:
                                    // item.id == selectedItemId
                                    //   ?
                                    item.circle ? primaryColor : null,
                                  // : null,
                                  border: item.circle ? "none" : null,
                                  color: item.circle ? white : null,
                                }}
                              >
                                Circle
                              </button>
                              <button
                                type=""
                                onClick={() => setShapeType("polygon")}
                                style={{
                                  backgroundColor:
                                    // item.id == selectedItemId
                                    //   ?
                                    item.polygon.length > 0
                                      ? primaryColor
                                      : null,
                                  // : null,
                                  border:
                                    item.polygon.length > 0 ? "none" : null,
                                  color: item.polygon.length > 0 ? white : null,
                                }}
                              >
                                Shape
                              </button>
                            </div> */}
                            <div className="deleivery-area-container-color-box">
                              <p>{`*${lang.color}:`}</p>
                              <div className="deleivery-area-container-colors">
                                <div className="deleivery-area-container-color-selector">
                                  <div className="deleivery-area-container-color-display">
                                    <span
                                      style={{
                                        backgroundColor: item
                                          ? item.Color
                                          : null,
                                      }}
                                    ></span>
                                  </div>
                                  <input
                                    className="backgroundColor-input"
                                    onMouseOver={handleOnclickeInputZoneColor}
                                    value={item ? item.Color : null}
                                    onChange={(e) => handleColorChange(e, item)}
                                    // Value={zoneColor}
                                    // onChange={handleInputZoneColor}
                                    id="FlashingColour"
                                  />
                                </div>
                                {showZoneColorPicker ? (
                                  <div
                                    className="delivery-area-container-color-picker"
                                    onMouseLeave={handleCloseZoneColor}
                                  >
                                    <SketchPicker
                                      color={zoneColor}
                                      onChangeComplete={(color) =>
                                        handleChangeCompleteZoneColor(
                                          color,
                                          item
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="deleivery-area-container-zone-name">
                              <p>{`*${lang.name}:`}</p>
                              <input
                                value={item.Title}
                                onChange={(e) => handleDetails(e, item, 1)}
                                maxLength={45}
                              />
                            </div>
                            <div className="deleivery-area-container-zone-name">
                              <p>{lang.min_amount}:</p>
                              <input
                                value={item.MinAmount}
                                onChange={(e) => handleDetails(e, item, 2)}
                              />
                            </div>
                            <div className="deleivery-area-container-zone-name">
                              <p>{lang.delivery_fee}:</p>
                              <input
                                value={item.DeliveryCharge}
                                onChange={(e) => handleDetails(e, item, 3)}
                              />
                            </div>
                            <div className="deleivery-area-container-zone-name">
                              <div className="deleivery-area-container-delete-hide-icons">
                                <i
                                  class="fa fa-trash"
                                  aria-hidden="true"
                                  ref={setTriggerRef}
                                  onClick={() => handleDeleteZone(item, 1)}
                                ></i>

                                {item.ShowDeleteBox && (
                                  <div
                                    // onClick={(i) => i.stopPropagation()}

                                    ref={setTooltipRef}
                                    {...getTooltipProps({
                                      className: "tooltip-container",
                                      // interactive: true,
                                    })}
                                  >
                                    <div
                                      {...getArrowProps({
                                        className: "tooltip-arrow",
                                      })}
                                    ></div>
                                    <div className="delete-popup">
                                      <h6 style={{ fontSize: "19px" }}>
                                        {lang.delete_message}
                                      </h6>
                                      <div className="menu-list-container-cancel-and-ok-button">
                                        <button
                                          onClick={() =>
                                            handleDeleteZone(item, 2)
                                          }
                                          onFocus={true}
                                        >
                                          {lang.cancel}
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDeleteZone(item, 3)
                                          }
                                        >
                                          {lang.ok}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <i
                                  class="fa fa-eye-slash"
                                  aria-hidden="true"
                                  onClick={() => handleHideZone(item)}
                                  style={{
                                    color: !item.IsActive ? "gray" : null,
                                  }}
                                ></i>
                              </div>
                              <div
                                className="deleivery-area-container-buttons"
                                style={{
                                  justifyContent: item.IsCreate
                                    ? "flex-end"
                                    : "space-between",
                                }}
                              >
                                {!item.IsCreate ? (
                                  <button
                                    className="deleivery-area-cancel-button"
                                    onClick={() => handleCreateAndSave(item, 1)}
                                  >
                                    {lang.cancel}
                                  </button>
                                ) : null}
                                <button
                                  type=""
                                  className="deleivery-area-save-button"
                                  onClick={() => handleCreateAndSave(item, 2)}
                                >
                                  {lang.save}
                                </button>{" "}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))
              : null
            : null}
          <div className="deleivery-area-container-addAnotherZone-text">
            {!showNewZone ? (
              <button onClick={() => handleAddNewZone()}>
                {zonesList.length > 0 ? "Add another zone?" : "Add zone?"}
              </button>
            ) : null}
          </div>{" "}
          {loading_post_code ? <Loader /> : null}
        </div>{" "}
      </div>
    );
  })
);

const DeliveryArea = () => {
  return (
    <MapComponent
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBPmiaHpGuilUxoaaCALx9b_tz9yXAjr5M&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div />}
      containerElement={<div className="delivery-area-container" />}
      mapElement={<div className="delivery-area-container-map" />}
    />
  );
};

export default DeliveryArea;
