import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import ListHeader from "../components/ListHeader";
import MenuListItem from "../components/MenuListItem";
import {
  getProductOptionsAction,
  selectedProductOptionListAction,
} from "../redux/product/Action";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
  changeProductSortLabelAction,
  productOptionSortNumberAction,
  updateProductOptionWebSortAction,
  updateProductOptionAppSortAction,
} from "../redux/menu/Actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const OptionsList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMountRef = useRef(false);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const {
    vat_id,
    vat,
    category_list,
    selected_category,
    product_option_list,
    selected_product_option_list,
    change_selected_button_border_color_on_focus,
    product_option_sort_number,
  } = useSelector((state) => state.categoryList);

  const { selected_Product, change_product_sort_label } = useSelector(
    (state) => state.productList
  );

  const [productOptionList, setProductOptionList] = useState([]);
  const [sortOnWebOrApp, setSortOnWebOrApp] = useState(0);
  const handleClickedItem = (item, key) => {
    setClickedItem(key);
    let vatList = null;
    if (item.VatId) {
      if (vat_id.includes(parseInt(item.VatId))) {
        let index = vat_id.indexOf(parseInt(item.VatId));
        vatList = {
          label: vat[index].label,
          value: vat[index].value,
        };
      }
    }
    dispatch(selectedProductOptionListAction({ ...item, VatId: vatList }));
  };
  const handleSortButtons = (key) => {
    // dispatch(selectedOtemInAllLists(null));
    dispatch(selectedProductOptionListAction(null));
    dispatch(changeProductSortLabelAction(true));
    if (key == 1) {
      setSortOnWebOrApp(1);
    } else {
      setSortOnWebOrApp(2);
    }
  };
  const handleDragEnd = (result) => {
    const copy_list = [...productOptionList];
    // dispatch(changeSortLableAction(true));
    if (sortOnWebOrApp == 1) {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setProductOptionList(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];

      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateProductOptionWebSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.WebSortId,
            new_item.WebSortId,
            selected_Product.Id
          )
        );
      }
    } else {
      if (!result.destination) return;
      const items = Array.from(copy_list);
      const [reorderData] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, reorderData);
      setProductOptionList(items);
      let previous_item = copy_list[result.source.index];
      let new_item = copy_list[result.destination.index];
      if (previous_item.Id != new_item.Id) {
        dispatch(
          updateProductOptionAppSortAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            previous_item.Id,
            previous_item.AppSortId,
            new_item.AppSortId,
            selected_Product.Id
          )
        );
      }
    }
  };
  // useEffect(() => {
  //   if (!result_login_successfull) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    dispatch(
      getProductOptionsAction(
        login_key,
        user_id,
        selected_Product.ShopId,
        selected_Product.Id,
        selected_Product.CategoryId
      )
    );
  }, []);

  useEffect(() => {
    if (selected_category && didMountRef.current) {
      setClickedItem(2);
    }
    didMountRef.current = true;
  }, [selected_category]);

  const handleEditIconClick = (item) => {
    const copy_list = [...productOptionList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showEditBox = true;
      } else {
        copy_list[i].showEditBox = false;
      }
    }
    setProductOptionList(copy_list);
    dispatch(selectedOtemInAllLists(item));
  };

  const handleCloseIcon = (item) => {
    const copy_list = [...productOptionList];
    for (let index = 0; index < copy_list.length; index++) {
      if (item.Id == copy_list[index].Id) {
        copy_list[index].showEditBox = false;
      }
    }
    setProductOptionList(copy_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...productOptionList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
    }

    setProductOptionList(copy_product_list);
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...productOptionList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setProductOptionList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...productOptionList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setProductOptionList(copy_list);
  };
  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_list = [...productOptionList];
    for (let index = 0; index < copy_list.length; index++) {
      if (copy_list[index].showEditBox) {
        copy_list[index].showEditBox = false;
      }
    }
    setProductOptionList(copy_list);
    dispatch(justTitleAndPriceAction(1));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(selectedOtemInAllLists(null));
      dispatch(justTitleAndPriceAction(0));
    };
  }, [change_selected_button_border_color_on_focus]);
  useEffect(() => {
    if (product_option_list.length > 0) {
      setProductOptionList(product_option_list);
    }
  }, [product_option_list]);
  useEffect(() => {
    const copy_product_list = [...product_option_list];

    if (sortOnWebOrApp == 1) {
      const sortArrey = copy_product_list.sort(function (a, b) {
        return a.WebSortId - b.WebSortId;
      });

      if (change_product_sort_label) {
        dispatch(changeProductSortLabelAction(false));
        dispatch(selectedProductOptionListAction(copy_product_list[0]));
        for (let i = 0; i < copy_product_list.length; i++) {
          copy_product_list[i].collapse = i == 0 ? true : false;
        }
      }
      setProductOptionList(sortArrey);
    } else {
      const sortArrey = copy_product_list.sort(function (a, b) {
        return a.AppSortId - b.AppSortId;
      });

      if (change_product_sort_label) {
        dispatch(changeProductSortLabelAction(false));
        dispatch(selectedProductOptionListAction(sortArrey[0]));
        for (let i = 0; i < sortArrey.length; i++) {
          sortArrey[i].collapse = i == 0 ? true : false;
        }
      }

      setProductOptionList(sortArrey);
    }
    return () => {
      dispatch(productOptionSortNumberAction(sortOnWebOrApp));
    };
  }, [sortOnWebOrApp, product_option_list, change_product_sort_label]);
  useEffect(() => {
    if (product_option_sort_number == 2) {
      setSortOnWebOrApp(2);
    } else {
      setSortOnWebOrApp(1);
    }
  }, []);
  return (
    <div className="countaining-div" onClick={handleCloseEditBoxWithOnBlur}>
      {/* BACK TITLE */}

      <ListHeader
        title={lang.product_options_list}
        show_back={true}
        clickedItem={() => setClickedItem(2)}
        sort_switch={true}
        sort_click={(key) => handleSortButtons(key)}
        sort_key={sortOnWebOrApp}
      />
      <div className="menu_list_header_container">
        {productOptionList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {productOptionList.map((item, index) => (
                      <Draggable
                        key={item.Id}
                        draggableId={item.Id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item.id}
                          >
                            {!item.IsDeleted ? (
                              <MenuListItem
                                field_number={2}
                                item_title={item.AppTitle}
                                item_price={item.AmountDelivery}
                                field_1_title={item.AppTitle}
                                field_2_title={`£${item.AmountDelivery}`}
                                btn_number={1}
                                bt_1_title={lang.edit}
                                bt_1_click={() => handleClickedItem(item, 8)}
                                show_delete_box={() =>
                                  handleShowDeleteBox(item)
                                }
                                cancel_delete_box={() =>
                                  handleCancelDeleteBox(item)
                                }
                                show_delete={false}
                                show_edit_icon={true}
                                selected={selected_product_option_list}
                                item={item}
                                click_edit_icon={() =>
                                  handleEditIconClick(item)
                                }
                                click_close_icon={() => handleCloseIcon(item)}
                                number={5}
                              />
                            ) : null}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <h2 className="no-option">{lang.noOption}</h2>
        )}
      </div>
    </div>
  );
};

export default OptionsList;
