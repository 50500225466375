import * as actionTypes from "../ActionTypes";

export const selectedProductSubmenuAction = (data) => {
  return {
    type: actionTypes.SELECTED_PRODUCT_SUBMENU,
    payload: data,
  };
};
export const showProductSubmenuListPopup = (data) => {
  return {
    type: actionTypes.VISIBLE_PRODUCT_SUBMENU_POPUP,
    payload: data,
  };
};

// create_product
export const createProductAction = (
  login_key,
  user_id,
  shopId,
  categoryId,
  title,
  describtion,
  amount
) => {
  return {
    type: actionTypes.CREATE_PRODUCT_SAGA,
    payload: {
      login_key,
      user_id,
      shopId,
      categoryId,
      title,
      describtion,
      amount,
    },
  };
};
export const createProductSuccessfulAction = (data) => {
  return {
    type: actionTypes.CREATE_PRODUCT_SUCCESSFULL,
    payload: data,
  };
};
export const createProductFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_PRODUCT_FAILED,
    payload: data,
  };
};
//selected product
export const selectedProductAction = (data) => {
  return {
    type: actionTypes.SELECTED_PRODUCT,
    payload: data,
  };
};

//GET_PRODUCT
export const getProductAction = (login_key, user_id, shopId, categoryId) => {
  return {
    type: actionTypes.GET_PRODUCTS,
    payload: { login_key, user_id, shopId, categoryId },
  };
};
export const getProductSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESSFULL,
    payload: data,
  };
};
export const getProductFailedAction = () => {
  return {
    type: actionTypes.GET_PRODUCTS_FAILED,
  };
};

// POST PRODUCTS

export const sendProductSettingsAction = (
  formData,
  cat_id,
  login_key,
  user_id,
  product_id,
  shop_id,
  just_title_and_price,
  app_title,
  app_description,
  web_title,
  web_description,
  kiosk_title,
  kiosk_description,
  second_language,
  app_visible,
  web_visible,
  kiosk_visible,
  amount_delivery,
  amount_collection,
  amount_takeaway,
  amount_eatIn,
  printer_id,
  report_section,
  is_show_options,
  product_group,
  vat_id,
  is_suggested,
  is_popular,
  is_dif_option_vat,
  unit_id,
  barcode,
  custom_title,
  close_count,
  custom_title_in_last,
  title_in_last,
  link_to,
  is_auto_display_sub_menu,
  print_label,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  app_bg_color,
  app_title_font_size,
  app_title_font_family,
  app_title_color,
  app_tile_width,
  app_tile_height,
  app_image_url,
  app_visible_image,
  web_image_url,
  web_visible_image,
  kiosk_image_url,
  kiosk_visible_image,
  default_vat_for_all_option
) => {
  return {
    type: actionTypes.SEND_PRODUCT_SETTINGS,
    payload: {
      formData,
      cat_id,
      login_key,
      user_id,
      product_id,
      shop_id,
      just_title_and_price,
      app_title,
      app_description,
      web_title,
      web_description,
      kiosk_title,
      kiosk_description,
      second_language,
      app_visible,
      web_visible,
      kiosk_visible,
      amount_delivery,
      amount_collection,
      amount_takeaway,
      amount_eatIn,
      printer_id,
      report_section,
      is_show_options,
      product_group,
      vat_id,
      is_suggested,
      is_popular,
      is_dif_option_vat,
      unit_id,
      barcode,
      custom_title,
      close_count,
      custom_title_in_last,
      title_in_last,
      link_to,
      is_auto_display_sub_menu,
      print_label,
      app_sort_id,
      web_sort_id,
      kiosk_sort_id,
      app_bg_color,
      app_title_font_size,
      app_title_font_family,
      app_title_color,
      app_tile_width,
      app_tile_height,
      app_image_url,
      app_visible_image,
      web_image_url,
      web_visible_image,
      kiosk_image_url,
      kiosk_visible_image,
      default_vat_for_all_option,
    },
  };
};
export const sendProductSettingsSuccessfulAction = (resultMesage) => {
  return {
    type: actionTypes.SEND_PRODUCT_SETTINGS_SUCCESSFULL,
    payload: { resultMesage },
  };
};
export const sendProductSettingsFailedAction = (data) => {
  return {
    type: actionTypes.SEND_PRODUCT_SETTINGS_FAILED,
    payload: data,
  };
};

// PRODUCT OPTIONS
export const getProductOptionsAction = (
  login_key,
  user_id,
  shop_id,
  product_id,
  cat_id
) => {
  return {
    type: actionTypes.GET_PRODUCT_OPTION,
    payload: { login_key, user_id, shop_id, product_id, cat_id },
  };
};
export const getProductOptionsSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_OPTION_SUCCESSFULL,
    payload: data,
  };
};
export const selectedProductOptionListAction = (data) => {
  return {
    type: actionTypes.SELECTED_PRODUCT_OPTION_LIST,
    payload: data,
  };
};
export const getProductOptionsFailedAction = () => {
  return {
    type: actionTypes.GET_PRODUCT_OPTION_FAILED,
  };
};

// DELETE PRODUCT
export const deleteProductAction = (
  login_key,
  user_id,
  shop_id,
  product_id,
  cat_id
) => {
  return {
    type: actionTypes.DELETE_PRODUCT,
    payload: { login_key, user_id, shop_id, product_id, cat_id },
  };
};
export const deleteProductSuccessfulAction = (data) => {
  return {
    type: actionTypes.DELETE_PRODUCT_SUCCESSFULL,
    payload: data,
  };
};
export const deleteProductFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_PRODUCT_FAILED,
    payload: data,
  };
};
// DELETE PRODUCT SUBMENU
export const deleteProductSubmenuAction = (
  login_key,
  user_id,
  shop_id,
  product_id,
  product_submenu_id
) => {
  return {
    type: actionTypes.DELETE_PRODUCT_SUBMENU,
    payload: { login_key, user_id, shop_id, product_id, product_submenu_id },
  };
};
export const deleteProductSubmenuSuccessfulAction = (data) => {
  return {
    type: actionTypes.DELETE_PRODUCT_SUBMENU_SUCCESSFULL,
    payload: data,
  };
};
export const deleteProductSubmenuFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_PRODUCT_SUBMENU_FAILED,
    payload: data,
  };
};
//PRODUCT SUBMENU
export const getProductSubmenuAction = (
  login_key,
  user_id,
  shop_id,
  product_id
) => {
  return {
    type: actionTypes.GET_PRODUCT_SUBMENU,
    payload: { login_key, user_id, shop_id, product_id },
  };
};
export const getProductSubmenuSuccessfulAction = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_SUBMENU_SUCCESSFULL,
    payload: data,
  };
};
export const getProductSubmenuFailedAction = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_SUBMENU_FAILED,
    payload: data,
  };
};
//ADD PRODUCT SUBMENU
export const addProductSubmenuAction = (
  login_key,
  user_id,
  shop_id,
  submenu_id,
  product_id
) => {
  return {
    type: actionTypes.ADD_PRODUCT_SUBMENU,
    payload: { login_key, user_id, shop_id, submenu_id, product_id },
  };
};
export const addProductSubmenuSuccessfulAction = (data) => {
  return {
    type: actionTypes.ADD_PRODUCT_SUBMENU_SUCCESSFULL,
    payload: data,
  };
};
export const addProductSubmenuFailedAction = (data) => {
  return {
    type: actionTypes.ADD_PRODUCT_SUBMENU_FAILED,
    payload: data,
  };
};
// POST PRODUCT OPTION
export const sendProductOptionEditAction = (
  login_key,
  user_id,
  id,
  shop_id,
  product_id,
  option_id,
  app_title,
  web_title,
  kiosk_title,
  amount_delivery,
  amount_collection,
  amount_takeaway,
  amount_eatIn,
  vat_id,
  stock,
  swap_title_position,
  app_visible,
  web_visible,
  kisok_visible,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  just_title_and_price,
  cat_id,
  calories
) => {
  return {
    type: actionTypes.SEND_PRODUCT_OPTION_EDIT,
    payload: {
      login_key,
      user_id,
      id,
      shop_id,
      product_id,
      option_id,
      app_title,
      web_title,
      kiosk_title,
      amount_delivery,
      amount_collection,
      amount_takeaway,
      amount_eatIn,
      vat_id,
      stock,
      swap_title_position,
      app_visible,
      web_visible,
      kisok_visible,
      app_sort_id,
      web_sort_id,
      kiosk_sort_id,
      just_title_and_price,
      cat_id,
      calories,
    },
  };
};
export const sendProductOptionSuccessfullAction = (data) => {
  return {
    type: actionTypes.SEND_PRODUCT_OPTION_EDIT_SUCCESSFULL,
    payload: data,
  };
};
export const sendProductOptionEditFailedAction = (data) => {
  return {
    type: actionTypes.SEND_PRODUCT_OPTION_EDIT_FAILED,
    payload: data,
  };
};

export const updateProductSubmenuEditAction = (
  formData1,
  login_key,
  user_id,
  id,
  shop_id,
  product_id,
  submenu_id,
  app_title,
  app_description,
  web_title,
  web_description,
  kiosk_title,
  kiosk_description,
  second_language,
  is_free,
  amount,
  min_select,
  max_select,
  app_visible,
  web_visible,
  kiosk_visible,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  app_bg_color,
  app_title_font_size,
  app_title_font_family,
  app_title_color,
  app_tile_width,
  app_tile_height,
  is_first,
  printer_id,
  is_after,
  count_item_for_use_amount,
  app_image_url,
  app_visible_image,
  web_image_url,
  web_visible_image,
  kiosk_image_url,
  kiosk_visible_image,
  one_of_each_item
) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_EDIT,
    payload: {
      formData1,
      login_key,
      user_id,
      id,
      shop_id,
      product_id,
      submenu_id,
      app_title,
      app_description,
      web_title,
      web_description,
      kiosk_title,
      kiosk_description,
      second_language,
      is_free,
      amount,
      min_select,
      max_select,
      app_visible,
      web_visible,
      kiosk_visible,
      app_sort_id,
      web_sort_id,
      kiosk_sort_id,
      app_bg_color,
      app_title_font_size,
      app_title_font_family,
      app_title_color,
      app_tile_width,
      app_tile_height,
      is_first,
      printer_id,
      is_after,
      count_item_for_use_amount,
      app_image_url,
      app_visible_image,
      web_image_url,
      web_visible_image,
      kiosk_image_url,
      kiosk_visible_image,
      one_of_each_item,
    },
  };
};
export const updateProductSubmenuEditSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_EDIT_SUCCESSFULL,
    payload: data,
  };
};
export const updateProductSubmenuEditFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_EDIT_FAILED,
    payload: data,
  };
};
export const showProductAvailabilityPopupAction = (data) => {
  return {
    type: actionTypes.SHOW_PRODUCT_AVAILABILITY_POPUP,
    payload: data,
  };
};
