import React, { useState, useEffect } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { useDispatch, useSelector } from "react-redux";
import WorksStationStatus from "../../components/WorksStationStatus";
import { useLocation } from "react-router-dom";
import { selecteWorksStationsAction } from "../../redux/WorksStations/Actions";
import {
  primaryColor,
  white,
  main_nav_back_color,
  main_nav_button_color,
} from "../../constants/Colors";
import WorksStations from "./index";
const WorkStationsTAb = () => {
  const dispatch = useDispatch();
  const { login_key, user_id, profile_list } = useSelector(
    (state) => state.loginReducer
  );
  const { state } = useLocation();

  const { lang } = useSelector((state) => state.selectedLanguage);
  const [clickedItem, setClickedItem] = useState(1);
  const [userAccesses, setUserAccesses] = useState([]);
  const [rolesid, setRolesId] = useState([]);
  const [userType, setUserType] = useState(0);
  const [width1, setWidth1] = React.useState(window.innerWidth);

  useEffect(() => {
    if (profile_list) {
      if (profile_list.length > 0) {
        setUserAccesses(profile_list);
      }
    }
  }, [profile_list]);

  useEffect(() => {
    let roles_id = [];
    if (userAccesses) {
      if (userAccesses.length > 0) {
        for (let i = 0; i < userAccesses.length; i++) {
          roles_id.push(userAccesses[i].RoleId);
          setUserType(userAccesses[i].UserType);
        }
        setRolesId(roles_id);
      }
    }
  }, [userAccesses]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return width1 <= 560 ? (
    <div style={{ width: "100%" }}>
      {state.id == 1 ? <WorksStationStatus /> : <WorksStations />}
    </div>
  ) : (
    <div
      className="countaining-div countaining-div-11 mobile-container-workstation-tabs"
      id="countaining"
    >
      <div className="menu_list_header_container_tabel_list">
        <div className="main_nav_container">
          <div className="settings_tab_buttons_container">
            {userType == 10001201 ? (
              <button
                key={1}
                onClick={() => setClickedItem(1)}
                style={{
                  backgroundColor: clickedItem == 1 ? primaryColor : white,
                  color: clickedItem == 1 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 1 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                WorkStation status
              </button>
            ) : rolesid ? (
              rolesid.length > 0 ? (
                rolesid.includes(12) ? (
                  <button
                    key={1}
                    onClick={() => setClickedItem(1)}
                    style={{
                      backgroundColor: clickedItem == 1 ? primaryColor : white,
                      color: clickedItem == 1 ? white : main_nav_button_color,
                      borderWidth: clickedItem == 1 ? 0 : 0.3,
                      height: "40px",
                    }}
                    className="main_nav_buttons"
                  >
                    {lang.work_station_status}
                  </button>
                ) : null
              ) : null
            ) : null}

            {userType == 10001201 ? (
              <button
                key={2}
                onClick={() => {
                  setClickedItem(2);
                  dispatch(selecteWorksStationsAction(null));
                }}
                style={{
                  backgroundColor: clickedItem == 2 ? primaryColor : white,
                  color: clickedItem == 2 ? white : main_nav_button_color,
                  borderWidth: clickedItem == 2 ? 0 : 0.3,
                  height: "40px",
                }}
                className="main_nav_buttons"
              >
                {lang.works_station_list}
              </button>
            ) : rolesid ? (
              rolesid.length > 0 ? (
                rolesid.includes(8) ? (
                  <button
                    key={2}
                    onClick={() => setClickedItem(2)}
                    style={{
                      backgroundColor: clickedItem == 2 ? primaryColor : white,
                      color: clickedItem == 2 ? white : main_nav_button_color,
                      borderWidth: clickedItem == 2 ? 0 : 0.3,
                      height: "40px",
                    }}
                    className="main_nav_buttons"
                  >
                    {lang.works_station_list}
                  </button>
                ) : null
              ) : null
            ) : null}
          </div>
        </div>

        {clickedItem == 1 ? (
          <WorksStationStatus />
        ) : clickedItem == 2 ? (
          <WorksStations />
        ) : null}

        {/* {loading_appSettings ? <Loader /> : null} */}
      </div>
      <div></div>
    </div>
  );
};

export default WorkStationsTAb;
