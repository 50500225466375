export const english_users_menu = [
  {
    id: 1,
    title: "Owners",
    icon: "fa fa-user",
    expanded: false,
    path: "",
    sublist: [],
  },
  {
    id: 2,
    title: "Employees",
    icon: "fa fa-user-circle-o",
    expanded: false,
    path: "employees",
    sublist: [],
  },
  {
    id: 3,
    title: "Drivers",
    icon: "fa-solid fa-motorcycle",
    expanded: false,
    path: "drivers",
    sublist: [],
  },
  {
    id: 4,
    title: "Shop group",
    icon: "fa-solid fa-shop",

    expanded: false,
    path: "shopGroups",
    sublist: [],
  },
];
export const turkish_users_menu = [];
export const persian_users_menu = [];
