import React, { useState, useEffect } from "react";
import ListHeader from "../../../components/ListHeader";
import { useDispatch, useSelector } from "react-redux";
import { main_nav_button_color } from "../../../constants/Colors";
import LicenceList from "./LicenceList";
import ViewLicences from "./ViewLicences";
import Loader from "../../../components/Loader";
const Licence = () => {
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { licencs_list, loading_devices_worksStations, loading_devices } =
    useSelector((state) => state.devicesWorkStationReduser);
  const [clickedItem, setClickedItem] = useState(1);
  return (
    <div
      className="countaining-div common-styles"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
      id="countaining"
    >
      <div>
        {clickedItem == 1 ? (
          <LicenceList setClickedItem={(i) => setClickedItem(i)} />
        ) : (
          <ViewLicences setClickedItem={(i) => setClickedItem(i)} />
        )}
        {loading_devices_worksStations ? <Loader /> : null}
      </div>
    </div>
  );
};

export default Licence;
