import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import store from "store2";
import {
  primaryColor,
  main_nav_button_color,
  main_nav_back_color,
} from "../../../../constants/Colors";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getWorksStationsAction } from "../../../../redux/WorksStations/Actions";
import {
  getNumberOfPrintsListAction,
  updateNumberOfPrintsAction,
} from "../../../../redux/settings/Action";
const NumberOfPrints = forwardRef((props, forwardedRef) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };
  const dispatch = useDispatch();
  const printer_Number_Copy_list = store.get("printer_Number_Copy_list");
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { selected_worksStations_list, worksStation_list } = useSelector(
    (state) => state.worksStationsReduser
  );
  const { number_of_prints_list } = useSelector(
    (state) => state.appSettingsRedusers
  );
  const [worksStationList, setWorksStationList] = useState([]);
  const [workStation, setWorkStation] = useState(
    worksStation_list
      ? worksStation_list.length > 0
        ? {
            label: worksStation_list[0].WorkStationName,
            value: worksStation_list[0].WorkStationId,
          }
        : null
      : null
  );
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [numberOfDeliveryPrint, setNumberOfDeliveryPrint] = useState(1);
  const [numberOfCollectionPrint, setNumberOfCollectionPrint] = useState(1);
  const [numberOfTakeawayPrint, setNumberOfTakeawayPrint] = useState(1);
  const [numberOfEatInPrint, setNumberOfEatInPrint] = useState(1);
  const [scrollStatus, setScrollStatus] = useState(false);
  const handleIncreaseNumberOfPrints = (id) => {
    let sum = 0;
    if (id == 1) {
      if (numberOfDeliveryPrint < 3) {
        sum = numberOfDeliveryPrint + 1;
        setNumberOfDeliveryPrint(sum);
      }
    } else if (id == 2) {
      if (numberOfCollectionPrint < 3) {
        sum = numberOfCollectionPrint + 1;
        setNumberOfCollectionPrint(sum);
      }
    } else if (id == 3) {
      if (numberOfTakeawayPrint < 3) {
        sum = numberOfTakeawayPrint + 1;
        setNumberOfTakeawayPrint(sum);
      }
    } else {
      if (numberOfEatInPrint < 3) {
        sum = numberOfEatInPrint + 1;
        setNumberOfEatInPrint(sum);
      }
    }
  };
  const handleDecreaseNumberOfPrints = (id) => {
    let sum = 0;
    if (id == 1) {
      if (numberOfDeliveryPrint > 1) {
        sum = numberOfDeliveryPrint - 1;
        setNumberOfDeliveryPrint(sum);
      }
    } else if (id == 2) {
      if (numberOfCollectionPrint > 1) {
        sum = numberOfCollectionPrint - 1;
        setNumberOfCollectionPrint(sum);
      }
    } else if (id == 3) {
      if (numberOfTakeawayPrint > 1) {
        sum = numberOfTakeawayPrint - 1;
        setNumberOfTakeawayPrint(sum);
      }
    } else {
      if (numberOfEatInPrint > 1) {
        sum = numberOfEatInPrint - 1;
        setNumberOfEatInPrint(sum);
      }
    }
  };
  const handleUpdateNumberOfPrints = () => {
    if (workStation.value) {
      dispatch(
        updateNumberOfPrintsAction(
          login_key,
          user_id,
          selected_shop[0].ShopId,
          workStation ? workStation.value : 0,
          numberOfDeliveryPrint,
          numberOfCollectionPrint,
          numberOfTakeawayPrint,
          numberOfEatInPrint
        )
      );
    }
  };

  const handleChangeWorkStation = (item, list) => {
    setWorkStation(item);
    dispatch(
      getNumberOfPrintsListAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        item.value
      )
    );
  };

  useImperativeHandle(forwardedRef, () => ({
    saveSettings: () => {
      handleUpdateNumberOfPrints();
    },
  }));

  useEffect(() => {
    let work_station = [];
    if (worksStation_list) {
      if (worksStation_list.length > 0) {
        for (let i = 0; i < worksStation_list.length; i++) {
          work_station.push({
            label: worksStation_list[i].WorkStationName,
            value: worksStation_list[i].WorkStationId,
          });
        }
      }
    }
    setWorksStationList(work_station);
  }, [worksStation_list]);

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getWorksStationsAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    let copy_worksStationList = [...worksStationList];
    let WSIds = [];

    let work_station = null;
    if (copy_worksStationList) {
      if (copy_worksStationList.length > 0) {
        for (let i = 0; i < copy_worksStationList.length; i++) {
          WSIds.push(copy_worksStationList[i].value);
        }
      }
    }

    if (worksStation_list) {
      if (worksStation_list.length > 0) {
        if (number_of_prints_list) {
          if (number_of_prints_list.length > 0) {
            setNumberOfDeliveryPrint(number_of_prints_list[0].DeliveryCount);
            setNumberOfCollectionPrint(
              number_of_prints_list[0].CollectionCount
            );
            setNumberOfTakeawayPrint(number_of_prints_list[0].TakeawayCount);
            setNumberOfEatInPrint(number_of_prints_list[0].EatInCount);
            if (number_of_prints_list[0].WSId) {
              if (WSIds.includes(parseInt(number_of_prints_list[0].WSId))) {
                let index = WSIds.indexOf(
                  parseInt(number_of_prints_list[0].WSId)
                );
                work_station = {
                  label: copy_worksStationList[index].label,
                  value: copy_worksStationList[index].value,
                };
              }
              setWorkStation(work_station);
            }
          } else {
            setNumberOfDeliveryPrint(1);
            setNumberOfCollectionPrint(1);
            setNumberOfTakeawayPrint(1);
            setNumberOfEatInPrint(1);
            setWorkStation(
              worksStation_list
                ? worksStation_list.length > 0
                  ? {
                      label: worksStation_list[0].WorkStationName,
                      value: worksStation_list[0].WorkStationId,
                    }
                  : null
                : null
            );
          }
        } else {
          setNumberOfDeliveryPrint(1);
          setNumberOfCollectionPrint(1);
          setNumberOfTakeawayPrint(1);
          setNumberOfEatInPrint(1);
          setWorkStation(
            worksStation_list
              ? worksStation_list.length > 0
                ? {
                    label: worksStation_list[0].WorkStationName,
                    value: worksStation_list[0].WorkStationId,
                  }
                : null
              : null
          );
        }
      } else {
        setNumberOfDeliveryPrint(1);
        setNumberOfCollectionPrint(1);
        setNumberOfTakeawayPrint(1);
        setNumberOfEatInPrint(1);
        setWorkStation(null);
      }
    } else {
      setNumberOfDeliveryPrint(1);
      setNumberOfCollectionPrint(1);
      setNumberOfTakeawayPrint(1);
      setNumberOfEatInPrint(1);
      setWorkStation(null);
    }
  }, [number_of_prints_list, worksStation_list]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleUpdateNumberOfPrints();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    workStation,
    numberOfDeliveryPrint,
    numberOfCollectionPrint,
    numberOfEatInPrint,
    numberOfTakeawayPrint,
  ]);
  useEffect(() => {
    if (scrollStatus) {
      document.getElementById("container").scroll({
        top: document.getElementById("container").scrollHeight,
        behavior: "smooth",
      });
    }
  }, [scrollStatus]);
  return (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container" id="container">
        <div className="margin_menu_normal_horizontal">
          <div className="nomber_of_prints_container">
            <p>{lang.sale_method}</p>

            <p>{lang.number_of_prints}</p>
          </div>
          <div>
            <div className="number_of_prints_container_sale_method">
              <h5>{lang.delivery}</h5>
              <div className="number_of_prints_container_minus_plus">
                <div
                  className="number_of_prints_container_icon"
                  onClick={() => handleDecreaseNumberOfPrints(1)}
                  // style={{
                  //   cursor: numberOfDeliveryPrint == 1 ? "auto" : "pointer",
                  // }}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </div>
                <h5>{numberOfDeliveryPrint}</h5>
                <div
                  className="number_of_prints_container_icon"
                  onClick={() => handleIncreaseNumberOfPrints(1)}
                  // style={{
                  //   cursor: numberOfDeliveryPrint == 3 ? "auto" : "pointer",
                  // }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <hr className="number_of_prints_hr"></hr>
            <div className="number_of_prints_container_sale_method">
              <h5>{lang.collection}</h5>
              <div className="number_of_prints_container_minus_plus">
                <div
                  className="number_of_prints_container_icon"
                  onClick={() => handleDecreaseNumberOfPrints(2)}
                  // style={{
                  //   cursor: numberOfCollectionPrint == 1 ? "auto" : "pointer",
                  // }}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </div>
                <h5>{numberOfCollectionPrint}</h5>
                <div
                  className="number_of_prints_container_icon"
                  onClick={() => handleIncreaseNumberOfPrints(2)}
                  // style={{
                  //   cursor: numberOfCollectionPrint == 3 ? "auto" : "pointer",
                  // }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <hr className="number_of_prints_hr"></hr>
            <div className="number_of_prints_container_sale_method">
              <h5>{lang.takeaway}</h5>
              <div className="number_of_prints_container_minus_plus">
                <div
                  className="number_of_prints_container_icon"
                  onClick={() => handleDecreaseNumberOfPrints(3)}
                  // style={{
                  //   cursor: numberOfTakeawayPrint == 1 ? "auto" : "pointer",
                  // }}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </div>
                <h5>{numberOfTakeawayPrint}</h5>
                <div
                  className="number_of_prints_container_icon"
                  onClick={() => handleIncreaseNumberOfPrints(3)}
                  // style={{
                  //   cursor: numberOfTakeawayPrint == 3 ? "auto" : "pointer",
                  // }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
              </div>
            </div>{" "}
            <hr className="number_of_prints_hr"></hr>
            <div className="number_of_prints_container_sale_method">
              <h5>{lang.eat_in}</h5>
              <div className="number_of_prints_container_minus_plus">
                <div
                  className="number_of_prints_container_icon"
                  onClick={() => handleDecreaseNumberOfPrints(4)}
                  // style={{
                  //   cursor: numberOfEatInPrint == 1 ? "auto" : "pointer",
                  // }}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </div>
                <h5>{numberOfEatInPrint}</h5>
                <div
                  className="number_of_prints_container_icon"
                  onClick={() => handleIncreaseNumberOfPrints(4)}
                  // style={{
                  //   cursor: numberOfEatInPrint == 3 ? "auto" : "pointer",
                  // }}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="nomber_of_prints_container_workStation_dropDown">
            <h6>* {lang.works_station}</h6>
            <Select
              onMenuOpen={() => setScrollStatus(true)}
              onMenuClose={() => setScrollStatus(false)}
              options={worksStationList}
              isSearchable={false}
              onChange={(list, item) => handleChangeWorkStation(list)}
              value={workStation}
              styles={customStyles}
              defaultValue={workStation}
              isClearable={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default NumberOfPrints;
