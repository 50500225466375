import * as actionTypes from "../ActionTypes";

const initialStates = {
  orders_list: [],
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_orders: false,
  show_order_list_popup: false,
  get_order_detail_list: [],
  get_order_app_shops_pending_list: [],
  get_order_app_shops_failed_list: [],
  get_order_app_shops_inComplete_list: [],
  get_order_app_shops_notPrinted_list: [],
  get_order_app_shops_rejected_list: [],
  selected_order_list: null,
  back_to_order: false,
  about_us_list: [],
  short_cut_button_list: [],
};

const ordersReduser = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_orders: false,
      };
    case actionTypes.ORDERS_LIST:
      return {
        ...state,
        loading_orders: true,
      };
    case actionTypes.ORDERS_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_orders: false,
        orders_list: action.payload,
      };
    case actionTypes.ORDERS_LIST_FAILED:
      return {
        ...state,
        loading_orders: false,
        orders_list: [],
      };
    case actionTypes.SELECTED_ORDER_LIST:
      return {
        ...state,
        selected_order_list: action.payload,
      };
    case actionTypes.SHOW_ORDER_LIST_POPUP:
      let loading;

      if (action.payload == false) {
        loading = false;
      }
      return {
        ...state,
        show_order_list_popup: action.payload,
        loading_orders: loading,
      };

    case actionTypes.GET_ORDER_DETAIL:
      return {
        ...state,
        loading_orders: true,
      };
    case actionTypes.GET_ORDER_DETAIL_SUCCESSFULL:
      return {
        ...state,
        loading_orders: false,
        get_order_detail_list: action.payload,
      };
    case actionTypes.GET_ORDER_DETAIL_FAILED:
      return {
        ...state,
        get_order_detail_list: [],
      };
    case actionTypes.GET_ORDER_LIST_APP_SHOPS:
      return {
        ...state,
        loading_orders: true,
      };
    case actionTypes.GET_ORDER_LIST_APP_SHOPS_FAILED_SUCCESSFULL:
      return {
        ...state,
        loading_orders: false,
        get_order_app_shops_failed_list: action.payload,
      };
    case actionTypes.GET_ORDER_LIST_APP_SHOPS_PENDING_SUCCESSFULL:
      return {
        ...state,
        loading_orders: false,
        get_order_app_shops_pending_list: action.payload,
      };
    case actionTypes.GET_ORDER_LIST_APP_SHOPS_INCOMPLETE_SUCCESSFULL:
      return {
        ...state,
        loading_orders: false,
        get_order_app_shops_inComplete_list: action.payload,
      };
    case actionTypes.GET_ORDER_LIST_APP_SHOPS_NOTPRINTED_SUCCESSFULL:
      return {
        ...state,
        loading_orders: false,
        get_order_app_shops_notPrinted_list: action.payload,
      };
    case actionTypes.GET_ORDER_LIST_APP_SHOPS_REJECTED_SUCCESSFULL:
      return {
        ...state,
        loading_orders: false,
        get_order_app_shops_rejected_list: action.payload,
      };
    case actionTypes.GET_ORDER_LIST_APP_SHOPS_FAILED:
      return {
        ...state,
        loading_orders: false,
        result_failed: true,
        result_message: action.payload,
        get_order_app_shops_pending_list: [],
        get_order_app_shops_failed_list: [],
        get_order_app_shops_inComplete_list: [],
      };
    case actionTypes.GET_SHORT_CUT_BUTTON_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GET_SHORT_CUT_BUTTON_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        short_cut_button_list: action.payload,
      };
    case actionTypes.GET_SHORT_CUT_BUTTON_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        short_cut_button_list: [],
      };
    case actionTypes.UPDATE_SHORT_CUT_BUTTON:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.UPDATE_SHORT_CUT_BUTTON_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_SHORT_CUT_BUTTON_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };

    //ABOUT US
    case actionTypes.GET_ABOUT_US_LIST:
      return {
        ...state,
        loading_post_code: true,
      };
    case actionTypes.GET_ABOUT_US_LIST_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        about_us_list: action.payload,
      };
    case actionTypes.GET_ABOUT_US_LIST_FAILED:
      return {
        ...state,
        loading_post_code: false,
        about_us_list: [],
      };
    case actionTypes.UPDATE_ABOUT_US:
      return {
        ...state,
        loading_post_code: true,
        result_successfull: false,
        result_failed: false,
        result_message: "",
      };
    case actionTypes.UPDATE_ABOUT_US_SUCCESSFULL:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
      };
    case actionTypes.UPDATE_ABOUT_US_FAILED:
      return {
        ...state,
        loading_post_code: false,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
      };

    case actionTypes.BACK_TO_ORDER:
      return {
        ...state,
        back_to_order: action.payload,
      };
    default:
      return state;
  }
};
export default ordersReduser;
