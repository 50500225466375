import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
const Loader = () => {
  let [color, setColor] = useState("red");
  return (
    <div className="loader_component_container">
      <ClipLoader
        color={color}
        loading={true}
        cssOverride={override}
        size={50}
      />
    </div>
  );
};

export default Loader;
