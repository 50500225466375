import axios from "axios";
import { baseUrl } from "../constants/BaseUrl";

//GET PRODUCT
export const getProductServices = async (login_key, user_id, shopId, catId) => {
  return await axios.put(`${baseUrl}productslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    CategoryId: catId,
  });
};

//CREATE PRODUCT
export const createProductServices = async (
  login_key,
  user_id,
  shopId,
  productId,
  title,
  description,
  amount
) => {
  return await axios.post(`${baseUrl}createproduct/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shopId,
    CategoryId: productId,
    Title: title,
    Description: description,
    MainPrice: amount,
  });
};
//SEND PRODCUT EDIT

export const sendProductSettingsServices = async (
  formData,
  login_key,
  user_id,
  product_id,
  shop_id,
  app_title,
  app_description,
  web_title,
  web_description,
  kiosk_title,
  kiosk_description,
  second_language,
  app_visible,
  web_visible,
  kiosk_visible,
  amount_delivery,
  amount_collection,
  amount_takeaway,
  amount_eatIn,
  printer_id,
  report_section,
  is_show_options,
  product_group,
  vat_id,
  is_suggested,
  is_popular,
  is_dif_option_vat,
  unit_id,
  barcode,
  custom_title,
  close_count,
  custom_title_in_last,
  title_in_last,
  link_to,
  is_auto_display_sub_menu,
  print_label,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  app_bg_color,
  app_title_font_size,
  app_title_font_family,
  app_title_color,
  app_tile_width,
  app_tile_height,
  app_image_url,
  app_visible_image,
  web_image_url,
  web_visible_image,
  kiosk_image_url,
  kiosk_visible_image,
  default_vat_for_all_option
) => {
  // formData.append("LoginKey", login_key);
  // formData.append("UserId", user_id);
  // formData.append("Id", product_id);
  // formData.append("ShopId", shop_id);
  // formData.append("AppTitle", app_title);
  // formData.append("AppDescription", app_description);
  // formData.append("WebTitle", web_title);
  // formData.append("WebDescription", web_description);
  // formData.append("KioskTitle", kiosk_title);
  // formData.append("KioskDescription", kiosk_description);
  // formData.append("SecondLanguage", second_language);
  // formData.append("AppVisible", app_visible);
  // formData.append("WebVisible", web_visible);
  // formData.append("KioskVisible", kiosk_visible);
  // formData.append("AmountDelivery", amount_delivery);
  // formData.append("AmountCollection", amount_collection);
  // formData.append("AmountTakeaway", amount_takeaway);
  // formData.append("AmountEatIn", amount_eatIn);
  // formData.append("PrinterId", printer_id);
  // formData.append("ReportSection", report_section);
  // formData.append("IsShowOptions", is_show_options);
  // formData.append("ProductGroup", product_group);
  // formData.append("VatId", vat_id);
  // formData.append("IsSugessted", is_suggested);
  // formData.append("IsPopular", is_popular);
  // formData.append("IsDifOptionVat", is_dif_option_vat);
  // formData.append("UnitId", unit_id);
  // formData.append("Barcode", barcode);
  // formData.append("CustomTitle", custom_title);
  // formData.append("CloseCount", close_count);
  // formData.append("CustomTitleInLast", custom_title_in_last);
  // formData.append("TitleInLast", title_in_last);
  // formData.append("linkto", link_to);
  // formData.append("IsAutoDisplaySubMenu", is_auto_display_sub_menu);
  // formData.append("PrintLabel", print_label);
  // formData.append("AppSortId", app_sort_id);
  // formData.append("WebSortId", web_sort_id);
  // formData.append("KioskSortId", kiosk_sort_id);
  // formData.append("AppBgColor", app_bg_color);
  // formData.append("AppTitleFontSize", app_title_font_size);
  // formData.append("AppTitleFontFamily", app_title_font_family);
  // formData.append("AppTitleColor", app_title_color);
  // formData.append("AppTileWidth", app_tile_width);
  // formData.append("AppTileHeight", app_tile_height);
  // formData.append("AppImageUrl", app_image_url);
  // formData.append("AppVisibleImage", app_visible_image);
  // formData.append("WebImageUrl", web_image_url);
  // formData.append("WebVisibleImage", web_visible_image);
  // formData.append("KioskImageUrl", kiosk_image_url);
  // formData.append("KioskVisibleImage", kiosk_visible_image);
  // formData.append("SetDefaultVatForAllOptions", default_vat_for_all_option);

  return await axios.post(
    `${baseUrl}updateproduct/`,
    formData

    //  {
    //   LoginKey: login_key,
    //   UserId: user_id,
    //   Id: product_id,
    //   ShopId: shop_id,
    //   AppTitle: app_title,
    //   AppDescription: app_description,
    //   WebTitle: web_title,
    //   WebDescription: web_description,
    //   KioskTitle: kiosk_title,
    //   KioskDescription: kiosk_description,
    //   SecondLanguage: second_language,
    //   AppVisible: app_visible,
    //   WebVisible: web_visible,
    //   KioskVisible: kiosk_visible,
    //   AmountDelivery: amount_delivery,
    //   AmountCollection: amount_collection,
    //   AmountTakeaway: amount_takeaway,
    //   AmountEatIn: amount_eatIn,
    //   PrinterId: printer_id,
    //   ReportSection: report_section,
    //   IsShowOptions: is_show_options,
    //   ProductGroup: product_group,
    //   VatId: vat_id,
    //   IsDifOptionVat: is_dif_option_vat,
    //   UnitId: unit_id,
    //   Barcode: barcode,
    //   CustomTitle: custom_title,
    //   CloseCount: close_count,
    //   CustomTitleInLast: custom_title_in_last,
    //   TitleInLast: title_in_last,
    //   linkto: link_to,
    //   IsAutoDisplaySubMenu: is_auto_display_sub_menu,
    //   PrintLabel: print_label,
    //   AppSortId: app_sort_id,
    //   WebSortId: web_sort_id,
    //   KioskSortId: kiosk_sort_id,
    //   AppBgColor: app_bg_color,
    //   AppTitleFontSize: app_title_font_size,
    //   AppTitleFontFamily: app_title_font_family,
    //   AppTitleColor: app_title_color,
    //   AppTileWidth: app_tile_width,
    //   AppTileHeight: app_tile_height,
    //   AppImageUrl: app_image_url,
    //   AppVisibleImage: app_visible_image,
    //   WebImageUrl: web_image_url,
    //   WebVisibleImage: web_visible_image,
    //   KioskImageUrl: kiosk_image_url,
    //   KioskVisibleImage: kiosk_visible_image,
    //   SetDefaultVatForAllOptions:default_vat_for_all_option

    // }
  );
  // formData.delete("AppImageUrl");
};

// DELETE PRODUCT
export const deleteProductService = async (
  login_key,
  user_id,
  shop_id,
  product_id
) => {
  return await axios.delete(`${baseUrl}deleteproduct/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      ProductId: product_id,
    },
  });
};
// GET PRODUCT OPTION
export const getProductOptionsService = async (
  login_key,
  user_id,
  shop_id,
  product_id
) => {
  return await axios.put(`${baseUrl}productoptionslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductId: product_id,
  });
};
// GET PRODUCT SUBMENU
export const getProductSubmenuService = async (
  login_key,
  user_id,
  shop_id,
  product_id
) => {
  return await axios.put(`${baseUrl}productsubmenuslist/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    ProductId: product_id,
  });
};
// ADD PRODUCT SUBMENU
export const addProductSubmenuService = async (
  login_key,
  user_id,
  shop_id,
  submenu_id,
  product_id
) => {
  return await axios.post(`${baseUrl}createproductsubmenu/`, {
    LoginKey: login_key,
    UserId: user_id,
    ShopId: shop_id,
    SubmenuId: submenu_id,
    ProductId: product_id,
  });
};
// DELETE PRODUCT SUBMENU
export const deleteProductSubmenuService = async (
  login_key,
  user_id,
  shop_id,
  product_id,
  product_submenu_id
) => {
  return await axios.delete(`${baseUrl}deleteproductsubmenu/`, {
    headers: {
      Authorization: "***",
    },
    data: {
      LoginKey: login_key,
      UserId: user_id,
      ShopId: shop_id,
      ProductId: product_id,
      ProductSubmenuId: product_submenu_id,
    },
  });
};

// UPDATE PRODUCT OPTION EDIT
export const sendProductOptionEditServices = async (
  login_key,
  user_id,
  id,
  shop_id,
  product_id,
  option_id,
  app_title,
  web_title,
  kiosk_title,
  amount_delivery,
  amount_collection,
  amount_takeaway,
  amount_eatIn,
  vat_id,
  stock,
  swap_title_position,
  app_visible,
  web_visible,
  kisok_visible,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  just_title_and_price,

  calories
) => {
  return await axios.put(`${baseUrl}updateproductoption/`, {
    LoginKey: login_key,
    UserId: user_id,
    Id: id,
    ShopId: shop_id,
    ProductId: product_id,
    OptionId: option_id,
    AppTitle: app_title,
    WebTitle: web_title,
    KioskTitle: kiosk_title,
    AmountDelivery: amount_delivery,
    AmountCollection: amount_collection,
    AmountTakeaway: amount_takeaway,
    AmountEatIn: amount_eatIn,
    VatId: vat_id,
    Stock: stock,
    SwapTitlePosition: swap_title_position,
    AppVisible: app_visible,
    WebVisible: web_visible,
    KioskVisible: kisok_visible,
    AppSortId: app_sort_id,
    WebSortId: web_sort_id,
    KioskSortId: kiosk_sort_id,
    JustTitleAndPrice: just_title_and_price,
    Calories: calories,
  });
};
const formData1 = new FormData();
export const updateProductSubmenuEditServices = async (
  formData1,
  login_key,
  user_id,
  id,
  shop_id,
  product_id,
  submenu_id,
  app_title,
  app_description,
  web_title,
  web_description,
  kiosk_title,
  kiosk_description,
  second_language,
  is_free,
  amount,
  min_select,
  max_select,
  app_visible,
  web_visible,
  kiosk_visible,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  app_bg_color,
  app_title_font_size,
  app_title_font_family,
  app_title_color,
  app_tile_width,
  app_tile_height,
  is_first,
  printer_id,
  is_after,
  count_item_for_use_amount,
  app_image_url,
  app_visible_image,
  web_image_url,
  web_visible_image,
  kiosk_image_url,
  kiosk_visible_image,
  one_of_each_item
) => {
  // formData1.append("LoginKey", login_key);
  // formData1.append("UserId", user_id);
  // formData1.append("Id", id);
  // formData1.append("ShopId", shop_id);
  // formData1.append("ProductId", product_id);
  // formData1.append("SubMenuId", submenu_id);
  // formData1.append("AppTitle", app_title);
  // formData1.append("AppDescription", app_description);
  // formData1.append("WebTitle", web_title);
  // formData1.append("WebDescription", web_description);
  // formData1.append("KioskTitle", kiosk_title);
  // formData1.append("KioskDescription", kiosk_description);
  // formData1.append("SecondLanguage", second_language);
  // formData1.append("IsFree", is_free);
  // formData1.append("Amount", amount);
  // formData1.append("MinSelect", min_select);
  // formData1.append("MaxSelect", max_select);
  // formData1.append("AppVisible", app_visible);
  // formData1.append("WebVisible", web_visible);
  // formData1.append("KioskVisible", kiosk_visible);
  // formData1.append("AppSortId", app_sort_id);
  // formData1.append("WebSortId", web_sort_id);
  // formData1.append("KioskSortId", kiosk_sort_id);
  // formData1.append("AppBgColor", app_bg_color);
  // formData1.append("AppTitleFontSize", app_title_font_size);
  // formData1.append("AppTitleFontFamily", app_title_font_family);
  // formData1.append("AppTitleColor", app_title_color);
  // formData1.append("AppTileWidth", app_tile_width);
  // formData1.append("AppTileHeight", app_tile_height);
  // formData1.append("IsFrist", is_first);
  // formData1.append("PrinterId", printer_id);
  // formData1.append("IsAfter", is_after);
  // formData1.append("CountItemForUseAmount", count_item_for_use_amount);
  // formData1.append("AppImageUrl", app_image_url);
  // formData1.append("AppVisibleImage", app_visible_image);
  // formData1.append("WebImageUrl", web_image_url);
  // formData1.append("WebVisibleImage", web_visible_image);
  // formData1.append("KioskImageUrl", kiosk_image_url);
  // formData1.append("KioskVisibleImage", kiosk_visible_image);
  // formData1.append("OneOfEachItem", one_of_each_item);
  return await axios.post(
    `${baseUrl}updateproductsubmenu/`,
    formData1
    //  {
    //   LoginKey:login_key,
    //   UserId:user_id,
    //   Id: id,
    //   ShopId: shop_id,
    //   ProductId: product_id,
    //   SubMenuId: submenu_id,
    //   AppTitle: app_title,
    //   AppDescription: app_description,
    //   WebTitle: web_title,
    //   WebDescription: web_description,
    //   KioskTitle: kiosk_title,
    //   KioskDescription: kiosk_description,
    //   SecondLanguage: second_language,
    //   IsFree: is_free,
    //   Amount: amount,
    //   MinSelect: min_select,
    //   MaxSelect: max_select,
    //   AppVisible: app_visible,
    //   WebVisible: web_visible,
    //   KioskVisible: kiosk_visible,
    //   AppSortId: app_sort_id,
    //   WebSortId: web_sort_id,
    //   KioskSortId: kiosk_sort_id,
    //   AppBgColor: app_bg_color,
    //   AppTitleFontSize: app_title_font_size,
    //   AppTitleFontFamily: app_title_font_family,
    //   AppTitleColor: app_title_color,
    //   AppTileWidth: app_tile_width,
    //   AppTileHeight: app_tile_height,
    //   IsFrist: is_first,
    //   PrinterId: printer_id,
    //   IsAfter: is_after,
    //   CountItemForUseAmount: count_item_for_use_amount,
    //   AppImageUrl: app_image_url,
    //   AppVisibleImage: app_visible_image,
    //   WebImageUrl: web_image_url,
    //   WebVisibleImage: web_visible_image,
    //   KioskImageUrl: kiosk_image_url,
    //   KioskVisibleImage: kiosk_visible_image,
    // }
  );
};
