import {
  main_nav_button_color,
  primaryColor,
  white,
  main_nav_back_color,
} from "../../../constants/Colors";
import { useState, useEffect } from "react";
import useScrollbarSize from "react-scrollbar-size";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import HeaderList from "../../../components/HeaderList";
import { shopListByGroupIdAction } from "../../../redux/Users/Actions";
import OrderListItem from "../../../components/OrderListItem ";
import {
  getFinancialOrderListAction,
  visibleRefunPopupAction,
  getFinancialRefundListAction,
  visibleDebtPopupAndSendInfoAction,
  visibleCreateDebtPopupAction,
  sendDebtAction,
} from "../../../redux/financial/Actions";
import { marginNormalMenuHorizontal } from "../../../constants/Sizes";
import { setDefaultAction } from "../../../redux/menu/Actions";
import Loader from "../../../components/Loader";
import { debit_filter } from "../../../constants/MainFinancialMenu";
const Debt = () => {
  const { height, width } = useScrollbarSize();
  const dispatch = useDispatch();
  const { orders_list, loading_orders } = useSelector(
    (state) => state.ordersReduser
  );
  const { loading, financial_refund_list, financial_debt_list } = useSelector(
    (state) => state.financialReduser
  );
  const {
    selected_financial_order,
    result_message,
    result_successfull,
    result_failed,
  } = useSelector((state) => state.financialReduser);
  const { shop_list_by_group_id } = useSelector((state) => state.usersReduser);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [shopList, setShopList] = useState([]);
  const [financialOrderList, setFinancialOrderList] = useState([]);
  const [financialOrderList1, setFinancialOrderList1] = useState([]);
  const [debitFilter, setDebitFilter] = useState({ label: "Debit", value: 2 });
  const [filteredFromAllItems, setFilteredFromAllItems] = useState(false);
  const [selectedShop, setSelectedShop] = useState({
    GroupId: 0,
    value: 999999,
    label: "All",
    selected: false,
  });

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),
    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleGetList = () => {
    setDebitFilter({ label: "Debit", value: 2 });
    dispatch(
      getFinancialRefundListAction(
        login_key,
        user_id,
        selectedShop.value,
        moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
        moment(endDate).format("YYYY-MM-DD hh:mm:ss")
      )
    );
  };

  const handleVisibleDebtPopup = () => {
    if (debitFilter.value == 1) {
      setFilteredFromAllItems(true);
    }

    if (!selectedShop || selectedShop.value == 999999) {
      toast.error(<h5> &nbsp;&nbsp;{lang.choose_shop}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            className="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else {
      dispatch(
        visibleCreateDebtPopupAction([
          true,
          selectedShop.value,
          startDate,
          endDate,
        ])
      );
    }
  };

  const handleShowSendBox = (item) => {
    const copy_financialOrderLis = [...financialOrderList];
    if (copy_financialOrderLis) {
      if (copy_financialOrderLis.length > 0) {
        for (let i = 0; i < copy_financialOrderLis.length; i++) {
          if (item.Id == copy_financialOrderLis[i].Id) {
            copy_financialOrderLis[i].show_send_box = true;
          } else {
            copy_financialOrderLis[i].show_send_box = false;
          }
        }
      }
    }
    setFinancialOrderList(copy_financialOrderLis);
  };

  const hanldeCancelSendDebt = (item) => {
    const copy_financialOrderLis = [...financialOrderList];
    if (copy_financialOrderLis) {
      if (copy_financialOrderLis.length > 0) {
        for (let i = 0; i < copy_financialOrderLis.length; i++) {
          if (item.Id == copy_financialOrderLis[i].Id) {
            copy_financialOrderLis[i].show_send_box = false;
          }
        }
      }
    }
    setFinancialOrderList(copy_financialOrderLis);
  };
  const handleSendDebt = (item) => {
    if (debitFilter.value == 1) {
      setFilteredFromAllItems(true);
    }
    dispatch(
      sendDebtAction(
        login_key,
        user_id,
        item.ShopId,
        parseInt(item.RefoundAmount * 100),
        item.RefoundNote,
        item.RefoundId ? item.RefoundId.toString() : "",
        selectedShop.value,
        moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
        moment(endDate).format("YYYY-MM-DD hh:mm:ss")
      )
    );
  };

  const hanldeFilterDebit = (list, item) => {
    let my_list = [];
    setDebitFilter(list);
    const copy_financialOrderList = [...financialOrderList1];
    if (list.value == 1) {
      my_list = copy_financialOrderList;
    } else if (list.value == 2) {
      if (copy_financialOrderList) {
        if (copy_financialOrderList.length > 0) {
          for (let i = 0; i < copy_financialOrderList.length; i++) {
            if (copy_financialOrderList[i].StatusId == 1) {
              my_list.push(copy_financialOrderList[i]);
            }
          }
        }
      }
    } else if (list.value == 3) {
      if (copy_financialOrderList) {
        if (copy_financialOrderList.length > 0) {
          for (let i = 0; i < copy_financialOrderList.length; i++) {
            if (copy_financialOrderList[i].StatusId == 3) {
              my_list.push(copy_financialOrderList[i]);
            }
          }
        }
      }
    }
    setFinancialOrderList(my_list);
  };
  useEffect(() => {
    let myList = [];
    let new_list = [];
    if (shop_list_by_group_id) {
      if (shop_list_by_group_id.length > 0) {
        myList.push({
          GroupId: 0,
          ShopId: 999999,
          Title: "All",
          selected: false,
        });
        for (let i = 0; i < shop_list_by_group_id.length; i++) {
          myList.push({ ...shop_list_by_group_id[i] });
        }
      }
    }
    if (myList) {
      if (myList.length > 0) {
        for (let i = 0; i < myList.length; i++) {
          new_list.push({
            ...myList[i],
            label:
              myList[i].ShopId == 999999
                ? myList[i].Title
                : `${myList[i].ShopId}  ${myList[i].Title}`,
            value: myList[i].ShopId,
          });
        }
      }
    }
    setShopList(new_list);
  }, [shop_list_by_group_id]);

  useEffect(() => {
    dispatch(shopListByGroupIdAction(login_key, user_id, 0));
  }, []);

  useEffect(() => {
    if (login_key && user_id) {
      dispatch(
        getFinancialRefundListAction(
          login_key,
          user_id,
          selectedShop.value,
          moment(startDate).format("YYYY-MM-DD hh:mm:ss"),
          moment(endDate).format("YYYY-MM-DD hh:mm:ss")
        )
      );
    }
  }, []);

  useEffect(() => {
    let debt_list = [];
    let new_debt_list = [];
    if (financial_debt_list) {
      if (financial_debt_list.length > 0) {
        for (let i = 0; i < financial_debt_list.length; i++) {
          debt_list.push({ ...financial_debt_list[i], show_send_box: false });
        }
        if (debt_list) {
          if (debt_list.length > 0) {
            for (let i = 0; i < debt_list.length; i++) {
              if (filteredFromAllItems) {
                setFilteredFromAllItems(false);
                new_debt_list = debt_list;
              } else {
                if (debt_list[i].StatusId == 1) {
                  new_debt_list.push(debt_list[i]);
                  setDebitFilter({ label: "Debit", value: 2 });
                }
              }
            }
          }
        }
        setFinancialOrderList(new_debt_list);
        setFinancialOrderList1(debt_list);
      } else {
        setFinancialOrderList([]);
        setFinancialOrderList1([]);
      }
    } else {
      setFinancialOrderList([]);
      setFinancialOrderList1([]);
    }
  }, [financial_debt_list]);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      dispatch(setDefaultAction());
      if (result_message) {
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        height: window.innerHeight - 160,
      }}
      id="countaining"
    >
      <div style={{ overflowY: "none" }}>
        <div className="margin_menu_normal_horizontal">
          <div
            className="sm-div2"
            style={{
              marginTop: "10px",
              marginBottom: "0px",
            }}
          >
            <div style={{ width: "24%" }}>
              <h6 className="h6">{lang.shop_list}</h6>
              <Select
                options={shopList}
                defaultValue={selectedShop}
                isSearchable={true}
                onChange={(list, item) => setSelectedShop(list)}
                value={selectedShop}
                isMulti={false}
                styles={customStyles}
                // className="select"
              />
            </div>
            <div style={{ width: "15.5%" }}>
              <h6 className="h6">{lang.status}</h6>
              <Select
                options={debit_filter}
                defaultValue={debitFilter}
                isSearchable={false}
                onChange={(list, item) => hanldeFilterDebit(list, item)}
                value={debitFilter}
                isMulti={false}
                styles={customStyles}
                // className="select"
              />
            </div>
            <div style={{ width: "15.5%", height: "40px" }}>
              <h6 className="h6">{lang.from}</h6>

              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(e) => setStartDate(e)}
                className="date_picker"
                calendarClassName="calendarClassName"
              />
            </div>
            <div style={{ width: "15.5%", height: "40px" }}>
              <h6 className="h6">{lang.to}</h6>

              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={endDate}
                onChange={(e) => setEndDate(e)}
                className="date_picker"
              />
            </div>
            <div
              style={{
                width: "10%",
                height: "40px",
                marginTop: "27px",
              }}
              // onClick={handleSearchOrderByDate}
            >
              <button
                style={{
                  border: `1px solid ${primaryColor}`,
                  backgroundColor: primaryColor,
                  width: "100%",
                  color: white,
                  height: "100%",
                  borderRadius: "2px",
                }}
                onClick={handleGetList}
              >
                {lang.result}
              </button>
            </div>

            {/* STICKY ADD SUBMENU BUTTON */}
            <div
              style={{
                width: "15%",
                height: "40px",
                marginTop: "27px",
              }}
              onClick={handleVisibleDebtPopup}
            >
              <button
                style={{
                  backgroundColor: "red",
                  width: "100%",
                  color: white,
                  height: "100%",
                  border: "none",
                  borderRadius: "2px",
                }}
                onClick={handleGetList}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                {"   "}
                <span>{lang.create_debt}</span>
              </button>
            </div>
          </div>

          <div className="debt-container-main">
            <div>
              <HeaderList number={4} />
            </div>
            <div
              style={{
                flex: 1,
                overflowY: "auto",
                paddingBottom: "39px",
              }}
            >
              {financialOrderList ? (
                financialOrderList.length > 0 ? (
                  financialOrderList.map((item, index) => (
                    <OrderListItem
                      index1={index}
                      item={item}
                      order_id=""
                      invice_id=""
                      status=""
                      status_name=""
                      channels=""
                      requested_for={item.RefoundDate}
                      sale_mathod=""
                      Refound_Date={item.RefoundDate}
                      // store_name={item.Store_name}
                      RefoundNote={item.RefoundNote}
                      shop_name={item.ShopName}
                      post_code=""
                      order_amount={item.RefoundAmount.toFixed(2)}
                      payment_status=""
                      customer=""
                      sale_mathod_number=""
                      number={4}
                      on_click1={() => handleSendDebt(item)}
                      show_send_box_onclick={() => handleShowSendBox(item)}
                      close_send_box_onclick={() => hanldeCancelSendDebt(item)}
                    />
                  ))
                ) : (
                  <div
                    className="div_submenu_add_container "
                    style={{
                      zIndex: 1,
                    }}
                  >
                    <h4>{lang.noOption}</h4>
                    {/* <span
                      style={{
                        width: "172px",
                        backgroundColor: "rgb(234, 179, 40)",
                        border: "none",
                      }}
                      onClick={handleVisibleDebtPopup}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      <p>{lang.create_debt}</p>
                    </span> */}
                  </div>
                )
              ) : null}{" "}
            </div>
            {/* <div className="debt-container-create-debt"></div> */}
          </div>
        </div>
      </div>

      <Toaster />
      {loading ? <Loader /> : null}
    </div>
  );
};

export default Debt;
