import * as actionTypes from "../ActionTypes";

export const getUsersListAction = (login_key, user_id, user_type) => {
  return {
    type: actionTypes.GET_USERS_LIST,
    payload: { login_key, user_id, user_type },
  };
};
// export const getUsersListSuccessfullAction = (data) => {

//   return {
//     type: actionTypes.GET_USERS_LIST_SUCCESSFUL,
//     payload: data,
//   };
// };
export const getUsersListFailedAction = (data) => {
  return {
    type: actionTypes.GET_USERS_LIST_FAILED,
    payload: data,
  };
};

export const selectedUsersEmployeesAction = (data) => {
  return {
    type: actionTypes.SELECTED_USERS_EMPLOYEES,
    payload: data,
  };
};
export const selectedUsersOwnersAction = (data) => {
  return {
    type: actionTypes.SELECTED_USERS_OWNERS,
    payload: data,
  };
};
export const createUsersAction = (
  login_key,
  user_id,
  user_type,
  first_name,
  last_name,
  active,
  shop_group_id,
  user_name,
  pass,
  access_group_id,
  shop_id,
  mobile,
  login_otp,
  two_factor
) => {
  return {
    type: actionTypes.CREATE_USERS,
    payload: {
      login_key,
      user_id,
      user_type,
      first_name,
      last_name,
      active,
      shop_group_id,
      user_name,
      pass,
      access_group_id,
      shop_id,
      mobile,
      login_otp,
      two_factor,
    },
  };
};
export const createUsersSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_USERS_SUCCESSFULL,
    payload: data,
  };
};

export const createUsersFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_USERS_FAILED,
    payload: data,
  };
};

export const updateUsersAction = (
  login_key,
  user_id,
  id,
  user_type,
  first_name,
  last_name,
  active,
  shop_group_id,
  user_name,
  pass,
  access_group_id,
  shop_id,
  mobile,
  login_otp,
  two_factor
) => {
  return {
    type: actionTypes.UPDATE_USERS,
    payload: {
      login_key,
      user_id,
      id,
      user_type,
      first_name,
      last_name,
      active,
      shop_group_id,
      user_name,
      pass,
      access_group_id,
      shop_id,
      mobile,
      login_otp,
      two_factor,
    },
  };
};
export const updateUsersSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_USERS_SUCCESSFULL,
    payload: data,
  };
};
export const updateUsersFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_USERS_FAILED,
    payload: data,
  };
};

export const deleteUsersAction = (login_key, user_id, id, user_type) => {
  return {
    type: actionTypes.DELETE_USERS,
    payload: { login_key, user_id, id, user_type },
  };
};
export const deleteUsersSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_USERS_SUCCESSFULL,
    payload: data,
  };
};

export const deleteUsersFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_USERS_FAILED,
    payload: data,
  };
};

export const getEmployeesListAction = (data) => {
  return { type: actionTypes.GET_EMPLOYEES_LIST, payload: data };
};
export const getOwnersListAction = (data) => {
  return {
    type: actionTypes.GET_OWNERS_LIST,
    payload: data,
  };
};
export const visibleShopGroupPopupAction = (data) => {
  return {
    type: actionTypes.VISIBLE_SHOP_GROUP_POPUP,
    payload: data,
  };
};
export const shopGroupListAction = (login_key, user_id) => {
  return {
    type: actionTypes.SHOP_GROUP_LIST,
    payload: { login_key, user_id },
  };
};
export const shopGroupListSuccessfullAction = (data) => {
  return {
    type: actionTypes.SHOP_GROUP_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const shopGroupListFailedAction = () => {
  return {
    type: actionTypes.SHOP_GROUP_LIST_FAILED,
  };
};
export const createShopGroupAction = (
  login_key,
  user_id,
  group_name,
  shops_group,
  sort_id
) => {
  return {
    type: actionTypes.CREATE_SHOP_GROUP,
    payload: { login_key, user_id, group_name, shops_group, sort_id },
  };
};
export const createShopGroupSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_SHOP_GROUP_SUCCESSFULL,
    payload: data,
  };
};
export const createShopGroupFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_SHOP_GROUP_FAILED,
    payload: data,
  };
};
export const updateShopGroupAction = (
  login_key,
  user_id,
  group_id,
  group_name,
  shops_group,
  sort_id
) => {
  return {
    type: actionTypes.UPDATE_SHOP_GROUP,
    payload: { login_key, user_id, group_id, group_name, shops_group, sort_id },
  };
};
export const updateShopGroupSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_SHOP_GROUP_SUCCESSFULL,
    payload: data,
  };
};
export const updateShopGroupFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_SHOP_GROUP_FAILED,
    payload: data,
  };
};
export const deleteShopGroupAction = (login_key, user_id, group_id) => {
  return {
    type: actionTypes.DELETE_SHOP_GROUP,
    payload: { login_key, user_id, group_id },
  };
};
export const deleteShopGroupSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_SHOP_GROUP_SUCCESSFULL,
    payload: data,
  };
};
export const deleteShopGroupFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_SHOP_GROUP_FAILED,
    payload: data,
  };
};
export const selectedShopGroupAction = (data) => {
  return { type: actionTypes.SELECTED_SHOP_GROUP, payload: data };
};
export const shopListByGroupIdAction = (login_key, user_id, group_id) => {
  return {
    type: actionTypes.SHOP_LIST_BY_GROUP_ID,
    payload: { login_key, user_id, group_id },
  };
};
export const shopListByGroupIdSuccessfullAction = (data) => {
  return {
    type: actionTypes.SHOP_LIST_BY_GROUP_ID_SUCCESSFULL,
    payload: data,
  };
};
export const shopListByGroupIdFailedAction = () => {
  return {
    type: actionTypes.SHOP_LIST_BY_GROUP_ID_SUCCESSFULL,
  };
};

export const selectedShopIdsAction = (data) => {
  return {
    type: actionTypes.SELECTED_SHOP_IDS,
    payload: data,
  };
};
export const newShopListAction = (data) => {
  return {
    type: actionTypes.NEW_SHOP_LIST,
    payload: data,
  };
};
