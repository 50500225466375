export const english_order_status_menu = [
  {
    id: 1,
    title: "Not Printed",
    icon: "",
    expanded: false,
    path: "",
    sublist: [],
  },
  {
    id: 2,
    title: "Pending",
    icon: "",
    expanded: false,
    path: "pending",
    sublist: [],
  },
  {
    id: 3,
    title: "Failed",
    icon: "",
    expanded: false,
    path: "field",
    sublist: [],
  },
  {
    id: 4,
    title: "inComplete",
    icon: "",
    expanded: false,
    path: "incomplete",
    sublist: [],
  },
  {
    id: 5,
    title: "Rejected",
    icon: "",
    expanded: false,
    path: "rejected",
    sublist: [],
  },
];
export const turkish_divices_menu = [];
export const persian_divices_menu = [];
