import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import {
  showNotificationEmailPopupAction,
  updateNotificationEmailAction,
  createNotificationEmailAction,
  deleteNotificationEmailAction,
} from "../../../../redux/settings/Action";
const NotificationEmailPopup = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_notification_email } = useSelector(
    (state) => state.appSettingsRedusers
  );

  const [email, setEmail] = useState("");
  const handleCreatAndSaveEmail = () => {
    if (selected_notification_email) {
      if (email == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (!validator.isEmail(String(email))) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.email_format_incorrect}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateNotificationEmailAction(
            login_key,
            user_id,
            selected_notification_email.ShopId,
            email,
            selected_notification_email.Id,
            1
          )
        );
      }
    } else {
      if (email == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (!validator.isEmail(String(email))) {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.email_format_incorrect}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          createNotificationEmailAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            email
          )
        );
      }
    }
  };
  useEffect(() => {
    if (selected_notification_email) {
      setEmail(selected_notification_email.Email);
    } else {
      setEmail("");
    }
  }, [selected_notification_email]);

  return (
    <div className="create_shop_popup_container">
      <div className="create_shop_popup_content_container email_notification_container_div">
        <div className=" create-shop-close-div-post-code">
          {" "}
          <i
            onClick={() => dispatch(showNotificationEmailPopupAction(false))}
            style={{ fontSize: 25 }}
            aria-hidden="true"
            className="fa fa-times post-code-close-icon"
          ></i>
          <p className="post-code-p">Email Notification</p>
        </div>
        <div className="container-input-div">
          <div
            className="create_shop_inputs_container_1 "
            style={{
              height: "50%",
              width: "90%",
              margin: "25px auto ",
            }}
          >
            <h6 className="h6 " style={{ margin: 0 }}>
              * Email
            </h6>
            <input
              autoFocus
              type="email"
              style={{
                height: "40px",
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="post-code-popup-buttons-container">
          <button
            // disabled={selectedSubmenuIds.length > 0 ? false : true}
            onClick={handleCreatAndSaveEmail}
            id="popup_add"
          >
            {lang.save}
          </button>
        </div>
        <Toaster />
      </div>
    </div>
  );
};

export default NotificationEmailPopup;
