import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import {
  getSubmenuPublicListActiom,
  selectedPublicSubmneuAction,
} from "../../../redux/MainNavGeneral/Actions";
import { Navigate, useNavigate } from "react-router-dom";
import ListHeader from "../../../components/ListHeader";
import MenuListItem from "../../../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../../../constants/Sizes";
import { main_nav_button_color } from "../../../constants/Colors";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
  justTitleAndPriceAction,
  selectedOtemInSomeLists,
} from "../../../redux/menu/Actions";
const DefaultSubmneu = ({ setClickedItem }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { submenu_public_list, selected_public_submneu } = useSelector(
    (state) => state.MainNavGeneralRedusers
  );
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const [defaultSubmenuList, setDefaultSubmenuList] = useState([]);
  const handleClickedItem = (item, key) => {
    setClickedItem(key);
    dispatch(selectedPublicSubmneuAction(item));
  };
  useEffect(() => {
    dispatch(getSubmenuPublicListActiom(login_key, user_id));
  }, []);
  useEffect(() => {
    if (submenu_public_list) {
      if (submenu_public_list.length > 0) {
        setDefaultSubmenuList(submenu_public_list);
      }
    }
  }, [submenu_public_list]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    const copy_product_list = [...defaultSubmenuList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (copy_product_list[index].showEditBox) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setDefaultSubmenuList(copy_product_list);
    dispatch(justTitleAndPriceAction(1));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
      dispatch(selectedOtemInAllLists(null));
      dispatch(justTitleAndPriceAction(0));
      dispatch(selectedOtemInSomeLists(null));
    };
  }, [change_selected_button_border_color_on_focus]);
  return (
    <div
      className="countaining-div"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      {/* BACK & TITLE */}
      <ListHeader title={lang.submenu_list} show_back={false} />
      <div className="menu_list_header_container">
        {defaultSubmenuList.length > 0 ? (
          <div style={{ marginBottom: "150px" }}>
            {defaultSubmenuList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={1}
                  field_1_title={item.Title}
                  btn_number={1}
                  bt_1_title={lang.item}
                  bt_1_click={() => handleClickedItem(item, 2)}
                  show_delete={false}
                  //   delete_click={() => handleDeleteItem(item)}
                  show_edit_icon={false}
                  selected={selected_public_submneu}
                  item={item}
                  number={0}
                  //   show_delete_box={() => handleShowDeleteBox(item)}
                  //   cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DefaultSubmneu;
