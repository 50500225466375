import * as actionTypes from "../ActionTypes";

export const getSaleOverviewListAction = (
  login_key,
  user_id,
  shop_id,
  from_date,
  to_date
) => {
  return {
    type: actionTypes.GET_SALES_OVERVIEW_LIST,
    payload: { login_key, user_id, shop_id, from_date, to_date },
  };
};
export const getSaleMethodSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_SALE_METHOD_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getPayMethodSuccessfulllAction = (data) => {
  return {
    type: actionTypes.GET_PAY_METHOD_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getSaleOverviewListFailedAction = (data) => {
  return {
    type: actionTypes.GET_SALES_OVERVIEW_LIST_FAILED,
    payload: data,
  };
};
