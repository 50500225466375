import React, { useState, useEffect } from "react";
import useScrollbarSize from "react-scrollbar-size";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, NavLink } from "react-router-dom";
import Loader from "../../../../components/Loader";
import toast, { Toaster } from "react-hot-toast";
import {
  primaryColor,
  main_nav_back_color,
} from "../../../../constants/Colors";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  getTabelAreaAction,
  selectedAreaAction,
  selectedAreaEmptyAction,
  selectedAllowedPostCode,
} from "../../../../redux/GeneralSetting/Actions";
import { selectedDeliveryChargeType } from "../../../../redux/settings/Action";
import { visibleCusomChargeAction } from "../../../../redux/settings/Action";
import { general_delivery_charge } from "../../../../constants/DeliveryCharge";

import Delivery from "./Delivery";
import PoctCodeList from "./PoctCodeList";
import AreaList from "./DeliveryAreaList";
import DeliveryArea from "./DeliveryArea";

import FOFPostCode from "./FOFPostCode";
const DeliveryCharge = () => {
  const navigate = useNavigate();
  const { height, width } = useScrollbarSize();
  const dispatch = useDispatch();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );

  const { lang } = useSelector((state) => state.selectedLanguage);

  const [clickedItem, setClickedItem] = useState(1);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);

  const handleCollapse = (item, myindex) => {
    const item_copy = { ...item };
    const delivery_charge_type_side_bar_copy = [...general_delivery_charge];
    if (item.id == 1) {
      setClickedItem(1);
    } else if (item.id == 2) {
      dispatch(selectedAllowedPostCode(null));
      setClickedItem(2);
    } else if (item.id == 3) {
      setClickedItem(3);
    } else {
      dispatch(selectedDeliveryChargeType(null));
      setClickedItem(4);
    }
    delivery_charge_type_side_bar_copy.forEach((element, index) => {
      if (index == myindex) {
        item_copy.expanded = !item_copy.expanded;
        delivery_charge_type_side_bar_copy[myindex] = item_copy;
      } else {
        element.expanded = false;
        delivery_charge_type_side_bar_copy[index] = element;
      }
    });
    setDeviceList(delivery_charge_type_side_bar_copy);

    if (!item.expanded) {
      setSelectedTitleIndex(myindex);
    } else {
      setSelectedTitleIndex(null);
    }
  };

  const handleEditItem = (item, key) => {
    setClickedItem(key);
    dispatch(selectedAreaAction(item));
  };

  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        getTabelAreaAction(login_key, user_id, selected_shop[0].ShopId, 0, 0)
      );
    }
  }, [selected_shop]);

  return (
    <div
      className="c-div"
      style={{
        height: window.innerHeight - 220,
      }}
    >
      <div
        className="s-div"
        style={{
          borderLeft: ".3px solid white",
          width: "17%",
        }}
      >
        <div id="idd" className="menu_list_header_container">
          <div>
            {general_delivery_charge.map((item, index) => (
              <div key={index}>
                <div className="category_list_container">
                  <div
                    style={{
                      backgroundColor:
                        selectedTitleIndex == index
                          ? main_nav_back_color
                          : null,
                      border:
                        selectedTitleIndex == index
                          ? "2px solid #f38321"
                          : null,
                    }}
                    onClick={() => handleCollapse(item, index)}
                    className="menu-div ganaral_app_setting_counteine_tabel_name general-menu-div "
                  >
                    <div
                      style={{ width: "90%", height: "100%" }}
                      onClick={() => handleEditItem(item, 1)}
                    >
                      <p
                        id="p1"
                        className="category_title 
                          general-category-title"
                      >
                        {item.title}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="Menu-div_countainer-component" style={{ width: "83%" }}>
        {clickedItem == 1 ? (
          <Delivery />
        ) : clickedItem == 2 ? (
          <PoctCodeList />
        ) : clickedItem == 3 ? (
          <FOFPostCode />
        ) : // <AreaList setClickedItem={(i) => setClickedItem(i)} />
        clickedItem == 4 ? (
          <DeliveryArea />
        ) : null}
      </div>
    </div>
  );
};

export default DeliveryCharge;
