import * as actionTypes from "../ActionTypes";

// CREATE CATEGORY
export const createCategoryAction = (login_key, user_id, id, title) => {
  return {
    type: actionTypes.CREATE_CATEGORY_SAGA,
    payload: { login_key, user_id, id, title },
  };
};
export const createCategorySuccessfulAction = (data) => {
  return {
    type: actionTypes.CREATE_CATEGORY_SUCCESSFULL,
    payload: data,
  };
};
export const createCategoryFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_CATEGORY_FAILED,
    payload: data,
  };
};
export const selectedCategoryAction = (data) => {
  return {
    type: actionTypes.SELECTED_CATEGORY,
    payload: data,
  };
};

// GET CATEGORY
export const getCategoriesListAction = (
  login_key,
  user_id,
  shop_id,
  cat_id
) => {
  return {
    type: actionTypes.GET_CATEGORY_SAGA,
    payload: { login_key, user_id, shop_id, cat_id },
  };
};
export const getCategoriesSuccessfulAction = (data) => {
  return {
    type: actionTypes.GET_CATEGORIES_SUCCESSFULL,
    payload: data,
  };
};
export const getCategoriesFailedAction = (data) => {
  return {
    type: actionTypes.GET_CATEGORIES_FAILED,
    payload: data,
  };
};
// DELETE CATEGORY
export const deleteCategoryAction = (
  login_key,
  user_id,
  shop_id,
  category_id
) => {
  return {
    type: actionTypes.DELETE_CATEGORY,
    payload: { login_key, user_id, shop_id, category_id },
  };
};
export const deleteCategorySuccessfulAction = (message) => {
  return {
    type: actionTypes.DELETE_CATEGORY_SUCCESSFULL,
    payload: { message },
  };
};
export const deleteCategoryFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_CATEGORY_FAILED,
    payload: data,
  };
};
// SEND  CATEGORY SETTINGS
export const sendCategorySettingsAction = (
  formData,
  login_key,
  user_id,
  id,
  shop_id,
  app_title,
  app_desc,
  web_title,
  web_desc,
  kiosk_title,
  kiosk_desc,
  second_language,
  app_visible,
  web_visible,
  kiosk_visible,
  app_sort,
  web_sort,
  kiosk_sort,
  printer_id,
  report_section,
  product_group,
  include_half,
  vat_id,
  app_bg_color,
  app_title_fontsize,
  app_title_font_family,
  app_title_color,
  app_title_width,
  app_title_height,
  app_image,
  app_visible_image,
  web_image,
  web_visible_image,
  kiosk_image,
  kiosk_visible_image
) => {
  return {
    type: actionTypes.SEND_CATEGORY_SETTINGS,
    payload: {
      formData,
      login_key,
      user_id,
      id,
      shop_id,
      app_title,
      app_desc,
      web_title,
      web_desc,
      kiosk_title,
      kiosk_desc,
      second_language,
      app_visible,
      web_visible,
      kiosk_visible,
      app_sort,
      web_sort,
      kiosk_sort,
      printer_id,
      report_section,
      product_group,
      include_half,
      vat_id,
      app_bg_color,
      app_title_fontsize,
      app_title_font_family,
      app_title_color,
      app_title_width,
      app_title_height,
      app_image,
      app_visible_image,
      web_image,
      web_visible_image,
      kiosk_image,
      kiosk_visible_image,
    },
  };
};
export const sendCategorySettingsSuccessfulAction = (resultMesage) => {
  return {
    type: actionTypes.SEND_CATEGORY_SETTINGS_SUCCESSFULL,
    payload: { resultMesage },
  };
};
export const sendCategorySettingsFailedAction = (data) => {
  return {
    type: actionTypes.SEND_CATEGORY_SETTINGS_FAILED,
    payload: data,
  };
};

// GET DROPDOWN
export const getDropDownAction = (login_key, user_id, id) => {
  return {
    type: actionTypes.GET_DROPDOWN,
    payload: { login_key, user_id, id },
  };
};
export const getDropDownSuccessfulAction = (data) => {
  return {
    type: actionTypes.GET_DROPDOWN_SUCCESSFULL,
    payload: data,
  };
};
export const getDropDownFailedAction = () => {
  return {
    type: actionTypes.GET_DROPDOWN_FAILED,
  };
};

// SET DEFAULT
export const setDefaultAction = () => {
  return {
    type: actionTypes.SET_DEFAULT,
  };
};

// GET CATEGORY OPTIONS
export const getCategoryOptionsListAction = (
  login_key,
  user_id,
  shop_id,
  cat_id
) => {
  return {
    type: actionTypes.GET_CATEGORY_OPTIONS,
    payload: { login_key, user_id, shop_id, cat_id },
  };
};
export const getCategoryOptionsListSuccessfulAction = (data) => {
  return {
    type: actionTypes.GET_CATEGORY_OPTIONS_SUCCESSFULL,
    payload: data,
  };
};
export const getCategoryOptionsListFailedAction = (data) => {
  return {
    type: actionTypes.GET_CATEGORY_OPTIONS_FAILED,
    payload: data,
  };
};
export const setSelectedCategoryOptionAction = (data) => {
  return {
    type: actionTypes.SET_SELECTED_CATEGORY_OPTION,
    payload: data,
  };
};
// CREATE CATEGORY OPTION
export const createCategoryOptionAction = (
  login_key,
  user_id,
  shop_id,
  cat_id,
  title,
  desc,
  amount
) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPTION,
    payload: {
      login_key,
      user_id,
      shop_id,
      cat_id,
      title,
      desc,
      amount,
    },
  };
};
export const createCategoryOptionSuccessfulAction = (data) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPTION_SUCCESSFULL,
    payload: data,
  };
};
export const createCategoryOptionFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPTION_FAILED,
    payload: data,
  };
};
// SAVE CATEGORY OPTION
export const sendCategoryOptionAction = (
  login_key,
  user_id,
  option_id,
  shop_id,
  cat_id,
  app_title,
  short_name,
  web_title,
  web_desc,
  kiosk_title,
  kiosk_desc,
  second_language,

  multi_select_price,
  price_level_main_1,
  price_level_main_2,
  price_level_main_3,
  price_level_main_4,
  price_level_main_5,
  price_level_main_6,
  price_level_main_7,
  price_level_main_8,
  price_level_collection_1,

  price_level_collection_2,
  price_level_collection_3,
  price_level_collection_4,
  price_level_collection_5,
  price_level_collection_6,
  price_level_collection_7,
  price_level_collection_8,
  price_level_eatin_1,
  price_level_eatin_2,
  price_level_eatin_3,

  price_level_eatin_4,
  price_level_eatin_5,
  price_level_eatin_6,
  price_level_eatin_7,
  price_level_eatin_8,
  vat,
  visible_app,
  visible_web,
  visible_kiosk,
  stock,

  sort_app,
  sort_web,
  sort_kiosk,
  font_size_app,
  bg_color_app,
  font_color_app,
  just_title_and_price,
  amount_delivery,
  amount_collection,
  amount_takeaway,
  amount_eatIn
) => {
  return {
    type: actionTypes.SEND_CATEGORY_OPTION,
    payload: {
      login_key,
      user_id,
      option_id,
      shop_id,
      cat_id,
      app_title,
      short_name,
      web_title,
      web_desc,
      kiosk_title,
      kiosk_desc,
      second_language,
      multi_select_price,
      price_level_main_1,
      price_level_main_2,
      price_level_main_3,
      price_level_main_4,
      price_level_main_5,
      price_level_main_6,
      price_level_main_7,
      price_level_main_8,
      price_level_collection_1,
      price_level_collection_2,
      price_level_collection_3,
      price_level_collection_4,
      price_level_collection_5,
      price_level_collection_6,
      price_level_collection_7,
      price_level_collection_8,
      price_level_eatin_1,
      price_level_eatin_2,
      price_level_eatin_3,
      price_level_eatin_4,
      price_level_eatin_5,
      price_level_eatin_6,
      price_level_eatin_7,
      price_level_eatin_8,
      vat,
      visible_app,
      visible_web,
      visible_kiosk,
      stock,
      sort_app,
      sort_web,
      sort_kiosk,
      font_size_app,
      bg_color_app,
      font_color_app,
      just_title_and_price,

      amount_delivery,
      amount_collection,
      amount_takeaway,
      amount_eatIn,
    },
  };
};
export const sendCategoryOptionSuccessfulAction = (data) => {
  return {
    type: actionTypes.SEND_CATEGORY_OPTION_SUCCESSFULL,
    payload: data,
  };
};
export const sendCategoryOptionFailedAction = (data) => {
  return {
    type: actionTypes.SEND_CATEGORY_OPTION_FAILED,
    payload: data,
  };
};
// DELETE CATEGORY OPTION
export const deleteCategoryOptionAction = (
  login_key,
  user_id,
  shop_id,
  option_id,
  cat_id
) => {
  return {
    type: actionTypes.DELETE_CATEGORY_OPTION,
    payload: { login_key, user_id, shop_id, option_id, cat_id },
  };
};
export const deleteCategoryOptionSuccessfulAction = (data) => {
  return {
    type: actionTypes.DELETE_CATEGORY_OPTION_SUCCESSFULL,
    payload: data,
  };
};
export const deleteCategoryOptionFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_CATEGORY_OPTION_FAILED,
    payload: data,
  };
};
export const getCategoryOptionCrustAction = (
  login_key,
  user_id,
  shop_id,
  option_id,
  category_id
) => {
  return {
    type: actionTypes.GET_CATEGORY_OPTION_CRUST,
    payload: {
      login_key,
      user_id,
      shop_id,
      option_id,
      category_id,
    },
  };
};
export const getCategoryOptionCrustSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_CATEGORY_OPTION_CRUST_SUCCESSFULL,
    payload: data,
  };
};
export const getCategoryOptionCrustFailedAction = () => {
  return {
    type: actionTypes.GET_CATEGORY_OPTION_CRUST_FAILED,
  };
};

export const createCategoryOptionCrustAction = (
  login_key,
  user_id,
  shop_id,
  option_id,
  app_title,
  desc,
  amount
) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPTION_CRUST,
    payload: {
      login_key,
      user_id,
      shop_id,
      option_id,
      app_title,
      desc,
      amount,
    },
  };
};
export const createCategoryOptionCrustSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPTION_CRUST_SUCCESSFULL,
    payload: data,
  };
};
export const createCategoryOptionCrustFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPTION_CRUST_FAILED,
    payload: data,
  };
};

export const deleteCategoryOptionCrustAction = (
  login_key,
  user_id,
  shop_id,
  crust_id,
  option_id
) => {
  return {
    type: actionTypes.DELETE_CATEGORY_OPTION_CRUST,
    payload: {
      login_key,
      user_id,
      shop_id,
      crust_id,
      option_id,
    },
  };
};
export const deleteCategoryOptionCrustSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_CATEGORY_OPTION_CRUST_SUCCESSFULL,
    payload: data,
  };
};
export const deleteCategoryOptionCrustFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_CATEGORY_OPTION_CRUST_FAILED,
    payload: data,
  };
};

export const selctedCategoryOptionCrust = (data) => {
  return {
    type: actionTypes.SELECTED_CATEGORY_OPTION_CRUST,
    payload: data,
  };
};

export const updateCategoryOptionCrustAction = (
  login_key,
  user_id,
  id,
  shop_id,
  option_id,
  app_title,
  web_title,
  web_desc,
  kiosk_title,
  kiosk_desc,
  second_language,
  price,
  app_visible,
  web_visible,
  kiosk_visible,
  app_sort_id,
  web_sort_id,
  kiosk_sort_id,
  app_bg_color,
  app_font_color,
  app_title_fontsize,
  just_title_and_price
) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_OPTION_CRUST,
    payload: {
      login_key,
      user_id,
      id,
      shop_id,
      option_id,
      app_title,
      web_title,
      web_desc,
      kiosk_title,
      kiosk_desc,
      second_language,
      price,
      app_visible,
      web_visible,
      kiosk_visible,
      app_sort_id,
      web_sort_id,
      kiosk_sort_id,
      app_bg_color,
      app_font_color,
      app_title_fontsize,
      just_title_and_price,
    },
  };
};
export const updateCategoryOptionCrustSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_OPTION_CRUST_SUCCESSFULL,
    payload: data,
  };
};
export const updateCategoryOptionCrustFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_OPTION_CRUST_FAILED,
    payload: data,
  };
};
export const handleEmterKeyAction = (data) => {
  return {
    type: actionTypes.HANDLE_ENTER_KEY,
    payload: data,
  };
};

export const changeSelectedButtonBorderColorOnFocus = (data) => {
  return {
    type: actionTypes.CHANGE_SELECTED_BUTTON_BORDER_COLOR_ON_FOCUS,
    payload: data,
  };
};
export const changeSelectedItemColorOnFocusAction = (data) => {
  return {
    type: actionTypes.CHANGE_SELECTED_ITEM_COLOR_ON_FOCUS,
    payload: data,
  };
};
export const clickedItemAction = (data) => {
  return {
    type: actionTypes.CLICKED_ITEM,
    payload: data,
  };
};

export const selectedOtemInAllLists = (data) => {
  return {
    type: actionTypes.SELECTED_ITEM_IN_ALL_LISTS,
    payload: data,
  };
};
export const selectedOtemInSomeLists = (data) => {
  return {
    type: actionTypes.SELECTED_ITEM_IN_SOME_LISTS,
    payload: data,
  };
};

export const editBoxParametersAction = (data) => {
  return {
    type: actionTypes.EDIT_BOX_PARAMETERS,
    payload: data,
  };
};

export const justTitleAndPriceAction = (data) => {
  return {
    type: actionTypes.JUST_TITLE_AND_PRICE,
    payload: data,
  };
};

export const sendAllItemsToAppAction = (data) => {
  return {
    type: actionTypes.SEND_ALL_ITEMS_TO_APP,
    payload: data,
  };
};

export const newCategoryIdAction = (data) => {
  return {
    type: actionTypes.NEW_CATEGORY_ID,
    payload: data,
  };
};

export const tabStatusAction = (data) => {
  return {
    type: actionTypes.TAB_STATUS,
    payload: data,
  };
};

// CREATE PRODUCT OPEN CLOSE
export const createProductOpenCloseTimeAction = (
  login_key,
  user_id,
  shop_id,
  product_id,
  days_of_week
) => {
  return {
    type: actionTypes.CREATE_PRODUCT_OPEN_CLOSE_TIME,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_id,
      days_of_week,
    },
  };
};

export const createProductOpenCloseTimeSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_PRODUCT_OPEN_CLOSE_TIME_SUCCESSFULL,
    payload: data,
  };
};
export const createProductOpenCloseTimeFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_PRODUCT_OPEN_CLOSE_TIME_FAILED,
    payload: data,
  };
};
// GET PRODUCT OPEN CLOSE LIST
export const getProductOpenCloseListAction = (
  login_key,
  user_id,
  shop_id,
  product_id
) => {
  return {
    type: actionTypes.GET_PRODUCT_OPEN_CLOSE_LIST,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_id,
    },
  };
};

export const getProductOpenCloseListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_OPEN_CLOSE_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getProductOpenCloseListFailedAction = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_OPEN_CLOSE_LIST_FAILED,
    payload: data,
  };
};
//CATEGORY STATUS
export const editCategoryStatusAction = (
  login_key,
  user_id,
  shop_id,
  cat_id,
  cat_status
) => {
  return {
    type: actionTypes.EDIT_CATEGORY_STATUS,
    payload: {
      login_key,
      user_id,
      shop_id,
      cat_id,
      cat_status,
    },
  };
};
export const editCategoryStatusSuccessfullAction = (data) => {
  return {
    type: actionTypes.EDIT_CATEGORY_STATUS_SUCCESSFULL,
    payload: data,
  };
};
export const editCategoeyStatusFailedAction = (data) => {
  return {
    type: actionTypes.EDIT_CATEGORY_STATUS_FAILED,
    payload: data,
  };
};
//PRODUCT STATUS
export const editProductStatusAction = (
  login_key,
  user_id,
  shop_id,
  product_status,
  product_id,
  cat_id
) => {
  return {
    type: actionTypes.EDIT_PRODUCT_STATUS,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_status,
      product_id,
      cat_id,
    },
  };
};
export const editProductStatusSuccessfullAction = (data) => {
  return {
    type: actionTypes.EDIT_PRODUCT_STATUS_SUCCESSFULL,
    payload: data,
  };
};
export const editProductStatusFailedAction = (data) => {
  return {
    type: actionTypes.EDIT_PRODUCT_STATUS_FAILED,
    payload: data,
  };
};

//CATEGORY OPEN CLOSE TIME
export const createCategoryOpenCloseTimeAction = (
  login_key,
  user_id,
  shop_id,
  cat_id,
  days_of_week
) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPEN_CLOSE_TIME,
    payload: {
      login_key,
      user_id,
      shop_id,
      cat_id,
      days_of_week,
    },
  };
};

export const createCategoryOpenCloseTimeSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPEN_CLOSE_TIME_SUCCESSFULL,
    payload: data,
  };
};
export const createCategoryOpenCloseTimeFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_CATEGORY_OPEN_CLOSE_TIME_FAILED,
    payload: data,
  };
};
// GET CATEGORY OPEN CLOSE LIST
export const getCategoryOpenCloseListAction = (
  login_key,
  user_id,
  shop_id,
  cat_id
) => {
  return {
    type: actionTypes.GET_CATEGORY_OPEN_CLOSE_LIST,
    payload: {
      login_key,
      user_id,
      shop_id,
      cat_id,
    },
  };
};

export const getCategoryOpenCloseListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_CATEGORY_OPEN_CLOSE_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getCategoryOpenCloseListFailedAction = (data) => {
  return {
    type: actionTypes.GET_CATEGORY_OPEN_CLOSE_LIST_FAILED,
    payload: data,
  };
};

export const showCategoryAvailabilityPopupAction = (data) => {
  return {
    type: actionTypes.SHOW_CATEGORY_AVAILABILITY_POPUP,
    payload: data,
  };
};

// UPDATE CATEGORY WEB SORT
export const updateCategorySortAction = (
  login_key,
  user_id,
  shop_id,
  cat_id,
  old_sort_id,
  new_sort_id
) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_SORT,
    payload: { login_key, user_id, shop_id, cat_id, old_sort_id, new_sort_id },
  };
};

export const updateCategorySortSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateCategorySortFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_SORT_FAILED,
    payload: data,
  };
};

//UPDATE CATEGORY APP SORT
export const updateCategoryAppSortAction = (
  login_key,
  user_id,
  shop_id,
  cat_id,
  old_sort_id,
  new_sort_id
) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_APP_SORT,
    payload: { login_key, user_id, shop_id, cat_id, old_sort_id, new_sort_id },
  };
};

export const updateCategoryAppSortSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_APP_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateCategoryAppSortFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_APP_SORT_FAILED,
    payload: data,
  };
};

//CHANGE SORT LABLE
export const changeSortLableAction = (data) => {
  return {
    type: actionTypes.CHANGE_SORT_LABEL,
    payload: data,
  };
};

// PRODUCT SORT
export const updateProductSortAction = (
  login_key,
  user_id,
  shop_id,
  product_id,
  old_sort_id,
  new_sort_id,
  cat_id
) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_id,
      old_sort_id,
      new_sort_id,
      cat_id,
    },
  };
};
export const updateProductSortSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateProductSortFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SORT_FAILED,
    payload: data,
  };
};

export const updateProductAppSortAction = (
  login_key,
  user_id,
  shop_id,
  product_id,
  old_sort_id,
  new_sort_id,
  cat_id
) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_APP_SORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_id,
      old_sort_id,
      new_sort_id,
      cat_id,
    },
  };
};
export const updateProductAppSortSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_APP_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateProductAppSortFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_APP_SORT_FAILED,
    payload: data,
  };
};
export const changeProductSortLabelAction = (data) => {
  return {
    type: actionTypes.CHANGE_PRODUCT_SORT_LABEL,
    payload: data,
  };
};

export const sortNumberAction = (data) => {
  return {
    type: actionTypes.SORT_NUMBER,
    payload: data,
  };
};
//UPDATE PRODUCT SUBMENU SORT
export const updateProductSubmenuWebSortAction = (
  login_key,
  user_id,
  shop_id,
  product_submenu_id,
  old_sort_id,
  new_sort_id,
  product_id
) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_WEB_SORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_submenu_id,
      old_sort_id,
      new_sort_id,
      product_id,
    },
  };
};
export const updateProductSubmenuWebSortSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_WEB_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateProductSubmenuWebSortFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_WEB_SORT_FAILED,
    payload: data,
  };
};

export const updateProductSubmenuAppSortAction = (
  login_key,
  user_id,
  shop_id,
  product_submenu_id,
  old_sort_id,
  new_sort_id,
  product_id
) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_APP_SORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_submenu_id,
      old_sort_id,
      new_sort_id,
      product_id,
    },
  };
};
export const updateProductSubmenuAppSortSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_APP_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateProductSubmenuAppSortFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUBMENU_APP_SORT_FAILED,
    payload: data,
  };
};

export const productSubmenuSortNumberAction = (data) => {
  return {
    type: actionTypes.PRODUCT_SUBMENU_SORT_NUMBER,
    payload: data,
  };
};

//UPDATE PRODUCT OPTION SORT
export const updateProductOptionWebSortAction = (
  login_key,
  user_id,
  shop_id,
  product_option_id,
  old_sort_id,
  new_sort_id,
  product_id
) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_OPTION_WEB_SORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_option_id,
      old_sort_id,
      new_sort_id,
      product_id,
    },
  };
};
export const updateProductOptionWebSortSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_OPTION_WEB_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateProductOptionWebSortFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_OPTION_WEB_SORT_FAILED,
    payload: data,
  };
};

export const updateProductOptionAppSortAction = (
  login_key,
  user_id,
  shop_id,
  product_option_id,
  old_sort_id,
  new_sort_id,
  product_id
) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_OPTION_APP_SORT,
    payload: {
      login_key,
      user_id,
      shop_id,
      product_option_id,
      old_sort_id,
      new_sort_id,
      product_id,
    },
  };
};
export const updateProductOptionAppSortSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_OPTION_APP_SORT_SUCCESSFULL,
    payload: data,
  };
};
export const updateProductOptionAppSortFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_PRODUCT_OPTION_APP_SORT_FAILED,
    payload: data,
  };
};
export const productOptionSortNumberAction = (data) => {
  return {
    type: actionTypes.PRODUCT_OPTION_SORT_NUMBER,
    payload: data,
  };
};
