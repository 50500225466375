import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import { resetCashOrderService } from "../../services/SetupServices";
import {
  resetCashOrderFailedAction,
  resetCashOrderSuccessfullAction,
} from "./Actions";
import * as action_types from "../ActionTypes";
function* resetCashOrder({ payload }) {
  try {
    const response = yield call(
      resetCashOrderService,
      payload.login_key,
      payload.user_id,
      payload.shop_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(resetCashOrderSuccessfullAction(response.data.ResultMessage));
      } else {
        yield put(resetCashOrderFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(resetCashOrderFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(resetCashOrderFailedAction(error.message));
  }
}
export function* watchResetCashOrder() {
  yield takeLatest(action_types.RESET_CASH_ORDER, resetCashOrder);
}
export default function* rootSetupSaga() {
  yield all([fork(watchResetCashOrder)]);
}
