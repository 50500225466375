let orderList = [
  {
    id: 1,
    icon: "fa fa-shopping-bag",
    OrderID: "#57080000",
    status: "Cancelled",
    status_code: 4,
    Channel: "Flipdish Web",
    Date: "11:07 14/12/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E5.00",
    Payment_Status: "Refunded",
    Customer: "Tom Smith",
    visible: true,
  },
  {
    id: 2,
    icon: "fa fa-shopping-bag",
    OrderID: "#55052369",
    status: "Pending",
    status_code: 2,
    Channel: "Flipdish Web",
    Date: "13:29 23/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E30.50",
    Payment_Status: "Unpaid",
    Customer: "Mobeen2",
    visible: true,
  },
  {
    id: 3,
    icon: "fa fa-shopping-bag",
    OrderID: "#55052331",
    status: "Cancelled",
    status_code: 4,
    Channel: "Flipdish Web",
    Date: "13:28 23/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E20.00",
    Payment_Status: "Refunded",
    Customer: "Mobeen2",
    visible: true,
  },
  {
    id: 4,
    icon: "fa fa-shopping-bag",
    OrderID: "#55052276",
    status: "Accepted",
    status_code: 3,
    Channel: "Flipdish Web",
    Date: "13:26 23/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E20.50",
    Payment_Status: "Unpaid",
    Customer: "Mobeen2",
    visible: true,
  },
  {
    id: 5,
    icon: "fa fa-shopping-bag",
    OrderID: "#55052238",
    status: "Rejected",
    status_code: 1,
    Channel: "Flipdish Web",
    Date: "13:25 23/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E12.00",
    Payment_Status: "Unpaid",
    Customer: "Mobeen2",
    visible: true,
  },
  {
    id: 6,
    icon: "fa fa-shopping-bag",
    OrderID: "#55052090",
    status: "Pending",
    status_code: 2,
    Channel: "Flipdish Web",
    Date: "13:22 23/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E20.50",
    Payment_Status: "Unpaid",
    Customer: "Mobeen2",
    visible: true,
  },
  {
    id: 7,
    icon: "fa fa-shopping-bag",
    OrderID: "#55050510",
    status_code: 3,
    status: "Accepted",
    Channel: "Flipdish Web",
    Date: "12:47 23/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E9.50",
    Payment_Status: "Unpaid",
    Customer: "Mobeen2",
    visible: true,
  },
  {
    id: 8,
    icon: "fa fa-shopping-bag",
    OrderID: "#55049996",
    status: "Rejected",
    status_code: 1,
    Channel: "Flipdish Web",
    Date: "12:37 23/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E7.50",
    Payment_Status: "Unpaid",
    Customer: "Mobeen2",
    visible: true,
  },
  {
    id: 9,
    icon: "fa fa-shopping-bag",
    OrderID: "#53329446",
    status: "Cancelled",
    status_code: 4,
    Channel: "Flipdish Web",
    Date: "14:13 05/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E20.50",
    Payment_Status: "Unpaid",
    Customer: "developer",
    visible: true,
  },
  {
    id: 10,
    icon: "fa fa-shopping-bag",
    OrderID: "#53326812",
    status: "Accepted",
    status_code: 3,
    Channel: "Flipdish Web",
    Date: "14:04 05/11/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E19.50",
    Payment_Status: "Partially Refunded",
    Customer: "developer",
    visible: true,
  },
  {
    id: 10,
    icon: "fa fa-shopping-bag",
    OrderID: "#52460907",
    status: "Cancelled",
    status_code: 4,
    Channel: "Flipdish Web",
    Date: "13:21 26/10/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E21.50",
    Payment_Status: "Unpaid",
    Customer: "developer",
    visible: true,
  },

  {
    id: 12,
    icon: "fa fa-shopping-bag",
    OrderID: "#51940033",
    status: "Accepted",
    status_code: 3,
    Channel: "Flipdish Web",
    Date: "15:22 21/10/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E5.50",
    Payment_Status: "Unpaid",
    Customer: "developer",
    visible: true,
  },

  {
    id: 16,
    icon: "fa fa-shopping-bag",
    OrderID: "#50595082",
    status: "Pending",
    status_code: 2,
    Channel: "Flipdish Web",
    Date: "17:39 06/10/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E5.00",
    Payment_Status: "Unpaid",
    Customer: "Alex Abe",
    visible: true,
  },
  {
    id: 17,
    icon: "fa fa-shopping-bag",
    OrderID: "#50595082",
    status: "Rejected",
    status_code: 1,
    Channel: "Flipdish Web",
    Date: "18:00 24/09/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E8.50",
    Payment_Status: "Unpaid",
    Customer: "Tina",
    visible: true,
  },
  {
    id: 18,
    icon: "fa fa-shopping-bag",
    OrderID: "#48966927",
    status: "Pending",
    status_code: 2,
    Channel: "Flipdish Web",
    Date: "18:57 15/09/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E18.50",
    Payment_Status: "Refunded",
    Customer: "Alex Abe",
    visible: true,
  },
  {
    id: 19,
    icon: "fa fa-shopping-bag",
    OrderID: "#48965963",
    status: "Accepted",
    status_code: 3,
    Channel: "Flipdish Web",
    Date: "18:51 15/09/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E18.50",
    Payment_Status: "Partially Refunded",
    Customer: "Alex Abe",
    visible: true,
  },
  {
    id: 20,
    icon: "fa fa-shopping-bag",
    OrderID: "#48965630",
    status: "Cancelled",
    status_code: 4,
    Channel: "Flipdish Web",
    Date: "18:49 15/09/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E23.00",
    Payment_Status: "Unpaid",
    Customer: "Alex Abe",
    visible: true,
  },
  {
    id: 21,
    icon: "fa fa-shopping-bag",
    OrderID: "#30445532",
    status: "Accepted",
    status_code: 3,
    Channel: "Flipdish Web",
    Date: "15:52 02/02/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E12.00",
    Payment_Status: "Unpaid",
    Customer: "Alex Abe",
    visible: true,
  },
  {
    id: 22,
    icon: "fa fa-shopping-bag",
    OrderID: "#30455085",
    status: "Rejected",
    status_code: 1,
    Channel: "Flipdish Web",
    Date: "15:36 2021/02/02",
    Store_name: "Silky Desserts",
    Order_amount: "E12.00",
    Payment_Status: "Refunded",
    Customer: "Alex Abe",
    visible: true,
  },
  {
    id: 23,
    icon: "fa fa-shopping-bag",
    OrderID: "#30443698",
    status: "Accepted",
    status_code: 3,
    Channel: "Flipdish Web",
    Date: "15:52 02/02/2021",
    Store_name: "Silky Desserts",
    Order_amount: "E5.00",
    Payment_Status: "Unpaid",
    Customer: "Alex Abe",
    visible: true,
  },
];
export default orderList;
export const objects = [
  {
    id: "1",
    title: "OrderID,status",
  },
  { id: "2", title: "Channels" },
  { id: "3", title: "Requested for" },
  { id: "4", title: "Store name" },
  { id: "5", title: "Order amount" },
  { id: "6", title: "Payment status" },
  { id: "7", title: "Customer" },
];

export const Channels = [
  { value: 7, label: "All " },
  { value: 1, label: "T2" },
  { value: 2, label: "V2" },
  { value: 3, label: "Tablet " },
  { value: 4, label: "Mobile" },
  { value: 5, label: "Kiosk" },
  { value: 6, label: "Website" },
];
export const Status = [
  { value: 1, label: "All " },
  { value: 1000404, label: "Accepted" },
  { value: 1000405, label: "Rejected" },
  { value: 1000403, label: "Pending" },
  { value: 1000407, label: "Cancelled" },
];
export const status = [
  { value: "1", label: "Rejected" },
  { value: "2", label: "Pending" },
  { value: "3", label: "Accepted" },
  { value: "4", label: "Cancelled" },
  { value: "5", label: "All" },
];
export const Stores = [
  { value: "1", label: "All stores" },
  { value: "2", label: "New stores 2" },
  { value: "3", label: "Silky Desserts" },
];

export const DeviceStatus = [
  { value: 1, label: "active" },
  { value: 2, label: "deactive" },
];
export const DaysLeft = [
  { value: "1", label: "0-5" },
  {
    value: "2",
    label: "5-10",
  },
];
