import React from "react";
import { main_nav_button_color } from "../../../../constants/Colors";
const SalesDetail = () => {
  return (
    <div
      className="countaining-div countaining-div-11"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    ></div>
  );
};

export default SalesDetail;
