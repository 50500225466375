import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  getWePosSettingListAction,
  getWePosSettingListFailedAction,
  getWePosSettingListSuccessfullAction,
  updateWePosSettingFailedAction,
  updateWePosSettingSuccessfullAction,
  getWePosMetaDataListFailedAction,
  getWePosMetaDataListSuccessfullAction,
  getWePosMetaDataListAction,
  updateWePosMetaDataFailedAction,
  updateWePosMetaDataSuccessfullAction,
} from "./Actions";
import {
  GetWePosSettingListService,
  updateWePosSettingService,
  getWePosMetaDataListService,
  updateWePosMetaDataService,
} from "../../services/WePosServices";
import { el } from "date-fns/locale";

function* getWePosSettingList({ payload }) {
  try {
    const response = yield call(
      GetWePosSettingListService,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.setting_fof) {
          if (response.data.setting_fof.length > 0) {
            yield put(
              getWePosSettingListSuccessfullAction(response.data.setting_fof)
            );
          } else {
            yield put(getWePosSettingListFailedAction(""));
          }
        } else {
          yield put(getWePosSettingListFailedAction(""));
        }
      } else {
        yield put(getWePosSettingListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getWePosSettingListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getWePosSettingListFailedAction(error.message));
  }
}

function* updateWePosSetting({ payload }) {
  try {
    const response = yield call(
      updateWePosSettingService,
      payload.formData,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateWePosSettingSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getWePosSettingListAction(payload.login_key, payload.user_id)
        );
      } else {
        yield put(updateWePosSettingFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateWePosSettingFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(updateWePosSettingFailedAction(error.message));
  }
}
export function* getWePosMetaDataList({ payload }) {
  try {
    const response = yield call(
      getWePosMetaDataListService,
      payload.login_key,
      payload.user_id
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.fof_meta_list) {
          if (response.data.fof_meta_list.length > 0) {
            yield put(
              getWePosMetaDataListSuccessfullAction(response.data.fof_meta_list)
            );
          } else {
            yield put(getWePosMetaDataListFailedAction(""));
          }
        } else {
          yield put(getWePosMetaDataListFailedAction(""));
        }
      } else {
        yield put(
          getWePosMetaDataListFailedAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(getWePosMetaDataListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getWePosMetaDataListFailedAction(error.message));
  }
}
export function* updateWePosMetaData({ payload }) {
  try {
    const response = yield call(
      updateWePosMetaDataService,
      payload.login_key,
      payload.user_id,
      payload.meta_title,
      payload.meta_desc,
      payload.meta_keywords
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(
          updateWePosMetaDataSuccessfullAction(response.data.ResultMessage)
        );
        yield put(
          getWePosMetaDataListAction(payload.login_key, payload.user_id)
        );
      } else {
        yield put(updateWePosMetaDataFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(updateWePosMetaDataFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getWePosMetaDataListFailedAction(error.message));
  }
}
export function* watchUpdateWePosMetaData() {
  yield takeLatest(action_types.UPDATE_WEPOS_META_DATA, updateWePosMetaData);
}

export function* watchGetWePosMetaDataList() {
  yield takeLatest(action_types.GET_WEPOS_META_DATA_LIST, getWePosMetaDataList);
}
export function* watchUpdateWePos() {
  yield takeLatest(action_types.UPDATE_WEPOS_SETTING, updateWePosSetting);
}
export function* watchGetWePosSettingList() {
  yield takeLatest(action_types.GET_WEPOS_SETTING_LIST, getWePosSettingList);
}
export default function* rootWePos() {
  yield all([
    fork(watchGetWePosSettingList),
    fork(watchUpdateWePos),
    fork(watchUpdateWePosMetaData),
    fork(watchGetWePosMetaDataList),
  ]);
}
