import * as actionTypes from "../ActionTypes";

const initialStates = {
  result_login_successfull: false,
  result_failed: false,
  result_message: "",
  loading_mini: false,
  login_key: "",
  user_id: "",
  user_type: "",
  result_successfull1: false,
  result_successfull: false,
  result_successfull_profile: false,
  profile_list: [],
  login_parameters: [],
  shop_access_list: [],
  show_profile_image: false,
  refresh_login_loader: false,
  login_date: [],
  enable_two_factor: false,
  result_verify_otp_login_successfull: false,
};

const loginReducer = (state = initialStates, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_LOGIN:
      return {
        ...state,
        result_login_successfull: false,
        result_failed: false,
        result_message: "",
        loading_mini: false,
        result_successfull_profile: false,
        refresh_login_loader: false,
        enable_two_factor: false,
      };

    case actionTypes.SET_DEFAULT_PROFILE:
      return {
        ...state,
        loading_category_settings: false,
        get_category_list_loading: false,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_category: false,
        result_successfull_profile: false,
        // show_profile_image:false
      };
    case actionTypes.LOGIN:
      return {
        ...state,
        result_login_successfull: false,
        result_failed: false,
        result_message: "",
        loading_mini: true,
        refresh_login_loader: true,

        result_successfull_profile: false,
      };
    case actionTypes.LOGIN_SUCCESSFULL:
      return {
        ...state,
        login_date: action.payload,
        refresh_login_loader: false,
        result_login_successfull: true,
        result_failed: false,
        result_message: action.payload.ResultMessage,
        loading_mini: false,
        login_key: action.payload.LoginKey,
        user_id: action.payload.UserAccesses[0].UserId,
        profile_list: action.payload.UserAccesses,
        result_successfull_profile: false,
        user_type: action.payload.UserAccesses[0].UserType,
        shop_access_list: action.payload.ShopAccessList,
      };
    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        result_login_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_mini: false,
        result_successfull_profile: false,
        refresh_login_loader: false,
      };

    case actionTypes.UPDATE_PROFILE:
      return {
        ...state,
        result_failed: false,
        result_message: "",
        loading_mini: true,
        result_successfull_profile: false,
      };
    case actionTypes.UPDATE_PROFILE_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_message: action.payload,
        loading_mini: false,
        result_successfull_profile: true,
        show_profile_image: true,
      };
    // case actionTypes.SEND_IMAGE_FORMAT:
    //   return {
    //     ...state,
    //     image_format: action.payload,
    //   };
    case actionTypes.RECEIVE_LOGIN_RESPONSE:
      return {
        ...state,
        profile_list: action.payload,
      };
    case actionTypes.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        result_successfull_profile: false,
        result_failed: true,
        result_message: action.payload,
        loading_mini: false,
      };
    case actionTypes.SEND_LOGIN_PARAMETERS_TO_PROFILE:
      return {
        ...state,
        login_parameters: action.payload,
      };
    case actionTypes.SEND_OPT_LOGIN:
      return {
        ...state,
        result_login_successfull: false,
        result_failed: false,
        result_message: "",
        loading_mini: true,
      };
    case actionTypes.SEND_OPT_LOGIN_SUCCESSFULL:
      return {
        ...state,
        result_login_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_mini: false,
      };
    case actionTypes.SEND_OPT_LOGIN_FAILED:
      return {
        ...state,
        result_login_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_mini: false,
      };
    case actionTypes.VERIFY_OTP_LOGIN:
      return {
        ...state,
        result_verify_otp_login_successfull: false,
        result_failed: false,
        result_message: "",
        loading_mini: true,
      };
    case actionTypes.VERIFY_OTP_LOGIN_SUCCESSFULL:
      return {
        ...state,
        result_verify_otp_login_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_mini: false,
      };
    case actionTypes.VERIFY_OTP_LOGIN_FAILED:
      return {
        ...state,
        result_verify_otp_login_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_mini: false,
      };

    //ACTIVE TWO FACTOR AUTH
    case actionTypes.ACTIVE_TWO_FACTOR_AUTH:
      return {
        ...state,
        result_verify_otp_login_successfull: false,
        result_failed: false,
        result_message: "",
        loading_mini: true,
        enable_two_factor: false,
      };
    case actionTypes.ACTIVE_TWO_FACTOR_AUTH_SUCCESSFULL:
      return {
        ...state,
        result_verify_otp_login_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_mini: false,
        enable_two_factor: true,
      };
    case actionTypes.ACTIVE_TWO_FACTOR_AUTH_FAILED:
      return {
        ...state,
        result_verify_otp_login_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_mini: false,
        enable_two_factor: false,
      };

    default:
      return state;
  }
};
export default loginReducer;
