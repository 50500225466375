import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";
import toast, { Toaster } from "react-hot-toast";
import MenuListItem from "../../../../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";

import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  getNotificationEmailListAction,
  showNotificationEmailPopupAction,
  selectedNotificationEmailAction,
  deleteNotificationEmailAction,
} from "../../../../redux/settings/Action";
const EmailNotification = () => {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const {
    get_notification_email_list,
    loading_appSettings,
    web_payment_list,
    result_failed,
    result_successfull,
    result_message,
  } = useSelector((state) => state.appSettingsRedusers);

  const { height, width } = useScrollbarSize();
  const [notificationEmailList, setNotificationEmailList] = useState([]);
  const handleCreateNotificationEmail = () => {
    dispatch(showNotificationEmailPopupAction(true));
    dispatch(selectedNotificationEmailAction(null));
  };
  const handleUpdateNotificationEmail = (item) => {
    dispatch(showNotificationEmailPopupAction(true));
    dispatch(selectedNotificationEmailAction(item));
  };
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getNotificationEmailListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId
          )
        );
      }
    }
  }, [selected_shop]);
  useEffect(() => {
    if (get_notification_email_list) {
      if (get_notification_email_list.length > 0) {
        setNotificationEmailList(get_notification_email_list);
      } else {
        setNotificationEmailList([]);
      }
    } else {
      setNotificationEmailList([]);
    }
  }, [get_notification_email_list]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      if (result_message != "") {
        dispatch(setDefaultAction());
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  const handleDeleteNotificationEmeil = (item) => {
    dispatch(
      deleteNotificationEmailAction(login_key, user_id, item.ShopId, item.Id)
    );
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...notificationEmailList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setNotificationEmailList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...notificationEmailList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setNotificationEmailList(copy_list);
  };
  return (
    <div
      className="countaining-div "
      id="countaining"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "89.5%",
      }}
    >
      <div className="menu_list_header_container">
        <div className="menu_list_header_container">
          {/* BACK & TITLE */}

          {notificationEmailList.length > 0 ? (
            <div style={{ marginBottom: "150px" }}>
              {notificationEmailList.map((item, index) => (
                <div key={index}>
                  <MenuListItem
                    field_number={1}
                    field_1_title={item.Email}
                    item_title={item.Email}
                    btn_number={1}
                    bt_1_title={lang.edit}
                    bt_1_click={() => handleUpdateNotificationEmail(item)}
                    show_delete={true}
                    delete_click={() => handleDeleteNotificationEmeil(item)}
                    show_edit_icon={false}
                    // selected={selected_shop_employees}
                    item={item}
                    number={0}
                    show_delete_box={() => handleShowDeleteBox(item)}
                    cancel_delete_box={() => handleCancelDeleteBox(item)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="div_submenu_add_container" style={{ zIndex: 1 }}>
              <h4>{lang.noOption}</h4>
              <span
                onClick={() => handleCreateNotificationEmail()}
                style={{ width: "160px" }}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                <p>{lang.create_email}</p>
              </span>
            </div>
          )}
        </div>
        <div>
          {/* STICKY ADD SUBMENU BUTTON */}
          {notificationEmailList.length > 0 ? (
            <div
              className="div_submenu_add_container_fixed"
              onClick={() => handleCreateNotificationEmail()}
              style={{
                right:
                  (window.innerWidth * 2) / 100 +
                  width +
                  marginNormalMenuHorizontal,
              }}
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_email}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EmailNotification;
