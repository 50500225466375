import React, { useState, useEffect } from "react";
import DojoList from "./DojoList";
import { useSelector, useDispatch } from "react-redux";
import DojoEdit from "./DojoEdit";

import { getWorksStationsAction } from "../../../../redux/WorksStations/Actions";
const DojoIndex = () => {
  const dispatch = useDispatch();
  const [clickedItem, setClickedItem] = useState(1);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  // useEffect(() => {
  //   if (selected_shop.length > 0) {
  //     dispatch(
  //       getWorksStationsAction(login_key, user_id, selected_shop[0].ShopId)
  //     );
  //   }
  // }, [selected_shop]);
  return (
    <div style={{ height: "89.5%" }}>
      {clickedItem == 1 ? (
        <DojoList setClickedItem={(i) => setClickedItem(i)} />
      ) : clickedItem == 2 ? (
        <DojoEdit setClickedItem={(i) => setClickedItem(i)} />
      ) : null}
    </div>
  );
};

export default DojoIndex;
