import { accordionActionsClasses } from "@mui/material";
import { Action } from "history";
import * as actionTypes from "../ActionTypes";

const initialState = {
  submenu_list: [],
  result_successfull: false,
  result_failed: false,
  result_message: "",
  loading_submenu: false,
  selected_submenu: null,
  loding_submenu_popup: false,
  submenu_item_list: [],
  selected_submenu_item: null,
  show_select_item_popup: false,
  is_dressing: false,
  show_import_submenu_popup: false,
  is_created: false,
  new_submenu_id: null,
  sub_item_sort_number: 0,
  fixed_submenu_list: [],
  fixed_submenu_ids: [],
};
const submenuRedusers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_submenu: false,
        is_created: false,
      };
    case actionTypes.CREATE_SUBMENU:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_submenu: true,
        is_created: false,
      };
    case actionTypes.CREATE_SUBMENU_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: false,
        is_created: true,
      };
    case actionTypes.CREATE_SUBMENU_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
        is_created: false,
      };
    //GET SUBMENU
    case actionTypes.GET_SUBMENU:
      return {
        ...state,
        loading_submenu: action.payload.product_id ? false : true,
        loding_submenu_popup: action.payload.product_id ? true : false,
      };
    case actionTypes.GET_SUBMENU_SUCCESSFULL:
      let my_list = [{ label: "None", value: 0 }];
      let ids = [0];
      if (action.payload) {
        if (action.payload.length > 0) {
          for (let i = 0; i < action.payload.length; i++) {
            if (action.payload[i].FixedItem) {
              my_list.push({
                label: action.payload[i].AppTitle,
                value: action.payload[i].Id,
              });
              ids.push(action.payload[i].Id);
            }
          }
        }
      }
      return {
        ...state,
        loading_submenu: false,
        loding_submenu_popup: false,
        submenu_list: action.payload,
        fixed_submenu_list: my_list,
        fixed_submenu_ids: ids,
      };
    case actionTypes.GET_SUBMENU_FAILED:
      return {
        ...state,
        // result_failed: true,
        // result_message: action.payload,
        loading_submenu: false,
        loding_submenu_popup: false,
        submenu_list: [],
        fixed_submenu_list: [{ label: "None", value: 0 }],
        fixed_submenu_ids: [0],
      };
    case actionTypes.SELECTED_SUBMENU:
      return {
        ...state,
        selected_submenu: action.payload,
      };
    case actionTypes.DELETE_SUBMENU:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_submenu: true,
      };
    case actionTypes.DELETE_SUBMENU_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.DELETE_SUBMENU_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.UPDATE_SUBMENU:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_submenu: true,
      };
    case actionTypes.UPDATE_SUBMENU_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.UPDATE_SUBMENU_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.CREATE_SUBMENU_ITEM:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_submenu: true,
      };
    case actionTypes.CREATE_SUBMENU_ITEM_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.CREATE_SUBMENU_ITEM_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.GET_SUBMENU_ITEM:
      return {
        ...state,
        loading_submenu: true,
      };
    case actionTypes.GET_SUBMENU_ITEM_SUCCESSFULL:
      return {
        ...state,
        loading_submenu: false,
        submenu_item_list: action.payload,
        result_failed: false,
      };
    case actionTypes.GET_SUBMENU_ITEM_FAILED:
      return {
        ...state,
        result_failed: true,
        result_message: action.payload,
        submenu_item_list: [],
        loading_submenu: false,
      };

    case actionTypes.SELECTED_SUBMENU_ITEM:
      return {
        ...state,
        selected_submenu_item: action.payload,
      };
    case actionTypes.SET_DEFAULT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_submenu: false,
      };
    case actionTypes.DELETE_SUBMENU_ITEM:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_submenu: true,
      };
    case actionTypes.DELETE_SUBMENU_ITEM_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.DELETE_SUBMENU_ITEM_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.UPDATE_SUBMENU_ITEM_EDIT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: true,
      };
    case actionTypes.UPDATE_SUBMENU_ITEM_EDIT_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.UPDATE_SUBMENU_ITEM_EDIT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.SHOW_SELECT_ITEM_POPUP:
      return {
        ...state,
        show_select_item_popup: action.payload,
      };
    case actionTypes.IS_DRESSING:
      return {
        ...state,
        is_dressing: action.payload,
      };

    //IMPORT SUBITEM
    case actionTypes.IMPORT_SUBITEM:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: true,
      };
    case actionTypes.IMPORT_SUBITEM_SUCCESSFULL:
      return {
        ...state,
        result_successfull: true,
        result_failed: false,
        result_message: action.payload,
        loading_submenu: false,
        show_select_item_popup: false,
        show_import_submenu_popup: false,
      };
    case actionTypes.IMPORT_SUBITEM_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.SHOW_IMPORT_SUBMENU_POPUP:
      return {
        ...state,
        show_import_submenu_popup: action.payload,
      };

    case actionTypes.GET_NEW_SUBMENU_ID:
      return {
        ...state,
        new_submenu_id: action.payload,
      };

    //UPDATE SUBMENU ITEM SORT
    case actionTypes.UPDATE_SUBMENU_ITEM_WEB_SORT:
      return {
        ...state,
        result_successfull: false,
        result_failed: false,
        result_message: "",
        loading_submenu: true,
      };
    case actionTypes.UPDATE_SUBMENU_ITEM_WEB_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.UPDATE_SUBMENU_ITEM_WEB_SORT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.UPDATE_SUBMENU_ITEM_APP_SORT:
      return {
        ...state,
        result_failed: false,
        result_successfull: false,
        result_message: "",
        loading_submenu: true,
      };
    case actionTypes.UPDATE_SUBMENU_ITEM_APP_SORT_SUCCESSFULL:
      return {
        ...state,
        result_failed: false,
        result_successfull: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.UPDATE_SUBMENU_ITEM_APP_SORT_FAILED:
      return {
        ...state,
        result_successfull: false,
        result_failed: true,
        result_message: action.payload,
        loading_submenu: false,
      };
    case actionTypes.SUBMENU_ITEM_SORT_NUMBER:
      return {
        ...state,
        sub_item_sort_number: action.payload,
      };
    default:
      return state;
  }
};
export default submenuRedusers;
