import * as actionTypes from "../ActionTypes";

export const getShopEmployersListAction = (login_key, user_id, shopId) => {
  return {
    type: actionTypes.GET_SHOP_EMPLOYEES,
    payload: { login_key, user_id, shopId },
  };
};
export const getShopEmployersListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_SHOP_EMPLOYEES_SUCCESSFULL,
    payload: data,
  };
};
export const getShopEmployersListFailedAction = (data) => {
  return {
    type: actionTypes.GET_SHOP_EMPLOYEES_FAILED,
    payload: data,
  };
};
export const createImployersListAction = (
  login_key,
  login_key1,
  user_id,
  shopId,
  gender_id,
  employee_name,
  job,
  national_id,
  mobile,
  telephone,
  email,
  address,
  post_code,
  user_name,
  pass,
  dob,
  married,
  in_suranceld,
  date_in,
  date_out,
  active,
  group_id,
  is_admin,
  single_user,
  btn_color,
  btn_font_color,
  url_image
) => {
  return {
    type: actionTypes.CREATE_EMPLOYEES,
    payload: {
      login_key,
      login_key1,
      user_id,
      shopId,
      gender_id,
      employee_name,
      job,
      national_id,
      mobile,
      telephone,
      email,
      address,
      post_code,
      user_name,
      pass,
      dob,
      married,
      in_suranceld,
      date_in,
      date_out,
      active,
      group_id,
      is_admin,
      single_user,
      btn_color,
      btn_font_color,
      url_image,
    },
  };
};
export const createEmployersListSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_EMPLOYEES_SUCCESSFULL,
    payload: data,
  };
};
export const createEmployersListFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_EMPLOYEES_FAILED,
    payload: data,
  };
};

export const updateImployersListAction = (
  formData,
  login_key,
  user_id,
  id,
  shopId,
  gender_id,
  employee_name,
  job,
  national_id,
  mobile,
  telephone,
  email,
  address,
  post_code,
  user_name,
  pass,
  dob,
  married,
  in_suranceld,
  date_in,
  date_out,
  active,
  group_id,
  is_admin,
  single_user,
  btn_color,
  btn_font_color,
  url_image
) => {
  return {
    type: actionTypes.UPDATE_EMPLOYEES,
    payload: {
      formData,
      login_key,
      user_id,
      id,
      shopId,
      gender_id,
      employee_name,
      job,
      national_id,
      mobile,
      telephone,
      email,
      address,
      post_code,
      user_name,
      pass,
      dob,
      married,
      in_suranceld,
      date_in,
      date_out,
      active,
      group_id,
      is_admin,
      single_user,
      btn_color,
      btn_font_color,
      url_image,
    },
  };
};
export const updateEmployersListSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_EMPLOYEES_SUCCESSFULL,
    payload: data,
  };
};
export const updateEmployersListFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_EMPLOYEES_FAILED,
    payload: data,
  };
};
export const selecteShopEmployees = (data) => {
  return {
    type: actionTypes.SELECTED_SHOP_EMPLOYEES,
    payload: data,
  };
};
export const deleteShopEmployeesAction = (
  login_key,
  user_id,
  shopId,
  employee_id
) => {
  return {
    type: actionTypes.DELETE_SHOP_EMPLOYEES,
    payload: { login_key, user_id, shopId, employee_id },
  };
};
export const deleteShopEmployeesSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_SHOP_EMPLOYEES_SUCCESSFULL,
    payload: data,
  };
};
export const deleteShopEmployeesFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_SHOP_EMPLOYEES_FAILED,
    payload: data,
  };
};

export const visibleDriversPopupAction = (data) => {
  return {
    type: actionTypes.VISIBLE_DRIVERS_POPUP,
    payload: data,
  };
};

export const getDriversListAction = (login_key, user_id) => {
  return {
    type: actionTypes.GET_DRIVERS_LIST,
    payload: { login_key, user_id },
  };
};
export const getDriversListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_DRIVERS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getDriversListFailedAction = () => {
  return {
    type: actionTypes.GET_DRIVERS_LIST_FAILED,
  };
};
export const createDriverAction = (
  formData,
  login_key,
  user_id,
  first_name,
  last_name,
  active,
  mobile,
  user_name,
  password,
  image
) => {
  return {
    type: actionTypes.CREATE_DRIVER,
    payload: {
      formData,
      login_key,
      user_id,
      first_name,
      last_name,
      active,
      mobile,
      user_name,
      password,
      image,
    },
  };
};
export const createDriverSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_DRIVER_SUCCESSFULL,
    payload: data,
  };
};
export const createDriverFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_DRIVER_FAILED,
    payload: data,
  };
};
export const updateDriverAction = (
  formData,
  login_key,
  user_id,
  id,
  first_name,
  last_name,
  active,
  mobile,
  user_name,
  password,
  image
) => {
  return {
    type: actionTypes.UPDATE_DRIVER,
    payload: {
      formData,
      login_key,
      user_id,
      id,
      first_name,
      last_name,
      active,
      mobile,
      user_name,
      password,
      image,
    },
  };
};
export const updateDriverSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_DRIVER_SUCCESSFULL,
    payload: data,
  };
};
export const updateDriverFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_DRIVER_FAILED,
    payload: data,
  };
};
export const deleteDriverAction = (login_key, user_id, id) => {
  return {
    type: actionTypes.DELETE_DRIVER,
    payload: { login_key, user_id, id },
  };
};
export const deleteDriverSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_DRIVER_SUCCESSFULL,
    payload: data,
  };
};
export const deleteDriverFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_DRIVER_FAILED,
    payload: data,
  };
};
export const selectedDriverAction = (data) => {
  return {
    type: actionTypes.SELECTED_DRIVER,
    payload: data,
  };
};

export const driverWithUniqueIdAction = (
  login_key,
  user_id,
  shop_id,
  driver_unique_id
) => {
  return {
    type: actionTypes.DRIVER_WITH_UNIQUE_ID,
    payload: { login_key, user_id, shop_id, driver_unique_id },
  };
};
export const driverWithUniqueIdSuccessfullAction = (data) => {
  return {
    type: actionTypes.DRIVER_WITH_UNIQUE_ID_SUCCESSFULL,
    payload: data,
  };
};
export const driverWithUniqueIdFailedAction = () => {
  return {
    type: actionTypes.DRIVER_WITH_UNIQUE_ID_FAILED,
  };
};
export const driverShopListAction = (login_key, user_id, shop_id) => {
  return {
    type: actionTypes.DRIVER_SHOP_LIST,
    payload: { login_key, user_id, shop_id },
  };
};
export const driverShopListSuccessfullAction = (data) => {
  return {
    type: actionTypes.DRIVER_SHOP_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const driverShopListFailedAction = () => {
  return {
    type: actionTypes.DRIVER_SHOP_LIST_FAILED,
  };
};

export const createDriverShopAction = (
  login_key,
  user_id,
  shop_id,
  driver_id
) => {
  return {
    type: actionTypes.CREATE_DRIVER_SHOP,
    payload: { login_key, user_id, shop_id, driver_id },
  };
};
export const createDriverShopSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_DRIVER_SHOP_SUCCESSFULL,
    payload: data,
  };
};
export const createDriverShopFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_DRIVER_SHOP_FAILED,
    payload: data,
  };
};
export const deleteDriverShopAction = (
  login_key,
  user_id,
  shop_id,
  driver_id
) => {
  return {
    type: actionTypes.DELETE_DRIVER_SHOP,
    payload: { login_key, user_id, shop_id, driver_id },
  };
};
export const deleteDriverShopSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_DRIVER_SHOP_SUCCESSFULL,
    payload: data,
  };
};
export const deleteDriverShopFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_DRIVER_SHOP_FAILED,
    payload: data,
  };
};

export const deviceAccessGroupListAction = (shop_id, login_key, user_id) => {
  return {
    type: actionTypes.DEVICE_ACCESS_GROUP_LIST,
    payload: { shop_id, login_key, user_id },
  };
};
export const deviceAccessGroupListSuccessfullAction = (data) => {
  return {
    type: actionTypes.DEVICE_ACCESS_GROUP_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const deviceAccessGroupListFailedAction = (data) => {
  return {
    type: actionTypes.DEVICE_ACCESS_GROUP_LIST_FAILED,
    payload: data,
  };
};

export const createDevicesAccessGroupAction = (
  shop_id,
  login_key,
  user_id,
  role_id,
  title
) => {
  return {
    type: actionTypes.CREATE_DEVICE_ACCESS_GROUP,
    payload: { shop_id, login_key, user_id, role_id, title },
  };
};
export const createDevicesAccessGroupSuccessfullAction = (data) => {
  return {
    type: actionTypes.CREATE_DEVICE_ACCESS_GROUP_SUCCESSFULL,
    payload: data,
  };
};
export const createDevicesAccessGroupFailedAction = (data) => {
  return {
    type: actionTypes.CREATE_DEVICE_ACCESS_GROUP_FAILED,
    payload: data,
  };
};
export const updateDevicesAccessGroupAction = (
  shop_id,
  login_key,
  user_id,
  role_id,
  title,
  id
) => {
  return {
    type: actionTypes.UPDATE_DEVICE_ACCESS_GROUP,
    payload: {
      shop_id,
      login_key,
      user_id,
      role_id,
      title,
      id,
    },
  };
};
export const updateDevicesAccessGroupSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_DEVICE_ACCESS_GROUP_SUCCESSFULL,
    payload: data,
  };
};
export const updateDevicesAccessGroupFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_DEVICE_ACCESS_GROUP_FAILED,
    payload: data,
  };
};
export const deleteDevicesAccessGroupAction = (
  login_key,
  user_id,
  shop_id,
  id
) => {
  return {
    type: actionTypes.DELETE_DEVICE_ACCESS_GROUP,
    payload: {
      login_key,
      user_id,
      shop_id,
      id,
    },
  };
};
export const deleteDevicesAccessGroupSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_DEVICE_ACCESS_GROUP_SUCCESSFULL,
    payload: data,
  };
};
export const deleteDevicesAccessGroupFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_DEVICE_ACCESS_GROUP_FAILED,
    payload: data,
  };
};

export const selectedAccessGroupAction = (data) => {
  return {
    type: actionTypes.SELECTED_ACCESS_GROUP,
    payload: data,
  };
};

export const deviceRoleListAction = (data) => {
  return {
    type: actionTypes.DEVICE_ROLE_LIST,
    payload: data,
  };
};

// CHECK IN CHECK OUT
export const getCheckInCheckOutListAction = (
  shop_id,
  login_key,
  user_id,
  date_from,
  date_to
) => {
  return {
    type: actionTypes.GET_CHECK_IC_CHECK_OUT_LIST,
    payload: { shop_id, login_key, user_id, date_from, date_to },
  };
};

export const getCheckInCheckOutListSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_CHECK_IC_CHECK_OUT_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getCheckInCheckOutListFailedAction = (data) => {
  return {
    type: actionTypes.GET_CHECK_IC_CHECK_OUT_LIST_FAILED,
    payload: data,
  };
};

export const updateCheckInCheckOutAction = (
  shop_id,
  login_key,
  user_id,
  id,
  date_from,
  date_to,
  low_time_work,
  login_user_id,
  date_from_for_list,
  date_to_for_list
) => {
  return {
    type: actionTypes.UPDATE_CHECK_IC_CHECK_OUT,
    payload: {
      shop_id,
      login_key,
      user_id,
      id,
      date_from,
      date_to,
      low_time_work,
      login_user_id,
      date_from_for_list,
      date_to_for_list,
    },
  };
};

export const updateCheckInCheckOutSuccessfullAction = (data) => {
  return {
    type: actionTypes.UPDATE_CHECK_IC_CHECK_OUT_SUCCESSFULL,
    payload: data,
  };
};
export const updateCheckInCheckOutFailedAction = (data) => {
  return {
    type: actionTypes.UPDATE_CHECK_IC_CHECK_OUT_FAILED,
    payload: data,
  };
};

export const deleteCheckIncheckOutAction = (
  shop_id,
  login_key,
  user_id,
  id,
  login_user_id,
  date_from,
  date_to
) => {
  return {
    type: actionTypes.DELETE_CHECK_IC_CHECK_OUT,
    payload: {
      shop_id,
      login_key,
      user_id,
      id,
      login_user_id,
      date_from,
      date_to,
    },
  };
};
export const deleteCheckIncheckOutSuccessfullAction = (data) => {
  return {
    type: actionTypes.DELETE_CHECK_IC_CHECK_OUT_SUCCESSFULL,
    payload: data,
  };
};
export const deleteCheckIncheckOutFailedAction = (data) => {
  return {
    type: actionTypes.DELETE_CHECK_IC_CHECK_OUT_FAILED,
    payload: data,
  };
};

// GET ENTER EXIT LIST BY EMPLOYEE ID
export const getEnterExitListByEmployeeIdAction = (
  shop_id,
  login_key,
  user_id,
  date_from,
  date_to,
  employee_id
) => {
  return {
    type: actionTypes.GET_ENTER_EXIT_LIST_BY_EMPLOYEE,
    payload: {
      shop_id,
      login_key,
      user_id,
      date_from,
      date_to,
      employee_id,
    },
  };
};
export const getEnterExitListByEmployeeIdSuccessfullAction = (data) => {
  return {
    type: actionTypes.GET_ENTER_EXIT_LIST_BY_EMPLOYEE_SUCCESSFULL,
    payload: data,
  };
};

export const getEnterExitListByEmployeeIdFailedAction = (data) => {
  return {
    type: actionTypes.GET_ENTER_EXIT_LIST_BY_EMPLOYEE_FAILED,
    payload: data,
  };
};
