import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import validator from "validator";
import { setDefaultAction } from "../../../../redux/menu/Actions";
import {
  vatListAction,
  visibleVatPopupAction,
  updateVatAction,
  createVatAction,
} from "../../../../redux/GeneralSetting/Actions";
import { getDropDownAction } from "../../../../redux/menu/Actions";
const VatPopup = () => {
  const vatNameRef = useRef();
  const vatValueRef = useRef();
  const activeRef = useRef();
  const dispatch = useDispatch();
  const {
    vat_list,
    selected_vat_list,
    result_successfull,
    result_failed,
    result_message,
  } = useSelector((state) => state.tabelAreaRedusers);
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const [vatName, setVatName] = useState("");
  const [vatValue, setVatValue] = useState("");
  const [percent, setPercent] = useState(false);

  const handleCreateAndUpdate = () => {
    if (selected_vat_list) {
      if (vatName == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (vatValue != "" && !validator.isFloat(String(vatValue))) {
        toast.error(
          <h5>
            {" "}
            &nbsp;&nbsp;{
              lang.the_vat_value_format_is_incorrect
            }&nbsp;&nbsp;{" "}
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          updateVatAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            selected_vat_list.VatId,
            vatName.replaceAll(`"`, `\"\"`),
            vatValue ? parseFloat(vatValue) : 0
          )
        );
      }
    } else {
      if (vatName == "") {
        toast.error(
          <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else if (vatValue != "" && !validator.isFloat(String(vatValue))) {
        toast.error(
          <h5>
            &nbsp;&nbsp;{lang.the_vat_value_format_is_incorrect}&nbsp;&nbsp;
          </h5>,
          {
            position: "top-center",
            duration: 3000,
            style: { backgroundColor: "red", color: "white" },
            icon: (
              <i
                style={{ fontSize: 40 }}
                className="fa fa-times-circle"
                aria-hidden="true"
              ></i>
            ),
          }
        );
      } else {
        dispatch(
          createVatAction(
            login_key,
            user_id,
            selected_shop[0].ShopId,
            vatName.replaceAll(`"`, `\"\"`),
            vatValue ? parseFloat(vatValue) : 0
          )
        );
      }
    }
  };
  const handleVatNameKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      vatValueRef.current.focus();
    }
  };
  const handleVatVaueKeyDown = (event) => {
    if (event.key == "Enter") {
      event.preventDefault();
      handleCreateAndUpdate();
    }
  };
  useEffect(() => {
    if (selected_vat_list) {
      setVatName(selected_vat_list.VatName);
      setVatValue(selected_vat_list.VatValue);
      setPercent(selected_vat_list.IsPercent);
    }
  }, [selected_vat_list]);

  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
      dispatch(visibleVatPopupAction(false));
    } else if (result_failed) {
      dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  return (
    <div className="create_shop_popup_container">
      <div className="create_shop_popup_content_container post-code-content-container">
        <div className=" create-shop-close-div-post-code">
          <i
            onClick={() => dispatch(visibleVatPopupAction(false))}
            style={{ fontSize: 25 }}
            aria-hidden="true"
            className="fa fa-times post-code-close-icon"
          ></i>
          <p className="post-code-p">VAT</p>
        </div>
        <div className="container-input-div">
          <div
            className="create_shop_inputs_container post-code-create_shop_inputs_container"
            style={{ height: "13%", margin: "25px auto " }}
          >
            <form>
              <h6 className="h6 post-code-h6">* {lang.vat_name}</h6>
              <input
                autoFocus
                type="text"
                value={vatName}
                onChange={(str) => setVatName(str.target.value)}
                ref={vatNameRef}
                onKeyDown={handleVatNameKeyDown}
              />
            </form>
          </div>
          <div
            className="create_shop_inputs_container"
            style={{ height: "13%", margin: " 45px auto" }}
          >
            <form>
              <h6 className="h6 post-code-h6">{lang.vat_value} %</h6>
              <input
                type="text"
                value={vatValue}
                onChange={(str) =>
                  setVatValue(str.target.value.replace(/\D/g, ""))
                }
                ref={vatValueRef}
                onKeyDown={handleVatVaueKeyDown}
              />
            </form>
          </div>

          {/* <div
            className="
            vat-container-checkbox-div"
          >
            <form>
              <input
                id="check9"
                // className="checkbox"
                type="checkbox"
                checked={percent}
                onChange={() => setPercent(!percent)}
              />
              <label for="check9" className="checkmark"></label>
              <h6 className=" h6">{lang.is_percent}</h6>
            </form>
          </div> */}
        </div>
        <div className="post-code-popup-buttons-container">
          <button
            // disabled={selectedSubmenuIds.length > 0 ? false : true}
            onClick={handleCreateAndUpdate}
            id="popup_add"
          >
            {lang.save}
          </button>
        </div>
        <Toaster />
      </div>
    </div>
  );
};

export default VatPopup;
