import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { main_nav_button_color } from "../../../constants/Colors";
import { getOrderListAppShopsAction } from "../../../redux/Orders/Actions";
import OrderListItem from "../../../components/OrderListItem ";
import HeaderList from "../../../components/HeaderList";
import {
  ordersListAction,
  showOrderListPopupAction,
  selectedOrderListAction,
} from "../../../redux/Orders/Actions";
const Rejected = () => {
  const dispatch = useDispatch();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { orders_list, get_order_app_shops_rejected_list } = useSelector(
    (state) => state.ordersReduser
  );
  useEffect(() => {
    dispatch(getOrderListAppShopsAction(login_key, user_id, 1000405));
  }, []);

  const handleSelectedOrder = (item) => {
    dispatch(showOrderListPopupAction(true));
    dispatch(selectedOrderListAction(item));
  };

  return (
    <div
      className="countaining-div"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        paddingBottom: "150px",
      }}
    >
      <div className="menu_list_header_container">
        <div className="margin_menu_normal_horizontal">
          <div>
            <HeaderList number={2} />
            {get_order_app_shops_rejected_list
              ? get_order_app_shops_rejected_list.length > 0
                ? get_order_app_shops_rejected_list.map((item, index) => (
                    <OrderListItem
                      item={item}
                      order_id={item.OrderId}
                      invice_id={item.InvoiceId}
                      status_name={item.OrderStatusName}
                      status={item.OrderStatus}
                      channels={item.DeviceTypeName}
                      requested_for={item.OrderDate}
                      // store_name={item.Store_name}
                      order_amount={item.ForPay.toFixed(2)}
                      payment_status={item.PayMethodName}
                      customer={item.CustomerFullName}
                      number={2}
                      index1={index}
                      on_click={() => handleSelectedOrder(item)}
                      post_code={item.CustomerPostCode}
                      sale_mathod_number={item.SaleMethod}
                    />
                  ))
                : null
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rejected;
