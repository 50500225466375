import {
  put,
  takeEvery,
  takeLatest,
  call,
  fork,
  all,
} from "redux-saga/effects";
import * as action_types from "../ActionTypes";
import {
  getCustomersListAction,
  getCustomersListFailedAction,
  getCustomersListSuccessfullAction,
  editCustomerFailedAction,
  editCustomerSuccessfullAction,
  deleteCustomerFailedAction,
  deleteCustomerSuccessfullAction,
  getCustomersExportFailedListAction,
  getCustomersExportSuccesffullListAction,
  getCustomersExportListAction,
} from "./Actions";
import {
  getCustomersListService,
  editCustomersListService,
  deleteCustomersListService,
  getCustomersExportListService,
} from "../../services/CustomersServices";

function* getCustomersList({ payload }) {
  try {
    const response = yield call(
      getCustomersListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.pageNumber,
      payload.search_text,
      payload.search_type
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.customers_list) {
          if (response.data.customers_list.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.customers_list.length; i++) {
              my_list.push({
                ...response.data.customers_list[i],
                show_edit_input: false,
                showDeleteBox: false,
                Adrress: response.data.customers_list[i].customer_address
                  ? response.data.customers_list[i].customer_address[0].Address
                  : null,
                PostCode: response.data.customers_list[i].customer_address
                  ? response.data.customers_list[i].customer_address[0].PostCode
                  : null,
              });
            }
            yield put(getCustomersListSuccessfullAction(my_list));
          } else {
            yield put(getCustomersListFailedAction(""));
          }
        } else {
          yield put(getCustomersListFailedAction(""));
        }
      } else {
        yield put(getCustomersListFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(getCustomersListFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(getCustomersListFailedAction(error.message));
  }
}
function* getCustomersExportList({ payload }) {
  try {
    const response = yield call(
      getCustomersExportListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,

      payload.search_text,
      payload.search_type
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        if (response.data.customers_list) {
          if (response.data.customers_list.length > 0) {
            let my_list = [];
            for (let i = 0; i < response.data.customers_list.length; i++) {
              my_list.push({
                ...response.data.customers_list[i],
                Adrress: response.data.customers_list[i].customer_address
                  ? response.data.customers_list[i].customer_address[0].Address
                  : null,
                PostCode: response.data.customers_list[i].customer_address
                  ? response.data.customers_list[i].customer_address[0].PostCode
                  : null,
              });
            }
            yield put(getCustomersExportSuccesffullListAction(my_list));
          } else {
            yield put(getCustomersExportFailedListAction(""));
          }
        } else {
          yield put(getCustomersExportFailedListAction(""));
        }
      } else {
        yield put(
          getCustomersExportFailedListAction(response.data.ResultMessage)
        );
      }
    } else {
      yield put(
        getCustomersExportFailedListAction(response.data.ResultMessage)
      );
    }
  } catch (error) {
    yield put(getCustomersExportFailedListAction(error.message));
  }
}
function* eidtCustomer({ payload }) {
  try {
    const response = yield call(
      editCustomersListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.customer_id,
      payload.customer_address_id,
      payload.first_name,
      payload.last_name,
      payload.email,
      payload.mobile,
      payload.address,
      payload.post_code,
      payload.delivery_charge,
      payload.pageNumber,
      payload.search_text,
      payload.search_type
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(editCustomerSuccessfullAction(response.data.ResultMessage));
        yield put(
          getCustomersListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.pageNumber,
            payload.search_text,
            payload.search_type
          )
        );
        yield put(
          getCustomersExportListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.search_text,
            payload.search_type
          )
        );
      } else {
        yield put(editCustomerFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(editCustomerFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(editCustomerFailedAction(error.message));
  }
}
function* deleteCustomer({ payload }) {
  try {
    const response = yield call(
      deleteCustomersListService,
      payload.login_key,
      payload.user_id,
      payload.shop_id,
      payload.cutomer_id,
      payload.pageNumber,
      payload.search_text,
      payload.search_type
    );

    if (response.status == 200) {
      if (response.data.ResultCode == 1) {
        yield put(deleteCustomerSuccessfullAction(response.data.ResultMessage));
        yield put(
          getCustomersListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.pageNumber,
            payload.search_text,
            payload.search_type
          )
        );
        yield put(
          getCustomersExportListAction(
            payload.login_key,
            payload.user_id,
            payload.shop_id,
            payload.search_text,
            payload.search_type
          )
        );
      } else {
        yield put(deleteCustomerFailedAction(response.data.ResultMessage));
      }
    } else {
      yield put(deleteCustomerFailedAction(response.data.ResultMessage));
    }
  } catch (error) {
    yield put(deleteCustomerFailedAction(error.message));
  }
}
export function* watchCustomersExportList() {
  yield takeLatest(
    action_types.GET_CUSTOMERS_EXPORT_LIST,
    getCustomersExportList
  );
}
export function* watchDeleteCustomer() {
  yield takeLatest(action_types.DELETE_CUSTOMER, deleteCustomer);
}
export function* watchEditCustomer() {
  yield takeLatest(action_types.EDIT_CUSTOMER, eidtCustomer);
}
export function* watchCustomersList() {
  yield takeLatest(action_types.GET_CUSTOMERS_LIST, getCustomersList);
}
export default function* rootCustomer() {
  yield all([
    fork(watchCustomersList),
    fork(watchEditCustomer),
    fork(watchDeleteCustomer),
    fork(watchCustomersExportList),
  ]);
}
