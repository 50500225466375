import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import {
  main_nav_button_color,
  main_nav_back_color,
  primaryColor,
  white,
} from "../constants/Colors";
import Loader from "./Loader";
import LoaderMini from "./LoaderMini";
import { setDefaultAction } from "../redux/menu/Actions";
import {
  getWorksStationStatusListAction,
  updateWorksStationStatusAction,
} from "../redux/WorksStations/Actions";
import ListHeader from "./ListHeader";
import { useSelector, useDispatch } from "react-redux";
const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "white",
  zIndex: "3333333333",
};
const WorksStationStatus = () => {
  const dispatch = useDispatch();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const {
    loading_worksStations,
    result_successfull,
    result_failed,
    result_message,
    get_woeksStation_status_list,
    loading_update_worksStations_status,
  } = useSelector((state) => state.worksStationsReduser);
  const { device_type, device_type_id } = useSelector(
    (state) => state.categoryList
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const [worksStationStatusList, setWorksStationStatusList] = useState([]);
  const [id, setId] = useState(0);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const handleUpdateWorksStationStatus = (item) => {
    const copy_worksStationStatusList = [...worksStationStatusList];
    if (copy_worksStationStatusList) {
      if (copy_worksStationStatusList.length > 0) {
        for (let i = 0; i < copy_worksStationStatusList.length; i++) {
          if (
            item.WorkStationId == copy_worksStationStatusList[i].WorkStationId
          ) {
            setId(item.WorkStationId);
            dispatch(
              updateWorksStationStatusAction(
                login_key,
                user_id,
                selected_shop[0].ShopId,
                item.WorkStationId
              )
            );
          }
        }
      }
    }
  };
  useEffect(() => {
    if (selected_shop) {
      if (selected_shop.length > 0) {
        dispatch(
          getWorksStationStatusListAction(
            login_key,
            user_id,
            selected_shop[0].ShopId
          )
        );
      }
    }
  }, [selected_shop]);

  useEffect(() => {
    if (get_woeksStation_status_list) {
      if (get_woeksStation_status_list.length > 0) {
        setWorksStationStatusList(get_woeksStation_status_list);
      } else {
        setWorksStationStatusList([]);
      }
    } else {
      setWorksStationStatusList([]);
    }
  }, [get_woeksStation_status_list]);
  useEffect(() => {
    if (result_successfull) {
      dispatch(setDefaultAction());
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 7000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      if (result_message != "") {
        dispatch(setDefaultAction());
        toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              class="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        });
      }
    }
  }, [result_successfull, result_failed]);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  useEffect(() => {
    if (!loading_update_worksStations_status) {
      setId(0);
    }
  }, [loading_update_worksStations_status]);
  return width1 <= 560 ? (
    <div className="countaining-div">
      {" "}
      <>
        <div>
          <ListHeader title={lang.workStation_status} show_back={false} />
        </div>
        <div
          className="menu_list_header_container"
          style={{ paddingBottom: "110px" }}
        >
          <div className="margin_menu_normal_horizontal">
            <div
              style={{
                marginTop: "25px",
              }}
            >
              {worksStationStatusList.length > 0 ? (
                worksStationStatusList.map((item, index) => (
                  <div
                    className="mobile-orders-container1"
                    onClick={() => handleUpdateWorksStationStatus(item)}
                    style={{ position: "relative" }}
                  >
                    <span className="mobile-container-sync-box">
                      {item.WorkStationId == id ? (
                        loading_update_worksStations_status ? (
                          <ClipLoader
                            color={"white"}
                            loading={true}
                            cssOverride={override}
                            size={35}
                          />
                        ) : null
                      ) : null}
                    </span>
                    <div
                      className="mobile-orders-container-orderBox"
                      style={{
                        filter:
                          item.WorkStationId == id
                            ? "brightness(.70)"
                            : "brightness(100%)",
                      }}
                    >
                      <div className="mobile-orders-container-first-row">
                        <div className="mobile-orders-container-first-row-labels">
                          <p>{lang.ws_name}</p>
                          <p>{lang.last_sync}</p>
                          <p>{lang.version}</p>
                        </div>
                        <div className="mobile-orders-container-first-row-details">
                          <p> {item.WorkStationName}</p>

                          <p>
                            {item.LastSyncDate
                              ? moment(item.LastSyncDate).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                              : null}
                          </p>
                          <p> {item.AppVersion}</p>
                        </div>
                      </div>

                      <div className="mobile-orders-container-first-row">
                        <div className="mobile-orders-container-first-row-labels">
                          <p>{lang.version_status}</p>
                          <p>{lang.last_connection}</p>
                          <p>{lang.status}</p>
                        </div>
                        <div className="mobile-orders-container-first-row-details">
                          <p
                            style={{
                              color: device_type
                                ? device_type.length > 0
                                  ? item.DeviceType == device_type[0].value
                                    ? item.T2_LastVersion == item.AppVersion
                                      ? "green"
                                      : "red"
                                    : item.DeviceType == device_type[1].value
                                    ? item.V2_LastVersion == item.AppVersion
                                      ? "green"
                                      : "red"
                                    : null
                                  : null
                                : null,
                            }}
                          >
                            {" "}
                            {device_type
                              ? device_type.length > 0
                                ? item.DeviceType == device_type[0].value
                                  ? item.T2_LastVersion == item.AppVersion
                                    ? "Up to date"
                                    : "Out date"
                                  : item.DeviceType == device_type[1].value
                                  ? item.V2_LastVersion == item.AppVersion
                                    ? "Up to date"
                                    : "Out date"
                                  : null
                                : null
                              : null}
                          </p>
                          <p>
                            {" "}
                            {item.LastConnection
                              ? moment(item.LastConnection).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                              : null}
                          </p>
                          <p
                            style={{
                              color: item.LastConnection
                                ? moment(item.LastConnection)
                                    .add(2, "minutes")
                                    .format("YYYY-MM-DD HH:mm:ss") >
                                  moment(item.DateNow).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                  ? "green"
                                  : "red"
                                : null,
                              // color: "white",
                            }}
                          >
                            {item.LastConnection
                              ? moment(item.LastConnection)
                                  .add(2, "minutes")
                                  .format("YYYY-MM-DD HH:mm:ss") >
                                moment(item.DateNow).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                                ? "Connected"
                                : "Disconnected"
                              : null}
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                ))
              ) : (
                <div className="mobile-container-noOrder-text">
                  <h4>{lang.no_workstation}</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </>{" "}
      {loading_worksStations ? <Loader /> : null}
      <Toaster />
    </div>
  ) : (
    <div
      className="countaining-div"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
      }}
    >
      <div className="worksation_status_container_header">
        <h6>Ws name</h6>
        <h6>Last sync</h6>
        <h6>Version</h6>
        <h6>Version status</h6>
        <h6>Last connection</h6>
        <h6>Status</h6>
        <h6
          style={{
            width: "110px",
          }}
        ></h6>
      </div>
      <div
        className="menu_list_header_container"
        style={{ paddingBottom: "100px" }}
      >
        {worksStationStatusList
          ? worksStationStatusList.length > 0
            ? worksStationStatusList.map((item, index) => (
                <div className="worksation_status_container_list">
                  <div className="worksation_status_container_items">
                    <h6 className="worksation_status_item_parameters">
                      {item.WorkStationName}
                    </h6>
                    <h6 className="worksation_status_item_parameters">
                      {item.LastSyncDate
                        ? moment(item.LastSyncDate).format("YYYY-MM-DD HH:mm")
                        : null}
                    </h6>
                    <h6 className="worksation_status_item_parameters">
                      {item.AppVersion}
                    </h6>
                    <div className="worksation_status_item_parameters worksation_status_item_container_version_status">
                      <h6
                        style={{
                          color: device_type
                            ? device_type.length > 0
                              ? item.DeviceType == device_type[0].value
                                ? item.T2_LastVersion == item.AppVersion
                                  ? "green"
                                  : "red"
                                : item.DeviceType == device_type[1].value
                                ? item.V2_LastVersion == item.AppVersion
                                  ? "green"
                                  : "red"
                                : null
                              : null
                            : null,
                        }}
                      >
                        {device_type
                          ? device_type.length > 0
                            ? item.DeviceType == device_type[0].value
                              ? item.T2_LastVersion == item.AppVersion
                                ? "Up to date"
                                : "Out date"
                              : item.DeviceType == device_type[1].value
                              ? item.V2_LastVersion == item.AppVersion
                                ? "Up to date"
                                : "Out date"
                              : null
                            : null
                          : null}
                        {/* {item.V2_LastVersion == item.AppVersion
                          ? "Up to date"
                          : "Out date"} */}
                      </h6>
                    </div>
                    <h6 className="worksation_status_item_parameters">
                      {" "}
                      {item.LastConnection
                        ? moment(item.LastConnection).format("YYYY-MM-DD HH:mm")
                        : null}
                    </h6>
                    <div className="worksation_status_item_parameters worksation_status_item_container_status">
                      <h6
                        style={{
                          color: item.LastConnection
                            ? moment(item.LastConnection)
                                .add(2, "minutes")
                                .format("YYYY-MM-DD HH:mm:ss") >
                              moment(item.DateNow).format("YYYY-MM-DD HH:mm:ss")
                              ? "green"
                              : "red"
                            : null,
                          // color: "white",
                        }}
                      >
                        {item.LastConnection
                          ? moment(item.LastConnection)
                              .add(2, "minutes")
                              .format("YYYY-MM-DD HH:mm:ss") >
                            moment(item.DateNow).format("YYYY-MM-DD HH:mm:ss")
                            ? "Connected"
                            : "Disconnected"
                          : null}
                      </h6>
                    </div>
                  </div>
                  <div
                    className="worksation_status_container_result_button"
                    onClick={() => handleUpdateWorksStationStatus(item)}
                  >
                    <button>
                      {item.WorkStationId == id ? (
                        loading_update_worksStations_status ? (
                          <LoaderMini />
                        ) : (
                          "Sync"
                        )
                      ) : (
                        "Sync"
                      )}
                    </button>
                  </div>
                </div>
              ))
            : null
          : null}
      </div>
      {loading_worksStations ? <Loader /> : null}
      <Toaster />
    </div>
  );
};

export default WorksStationStatus;
