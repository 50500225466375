import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuListItem from "../components/MenuListItem";
import { marginNormalMenuHorizontal } from "../constants/Sizes";
import useScrollbarSize from "react-scrollbar-size";
import ListHeader from "../components/ListHeader";
import {
  getDriversListAction,
  selectedDriverAction,
  deleteDriverAction,
} from "../redux/Employees/Actions";
import { changeSelectedButtonBorderColorOnFocus } from "../redux/menu/Actions";
const DriversList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { lang } = useSelector((state) => state.selectedLanguage);

  const { drivers_list, selected_driver } = useSelector(
    (state) => state.employeesRedusers
  );
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const [driverList, setDriverList] = useState([]);
  const handleCreateDriver = (key) => {
    setClickedItem(2);
    dispatch(selectedDriverAction(null));
  };
  const handleEditDriver = (key, item) => {
    setClickedItem(key);
    dispatch(selectedDriverAction(item));
  };
  const handleDeleteDriver = (item) => {
    dispatch(deleteDriverAction(login_key, user_id, item.DriverId));
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...driverList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.DriverId == copy_list[i].DriverId) {
        copy_list[i].showDeleteBox = true;
      } else {
        copy_list[i].showDeleteBox = false;
      }
    }
    setDriverList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...driverList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.DriverId == copy_list[i].DriverId) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setDriverList(copy_list);
  };
  useEffect(() => {
    dispatch(getDriversListAction(login_key, user_id));
  }, []);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);

  useEffect(() => {
    if (drivers_list.length > 0) {
      setDriverList(drivers_list);
    }
  }, [drivers_list]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
      id="countaining"
    >
      <ListHeader
        title={lang.drivers_list}
        show_back={false}
        item_uniqe_id={"Driver id"}
      />
      <div
        className="menu_list_header_container"
        style={{ paddingBottom: "50px" }}
      >
        {driverList.length > 0 ? (
          <div>
            {driverList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={2}
                  field_1_title={`${item.FirstName} ${item.LastName}`}
                  field_2_title={item.UniqueId}
                  item_title={`${item.FirstName} ${item.LastName}`}
                  item_uniqe_id={item.UniqueId}
                  btn_number={1}
                  show_status={false}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleEditDriver(2, item)}
                  show_delete={true}
                  delete_click={() => handleDeleteDriver(item)}
                  show_edit_icon={false}
                  selected={selected_driver}
                  item={item}
                  number={0}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span style={{ width: "172px" }} onClick={handleCreateDriver}>
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_driver}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {driverList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={handleCreateDriver}
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_driver}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DriversList;
