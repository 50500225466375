import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import InputMask from "react-input-mask";

import ListHeader from "./ListHeader";
import SaveMenuButton from "../components/SaveMenuButton";
import { primaryColor, main_nav_back_color } from "../constants/Colors";
import { setDefaultAction } from "../redux/menu/Actions";
import { main_nav_button_color } from "../constants/Colors";
import {
  createWorksStationsAction,
  sendWorksStationsAction,
  getWorksStationsAction,
  selecteWorksStationsAction,
} from "../redux/WorksStations/Actions";

const WorksStationsEdit = ({ setClickedItem, changingPage }) => {
  const didMountRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { result_login_successfull } = useSelector(
    (state) => state.loginReducer
  );
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);

  const {
    device_type,
    device_type_id,
    licence_options_id,
    licence_options,
    callerId_values,
    callerId_ids,
  } = useSelector((state) => state.categoryList);

  const { lang } = useSelector((state) => state.selectedLanguage);
  const { selected_worksStations_list, worksStation_list, result_successfull } =
    useSelector((state) => state.worksStationsReduser);
  const [width1, setWidth1] = React.useState(window.innerWidth);
  const [workStationName, setWorkStationName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [deviceType, setDeviceType] = useState(null);
  const [expireDate, setExpireDate] = useState("");
  const [active, setActive] = useState(false);
  const [isExpired, setIsExpired] = useState(true);
  const [activeSecondScreen, setActiveSecondScreen] = useState(true);
  const [getOrderOnline, setGetOrderOnline] = useState(true);
  const [shopFrontWorkStation, setShopFrontWorkStation] = useState(true);
  const [isKitchen, setIsKitchen] = useState(true);
  const [passwordProtectedTillOpening, setPasswordProtectedTillOpening] =
    useState(false);
  const [printCustomerTicket, setPrintCustomerTicket] = useState(true);
  const [callerId, setCallerId] = useState(null);
  const [hasCashDrawer, setHasCashDrawer] = useState(true);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 40,
      minHeight: 40,
      boxShadow: "none",
      border: state.isFocused
        ? `1px solid ${primaryColor}`
        : `1px solid ${main_nav_button_color}`,
      borderRadius: "2px 2px 2px 2px",
      "&:hover": {
        border: `1px solid ${primaryColor}`,
      },
    }),

    menuList: (base, state) => ({
      ...base,
      // backgroundColor: "yellow",
      // height: 40,
      maxHeight: 220,
      // backgroundColor: state.isSelected ? primaryColor : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dashed #ccc",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? primaryColor : "white",
      // padding: 2,
      // fontSize: "20px",
      "&:hover": {
        backgroundColor: state.isSelected ? primaryColor : main_nav_back_color,
      },
    }),
  };

  const handleSave = () => {
    if (workStationName == "" || deviceId == "" || !deviceType || !callerId) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.all_fields_required}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else if (deviceId.split("").length != 16) {
      toast.error(
        <h5> &nbsp;&nbsp;{lang.device_id_length_wrong}&nbsp;&nbsp; </h5>,
        {
          position: "top-center",
          duration: 3000,
          style: { backgroundColor: "red", color: "white" },
          icon: (
            <i
              style={{ fontSize: 40 }}
              className="fa fa-times-circle"
              aria-hidden="true"
            ></i>
          ),
        }
      );
    } else {
      dispatch(
        sendWorksStationsAction(
          login_key,
          user_id,
          selected_worksStations_list.WorkStationId,
          selected_worksStations_list.ShopId,
          workStationName.replaceAll(`"`, `\"\"`),
          deviceId.replaceAll(`"`, `\"\"`),
          deviceType.value,
          active ? 1 : 0,
          // 0,
          isExpired ? 1 : 0,
          activeSecondScreen ? 1 : 0,
          getOrderOnline ? 1 : 0,
          shopFrontWorkStation ? 1 : 0,
          isKitchen ? 1 : 0,
          passwordProtectedTillOpening ? 1 : 0,
          printCustomerTicket ? 1 : 0,
          callerId ? callerId.value : 0,
          hasCashDrawer ? 1 : 0
        )
      );
    }
  };

  const handleClickedItem = (key) => {
    setClickedItem(key);
  };

  useEffect(() => {
    if (!result_login_successfull) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (selected_worksStations_list) {
      setWorkStationName(selected_worksStations_list.WorkStationName);
      setDeviceId(selected_worksStations_list.DeviceId);
      setDeviceType(selected_worksStations_list.DeviceType);
      setActive(selected_worksStations_list.Active);
      setIsExpired(selected_worksStations_list.IsExpired);
      setActiveSecondScreen(selected_worksStations_list.ActiveSecondScreen);
      setGetOrderOnline(selected_worksStations_list.GetOrderOnline);
      setShopFrontWorkStation(selected_worksStations_list.ShopFrontWorkStation);
      setIsKitchen(selected_worksStations_list.IsKitchen);
      setPasswordProtectedTillOpening(
        selected_worksStations_list.PasswordProtectedTillOpening
      );
      setPrintCustomerTicket(selected_worksStations_list.PrintCustomerTicket);

      setCallerId(selected_worksStations_list.CallerId);

      setHasCashDrawer(selected_worksStations_list.HasCashDrawer);
    }
  }, [selected_worksStations_list]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        handleSave();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [
    workStationName,
    deviceId,
    deviceType,
    active,
    isExpired,
    activeSecondScreen,
    getOrderOnline,
    shopFrontWorkStation,
    isKitchen,
    passwordProtectedTillOpening,
    printCustomerTicket,
  ]);
  useEffect(() => {
    if (selected_shop && didMountRef.current) {
      setClickedItem(1);
    }
    didMountRef.current = true;
  }, [selected_shop]);
  useEffect(() => {
    if (result_successfull) {
      setClickedItem(1);
    }
  }, [result_successfull]);
  useEffect(() => {
    const handleResizeWindow = () => setWidth1(window.innerWidth);

    window.addEventListener("resize", handleResizeWindow);

    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return width1 <= 560 ? (
    <div className="countaining-div">
      <div>
        <ListHeader
          title={lang.create_works_station}
          show_back={true}
          clickedItem={() => handleClickedItem(1)}
        />
      </div>
      <div
        className="menu_list_header_container"
        style={{
          flex: 1,
          paddingBottom: "30px",
        }}
      >
        <div className="margin_menu_normal_horizontal">
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">* {lang.work_station_name}</h6>
            <input
              className="sm-input input-padding"
              value={workStationName}
              onChange={(e) => setWorkStationName(e.target.value)}
            />
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">* {lang.device_id}</h6>

            <InputMask
              maskChar={null}
              mask="9999 9999 9999 9999"
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value.replace(/\D/g, ""))}
              className="sm-input input-padding"
            ></InputMask>
          </div>
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">* {lang.device_type}</h6>
            <Select
              options={device_type}
              defaultValue={deviceType}
              isSearchable={false}
              onChange={(i, d) => setDeviceType(i)}
              value={deviceType}
              isMulti={false}
              styles={customStyles}
            />
          </div>{" "}
          <div className="ASOP-div1-input-text" style={{ width: "100%" }}>
            <h6 className="h6">* {lang.caller_id}</h6>
            <Select
              options={callerId_values}
              defaultValue={callerId}
              isSearchable={false}
              onChange={(i, d) => setCallerId(i)}
              value={callerId}
              isMulti={false}
              styles={customStyles}
            />
          </div>
          <div
            className="ASOP-div1-input-text mobile-workstation-container-checkbox"
            style={{ width: "100%" }}
          >
            <div className=" div-chechbox-p ">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={active}
                      onChange={() => setActive(!active)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.active}
                />
              </FormGroup>
            </div>{" "}
          </div>
          <div
            className="ASOP-div1-input-text mobile-workstation-container-checkbox"
            style={{ width: "100%" }}
          >
            <div className=" div-chechbox-p">
              <FormGroup style={{ width: "100%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isExpired}
                      onChange={() => setIsExpired(!isExpired)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.is_expired}
                />
              </FormGroup>
            </div>{" "}
            <div className=" div-chechbox-p">
              <FormGroup style={{ width: "100%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isKitchen}
                      onChange={() => setIsKitchen(!isKitchen)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.is_kitchen}
                />
              </FormGroup>
            </div>
            {/* <div className=" div-chechbox-p">
              <FormGroup style={{ width: "100%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={callerId}
                      onChange={() => setCallerId(!callerId)}
                      sx={{
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={lang.caller_id}
                />
              </FormGroup>
            </div> */}
          </div>
          <div style={{ width: "100%" }}>
            <FormGroup style={{ width: "100%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activeSecondScreen}
                    onChange={() => setActiveSecondScreen(!activeSecondScreen)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.active_second_screen}
              />
            </FormGroup>
          </div>
          <div style={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={passwordProtectedTillOpening}
                    onChange={() =>
                      setPasswordProtectedTillOpening(
                        !passwordProtectedTillOpening
                      )
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.password_protected_till_opening}
              />
            </FormGroup>
          </div>
          <div style={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getOrderOnline}
                    onChange={() => setGetOrderOnline(!getOrderOnline)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.get_Oorder_online}
              />
            </FormGroup>
          </div>
          <div style={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shopFrontWorkStation}
                    onChange={() =>
                      setShopFrontWorkStation(!shopFrontWorkStation)
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.shop_front_work_station}
              />
            </FormGroup>
          </div>
          <div style={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={printCustomerTicket}
                    onChange={() =>
                      setPrintCustomerTicket(!printCustomerTicket)
                    }
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.print_customer_ticket}
              />
            </FormGroup>
          </div>
          <div style={{ width: "100%" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasCashDrawer}
                    onChange={() => setHasCashDrawer(!hasCashDrawer)}
                    sx={{
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={lang.has_cash_drawer}
              />
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="mobile-container-save-button" style={{ height: "0px" }}>
        <button type="" onClick={() => handleSave()}>
          {lang.save}
        </button>
      </div>
    </div>
  ) : (
    <div
      className="countaining-div"
      id="countaining"
      style={{
        borderLeftWidth: 0.3,
        borderLeftColor: main_nav_button_color,
        borderLeftStyle: "solid",
        overflowY: "auto",
      }}
    >
      <div style={{ marginTop: "10px" }}>
        <ListHeader
          title={lang.works_station_edit}
          show_back={true}
          clickedItem={() => handleClickedItem(1)}
        />
      </div>
      <div className="workstation-container-main-section">
        <div className="menu_list_header_container">
          <div
            className="margin_menu_normal_horizontal"
            style={{ paddingBottom: "100px" }}
          >
            <div className="sm-div2">
              <div className="sm-m">
                <h6 className="h6">* {lang.work_station_name}</h6>
                <input
                  className="sm-input input-padding"
                  value={workStationName}
                  onChange={(e) => setWorkStationName(e.target.value)}
                />
              </div>
              <div className="sm-m">
                <h6 className="h6">* {lang.device_id}</h6>

                <InputMask
                  maskChar={null}
                  mask="9999 9999 9999 9999"
                  value={deviceId}
                  onChange={(e) =>
                    setDeviceId(e.target.value.replace(/\D/g, ""))
                  }
                  className="sm-input input-padding"
                ></InputMask>
              </div>
              <div className="sm-m">
                <h6 className="h6">* {lang.device_type}</h6>
                <Select
                  options={device_type}
                  defaultValue={deviceType}
                  isSearchable={false}
                  onChange={(i, d) => setDeviceType(i)}
                  value={deviceType}
                  isMulti={false}
                  styles={customStyles}
                  isDisabled
                />
              </div>
            </div>

            <div className="sm-div2">
              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={active}
                        onChange={() => setActive(!active)}
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.active}
                  />
                </FormGroup>
              </div>
              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isKitchen}
                        onChange={() => setIsKitchen(!isKitchen)}
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.is_kitchen}
                  />
                </FormGroup>
              </div>{" "}
              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getOrderOnline}
                        onChange={() => setGetOrderOnline(!getOrderOnline)}
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.get_Oorder_online}
                  />
                </FormGroup>
              </div>
              {/* <div className="submenuItemEdit-div1 div-chechbox-p"></div> */}
            </div>
            <div className="sm-div2">
              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeSecondScreen}
                        onChange={() =>
                          setActiveSecondScreen(!activeSecondScreen)
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.active_second_screen}
                  />
                </FormGroup>
              </div>

              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isExpired}
                        onChange={() => setIsExpired(!isExpired)}
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.is_expired}
                  />
                </FormGroup>
              </div>
              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={shopFrontWorkStation}
                        onChange={() =>
                          setShopFrontWorkStation(!shopFrontWorkStation)
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.shop_front_work_station}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="sm-div2">
              {" "}
              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={passwordProtectedTillOpening}
                        onChange={() =>
                          setPasswordProtectedTillOpening(
                            !passwordProtectedTillOpening
                          )
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.password_protected_till_opening}
                  />
                </FormGroup>
              </div>
              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={printCustomerTicket}
                        onChange={() =>
                          setPrintCustomerTicket(!printCustomerTicket)
                        }
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.print_customer_ticket}
                  />
                </FormGroup>
              </div>
              <div className="sm-m">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasCashDrawer}
                        onChange={() => setHasCashDrawer(!hasCashDrawer)}
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.has_cash_drawer}
                  />
                </FormGroup>
              </div>
            </div>
            {/* <div className="WSE-container-div2">
              <div className="submenuItemEdit-div2 div-chechbox-p">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={callerId}
                        onChange={() => setCallerId(!callerId)}
                        sx={{
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                      />
                    }
                    label={lang.caller_id}
                  />
                </FormGroup>
              </div>
            </div> */}
            <div className="sm-div2">
              <div className="sm-m" style={{ marginTop: "10px" }}>
                <h6 className="h6">* {lang.caller_id}</h6>
                <Select
                  options={callerId_values}
                  defaultValue={callerId}
                  isSearchable={false}
                  onChange={(i, d) => setCallerId(i)}
                  value={callerId}
                  isMulti={false}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
        <SaveMenuButton clickButton={() => handleSave()} />
        <Toaster /> {/* <div></div> */}
      </div>
    </div>
  );
};
export default WorksStationsEdit;
