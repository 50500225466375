export const english = {
  active_on_web: "Active on the web",
  active_in_app: "Active in the app",
  active_in_kiosk: "Active in the kiosk",
  asck_before_sending_payment_link: "Ask before sending link",
  all_shops: "All shops",
  selected_shops: "Selected shops",
  unselected_shops: "Unselected shops",
  amount_format_incorrect: "The amount format is incorrect.",
  amount_status_pence: "The Status(pence) format is incorrect.",
  android_url: "Android url",
  main_nav_title: "Everything Takeaway",
  maintenance_mode: "Maintenance mode",
  maintenance_type: "Maintenance type",
  main_buttons: "Main buttons",
  settings: "Settings",
  stripe: "Stripe",
  products: "Products",
  product_edit: "Edit product",
  products_list: "Products list",
  product_options_list: "Product options list",
  product_options_edit: "Edit product option",
  payments: "Payments",
  category_options_list: "Category options list",
  category_options_edit: "Edit category option",
  placeholderTitle: "title",
  placeholderDesc: "desc",
  placeholderSort: "sort",
  placeholder_categoeyName: "Category Name",
  pree_order_message: "Pre order message",
  save: "Save",
  adyen: "Adyen",
  add_button_title: "Add",
  NoOptions_title: "No category",
  first_option: "All",
  secound_option: "None",
  third_option: "Collection",
  time_gap: "Time gap",
  total: "Total",
  forth_option: "Takeaway",
  fifth_option: "Delivery",
  sixth_option: "Eat in",
  app_title: "App title",
  web_title: "Web title",
  kiosk_title: "Kiosk title",
  list: "List",
  show_hide: "Show/hide",
  sort: "Sort",
  sort_app: "Sort App",
  sort_web: "Sort Web",
  sort_kiosk: "Sort Kiosk",
  sure_charge: "Surcharge",
  image: "image",
  showImage: "Show image",
  general: "General",
  vat: "Vat",
  printerSection: "Printer section",
  productGroup: "Product Group",
  printer: "Printer",
  secoundLanguage: "Secound Language",
  suggested: "Suggested",
  popular: "Popular",
  backgroundColor: "backgroundColor",
  fontColor: "Font Color",
  fontSize: "font size",
  no_order: "No Order",
  connection: "Connection",
  date_now: "Date now",
  fontFamily: "Font Family",
  width: "Width",
  height: "Height",
  mainprice: "Main price",
  collectionPrice: "Collection price",
  takeawayPrice: "Takeaway price",
  eatinPrice: "Eat in price",
  customTitle: "Custom title",
  customTitle_inLast: "Custom title in last",
  customers: "Customers",
  productTitle_inLast: "Product title in last",
  printLabel: "Print label",
  product_font_size: "Products font size",
  closeWhen: "Close when",
  closed_order_message: "Closed order message",
  linkTo: "Link To",
  automaticallyDisplay_subMenu: "Automatically display sub menu",
  noOption: "No item",
  no_tabel: "No tabel",
  edit: "Edit",
  options: "Options",
  subMenu: "Submenu",
  submenu_list: "Submenu list",
  item_list: "Item list",
  item_edit: "Edit item",
  submenu_item_list: "Submenu item list",
  submenu_item_edit: "Edit submenu item",
  submenus_font_size: "Submenus font size",
  addProduct: "Add Product",
  addSubmenu: "Add Submenu",
  addOption: "Add Option",
  addItem: "Add item",
  fill_app_title: "Please fill app title",
  fill_show_hide: "Please fill all  show hide sections",
  category_successfully_updated: "Successfully updated",
  category_update_failed: "Update failed",
  updating_category: "Updating...",
  visible_on_app: "Visible on app",
  visible_on_web: "Visible on web",
  visible_on_kiosk: "Visible on kiosk",
  multi_select_price: "Multi select price",
  main: "Main",
  name: "Name",
  delivery: "Delivery",
  delete_message: "Are you sure you want to delete ?",
  debt_message: "Are you sure you want to debit ",
  delivery_segmentation: "Delivery segmentation",
  takeaway: "Takeaway",
  collection: "Collection",
  collection_segmentation: "Collection segmentation",
  eat_in: "Eat In",
  price_level: "Price level",
  minSelect: "Min select",
  maxSelect: "Max select",
  minute: "min",
  hour: "h",
  joinBefore: "Join before",
  inFirst: "In first",
  items_font_size: "Items font size",
  isOverridePrice: "Is override price",
  incloudeExtra: "Include extra",
  incloudeDressing: "Include dressing",
  ios_url: "IOS url",
  visible: "Visible",
  shortName: "Short name",
  item: "item",
  add_submenu: "Add submenu",
  cancel: "Cancel",
  add: "Add",
  select: "Select",
  product_submenu_list: "Product submenu list",
  create_new_shop: "Create new shop",
  create: "Create",
  crowded_time: "Crowded time",
  shop_name: "Shop name",
  shop: "Shop",
  city: "City",
  cancel_reasone: "Cancel reasons",
  mobile_phone: "Mobile phone",
  address: "Address",
  postcode: "Post code",
  dashboard: "Dashboard",
  users: "Users",
  shops_status: "Shops status",
  devices: "Devices",
  logs: "Logs",
  orders_status: "Orders status",
  order_proccess: "Order proccess",
  ordinary_time: "Ordinary time",
  ok: "Ok",
  incloud_half_and_half: "Include Half And Half",
  edit_category: "Edit category",
  app_visible: "AppVisible",
  web_visible: "WebVisible",
  kiosk_visible: "KioskVisible",
  is_dif_option_vat: "IsDifOptionVat",
  total_half_text: "Total half text",
  prefix_no_forse_submenu: "Prefix no forse submenu",
  prefix_yes_forse_submenu: "Prefix yes forse submenu",
  ask_customer_on_takaway: "Ask customer on takaway",
  ask_customer_on_collection: "Ask customer on collection",
  users_need_to_login_before_taking_order:
    "Users need to login before taking order",
  login: "Login",
  use_table_area: "Use table area",
  show_tipln_eatIn: "Show tip in eat in",
  ask_number_guests: " Ask number guests",
  show_tipln_takeaway: "Show tip in takeaway",
  report_section: "Report Section",
  amount_main: "Amount Main",
  amount_collection: "Amount Collection",
  amount_takeaway: "Amount Takeaway",
  amount_eatIn: "Amount EatIn",
  barcode: "Barcode",
  close_count: "Close Count",
  collection_default_peyment_method: "Collection Default Peyment Method",
  delivery_default_payment_method: "Delivery Default Peyment Method",
  eatIn_default_payment_method: "Eat In Default Payment Method",
  show_not_paid_in_collection: "Show Not Paid In Collection",
  show_not_paid_in_Delivery: "Show Not Paid In Delivery",
  show_not_paid_in_eatIn: "Show Not Paid In EatIn",
  show_not_paid_in_takeaway: "Show Not Paid In Takeaway",
  takeaway_default_payment_mathod: "Takeaway Default Payment Method",
  use_peyment_method: "Use Peyment Method",
  edit_product_option: "Edit product option",
  edit_product_submenu: "Edit product submenu",
  expire_crowded_time_after: "Expire crowded time after",
  assing_driver_flashing_colour: "Assing Deliver Flashing Colour",
  display_order_lasting_moretthan_minutes:
    "Display Order Lasting More Than Minutes",
  order_queue_list_colour: "Order Queue List Colour",
  printed_orders_colour: "Printed Orders Colour",
  delivery_description: "Delivery Description",
  collection_description: "Collection Description",
  takeaway_description: "Takeaway Description",
  eatIn_description: "Eat In Description",
  web_del_description: "Web Del Description",
  web_coll_description: "Web Coll Description",
  kiosk_takeaway_description: "Kiosk Takeaway Description",
  kiosk_eatIn_description: "Kiosk Eat In Description",
  web_eatIn_description: "Web Eat In Description",
  shop_opening_time: "Shop Opening Time",
  shop_closing_time: "Shop Closing Time",
  display_previous_order: "Display Previous Order",
  display: "Display",
  show_basket_in_second_screen: "Show Basket In Second Screen",
  show_barcode: "Show Barcode",
  show_not_paid_finishing_order_in_collection:
    "Show Not Paid Finishing Order In Collection",
  show_not_paid_finishing_order_in_eatIn:
    "Show Not Paid Finishing Order In EatIn",
  cancel_order: "Cancel Order",
  data_export_to_excel: "Data Export To Excel",
  discount: "Discount",
  driver_analysis: "Driver Analisis",
  driver_float: "Driver Float",
  driver_payment: "Driver Payment",
  payment: "Payment",
  edit_order: "Edit Order",
  list_all_order: "List All Order",
  label: "Label",
  maintenance: "Maintenance",
  maintenance_reasones: "Maintenance reasones",
  order_line_add_change: "Order Line Add Change",
  system_setup: "System Setup",
  takings: "Takings",
  timeSheet: "Time Sheet",
  crust: "Crust",
  category_option_crustList: "Category option crust list",
  category_option_crust_edit: " Edit category option crust",
  title: "Title",
  desc: "Desc",
  description: "Description",
  imployers_edit: "Employers Edit",
  gender_id: "Gender Id",
  all_fields_required: "all fields with star are required",
  mobile_incorrect: "Type or length of mobile incorrect",
  imployees_edit: "Edit employee ",
  gender_id: "Gender",
  employee_name: "Employee Name",
  job: "Job",
  national: "National",
  mobile: "Mobile",
  mobile1: "* Mobile",
  telephone: "Telephone",
  email: "Email",
  post_code: "Post Code",
  user_name: "User Name",
  user_name1: "* User Name",
  pass: "Password",
  pass1: "* Password",
  password_protect: "Password protect",
  payment_maethod: "Payment methods",
  dob: "DOB",
  cash: "Cash",
  card: "Card",
  calander: "Calander",
  today: "Today",
  week: "This Week",
  month: "This Month",
  year: "This Year",
  married: "Married",
  in_suranced: "Insuranced",
  date_in: "Date In",
  date_out: "Date Out",
  active: "Active",
  group: "Group",
  is_admin: "Is Admin",
  single_user: "Single User",
  btn_color: "Btn color",
  color: "Color",
  btn_font_color: "Btn Font Color",
  url_image: "Url Image",
  employees_list: "Employees list",
  create_employee: "Create employee",
  work_station_name: "Work Station Name",
  device_id: "Device Id",
  expire_date: "Expire Date",
  device_type: "Device Type",
  is_expired: "Is Expired",
  active_second_screen: "Active Second Screen",
  get_Oorder_online: "Get Order Online",
  shop_front_work_station: "Shop Front Work Station",
  is_kitchen: "Is Kitchen",
  password_protected_till_opening: "Password Protected Till Opening",
  print_customer_ticket: "Print Customer Ticket",
  pending_orders: "Pending orders",
  promotions: "Promotions",
  orderId_status: "OrderId,Status",
  orderId: "OrderId",
  channels: "Channels",
  channel: "Channel",
  requested_for: "Requested for",
  store_name: "Store name",
  order_amount: "Order amount",
  payment_status: "Payment status",
  customer: "Customer",
  create_workstation: "Create workstation",
  amount_delivery: "Amount delivery",
  price: "Price",
  orders: "Orders",
  order: "Order",
  works_station: "Works Station",
  works_station_list: "Works station list",
  works_station_edit: "Edit work station",
  create_works_station: "Create work station",
  submenu_edit: "Edit submenu ",
  count_item_for_use_amount: "Count Item For Use Amount",
  amount: "Amount",
  printer_id: "Printer Id",
  is_after: "Is After",
  is_first: "Is First",
  is_free: "Is Free",
  swap_title_position: "Swap Title Position",
  create_employee: "Create employee",
  category_list: "Category list",
  first_name: "First Name",
  first_name1: "* First Name",
  last_name: "Last Name",
  last_name1: "* Last Name",
  shop_group: "Shop group",
  access: "Access",
  owners_list: "Owners list",
  edit_owner: "Edit owner",
  create_owner: "Create owner",
  edit_ownwer: "Edit owner",
  add_crust: "Add Crust",
  status: "Status",
  sale_method: "Sale method",
  active: "Active",
  de_active: "Deactive",
  re_new: "renew",
  vew: "view",
  days_left: "Days left",
  ws_name: "Workstation ",
  full_name: "Full name",
  name_first: "Name first",
  tabel_area: "Tabel area",
  area_list: "Area list",
  tabels_list: "Tabels list",
  devuces: "Devices",
  licence: "Licence",
  area_name: "Area name",
  add_tabels: "Add tabel",
  add_area: "Add area",
  tebel_edit: "Edit tabel",
  create_tabel: "Create tabel",
  tabel_name: "Tabel name",
  position: "Position",
  edit_area: "Edit area",
  create_area: "Create area",
  price_level_main: "Price level main",
  price_level_takeaway: "Price level takeaway",
  price_level_eat_in: "Price level eat in",
  collections: "collection",
  edit: "Edit",
  expired: "Expired",
  start_date: "Start date",
  shop_id_shop_name: "Shop name/Id",
  reserve: "Reserve",
  licence_course: "Licence course",
  licence_list: "Licence list",
  device_id_length_wrong: "Device id length incorrect",
  name_first: "Name first",
  tabel_area: "Tabel area",
  area_list: "Area list",
  tabels_list: "Tabels list",
  devuces: "Devices",
  licence: "Licence",
  area_name: "Area name",
  add_tabels: "Add tabel",
  add_area: "Add area",
  tebel_edit: "Edit tabel",
  create_tabel: "Create tabel",
  tabel_name: "Tabel name",
  position: "Position",
  edit_area: "Edit area",
  create_area: "Create area",
  price_level_main: "Price level main",
  price_level_takeaway: "Price level takeaway",
  price_level_eat_in: "Price level eat in",
  collections: "collection",
  edit: "Edit",
  expired: "Expired",
  start_date: "Start date",
  reserve: "Reserve",
  licence_course: "Licence course",
  licence_list: "Licence list",
  device_id_length_wrong: "Device id length incorrect",
  licence_option: "Licence option",
  area: "Area",
  deviry_charge: "Delivery charge",
  delivey_min_charge: "Delivery min charge",
  add_deliver_area: "Add delivery area",
  post_code: "Post code",
  create_post_code: "Create post code",
  the_delivery_charge_format_is_incorrect:
    "The delivery charge format is incorrect",
  the_delivery_min_charge_format_is_incorrect:
    "The delivery min charge format is incorrect",
  vat_name: "Vat name",
  vat_value: "Vat value",
  is_percent: "Is percent",
  the_vat_value_format_is_incorrect: "The vat value format is incorrect",
  first_half: "First half %",
  second_half: "Second half %",
  sit_in: "Sit in Area",
  equal: "Equal",
  max_price: "Max price",
  by_percenet: "By percent",
  select_one_of_the_checkboxes: "Select one of the checkboxes",
  the_fisrt_half_format_is_incorrect: "The first half format is incorrect",
  the_percentage_cannot_be_more_than_one_hundred:
    "The percentage cannot be more than one hundred",
  create_vat: "Create vat",
  edit_drivers: "Edit driver",
  drivers_list: "Drivers list",
  create_driver: "Create driver",
  the_mobile_format_is_incorrect: "The mobile format is incorrect",
  the_phone_number_format_is_incorrect: "The phone numbers format is incorrect",
  the_fax_number_format_is_incorrect: "The fax number format is incorrect",
  mobile_length_incorrect:
    "The length of mobile should not be less than eleven.",
  there_is_no_such_a_dirver: "There is no such a driver.",
  unique_id_wrong: "Uinque id format is incorrect",
  driver_id: "Driver id",
  enter_driver_id_to_add_driver: "Enter driver id to add driver.",
  enable_asap: "Enable ASAP",
  enable: "Enable",
  button_name: "Button Name",
  expire_time: "Expire time",
  search: "Search",
  deliver_is_with_in: "Delivery is with in",
  min_order_value: "Minimum order value",
  min_order_charge_message: "Min order charge message",
  minimum_order_amount_to_free_delivery_charge:
    "Min order amount to free delivery charge",
  driver_change_round_uptothe_nearest: "Driver change round up to the nearest",
  default_delivery_charge_type_id: "Default delivery charge type id",
  spicific_amount: "Spicific amount",
  minimum_accepted_mil: "Minimum accepted mil",
  Minimum_accepted_mil_message: "Minimum accepted mil message",
  Print_free_delivery_message: "Print free delivery message",
  pree_order: "Pre order",
  spicific_amount_format: "Spicific amount format is incorrect",
  min_order_value_format: "Min order value is incorrect",
  sort_format_is_incorrect: "Sort id format is incorrect",
  create_dicount_reasones: "Create discount reason",
  create_food_group_section: "Create food group section",
  custom_charge: "Create custom charge",
  discount_percentage: "Discount percentage",
  discount_money_value: "Discount money value ",
  create_discount_percentage: "Create discount percentage",
  create_discount_money_value: "Create discount money value",
  dicount_reasones: "Discount reason",
  food_group_section: "Food group section",
  custom_charge_title: "Custom charge",
  code_detail: "Code detail",
  calories: "Calories",
  phone_number: "Phone Number",
  fax_number: "Fax Number",
  send_sms_header: "Send SMS header",
  send_email_header: "Send email header",
  include_android_app: "Include android app",
  include_ios_app: "Include IOS app",
  shop_logo: "Shop logo",
  Edit_shop: "Edit shop",
  shop_group_list: "Shop group list",
  create_shop_group: "Create shop group",
  group_name: "Group name",
  sort_id: "Sort id",
  shop_list: "Shop list",
  set_default_vat: "Set default vat for all option",
  role: "Role",
  cell_mobile: "Cell/Mobile No",
  land_line_no: "Landline No",
  country: "Country",
  size_image: "The image size is large ,choose onother one.",
  app_image_size: "The App image size is large,choose onother one.",
  web_image_size: "The Web image size is large,choose onother one.",
  kiosk_image_size: "The Kiosk image size is large,choose onother one.",
  payment_method: "Payment method",
  order_date: "Order date",
  only_asap: "Only ASAP",
  domain: "Domain",
  banner_text: "Banner text",
  twitter_url: "Twitter url",
  app_store_url: "App store url",
  facebook_url: "Facebook url",
  banner_text_language: "Banner text language",
  instgram_url: "Instagram url",
  google_play_url: "Google play url",
  post_code_message: "Post code message",
  permission_previllage: "Permission previllage",
  favicon: "Fav icon",
  banner_image: "Banner image",
  bachground_image: "Background image",
  show_banner_image: "Show banner image",
  setting: "Setting",
  background_repeact: "Background repeat",
  show_background_image: "Show background image",
  show_mobile_banner_image: "Show banner mobile image",
  image_size: "Size <= 200kb",
  banner_image_size: "Size <= 400kb",
  desctop_and_mobile_size: "Size <= 1MB",
  social_media_dimensions: "Dimensions : 1200 * 630",
  desctop_background_dimensions: "Dimensions : 1920 * 1080",
  mobile_background_dimensions: "Dimensions : 1080 * 1920",
  fav_icon_dimensions: "Dimensions : width = height",
  banner_image_dimensions: "Dimensions : width >= height * 2",
  fav_icon_size_error: "The favIcon size is large,choose onother one.",
  banner_image_size_error: "The banner image size is large,choose onother one.",
  bachground_image_size_error:
    "The banner mobile size is large,choose onother one.",
  image_size_error: "The image size is large,choose onother one.",
  create_group: "Create group",
  edit_group: "Edit group",
  group_title: "Group title",
  incorrect_pss_format: "The password format is incorrect",
  choose_potho: "Choose a potho",
  base_name: "Base name",
  one_of_each_item: "One of each item",
  online: "Online",
  maintainence: "Maintainence",
  message: "Message",
  open_close_time: "Open / Close time",
  no_data: "No data",
  number_of_product_row: "Number of products row",
  number_of_product_culmn: "Number of products column",
  number_of_submenus_row: "Number of submenus row",
  number_of_submenus_culmn: "Number of submenus column",
  number_of_items_row: "Number of items row",
  number_of_items_culmn: "Number of items column",
  next_day_pree_order: "Next day pre order",
  try_again: "Try Again",
  secret_key: "Secret key",
  public_key: "Public key",
  delete: "Delete",
  force_close_order: "Force close order",
  special_note: "Special note",
  groups: "Gropus",
  group_list: "Group list",
  staff_access: "Staff access",
  staff_access_list: "Staff access list",
  staff_access_edit: "Edit staff access",
  create_staff_access: "Create staff access",
  verify_with_height_in_appimage:
    "The width of the app image should be equal to its height.",
  verify_with_height_in_webimage:
    "The width of the web image must be greater than its height.",
  verify_with_height_in_kioskimage:
    "The width of the kiosk image must be greater than its height.",
  verify_with_height_in_shopLogo:
    "The width of the shop logo should be equal to its height. ",
  verify_with_height_in_fav_icon:
    "The width of the fav icone should be equal to its height. ",
  verify_with_height_in_banner_mobile:
    "The width of the banner mobile should be equal to its height. ",
  verify_with_height_in_banner_image:
    "The width of the banner image should be at least twice its height",
  verify_with_height_in_image:
    "The width of the image should be equal to its height.",
  admin_fee: "Admin fee",
  email_notification: "Email Notification",
  email_format_incorrect: "The email format is incorrect",
  create_email: "Create email",
  webhook: "Webhook",
  account: "Account",
  of_order: "% of order",
  static_pence: "Static(pence)",
  account_type: "Account type",
  stripe_mode: "Stripe mode",
  favicon_format: "The fav icon format should be ico.",
  last_order: "Last order",
  order_no: "Order no",
  availability: "Availability",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  from: "From",
  to: "To",
  code: "Code",
  type: "Type",
  rate: "Rate",
  rate_option: "Rate Option",
  min_order: "Min Order",
  apply_at_checkout: "Apply at checkout",
  one_time_only: "One time only",
  one_time_only_rate: "One time only rate %",
  exclude_meal_deals: "Exclude Meal Deals",
  create_promotion: "Create promotion",
  uses_per_promotion: "Uses per promotion",
  uses_per_customer: "Uses per customer",
  excluded_promotion: "Excluded promotions",
  show_in_web: "Show on the web",
  meal_deals: "Meal deal",
  banner_mobile: "Banner mobile",
  start_date_end_date_in_order_list:
    "The start date must be equal to or less than the end date",
  messages: "Messages",
  message1: "message",
  new_message: "New messages",
  no_message: "No message",
  search_by: "Search by",
  number_of_prints: "Number of Prints",
  caller_id: "Caller id",
  work_station_status: "Work station status",
  check_in: "Check in",
  check_out: "Check out",
  duration: "Duration",
  work_deficit: "Work deficit",
  password_characters:
    "The number of password characters must not be less than 4",
  delivery_charge: "Delivery Charge",
  vat_number: "Vat number",
  financial: "Financial",
  refund: "Refund",
  refunded: "Refunded",
  responsibility:
    "I accept all the responsibility and consequences of the refund",
  refund_reasone: "Refund reason",
  create_refund_reasone: "Create refund reasone",
  debt_reasone: "Debit title",
  debt: "Debit",
  create_debt: "Create debit",
  choose_shop: "Choose a shop from the shop list",
  done: "Debited",
  create_date: "Create date",
  total_debt: "Total debit",
  create_debit: "Create debit",
  confirm: "Confirm",
  result: "Result",
  dojo: "Dojo",
  create_dojo: "Create dojo",
  edit_dojo: "Edit dojo",
  t_id: "Terminal id",
  currency: "Currency",
  installer_id: "Installer id",
  software_house_id: "Software house id",
  api_key: "Api key",
  api_url: "Api url",
  menu_status: "Menu status",
  sales: "Sales",
  log_out: "Log out",
  promotions_list: "Promotions list",
  workstation_status: "Work station status",
  ws_name: "Ws name",
  last_sync: "Last sync",
  version: "Version",
  version_status: "Version status",
  last_connection: "Last connection",
  no_workstation: "No work station",
  workStation_status: " Work stations status",
  mon: "Mon",
  tues: "Tues",
  wen: "Wed",
  thurs: "Thurs",
  fri: "Fri",
  sat: "Sat",
  sun: "Sun",
  second_screen: "Second screen",
  second_screen_width_height: "The width should be 75-80% of the height",
  general_second_screen_image_sizes: "Dimensions : 1920 * 1080",
  second_screen_image_sizes: "Dimensions : 1070 * 1080",
  upload_image: "Upload image",
  add_new_image: "Add new image",
  select_shop: "Select shop",
  number_of_table: "Number of table",
  add_table: "Add table",
  edit_table: "Edit table",
  add_image: "Add image",
  short_cut: "Shortcuts",
  meal_deals: "Meal deals",
  drinks: "Drinks",
  dressings: "Dressings",
  meal_deals_label: "Meal deals label",
  drinks_label: "Drinks label",
  drewwings_label: "Dressings label",
  select_meal_deals: "Select meal deal",
  select_drinks: "Select drinks",
  select_dressings: "Select dressing",
  terminal_Webhook: "Terminal webhook",
  terminal_id: "Terminal id",
  about: "About",
  about_us: "About us",
  login_otp: "Login OTP",
  order_detail: "Order details",
  details: "Details",
  order_items: "Order Items",
  refund_payment: "Refund Payment",
  reset_order: "Reset Order",
  workstation_list: "Work station list",
  terminal_id_duplicate: "This terminal ID exists, enter another one",
  reason: "Reason",
  user_lock_screen: "User lock screen",
  apply: "Apply",
  for_all_shops: "For All Shops",
  only_this_shop: "Only This Shop",
  pricavy_policy: "Privacy Policy",
  kitchen_message: "Kitchen Message",
  create_kitchen_message: "Create kitchen message",
  report: "Report",
  export_to_pdf: "Export to pdf",
  verification_code_sent: "verification code sent",
  enter_verification_code: "Enter verification code",
  verification_code: "Verification code",
  verification_code_send: "Verification code sent",
  send_verification_code: "Send verification code",
  no_number_for_send_code: "There is no number to send verification code",
  continue: "Continue",
  resend_code: "Resend",
  et_pay: "ET pay",
  create_ET_pay: "Create ET pay",
  edit_ET_pay: "Edit ET pay",
  show_category_on_zprint: "Show Categories On ZPrint",
  sales_report: "Sales Report",
  sale_report: "Sale Report",
  edit_basket_line: "Edit Basket Line",
  external_company_list: "External company list",
  external_company_edit: "Edit external company",
  two_factor: "Two Factor",
  we_pos: "We Pos",
  web: "Web",
  app: "App",
  meta: "Meta",
  meta_title: "Meta title",
  meta_desc: "Meta description",
  meta_keywords: "Meta keywords",
  close: "Close",
  shop_activity_type: "Shop activity type",
  social_media_image: "Social media image",
  oto: "OTO",
  logo: "Logo",
  is_next: "Is next",
  categories: "Categories",
  topping_list: "Toppings list",
  create_topping: "Create topping",
  edit_topping: "Edit topping",
  dressing_list: "Dressings list",
  create_dressing: "Create dressing",
  edit_dressing: "Edit dressing",
  fixed: "Fixed",
  fixed_submenu: "Fixed Submenu",
  fof: "FOF",
  exited_items: " These items exist in the list",
  select_items: "Select item",
  extend_postcode: "Extend postcode",
  all: "All",
  beside_previous_item: "Beside previous item",
  has_cash_drawer: "Has Cash Drawer",
  product_background_color: "Products Background Color",
  background_image: "Desktop background image",
  mobile_bg_image: "Mobile background image",
  landing_page: "Landing page",
  lat: "Latitude",
  long: "Longitude",
  second_language: "Second Language",
  second_screen: "Second screen",
  second_screen_width_height: "The width should be 75-80% of the height",
  general_second_screen_image_sizes: "Dimensions : 1920 * 1080",
  second_screen_image_sizes: "Dimensions : 1070 * 1080",
  desctop_background_image_size_dimensions:
    "Desktop background image dimensions : 1920 * 1080",
  mobile_background_image_size_dimenssions:
    "Mobile background image dimensions : 1080 * 1920 ",
  social_media_size_dimenssions: "Social media image dimensions : 1200  * 630 ",
  image_size: "Size <= 200kb",
  banner_image_size: "Size <= 400kb",
  desctop_and_mobile_size: "Size <= 1MB",
  social_media_dimensions: "Dimensions : 1200 * 630",
  desctop_background_dimensions: "Dimensions : 1920 * 1080",
  mobile_background_dimensions: "Dimensions : 1080 * 1920",
  fav_icon_dimensions: "Dimensions : width = height",
  banner_image_dimensions: "Dimensions : width >= height * 2",
  fav_icon_size_error: "The favIcon size is large,choose onother one.",
  logo_size_error: "The logo size is large,choose onother one.",
  banner_image_size_error: "The banner image size is large,choose onother one.",
  desctop_background_image_size:
    "The size of the desktop background is large, choose another one.",
  mobile_background_image_size:
    "The size of the Mobile background is large,choose onother one.",
  social_media_image_size:
    "The size of the Social media image is large,choose onother one.",
  bachground_image_size_error:
    "The banner mobile size is large,choose onother one.",
  image_size_error: "The image size is large,choose onother one.",
  crusts: "Crusts",
  items: "Items",
  min_amount: "Min amount",
  number_of_items_to_apply_price: "Number of items to apply price",
  delivery_fee: "Delivery fee",
};
export const turkish = {};
export const persian = { main_nav_title: "برای تست", settings: "تنظیمات" };
