import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import {
  main_nav_button_color,
  main_nav_back_color,
  primaryColor,
  white,
} from "../../../../constants/Colors";
import toast, { Toaster } from "react-hot-toast";
import useScrollbarSize from "react-scrollbar-size";
import { marginNormalMenuHorizontal } from "../../../../constants/Sizes";
import ListHeader from "../../../../components/ListHeader";
import MenuListItem from "../../../../components/MenuListItem";
import {
  visiblePostCodePopup,
  allowedPostCodeListAction,
  selectedAllowedPostCode,
  deleteAllowedPostCodeAction,
} from "../../../../redux/GeneralSetting/Actions";
import {
  changeSelectedButtonBorderColorOnFocus,
  selectedOtemInAllLists,
  setDefaultAction,
} from "../../../../redux/menu/Actions";
const PoctCodeList = ({ setClickedItem }) => {
  const dispatch = useDispatch();
  const { height, width } = useScrollbarSize();
  const { selected_shop } = useSelector((state) => state.shopReducer);
  const { login_key, user_id } = useSelector((state) => state.loginReducer);
  const { allowed_post_code_list, selected_allowed_post_code } = useSelector(
    (state) => state.tabelAreaRedusers
  );
  const { lang } = useSelector((state) => state.selectedLanguage);
  const { change_selected_button_border_color_on_focus } = useSelector(
    (state) => state.categoryList
  );
  const {
    result_successfull,
    result_failed,
    result_message,
    loading_post_code,
  } = useSelector((state) => state.tabelAreaRedusers);
  const [postCodeList, setPostCodeList] = useState([]);
  const handleEditPostCode = (item) => {
    dispatch(visiblePostCodePopup(true));
    dispatch(selectedAllowedPostCode(item));
  };
  const handleCreatePostCode = () => {
    dispatch(visiblePostCodePopup(true));
    dispatch(selectedAllowedPostCode(null));
  };
  const handleDeleteItem = (item) => {
    dispatch(
      deleteAllowedPostCodeAction(
        login_key,
        user_id,
        selected_shop[0].ShopId,
        item.Id
      )
    );
  };
  const handleShowDeleteBox = (item) => {
    const copy_list = [...postCodeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = true;
      }
    }
    setPostCodeList(copy_list);
  };
  const handleCancelDeleteBox = (item) => {
    const copy_list = [...postCodeList];
    for (let i = 0; i < copy_list.length; i++) {
      if (item.Id == copy_list[i].Id) {
        copy_list[i].showDeleteBox = false;
      }
    }
    setPostCodeList(copy_list);
  };
  const handleCloseIcon = (item) => {
    const copy_product_list = [...postCodeList];
    for (let index = 0; index < copy_product_list.length; index++) {
      if (item.Id == copy_product_list[index].Id) {
        copy_product_list[index].showEditBox = false;
      }
    }
    setPostCodeList(copy_product_list);
  };
  const handleCloseEditBoxWithOnBlur = () => {
    const copy_product_list = [...postCodeList];
    for (let index = 0; index < copy_product_list.length; index++) {
      copy_product_list[index].showEditBox = false;
      // dispatch(justTitleAndPriceAction(0));
    }

    setPostCodeList(copy_product_list);
  };
  const handleEditIconClick = (item) => {
    const copy_product_list = [...postCodeList];
    for (let i = 0; i < copy_product_list.length; i++) {
      if (item.Id == copy_product_list[i].Id) {
        copy_product_list[i].showEditBox = true;
      } else {
        copy_product_list[i].showEditBox = false;
      }
    }
    setPostCodeList(copy_product_list);
    dispatch(selectedOtemInAllLists(item));
  };
  useEffect(() => {
    if (selected_shop.length > 0) {
      dispatch(
        allowedPostCodeListAction(login_key, user_id, selected_shop[0].ShopId)
      );
    }
  }, [selected_shop]);

  useEffect(() => {
    dispatch(changeSelectedButtonBorderColorOnFocus(true));
    return () => {
      dispatch(changeSelectedButtonBorderColorOnFocus(false));
    };
  }, [change_selected_button_border_color_on_focus]);
  useEffect(() => {
    if (allowed_post_code_list) {
      if (allowed_post_code_list.length > 0) {
        setPostCodeList(allowed_post_code_list);
      } else {
        setPostCodeList([]);
      }
    } else {
      setPostCodeList([]);
    }
  }, [allowed_post_code_list]);
  useEffect(() => {
    if (result_successfull) {
      // dispatch(setDefaultAction);
      toast.success(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "green", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-check-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    } else if (result_failed) {
      // dispatch(setDefaultAction());
      toast.error(<h5> &nbsp;&nbsp;{result_message}&nbsp;&nbsp; </h5>, {
        position: "top-center",
        duration: 3000,
        style: { backgroundColor: "red", color: "white" },
        icon: (
          <i
            style={{ fontSize: 40 }}
            class="fa fa-times-circle"
            aria-hidden="true"
          ></i>
        ),
      });
    }
  }, [result_successfull, result_failed]);

  return (
    <div
      className="countaining-div common-styles"
      style={{
        // borderLeftWidth: 0.3,
        // borderLeftColor: main_nav_button_color,
        // borderLeftStyle: "solid",
        height: "100%",
      }}
      id="countaining"
    >
      <div className="menu_list_header_container" style={{ paddingBottom: 50 }}>
        {postCodeList.length > 0 ? (
          <div>
            {postCodeList.map((item, index) => (
              <div key={index}>
                <MenuListItem
                  field_number={3}
                  item_title={item.Postcode}
                  field_1_title={item.Postcode}
                  field_2_title={`${lang.delivery_charge} : ${item.DeliveryCharge}`}
                  field_3_title={`${lang.min_order_charge} : ${item.DeliveryMinCharge}`}
                  btn_number={0}
                  delivery_charge={item.DeliveryCharge}
                  delivery_min_charge={item.DeliveryMinCharge}
                  bt_1_title={lang.edit}
                  bt_1_click={() => handleEditPostCode(item)}
                  show_delete={true}
                  delete_click={() => handleDeleteItem(item)}
                  show_edit_icon={true}
                  selected={selected_allowed_post_code}
                  item={item}
                  number={9}
                  show_delete_box={() => handleShowDeleteBox(item)}
                  cancel_delete_box={() => handleCancelDeleteBox(item)}
                  click_edit_icon={() => handleEditIconClick(item)}
                  on_blur={() => handleCloseEditBoxWithOnBlur()}
                  click_close_icon={() => handleCloseIcon(item)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="div_submenu_add_container " style={{ zIndex: 1 }}>
            <h4>{lang.noOption}</h4>
            <span style={{ width: "172px" }} onClick={handleCreatePostCode}>
              <i className="fa fa-plus" aria-hidden="true"></i>
              <p>{lang.create_post_code}</p>
            </span>
          </div>
        )}
      </div>
      <div>
        {/* STICKY ADD SUBMENU BUTTON */}
        {postCodeList.length > 0 ? (
          <div
            className="div_submenu_add_container_fixed"
            onClick={handleCreatePostCode}
            style={{
              width: 200,
              right:
                (window.innerWidth * 2) / 100 +
                width +
                marginNormalMenuHorizontal,
            }}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            <p>{lang.create_post_code}</p>
          </div>
        ) : null}
      </div>
      {loading_post_code ? <Loader /> : null}
    </div>
  );
};

export default PoctCodeList;
